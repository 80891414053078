export const GAME_TITLE = process.env.REACT_APP_GAME_NAME!

export const WIN_MESSAGES = ['Great Job!', 'लाजवाब', 'बहुत बढ़िया!']
export const GAME_COPIED_MESSAGE = 'गेम को क्लिपबोर्ड पर कॉपी किया गया'
export const NOT_ENOUGH_LETTERS_MESSAGE = 'पर्याप्त अक्षर नहीं है'
export const WORD_NOT_FOUND_MESSAGE = 'शब्द नहीं मिला'
export const HARD_MODE_ALERT_MESSAGE =
  'हार्ड मोड को केवल शुरुआत में ही सक्षम किया जा सकता है!'
export const HARD_MODE_DESCRIPTION =
  'किसी भी प्रकट संकेत का उपयोग बाद के अनुमानों में किया जाना चाहिए'
export const HIGH_CONTRAST_MODE_DESCRIPTION = 'बेहतर रंग दृष्टि के लिए'
export const CORRECT_WORD_MESSAGE = (solution: string) =>
  `शब्द "${solution}" था`
export const WRONG_SPOT_MESSAGE = (guess: string, position: number) =>
  `Must use ${guess} in position ${position}`
export const NOT_CONTAINED_MESSAGE = (letter: string) =>
  `अनुमान में शामिल होना चाहिए : ${letter}`
export const ENTER_TEXT = 'दर्ज करें'
export const DELETE_TEXT = 'हटाएं'
export const GIVE_UP_TEXT = 'हार मानें'
export const GIVE_UP_CONFIRM_TEXT =
  'क्या आप वाकई यह गेम समाप्त करके शब्द देखना चाहते हैं?'
export const STATISTICS_TITLE = 'आंकड़े'
export const GUESS_DISTRIBUTION_TEXT = 'अनुमान वितरण'
export const NEW_WORD_TEXT = 'नया शब्द:'
export const SHARE_TEXT = 'साझा करें'
export const TOTAL_TRIES_TEXT = 'कुल प्रयास'
export const SUCCESS_RATE_TEXT = 'सफलता दर'
export const CURRENT_STREAK_TEXT = 'Current streak'
export const BEST_STREAK_TEXT = 'Best streak'
