import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="कैसे खेलें?" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        6 कोशिशों में शब्द का अनुमान लगाएं। प्रत्येक अनुमान के बाद, टाइलों का
        रंग यह दिखाने के लिए बदल जाएगा कि आपका अनुमान शब्द के कितना करीब था।
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="पं"
          status="correct"
        />
        <Cell value="च" />
        <Cell value="म" />
        <Cell value="का" />
        <Cell value="ल" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        "प" अक्षर शब्द में और सही जगह पर है।
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="क्षा" />
        <Cell value="यि" />
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="क"
          status="present"
        />
        <Cell value="ज्ञा" />
        <Cell value="न" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        "क" अक्षर शब्द में है लेकिन गलत जगह पर है।
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="ज्ञा" />
        <Cell isRevealing={true} isCompleted={true} value="य" status="absent" />
        <Cell value="क" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        "य" अक्षर किसी भी स्थान पर शब्द में नहीं है।
      </p>
      <br />
      <p className="text-sm text-gray-500 dark:text-gray-300">
        रोज रात 10 बजे नया शब्द आएगा।
      </p>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        New word will come at 10 pm everyday.
      </p>
      <br />
      <hr />
      <br />
      <p className="text-sm text-gray-500 dark:text-gray-300">
        <b>Demo:</b>{' '}
        <a href="https://youtu.be/BURcbS_w3Yg" target="_blank" rel="noreferrer">
          <u>https://youtu.be/BURcbS_w3Yg</u>
        </a>
      </p>
      <br />
      <p className="text-sm text-gray-500 dark:text-gray-300">
        This game is inspired by{' '}
        <a
          href="https://www.nytimes.com/games/wordle/index.html"
          target="_blank"
          rel="noreferrer"
          tabIndex={-1}
        >
          <u>English Wordle</u>
        </a>{' '}
        and is developed by{' '}
        <u>
          <a href="https://jinswara.com/" target="_blank" rel="noreferrer">
            Team JinSwara
          </a>
        </u>
        .
      </p>
    </BaseModal>
  )
}
