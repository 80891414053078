import { getGuessStatuses } from '../../lib/statuses'
import { Cell } from './Cell'
import { matras } from '../../lib/words'
import { dotted_circle } from '../../lib/unicode.helper'
import { unicodeSplit } from '../../lib/words'

type Props = {
  guess: string
  isRevealing?: boolean
}

export const CompletedRow = ({ guess, isRevealing }: Props) => {
  const statuses = getGuessStatuses(guess)
  const splitGuess = unicodeSplit(guess)

  return (
    <div className="flex justify-center mb-1">
      {splitGuess.map((letter, i) => {
        let value
        if (!matras[i]) {
          value = letter
        } else if (matras[i].post === '' && letter === '') {
          value = matras[i].pre + dotted_circle + matras[i].post
        } else {
          value = matras[i].pre + letter + matras[i].post
        }
        return (
          <Cell
            key={i}
            value={value}
            status={statuses[i]}
            position={i}
            isRevealing={isRevealing}
            isCompleted
          />
        )
      })}
    </div>
  )
}
