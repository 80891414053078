import { classifyAkshar, AKSHAR } from '../lib/unicode.helper'
import { MAX_WORD_LENGTH, MIN_WORD_LENGTH } from './settings'

export const WORDS = [
  'यशोधर',
  'वीर',
  'योजन',
  'माया',
  'मोक्ष',
  'भोग',
  'संयोग',
  'शोक',
  'अधिगम',
  'तैजस',
  'समवाय',
  'अतिचार',
  'संसारी',
  'भरत',
  'वेदनीय',
  'आहारक',
  'मूलगुण',
  'कामदेव',
  'भय',
  'उदय',
  'मंदिर',
  'आराधना',
  'संयम',
  'चोरी',
  'जीव',
  'आयतन',
  'उपयोग',
  'अजीव',
  'देशना',
  'नियति',
  'रति',
  'अतिवीर',
  'गिरनार',
  'तप',
  'मान',
  'आदिपुराण',
  'योग',
  'संवर',
  'यथाजात',
  'मति',
  'आयु',
  'हितोपदेश',
  'साधु',
  'लोभ',
  'नेमिनाथ',
  'वेद',
  'दौलतराम',
  'बंध',
  'आकाश',
  'अरति',
  'समयसार',
  'वीतराग',
  'मोहनीय',
  'समाधि',
  'केवली',
  'दान',
  'शरण',
  'शौच',
  'अवधि',
  'करण',
  'अंतराय',
  'उपासना',
  'आवलि',
  'उपाधि',
  'महावीर',
  'वसुदेव',
  'मंगल',
  'नाम',
  'नियमसार',
  'नारायण',
  'उदय',
  'संगति',
  'आचारांग',
  'उपभोग',
  'मोह',
  'कथा',
  'तप',
  'अजित',
  'तपमद',
  'वाचना',
  'सुविधि',
  'बकुश',
  'देवसेन',
  'उपकार',
  'औषधि',
  'रूपातीत',
  'निबंधन',
  'मरुभूति',
  'औदारिक',
  'महिष',
  'संबंध',
  'रेवती',
  'विसंवाद',
  'आगार',
  'आवली',
  'परीषह',
  'जिनालय',
  'मीन',
  'बंधन',
  'घंटा',
  'ओजाहार',
  'गजदंत',
  'चिति',
  'संगमक',
  'रावण',
  'सविपाक',
  'भगवती',
  'अणु',
  'विमान',
  'एकाशना',
  'सूरि',
  'पाप',
  'मगध',
  'पल',
  'चूडामणि',
  'घनवात',
  'निरंजन',
  'आदिनाथ',
  'तुंगीगिरि',
  'दया',
  'अंजना',
  'सहकारी',
  'गांधारी',
  'अंगुल',
  'घन',
  'सेमर',
  'दंडक',
  'परिभोग',
  'संसार',
  'कांडक',
  'सुकौशल',
  'पौरुष',
  'रविषेण',
  'ऐलक',
  'उपचार',
  'जरासंघ',
  'कलश',
  'सागर',
  'अनगार',
  'परिणाम',
  'मूल',
  'संवेग',
  'राजगृह',
  'मंडल',
  'छहढाला',
  'जिन',
  'घड़ी',
  'साता',
  'पुराण',
  'माघनंदि',
  'मन',
  'इंगिनी',
  'वंदना',
  'भामंडल',
  'ऐरावत',
  'जीवहिंसा',
  'परिषह',
  'लोभ',
  'बीजपद',
  'अघातिया',
  'जिनसेन',
  'विहार',
  'योगसार',
  'कषाय',
  'बंधावलि',
  'भोगभूमि',
  'बल',
  'पंचमेरु',
  'बहुमान',
  'समिति',
  'अतिशय',
  'अतिचार',
  'उपादान',
  'हीयमान',
  'चोरी',
  'नोकषाय',
  'मंदिर',
  'कायबल',
  'राग',
  'संसारी',
  'अंगद',
  'अनुयोग',
  'सहज',
  'सीतोदा',
  'कंस',
  'गुरु',
  'सार',
  'शौरिपुर',
  'वीतराग',
  'विधान',
  'फल',
  'बहुविध',
  'घनाकार',
  'ललितांग',
  'निधि',
  'मधु',
  'निषेक',
  'पारणा',
  'षटकाय',
  'वासना',
  'रसना',
  'भाव',
  'संयमी',
  'वलय',
  'अनुभव',
  'योगी',
  'घनांगुल',
  'हिंसा',
  'समता',
  'अधोलोक',
  'पीठिका',
  'कारक',
  'शुभयोग',
  'बुध',
  'नियति',
  'मानतुंग',
  'संचया',
  'वपु',
  'देवगुरु',
  'नमिनाथ',
  'निगोद',
  'शुभ',
  'सुरगुरु',
  'यम',
  'वीर',
  'साधु',
  'भारती',
  'भेद',
  'करण',
  'शोक',
  'सुरदेव',
  'विकार',
  'मौन',
  'विजया',
  'आहारक',
  'पावन',
  'सामायिक',
  'बालतप',
  'मति',
  'संयम',
  'दुखमा',
  'मैथुन',
  'चरण',
  'नाड़ी',
  'तिगंछ',
  'परमाणु',
  'पंचम',
  'गति',
  'तपाचार',
  'रायचंद',
  'जरासंध',
  'उपधान',
  'गौतम',
  'मद',
  'अतिवीर',
  'संवर',
  'रामकथा',
  'अचल',
  'भाग',
  'शौच',
  'घनोदधि',
  'मुनि',
  'लोकाकाश',
  'अनेकांत',
  'छंद',
  'धारणा',
  'बादर',
  'जूआ',
  'संतलाल',
  'पाहुड़',
  'देव',
  'निराकार',
  'साधन',
  'गंधकुटी',
  'विवेकी',
  'औदयिक',
  'षटकाल',
  'धनुष',
  'चरम',
  'जंबु',
  'आदेय',
  'खारवेल',
  'विवेक',
  'बाली',
  'बिंदुसार',
  'भागचंद',
  'जल',
  'अंतरंग',
  'अजीव',
  'बोधि',
  'बंध',
  'भावना',
  'बहु',
  'विदिशा',
  'मदिरा',
  'उदंबर',
  'सुभग',
  'कमठ',
  'वंचना',
  'योनि',
  'अंजन',
  'परिमाण',
  'मूलाचार',
  'भीम',
  'काय',
  'माया',
  'यथाजात',
  'आराधना',
  'निकाय',
  'ईशान',
  'संकेत',
  'घनाघन',
  'जातिमद',
  'जटायु',
  'दशरथ',
  'संयत',
  'संयोग',
  'आयु',
  'कलिंग',
  'शची',
  'हरि',
  'ऋषि',
  'रोग',
  'शरण',
  'अनुदिश',
  'विभाषा',
  'हरिवंश',
  'उपाधि',
  'दधिमुख',
  'ऋषभ',
  'टीका',
  'ऋजुगति',
  'निकल',
  'योग',
  'आसन',
  'जगत',
  'चंदन',
  'एषणा',
  'रति',
  'हिंसानंद',
  'राजू',
  'तैजस',
  'विचय',
  'चूलिका',
  'संथारा',
  'दुंदुभि',
  'विभाव',
  'अघ',
  'चेलना',
  'नय',
  'मरुदेवी',
  'जिनदेव',
  'आहार',
  'पांडव',
  'भय',
  'सुमेरु',
  'रोष',
  'अंगोपांग',
  'घृतवर',
  'संघात',
  'समाधि',
  'जनक',
  'भूमि',
  'नकुल',
  'संभाषण',
  'एकविध',
  'बादाल',
  'संचार',
  'नियम',
  'नीलांजना',
  'संरंभ',
  'वैतरणी',
  'परलोक',
  'नेमिनाथ',
  'भावबंध',
  'कामदेव',
  'भावनय',
  'वादिराज',
  'महावीर',
  'बीज',
  'भूधर',
  'संशय',
  'देशना',
  'गणधर',
  'जिनमत',
  'शिवकोटि',
  'बाहुबली',
  'भव',
  'चामुंड',
  'पुंडरीक',
  'समय',
  'अकषाय',
  'संहनन',
  'चंदना',
  'दोष',
  'पूजा',
  'अनुभूति',
  'नाभिराय',
  'नाम',
  'निषध',
  'आकाश',
  'उपघात',
  'जीवंधर',
  'विराग',
  'अनशन',
  'सुषमा',
  'बिंब',
  'जितमोह',
  'आलोचना',
  'काययोग',
  'छल',
  'लोंच',
  'संपराय',
  'अजर',
  'कापोत',
  'बिंबसार',
  'संघ',
  'पंडित',
  'शांतिनाथ',
  'भानुमती',
  'आगम',
  'संचेतन',
  'ऐशान',
  'विषय',
  'बंधक',
  'जाति',
  'चमर',
  'संभव',
  'जीव',
  'मनु',
  'मुकुट',
  'चेतना',
  'वचन',
  'शिवभूति',
  'पांडुशिला',
  'करुणा',
  'अणिमा',
  'अनादि',
  'एकदेश',
  'घनधारा',
  'सहचर',
  'विदेह',
  'तनुवात',
  'गुण',
  'पिंड',
  'गिरनार',
  'विनय',
  'पुलाक',
  'उपासना',
  'लोक',
  'रतिकर',
  'शुचि',
  'गमन',
  'बलमद',
  'यति',
  'निगमन',
  'विधि',
  'राजकथा',
  'आचार',
  'जैन',
  'जानकी',
  'भागीरथी',
  'शासन',
  'दान',
  'समवाय',
  'गणिनी',
  'विमोह',
  'सुख',
  'चारण',
  'निलय',
  'रवि',
  'दशानन',
  'नवधा',
  'उपदेश',
  'मांस',
  'जिवानी',
  'विवेचन',
  'अनंत',
  'मंदार',
  'हनुमान',
  'वाचक',
  'नंदी',
  'बुधजन',
  'मोहनीय',
  'अंतराय',
  'विकथा',
  'योजन',
  'ऋजुकूला',
  'आरंभ',
  'वेद',
  'नारायण',
  'सादि',
  'सासादन',
  'उपशम',
  'बिल',
  'तम',
  'नमुचि',
  'नाराच',
  'ईहा',
  'गारव',
  'नरक',
  'वसुदेव',
  'मूढ़ता',
  'दु:ख',
  'झूठ',
  'विशेष',
  'समारंभ',
  'वेदनीय',
  'दानकथा',
  'निदान',
  'उदीरणा',
  'भरत',
  'रायमल',
  'अचेतन',
  'दाता',
  'चूड़ामणि',
  'लंका',
  'उपयोग',
  'सूतक',
  'ऋजुमति',
  'परम',
  'शिला',
  'शलाका',
  'भूतबली',
  'सीमंधर',
  'आयतन',
  'बलि',
  'अनुकंपा',
  'मूलगुण',
  'मान',
  'आशाधर',
  'तेजस',
  'नरकायु',
  'समास',
  'संघातन',
  'धवला',
  'नाड़ी',
  'मंगल',
  'विरति',
  'दिगंबर',
  'परिणामी',
  'कमल',
  'चेतन',
  'पुरु',
  'भारत',
  'बलदेव',
  'उपवास',
  'राम',
  'घाती',
  'बाधित',
  'उपासक',
  'याचना',
  'पिंगल',
  'अनाचार',
  'एकांत',
  'धनंजय',
  'विराधन',
  'मिथिला',
  'ममकार',
].filter((word) => {
  const length = word
    .split('')
    .filter((_, i) => classifyAkshar(word, i) === AKSHAR.POORA).length
  return length >= MIN_WORD_LENGTH && length <= MAX_WORD_LENGTH
})
