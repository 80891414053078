export enum AKSHAR {
  AADHA,
  POORA,
  MATRA,
}

export const dotted_circle = '\u25cc'

export function classifyAkshar(word: string, i: number): number {
  const cc = word.charCodeAt(i)
  if (cc >= 0x0900 && cc <= 0x0903) return AKSHAR.MATRA
  if (cc >= 0x093a && cc <= 0x0957) return AKSHAR.MATRA
  if (i === word.length - 1) return AKSHAR.POORA
  if (word.charCodeAt(i + 1) === 0x094d) return AKSHAR.AADHA
  return AKSHAR.POORA
}

export const getMatras = (word: string) => {
  const puzzle = []
  const row = []
  let needs_new = false
  for (let i = 0; i < word.length; i++) {
    const c = classifyAkshar(word, i)

    if (i === 0 || (c !== AKSHAR.MATRA && needs_new)) {
      if (classifyAkshar(word, i - 1) === AKSHAR.POORA)
        row[row.length - 1] += dotted_circle
      row.push('')
      puzzle.push({ pre: '', post: '', mid: '' })
    }
    if (c === AKSHAR.MATRA) {
      row[row.length - 1] += word.charAt(i)
      if (word.charCodeAt(i) === 0x094d)
        puzzle[puzzle.length - 1].pre += word.charAt(i)
      else puzzle[puzzle.length - 1].post += word.charAt(i)
    }
    if (c === AKSHAR.AADHA) {
      row[row.length - 1] += word.charAt(i)
      puzzle[puzzle.length - 1].pre += word.charAt(i)
    }
    if (c === AKSHAR.POORA) {
      puzzle[puzzle.length - 1].mid = word.charAt(i)
    }

    needs_new =
      c === AKSHAR.POORA ||
      (c === AKSHAR.MATRA && word.charCodeAt(i) !== 0x094d)
  }
  return { matras: puzzle, row }
}
