export const VALID_GUESSES = [
    'अँकटी',
    'अँकड़ी',
    'अँकरास',
    'अँकरोर',
    'अँकरोरा',
    'अँकरोरी',
    'अँकरौरी',
    'अँकवार',
    'अँकवारना',
    'अँकसी',
    'अँका',
    'अँकाई',
    'अँकाव',
    'अँकुड़ा',
    'अँकुड़ी',
    'अँकुरना',
    'अँकुरा',
    'अँकुराना',
    'अँकुराया',
    'अँकुरी',
    'अँकुसी',
    'अँकोर',
    'अँख',
    'अँखड़ी',
    'अँखियाँ',
    'अँखुआ',
    'अँखुआना',
    'अँखुआया',
    'अँगऊँ',
    'अँगड़ाना',
    'अँगडाई',
    'अँगना',
    'अँगरखा',
    'अँगरना',
    'अँगराना',
    'अँगरी',
    'अँगरेखा',
    'अँगरेजी',
    'अँगवारा',
    'अँगारखा',
    'अँगिया',
    'अँगीठा',
    'अँगीठी',
    'अँगुठी',
    'अँगुरिया',
    'अँगुसाना',
    'अँगुसी',
    'अँगूठा',
    'अँगूठी',
    'अँगूठे',
    'अँगेजना',
    'अँगेठी',
    'अँगोजना',
    'अँगौंगा',
    'अँचरा',
    'अँजोरना',
    'अँजोरा',
    'अँजोरी',
    'अँटना',
    'अँटाना',
    'अँटिया',
    'अँटियाना',
    'अँटौतल',
    'अँठली',
    'अँठुली',
    'अँड़सना',
    'अँडिया',
    'अँडुआ',
    'अँडुआना',
    'अँतड़ी',
    'अँतरा',
    'अँतराना',
    'अँधियारा',
    'अँधियारी',
    'अँधियाव',
    'अँधेरना',
    'अँधेरा',
    'अँधेरी',
    'अँधेरे',
    'अँधोटी',
    'अँसुआना',
    'अँसुवन',
    'अंक',
    'अंकः',
    'अंकक',
    'अंककार',
    'अंकगणित',
    'अंकगणितीय',
    'अंकगत',
    'अंकड़',
    'अंकति',
    'अंकन',
    'अंकनी',
    'अंकप',
    'अंकपट्ट',
    'अंकपत्र',
    'अंकपालि',
    'अंकपालिका',
    'अंकपाली',
    'अंकपूरण',
    'अंकमुख',
    'अंकलंक',
    'अंकलोप',
    'अंकशायिनी',
    'अंकाति',
    'अंकानुसार',
    'अंकिका',
    'अंकित',
    'अंकितता',
    'अंकित्र',
    'अंकिनी',
    'अंकिश्त',
    'अंकीय',
    'अंकुर',
    'अंकुरण',
    'अंकुरना',
    'अंकुरार्बुद',
    'अंकुरावली',
    'अंकुरित',
    'अंकुरितयौवना',
    'अंकुलास्थि',
    'अंकुश',
    'अंकुशहीन',
    'अंकुशहीनता',
    'अंकुशाकार',
    'अंकुशित',
    'अंकुशी',
    'अंकेक्षक',
    'अंकेक्षा',
    'अंकेक्षित',
    'अंको',
    'अंकों',
    'अंक्य',
    'अंखडनीय',
    'अंग',
    'अंगः',
    'अंगघात',
    'अंगघातित',
    'अंगच्छिन्न',
    'अंगच्छेद',
    'अंगच्छेदन',
    'अंगछेद',
    'अंगछेदन',
    'अंगज',
    'अंगजा',
    'अंगजात',
    'अंगजाया',
    'अंगड',
    'अंगड़',
    'अंगता',
    'अंगति',
    'अंगतुक',
    'अंगत्राण',
    'अंगद',
    'अंगदा',
    'अंगदान',
    'अंगदीप्ति',
    'अंगध',
    'अंगधारी',
    'अंगन',
    'अंगना',
    'अंगनाप्रिय',
    'अंगनाश',
    'अंगन्यास',
    'अंगपाली',
    'अंगभंग',
    'अंगभीर',
    'अंगभीरता',
    'अंगभूत',
    'अंगमर्द',
    'अंगमर्दक',
    'अंगमर्दका',
    'अंगमर्दी',
    'अंगमारी',
    'अंगयष्टि',
    'अंगयुक्तता',
    'अंगयोजक',
    'अंगरक्षक',
    'अंगरक्षिका',
    'अंगरक्षी',
    'अंगराग',
    'अंगराज',
    'अंगरुह',
    'अंगलेट',
    'अंगवस्त्र',
    'अंगविकल',
    'अंगविकास',
    'अंगविज्ञान',
    'अंगविद',
    'अंगविद्य',
    'अंगहार',
    'अंगहीन',
    'अंगा',
    'अंगांगी',
    'अंगाकड़ी',
    'अंगाड़िया',
    'अंगाधिप',
    'अंगानुभूति',
    'अंगाभिनय',
    'अंगार',
    'अंगारक',
    'अंगारकाडी',
    'अंगारण',
    'अंगारपर्ण',
    'अंगारपाचित',
    'अंगारा',
    'अंगारिका',
    'अंगारिणी',
    'अंगारित',
    'अंगारी',
    'अंगारे',
    'अंगारों',
    'अंगिका',
    'अंगिया',
    'अंगिरस',
    'अंगिरा',
    'अंगिरावृत्त',
    'अंगी',
    'अंगीकरण',
    'अंगीकार',
    'अंगीकारक',
    'अंगीकार्य',
    'अंगीकृत',
    'अंगीकृति',
    'अंगीठी',
    'अंगीभूत',
    'अंगीय',
    'अंगुल',
    'अंगुलः',
    'अंगुलप्रमाण',
    'अंगुलांक',
    'अंगुलि',
    'अंगुलिचालन',
    'अंगुलिछाप',
    'अंगुलित्र',
    'अंगुली',
    'अंगुलीधानी',
    'अंगुलीयक',
    'अंगुल्यादेश',
    'अंगुश्त',
    'अंगुश्तनर',
    'अंगुश्तरी',
    'अंगुश्ताना',
    'अंगुष्ठ',
    'अंगुष्ठमात्रक',
    'अंगुष्ठांक',
    'अंगूठा',
    'अंगूठी',
    'अंगूठे',
    'अंगूर',
    'अंगूरी',
    'अंगेट',
    'अंगों',
    'अंगोच्छेदन',
    'अंगोछ',
    'अंगोछना',
    'अंगोछा',
    'अंग्रमैन्यू',
    'अंग्रेज',
    'अंग्रेज़ी',
    'अंग्रेजी',
    'अंग्रेज़',
    'अंग्रेज़ी',
    'अंघिया',
    'अंचति',
    'अंचल',
    'अंचलाधीश',
    'अंचलीय',
    'अंचलीयता',
    'अंचलेश्वर',
    'अंचित',
    'अंचितनीय',
    'अंछर',
    'अंछ्या',
    'अंजन',
    'अंजनकेश',
    'अंजनहारी',
    'अंजना',
    'अंजनाः',
    'अंजनाद्रि',
    'अंजनावती',
    'अंजनिका',
    'अंजनियारी',
    'अंजनी',
    'अंजर',
    'अंजलि',
    'अंजलिबद्घतः',
    'अंजलिबद्घता',
    'अंजली',
    'अंजस',
    'अंजसा',
    'अंजही',
    'अंजाम',
    'अंजि',
    'अंजिष्ठ',
    'अंजी',
    'अंजीर',
    'अंजुमन',
    'अंजुमनआरा',
    'अंजुरी',
    'अंजुली',
    'अंझा',
    'अंटबर',
    'अंटशंट',
    'अंटसंट',
    'अंटा',
    'अंटाघर',
    'अंटाचित',
    'अंटी',
    'अंटीगर',
    'अंटीगरी',
    'अंटीबाज़',
    'अंटीबाज़ी',
    'अंटीमार',
    'अंठी',
    'अंड',
    'अंडक',
    'अंडकोश',
    'अंडखोल',
    'अंडज',
    'अंडजा',
    'अंडजेश्वर',
    'अंडधर',
    'अंडपीत',
    'अंडपुटी',
    'अंडबंड',
    'अंडमान',
    'अंडयुक्त',
    'अंडर',
    'अंडरग्राउंड',
    'अंडरण',
    'अंडरना',
    'अंडरलाइन',
    'अंडरवीअर',
    'अंडरस्टैंडिंग',
    'अंडवृत्त',
    'अंडश्वेत',
    'अंडस',
    'अंड़गेबाज़ी',
    'अंडा',
    'अंडाः',
    'अंडाकर्षित',
    'अंडाकार',
    'अंडाकृति',
    'अंडाद',
    'अंडालु',
    'अंडाशय',
    'अंडाशी',
    'अंडाहारी',
    'अंडिद',
    'अंडी',
    'अंडीर',
    'अंडे',
    'अंडैल',
    'अंत',
    'अंतः',
    'अंतःकरण',
    'अंतःकरणीय',
    'अंतःगृह',
    'अंतःग्रहीत',
    'अंतःपटी',
    'अंतःपाल',
    'अंतःपुर',
    'अंतःपुरिक',
    'अंतःपुरिका',
    'अंतःपुरुष',
    'अंतःप्रकृति',
    'अंतःप्रज्ञा',
    'अंतःप्रविष्ट',
    'अंतःप्राकृतिक',
    'अंतःप्रेरणा',
    'अंतःप्रेरित',
    'अंतःभूत',
    'अंतःभूतता',
    'अंतःभूमि',
    'अंतःशक्ति',
    'अंतःश्वसन',
    'अंतःसलिल',
    'अंतःस्थ',
    'अंतःस्थता',
    'अंतःस्थिति',
    'अंतःस्रवण',
    'अंतक',
    'अंतकारी',
    'अंतक्षिर्प्त',
    'अंतग',
    'अंतगत',
    'अंतगामी',
    'अंतगृर्ही',
    'अंतग्रर्हण',
    'अंतग्रर्हीत',
    'अंतग्रार्ही',
    'अंतग्रेर्ह',
    'अंतघाती',
    'अंतचिर्त्र',
    'अंतज्र्वाला',
    'अंतड़ियां',
    'अंततः',
    'अंततोगत्वा',
    'अंतध्र्वस',
    'अंतध्र्वसी',
    'अंतनिर्रीक्षण',
    'अंतनिर्विष्ट',
    'अंतनिर्हित',
    'अंतपरिधान',
    'अंतपाल',
    'अंतप्रर्स्थपन',
    'अंतबाकंक',
    'अंतबोर्ध',
    'अंतब्रर्ह्म',
    'अंतभूत',
    'अंतभूमि',
    'अंतयुक्त',
    'अंतयोर्ग',
    'अंतर',
    'अंतरंग',
    'अंतरंगता',
    'अंतरंगहीनता',
    'अंतरंगी',
    'अंतरक',
    'अंतरग्रहण',
    'अंतरण',
    'अंतरणकर्ता',
    'अंतरणीय',
    'अंतरणीयता',
    'अंतरतम',
    'अंतरदेशीय',
    'अंतरप्रदेशीय',
    'अंतरप्रभव',
    'अंतरप्रांतीय',
    'अंतरप्रादेशिक',
    'अंतरम',
    'अंतरमहाद्वीपीय',
    'अंतरयण',
    'अंतरयन',
    'अंतरयुक्त',
    'अंतरराज्यीय',
    'अंतरराष्ट्र',
    'अंतरराष्ट्रिय',
    'अंतरवर्ती',
    'अंतरवाली',
    'अंतरशायी',
    'अंतरस्थ',
    'अंतरस्थायी',
    'अंतरस्थित',
    'अंतरहीन',
    'अंतरहीनता',
    'अंतरा',
    'अंतराकाश',
    'अंतराकूत',
    'अंतरागत',
    'अंतरागम',
    'अंतरागमन',
    'अंतरात्मा',
    'अंतरात्मीय',
    'अंतराधान',
    'अंतराधिदेश',
    'अंतरानुभागीय',
    'अंतरानुभूति',
    'अंतराबंध',
    'अंतराभिमुखता',
    'अंतराभिमुखी',
    'अंतराय',
    'अंतरायण',
    'अंतरायन',
    'अंतराल',
    'अंतरालक',
    'अंतरालन',
    'अंतरालवर्ती',
    'अंतरालहीन',
    'अंतरालहीनता',
    'अंतरालीय',
    'अंतरालेखन',
    'अंतरालों',
    'अंतरावधि',
    'अंतरावास',
    'अंतरावासी',
    'अंतराविष्ट',
    'अंतरावेदी',
    'अंतरावेश',
    'अंतरिंद्रिय',
    'अंतरिंद्रीय',
    'अंतरिक',
    'अंतरिका',
    'अंतरिक्ष',
    'अंतरिक्षः',
    'अंतरिक्षचर',
    'अंतरिक्षीय',
    'अंतरित',
    'अंतरितता',
    'अंतरिम',
    'अंतरिमता',
    'अंतरीक्षक',
    'अंतरीक्षण',
    'अंतरीप',
    'अंतरीय',
    'अंतर्',
    'अंतर्काट',
    'अंतर्क्षेत्र',
    'अंतर्क्षेपण',
    'अंतर्क्षेषण',
    'अंतर्गत',
    'अंतर्गम',
    'अंतर्गमन',
    'अंतर्गेह',
    'अंतर्गेहीय',
    'अंतर्ग्रहण',
    'अंतर्घट',
    'अंतर्चक्र',
    'अंतर्चेतना',
    'अंतर्जठर',
    'अंतर्जात',
    'अंतर्जातीय',
    'अंतर्ज्ञान',
    'अंतर्ज्ञानी',
    'अंतर्तम',
    'अंतर्दर्शन',
    'अंतर्दर्शी',
    'अंतर्दष्टि',
    'अंतर्दिशा',
    'अंतर्दीप्ति',
    'अंतर्दुग',
    'अंतर्दृष्टा',
    'अंतर्दृष्टि',
    'अंतर्देश',
    'अंतर्देशी',
    'अंतर्देशीय',
    'अंतर्द्वद्व',
    'अंतर्धान',
    'अंतर्धानन',
    'अंतर्ध्यान',
    'अंतर्नगर',
    'अंतर्नियम',
    'अंतर्निहित',
    'अंतर्पट',
    'अंतर्पत्र',
    'अंतर्परिधान',
    'अंतर्परीक्षण',
    'अंतर्पुरुष',
    'अंतर्पूरण',
    'अंतर्बदल',
    'अंतर्बाधक',
    'अंतर्भाग',
    'अंतर्भाव',
    'अंतर्भावना',
    'अंतर्भूत',
    'अंतर्मनस्क',
    'अंतर्मनस्कता',
    'अंतर्मार्ग',
    'अंतर्मुखता',
    'अंतर्मुखी',
    'अंतर्याम',
    'अंतर्यामी',
    'अंतर्राष्ट्रीय',
    'अंतर्राष्ट्रीयता',
    'अंतर्लब',
    'अंतर्लीन',
    'अंतर्लीनता',
    'अंतर्वर्तिता',
    'अंतर्वर्ती',
    'अंतर्वश',
    'अंतर्वस्तु',
    'अंतर्वादी',
    'अंतर्वासन',
    'अंतर्वाह',
    'अंतर्विरोध',
    'अंतर्विवेकशीलता',
    'अंतर्विवेकी',
    'अंतर्विष्ट',
    'अंतर्वेग',
    'अंतर्वेद',
    'अंतर्वेदना',
    'अंतर्वेधक',
    'अंतर्वेधन',
    'अंतर्वेधित',
    'अंतर्वेश',
    'अंतर्वेशन',
    'अंतर्वेशित',
    'अंतर्स्थान',
    'अंतर्स्थापन',
    'अंतर्स्थापना',
    'अंतर्स्थापित',
    'अंतर्हास',
    'अंतर्हित',
    'अंतवंत',
    'अंतवत्ता',
    'अंतवर्ण',
    'अंतवर्ती',
    'अंतवाक्य',
    'अंतवासिनी',
    'अंतवासी',
    'अंतविरोध',
    'अंतविर्कार',
    'अंतविह्न',
    'अंतवैयक्तिक',
    'अंतव्र्याप्त',
    'अंतव्र्याप्ति',
    'अंतव्र्यास',
    'अंतशय्या',
    'अंतश्चंद्रता',
    'अंतश्चेतना',
    'अंतस',
    'अंतसंचार',
    'अंतसर्ग',
    'अंतस्तत्त्व',
    'अंतस्तप्त',
    'अंतस्तल',
    'अंतस्ताप',
    'अंतस्त्वक',
    'अंतस्थ',
    'अंतस्थर्ता',
    'अंतस्सलिल',
    'अंतस्सलिला',
    'अंतस्सार',
    'अंतहीन',
    'अंतहीनता',
    'अंताक्षर',
    'अंताक्षरी',
    'अंतावशायी',
    'अंतावसायी',
    'अंति',
    'अंतिक',
    'अंतिका',
    'अंतिम',
    'अंतिमतम',
    'अंतिमता',
    'अंतिमांक',
    'अंतिमांश',
    'अंतिमाक्षर',
    'अंतिमेत्थम',
    'अंतेनिवास',
    'अंतोपयोगी',
    'अंत्य',
    'अंत्यज',
    'अंत्यजा',
    'अंत्यपद',
    'अंत्यबल',
    'अंत्यमूल',
    'अंत्ययुग',
    'अंत्या',
    'अंत्यांश',
    'अंत्याकार',
    'अंत्याक्षर',
    'अंत्याक्षरी',
    'अंत्यानुप्रास',
    'अंत्यावसायी',
    'अंत्याश्रम',
    'अंत्याश्रमी',
    'अंत्येष्टि',
    'अंत्योदात्तशब्द',
    'अंत्र',
    'अंथऊ',
    'अंदर',
    'अंदरकोट',
    'अंदरख़ाने',
    'अंदरला',
    'अंदरी',
    'अंदरीपन',
    'अंदरुनी',
    'अंदरुनीपन',
    'अंदाज',
    'अंदाज़',
    'अंदाज़न',
    'अंदाज़ना',
    'अंदाज़पट्टी',
    'अंदाज़पीटी',
    'अंदाज़पीर',
    'अंदाज़पीरी',
    'अंदाज़ा',
    'अंदाज़े',
    'अंदाजा',
    'अंदाजी',
    'अंदाजे',
    'अंदाज़',
    'अंदु',
    'अंदेशा',
    'अंदोरा',
    'अंदोह',
    'अंध',
    'अंधक',
    'अंधकार',
    'अंधकारः',
    'अंधकारण',
    'अंधकारपूर्ण',
    'अंधकारपूर्णता',
    'अंधकारमय',
    'अंधकारहीन',
    'अंधकारि',
    'अंधखोपड़ी',
    'अंधड़',
    'अंधता',
    'अंधनंगापन',
    'अंधबाई',
    'अंधबिन्दु',
    'अंधभक्त',
    'अंधमति',
    'अंधविश्वासी',
    'अंधा',
    'अंधाकुप्प',
    'अंधाधुंध',
    'अंधाधुन्ध',
    'अंधानुकरण',
    'अंधानुकर्ता',
    'अंधानुगामी',
    'अंधानुयायिता',
    'अंधानुयायी',
    'अंधापन',
    'अंधार',
    'अंधाहि',
    'अंधिका',
    'अंधियार',
    'अंधियारी',
    'अंधिर',
    'अंधिरप',
    'अंधिरस्कंध',
    'अंधी',
    'अंधुल',
    'अंधेपन',
    'अंधेर',
    'अंधेरगर्दी',
    'अंधेरा',
    'अंधेरी',
    'अंधेरे',
    'अंपजीकृत',
    'अंपायर',
    'अंब',
    'अंबक',
    'अंबर',
    'अंबरचर',
    'अंबरचारी',
    'अंबरद',
    'अंबरलेखी',
    'अंबरसारी',
    'अंबरांत',
    'अंबरांतीय',
    'अंबराई',
    'अंबरारंभ',
    'अंबरीय',
    'अंबरीष',
    'अंबरौका',
    'अंबष्ट',
    'अंबष्टा',
    'अंबा',
    'अंबाझोर',
    'अंबाड़ा',
    'अंबार',
    'अंबारी',
    'अंबारीय',
    'अंबालिका',
    'अंबिका',
    'अंबी',
    'अंबु',
    'अंबुकीश',
    'अंबुग',
    'अंबुचर',
    'अंबुज',
    'अंबुजा',
    'अंबुजाक्ष',
    'अंबुजात',
    'अंबुजासन',
    'अंबुजासना',
    'अंबुद',
    'अंबुधन',
    'अंबुधर',
    'अंबुधि',
    'अंबुनिधि',
    'अंबुपात',
    'अंबुभव',
    'अंबुमात्रज',
    'अंबुराज',
    'अंबुरूह',
    'अंबुवाह',
    'अंबुवाही',
    'अंबुशायी',
    'अंबोह',
    'अंभ',
    'अंभज',
    'अंभसार',
    'अंभारि',
    'अंभोज',
    'अंभोजिनी',
    'अंलकार',
    'अंश',
    'अंशः',
    'अंशकालिक',
    'अंशकालीन',
    'अंशतः',
    'अंशता',
    'अंशदत्त',
    'अंशदाताओं',
    'अंशदान',
    'अंशदायी',
    'अंशधर',
    'अंशधारी',
    'अंशपत्र',
    'अंशबंध्यता',
    'अंशबोध',
    'अंशभागी',
    'अंशभूत',
    'अंशमेखला',
    'अंशयुक्त',
    'अंशल',
    'अंशहारी',
    'अंशहीन',
    'अंशहीनता',
    'अंशाक',
    'अंशाधिकार',
    'अंशाधिकारी',
    'अंशार्थी',
    'अंशावतार',
    'अंशी',
    'अंशीय',
    'अंशीयता',
    'अंशु',
    'अंशुक',
    'अंशुधर',
    'अंशुमय',
    'अंशुमान',
    'अंशुमाली',
    'अंशुल',
    'अंशोच्छेदन',
    'अंशोद्धरण',
    'अंस',
    'अंसख्यता',
    'अंसन्न',
    'अंसपूर्ति',
    'अंसभारिक',
    'अंसल',
    'अंसारी',
    'अंस्य',
    'अंहकार',
    'अऊठा',
    'अऊत',
    'अऊलना',
    'अऋणी',
    'अक',
    'अकं',
    'अकंटक',
    'अकंठ',
    'अकंपन',
    'अकंपित',
    'अकंपितता',
    'अककल',
    'अकच',
    'अकचकाना',
    'अकच्छ',
    'अकटिबद्घ',
    'अकटिबद्घता',
    'अकट्टर',
    'अकठिन',
    'अकठोर',
    'अकठोरता',
    'अकड़',
    'अकड़तकड़',
    'अकड़न',
    'अकड़ना',
    'अकड़फों',
    'अकड़बाज़',
    'अकड़बाज़ी',
    'अकड़ू',
    'अकडै',
    'अकण्र्य',
    'अकतौर',
    'अकथन',
    'अकथनीय',
    'अकथनीयता',
    'अकथित',
    'अकथ्य',
    'अकथ्यता',
    'अकधक',
    'अकनना',
    'अकनिष्ठ',
    'अकपट',
    'अकबक',
    'अकबकाना',
    'अकबर',
    'अकबरः',
    'अकर',
    'अकरण',
    'अकरणीय',
    'अकरा',
    'अकरापन',
    'अकराम',
    'अकरास',
    'अकरूण',
    'अकरूणा',
    'अकर्ण',
    'अकर्तन',
    'अकर्तव्य',
    'अकर्ता',
    'अकर्तृक',
    'अकर्म',
    'अकर्मक',
    'अकर्मठ',
    'अकर्मण्य',
    'अकर्मण्यतः',
    'अकर्मण्यता',
    'अकर्मा',
    'अकर्मी',
    'अकल',
    'अकलंक',
    'अकलंकता',
    'अकलखु',
    'अकला',
    'अकलात्मक',
    'अकलात्मकता',
    'अकलुष',
    'अकलुषता',
    'अकल्क',
    'अकल्कक',
    'अकल्पनीय',
    'अकल्पनीयता',
    'अकल्पित',
    'अकल्मष',
    'अकल्याण',
    'अकल्याणकारिता',
    'अकल्याणकारी',
    'अकल्याणाकारी',
    'अकस',
    'अकसर',
    'अकसरीयत',
    'अकसी',
    'अकसीर',
    'अकसीरगर',
    'अकस्मात',
    'अकह',
    'अक़ल',
    'अकांड',
    'अकांडजात',
    'अकांडजातता',
    'अकाउंट',
    'अकाउंटेंट',
    'अकाउंटैंट',
    'अकाउंटैंटी',
    'अकाचित',
    'अकाज',
    'अकाजी',
    'अकाट',
    'अकाट्य',
    'अकाट्यता',
    'अकाड़ी',
    'अकातर',
    'अकादमी',
    'अकान्तरंजन',
    'अकाम',
    'अकामतः',
    'अकामना',
    'अकाम्य',
    'अकाय',
    'अकारण',
    'अकारणतः',
    'अकारणता',
    'अकारथ',
    'अकारथता',
    'अकाराणु',
    'अकारादिका',
    'अकारादिक्रम',
    'अकार्बनिक',
    'अकार्य',
    'अकार्यकारी',
    'अकार्यता',
    'अकार्यरत',
    'अकार्यरतता',
    'अकार्यान्वयन',
    'अकार्यिन्वत',
    'अकाल',
    'अकालग्रस्त',
    'अकालजात',
    'अकालतः',
    'अकालपक्य',
    'अकालातीत',
    'अकालिक',
    'अकाली',
    'अकालोत्पन्न',
    'अकाल्पनिक',
    'अकाल्पनिकता',
    'अकाव्यात्मक',
    'अकास',
    'अकासी',
    'अकिंचन',
    'अकिंचनकरण',
    'अकितव',
    'अकीर्ति',
    'अकीर्तिकर',
    'अकुंठ',
    'अकुंठित',
    'अकुटिल',
    'अकुटिलता',
    'अकुटुंब',
    'अकुपंचर',
    'अकुप्प',
    'अकुल',
    'अकुलागत',
    'अकुलागति',
    'अकुलाता',
    'अकुलाना',
    'अकुलाहट',
    'अकुलिनी',
    'अकुलीन',
    'अकुलीनता',
    'अकुलीय',
    'अकुश',
    'अकुशल',
    'अकुशलता',
    'अकुशाग्र',
    'अकूट',
    'अकूटकृत',
    'अकूत',
    'अकूतता',
    'अकूपार',
    'अकूर्च',
    'अकूल',
    'अकूवार',
    'अकूहल',
    'अकृत',
    'अकृतकार्य',
    'अकृतकार्यता',
    'अकृतज्ञ',
    'अकृतज्ञता',
    'अकृततः',
    'अकृतशुल्क',
    'अकृतात्मा',
    'अकृतार्थ',
    'अकृतार्थता',
    'अकृति',
    'अकृतित्व',
    'अकृत्य',
    'अकृत्यकारी',
    'अकृत्रिम',
    'अकृत्रिमता',
    'अकृपण',
    'अकृपणता',
    'अकृपा',
    'अकृपालु',
    'अकृश',
    'अकृषित',
    'अकृष्ट',
    'अकृष्ण',
    'अकेंद्रित',
    'अकेंद्रितता',
    'अकेतन',
    'अकेला',
    'अकेलापन',
    'अकेली',
    'अकेले',
    'अकेलेपन',
    'अकेश',
    'अकैया',
    'अकोट',
    'अकोतर',
    'अकोप',
    'अकोमल',
    'अकोमलकाय',
    'अकोमलकायता',
    'अकोमलांग',
    'अकोमलांगता',
    'अकोविद',
    'अकोविदता',
    'अकोसना',
    'अकौआ',
    'अकौटा',
    'अकौतुक',
    'अक्',
    'अक्कड़',
    'अक्का',
    'अक्कादियाई',
    'अक्कास',
    'अक्कासी',
    'अक्ख',
    'अक्खड़',
    'अक्खड़पन',
    'अक्खा',
    'अक्टूबर',
    'अक्त',
    'अक्तुंग',
    'अक्तूबर',
    'अक्रद',
    'अक्रम',
    'अक्रमतः',
    'अक्रमता',
    'अक्रमिक',
    'अक्रमित',
    'अक्रर्',
    'अक्रर्गीर',
    'अक्रांत',
    'अक्रान्त',
    'अक्रिय',
    'अक्रियकरण',
    'अक्रियता',
    'अक्रिया',
    'अक्रियाशील',
    'अक्रीदत',
    'अक्रीदतमंद',
    'अक्रीदा',
    'अक्रुद्घ',
    'अक्रूर',
    'अक्रोध',
    'अक्रोधी',
    'अक्लांत',
    'अक्लांति',
    'अक्वणित',
    'अक्ष',
    'अक्षक',
    'अक्षकास्थि',
    'अक्षकृत',
    'अक्षज',
    'अक्षजन',
    'अक्षत',
    'अक्षतंतु',
    'अक्षतता',
    'अक्षतवीर्य',
    'अक्षता',
    'अक्षति',
    'अक्षतिग्रस्त',
    'अक्षत्री',
    'अक्षदंड',
    'अक्षदेवी',
    'अक्षधर',
    'अक्षधुर',
    'अक्षनति',
    'अक्षम',
    'अक्षमता',
    'अक्षमली',
    'अक्षमा',
    'अक्षमाला',
    'अक्षम्य',
    'अक्षय',
    'अक्षयता',
    'अक्षयनिधि',
    'अक्षर',
    'अक्षरचारी',
    'अक्षरज्ञ',
    'अक्षरता',
    'अक्षरन्यास',
    'अक्षरमुख',
    'अक्षरयोजक',
    'अक्षरशः',
    'अक्षरांकन',
    'अक्षरांकित',
    'अक्षरांकीय',
    'अक्षरांग',
    'अक्षरांत',
    'अक्षरांभ',
    'अक्षरात्मक',
    'अक्षरानुसरण',
    'अक्षरारंभ',
    'अक्षरी',
    'अक्षरीय',
    'अक्षरों',
    'अक्षरौटी',
    'अक्षर्य',
    'अक्षल',
    'अक्षलाग्र',
    'अक्षवाट',
    'अक्षवृत्त',
    'अक्षांत',
    'अक्षांतर',
    'अक्षांति',
    'अक्षांश',
    'अक्षांशीय',
    'अक्षाग्र',
    'अक्षाति',
    'अक्षि',
    'अक्षिकूट',
    'अक्षिगत',
    'अक्षिगुहिका',
    'अक्षिगोल',
    'अक्षित',
    'अक्षिताल',
    'अक्षिद',
    'अक्षिदोलन',
    'अक्षीय',
    'अक्षुण्ण',
    'अक्षुण्णता',
    'अक्षुधा',
    'अक्षुर',
    'अक्षेत्र',
    'अक्षेत्री',
    'अक्षैहिणी',
    'अक्षोन्मुख',
    'अक्षोभ्य',
    'अक्षोम',
    'अक्षौहिणी',
    'अक्स',
    'अक्सर',
    'अक्सैलोमीटर',
    'अख',
    'अखंड',
    'अखंडता',
    'अखंडवीर्य',
    'अखंडित',
    'अखड़िया',
    'अखण्ड',
    'अखबार',
    'अखबारी',
    'अखरना',
    'अखरा',
    'अखरी',
    'अखरोट',
    'अख़नी',
    'अख़बार',
    'अख़रोट',
    'अख़लाक़',
    'अखाड़ा',
    'अखाड़े',
    'अखाड़ें',
    'अखाड़ेबाज़ी',
    'अखात',
    'अखादे',
    'अखाद्य',
    'अखाद्यता',
    'अखाव',
    'अखिन्न',
    'अखिल',
    'अखिलता',
    'अखिलेश',
    'अखूट',
    'अखेद',
    'अखैबर',
    'अखोर',
    'अखोह',
    'अख्यति',
    'अख्यात',
    'अग',
    'अगंभीरता',
    'अगग',
    'अगच्छ',
    'अगज',
    'अगठित',
    'अगड़धत्त',
    'अगड़म',
    'अगड़ी',
    'अगड़ीपन',
    'अगढ',
    'अगढ़',
    'अगणनीय',
    'अगणनीयता',
    'अगणित',
    'अगणिततः',
    'अगणितता',
    'अगत',
    'अगति',
    'अगतिक',
    'अगतिकता',
    'अगतीपन',
    'अगत्या',
    'अगत्वर',
    'अगत्वरता',
    'अगद',
    'अगदंकरता',
    'अगदंकार',
    'अगदकरण',
    'अगन',
    'अगम',
    'अगमन',
    'अगमनशील',
    'अगम्य',
    'अगम्यगा',
    'अगम्यता',
    'अगम्यागामी',
    'अगर',
    'अगरचे',
    'अगरबत्तियों',
    'अगरबत्ती',
    'अगरा',
    'अगराई',
    'अगरिष्ठ',
    'अगरी',
    'अगरु',
    'अगरू',
    'अगरूबत्ती',
    'अगल',
    'अगला',
    'अगलापन',
    'अगली',
    'अगले',
    'अगवाँसी',
    'अगवाड़',
    'अगवाड़ा',
    'अगवान',
    'अगवानी',
    'अगवार',
    'अगवारा',
    'अगवारी',
    'अगवासी',
    'अगस्त',
    'अगस्त्य',
    'अगहन',
    'अगहनी',
    'अग़वा',
    'अगाऊ',
    'अगाऊपन',
    'अगाड़',
    'अगाड़ा',
    'अगाड़ी',
    'अगाड़ीपन',
    'अगाध',
    'अगाधता',
    'अगाधा',
    'अगाध्य',
    'अगामी',
    'अगाव',
    'अगास',
    'अगाह',
    'अगि',
    'अगिन',
    'अगिनत',
    'अगिनबान',
    'अगिया',
    'अगियाना',
    'अगियारी',
    'अगिर',
    'अगिरौका',
    'अगिहाना',
    'अगीठा',
    'अगीत',
    'अगुंठित',
    'अगुआ',
    'अगुआई',
    'अगुण',
    'अगुणज्ञ',
    'अगुणज्ञता',
    'अगुणता',
    'अगुणी',
    'अगुप्त',
    'अगुप्ततः',
    'अगुप्तता',
    'अगुफितता',
    'अगुरू',
    'अगुलियां',
    'अगुवा',
    'अगुवाई',
    'अगूढ़',
    'अगृह',
    'अगृहीत',
    'अगेंद्र',
    'अगेती',
    'अगेरिया',
    'अगेह',
    'अगेहा',
    'अगेहिनी',
    'अगोचर',
    'अगोचरता',
    'अगोट',
    'अगोटना',
    'अगोपन',
    'अगोपनीय',
    'अगोरना',
    'अगौड़ी',
    'अगौर',
    'अगौरव',
    'अगौरा',
    'अगौरी',
    'अग्नयस्त्र',
    'अग्नयुत्पात',
    'अग्नायी',
    'अग्नाशय',
    'अग्नाशयी',
    'अग्नाश्य',
    'अग्नि',
    'अग्निकंड',
    'अग्निकण',
    'अग्निकांड',
    'अग्निकुंड',
    'अग्निकुण्ड',
    'अग्निकुमार',
    'अग्निकेतु',
    'अग्निगर्भा',
    'अग्निचय',
    'अग्निज',
    'अग्निजन्मा',
    'अग्निजात',
    'अग्निजिह्व',
    'अग्निदूत',
    'अग्निदेव',
    'अग्निनाड़ी',
    'अग्निनिरोधी',
    'अग्निनेत्र',
    'अग्निपरिक्रमा',
    'अग्निपरीक्षा',
    'अग्निपुराण',
    'अग्निपूजक',
    'अग्निप्रणयन',
    'अग्निप्रतिष्ठा',
    'अग्निभू',
    'अग्निमंदिर',
    'अग्निमुख',
    'अग्नियुक्त',
    'अग्निरक्षक',
    'अग्निवधू',
    'अग्निशमन',
    'अग्निशर्मा',
    'अग्निशस्त्र',
    'अग्निशामक',
    'अग्निष्टोम',
    'अग्निष्ठ',
    'अग्निसह',
    'अग्निस्थापना',
    'अग्निहोत्र',
    'अग्निहोत्री',
    'अग्नीधर',
    'अग्नीय',
    'अग्नेयी',
    'अग्न्यस्त्र',
    'अग्न्याधान',
    'अग्न्याधानित',
    'अग्न्यास्त्र',
    'अग्यान',
    'अग्र',
    'अग्रंथ',
    'अग्रंथित',
    'अग्रंथितता',
    'अग्रकरण',
    'अग्रकूपः',
    'अग्रकृत',
    'अग्रकेश',
    'अग्रक्रय',
    'अग्रगंता',
    'अग्रगण्य',
    'अग्रगण्यता',
    'अग्रगत',
    'अग्रगन',
    'अग्रगमन',
    'अग्रगामिता',
    'अग्रगामी',
    'अग्रगायक',
    'अग्रचर',
    'अग्रचारी',
    'अग्रज',
    'अग्रजता',
    'अग्रजन्मता',
    'अग्रजन्मा',
    'अग्रजा',
    'अग्रजात',
    'अग्रजातता',
    'अग्रजाति',
    'अग्रणी',
    'अग्रतः',
    'अग्रतट',
    'अग्रतम',
    'अग्रता',
    'अग्रतिथि',
    'अग्रथित',
    'अग्रदर्शी',
    'अग्रदल',
    'अग्रदीप',
    'अग्रदीर्घा',
    'अग्रदूत',
    'अग्रदृश्य',
    'अग्रपंक्ति',
    'अग्रपूज्य',
    'अग्रप्रेषण',
    'अग्रप्रेषित',
    'अग्रबाहु',
    'अग्रबीज',
    'अग्रभाग',
    'अग्रभू',
    'अग्रभूम',
    'अग्रभूमि',
    'अग्रभूमीय',
    'अग्रमंच',
    'अग्रमांस',
    'अग्रयान',
    'अग्रयोद्घा',
    'अग्रलेख',
    'अग्रवर्तिता',
    'अग्रवर्ती',
    'अग्रवाल',
    'अग्रशाला',
    'अग्रसर',
    'अग्रसरण',
    'अग्रसारक',
    'अग्रसारण',
    'अग्रसारित',
    'अग्रसारी',
    'अग्रस्थ',
    'अग्रस्थता',
    'अग्रस्थान',
    'अग्रहण',
    'अग्रहरण',
    'अग्रहस्त',
    'अग्रहायण',
    'अग्रहार',
    'अग्रहारता',
    'अग्रहीत',
    'अग्रांश',
    'अग्रांशु',
    'अग्राधिकार',
    'अग्राधिकारी',
    'अग्रानीक',
    'अग्राशन',
    'अग्रासन',
    'अग्राहार',
    'अग्राहिता',
    'अग्राह्य',
    'अग्राह्यता',
    'अग्रि',
    'अग्रिम',
    'अग्रिमता',
    'अग्रिमों',
    'अग्रिय',
    'अग्रेनीत',
    'अग्रेषण',
    'अग्रेषित',
    'अग्रेसरिक',
    'अग्ल',
    'अग्लानि',
    'अघ',
    'अघघ्न',
    'अघट',
    'अघटन',
    'अघटना',
    'अघटनीय',
    'अघटित',
    'अघटितता',
    'अघन',
    'अघनिष्ठ',
    'अघनिष्ठता',
    'अघमर्षण',
    'अघर्म',
    'अघल',
    'अघविष',
    'अघशंस',
    'अघाए',
    'अघात',
    'अघाती',
    'अघाना',
    'अघाया',
    'अघारि',
    'अघिक्षिप्त',
    'अघिया',
    'अघिष्ठापन',
    'अघी',
    'अघीर',
    'अघोर',
    'अघोरी',
    'अघोष',
    'अघोषित',
    'अघ्न्य',
    'अघ्न्या',
    'अघ्यक्ष',
    'अघ्र्य',
    'अचंचल',
    'अचंचलता',
    'अचंडता',
    'अचंद्रा',
    'अचंभा',
    'अचंभित',
    'अचक',
    'अचकचाना',
    'अचकचाया',
    'अचकचाहट',
    'अचकन',
    'अचकां',
    'अचक्के',
    'अचक्र',
    'अचक्रित',
    'अचक्रिलता',
    'अचचंलता',
    'अचचिर्त',
    'अचतुर',
    'अचपल',
    'अचपली',
    'अचर',
    'अचरज',
    'अचरम',
    'अचरित',
    'अचरितार्थ',
    'अचरितार्थता',
    'अचल',
    'अचलता',
    'अचलन',
    'अचला',
    'अचलाधिप',
    'अचलायमान',
    'अचलायमानता',
    'अचलेश्वर',
    'अचाक्षुष',
    'अचाक्षुषता',
    'अचातुर्य',
    'अचान',
    'अचानक',
    'अचानकचक',
    'अचार',
    'अचारज',
    'अचारदान',
    'अचारदानी',
    'अचारू',
    'अचाहा',
    'अचिंतक',
    'अचिंतन',
    'अचिंतित',
    'अचिंतितता',
    'अचिंत्य',
    'अचिकित्स्य',
    'अचिति',
    'अचित्',
    'अचित्त',
    'अचिर',
    'अचिरंतनता',
    'अचिरता',
    'अचिरप्रभा',
    'अचिरांशु',
    'अचिरेण',
    'अचिर्',
    'अचिर्त',
    'अचिर्ष्मान',
    'अचीता',
    'अचुतर',
    'अचुम्बकीय',
    'अचूक',
    'अचेत',
    'अचेतता',
    'अचेतन',
    'अचेतनतः',
    'अचेतनता',
    'अचेतना',
    'अचेतनीकरण',
    'अचेतनीकृत',
    'अचेता',
    'अचेष्ट',
    'अचेष्टता',
    'अचेष्टा',
    'अचैतन्य',
    'अचैना',
    'अच्छ',
    'अच्छा',
    'अच्छाई',
    'अच्छापन',
    'अच्छिन्नपर्ण',
    'अच्छी',
    'अच्छे',
    'अच्छोदा',
    'अच्युत',
    'अच्युतागंज',
    'अच्युताग्रज',
    'अच्युतावास',
    'अच्र्य',
    'अछंदबद्घ',
    'अछक',
    'अछद्म',
    'अछन',
    'अछवाई',
    'अछवाना',
    'अछिद्र',
    'अछिपाय',
    'अछूत',
    'अछूतता',
    'अछूता',
    'अछूतापन',
    'अछेद',
    'अछेद्यता',
    'अछेय',
    'अछोर',
    'अछोह',
    'अछोही',
    'अज',
    'अजंगम',
    'अजंगमता',
    'अजंघ',
    'अजंभ',
    'अजः',
    'अजकव',
    'अजकाव',
    'अजगर',
    'अजगरी',
    'अजगाव',
    'अजगुत',
    'अजज़ा',
    'अजटिलता',
    'अजड़ता',
    'अजन',
    'अजनक',
    'अजनन',
    'अजननत्व',
    'अजननिका',
    'अजननी',
    'अजनबी',
    'अजनबीपन',
    'अजनाकीर्ण',
    'अजनाकीर्णता',
    'अजन्म',
    'अजन्मगत',
    'अजन्मगतता',
    'अजन्मजात',
    'अजन्मजातता',
    'अजन्मा',
    'अजन्य',
    'अजपति',
    'अजपा',
    'अजपाल',
    'अजब',
    'अजमुख',
    'अजमेर',
    'अजमोद',
    'अजमोदा',
    'अजमोदिका',
    'अजय',
    'अजया',
    'अजर',
    'अजरत',
    'अजरता',
    'अजरा',
    'अजर्जर',
    'अजर्य',
    'अजवाइन',
    'अजवायन',
    'अजवीथी',
    'अजहिल्लंग',
    'अज़गैब',
    'अज़गैबी',
    'अज़दहा',
    'अज़मत',
    'अज़र्',
    'अज़र्दाश्त',
    'अज़ल',
    'अज़हद',
    'अज़ा',
    'अज़ाज़ील',
    'अज़ादार',
    'अज़ान',
    'अज़ानी',
    'अज़ाब',
    'अज़ाबुल्कब्र',
    'अज़ीज',
    'अज़ीज़',
    'अज़ीज़दार',
    'अज़ीज़दारी',
    'अज़ीज़ी',
    'अज़ीम',
    'अज़ीमुश्शान',
    'अज़ीर्',
    'अज़ीर्नामा',
    'अजा',
    'अजागरुक',
    'अजाग्रत',
    'अजाजीव',
    'अजात',
    'अजातशत्रु',
    'अजातश्मश्रू',
    'अजातारि',
    'अजाति',
    'अजातिगत',
    'अजातिगतता',
    'अजान',
    'अजाना',
    'अजानि',
    'अजाने',
    'अजाप्रिया',
    'अजाभक्ष',
    'अजामुख',
    'अजामेध',
    'अजार',
    'अजारज',
    'अजारजता',
    'अजिज्ञासु',
    'अजित',
    'अजितता',
    'अजितेंद्रिय',
    'अजिन',
    'अजिनपत्री',
    'अजिनयोनि',
    'अजिया',
    'अजियारना',
    'अजिर',
    'अजिर्त',
    'अजिल्द',
    'अजिह्म',
    'अजिह्मग',
    'अजीटन',
    'अजीत',
    'अजीब',
    'अजीरन',
    'अजीर्ण',
    'अजीर्णहर',
    'अजीर्णित',
    'अजीव',
    'अजीवंत',
    'अजीवंतता',
    'अजीवद्रव्य',
    'अजीवित',
    'अजूबा',
    'अजूरा',
    'अजेतव्य',
    'अजेय',
    'अजेयता',
    'अजैंट',
    'अजैकपद',
    'अजैब',
    'अजैव',
    'अजोगा',
    'अज्ज',
    'अज्ञ',
    'अज्ञता',
    'अज्ञात',
    'अज्ञातकुल',
    'अज्ञातगोत्र',
    'अज्ञातचारी',
    'अज्ञातता',
    'अज्ञातनाम',
    'अज्ञातपूर्वता',
    'अज्ञातपूर्वर्',
    'अज्ञातवंश',
    'अज्ञातवास',
    'अज्ञातवासी',
    'अज्ञान',
    'अज्ञानतः',
    'अज्ञानता',
    'अज्ञानवश',
    'अज्ञानी',
    'अज्ञेय',
    'अज्ञेयतः',
    'अज्ञेयता',
    'अज्ञेयवाद',
    'अझोरी',
    'अट',
    'अटक',
    'अटकन',
    'अटकना',
    'अटकनी',
    'अटकल',
    'अटकलना',
    'अटकलबाज',
    'अटकलबाज़ी',
    'अटकलबाजी',
    'अटकलबाज़',
    'अटकलबाज़ी',
    'अटका',
    'अटकाना',
    'अटकाने',
    'अटकापन',
    'अटकाव',
    'अटन',
    'अटना',
    'अटनी',
    'अटपटा',
    'अटरंडम',
    'अटरम',
    'अटरिया',
    'अटर्नी',
    'अटल',
    'अटलता',
    'अटलनीय',
    'अटलनीयता',
    'अटलांटिक',
    'अटवारी',
    'अटवि',
    'अटविक',
    'अटहर',
    'अटा',
    'अटाट',
    'अटाटूट',
    'अटाना',
    'अटारी',
    'अटाला',
    'अटिकाऊ',
    'अटिकाऊपन',
    'अटूट',
    'अटूटता',
    'अटॅहासी',
    'अटेरन',
    'अटेरना',
    'अटेरा',
    'अटैंशन',
    'अटैच',
    'अटैची',
    'अटैशी',
    'अटैस्टिड',
    'अट्ट',
    'अट्टम',
    'अट्टसट्ट',
    'अट्टहास',
    'अट्टहासी',
    'अट्टा',
    'अट्टाल',
    'अट्टालिका',
    'अट्टी',
    'अट्ठा',
    'अट्ठारह',
    'अट्ठी',
    'अठ',
    'अठई',
    'अठकड़ा',
    'अठकौसल',
    'अठखंडा',
    'अठखेलपन',
    'अठखेलिया',
    'अठखेलियाँ',
    'अठखेली',
    'अठखेलीपूर्ण',
    'अठताल',
    'अठन्नी',
    'अठपहला',
    'अठपहली',
    'अठपहलू',
    'अठपेजी',
    'अठभुज',
    'अठभुजा',
    'अठमासा',
    'अठवाड़ा',
    'अठवाडे',
    'अठवारा',
    'अठहत्तर',
    'अठान',
    'अठाना',
    'अठारह',
    'अठारहपेजी',
    'अठारहवां',
    'अठोतर',
    'अठोतरी',
    'अठोस',
    'अडंगा',
    'अडंगेबाज़',
    'अडंगेबाजी',
    'अडबंगा',
    'अडल',
    'अड़',
    'अड़ंत',
    'अड़का',
    'अड़चन',
    'अड़चनें',
    'अड़डंडा',
    'अड़ड़पोपो',
    'अड़तल',
    'अड़दब',
    'अड़दार',
    'अड़ना',
    'अड़बंग',
    'अड़बंगा',
    'अड़वाल',
    'अड़हुल',
    'अड़ा',
    'अड़ाअड़ी',
    'अड़ाना',
    'अड़ापन',
    'अड़ार',
    'अड़ियल',
    'अड़ियलपन',
    'अड़ी',
    'अड़ोस',
    'अडाकार',
    'अडाना',
    'अडिग',
    'अडिगता',
    'अड्डा',
    'अड्डे',
    'अड्डेबाज',
    'अड्डेबाजी',
    'अढ़ाई',
    'अढै',
    'अणि',
    'अणिमा',
    'अणीय',
    'अणु',
    'अणुक',
    'अणुकथा',
    'अणुद्रुत',
    'अणुनाभिक',
    'अणुबम',
    'अणुभा',
    'अणुमात्र',
    'अणुमात्रिक',
    'अणुयुग',
    'अणुल',
    'अणुवत्त',
    'अणुवीक्षण',
    'अणुव्रीहि',
    'अणोर्द',
    'अण्टा',
    'अण्ड',
    'अण्डक',
    'अण्डकगोल',
    'अण्डकाश्म',
    'अण्डकोश',
    'अण्डज',
    'अण्डजनन',
    'अण्डधानी',
    'अण्डनिक्षेपक',
    'अण्डनिक्षेपण',
    'अण्डपुटिका',
    'अण्डप्रजनक',
    'अण्डरूप',
    'अण्डवक्र',
    'अण्डवाहिनी',
    'अण्डा',
    'अण्डाकार',
    'अण्डाणु',
    'अण्डाभ',
    'अण्डाशय',
    'अण्डाशयशोध',
    'अण्डाशयी',
    'अण्डे',
    'अण्डों',
    'अण्डोत्सर्ग',
    'अतंत्र',
    'अतंद्र',
    'अतंद्रा',
    'अतः',
    'अतःपरम्',
    'अतकनीकता',
    'अतकनीकी',
    'अतक्र्य',
    'अतट',
    'अतटस्थ',
    'अतटस्थता',
    'अतत्पर',
    'अतत्परता',
    'अतत्परतापूर्वक',
    'अतप',
    'अतप्त',
    'अतमि',
    'अतर',
    'अतरलता',
    'अतरसों',
    'अतरौटा',
    'अतर्कबुद्धिवाद',
    'अतर्कित',
    'अतर्पण',
    'अतल',
    'अतलांतिक',
    'अतल्लीन',
    'अतल्लीनता',
    'अतसी',
    'अता',
    'अताई',
    'अतात्विक',
    'अताप',
    'अतापता',
    'अतापी',
    'अताबख़्श',
    'अताशे',
    'अति',
    'अतिउग्रता',
    'अतिउत्तेजनशील',
    'अतिकर',
    'अतिकर्ष',
    'अतिकर्षी',
    'अतिकल्पना',
    'अतिकष्ट',
    'अतिकाय',
    'अतिकायता',
    'अतिकारी',
    'अतिकालिक',
    'अतिकालीन',
    'अतिकुशल',
    'अतिकृष्णता',
    'अतिक्रम',
    'अतिक्रमण',
    'अतिक्रमणकारी',
    'अतिक्रमित',
    'अतिक्रमी',
    'अतिक्रांत',
    'अतिक्रामक',
    'अतिक्रामी',
    'अतिक्रियता',
    'अतिक्रूरता',
    'अतिक्लान्त',
    'अतिक्षुब्ध',
    'अतिक्षेप',
    'अतिक्षेपण',
    'अतिगत',
    'अतिगमन',
    'अतिगव',
    'अतिगामी',
    'अतिगुप्त',
    'अतिगूढ',
    'अतिग्रही',
    'अतिग्राह',
    'अतिचंडा',
    'अतिचतुर',
    'अतिचार',
    'अतिचारी',
    'अतिचिंतित',
    'अतिचित्र',
    'अतिचिन्ता',
    'अतितरण',
    'अतितारी',
    'अतितिक्षा',
    'अतितिक्षु',
    'अतितृप्त',
    'अतितृष्णा',
    'अतिथ',
    'अतिथि',
    'अतिथिगृह',
    'अतिथिदेव',
    'अतिदंतुर',
    'अतिदिव्य',
    'अतिदीर्घजीवी',
    'अतिदुष्ट',
    'अतिदुष्टता',
    'अतिदूर',
    'अतिदूरता',
    'अतिदेय',
    'अतिदेव',
    'अतिदेश',
    'अतिधनी',
    'अतिधर्मपरायणता',
    'अतिधारण',
    'अतिनाटक',
    'अतिनाटकीय',
    'अतिनाटकीयता',
    'अतिनिद्र',
    'अतिनिर्गम',
    'अतिनिर्धारण',
    'अतिपतन',
    'अतिपद',
    'अतिपांडुकंबला',
    'अतिपात',
    'अतिपूंजीकरण',
    'अतिप्रकृत',
    'अतिप्रचुर',
    'अतिप्रपद',
    'अतिप्रभ',
    'अतिप्रभावी',
    'अतिप्रमाणन',
    'अतिप्रशंसा',
    'अतिप्रसिद्धोपदेश',
    'अतिप्रहसन',
    'अतिप्रिय',
    'अतिबल',
    'अतिबहुल',
    'अतिब्याजी',
    'अतिभार',
    'अतिभारग्रस्त',
    'अतिभारग्रस्तता',
    'अतिभारित',
    'अतिभावुक',
    'अतिभावुकता',
    'अतिभाषी',
    'अतिभीत',
    'अतिभोगिता',
    'अतिभोजी',
    'अतिभौतिक',
    'अतिमंडित',
    'अतिमांस',
    'अतिमात्र',
    'अतिमात्रा',
    'अतिमान',
    'अतिमानव',
    'अतिमानुष',
    'अतिमाय',
    'अतिमूर्छा',
    'अतिमूल्यांकन',
    'अतिरंग',
    'अतिरंजन',
    'अतिरंजना',
    'अतिरंजनाकार',
    'अतिरंजित',
    'अतिरक्ति',
    'अतिरक्तिकंबला',
    'अतिरथी',
    'अतिरन्जित',
    'अतिरात्र',
    'अतिराष्ट्रीयता',
    'अतिरिक्त',
    'अतिरिक्ततः',
    'अतिरिक्तता',
    'अतिरुप',
    'अतिरेक',
    'अतिरेकी',
    'अतिलंघन',
    'अतिलघु',
    'अतिवक्ता',
    'अतिवक्रता',
    'अतिवाद',
    'अतिवादी',
    'अतिविरूपता',
    'अतिविवरण',
    'अतिविशाल',
    'अतिविश्वास',
    'अतिवृद्वि',
    'अतिवृष्टि',
    'अतिव्यग्र',
    'अतिव्यापक',
    'अतिव्यापन',
    'अतिव्यापी',
    'अतिव्याप्ति',
    'अतिशय',
    'अतिशयी',
    'अतिशयोक्ति',
    'अतिशयोक्तिपूर्ण',
    'अतिशीघ्र',
    'अतिशेष',
    'अतिशोध्य',
    'अतिश्रम',
    'अतिश्रान्त',
    'अतिसंकुचन',
    'अतिसंकुलित',
    'अतिसंध',
    'अतिसंभरण',
    'अतिसंभोग',
    'अतिसंयम',
    'अतिसंवेगी',
    'अतिसंवेदनशील',
    'अतिसंवेदनशीलता',
    'अतिसम्मोहक',
    'अतिसरलता',
    'अतिसार',
    'अतिसावधान',
    'अतिस्वप्न',
    'अतिहासी',
    'अतींद्र',
    'अतीक्ष्ण',
    'अतीक्ष्णता',
    'अतीत',
    'अतीतता',
    'अतीतवाद',
    'अतीतवादी',
    'अतीतावलोकन',
    'अतीद्रिंय',
    'अतीन्द्रिय',
    'अतीब',
    'अतीव्र',
    'अतीव्रता',
    'अतुंग',
    'अतुंद',
    'अतुकांत',
    'अतुदिनी',
    'अतुल',
    'अतुलता',
    'अतुलना',
    'अतुलनात्मकता',
    'अतुलनीय',
    'अतुलनीयता',
    'अतुलित',
    'अतुलितता',
    'अतुल्य',
    'अतुष्टि',
    'अतृंप्ततः',
    'अतृप्त',
    'अतृप्ति',
    'अतृप्य',
    'अतृष',
    'अतृषा',
    'अतृषित',
    'अतृष्टि',
    'अतृष्ण',
    'अतृष्णा',
    'अतेज',
    'अतेजस्वी',
    'अतेजिस्वता',
    'अतोषित',
    'अत्तक',
    'अत्तर',
    'अत्ता',
    'अत्ताबिया',
    'अत्ताबी',
    'अत्तिका',
    'अत्यंत',
    'अत्यंततः',
    'अत्यंतता',
    'अत्यंतीन',
    'अत्यधिक',
    'अत्यधिकता',
    'अत्यनुभाविक',
    'अत्यन्त',
    'अत्यभिदत्त',
    'अत्यभिदान',
    'अत्यभिनय',
    'अत्यय',
    'अत्यलंकृत',
    'अत्यलंकृतता',
    'अत्यल्प',
    'अत्यल्पतः',
    'अत्यल्पता',
    'अत्यल्पताः',
    'अत्याग',
    'अत्यागी',
    'अत्याग्रह',
    'अत्याचार',
    'अत्याचारपूर्ण',
    'अत्याचारित',
    'अत्याचारिता',
    'अत्याचारी',
    'अत्याधिकता',
    'अत्याधुनिक',
    'अत्यालोची',
    'अत्यावश्यक',
    'अत्यावश्यकता',
    'अत्यासक्ति',
    'अत्याहार',
    'अत्याहारी',
    'अत्युक्ति',
    'अत्युग्र',
    'अत्युच्च',
    'अत्युच्चता',
    'अत्युत्तम',
    'अत्युत्पादन',
    'अत्युत्सुक',
    'अत्युपलब्धि',
    'अत्युल्लास',
    'अत्युष्ण',
    'अत्रदान',
    'अत्रि',
    'अत्रिगुण',
    'अत्रिज',
    'अत्रिजात',
    'अत्वरा',
    'अत्वरित',
    'अत्वरितता',
    'अथ',
    'अथकान',
    'अथच',
    'अथरा',
    'अथरी',
    'अथर्थवेद',
    'अथर्व',
    'अथर्वण',
    'अथर्ववेद',
    'अथर्ववेदः',
    'अथर्वा',
    'अथवा',
    'अथशेष',
    'अथाह',
    'अथाहता',
    'अथोर्',
    'अथोर्पक्षेपक',
    'अद',
    'अदंड',
    'अदंत',
    'अदंतुरित',
    'अदंत्य',
    'अदंभ',
    'अदंभी',
    'अदक्ष',
    'अदक्षता',
    'अदक्षिण',
    'अदत्त',
    'अदत्तमत',
    'अदद',
    'अदन',
    'अदनवाटिका',
    'अदना',
    'अदब',
    'अदबकार',
    'अदबदा',
    'अदबी',
    'अदभुत',
    'अदम',
    'अदमित',
    'अदम्य',
    'अदम्यता',
    'अदया',
    'अदयालु',
    'अदरक',
    'अदर्प',
    'अदर्पी',
    'अदर्शन',
    'अदर्शनीय',
    'अदर्शनीयता',
    'अदर्शित',
    'अदल',
    'अदला',
    'अदलीय',
    'अदवाइन',
    'अदवान',
    'अदवायन',
    'अदशन',
    'अदा',
    'अदांत',
    'अदाएँ',
    'अदाकर्त्ता',
    'अदाकार',
    'अदाकारा',
    'अदाकारी',
    'अदातव्य',
    'अदान',
    'अदानी',
    'अदानीत्व',
    'अदाबंदी',
    'अदाबाज़',
    'अदाय',
    'अदायगियां',
    'अदायगियों',
    'अदायगी',
    'अदायागत',
    'अदायागतता',
    'अदायाद',
    'अदायिक',
    'अदायित्व',
    'अदार',
    'अदालत',
    'अदालती',
    'अदावत',
    'अदावतपेशा',
    'अदावती',
    'अदावी',
    'अदिति',
    'अदितिज',
    'अदिन',
    'अदिव्य',
    'अदिष्ट',
    'अदीक्षित',
    'अदीन',
    'अदीनात्मा',
    'अदीप्त',
    'अदीप्ति',
    'अदीब',
    'अदीम',
    'अदूर',
    'अदूरता',
    'अदूरदर्शी',
    'अदृढ',
    'अदृढ़',
    'अदृढ़ता',
    'अदृढ़मत',
    'अदृढ़ावलंबन',
    'अदृश्य',
    'अदृश्यतः',
    'अदृश्यता',
    'अदृश्यभूत',
    'अदृश्यमान',
    'अदृष्ट',
    'अदृष्टपूर्व',
    'अदृष्टपूर्वता',
    'अदृष्टिगोचर',
    'अदृष्टिगोचरता',
    'अदृष्य',
    'अदेखा',
    'अदेय',
    'अदेयता',
    'अदेव',
    'अदेवता',
    'अदेवमातृक',
    'अदेह',
    'अदैव',
    'अदैहिक',
    'अदोलन',
    'अदोलित',
    'अद्घम',
    'अद्घा',
    'अद्न',
    'अद्भुत',
    'अद्भुतता',
    'अद्भुतालय',
    'अद्य',
    'अद्यतन',
    'अद्यतनता',
    'अद्यतनीय',
    'अद्यापि',
    'अद्यावधि',
    'अद्यावधिक',
    'अद्यैव',
    'अद्रव',
    'अद्रि',
    'अद्रिज',
    'अद्रिजा',
    'अद्रिसार',
    'अद्रीश',
    'अद्रोघ',
    'अद्ल',
    'अद्वय',
    'अद्वषपूर्ण',
    'अद्विग्न',
    'अद्विज',
    'अद्वितीय',
    'अद्वितीयता',
    'अद्वेष',
    'अद्वेषी',
    'अद्वैत',
    'अद्वैतता',
    'अद्वैतवाद',
    'अद्वैतिक',
    'अद्वैध',
    'अध',
    'अधः',
    'अधकचरा',
    'अधकचरापन',
    'अधकच्छा',
    'अधकपाली',
    'अधखिला',
    'अधखुला',
    'अधचना',
    'अधजला',
    'अधनंगा',
    'अधनाढ',
    'अधन्ना',
    'अधन्य',
    'अधन्यवाद',
    'अधपका',
    'अधपकी',
    'अधपढ़ा',
    'अधपन्ना',
    'अधबटाई',
    'अधबना',
    'अधबर',
    'अधबीच',
    'अधभरा',
    'अधम',
    'अधमता',
    'अधमतापूर्वक',
    'अधमरा',
    'अधमांग',
    'अधमाधम',
    'अधमुँदी',
    'अधमुआ',
    'अधर',
    'अधरज',
    'अधरपान',
    'अधरांग',
    'अधरात',
    'अधराधर',
    'अधरास्ते',
    'अधरिया',
    'अधरुव',
    'अधरुवता',
    'अधरोत्तर',
    'अधरोष्ठ',
    'अधर्',
    'अधर्म',
    'अधर्मज',
    'अधर्मजता',
    'अधर्मशत्रु',
    'अधर्माधिकारी',
    'अधर्मी',
    'अधवल',
    'अधश्वर',
    'अधसना',
    'अधस्तल',
    'अधस्तवक',
    'अधान्य',
    'अधामिर्क',
    'अधामिर्कता',
    'अधार',
    'अधारिका',
    'अधारी',
    'अधालय',
    'अधि',
    'अधिक',
    'अधिकंठ',
    'अधिकतः',
    'अधिकतम',
    'अधिकतमकरण',
    'अधिकतर',
    'अधिकतरता',
    'अधिकता',
    'अधिकताई',
    'अधिकमान',
    'अधिकर',
    'अधिकरण',
    'अधिकर्ता',
    'अधिकर्म',
    'अधिकर्मा',
    'अधिकर्मी',
    'अधिकांश',
    'अधिकांशतः',
    'अधिकांशीय',
    'अधिकाई',
    'अधिकाधिक',
    'अधिकार',
    'अधिकारकर्ता',
    'अधिकारतः',
    'अधिकारपूर्ण',
    'अधिकारपूर्णता',
    'अधिकारपूर्वक',
    'अधिकारप्राप्त',
    'अधिकारयुक्त',
    'अधिकारवाद',
    'अधिकारवादी',
    'अधिकारहरण',
    'अधिकारहीन',
    'अधिकारहीनतः',
    'अधिकारहीनता',
    'अधिकाराधिकतः',
    'अधिकाराभिषेक',
    'अधिकारिक',
    'अधिकारिता',
    'अधिकारियों',
    'अधिकारी',
    'अधिकारीगण',
    'अधिकारीतंत्र',
    'अधिकारीय',
    'अधिकारेतरतः',
    'अधिकारों',
    'अधिकाल',
    'अधिकाशंतः',
    'अधिकीलन',
    'अधिकुंचन',
    'अधिकृत',
    'अधिकृततः',
    'अधिकृतता',
    'अधिकेन्द्र',
    'अधिकोश',
    'अधिकोष',
    'अधिकोषण',
    'अधिक्य',
    'अधिक्रमण',
    'अधिक्रमिक',
    'अधिक्रमित',
    'अधिक्रय',
    'अधिक्रीत',
    'अधिक्षमता',
    'अधिक्षेत्र',
    'अधिक्षेपण',
    'अधिगंतव्य',
    'अधिगणन',
    'अधिगत',
    'अधिगति',
    'अधिगम',
    'अधिगम्य',
    'अधिगल',
    'अधिगामी',
    'अधिगुण',
    'अधिगृहीत',
    'अधिगोपन',
    'अधिग्रह',
    'अधिग्रहण',
    'अधिग्रहणशील',
    'अधिग्रहणशीलता',
    'अधिग्रहीत',
    'अधिग्राही',
    'अधिचर्म',
    'अधिचालित',
    'अधिचिकित्स्य',
    'अधिचित्र',
    'अधिचिह्न',
    'अधिजठर',
    'अधिज्ञात',
    'अधिज्ञान',
    'अधिटंकण',
    'अधित्यका',
    'अधित्याग',
    'अधिदत्त',
    'अधिदश्रन',
    'अधिदान',
    'अधिदिष्ट',
    'अधिदेय',
    'अधिदेव',
    'अधिदेश',
    'अधिदेशात्मक',
    'अधिदेशाधीन',
    'अधिदेशी',
    'अधिदैव',
    'अधिधायक',
    'अधिधारण',
    'अधिधारित',
    'अधिनव',
    'अधिनाथ',
    'अधिनाप',
    'अधिनायक',
    'अधिनायकता',
    'अधिनायकत्व',
    'अधिनियतन',
    'अधिनियम',
    'अधिनियमित',
    'अधिनियोजन',
    'अधिनिर्गमन',
    'अधिनिर्णय',
    'अधिनिर्णयन',
    'अधिनिर्णायक',
    'अधिनिर्धारण',
    'अधिनिवेश',
    'अधिनैतिक',
    'अधिन्यासक',
    'अधिप',
    'अधिपति',
    'अधिपतीय',
    'अधिपत्य',
    'अधिपत्र',
    'अधिपारखी',
    'अधिपूंजीयन',
    'अधिप्रतिनिधित्व',
    'अधिप्रभार',
    'अधिप्रमाणन',
    'अधिप्रमाणिकता',
    'अधिप्रमाणित',
    'अधिप्राणी',
    'अधिप्राप्ति',
    'अधिबचत',
    'अधिभार',
    'अधिभारित',
    'अधिभावी',
    'अधिभाषा',
    'अधिभूत',
    'अधिभोक्ता',
    'अधिभोग',
    'अधिभोगपत्र',
    'अधिभोगी',
    'अधिभोगेतर',
    'अधिभौतिक',
    'अधिमत',
    'अधिमनोविज्ञान',
    'अधिमांग',
    'अधिमात्र',
    'अधिमान',
    'अधिमानव',
    'अधिमानवीय',
    'अधिमानित',
    'अधिमानी',
    'अधिमानों',
    'अधिमान्य',
    'अधिमाप',
    'अधिमिलन',
    'अधिमिश्रण',
    'अधिमुद्रण',
    'अधिमूल्य',
    'अधिमूल्यन',
    'अधिमूल्यांकन',
    'अधिमूल्यित',
    'अधियज्ञ',
    'अधियारिन',
    'अधियारी',
    'अधिरंतन',
    'अधिरक्तस्राव',
    'अधिरचना',
    'अधिरथ',
    'अधिरथः',
    'अधिराज',
    'अधिराज्य',
    'अधिराष्ट्र',
    'अधिरुढ़',
    'अधिरोपित',
    'अधिरोह',
    'अधिरोहण',
    'अधिलंघक',
    'अधिलंघन',
    'अधिलंघित',
    'अधिलाभ',
    'अधिलाभांश',
    'अधिलेखन',
    'अधिलोक',
    'अधिवक्ता',
    'अधिवचन',
    'अधिवयस्क',
    'अधिवर्ण',
    'अधिवर्ष',
    'अधिवर्षिता',
    'अधिवाचन',
    'अधिवानर',
    'अधिवास',
    'अधिवासन',
    'अधिवासित',
    'अधिवासिता',
    'अधिवासी',
    'अधिविकर्ष',
    'अधिविक्रीत',
    'अधिविज्ञान',
    'अधिवीक्षण',
    'अधिवृद्धि',
    'अधिवेतन',
    'अधिवेत्ता',
    'अधिवेद',
    'अधिवेशन',
    'अधिव्यय',
    'अधिव्यापन',
    'अधिशासी',
    'अधिशीतलित',
    'अधिशेष',
    'अधिश्रयण',
    'अधिश्रित',
    'अधिश्लिष्ट',
    'अधिश्वेत',
    'अधिष्ठाता',
    'अधिष्ठान',
    'अधिष्ठापक',
    'अधिष्ठापना',
    'अधिष्ठापित',
    'अधिष्ठित',
    'अधिष्पंद',
    'अधिसंकुचन',
    'अधिसंख्य',
    'अधिसंचयन',
    'अधिसिद्धान्त',
    'अधिसूचना',
    'अधिसूचनाएं',
    'अधिसूचित',
    'अधिसेविका',
    'अधिस्त्राव',
    'अधिस्थगित',
    'अधिस्थिति',
    'अधिस्फोट',
    'अधिस्फोटित',
    'अधिस्वरक',
    'अधिस्वामित्ववाली',
    'अधिस्वामी',
    'अधिहरण',
    'अधिहृत',
    'अधीक्षक',
    'अधीक्षण',
    'अधीक्षिका',
    'अधीत',
    'अधीता',
    'अधीन',
    'अधीनता',
    'अधीनताः',
    'अधीनस्थ',
    'अधीनस्थता',
    'अधीर',
    'अधीरता',
    'अधीरा',
    'अधीश',
    'अधीश्वर',
    'अधुना',
    'अधुनातन',
    'अधुनातम',
    'अधूम्र',
    'अधूरा',
    'अधूरापन',
    'अधूरे',
    'अधूर्त',
    'अधृत',
    'अधृति',
    'अधृष्ट',
    'अधृष्टतः',
    'अधृष्टता',
    'अधेंदु',
    'अधेंर्दुभूषण',
    'अधेड़',
    'अधेड़ता',
    'अधेय',
    'अधेला',
    'अधेली',
    'अधेसिव',
    'अधैर्य',
    'अधो',
    'अधोकर्षक',
    'अधोकर्षण',
    'अधोकर्षित',
    'अधोकाष्ठ',
    'अधोकाष्ठा',
    'अधोकृष्ट',
    'अधोक्षिप्त',
    'अधोक्षेक्षेपण',
    'अधोगत',
    'अधोगति',
    'अधोगमन',
    'अधोगामी',
    'अधोचक्रण',
    'अधोचेतना',
    'अधोतल',
    'अधोतलीय',
    'अधोनयन',
    'अधोनिलय',
    'अधोनीत',
    'अधोन्मुख',
    'अधोपतन',
    'अधोपतनकारी',
    'अधोपतित',
    'अधोपरिधान',
    'अधोपातन',
    'अधोपारपथ',
    'अधोप्रवाह',
    'अधोबिंदु',
    'अधोभाग',
    'अधोभूमि',
    'अधोभौम',
    'अधोमार्ग',
    'अधोमुख',
    'अधोमुखतः',
    'अधोमुखता',
    'अधोमुखी',
    'अधोमुख्यतः',
    'अधोरथ',
    'अधोरेखन',
    'अधोरेखा',
    'अधोरेखांकित',
    'अधोर्षण',
    'अधोलंब',
    'अधोलिखित',
    'अधोलिखितता',
    'अधोलोक',
    'अधोवर्ती',
    'अधोवस्त्र',
    'अधोवस्र',
    'अधोवहन',
    'अधोवायु',
    'अधोवाहक',
    'अधोशील',
    'अधोस्थापन',
    'अधोस्थापित',
    'अधोस्वस्तिक',
    'अधोहस्ताक्षरकर्ता',
    'अधोहस्ताक्षरी',
    'अध्तात्म',
    'अध्तीत',
    'अध्मता',
    'अध्यक्ष',
    'अध्यक्षता',
    'अध्यक्षपद',
    'अध्यक्षर',
    'अध्यक्षा',
    'अध्यक्षीय',
    'अध्ययन',
    'अध्ययनकर्ता',
    'अध्ययनपूर्ण',
    'अध्ययनशील',
    'अध्ययनशीलता',
    'अध्ययनाधीन',
    'अध्ययनार्थ',
    'अध्ययेतावृत्ति',
    'अध्यर्धशत',
    'अध्यर्पण',
    'अध्यवकलन',
    'अध्यवसाय',
    'अध्यवसायी',
    'अध्यात',
    'अध्यातव्य',
    'अध्यात्म',
    'अध्यात्मज्ञ',
    'अध्यात्मवाद',
    'अध्यादेश',
    'अध्यापक',
    'अध्यापकत्व',
    'अध्यापन',
    'अध्यापनीय',
    'अध्यापिका',
    'अध्याय',
    'अध्यारोही',
    'अध्यावरक',
    'अध्यावरण',
    'अध्यावरणी',
    'अध्यावास',
    'अध्यावासी',
    'अध्यास',
    'अध्यासित',
    'अध्याहरण',
    'अध्याहर्ता',
    'अध्याहार',
    'अध्युष्ट्र',
    'अध्यूढ़',
    'अध्येता',
    'अध्येतावृत्ति',
    'अध्येय',
    'अध्वग',
    'अध्वगा',
    'अध्वनि',
    'अध्वनित',
    'अध्वपति',
    'अध्वा',
    'अध्वांत',
    'अध्वाति',
    'अन',
    'अनंकारवाद',
    'अनंग',
    'अनंगका',
    'अनंगवती',
    'अनंगा',
    'अनंगारि',
    'अनंगी',
    'अनंजन',
    'अनंडाकर्षित',
    'अनंत',
    'अनंतगुणता',
    'अनंतता',
    'अनंतदृष्टि',
    'अनंतर',
    'अनंतरंग',
    'अनंतरंगता',
    'अनंतरणीय',
    'अनंतरता',
    'अनंतरुप',
    'अनंतवान',
    'अनंतशीर्ष',
    'अनंतशीर्षा',
    'अनंतश्री',
    'अनंता',
    'अनंतिम',
    'अनंती',
    'अनंत्य',
    'अनंबर',
    'अनंवुशतः',
    'अनंश',
    'अनंशता',
    'अनअदायगी',
    'अनअहिवात',
    'अनउठी',
    'अनकटी',
    'अनकमाया',
    'अनकस्मात',
    'अनकहा',
    'अनक़रीब',
    'अनकिए',
    'अनकिया',
    'अनक्षर',
    'अनख',
    'अनखना',
    'अनखाए',
    'अनखाना',
    'अनखाया',
    'अनखिला',
    'अनखी',
    'अनखुलझा',
    'अनखुला',
    'अनगढपन',
    'अनगढ़',
    'अनगढ़ता',
    'अनगढ़तापूर्वक',
    'अनगढ़े',
    'अनगिन',
    'अनगिनत',
    'अनगिने',
    'अनग्न',
    'अनघ',
    'अनघता',
    'अनघ्र्य',
    'अनचाहा',
    'अनचाहे',
    'अनचीता',
    'अनचीन्हा',
    'अनचुकी',
    'अनच्छादन',
    'अनछके',
    'अनछिदा',
    'अनछिदापन',
    'अनछुआ',
    'अनजान',
    'अनजाना',
    'अनजानापन',
    'अनजाने',
    'अनजिर्त',
    'अनजुड़ा',
    'अनजुता',
    'अनजुती',
    'अनझुका',
    'अनटिकापन',
    'अनढँका',
    'अनढँपा',
    'अनत',
    'अनतःपुर',
    'अनतरंग',
    'अनति',
    'अनतिक्रमण',
    'अनतिक्रमित',
    'अनतिक्रान्त',
    'अनतिक्रामक',
    'अनतिरेक',
    'अनतीत',
    'अनतीतता',
    'अनथक',
    'अनथका',
    'अनदबला',
    'अनदेखा',
    'अनदेखापन',
    'अनदेखी',
    'अनद्यतन',
    'अनधिकार',
    'अनधिकारतः',
    'अनधिकारता',
    'अनधिकारी',
    'अनधिकृत',
    'अनधिकृतता',
    'अनधिगत',
    'अनधिगम्य',
    'अनधिमान',
    'अनधिमानी',
    'अनधिष्ठित',
    'अनधिसूचित',
    'अनधिहरण',
    'अनधीन',
    'अनधीनता',
    'अनधुला',
    'अननमुत',
    'अननुकत',
    'अननुकूल',
    'अननुकूलता',
    'अननुज्ञा',
    'अननुज्ञात',
    'अननुबंध',
    'अननुबंधित',
    'अननुबंधिता',
    'अननुभव',
    'अननुभवी',
    'अननुभूति',
    'अननुमत',
    'अननुमति',
    'अननुमानित',
    'अननुमेय',
    'अननुमेयता',
    'अननुमोदन',
    'अननुरक्त',
    'अननुरुप',
    'अननुरुपता',
    'अननुश्यासित',
    'अननुष्ठेय',
    'अननृरुप',
    'अनन्त',
    'अनन्तता',
    'अनन्न',
    'अनन्नास',
    'अनन्य',
    'अनन्यगतिक',
    'अनन्यचित्त',
    'अनन्यचित्तता',
    'अनन्यता',
    'अनन्यतापूर्वक',
    'अनन्यपरता',
    'अनन्यमनस्कता',
    'अनन्यमना',
    'अनन्यमानस',
    'अनन्याधिकार',
    'अनन्योपासना',
    'अनन्वय',
    'अनन्वित',
    'अनपंग',
    'अनपंगता',
    'अनपका',
    'अनपढ़',
    'अनपढ़ा',
    'अनपत्य',
    'अनपदेश',
    'अनपर',
    'अनपराध',
    'अनपराधी',
    'अनपवाद',
    'अनपसारक',
    'अनपाकरण',
    'अनपार्लमैंटरी',
    'अनपावना',
    'अनपेक्ष',
    'अनपेक्षा',
    'अनपेक्षित',
    'अनपेक्षिततः',
    'अनपेक्षितता',
    'अनपेक्षी',
    'अनबँधा',
    'अनबचा',
    'अनबन',
    'अनबना',
    'अनबसा',
    'अनबसापन',
    'अनबिंधा',
    'अनबिंधापन',
    'अनबीता',
    'अनबुझा',
    'अनबुझे',
    'अनबुलाए',
    'अनबुलाया',
    'अनबूझ',
    'अनबोल',
    'अनबोला',
    'अनब्याहा',
    'अनब्याहापन',
    'अनब्याही',
    'अनभरा',
    'अनभरापन',
    'अनभाता',
    'अनभिग्रह',
    'अनभिग्रहण',
    'अनभिज्ञ',
    'अनभिज्ञता',
    'अनभिप्रेत',
    'अनभिभूत',
    'अनभिभूतता',
    'अनभिभूति',
    'अनभिमान',
    'अनभिमान्य',
    'अनभिराम',
    'अनभिरामता',
    'अनभिरुचि',
    'अनभिलषित',
    'अनभिलाष',
    'अनभिलाषा',
    'अनभिलाषी',
    'अनभिलाष्य',
    'अनभिलिखित',
    'अनभिवाचन',
    'अनभिव्यंजना',
    'अनभिव्यक्त',
    'अनभिव्यक्ति',
    'अनभिहित',
    'अनभीष्ट',
    'अनभ्यर्थित',
    'अनभ्यस्त',
    'अनभ्यस्तता',
    'अनभ्यास',
    'अनमना',
    'अनमनापन',
    'अनमने',
    'अनमरा',
    'अनमाँगे',
    'अनमांगी',
    'अनमारग',
    'अनमित',
    'अनमिल',
    'अनमिला',
    'अनमुरझाया',
    'अनमेल',
    'अनमेलपन',
    'अनमोल',
    'अनमोलपन',
    'अनम्य',
    'अनम्यता',
    'अनम्रता',
    'अनम्रर',
    'अनय',
    'अनरँगा',
    'अनरस',
    'अनरीति',
    'अनर्गल',
    'अनर्गलता',
    'अनर्घ',
    'अनर्जक',
    'अनर्जन',
    'अनर्जित',
    'अनर्णीत',
    'अनर्थ',
    'अनर्थक',
    'अनर्थकारी',
    'अनर्थदर्शी',
    'अनर्ह',
    'अनर्हता',
    'अनल',
    'अनलंकृत',
    'अनलंकृतता',
    'अनलंब',
    'अनलगा',
    'अनलगापन',
    'अनलमुख',
    'अनललाह',
    'अनलहक़',
    'अनली',
    'अनलोडिंग',
    'अनवकाश',
    'अनवगत',
    'अनवगाह्य',
    'अनवट',
    'अनवद्य',
    'अनवद्या',
    'अनवधान',
    'अनवधि',
    'अनवनत',
    'अनवरत',
    'अनवरतता',
    'अनवरता',
    'अनवरूद्घ',
    'अनवरूद्धता',
    'अनवरोध',
    'अनवलंबनीय',
    'अनवलंबितता',
    'अनवलोकन',
    'अनवलोकनीय',
    'अनवलोकित',
    'अनवशा',
    'अनवशिष्ट',
    'अनवशोषित',
    'अनवशोषी',
    'अनवसर',
    'अनवसरवादिता',
    'अनवसरवादी',
    'अनवसाद',
    'अनवसित',
    'अनवस्था',
    'अनवस्थित',
    'अनवांछित',
    'अनवीकरणीय',
    'अनवीकृतता',
    'अनवीक्षण',
    'अनवेक्ष',
    'अनवेक्षक',
    'अनवेक्षणीय',
    'अनवेक्षणीयता',
    'अनवेक्षा',
    'अनवेक्षित',
    'अनशन',
    'अनश्वर',
    'अनश्वरता',
    'अनष्ट',
    'अनसकरा',
    'अनसजा',
    'अनसटा',
    'अनसांइटिफ्रिक',
    'अनसितत्व',
    'अनसिला',
    'अनसुना',
    'अनसुनापन',
    'अनसुनी',
    'अनसुहाती',
    'अनसूय',
    'अनसूयतः',
    'अनसूया',
    'अनसोचे',
    'अनस्क्रुइंग',
    'अनस्तित्व',
    'अनस्तित्वमान',
    'अनस्तिव',
    'अनस्थ',
    'अनस्थिक',
    'अनस्थित',
    'अनस्थिर',
    'अनस्थिरण',
    'अनस्थिरता',
    'अनहंकार',
    'अनहंकारी',
    'अनहंकृति',
    'अनहद',
    'अनहितू',
    'अनहुआ',
    'अनहोनहारी',
    'अनहोनी',
    'अनहोवनि',
    'अनांडबर',
    'अनांतरिक',
    'अनांतरिकता',
    'अनांदोलन',
    'अनांदोलित',
    'अनांशिकता',
    'अनाउंसमैंट',
    'अनाउंसर',
    'अनाकर्षक',
    'अनाकर्षकता',
    'अनाकांक्षा',
    'अनाकांक्षित',
    'अनाकांक्षी',
    'अनाकांक्ष्य',
    'अनाकार',
    'अनाकारता',
    'अनाकीर्ण',
    'अनाकीर्णता',
    'अनाकुल',
    'अनाकुलता',
    'अनाक्रमण',
    'अनाक्रम्य',
    'अनाक्रम्यता',
    'अनाक्रामक',
    'अनाक्रामकता',
    'अनागत',
    'अनागति',
    'अनागम',
    'अनागमन',
    'अनागामी',
    'अनागार',
    'अनाचरण',
    'अनाचरणीय',
    'अनाचार',
    'अनाचारिणी',
    'अनाचारी',
    'अनाच्छादन',
    'अनाच्छादित',
    'अनाज',
    'अनाजः',
    'अनाजघर',
    'अनाज्ञाकारिता',
    'अनाज्ञाकारी',
    'अनाड़ी',
    'अनाड़ीपन',
    'अनातप',
    'अनातर',
    'अनातुर',
    'अनात्म',
    'अनात्मज',
    'अनात्मता',
    'अनात्मीय',
    'अनात्मीयता',
    'अनात्म्य',
    'अनात्यंतिक',
    'अनाथ',
    'अनाथतः',
    'अनाथता',
    'अनाथत्व',
    'अनाथालय',
    'अनाथाश्रम',
    'अनादत्त',
    'अनादर',
    'अनादरण',
    'अनादरणीय',
    'अनादरपूर्वक',
    'अनादरसूचक',
    'अनादरित',
    'अनादर्शता',
    'अनादान',
    'अनादायाद',
    'अनादि',
    'अनादित',
    'अनादिता',
    'अनादिनिधन',
    'अनादिम',
    'अनादिमता',
    'अनादेय',
    'अनाद्यंत',
    'अनाद्यंतता',
    'अनाधिकार',
    'अनाध्यात्मवाद',
    'अनानंद',
    'अनानंदित',
    'अनानुपूव्र्य',
    'अनानुवंशिक',
    'अनानुवंशिकता',
    'अनाप',
    'अनापत्ति',
    'अनापद',
    'अनापदता',
    'अनापदा',
    'अनापन्न',
    'अनाप्त',
    'अनाप्तता',
    'अनाबंटित',
    'अनाबद्घ',
    'अनाबद्घता',
    'अनाबद्घन',
    'अनाभूषित',
    'अनाभूषितता',
    'अनाम',
    'अनामंत्रित',
    'अनामक',
    'अनामय',
    'अनामयता',
    'अनामा',
    'अनामिक',
    'अनामिका',
    'अनामित',
    'अनामी',
    'अनामोद',
    'अनाम्य',
    'अनाम्यता',
    'अनायक',
    'अनायत्त',
    'अनायास',
    'अनायुक्त',
    'अनायुषा',
    'अनार',
    'अनारंजित',
    'अनारंभ',
    'अनारक्षित',
    'अनारदाना',
    'अनारूढ़',
    'अनारोग्यकर',
    'अनार्जव',
    'अनार्तव',
    'अनार्थिक',
    'अनार्दता',
    'अनार्द्र',
    'अनार्द्रण',
    'अनार्द्रीकरण',
    'अनार्य',
    'अनार्यक',
    'अनार्यता',
    'अनार्ष',
    'अनालंबी',
    'अनालाप',
    'अनालेपित',
    'अनालोकित',
    'अनालोचित',
    'अनावरण',
    'अनावर्तन',
    'अनावर्तित',
    'अनावर्तिता',
    'अनावर्ती',
    'अनावलंब',
    'अनावलंबी',
    'अनावश्यक',
    'अनावश्यकता',
    'अनावास',
    'अनावासित',
    'अनावासितता',
    'अनावासिता',
    'अनावासी',
    'अनावाहन',
    'अनावाहित',
    'अनाविष्ट',
    'अनाविष्टता',
    'अनावृत',
    'अनावृत्त',
    'अनावृत्ति',
    'अनावृष्टि',
    'अनाश',
    'अनाशंका',
    'अनाशंकित',
    'अनाशकायन',
    'अनाशयित',
    'अनाशा',
    'अनाशी',
    'अनाशोधन',
    'अनाश्रयता',
    'अनासक्त',
    'अनासक्तता',
    'अनासक्ति',
    'अनासन्न',
    'अनासिर',
    'अनास्था',
    'अनाहत',
    'अनाहरण',
    'अनाहरित',
    'अनाहार',
    'अनाहारी',
    'अनाहार्य',
    'अनाहार्यता',
    'अनाहूत',
    'अनिंद्य',
    'अनिंद्रिय',
    'अनिकट',
    'अनिकटता',
    'अनिकृष्ट',
    'अनिकेत',
    'अनिगमित',
    'अनिग्न',
    'अनिग्रह',
    'अनिच्छा',
    'अनिच्छापूर्वक',
    'अनिच्छित',
    'अनिच्छुक',
    'अनिजक',
    'अनितांत',
    'अनित्य',
    'अनित्यता',
    'अनिदान',
    'अनिद्र',
    'अनिद्रा',
    'अनिद्रित',
    'अनिन्वत',
    'अनिपुण',
    'अनिपुणता',
    'अनिबद्ध',
    'अनिमंत्रण',
    'अनिमंत्रित',
    'अनिमंत्रितः',
    'अनिमंत्रिततः',
    'अनिमार्ता',
    'अनिमित',
    'अनिमित्तक',
    'अनिमित्ततः',
    'अनिमित्त्कता',
    'अनिमिर्त',
    'अनिमिष',
    'अनिमिषीय',
    'अनिमीलित',
    'अनिमीलिततः',
    'अनिमेष',
    'अनिमेषिततः',
    'अनियंत्रण',
    'अनियंत्रित',
    'अनियंत्रिततः',
    'अनियंत्रितता',
    'अनियत',
    'अनियतरूपी',
    'अनियता',
    'अनियन्त्रित',
    'अनियम',
    'अनियमवाद',
    'अनियमित',
    'अनियमितता',
    'अनियमितताएं',
    'अनियुक्त',
    'अनियोग',
    'अनियोजित',
    'अनियोजिततः',
    'अनियोज्यता',
    'अनिरंतर',
    'अनिरंतरता',
    'अनिरत',
    'अनिराकरण',
    'अनिराकृत',
    'अनिरुद्घ',
    'अनिरुपित',
    'अनिरूद्घ',
    'अनिरूद्घः',
    'अनिर्गमित',
    'अनिर्णय',
    'अनिर्णयग्रस्तता',
    'अनिर्णीत',
    'अनिर्णीततः',
    'अनिर्णीतता',
    'अनिर्दिष्ट',
    'अनिर्दिष्टकाल',
    'अनिर्दिष्टतः',
    'अनिर्दिष्टता',
    'अनिर्धारणीय',
    'अनिर्धारित',
    'अनिर्धारितता',
    'अनिर्बध',
    'अनिर्माण',
    'अनिर्माता',
    'अनिर्वचनीय',
    'अनिर्वचनीयता',
    'अनिर्वाच्य',
    'अनिर्वाच्यता',
    'अनिर्वाह',
    'अनिर्वाह्य',
    'अनिल',
    'अनिलात्मज',
    'अनिलामय',
    'अनिलाशी',
    'अनिवारण',
    'अनिवारणीय',
    'अनिवारणीयता',
    'अनिवारित',
    'अनिवार्य',
    'अनिवार्यतः',
    'अनिवार्यता',
    'अनिवार्यरूप',
    'अनिवासिता',
    'अनिवासी',
    'अनिवृत्तात्मा',
    'अनिवृत्ति',
    'अनिशात',
    'अनिश्चतता',
    'अनिश्चय',
    'अनिश्चयग्रस्तता',
    'अनिश्चयात्मक',
    'अनिश्चल',
    'अनिश्चित',
    'अनिश्चितता',
    'अनिश्चिता',
    'अनिश्चिय',
    'अनिषिद्घ',
    'अनिषिद्घतः',
    'अनिषिद्घता',
    'अनिषेध',
    'अनिष्छनीय',
    'अनिष्ट',
    'अनिष्टकर',
    'अनिष्टकरपन',
    'अनिष्टकर्त्ता',
    'अनिष्टकारिता',
    'अनिष्टकारी',
    'अनिष्टसूचक',
    'अनिष्ठ',
    'अनिष्ठा',
    'अनिष्ठित',
    'अनिष्णतता',
    'अनिष्णात',
    'अनिष्पत्ति',
    'अनिष्पन्न',
    'अनिष्पादित',
    'अनिष्पाद्य',
    'अनिष्पाद्यता',
    'अनिस्तीर्यता',
    'अनी',
    'अनीक',
    'अनीकिनी',
    'अनीकीय',
    'अनीड़',
    'अनीता',
    'अनीति',
    'अनीतिज्ञता',
    'अनीतिपूर्ण',
    'अनीप्सा',
    'अनीय',
    'अनीश',
    'अनीश्वर',
    'अनीश्वरता',
    'अनीश्वरवाद',
    'अनीश्वरवादी',
    'अनीष्र्यतः',
    'अनीह',
    'अनीहा',
    'अनु',
    'अनुकंप',
    'अनुकंपन',
    'अनुकंपनीय',
    'अनुकंपनीयता',
    'अनुकंपा',
    'अनुकथन',
    'अनुकथा',
    'अनुकपाल',
    'अनुकम्पा',
    'अनुकर',
    'अनुकरण',
    'अनुकरणकर्ता',
    'अनुकरणज',
    'अनुकरणशील',
    'अनुकरणात्मक',
    'अनुकरणात्मकता',
    'अनुकरणीय',
    'अनुकरात्मक',
    'अनुकर्ता',
    'अनुकर्म',
    'अनुकर्ष',
    'अनुकर्षण',
    'अनुकल्प',
    'अनुकांक्षा',
    'अनुकांक्षित',
    'अनुकांक्षी',
    'अनुकाम',
    'अनुकामी',
    'अनुकार',
    'अनुकारी',
    'अनुकीर्तन',
    'अनुकूल',
    'अनुकूलतम',
    'अनुकूलता',
    'अनुकूलन',
    'अनुकूलनशील',
    'अनुकूलनशीलता',
    'अनुकूलनीय',
    'अनुकूलनीयता',
    'अनुकूलित',
    'अनुकृत',
    'अनुकृति',
    'अनुकेंद्र',
    'अनुकोण',
    'अनुक्त',
    'अनुक्ति',
    'अनुक्रकच',
    'अनुक्रम',
    'अनुक्रमण',
    'अनुक्रमणिक',
    'अनुक्रमणिका',
    'अनुक्रमणी',
    'अनुक्रमणीकार',
    'अनुक्रमहीन',
    'अनुक्रमहीनतः',
    'अनुक्रमहीनता',
    'अनुक्रमागत',
    'अनुक्रमानुपाती',
    'अनुक्रमिक',
    'अनुक्रमिकता',
    'अनुक्रमित',
    'अनुक्रमी',
    'अनुक्रमेण',
    'अनुक्रांत',
    'अनुक्रियता',
    'अनुक्रिया',
    'अनुक्रियात्मक',
    'अनुक्रियाशील',
    'अनुक्रियाशीलता',
    'अनुक्षण',
    'अनुक्षितिज',
    'अनुक्षितिजता',
    'अनुक्षेत्र',
    'अनुगंतव्य',
    'अनुगंता',
    'अनुगत',
    'अनुगततः',
    'अनुगति',
    'अनुगम',
    'अनुगमन',
    'अनुगामिता',
    'अनुगामी',
    'अनुगामीयुक्त',
    'अनुगामीहीन',
    'अनुगुंजन',
    'अनुगुंजित',
    'अनुगुण',
    'अनुगुणित',
    'अनुगूँज',
    'अनुगृहित',
    'अनुगृहीत',
    'अनुग्र',
    'अनुग्रता',
    'अनुग्रह',
    'अनुग्रहपूर्वक',
    'अनुग्रहांक',
    'अनुग्रही',
    'अनुग्राही',
    'अनुघटी',
    'अनुचक्र',
    'अनुचक्रिल',
    'अनुचक्रिलता',
    'अनुचर',
    'अनुचरण',
    'अनुचरतः',
    'अनुचरता',
    'अनुचरी',
    'अनुचर्या',
    'अनुचलन',
    'अनुचिंतत',
    'अनुचिंतन',
    'अनुचित',
    'अनुचितता',
    'अनुचित्रण',
    'अनुचित्रित',
    'अनुचिर्तित',
    'अनुचिहृन',
    'अनुचुम्बकीय',
    'अनुच्चरण',
    'अनुच्चरित',
    'अनुच्चारण',
    'अनुच्चारणीय',
    'अनुच्चारित',
    'अनुच्चार्य',
    'अनुच्छिष्ट',
    'अनुच्छेद',
    'अनुच्छेदों',
    'अनुज',
    'अनुजता',
    'अनुजल',
    'अनुजा',
    'अनुजात',
    'अनुजीवी',
    'अनुज्ज्वल',
    'अनुज्ञप्त',
    'अनुज्ञप्ति',
    'अनुज्ञप्तिधारी',
    'अनुज्ञा',
    'अनुज्ञात',
    'अनुज्ञाता',
    'अनुज्ञात्मकता',
    'अनुज्ञापक',
    'अनुज्ञापत्र',
    'अनुज्ञापन',
    'अनुज्ञापित',
    'अनुज्ञाप्राप्त',
    'अनुज्ञेय',
    'अनुतः',
    'अनुतप्त',
    'अनुतप्तता',
    'अनुतरंग',
    'अनुतानात्मक',
    'अनुताप',
    'अनुतापन',
    'अनुतापित्र',
    'अनुतापी',
    'अनुतिथ्य',
    'अनुतुरता',
    'अनुतेजना',
    'अनुतोष',
    'अनुत्कंठि',
    'अनुत्क्रम',
    'अनुत्क्रमण',
    'अनुत्क्रमणीय',
    'अनुत्क्रमणीयता',
    'अनुत्क्रमित',
    'अनुत्क्रामक',
    'अनुत्तर',
    'अनुत्तरता',
    'अनुत्तरदायित्व',
    'अनुत्तरदायी',
    'अनुत्तान',
    'अनुत्तीर्ण',
    'अनुत्तेजित',
    'अनुत्तेजितता',
    'अनुत्पत्ति',
    'अनुत्पन्न',
    'अनुत्पादक',
    'अनुत्पादन',
    'अनुत्पादनशील',
    'अनुत्पादी',
    'अनुत्मक',
    'अनुत्रिक',
    'अनुत्साह',
    'अनुत्साहन',
    'अनुत्साही',
    'अनुत्सुक',
    'अनुदक',
    'अनुदत्त',
    'अनुदर',
    'अनुदरा',
    'अनुदर्शन',
    'अनुदशक',
    'अनुदाता',
    'अनुदात्तता',
    'अनुदान',
    'अनुदानकर्ता',
    'अनुदानों',
    'अनुदार',
    'अनुदारता',
    'अनुदित',
    'अनुदिन',
    'अनुदिश',
    'अनुदिशतः',
    'अनुदिशत्व',
    'अनुदेय',
    'अनुदेश',
    'अनुदेशक',
    'अनुदेशानुसार',
    'अनुदेशी',
    'अनुदेशों',
    'अनुद्घाटन',
    'अनुद्घाटित',
    'अनुद्भव',
    'अनुद्भूत',
    'अनुद्यत',
    'अनुद्यततः',
    'अनुद्यतता',
    'अनुद्यम',
    'अनुद्यमी',
    'अनुद्योग',
    'अनुद्वत',
    'अनुद्वततः',
    'अनुद्वतता',
    'अनुद्विग्न',
    'अनुद्विग्नता',
    'अनुद्वेग',
    'अनुधावन',
    'अनुध्यात',
    'अनुध्यान',
    'अनुनय',
    'अनुनयन',
    'अनुनयपूर्वक',
    'अनुनयी',
    'अनुनाद',
    'अनुनादक',
    'अनुनादन',
    'अनुनादित',
    'अनुनादी',
    'अनुनासिक',
    'अनुनीत',
    'अनुनुकृति',
    'अनुनेय',
    'अनुन्मत्त',
    'अनुन्मुक्त',
    'अनुन्मुख',
    'अनुन्मोचित',
    'अनुपंरपरा',
    'अनुपगत',
    'अनुपगति',
    'अनुपजाऊ',
    'अनुपठन',
    'अनुपत्र',
    'अनुपथ',
    'अनुपद',
    'अनुपदवी',
    'अनुपदिष्ट',
    'अनुपदी',
    'अनुपपत्ति',
    'अनुपपत्र',
    'अनुपपन्न',
    'अनुपभुक्त',
    'अनुपभुक्तता',
    'अनुपभोग',
    'अनुपम',
    'अनुपमता',
    'अनुपमा',
    'अनुपमेय',
    'अनुपमेयता',
    'अनुपयुक्त',
    'अनुपयुक्तता',
    'अनुपयोग',
    'अनुपयोगिता',
    'अनुपयोगी',
    'अनुपयोग्य',
    'अनुपरीक्षा',
    'अनुपर्व',
    'अनुपल',
    'अनुपलक्षित',
    'अनुपलब्ध',
    'अनुपलब्धता',
    'अनुपलब्धि',
    'अनुपलभ्य',
    'अनुपस्थित',
    'अनुपस्थितता',
    'अनुपस्थिति',
    'अनुपाठ',
    'अनुपात',
    'अनुपातक',
    'अनुपातन',
    'अनुपातपूर्ण',
    'अनुपातयुक्त',
    'अनुपातयुक्तता',
    'अनुपातहीन',
    'अनुपातहीनता',
    'अनुपातिक',
    'अनुपातित',
    'अनुपातीय',
    'अनुपादकता',
    'अनुपान',
    'अनुपार्जक',
    'अनुपार्जित',
    'अनुपाल',
    'अनुपालक',
    'अनुपालन',
    'अनुपालनकर्ता',
    'अनुपालनीय',
    'अनुपुरुष',
    'अनुपूरक',
    'अनुपूरण',
    'अनुपूर्त',
    'अनुपूर्ति',
    'अनुपृच्छा',
    'अनुपेक्षक',
    'अनुपेक्षा',
    'अनुपेक्षित',
    'अनुपेत',
    'अनुप्रणन',
    'अनुप्रतीक',
    'अनुप्रथा',
    'अनुप्रभाव',
    'अनुप्रमाणन',
    'अनुप्रमाणित',
    'अनुप्रयुक्त',
    'अनुप्रयोग',
    'अनुप्रयोज्य',
    'अनुप्रयोज्यता',
    'अनुप्रवाह',
    'अनुप्रवाहगामी',
    'अनुप्रवाहतः',
    'अनुप्रवेश',
    'अनुप्रश्न',
    'अनुप्रस्थ',
    'अनुप्राणन',
    'अनुप्राणित',
    'अनुप्रास',
    'अनुप्रेक्षा',
    'अनुबंध',
    'अनुबंधक',
    'अनुबंधकारी',
    'अनुबंधन',
    'अनुबंधहीनता',
    'अनुबंधित',
    'अनुबद्घ',
    'अनुबद्घता',
    'अनुबद्घन',
    'अनुबद्ध',
    'अनुबन्ध',
    'अनुबन्धन',
    'अनुबल',
    'अनुबोध',
    'अनुबोधक',
    'अनुभव',
    'अनुभवगम्य',
    'अनुभवजन्य',
    'अनुभवमूलक',
    'अनुभवयुक्तता',
    'अनुभववाद',
    'अनुभवहीन',
    'अनुभवहीनता',
    'अनुभवातीत',
    'अनुभवाश्रित',
    'अनुभवी',
    'अनुभवों',
    'अनुभाग',
    'अनुभागाध्यक्ष',
    'अनुभागीय',
    'अनुभागों',
    'अनुभाजन',
    'अनुभाव',
    'अनुभावक',
    'अनुभावी',
    'अनुभाषण',
    'अनुभूत',
    'अनुभूति',
    'अनुभूतियुक्त',
    'अनुभूतिहीन',
    'अनुभूतिहीनता',
    'अनुभूतींद्रिय',
    'अनुभोक्ता',
    'अनुभोग',
    'अनुभ्राता',
    'अनुमंता',
    'अनुमत',
    'अनुमति',
    'अनुमतिहीन',
    'अनुमतिहीनता',
    'अनुमनन',
    'अनुमरण',
    'अनुमस्तिष्क',
    'अनुमाता',
    'अनुमान',
    'अनुमानतः',
    'अनुमानन',
    'अनुमानहीनता',
    'अनुमानातीत',
    'अनुमानातीतता',
    'अनुमानिक',
    'अनुमानित',
    'अनुमानितता',
    'अनुमानुरुपतः',
    'अनुमानों',
    'अनुमाप',
    'अनुमापक',
    'अनुमार्ग',
    'अनुमार्गतः',
    'अनुमित',
    'अनुमिति',
    'अनुमितिवाद',
    'अनुमुद्रण',
    'अनुमुद्रित',
    'अनुमेय',
    'अनुमेयता',
    'अनुमोदक',
    'अनुमोदन',
    'अनुमोदनात्मक',
    'अनुमोदनार्थ',
    'अनुमोदित',
    'अनुम्लोचा',
    'अनुयाचक',
    'अनुयाचना',
    'अनुयान',
    'अनुयायन',
    'अनुयायिता',
    'अनुयायी',
    'अनुयूषम',
    'अनुयोक्ता',
    'अनुयोग',
    'अनुयोजन',
    'अनुरंजक',
    'अनुरंजित',
    'अनुरंजिता',
    'अनुरक्त',
    'अनुरक्ति',
    'अनुरक्षक',
    'अनुरक्षण',
    'अनुरक्षणीय',
    'अनुरत',
    'अनुरतता',
    'अनुरति',
    'अनुरथ्या',
    'अनुरमण',
    'अनुरस',
    'अनुराग',
    'अनुरागक',
    'अनुरागना',
    'अनुरागमय',
    'अनुरागिनी',
    'अनुरागी',
    'अनुराग्य',
    'अनुराध',
    'अनुराधा',
    'अनुरिक्थ',
    'अनुरीति',
    'अनुरुप',
    'अनुरुपक',
    'अनुरुपतः',
    'अनुरुपता',
    'अनुरुपी',
    'अनुरूदन',
    'अनुरूप',
    'अनुरूपक',
    'अनुरूपण',
    'अनुरूपता',
    'अनुरूपी',
    'अनुरेख',
    'अनुरेखक',
    'अनुरेखण',
    'अनुरोदन',
    'अनुरोध',
    'अनुरोधक',
    'अनुरोधपूर्ण',
    'अनुरोधित',
    'अनुरोधी',
    'अनुर्वर',
    'अनुर्वरता',
    'अनुलंगम',
    'अनुलंब',
    'अनुलंबता',
    'अनुलंबित',
    'अनुलक्ष्य',
    'अनुलगम',
    'अनुलग्गन',
    'अनुलग्न',
    'अनुलग्नक',
    'अनुलग्नन',
    'अनुलब्धियां',
    'अनुलब्न',
    'अनुलम्ब',
    'अनुलहर',
    'अनुलाक्ष्य',
    'अनुलाप',
    'अनुलाभ',
    'अनुलिपि',
    'अनुलिपिक',
    'अनुलिपिकरण',
    'अनुलिपित्र',
    'अनुलिपियां',
    'अनुलेखन',
    'अनुलेखित',
    'अनुलेपन',
    'अनुलोम',
    'अनुलोमतः',
    'अनुल्क',
    'अनुल्लंघक',
    'अनुल्लंघन',
    'अनुल्लंघनीय',
    'अनुल्लंघित',
    'अनुल्लेखनीय',
    'अनुल्लेखनीयता',
    'अनुवंश',
    'अनुवंशतः',
    'अनुवंशता',
    'अनुवंशावलि',
    'अनुवंशिकता',
    'अनुवंशी',
    'अनुवंशीय',
    'अनुवंशीयता',
    'अनुवक्ता',
    'अनुवचन',
    'अनुवर्तन',
    'अनुवर्तिता',
    'अनुवर्ती',
    'अनुवर्ष',
    'अनुवर्षस्तर',
    'अनुवाक',
    'अनुवाचन',
    'अनुवाची',
    'अनुवात',
    'अनुवातोमिर्',
    'अनुवाद',
    'अनुवादक',
    'अनुवादकों',
    'अनुवादित',
    'अनुवादी',
    'अनुवार्षिकी',
    'अनुवास',
    'अनुविधान',
    'अनुविभाजन',
    'अनुवीक्षण',
    'अनुवीचि',
    'अनुवृत',
    'अनुवृत्ति',
    'अनुवृद्धि',
    'अनुवेतन',
    'अनुवेदना',
    'अनुवेप',
    'अनुवेल्लन',
    'अनुव्रत',
    'अनुशंसा',
    'अनुशंसाकारी',
    'अनुशय',
    'अनुशयान',
    'अनुशयी',
    'अनुशर',
    'अनुशासक',
    'अनुशासन',
    'अनुशासनबद्घ',
    'अनुशासनहीन',
    'अनुशासनहीनता',
    'अनुशासनात्मक',
    'अनुशासनिक',
    'अनुशासिक',
    'अनुशासित',
    'अनुशासितता',
    'अनुशासी',
    'अनुशास्ता',
    'अनुशिक्षक',
    'अनुशिष्ट',
    'अनुशीतक',
    'अनुशीतन',
    'अनुशीतित्र',
    'अनुशीलन',
    'अनुशीलित',
    'अनुशेष',
    'अनुशोक',
    'अनुशोधन',
    'अनुश्रवण',
    'अनुश्रवणक',
    'अनुश्रुत',
    'अनुश्रुति',
    'अनुषंग',
    'अनुषंगी',
    'अनुष्टुभ',
    'अनुष्ठाता',
    'अनुष्ठान',
    'अनुष्ठेय',
    'अनुष्ण',
    'अनुसंधान',
    'अनुसंधानी',
    'अनुसंधि',
    'अनुसमर्थक',
    'अनुसमर्थन',
    'अनुसर',
    'अनुसरण',
    'अनुसर्प',
    'अनुसाम',
    'अनुसार',
    'अनुसारण',
    'अनुसारतः',
    'अनुसारता',
    'अनुसूचित',
    'अनुसूचियां',
    'अनुसूची',
    'अनुसृत',
    'अनुस्तरण',
    'अनुस्तरणी',
    'अनुस्तोत्र',
    'अनुस्थापन',
    'अनुस्मरण',
    'अनुस्मारक',
    'अनुस्मृति',
    'अनुस्यूत',
    'अनुस्वन',
    'अनुस्वार',
    'अनुहार',
    'अनुहारी',
    'अनुहार्य',
    'अनुिल्लखित',
    'अनुृणी',
    'अनूक',
    'अनूकूल',
    'अनूचान',
    'अनूजिर्त',
    'अनूठा',
    'अनूठापन',
    'अनूठी',
    'अनूठेपन',
    'अनूढ़',
    'अनूढ़ा',
    'अनूढ़ाः',
    'अनूदित',
    'अनून',
    'अनूप',
    'अनूरू',
    'अनूसूची',
    'अनृत',
    'अनृतभाषी',
    'अनृती',
    'अनृमन्यु',
    'अनेक',
    'अनेकचर',
    'अनेकता',
    'अनेकत्व',
    'अनेकदा',
    'अनेकधा',
    'अनेकमूर्ति',
    'अनेकरुप',
    'अनेकरुपता',
    'अनेकलोचन',
    'अनेकवाद',
    'अनेकविध',
    'अनेकवृत्ति',
    'अनेकशः',
    'अनेकांगी',
    'अनेकांत',
    'अनेकांतवाद',
    'अनेकाकार',
    'अनेकाकारता',
    'अनेकाक्षर',
    'अनेकाग्र',
    'अनेकाग्रता',
    'अनेकानेक',
    'अनेकार्थक',
    'अनेकार्थता',
    'अनेकार्थी',
    'अनेकेश्वर',
    'अनेतर',
    'अनेतरता',
    'अनैकांतिक',
    'अनैक्य',
    'अनैच्छिक',
    'अनैच्छिकता',
    'अनैतिक',
    'अनैतिकता',
    'अनोखा',
    'अनोखापन',
    'अनोखी',
    'अनोजस्वी',
    'अनौचित्य',
    'अनौद्घत्य',
    'अनौपचारिक',
    'अनौपचारिकतः',
    'अनौपचारिकता',
    'अनौपचारिकतापूर्वक',
    'अनौरस',
    'अन्त',
    'अन्तकरण',
    'अन्तगर्भाशयी',
    'अन्तध्वंस',
    'अन्तपुर',
    'अन्तप्रक्षेपगृह',
    'अन्तभावितता',
    'अन्तयानुप्रास',
    'अन्तर',
    'अन्तरंग',
    'अन्तरक',
    'अन्तरण',
    'अन्तरत्वचा',
    'अन्तराग्रहीय',
    'अन्तरातारकी',
    'अन्तरात्मा',
    'अन्तरापृष्ठीय',
    'अन्तरायक',
    'अन्तराल',
    'अन्तरालिका',
    'अन्तरालीय',
    'अन्तरिक्ष',
    'अन्तरिक्षयान',
    'अन्तरिम',
    'अन्तरीप',
    'अन्तर्गत',
    'अन्तर्गामी',
    'अन्तर्गृह',
    'अन्तर्ग्रथन',
    'अन्तर्ग्रथित',
    'अन्तर्दहन',
    'अन्तर्दृष्टि',
    'अन्तर्देशीय',
    'अन्तर्दौहिक',
    'अन्तर्द्वन्द',
    'अन्तर्धान',
    'अन्तर्निरीक्षण',
    'अन्तर्निविष्ट',
    'अन्तर्निवेशन',
    'अन्तर्निहित',
    'अन्तर्बदल',
    'अन्तर्भासिक',
    'अन्तर्भासी',
    'अन्तर्मुखी',
    'अन्तर्राज',
    'अन्तर्राज्यी',
    'अन्तर्राष्ट्रवादी',
    'अन्तर्राष्ट्रीय',
    'अन्तर्राष्ट्रीयकरण',
    'अन्तर्राष्ट्रीयता',
    'अन्तर्राष्ट्रीयतावादी',
    'अन्तर्रोध',
    'अन्तर्रोधन',
    'अन्तर्लम्ब',
    'अन्तर्लीन',
    'अन्तर्लेखन',
    'अन्तर्वदल',
    'अन्तर्वर्धित',
    'अन्तर्वाह',
    'अन्तर्विद्ध',
    'अन्तर्विनिमय',
    'अन्तर्विभागीय',
    'अन्तर्विवाह',
    'अन्तर्विष्ट',
    'अन्तर्वेश',
    'अन्तर्वेशन',
    'अन्तर्वेशी',
    'अन्तवर्ती',
    'अन्तस्थ',
    'अन्तस्यता',
    'अन्तहीनता',
    'अन्तिम',
    'अन्त्याघाती',
    'अन्त्येष्टि',
    'अन्दर',
    'अन्दरूनी',
    'अन्दाज',
    'अन्दाजा',
    'अन्दाज़',
    'अन्दाज़े',
    'अन्ध',
    'अन्धकार',
    'अन्धकारपन',
    'अन्धकारपूर्णता',
    'अन्धा',
    'अन्धाधुन्ध',
    'अन्धानुकरण',
    'अन्धों',
    'अन्न',
    'अन्नमय',
    'अन्नमल',
    'अन्नशेष',
    'अन्नसन्न',
    'अन्नस्त',
    'अन्ना',
    'अन्नांकुर',
    'अन्नाकाल',
    'अन्नागार',
    'अन्नाद',
    'अन्नाद्य',
    'अन्नामलै',
    'अन्नाहारी',
    'अन्नि',
    'अन्नोत्पादक',
    'अन्नोत्पादन',
    'अन्नोत्सर्ग',
    'अन्य',
    'अन्यगामिनी',
    'अन्यगामी',
    'अन्यचित',
    'अन्यच्च',
    'अन्यतः',
    'अन्यतम',
    'अन्यतमता',
    'अन्यता',
    'अन्यत्र',
    'अन्यत्रवासी',
    'अन्यथा',
    'अन्यदा',
    'अन्यदीय',
    'अन्यदीया',
    'अन्यदेशी',
    'अन्यपुरुष',
    'अन्यपुष्ट',
    'अन्यपुष्टा',
    'अन्यभरण',
    'अन्यभृत',
    'अन्यमनस्क',
    'अन्यमनस्कता',
    'अन्यसंक्रामण',
    'अन्यसंक्राम्य',
    'अन्यस्य',
    'अन्या',
    'अन्यादृश',
    'अन्यापदेश',
    'अन्यापि',
    'अन्याय',
    'अन्यायपूर्ण',
    'अन्यायी',
    'अन्यारा',
    'अन्यार्थ',
    'अन्योअन्य',
    'अन्योक्ति',
    'अन्योक्तिकार',
    'अन्योक्तिपरक',
    'अन्योक्तिपरकता',
    'अन्योदर',
    'अन्योदरज',
    'अन्योदरता',
    'अन्योदर्य',
    'अन्योन्य',
    'अन्योन्यतः',
    'अन्योन्यता',
    'अन्योन्याश्रय',
    'अन्योन्याश्रयवाद',
    'अन्योय',
    'अन्वक्ष',
    'अन्वक्षता',
    'अन्वय',
    'अन्वयता',
    'अन्वयन',
    'अन्वयाकर्षण',
    'अन्वयागत',
    'अन्वयागतता',
    'अन्वयी',
    'अन्वाकर्षण',
    'अन्वायन',
    'अन्वित',
    'अन्विता',
    'अन्वितार्थ',
    'अन्वीक्षण',
    'अन्वीक्षा',
    'अन्वेषक',
    'अन्वेषण',
    'अन्वेषणों',
    'अन्वेषा',
    'अन्वेषित',
    'अन्वेषी',
    'अप',
    'अपंकिल',
    'अपंख',
    'अपंखोद्भूत',
    'अपंग',
    'अपंगघात',
    'अपंगतः',
    'अपंगता',
    'अपंगु',
    'अपंजीयन',
    'अपंडित',
    'अपंवचित',
    'अपकरण',
    'अपकर्ता',
    'अपकर्म',
    'अपकर्मा',
    'अपकर्मी',
    'अपकर्ष',
    'अपकर्षक',
    'अपकर्षकारी',
    'अपकर्षण',
    'अपकर्षता',
    'अपकर्षित',
    'अपकर्षी',
    'अपकामी',
    'अपकामुकता',
    'अपकार',
    'अपकारक',
    'अपकारिता',
    'अपकारी',
    'अपकार्य',
    'अपकाष्ठा',
    'अपकाष्ठात्मक',
    'अपकीर्त',
    'अपकीर्तन',
    'अपकीर्ति',
    'अपकीर्तिकर',
    'अपकृत',
    'अपकृत्य',
    'अपकृष्ट',
    'अपकेंद्र',
    'अपकेंद्रण',
    'अपकेंद्रता',
    'अपकेंद्रित',
    'अपकेंद्रीय',
    'अपकेन्द्रण',
    'अपकेन्द्रित',
    'अपक्य',
    'अपक्रम',
    'अपक्रमतः',
    'अपक्व',
    'अपक्वता',
    'अपक्ष',
    'अपक्षधर',
    'अपक्षधरता',
    'अपक्षपात',
    'अपक्षपाती',
    'अपक्षय',
    'अपक्षरणीय',
    'अपक्षीय',
    'अपक्षेप',
    'अपक्षेपण',
    'अपक्षोद्भूत',
    'अपखंडन',
    'अपगत',
    'अपगति',
    'अपगमन',
    'अपगा',
    'अपगामी',
    'अपगीत',
    'अपगुण',
    'अपगुणी',
    'अपग्रल्भता',
    'अपघटन',
    'अपघटित',
    'अपघट्य',
    'अपघर्षण',
    'अपघात',
    'अपघाती',
    'अपच',
    'अपचार',
    'अपचारी',
    'अपचित',
    'अपच्छाया',
    'अपच्छेदन',
    'अपच्युत',
    'अपच्युति',
    'अपछाया',
    'अपटापन',
    'अपटुडेट',
    'अपटुता',
    'अपठनीय',
    'अपढ़',
    'अपढ़ता',
    'अपतंत्रक',
    'अपतट',
    'अपतटीय',
    'अपताप',
    'अपति',
    'अपतिव्रत',
    'अपती',
    'अपत्नीकता',
    'अपत्य',
    'अपत्र',
    'अपथ्य',
    'अपद',
    'अपदचर',
    'अपदस्थ',
    'अपदस्थीकरण',
    'अपदार्थ',
    'अपदूत',
    'अपदेवता',
    'अपदेश',
    'अपदोहन',
    'अपद्रव्य',
    'अपद्रव्यता',
    'अपद्रावक',
    'अपद्रावण',
    'अपद्रावित',
    'अपधर्मी',
    'अपध्वंस',
    'अपनत्व',
    'अपनत्वपूर्ण',
    'अपनत्वपूर्णता',
    'अपनत्वहीन',
    'अपनयन',
    'अपना',
    'अपनांत',
    'अपनाए',
    'अपनाती',
    'अपनाते',
    'अपनाना',
    'अपनाने',
    'अपनान्तिक',
    'अपनापन',
    'अपनापा',
    'अपनाम',
    'अपनायक',
    'अपनाया',
    'अपनाव',
    'अपनिर्देशन',
    'अपनिर्वचन',
    'अपनी',
    'अपनीत',
    'अपनुत्ति',
    'अपने',
    'अपनेता',
    'अपनोद',
    'अपनोदन',
    'अपन्न',
    'अपपरिवर्तन',
    'अपपाठ',
    'अपपात्रित',
    'अपभंरश',
    'अपभाषण',
    'अपभाषा',
    'अपभाषी',
    'अपभोग',
    'अपभ्रष्ट',
    'अपमनस्कता',
    'अपमाजिर्त',
    'अपमान',
    'अपमानकारी',
    'अपमानजनक',
    'अपमानपूर्ण',
    'अपमानपूर्वक',
    'अपमानवीकरण',
    'अपमानित',
    'अपमानी',
    'अपमान्य',
    'अपमार्ग',
    'अपमार्गी',
    'अपमार्जक',
    'अपमार्जकाः',
    'अपमार्जन',
    'अपमिश्रक',
    'अपमिश्रण',
    'अपमिश्रित',
    'अपमिश्रितता',
    'अपमुद्रण',
    'अपमृत्यु',
    'अपमोचक',
    'अपयश',
    'अपयशी',
    'अपयान',
    'अपयुग्मन',
    'अपयोग',
    'अपयोजन',
    'अपर',
    'अपरंच',
    'अपरंतुकतः',
    'अपरंतुकता',
    'अपरंपरागत',
    'अपरंपार',
    'अपरक्राम्य',
    'अपरज',
    'अपरजता',
    'अपरद',
    'अपरदन',
    'अपरदनकारी',
    'अपरदेशीय',
    'अपरम्परागत',
    'अपरसरण',
    'अपरस्पर',
    'अपरस्परता',
    'अपरा',
    'अपरांत',
    'अपराक्रमी',
    'अपराग',
    'अपराजित',
    'अपराजितता',
    'अपराजेय',
    'अपराजेयता',
    'अपराध',
    'अपराधक',
    'अपराधता',
    'अपराधदंड',
    'अपराधपन',
    'अपराधपूर्ण',
    'अपराधभीति',
    'अपराधवृत्ति',
    'अपराधशील',
    'अपराधहीन',
    'अपराधहीनतः',
    'अपराधहीनता',
    'अपराधिता',
    'अपराधियों',
    'अपराधी',
    'अपराधीत्व',
    'अपराधीन',
    'अपराधीनता',
    'अपराधीय',
    'अपराभवनीय',
    'अपराभूत',
    'अपरार्ध',
    'अपरावली',
    'अपरास्त',
    'अपराहन',
    'अपराह्न',
    'अपरिगणनीततः',
    'अपरिगणनीय',
    'अपरिगणनीयता',
    'अपरिगणित',
    'अपरिगत',
    'अपरिगृहीत',
    'अपरिग्रह',
    'अपरिग्रही',
    'अपरिचय',
    'अपरिचित',
    'अपरिचितता',
    'अपरिच्छादित',
    'अपरिज्ञाता',
    'अपरिज्ञान',
    'अपरिणय',
    'अपरिणामदर्शी',
    'अपरिणीत',
    'अपरिणीता',
    'अपरिधानन',
    'अपरिधानित',
    'अपरिनिर्धारित',
    'अपरिपक्व',
    'अपरिपक्वता',
    'अपरिपाचित',
    'अपरिभाषा',
    'अपरिभाषित',
    'अपरिभाषितता',
    'अपरिभाष्य',
    'अपरिभाष्यता',
    'अपरिमापित',
    'अपरिमाप्य',
    'अपरिमित',
    'अपरिमितता',
    'अपरिमेय',
    'अपरिमेयता',
    'अपरिम्लान',
    'अपरिवर्तन',
    'अपरिवर्तनवादी',
    'अपरिवर्तनशील',
    'अपरिवर्तनशीलता',
    'अपरिवर्तनीय',
    'अपरिवर्तनीयता',
    'अपरिवर्तित',
    'अपरिवर्ती',
    'अपरिवार',
    'अपरिवारता',
    'अपरिवृत',
    'अपरिशेष',
    'अपरिशोधित',
    'अपरिश्रम',
    'अपरिश्रमी',
    'अपरिष्कृत',
    'अपरिष्कृतता',
    'अपरिष्कृति',
    'अपरिसीम',
    'अपरिसीमित',
    'अपरिसीमितता',
    'अपरिहारण',
    'अपरिहारित',
    'अपरिहार्य',
    'अपरिहार्यता',
    'अपरीक्षित',
    'अपरुचि',
    'अपरुतुक',
    'अपरुपता',
    'अपरूप',
    'अपरूपण',
    'अपरूपता',
    'अपरूपी',
    'अपरोक्ष',
    'अपरोक्षता',
    'अपरोक्षानुभूति',
    'अपरोध',
    'अपरोधक',
    'अपरोपण',
    'अपर्ण',
    'अपर्णा',
    'अपर्धम',
    'अपर्यत',
    'अपर्याप्त',
    'अपर्याप्तता',
    'अपर्याप्य',
    'अपर्वक',
    'अपर्स्पश',
    'अपलक',
    'अपलन',
    'अपलब्धि',
    'अपलम',
    'अपलाप',
    'अपलापक',
    'अपलाभ',
    'अपलाभिया',
    'अपलाभी',
    'अपलायन',
    'अपलायनशील',
    'अपलाषी',
    'अपलिखित',
    'अपलेख',
    'अपलेखन',
    'अपल्लव',
    'अपल्लवित',
    'अपवंचक',
    'अपवंचकता',
    'अपवंचन',
    'अपवक्ता',
    'अपवचन',
    'अपवजिर्त',
    'अपवर्ग',
    'अपवर्जक',
    'अपवर्जन',
    'अपवर्ण',
    'अपवर्त',
    'अपवर्तक',
    'अपवर्तन',
    'अपवर्तित',
    'अपवल्कित',
    'अपवहन',
    'अपवहित',
    'अपवाक्य',
    'अपवाद',
    'अपवादक',
    'अपवादतः',
    'अपवादपूर्ण',
    'अपवादस्वरुप',
    'अपवादहीन',
    'अपवादहीनतः',
    'अपवादहीनता',
    'अपवादात्मक',
    'अपवादित',
    'अपवादी',
    'अपवारण',
    'अपवाह',
    'अपवाहक',
    'अपवाहन',
    'अपवाहित',
    'अपवाही',
    'अपविकास',
    'अपविक्रय',
    'अपविचार',
    'अपवित्र',
    'अपवित्रकर्ता',
    'अपवित्रता',
    'अपवित्रीकरण',
    'अपविद्युतन',
    'अपवृद्घ',
    'अपवृद्धि',
    'अपवोड़ा',
    'अपव्यय',
    'अपव्ययी',
    'अपव्यूहन',
    'अपशकुन',
    'अपशकुनः',
    'अपशब्द',
    'अपशब्दपूर्ण',
    'अपशब्दों',
    'अपशल्कन',
    'अपशिष्ट',
    'अपशेष',
    'अपशोधन',
    'अपश्री',
    'अपसंवेदन',
    'अपसगुन',
    'अपसमायोजन',
    'अपसर',
    'अपसरण',
    'अपसर्जन',
    'अपसर्पण',
    'अपसव्य',
    'अपसामान्य',
    'अपसामान्यता',
    'अपसार',
    'अपसारक',
    'अपसारण',
    'अपसारित',
    'अपसारी',
    'अपसिद्वांत',
    'अपसृत',
    'अपसृति',
    'अपस्कर',
    'अपस्थानिक',
    'अपस्नान',
    'अपस्फीति',
    'अपस्फीतिकारक',
    'अपस्फीतिकारी',
    'अपस्मार',
    'अपस्मारी',
    'अपस्मृति',
    'अपहत्या',
    'अपहन',
    'अपहनन',
    'अपहरण',
    'अपहरणकर्ता',
    'अपहर्ता',
    'अपहानि',
    'अपहार',
    'अपहारक',
    'अपहारित',
    'अपहारी',
    'अपहास',
    'अपहित',
    'अपहृत',
    'अपहृतज्ञान',
    'अपहृति',
    'अपहेला',
    'अपह्नव',
    'अपह्नुति',
    'अपह्नोता',
    'अपह्रत',
    'अपांग',
    'अपांनाथ',
    'अपांनिधि',
    'अपांपति',
    'अपांशुला',
    'अपाक',
    'अपाकरण',
    'अपाकर्ता',
    'अपाकृत',
    'अपाकृति',
    'अपाखंड',
    'अपाचित',
    'अपाटव',
    'अपाठ',
    'अपाठ्य',
    'अपाठ्यता',
    'अपात्र',
    'अपात्रता',
    'अपाद',
    'अपादान',
    'अपान',
    'अपानन',
    'अपाप',
    'अपाय',
    'अपार',
    'अपारंपरिक',
    'अपारंपरिकता',
    'अपारक्षण',
    'अपारगम्य',
    'अपारगम्यता',
    'अपारण',
    'अपारता',
    'अपारदर्शिता',
    'अपारदर्शी',
    'अपारदृश्यता',
    'अपारप्य',
    'अपारा',
    'अपारित',
    'अपार्टमैंट',
    'अपार्टहीड',
    'अपार्थ',
    'अपार्थित',
    'अपार्य',
    'अपार्यता',
    'अपालंक',
    'अपालक',
    'अपालतू',
    'अपालन',
    'अपावत्तर्न',
    'अपावन',
    'अपावरण',
    'अपावृत्ति',
    'अपाश्रय',
    'अपाश्रित',
    'अपास्त',
    'अपाहरक',
    'अपाहरण',
    'अपाहर्ता',
    'अपाहिज',
    'अपाहिजी',
    'अपाहृत',
    'अपि',
    'अपिंड',
    'अपिकंठ',
    'अपिकंठिका',
    'अपिच',
    'अपिज',
    'अपितकता',
    'अपितु',
    'अपितृक',
    'अपितृकता',
    'अपित्र्य',
    'अपिधानक',
    'अपिधानन',
    'अपिधानित',
    'अपिपासा',
    'अपिपासु',
    'अपिवानर',
    'अपिश्चम',
    'अपीज़मैंट',
    'अपील',
    'अपीलकर्ता',
    'अपीलांट',
    'अपीलार्थी',
    'अपीली',
    'अपीलों',
    'अपुचित',
    'अपुच्छ',
    'अपुनः',
    'अपुनरावृत्त',
    'अपुनरावृत्ति',
    'अपुनर्भूत',
    'अपुपर्जन्म',
    'अपुरातन',
    'अपुरातनता',
    'अपुरुप',
    'अपुरुपण',
    'अपुरुष',
    'अपुरुषता',
    'अपुरुषार्थी',
    'अपुरुषोचित',
    'अपुष्कल',
    'अपुष्ट',
    'अपुष्टि',
    'अपुष्टिकरण',
    'अपुष्टिकृत',
    'अपुष्टीकृत',
    'अपुष्प',
    'अपुष्पकलता',
    'अपुष्पित',
    'अपूणा',
    'अपूत',
    'अपूतता',
    'अपूता',
    'अपून',
    'अपूप',
    'अपूर',
    'अपूर्ण',
    'अपूर्णकाम',
    'अपूर्णच्छ',
    'अपूर्णतः',
    'अपूर्णता',
    'अपूर्णान्वय',
    'अपूर्त',
    'अपूर्ति',
    'अपूर्व',
    'अपूर्वकालीन',
    'अपूर्वतः',
    'अपूर्वतया',
    'अपूर्वता',
    'अपूर्वतापूर्वक',
    'अपूर्वानुमेय',
    'अपृथक',
    'अपृथकता',
    'अपृष्ठवंशी',
    'अपेक्ष',
    'अपेक्षक',
    'अपेक्षणीय',
    'अपेक्षया',
    'अपेक्षा',
    'अपेक्षाएं',
    'अपेक्षाओं',
    'अपेक्षाकृत',
    'अपेक्षाकृततः',
    'अपेक्षात्मक',
    'अपेक्षापूर्ण',
    'अपेक्षित',
    'अपेक्षिततः',
    'अपेक्षी',
    'अपेक्ष्य',
    'अपेत',
    'अपैठ',
    'अपैतृक',
    'अपैतृकता',
    'अपैत्रकता',
    'अपोज़ीशन',
    'अपोजीशन',
    'अपोत्तेजक',
    'अपोत्तेजना',
    'अपोत्तेजित',
    'अपोत्साह',
    'अपोरुषेय',
    'अपोलो',
    'अपोषण',
    'अपोषित',
    'अपोसल',
    'अपोह',
    'अपोहन',
    'अपौराणिक',
    'अपौरुष',
    'अपौरुषेय',
    'अपौरुषेयता',
    'अप्पति',
    'अप्रकट',
    'अप्रकटन',
    'अप्रकटित',
    'अप्रकांड',
    'अप्रकाश',
    'अप्रकाशन',
    'अप्रकाशित',
    'अप्रकृत',
    'अप्रकृति',
    'अप्रकोपक',
    'अप्रखर',
    'अप्रखरता',
    'अप्रगति',
    'अप्रगल्भ',
    'अप्रगल्भतः',
    'अप्रगाढ़ता',
    'अप्रचलन',
    'अप्रचलित',
    'अप्रचुर',
    'अप्रचुरता',
    'अप्रचेतित',
    'अप्रच्छन्न',
    'अप्रच्छन्नता',
    'अप्रच्छिन्नता',
    'अप्रज',
    'अप्रजनन',
    'अप्रजनित',
    'अप्रणेता',
    'अप्रति',
    'अप्रतिकार',
    'अप्रतिकारपूर्ण',
    'अप्रतिकारी',
    'अप्रतिकार्य',
    'अप्रतिकूलता',
    'अप्रतिघात',
    'अप्रतिदत्त',
    'अप्रतिदेय',
    'अप्रतिपक्ष',
    'अप्रतिपत्ति',
    'अप्रतिपन्न',
    'अप्रतिफलता',
    'अप्रतिबंध',
    'अप्रतिबंधक',
    'अप्रतिबंधित',
    'अप्रतिबंधिततः',
    'अप्रतिबद्घ',
    'अप्रतिबद्ध',
    'अप्रतिभ',
    'अप्रतिभाव्य',
    'अप्रतिमता',
    'अप्रतिरुप',
    'अप्रतिरोध',
    'अप्रतिरोधक',
    'अप्रतिरोध्य',
    'अप्रतिवर्तन',
    'अप्रतिवीर',
    'अप्रतिशोध',
    'अप्रतिशोधी',
    'अप्रतिष्ठ',
    'अप्रतिष्ठता',
    'अप्रतिष्ठा',
    'अप्रतिष्ठित',
    'अप्रतिष्ठितता',
    'अप्रतिसंहरणीय',
    'अप्रतीक',
    'अप्रतीक्षा',
    'अप्रतीति',
    'अप्रतुल',
    'अप्रत्यक्ष',
    'अप्रत्यक्षता',
    'अप्रत्यनः',
    'अप्रत्यय',
    'अप्रत्यावर्तन',
    'अप्रत्यावर्तित',
    'अप्रत्याशा',
    'अप्रत्याशित',
    'अप्रत्याशिततः',
    'अप्रत्याशितता',
    'अप्रत्युत्पन्नमति',
    'अप्रथमता',
    'अप्रद',
    'अप्रदत्त',
    'अप्रदाता',
    'अप्रदान',
    'अप्रदेश्यता',
    'अप्रधान',
    'अप्रधानता',
    'अप्रबंध',
    'अप्रबंधित',
    'अप्रबल',
    'अप्रबलता',
    'अप्रभ',
    'अप्रभाज्य',
    'अप्रभाव',
    'अप्रभावकारी',
    'अप्रभावशाली',
    'अप्रभावित',
    'अप्रभावी',
    'अप्रभूत',
    'अप्रमत्त',
    'अप्रमत्तता',
    'अप्रमद',
    'अप्रमा',
    'अप्रमाढ़',
    'अप्रमाणिक',
    'अप्रमाद',
    'अप्रमादी',
    'अप्रमित',
    'अप्रमुख',
    'अप्रमुखता',
    'अप्रमेयता',
    'अप्रमोद',
    'अप्रयत्न',
    'अप्रयत्नतः',
    'अप्रयत्नशील',
    'अप्रयास',
    'अप्रयासी',
    'अप्रयुक्त',
    'अप्रयुक्तत्व',
    'अप्रयोग',
    'अप्रयोग्य',
    'अप्रयोजनता',
    'अप्रयोज्य',
    'अप्रलेखी',
    'अप्रवणता',
    'अप्रवर्तनीय',
    'अप्रवर्ती',
    'अप्रवाह',
    'अप्रवाही',
    'अप्रविष्ट',
    'अप्रवीण',
    'अप्रवीणता',
    'अप्रवृत्त',
    'अप्रवृत्तता',
    'अप्रवृत्ति',
    'अप्रवेश',
    'अप्रवेश्य',
    'अप्रशस्त',
    'अप्रशस्तता',
    'अप्रशिक्षित',
    'अप्रसंग',
    'अप्रसन्न',
    'अप्रसन्नकारी',
    'अप्रसन्नता',
    'अप्रसन्नतापूर्ण',
    'अप्रसव',
    'अप्रसाद',
    'अप्रसामान्य',
    'अप्रसार',
    'अप्रसारित',
    'अप्रसिद्घ',
    'अप्रसिद्धि',
    'अप्रसू',
    'अप्रसूत',
    'अप्रस्तुत',
    'अप्रस्तुतता',
    'अप्रस्तुति',
    'अप्रस्थता',
    'अप्रस्थित',
    'अप्रस्फुटित',
    'अप्रहत',
    'अप्रांगार',
    'अप्राकृत',
    'अप्राकृतिक',
    'अप्राकृतिकता',
    'अप्रागैतिहासिक',
    'अप्रागैतिहासिकता',
    'अप्राचीन',
    'अप्राचीनता',
    'अप्राण',
    'अप्राथमिकता',
    'अप्रादुर्भाव',
    'अप्रादुर्भूत',
    'अप्राधिकृत',
    'अप्राप्त',
    'अप्राप्तता',
    'अप्राप्तभोजन',
    'अप्राप्तमार्ग',
    'अप्राप्तवय',
    'अप्राप्तव्य',
    'अप्राप्ति',
    'अप्राप्य',
    'अप्राप्यता',
    'अप्रामाणिक',
    'अप्रायः',
    'अप्रारंभिक',
    'अप्रावहता',
    'अप्राविधिक',
    'अप्राशन',
    'अप्रासंगिक',
    'अप्रासंगिकता',
    'अप्रिय',
    'अप्रियंवद',
    'अप्रियता',
    'अप्रियदर्शन',
    'अप्रियदर्शनता',
    'अप्रियभाषी',
    'अप्रियवादी',
    'अप्रीति',
    'अप्रीतिकर',
    'अप्रेक्ष्य',
    'अप्रेत',
    'अप्रेमय',
    'अप्रेय',
    'अप्रैल',
    'अप्रोत्साहन',
    'अप्रौढ़',
    'अप्रौढ़धी',
    'अप्र्य',
    'अप्ल',
    'अप्सरा',
    'अप्सराः',
    'अप्सराधिपति',
    'अप्सरान्माद',
    'अप्सरेश',
    'अप्सरोन्माद',
    'अफगानी',
    'अफज़ाही',
    'अफरना',
    'अफरा',
    'अफल',
    'अफलातूनवाद',
    'अफलातूनी',
    'अफलित',
    'अफवाह',
    'अफसर',
    'अफसरशाही',
    'अफसोस',
    'अफ़गन',
    'अफ़जाही',
    'अफ़रातफ़री',
    'अफ़राब',
    'अफ़वाह',
    'अफ़सर',
    'अफ़सरी',
    'अफ़साना',
    'अफ़सानानिगार',
    'अफ़सोस',
    'अफ़ीका',
    'अफ़ीकी',
    'अफ़ीम',
    'अफ़ीमची',
    'अफारा',
    'अफीम',
    'अफीमः',
    'अफीमची',
    'अफुल्ल',
    'अफ्',
    'अफ्रग़ानिस्तान',
    'अफ्रयून',
    'अफ्रयूनी',
    'अफ्रिका',
    'अफ्रीका',
    'अफ्रीकी',
    'अफ्रीमची',
    'अब',
    'अबंधन',
    'अबंधु',
    'अबंधुत्व',
    'अबतर',
    'अबतरी',
    'अबद्घ',
    'अबद्धता',
    'अबरक',
    'अबरस',
    'अबरा',
    'अबरी',
    'अबरीदार',
    'अबरु',
    'अबल',
    'अबलक़',
    'अबला',
    'अबलिष्ठ',
    'अबलिष्ठता',
    'अबलों',
    'अबहुँ',
    'अबहुल',
    'अबहुलता',
    'अबा',
    'अबांधव',
    'अबाकस',
    'अबाध',
    'अबाधता',
    'अबाधित',
    'अबाधितता',
    'अबान',
    'अबाबील',
    'अबारंबार',
    'अबारंबारता',
    'अबारी',
    'अबाहु',
    'अबीज',
    'अबीजा',
    'अबीर',
    'अबुंद',
    'अबुधित',
    'अबुभुक्षा',
    'अबू',
    'अबूझ',
    'अबे',
    'अबेचनीय',
    'अबेर',
    'अबोध',
    'अबोधगम्य',
    'अबोध्य',
    'अबोध्यता',
    'अबोला',
    'अबोलापन',
    'अब्ज',
    'अब्जज',
    'अब्जद',
    'अब्जनयना',
    'अब्जयोनि',
    'अब्जलोचना',
    'अब्जवाहना',
    'अब्जा',
    'अब्जाद',
    'अब्द',
    'अब्दकोश',
    'अब्दुल',
    'अब्धि',
    'अब्धिज',
    'अब्धिजा',
    'अब्धिफेन',
    'अब्धिशयन',
    'अब्बा',
    'अब्बू',
    'अब्याजी',
    'अब्र',
    'अब्रम',
    'अब्राह्म',
    'अभंग',
    'अभंगता',
    'अभंजनशील',
    'अभंजनीय',
    'अभंजित',
    'अभक्ष्य',
    'अभग्न',
    'अभच्यता',
    'अभद्र',
    'अभद्रता',
    'अभय',
    'अभयकरी',
    'अभयद',
    'अभयप्रद',
    'अभयभीत',
    'अभयवन',
    'अभया',
    'अभयारण्य',
    'अभव',
    'अभविष्णु',
    'अभव्य',
    'अभागा',
    'अभागापन',
    'अभागों',
    'अभाग्य',
    'अभारण',
    'अभारतीय',
    'अभारयुक्त',
    'अभारित',
    'अभाव',
    'अभावग्रस्त',
    'अभावग्रस्तता',
    'अभावना',
    'अभाववाद',
    'अभाववाले',
    'अभावहीन',
    'अभावहीनता',
    'अभावात्मक',
    'अभावुक',
    'अभावुकता',
    'अभाषण',
    'अभाषित',
    'अभासित',
    'अभिंसतान',
    'अभिक',
    'अभिकथन',
    'अभिकथनीय',
    'अभिकथित',
    'अभिकन्या',
    'अभिकरण',
    'अभिकर्ता',
    'अभिकर्त्ता',
    'अभिकलन',
    'अभिकलित्र',
    'अभिकल्प',
    'अभिकल्पक',
    'अभिकल्पन',
    'अभिकल्पना',
    'अभिकल्पनीय',
    'अभिकल्पित',
    'अभिकाम',
    'अभिकेंंद्रीय',
    'अभिकेंद्र',
    'अभिकेन्द्री',
    'अभिक्रमण',
    'अभिक्रांत',
    'अभिक्रामक',
    'अभिक्रामित',
    'अभिक्रिया',
    'अभिक्रोश',
    'अभिक्षमता',
    'अभिखंडन',
    'अभिखंडित',
    'अभिखनन',
    'अभिगम',
    'अभिगमन',
    'अभिगृहीत',
    'अभिगृहीतीय',
    'अभिगोप्ता',
    'अभिग्रह',
    'अभिग्रहण',
    'अभिग्रहीत',
    'अभिग्रहीता',
    'अभिग्राही',
    'अभिचर',
    'अभिचार',
    'अभिचारक',
    'अभिचारिका',
    'अभिचारी',
    'अभिजन',
    'अभिजनक',
    'अभिजात',
    'अभिजातीय',
    'अभिजात्य',
    'अभिजित',
    'अभिज्ञ',
    'अभिज्ञता',
    'अभिज्ञा',
    'अभिज्ञात',
    'अभिज्ञान',
    'अभिज्ञानी',
    'अभिज्ञापन',
    'अभिज्ञेय',
    'अभित्यजन',
    'अभित्रस्त',
    'अभित्रास',
    'अभिदत्त',
    'अभिदान',
    'अभिदिशा',
    'अभिदेय',
    'अभिधम्म',
    'अभिधा',
    'अभिधात्मक',
    'अभिधान',
    'अभिधायक',
    'अभिधारण',
    'अभिधारणा',
    'अभिधारी',
    'अभिधार्थ',
    'अभिधृति',
    'अभिधेय',
    'अभिधेयार्थ',
    'अभिधेयार्थवाद',
    'अभिनंदक',
    'अभिनंदन',
    'अभिनंदना',
    'अभिनंदनीय',
    'अभिनंदित',
    'अभिनंदी',
    'अभिनंद्य',
    'अभिनत',
    'अभिनतता',
    'अभिनति',
    'अभिनन्दन',
    'अभिनय',
    'अभिनयन',
    'अभिनव',
    'अभिनवता',
    'अभिनहन',
    'अभिनाल',
    'अभिनियम',
    'अभिनियोजन',
    'अभिनिर्णायक',
    'अभिनिर्णीत',
    'अभिनिर्धारण',
    'अभिनिवृर्त्ति',
    'अभिनिवेश',
    'अभिनिश्चित',
    'अभिनिषिद्ध',
    'अभिनिष्कम',
    'अभिनिष्क्रमण',
    'अभिनिष्पत्ति',
    'अभिनिष्पन्न',
    'अभिनीत',
    'अभिनीय',
    'अभिनेतव्य',
    'अभिनेता',
    'अभिनेताओं',
    'अभिनेत्री',
    'अभिनेय',
    'अभिन्न',
    'अभिन्नता',
    'अभिन्नहृदयता',
    'अभिपिता',
    'अभिपुत्र',
    'अभिपुत्री',
    'अभिपुष्ट',
    'अभिपुष्टि',
    'अभिप्राणन',
    'अभिप्राप्ति',
    'अभिप्राय',
    'अभिप्रायतः',
    'अभिप्रेत',
    'अभिप्रेय',
    'अभिप्रेरण',
    'अभिप्रेरणा',
    'अभिप्लव',
    'अभिबंधन',
    'अभिभावक',
    'अभिभावकता',
    'अभिभावकहीन',
    'अभिभाविका',
    'अभिभावित',
    'अभिभावी',
    'अभिभाषण',
    'अभिभूत',
    'अभिभूतकर्ता',
    'अभिभूतता',
    'अभिभूति',
    'अभिमंचन',
    'अभिमंचित',
    'अभिमंडन',
    'अभिमंडित',
    'अभिमंत्रण',
    'अभिमंत्रित',
    'अभिमत',
    'अभिमतहीन',
    'अभिमति',
    'अभिमन्यु',
    'अभिमन्युः',
    'अभिमरण',
    'अभिमाता',
    'अभिमाद',
    'अभिमान',
    'अभिमानपूर्ण',
    'अभिमानपूर्वक',
    'अभिमानहीन',
    'अभिमानहीनता',
    'अभिमानिनी',
    'अभिमानी',
    'अभिमार्जन',
    'अभिमुख',
    'अभिमुखतः',
    'अभिमुखता',
    'अभिमुखीकरण',
    'अभियंता',
    'अभियन्ता',
    'अभियांत्रिक',
    'अभियांत्रिकी',
    'अभियाचक',
    'अभियाचना',
    'अभियाता',
    'अभियात्रा',
    'अभियान',
    'अभियानी',
    'अभियुक्त',
    'अभियुक्ता',
    'अभियुक्ति',
    'अभियुक्तों',
    'अभियुग्व',
    'अभियोक्ता',
    'अभियोग',
    'अभियोगाधीन',
    'अभियोगी',
    'अभियोजक',
    'अभियोजन',
    'अभियोजनीय',
    'अभियोज्य',
    'अभियोज्यता',
    'अभिरक्षक',
    'अभिरक्षकता',
    'अभिरक्षण',
    'अभिरक्षणीय',
    'अभिरक्षा',
    'अभिरक्षित',
    'अभिरक्षिता',
    'अभिरचना',
    'अभिरत',
    'अभिरतता',
    'अभिरति',
    'अभिराम',
    'अभिरामता',
    'अभिरामी',
    'अभिरुचि',
    'अभिरुप',
    'अभिरूचि',
    'अभिलक्षण',
    'अभिलक्षित',
    'अभिलम्ब',
    'अभिलम्बीय',
    'अभिलषित',
    'अभिलाप',
    'अभिलाभ',
    'अभिलाव',
    'अभिलाषक',
    'अभिलाषणीय',
    'अभिलाषा',
    'अभिलाषापूर्ण',
    'अभिलाषाहीन',
    'अभिलाषाहीनतः',
    'अभिलाषाहीनता',
    'अभिलाषी',
    'अभिलाष्य',
    'अभिलिखित',
    'अभिलिखितता',
    'अभिलेख',
    'अभिलेखक',
    'अभिलेखदाता',
    'अभिलेखन',
    'अभिलेखपाल',
    'अभिलेखबद्ध',
    'अभिलेखागार',
    'अभिलेखाध्यक्ष',
    'अभिलेखित',
    'अभिलेखी',
    'अभिलेखों',
    'अभिवंचित',
    'अभिवंदक',
    'अभिवंदन',
    'अभिवंदना',
    'अभिवंदित',
    'अभिवक्ता',
    'अभिवचन',
    'अभिवर्तन',
    'अभिवर्धक',
    'अभिवर्धन',
    'अभिवर्धित',
    'अभिवर्धी',
    'अभिवाक',
    'अभिवाचक',
    'अभिवाचन',
    'अभिवाचित',
    'अभिवादक',
    'अभिवादन',
    'अभिवादयिता',
    'अभिवादित',
    'अभिवादी',
    'अभिवानर',
    'अभिविन्यास',
    'अभिवृति',
    'अभिवृत्तिकरण',
    'अभिवृद्धि',
    'अभिवेचक',
    'अभिवेचन',
    'अभिवेदक',
    'अभिवेदन',
    'अभिवेदनों',
    'अभिवेदित',
    'अभिव्यंजक',
    'अभिव्यंजकता',
    'अभिव्यंजन',
    'अभिव्यंजना',
    'अभिव्यंजनावाद',
    'अभिव्यंजनावादी',
    'अभिव्यक्त',
    'अभिव्यक्ति',
    'अभिव्यक्तिपूर्ण',
    'अभिव्यक्तिवाद',
    'अभिव्यापक',
    'अभिव्यापन',
    'अभिव्यिक्त',
    'अभिशंका',
    'अभिशंसन',
    'अभिशप्त',
    'अभिशस्ति',
    'अभिशाप',
    'अभिशापन',
    'अभिशापपूर्ण',
    'अभिशापित',
    'अभिशासन',
    'अभिशीर्ष',
    'अभिशून्यन',
    'अभिषंग',
    'अभिषव',
    'अभिषिक्त',
    'अभिषेक',
    'अभिसंधान',
    'अभिसंधि',
    'अभिसंस्करण',
    'अभिसक्त',
    'अभिसत्र',
    'अभिसत्राधिनियम',
    'अभिसमय',
    'अभिसम्मत',
    'अभिसरित',
    'अभिसर्ग',
    'अभिसव',
    'अभिसाक्षी',
    'अभिसाक्ष्य',
    'अभिसार',
    'अभिसारिका',
    'अभिसारी',
    'अभिसिंचन',
    'अभिसूचन',
    'अभिसूचना',
    'अभिसृष्टि',
    'अभिस्त्रावक',
    'अभिस्त्रावण',
    'अभिस्त्रावणी',
    'अभिस्थापन',
    'अभिस्वीकरण',
    'अभिस्वीकृत',
    'अभिस्वीकृति',
    'अभिहरण',
    'अभिहव',
    'अभिहवन',
    'अभिहस्तांतर',
    'अभिहस्तांतरण',
    'अभिहस्तान्तरित',
    'अभिहार',
    'अभिहारी',
    'अभिहित',
    'अभिहूत',
    'अभिहृत',
    'अभी',
    'अभीक',
    'अभीत',
    'अभीप्सा',
    'अभीप्सु',
    'अभीरू',
    'अभीरूता',
    'अभीष्ट',
    'अभीष्टद',
    'अभुंगर',
    'अभुंगरता',
    'अभुक्त',
    'अभुक्तता',
    'अभुक्तपूर्व',
    'अभुक्तभोगी',
    'अभुगतान',
    'अभुज',
    'अभूत',
    'अभूतपूर्व',
    'अभूतपूर्वतः',
    'अभूतपूर्वता',
    'अभूमि',
    'अभूरि',
    'अभृतक',
    'अभेद',
    'अभेदक',
    'अभेद्य',
    'अभेद्यता',
    'अभोक्तव्य',
    'अभोक्ता',
    'अभोग',
    'अभोगा',
    'अभोगी',
    'अभोग्य',
    'अभोजन',
    'अभोजित',
    'अभोजिततः',
    'अभोज्य',
    'अभौतिक',
    'अभौतिकता',
    'अभौतिकवाद',
    'अभ्यंजन',
    'अभ्यंतर',
    'अभ्यंतरक',
    'अभ्यंतरीय',
    'अभ्यथ्र्य',
    'अभ्यन्तर',
    'अभ्यमन',
    'अभ्यर्चन',
    'अभ्यर्थक',
    'अभ्यर्थन',
    'अभ्यर्थना',
    'अभ्यर्थनीय',
    'अभ्यर्थित',
    'अभ्यर्थिता',
    'अभ्यर्थी',
    'अभ्यर्पक',
    'अभ्यर्पण',
    'अभ्यर्पयिता',
    'अभ्यर्पित',
    'अभ्यर्पिती',
    'अभ्यसित',
    'अभ्यसूय',
    'अभ्यस्त',
    'अभ्यस्तता',
    'अभ्याकांक्षा',
    'अभ्यागत',
    'अभ्यागति',
    'अभ्यागम',
    'अभ्यागमन',
    'अभ्यागारिक',
    'अभ्यागारिणी',
    'अभ्यावेदक',
    'अभ्यावेदन',
    'अभ्यास',
    'अभ्यासहीन',
    'अभ्यासहीनता',
    'अभ्यासी',
    'अभ्याहत',
    'अभ्युक्ति',
    'अभ्युक्तियां',
    'अभ्युच्छूय',
    'अभ्युत्थान',
    'अभ्युत्थित',
    'अभ्युदय',
    'अभ्युदाहरण',
    'अभ्युदित',
    'अभ्युद्गमन',
    'अभ्युद्घात',
    'अभ्युद्देश',
    'अभ्युद्देशक',
    'अभ्युद्देशित',
    'अभ्युपगमन',
    'अभ्युपेत',
    'अभ्र',
    'अभ्रक',
    'अभ्रनाग',
    'अभ्रम',
    'अभ्रमणशील',
    'अभ्रमित',
    'अभ्रमु',
    'अभ्ररोह',
    'अभ्रष्ट',
    'अभ्रांत',
    'अभ्रांति',
    'अभ्रावकाशी',
    'अभ्रित',
    'अभ्षक्ति',
    'अमंगल',
    'अमंगलकारिता',
    'अमंगलकारी',
    'अमंगल्य',
    'अमंचनीय',
    'अमंच्य',
    'अमंडित',
    'अमंद',
    'अमंदता',
    'अमगंल',
    'अमजोद',
    'अमड़ा',
    'अमत',
    'अमतांध',
    'अमतांधत',
    'अमति',
    'अमत्त',
    'अमत्र्य',
    'अमत्र्यता',
    'अमत्सर',
    'अमदन',
    'अमदोन्मत्त',
    'अमन',
    'अमनपंसद',
    'अमनपंसदी',
    'अमनस्क',
    'अमनस्कता',
    'अमना',
    'अमनिया',
    'अमनीषी',
    'अमनुजता',
    'अमनुष्यता',
    'अमनैकी',
    'अमनो',
    'अमनोज्ञ',
    'अमनोयोग',
    'अमनोरंजकता',
    'अमम',
    'अममता',
    'अमय',
    'अमर',
    'अमरण',
    'अमरता',
    'अमरत्व',
    'अमरदास',
    'अमरपुरी',
    'अमरपुष्प',
    'अमरस',
    'अमराई',
    'अमरागंना',
    'अमराचार्य',
    'अमराद्रि',
    'अमराधिप',
    'अमरापगा',
    'अमरारि',
    'अमरालय',
    'अमराव',
    'अमरावती',
    'अमरी',
    'अमरीकर',
    'अमरीका',
    'अमरीकी',
    'अमरुत',
    'अमरुद',
    'अमरूद',
    'अमरेश्वर',
    'अमर्दित',
    'अमर्याद',
    'अमर्यादतः',
    'अमर्यादता',
    'अमर्यादा',
    'अमर्यादितता',
    'अमर्ष',
    'अमर्षी',
    'अमल',
    'अमलतास',
    'अमलदार',
    'अमलदारी',
    'अमला',
    'अमलासाज़',
    'अमलिन',
    'अमली',
    'अमसृणता',
    'अमस्तु',
    'अमहत्त्वाकांक्षा',
    'अमहत्त्वाकांक्षी',
    'अमहत्व',
    'अमहा',
    'अमहाविद्यालीय',
    'अमा',
    'अमांगलिक',
    'अमांस',
    'अमांसक',
    'अमाजिर्त',
    'अमाजिर्तता',
    'अमातृक',
    'अमातृता',
    'अमात्य',
    'अमात्र',
    'अमात्रता',
    'अमात्रा',
    'अमात्सर्य',
    'अमादक',
    'अमान',
    'अमानक',
    'अमानत',
    'अमानतदार',
    'अमानती',
    'अमानव',
    'अमानवता',
    'अमानवीय',
    'अमानवीयता',
    'अमानवीयतापूर्ण',
    'अमानवोचित',
    'अमानवोचितता',
    'अमानिया',
    'अमानी',
    'अमानीदार',
    'अमानीदारी',
    'अमानुष',
    'अमानुषिक',
    'अमानुषिकता',
    'अमानुषीकरण',
    'अमानुषीय',
    'अमान्य',
    'अमान्यकरण',
    'अमान्यता',
    'अमापनीय',
    'अमापित',
    'अमाप्य',
    'अमाय',
    'अमायिक',
    'अमायी',
    'अमारण',
    'अमारणीय',
    'अमार्ग',
    'अमावट',
    'अमावस',
    'अमावस्या',
    'अमाहात्म्य',
    'अमिट',
    'अमिटता',
    'अमित',
    'अमितव्ययी',
    'अमिताचारी',
    'अमिताभ',
    'अमिताशन',
    'अमिति',
    'अमितौजा',
    'अमित्र',
    'अमित्रघाती',
    'अमित्रता',
    'अमिथुनक',
    'अमिथ्या',
    'अमिय',
    'अमिल',
    'अमिलन',
    'अमिलित',
    'अमिश्र',
    'अमिश्रण',
    'अमिश्रतता',
    'अमिश्रता',
    'अमिश्रित',
    'अमिश्रितता',
    'अमिष',
    'अमिहति',
    'अमीत',
    'अमीन',
    'अमीबा',
    'अमीबाणु',
    'अमीबीय',
    'अमीर',
    'अमीरज़ादी',
    'अमीराना',
    'अमीरी',
    'अमीरूल',
    'अमुक',
    'अमुकता',
    'अमुकुलित',
    'अमुकुलितता',
    'अमुक्त',
    'अमुखर',
    'अमुखरता',
    'अमुखरित',
    'अमुख्य',
    'अमुग्ध',
    'अमुत्र',
    'अमुत्रत्य',
    'अमुद्रीकृत',
    'अमुसलिम',
    'अमूक',
    'अमूढ़',
    'अमूत्तर्',
    'अमूमन',
    'अमूर्त',
    'अमूर्तता',
    'अमूर्तत्व',
    'अमूर्तदाय',
    'अमूर्ति',
    'अमूर्तिकरण',
    'अमूल',
    'अमूलता',
    'अमूल्य',
    'अमूल्यता',
    'अमृणाल',
    'अमृत',
    'अमृतकौर',
    'अमृतगर्भ',
    'अमृतदान',
    'अमृतप',
    'अमृतबान',
    'अमृतवपु',
    'अमृतसर',
    'अमृता',
    'अमृतांध',
    'अमृताश',
    'अमृताशं',
    'अमृताशी',
    'अमृताहरण',
    'अमृतेशय',
    'अमृतेश्वर',
    'अमृतेश्वरी',
    'अमृत्यरतः',
    'अमृत्यु',
    'अमृत्व',
    'अमृदुता',
    'अमेदस्क',
    'अमेदस्कता',
    'अमेदस्किनी',
    'अमेय',
    'अमेरिकन',
    'अमेरिका',
    'अमेरिकी',
    'अमोक्ष',
    'अमोघ',
    'अमोघा',
    'अमोच्य',
    'अमोद',
    'अमोनियम',
    'अमोरी',
    'अमोल',
    'अमोह',
    'अमोहित',
    'अमोही',
    'अमोह्य',
    'अमौन',
    'अमौनत्व',
    'अमौलिक',
    'अम्पायर',
    'अम्बर',
    'अम्बरिका',
    'अम्बारी',
    'अम्बिका',
    'अम्म',
    'अम्मय',
    'अम्मा',
    'अम्मारी',
    'अम्मी',
    'अम्मौं',
    'अम्ल',
    'अम्लक्षार',
    'अम्लता',
    'अम्लनाशक',
    'अम्लशाक',
    'अम्लसार',
    'अम्लाधिक्य',
    'अम्लान',
    'अम्लानता',
    'अम्लामय',
    'अम्लीकरण',
    'अम्लीय',
    'अम्हौरी',
    'अयंत्रचालन',
    'अयंत्रित',
    'अयंत्रीकृत',
    'अयः',
    'अयःशंकु',
    'अयतार्थता',
    'अयति',
    'अयत्न',
    'अयत्नज',
    'अयथा',
    'अयथापूर्व',
    'अयथार्थ',
    'अयथार्थता',
    'अयथेष्ट',
    'अयथेष्टता',
    'अयन',
    'अयनांत',
    'अयव',
    'अयश',
    'अयशस्वी',
    'अयशी',
    'अयस',
    'अयस्क',
    'अयस्कांत',
    'अयस्कांतता',
    'अयस्कार',
    'अयाँ',
    'अयाचिततः',
    'अयातयाम',
    'अयातयामता',
    'अयात्रा',
    'अयान',
    'अयानगी',
    'अयाना',
    'अयानापन',
    'अयापित',
    'अयाल',
    'अयालदार',
    'अयाली',
    'अयास',
    'अयुक्त',
    'अयुक्तता',
    'अयुक्ति',
    'अयुक्तियुक्त',
    'अयुक्तियुक्तता',
    'अयुगपद',
    'अयुगल',
    'अयुग्म',
    'अयुग्मकता',
    'अयुग्मनेत्र',
    'अयुज',
    'अयुत',
    'अयुति',
    'अयुद्घ',
    'अयुध्य',
    'अयुम्मशर',
    'अयूरोपीय',
    'अयो',
    'अयोग',
    'अयोग्य',
    'अयोग्यता',
    'अयोचन',
    'अयोजित',
    'अयोध',
    'अयोधी',
    'अयोधेय',
    'अयोध्य',
    'अयोध्यता',
    'अयोध्या',
    'अयोनि',
    'अयोनिज',
    'अयोनिजा',
    'अयोमणि',
    'अयोमल',
    'अयोमुख',
    'अयौक्तिक',
    'अयौगिक',
    'अयौगिकता',
    'अय्यार',
    'अय्यारी',
    'अय्याशी',
    'अर',
    'अरंग',
    'अरंगर',
    'अरंजित',
    'अरंड',
    'अरंडी',
    'अरक',
    'अरकटी',
    'अरक़',
    'अरकाटी',
    'अरक्त',
    'अरक्षा',
    'अरक्षित',
    'अरक्षितता',
    'अरगंजा',
    'अरग़वानी',
    'अरग्रगामी',
    'अरघा',
    'अरचना',
    'अरचित',
    'अरचेता',
    'अरज',
    'अरजिस्ट्रीकृत',
    'अरण',
    'अरणि',
    'अरण्य',
    'अरण्यवास',
    'अरण्यानी',
    'अरण्यायन',
    'अरण्यीय',
    'अरत',
    'अरतता',
    'अरति',
    'अरथ',
    'अरथी',
    'अरदन',
    'अरदास',
    'अरना',
    'अरब',
    'अरबिन्द',
    'अरबी',
    'अरमण',
    'अरमणीय',
    'अरमान',
    'अरम्यता',
    'अरर',
    'अरराई',
    'अरराना',
    'अररू',
    'अरलोन',
    'अरविंद',
    'अरविंदनयन',
    'अरविंदनयना',
    'अरविंदयोनि',
    'अरविंदिनी',
    'अरविन्द',
    'अरवी',
    'अरस',
    'अरसज्ञ',
    'अरसना',
    'अरसा',
    'अरसात्मक',
    'अरसिक',
    'अरसिकता',
    'अरहट्ठ',
    'अरहन',
    'अरहर',
    'अरहित',
    'अरा',
    'अराअरी',
    'अराग',
    'अरागात्मक',
    'अरागी',
    'अराजक',
    'अराजकता',
    'अराजकतापूर्ण',
    'अराजपत्रित',
    'अराज़ी',
    'अराति',
    'अराद्घ',
    'अराबा',
    'अरारुट',
    'अरारोट',
    'अराल',
    'अरावल',
    'अरावली',
    'अराष्ट्रीयकरण',
    'अरि',
    'अरिंद',
    'अरिंदम',
    'अरिघ्न',
    'अरित्न',
    'अरित्र',
    'अरिदमन',
    'अरिमर्द',
    'अरिमर्दन',
    'अरिया',
    'अरियाना',
    'अरिष्ट',
    'अरिष्टनेमि',
    'अरिसूदन',
    'अरिहन',
    'अरिहा',
    'अरिहीन',
    'अरीकरण',
    'अरीठा',
    'अरुंधती',
    'अरुग्णता',
    'अरुचि',
    'अरुचिकता',
    'अरुचिकर',
    'अरुढ',
    'अरुढिगत',
    'अरुढिगतता',
    'अरुण',
    'अरुणिमा',
    'अरुणोदय',
    'अरुप',
    'अरुपता',
    'अरुपांयित',
    'अरुपित',
    'अरुरु',
    'अरू',
    'अरूंतुद',
    'अरूंधती',
    'अरूकन',
    'अरूक्ष',
    'अरूग्ण',
    'अरूचि',
    'अरूचिकर',
    'अरूचिकरता',
    'अरूच्च',
    'अरूण',
    'अरूणचुड',
    'अरूणता',
    'अरूणनेत्र',
    'अरूणसारथी',
    'अरूणा',
    'अरूणाचल',
    'अरूणात्मज',
    'अरूणात्मजा',
    'अरूणाश्व',
    'अरूणि',
    'अरूणिम',
    'अरूणिमा',
    'अरूणोदय',
    'अरूधिर',
    'अरूष',
    'अरूषी',
    'अरूष्कर',
    'अरेखित',
    'अरैक्ट',
    'अरैखिक',
    'अरोग्यता',
    'अरोचक',
    'अरोचकता',
    'अरोड़ा',
    'अरोधित',
    'अरोम',
    'अरौद्र',
    'अर्क',
    'अर्ककांता',
    'अर्कज',
    'अर्कजा',
    'अर्कनयन',
    'अर्कबंधु',
    'अर्कभक्ता',
    'अर्करिपु',
    'अर्करेज़ी',
    'अर्कसुत',
    'अर्कसोदर',
    'अर्किचन',
    'अर्किचनता',
    'अर्गल',
    'अर्गला',
    'अर्घ',
    'अर्घदान',
    'अर्घहीनता',
    'अर्घापचय',
    'अर्घेश्वर',
    'अर्चक',
    'अर्चना',
    'अर्चनीय',
    'अर्चा',
    'अर्ची',
    'अर्ज',
    'अर्जक',
    'अर्जन',
    'अर्जनजन्य',
    'अर्जनशील',
    'अर्जनशीलता',
    'अर्जनहीन',
    'अर्जनहीनता',
    'अर्जनीय',
    'अर्जित',
    'अर्जी',
    'अर्जीदार',
    'अर्जुन',
    'अर्जुनः',
    'अर्जुनोपदेश',
    'अर्जुनोपम',
    'अर्जेंट',
    'अर्ण',
    'अर्णव',
    'अर्णवोद्भवा',
    'अर्णा',
    'अर्ति',
    'अर्तुन',
    'अर्थ',
    'अर्थक',
    'अर्थकर',
    'अर्थकाम',
    'अर्थकोश',
    'अर्थक्षम',
    'अर्थक्षमता',
    'अर्थगत',
    'अर्थगर्भित',
    'अर्थग्राम',
    'अर्थच्छयित',
    'अर्थच्छाया',
    'अर्थतंत्र',
    'अर्थतः',
    'अर्थतत्व',
    'अर्थतन्त्र',
    'अर्थद',
    'अर्थदंड',
    'अर्थदण्ड',
    'अर्थदण्डनीय',
    'अर्थना',
    'अर्थपति',
    'अर्थपूर्ण',
    'अर्थपूर्णता',
    'अर्थपूर्वक',
    'अर्थप्रबन्ध',
    'अर्थमिति',
    'अर्थमितिपरक',
    'अर्थयुक्त',
    'अर्थलिप्सा',
    'अर्थवत्ता',
    'अर्थविज्ञान',
    'अर्थव्यक्ति',
    'अर्थव्यवस्था',
    'अर्थव्यस्था',
    'अर्थशास्त्र',
    'अर्थशास्त्री',
    'अर्थशास्त्रीय',
    'अर्थसुलभ',
    'अर्थहीन',
    'अर्थहीनता',
    'अर्थागम',
    'अर्थात',
    'अर्थातर',
    'अर्थाथी',
    'अर्थाधिकारी',
    'अर्थान्वित',
    'अर्थापकर्ष',
    'अर्थापति',
    'अर्थापत्ति',
    'अर्थापत्र',
    'अर्थालंकार',
    'अर्थावली',
    'अर्थिंग',
    'अर्थित',
    'अर्थी',
    'अर्थीय',
    'अर्थे',
    'अर्थेच्छा',
    'अर्थेतर',
    'अर्थोत्कर्षक',
    'अर्थोपक्षेपक',
    'अर्दन',
    'अर्दना',
    'अर्दनि',
    'अर्दली',
    'अर्दित',
    'अर्द्ध',
    'अर्द्धचन्द्र',
    'अर्द्धनिद्रा',
    'अर्द्धवार्षिक',
    'अर्द्धशासकीय',
    'अर्द्धसूत्री',
    'अर्द्धस्वर',
    'अर्ध',
    'अर्धकुशल',
    'अर्धकृत',
    'अर्धघातक',
    'अर्धचंद्राकार',
    'अर्धचंद्राकारता',
    'अर्धचन्द्र',
    'अर्धचालक',
    'अर्धचेतन',
    'अर्धता',
    'अर्धदैनिक',
    'अर्धन',
    'अर्धनग्न',
    'अर्धनत',
    'अर्धनरनारी',
    'अर्धनारी',
    'अर्धनारीनर',
    'अर्धनारीश',
    'अर्धनारीश्वर',
    'अर्धनारीश्वरी',
    'अर्धनिर्मित',
    'अर्धपारदर्शी',
    'अर्धप्रवासी',
    'अर्धबुद्धि',
    'अर्धमासिक',
    'अर्धमूल्य',
    'अर्धरिक्त',
    'अर्धवार्षिक',
    'अर्धविराम',
    'अर्धवृत',
    'अर्धवृत्ताकार',
    'अर्धव्यास',
    'अर्धशासकीय',
    'अर्धसरकारी',
    'अर्धसैद्धान्तिक',
    'अर्धस्थायी',
    'अर्धस्वर',
    'अर्धांग',
    'अर्धांगिनी',
    'अर्धांश',
    'अर्धागी',
    'अर्धानुनासिक',
    'अर्धान्ग',
    'अर्धायु',
    'अर्धार्ध',
    'अर्धाली',
    'अर्धावस्था',
    'अर्धाश',
    'अर्धाश्ता',
    'अर्धिक',
    'अर्धीकरण',
    'अर्धेदुमौलि',
    'अर्न्ड',
    'अर्न्तग्रथन',
    'अर्पक',
    'अर्पण',
    'अर्पणा',
    'अर्पणीय',
    'अर्पयिता',
    'अर्पाप्त',
    'अर्पित',
    'अर्बजा',
    'अर्बुद',
    'अर्बुद्घ',
    'अर्भक',
    'अर्य',
    'अर्यमा',
    'अर्या',
    'अर्याणी',
    'अर्यी',
    'अर्वट',
    'अर्वती',
    'अर्वाक्',
    'अर्वाचीन',
    'अर्वाचीनता',
    'अर्श',
    'अर्शी',
    'अर्ह',
    'अर्हणा',
    'अर्हत',
    'अर्हता',
    'अर्हतासंपन्न',
    'अर्हनाथ',
    'अर्हा',
    'अर्हित',
    'अल',
    'अलं',
    'अलंक',
    'अलंकरण',
    'अलंकरोति',
    'अलंकार',
    'अलंकारपूर्ण',
    'अलंकारपूर्णता',
    'अलंकारहीन',
    'अलंकारहीनता',
    'अलंकारी',
    'अलंकृत',
    'अलंकृतपट',
    'अलंघनीय',
    'अलंघ्य',
    'अलंपट',
    'अलंबुष',
    'अलंबुषा',
    'अलक',
    'अलकनंदा',
    'अलकप्रभा',
    'अलका',
    'अलकापुरी',
    'अलकावली',
    'अलकेश',
    'अलकेश्वर',
    'अलकोहल',
    'अलक्त',
    'अलक्तक',
    'अलक्रत',
    'अलक्षण',
    'अलक्षित',
    'अलक्ष्मी',
    'अलक्ष्य',
    'अलख',
    'अलग',
    'अलगनी',
    'अलगाना',
    'अलगानी',
    'अलगाव',
    'अलगाववाद',
    'अलगाववादी',
    'अलगौझा',
    'अलग्न',
    'अलग्नता',
    'अलघुकरणीय',
    'अलजबा',
    'अलज्ज',
    'अलट',
    'अलटा',
    'अलपकालीन',
    'अलपबुद्धि',
    'अलबत्ता',
    'अलबम',
    'अलबी',
    'अलबेट',
    'अलबेत्ता',
    'अलबेलापन',
    'अलब्ध',
    'अलब्धि',
    'अलभ्य',
    'अलभ्यता',
    'अलम',
    'अलमक',
    'अलमनाक',
    'अलमस्त',
    'अलमस्ती',
    'अलमारी',
    'अलमारीः',
    'अलय',
    'अलर्क',
    'अलल',
    'अललटप्पू',
    'अललाम',
    'अललित',
    'अलवणीय',
    'अलवाई',
    'अलवाल',
    'अलविदा',
    'अलवौती',
    'अलस',
    'अलसगामी',
    'अलसता',
    'अलसना',
    'अलसान',
    'अलसाना',
    'अलसाया',
    'अलसी',
    'अलसेट',
    'अलसेटिया',
    'अलसौंहा',
    'अलस्का',
    'अलस्सुबह',
    'अलह',
    'अलहदगी',
    'अलहदा',
    'अलाउंस',
    'अलाउन्स',
    'अलाकंगिक',
    'अलाकंगिकता',
    'अलात',
    'अलादीन',
    'अलानिया',
    'अलाप',
    'अलापना',
    'अलापी',
    'अलाबू',
    'अलाभ',
    'अलाभकारी',
    'अलाभप्रद',
    'अलाभान्वित',
    'अलाय',
    'अलायुध',
    'अलार',
    'अलार्म',
    'अलाव',
    'अलावा',
    'अलि',
    'अलिंग',
    'अलिंगी',
    'अलिंजर',
    'अलिंद',
    'अलिखित',
    'अलिखितता',
    'अलिजिह्वा',
    'अलिपिक',
    'अलिपित',
    'अलिपिबद्घ',
    'अलिप्त',
    'अलिप्तता',
    'अलिप्रिय',
    'अलिफ़',
    'अलिफ़बे',
    'अलिफ़बेपे',
    'अलिमक',
    'अली',
    'अलीः',
    'अलीक',
    'अलीन',
    'अलीनता',
    'अलील',
    'अलीज़',
    'अलुब्ध',
    'अलुब्ध्य',
    'अलुमिनम',
    'अलुमिनियम',
    'अलूमीनम',
    'अलोकप्रिय',
    'अलोकप्रियता',
    'अलोकोक्त',
    'अलोना',
    'अलोपा',
    'अलोभ',
    'अलोभी',
    'अलोभ्य',
    'अलोम',
    'अलोल',
    'अलोलता',
    'अलोलुप',
    'अलोलुपता',
    'अलोही',
    'अलौकिक',
    'अलौकिकता',
    'अलौय',
    'अल्क',
    'अल्कुहल',
    'अल्कोहल',
    'अल्गोरिद्म',
    'अल्टीमेटम',
    'अल्ट्रासाउंड',
    'अल्प',
    'अल्पकरण',
    'अल्पकाल',
    'अल्पकालिक',
    'अल्पकालिकता',
    'अल्पकालीन',
    'अल्पक्रिय',
    'अल्पज्ञ',
    'अल्पज्ञता',
    'अल्पज्ञान',
    'अल्पतंत्र',
    'अल्पतंत्रवादी',
    'अल्पतंत्रीय',
    'अल्पतः',
    'अल्पतम',
    'अल्पतर',
    'अल्पता',
    'अल्पतापीय',
    'अल्पदा',
    'अल्पदृष्टि',
    'अल्पधी',
    'अल्पन',
    'अल्पना',
    'अल्पप्राण',
    'अल्पबचत',
    'अल्पबुद्धि',
    'अल्पभक्षी',
    'अल्पभाषी',
    'अल्पभोग',
    'अल्पभोजी',
    'अल्पमत',
    'अल्पमति',
    'अल्पमध्यमा',
    'अल्पमात्र',
    'अल्पमात्रा',
    'अल्पमूल्य',
    'अल्पमूल्यतः',
    'अल्पमूल्यता',
    'अल्पमूल्यांकन',
    'अल्परक्तता',
    'अल्परास',
    'अल्परिश्रमिक',
    'अल्पर्धित',
    'अल्पलवणीय',
    'अल्पवयस्क',
    'अल्पवादी',
    'अल्पविकसित',
    'अल्पवित्त',
    'अल्पविधि',
    'अल्पवृत्ति',
    'अल्पवेतन',
    'अल्पव्यय',
    'अल्पव्ययी',
    'अल्पसंख्य',
    'अल्पसंख्यक',
    'अल्पसंख्या',
    'अल्पहार',
    'अल्पांश',
    'अल्पांशतः',
    'अल्पाक्षरिक',
    'अल्पाधिक',
    'अल्पाधिकता',
    'अल्पाधिकार',
    'अल्पाधिकारी',
    'अल्पान्तर',
    'अल्पायु',
    'अल्पायुता',
    'अल्पायुषी',
    'अल्पायुष्य',
    'अल्पार्जक',
    'अल्पार्थक',
    'अल्पावधि',
    'अल्पावधिक',
    'अल्पाविधि',
    'अल्पाशन',
    'अल्पाहार',
    'अल्पाहारी',
    'अल्पित',
    'अल्पियता',
    'अल्पीकरण',
    'अल्पीयता',
    'अल्पेक्षा',
    'अल्पेच्छ',
    'अल्ल',
    'अल्लम',
    'अल्ला',
    'अल्लाती',
    'अल्लामा',
    'अल्लाह',
    'अल्लाही',
    'अल्सर',
    'अल्हड़',
    'अल्हड़ता',
    'अल्हड़पन',
    'अव',
    'अवंध्य',
    'अवंश',
    'अवंशगतता',
    'अवंशागत',
    'अवंशानुगत',
    'अवंशानुगतता',
    'अवकण',
    'अवकर',
    'अवकर्षण',
    'अवकर्षित',
    'अवकलन',
    'अवकलना',
    'अवकाश',
    'अवकाशपूर्ण',
    'अवकाशहीन',
    'अवकाशहीनता',
    'अवकिरण',
    'अवकीर्ण',
    'अवकीलक',
    'अवकुंचन',
    'अवकुंचित',
    'अवकुशलन',
    'अवकृष्ट',
    'अवक्तव्य',
    'अवक्र',
    'अवक्रता',
    'अवक्रम',
    'अवक्रमण',
    'अवक्रमतः',
    'अवक्रमिक',
    'अवक्रय',
    'अवक्रीतक',
    'अवक्रोश',
    'अवक्षय',
    'अवक्षयण',
    'अवक्षयी',
    'अवक्षिप्त',
    'अवक्षेप',
    'अवक्षेपी',
    'अवखंडन',
    'अवखात',
    'अवगंण',
    'अवगणना',
    'अवगणित',
    'अवगण्य',
    'अवगत',
    'अवगमन',
    'अवगहन',
    'अवगहना',
    'अवगहिका',
    'अवगाढ़',
    'अवगाह',
    'अवगाहन',
    'अवगाही',
    'अवगीअरन',
    'अवगुंठन',
    'अवगुंठनवती',
    'अवगुठंन',
    'अवगुण',
    'अवगुणी',
    'अवगुहन',
    'अवग्रह',
    'अवघर्षण',
    'अवघात',
    'अवघूर्ण',
    'अवघोष',
    'अवचक्र',
    'अवचक्रिल',
    'अवचन',
    'अवचय',
    'अवचार',
    'अवचूषक',
    'अवचूषित',
    'अवचेतना',
    'अवच्छाद',
    'अवच्छिन्न',
    'अवच्छेदक',
    'अवच्छेदन',
    'अवजिर्त',
    'अवज्ञ',
    'अवज्ञतः',
    'अवज्ञा',
    'अवज्ञाकारी',
    'अवज्ञाकृत',
    'अवज्ञात',
    'अवज्ञाधीनता',
    'अवज्ञापूर्ण',
    'अवज्ञापूर्णता',
    'अवट',
    'अवटुज',
    'अवडीन',
    'अवण्र्यता',
    'अवतंस',
    'अवतंसक',
    'अवतंसन',
    'अवतंसित',
    'अवतमस',
    'अवतरण',
    'अवतरणिका',
    'अवतरणी',
    'अवतरित',
    'अवतल',
    'अवतलता',
    'अवतलन',
    'अवतलोत्तल',
    'अवतान',
    'अवतार',
    'अवतारक',
    'अवतारण',
    'अवतारणकरना',
    'अवतारवाद',
    'अवतारित',
    'अवतारी',
    'अवतारीय',
    'अवतीर्ण',
    'अवत्तीर्ण',
    'अवत्रण',
    'अवत्वरक',
    'अवत्वरित',
    'अवदंश',
    'अवदमन',
    'अवदात',
    'अवदाध',
    'अवदान',
    'अवदान्य',
    'अवदान्यता',
    'अवदारण',
    'अवदाह',
    'अवदेश',
    'अवदोह',
    'अवद्घ',
    'अवध',
    'अवधरण',
    'अवधान',
    'अवधानी',
    'अवधार',
    'अवधारण',
    'अवधारणा',
    'अवधारणीय',
    'अवधावन',
    'अवधि',
    'अवधिपूर्ण',
    'अवधियुक्त',
    'अवधिवर्धित',
    'अवधिवार',
    'अवधिवाली',
    'अवधिहीन',
    'अवधी',
    'अवधूत',
    'अवधूतता',
    'अवधृत',
    'अवधेय',
    'अवधेश',
    'अवध्य',
    'अवध्वंस',
    'अवनत',
    'अवनततः',
    'अवनतता',
    'अवनतन',
    'अवनतभाल',
    'अवनति',
    'अवनतिकारी',
    'अवनतिशील',
    'अवनद्घ',
    'अवनमन',
    'अवनमित',
    'अवनमितता',
    'अवनयन',
    'अवनालिका',
    'अवनाह',
    'अवनि',
    'अवनिजा',
    'अवनिरूह',
    'अवनींद्र',
    'अवनीध',
    'अवनीश',
    'अवनेजन',
    'अवन्य',
    'अवपंक',
    'अवपंकता',
    'अवपंकी',
    'अवपतन',
    'अवपतित',
    'अवपथन',
    'अवपरमाणु',
    'अवपाक',
    'अवपात',
    'अवपातन',
    'अवपातयिता',
    'अवपाती',
    'अवपाद',
    'अवपीड़क',
    'अवपीड़कता',
    'अवपीड़न',
    'अवपूंजीकरण',
    'अवप्याप्ति',
    'अवप्रवाह',
    'अवप्रेरण',
    'अवबुंठनहीनता',
    'अवबुद्घ',
    'अवबोध',
    'अवबोधक',
    'अवबोधन',
    'अवबोध्य',
    'अवभाषिका',
    'अवम',
    'अवमंदक',
    'अवमंदन',
    'अवमज्ज',
    'अवमज्जन',
    'अवमन्दन',
    'अवमान',
    'अवमानक',
    'अवमानन',
    'अवमानना',
    'अवमाननापूर्ण',
    'अवमाननायुक्त',
    'अवमानित',
    'अवमानी',
    'अवमान्य',
    'अवमिज्जत',
    'अवमिश्रण',
    'अवमूल्य',
    'अवमूल्यन',
    'अवमूल्यनात्मक',
    'अवयव',
    'अवयवता',
    'अवयवन',
    'अवयवी',
    'अवयस्क',
    'अवयस्कता',
    'अवर',
    'अवरज',
    'अवरत',
    'अवरति',
    'अवरार्ध',
    'अवरावली',
    'अवरुढ़ा',
    'अवरुद्ध',
    'अवरूढ',
    'अवरूद्घ',
    'अवरूद्घकंठ',
    'अवरूद्घता',
    'अवरूद्ध',
    'अवरेब',
    'अवरेबदार',
    'अवरोक्त',
    'अवरोचितता',
    'अवरोध',
    'अवरोधक',
    'अवरोधकता',
    'अवरोधकर्ता',
    'अवरोधन',
    'अवरोधना',
    'अवरोधपूर्ण',
    'अवरोधहीन',
    'अवरोधहीनतः',
    'अवरोधहीनता',
    'अवरोधात्मक',
    'अवरोधिता',
    'अवरोधित्र',
    'अवरोधी',
    'अवरोपक',
    'अवरोह',
    'अवरोहण',
    'अवरोहतः',
    'अवरोहशाखी',
    'अवरोहशील',
    'अवरोहित',
    'अवरोही',
    'अवर्ग',
    'अवर्गीकृत',
    'अवर्जना',
    'अवर्जनीय',
    'अवर्ण',
    'अवर्णता',
    'अवर्णनीय',
    'अवर्णनीयता',
    'अवर्णाली',
    'अवर्णित',
    'अवर्तमान',
    'अवर्तमानता',
    'अवर्ती',
    'अवर्धन',
    'अवर्धित',
    'अवर्षा',
    'अवर्षित',
    'अवलंब',
    'अवलंबता',
    'अवलंबन',
    'अवलंबनहीन',
    'अवलंबनहीनता',
    'अवलंबनीय',
    'अवलंबनीयता',
    'अवलंबहीन',
    'अवलंबहीनता',
    'अवलंबित',
    'अवलंबितता',
    'अवलंबी',
    'अवलम्ब',
    'अवलि',
    'अवलिखित',
    'अवली',
    'अवलीढ़',
    'अवलुंठन',
    'अवलुंठित',
    'अवलेखनी',
    'अवलेप',
    'अवलेह',
    'अवलेहक',
    'अवलोकक',
    'अवलोकन',
    'अवलोकना',
    'अवलोकनार्थ',
    'अवलोकनि',
    'अवलोकनीय',
    'अवलोकित',
    'अवलोकितेश्वर',
    'अवलोन',
    'अवश',
    'अवशता',
    'अवशप्त',
    'अवशवर्ती',
    'अवशाधीन',
    'अवशाधीनता',
    'अवशिष्ट',
    'अवशिष्टांश',
    'अवशिष्टांशीय',
    'अवशीभूत',
    'अवशेष',
    'अवशेषण',
    'अवशेषन',
    'अवशेषयुक्त',
    'अवशेषहीन',
    'अवशेषहीनता',
    'अवशेषित',
    'अवशोष',
    'अवशोषक',
    'अवशोषकता',
    'अवशोषण',
    'अवशोषित',
    'अवशोषित्र',
    'अवशोषी',
    'अवश्य',
    'अवश्यंभावना',
    'अवश्यंभावी',
    'अवश्यक',
    'अवश्यतः',
    'अवश्यभावी',
    'अवश्यमेव',
    'अवश्याय',
    'अवष्टंभ',
    'अवसन्न',
    'अवसन्नता',
    'अवसन्नन',
    'अवसन्नभाग्य',
    'अवसन्ना',
    'अवसन्नीकरण',
    'अवसर',
    'अवसरवाद',
    'अवसरवादिता',
    'अवसरवादी',
    'अवसरानुकूल',
    'अवसरानुकूलता',
    'अवसरीय',
    'अवसरों',
    'अवसरोचित',
    'अवसर्जन',
    'अवसर्पण',
    'अवसर्पिणी',
    'अवसाद',
    'अवसादक',
    'अवसादकारी',
    'अवसादन',
    'अवसादहीन',
    'अवसादहीनता',
    'अवसादानुभूति',
    'अवसादित',
    'अवसादी',
    'अवसान',
    'अवसेचन',
    'अवस्कंद',
    'अवस्कर',
    'अवस्करण',
    'अवस्करित',
    'अवस्तार',
    'अवस्तु',
    'अवस्त्र',
    'अवस्थ',
    'अवस्था',
    'अवस्थाओं',
    'अवस्थान',
    'अवस्थापक',
    'अवस्थापन',
    'अवस्थित',
    'अवस्थितता',
    'अवस्थिति',
    'अवस्थी',
    'अवस्फीति',
    'अवस्फीतिकारक',
    'अवस्मरण',
    'अवह',
    'अवहनन',
    'अवहनीय',
    'अवहरण',
    'अवहस्त',
    'अवहार',
    'अवहित',
    'अवहिल्थ',
    'अवहिल्था',
    'अवहेलन',
    'अवहेलना',
    'अवहेलनीय',
    'अवहेल्य',
    'अवाँसी',
    'अवांछनीय',
    'अवांछनीयता',
    'अवांछा',
    'अवांछित',
    'अवांछितता',
    'अवांतर',
    'अवाक',
    'अवाकता',
    'अवाक्ता',
    'अवाग्दत्त',
    'अवाचलता',
    'अवाचा',
    'अवाचाल',
    'अवाचित',
    'अवाची',
    'अवाचीन',
    'अवाच्य',
    'अवाप्त',
    'अवार',
    'अवारजा',
    'अवारण',
    'अवारणीय',
    'अवारणीयता',
    'अवारिजा',
    'अवारित',
    'अवार्ड',
    'अवार्य',
    'अवालांच',
    'अवासा',
    'अवासिता',
    'अवासी',
    'अवास्तवता',
    'अवास्तविक',
    'अवास्तविकता',
    'अवाहन',
    'अवाह्यता',
    'अवि',
    'अविक',
    'अविकट',
    'अविकटता',
    'अविकल',
    'अविकलता',
    'अविकलांग',
    'अविकलांगता',
    'अविकल्प',
    'अविकल्पी',
    'अविकसित',
    'अविका',
    'अविकार',
    'अविकारशील',
    'अविकारी',
    'अविकास',
    'अविकृत',
    'अविकृति',
    'अविकेंद्रित',
    'अविक्रेय',
    'अविक्षत',
    'अविखंडितता',
    'अविख्यात',
    'अविगत',
    'अविगतराग',
    'अविगतस्पृह',
    'अविचल',
    'अविचलता',
    'अविचलन',
    'अविचलित',
    'अविचलितता',
    'अविचार',
    'अविचारक',
    'अविचारण',
    'अविचारणीय',
    'अविचारतः',
    'अविचारपूर्ण',
    'अविचारशील',
    'अविचारित',
    'अविचारी',
    'अविचार्य',
    'अविच्छिन्न',
    'अविच्छिन्नक',
    'अविच्छिन्नतः',
    'अविच्छिन्नता',
    'अविच्युत',
    'अविजड़ित',
    'अविजड़ितता',
    'अविजित',
    'अविजितता',
    'अविजेयता',
    'अविज्ञ',
    'अविज्ञता',
    'अविज्ञात',
    'अविज्ञानीय',
    'अविज्ञेय',
    'अवितरण',
    'अवितरणीय',
    'अवितरित',
    'अवितर्कित',
    'अवित्त',
    'अविदित',
    'अविद्घ',
    'अविद्घता',
    'अविद्य',
    'अविद्यमान',
    'अविद्यमानता',
    'अविद्या',
    'अविद्वत्ता',
    'अविद्वान',
    'अविधाता',
    'अविधान',
    'अविधि',
    'अविधिमान्य',
    'अविधिमान्यता',
    'अविधेय',
    'अविध्वस्त',
    'अविनम्र',
    'अविनय',
    'अविनश्वर',
    'अविनाशी',
    'अविनिमेय',
    'अविनियमन',
    'अविनियमित',
    'अविनियोजित',
    'अविनीत',
    'अविनीतता',
    'अविनीता',
    'अविपट',
    'अविपाल',
    'अविभक्त',
    'अविभक्तता',
    'अविभागीय',
    'अविभाजन',
    'अविभाजित',
    'अविभाजितता',
    'अविभाज्य',
    'अविभूषित',
    'अविभेद्यता',
    'अविमर्शित',
    'अवियुक्त',
    'अवियोगिनी',
    'अवियोगी',
    'अविरक्त',
    'अविरक्ति',
    'अविरत',
    'अविरतता',
    'अविरति',
    'अविरल',
    'अविरलता',
    'अविरहित',
    'अविरही',
    'अविराम',
    'अविरामतः',
    'अविरामता',
    'अविरूद्घ',
    'अविरूपित',
    'अविरोध',
    'अविरोधित',
    'अविरोधी',
    'अविलंब',
    'अविलंबता',
    'अविलगितता',
    'अविलम्ब',
    'अविलेयता',
    'अविवक्षा',
    'अविवक्षित',
    'अविवाद',
    'अविवादता',
    'अविवादित',
    'अविवाह',
    'अविवाहित',
    'अविवाहितता',
    'अविवाहिता',
    'अविविध',
    'अविविधतः',
    'अविविधता',
    'अविवेक',
    'अविवेकपूर्णता',
    'अविवेकशील',
    'अविवेकशीलता',
    'अविवेकी',
    'अविशंक',
    'अविशद',
    'अविशदता',
    'अविशाल',
    'अविशिष्ट',
    'अविशुद्घ',
    'अविशुद्घता',
    'अविशेष',
    'अविशेषता',
    'अविशोधित',
    'अविश्राम',
    'अविश्रामतः',
    'अविश्वसनीय',
    'अविश्वसनीयता',
    'अविश्वस्त',
    'अविश्वास',
    'अविश्वासी',
    'अविषम',
    'अविषयासक्त',
    'अविषयासक्तता',
    'अविष्कार',
    'अविस्तीर्ण',
    'अविस्तीर्णता',
    'अविस्तृत',
    'अविस्तृतता',
    'अविस्फीति',
    'अविस्मरण',
    'अविस्मरणीय',
    'अविस्मृत',
    'अविस्रणशील',
    'अविस्फ़ोटन',
    'अविहित',
    'अविहितता',
    'अविहीन',
    'अवीत',
    'अवीतारागी',
    'अवीरा',
    'अवृत्ति',
    'अवृथा',
    'अवृष्ट',
    'अवृष्टि',
    'अवेक्षक',
    'अवेक्षण',
    'अवेक्षा',
    'अवेक्षाहीन',
    'अवेक्षित',
    'अवेघ्यता',
    'अवेचित',
    'अवेज्ञा',
    'अवेदीय',
    'अवेद्य',
    'अवेध्य',
    'अवेध्यता',
    'अवेस्ता',
    'अवैज्ञानिक',
    'अवैज्ञानिकता',
    'अवैतनिक',
    'अवैदिक',
    'अवैद्य',
    'अवैध',
    'अवैधता',
    'अवैधव्य',
    'अवैधाचार',
    'अवैधानिक',
    'अवैधानिकता',
    'अवैधीकरण',
    'अवैमत्य',
    'अवैमनस्य',
    'अवैयक्तिक',
    'अवैयक्तिकता',
    'अवैराग्य',
    'अवोत्कीर्णन',
    'अव्यंग',
    'अव्यक्त',
    'अव्यक्तता',
    'अव्यक्तिगत',
    'अव्यग्र',
    'अव्यग्रता',
    'अव्यतीत',
    'अव्यथीषी',
    'अव्यपदेश्य',
    'अव्यमिचार',
    'अव्यय',
    'अव्ययसाध्य',
    'अव्ययित',
    'अव्यर्थ',
    'अव्यवधान',
    'अव्यवसायी',
    'अव्यवस्था',
    'अव्यवस्थित',
    'अव्यवस्थिततः',
    'अव्यवस्थितता',
    'अव्यवहत',
    'अव्यवहतता',
    'अव्यवहत्य',
    'अव्यवहार',
    'अव्यवहारिक',
    'अव्यवहार्य',
    'अव्यवहित',
    'अव्यवहितत्व',
    'अव्यसन',
    'अव्यस्त',
    'अव्यस्तता',
    'अव्याकुल',
    'अव्याकुलता',
    'अव्याकृत',
    'अव्यापकता',
    'अव्यापारिक',
    'अव्याप्त',
    'अव्याप्ति',
    'अव्यावर्तन',
    'अव्यावहारिक',
    'अव्यावहारिकता',
    'अव्यावहारिकतापूर्ण',
    'अव्युत्पत्ति',
    'अव्वल',
    'अव्वलः',
    'अशंक',
    'अशंका',
    'अशंकित',
    'अशंकी',
    'अशकुन',
    'अशक्त',
    'अशक्तता',
    'अशक्ति',
    'अशक्य',
    'अशक्यता',
    'अशत्रु',
    'अशन',
    'अशना',
    'अशनि',
    'अशफ्रीर्',
    'अशब्द',
    'अशब्दित',
    'अशमन',
    'अशयन',
    'अशय्या',
    'अशरण',
    'अशरणतः',
    'अशरफ़ी',
    'अशरफी',
    'अशरफ्र',
    'अशरफ्री',
    'अशरीर',
    'अशरीरता',
    'अशरीरी',
    'अशर्त',
    'अशर्ततः',
    'अशर्तता',
    'अशल्यकरणीय',
    'अशस्त्र',
    'अशस्त्रीकरण',
    'अशस्त्रीकृत',
    'अशांत',
    'अशांतचित्तता',
    'अशांततः',
    'अशांतता',
    'अशांति',
    'अशांतिकर',
    'अशांतिपूर्ण',
    'अशाखित',
    'अशात',
    'अशान्त',
    'अशान्ति',
    'अशाब्दिक',
    'अशारीरिक',
    'अशालीन',
    'अशालीनता',
    'अशाश्वत',
    'अशाश्वतता',
    'अशासनीय',
    'अशासित',
    'अशासितता',
    'अशास्त्रीय',
    'अशिक्षणीयता',
    'अशिक्षा',
    'अशिक्षित',
    'अशिक्षितपन',
    'अशित',
    'अशिव',
    'अशिष्ट',
    'अशिष्टतः',
    'अशिष्टता',
    'अशिष्टतापूर्वक',
    'अशिष्टाचारपूर्णतः',
    'अशीघरतः',
    'अशीघरता',
    'अशीति',
    'अशील',
    'अशीलता',
    'अशुक्ल',
    'अशुचि',
    'अशुचिता',
    'अशुद्घ',
    'अशुद्घचित्त',
    'अशुद्घता',
    'अशुद्घमनस्क',
    'अशुद्घाशय',
    'अशुद्घाशयता',
    'अशुद्ध',
    'अशुद्धता',
    'अशुद्धतापूर्वक',
    'अशुद्धि',
    'अशुद्धियों',
    'अशुभ',
    'अशुभकर',
    'अशुभकरता',
    'अशुभता',
    'अशुभसूचक',
    'अशुभेव्छुक',
    'अशुभ्र',
    'अशुल्क',
    'अशून्य',
    'अशून्यता',
    'अशृत',
    'अशेष',
    'अशेषता',
    'अशोक',
    'अशोका',
    'अशोकारि',
    'अशोघ्य',
    'अशोधन',
    'अशोधित',
    'अशोध्य',
    'अशोभन',
    'अशोभनता',
    'अशोभनीय',
    'अशोभनीयता',
    'अशौच',
    'अशौचपूर्ण',
    'अश्म',
    'अश्मंत',
    'अश्मंतक',
    'अश्मकुट्टक',
    'अश्मज',
    'अश्मभक्षी',
    'अश्मर',
    'अश्मरी',
    'अश्मलेख',
    'अश्मलेखन',
    'अश्मविज्ञान',
    'अश्मसार',
    'अश्मा',
    'अश्माज',
    'अश्माजतु',
    'अश्मीकृत',
    'अश्मीभूत',
    'अश्मोत्थ',
    'अश्रद्घ',
    'अश्रद्घा',
    'अश्रद्धा',
    'अश्रप',
    'अश्रम',
    'अश्रवण',
    'अश्रांत',
    'अश्रांति',
    'अश्राव्य',
    'अश्रीक',
    'अश्रु',
    'अश्रुगैस',
    'अश्रुग्रंथी',
    'अश्रुत',
    'अश्रुति',
    'अश्रुतिगम्य',
    'अश्रुपात',
    'अश्रुपूर्ण',
    'अश्रुबिन्द',
    'अश्रुमय',
    'अश्रुमुख',
    'अश्रुवाहिनी',
    'अश्रुविहीन',
    'अश्रुस्नात',
    'अश्रेणी',
    'अश्रेय',
    'अश्रौत',
    'अश्लिष्ट',
    'अश्लील',
    'अश्लीलता',
    'अश्लेष',
    'अश्लेषा',
    'अश्व',
    'अश्वक',
    'अश्वगति',
    'अश्वगोष्ठ',
    'अश्वतर',
    'अश्वत्थ',
    'अश्वत्थांमा',
    'अश्वदल',
    'अश्वपति',
    'अश्वपथ',
    'अश्वपाल',
    'अश्वप्रीव',
    'अश्वबलः',
    'अश्वमीन',
    'अश्वमुख',
    'अश्वमेघ',
    'अश्वयक्त्र',
    'अश्ववार',
    'अश्ववाह',
    'अश्वशाला',
    'अश्वशिरा',
    'अश्वसदृश',
    'अश्वा',
    'अश्वानीक',
    'अश्वारि',
    'अश्वारोहण',
    'अश्वारोही',
    'अश्वासुर',
    'अश्विनी',
    'अश्वीथी',
    'अश्वीय',
    'अश्वेत',
    'अश्वेतता',
    'अषाउट',
    'अष्ट',
    'अष्टक',
    'अष्टकर्ण',
    'अष्टकली',
    'अष्टकी',
    'अष्टकोण',
    'अष्टकौशल',
    'अष्टखंड',
    'अष्टखंडीय',
    'अष्टग्रह',
    'अष्टग्रही',
    'अष्टधातु',
    'अष्टपद',
    'अष्टपदी',
    'अष्टपाटीय',
    'अष्टपाद',
    'अष्टपाश्र्व',
    'अष्टपाश्र्वीय',
    'अष्टफलक',
    'अष्टभागीय',
    'अष्टभुज',
    'अष्टभुजा',
    'अष्टभुजाकार',
    'अष्टम',
    'अष्टमांशक',
    'अष्टमी',
    'अष्टमूर्ति',
    'अष्टयोग',
    'अष्टरुप',
    'अष्टलोह',
    'अष्टलोहक',
    'अष्टवर्षीय',
    'अष्टशताब्दी',
    'अष्टांग',
    'अष्टांश',
    'अष्टाक्षर',
    'अष्टाक्षरी',
    'अष्टादश',
    'अष्टावक्र',
    'अष्टावक्रता',
    'अष्टि',
    'अष्ठ',
    'अष्ठीला',
    'अस',
    'असंकर',
    'असंकरता',
    'असंकलन',
    'असंकल्प',
    'असंकुल',
    'असंकुलता',
    'असंक्रामक',
    'असंक्षिप्त',
    'असंक्षिप्ततः',
    'असंक्षिप्तता',
    'असंख्य',
    'असंख्यक',
    'असंख्यतः',
    'असंख्यता',
    'असंख्या',
    'असंख्यात',
    'असंख्येय',
    'असंग',
    'असंगठन',
    'असंगठित',
    'असंगठिततः',
    'असंगठितता',
    'असंगत',
    'असंगतता',
    'असंगति',
    'असंगतियां',
    'असंगम',
    'असंग्रंथित',
    'असंग्रंथितता',
    'असंघनित',
    'असंघी',
    'असंचय',
    'असंचयी',
    'असंचर',
    'असंचल',
    'असंचलन',
    'असंजसकारी',
    'असंजात',
    'असंज्ञ',
    'असंज्ञता',
    'असंज्ञित',
    'असंज्ञीकरण',
    'असंज्ञीकृत',
    'असंज्ञेय',
    'असंत',
    'असंतत',
    'असंतान',
    'असंतुलन',
    'असंतुलनों',
    'असंतुलित',
    'असंतुलितता',
    'असंतुष्ट',
    'असंतुष्टतः',
    'असंतुष्टि',
    'असंतोष',
    'असंतोषजनक',
    'असंतोषी',
    'असंदर्भता',
    'असंदिगध',
    'असंदिग्ध',
    'असंदिग्धतः',
    'असंदिग्धता',
    'असंदिष्ट',
    'असंदेह',
    'असंधि',
    'असंन्यासी',
    'असंपत्ति',
    'असंपन्न',
    'असंपन्नता',
    'असंपर्क',
    'असंपादन',
    'असंपादित',
    'असंपाद्य',
    'असंपाद्यता',
    'असंपुष्ट',
    'असंपुष्टि',
    'असंपुष्टिकरण',
    'असंपूर्ण',
    'असंपूर्णतः',
    'असंपूर्णता',
    'असंपूर्त',
    'असंपूर्ततः',
    'असंपूर्तता',
    'असंपृकतता',
    'असंपृक्त',
    'असंपृक्तता',
    'असंबंध',
    'असंबंधित',
    'असंबंधितता',
    'असंबद्घ',
    'असंबद्घता',
    'असंबद्ध',
    'असंबद्धता',
    'असंबधित',
    'असंबैधानिक',
    'असंभव',
    'असंभवतः',
    'असंभवता',
    'असंभविष्णु',
    'असंभायी',
    'असंभार',
    'असंभावना',
    'असंभावनापूर्ण',
    'असंभावित',
    'असंभाविता',
    'असंभावी',
    'असंभाव्य',
    'असंभाव्यता',
    'असंभाष्य',
    'असंभूत',
    'असंभूति',
    'असंयत',
    'असंयत्त',
    'असंयम',
    'असंयमता',
    'असंयमित',
    'असंयमी',
    'असंयुक्त',
    'असंयुक्तता',
    'असंरक्षित',
    'असंलग्न',
    'असंलग्नता',
    'असंलयन',
    'असंलिप्त',
    'असंलीन',
    'असंवरण',
    'असंवार',
    'असंवेदनशील',
    'असंवेदिता',
    'असंवेदी',
    'असंवैधानिक',
    'असंवैधानिकता',
    'असंशयात्मा',
    'असंशयालु',
    'असंशयी',
    'असंशोध्य',
    'असंश्लिष्ट',
    'असंसक्त',
    'असंसदीय',
    'असंसाधित',
    'असंसारी',
    'असंस्कृत',
    'असंस्कृतता',
    'असंस्थित',
    'असंस्थिततः',
    'असंस्थितता',
    'असंस्मृत',
    'असंस्वीकृति',
    'असकल',
    'असकलता',
    'असक्रिय',
    'असक्रियता',
    'असखा',
    'असगुन',
    'असगोत्र',
    'असगोत्रता',
    'असघन',
    'असघनता',
    'असजग',
    'असजिर्त',
    'असज्जन',
    'असज्जनता',
    'असज्जित',
    'असतत',
    'असतता',
    'असतर्क',
    'असती',
    'असतीत्व',
    'असत्',
    'असत्कार',
    'असत्कारपूर्णतः',
    'असत्कारशील',
    'असत्कृत',
    'असत्ता',
    'असत्त्य',
    'असत्पुत्र',
    'असत्य',
    'असत्यः',
    'असत्यतः',
    'असत्यता',
    'असत्यभाषी',
    'असत्यवादिता',
    'असत्यवादी',
    'असत्यशील',
    'असत्यसिद्घ',
    'असत्यसिद्ध',
    'असत्यापूर्ण',
    'असदाचार',
    'असदाचारिणी',
    'असदाचारी',
    'असदाशय',
    'असदाशयता',
    'असदुल्लाह',
    'असदृश',
    'असदृश्य',
    'असदृश्यता',
    'असद्',
    'असद्घर्म',
    'असद्ता',
    'असद्भाव',
    'असद्भावपूर्ण',
    'असद्भावपूर्वक',
    'असद्भावी',
    'असधनता',
    'असनातन',
    'असनातनता',
    'असन्तुलन',
    'असन्तुलित',
    'असन्तुष्ट',
    'असन्तुष्टता',
    'असन्तोष',
    'असन्तोषजनक',
    'असन्नद्घ',
    'असन्नद्घता',
    'असपत्न',
    'असपिंड',
    'असफल',
    'असफलता',
    'असबाब',
    'असभीचीन',
    'असभ्य',
    'असभ्यतः',
    'असभ्यता',
    'असभ्यावस्था',
    'असभ्यीकरण',
    'असम',
    'असमंजस',
    'असमंजसग्रस्त',
    'असमंजसहीन',
    'असमंजसहीनता',
    'असमःराजधानी',
    'असमकक्ष',
    'असमकक्षता',
    'असमकालीन',
    'असमक्ष',
    'असमक्षतः',
    'असमक्षता',
    'असमगुणता',
    'असमग्र',
    'असमग्रता',
    'असमघाती',
    'असमचतुर्भज',
    'असमचित्त',
    'असमचेता',
    'असमत',
    'असमतल',
    'असमतलता',
    'असमता',
    'असमतापी',
    'असमनेत्र',
    'असमपिंड',
    'असमभार',
    'असममित',
    'असमय',
    'असमयतः',
    'असमयता',
    'असमयानुसार',
    'असमयानुसारतः',
    'असमयानुसारता',
    'असमरस',
    'असमरुपता',
    'असमर्थ',
    'असमर्थता',
    'असमलक्षण',
    'असमलक्षणता',
    'असमवर्ती',
    'असमव्यवहार',
    'असमव्यवहारी',
    'असमशर',
    'असमस्त',
    'असमस्तता',
    'असमस्तर',
    'असमस्तरता',
    'असमांगी',
    'असमांजस्य',
    'असमांतर',
    'असमांशी',
    'असमाकार',
    'असमागम',
    'असमाधान',
    'असमाधानित',
    'असमाधान्य',
    'असमाधान्यता',
    'असमाधिकारी',
    'असमाधेय',
    'असमाधेयता',
    'असमाध्येय',
    'असमान',
    'असमानता',
    'असमानाधिकार',
    'असमानाधिकारी',
    'असमान्य',
    'असमान्यता',
    'असमापन',
    'असमापनीय',
    'असमाप्त',
    'असमाप्ततः',
    'असमाप्तता',
    'असमाप्ति',
    'असमाप्य',
    'असमायोजित',
    'असमार्धित',
    'असमाविष्ट',
    'असमाविष्टता',
    'असमावेश',
    'असमाशोधित',
    'असमाहार',
    'असमाहित',
    'असमिया',
    'असमीप',
    'असमीपता',
    'असमुपस्थित',
    'असमूहतः',
    'असमृद्घ',
    'असमृद्घता',
    'असमृद्धि',
    'असम्बद्ध',
    'असम्बद्धता',
    'असम्भव',
    'असम्मत',
    'असम्मतता',
    'असम्मता',
    'असम्मति',
    'असम्मान',
    'असम्माननीय',
    'असम्मुख',
    'असम्मोहित',
    'असम्मोहृय',
    'असम्यक',
    'असयोग',
    'असर',
    'असरंदाज',
    'असरंदाजी',
    'असरदार',
    'असरिया',
    'असर्जक',
    'असल',
    'असला',
    'असलियत',
    'असली',
    'असलीपन',
    'असलीयत',
    'असवांग',
    'असवांगतः',
    'असवांगता',
    'असवांगीणता',
    'असवार',
    'असवारी',
    'असह',
    'असहकर्म',
    'असहकर्मी',
    'असहकार',
    'असहकारिता',
    'असहकारी',
    'असहकालीन',
    'असहचर',
    'असहचर्य',
    'असहचर्या',
    'असहज',
    'असहजता',
    'असहन',
    'असहनशील',
    'असहनशीलता',
    'असहनीय',
    'असहमत',
    'असहमति',
    'असहयोग',
    'असहयोगी',
    'असहवर्तिता',
    'असहवर्ती',
    'असहाय',
    'असहायक',
    'असहायकता',
    'असहायतः',
    'असहायता',
    'असहायपन',
    'असहिष्णु',
    'असहिष्णुता',
    'असहोदर',
    'असहोदरता',
    'असहोदरत्व',
    'असह्य',
    'असह्यता',
    'असा',
    'असांख्य',
    'असांद्र',
    'असांध्य',
    'असांप्रदायिक',
    'असांप्रदायिकता',
    'असांसदिक',
    'असांसरिकता',
    'असांसारिक',
    'असाए',
    'असाक्षर',
    'असाघित',
    'असाढ़',
    'असाढ़ा',
    'असादृश्य',
    'असाधन',
    'असाधारण',
    'असाधारणतः',
    'असाधारणता',
    'असाधु',
    'असाधुता',
    'असाध्य',
    'असाध्यता',
    'असाध्यी',
    'असानमंदी',
    'असान्निध्य',
    'असापेक्ष',
    'असामंजस्य',
    'असामंस्यपूर्ण',
    'असामथ्र्य',
    'असामप्यता',
    'असामयिक',
    'असामयिकता',
    'असामर्थ्य',
    'असामाजिक',
    'असामाजिकता',
    'असामान्य',
    'असामान्यतः',
    'असामान्यता',
    'असामासिकता',
    'असामी',
    'असामीप्य',
    'असाम्य',
    'असाम्यिक',
    'असायतः',
    'असार',
    'असार्थक',
    'असार्थकता',
    'असार्वजनिकता',
    'असालत',
    'असावक',
    'असावधान',
    'असावधानतः',
    'असावधानता',
    'असावधानी',
    'असावधानीवश',
    'असाहचर्य',
    'असि',
    'असिंचन',
    'असिंचित',
    'असिक्नी',
    'असिगंड',
    'असिज्जत',
    'असिज्जतता',
    'असित',
    'असितः',
    'असितता',
    'असिता',
    'असितांग',
    'असितांबुज',
    'असिताचिर्',
    'असिताश्म',
    'असितोत्पल',
    'असितोपल',
    'असिद्घ',
    'असिद्घता',
    'असिद्घार्थ',
    'असिद्धकरण',
    'असिद्धि',
    'असिधारी',
    'असिन्नकट',
    'असिन्नधि',
    'असिन्नहित',
    'असिपत्र',
    'असिपाणि',
    'असिपुच्छक',
    'असिपुत्रिका',
    'असिमैट्रिकल',
    'असिम्मत',
    'असिम्मलन',
    'असिम्मश्रण',
    'असिम्मश्रित',
    'असिम्मश्रितता',
    'असिलोमा',
    'असिस्टेंट',
    'असिस्टैंट',
    'असीम',
    'असीमता',
    'असीमित',
    'असीमितता',
    'असीयीकरण',
    'असीर',
    'असीरियाई',
    'असीरी',
    'असील',
    'असीस',
    'असीसना',
    'असु',
    'असुंदर',
    'असुंदरता',
    'असुकर',
    'असुखंकर',
    'असुगम',
    'असुत्याग',
    'असुधर',
    'असुधार्य',
    'असुन्दर',
    'असुप्त',
    'असुप्ति',
    'असुर',
    'असुरः',
    'असुरक्षा',
    'असुरघ्न',
    'असुरता',
    'असुरमर्दन',
    'असुरा',
    'असुराघिप',
    'असुराचार्य',
    'असुरारि',
    'असुराह्व',
    'असुरी',
    'असुरीय',
    'असुर्य',
    'असुलभ',
    'असुलभता',
    'असुवक्त्र',
    'असुविधा',
    'असुविधाओं',
    'असुविधाजनक',
    'असुविधापूर्ण',
    'असुशील',
    'असुशीलता',
    'असुहाना',
    'असूचना',
    'असूय',
    'असूयक',
    'असूया',
    'असूर्यपश्या',
    'असूल',
    'असृक्प',
    'असृजन',
    'असृजनशील',
    'असृजनशीलता',
    'असृष्ट',
    'असृष्टा',
    'असृष्टि',
    'असेवन',
    'असेव्य',
    'असैंबली',
    'असैट्स',
    'असैनिक',
    'असैनिकिकरण',
    'असैन्य',
    'असैन्यीककृत',
    'असैन्यीकरण',
    'असोपचार',
    'असोम',
    'असौंदर्य',
    'असौम्य',
    'असौम्यता',
    'असौष्ठव',
    'अस्',
    'अस्करी',
    'अस्त',
    'अस्तंगत',
    'अस्तंगमन',
    'अस्तंगमनशील',
    'अस्तंगामी',
    'अस्तगत',
    'अस्तगमन',
    'अस्तगामी',
    'अस्तगिरि',
    'अस्तना',
    'अस्तबल',
    'अस्तभाग्य',
    'अस्तमन',
    'अस्तर',
    'अस्तरः',
    'अस्तरकारी',
    'अस्तरी',
    'अस्तव्यस्त',
    'अस्तव्यस्तकारी',
    'अस्तव्यस्ततः',
    'अस्तव्यस्तता',
    'अस्ताक्षरी',
    'अस्ताचल',
    'अस्ति',
    'अस्तित्व',
    'अस्तित्वः',
    'अस्तित्वमान',
    'अस्तित्ववाद',
    'अस्तित्ववादी',
    'अस्तित्ववान',
    'अस्तित्वशाली',
    'अस्तित्वहीन',
    'अस्तित्वहीनता',
    'अस्तिवाचक',
    'अस्तेय',
    'अस्तोदय',
    'अस्त्र',
    'अस्त्रकारक',
    'अस्त्रकारी',
    'अस्त्रजीवी',
    'अस्त्रधारी',
    'अस्त्रफला',
    'अस्त्रशाला',
    'अस्त्राखान',
    'अस्त्रागार',
    'अस्त्री',
    'अस्त्रीक',
    'अस्त्रीय',
    'अस्थगित',
    'अस्थमा',
    'अस्थर',
    'अस्थविर',
    'अस्थाई',
    'अस्थाणु',
    'अस्थायिक',
    'अस्थायित्व',
    'अस्थायी',
    'अस्थावर',
    'अस्थावरता',
    'अस्थि',
    'अस्थिकच्छद',
    'अस्थिका',
    'अस्थिकुंड',
    'अस्थिक्षय',
    'अस्थिक्षरण',
    'अस्थिखंड',
    'अस्थिचर्ममय',
    'अस्थिज',
    'अस्थित',
    'अस्थिधन्या',
    'अस्थिपात्र',
    'अस्थिबंध',
    'अस्थिभंद',
    'अस्थिभवन',
    'अस्थिभोजी',
    'अस्थिमय',
    'अस्थिमात्र',
    'अस्थिमाली',
    'अस्थियुक्त',
    'अस्थियुजा',
    'अस्थिर',
    'अस्थिरचित्त',
    'अस्थिरचित्तता',
    'अस्थिरण',
    'अस्थिरता',
    'अस्थिरतापूर्वक',
    'अस्थिरोग',
    'अस्थिल',
    'अस्थिशाला',
    'अस्थिशेष',
    'अस्थिसार',
    'अस्थिस्नेह',
    'अस्थिहीन',
    'अस्थीय',
    'अस्थैर्य',
    'अस्पताल',
    'अस्पतालों',
    'अस्पर्शित',
    'अस्पष्ट',
    'अस्पष्टतः',
    'अस्पष्टता',
    'अस्पष्टीकृत',
    'अस्पृश्य',
    'अस्पृश्यता',
    'अस्पृष्ट',
    'अस्पृह',
    'अस्पृहा',
    'अस्फीतिकारी',
    'अस्फुट',
    'अस्फुटता',
    'अस्फूर्त',
    'अस्मार्त',
    'अस्मार्तता',
    'अस्मिक',
    'अस्मिता',
    'अस्मिताहीन',
    'अस्मितीय',
    'अस्मृत',
    'अस्मृति',
    'अस्य',
    'अस्र',
    'अस्रज',
    'अस्ल',
    'अस्लतन',
    'अस्ला',
    'अस्वःकृततः',
    'अस्वकीय',
    'अस्वचालन',
    'अस्वचालित',
    'अस्वच्छ',
    'अस्वच्छता',
    'अस्वतंत्र',
    'अस्वता',
    'अस्वनित',
    'अस्वप्न',
    'अस्वभावज',
    'अस्वभावजता',
    'अस्वभाविक',
    'अस्वर',
    'अस्वरण',
    'अस्वरित',
    'अस्वरीकरण',
    'अस्वस्थ',
    'अस्वस्थकर',
    'अस्वस्थता',
    'अस्वस्य',
    'अस्वाद',
    'अस्वादिष्ट',
    'अस्वाधीन',
    'अस्वाभविक',
    'अस्वाभाविक',
    'अस्वाभाविकता',
    'अस्वाभिमान',
    'अस्वाभिमानी',
    'अस्वामी',
    'अस्वार्थ',
    'अस्वार्थी',
    'अस्वास्थ्य',
    'अस्वास्थ्यकर',
    'अस्वास्थ्यकारक',
    'अस्वीकरण',
    'अस्वीकर्ता',
    'अस्वीकार',
    'अस्वीकारात्मक',
    'अस्वीकार्य',
    'अस्वीकृत',
    'अस्वीकृति',
    'अस्वीकृतियां',
    'अस्सरे',
    'अस्सी',
    'अस्सीवां',
    'असफ़ल',
    'असफ़लता',
    'अह',
    'अहं',
    'अहंकार',
    'अहंकारपूर्णता',
    'अहंकारहीन',
    'अहंकारहीनता',
    'अहंकारी',
    'अहंकारोक्ति',
    'अहंकेन्द्रता',
    'अहंकेन्द्रिक',
    'अहंतव्य',
    'अहंपूविर्का',
    'अहंभद्रता',
    'अहंमात्रवाद',
    'अहंमात्रवादी',
    'अहंवाद',
    'अहंवादी',
    'अहत्या',
    'अहद',
    'अहदनामा',
    'अहदी',
    'अहनिर्श',
    'अहनिसि',
    'अहबाब',
    'अहम',
    'अहमक्र',
    'अहमक्रपना',
    'अहमद',
    'अहमदाबाद',
    'अहमन्य',
    'अहमन्यता',
    'अहमर',
    'अहम्',
    'अहम्मति',
    'अहम्मन्य',
    'अहम्मन्यता',
    'अहरा',
    'अहरी',
    'अहर्मणि',
    'अहलि',
    'अहलित',
    'अहल्या',
    'अहवाल',
    'अहसान',
    'अहसानमंद',
    'अहसास',
    'अहस्तक्षेप',
    'अहस्तांतरणीय',
    'अहा',
    'अहाता',
    'अहाताबंदी',
    'अहाते',
    'अहानिकर',
    'अहानिकरता',
    'अहानिकारक',
    'अहारना',
    'अहाहा',
    'अहि',
    'अहिं',
    'अहिंसक',
    'अहिंसकता',
    'अहिंसा',
    'अहिंसात्मक',
    'अहिंसावाद',
    'अहिंसावादी',
    'अहिक',
    'अहिकांत',
    'अहिच्छत्रक',
    'अहिजित',
    'अहित',
    'अहितकर',
    'अहितकारी',
    'अहितकृत',
    'अहितू',
    'अहिपति',
    'अहिफेन',
    'अहिभोजी',
    'अहिमन',
    'अहिमान',
    'अहिमाली',
    'अहियान',
    'अहिरमन',
    'अहिरावण',
    'अहिरिपू',
    'अहिर्बुध्न',
    'अहिर्बुध्न्य',
    'अहिर्मन',
    'अहिलोचन',
    'अहिवात',
    'अहिवाती',
    'अहीक',
    'अहीर',
    'अहीरणि',
    'अहीरनी',
    'अहीश',
    'अहुत',
    'अहुर',
    'अहुरमज्',
    'अहूठन',
    'अहृदय',
    'अहृषीक',
    'अहृस्त',
    'अहेतु',
    'अहेतुक',
    'अहेरी',
    'अहो',
    'अहोई',
    'अहोबिल',
    'अहोम',
    'अहोरा',
    'अहोरात्र',
    'अहोरात्रः',
    'अहोरात्रीय',
    'अहोरुपमहों',
    'अह्दनामा',
    'अह्दशिकनी',
    'अह्न',
    'अह्र्य',
    'अह्ल',
    'अह्लकार',
    'अह्लकारी',
    'अज़मानतीय',
    'अज़ान',
    'अफ़लातून',
    'अफ़वाह',
    'अफ़सोस',
    'अफ़ीम',
    'अफ़्रीका',
    'आ',
    'आँक',
    'आँकड़ा',
    'आँकड़े',
    'आँकना',
    'आँकुड़ा',
    'आँकुसी',
    'आँकू',
    'आँख',
    'आँखः',
    'आँखड़ी',
    'आँखें',
    'आँखों',
    'आँगन',
    'आँगनबाड़ी',
    'आँच',
    'आँचल',
    'आँचलः',
    'आँट',
    'आँटसाँट',
    'आँटी',
    'आँठी',
    'आँड़',
    'आँडू',
    'आँत',
    'आँतर',
    'आँतें',
    'आँधी',
    'आँबी',
    'आँय',
    'आँव',
    'आँवठ',
    'आँवन',
    'आँवल',
    'आँवला',
    'आँसू',
    'आँहाँ',
    'आंकड़ा',
    'आंकड़े',
    'आंकड़ों',
    'आंकडे',
    'आंकना',
    'आंकलक',
    'आंकलन',
    'आंका',
    'आंकाक्षा',
    'आंकिक',
    'आंख',
    'आंखें',
    'आंखों',
    'आंग',
    'आंगन',
    'आंगविद्य',
    'आंगिक',
    'आंगिरस',
    'आंचल',
    'आंचलिक',
    'आंचलिकता',
    'आंछन',
    'आंजनेय',
    'आंड',
    'आंडज',
    'आंत',
    'आंतर',
    'आंतरिक',
    'आंतरिकतः',
    'आंतरिकतम',
    'आंतरिकता',
    'आंतरिक्ष',
    'आंतिक',
    'आंतें',
    'आंतों',
    'आंत्र',
    'आंत्रप्रनेर',
    'आंत्रिकी',
    'आंत्रीय',
    'आंदोल',
    'आंदोलक',
    'आंदोलन',
    'आंदोलनकारी',
    'आंदोलनहीनता',
    'आंदोलनीय',
    'आंदोलित',
    'आंदोलितता',
    'आंधर',
    'आंधी',
    'आंध्र',
    'आंब',
    'आंबटिती',
    'आंबिकेय',
    'आंमत्रित',
    'आंरभिक',
    'आंव',
    'आंवला',
    'आंशका',
    'आंशिक',
    'आंशिकघात',
    'आंशिकता',
    'आंसर',
    'आंसू',
    'आइंदा',
    'आइंस्टीनी',
    'आइजोल',
    'आइटनरेरी',
    'आइटम',
    'आइडैंटिटी',
    'आइना',
    'आइये',
    'आइल',
    'आइलैंड',
    'आइवरी',
    'आइस',
    'आइसक्ररीम',
    'आइसक्रीम',
    'आइसथर्म',
    'आइसबोट',
    'आइसोटोन',
    'आइसोटोप',
    'आई',
    'आईऐसआई',
    'आईओयू',
    'आईन',
    'आईनी',
    'आउंस',
    'आउट',
    'आउटफ्रिट',
    'आउन्स',
    'आए',
    'आएं',
    'आएगा',
    'आक',
    'आकंठ',
    'आकंठन',
    'आकंठना',
    'आकंपित',
    'आकट',
    'आकड़े',
    'आकडों',
    'आकर',
    'आकरिक',
    'आकरी',
    'आकरीय',
    'आकर्णन',
    'आकर्णित',
    'आकर्षक',
    'आकर्षकता',
    'आकर्षण',
    'आकर्षणता',
    'आकर्षणहीन',
    'आकर्षणहीनता',
    'आकर्षित',
    'आकर्षितता',
    'आकर्षी',
    'आकलक',
    'आकलजातता',
    'आकलन',
    'आकलित',
    'आकष',
    'आकस्मिक',
    'आकस्मिकता',
    'आकांक्षतः',
    'आकांक्षा',
    'आकांक्षाहीन',
    'आकांक्षाहीनतः',
    'आकांक्षाहीनता',
    'आकांक्षित',
    'आकांक्षी',
    'आकाट',
    'आकार',
    'आकारः',
    'आकारयुक्त',
    'आकारयुक्ततता',
    'आकारवाद',
    'आकारवान',
    'आकारवाली',
    'आकारवाले',
    'आकारहीन',
    'आकारहीनता',
    'आकारिक',
    'आकारिकी',
    'आकारीय',
    'आकारों',
    'आकार्य',
    'आकार्यता',
    'आकालिक',
    'आकाश',
    'आकाशगत',
    'आकाशगमिता',
    'आकाशगामिनी',
    'आकाशगामी',
    'आकाशचर',
    'आकाशचरी',
    'आकाशचारी',
    'आकाशचुंबी',
    'आकाशवाणी',
    'आकाशी',
    'आकाशीय',
    'आकाशीयता',
    'आकाशेश',
    'आकिस्मक',
    'आकिस्मकता',
    'आकीर्ण',
    'आकीर्णता',
    'आकुंचन',
    'आकुंचनी',
    'आकुंचित',
    'आकुंठित',
    'आकुल',
    'आकुलता',
    'आकुलताकारी',
    'आकूत',
    'आकूति',
    'आकृत',
    'आकृति',
    'आकृतिः',
    'आकृतिकार',
    'आकृतिमूलक',
    'आकृतियां',
    'आकृतियुक्त',
    'आकृतिविज्ञान',
    'आकृतिहीन',
    'आकृतिहीनता',
    'आकृतीय',
    'आकृष्ट',
    'आक्रंद',
    'आक्रंदन',
    'आक्रन्द',
    'आक्रम',
    'आक्रमण',
    'आक्रमणकारी',
    'आक्रमणातीत',
    'आक्रमणीय',
    'आक्रमित',
    'आक्रम्यता',
    'आक्रय',
    'आक्रयण',
    'आक्रवत',
    'आक्रा',
    'आक्रांत',
    'आक्रांता',
    'आक्रांति',
    'आक्रान्त',
    'आक्रान्तता',
    'आक्रामक',
    'आक्रामकता',
    'आक्रामकतापूर्ण',
    'आक्रीड',
    'आक्रीड़',
    'आक्रीड़ी',
    'आक्रीत',
    'आक्रुष्ट',
    'आक्रोश',
    'आक्रोशित',
    'आक्लेदन',
    'आक्षर',
    'आक्षरिक',
    'आक्षिप्त',
    'आक्षेप',
    'आक्षेपक',
    'आक्षेपकारी',
    'आक्षेपण',
    'आक्षेपित',
    'आक्षेपी',
    'आक्साइड',
    'आक्सीकरण',
    'आक्सीजन',
    'आक्सीजनीकरण',
    'आक्सीडेंट',
    'आख',
    'आखन',
    'आखनिक',
    'आखर',
    'आखरी',
    'आख़िर',
    'आखा',
    'आखात',
    'आखान',
    'आखिर',
    'आखिरकार',
    'आखिरत',
    'आखिरी',
    'आखु',
    'आखुघात',
    'आखुभोजी',
    'आखुवाहन',
    'आखेट',
    'आखेटक',
    'आखेटिक',
    'आखेटीय',
    'आखोर',
    'आख्या',
    'आख्यात',
    'आख्याता',
    'आख्याति',
    'आख्यान',
    'आख्यानक',
    'आख्यापक',
    'आख्यापन',
    'आख्यायक',
    'आख्यायिका',
    'आग',
    'आगंतु',
    'आगंतुक',
    'आगजनी',
    'आगणक',
    'आगत',
    'आगतता',
    'आगति',
    'आगन्तुक',
    'आगन्तुकों',
    'आगम',
    'आगमन',
    'आगमनवादी',
    'आगमनात्मक',
    'आगमनात्मकता',
    'आगमापायी',
    'आगमिक',
    'आगमी',
    'आगमों',
    'आगर',
    'आगरा',
    'आगल',
    'आगस्ती',
    'आगस्त्य',
    'आगा',
    'आगाज',
    'आगापीछा',
    'आगामी',
    'आगार',
    'आगारीय',
    'आगाह',
    'आगाही',
    'आगुच्छन',
    'आगे',
    'आगोश',
    'आग्नीधर',
    'आग्नेय',
    'आग्नेयास्त्र',
    'आग्रह',
    'आग्रहपूर्ण',
    'आग्रही',
    'आगज़नी',
    'आघट्टना',
    'आघराणक',
    'आघराणन',
    'आघराणित',
    'आघर्षण',
    'आघर्षणी',
    'आघाट',
    'आघात',
    'आघातक',
    'आघातवर्धनीय',
    'आघातवर्धनीयता',
    'आघातव्ध्र्य',
    'आघातशील',
    'आघातहीन',
    'आघाती',
    'आघार',
    'आघारभूत',
    'आघुर्णित',
    'आघूर्ण',
    'आघूर्णक',
    'आघोष',
    'आघोषक',
    'आघोषणा',
    'आघोषी',
    'आचक्रक',
    'आचक्रित',
    'आचमन',
    'आचमनक',
    'आचमनीयक',
    'आचय',
    'आचरण',
    'आचरणवान',
    'आचरणहीन',
    'आचरणहीनता',
    'आचरणीय',
    'आचरन',
    'आचरा',
    'आचरित',
    'आचर्य',
    'आचवन',
    'आचाम',
    'आचार',
    'आचारज',
    'आचारभ्रष्ट',
    'आचारभ्रष्टता',
    'आचारयान',
    'आचारवान',
    'आचारशून्य',
    'आचारहीन',
    'आचारी',
    'आचार्य',
    'आचार्यक',
    'आचूषक',
    'आचूषण',
    'आचूषित',
    'आच्छद',
    'आच्छन्न',
    'आच्छन्नता',
    'आच्छाजन',
    'आच्छाजित',
    'आच्छाद',
    'आच्छादक',
    'आच्छादकर',
    'आच्छादन',
    'आच्छादित',
    'आच्छादी',
    'आच्दाजित',
    'आछत्रण',
    'आछन्न',
    'आज',
    'आजकल',
    'आजगव',
    'आजनि',
    'आजन्म',
    'आजम',
    'आजमाइश',
    'आजमाइशी',
    'आजमाना',
    'आजमाया',
    'आजमूदा',
    'आज़म',
    'आज़माइश',
    'आज़माई',
    'आज़माना',
    'आजा',
    'आजाद',
    'आजादाना',
    'आजादी',
    'आजानि',
    'आजानुबाहु',
    'आजि',
    'आजिज',
    'आजिजी',
    'आजी',
    'आजीव',
    'आजीवक',
    'आजीवन',
    'आजीविका',
    'आजीविकायुक्त',
    'आजीविकाहीन',
    'आजीविकाहीनता',
    'आजीविकीय',
    'आजुल',
    'आजू',
    'आज्ञप्त',
    'आज्ञप्ति',
    'आज्ञा',
    'आज्ञाकारिता',
    'आज्ञाकारी',
    'आज्ञात्मक',
    'आज्ञाधीन',
    'आज्ञाधीनता',
    'आज्ञानुवर्तन',
    'आज्ञानुसार',
    'आज्ञानुसारतः',
    'आज्ञापक',
    'आज्ञापत्र',
    'आज्ञापरायण',
    'आज्ञापालक',
    'आज्ञापालन',
    'आज्ञापित',
    'आज्ञापूर्ण',
    'आज्ञापूर्वक',
    'आज्ञार्थक',
    'आज्य',
    'आटना',
    'आटविक',
    'आटा',
    'आटि',
    'आटे',
    'आटोप',
    'आटोमोबाईल',
    'आटोवाहन',
    'आट्र्स',
    'आठ',
    'आठवाँ',
    'आठवां',
    'आठें',
    'आठैं',
    'आठों',
    'आडंबर',
    'आडंबरपूर्ण',
    'आडंबरहीन',
    'आडंबरी',
    'आडंबरोक्ति',
    'आडम्बर',
    'आडम्बरता',
    'आडम्बरपूर्ण',
    'आडम्बरप्रिय',
    'आडम्बरहीन',
    'आडम्बरी',
    'आड़',
    'आड़दार',
    'आड़मान',
    'आड़ा',
    'आड़ापन',
    'आड़ी',
    'आड़ू',
    'आड़े',
    'आडि',
    'आडिटोरियम',
    'आडू',
    'आढ',
    'आढय',
    'आढयंकर',
    'आढयता',
    'आढ़त',
    'आढ़तदार',
    'आढ़तिया',
    'आढ़ती',
    'आणक',
    'आणय',
    'आणविक',
    'आणि',
    'आणी',
    'आतंक',
    'आतंकपूर्ण',
    'आतंकवाद',
    'आतंकवादी',
    'आतंकानुभूति',
    'आतंकित',
    'आतंचन',
    'आतंचित',
    'आतंरिक',
    'आततायी',
    'आतन',
    'आतप',
    'आतपन',
    'आतप्त',
    'आतप्तता',
    'आतर्पण',
    'आतशखाना',
    'आतशी',
    'आता',
    'आतापि',
    'आतापी',
    'आताम्र',
    'आतिथेय',
    'आतिथ्य',
    'आतिवाहिक',
    'आतिश',
    'आतिशगर',
    'आतिशगाह',
    'आतिशजन',
    'आतिशपरस्त',
    'आतिशबाज',
    'आतिशबाजी',
    'आतिशबाज़ी',
    'आती',
    'आतुर',
    'आतुरता',
    'आते',
    'आतें',
    'आत्त',
    'आत्तगर्य',
    'आत्म',
    'आत्मंभरि',
    'आत्मक',
    'आत्मकथन',
    'आत्मकथा',
    'आत्मकथात्मक',
    'आत्मकल्याण',
    'आत्मकृत',
    'आत्मकृततः',
    'आत्मकेंद्रता',
    'आत्मकेंद्रित',
    'आत्मक्षमता',
    'आत्मक्षमताः',
    'आत्मगौरव',
    'आत्मग्राही',
    'आत्मघात',
    'आत्मघातक',
    'आत्मघाती',
    'आत्मघोष',
    'आत्मघोषित',
    'आत्मचरित',
    'आत्मचरित्र',
    'आत्मचालित',
    'आत्मचित्र',
    'आत्मज',
    'आत्मजता',
    'आत्मजन',
    'आत्मजनीय',
    'आत्मजय',
    'आत्मजा',
    'आत्मजेता',
    'आत्मज्ञान',
    'आत्मज्ञानजन्य',
    'आत्मज्ञानी',
    'आत्मज्योति',
    'आत्मतः',
    'आत्मता',
    'आत्मतोषी',
    'आत्मत्याग',
    'आत्मत्यागी',
    'आत्मत्वयुक्त',
    'आत्मदमन',
    'आत्मदर्शन',
    'आत्मदर्शी',
    'आत्मदान',
    'आत्मद्रष्टा',
    'आत्मद्रोही',
    'आत्मद्वेषी',
    'आत्मनिग्रह',
    'आत्मनिग्रही',
    'आत्मनियंत्रण',
    'आत्मनियंत्रणहीनता',
    'आत्मनियंत्रित',
    'आत्मनिरीक्षण',
    'आत्मनिर्भर',
    'आत्मनिर्भरता',
    'आत्मनिवेदन',
    'आत्मनिषेध',
    'आत्मनिष्ठ',
    'आत्मनेपदी',
    'आत्मपरीक्षक',
    'आत्मपरीक्षा',
    'आत्मपीडनोन्माद',
    'आत्मपीड़क',
    'आत्मपीड़न',
    'आत्मप्रतिष्ठा',
    'आत्मप्रदर्शन',
    'आत्मप्रशंसक',
    'आत्मप्रशंसा',
    'आत्मप्रशंसी',
    'आत्मप्रेक्षण',
    'आत्मबल',
    'आत्मबलि',
    'आत्मबलिदान',
    'आत्मबलिदानी',
    'आत्मबोध',
    'आत्मभर',
    'आत्मभू',
    'आत्ममंथन',
    'आत्ममान',
    'आत्ममानी',
    'आत्ममोह',
    'आत्मयोनि',
    'आत्मरक्षक',
    'आत्मरक्षा',
    'आत्मरक्षी',
    'आत्मरति',
    'आत्मरहित',
    'आत्मरुप',
    'आत्मलयन',
    'आत्मलीन',
    'आत्मलीनता',
    'आत्मवाद',
    'आत्मवादी',
    'आत्मविचारण',
    'आत्मविश्वास',
    'आत्मविश्वासपूर्ण',
    'आत्मविश्वासपूर्णता',
    'आत्मविश्वासहीन',
    'आत्मविश्वासहीनता',
    'आत्मविश्वासी',
    'आत्मविस्मरण',
    'आत्मविस्मृत',
    'आत्मविस्मृति',
    'आत्मशत्रु',
    'आत्मशुद्धि',
    'आत्मशोधन',
    'आत्मश्लाघा',
    'आत्मश्लाघी',
    'आत्मसंतापन',
    'आत्मसंतुष्टता',
    'आत्मसंभव',
    'आत्मसंयम',
    'आत्मसंयमी',
    'आत्मसंशयी',
    'आत्मसंसूचन',
    'आत्मसन्तुष्ट',
    'आत्मसन्तुष्टतापूर्वक',
    'आत्मसन्तोष',
    'आत्मसमर्पक',
    'आत्मसमर्पण',
    'आत्मसम्मान',
    'आत्मसाक्षात्कार',
    'आत्मसात',
    'आत्मसात्',
    'आत्मसात्करण',
    'आत्मसिद्धि',
    'आत्मसुखाय',
    'आत्मसुजीविता',
    'आत्मस्थ',
    'आत्मस्वीकृत',
    'आत्मस्वीकृति',
    'आत्महंता',
    'आत्महत्या',
    'आत्महा',
    'आत्महित',
    'आत्मा',
    'आत्माः',
    'आत्माधिक',
    'आत्माधीन',
    'आत्माधीनता',
    'आत्मानंद',
    'आत्मानुभूति',
    'आत्मानुशासन',
    'आत्मानुशासित',
    'आत्मानुशासी',
    'आत्माभमुखी',
    'आत्माभिमान',
    'आत्माभिमानी',
    'आत्माभिमुखता',
    'आत्माभिव्यक्ति',
    'आत्मावलंबी',
    'आत्माशास्त्र',
    'आत्माशी',
    'आत्माश्रयदोष',
    'आत्माहीन',
    'आत्मिक',
    'आत्मीय',
    'आत्मीयकरण',
    'आत्मीयकृत',
    'आत्मीयता',
    'आत्मीयतापूर्ण',
    'आत्मीयताहीन',
    'आत्मीया',
    'आत्मोत्सर्ग',
    'आत्मोद्भव',
    'आत्मोद्भवा',
    'आत्मोद्भूत',
    'आत्मोद्भूति',
    'आत्मोपलब्धि',
    'आत्यंतिक',
    'आत्यंतिकता',
    'आत्रेय',
    'आथोराइज',
    'आथोरिटी',
    'आदंशन',
    'आदग्ध',
    'आदग्धक',
    'आदत',
    'आदतन',
    'आदतें',
    'आदतों',
    'आदत्त',
    'आदम',
    'आदमः',
    'आदमक्रद',
    'आदमखोर',
    'आदमखोरी',
    'आदमजाद',
    'आदमन',
    'आदमियों',
    'आदमी',
    'आदमीयत',
    'आदमे',
    'आदर',
    'आदरण',
    'आदरणीय',
    'आदरणीयता',
    'आदरपूर्ण',
    'आदरसत्कार',
    'आदरसूचक',
    'आदरहीन',
    'आदर्श',
    'आदर्शनिष्ठ',
    'आदर्शनीय',
    'आदर्शवाक्य',
    'आदर्शवाद',
    'आदर्शवादहीनता',
    'आदर्शवादिता',
    'आदर्शवादी',
    'आदर्शहीन',
    'आदर्शहीनता',
    'आदर्शीकरण',
    'आदर्शोन्मुख',
    'आदश्र्य',
    'आदहन',
    'आदाता',
    'आदान',
    'आदानित',
    'आदानी',
    'आदानों',
    'आदाब',
    'आदाय',
    'आदायता',
    'आदायाद',
    'आदायी',
    'आदि',
    'आदिः',
    'आदिअंतयुक्त',
    'आदिक',
    'आदिकवि',
    'आदिकाव्य',
    'आदिग्रंथ',
    'आदित',
    'आदितेय',
    'आदित्य',
    'आदित्यः',
    'आदित्यवार',
    'आदिदेय',
    'आदिनाथ',
    'आदिम',
    'आदिमता',
    'आदिमानय',
    'आदियास',
    'आदिराज',
    'आदिल',
    'आदिवास',
    'आदिवासियों',
    'आदिवासी',
    'आदिशेष',
    'आदिष्ट',
    'आदी',
    'आदीपन',
    'आदीप्त',
    'आदूषित',
    'आदेय',
    'आदेयता',
    'आदेयी',
    'आदेवक',
    'आदेवन',
    'आदेश',
    'आदेशक',
    'आदेशदेय',
    'आदेशन',
    'आदेशपत्र',
    'आदेशपूर्ण',
    'आदेशपूर्वक',
    'आदेशात्मक',
    'आदेशानुसार',
    'आदेशार्थ',
    'आदेशिका',
    'आदेशित',
    'आदेशिततः',
    'आदेशिती',
    'आदेशी',
    'आदेशों',
    'आदेष्टा',
    'आद्य',
    'आद्यंग',
    'आद्यंत',
    'आद्यंतता',
    'आद्यंतयुक्त',
    'आद्यंतयुक्तता',
    'आद्यंतहीन',
    'आद्यंतहीनता',
    'आद्यक्षर',
    'आद्यता',
    'आद्यप्ररूप',
    'आद्यप्ररूपीय',
    'आद्या',
    'आद्योपांत',
    'आद्योपांततः',
    'आद्योपांतता',
    'आध',
    'आधरण',
    'आधरभूत',
    'आधर्ता',
    'आधर्य',
    'आधा',
    'आधात्री',
    'आधान',
    'आधानक',
    'आधानन',
    'आधानित',
    'आधानी',
    'आधार',
    'आधारक',
    'आधारच्युत',
    'आधारण',
    'आधारणीय',
    'आधारतत्व',
    'आधारपूर्ण',
    'आधारपूर्णता',
    'आधारभूत',
    'आधारयुक्त',
    'आधारयुक्तता',
    'आधारहीन',
    'आधारहीनता',
    'आधारिक',
    'आधारिका',
    'आधारित',
    'आधारितता',
    'आधारिता',
    'आधार्य',
    'आधासीसी',
    'आधि',
    'आधिकरणिक',
    'आधिकारिक',
    'आधिकारिकतः',
    'आधिक्य',
    'आधिदैविक',
    'आधिपत्य',
    'आधिपत्यधारी',
    'आधिपत्यीय',
    'आधिपाल',
    'आधी',
    'आधीन',
    'आधीरात',
    'आधुनिक',
    'आधुनिकतम',
    'आधुनिकता',
    'आधुनिकतावाद',
    'आधुनिकतावादी',
    'आधुनिका',
    'आधुनिकीकरण',
    'आधूत',
    'आधृत',
    'आधे',
    'आधेय',
    'आध्मात',
    'आध्य',
    'आध्यात्मिक',
    'आध्यात्मिकता',
    'आध्यान',
    'आध्राण',
    'आन',
    'आनंद',
    'आनंदक',
    'आनंदघन',
    'आनंदति',
    'आनंददायक',
    'आनंददायकता',
    'आनंदना',
    'आनंदपूर्ण',
    'आनंदपूर्णता',
    'आनंदप्रद',
    'आनंदप्रदता',
    'आनंदमय',
    'आनंदवर्धन',
    'आनंदवाद',
    'आनंदशील',
    'आनंदहीन',
    'आनंदहीनता',
    'आनंदातिरेक',
    'आनंदानुभूति',
    'आनंदाश्रु',
    'आनंदित',
    'आनंदी',
    'आनंदोत्कर्ष',
    'आनंदोत्सव',
    'आनंदोन्मत्त',
    'आनक',
    'आनचन',
    'आनत',
    'आनति',
    'आनद्घ',
    'आनद्घन',
    'आनन',
    'आननहीन',
    'आनन्द',
    'आनन्दकर',
    'आनन्दगान',
    'आनन्ददायक',
    'आनन्ददायी',
    'आनन्दपूर्वक',
    'आनन्दप्रद',
    'आनन्दमग्न',
    'आनन्दमय',
    'आनन्दमयता',
    'आनन्दरहित',
    'आनन्दविभोर',
    'आनन्दित',
    'आनन्दोत्सव',
    'आनमन',
    'आनम्यता',
    'आनयन',
    'आनर्त',
    'आनर्तक',
    'आनर्तन',
    'आना',
    'आनाः',
    'आनाकानी',
    'आनावरी',
    'आनिल',
    'आनिवेश',
    'आनी',
    'आनीत',
    'आनील',
    'आनुक्रम',
    'आनुक्रमिक',
    'आनुक्रमिकता',
    'आनुगत्य',
    'आनुतोषिक',
    'आनुपतिक',
    'आनुपदिक',
    'आनुपातिक',
    'आनुपूर्व',
    'आनुपूर्वता',
    'आनुभाविक',
    'आनुमानिक',
    'आनुयूम',
    'आनुयोगी',
    'आनुलोमिक',
    'आनुवंशिक',
    'आनुवंशिकता',
    'आनुवंशिकी',
    'आनुवंशिकीविद',
    'आनुवांशिकी',
    'आनुवांशिकीवाद',
    'आनुषंगिक',
    'आनुषंगिकता',
    'आनुषांगिकता',
    'आनुसार',
    'आनूप',
    'आने',
    'आनेता',
    'आनेवाला',
    'आनेवाली',
    'आनेवाले',
    'आन्तरिक',
    'आन्त्र',
    'आन्त्रेर',
    'आन्दोलन',
    'आन्दोलनकर्त्ता',
    'आन्द्रा',
    'आन्ध्र',
    'आन्नद',
    'आन्यीक्षिकी',
    'आन्वयिक',
    'आप',
    'आपः',
    'आपका',
    'आपकी',
    'आपके',
    'आपको',
    'आपगा',
    'आपट्ट',
    'आपट्टित',
    'आपणिक',
    'आपण्य',
    'आपतन',
    'आपतिक',
    'आपत्कल्प',
    'आपत्ति',
    'आपत्तिकर्ता',
    'आपत्तिजनक',
    'आपत्तिजनकता',
    'आपत्तियां',
    'आपत्तिशील',
    'आपत्तिहीन',
    'आपद',
    'आपदता',
    'आपदा',
    'आपदाग्रस्त',
    'आपदादा',
    'आपदापूर्ण',
    'आपदाहीनता',
    'आपद्',
    'आपद्ग्रस्त',
    'आपनिक',
    'आपनित',
    'आपने',
    'आपन्न',
    'आपबीती',
    'आपराधिक',
    'आपरेट',
    'आपरेटर',
    'आपरेशन',
    'आपर्ति',
    'आपस',
    'आपसदारी',
    'आपसी',
    'आपसे',
    'आपस्',
    'आपस्तंब',
    'आपा',
    'आपाआपी',
    'आपात',
    'आपातकालीन',
    'आपातन',
    'आपाती',
    'आपात्',
    'आपादन',
    'आपाधापी',
    'आपान',
    'आपानक',
    'आपानन',
    'आपानित',
    'आपिंजर',
    'आपित्तजनक',
    'आपीड',
    'आपीड़न',
    'आपीत',
    'आपीन',
    'आपूर',
    'आपूरण',
    'आपूर्ण',
    'आपूर्त',
    'आपूर्ति',
    'आपूर्तिकार',
    'आपूष',
    'आपृच्छक',
    'आपृच्छा',
    'आपृच्छित',
    'आपृच्दन',
    'आपृष्ठशायी',
    'आपृष्ठाशय',
    'आपे',
    'आपेक्षिक',
    'आपेक्षित',
    'आपेनर',
    'आपेरा',
    'आपो',
    'आप्त',
    'आप्तकाम',
    'आप्तगर्भा',
    'आप्तता',
    'आप्तमान',
    'आप्तवचन',
    'आप्तवाद',
    'आप्तागम',
    'आप्ति',
    'आप्य',
    'आप्रवास',
    'आप्रवासन',
    'आप्रवासी',
    'आप्रवाह',
    'आप्रविष्ट',
    'आप्रवेश',
    'आप्रवेश्ति',
    'आप्रश्न',
    'आप्रश्नन',
    'आप्रिश्नत',
    'आप्लाय',
    'आप्लायन',
    'आप्लावन',
    'आप्लावित',
    'आप्लुत',
    'आफ',
    'आफजाही',
    'आफ़',
    'आफ़िस',
    'आफ़िसर',
    'आफिस',
    'आफिसर',
    'आफ्र',
    'आफ्रत',
    'आफ्रताब',
    'आफ्रताबा',
    'आफ्रताबी',
    'आफ्ररी',
    'आफ्रिस',
    'आफ्रिसर',
    'आब',
    'आबंटक',
    'आबंटन',
    'आबंटनों',
    'आबंटित',
    'आबंध',
    'आबंधन',
    'आबकार',
    'आबकारी',
    'आबखर्दा',
    'आबखोरा',
    'आबगीर',
    'आबगुल',
    'आबचक',
    'आबताब',
    'आबदार',
    'आबदोज',
    'आबद्घ',
    'आबद्घक',
    'आबद्घता',
    'आबद्घन',
    'आबद्ध',
    'आबनूस',
    'आबनूसी',
    'आबन्टन',
    'आबन्ध',
    'आबपाशी',
    'आबरु',
    'आबरुदार',
    'आबरुरेजी',
    'आबला',
    'आबशार',
    'आबहवा',
    'आबाद',
    'आबादी',
    'आबिद',
    'आबिर्ट्रेशन',
    'आबिल',
    'आबी',
    'आबीजन',
    'आबू',
    'आबे',
    'आबोदाना',
    'आबोहवा',
    'आब्दिक',
    'आब्दिकी',
    'आभरण',
    'आभा',
    'आभार',
    'आभारी',
    'आभारोक्ति',
    'आभाव',
    'आभाषण',
    'आभास',
    'आभासित',
    'आभासी',
    'आभाहीन',
    'आभाहीनता',
    'आभिजन',
    'आभिजात्य',
    'आभिधानिक',
    'आभिधानिकी',
    'आभिलाषिक',
    'आभिव्यंजित',
    'आभीर',
    'आभुक्त',
    'आभूषण',
    'आभूषणकार',
    'आभूषणहीन',
    'आभूषणहीनता',
    'आभूषित',
    'आभोग',
    'आभोगन',
    'आभोगी',
    'आभोग्य',
    'आभोजी',
    'आभोज्य',
    'आभ्यंतर',
    'आभ्यंतरिक',
    'आभ्यंतरिकता',
    'आभ्रमी',
    'आम',
    'आमंडन',
    'आमंडपन',
    'आमंडित',
    'आमंत्र',
    'आमंत्रक',
    'आमंत्रण',
    'आमंत्रणा',
    'आमंत्रयिता',
    'आमंत्रित',
    'आमंत्रितगण',
    'आमक',
    'आमखास',
    'आमतौर',
    'आमद',
    'आमदनी',
    'आमदरफ्त',
    'आमध्यस्थीकरण',
    'आमना',
    'आमने',
    'आमन्त्रण',
    'आमन्त्रित',
    'आमफ्रहम',
    'आमय',
    'आमरण',
    'आमलक',
    'आमला',
    'आमलेट',
    'आमवात',
    'आमवातिक',
    'आमातिसार',
    'आमादगी',
    'आमादा',
    'आमाशय',
    'आमाशयी',
    'आमिल',
    'आमिश्र',
    'आमिश्रण',
    'आमिष',
    'आमिषता',
    'आमिषाहारी',
    'आमी',
    'आमीन',
    'आमीलन',
    'आमुक्त',
    'आमुख',
    'आमुदित',
    'आमूल',
    'आमूलचूल',
    'आमूलन',
    'आमूलित',
    'आमेज',
    'आमेलक',
    'आमेलन',
    'आमेलित',
    'आमोचक',
    'आमोचन',
    'आमोचित',
    'आमोद',
    'आमोदक',
    'आमोदन',
    'आमोदपूर्वक',
    'आमोदप्रमोदपूर्ण',
    'आमोदप्रमोदहीन',
    'आमोदप्रमोदहीनता',
    'आमोदप्रिय',
    'आमोदित',
    'आमोदी',
    'आम्नात',
    'आम्नाय',
    'आम्र',
    'आम्रम',
    'आम्रवन',
    'आम्रातक',
    'आय',
    'आयँ',
    'आयँता',
    'आयकर',
    'आयकारी',
    'आयजन्य',
    'आयडिया',
    'आयत',
    'आयतन',
    'आयतनता',
    'आयतनिक',
    'आयतनीय',
    'आयताकार',
    'आयति',
    'आयत्त',
    'आयद',
    'आयन',
    'आयनित',
    'आयनीकरण',
    'आयमी',
    'आयमूलक',
    'आयरन',
    'आयरलाकंड',
    'आयरिश',
    'आयव्ययक',
    'आयस',
    'आयहीन',
    'आया',
    'आयाचक',
    'आयाचन',
    'आयाचना',
    'आयाचित',
    'आयात',
    'आयातक',
    'आयातकर्ता',
    'आयातित',
    'आयातों',
    'आयान',
    'आयाम',
    'आयामत्व',
    'आयामयुक्त',
    'आयामयुक्तता',
    'आयामहीन',
    'आयामहीनता',
    'आयामी',
    'आयामीय',
    'आयामों',
    'आयाराम',
    'आयास',
    'आयासपूर्वक',
    'आयासहीन',
    'आयासहीनतः',
    'आयासित',
    'आयासी',
    'आयी',
    'आयु',
    'आयुः',
    'आयुकाल',
    'आयुक्त',
    'आयुध',
    'आयुधजीवी',
    'आयुधहीन',
    'आयुधांकन',
    'आयुधागार',
    'आयुधिक',
    'आयुधी',
    'आयुधीय',
    'आयुर्द्रव्य',
    'आयुर्विज्ञान',
    'आयुर्वेद',
    'आयुर्वेदाचार्य',
    'आयुर्वेदिक',
    'आयुष्कांमी',
    'आयुष्काम',
    'आयुष्कामना',
    'आयुष्टोम',
    'आयुष्पति',
    'आयुष्मान',
    'आयुष्य',
    'आयुष्यता',
    'आयूषम',
    'आये',
    'आयेंगे',
    'आयेगा',
    'आयो',
    'आयोग',
    'आयोजक',
    'आयोजन',
    'आयोजना',
    'आयोजनाश्रम',
    'आयोजनों',
    'आयोजित',
    'आयोडीन',
    'आर',
    'आरंजक',
    'आरंजकता',
    'आरंजित',
    'आरंपार',
    'आरंपारता',
    'आरंब्ध',
    'आरंभ',
    'आरंभः',
    'आरंभक',
    'आरंभण',
    'आरंभतः',
    'आरंभयुक्त',
    'आरंभहीन',
    'आरंभहीनता',
    'आरंभांतता',
    'आरंभिक',
    'आरंभिकता',
    'आरंभित',
    'आरंभीय',
    'आरंम्भिक',
    'आरऐसवीपी',
    'आरकाटी',
    'आरकेस्ट्र',
    'आरकेस्ट्रा',
    'आरक्त',
    'आरक्तक',
    'आरक्षक',
    'आरक्षण',
    'आरक्षणीय',
    'आरक्षा',
    'आरक्षित',
    'आरक्षी',
    'आरगजा',
    'आरग्वध',
    'आरज़ू',
    'आरजू',
    'आरजूमंद',
    'आरटीओ',
    'आरडी',
    'आरण्य',
    'आरण्यक',
    'आरण्यकः',
    'आरत',
    'आरता',
    'आरति',
    'आरती',
    'आरपार',
    'आरपारता',
    'आरबिट्रेशन',
    'आरब्ध',
    'आरब्धक',
    'आरब्धन',
    'आरब्धा',
    'आरभट',
    'आरभटी',
    'आरमपार',
    'आरम्भ',
    'आरयक',
    'आरव',
    'आरसी',
    'आरा',
    'आराकश',
    'आराकाटी',
    'आराघर',
    'आरात्रिक',
    'आराधक',
    'आराधन',
    'आराधना',
    'आराधनीय',
    'आराधिका',
    'आराधित',
    'आराध्य',
    'आराम',
    'आरामगाह',
    'आरामतलब',
    'आरामदान',
    'आरामदायक',
    'आरामदायकता',
    'आरामदायकपन',
    'आरामदेह',
    'आरामदेही',
    'आरामपसन्द',
    'आरामिक',
    'आराव',
    'आराहण',
    'आरिंभक',
    'आरिज',
    'आरी',
    'आरुक',
    'आरुढ़',
    'आरुढ़ि',
    'आरुप',
    'आरुपण',
    'आरुपित',
    'आरुष्ट',
    'आरुह',
    'आरूप',
    'आरेख',
    'आरेखकर',
    'आरेखण',
    'आरेखन',
    'आरेखी',
    'आरेखीय',
    'आरोग्य',
    'आरोग्यकर',
    'आरोग्यता',
    'आरोग्यपूर्ण',
    'आरोग्यप्रद',
    'आरोग्यप्रदता',
    'आरोग्यहर',
    'आरोग्योन्मुख',
    'आरोध',
    'आरोप',
    'आरोपक',
    'आरोपग्रस्त',
    'आरोपण',
    'आरोपणीय',
    'आरोपवाद',
    'आरोपित',
    'आरोपी',
    'आरोह',
    'आरोहक',
    'आरोहण',
    'आरोहणिका',
    'आरोहणी',
    'आरोहतः',
    'आरोहशील',
    'आरोहित',
    'आरोही',
    'आर्क',
    'आर्कटिक',
    'आर्कप्रीस्ट',
    'आर्कबिशप',
    'आर्कषक',
    'आर्काइव्ज्र',
    'आर्किटेक्ट',
    'आर्किड',
    'आर्किपेलागो',
    'आर्कियोलाजी',
    'आर्किविस्ट',
    'आर्केड',
    'आर्गन',
    'आर्गनाइजर',
    'आर्गनाइजेशन',
    'आर्गैनिक',
    'आर्जय',
    'आर्जुनि',
    'आर्ट',
    'आर्टपीस',
    'आर्टवर्क',
    'आर्टस',
    'आर्टिकल',
    'आर्टिलरी',
    'आर्टिस्ट',
    'आर्टीफ्रिशल',
    'आर्डर',
    'आर्डरों',
    'आर्डिनैस',
    'आर्त',
    'आर्तनाद',
    'आर्तनादी',
    'आर्तबंधु',
    'आर्तव',
    'आर्तवी',
    'आर्थिक',
    'आर्थिकेतर',
    'आर्थी',
    'आर्दक',
    'आर्द्र',
    'आर्द्रज',
    'आर्द्रण',
    'आर्द्रता',
    'आर्द्रताग्राही',
    'आर्द्रतामापी',
    'आर्द्रतामिति',
    'आर्द्रतारुद्घ',
    'आर्द्रतारोधक',
    'आर्द्रव',
    'आर्द्रा',
    'आर्द्रीकरण',
    'आर्ममेंट',
    'आर्मर्ड',
    'आर्मामेंट',
    'आर्मी',
    'आर्मेचर',
    'आर्म्स',
    'आर्य',
    'आर्यक',
    'आर्यका',
    'आर्यपत्नी',
    'आर्यव',
    'आर्यवाद',
    'आर्यवृत्त',
    'आर्या',
    'आर्यावर्त',
    'आर्यावर्तीय',
    'आर्याष्टांग',
    'आर्युविज्ञान',
    'आर्ष',
    'आर्षेय',
    'आर्हत',
    'आल',
    'आलंकारिक',
    'आलंकारिकता',
    'आलंग',
    'आलंब',
    'आलंबक',
    'आलंबन',
    'आलंबनशील',
    'आलंबनहीन',
    'आलंबनहीनता',
    'आलंबित',
    'आलंबी',
    'आलंभन',
    'आलओवर',
    'आलकस',
    'आलक्षक',
    'आलक्षण',
    'आलक्षी',
    'आलजाल',
    'आलत',
    'आलता',
    'आलथी',
    'आलन',
    'आलपिन',
    'आलपीन',
    'आलबाल',
    'आलब्ध',
    'आलब्धता',
    'आलब्धन',
    'आलब्धा',
    'आलब्धि',
    'आलभ्यता',
    'आलम',
    'आलमटोल',
    'आलमारी',
    'आलमी',
    'आलय',
    'आलयी',
    'आलर्क',
    'आलवाल',
    'आलस',
    'आलसी',
    'आलसीपन',
    'आलस्य',
    'आलस्ययुक्त',
    'आलस्यहीन',
    'आलस्यहीनतः',
    'आला',
    'आलात',
    'आलाते',
    'आलान',
    'आलाप',
    'आलापक',
    'आलापचारी',
    'आलापन',
    'आलापना',
    'आलापनीय',
    'आलापप्रिय',
    'आलापयुक्त',
    'आलापी',
    'आलाबू',
    'आलारासी',
    'आलि',
    'आलिंग',
    'आलिंगन',
    'आलिंजर',
    'आलिखित',
    'आलिज्जत',
    'आलिन्द',
    'आलिप्त',
    'आलिप्तता',
    'आलिप्सा',
    'आलिप्सु',
    'आलिम',
    'आली',
    'आलीजाह',
    'आलीढ़',
    'आलीन',
    'आलीनक',
    'आलीशान',
    'आलुंचन',
    'आलुब्ध',
    'आलुब्धन',
    'आलुभन',
    'आलुलित',
    'आलू',
    'आलूचा',
    'आलूबुखारा',
    'आलेख',
    'आलेखक',
    'आलेखन',
    'आलेखनी',
    'आलेखनीय',
    'आलेखितता',
    'आलेखीय',
    'आलेख्य',
    'आलेप',
    'आलेपक',
    'आलेपन',
    'आलेपित',
    'आलों',
    'आलोक',
    'आलोकक',
    'आलोकन',
    'आलोकनीय',
    'आलोकहीन',
    'आलोकहृत',
    'आलोकिक',
    'आलोकित',
    'आलोचक',
    'आलोचन',
    'आलोचना',
    'आलोचनात्मक',
    'आलोच्य',
    'आलोड़न',
    'आलोड़ना',
    'आलोड़ितता',
    'आलोभन',
    'आल्टर',
    'आल्टरनेट',
    'आल्टरनेटिंग',
    'आल्माइटी',
    'आल्हा',
    'आवंटन',
    'आवंटित',
    'आवंटिती',
    'आवंदन',
    'आवक',
    'आवक्ष',
    'आवचक',
    'आवती',
    'आवधिकता',
    'आवन',
    'आवभगत',
    'आवर',
    'आवरक',
    'आवरण',
    'आवरणकर्त्ता',
    'आवरणपूर्ण',
    'आवरणहीन',
    'आवरणहीनता',
    'आवरणहीना',
    'आवरणों',
    'आवरिका',
    'आवरित',
    'आवरिता',
    'आवरी',
    'आवर्णित',
    'आवर्त',
    'आवर्तक',
    'आवर्तकाल',
    'आवर्तन',
    'आवर्तनशील',
    'आवर्तनशीलता',
    'आवर्तनांक',
    'आवर्तनी',
    'आवर्तायन',
    'आवर्तित',
    'आवर्तिता',
    'आवर्तिताः',
    'आवर्तिशील',
    'आवर्ती',
    'आवर्धक',
    'आवर्धन',
    'आवर्धित',
    'आवश्यक',
    'आवश्यकता',
    'आवश्यकताएं',
    'आवश्यकताओं',
    'आवश्यकतानुकूल',
    'आवश्यकतानुकूलता',
    'आवश्यकतानुरुप',
    'आवश्यकतानुसार',
    'आवसथ',
    'आवसथ्य',
    'आवह',
    'आवाँ',
    'आवागमन',
    'आवागमनशील',
    'आवागमनहीनता',
    'आवाज',
    'आवाजाही',
    'आवाजें',
    'आवापन',
    'आवाम',
    'आवारगी',
    'आवारा',
    'आवारागर्द',
    'आवारागर्दी',
    'आवाल',
    'आवास',
    'आवासक',
    'आवासगृह',
    'आवासथ',
    'आवासन',
    'आवासहीन',
    'आवासहीनता',
    'आवासिक',
    'आवासिकता',
    'आवासित',
    'आवासिता',
    'आवासी',
    'आवासीय',
    'आवास्य',
    'आवाहक',
    'आवाहन',
    'आवाहित',
    'आवाज़',
    'आविक',
    'आविद्घ',
    'आविर्भाव',
    'आविर्भूत',
    'आविर्मूल',
    'आविर्मूलन',
    'आविष',
    'आविषता',
    'आविष्करण',
    'आविष्कर्ता',
    'आविष्कार',
    'आविष्कारक',
    'आविष्कारक्षम',
    'आविष्कारशील',
    'आविष्कारशीलता',
    'आविष्कृत',
    'आविष्ट',
    'आविहोर्त्र',
    'आवीत',
    'आवृत',
    'आवृतता',
    'आवृति',
    'आवृत्त',
    'आवृत्ति',
    'आवृत्तिशील',
    'आवृत्तिहीन',
    'आवृत्तिहीनता',
    'आवृष्टि',
    'आवेग',
    'आवेगक',
    'आवेगन',
    'आवेगित',
    'आवेगी',
    'आवेजा',
    'आवेदक',
    'आवेदकता',
    'आवेदन',
    'आवेदनपत्र',
    'आवेदनों',
    'आवेदित',
    'आवेश',
    'आवेशकर',
    'आवेशग्रस्त',
    'आवेशपूर्ण',
    'आवेशहीन',
    'आवेशहीनता',
    'आवेशात्मक',
    'आवेशी',
    'आवेष्टन',
    'आवेष्टित',
    'आव्रजक',
    'आव्रजन',
    'आशंकनीय',
    'आशंकहीन',
    'आशंका',
    'आशंकापूर्ण',
    'आशंकाशील',
    'आशंकाहीन',
    'आशंकाहीनतः',
    'आशंकाहीनता',
    'आशंकित',
    'आशंकी',
    'आशंसी',
    'आशन',
    'आशना',
    'आशनाई',
    'आशय',
    'आशयन',
    'आशयपत्र',
    'आशयित',
    'आशर',
    'आशा',
    'आशागज',
    'आशाजनक',
    'आशातीत',
    'आशातीतता',
    'आशानाश',
    'आशानुकूल',
    'आशानुकूलता',
    'आशानुरुप',
    'आशान्वित',
    'आशापूर्ण',
    'आशापूर्वक',
    'आशाप्रद',
    'आशावाद',
    'आशावादिता',
    'आशावादी',
    'आशावान',
    'आशाहीन',
    'आशाहीनता',
    'आशिंकततः',
    'आशिकतः',
    'आशिक्र',
    'आशिक्राना',
    'आशिक्री',
    'आशित',
    'आशिमा',
    'आशियाना',
    'आशी',
    'आशीर्वचन',
    'आशीर्वाद',
    'आशीर्वादात्मक',
    'आशीष',
    'आशु',
    'आशुकवि',
    'आशुकापी',
    'आशुग',
    'आशुगामी',
    'आशुग्राहिता',
    'आशुचित्र',
    'आशुता',
    'आशुताषिता',
    'आशुतोष',
    'आशुतोषता',
    'आशुनिबंध',
    'आशुपाती',
    'आशुलिपि',
    'आशुलिपिक',
    'आशुलेख',
    'आशुलेखक',
    'आशुलोपी',
    'आशुवक्ता',
    'आशुविश्वासी',
    'आशोधन',
    'आशोधित',
    'आश्चयिर्त',
    'आश्चयिर्तता',
    'आश्चर्य',
    'आश्चर्यः',
    'आश्चर्यकारी',
    'आश्चर्यचकित',
    'आश्चर्यचकितता',
    'आश्चर्यजनक',
    'आश्चर्यजनकता',
    'आश्चर्यपूर्ण',
    'आश्चर्यपूर्णता',
    'आश्चर्यपूर्वक',
    'आश्म',
    'आश्यर्चपूर्वक',
    'आश्यान',
    'आश्रपण',
    'आश्रम',
    'आश्रमवासी',
    'आश्रमिक',
    'आश्रमी',
    'आश्रय',
    'आश्रयण',
    'आश्रयहीन',
    'आश्रयहीनता',
    'आश्रयारण्य',
    'आश्रवण',
    'आश्रित',
    'आश्रितता',
    'आश्रुत',
    'आश्रेय',
    'आश्रेयता',
    'आश्लिष्ट',
    'आश्लेषी',
    'आश्वस्त',
    'आश्वास',
    'आश्वासक',
    'आश्वासन',
    'आश्वासपूर्ण',
    'आश्वासित',
    'आश्विन',
    'आश्विनेय',
    'आषाढस्य',
    'आषाढ़',
    'आषाढ़ा',
    'आषाढाभव',
    'आस',
    'आसंग',
    'आसंगी',
    'आसंजक',
    'आसंजनी',
    'आसंजित',
    'आसंदी',
    'आसंधन',
    'आसक्त',
    'आसक्ततः',
    'आसक्ति',
    'आसक्तिकर',
    'आसक्तिकवाद',
    'आसक्तिपूर्ण',
    'आसक्तिपूर्वक',
    'आसक्तिहीन',
    'आसक्तिहीनता',
    'आसदन',
    'आसन',
    'आसनधारी',
    'आसनन',
    'आसनी',
    'आसनों',
    'आसन्न',
    'आसन्नकाल',
    'आसन्नतः',
    'आसन्नता',
    'आसन्नमृत्यु',
    'आसपास',
    'आसमान',
    'आसमानी',
    'आसरा',
    'आसरित',
    'आसरे',
    'आसव',
    'आसवन',
    'आसवनी',
    'आसवित',
    'आसव्य',
    'आसाढ़',
    'आसादन',
    'आसान',
    'आसानी',
    'आसाबरदार',
    'आसाम',
    'आसार',
    'आसारित',
    'आसावरी',
    'आसावान',
    'आसिया',
    'आसीन',
    'आसीनता',
    'आसीस',
    'आसुत',
    'आसुतों',
    'आसुर',
    'आसुरि',
    'आसुरिकता',
    'आसुरी',
    'आसूचना',
    'आसेध',
    'आसेव',
    'आसेवन',
    'आसेवित',
    'आसेवी',
    'आसेव्य',
    'आस्ट्रेलियन',
    'आस्ट्रेलिया',
    'आस्ट्रेलियाई',
    'आस्तर',
    'आस्तरण',
    'आस्तरित',
    'आस्ताना',
    'आस्तारण',
    'आस्ताव',
    'आस्ति',
    'आस्तिक',
    'आस्तिकता',
    'आस्तिकतावाद',
    'आस्तिक्य',
    'आस्तियाँ',
    'आस्तियां',
    'आस्तियों',
    'आस्तीक',
    'आस्तीन',
    'आस्तीनकोट',
    'आस्त्रव',
    'आस्त्राव',
    'आस्थगन',
    'आस्थगित',
    'आस्था',
    'आस्थापना',
    'आस्थावान',
    'आस्थाहीन',
    'आस्थाहीनता',
    'आस्थिक',
    'आस्पद',
    'आस्य',
    'आस्यंदन',
    'आस्यूत',
    'आस्वनित',
    'आस्वाद',
    'आस्वादक',
    'आस्वादन',
    'आस्वादित',
    'आस्वाद्य',
    'आस्वाद्यता',
    'आह',
    'आहट',
    'आहत',
    'आहति',
    'आहन',
    'आहनगर',
    'आहनगरी',
    'आहनन',
    'आहनी',
    'आहरण',
    'आहरणीय',
    'आहरित',
    'आहर्ता',
    'आहव',
    'आहवन',
    'आहा',
    'आहाँ',
    'आहाता',
    'आहार',
    'आहारशाला',
    'आहारहीन',
    'आहारहीनता',
    'आहारिका',
    'आहारी',
    'आहारीय',
    'आहार्य',
    'आहार्यता',
    'आहार्याभिनय',
    'आहाव',
    'आहिस्ता',
    'आहुत',
    'आहुति',
    'आहू',
    'आहूकार',
    'आहूजा',
    'आहूत',
    'आहूति',
    'आहृत',
    'आह्लाद',
    'आह्लादक',
    'आह्लादकारी',
    'आह्लादन',
    'आह्लादपूर्णता',
    'आह्लादित',
    'आह्वान',
    'आिग्नक',
    'आित्मक',
    'आिश्वन',
    'आज़माइश',
    'आज़माइशी',
    'आफ़त',
    'आफ़िसर',
    'आय़',
    'इ',
    'इँट',
    'इँटकोहरा',
    'इँडुरी',
    'इँदारा',
    'इँधन',
    'इंक',
    'इंकपैड',
    'इंकपौट',
    'इंकार',
    'इंक्रलाब',
    'इंक्रलाबी',
    'इंग',
    'इंगन',
    'इंगला',
    'इंगित',
    'इंगिता',
    'इंगिताभिव्यक्ति',
    'इंगुर',
    'इंगुरौटी',
    'इंग्लैण्ड',
    'इंच',
    'इंचः',
    'इंचाज्र',
    'इंचार्ज',
    'इंची',
    'इंजन',
    'इंजनवाला',
    'इंजिन',
    'इंजीनियर',
    'इंजीनियरिंग',
    'इंजीनियरी',
    'इंजीनियरों',
    'इंजीनियर्स',
    'इंजील',
    'इंजेक्षन',
    'इंजैक्शन',
    'इंटर',
    'इंटरकाम',
    'इंटरनल',
    'इंटरनेट',
    'इंटरनेशनल',
    'इंटरनैट',
    'इंटरलीव',
    'इंटरवल',
    'इंटरव्यू',
    'इंटरव्यूअर',
    'इंटर्नमैंट',
    'इंटैग्रल',
    'इंटैरिम',
    'इंटैलिजैंस',
    'इंटोरा',
    'इंड',
    'इंडक्शन',
    'इंडक्स',
    'इंडस्ट्रियलिस्ट',
    'इंडस्ट्री',
    'इंडिगो',
    'इंडियन',
    'इंडिया',
    'इंडेंटकर्ता',
    'इंडैक्स',
    'इंतकाम',
    'इंतखाब',
    'इंतजाम',
    'इंतजामिया',
    'इंतजामी',
    'इंतजार',
    'इंतजारी',
    'इंतहापसंद',
    'इंतहापसंदी',
    'इंतिक्राल',
    'इंतिहा',
    'इंतिहाई',
    'इंदर',
    'इंदराज',
    'इंदिरा',
    'इंदिराज',
    'इंदिरालय',
    'इंदीवर',
    'इंदु',
    'इंदुकांत',
    'इंदुकांता',
    'इंदुजनक',
    'इंदुजा',
    'इंदुदेश',
    'इंदुभूषण',
    'इंदुमती',
    'इंदुल',
    'इंदुवदन',
    'इंदुवार',
    'इंदुशेखर',
    'इंदूर',
    'इंद्र',
    'इंद्रः',
    'इंद्रकार्मुक',
    'इंद्रकील',
    'इंद्रकृष्ट',
    'इंद्रकृष्टा',
    'इंद्रकेतु',
    'इंद्रगुरु',
    'इंद्रगोप',
    'इंद्रचाप',
    'इंद्रछंद',
    'इंद्रजाल',
    'इंद्रजालग्रस्त',
    'इंद्रजालीय',
    'इंद्रजित',
    'इंद्रदमन',
    'इंद्रदारु',
    'इंद्रदेव',
    'इंद्रद्रोही',
    'इंद्रधनुष',
    'इंद्रधनुषः',
    'इंद्रधनुषी',
    'इंद्रनील',
    'इंद्रनीलक',
    'इंद्रनेत्र',
    'इंद्रपथ',
    'इंद्रपुरी',
    'इंद्रप्रस्थ',
    'इंद्रमह',
    'इंद्रलुप्त',
    'इंद्रलुप्ति',
    'इंद्रवन',
    'इंद्रसुत',
    'इंद्रसेन',
    'इंद्रा',
    'इंद्राणी',
    'इंद्रानुज',
    'इंद्रायुध',
    'इंद्रावसान',
    'इंद्राशन',
    'इंद्रिय',
    'इंद्रियगम्य',
    'इंद्रियग्राह्य',
    'इंद्रियजित',
    'इंद्रियजितता',
    'इंद्रियजीत',
    'इंद्रियदमन',
    'इंद्रिययुक्त',
    'इंद्रियवाद',
    'इंद्रियवान',
    'इंद्रियहीन',
    'इंद्रियाँ',
    'इंद्रियातीत',
    'इंद्रियात्मक',
    'इंद्रियानुभूति',
    'इंद्रियायतन',
    'इंद्रियाराम',
    'इंद्रियार्थ',
    'इंद्रियों',
    'इंद्री',
    'इंद्रीय',
    'इंध',
    'इंधन',
    'इंपोर्ट',
    'इंपोर्टर',
    'इंप्रैशन',
    'इंप्रैस्ट',
    'इंफाल',
    'इंशा',
    'इंश्योरेंस',
    'इंश्योरैंस',
    'इंसर्ट',
    'इंसान',
    'इंसानी',
    'इंसाफ',
    'इंसुलअर',
    'इंसुलेटर',
    'इंस्टीटयूशन',
    'इंस्टीयूट',
    'इंस्ट्रक्टर',
    'इंज़न',
    'इक',
    'इकंग',
    'इकट्टा',
    'इकट्ठ',
    'इकट्ठा',
    'इकट्ठे',
    'इकड़ा',
    'इकतरफा',
    'इकतरफापन',
    'इकतरफ़ा',
    'इकतल्ला',
    'इकतार',
    'इकतारा',
    'इकदाम',
    'इकनाली',
    'इकन्नी',
    'इकपहला',
    'इकपहलू',
    'इकपहिया',
    'इकबारगी',
    'इकबाल',
    'इकबालिया',
    'इकबाली',
    'इकबाले',
    'इकमंजिला',
    'इकमुश्त',
    'इकरवाँ',
    'इकरस',
    'इकराम',
    'इकरामी',
    'इकरार',
    'इकरारनामा',
    'इकलखुरा',
    'इकलखुरापन',
    'इकलड़ी',
    'इकलीसिया',
    'इकलौता',
    'इकलौतापन',
    'इकल्ला',
    'इकसार',
    'इकहरा',
    'इकहरी',
    'इकहरे',
    'इकहेजवाँ',
    'इकहेजिया',
    'इकहेजू',
    'इकाइयां',
    'इकाइयों',
    'इकाई',
    'इकाईवाद',
    'इकीस',
    'इकोतर',
    'इकोत्तर',
    'इक्का',
    'इक्कीस',
    'इक्कीसवी',
    'इक्के',
    'इक्केवान',
    'इक्ट्ठा',
    'इक्षु',
    'इक्षुज',
    'इक्षुरस',
    'इक्ष्वाकु',
    'इखलास',
    'इख्तियार',
    'इख्तिलाफ',
    'इख्तिलाफे',
    'इगारह',
    'इग्यारह',
    'इच्छक',
    'इच्छद',
    'इच्छनीय',
    'इच्छा',
    'इच्छाः',
    'इच्छाएं',
    'इच्छागामी',
    'इच्छाचारी',
    'इच्छाधीन',
    'इच्छानुकूल',
    'इच्छानुकूलतः',
    'इच्छानुपत्र',
    'इच्छानुरुप',
    'इच्छानुवर्ती',
    'इच्छानुसार',
    'इच्छानुसारतः',
    'इच्छापत्र',
    'इच्छापूर्वक',
    'इच्छाबोधक',
    'इच्छामूलक',
    'इच्छारुप',
    'इच्छारुपता',
    'इच्छारुपी',
    'इच्छार्थ',
    'इच्छालु',
    'इच्छावती',
    'इच्छाहीन',
    'इच्छाहीनतः',
    'इच्छाहीनता',
    'इच्छित',
    'इच्छु',
    'इच्छुक',
    'इच्छुकता',
    'इजराइली',
    'इजलास',
    'इजहार',
    'इजाजत',
    'इजाफा',
    'इजार',
    'इजारबंद',
    'इजारा',
    'इजारेदार',
    'इजारेदारी',
    'इजाज़त',
    'इज्जत',
    'इज्जतदार',
    'इज्जती',
    'इज्म',
    'इज्या',
    'इज्राईल',
    'इटली',
    'इटलीयाद',
    'इटा',
    'इटालिक',
    'इटालियन',
    'इटैलिक्स',
    'इट्चर',
    'इठलाऊ',
    'इठलाते',
    'इठलाना',
    'इठलाहट',
    'इड',
    'इडपति',
    'इडविडा',
    'इडा',
    'इत',
    'इतना',
    'इतनापन',
    'इतनी',
    'इतने',
    'इतमीनान',
    'इतर',
    'इतरतः',
    'इतरता',
    'इतरा',
    'इतराऊ',
    'इतराना',
    'इतराहट',
    'इतराज़',
    'इतरेतर',
    'इतरेतरता',
    'इतवार',
    'इतालवी',
    'इति',
    'इतिकरणीय',
    'इतिकरणीयता',
    'इतिकर्तव्य',
    'इतिमात्र',
    'इतिवृत',
    'इतिवृत्त',
    'इतिशेष',
    'इतिहास',
    'इतिहासः',
    'इतिहासज्ञ',
    'इतिहासपरक',
    'इत्तफ्राक्र',
    'इत्तफ्राक्रन',
    'इत्तफ्राक्रिया',
    'इत्तला',
    'इत्ता',
    'इत्तिलानामा',
    'इत्तिहाद',
    'इत्तफ़ाक',
    'इत्यलं',
    'इत्यादि',
    'इत्यादिक',
    'इत्र',
    'इत्रदान',
    'इत्रदानी',
    'इत्वर',
    'इत्वरी',
    'इत्फ़ाकिया',
    'इथ',
    'इदं',
    'इदंतन',
    'इदम्मद',
    'इदारा',
    'इदाराई',
    'इद्घ',
    'इद्दत',
    'इधर',
    'इधरला',
    'इध्म',
    'इन',
    'इनकम',
    'इनका',
    'इनकापोर्रेशन',
    'इनकार',
    'इनकारी',
    'इनकी',
    'इनके',
    'इनको',
    'इनक्वायरी',
    'इनगौट',
    'इनचार्ज',
    'इनचीफ़',
    'इनटोनेशन',
    'इनडाइरैक्ट',
    'इनडैंट',
    'इनडोर',
    'इनन',
    'इनफ्ररैंस',
    'इनफ्रामेंशन',
    'इनफ्रीरियरिटी',
    'इनफ्रैक्शन',
    'इनब्रीडिंग',
    'इनमें',
    'इनर्फौमेंशन',
    'इनर्शियल',
    'इनले',
    'इनलेअर',
    'इनलेड',
    'इनवाइट',
    'इनवाइस',
    'इनविजिलेटर',
    'इनविजिलेशन',
    'इनवैंटर',
    'इनवैंटिड',
    'इनवैंशन',
    'इनवैस्टिगेशन',
    'इनवौइस',
    'इनसर्ट',
    'इनसान',
    'इनसानियत',
    'इनसानी',
    'इनसाफ्र',
    'इनसे',
    'इनसैक्ट',
    'इनसैट',
    'इनसैनिटी',
    'इना',
    'इनाम',
    'इनामदार',
    'इनामल',
    'इनामी',
    'इनायत',
    'इनारा',
    'इनिंग',
    'इनिर्ग्स',
    'इने',
    'इनोगैंनिक',
    'इन्कार',
    'इन्क्यूबस',
    'इन्क्वायरी',
    'इन्चार्ज',
    'इन्जीनियर',
    'इन्जेक्षन',
    'इन्टर',
    'इन्डेट',
    'इन्तजाम',
    'इन्तजार',
    'इन्दौर',
    'इन्द्रजाल',
    'इन्द्रधनुष',
    'इन्द्रिय',
    'इन्द्रियगम्य',
    'इन्द्रियगम्यता',
    'इन्द्रियगम्यतापूर्वक',
    'इन्द्रियजन्य',
    'इन्द्रियनिष्ठता',
    'इन्द्रियागोचर',
    'इन्द्रियानुभववादी',
    'इन्द्रियानुभाविक',
    'इन्द्रियार्थवाद',
    'इन्द्रियार्थवादी',
    'इन्द्रियों',
    'इन्फरोमैंटिक्स',
    'इन्वैस्टमैंट',
    'इन्सपैक्टर',
    'इन्सान',
    'इन्सुलिन',
    'इन्ही',
    'इन्हीं',
    'इन्हें',
    'इन्हों',
    'इफलास',
    'इफ्तारी',
    'इफ्रतार',
    'इफ्रतारना',
    'इफ्रतारी',
    'इफ्ररात',
    'इफ्रलास',
    'इब',
    'इबतिदाई',
    'इबरत',
    'इबलीस',
    'इबादतगार',
    'इबादतगाह',
    'इबारत',
    'इब्तिदा',
    'इब्न',
    'इब्ने',
    'इब्राहिम',
    'इभ',
    'इभराज',
    'इभानन',
    'इभी',
    'इभोषणा',
    'इभ्य',
    'इभ्या',
    'इमकान',
    'इमदाद',
    'इमदादी',
    'इमरतबान',
    'इमला',
    'इमली',
    'इमल्शन',
    'इमान',
    'इमाम',
    'इमामः',
    'इमारत',
    'इमारती',
    'इमि',
    'इमिग्रेशन',
    'इमिग्रैंट',
    'इमेज',
    'इम्तहान',
    'इम्तिहान',
    'इम्यू',
    'इयत',
    'इयत्ता',
    'इयर',
    'इरला',
    'इरा',
    'इरादतन',
    'इरादा',
    'इरादे',
    'इरायान',
    'इरावती',
    'इरावतीः',
    'इरिण',
    'इरे',
    'इरेश',
    'इर्दगिर्द',
    'इर्वारु',
    'इलजाम',
    'इलबिला',
    'इलल्लाह',
    'इलस्ट्रेटर',
    'इलस्ट्रेशन',
    'इलहाम',
    'इलहामी',
    'इला',
    'इलाक़ा',
    'इलाका',
    'इलाकों',
    'इलाक्रा',
    'इलाक्राई',
    'इलाज',
    'इलाधर',
    'इलायची',
    'इलास्टिक',
    'इलास्टिसिटी',
    'इलाह',
    'इलाहाबाद',
    'इलाहियात',
    'इलाही',
    'इली',
    'इलीश',
    'इलूजन',
    'इलेक्ट्रानिक',
    'इलेक्ट्रानिकी',
    'इलेक्ट्रॉन',
    'इलैक्ट्रा',
    'इलैक्ट्रास्टैट',
    'इलैक्ट्रिक',
    'इलैक्ट्रिसिटी',
    'इलैक्ट्रौन',
    'इलैक्शन',
    'इल्जाम',
    'इल्तिजा',
    'इल्म',
    'इल्मी',
    'इल्यूमिनो',
    'इल्लत',
    'इल्ला',
    'इल्ली',
    'इल्वल',
    'इलज़ाम',
    'इव',
    'इवादकदा',
    'इवादत',
    'इवोल्यूशन',
    'इशरतगाह',
    'इशा',
    'इशारा',
    'इशारे',
    'इशू',
    'इश्कबाजी',
    'इश्कबाज़',
    'इश्क्र',
    'इश्क्रबाज',
    'इश्क्रबाजी',
    'इश्तहार',
    'इश्तियाक्र',
    'इश्तिहार',
    'इश्योरेंस',
    'इषु',
    'इषुधंर',
    'इषुधि',
    'इष्ट',
    'इष्टकर',
    'इष्टचिंतक',
    'इष्टतम',
    'इष्टता',
    'इष्टदेव',
    'इष्टलाभ',
    'इष्टि',
    'इष्टिका',
    'इष्टिकान्यास',
    'इष्टिपशु',
    'इष्यास',
    'इष्र्यालु',
    'इस',
    'इसका',
    'इसकी',
    'इसके',
    'इसको',
    'इसपात',
    'इसबगोल',
    'इसमाईली',
    'इसमें',
    'इसरार',
    'इसलाम',
    'इसलामवाद',
    'इसलामी',
    'इसलाह',
    'इसलिए',
    'इसलिये',
    'इससे',
    'इसाइयों',
    'इसी',
    'इसीलिए',
    'इसीलिये',
    'इसे',
    'इस्तगासा',
    'इस्तरी',
    'इस्तिगासा',
    'इस्तिबाग',
    'इस्तिरी',
    'इस्तिलाही',
    'इस्तीफा',
    'इस्तेमाल',
    'इस्तेमालकर्त्ता',
    'इस्तेमालशुदा',
    'इस्त्री',
    'इस्पंज',
    'इस्पनाक',
    'इस्पात',
    'इस्फरंजा',
    'इस्म',
    'इस्माइली',
    'इस्लाम',
    'इस्लामवाद',
    'इस्लामाबाद',
    'इस्लाह',
    'इह',
    'इहकाल',
    'इहकालीन',
    'इहलोक',
    'इहलोकवाद',
    'इहलौकिक',
    'इहलौकिकता',
    'इहलौकिता',
    'इहाँ',
    'इहामुत्र',
    'इहुँ',
    'ई',
    'ईंगुर',
    'ईंच',
    'ईंचें',
    'ईंजन',
    'ईंट',
    'ईंटः',
    'ईंटा',
    'ईंटों',
    'ईंधन',
    'ईकाईयों',
    'ईकोर',
    'ईक्विटी',
    'ईक्षण',
    'ईक्षणिक',
    'ईख',
    'ईखः',
    'ईगल',
    'ईगो',
    'ईजाद',
    'ईजान',
    'ईजी',
    'ईट',
    'ईडीपस',
    'ईडुरी',
    'ईथर',
    'ईद',
    'ईदः',
    'ईदी',
    'ईदुज्जुहा',
    'ईदृश',
    'ईदे',
    'ईदैन',
    'ईधन',
    'ईनाम',
    'ईप्सा',
    'ईमान',
    'ईमानदार',
    'ईमानदारी',
    'ईमानदारीः',
    'ईर',
    'ईरान',
    'ईरानी',
    'ईर्म',
    'ईर्वनिंग',
    'ईर्वारु',
    'ईर्षा',
    'ईर्षापूर्ण',
    'ईर्षालु',
    'ईर्षाहीन',
    'ईर्षाहीनतः',
    'ईर्षित',
    'ईर्षिततः',
    'ईर्ष्या',
    'ईर्सा',
    'ईली',
    'ईव',
    'ईवा',
    'ईश',
    'ईशचाप',
    'ईशदूत',
    'ईशा',
    'ईशान',
    'ईशानी',
    'ईशिता',
    'ईशित्व',
    'ईश्वर',
    'ईश्वरः',
    'ईश्वरःविभूति',
    'ईश्वरमय',
    'ईश्वरवाद',
    'ईश्वरवादात्मकता',
    'ईश्वरवादी',
    'ईश्वरा',
    'ईश्वराज्ञा',
    'ईश्वरादिष्ट',
    'ईश्वरी',
    'ईश्वरीय',
    'ईश्वरीयता',
    'ईषत्सपृष्ट',
    'ईषदुष्ण',
    'ईषा',
    'ईषिका',
    'ईष्ट',
    'ईष्र्य',
    'ईष्र्यकतः',
    'ईष्र्यतः',
    'ईष्र्या',
    'ईष्र्यापूर्ण',
    'ईष्र्यापूर्णता',
    'ईष्र्यापूर्वक',
    'ईष्र्यालु',
    'ईष्र्यालुता',
    'ईष्र्याहीन',
    'ईष्र्यु',
    'ईसवी',
    'ईसवीं',
    'ईसा',
    'ईसाः',
    'ईसाइयत',
    'ईसाइयों',
    'ईसाई',
    'ईसाईकरण',
    'ईसावतान',
    'ईस्टर',
    'ईस्टरी',
    'ईस्वर',
    'ईहा',
    'ईहामृग',
    'ईहाहीनतः',
    'ईहित',
    'उँगनी',
    'उँगलियाँ',
    'उँगली',
    'उँगलीः',
    'उँगुली',
    'उँचा',
    'उँचाई',
    'उँचान',
    'उँजरिया',
    'उँटड़ा',
    'उँटहार',
    'उँटहारा',
    'उँडलन',
    'उँडलना',
    'उँडलाव',
    'उँडेल',
    'उँडेलना',
    'उँदरी',
    'उँह',
    'उंगल',
    'उंगलियां',
    'उंगलियों',
    'उंगली',
    'उंचन',
    'उंछ',
    'उंछजीवी',
    'उंछशील',
    'उंछहार',
    'उंछहारा',
    'उंछाहारी',
    'उंडुक',
    'उंदुरु',
    'उंबुर',
    'उंस',
    'उऋण',
    'उऋणता',
    'उकट',
    'उकड़ूँ',
    'उकडूँ',
    'उकताऊ',
    'उकताया',
    'उकताहट',
    'उकलाई',
    'उकलाहट',
    'उकलैदिस',
    'उकसना',
    'उकसा',
    'उकसाना',
    'उकसाने',
    'उकसाव',
    'उकसावा',
    'उकसाहट',
    'उकेरक',
    'उकेरना',
    'उकेरा',
    'उकौना',
    'उक्त',
    'उक्तता',
    'उक्ति',
    'उक्थ',
    'उक्षा',
    'उखड़ना',
    'उखड़ा',
    'उखड़ाई',
    'उखड़ाव',
    'उखड़ी',
    'उखाड़',
    'उखाड़ना',
    'उखाड़ने',
    'उखाड़ू',
    'उखेड़ना',
    'उग',
    'उगता',
    'उगन',
    'उगना',
    'उगने',
    'उगलदान',
    'उगलना',
    'उगा',
    'उगाई',
    'उगादी',
    'उगाना',
    'उगाने',
    'उगाया',
    'उगार',
    'उगाल',
    'उगालदानी',
    'उगाहना',
    'उगाही',
    'उग्र',
    'उग्रगंध',
    'उग्रचंडा',
    'उग्रजाति',
    'उग्रता',
    'उग्रतापूर्वक',
    'उग्रतारा',
    'उग्रधन्वा',
    'उग्ररेता',
    'उग्रवाद',
    'उग्रवादी',
    'उग्रसेन',
    'उग्रसेनज',
    'उग्रहण',
    'उग्रा',
    'उग्रानुगामी',
    'उग्रानुभूति',
    'उघटना',
    'उघड़ना',
    'उघड़ा',
    'उघड़ाई',
    'उघड़ापन',
    'उघाड़ना',
    'उचंत',
    'उचक',
    'उचकन',
    'उचकना',
    'उचकाना',
    'उचकू',
    'उचक्का',
    'उचक्कापन',
    'उचक्रण',
    'उचटना',
    'उचटा',
    'उचटाना',
    'उचाट',
    'उचाटता',
    'उचार',
    'उचारना',
    'उचित',
    'उचितता',
    'उचितपन',
    'उच्च',
    'उच्चंड',
    'उच्चकित',
    'उच्चक्र',
    'उच्चक्रण',
    'उच्चक्रिल',
    'उच्चटा',
    'उच्चतः',
    'उच्चतम',
    'उच्चतमता',
    'उच्चतर',
    'उच्चता',
    'उच्चमत',
    'उच्चमनस्क',
    'उच्चमन्यता',
    'उच्चय',
    'उच्चरण',
    'उच्चरित',
    'उच्चल',
    'उच्चलन',
    'उच्चवर्णीय',
    'उच्चविचार',
    'उच्चवितता',
    'उच्चवित्त',
    'उच्चसिद्धांती',
    'उच्चसैन्याधिकारी',
    'उच्चस्तरीय',
    'उच्चस्थ',
    'उच्चाकांक्षा',
    'उच्चाकांक्षी',
    'उच्चाकार',
    'उच्चाकाश',
    'उच्चाकाशीय',
    'उच्चाटक',
    'उच्चाटन',
    'उच्चादशीर',
    'उच्चाधिकार',
    'उच्चाधिकारी',
    'उच्चायुक्त',
    'उच्चायोग',
    'उच्चार',
    'उच्चारक',
    'उच्चारण',
    'उच्चारणीय',
    'उच्चारना',
    'उच्चारित',
    'उच्चार्य',
    'उच्चाशय',
    'उच्चाशयता',
    'उच्चासन',
    'उच्चित',
    'उच्च्तर',
    'उच्छल',
    'उच्छलन',
    'उच्छलनशीलता',
    'उच्छलशील',
    'उच्छलिका',
    'उच्छलित',
    'उच्छलितता',
    'उच्छालक',
    'उच्छालन',
    'उच्छालित',
    'उच्छिन्न',
    'उच्छिन्नक',
    'उच्छिष्ट',
    'उच्छिष्टता',
    'उच्छीर्षक',
    'उच्छुल्क',
    'उच्छृंखल',
    'उच्छृंखलतः',
    'उच्छृंखलता',
    'उच्छेता',
    'उच्छेद',
    'उच्छेदक',
    'उच्छेदन',
    'उच्छेदनवाद',
    'उच्छेदित',
    'उच्छेदी',
    'उच्छेष',
    'उच्छ्वसित',
    'उच्छ्वास',
    'उच्छ्वासन',
    'उच्ण',
    'उछल',
    'उछलता',
    'उछलन',
    'उछलनशील',
    'उछलना',
    'उछलने',
    'उछलाव',
    'उछलू',
    'उछाल',
    'उछालदार',
    'उछालन',
    'उछालना',
    'उछालने',
    'उछाला',
    'उछालू',
    'उछालें',
    'उछाव',
    'उछाह',
    'उजका',
    'उजडना',
    'उजड़ना',
    'उजड़ा',
    'उजड्ड',
    'उजड्डता',
    'उजबंक',
    'उजबकपन',
    'उजरत',
    'उजरती',
    'उजलतबाज',
    'उजलतबाजी',
    'उजला',
    'उजलापन',
    'उजागर',
    'उजाड़',
    'उजाड़ना',
    'उजाला',
    'उजास',
    'उजियाला',
    'उजियाली',
    'उजिर्त',
    'उज्जड़न',
    'उज्जनन',
    'उज्जयिनी',
    'उज्जवल',
    'उज्जवलता',
    'उज्जागरक',
    'उज्जागरण',
    'उज्जीवन',
    'उज्जीवित',
    'उज्जृंभा',
    'उज्ज्वल',
    'उज्ज्वलन',
    'उज्ज्वला',
    'उज्ज्वलित',
    'उज्ज्वलीकरण',
    'उज्झटित',
    'उज्झड़',
    'उज्र',
    'उझक',
    'उझकना',
    'उझकाव',
    'उझकू',
    'उटंग',
    'उटंगा',
    'उटज',
    'उटड़पा',
    'उटड़ा',
    'उटहड़ा',
    'उटारी',
    'उट्ठन',
    'उठ',
    'उठँगना',
    'उठंगन',
    'उठंगी',
    'उठंत',
    'उठक',
    'उठता',
    'उठती',
    'उठन',
    'उठना',
    'उठनाः',
    'उठने',
    'उठल्लू',
    'उठवाकर',
    'उठा',
    'उठाई',
    'उठाईगीर',
    'उठाईगीरा',
    'उठाईगीरी',
    'उठाऊ',
    'उठाए',
    'उठाएं',
    'उठाकर',
    'उठान',
    'उठानदार',
    'उठाना',
    'उठानाः',
    'उठाने',
    'उठापन',
    'उठाया',
    'उठाये',
    'उठाव',
    'उठावन',
    'उठावनी',
    'उठौआ',
    'उठौनी',
    'उडंत',
    'उडना',
    'उडने',
    'उड़',
    'उड़ंत',
    'उड़कर',
    'उड़ता',
    'उड़ती',
    'उड़द',
    'उड़न',
    'उड़नदस्ता',
    'उड़ना',
    'उड़ने',
    'उड़लन',
    'उड़ा',
    'उड़ाई',
    'उड़ाऊ',
    'उड़ाका',
    'उड़ान',
    'उड़ाना',
    'उड़ाने',
    'उड़ापन',
    'उड़ाया',
    'उड़िया',
    'उड़िसी',
    'उड़ी',
    'उड़ीसा',
    'उड़ेलना',
    'उडान',
    'उडाना',
    'उडिपी',
    'उडु',
    'उडुप',
    'उडुपति',
    'उडुपथ',
    'उडुराज',
    'उडेलना',
    'उड्डनीय',
    'उड्डयन',
    'उड्डयनशील',
    'उड्डित',
    'उड्डीन',
    'उड्डीश',
    'उड्र',
    'उढ़',
    'उढ़क',
    'उढ़कन',
    'उढ़कना',
    'उढ़कर',
    'उढ़रना',
    'उढ़ाई',
    'उढ़ारना',
    'उढ़ावन',
    'उतथ्य',
    'उतना',
    'उतनी',
    'उतने',
    'उतर',
    'उतरता',
    'उतरते',
    'उतरदायित्व',
    'उतरन',
    'उतरना',
    'उतरनाः',
    'उतरने',
    'उतरवाँ',
    'उतरवाना',
    'उतरा',
    'उतराइर',
    'उतराई',
    'उतराधिकार',
    'उतरान',
    'उतराना',
    'उतराव',
    'उतरी',
    'उतरे',
    'उतार',
    'उतारक',
    'उतारचढ़ावदार',
    'उतारचढ़ावपूर्ण',
    'उतारण',
    'उतारन',
    'उतारना',
    'उतारने',
    'उतारा',
    'उतारी',
    'उतारु',
    'उतारे',
    'उतावला',
    'उतावलापन',
    'उतावली',
    'उत्क',
    'उत्कंठ',
    'उत्कंठा',
    'उत्कंठापूर्ण',
    'उत्कंठाहीन',
    'उत्कंठित',
    'उत्कंठितता',
    'उत्कक्ष',
    'उत्कच',
    'उत्कट',
    'उत्कटता',
    'उत्कर्ष',
    'उत्कर्षक',
    'उत्कर्षकारी',
    'उत्कर्षण',
    'उत्कर्षता',
    'उत्कल',
    'उत्कलिका',
    'उत्काष्ठा',
    'उत्कास',
    'उत्कासन',
    'उत्कीर्ण',
    'उत्कीर्णक',
    'उत्कीर्णन',
    'उत्कीर्णित',
    'उत्कीर्तन',
    'उत्कीलन',
    'उत्कुटक',
    'उत्कुण',
    'उत्कूल',
    'उत्कृष्ट',
    'उत्कृष्टता',
    'उत्कृष्ठ',
    'उत्केंद्र',
    'उत्केंद्रक',
    'उत्केंद्रता',
    'उत्केंद्रिक',
    'उत्केंद्रित',
    'उत्केंद्रियता',
    'उत्केंद्रीय',
    'उत्केन्द्र',
    'उत्केन्द्रता',
    'उत्कोण',
    'उत्क्रम',
    'उत्क्रमण',
    'उत्क्रमिक',
    'उत्क्रमित',
    'उत्क्राम',
    'उत्क्रोश',
    'उत्क्लेद',
    'उत्क्लेश',
    'उत्क्षिप्त',
    'उत्क्षेप',
    'उत्क्षेपक',
    'उत्क्षेपण',
    'उत्खचन',
    'उत्खचित',
    'उत्खनक',
    'उत्खनन',
    'उत्खनित',
    'उत्खनित्र',
    'उत्खात',
    'उत्खाता',
    'उत्खान',
    'उत्तंक',
    'उत्तंभ',
    'उत्तंभक',
    'उत्तंभन',
    'उत्तंरग',
    'उत्तंस',
    'उत्तट',
    'उत्तपम',
    'उत्तपा',
    'उत्तप्त',
    'उत्तप्तता',
    'उत्तब्ध',
    'उत्तम',
    'उत्तमतमाला',
    'उत्तमतर',
    'उत्तमतरता',
    'उत्तमता',
    'उत्तमा',
    'उत्तमांग',
    'उत्तमार्थ',
    'उत्तमोत्तम',
    'उत्तर',
    'उत्तरंग',
    'उत्तरंगित',
    'उत्तरकथा',
    'उत्तरजीविता',
    'उत्तरण',
    'उत्तरता',
    'उत्तरदाता',
    'उत्तरदान',
    'उत्तरदानकर्त्ता',
    'उत्तरदायिता',
    'उत्तरदायित्व',
    'उत्तरदायित्वहीन',
    'उत्तरदायित्वहीनता',
    'उत्तरदायी',
    'उत्तरदेय',
    'उत्तरध्रुवीय',
    'उत्तरनिष्ठ',
    'उत्तरवर्तिता',
    'उत्तरवर्ती',
    'उत्तरहीन',
    'उत्तरहीनता',
    'उत्तरा',
    'उत्तराकांक्षी',
    'उत्तराखंड',
    'उत्तराधर्',
    'उत्तराधिकार',
    'उत्तराधिकारतः',
    'उत्तराधिकारविहीन',
    'उत्तराधिकारागत',
    'उत्तराधिकारागतता',
    'उत्तराधिकारी',
    'उत्तराधिकारीय',
    'उत्तराधिकारीहीन',
    'उत्तराधिकारीहीनता',
    'उत्तरान्तर',
    'उत्तरापथ',
    'उत्तराभिमुख',
    'उत्तराभिलेख',
    'उत्तरायण',
    'उत्तरार्ध',
    'उत्तरित',
    'उत्तरी',
    'उत्तरीय',
    'उत्तरेतरा',
    'उत्तरोत्तर',
    'उत्तरोत्तरतः',
    'उत्तरोत्तरता',
    'उत्तरोष्ठ',
    'उत्तल',
    'उत्तलता',
    'उत्तलस्थता',
    'उत्तलित',
    'उत्तलीय',
    'उत्तलीयता',
    'उत्ता',
    'उत्तान',
    'उत्ताप',
    'उत्तापक',
    'उत्तापन',
    'उत्तापित',
    'उत्तार',
    'उत्तारक',
    'उत्तारण',
    'उत्तारिका',
    'उत्तारी',
    'उत्ताल',
    'उत्तालता',
    'उत्तालताल',
    'उत्तिष्ठ',
    'उत्तिष्ठन',
    'उत्तिष्ठा',
    'उत्ती',
    'उत्तीर्ण',
    'उत्तीर्णता',
    'उत्तुंग',
    'उत्तुंगता',
    'उत्तेजक',
    'उत्तेजन',
    'उत्तेजनशील',
    'उत्तेजना',
    'उत्तेजनाकारी',
    'उत्तेजनात्मक',
    'उत्तेजनाप्रद',
    'उत्तेजनाहीन',
    'उत्तेजनीय',
    'उत्तेजनीयता',
    'उत्तेजित',
    'उत्तेजितता',
    'उत्तोल',
    'उत्तोलक',
    'उत्तोलन',
    'उत्तोलित',
    'उत्त्वरक',
    'उत्त्वरण',
    'उत्त्वरित',
    'उत्थ',
    'उत्थपन',
    'उत्थपा',
    'उत्थलापन',
    'उत्था',
    'उत्थान',
    'उत्थानक्षम',
    'उत्थानता',
    'उत्थानपतनशील',
    'उत्थानशील',
    'उत्थापक',
    'उत्थापन',
    'उत्थापित',
    'उत्थापित्र',
    'उत्थायी',
    'उत्थित',
    'उत्थितता',
    'उत्थिति',
    'उत्पतन',
    'उत्पत्ति',
    'उत्पत्तिः',
    'उत्पथ',
    'उत्पन्न',
    'उत्पन्नन',
    'उत्परवाह',
    'उत्परवाहशील',
    'उत्परवाहित',
    'उत्परवाही',
    'उत्परास',
    'उत्परासन',
    'उत्परिवर्तन',
    'उत्परेक्षक',
    'उत्परेक्षा',
    'उत्परेरक',
    'उत्परेरण',
    'उत्परेरणा',
    'उत्परेरित',
    'उत्पल',
    'उत्पलिनी',
    'उत्पवन',
    'उत्पाटक',
    'उत्पाटन',
    'उत्पाटित',
    'उत्पाटी',
    'उत्पात',
    'उत्पातहीन',
    'उत्पातिक',
    'उत्पाती',
    'उत्पाद',
    'उत्पादक',
    'उत्पादकता',
    'उत्पादकर',
    'उत्पादन',
    'उत्पादनकारी',
    'उत्पादनालय',
    'उत्पादनेतर',
    'उत्पादिकता',
    'उत्पादित',
    'उत्पादिता',
    'उत्पाद्य',
    'उत्पीउन',
    'उत्पीड़क',
    'उत्पीड़कता',
    'उत्पीड़न',
    'उत्पीड़नोन्माद',
    'उत्पीड़ित',
    'उत्पुलक',
    'उत्प्रवास',
    'उत्प्रवासन',
    'उत्प्रवासी',
    'उत्प्रान्त',
    'उत्प्रेरक',
    'उत्प्रेरण',
    'उत्प्रेरित',
    'उत्प्लव',
    'उत्प्लाव',
    'उत्प्लावित',
    'उत्प्लावी',
    'उत्फुल्ल',
    'उत्फुल्लता',
    'उत्स',
    'उत्संग',
    'उत्सजॉन',
    'उत्सरण',
    'उत्सर्ग',
    'उत्सर्ज',
    'उत्सर्जक',
    'उत्सर्जन',
    'उत्सर्पण',
    'उत्सर्पिणी',
    'उत्सर्पी',
    'उत्सव',
    'उत्सवपूर्ण',
    'उत्सवपूर्णता',
    'उत्सवपूर्वक',
    'उत्सवाग्नि',
    'उत्सवी',
    'उत्सादन',
    'उत्सारण',
    'उत्साह',
    'उत्साहपूर्ण',
    'उत्साहपूर्णता',
    'उत्साहपूर्वक',
    'उत्साहप्रद',
    'उत्साहवर्धन',
    'उत्साहहीन',
    'उत्साहहीनता',
    'उत्साहित',
    'उत्साहिल',
    'उत्साही',
    'उत्सुक',
    'उत्सुकता',
    'उत्सुकताः',
    'उत्सुकतापूर्वक',
    'उत्सुकतावश',
    'उत्सुकताहीन',
    'उत्सृष्ट',
    'उत्सृष्टि',
    'उत्सेकी',
    'उत्सेचन',
    'उत्स्त्राव',
    'उत्स्त्रोत',
    'उत्स्त्रोतज',
    'उत्स्त्रोतन',
    'उत्स्त्रोतशील',
    'उत्स्य',
    'उत्स्वन',
    'उथल',
    'उथला',
    'उथलापन',
    'उथलापानी',
    'उदंक',
    'उदंचन',
    'उदक',
    'उदकघात',
    'उदकहार',
    'उदक्',
    'उदक्य',
    'उदग्र',
    'उदग्रता',
    'उदचिर्',
    'उदज',
    'उदजन',
    'उदण्ड',
    'उदधि',
    'उदप',
    'उदपान',
    'उदपुर',
    'उदभार',
    'उदमाता',
    'उदमातापन',
    'उदमाद',
    'उदमादा',
    'उदय',
    'उदयन',
    'उदयशील',
    'उदया',
    'उदयाचल',
    'उदयास्त',
    'उदयी',
    'उदर',
    'उदरंभर',
    'उदरंभरी',
    'उदरशय',
    'उदरशूल',
    'उदरसेवा',
    'उदरस्थ',
    'उदरस्थन',
    'उदरामय',
    'उदरावर्त',
    'उदरिक',
    'उदरीय',
    'उदर्क',
    'उदर्य',
    'उदवाह',
    'उदाति',
    'उदात्त',
    'उदात्तता',
    'उदान',
    'उदानन',
    'उदार',
    'उदारचेता',
    'उदारता',
    'उदारतावाद',
    'उदारतावादी',
    'उदारधी',
    'उदारमति',
    'उदारमनस्क',
    'उदारमना',
    'उदारावरण',
    'उदाराशय',
    'उदारीकरण',
    'उदारीकृत',
    'उदास',
    'उदासर्ज',
    'उदासर्जन',
    'उदासी',
    'उदासीन',
    'उदासीनता',
    'उदाहरण',
    'उदाहरणतः',
    'उदाहरणस्वरूप',
    'उदाहरणहीन',
    'उदाहरणहीनता',
    'उदाहरणार्थ',
    'उदित',
    'उदितभाग्य',
    'उदिति',
    'उदिष्ट',
    'उदीची',
    'उदीचीन',
    'उदीच्य',
    'उदीयमान',
    'उदीर्ण',
    'उदुंबर',
    'उदूढ़',
    'उदूल',
    'उदूली',
    'उद्क्वथन',
    'उद्क्वाथ',
    'उद्क्वाथित',
    'उद्गत',
    'उद्गतता',
    'उद्गम',
    'उद्गमन',
    'उद्गमनशील',
    'उद्गाता',
    'उद्गामी',
    'उद्गायक',
    'उद्गार',
    'उद्गीअरन',
    'उद्गीत',
    'उद्गीति',
    'उद्गीथ',
    'उद्गीरण',
    'उद्गीर्ण',
    'उद्ग्रंथ',
    'उद्ग्रंथक',
    'उद्ग्रंथन',
    'उद्ग्रंथित',
    'उद्ग्रहण',
    'उद्ग्रहीत',
    'उद्घत',
    'उद्घतता',
    'उद्घतमना',
    'उद्घम',
    'उद्घरण',
    'उद्घर्ष',
    'उद्घर्षिणी',
    'उद्घव',
    'उद्घाट',
    'उद्घाटक',
    'उद्घाटन',
    'उद्घाटित',
    'उद्घात',
    'उद्घातक',
    'उद्घातन',
    'उद्घातित',
    'उद्घार',
    'उद्घारक',
    'उद्घारण',
    'उद्घारित',
    'उद्घुष्ट',
    'उद्घूर्ण',
    'उद्घृत',
    'उद्घोष',
    'उद्घोषक',
    'उद्घोषणा',
    'उद्घोषित',
    'उद्दंड',
    'उद्दंडता',
    'उद्दंत',
    'उद्दंतुर',
    'उद्दंश',
    'उद्दण्डता',
    'उद्दमन',
    'उद्दाम',
    'उद्दामता',
    'उद्दालक',
    'उद्दीपक',
    'उद्दीपन',
    'उद्दीप्त',
    'उद्दीप्ति',
    'उद्दीप्र',
    'उद्देश',
    'उद्देशन',
    'उद्देशिका',
    'उद्देशित',
    'उद्देशीय',
    'उद्देश्य',
    'उद्देश्यः',
    'उद्देश्यतः',
    'उद्देश्यता',
    'उद्देश्यपरकता',
    'उद्देश्यपूर्ण',
    'उद्देश्यपूर्वक',
    'उद्देश्यहीन',
    'उद्देश्यहीनतः',
    'उद्देश्यहीनता',
    'उद्देष्टा',
    'उद्धत',
    'उद्धम',
    'उद्धरण',
    'उद्धार',
    'उद्धारक',
    'उद्धिग्नता',
    'उद्धृत',
    'उद्ध्वंस',
    'उद्बुजन',
    'उद्बुजित',
    'उद्बुद्घ',
    'उद्बुधन',
    'उद्बोध',
    'उद्बोधक',
    'उद्बोधन',
    'उद्बोधात्मक',
    'उद्बोधित',
    'उद्भट',
    'उद्भरण',
    'उद्भरित',
    'उद्भव',
    'उद्भवन',
    'उद्भाज्य',
    'उद्भार',
    'उद्भारग्रस्त',
    'उद्भारग्रस्तता',
    'उद्भाराक्रांत',
    'उद्भार्य',
    'उद्भावक',
    'उद्भावन',
    'उद्भावना',
    'उद्भावित',
    'उद्भासक',
    'उद्भासत',
    'उद्भासन',
    'उद्भासित',
    'उद्भिजः',
    'उद्भिजन',
    'उद्भिज्ज',
    'उद्भिज्य',
    'उद्भिद',
    'उद्भूत',
    'उद्भूति',
    'उद्भृत',
    'उद्भेद',
    'उद्भेदन',
    'उद्भ्रम',
    'उद्भ्रमण',
    'उद्भ्रांत',
    'उद्भ्रांति',
    'उद्यत',
    'उद्यततः',
    'उद्यतता',
    'उद्यम',
    'उद्यमकर्ता',
    'उद्यमशील',
    'उद्यमशीलता',
    'उद्यमशीलतापूर्वक',
    'उद्यमहीन',
    'उद्यमहीनता',
    'उद्यमिता',
    'उद्यमी',
    'उद्यमीय',
    'उद्यान',
    'उद्योग',
    'उद्योगजन्य',
    'उद्योगपति',
    'उद्योगवार',
    'उद्योगव्यापी',
    'उद्योगशील',
    'उद्योगहीन',
    'उद्योगांतर्गत',
    'उद्योगी',
    'उद्योगीकरण',
    'उद्योगेतर',
    'उद्योगों',
    'उद्योतकर',
    'उद्योतन',
    'उद्योतित',
    'उद्र',
    'उद्रथ',
    'उद्राव',
    'उद्रावण',
    'उद्रेक',
    'उद्वज्र',
    'उद्वमन',
    'उद्वरण',
    'उद्वर्त',
    'उद्वर्तन',
    'उद्वह',
    'उद्वहन',
    'उद्वहित',
    'उद्वाचन',
    'उद्वारक',
    'उद्वास',
    'उद्वासन',
    'उद्वासा',
    'उद्वाहक',
    'उद्वाहन',
    'उद्वाहित',
    'उद्वाहिनी',
    'उद्वाही',
    'उद्वाह्यता',
    'उद्विकास',
    'उद्विग्न',
    'उद्विग्नतः',
    'उद्विग्नता',
    'उद्विग्नतापूर्वक',
    'उद्वेग',
    'उद्वेगपूर्ण',
    'उद्वेगहीन',
    'उद्वेगहीनता',
    'उद्वेगी',
    'उद्वेघन',
    'उद्वेजक',
    'उद्वेधन',
    'उद्वेप',
    'उद्वेल',
    'उद्वेलक',
    'उद्वेलन',
    'उद्वेलित',
    'उद्वेलितता',
    'उद्वेष्टन',
    'उद्सर्ज',
    'उधड़न',
    'उधड़ना',
    'उधड़ाई',
    'उधमता',
    'उधमी',
    'उधर',
    'उधरला',
    'उधार',
    'उधारकर्ता',
    'उधारदाता',
    'उधारी',
    'उधारीकृत',
    'उधेड़ना',
    'उधेड़बुन',
    'उन',
    'उन/DE',
    'उनका',
    'उनकी',
    'उनके',
    'उनको',
    'उनवान',
    'उनसे',
    'उनींदा',
    'उनींदापन',
    'उनींदे',
    'उनीस',
    'उन्न',
    'उन्नत',
    'उन्नतता',
    'उन्नतभाल',
    'उन्नतमस्तक',
    'उन्नतमस्तकता',
    'उन्नतांश',
    'उन्नतावनत',
    'उन्नतावनतता',
    'उन्नति',
    'उन्नतिअवनतिशील',
    'उन्नतिअवनतिशीलता',
    'उन्नतिकारी',
    'उन्नतिशील',
    'उन्नतोदर',
    'उन्नतोदरता',
    'उन्नमन',
    'उन्नमित',
    'उन्नमितता',
    'उन्नम्र',
    'उन्नयन',
    'उन्नयनवाद',
    'उन्नायक',
    'उन्नाव',
    'उन्निर्यास',
    'उन्नीस',
    'उन्नीसवां',
    'उन्मज्जक',
    'उन्मज्जन',
    'उन्मज्जायन',
    'उन्मज्जायित',
    'उन्मतत्ता',
    'उन्मत्त',
    'उन्मत्तता',
    'उन्मत्तवेश',
    'उन्मत्तालय',
    'उन्मथन',
    'उन्मद',
    'उन्मदिष्णु',
    'उन्मन',
    'उन्मनस्क',
    'उन्मना',
    'उन्माजिर्त',
    'उन्माथ',
    'उन्माद',
    'उन्मादक',
    'उन्मादकता',
    'उन्मादकारी',
    'उन्मादग्रस्त',
    'उन्मादन',
    'उन्मादहीन',
    'उन्मादी',
    'उन्मान',
    'उन्मार्जन',
    'उन्मिज्जत',
    'उन्मील',
    'उन्मीलक',
    'उन्मीलन',
    'उन्मीलित',
    'उन्मुक्त',
    'उन्मुक्तता',
    'उन्मुक्ति',
    'उन्मुख',
    'उन्मुखता',
    'उन्मुग्ध',
    'उन्मूलक',
    'उन्मूलन',
    'उन्मूलनवादी',
    'उन्मूलित',
    'उन्मेष',
    'उन्मेषित',
    'उन्मोचक',
    'उन्मोचन',
    'उन्मोचित',
    'उन्वेधन',
    'उन्हीं',
    'उन्हें',
    'उन्हों',
    'उप',
    'उपकक्ष',
    'उपकथा',
    'उपकनिष्ठिका',
    'उपकपोल',
    'उपकरण',
    'उपकरणयुक्त',
    'उपकरणहीन',
    'उपकरणों',
    'उपकर्ता',
    'उपकलक्टर',
    'उपकार',
    'उपकारक',
    'उपकारिका',
    'उपकारिता',
    'उपकारी',
    'उपकार्या',
    'उपकुल्या',
    'उपकूप',
    'उपकूल',
    'उपकृत',
    'उपकृतता',
    'उपकेन्द्र',
    'उपकोटि',
    'उपक्रम',
    'उपक्रमण',
    'उपक्रमणिका',
    'उपक्रमी',
    'उपक्रांत',
    'उपक्रांता',
    'उपक्रोश',
    'उपक्रोष्टा',
    'उपक्वण',
    'उपक्षेत्र',
    'उपक्षेत्रों',
    'उपक्षेप',
    'उपखंड',
    'उपखण्ड',
    'उपखाड़ी',
    'उपगत',
    'उपगमन',
    'उपगाता',
    'उपगाल',
    'उपगिरि',
    'उपगृह',
    'उपगोपन',
    'उपग्रह',
    'उपग्राम',
    'उपघात',
    'उपचंद्र',
    'उपचंद्रक',
    'उपचक्र',
    'उपचय',
    'उपचयन',
    'उपचयी',
    'उपचर्म',
    'उपचर्या',
    'उपचार',
    'उपचारक',
    'उपचारात्मक',
    'उपचारिका',
    'उपचारी',
    'उपचित',
    'उपचिति',
    'उपचेतना',
    'उपच्छद',
    'उपच्छाया',
    'उपज',
    'उपजकर्ता',
    'उपजन',
    'उपजना',
    'उपजमारी',
    'उपजवाली',
    'उपजवाले',
    'उपजा',
    'उपजाई',
    'उपजाऊ',
    'उपजाऊपन',
    'उपजात',
    'उपजाति',
    'उपजातीय',
    'उपजाना',
    'उपजाप',
    'उपजापक',
    'उपजापित',
    'उपजापी',
    'उपजिहृवा',
    'उपजिह्वा',
    'उपजीविका',
    'उपजीविकाजन्य',
    'उपजीविता',
    'उपजीवी',
    'उपज्ञा',
    'उपज्ञात',
    'उपटाव',
    'उपड़ाव',
    'उपतंभित',
    'उपतप्त',
    'उपतारा',
    'उपत्यका',
    'उपदंश',
    'उपदान',
    'उपदिशा',
    'उपदिष्ट',
    'उपदेव',
    'उपदेवता',
    'उपदेश',
    'उपदेशक',
    'उपदेशन',
    'उपदेशपूर्ण',
    'उपदेशवाद',
    'उपदेशात्मक',
    'उपदेष्टा',
    'उपद्रव',
    'उपद्रवकारी',
    'उपद्रवग्रस्त',
    'उपद्रवग्रस्तता',
    'उपद्रवहीन',
    'उपद्रवी',
    'उपद्रष्टा',
    'उपद्वार',
    'उपद्वीप',
    'उपधातु',
    'उपधान',
    'उपधारणा',
    'उपधारा',
    'उपधि',
    'उपनगर',
    'उपनगरीय',
    'उपनदी',
    'उपनमन',
    'उपनय',
    'उपनयन',
    'उपनरक',
    'उपनाम',
    'उपनायक',
    'उपनायिका',
    'उपनावाध्यक्ष',
    'उपनाह',
    'उपनिधाता',
    'उपनिधान',
    'उपनिधि',
    'उपनिपात',
    'उपनियम',
    'उपनिरीक्षक',
    'उपनिवेश',
    'उपनिवेशक',
    'उपनिवेशन',
    'उपनिवेशवाद',
    'उपनिवेशीय',
    'उपनिषद',
    'उपनिषदन',
    'उपनिषदीय',
    'उपनिषादी',
    'उपनिष्क्रमण',
    'उपनिहिती',
    'उपनीत',
    'उपनेता',
    'उपनेत्र',
    'उपन्यायाधीश',
    'उपन्यास',
    'उपन्यासकार',
    'उपन्यासिका',
    'उपपति',
    'उपपत्ति',
    'उपपत्तीय',
    'उपपत्नी',
    'उपपद',
    'उपपन्न',
    'उपपरिणाम',
    'उपपाचन',
    'उपपातक',
    'उपपादक',
    'उपपादन',
    'उपपादित',
    'उपपाद्य',
    'उपपाप',
    'उपपीड़न',
    'उपप्रबंधक',
    'उपप्रभाग',
    'उपप्रभागीय',
    'उपप्रश्न',
    'उपप्लव',
    'उपबंध',
    'उपबंधित',
    'उपबन्ध',
    'उपबन्धों',
    'उपबर्ह',
    'उपबर्हण',
    'उपबाहु',
    'उपबृंहण',
    'उपभवन',
    'उपभाग',
    'उपभाड़ा',
    'उपभाषा',
    'उपभुक्त',
    'उपभुक्तता',
    'उपभेद',
    'उपभोक्तव्य',
    'उपभोक्ता',
    'उपभोक्तावाद',
    'उपभोग',
    'उपभोगन',
    'उपभोगी',
    'उपभोग्य',
    'उपभोग्यता',
    'उपभोजन',
    'उपभोज्य',
    'उपमंत्री',
    'उपमन्यु',
    'उपमहाद्वीप',
    'उपमहानिदेशक',
    'उपमा',
    'उपमाता',
    'उपमान',
    'उपमार्ग',
    'उपमालंकार',
    'उपमाहीन',
    'उपमाहीनता',
    'उपमित',
    'उपमिति',
    'उपमेय',
    'उपयंत्र',
    'उपयाज',
    'उपयासांत',
    'उपयुक्त',
    'उपयुक्तता',
    'उपयोक्ता',
    'उपयोग',
    'उपयोगहीन',
    'उपयोगित',
    'उपयोगिता',
    'उपयोगी',
    'उपयोजन',
    'उपयोज्य',
    'उपयोज्यता',
    'उपरंजित',
    'उपरंधर',
    'उपरक्षक',
    'उपरत',
    'उपरति',
    'उपरत्न',
    'उपरथ्या',
    'उपरना',
    'उपरनी',
    'उपरला',
    'उपरली',
    'उपरांत',
    'उपरांततर',
    'उपरांतता',
    'उपराग',
    'उपराचढ़ी',
    'उपराजिक',
    'उपराज्यपाल',
    'उपरात',
    'उपरान्त',
    'उपराम',
    'उपरालू',
    'उपरालूपन',
    'उपरि',
    'उपरिचर',
    'उपरितन',
    'उपरिनिर्दिष्ट',
    'उपरिलिखित',
    'उपरिलिखितता',
    'उपरिलेखन',
    'उपरिशायी',
    'उपरी',
    'उपरुद्घ',
    'उपरुपक',
    'उपरैनी',
    'उपरोक्त',
    'उपरोध',
    'उपरोधक',
    'उपरोधी',
    'उपरोपित',
    'उपर्युक्त',
    'उपर्युक्तता',
    'उपल',
    'उपलक्षित',
    'उपलक्ष्य',
    'उपलक्ष्यता',
    'उपलब्ध',
    'उपलब्धता',
    'उपलब्धा',
    'उपलब्धि',
    'उपलब्धियां',
    'उपलब्धिहीन',
    'उपलब्धिहीनता',
    'उपला',
    'उपलाभ',
    'उपल्ला',
    'उपवण्र्य',
    'उपवन',
    'उपवर्णन',
    'उपवाक्य',
    'उपवाक्यश्',
    'उपवाद',
    'उपवास',
    'उपवासः',
    'उपवासक',
    'उपवासत्रयी',
    'उपवासशील',
    'उपवासी',
    'उपवाहन',
    'उपविक्रय',
    'उपविक्रेता',
    'उपविधि',
    'उपविभाग',
    'उपविभाजन',
    'उपविष्ट',
    'उपविष्ठन',
    'उपवीत',
    'उपवीती',
    'उपवीथिका',
    'उपवीथी',
    'उपवेद',
    'उपवेश',
    'उपवेशन',
    'उपवेशित',
    'उपवेष्टक',
    'उपवेष्टन',
    'उपव्यवसाय',
    'उपव्याघर',
    'उपशम',
    'उपशमन',
    'उपशल्य',
    'उपशांति',
    'उपशाखा',
    'उपशामक',
    'उपशिक्षक',
    'उपश्रुत',
    'उपश्रुति',
    'उपश्रोता',
    'उपसंख्यान',
    'उपसंयम',
    'उपसंवाद',
    'उपसंवीत',
    'उपसंवीतन',
    'उपसंहरण',
    'उपसंहार',
    'उपसंहृत',
    'उपसचिव',
    'उपसदन',
    'उपसना',
    'उपसमष्टि',
    'उपसमिति',
    'उपसरण',
    'उपसर्ग',
    'उपसागर',
    'उपसाधक',
    'उपसाधन',
    'उपसाध्य',
    'उपसामन्त',
    'उपसिद्धांत',
    'उपसिद्धान्त',
    'उपसुंद',
    'उपसूर्य',
    'उपसूर्यक',
    'उपस्कर',
    'उपस्टेशन',
    'उपस्तंभक',
    'उपस्तंभन',
    'उपस्त्री',
    'उपस्थ',
    'उपस्थाता',
    'उपस्थापन',
    'उपस्थित',
    'उपस्थितता',
    'उपस्थितप्राय',
    'उपस्थिति',
    'उपस्वेदन',
    'उपहत',
    'उपहर्ता',
    'उपहस्त',
    'उपहार',
    'उपहारकर',
    'उपहारगृह',
    'उपहारदार',
    'उपहारीय',
    'उपहार्य',
    'उपहास',
    'उपहासक',
    'उपहासपात्र',
    'उपहासात्मक',
    'उपहासात्मकता',
    'उपहासास्पद',
    'उपहासी',
    'उपहास्य',
    'उपहास्यता',
    'उपहित',
    'उपहृत',
    'उपांग',
    'उपांत',
    'उपांतर',
    'उपांतरण',
    'उपांतरित',
    'उपांतिक',
    'उपांतिम',
    'उपांतेतर',
    'उपांत्य',
    'उपाकर्म',
    'उपाकृत',
    'उपाख्यान',
    'उपाख्यानक',
    'उपाख्यानवाद',
    'उपाख्यानात्मक',
    'उपागत',
    'उपागम',
    'उपागम्यता',
    'उपाचार्य',
    'उपाजिर्त',
    'उपाड़ना',
    'उपातंरण',
    'उपादान',
    'उपाधातु',
    'उपाधि',
    'उपाधित',
    'उपाधिपत्र',
    'उपाधियां',
    'उपाधिरहित',
    'उपाध्यक्ष',
    'उपाध्याय',
    'उपाध्वा',
    'उपानह',
    'उपान्त',
    'उपापराध',
    'उपाबंध',
    'उपाय',
    'उपायतः',
    'उपायन',
    'उपायपूर्ण',
    'उपायहीन',
    'उपायहीनतः',
    'उपायहीनता',
    'उपायी',
    'उपायुक्त',
    'उपायोजक',
    'उपारंभ',
    'उपार्जक',
    'उपार्जन',
    'उपार्जित',
    'उपार्ज्य',
    'उपार्थक',
    'उपालंभ',
    'उपालंभित',
    'उपावर्तन',
    'उपाश्रय',
    'उपासंग',
    'उपासक',
    'उपासन',
    'उपासना',
    'उपासनालय',
    'उपासनीय',
    'उपासा',
    'उपासिका',
    'उपासित',
    'उपासिता',
    'उपास्थि',
    'उपास्य',
    'उपाहार',
    'उपेंद्र',
    'उपेक्षक',
    'उपेक्षण',
    'उपेक्षा',
    'उपेक्षात्मक',
    'उपेक्षापूर्ण',
    'उपेक्षित',
    'उपेक्षी',
    'उपेक्ष्य',
    'उपेत',
    'उपोढ़',
    'उपोत',
    'उपोत्पाद',
    'उपोद्घात',
    'उपोषण',
    'उपोहन',
    'उफनता',
    'उफनना',
    'उफना',
    'उफनाय',
    'उफान',
    'उफाल',
    'उफ्रुक',
    'उबकना',
    'उबकाई',
    'उबकी',
    'उबजन',
    'उबजना',
    'उबजा',
    'उबटन',
    'उबड़',
    'उबरन',
    'उबरना',
    'उबरा',
    'उबराई',
    'उबराव',
    'उबलता',
    'उबलन',
    'उबलना',
    'उबला',
    'उबलाई',
    'उबलाव',
    'उबली',
    'उबले',
    'उबसन',
    'उबसना',
    'उबहन',
    'उबहना',
    'उबहनी',
    'उबाऊ',
    'उबाऊपन',
    'उबाने',
    'उबार',
    'उबारना',
    'उबारा',
    'उबाल',
    'उबालकर',
    'उबालना',
    'उबालने',
    'उबाला',
    'उबालांक',
    'उबासी',
    'उबुद्घ',
    'उबोधक',
    'उबोधन',
    'उभय',
    'उभयचराः',
    'उभयदंश',
    'उभयनिष्ठ',
    'उभयपक्षी',
    'उभयलिंगता',
    'उभयलिंगी',
    'उभयवेतन',
    'उभयश्वासी',
    'उभयहस्तता',
    'उभयहस्तिक',
    'उभयहस्तिकता',
    'उभयाग्र',
    'उभयार्थक',
    'उभरता',
    'उभरती',
    'उभरते',
    'उभरन',
    'उभरना',
    'उभरा',
    'उभरापन',
    'उभराव',
    'उभरी',
    'उभरे',
    'उभार',
    'उभारदार',
    'उभारना',
    'उभारने',
    'उभावन',
    'उभास',
    'उमँगना',
    'उमँड़ना',
    'उमंग',
    'उमंगक',
    'उमंगकारी',
    'उमंगपूर्ण',
    'उमंगहीन',
    'उमंगहीनता',
    'उमंड',
    'उमगन',
    'उमगना',
    'उमगा',
    'उमगाय',
    'उमचना',
    'उमठाई',
    'उमड़',
    'उमड़ता',
    'उमड़न',
    'उमड़ना',
    'उमड़ा',
    'उमड़ाय',
    'उमदा',
    'उमदापन',
    'उमर',
    'उमरा',
    'उमराव',
    'उमरावी',
    'उमस',
    'उमसदार',
    'उमसपन',
    'उमहना',
    'उमा',
    'उमाकांत',
    'उमानाथ',
    'उमासुत',
    'उमियाक',
    'उमिर',
    'उमिर्ल',
    'उमिर्लता',
    'उमिर्ला',
    'उमेठ',
    'उमेठन',
    'उमेठना',
    'उमेठा',
    'उमेश',
    'उम्म',
    'उम्मत',
    'उम्मी',
    'उम्मीद',
    'उम्मीदवार',
    'उम्मीदवारी',
    'उम्मुल',
    'उम्म्',
    'उम्र',
    'उम्रर',
    'उम्ररसीद',
    'उर',
    'उरंग',
    'उरंगम',
    'उरग',
    'उरगभूषण',
    'उरगराज',
    'उरगाः',
    'उरगाद',
    'उरगिनी',
    'उरगी',
    'उरभ्र',
    'उरमज्द',
    'उरलम',
    'उरला',
    'उरली',
    'उरव्य',
    'उरस',
    'उरसिज',
    'उरस्क',
    'उरस्त्राण',
    'उरस्य',
    'उरस्यता',
    'उरावरण',
    'उरियाँ',
    'उरियानी',
    'उरु',
    'उरूसन्धि',
    'उरे',
    'उरेब',
    'उरेबदार',
    'उरेबवाँ',
    'उरेबवाँपन',
    'उरेह',
    'उरेहन',
    'उरेहना',
    'उरोज',
    'उरोभाग',
    'उर्जानी',
    'उर्दू',
    'उर्नीदापन',
    'उर्फ',
    'उर्फीयत',
    'उर्वर',
    'उर्वरक',
    'उर्वरण',
    'उर्वरणहीन',
    'उर्वरता',
    'उर्वरा',
    'उर्वरीकरण',
    'उर्वशी',
    'उर्वारु',
    'उर्वारुक',
    'उर्वीधर',
    'उर्स',
    'उलंगा',
    'उलंघन',
    'उलछना',
    'उलझ',
    'उलझट',
    'उलझन',
    'उलझनकारी',
    'उलझनपूर्ण',
    'उलझनहीन',
    'उलझना',
    'उलझा',
    'उलझाना',
    'उलझानेवाला',
    'उलझाये',
    'उलझाव',
    'उलझे',
    'उलट',
    'उलटकर',
    'उलटना',
    'उलटबाँसी',
    'उलटवांसी',
    'उलटा',
    'उलटाई',
    'उलटापन',
    'उलटाव',
    'उलटासीधापन',
    'उलटी',
    'उलटे',
    'उलड़न',
    'उलप्य',
    'उलफत',
    'उलरुआ',
    'उलाँक',
    'उलाँकिया',
    'उलाँकी',
    'उलाँघना',
    'उलाउंस',
    'उलाउद्दीन',
    'उलाड़',
    'उलार',
    'उलाहना',
    'उलीच',
    'उलीचकर',
    'उलीचना',
    'उलूक',
    'उलूखल',
    'उलूपी',
    'उल्',
    'उल्का',
    'उल्कापिण्ड',
    'उल्काभ',
    'उल्काश्म',
    'उल्टा',
    'उल्टी',
    'उल्टे',
    'उल्था',
    'उल्थाकार',
    'उल्ब',
    'उल्मुक',
    'उल्लंघक',
    'उल्लंघन',
    'उल्लंघनकारी',
    'उल्लंघित',
    'उल्लंबित',
    'उल्लल',
    'उल्लसित',
    'उल्लाघ',
    'उल्लाघव',
    'उल्लापी',
    'उल्लास',
    'उल्लासकारी',
    'उल्लासना',
    'उल्लासपूर्ण',
    'उल्लासहीनता',
    'उल्लासानुभूति',
    'उल्लासित',
    'उल्लासी',
    'उल्लासोन्माद',
    'उल्लासोन्मादात्मक',
    'उल्लिखित',
    'उल्लीढ़',
    'उल्लुंचन',
    'उल्लू',
    'उल्लेख',
    'उल्लेखनीय',
    'उल्लेखनीयता',
    'उल्लोल',
    'उल्व',
    'उवाच',
    'उविर्जा',
    'उशना',
    'उशीर',
    'उशीरक',
    'उषण',
    'उषसी',
    'उषा',
    'उषानाथ',
    'उषारमण',
    'उषेश',
    'उष्ट्र',
    'उष्ट्रपाल',
    'उष्ट्रिका',
    'उष्ट्री',
    'उष्ण',
    'उष्णता',
    'उष्णरिश्म',
    'उष्णा',
    'उष्णांक',
    'उष्णागम',
    'उष्णिमा',
    'उष्णीष',
    'उष्णीषी',
    'उष्म',
    'उष्मता',
    'उष्मप',
    'उष्मा',
    'उस',
    'उसका',
    'उसकाना',
    'उसकी',
    'उसके',
    'उसना',
    'उसनाना',
    'उसने',
    'उसमान',
    'उसमे',
    'उसमें',
    'उससना',
    'उससे',
    'उसाँस',
    'उसारा',
    'उसी',
    'उसूल',
    'उसूली',
    'उसूलों',
    'उस्तरा',
    'उस्ताद',
    'उस्तादी',
    'उस्तानी',
    'उस्तुरा',
    'उस्तुराः',
    'उस्त्र',
    'उस्मान',
    'उह',
    'उहाँ',
    'उहार',
    'उफ़',
    'ऊँघ',
    'ऊँघता',
    'ऊँघना',
    'ऊँच',
    'ऊँचनीच',
    'ऊँचा',
    'ऊँचाई',
    'ऊँचानीचापन',
    'ऊँचापन',
    'ऊँची',
    'ऊँचे',
    'ऊँट',
    'ऊँटः',
    'ऊँटड़ा',
    'ऊँटनी',
    'ऊँटवान',
    'ऊंघ',
    'ऊंघना',
    'ऊंचा',
    'ऊंचाई',
    'ऊंची',
    'ऊंचे',
    'ऊखल',
    'ऊचर',
    'ऊजड़',
    'ऊजिर्त',
    'ऊटक',
    'ऊटपटाँग',
    'ऊटपटांग',
    'ऊटर्स',
    'ऊढ़',
    'ऊढ़ना',
    'ऊढ़ा',
    'ऊढ़ि',
    'ऊत',
    'ऊतक',
    'ऊतकावली',
    'ऊति',
    'ऊतिनाश',
    'ऊत्तक',
    'ऊत्तकों',
    'ऊद',
    'ऊदबत्ती',
    'ऊदबिलाव',
    'ऊदा',
    'ऊधम',
    'ऊधमता',
    'ऊधमतापूर्वक',
    'ऊधमी',
    'ऊधमीपन',
    'ऊधव',
    'ऊधा',
    'ऊधो',
    'ऊध्र्व',
    'ऊध्र्वकेश',
    'ऊध्र्वकोणीयता',
    'ऊध्र्वग',
    'ऊध्र्वगत',
    'ऊध्र्वगमन',
    'ऊध्र्वगामी',
    'ऊध्र्वतः',
    'ऊध्र्वता',
    'ऊध्र्वदृष्टि',
    'ऊध्र्वमुख',
    'ऊध्र्वमुखतः',
    'ऊध्र्वमुखता',
    'ऊध्र्वरेता',
    'ऊध्र्वलिंगी',
    'ऊध्र्वलिखित',
    'ऊध्र्वशायी',
    'ऊध्र्वसानु',
    'ऊध्र्वस्थ',
    'ऊध्र्वाधर',
    'ऊध्र्वाधरता',
    'ऊध्वांग',
    'ऊध्वोर्क्त',
    'ऊध्वोर्क्तता',
    'ऊध्वोर्न्मुख',
    'ऊन',
    'ऊनक',
    'ऊनदार',
    'ऊनी',
    'ऊपर',
    'ऊपरः',
    'ऊपरला',
    'ऊपरी',
    'ऊपरीपन',
    'ऊपरोक्त',
    'ऊब',
    'ऊबट',
    'ऊबड़',
    'ऊबड़खाबड़',
    'ऊबड़खाबड़पन',
    'ऊबना',
    'ऊबा',
    'ऊबाऊ',
    'ऊभट',
    'ऊमस',
    'ऊमिर',
    'ऊमिर्',
    'ऊमिर्का',
    'ऊमिर्त',
    'ऊमिर्माला',
    'ऊमिर्माली',
    'ऊमिर्ल',
    'ऊमिर्लता',
    'ऊमिर्ला',
    'ऊरु',
    'ऊरू',
    'ऊर्ज',
    'ऊर्जक',
    'ऊर्जन',
    'ऊर्जस्वी',
    'ऊर्जा',
    'ऊर्जांक',
    'ऊर्जायक',
    'ऊर्जायन',
    'ऊर्जायित',
    'ऊर्जावान',
    'ऊर्जाविज्ञान',
    'ऊर्ण',
    'ऊर्णनाभ',
    'ऊर्णनाभि',
    'ऊर्णवाभि',
    'ऊर्णा',
    'ऊर्णायु',
    'ऊर्दर',
    'ऊर्ध्वबाहु',
    'ऊर्ध्वस्थ',
    'ऊर्व',
    'ऊर्वस्थि',
    'ऊर्वी',
    'ऊल',
    'ऊश्मस्थैतिक',
    'ऊष',
    'ऊषक',
    'ऊषर',
    'ऊषा',
    'ऊष्णकटिबंधीय',
    'ऊष्णीय',
    'ऊष्म',
    'ऊष्मता',
    'ऊष्मवर्ण',
    'ऊष्मा',
    'ऊष्मांक',
    'ऊष्मागम',
    'ऊष्मानुभूति',
    'ऊष्मीय',
    'ऊसर',
    'ऊह',
    'ऊहन',
    'ऊहा',
    'ऊहापोह',
    'ऋंचाकार',
    'ऋक्',
    'ऋक्ष',
    'ऋक्षनाथ',
    'ऋक्षनेमि',
    'ऋक्षपति',
    'ऋक्षराज',
    'ऋक्षाः',
    'ऋक्षेश',
    'ऋग्',
    'ऋग्वेद',
    'ऋचा',
    'ऋचीक',
    'ऋच्',
    'ऋजु',
    'ऋजुता',
    'ऋजुपक्ष',
    'ऋण',
    'ऋणकर्ता',
    'ऋणग्रस्त',
    'ऋणग्रस्तता',
    'ऋणजाल',
    'ऋणद',
    'ऋणदाता',
    'ऋणपत्र',
    'ऋणपाश',
    'ऋणबंध',
    'ऋणमुक्त',
    'ऋणयोग्य',
    'ऋणरहित',
    'ऋणवत',
    'ऋणशेष',
    'ऋणशोध',
    'ऋणशोधन',
    'ऋणहीन',
    'ऋणाग्र',
    'ऋणात्मक',
    'ऋणादान',
    'ऋणाधार',
    'ऋणाधारित',
    'ऋणायन',
    'ऋणायनी',
    'ऋणी',
    'ऋणेतर',
    'ऋणों',
    'ऋणोत्तर',
    'ऋत',
    'ऋतंभर',
    'ऋतंभरण',
    'ऋतंभरता',
    'ऋतता',
    'ऋतधामा',
    'ऋतध्वज',
    'ऋतव्य',
    'ऋतु',
    'ऋतुचक्र',
    'ऋतुजैविकी',
    'ऋतुमती',
    'ऋतुराज',
    'ऋत्विक',
    'ऋत्विज',
    'ऋत्वीय',
    'ऋद्घ',
    'ऋद्धि',
    'ऋभु',
    'ऋश्य',
    'ऋषभ',
    'ऋषभी',
    'ऋषि',
    'ऋषिः',
    'ऋषिसत्तमौ',
    'ऋषी',
    'ऋषीय',
    'ऋष्टि',
    'ऋष्य',
    'ऋष्यकेतु',
    'ऋष्यमूक',
    'ऋष्यशृंग',
    'ए',
    'एँच',
    'एंटरप्राइस',
    'एंटीबायोटिक्स',
    'एंट्रीज',
    'एंड',
    'एंव',
    'एअर',
    'एअरकंडीशनर',
    'एअरकूलर',
    'एअरक्राफ्ट',
    'एअरपोर्ट',
    'एअरबोर्न',
    'एअरमीटर',
    'एअरलाइंस',
    'एअरवोट',
    'एअरस्पेस',
    'एअरहीटर',
    'एअरहीटिंग',
    'एअरो',
    'एअरोड्रोम',
    'एआईआर',
    'एक',
    'एकंगी',
    'एकक',
    'एककटिबंधीय',
    'एककपरिवार',
    'एककुंडल',
    'एककेंद्रिकता',
    'एकखंडीय',
    'एकखंडीयता',
    'एकगाछी',
    'एकगोत्रता',
    'एकगोत्रीय',
    'एकचक्र',
    'एकचर',
    'एकचर्या',
    'एकचारिणी',
    'एकचित्त',
    'एकचेता',
    'एकछत्र',
    'एकछत्रता',
    'एकछत्राधिकार',
    'एकज',
    'एकजटा',
    'एकजन',
    'एकजन्मा',
    'एकजवान',
    'एकजात',
    'एकजातीय',
    'एकजान',
    'एकजीव',
    'एकजुट',
    'एकजुटता',
    'एकटंगा',
    'एकटक',
    'एकठा',
    'एकड़',
    'एकड़ा',
    'एकड़े',
    'एकतंत्र',
    'एकतंत्रवाद',
    'एकतंत्री',
    'एकतरफा',
    'एकतरफापन',
    'एकता',
    'एकतापूर्ण',
    'एकतापूर्वक',
    'एकतार',
    'एकतारा',
    'एकताल',
    'एकतावर्धक',
    'एकताहीन',
    'एकताहीनतः',
    'एकताहीनता',
    'एकत्र',
    'एकत्रक',
    'एकत्रण',
    'एकत्रित',
    'एकत्रितता',
    'एकत्रीककृत',
    'एकत्रीकरण',
    'एकत्रीभवन',
    'एकदंत',
    'एकदंष्ट्र',
    'एकदम',
    'एकदल',
    'एकदलीय',
    'एकदलीयता',
    'एकदा',
    'एकदिल',
    'एकदिली',
    'एकदिश',
    'एकदिशंत',
    'एकधा',
    'एकनयन',
    'एकनिष्ठ',
    'एकनिष्ठता',
    'एकनिष्ठा',
    'एकनेत्र',
    'एकन्नी',
    'एकपक्षी',
    'एकपक्षीय',
    'एकपक्षीयता',
    'एकपटरी',
    'एकपति',
    'एकपतित्व',
    'एकपत्नी',
    'एकपथ',
    'एकपदी',
    'एकपर्णा',
    'एकपात्रीय',
    'एकपाद',
    'एकपाश्र्वता',
    'एकपाश्र्वीय',
    'एकपाश्र्वीयता',
    'एकपिंगल',
    'एकप्रतिरूपी',
    'एकफसली',
    'एकबारगी',
    'एकबाल',
    'एकबाहु',
    'एकबीजाणु',
    'एकभाव',
    'एकभाषी',
    'एकभूम',
    'एकमंजिला',
    'एकमंज़िला',
    'एकमत',
    'एकमतता',
    'एकमति',
    'एकमत्य',
    'एकमात्र',
    'एकमात्रता',
    'एकमात्रिक',
    'एकमुश्त',
    'एकमेक',
    'एकमेकता',
    'एकरंग',
    'एकरंगीय',
    'एकरस',
    'एकरसता',
    'एकराग',
    'एकरात्र',
    'एकराय',
    'एकरुखा',
    'एकरुप',
    'एकरुपता',
    'एकरुपीय',
    'एकरूप',
    'एकरूपता',
    'एकल',
    'एकलक्ष्यग्रस्तता',
    'एकलड़ी',
    'एकलवय',
    'एकलिंग',
    'एकलौतापन',
    'एकवचन',
    'एकवर्गीय',
    'एकवस्त्रा',
    'एकवाँ',
    'एकविंशति',
    'एकविधतः',
    'एकवेणी',
    'एकसमान',
    'एकसमानता',
    'एकसा',
    'एकसापन',
    'एकसार',
    'एकसारता',
    'एकसूत्र',
    'एकसूत्रता',
    'एकस्तरीयता',
    'एकस्व',
    'एकस्वधारी',
    'एकस्वाधिकारी',
    'एकस्वी',
    'एका',
    'एकांकी',
    'एकांग',
    'एकांगी',
    'एकांगीपन',
    'एकांगीय',
    'एकांगीयता',
    'एकांत',
    'एकांतचर',
    'एकांतचरता',
    'एकांतचर्या',
    'एकांततः',
    'एकांतता',
    'एकांतप्रिय',
    'एकांतभीति',
    'एकांतर',
    'एकांतरा',
    'एकांतरिक',
    'एकांतरित',
    'एकांतवासी',
    'एकांतसेवी',
    'एकांतिक',
    'एकांतिकता',
    'एकांतिम',
    'एकांती',
    'एकांश',
    'एकाउंट',
    'एकाएक',
    'एकाएकी',
    'एकाकार',
    'एकाकारता',
    'एकाकिनी',
    'एकाकी',
    'एकाकीपन',
    'एकाक्ष',
    'एकाक्षर',
    'एकाक्षरीय',
    'एकाक्षिक',
    'एकाग्र',
    'एकाग्रचित्त',
    'एकाग्रचित्ततः',
    'एकाग्रचित्तता',
    'एकाग्रण',
    'एकाग्रता',
    'एकाग्रताहीनता',
    'एकाग्रिचत्तता',
    'एकाणुक',
    'एकात्म',
    'एकात्मक',
    'एकात्मता',
    'एकात्मवाद',
    'एकादश',
    'एकादशभुज',
    'एकादशाक्षर',
    'एकादशी',
    'एकाध',
    'एकाधिक',
    'एकाधिकता',
    'एकाधिकार',
    'एकाधिकारण',
    'एकाधिकारवत',
    'एकाधिकारवाद',
    'एकाधिकारात्मक',
    'एकाधिकारी',
    'एकाधिप',
    'एकाधिपति',
    'एकाधिपत्य',
    'एकान्त',
    'एकान्तर',
    'एकान्तरण',
    'एकान्तवासी',
    'एकायन',
    'एकायामता',
    'एकायामीय',
    'एकार्णव',
    'एकार्थ',
    'एकार्थक',
    'एकार्थी',
    'एकालाप',
    'एकावधान',
    'एकावधानी',
    'एकावलि',
    'एकाविध',
    'एकाश्मक',
    'एकाश्रय',
    'एकाश्रयी',
    'एकास्सी',
    'एकाह',
    'एकीकरण',
    'एकीकृत',
    'एकीकृतता',
    'एकीभवन',
    'एकीभूत',
    'एकेश्वरवाद',
    'एकोत्तर',
    'एकोत्तरता',
    'एकोदक',
    'एकोदरज',
    'एकोदरजता',
    'एकोद्देश्यता',
    'एकोद्भव',
    'एकोन',
    'एकोनता',
    'एकोनविंशति',
    'एकोन्माद',
    'एकोन्मादी',
    'एक्का',
    'एक्जिम',
    'एक्षन',
    'एक्स',
    'एक्सकिरण',
    'एक्सचेंज',
    'एक्सटेंशन',
    'एक्सपोर्ट',
    'एक्सप्रेस',
    'एक्सीडेंट',
    'एक्सैस',
    'एजंट',
    'एजंसी',
    'एजर',
    'एजुकेशन',
    'एजेंट',
    'एजेंटी',
    'एजेंसियां',
    'एजेंसियों',
    'एजेंसी',
    'एजेन्ट',
    'एजेन्सी',
    'एजैंट',
    'एजैंसी',
    'एजैन्सी',
    'एटलस',
    'एड',
    'एडक',
    'एडका',
    'एडवोकेट',
    'एड़',
    'एड़िया',
    'एड़ियों',
    'एड़ी',
    'एड़ीः',
    'एडिशनल',
    'एण',
    'एणी',
    'एण्ड',
    'एतकाद',
    'एतद',
    'एतदनंतर',
    'एतदनुरुप',
    'एतदनुसार',
    'एतदुपरांत',
    'एतद्काल',
    'एतद्कालीन',
    'एतद्द्वारा',
    'एतद्पश्चात',
    'एतद्पूर्व',
    'एतबार',
    'एतबारी',
    'एतमाद',
    'एतवार',
    'एनाउंसर',
    'एनीमा',
    'एबीसी',
    'एबीसीडी',
    'एमन',
    'एम्पोरियम',
    'एयर',
    'एयरकूलर',
    'एयरमैन',
    'एयरलाइंस',
    'एयरलाइन्स',
    'एरंड',
    'एरण',
    'एराफ',
    'एरियर्स',
    'एरियल',
    'एरिया',
    'एला',
    'एलाज',
    'एलान',
    'एलापत्र',
    'एलीका',
    'एलोपैथी',
    'एल्यूमीनियम',
    'एव',
    'एवं',
    'एवज',
    'एवजी',
    'एवम',
    'एवमस्तु',
    'एवरेस्ट',
    'एवेन्यू',
    'एशिया',
    'एशियाई',
    'एषणा',
    'एषणिका',
    'एषणी',
    'एषिता',
    'एस',
    'एसिड',
    'एसी',
    'एसोशिएट',
    'एस्टेट',
    'एह',
    'एहतिमाम',
    'एहतियात',
    'एहसान',
    'एहसानमंद',
    'एहसानमंदी',
    'एहसास',
    'ऐंकर',
    'ऐंक्लोयर',
    'ऐंगल',
    'ऐंग्जाइटी',
    'ऐंच',
    'ऐंचा',
    'ऐंचातानी',
    'ऐंजल',
    'ऐंजिन',
    'ऐंटरटेनमैंट',
    'ऐंटिमनी',
    'ऐंटीक्लाईमैक्स',
    'ऐंटीक्लौकवाइज',
    'ऐंटीथीसिस',
    'ऐंटीसैप्टिक',
    'ऐंटेना',
    'ऐंटैसिड',
    'ऐंट्री',
    'ऐंठ',
    'ऐंठन',
    'ऐंठना',
    'ऐंठा',
    'ऐंठाई',
    'ऐंठाना',
    'ऐंठू',
    'ऐंड',
    'ऐंड़ना',
    'ऐंड़ा',
    'ऐंड़ाना',
    'ऐंदय',
    'ऐंद्र',
    'ऐंद्रजालिक',
    'ऐंद्रजालिका',
    'ऐंद्राणी',
    'ऐंद्रि',
    'ऐंद्रिक',
    'ऐंद्रिय',
    'ऐंद्री',
    'ऐंपैथी',
    'ऐंपोरियम',
    'ऐंप्लायमैंट',
    'ऐंबुलेंस',
    'ऐंबैसी',
    'ऐंबैस्डर',
    'ऐंब्लम',
    'ऐकनालिजमैंट',
    'ऐकमत्य',
    'ऐकांतिक',
    'ऐकाउन्टस',
    'ऐकात्म्य',
    'ऐकुपंक्चर',
    'ऐक्ट',
    'ऐक्टर',
    'ऐक्टिंग',
    'ऐक्ट्रैस',
    'ऐक्य',
    'ऐक्यूपंक्चर',
    'ऐक्रिडिटिड',
    'ऐक्रिलिक',
    'ऐक्रिलौन',
    'ऐक्वामरीन',
    'ऐक्वेरियम',
    'ऐक्शन',
    'ऐक्स',
    'ऐक्सचेंज',
    'ऐक्सचैंज',
    'ऐक्सट्रा',
    'ऐक्सट्रैडीशन',
    'ऐक्सपर्ट',
    'ऐक्सपैंडीचर',
    'ऐक्सपैरिमैंट',
    'ऐक्सपैरिमैंटर',
    'ऐक्सपोज',
    'ऐक्सपोर्ट',
    'ऐक्सप्रैस',
    'ऐक्सरा',
    'ऐक्सरे',
    'ऐक्सल',
    'ऐक्सलरेटर',
    'ऐक्सलरेटिंग',
    'ऐक्सवाईजैड',
    'ऐक्साइज',
    'ऐक्सैंट',
    'ऐक्सैंट्रिक',
    'ऐक्सैंट्रिसिटी',
    'ऐक्सैप्टैंस',
    'ऐक्स्ट्रा',
    'ऐगमार्क',
    'ऐग्जास्ट',
    'ऐग्जीबिट',
    'ऐग्जीबिटर',
    'ऐग्जीबिशन',
    'ऐग्जीमा',
    'ऐग्जैक्टिव',
    'ऐग्मार्क',
    'ऐग्रिकल्चरल',
    'ऐग्रीमैंट',
    'ऐग्रैशन',
    'ऐचिंग',
    'ऐच्छिक',
    'ऐजेंडा',
    'ऐट',
    'ऐटम',
    'ऐटमिक',
    'ऐटमी',
    'ऐटलस',
    'ऐटोल',
    'ऐड',
    'ऐडजुटैंट',
    'ऐडफिल्म',
    'ऐडमिनिस्ट्रेशन',
    'ऐडमिरल',
    'ऐडमिशन',
    'ऐडल्ट',
    'ऐडवर्ट',
    'ऐडवर्टाइजर',
    'ऐडवेंचर',
    'ऐडवेंचरर',
    'ऐडवेंटवादी',
    'ऐडवेंटिस्ट',
    'ऐडवैंट',
    'ऐडवैंटिस्ट',
    'ऐडवोकेट',
    'ऐडिंग',
    'ऐडिक्ट',
    'ऐडिटर',
    'ऐडिटिंग',
    'ऐडिटोरियल',
    'ऐडिशन',
    'ऐडिशनल',
    'ऐडीशन',
    'ऐडीशनल',
    'ऐडोप्टेशन',
    'ऐड्रैस',
    'ऐतराज',
    'ऐतरेय',
    'ऐतिहसिक',
    'ऐतिहासिक',
    'ऐतिहासिकता',
    'ऐथ्लीट',
    'ऐथ्लैटिक्स',
    'ऐन',
    'ऐनक',
    'ऐनकंब्रैंस',
    'ऐनक्लेव',
    'ऐनक्लोज',
    'ऐनक्लोजर',
    'ऐनक्लोजिंग',
    'ऐनक्लोज्ड',
    'ऐनग्रेवर',
    'ऐनग्रेविंग',
    'ऐनज़ाइम',
    'ऐनजाइम',
    'ऐनडोर्समैंट',
    'ऐनर्जी',
    'ऐनलमैंट',
    'ऐनवलप',
    'ऐनसाइक्लोपीडिया',
    'ऐनसिलरी',
    'ऐनि',
    'ऐनिमल',
    'ऐनिमेटर',
    'ऐनिमेटिड',
    'ऐनिमेशन',
    'ऐनिस्थीटाइज',
    'ऐनिस्थीटाइज्ड',
    'ऐनुअल',
    'ऐनुइटी',
    'ऐनेलौग',
    'ऐनेस्थीटाइजेशन',
    'ऐन्टिरैबिक',
    'ऐन्द्रजालिक',
    'ऐन्द्रिय',
    'ऐन्वौय',
    'ऐपिलौग',
    'ऐपिसोड',
    'ऐपोसल',
    'ऐपौइंटमैंट',
    'ऐप्रन',
    'ऐप्रैंटिस',
    'ऐप्रैंटिसशिप',
    'ऐप्लाई',
    'ऐप्लिकेशन',
    'ऐप्लीकेशन',
    'ऐफर्मेशन',
    'ऐफ्र',
    'ऐब',
    'ऐबी',
    'ऐबैटमैंट',
    'ऐबोलिश',
    'ऐब्जार्बर',
    'ऐब्डक्टर',
    'ऐब्डक्टिड',
    'ऐब्डक्शन',
    'ऐब्सटैंशन',
    'ऐब्सौल्यूट',
    'ऐम',
    'ऐमऐनसी',
    'ऐमऐलए',
    'ऐमजैंसी',
    'ऐमजैसी',
    'ऐमपी',
    'ऐमल्शन',
    'ऐमिग्रेशन',
    'ऐमेचर',
    'ऐमेथिस्ट',
    'ऐम्बुलेंस',
    'ऐयाम',
    'ऐयार',
    'ऐयारी',
    'ऐयाश',
    'ऐयाशी',
    'ऐर',
    'ऐरा',
    'ऐराटा',
    'ऐरावण',
    'ऐरावत',
    'ऐरावती',
    'ऐरियल',
    'ऐरेटिड',
    'ऐरोप्लेन',
    'ऐल',
    'ऐलपीजी',
    'ऐलविल',
    'ऐलान',
    'ऐलाने',
    'ऐलिमैंट',
    'ऐलिवेटर',
    'ऐलीवेटर',
    'ऐलेय',
    'ऐलोपैथ',
    'ऐलोपैथिक',
    'ऐलोपैथी',
    'ऐलोय',
    'ऐल्कलाइजर',
    'ऐल्कली',
    'ऐल्कोहलिज्म',
    'ऐल्कोहॉल',
    'ऐल्गोरिद्म',
    'ऐल्फा',
    'ऐल्बम',
    'ऐल्यूमीनियम',
    'ऐवरडिपोइज',
    'ऐवरेज',
    'ऐवरैस्ट',
    'ऐवार्ड',
    'ऐवेन्यु',
    'ऐश',
    'ऐशगाह',
    'ऐशट्रे',
    'ऐशान',
    'ऐशिक',
    'ऐश्य',
    'ऐश्वर',
    'ऐश्वर्य',
    'ऐश्वर्यपूर्ण',
    'ऐश्वर्ययुक्त',
    'ऐश्वर्यवाद',
    'ऐश्वर्यशाली',
    'ऐस',
    'ऐसा',
    'ऐसिड',
    'ऐसिडिटी',
    'ऐसी',
    'ऐसे',
    'ऐसैडिंग',
    'ऐस्कलेटर',
    'ऐस्टेट',
    'ऐस्ट्रानौट',
    'ऐस्ट्रानौटिकल',
    'ऐस्प्लेनेड',
    'ऐहिक',
    'ऐहिकता',
    'ऑ',
    'ऑक्साइड',
    'ऑक्सीकरण',
    'ऑटो',
    'ऑटोकेंद्र',
    'ऑटोकेन्द्र',
    'ऑटोमोबाईल',
    'ऑडिटर',
    'ऑडिटोरियम',
    'ऑनर्स',
    'ऑपरेटर',
    'ऑपरेशन',
    'ऑप्टिकल',
    'ऑफ',
    'ऑफ़',
    'ऑफ़िस',
    'ऑफिसर',
    'ऑयल',
    'ऑर्गेनिक',
    'ऑर्डनेन्स',
    'ऑफ़सेट',
    'ओंकार',
    'ओंकाररुप',
    'ओंकारेश्वर',
    'ओंगन',
    'ओंगाई',
    'ओंठ',
    'ओंडा',
    'ओंस',
    'ओक',
    'ओकना',
    'ओकाई',
    'ओके',
    'ओखल',
    'ओखली',
    'ओखा',
    'ओगल',
    'ओगाई',
    'ओगार',
    'ओगारना',
    'ओघ',
    'ओछा',
    'ओछापन',
    'ओज',
    'ओजपूर्ण',
    'ओजपूर्णता',
    'ओजस्वी',
    'ओजहीन',
    'ओजहीनता',
    'ओजिस्वता',
    'ओजोन',
    'ओझ',
    'ओझरी',
    'ओझल',
    'ओझा',
    'ओझाइन',
    'ओझाई',
    'ओट',
    'ओटक',
    'ओटना',
    'ओटनी',
    'ओटने',
    'ओटयुक्त',
    'ओटा',
    'ओटाई',
    'ओटाईः',
    'ओटी',
    'ओठ',
    'ओडव',
    'ओड़ना',
    'ओड़ा',
    'ओढ़न',
    'ओढ़ना',
    'ओढ़नी',
    'ओढ़ाना',
    'ओढ़े',
    'ओत',
    'ओतप्रोत',
    'ओतप्रोतता',
    'ओथ',
    'ओदा',
    'ओदोरी',
    'ओनामासी',
    'ओप',
    'ओपचारिकता',
    'ओपची',
    'ओपथी',
    'ओपनर',
    'ओपना',
    'ओपनिंग',
    'ओपल',
    'ओपियम',
    'ओबरी',
    'ओम',
    'ओमनी',
    'ओम्',
    'ओर',
    'ओरछोरयुक्त',
    'ओरछोरहीन',
    'ओरिजिनल',
    'ओरेंज',
    'ओल',
    'ओलचा',
    'ओलती',
    'ओला',
    'ओलिंपस',
    'ओलिंपिक्स',
    'ओली',
    'ओल्ड',
    'ओल्ल',
    'ओवन',
    'ओवर',
    'ओवरकोट',
    'ओवरटेक',
    'ओवरटेकिंग',
    'ओवरड्राफ्ट',
    'ओवरब्रिज',
    'ओवरसियर',
    'ओवरसियरी',
    'ओवरसीइंग',
    'ओवरसीयर',
    'ओवरहैड्स',
    'ओवरी',
    'ओवल',
    'ओशन',
    'ओषजन',
    'ओषण',
    'ओषध',
    'ओषधि',
    'ओषधिगर्भ',
    'ओषधिधर',
    'ओषधीश',
    'ओषाति',
    'ओष्ठ',
    'ओष्ठय',
    'ओष्ठवाला',
    'ओष्ठीय',
    'ओस',
    'ओसपूर्ण',
    'ओसर',
    'ओसांक',
    'ओसाई',
    'ओसाना',
    'ओसारा',
    'ओसीला',
    'ओह',
    'ओहदा',
    'ओहदेदार',
    'ओहदेदारी',
    'ओहना',
    'ओहार',
    'ओऽम',
    'ओऽम्',
    'औ',
    'औंठ',
    'औंधना',
    'औंधा',
    'औंधापन',
    'औंधी',
    'औंधे',
    'औइल',
    'औइलिंग',
    'औकात',
    'औक्टंट',
    'औक्टेगन',
    'औक्सीजन',
    'औखा',
    'औगा',
    'औगी',
    'औगुन',
    'औघ',
    'औघट',
    'औघड़',
    'औघर',
    'औचक',
    'औचट',
    'औचित्य',
    'औचित्यपूर्ण',
    'औचित्यवाद',
    'औचित्यहीनता',
    'औजार',
    'औजिर्त्य',
    'औटन',
    'औटना',
    'औटनी',
    'औटाई',
    'औटाईः',
    'औटाना',
    'औटाव',
    'औटोनमी',
    'औड',
    'औडव',
    'औड़ी',
    'औडिऐंस',
    'औडिट',
    'औडिटर',
    'औडिटिंग',
    'औडिटिड',
    'औडिटोरियम',
    'औडियो',
    'औडुपिक',
    'औढर',
    'औतारी',
    'औत्तरेय',
    'औत्तानपादि',
    'औत्स',
    'औथेंटिकेटिड',
    'औदक',
    'औदर',
    'औदरिक',
    'औदर्य',
    'औदार्य',
    'औदिकाः',
    'औदुंबर',
    'औद्भिद',
    'औद्यम',
    'औद्यमिक',
    'औद्योगिक',
    'औद्योगिकता',
    'औद्योगिकीकरण',
    'औद्योगीकरण',
    'औद्योगीकृत',
    'औद्यौगिक',
    'औधस',
    'औन',
    'औनली',
    'औने',
    'औपचारिक',
    'औपचारिकतः',
    'औपचारिकता',
    'औपचारिकताएं',
    'औपचारिकताओं',
    'औपचारिकतापूर्ण',
    'औपचारिकतावादी',
    'औपचारिकताहीनता',
    'औपचारित',
    'औपनिवेशिक',
    'औपनिवेशिकता',
    'औपनिषद',
    'औपनिषदिक',
    'औपन्यासिक',
    'औपम्य',
    'औपरेटिंग',
    'औपहारिक',
    'औपेरा',
    'औफर',
    'औब्जर्वर',
    'औब्जर्वेशन',
    'औब्जैक्टिव',
    'औब्सैशन',
    'औम्',
    'और',
    'औरंगाबाद',
    'औरगंजा',
    'औरत',
    'औरतों',
    'औरभ्र',
    'औरभ्रिक',
    'औरस',
    'औरसता',
    'औरस्य',
    'और्केस्ट्रा',
    'और्गन',
    'और्गेनिक',
    'और्ज',
    'और्डनैंस',
    'और्डर',
    'और्णिक',
    'और्व',
    'और्वशेय',
    'औला',
    'औलाद',
    'औलान',
    'औलिया',
    'औवर',
    'औवल',
    'औशीर',
    'औषध',
    'औषधकारक',
    'औषधजल',
    'औषधशाला',
    'औषधांग',
    'औषधागार',
    'औषधालय',
    'औषधि',
    'औषधिक',
    'औषधियां',
    'औषधी',
    'औषधीय',
    'औष्ट्रिक',
    'औष्ठ',
    'औष्ण',
    'औसत',
    'औसतन',
    'औसर',
    'औसान',
    'औसिलोग्राफ',
    'औस्सीलेटर',
    'औज़ार',
    'क',
    'कँकड़ीला',
    'कँगन',
    'कँगना',
    'कँगनी',
    'कँगला',
    'कँगलापन',
    'कँचुली',
    'कँजियाना',
    'कँजी',
    'कँटिया',
    'कँटियाना',
    'कँटीला',
    'कँडेरा',
    'कँधेला',
    'कँपकँपाता',
    'कँपकँपाती',
    'कँपकँपाना',
    'कँपकँपाहट',
    'कँपकँपी',
    'कँसहँड़ा',
    'कँसहँड़ी',
    'कंक',
    'कंकड़',
    'कंकड़िया',
    'कंकड़ी',
    'कंकड़ीः',
    'कंकड़ीला',
    'कंकण',
    'कंकतांग',
    'कंकताकार',
    'कंकर',
    'कंकरिया',
    'कंकरी',
    'कंकरीट',
    'कंकरीला',
    'कंकाल',
    'कंकालय',
    'कंकालिनी',
    'कंकेल्ल',
    'कंकोल',
    'कंकोलक',
    'कंक्रीट',
    'कंगण',
    'कंगन',
    'कंगनी',
    'कंगारू',
    'कंगाल',
    'कंगाली',
    'कंगुनी',
    'कंगूरा',
    'कंघा',
    'कंघी',
    'कंच',
    'कंचन',
    'कंचनजंघा',
    'कंचना',
    'कंचा',
    'कंचिका',
    'कंचुक',
    'कंचुकित',
    'कंचुकी',
    'कंचे',
    'कंज',
    'कंजंप्शन',
    'कंजई',
    'कंजप्शन',
    'कंजर',
    'कंजा',
    'कंजार',
    'कंजूस',
    'कंजूसी',
    'कंज्यूमर',
    'कंज्यूमर्स',
    'कंज्यूमेबल्स',
    'कंट',
    'कंटक',
    'कंटकः',
    'कंटकन',
    'कंटकयुक्त',
    'कंटकहीन',
    'कंटकाकीर्ण',
    'कंटकाशन',
    'कंटकित',
    'कंटकी',
    'कंटकीय',
    'कंटफल',
    'कंटर',
    'कंटाइन',
    'कंटालु',
    'कंटिका',
    'कंटिनैंट',
    'कंटिया',
    'कंटी',
    'कंटीनैंटल',
    'कंटीला',
    'कंटीलापन',
    'कंटीले',
    'कंटेनर',
    'कंटैंट्स',
    'कंटैक्स्ट',
    'कंट्री',
    'कंट्रैक्ट',
    'कंट्रोल',
    'कंट्रोलर',
    'कंठ',
    'कंठगत',
    'कंठग्रंथि',
    'कंठतालव्य',
    'कंठधर',
    'कंठबंध',
    'कंठमाला',
    'कंठय',
    'कंठविषयक',
    'कंठशोथ',
    'कंठस्थ',
    'कंठस्थपाठी',
    'कंठस्थीकरण',
    'कंठहार',
    'कंठहीन',
    'कंठहीनता',
    'कंठा',
    'कंठाग्र',
    'कंठाग्रीकरण',
    'कंठाल',
    'कंठालिंगन',
    'कंठावरोध',
    'कंठावरोधन',
    'कंठिका',
    'कंठिद',
    'कंठिनी',
    'कंठी',
    'कंठीय',
    'कंठीरव',
    'कंठुआ',
    'कंठेकाल',
    'कंठोष्ठय',
    'कंठ्य',
    'कंडक्ट',
    'कंडक्टर',
    'कंडम',
    'कंडरा',
    'कंडलन',
    'कंडलिनी',
    'कंडा',
    'कंडाल',
    'कंडील',
    'कंडोल',
    'कंडोलक',
    'कंत',
    'कंता',
    'कंथा',
    'कंथाधारी',
    'कंथी',
    'कंद',
    'कंदभोजी',
    'कंदमूल',
    'कंदर',
    'कंदरा',
    'कंदराकर',
    'कंदर्थ',
    'कंदर्प',
    'कंदर्पदहन',
    'कंदल',
    'कंदला',
    'कंदशूरण',
    'कंदा',
    'कंदाकार',
    'कंदाकृति',
    'कंदाहारी',
    'कंदीय',
    'कंदील',
    'कंदु',
    'कंदुक',
    'कंदुकता',
    'कंदुकनशीलता',
    'कंदुकाकार',
    'कंदुकाकृति',
    'कंदुकार',
    'कंदुपक्य',
    'कंध',
    'कंधर',
    'कंधरा',
    'कंधवाह',
    'कंधा',
    'कंधाः',
    'कंधाकार',
    'कंधावर',
    'कंधे',
    'कंधेली',
    'कंप',
    'कंपकंपी',
    'कंपन',
    'कंपनशील',
    'कंपनियां',
    'कंपनियों',
    'कंपनी',
    'कंपस',
    'कंपसघर',
    'कंपा',
    'कंपाउंड',
    'कंपाउंडर',
    'कंपायक',
    'कंपायमान',
    'कंपायित',
    'कंपास',
    'कंपित',
    'कंपित्र',
    'कंपिल',
    'कंपू',
    'कंपूटर',
    'कंपैक्ट',
    'कंपोज',
    'कंपोज़',
    'कंपोज़र',
    'कंपोज़ीशन',
    'कंपोजि',
    'कंपोजिं',
    'कंपोजिंग',
    'कंपोजीटर',
    'कंपोस्ट',
    'कंपोज़ीटर',
    'कंप्यूटर',
    'कंप्यूटराइज्',
    'कंप्यूटरीकरण',
    'कंप्यूटरीकृत',
    'कंप्यूटिंग',
    'कंप्रेसर',
    'कंप्रैस',
    'कंप्लैक्स',
    'कंबल',
    'कंबली',
    'कंबाइन',
    'कंबी',
    'कंबु',
    'कंबुका',
    'कंभकार',
    'कंम्प्यूटर',
    'कंरट',
    'कंश',
    'कंस',
    'कंसः',
    'कंसक',
    'कंसताल',
    'कंसल्टैंट',
    'कंसारि',
    'कंसैंट्रिक',
    'कअपवादी',
    'कई',
    'कईक',
    'ककंधु',
    'ककड़ी',
    'ककहरा',
    'ककार',
    'ककुद',
    'ककुद्मान',
    'ककुद्मी',
    'ककुभ',
    'ककूना',
    'ककैया',
    'ककोड़ा',
    'ककोर्टक',
    'कक्का',
    'कक्ष',
    'कक्षप',
    'कक्षपथ',
    'कक्षवर्ती',
    'कक्षा',
    'कक्षाएं',
    'कक्षापट',
    'कक्षावली',
    'कक्षिका',
    'कक्षीय',
    'कक्ष्या',
    'कखग',
    'कगर',
    'कगार',
    'कगारण',
    'कगारिका',
    'कच',
    'कचकच',
    'कचकचाहट',
    'कचकड़',
    'कचपच',
    'कचपैंदिया',
    'कचबचिया',
    'कचर',
    'कचरधान',
    'कचरा',
    'कचरी',
    'कचलोहा',
    'कचलोही',
    'कचलोहू',
    'कचलौंदा',
    'कचव',
    'कचहरी',
    'कचहरीः',
    'कचाई',
    'कचारना',
    'कचालू',
    'कचिया',
    'कचूमर',
    'कचोट',
    'कचोटना',
    'कचोरा',
    'कचोरी',
    'कचौड़ी',
    'कचौरी',
    'कच्चर',
    'कच्चहाल',
    'कच्चा',
    'कच्चापन',
    'कच्चासूत',
    'कच्ची',
    'कच्चू',
    'कच्चे',
    'कच्चेपन',
    'कच्छ',
    'कच्छप',
    'कच्छपावातार',
    'कच्छवास',
    'कच्छा',
    'कच्छी',
    'कछनी',
    'कछरा',
    'कछार',
    'कछुआ',
    'कछुए',
    'कछोटा',
    'कज',
    'कजकोल',
    'कजरारा',
    'कजरी',
    'कजला',
    'कजली',
    'कजलौटा',
    'कजलौटी',
    'कजाक',
    'कजावा',
    'कजुष्क',
    'कज्',
    'कज्जल',
    'कज्जलित',
    'कज्या',
    'कट',
    'कटंब',
    'कटक',
    'कटका',
    'कटकीय',
    'कटखना',
    'कटग्लास',
    'कटघरा',
    'कटड़ा',
    'कटड़ी',
    'कटन',
    'कटना',
    'कटनास',
    'कटपीस',
    'कटया',
    'कटर',
    'कटरा',
    'कटरु',
    'कटलफिश',
    'कटलरी',
    'कटलेट',
    'कटल्ला',
    'कटवाना',
    'कटवाने',
    'कटहरा',
    'कटहल',
    'कटहा',
    'कटा',
    'कटाई',
    'कटाकटी',
    'कटाक्ष',
    'कटाक्षपूर्ण',
    'कटाटंक',
    'कटान',
    'कटापन',
    'कटार',
    'कटारा',
    'कटारिया',
    'कटारी',
    'कटाव',
    'कटावदान',
    'कटावदार',
    'कटास',
    'कटाह',
    'कटाही',
    'कटि',
    'कटिंग',
    'कटिक',
    'कटिका',
    'कटिबंध',
    'कटिबंधीय',
    'कटिबद्घ',
    'कटिबद्घता',
    'कटिभूषण',
    'कटिया',
    'कटिलट',
    'कटिवस्त्र',
    'कटिवात',
    'कटिसूत्र',
    'कटिस्नान',
    'कटिहीनता',
    'कटिहीना',
    'कटी',
    'कटीर',
    'कटीला',
    'कटु',
    'कटुक',
    'कटुक्याण',
    'कटुता',
    'कटुतापूर्वक',
    'कटुफल',
    'कटुबीजा',
    'कटुभाषण',
    'कटुभाषा',
    'कटुभाषी',
    'कटुरव',
    'कटूक्ति',
    'कटे',
    'कटेपुष्पः',
    'कटेहर',
    'कटैना',
    'कटैनी',
    'कटैया',
    'कटैला',
    'कटोरदान',
    'कटोरा',
    'कटोरी',
    'कटौतियां',
    'कटौती',
    'कटौनी',
    'कट्टम',
    'कट्टर',
    'कट्टरता',
    'कट्टरपंथी',
    'कट्टरपन',
    'कट्टा',
    'कट्टी',
    'कट्टूमरम',
    'कट्टो',
    'कट्ठाः',
    'कठ',
    'कठः',
    'कठकोला',
    'कठघरा',
    'कठपुतली',
    'कठफोड़',
    'कठफोड़वा',
    'कठफोड़ा',
    'कठफोर',
    'कठबाप',
    'कठबैद',
    'कठमर्द',
    'कठमस्त',
    'कठमस्ता',
    'कठमुल्ला',
    'कठमोर',
    'कठरा',
    'कठरी',
    'कठवत',
    'कठवता',
    'कठवैद्य',
    'कठहुज्जत',
    'कठहुज्जती',
    'कठारी',
    'कठिन',
    'कठिनता',
    'कठिनाइयां',
    'कठिनाइयों',
    'कठिनाई',
    'कठिनार्बुद',
    'कठिनिका',
    'कठिनीकृत',
    'कठिनीभूत',
    'कठैला',
    'कठैली',
    'कठोर',
    'कठोरता',
    'कठोरतापूर्वक',
    'कठोरपन',
    'कठोरहृदय',
    'कठोरह्रदय',
    'कठोरीकरण',
    'कठोरीकृत',
    'कठोरीभवन',
    'कठोरीभूत',
    'कठौत',
    'कठौता',
    'कठौती',
    'कठफ़ोड़ा',
    'कडंगर',
    'कडमडपन',
    'कड़',
    'कड़ंगा',
    'कड़क',
    'कड़कड़',
    'कड़कड़ाती',
    'कड़कड़ाना',
    'कड़कड़ाहट',
    'कड़कना',
    'कड़का',
    'कड़काना',
    'कड़की',
    'कड़खा',
    'कड़खैत',
    'कड़वा',
    'कड़वाना',
    'कड़वापन',
    'कड़वासच',
    'कड़वाहट',
    'कड़वाहोना',
    'कड़वी',
    'कड़हन',
    'कड़ा',
    'कड़ाई',
    'कड़ाका',
    'कड़ाके',
    'कड़ापन',
    'कड़ाह',
    'कड़ाहा',
    'कड़ाही',
    'कड़ियल',
    'कड़ी',
    'कड़ुआ',
    'कड़े',
    'कड़ेरा',
    'कड़ों',
    'कडाह',
    'कडाही',
    'कडुआ',
    'कडुआना',
    'कडुआहट',
    'कडुवा',
    'कडे',
    'कढ़ना',
    'कढ़नी',
    'कढ़ा',
    'कढ़ाई',
    'कढ़ाईदार',
    'कढ़ी',
    'कढ़ैया',
    'कढुआ',
    'कढुला',
    'कढुवा',
    'कण',
    'कणन',
    'कणरी',
    'कणहीन',
    'कणाटीरक',
    'कणाद',
    'कणाश्म',
    'कणिकता',
    'कणिका',
    'कणिकांकन',
    'कणिश',
    'कणी',
    'कणीक',
    'कणीकरण',
    'कणीकृत',
    'कणीभवन',
    'कणेंद्रिय',
    'कणोर्पकर्णिका',
    'कण्टक',
    'कण्ठ',
    'कण्ठच्छद',
    'कण्ठस्थ',
    'कण्डशोथ',
    'कण्डिका',
    'कण्व',
    'कतक',
    'कतन',
    'कतनी',
    'कतनैत',
    'कतरछाँट',
    'कतरन',
    'कतरना',
    'कतरनी',
    'कतरने',
    'कतरब्योंत',
    'कतरवाँ',
    'कतरा',
    'कतराई',
    'कतराना',
    'कतरी',
    'कतला',
    'कतली',
    'कतले',
    'कतवार',
    'कता',
    'कताई',
    'कतार',
    'कतिपय',
    'कतिपयता',
    'कतुपशु',
    'कतौनी',
    'कत्तर',
    'कत्तल',
    'कत्तार्',
    'कत्ती',
    'कत्थई',
    'कत्थक',
    'कत्थना',
    'कत्था',
    'कत्थाः',
    'कत्रतु',
    'कत्रीं',
    'कत्ल',
    'कथं',
    'कथंकथिक',
    'कथक',
    'कथकली',
    'कथक्कड़',
    'कथन',
    'कथनानुसार',
    'कथनीय',
    'कथरी',
    'कथा',
    'कथांतर',
    'कथाएं',
    'कथाकरण',
    'कथाकार',
    'कथाक्रम',
    'कथाचित्र',
    'कथानक',
    'कथाप्रवेश',
    'कथामाला',
    'कथामुख',
    'कथावस्तु',
    'कथासूत्र',
    'कथित',
    'कथूरिया',
    'कथेपकथन',
    'कथ्य',
    'कद',
    'कदंब',
    'कदंबः',
    'कदंबक',
    'कदन',
    'कदम',
    'कदमी',
    'कदमों',
    'कदर',
    'कदर्थन',
    'कदली',
    'कदलीजंघ',
    'कदा',
    'कदाचन',
    'कदाचार',
    'कदाचारिणी',
    'कदाचारिता',
    'कदाचारी',
    'कदाचित',
    'कदापि',
    'कदाशय',
    'कदाश्य',
    'कदाहार',
    'कदुज',
    'कदुपुत्री',
    'कद्',
    'कद्द',
    'कद्दू',
    'कद्दूकस',
    'कद्यत्लबाज़',
    'कद्यलम',
    'कद्यलमज़द',
    'कद्यलमदानी',
    'कद्याबू',
    'कद्रु',
    'कद्रुसूत',
    'कन',
    'कनक',
    'कनकटा',
    'कनकनाना',
    'कनकनापन',
    'कनकनाहट',
    'कनकप्रभ',
    'कनका',
    'कनकाचल',
    'कनकी',
    'कनकूत',
    'कनक्शन',
    'कनखज़ूरा',
    'कनखजूरा',
    'कनखियाना',
    'कनखियों',
    'कनखी',
    'कनछिदाई',
    'कनछेदन',
    'कनछेदी',
    'कनटेनर',
    'कनटोप',
    'कनटोपा',
    'कनडैंस्ड',
    'कनपटी',
    'कनपेड़ा',
    'कनफ़र्म',
    'कनफ़र्मेशन',
    'कनफ़ैशन',
    'कनफ़ैशनल',
    'कनफ़ैसर',
    'कनफुँकवा',
    'कनफुँका',
    'कनफुसका',
    'कनफुसकी',
    'कनफ्रैशन',
    'कनबतिया',
    'कनबिंघा',
    'कनमैल',
    'कनवाँसा',
    'कनवाँसी',
    'कनवाय',
    'कनवास',
    'कनविक्ट',
    'कनवीन',
    'कनवीनर',
    'कनवीनिंग',
    'कनवैंट',
    'कनवैंशन',
    'कनवैक्टर',
    'कनवोकेशन',
    'कनसलाई',
    'कनसाइनर',
    'कनसाइनी',
    'कनसुई',
    'कनसुरा',
    'कनसैंट्रिक',
    'कनस्तर',
    'कनस्तरी',
    'कनहा',
    'कनहार',
    'कनहारी',
    'कनागत',
    'कनाट',
    'कनाडा',
    'कनाती',
    'कनाल',
    'कनासी',
    'कनियाना',
    'कनिव्क्ट',
    'कनिष्ट',
    'कनिष्ठ',
    'कनिष्ठक',
    'कनिष्ठता',
    'कनिष्ठा',
    'कनिष्ठिका',
    'कनी',
    'कनीज़',
    'कनीनक',
    'कनीनका',
    'कनीनिका',
    'कनीयता',
    'कनीयस',
    'कनू',
    'कनेक्शन',
    'कनेखी',
    'कनेठा',
    'कनेठी',
    'कनेर',
    'कनैक्शन',
    'कनैठी',
    'कनोहू',
    'कनौजिया',
    'कनौठा',
    'कनौड़ा',
    'कनौड़ापन',
    'कनौड़िया',
    'कन्क्रीट',
    'कन्द',
    'कन्धे',
    'कन्नड़',
    'कन्ना',
    'कन्नाईवैंस',
    'कन्नास',
    'कन्नी',
    'कन्यका',
    'कन्यस',
    'कन्या',
    'कन्याः',
    'कन्यागत',
    'कन्यादान',
    'कन्यादानी',
    'कन्याधन',
    'कन्यापति',
    'कन्यापुर',
    'कन्यापूजा',
    'कन्याप्राप्ति',
    'कन्यारासी',
    'कन्यालाभ',
    'कन्याविजय',
    'कन्यावेदी',
    'कन्युष',
    'कन्हाई',
    'कन्हैया',
    'कऩफ़र्म्ड',
    'कप',
    'कपंथ',
    'कपंथी',
    'कपंनी',
    'कपकपी',
    'कपट',
    'कपटता',
    'कपटपूर्ण',
    'कपटपूर्वक',
    'कपटवार्ता',
    'कपटवेष',
    'कपटहीन',
    'कपटहीनता',
    'कपटा',
    'कपटाचरण',
    'कपटाचार',
    'कपटी',
    'कपटीपन',
    'कपड़',
    'कपड़उ',
    'कपड़कोट',
    'कपड़छन',
    'कपड़छनाई',
    'कपड़छान',
    'कपड़धूलि',
    'कपड़ा',
    'कपड़ाः',
    'कपड़े',
    'कपड़ों',
    'कपडा',
    'कपडे',
    'कपन',
    'कपरथ',
    'कपर्द',
    'कपर्दिका',
    'कपर्दिनी',
    'कपर्दी',
    'कपाट',
    'कपाटःदिल्ला',
    'कपाल',
    'कपालपाणि',
    'कपालस्थ',
    'कपालास्त्र',
    'कपालिका',
    'कपाली',
    'कपास',
    'कपासः',
    'कपासी',
    'कपासीय',
    'कपि',
    'कपिंजल',
    'कपिज',
    'कपिध्वज',
    'कपिल',
    'कपिला',
    'कपिलांजन',
    'कपिलाचार्य',
    'कपिलाश्व',
    'कपिली',
    'कपिश',
    'कपिशक',
    'कपिशा',
    'कपिशाक',
    'कपींद्र',
    'कपीतल',
    'कपीय',
    'कपीश',
    'कपूत',
    'कपूर',
    'कपूरा',
    'कपूरी',
    'कपूरे',
    'कपॄ',
    'कपोत',
    'कपोतक',
    'कपोतांजन',
    'कपोतारि',
    'कपोती',
    'कपोल',
    'कपोलास्थि',
    'कप्तान',
    'कप्तानी',
    'कप्फस',
    'कफ',
    'कफन',
    'कफनाना',
    'कफपूर्ण',
    'कफ़',
    'कफ़न',
    'कफ़नी',
    'कफ़पा',
    'कफ़स',
    'कफांतक',
    'कफारि',
    'कफालत',
    'कफोणि',
    'कफोत्सारण',
    'कब',
    'कबंध',
    'कबक',
    'कबर',
    'कबरा',
    'कबरी',
    'कबर्ड',
    'कबवासपूर्ण',
    'कबाड़',
    'कबाड़ा',
    'कबाड़िया',
    'कबाड़ी',
    'कबाब',
    'कबाबः',
    'कबाबी',
    'कबारी',
    'कबीर',
    'कबीला',
    'कबूतर',
    'कबूतरः',
    'कबूतरी',
    'कबूलना',
    'कब्ज',
    'कब्जकर',
    'कब्जा',
    'कब्जाकर्ता',
    'कब्जाधारी',
    'कब्जी',
    'कब्जे',
    'कब्र',
    'कब्ज़ा',
    'कभार',
    'कभी',
    'कम',
    'कमंगर',
    'कमंडल',
    'कमंडली',
    'कमंडलु',
    'कमंडलुधर',
    'कमंद',
    'कमअक्',
    'कमअसल',
    'कमइल्म',
    'कमउम्र',
    'कमकर',
    'कमकस',
    'कमख़र्च',
    'कमख़्वाब',
    'कमगो',
    'कमची',
    'कमजल',
    'कमज़र्फ',
    'कमज़र्फी',
    'कमज़ोर',
    'कमज़ोरी',
    'कमजोर',
    'कमजोरी',
    'कमठा',
    'कमतर',
    'कमतरी',
    'कमतरीन',
    'कमतरीनी',
    'कमतवज्जही',
    'कमताई',
    'कमती',
    'कमतोला',
    'कमननामि',
    'कमनसीब',
    'कमनसीबी',
    'कमनीय',
    'कमनीयता',
    'कमन्द',
    'कमबख़्त',
    'कमबख़्ती',
    'कमबोला',
    'कमबोलापन',
    'कमभरा',
    'कमयाब',
    'कमर',
    'कमरकटि',
    'कमरख',
    'कमरबंद',
    'कमरबंदी',
    'कमरबन्द',
    'कमरहना',
    'कमरा',
    'कमरिया',
    'कमरे',
    'कमरों',
    'कमल',
    'कमलनयन',
    'कमलनयना',
    'कमलनयनी',
    'कमलनेत्र',
    'कमलबाई',
    'कमलभक्षी',
    'कमलभोजी',
    'कमलमुख',
    'कमला',
    'कमलाकर',
    'कमलाकृति',
    'कमलाग्रजा',
    'कमलालया',
    'कमलासन',
    'कमलासना',
    'कमलिनी',
    'कमली',
    'कमलेक्षण',
    'कमलेगवर',
    'कमलेश',
    'कमलों',
    'कमसरियट',
    'कमसिन',
    'कमसिनी',
    'कमसुख़न',
    'कमहिम्मत',
    'कमहिम्मती',
    'कमहेरा',
    'कमहैसियत',
    'कमांडमैंट्स',
    'कमांडर',
    'कमांडाकंट',
    'कमांडिंग',
    'कमांडिग',
    'कमांडैंट',
    'कमांडो',
    'कमाइच',
    'कमाई',
    'कमाऊ',
    'कमाए',
    'कमाण्डर',
    'कमान',
    'कमानः',
    'कमानचा',
    'कमानदार',
    'कमाना',
    'कमानिया',
    'कमानी',
    'कमानीदारी',
    'कमाने',
    'कमाया',
    'कमाल',
    'कमालिनी',
    'कमासुत',
    'कमिटी',
    'कमिर्णी',
    'कमिर्ता',
    'कमिर्ष्ठ',
    'कमिशन',
    'कमिशनर',
    'कमिश्नर',
    'कमिश्नरी',
    'कमिश्वन',
    'कमी',
    'कमीं',
    'कमीज',
    'कमीज़',
    'कमीन',
    'कमीनगाह',
    'कमीनगी',
    'कमीना',
    'कमीनापन',
    'कमीपूरक',
    'कमीबेशी',
    'कमीशंड',
    'कमीशंडआफिसर',
    'कमीशंडऔफिसर',
    'कमीशन',
    'कमीज़',
    'कमुआ',
    'कमेंद्रिय',
    'कमेटी',
    'कमेड़ा',
    'कमेरा',
    'कमेला',
    'कमो',
    'कमोड',
    'कमोरी',
    'कमोर्',
    'कमोर्न्मुखता',
    'कमौडिटी',
    'कम्पन',
    'कम्पनियां',
    'कम्पनी',
    'कम्पनीवार',
    'कम्पायमान',
    'कम्पायमानता',
    'कम्प्यूटर',
    'कम्प्यूटरीकरण',
    'कम्प्यूटरीकृत',
    'कम्प्यूटरों',
    'कम्प्रेसर',
    'कम्बल',
    'कम्बु',
    'कम्बू',
    'कम्मा',
    'कम्यून',
    'कम्यूनिकेटर',
    'कम्यूनिकेशन',
    'कम्यूनिज्',
    'कम्यूनिटी',
    'कम्यूनियन',
    'कम्यूनीक',
    'कम्र',
    'कम्रत्याग',
    'कम्रभूत',
    'कम्रयोग',
    'कम्र्य',
    'कम्र्यता',
    'कमज़ोर',
    'कमज़ोरी',
    'कयाक',
    'कयाधु',
    'कयामत',
    'कयूरेट',
    'कयूरेटर',
    'कयोंकि',
    'कर',
    'करंक',
    'करंगण',
    'करंजक',
    'करंट',
    'करंड',
    'करंडक',
    'करंडिका',
    'करंडी',
    'करंद',
    'करंबन',
    'करंमिबत',
    'करंसी',
    'करक',
    'करकट',
    'करकता',
    'करकना',
    'करके',
    'करखनदार',
    'करखना',
    'करखा',
    'करगत',
    'करगहनी',
    'करग्रह',
    'करग्राही',
    'करघा',
    'करच्चा',
    'करछल',
    'करछा',
    'करछी',
    'करछुल',
    'करछुला',
    'करछुली',
    'करज',
    'करड',
    'करडी',
    'करण',
    'करणताः',
    'करणी',
    'करणीय',
    'करणोयता',
    'करतब',
    'करतबबाज़',
    'करतबबाज़ी',
    'करतबिया',
    'करतबी',
    'करतबों',
    'करतल',
    'करतलध्वनि',
    'करतली',
    'करता',
    'करतार',
    'करताल',
    'करतालिका',
    'करताली',
    'करती',
    'करतूत',
    'करतूतिया',
    'करतूती',
    'करते',
    'करदा',
    'करदाता',
    'करदेयता',
    'करदौना',
    'करधनी',
    'करन',
    'करनहार',
    'करनहारा',
    'करना',
    'करनाः',
    'करनाई',
    'करनाकी',
    'करनाटक',
    'करनाटकी',
    'करनाटी',
    'करनाल',
    'करनी',
    'करने',
    'करनेवाला',
    'करनेवाली',
    'करनेवाले',
    'करनैल',
    'करपलई',
    'करपात्र',
    'करपाल',
    'करपृष्ठ',
    'करफ्',
    'करबद्घ',
    'करबद्घता',
    'करबला',
    'करबाल',
    'करब्र',
    'करब्रगाह',
    'करभ',
    'करभी',
    'करम',
    'करमकल्ला',
    'करमफ़रमाँ',
    'करमर्द',
    'करमर्दक',
    'करमशः',
    'करमाल',
    'करमाला',
    'करमाली',
    'करमुक्त',
    'करमूल',
    'करयात्रा',
    'करयुक्त',
    'करयोग्य',
    'कररहित',
    'कररा',
    'करवंचन',
    'करवट',
    'करवल',
    'करवा',
    'करवाइए',
    'करवाए',
    'करवाएं',
    'करवाकर',
    'करवाता',
    'करवाते',
    'करवाना',
    'करवाने',
    'करवाया',
    'करवायें',
    'करवाल',
    'करवालधारी',
    'करवाली',
    'करवीर',
    'करवेर',
    'करसंग्रह',
    'करसा',
    'करसी',
    'करस्पर्श',
    'करह',
    'करहाट',
    'करहाटक',
    'करहीन',
    'करा',
    'कराँकुल',
    'करांकुल',
    'करांगुष्ठ',
    'करांत',
    'करांती',
    'कराई',
    'कराए',
    'कराकर',
    'कराटे',
    'करात',
    'कराती',
    'कराते',
    'कराधान',
    'कराधीन',
    'कराधीनता',
    'कराधेय',
    'कराना',
    'कराने',
    'करानेवाला',
    'करापवंचन',
    'करापात',
    'करामात',
    'करामाती',
    'कराया',
    'करायें',
    'करायेंगे',
    'करार',
    'करारनामा',
    'करारमूलक',
    'करारा',
    'करारी',
    'करारोपण',
    'कराल',
    'करालता',
    'करालिक',
    'करालिका',
    'करावकाश',
    'करावल',
    'कराह',
    'कराहत',
    'कराहते',
    'कराहना',
    'कराहनेवाला',
    'कराही',
    'करिए',
    'करिबदन',
    'करिया',
    'करिये',
    'करिश्मा',
    'करी',
    'करींद्र',
    'करीपति',
    'करीब',
    'करीम',
    'करीमी',
    'करीश',
    'करुण',
    'करुणता',
    'करुणा',
    'करुणाकर',
    'करुणामय',
    'करू',
    'करूंगा',
    'करूआ',
    'करूण',
    'करूणा',
    'करूणाकार',
    'करूणामय',
    'करूणायुक्त',
    'करूणार्द्र',
    'करूणाशील',
    'करूणाहीन',
    'करूणाहीनता',
    'करूणोत्पादक',
    'करूवा',
    'करूवार',
    'करे',
    'करें',
    'करेंगी',
    'करेंगे',
    'करेंगें',
    'करेगा',
    'करेगी',
    'करेणु',
    'करेणुका',
    'करेणू',
    'करेतर',
    'करेन्सी',
    'करेब',
    'करेल',
    'करेला',
    'करैत',
    'करैया',
    'करैल',
    'करो',
    'करों',
    'करोट',
    'करोटि',
    'करोटी',
    'करोड',
    'करोड़',
    'करोड़खुख',
    'करोड़पती',
    'करोड़ाधिक',
    'करोड़ी',
    'करोना',
    'करोनी',
    'करौंतना',
    'करौंती',
    'करौंदा',
    'करौत',
    'करौता',
    'करौती',
    'करौल',
    'करौली',
    'कर्क',
    'कर्कट',
    'कर्कटक',
    'कर्कटार्बुद',
    'कर्कटी',
    'कर्कर',
    'कर्कराक्ष',
    'कर्कश',
    'कर्कशता',
    'कर्कशा',
    'कर्कारू',
    'कर्चूरक',
    'कर्ज',
    'कर्जदाता',
    'कर्जदार',
    'कर्जा',
    'कर्टसी',
    'कर्टेन',
    'कर्ण',
    'कर्णक',
    'कर्णकटु',
    'कर्णकीट',
    'कर्णकुण्डल',
    'कर्णगूथ',
    'कर्णगोचर',
    'कर्णगोचरता',
    'कर्णदर्शी',
    'कर्णधार',
    'कर्णन',
    'कर्णनली',
    'कर्णपटी',
    'कर्णपत्र',
    'कर्णपलि',
    'कर्णपाली',
    'कर्णपुटी',
    'कर्णपुष्प',
    'कर्णपूर',
    'कर्णपूरक',
    'कर्णप्रसू',
    'कर्णप्रिय',
    'कर्णफूल',
    'कर्णफोन',
    'कर्णभार',
    'कर्णभेदता',
    'कर्णभेदी',
    'कर्णमधुर',
    'कर्णमल',
    'कर्णमूल',
    'कर्णमोटी',
    'कर्णविज्ञान',
    'कर्णविद्घ',
    'कर्णवेध',
    'कर्णशोथ',
    'कर्णसंबंधी',
    'कर्णहीन',
    'कर्णांक',
    'कर्णाकणिं',
    'कर्णाकार',
    'कर्णाट',
    'कर्णाटक',
    'कर्णातीत',
    'कर्णाधार',
    'कर्णानुज',
    'कर्णारि',
    'कर्णार्ध',
    'कर्णार्पंण',
    'कर्णावधान',
    'कर्णिक',
    'कर्णिका',
    'कर्णी',
    'कर्णीसुत',
    'कर्णेजप',
    'कर्त',
    'कर्तक',
    'कर्तन',
    'कर्तनक',
    'कर्तनकार',
    'कर्तनकारी',
    'कर्तनक्षम',
    'कर्तनी',
    'कर्तव्य',
    'कर्तव्यता',
    'कर्तव्यत्यागी',
    'कर्तव्यनिष्ठ',
    'कर्तव्यपूर्ण',
    'कर्तव्यशील',
    'कर्ता',
    'कर्ताः',
    'कर्ताकारक',
    'कर्ताधर्ता',
    'कर्तार',
    'कर्तार्',
    'कर्ताहीन',
    'कर्तित',
    'कर्तृक',
    'कर्तृतव',
    'कर्तृतववादी',
    'कर्तृत्ववाद',
    'कर्तृवाच्य',
    'कर्त्तव्य',
    'कर्त्तव्यच्युति',
    'कर्त्तव्यनिष्ठ',
    'कर्त्तव्यनिष्ठा',
    'कर्त्ता',
    'कर्दम',
    'कर्दमित',
    'कर्दा',
    'कर्धा',
    'कर्नल',
    'कर्नाटक',
    'कर्नाटकी',
    'कर्नैल',
    'कर्पट',
    'कर्पण',
    'कर्पर',
    'कर्पूर',
    'कर्ब',
    'कर्बर',
    'कर्बरी',
    'कर्बुर',
    'कर्बुरी',
    'कर्म',
    'कर्मः',
    'कर्मःविस्तार',
    'कर्मक',
    'कर्मकर',
    'कर्मकरता',
    'कर्मकांड',
    'कर्मकांडी',
    'कर्मकाण्ड',
    'कर्मकाण्डवादी',
    'कर्मकार',
    'कर्मक्षम',
    'कर्मक्षय',
    'कर्मक्षेत्र',
    'कर्मचर',
    'कर्मचर्या',
    'कर्मचाच्य',
    'कर्मचारिता',
    'कर्मचारियों',
    'कर्मचारी',
    'कर्मचारीवर्ग',
    'कर्मज',
    'कर्मठ',
    'कर्मठता',
    'कर्मठतापूर्वक',
    'कर्मणा',
    'कर्मणि',
    'कर्मण्य',
    'कर्मण्यता',
    'कर्मण्या',
    'कर्मता',
    'कर्मत्याग',
    'कर्मत्यागी',
    'कर्मदान',
    'कर्मधात',
    'कर्मनिष्ठ',
    'कर्मन्यास',
    'कर्मपात्रता',
    'कर्मपूर्वक',
    'कर्मप्रधान',
    'कर्मफल',
    'कर्मफलः',
    'कर्मफलवाद',
    'कर्मयुग',
    'कर्मयोग',
    'कर्मयोगी',
    'कर्मरेख',
    'कर्मर्',
    'कर्मलेख',
    'कर्मवाच्य',
    'कर्मवाद',
    'कर्मविपाक',
    'कर्मवीर',
    'कर्मवीरता',
    'कर्मशाला',
    'कर्मशील',
    'कर्मशूर',
    'कर्मसाक्ष्ज्ञी',
    'कर्मसाक्ष्य',
    'कर्मसूचक',
    'कर्महीन',
    'कर्महीनता',
    'कर्मात',
    'कर्माधीनतः',
    'कर्मानुराग',
    'कर्मानुरागी',
    'कर्मार',
    'कर्मासक्ति',
    'कर्मिक',
    'कर्मियों',
    'कर्मी',
    'कर्मीदल',
    'कर्मीय',
    'कर्मीर',
    'कर्मॅफल',
    'कर्मेद्रिय',
    'कर्य',
    'कर्रा',
    'कर्री',
    'कर्वट',
    'कर्वर',
    'कर्वरी',
    'कर्ष',
    'कर्षक',
    'कर्षण',
    'कर्षणकरना',
    'कर्षणी',
    'कर्षिणी',
    'कर्षित',
    'कर्षी',
    'कर्षू',
    'कर्सर',
    'कल',
    'कलं',
    'कलंक',
    'कलंकषी',
    'कलंकित',
    'कलंकी',
    'कलंडर',
    'कलंदर',
    'कलईः',
    'कलकंठ',
    'कलकत्ता',
    'कलकल',
    'कलकानि',
    'कलकूजिका',
    'कलकूणिका',
    'कलक्टर',
    'कलक्टरी',
    'कलक्टिंग',
    'कलक्शन',
    'कलगी',
    'कलगीदार',
    'कलघ्वनि',
    'कलछा',
    'कलछी',
    'कलछुल',
    'कलछुला',
    'कलजिम्भा',
    'कलजुग',
    'कलजुगी',
    'कलझँवाँ',
    'कलट्टर',
    'कलत्र',
    'कलथरा',
    'कलदार',
    'कलधरा',
    'कलनाद',
    'कलनादी',
    'कलन्न',
    'कलपना',
    'कलपा',
    'कलपाना',
    'कलपुर्जे',
    'कलपेंच',
    'कलफ़',
    'कलबल',
    'कलबूत',
    'कलभ',
    'कलभाषिणी',
    'कलम',
    'कलमदान',
    'कलमलाना',
    'कलमा',
    'कलमागो',
    'कलमी',
    'कलमुँहा',
    'कलमुँही',
    'कलयाना',
    'कलयुग',
    'कलयुगी',
    'कलर',
    'कलरव',
    'कलरवी',
    'कलल',
    'कलवारन',
    'कलवारी',
    'कलविंक',
    'कलश',
    'कलशधारिणी',
    'कलशी',
    'कलस',
    'कलसा',
    'कलसी',
    'कलह',
    'कलहंस',
    'कलहकार',
    'कलहकारिता',
    'कलहकारी',
    'कलहप्रिय',
    'कलहप्रिया',
    'कलहाथ',
    'कलही',
    'कलहीन',
    'कलहीनता',
    'कला',
    'कलाँ',
    'कलाँस',
    'कलाई',
    'कलाएं',
    'कलाकंडर',
    'कलाकंद',
    'कलाकर',
    'कलाकर्मी',
    'कलाकार',
    'कलाकारिता',
    'कलाकारी',
    'कलाकृति',
    'कलागुरू',
    'कलाची',
    'कलाजन्य',
    'कलात्मक',
    'कलात्मकता',
    'कलादा',
    'कलाधर',
    'कलानाथ',
    'कलानिधि',
    'कलानुभूति',
    'कलाप',
    'कलापक',
    'कलापिनी',
    'कलापी',
    'कलापूर्ण',
    'कलापूर्णता',
    'कलापों',
    'कलाप्रेमित्व',
    'कलाप्रेमी',
    'कलाबतून',
    'कलाबत्तू',
    'कलाबाज़',
    'कलाबाजी',
    'कलाबाती',
    'कलाबार',
    'कलाबाज़',
    'कलाम',
    'कलामय',
    'कलामी',
    'कलामे',
    'कलाय',
    'कलार',
    'कलाल',
    'कलाली',
    'कलावंत',
    'कलावा',
    'कलावादी',
    'कलाविद',
    'कलासृजन',
    'कलाहीन',
    'कलाहीनता',
    'कलि',
    'कलिंग',
    'कलिंगक',
    'कलिंगड़ा',
    'कलिंज',
    'कलिका',
    'कलिजुग',
    'कलित',
    'कलियाना',
    'कलियुग',
    'कलियुगी',
    'कलियुगीन',
    'कलियुगीय',
    'कलियों',
    'कलिल',
    'कलिहारी',
    'कली',
    'कलींदा',
    'कलीतनय',
    'कलीदार',
    'कलीसा',
    'कलीसाई',
    'कलीसिया',
    'कलुक्का',
    'कलुष',
    'कलुषता',
    'कलुषा',
    'कलुषित',
    'कलूटा',
    'कले',
    'कलेऊ',
    'कलेजा',
    'कलेजी',
    'कलेजे',
    'कलेवर',
    'कलेवा',
    'कलैंगी',
    'कलोनाइज़र',
    'कलोर',
    'कलोरी',
    'कलोल',
    'कलौजी',
    'कल्क',
    'कल्कि',
    'कल्किः',
    'कल्किद',
    'कल्किदता',
    'कल्की',
    'कल्टीवेटर',
    'कल्प',
    'कल्पः',
    'कल्पक',
    'कल्पतरू',
    'कल्पद्रुम',
    'कल्पन',
    'कल्पना',
    'कल्पनाकार',
    'कल्पनागत',
    'कल्पनाजन्य',
    'कल्पनातीत',
    'कल्पनातीतता',
    'कल्पनात्मक',
    'कल्पनापूर्ण',
    'कल्पनायुक्त',
    'कल्पनाशील',
    'कल्पनाशीलता',
    'कल्पनाहीन',
    'कल्पनाहीनता',
    'कल्पनीय',
    'कल्पनीयता',
    'कल्पवृक्ष',
    'कल्पसूत्र',
    'कल्पादि',
    'कल्पित',
    'कल्पिन',
    'कल्प्य',
    'कल्प्यता',
    'कल्मष',
    'कल्मषा',
    'कल्माष',
    'कल्माषकंठ',
    'कल्माषी',
    'कल्य',
    'कल्याण',
    'कल्याणकर',
    'कल्याणकाम',
    'कल्याणकामी',
    'कल्याणकारिता',
    'कल्याणकारी',
    'कल्याणपूर्ण',
    'कल्याणप्रद',
    'कल्याणमय',
    'कल्याणमस्तु',
    'कल्याणाकल्याण',
    'कल्याणात्मक',
    'कल्याणी',
    'कल्याणेतर',
    'कल्याणेश्वर',
    'कल्याश',
    'कल्लदराज़',
    'कल्लदराज़ी',
    'कल्लर',
    'कल्ला',
    'कल्लाँच',
    'कल्लातोड़',
    'कल्ले',
    'कल्लोल',
    'कल्लोलना',
    'कल्लोलपूर्ण',
    'कल्लोलप्रिय',
    'कल्लोलप्रियता',
    'कल्लोलिनी',
    'कल्वर्ट',
    'कव',
    'कवक',
    'कवकगंधिलता',
    'कवकरूपी',
    'कवकविज्ञान',
    'कवकविज्ञानी',
    'कवकी',
    'कवच',
    'कवचधारी',
    'कवचावली',
    'कवचित',
    'कवधी',
    'कवन',
    'कवयिता',
    'कवयित्री',
    'कवर',
    'कवरत्ति',
    'कवरिंग',
    'कवरी',
    'कवल',
    'कवलन',
    'कवलिका',
    'कवलित',
    'कवायद',
    'कवि',
    'कविकुल',
    'कविज्येष्ठ',
    'कवित',
    'कविता',
    'कवितावली',
    'कवित्व',
    'कवित्वपूर्ण',
    'कवित्वहीन',
    'कवियों',
    'कविराज',
    'कविराय',
    'कवींदु',
    'कवीन्द्र',
    'कव्य',
    'कश',
    'कशभूषा',
    'कशमकश',
    'कशमकशे',
    'कशमीर',
    'कशा',
    'कशाघात',
    'कशिपु',
    'कशिपुः',
    'कशिश',
    'कशी',
    'कशीद',
    'कशीदगी',
    'कशीदा',
    'कशीदाकार',
    'कशीदाकारी',
    'कशेरुक',
    'कशेरूक',
    'कशेरूका',
    'कश्ती',
    'कश्मल',
    'कश्मीर',
    'कश्मीरज',
    'कश्मीरी',
    'कश्य',
    'कश्यप',
    'कश्यपः',
    'कषण',
    'कषाय',
    'कषायता',
    'कषायस्वाद',
    'कषायी',
    'कष्ट',
    'कष्टः',
    'कष्टकर',
    'कष्टकारी',
    'कष्टकृष्य',
    'कष्टग्रस्त',
    'कष्टदायक',
    'कष्टदायकता',
    'कष्टप्रद',
    'कष्टमोचन',
    'कष्टश्वास',
    'कष्टसाध्य',
    'कष्टसाध्यता',
    'कस',
    'कसक',
    'कसकता',
    'कसकना',
    'कसकर',
    'कसन',
    'कसना',
    'कसब',
    'कसबा',
    'कसम',
    'कसमसाता',
    'कसमसाना',
    'कसमसाहट',
    'कसमिया',
    'कसर',
    'कसरत',
    'कसरती',
    'कसरहट्टा',
    'कसा',
    'कसाई',
    'कसाईखाना',
    'कसापन',
    'कसाय',
    'कसाया',
    'कसार',
    'कसालत',
    'कसाला',
    'कसाले',
    'कसालेसे',
    'कसियाना',
    'कसियाय',
    'कसियाया',
    'कसियाहट',
    'कसीदा',
    'कसीदाकारी',
    'कसीस',
    'कसे',
    'कसेरा',
    'कसेला',
    'कसैला',
    'कसैलापन',
    'कसैलाहोना',
    'कसोरा',
    'कसौटी',
    'कसौटीः',
    'कसौटीकार',
    'कस्टडी',
    'कस्टम',
    'कस्टमर',
    'कस्टम्स',
    'कस्तरी',
    'कस्तीर',
    'कस्तूर',
    'कस्तूरक',
    'कस्तूरिया',
    'कस्तूरी',
    'कस्बा',
    'कस्सी',
    'कह',
    'कहकर',
    'कहकशाँ',
    'कहगिल',
    'कहतसुनत',
    'कहते',
    'कहना',
    'कहनासुनना',
    'कहनी',
    'कहने',
    'कहरया',
    'कहल',
    'कहलाती',
    'कहवा',
    'कहा',
    'कहाँ',
    'कहां',
    'कहाकही',
    'कहानियों',
    'कहानी',
    'कहानीकार',
    'कहार',
    'कहारा',
    'कहावत',
    'कहासुनी',
    'कहिये',
    'कही',
    'कहीं',
    'कहुँ',
    'कहूं',
    'कहूंगा',
    'कहे',
    'कहें',
    'कहेलिका',
    'कहैं',
    'कहो',
    'क़ज़र्',
    'क़ज़र्दार',
    'क़ज़ा',
    'क़ज़ार्',
    'क़ज्ज़ाक़ी',
    'क़त',
    'क़तई',
    'क़तरा',
    'क़तल',
    'क़ता',
    'क़ताकलामी',
    'क़तार',
    'क़त्लगाह',
    'क़द',
    'क़दंब',
    'क़दम',
    'क़दमचा',
    'क़दमबाज़ी',
    'क़दमबोसी',
    'क़दर',
    'क़दामत',
    'क़दावर',
    'क़दीमी',
    'क़दीमीपन',
    'क़द्र',
    'क़द्रदान',
    'क़द्रदानी',
    'क़नात',
    'क़बा',
    'क़बाला',
    'क़बीला',
    'क़बूल',
    'क़बूलियत',
    'क़बूली',
    'क़ब्ज',
    'क़ब्ज़ा',
    'क़ब्ज़ादार',
    'क़ब्ज़ादारी',
    'क़ब्ज़े',
    'क़ब्जा',
    'क़ब्र',
    'क़मती',
    'क़यदगी',
    'क़याम',
    'क़यामत',
    'क़यामतः',
    'क़यामती',
    'क़यास',
    'क़राबा',
    'क़रार',
    'क़रीन',
    'क़रीना',
    'क़रीनावार',
    'क़रीने',
    'क़रीब',
    'क़रीबन',
    'क़रीबी',
    'क़लंक',
    'क़लंदर',
    'क़लंदरी',
    'क़लई',
    'क़लम',
    'क़लमः',
    'क़लमकार',
    'क़लमकारी',
    'क़लमजीवी',
    'क़लमदान',
    'क़लमबंद',
    'क़लमबंदी',
    'क़लमी',
    'क़लिया',
    'क़वायद',
    'क़व्वाली',
    'क़श्क़ा',
    'क़सबाई',
    'क़सबाती',
    'क़सबिन',
    'क़सबीख़ाना',
    'क़सम',
    'क़समन',
    'क़सर',
    'क़साई',
    'क़सीदा',
    'क़सीदाख़्याँ',
    'क़सीदागोई',
    'क़सूर',
    'क़सूरवार',
    'क़स्त्र',
    'क़स्साब',
    'क़ह',
    'क़हक़हा',
    'क़हत',
    'क़हर',
    'क़हरी',
    'क़हे',
    'क़ातिल',
    'क़ातिलाना',
    'क़ानून',
    'क़ानूनदाँ',
    'क़ानूनदानी',
    'क़ानूनन',
    'क़ानूनिया',
    'क़ानूनी',
    'क़ाफ़िया',
    'क़ाफ़ियाबंद',
    'क़ाफ़ियाबाज़ी',
    'क़ाबा',
    'क़ाबिज़',
    'क़ाबिल',
    'क़ाबिले',
    'क़ाबू',
    'क़ाम',
    'क़ामत',
    'क़ायदा',
    'क़ायदाक़ानून',
    'क़ायदे',
    'क़ायम',
    'क़ायमत',
    'क़ायममिज़ाजी',
    'क़ायल',
    'क़ारूरा',
    'क़ासिद',
    'क़िंदील',
    'क़ितआ',
    'क़िता',
    'क़िताबत',
    'क़िबला',
    'क़िबलानुमा',
    'क़िमारबाज़',
    'क़िला',
    'क़िलादार',
    'क़िलाबंदी',
    'क़िलेदार',
    'क़िलेबंदी',
    'क़िल्लत',
    'क़िवाम',
    'क़िश्तवार',
    'क़िस्त',
    'क़िस्तबंदी',
    'क़िस्तवार',
    'क़िस्तवाराना',
    'क़िस्म',
    'क़िस्मत',
    'क़िस्मतवर',
    'क़िस्रा',
    'क़िस्सा',
    'क़िस्साख़्यानी',
    'क़िस्सागो',
    'क़िस्सागोई',
    'क़ीज',
    'क़ीज़',
    'क़ीमत',
    'क़ीमती',
    'क़ीमतीपन',
    'क़ीमतें',
    'क़ीमाः',
    'क़ुत्य',
    'क़ुदरत',
    'क़ुदरती',
    'क़ुरबान',
    'क़ुरबानगाह',
    'क़ुरबानी',
    'क़ुराज',
    'क़ुरान',
    'क़ुरानः',
    'क़ुरानी',
    'क़ुलमा',
    'क़ुली',
    'क़ुलीगीरी',
    'क़ुसुर',
    'क़ूवत',
    'क़ैंपस',
    'क़ैद',
    'क़ैदख़ाना',
    'क़ैदघर',
    'क़ैदी',
    'क़ोमियत',
    'क़ोमी',
    'क़ौम',
    'क़ौमी',
    'क़ौल',
    'का',
    'काँकर',
    'काँकरिया',
    'काँकरी',
    'काँख',
    'काँखसोती',
    'काँच',
    'काँचः',
    'काँचिया',
    'काँची',
    'काँचीः',
    'काँचीय',
    'काँजी',
    'काँटा',
    'काँटाः',
    'काँटी',
    'काँटे',
    'काँटेदार',
    'काँटेदारः',
    'काँटों',
    'काँडी',
    'काँदा',
    'काँदो',
    'काँधा',
    'काँधासोती',
    'काँप',
    'काँपता',
    'काँपते',
    'काँपना',
    'काँरू',
    'काँवर',
    'काँवरथी',
    'काँवरिया',
    'काँवरी',
    'काँवाँरथी',
    'काँस',
    'काँसा',
    'काँसाकर',
    'काँसाकार',
    'काँसागर',
    'काँसागरी',
    'काँसार',
    'काँसिल',
    'कांकिणी',
    'कांक्षणीय',
    'कांक्षा',
    'कांक्षिका',
    'कांक्षिणी',
    'कांक्षित',
    'कांक्षी',
    'कांक्षेरू',
    'कांक्ष्य',
    'कांख',
    'कांगारी',
    'कांग्रेस',
    'कांच',
    'कांचन',
    'कांचनक',
    'कांचनी',
    'कांचि',
    'कांचिक',
    'कांची',
    'कांजिका',
    'कांट',
    'कांटा',
    'कांटे',
    'कांटेदार',
    'कांटेवाला',
    'कांटों',
    'कांट्रैक्ट',
    'कांट्रैक्टर',
    'कांड',
    'कांडला',
    'कांडारला',
    'कांडी',
    'कांडील',
    'कांत',
    'कांतसार',
    'कांता',
    'कांतार',
    'कांति',
    'कांतिद',
    'कांतिप्रद',
    'कांतिमय',
    'कांतिमान',
    'कांतिसार',
    'कांतिहीन',
    'कांतिहीनता',
    'कांती',
    'कांदय',
    'कांदविक',
    'कांप',
    'कांपते',
    'कांपना',
    'कांसे',
    'कांस्टेबल',
    'कांस्य',
    'कांस्यक',
    'कांस्यकार',
    'कांस्यपत्र',
    'काइन',
    'काइयाँपन',
    'काई',
    'काउंट',
    'काउंटर',
    'काउंटरफ़ीटिंग',
    'काउंटरफौइल',
    'काउंटरमैंडिड',
    'काउंसिल',
    'काउन्टर',
    'काओलिन',
    'काक',
    'काकंची',
    'काकंटीन',
    'काकंप',
    'काकंसर',
    'काकटेल',
    'काकदंत',
    'काकपक्ष',
    'काकपाली',
    'काकपेय',
    'काकभीरू',
    'काकमाची',
    'काकररोच',
    'काकरव',
    'काकरूहा',
    'काकरोच',
    'काकल',
    'काकलदर्शी',
    'काकलशोथ',
    'काकली',
    'काकलीय',
    'काकल्य',
    'काका',
    'काकिणी',
    'काकी',
    'काकीय',
    'काकु',
    'काकुद',
    'काकुल',
    'काकूक्ति',
    'काकेशस',
    'काकेशियाई',
    'काकोदंबरिका',
    'काकोदर',
    'काग',
    'कागज',
    'कागजपत्र',
    'कागज़',
    'कागजात',
    'कागजातों',
    'कागजी',
    'कागजों',
    'कागद',
    'काग़ज़',
    'काग़ज़ः',
    'काग़ज़ी',
    'कागा',
    'कागारोल',
    'कागोरं',
    'कागज़',
    'कागज़ात',
    'कागज़ी',
    'कागज़ों',
    'काच',
    'काचक',
    'काचन',
    'काचाभ',
    'काचित',
    'काचीकरण',
    'काचो',
    'काछ',
    'काछना',
    'काछनी',
    'काज',
    'काजल',
    'काजलः',
    'काजी',
    'काजू',
    'काट',
    'काटकपट',
    'काटकर',
    'काटकी',
    'काटछाँट',
    'काटता',
    'काटती',
    'काटते',
    'काटदार',
    'काटन',
    'काटना',
    'काटने',
    'काटनेवाला',
    'काटफाँट',
    'काटफाँस',
    'काटा',
    'काटिकृत',
    'काटी',
    'काटू',
    'काटो',
    'काठ',
    'काठः',
    'काठड़ा',
    'काठड़ी',
    'काठमांडू',
    'काठी',
    'काड',
    'काड़ा',
    'काड़ी',
    'काढ़ना',
    'काढ़ा',
    'कात',
    'कातती',
    'कातना',
    'कातर',
    'कातरता',
    'कातररता',
    'कातर्य',
    'कातल',
    'काता',
    'कातिक',
    'कातिब',
    'कात्तिर्क',
    'कात्तिर्केय',
    'कात्यायन',
    'कात्यायनी',
    'काथलिक',
    'काथिक',
    'कादंब',
    'कादंबक',
    'कादंबर',
    'कादंबरी',
    'कादंबिनी',
    'काद्रवेय',
    'काद्रवेयः',
    'काद्रवेयी',
    'कान',
    'कानः',
    'कानकार',
    'कानखजूरा',
    'कानन',
    'कानपुर',
    'कानफ़रेंस',
    'कानफ्रैरेंस',
    'काना',
    'कानाकानी',
    'कानागोसी',
    'कानाफूसी',
    'कानाबाती',
    'कानि',
    'कानी',
    'कानीन',
    'कानून',
    'कानूनन',
    'कानूनियत',
    'कानूनी',
    'कानों',
    'कानोकान',
    'कान्ति',
    'कान्तिवर्धक',
    'कान्तिवृत्त',
    'कान्फ्रेंस',
    'कान्ह',
    'कान्हड़ा',
    'कान्हा',
    'कापदी',
    'कापालिक',
    'कापालिकी',
    'कापालिनी',
    'कापाली',
    'कापिशेय',
    'कापी',
    'कापीइंग',
    'कापीराइट',
    'कापुरुष',
    'कापुरुषता',
    'कापुरुषतापूर्ण',
    'कापोत',
    'कापोतक',
    'कापोर्रेशन',
    'काफ़िर',
    'काफ़िला',
    'काफ़ी',
    'काफ़ूर',
    'काफ़े',
    'काफिया',
    'काफिर',
    'काफी',
    'काफीः',
    'काफूर',
    'काबला',
    'काबा',
    'काबाःकूप',
    'काबीना',
    'काबुक',
    'काबुकी',
    'काबुली',
    'काबोर्हाइड्रेट',
    'काम',
    'कामकाज',
    'कामकाली',
    'कामकूट',
    'कामगति',
    'कामगर',
    'कामगार',
    'कामगारों',
    'कामचर',
    'कामचलाऊ',
    'कामचारी',
    'कामचोर',
    'कामचोरी',
    'कामज',
    'कामजित',
    'कामजितता',
    'कामथ',
    'कामद',
    'कामदाता',
    'कामदानी',
    'कामदार',
    'कामदुध',
    'कामदेव',
    'कामदेवः',
    'कामदेवी',
    'कामधंधा',
    'कामधान',
    'कामधेनु',
    'कामध्वज',
    'कामना',
    'कामनाओं',
    'कामनापूर्ण',
    'कामनाहीन',
    'कामनाहीनतः',
    'कामपरायणता',
    'कामपाल',
    'कामप्रद',
    'कामबंदी',
    'कामबाण',
    'काममह',
    'कामयाब',
    'कामयाबी',
    'कामरा',
    'कामरानी',
    'कामरि',
    'कामरिपु',
    'कामरुपता',
    'कामरुपी',
    'कामरूप',
    'कामरूपा',
    'कामरूपाणु',
    'कामरूपिणी',
    'कामरूपी',
    'कामरेड',
    'कामल',
    'कामलिप्सा',
    'कामली',
    'कामलेखा',
    'कामवशायिता',
    'कामवासना',
    'कामवृत्त',
    'कामशक्तिहीन',
    'कामशर',
    'कामशास्त्र',
    'कामसुत',
    'कामहा',
    'कामहीन',
    'कामहीनतः',
    'कामहीनता',
    'कामहुह',
    'कामा',
    'कामांकुरण',
    'कामांग',
    'कामांध',
    'कामाकांक्षा',
    'कामाक्षी',
    'कामाख्या',
    'कामाचारी',
    'कामातुर',
    'कामायुध',
    'कामार्त',
    'कामार्ति',
    'कामासक्त',
    'कामिका',
    'कामित',
    'कामिनी',
    'कामिर्क',
    'कामिल',
    'कामी',
    'कामुक',
    'कामुकता',
    'कामुकतापूर्ण',
    'कामुकतापूर्वक',
    'कामुकतावश',
    'कामुकी',
    'कामेच्छा',
    'कामेच्छाहीन',
    'कामेडी',
    'कामेशी',
    'कामेश्वर',
    'कामेश्वरी',
    'कामेष्टि',
    'कामों',
    'कामोत्तेजक',
    'कामोत्तेजकता',
    'कामोत्तेजकतापूर्वक',
    'कामोत्तेजना',
    'कामोत्सव',
    'कामोद',
    'कामोद्दीपक',
    'कामोद्रेक',
    'कामोन्माद',
    'कामोन्मादिनी',
    'काम्प्लैक्स',
    'काम्य',
    'काम्यता',
    'काम्या',
    'काम्येष्टि',
    'काय',
    'कायक्रम',
    'कायचिकित्सक',
    'कायचिकित्सा',
    'कायता',
    'कायदे',
    'कायनात',
    'कायम',
    'कायर',
    'कायरता',
    'कायरतापूर्ण',
    'कायराँग',
    'कायल',
    'कायस्थ',
    'कायस्था',
    'काया',
    'कायांतर',
    'कायांतरित',
    'कायाकल्प',
    'कायान्तरण',
    'कायापलट',
    'कायिक',
    'कायिका',
    'कायी',
    'काय्र',
    'कार',
    'कारंज',
    'कारंडव',
    'कारंधमी',
    'कारः',
    'कारक',
    'कारकयुक्त',
    'कारकहीन',
    'कारकुन',
    'कारकुनी',
    'कारख़ाना',
    'कारख़ानेदार',
    'कारखाना',
    'कारखाने',
    'कारगर',
    'कारगाह',
    'कारगुज़ार',
    'कारगुज़ारी',
    'कारघर',
    'कारचोब',
    'कारचोबी',
    'कारज',
    'कारण',
    'कारणः',
    'कारणकार्य',
    'कारणतः',
    'कारणता',
    'कारणभूत',
    'कारणभूतता',
    'कारणयुक्त',
    'कारणवश',
    'कारणवादी',
    'कारणहीनतः',
    'कारणहीनता',
    'कारणा',
    'कारणिक',
    'कारणों',
    'कारतूस',
    'कारदौड़',
    'कारनामा',
    'कारनामें',
    'कारनिस',
    'कारपरदाज़',
    'कारपैंटर',
    'कारपोरेशन',
    'कारबंद',
    'कारबन',
    'कारबार',
    'कारबारी',
    'काररवाई',
    'कारव',
    'कारवान',
    'कारवी',
    'कारवेल्स',
    'कारसाज़',
    'कारसाज़ी',
    'कारह',
    'कारा',
    'काराकोरम',
    'कारागार',
    'कारागुप्त',
    'कारागृह',
    'कारापाल',
    'काराबद्घ',
    'काराभ्यतर',
    'कारामद',
    'कारामुक्ति',
    'कारावास',
    'कारावासी',
    'कारिंदा',
    'कारिका',
    'कारिणी',
    'कारिन्दा',
    'कारी',
    'कारीगर',
    'कारीगरः',
    'कारीगरी',
    'कारीग़र',
    'कारुणिक',
    'कारूंडी',
    'कारूचौर',
    'कारें',
    'कारोबार',
    'कारोबारी',
    'कार्क',
    'कार्गो',
    'कार्ट',
    'कार्टन',
    'कार्टिलेज',
    'कार्टून',
    'कार्टूनकार',
    'कार्टूनिस्ट',
    'कार्टेज',
    'कार्ड',
    'कार्डबोर्ड',
    'कार्डराय',
    'कार्डिगन',
    'कार्डिनल',
    'कार्डुराय',
    'कार्णिक',
    'कार्तवीर्य',
    'कार्तिक',
    'कार्तिकेय',
    'कार्दम',
    'कार्नेलियन',
    'कार्पटिक',
    'कार्पेट',
    'कार्पोरेशन',
    'कार्बकल',
    'कार्बन',
    'कार्बनिक',
    'कार्बनी',
    'कार्बनीकरण',
    'कार्बनेटिड',
    'कार्मिक',
    'कार्मुक',
    'कार्य',
    'कार्यकक्ष',
    'कार्यकर्ता',
    'कार्यकर्ताओं',
    'कार्यकलाप',
    'कार्यकलापों',
    'कार्यकारिणी',
    'कार्यकारिता',
    'कार्यकारी',
    'कार्यकाल',
    'कार्यकुशल',
    'कार्यकुशलता',
    'कार्यकौशल',
    'कार्यक्रम',
    'कार्यक्रमबद्ध',
    'कार्यक्रमहीन',
    'कार्यक्रमहीनता',
    'कार्यक्रमानुसार',
    'कार्यक्रमावली',
    'कार्यक्रमेण',
    'कार्यक्रमों',
    'कार्यक्षम',
    'कार्यक्षमता',
    'कार्यक्षेत्र',
    'कार्यगत',
    'कार्यगोष्ठी',
    'कार्यग्रहण',
    'कार्यचालन',
    'कार्यज्ञान',
    'कार्यणी',
    'कार्यता',
    'कार्यदल',
    'कार्यदूत',
    'कार्यनिष्ठ',
    'कार्यनिष्पादन',
    'कार्यनीति',
    'कार्यपद्धति',
    'कार्यपरक',
    'कार्यपालक',
    'कार्यपालकों',
    'कार्यपालिका',
    'कार्यप्रणाली',
    'कार्यबंदी',
    'कार्यबद्ध',
    'कार्यभार',
    'कार्यभारग्रहण',
    'कार्यभारी',
    'कार्यमग्न',
    'कार्यमग्नता',
    'कार्यमुक्त',
    'कार्यमुक्ति',
    'कार्ययोजना',
    'कार्यरत',
    'कार्यरतता',
    'कार्यरति',
    'कार्यर्क्रम',
    'कार्यलीन',
    'कार्यलीनता',
    'कार्यवर्तन',
    'कार्यवाहियों',
    'कार्यवाही',
    'कार्यविक्षक',
    'कार्यविधि',
    'कार्यविवरण',
    'कार्यवृत',
    'कार्यवृत्त',
    'कार्यशक्ति',
    'कार्यशाला',
    'कार्यशालाएं',
    'कार्यशालाओं',
    'कार्यशील',
    'कार्यसाधक',
    'कार्यसिद्धि',
    'कार्यसूची',
    'कार्यहीन',
    'कार्यहीनता',
    'कार्याक्षमता',
    'कार्यात',
    'कार्यात्मक',
    'कार्याधिकार',
    'कार्याधिकारी',
    'कार्याध्यक्ष',
    'कार्यानुभव',
    'कार्यानुसार',
    'कार्यान्दयन',
    'कार्यान्वन',
    'कार्यान्वयक',
    'कार्यान्वयन',
    'कार्यान्वयनीय',
    'कार्यान्वित',
    'कार्यापालक',
    'कार्याभ्यास',
    'कार्यारंभ',
    'कार्यारंभक',
    'कार्यारम्भ',
    'कार्यालय',
    'कार्यालयी',
    'कार्यालयीन',
    'कार्यालयों',
    'कार्यावरोध',
    'कार्यावली',
    'कार्यािन्वति',
    'कार्यिन्वत',
    'कार्येक्षक',
    'कार्येक्षण',
    'कार्येत्तर',
    'कार्योत्तर',
    'कार्योत्पादक',
    'कार्रवाई',
    'कार्वर',
    'कार्ष्णि',
    'काल',
    'कालंकठिनी',
    'कालंजर',
    'कालः',
    'कालकंठ',
    'कालकाल',
    'कालकुंज',
    'कालकोठरी',
    'कालक्रम',
    'कालक्रमेण',
    'कालगणना',
    'कालगत',
    'कालगति',
    'कालगर्ल',
    'कालचक्र',
    'कालजयी',
    'कालजात',
    'कालजातता',
    'कालजीत',
    'कालज्ञ',
    'कालज्येष्ठ',
    'कालत्र',
    'कालदोष',
    'कालदोषी',
    'कालनाथ',
    'कालनिशा',
    'कालनेमि',
    'कालनेमिः',
    'कालनेमिहा',
    'कालपाश',
    'कालपूर्व',
    'कालपूर्वता',
    'कालबैल',
    'कालम',
    'कालमकार',
    'कालमान',
    'कालमूलक',
    'कालमों',
    'कालयोगी',
    'कालर',
    'कालरा',
    'कालरात्रि',
    'काललौह',
    'कालवाची',
    'कालवृष्टि',
    'कालशाक',
    'कालसर्प',
    'कालसूर्य',
    'कालहीन',
    'कालहीनता',
    'काला',
    'कालांतराल',
    'कालाख्या',
    'कालात',
    'कालातिपात',
    'कालातीत',
    'कालातीतता',
    'कालाधन',
    'कालाध्यक्ष',
    'कालानल',
    'कालानुक्रमिक',
    'कालानुसार',
    'कालाप',
    'कालापन',
    'कालायस',
    'कालावधि',
    'कालाशैच',
    'कालिंग',
    'कालिंदक',
    'कालिंदी',
    'कालिंदीसू',
    'कालिक',
    'कालिका',
    'कालिख',
    'कालिज',
    'कालिन',
    'कालिमा',
    'कालिय',
    'कालिया',
    'काली',
    'कालीः',
    'कालीक',
    'कालीन',
    'कालीनता',
    'कालीय',
    'कालुष्य',
    'काले',
    'कालेज',
    'कालेश',
    'कालोचित',
    'कालोनी',
    'कालौंछ',
    'काल्पनिक',
    'काल्पनिकता',
    'काल्पनिकतापूर्ण',
    'काल्पित',
    'काल्ह',
    'कावाबाज़ी',
    'कावेरी',
    'काव्य',
    'काव्यः',
    'काव्यकार',
    'काव्यकृति',
    'काव्यमय',
    'काव्या',
    'काव्यात्मक',
    'काव्यात्मकता',
    'काव्यार्थ',
    'काव्यालंकार',
    'काव्योचित',
    'काश',
    'काशि',
    'काशी',
    'काशीनाथ',
    'काशीनाथन',
    'काशीनो',
    'काशीफल',
    'काश्त',
    'काश्तकार',
    'काश्तकारी',
    'काश्मीर',
    'काश्यप',
    'काश्यपि',
    'काश्यपेय',
    'काष',
    'काषाय',
    'काष्ठ',
    'काष्ठकर्म',
    'काष्ठकार',
    'काष्ठचत्वर',
    'काष्ठद्रु',
    'काष्ठफल',
    'काष्ठफलों',
    'काष्ठा',
    'काष्ठीय',
    'काष्णर्',
    'कास',
    'कासा',
    'कासार',
    'कासीस',
    'कासृति',
    'कास्क',
    'कास्ट',
    'कास्टयूम',
    'कास्टर',
    'कास्टिंग',
    'कास्टिक',
    'कास्टिग',
    'कास्थि',
    'कास्मैटिक्स',
    'कास्य',
    'काह',
    'काहल',
    'काहिल',
    'काहिली',
    'काहे',
    'काग़ज',
    'काग़ज़',
    'काग़ज़ात',
    'काग़ज़ी',
    'काग़ज़ों',
    'काज़',
    'काफ़ी',
    'कि',
    'किं',
    'किंकर',
    'किंकरता',
    'किंकरी',
    'किंकर्तव्य',
    'किंकिणी',
    'किंकिरात',
    'किंग',
    'किंचा',
    'किंचित',
    'किंचिद',
    'किंचिन्मात्र',
    'किंचिलुक',
    'किंजल्क',
    'किंडरगार्टन',
    'किंतु',
    'किंन्हें',
    'किंपुरुष',
    'किंवदन्ती',
    'किंशारु',
    'किए',
    'किक',
    'किकियाना',
    'किकियाहट',
    'किग्रा',
    'किचकिच',
    'किचकिचाना',
    'किचकिचिया',
    'किचन',
    'किचनेट',
    'किचपिच',
    'किचपिचिया',
    'किचर',
    'किट',
    'किटकिट',
    'किटकिटाती',
    'किटकिटाना',
    'किटकिटाहट',
    'किटकिटी',
    'किटकिरा',
    'किटबैग',
    'किटिका',
    'किट्ट',
    'किट्टक',
    'किट्टी',
    'किड',
    'किडनैपर',
    'किडनैपिंग',
    'किड़किड़',
    'किड़किड़िया',
    'किण्व',
    'किण्वक',
    'किण्वज',
    'किण्वन',
    'किण्वाणु',
    'किण्वीकरण',
    'कित',
    'कितक',
    'कितना',
    'कितनापन',
    'कितनी',
    'कितने',
    'कितनों',
    'कितव',
    'किताब',
    'किताबघर',
    'किताबत',
    'किताबी',
    'किताबों',
    'कितेक',
    'किधर',
    'किधौं',
    'किन',
    'किनका',
    'किनकी',
    'किनको',
    'किनवानी',
    'किनारा',
    'किनाराकशी',
    'किनारी',
    'किनारे',
    'किनारेदार',
    'किनारों',
    'किनेमैटोग्राफ़',
    'किन्तु',
    'किन्नर',
    'किन्नरः',
    'किन्नरी',
    'किन्हीं',
    'किन्हों',
    'किफ़ायत',
    'किफ़ायतशार',
    'किफ़ायती',
    'किफायत',
    'किफायती',
    'किमख़ाब',
    'किमर्थ',
    'किमि',
    'किमीर',
    'किमुत',
    'किमोनो',
    'कियत्ता',
    'किया',
    'कियामत',
    'किये',
    'किरक',
    'किरकिर',
    'किरकिरा',
    'किरकिराना',
    'किरकिराहट',
    'किरकिरी',
    'किरकिल',
    'किरच',
    'किरचा',
    'किरची',
    'किरण',
    'किरणन',
    'किरणपाणि',
    'किरणपुंज',
    'किरणावली',
    'किरणित',
    'किरणीय',
    'किरणें',
    'किरणों',
    'किरदार',
    'किरन',
    'किरना',
    'किरमिच',
    'किरवा',
    'किराँची',
    'किराए',
    'किराएदार',
    'किराएदारी',
    'किरात',
    'किराति',
    'किराती',
    'किरातीय',
    'किराना',
    'किरानी',
    'किराया',
    'किराये',
    'किरायेदार',
    'किरायेदारी',
    'किरासन',
    'किरासिन',
    'किरिच',
    'किरीट',
    'किरीटमाली',
    'किरीटी',
    'किरोशिया',
    'किरौना',
    'किर्मी',
    'किर्र',
    'किलक',
    'किलकना',
    'किलकारना',
    'किलकारी',
    'किलकिल',
    'किलकिला',
    'किलकिलाना',
    'किलनी',
    'किलबिलाता',
    'किलबिलाहट',
    'किलमना',
    'किलविषी',
    'किलसता',
    'किलसाऊ',
    'किला',
    'किलाई',
    'किलाबन्दी',
    'किले',
    'किलेबन्दी',
    'किलो',
    'किलोग्रराम',
    'किलोग्राम',
    'किलोमीटर',
    'किलोमीटरः',
    'किलोवोल्ट',
    'किल्ला',
    'किल्लाना',
    'किल्ली',
    'किल्विष',
    'किवाड़',
    'किशन',
    'किशना',
    'किशमिश',
    'किशलय',
    'किशोर',
    'किशोरता',
    'किशोरावस्था',
    'किशोरी',
    'किश्चत्',
    'किश्ट',
    'किश्त',
    'किश्तज़ार',
    'किश्ती',
    'किश्तें',
    'किश्तों',
    'किष्किंधा',
    'किष्किधा',
    'किष्कु',
    'किस',
    'किसन',
    'किसना',
    'किसबत',
    'किसमिस',
    'किसल',
    'किसलय',
    'किससे',
    'किसान',
    'किसानी',
    'किसानों',
    'किसी',
    'किसू',
    'किसे',
    'किस्त',
    'किस्तों',
    'किस्म',
    'किस्मत',
    'किस्मवार',
    'किस्में',
    'किस्मों',
    'किस्सा',
    'किहित',
    'किुतु',
    'किफ़ायत',
    'किफ़ायती',
    'किफ़ायतीपन',
    'की',
    'कींकीं',
    'कीओर',
    'कीक',
    'कीकड़',
    'कीकर',
    'कीकस',
    'कीकान',
    'कीच',
    'कीचक',
    'कीचट',
    'कीचड़',
    'कीचड़दान',
    'कीचड़दार',
    'कीचड़भरा',
    'कीचदार',
    'कीजड',
    'कीजिए',
    'कीट',
    'कीटध्न',
    'कीटनाशकों',
    'कीटनाशी',
    'कीटपरागित',
    'कीटभक्षी',
    'कीटविज्ञान',
    'कीटविज्ञानी',
    'कीटहारी',
    'कीटाणु',
    'कीटाणुनाशक',
    'कीटाणुनाशन',
    'कीटाणुहीन',
    'कीटादि',
    'कीटिका',
    'कीड़ा',
    'कीड़ासह',
    'कीड़ी',
    'कीड़े',
    'कीड़ों',
    'कीना',
    'कीनाकशी',
    'कीनिया',
    'कीप',
    'कीपर',
    'कीपिंग',
    'कीमख़्वाब',
    'कीमत',
    'कीमतवाला',
    'कीमती',
    'कीमतें',
    'कीमतों',
    'कीमा',
    'कीमिया',
    'कीमियागर',
    'कीमियागरी',
    'कीमियासाज़',
    'कीमुख्त',
    'कीमू',
    'कीर',
    'कीरम',
    'कीरोवाद',
    'कीर्तन',
    'कीर्तनकार',
    'कीर्तनिया',
    'कीर्ति',
    'कीर्तित',
    'कीर्तिमान',
    'कीर्तिमानों',
    'कीर्तिशाली',
    'कीर्तिस्तंभ',
    'कीर्तिस्व',
    'कीर्तिहीन',
    'कीर्तिहीनता',
    'कील',
    'कीलक',
    'कीलकाँटे',
    'कीलकीय',
    'कीलन',
    'कीलना',
    'कीला',
    'कीलाकार',
    'कीलाक्षर',
    'कीलाल',
    'कीलालज',
    'कीलालप',
    'कीलिका',
    'कीलित',
    'कीलिया',
    'कीली',
    'कीलीः',
    'कीलें',
    'कीवी',
    'कीश',
    'कुँअर',
    'कुँअरी',
    'कुँअरेटा',
    'कुँआ',
    'कुँआरापन',
    'कुँआरी',
    'कुँडा',
    'कुँडी',
    'कुँदेरना',
    'कुँदेरा',
    'कुँवर',
    'कुँवरी',
    'कुँवारा',
    'कुँवारी',
    'कुंंडलित',
    'कुंआ',
    'कुंआरा',
    'कुंआरापन',
    'कुंआरी',
    'कुंए',
    'कुंओं',
    'कुंकुम',
    'कुंचक',
    'कुंचन',
    'कुंचनकारी',
    'कुंचा',
    'कुंचिका',
    'कुंचित',
    'कुंचितता',
    'कुंज',
    'कुंजड़ा',
    'कुंजनी',
    'कुंजर',
    'कुंजरारि',
    'कुंजराशन',
    'कुंजरी',
    'कुंजित',
    'कुंजियों',
    'कुंजी',
    'कुंजीगत',
    'कुंजीदार',
    'कुंजीपटल',
    'कुंठदंती',
    'कुंठन',
    'कुंठा',
    'कुंठाग्रता',
    'कुंठित',
    'कुंड',
    'कुंडक',
    'कुंडल',
    'कुंडलाकार',
    'कुंडलाकारता',
    'कुंडलिका',
    'कुंडलित',
    'कुंडलितता',
    'कुंडलिनी',
    'कुंडलिया',
    'कुंडली',
    'कुंडलीदार',
    'कुंडा',
    'कुंडी',
    'कुंत',
    'कुंतल',
    'कुंतलवाही',
    'कुंतलित',
    'कुंता',
    'कुंती',
    'कुंतीः',
    'कुंथुनाथ',
    'कुंद',
    'कुंदकार',
    'कुंदता',
    'कुंदन',
    'कुंदनसाज',
    'कुंदरु',
    'कुंदा',
    'कुंदी',
    'कुंदीगर',
    'कुंदुर',
    'कुंभ',
    'कुंभकर्ण',
    'कुंभकार',
    'कुंभकारिता',
    'कुंभज',
    'कुंभदासी',
    'कुंभनाथ',
    'कुंभा',
    'कुंभिका',
    'कुंभिनी',
    'कुंभिल',
    'कुंभी',
    'कुंभीनसी',
    'कुंभीपाक',
    'कुंभील',
    'कुंवारी',
    'कुंसी',
    'कुअवसर',
    'कुआँ',
    'कुइयाँ',
    'कुई',
    'कुएँ',
    'कुएं',
    'कुक',
    'कुकंदर',
    'कुकड़ना',
    'कुकड़ी',
    'कुकमिर्णी',
    'कुकर',
    'कुकराँछी',
    'कुकरी',
    'कुकर्म',
    'कुकर्मी',
    'कुकवि',
    'कुक़र्',
    'कुक़ीर्',
    'कुकाति',
    'कुकिंग',
    'कुकुरमाछी',
    'कुकुरमुत्ता',
    'कुकृत',
    'कुकृत्य',
    'कुक्कटी',
    'कुक्कर',
    'कुक्कुट',
    'कुक्कुटरेक',
    'कुक्कुटी',
    'कुक्कुर',
    'कुक्कू',
    'कुक्ष',
    'कुक्षि',
    'कुक्षीय',
    'कुख्यात',
    'कुख्याति',
    'कुगठित',
    'कुगढ़',
    'कुघड़ी',
    'कुघात',
    'कुच',
    'कुचकुचवा',
    'कुचकुचा',
    'कुचकुचाना',
    'कुचक्र',
    'कुचक्री',
    'कुचन',
    'कुचमुख',
    'कुचर',
    'कुचल',
    'कुचलना',
    'कुचलने',
    'कुचला',
    'कुचलाई',
    'कुचवती',
    'कुचहीना',
    'कुचाग्र',
    'कुचाल',
    'कुचालक',
    'कुचाली',
    'कुचीपुड़ी',
    'कुचेता',
    'कुचैला',
    'कुचैले',
    'कुछ',
    'कुछाया',
    'कुज',
    'कुजंत्र',
    'कुजंभल',
    'कुजनी',
    'कुजा',
    'कुज्जा',
    'कुज्जी',
    'कुज्झटिका',
    'कुज्ञान',
    'कुट',
    'कुटंक',
    'कुटंत',
    'कुटंब',
    'कुटंबक',
    'कुटंबयुक्त',
    'कुटंबयुक्तता',
    'कुटंबहीन',
    'कुटंबहीनता',
    'कुटंबी',
    'कुटंुब',
    'कुटक',
    'कुटका',
    'कुटकी',
    'कुटज',
    'कुटनपना',
    'कुटनहार',
    'कुटनहारा',
    'कुटना',
    'कुटनापन',
    'कुटनापा',
    'कुटनी',
    'कुटम',
    'कुटा',
    'कुटाई',
    'कुटि',
    'कुटिया',
    'कुटिल',
    'कुटिलता',
    'कुटिलधी',
    'कुटिलभाषी',
    'कुटिलिका',
    'कुटी',
    'कुटीर',
    'कुटुंबी',
    'कुटुम',
    'कुटुम्ब',
    'कुटुम्बहीन',
    'कुटेक',
    'कुटेव',
    'कुटेवहीन',
    'कुटेवी',
    'कुट्टक',
    'कुट्टा',
    'कुट्टित',
    'कुट्टिम',
    'कुट्टी',
    'कुट्टीर',
    'कुठ',
    'कुठर',
    'कुठरा',
    'कुठरिया',
    'कुठलन',
    'कुठला',
    'कुठाँव',
    'कुठाट',
    'कुठाय',
    'कुठार',
    'कुठारपाणि',
    'कुठारी',
    'कुठारू',
    'कुठाली',
    'कुठि',
    'कुठितता',
    'कुठियार',
    'कुठिला',
    'कुठौर',
    'कुडली',
    'कुड़',
    'कुड़क',
    'कुड़कुड़',
    'कुड़कुड़ाना',
    'कुड़कुड़ाहट',
    'कुड़कुड़ी',
    'कुड़माई',
    'कुड़री',
    'कुड़ा',
    'कुड़ाई',
    'कुड़ुक',
    'कुडा',
    'कुडुक',
    'कुडौल',
    'कुडौलता',
    'कुढंगा',
    'कुढंगापन',
    'कुढ़न',
    'कुढ़ना',
    'कुढ़ा',
    'कुण्ठित',
    'कुण्ड',
    'कुण्डल',
    'कुण्डलित',
    'कुण्डली',
    'कुण्डा',
    'कुतक',
    'कुतकर्ता',
    'कुतका',
    'कुतप',
    'कुतरन',
    'कुतरना',
    'कुतरने',
    'कुतरल',
    'कुतरा',
    'कुतराई',
    'कुतर्क',
    'कुतर्की',
    'कुतवार',
    'कुताई',
    'कुतिभोजः',
    'कुतिया',
    'कुतुप',
    'कुतुब',
    'कुतुबनुमा',
    'कुतुहलजनक',
    'कुतू',
    'कुतूहल',
    'कुतूहलपूर्ण',
    'कुतूहलहीन',
    'कुतूहली',
    'कुत्तप',
    'कुत्ता',
    'कुत्ताः',
    'कुत्ती',
    'कुत्ते',
    'कुत्तों',
    'कुत्सा',
    'कुत्सित',
    'कुत्सितता',
    'कुथ',
    'कुथेदरी',
    'कुदक',
    'कुदकता',
    'कुदकती',
    'कुदकना',
    'कुदकी',
    'कुदक्कड़',
    'कुदक्कड़ी',
    'कुदक्का',
    'कुदर्शन',
    'कुदर्शनता',
    'कुदाँव',
    'कुदाक',
    'कुदान',
    'कुदारी',
    'कुदाल',
    'कुदाली',
    'कुदास',
    'कुदिन',
    'कुदूरत',
    'कुदृश्य',
    'कुदृश्यता',
    'कुदृष्टि',
    'कुदृष्टिपूर्ण',
    'कुदेदना',
    'कुदेव',
    'कुदेवता',
    'कुदेशित',
    'कुद्रंक',
    'कुद्रा',
    'कुद्सी',
    'कुधर्म',
    'कुनकुना',
    'कुनबा',
    'कुनबे',
    'कुनमुनाता',
    'कुनमुनाना',
    'कुनमुनाहट',
    'कुनाई',
    'कुनाल',
    'कुनेरा',
    'कुन्जी',
    'कुन्द',
    'कुन्दा',
    'कुन्यत',
    'कुन्याय',
    'कुपक',
    'कुपढ़',
    'कुपढ़ता',
    'कुपथ',
    'कुपथ्य',
    'कुपध्याहार',
    'कुपरामर्श',
    'कुपरिपाचित',
    'कुपाच्य',
    'कुपाठ',
    'कुपात्र',
    'कुपात्रता',
    'कुपित',
    'कुपुत्र',
    'कुपोषण',
    'कुपोषित',
    'कुप्ता',
    'कुप्पक',
    'कुप्पा',
    'कुप्पी',
    'कुप्रथा',
    'कुप्रथाएं',
    'कुप्रबंध',
    'कुप्रबन्ध',
    'कुप्रभाव',
    'कुप्रभावित',
    'कुप्रयोग',
    'कुप्रवृत्ति',
    'कुप्रशासन',
    'कुप्रसिद्घ',
    'कुप्रसिद्धि',
    'कुप्रिंधित',
    'कुफ्र',
    'कुबंड',
    'कुबंद्धि',
    'कुबजा',
    'कुबड़ा',
    'कुबड़ापन',
    'कुबड़ी',
    'कुबर',
    'कुबरी',
    'कुबेर',
    'कुबेरः',
    'कुबेला',
    'कुबोध',
    'कुबोध्यता',
    'कुबोल',
    'कुबोला',
    'कुब्ज',
    'कुब्जता',
    'कुब्जा',
    'कुभंरेता',
    'कुभाषण',
    'कुभाषी',
    'कुमंत्र',
    'कुमंत्रणा',
    'कुमक',
    'कुमकुम',
    'कुमकुमा',
    'कुमति',
    'कुमाऊँ',
    'कुमागिर्णी',
    'कुमार',
    'कुमारक',
    'कुमारता',
    'कुमारन',
    'कुमारवाही',
    'कुमारव्रत',
    'कुमारसू',
    'कुमारिका',
    'कुमारी',
    'कुमारीपन',
    'कुमार्ग',
    'कुमार्गी',
    'कुमुख',
    'कुमुद',
    'कुमुदिनी',
    'कुमुद्वती',
    'कुमुहूर्त',
    'कुमेरू',
    'कुम्भ',
    'कुम्भीरूप',
    'कुम्हड़ा',
    'कुम्हरहा',
    'कुम्हराना',
    'कुम्हरौटी',
    'कुम्हला',
    'कुम्हलाना',
    'कुम्हलाया',
    'कुम्हलायापन',
    'कुम्हलावन',
    'कुम्हलाहट',
    'कुम्हार',
    'कुम्हारः',
    'कुम्हारकर्म',
    'कुम्हारगीरी',
    'कुयश',
    'कुयोग',
    'कुर',
    'कुरंकुर',
    'कुरंग',
    'कुरंगनामि',
    'कुरंगी',
    'कुरंड',
    'कुरंडम',
    'कुरंदम',
    'कुरआन',
    'कुरकुरा',
    'कुरखेत',
    'कुरगरा',
    'कुरच',
    'कुरचना',
    'कुरण्ड',
    'कुरता',
    'कुरती',
    'कुरबानी',
    'कुरमा',
    'कुरमुरा',
    'कुरर',
    'कुररी',
    'कुरल',
    'कुरव',
    'कुरस',
    'कुरसी',
    'कुरसीनामा',
    'कुराँकुर',
    'कुराज्य',
    'कुरान',
    'कुराह',
    'कुरिया',
    'कुरी',
    'कुरीति',
    'कुरुप',
    'कुरुपता',
    'कुरू',
    'कुरूई',
    'कुरूक्षेत्र',
    'कुरूख',
    'कुरूचिपूर्ण',
    'कुरूप',
    'कुरूपता',
    'कुरूप्य',
    'कुरूराज',
    'कुरूल',
    'कुरूवंशी',
    'कुरूविंद',
    'कुरूविन्द',
    'कुरूवृद्घ',
    'कुरूश्रेष्ठ',
    'कुरूसत्तम',
    'कुरेद',
    'कुरेदन',
    'कुरेदना',
    'कुरेदनी',
    'कुरेदने',
    'कुरेल',
    'कुरेलना',
    'कुरेलनी',
    'कुरोचक',
    'कुर्क',
    'कुर्की',
    'कुर्कीयोग्य',
    'कुर्ता',
    'कुर्बानी',
    'कुर्सियों',
    'कुर्सी',
    'कुर्सीआवरण',
    'कुल',
    'कुलंग',
    'कुलंजन',
    'कुलंतंत्र',
    'कुलंधन',
    'कुलंधरा',
    'कुलंभर',
    'कुलक',
    'कुलकता',
    'कुलकर्णी',
    'कुलकेतु',
    'कुलक्रमतः',
    'कुलक्रमशः',
    'कुलक्रमागत',
    'कुलक्रमेण',
    'कुलक्षण',
    'कुलक्षणता',
    'कुलक्षणयुक्त',
    'कुलक्ष्मता',
    'कुलचा',
    'कुलचिह्ल',
    'कुलच्छन',
    'कुलज',
    'कुलजा',
    'कुलटा',
    'कुलटापतित्व',
    'कुलतार',
    'कुलथी',
    'कुलदीप',
    'कुलधर',
    'कुलन',
    'कुलनष्ट',
    'कुलना',
    'कुलनाम',
    'कुलनार',
    'कुलपति',
    'कुलपत्नी',
    'कुलफ़ा',
    'कुलफ़ी',
    'कुलबुलाता',
    'कुलबुलाना',
    'कुलबुलाहट',
    'कुलबैर',
    'कुलयुक्त',
    'कुललाभ',
    'कुलवंत',
    'कुलवंती',
    'कुलवर्धन',
    'कुलवेशधारी',
    'कुलश्रेष्ठ',
    'कुलसत्र',
    'कुलह',
    'कुलहीन',
    'कुलहेन',
    'कुलाँच',
    'कुलाँचना',
    'कुलाँट',
    'कुलांगत',
    'कुलांगना',
    'कुलांगार',
    'कुलागत',
    'कुलागतता',
    'कुलागति',
    'कुलाचार',
    'कुलाचार्य',
    'कुलाधिपति',
    'कुलाध्यक्ष',
    'कुलाबा',
    'कुलाल',
    'कुलालिका',
    'कुलावा',
    'कुलाह',
    'कुलि',
    'कुलिंग',
    'कुलिंगक',
    'कुलिक',
    'कुलिखित',
    'कुलिनी',
    'कुलिया',
    'कुलिश',
    'कुलिस',
    'कुली',
    'कुलीन',
    'कुलीनता',
    'कुलीय',
    'कुलीर',
    'कुलेख',
    'कुलोद्भवता',
    'कुलोन्नति',
    'कुलोन्नायक',
    'कुल्फी',
    'कुल्या',
    'कुल्ला',
    'कुल्ली',
    'कुल्हड़',
    'कुल्हाड़ा',
    'कुल्हाड़ी',
    'कुल्हिया',
    'कुल्हियाः',
    'कुवंग',
    'कुवचन',
    'कुवयवस्था',
    'कुवर्धित',
    'कुवर्ष',
    'कुवलयापीड़',
    'कुवाहक',
    'कुविंद',
    'कुविकसति',
    'कुविचार',
    'कुविचारी',
    'कुवितरण',
    'कुविधि',
    'कुविधित',
    'कुविधिपूर्वक',
    'कुविन्यस्त',
    'कुविहित',
    'कुवृत्ति',
    'कुवृद्धि',
    'कुवेणी',
    'कुवेशिता',
    'कुव्यवस्थत',
    'कुव्यवस्था',
    'कुव्यवहार',
    'कुव्यसन',
    'कुव्यसनहीन',
    'कुव्यसनहीनता',
    'कुव्यसनी',
    'कुश',
    'कुशन',
    'कुशप',
    'कुशय',
    'कुशल',
    'कुशलं',
    'कुशलकाम',
    'कुशलतः',
    'कुशलता',
    'कुशलतापूर्वक',
    'कुशलपूर्वक',
    'कुशलहस्त',
    'कुशस्थली',
    'कुशा',
    'कुशाई',
    'कुशाग्र',
    'कुशाग्रता',
    'कुशाग्रधी',
    'कुशाग्रबुद्धि',
    'कुशाघ्रबुद्धि',
    'कुशादगी',
    'कुशादा',
    'कुशादादिल',
    'कुशासन',
    'कुशासनपूर्ण',
    'कुशिक',
    'कुशिक्षा',
    'कुशिक्षित',
    'कुशीलक',
    'कुशीलय',
    'कुश्तमकुश्ता',
    'कुश्तमकुश्ती',
    'कुश्ता',
    'कुश्ती',
    'कुश्तीबाज़',
    'कुश्तीबाज़ी',
    'कुषाकु',
    'कुष्ठ',
    'कुष्ठी',
    'कुष्मांड',
    'कुसंकसृत',
    'कुसंग',
    'कुसंगति',
    'कुसंघात',
    'कुसंभी',
    'कुसंयोग',
    'कुसंवाद',
    'कुसंस्कार',
    'कुसभ्यता',
    'कुसमय',
    'कुसमायोजन',
    'कुसली',
    'कुसवारी',
    'कुसामाचार',
    'कुसिज्जतता',
    'कुसियारी',
    'कुसीद',
    'कुसीदी',
    'कुसुंबी',
    'कुसुम',
    'कुसुमकलि',
    'कुसुमधन्या',
    'कुसुमवती',
    'कुसुमशर',
    'कुसुमांजन',
    'कुसुमाकर',
    'कुसुमागम',
    'कुसुमाधिराज',
    'कुसुमालय',
    'कुसुमासव',
    'कुसुमित',
    'कुस्तुंबरू',
    'कुस्तुभ',
    'कुस्पष्टता',
    'कुस्यादु',
    'कुस्वप्न',
    'कुस्वभाव',
    'कुस्वभावी',
    'कुस्वाद',
    'कुस्वादिष्ट',
    'कुस्वास्थ्य',
    'कुस्सा',
    'कुह',
    'कुहकनी',
    'कुहन',
    'कुहनी',
    'कुहपं',
    'कुहबर',
    'कुहर',
    'कुहरा',
    'कुहराम',
    'कुहरित',
    'कुहरितता',
    'कुहरे',
    'कुहरेदार',
    'कुहाड़ा',
    'कुहाड़ी',
    'कुहासा',
    'कुहिन',
    'कुहु',
    'कुहुक',
    'कुहुकंठ',
    'कुहू',
    'कुित्सत',
    'कूँकूँ',
    'कूँच',
    'कूँचना',
    'कूँचा',
    'कूँची',
    'कूँड़',
    'कूँड़ा',
    'कूँड़ी',
    'कूँथना',
    'कूंची',
    'कूंज',
    'कूएँ',
    'कूक',
    'कूकना',
    'कूकनी',
    'कूकर',
    'कूका',
    'कूच',
    'कूचा',
    'कूचागर्दी',
    'कूचाबंद',
    'कूचिका',
    'कूचिर्का',
    'कूची',
    'कूज',
    'कूजन',
    'कूजना',
    'कूजा',
    'कूट',
    'कूटक',
    'कूटकथा',
    'कूटकर',
    'कूटकरण',
    'कूटकार',
    'कूटकृत',
    'कूटता',
    'कूटतापूर्ण',
    'कूटन',
    'कूटना',
    'कूटनीति',
    'कूटनीतिः',
    'कूटनीतिक',
    'कूटनीतिज्ञ',
    'कूटरचना',
    'कूटवाचन',
    'कूटस्थ',
    'कूटहंता',
    'कूटहीन',
    'कूटहीनता',
    'कूटा',
    'कूटाक्ष',
    'कूटागार',
    'कूटायुध',
    'कूटावपंत',
    'कूटावपात',
    'कूटास्त्र',
    'कूटी',
    'कूटू',
    'कूटोक्ति',
    'कूड़ा',
    'कूड़ाकोठ',
    'कूड़ादान',
    'कूड़े',
    'कूड़ेखाना',
    'कूड़ेदान',
    'कूड़ेदानी',
    'कूढ़',
    'कूढ़मग्ज़',
    'कूणिका',
    'कूतन',
    'कूतना',
    'कूतने',
    'कूता',
    'कूथना',
    'कूद',
    'कूदकर',
    'कूदता',
    'कूदते',
    'कूदना',
    'कूदने',
    'कूदनेवाला',
    'कूदफाँद',
    'कूदू',
    'कूप',
    'कूपक',
    'कूपकार',
    'कूपन',
    'कूपादान',
    'कूपिका',
    'कूपी',
    'कूपुपु',
    'कूपे',
    'कूब',
    'कूबड़',
    'कूबयुक्त',
    'कूबर',
    'कूबरपन',
    'कूबा',
    'कूम',
    'कूमल',
    'कूर',
    'कूरियर',
    'कूर्च',
    'कूर्चाकार',
    'कूर्चिका',
    'कूर्दक',
    'कूर्पर',
    'कूर्म',
    'कूर्मपृष्ठाकारता',
    'कूर्मराज',
    'कूर्मावतार',
    'कूर्मासन',
    'कूलंकचा',
    'कूलर',
    'कूला',
    'कूलिंग',
    'कूलिनी',
    'कूल्हा',
    'कूष्मांड',
    'कूष्मांडी',
    'कूहा',
    'कृक',
    'कृकण',
    'कृकयाक',
    'कृकलास',
    'कृकाट',
    'कृकाटक',
    'कृकाटिका',
    'कृक्षाम्ल',
    'कृत',
    'कृतंक',
    'कृतंन',
    'कृतक',
    'कृतकर्मा',
    'कृतकाम',
    'कृतकार्य',
    'कृतकार्यता',
    'कृतकृत्य',
    'कृतघ्न',
    'कृतघ्नता',
    'कृतज्ञ',
    'कृतज्ञता',
    'कृततः',
    'कृतताः',
    'कृतध्न',
    'कृतध्नता',
    'कृतध्नतापूर्वक',
    'कृतनिश्चय',
    'कृतप्रतिज्ञ',
    'कृतयुग',
    'कृतयुगी',
    'कृतवाची',
    'कृतसंकल्प',
    'कृतांक',
    'कृतांत',
    'कृताकृत',
    'कृतागम',
    'कृतात्मा',
    'कृतान्न',
    'कृतामिषेक',
    'कृतार्थ',
    'कृतार्थता',
    'कृति',
    'कृतिक',
    'कृतिचोर',
    'कृतित्व',
    'कृतित्वहीनता',
    'कृतिनामा',
    'कृतिवाद',
    'कृतिविद्या',
    'कृतिस्व',
    'कृती',
    'कृते',
    'कृत्तिका',
    'कृत्तिकाः',
    'कृत्तिवासा',
    'कृत्थता',
    'कृत्य',
    'कृत्यता',
    'कृत्या',
    'कृत्रिम',
    'कृत्रिमता',
    'कृत्रिमतापूर्ण',
    'कृत्स',
    'कृदंत',
    'कृदंती',
    'कृदन्तीकाल',
    'कृप',
    'कृपण',
    'कृपणता',
    'कृपया',
    'कृपा',
    'कृपाचार्य',
    'कृपाण',
    'कृपाणी',
    'कृपापात्र',
    'कृपापूर्वक',
    'कृपाल',
    'कृपालु',
    'कृपालुता',
    'कृपास्थायित्व',
    'कृपाहीन',
    'कृपाहीनता',
    'कृपीसुत',
    'कृभको',
    'कृमि',
    'कृमिकोष',
    'कृमिज',
    'कृमिजा',
    'कृमिध्न',
    'कृमिनाशी',
    'कृमिरोग',
    'कृमिला',
    'कृमिवर्ण',
    'कृश',
    'कृशकाय',
    'कृशकायता',
    'कृशता',
    'कृशांग',
    'कृशानुरेता',
    'कृशोदरी',
    'कृश्ज्ञि',
    'कृश्न',
    'कृषक',
    'कृषकवर्ग',
    'कृषाण',
    'कृषि',
    'कृषिउपकरण',
    'कृषिक',
    'कृषिकर्म',
    'कृषिकारिता',
    'कृषिगत',
    'कृषिज',
    'कृषिजन्य',
    'कृषित',
    'कृषिवर्ग',
    'कृषीय',
    'कृष्ट',
    'कृष्टयान',
    'कृष्ण',
    'कृष्णः',
    'कृष्णकालीन',
    'कृष्णकेशिनी',
    'कृष्णचंद्र',
    'कृष्णन',
    'कृष्णनयना',
    'कृष्णभद्र',
    'कृष्णमुख',
    'कृष्णलाल',
    'कृष्णवर्ण',
    'कृष्णसारथी',
    'कृष्णसारा',
    'कृष्णसारिका',
    'कृष्णा',
    'कृष्णांगी',
    'कृष्णांश',
    'कृष्णाग्रज',
    'कृष्णाचल',
    'कृष्णामूर्ति',
    'कृष्णायस',
    'कृष्णायास',
    'कृष्णार्जुन',
    'कृष्णाश्म',
    'कृष्णाष्टमी',
    'कृष्णास्वामी',
    'कृष्णिका',
    'कृष्णिमा',
    'कृष्णी',
    'कृष्णोपदेश',
    'कृष्य',
    'कृित्तका',
    'के',
    'केंचुआ',
    'केंचुल',
    'केंचुली',
    'केंत',
    'केंद्र',
    'केंद्रग',
    'केंद्रगामी',
    'केंद्रण',
    'केंद्रता',
    'केंद्रभ्रष्ट',
    'केंद्रभ्रष्टता',
    'केंद्रवर्ती',
    'केंद्रवाद',
    'केंद्रविमुख',
    'केंद्रस्थ',
    'केंद्रहीन',
    'केंद्रहीनता',
    'केंद्राकर्षण',
    'केंद्रानुशासन',
    'केंद्रापसरण',
    'केंद्रापसारिता',
    'केंद्रापसारी',
    'केंद्राभिमुख',
    'केंद्राभिमुखी',
    'केंद्राभिसरण',
    'केंद्राभिसार',
    'केंद्राभिसारी',
    'केंद्रिक',
    'केंद्रिकरण',
    'केंद्रित',
    'केंद्रितता',
    'केंद्रिय',
    'केंद्रीभूत',
    'केंद्रीय',
    'केंद्रीयकरण',
    'केंद्रों',
    'केंब्रिज',
    'केक',
    'केकड़ा',
    'केकयी',
    'केकर',
    'केका',
    'केकावल',
    'केकी',
    'केटू',
    'केड़ा',
    'केतक',
    'केतकी',
    'केतन',
    'केतली',
    'केता',
    'केतिक',
    'केतु',
    'केतुजा',
    'केतुवीर्य',
    'केतू',
    'केद',
    'केदार',
    'केदारेश्वर',
    'केद्रण',
    'केद्राभिमुख',
    'केन',
    'केनिपात',
    'केन्द्र',
    'केन्द्रक',
    'केन्द्रण',
    'केन्द्रवार',
    'केन्द्रिका',
    'केन्द्रित',
    'केन्द्रीकरण',
    'केन्द्रीय',
    'केन्द्रीयकृत',
    'केन्द्रों',
    'केप',
    'केबल',
    'केबलः',
    'केबिन',
    'केबिल',
    'केबिलों',
    'केमरे',
    'केमिकल',
    'केयर',
    'केयरटेकर',
    'केयली',
    'केयूर',
    'केरल',
    'केराव',
    'केरोसिन',
    'केला',
    'केलाः',
    'केलास',
    'केलासन',
    'केलासित',
    'केलि',
    'केलिकला',
    'केलिगृह',
    'केवट',
    'केवटाई',
    'केवटियां',
    'केवड़ा',
    'केवड़े',
    'केवल',
    'केवलता',
    'केवलात्मा',
    'केवलीय',
    'केश',
    'केशंत',
    'केशः',
    'केशक',
    'केशगर्भ',
    'केशचर्या',
    'केशदान',
    'केशधारी',
    'केशपक्ष',
    'केशपाश',
    'केशबंध',
    'केशयुक्त',
    'केशर',
    'केशरी',
    'केशव',
    'केशवायुध',
    'केशवावास',
    'केशहस्त',
    'केशहीन',
    'केशहीनता',
    'केशाच्छादित',
    'केशार्पण',
    'केशि',
    'केशिक',
    'केशिनी',
    'केशिहा',
    'केशी',
    'केशोच्चय',
    'केशोत्पाटन',
    'केस',
    'केसमैंट',
    'केसर',
    'केसराचल',
    'केसरिया',
    'केसरी',
    'केसरीः',
    'केसारी',
    'केहरिनहा',
    'केहरी',
    'केहा',
    'केहि',
    'कैंचा',
    'कैंची',
    'कैंछोनमैंट',
    'कैंट',
    'कैंटीलीवर',
    'कैंडल',
    'कैंडलमस',
    'कैंडसैंस',
    'कैंडा',
    'कैंडिडेट',
    'कैंडी',
    'कैंप',
    'कैंपस',
    'कैंवल',
    'कैंसर',
    'कैकयी',
    'कैकस',
    'कैकसी',
    'कैकेयी',
    'कैकेयीः',
    'कैग',
    'कैच',
    'कैज़ुअल',
    'कैटभ',
    'कैटभा',
    'कैटभारि',
    'कैटल',
    'कैटलाग',
    'कैटलॉग',
    'कैटवाक',
    'कैटापुल्ट',
    'कैटापुल्टिंग',
    'कैटेलाग',
    'कैट्स',
    'कैडेट',
    'कैतव',
    'कैथलिक',
    'कैथालिक',
    'कैथासिंस',
    'कैथीड्रल',
    'कैथोलिक',
    'कैद',
    'कैदगाड़ी',
    'कैदियों',
    'कैदी',
    'कैन',
    'कैनटीन',
    'कैनन',
    'कैनवस',
    'कैनवास',
    'कैनवैसर',
    'कैनवैसिंग',
    'कैनाल',
    'कैनिबाल',
    'कैनिस्टर',
    'कैन्ड',
    'कैप',
    'कैपिटल',
    'कैपिटलिज्',
    'कैपिटलिस्ट',
    'कैप्टन',
    'कैप्टेन',
    'कैप्सूल',
    'कैफ़ियत',
    'कैफ़ियततलबी',
    'कैफ़ी',
    'कैब',
    'कैबिनेट',
    'कैबी',
    'कैम',
    'कैमरा',
    'कैमरामैन',
    'कैमरे',
    'कैमल',
    'कैमिकल',
    'कैमिकल्स',
    'कैमिस्ट',
    'कैमिस्ट्री',
    'कैमिस्ट्स',
    'कैमोथैरपी',
    'कैम्ब्रिज',
    'कैरट',
    'कैरम',
    'कैरव',
    'कैरविणी',
    'कैरात',
    'कैरातक',
    'कैरातिक',
    'कैरिकेचर',
    'कैरिकेचरिस्ट',
    'कैरिज',
    'कैरियर',
    'कैरियर्स',
    'कैरेक्टर',
    'कैरेवान',
    'कैरेवैल',
    'कैल',
    'कैलंडर',
    'कैलकुलस',
    'कैलाश',
    'कैलाशयासी',
    'कैलास',
    'कैलासवास',
    'कैलासवासिनी',
    'कैलासवासी',
    'कैलासाधिपति',
    'कैलिग्राफ़र',
    'कैलिबर',
    'कैलेन्डर',
    'कैलोरी',
    'कैल्कुलेटर',
    'कैल्कुलेटिंग',
    'कैल्शियम',
    'कैवर्ल',
    'कैवलरी',
    'कैवल्य',
    'कैवियट',
    'कैवेलियर',
    'कैश',
    'कैशबुक',
    'कैशियर',
    'कैशोर्य',
    'कैसर',
    'कैसा',
    'कैसिनो',
    'कैसुला',
    'कैसुली',
    'कैसे',
    'कैसेट',
    'कॉकरोच',
    'कॉटेज',
    'कॉपी',
    'कॉपीराइट',
    'कॉफी',
    'कॉम्प्लेक्स',
    'कॉर्पोरेट',
    'कॉर्पोरेशन',
    'कॉर्पोरेशनर',
    'कॉल',
    'कॉलम',
    'कॉलेज',
    'कॉलोनी',
    'को',
    'कोंइछा',
    'कोंकणी',
    'कोंच',
    'कोंचना',
    'कोंचा',
    'कोंछ',
    'कोंढ़ा',
    'कोंदना',
    'कोंपल',
    'कोआ',
    'कोआपरेटिव',
    'कोआर्डिनेटर',
    'कोइँदा',
    'कोइड़ार',
    'कोइनद',
    'कोइरार',
    'कोइल',
    'कोइलिया',
    'कोई',
    'कोऐग्युलेटिड',
    'कोऔपरेटिव',
    'कोऔपरेशन',
    'कोक',
    'कोकदेव',
    'कोकनद',
    'कोकना',
    'कोकहर',
    'कोकाकोला',
    'कोकिल',
    'कोकिलकंठ',
    'कोकिला',
    'कोकिलावास',
    'कोकिलोत्सव',
    'कोकी',
    'कोकीन',
    'कोकीनची',
    'कोकीनबाज़',
    'कोकून',
    'कोकेन',
    'कोको',
    'कोख',
    'कोखजना',
    'कोखहीना',
    'कोख्जली',
    'कोच',
    'कोचनी',
    'कोचली',
    'कोचवान',
    'कोचिंग',
    'कोचीन',
    'कोज़ी',
    'कोजागर',
    'कोट',
    'कोटपाल',
    'कोटबंदी',
    'कोटर',
    'कोटरा',
    'कोटराकारता',
    'कोटरीय',
    'कोटला',
    'कोटा',
    'कोटारिका',
    'कोटि',
    'कोटिक',
    'कोटिकरण',
    'कोटिकरणीय',
    'कोटिच्युति',
    'कोटिज्या',
    'कोटिपात्र',
    'कोटियां',
    'कोटियों',
    'कोटिर',
    'कोटिशा',
    'कोटिस्पर्शज्या',
    'कोटिहीन',
    'कोटी',
    'कोटीकरण',
    'कोटीय',
    'कोटीर',
    'कोटीश्वर',
    'कोटुंबिक',
    'कोटू',
    'कोटेशन',
    'कोट्टवी',
    'कोट्टार',
    'कोठरी',
    'कोठला',
    'कोठा',
    'कोठार',
    'कोठारी',
    'कोठिला',
    'कोठी',
    'कोठीवाल',
    'कोठीवाली',
    'कोठे',
    'कोठेदार',
    'कोड',
    'कोड़',
    'कोड़ना',
    'कोड़ा',
    'कोड़ी',
    'कोड़े',
    'कोड़ों',
    'कोडिसिल',
    'कोढ़',
    'कोढ़ी',
    'कोण',
    'कोणः',
    'कोणमयता',
    'कोणमान',
    'कोणमापी',
    'कोणयुक्त',
    'कोणयुक्तता',
    'कोणवादी',
    'कोणहीन',
    'कोणहीनता',
    'कोणांक',
    'कोणांश',
    'कोणान्तर',
    'कोणिक',
    'कोणीय',
    'कोणों',
    'कोतल',
    'कोतवाल',
    'कोतवाली',
    'कोतह',
    'कोताह',
    'कोताही',
    'कोथ',
    'कोथला',
    'कोथली',
    'कोदंड',
    'कोदंडी',
    'कोदों',
    'कोद्रा',
    'कोन',
    'कोना',
    'कोनाः',
    'कोनिया',
    'कोनियां',
    'कोनिर्या',
    'कोने',
    'कोनों',
    'कोप',
    'कोपना',
    'कोपरा',
    'कोपवान',
    'कोपाइलट',
    'कोपिता',
    'कोपिनी',
    'कोपी',
    'कोपीन',
    'कोपीहोल्डर',
    'कोफ्',
    'कोफ्ता',
    'कोबरा',
    'कोबा',
    'कोबी',
    'कोमल',
    'कोमलकाय',
    'कोमलकायता',
    'कोमलचित्त',
    'कोमलता',
    'कोमलमनस्क',
    'कोमलमनस्कता',
    'कोमलहृदय',
    'कोमला',
    'कोमलांग',
    'कोमलांगता',
    'कोमलांगना',
    'कोमलांगिनी',
    'कोमलांगी',
    'कोमलास्थि',
    'कोयल',
    'कोयलः',
    'कोयला',
    'कोयलिया',
    'कोयले',
    'कोया',
    'कोर',
    'कोरंडम',
    'कोरक',
    'कोरच्छादी',
    'कोरट',
    'कोरनिश',
    'कोरम',
    'कोरमा',
    'कोरल',
    'कोरस',
    'कोरा',
    'कोरापन',
    'कोरियर',
    'कोरिया',
    'कोरियोग्राफर',
    'कोरियोग्राफी',
    'कोरी',
    'कोर्ट',
    'कोर्टशिप',
    'कोर्डेज',
    'कोर्स',
    'कोल',
    'कोलंबो',
    'कोलक',
    'कोलकी',
    'कोलट्टम',
    'कोलतार',
    'कोलवल्ली',
    'कोलस्त्रुव',
    'कोला',
    'कोलाहल',
    'कोलाहलकारी',
    'कोलाहलपूर्ण',
    'कोलाहलहीन',
    'कोलाहलहीनता',
    'कोलिया',
    'कोली',
    'कोलेंदा',
    'कोल्ड',
    'कोल्हाड़',
    'कोल्हू',
    'कोविद',
    'कोश',
    'कोशकार',
    'कोशकारिता',
    'कोशगत',
    'कोशग्रंथ',
    'कोशचंचु',
    'कोशपाल',
    'कोशपुस्सतक',
    'कोशल',
    'कोशला',
    'कोशवासी',
    'कोशविज्ञान',
    'कोशा',
    'कोशागार',
    'कोशातकी',
    'कोशाधिकारी',
    'कोशाध्यक्ष',
    'कोशार्थ',
    'कोशालय',
    'कोशिका',
    'कोशिकाणु',
    'कोशिकीय',
    'कोशित',
    'कोशिश',
    'कोशिशें',
    'कोशी',
    'कोशीय',
    'कोश्का',
    'कोश्कि',
    'कोष',
    'कोषपाल',
    'कोषाध्यक्ष',
    'कोषिका',
    'कोष्टागार',
    'कोष्ठ',
    'कोष्ठक',
    'कोष्ठकित',
    'कोष्ठकीय',
    'कोष्ठपाल',
    'कोष्ठबद्घता',
    'कोष्ठागार',
    'कोष्ठालय',
    'कोष्ठावली',
    'कोष्ठिका',
    'कोष्ठी',
    'कोष्ण',
    'कोस',
    'कोसः',
    'कोसना',
    'कोसल',
    'कोसला',
    'कोसली',
    'कोस्टर',
    'कोह',
    'कोहँड़ा',
    'कोहनी',
    'कोहनूर',
    'कोहबर',
    'कोहरा',
    'कोहराम',
    'कोहल',
    'कोहली',
    'कोहसार',
    'कोहिमा',
    'कौंघना',
    'कौंच',
    'कौंचा',
    'कौंची',
    'कौंडिन्य',
    'कौंतेय',
    'कौंध',
    'कौंधक',
    'कौंधना',
    'कौंधने',
    'कौंशस',
    'कौंशैंस',
    'कौंसिल',
    'कौंसिलर',
    'कौंस्टिट',
    'कौंस्टिटुऐंसी',
    'कौआ',
    'कौआः',
    'कौइल',
    'कौए',
    'कौओं',
    'कौक',
    'कौकपिट',
    'कौक्सस्वेन',
    'कौग',
    'कौग्नीजेबल',
    'कौच',
    'कौचुमार',
    'कौज़वे',
    'कौटन',
    'कौटवी',
    'कौटिक',
    'कौटिलिक',
    'कौटिल्य',
    'कौटुंबिक',
    'कौड',
    'कौडफ़िश',
    'कौड़ा',
    'कौड़ियाला',
    'कौड़ी',
    'कौड़ीः',
    'कौडिल्ला',
    'कौणप',
    'कौणपी',
    'कौतुक',
    'कौतुकपूर्ण',
    'कौतुकप्रिय',
    'कौतुकागार',
    'कौतुकालय',
    'कौतुकिया',
    'कौतुकी',
    'कौतूहल',
    'कौत्स',
    'कौन',
    'कौनिंग',
    'कौप',
    'कौपर',
    'कौपरैस',
    'कौपीन',
    'कौप्टर',
    'कौप्य',
    'कौफ़ी',
    'कौम',
    'कौमडभ',
    'कौमनवैल्थ',
    'कौमरेड',
    'कौमर्स',
    'कौमा',
    'कौमार',
    'कौमारचारी',
    'कौमारिक',
    'कौमारी',
    'कौमार्य',
    'कौमार्ययुक्त',
    'कौमार्यवती',
    'कौमिक्स',
    'कौमी',
    'कौमीयता',
    'कौमुद',
    'कौमुदी',
    'कौमुदीचार',
    'कौमेडियन',
    'कौमेडी',
    'कौमेदकी',
    'कौयर',
    'कौर',
    'कौरव',
    'कौरवींयन',
    'कौरवीतिय',
    'कौरा',
    'कौरिडोर',
    'कौरूगेटिड',
    'कौरूगेशन',
    'कौरेसपौंडैंट',
    'कौर्क',
    'कौर्ड',
    'कौर्डियल',
    'कौर्नर',
    'कौल',
    'कौलई',
    'कौलम',
    'कौलर',
    'कौलर्ड',
    'कौलाचार',
    'कौलिक',
    'कौलिम्नस्ट',
    'कौली',
    'कौलीन',
    'कौलेयक',
    'कौलैस्टैरौल',
    'कौलोइड',
    'कौल्य',
    'कौविंदी',
    'कौवे',
    'कौवेनैंट',
    'कौशल',
    'कौशलपूर्वक',
    'कौशलिक',
    'कौशली',
    'कौशल्या',
    'कौशांबर',
    'कौशिक',
    'कौशिका',
    'कौशिकायुध',
    'कौशिकारि',
    'कौशिकी',
    'कौशीतकी',
    'कौशीलक',
    'कौशीलव',
    'कौशेय',
    'कौशेयता',
    'कौषीतकी',
    'कौसल्या',
    'कौसवर्ड',
    'कौसाद्य',
    'कौसिला',
    'कौसीद',
    'कौसुम',
    'कौस्ट',
    'कौस्टिंग',
    'कौस्तुभ',
    'कौस्मोड्रोन',
    'कौस्मोनौट',
    'कौस्मोनौटिकल',
    'क्मैन',
    'क्यण',
    'क्या',
    'क्यारी',
    'क्यू',
    'क्यूब',
    'क्यूबद्घ',
    'क्यूबद्घता',
    'क्यूबिक',
    'क्यूबिट',
    'क्यूसेक',
    'क्यों',
    'क्योंकर',
    'क्योंकि',
    'क्रंद',
    'क्रंदन',
    'क्रकच',
    'क्रकचवत',
    'क्रकची',
    'क्रकर',
    'क्रतु',
    'क्रतुध्वंसी',
    'क्रतुपति',
    'क्रतुराज',
    'क्रथन',
    'क्रद',
    'क्रम',
    'क्रमक',
    'क्रमचय',
    'क्रमण',
    'क्रमणिका',
    'क्रमतः',
    'क्रमनिहित',
    'क्रमपरिवर्तन',
    'क्रमबद्घ',
    'क्रमबद्घता',
    'क्रमबद्घन',
    'क्रमबद्ध',
    'क्रमभंग',
    'क्रमवाद',
    'क्रमवार',
    'क्रमशः',
    'क्रमसंचयी',
    'क्रमसूचक',
    'क्रमस्थान',
    'क्रमस्थापन',
    'क्रमहीन',
    'क्रमहीनतः',
    'क्रमहीनता',
    'क्रमांक',
    'क्रमांकन',
    'क्रमांकित',
    'क्रमांकिन्न',
    'क्रमाकुंचक',
    'क्रमाकुंचन',
    'क्रमागत',
    'क्रमागतता',
    'क्रमानुकूल',
    'क्रमानुकूलता',
    'क्रमानुसार',
    'क्रमानुसारतः',
    'क्रमानुसारता',
    'क्रमान्तरण',
    'क्रमावस्था',
    'क्रमिक',
    'क्रमिकतः',
    'क्रमिकता',
    'क्रमित',
    'क्रमीय',
    'क्रमेण',
    'क्रमेतरता',
    'क्रमेल',
    'क्रमोक्ति',
    'क्रय',
    'क्रयण',
    'क्रयविक्रयिक',
    'क्रयविक्रयी',
    'क्रयशक्ति',
    'क्रयी',
    'क्रयू',
    'क्रव्वाद',
    'क्रशर',
    'क्रस्ट',
    'क्रांत',
    'क्रांतदर्शी',
    'क्रांतवृत्त',
    'क्रांति',
    'क्रांतिकर',
    'क्रांतिकारी',
    'क्रांतिपात',
    'क्रांतिपूर्णर्',
    'क्रांतिवाद',
    'क्रांतिवीर',
    'क्रांतीय',
    'क्राइस्ट',
    'क्राकचिक',
    'क्रान्ति',
    'क्रान्तिकारी',
    'क्राफ्',
    'क्राफ्ट',
    'क्राफ्टमैन',
    'क्राफ्ट्स',
    'क्राम',
    'क्रामिक',
    'क्रायदा',
    'क्रास',
    'क्रासिंग',
    'क्रिकेट',
    'क्रियण',
    'क्रियमाण',
    'क्रिया',
    'क्रियांग',
    'क्रियाः',
    'क्रियाएं',
    'क्रियाओं',
    'क्रियाकलाप',
    'क्रियाकारी',
    'क्रियागत',
    'क्रियातंत्र',
    'क्रियात्मक',
    'क्रियान्वयन',
    'क्रियान्वित',
    'क्रियाफलभागिता',
    'क्रियार्थ',
    'क्रियावली',
    'क्रियावाचक',
    'क्रियाविज्ञानी',
    'क्रियाविधि',
    'क्रियाशील',
    'क्रियाशीलता',
    'क्रियाहीन',
    'क्रियाहीनता',
    'क्रियोन्मुख',
    'क्रिवाम',
    'क्रिश्चियन',
    'क्रिसमस',
    'क्रिसैंथेमम',
    'क्रिस्टल',
    'क्रिस्टलन',
    'क्रिस्टलित',
    'क्रिस्टलीकरण',
    'क्रिस्तानी',
    'क्रिस्निंग',
    'क्रिस्मस',
    'क्रिस्स्तान',
    'क्रीक',
    'क्रीज़',
    'क्रीट',
    'क्रीड़',
    'क्रीड़नक',
    'क्रीड़ा',
    'क्रीड़ाकक्ष',
    'क्रीड़ाकर',
    'क्रीड़ागृह',
    'क्रीड़ानक',
    'क्रीड़ापूर्ण',
    'क्रीड़ापूर्वक',
    'क्रीड़ाप्रिय',
    'क्रीड़ाप्रियता',
    'क्रीडा',
    'क्रीडांगन',
    'क्रीडाप्रिय',
    'क्रीत',
    'क्रीम',
    'क्रीमिया',
    'क्रीमैटोरियम',
    'क्रुज़र',
    'क्रुडनैस',
    'क्रुद्घ',
    'क्रुद्ध',
    'क्रुर',
    'क्रुरता',
    'क्रुरतापूर्ण',
    'क्रुरलोचन',
    'क्रुराकृति',
    'क्रुरात्मा',
    'क्रुस',
    'क्रू',
    'क्रूर',
    'क्रूरता',
    'क्रूरतापूर्ण',
    'क्रूस',
    'क्रूसमूर्ति',
    'क्रेंच',
    'क्रेज़',
    'क्रेट',
    'क्रेटबंद',
    'क्रेटबंदी',
    'क्रेटिंग',
    'क्रेटित',
    'क्रेडिट',
    'क्रेता',
    'क्रेन',
    'क्रेनमैन',
    'क्रेप',
    'क्रेय',
    'क्रेयन',
    'क्रैंक',
    'क्रैडल',
    'क्रैडिट',
    'क्रैडिबिलिटी',
    'क्रैब',
    'क्रैश',
    'क्रॉस',
    'क्रोज़',
    'क्रोड',
    'क्रोड़',
    'क्रोड़पत्र',
    'क्रोड़मुख',
    'क्रोडीय',
    'क्रोडेतर',
    'क्रोध',
    'क्रोधपूर्ण',
    'क्रोधपूर्वक',
    'क्रोधवंत',
    'क्रोधवशा',
    'क्रोधशील',
    'क्रोधशीलता',
    'क्रोधहा',
    'क्रोधहीन',
    'क्रोधहीनता',
    'क्रोधा',
    'क्रोधानुभूति',
    'क्रोधावस्था',
    'क्रोधाविष्ट',
    'क्रोधावेश',
    'क्रोधित',
    'क्रोधी',
    'क्रोधोन्मत्त',
    'क्रोधोन्मत्तता',
    'क्रोधोन्माद',
    'क्रोबार',
    'क्रोमियम',
    'क्रोमोसोम',
    'क्रोश',
    'क्रोशिया',
    'क्रोष्टा',
    'क्रौंच',
    'क्रौंचादन',
    'क्रौंचाराति',
    'क्रौंचारि',
    'क्रौस',
    'क्रौसधारी',
    'क्लच',
    'क्लब',
    'क्लर्क',
    'क्लर्की',
    'क्लर्कीगीरी',
    'क्लर्जी',
    'क्लांत',
    'क्लांति',
    'क्लांतिकर',
    'क्लांतिपूर्ण',
    'क्लांतिहर',
    'क्लांतिहीनता',
    'क्लाइमेट',
    'क्लाइमैक्स',
    'क्लाईमैक्स',
    'क्लाथ',
    'क्लास',
    'क्लासफ़ैलो',
    'क्लासिफ़ाइड',
    'क्लासिफ़ाई',
    'क्लासिफ़िकेटरी',
    'क्लासिफ़िकेशन',
    'क्लिनिक',
    'क्लिन्न',
    'क्लिप',
    'क्लिपर',
    'क्ली',
    'क्लीक',
    'क्लीनिंग',
    'क्लीनिक',
    'क्लीनिकल',
    'क्लीय',
    'क्लीयता',
    'क्लीयरैंस',
    'क्लीव',
    'क्ले',
    'क्लेद',
    'क्लेदन',
    'क्लेदित',
    'क्लेश',
    'क्लेशक',
    'क्लेशकारी',
    'क्लेशग्रस्त',
    'क्लेशप्रद',
    'क्लेशी',
    'क्लेष्टा',
    'क्लैक्सोन',
    'क्लैरिक',
    'क्लोक',
    'क्लोज़अप',
    'क्लोनिंग',
    'क्लोम',
    'क्लोरीन',
    'क्लोरोफ़िल',
    'क्लौइस्टर',
    'क्लौक',
    'क्लौकवाइज़',
    'क्लौज़',
    'क्लौथ',
    'क्वचित्',
    'क्वणन',
    'क्वथन',
    'क्वथनांक',
    'क्वथनित्र',
    'क्वांतम',
    'क्वाणित',
    'क्वाथ',
    'क्वारा',
    'क्वारी',
    'क्वार्टर',
    'क्विकमार्च',
    'क्व्थित',
    'क्ष',
    'क्षण',
    'क्षणजीवी',
    'क्षणदीप',
    'क्षणदीप्ति',
    'क्षणद्युति',
    'क्षणभंगुर',
    'क्षणभंगुरता',
    'क्षणस्थायी',
    'क्षणिक',
    'क्षणिकता',
    'क्षणिकवाद',
    'क्षणिका',
    'क्षणों',
    'क्षत',
    'क्षताक्षम',
    'क्षति',
    'क्षतिकरण',
    'क्षतिकारक',
    'क्षतिकारी',
    'क्षतिकृत',
    'क्षतिग्रस्त',
    'क्षतिज',
    'क्षतिपूरक',
    'क्षतिपूर्ण',
    'क्षतिपूर्ति',
    'क्षतिपूर्त्ति',
    'क्षतिप्रद',
    'क्षतिम',
    'क्षतिमा',
    'क्षतिमूल्य',
    'क्षतिहीन',
    'क्षत्र',
    'क्षत्रज',
    'क्षत्रप',
    'क्षत्रसत्र',
    'क्षत्रांतक',
    'क्षत्राणी',
    'क्षत्रिय',
    'क्षत्रिया',
    'क्षत्रियाणी',
    'क्षत्री',
    'क्षत्रीय',
    'क्षदन',
    'क्षपण',
    'क्षपणक',
    'क्षपा',
    'क्षपाकर',
    'क्षपाचर',
    'क्षम',
    'क्षमणीय',
    'क्षमता',
    'क्षमताः',
    'क्षमताओं',
    'क्षमतानुसार',
    'क्षमतापूर्वक',
    'क्षमताशाली',
    'क्षमताहीन',
    'क्षमताहीनता',
    'क्षमा',
    'क्षमादत्त',
    'क्षमादाता',
    'क्षमादान',
    'क्षमापत्र',
    'क्षमापात्र',
    'क्षमापूर्ण',
    'क्षमाप्राप्त',
    'क्षमायाचक',
    'क्षमायाचना',
    'क्षमायाची',
    'क्षमावान',
    'क्षमाशील',
    'क्षमाशीलता',
    'क्षमाहीन',
    'क्षमाहीनता',
    'क्षम्य',
    'क्षम्यता',
    'क्षय',
    'क्षयंकर',
    'क्षयकाय',
    'क्षयकारक',
    'क्षयकारिता',
    'क्षयकारी',
    'क्षयन',
    'क्षयरोग',
    'क्षयरोगी',
    'क्षयशील',
    'क्षयशीलता',
    'क्षयहीन',
    'क्षयहीनता',
    'क्षयित',
    'क्षयिष्णु',
    'क्षयिष्णुता',
    'क्षयी',
    'क्षर',
    'क्षरण',
    'क्षरणशील',
    'क्षरता',
    'क्षरा',
    'क्षरित',
    'क्षांत',
    'क्षांता',
    'क्षांति',
    'क्षात्र',
    'क्षाम',
    'क्षार',
    'क्षारक',
    'क्षारता',
    'क्षारमूला',
    'क्षारिकी',
    'क्षारीकरण',
    'क्षारीय',
    'क्षारीयन',
    'क्षालन',
    'क्षालित',
    'क्षिजाङ',
    'क्षिति',
    'क्षितिज',
    'क्षितिजा',
    'क्षितिजीय',
    'क्षितिजीयता',
    'क्षितिधर',
    'क्षितिनाथ',
    'क्षितिरूह',
    'क्षितिसुत',
    'क्षितिज़',
    'क्षितींद्र',
    'क्षितीय',
    'क्षितीश',
    'क्षित्यदिति',
    'क्षिपक',
    'क्षिपणी',
    'क्षिपणु',
    'क्षिप्त',
    'क्षिप्तचित्त',
    'क्षिप्ति',
    'क्षिप्र',
    'क्षिप्रतः',
    'क्षिप्रता',
    'क्षीण',
    'क्षीणकाय',
    'क्षीणकायता',
    'क्षीणता',
    'क्षीणन',
    'क्षीयमान',
    'क्षीर',
    'क्षीरज',
    'क्षीरज़ा',
    'क्षीरद्रुम',
    'क्षीरधि',
    'क्षीरस',
    'क्षीराद',
    'क्षीरिका',
    'क्षीरी',
    'क्षीरीय',
    'क्षुद्र',
    'क्षुद्रकर्म',
    'क्षुद्रता',
    'क्षुद्रमुस्ता',
    'क्षुद्रल',
    'क्षुद्रा',
    'क्षुद्राणु',
    'क्षुद्रात्मा',
    'क्षुद्रान्त',
    'क्षुद्राशय',
    'क्षुद्राशयता',
    'क्षुधा',
    'क्षुधाग्रस्त',
    'क्षुधात्तर्',
    'क्षुधापूर्वक',
    'क्षुधावर्धक',
    'क्षुधाहर',
    'क्षुधाहीनता',
    'क्षुधित',
    'क्षुधोत्तेजन',
    'क्षुप',
    'क्षुब्ध',
    'क्षुब्धता',
    'क्षुर',
    'क्षुरक',
    'क्षुरपा',
    'क्षुरिका',
    'क्षुरिपा',
    'क्षुविततः',
    'क्षुष्ण',
    'क्षेतक',
    'क्षेत्र',
    'क्षेत्रः',
    'क्षेत्रक',
    'क्षेत्रज्ञ',
    'क्षेत्रज्ञता',
    'क्षेत्रण',
    'क्षेत्रपति',
    'क्षेत्रपार',
    'क्षेत्रपाल',
    'क्षेत्रफल',
    'क्षेत्रफलीय',
    'क्षेत्रमापी',
    'क्षेत्रमिति',
    'क्षेत्रमूल',
    'क्षेत्रवार',
    'क्षेत्रविद',
    'क्षेत्रव्यापी',
    'क्षेत्रांकन',
    'क्षेत्रांकित',
    'क्षेत्राकृति',
    'क्षेत्राधिकार',
    'क्षेत्राधिदेव',
    'क्षेत्राधिप',
    'क्षेत्राधीश',
    'क्षेत्रानुसार',
    'क्षेत्रावार',
    'क्षेत्रिका',
    'क्षेत्री',
    'क्षेत्रीकरण',
    'क्षेत्रीय',
    'क्षेत्रीयता',
    'क्षेत्रों',
    'क्षेत्रोन्मुखता',
    'क्षेप',
    'क्षेपक',
    'क्षेपण',
    'क्षेपणि',
    'क्षेपणिक',
    'क्षेपणी',
    'क्षेपणीय',
    'क्षेपास्त्र',
    'क्षेप्ता',
    'क्षेप्यास्त्र',
    'क्षेम',
    'क्षेमंकर',
    'क्षेमकर',
    'क्षेमा',
    'क्षेम्य',
    'क्षैतिज',
    'क्षोणि',
    'क्षोद',
    'क्षोदिमा',
    'क्षोभ',
    'क्षोभकारी',
    'क्षोभजनक',
    'क्षोभजनकता',
    'क्षोभण',
    'क्षोभशील',
    'क्षोभहीन',
    'क्षोभहीनता',
    'क्षोभाकुल',
    'क्षोभी',
    'क्षौभ',
    'क्षौर',
    'क्ष्मता',
    'क्ष्मा',
    'क्ष्वेड',
    'क्सालरूम',
    'क्सालिक',
    'क्सालिकल',
    'कज़र्दार',
    'कफ़न',
    'खँगालना',
    'खँगैल',
    'खँगोलना',
    'खँचाना',
    'खँचिया',
    'खँचू',
    'खँजरी',
    'खँडरिच',
    'खँडरू',
    'खँडहर',
    'खँड़',
    'खँड़सार',
    'खँड़सारी',
    'खँतरा',
    'खँपारना',
    'खँसन',
    'खँाड़',
    'खंख',
    'खंखारना',
    'खंग',
    'खंगड़',
    'खंगधारी',
    'खंगपाणि',
    'खंगहा',
    'खंगालना',
    'खंजन',
    'खंजनाक्ष',
    'खंजनाक्षा',
    'खंजर',
    'खंजरीट',
    'खंड',
    'खंडः',
    'खंडकरण',
    'खंडछेद',
    'खंडछेदन',
    'खंडज',
    'खंडजा',
    'खंडजित',
    'खंडता',
    'खंडन',
    'खंडनात्मक',
    'खंडनीय',
    'खंडपरशु',
    'खंडमय',
    'खंडरण',
    'खंडला',
    'खंडश',
    'खंडशः',
    'खंडशाला',
    'खंडहर',
    'खंडहीन',
    'खंडहीनता',
    'खंडा',
    'खंडांक',
    'खंडात्मक',
    'खंडाधीश',
    'खंडाली',
    'खंडित',
    'खंडी',
    'खंडीकरण',
    'खंडीय',
    'खंडीयता',
    'खंता',
    'खंती',
    'खंदक्र',
    'खंबा',
    'खंभा',
    'खंभे',
    'खअ',
    'खक्खट',
    'खखर',
    'खखरा',
    'खग',
    'खगंगा',
    'खगनाथ',
    'खगराज',
    'खगवती',
    'खगवन',
    'खगांतक',
    'खगारण्',
    'खगालय',
    'खगाश्रय',
    'खगासन',
    'खगिनी',
    'खगी',
    'खगेंद्र',
    'खगोल',
    'खगोलज्ञ',
    'खगोलीय',
    'खग्रास',
    'खच',
    'खचन',
    'खचना',
    'खचमस',
    'खचर',
    'खचा',
    'खचाखच',
    'खचाना',
    'खचावट',
    'खचित',
    'खचेड़',
    'खच्चर',
    'खजका',
    'खजल',
    'खजहता',
    'खज़ाँची',
    'खजा',
    'खजांची',
    'खजाना',
    'खजुरहट',
    'खजुरहटी',
    'खजुराही',
    'खजूर',
    'खजोहरा',
    'खज्योति',
    'खट',
    'खटकता',
    'खटकना',
    'खटकरम',
    'खटका',
    'खटकाना',
    'खटकेदार',
    'खटक्किका',
    'खटखट',
    'खटखटा',
    'खटखटाना',
    'खटखटाने',
    'खटखटाहट',
    'खटखदक',
    'खटना',
    'खटपट',
    'खटपटिया',
    'खटपाटी',
    'खटमल',
    'खटर',
    'खटराग',
    'खटला',
    'खटवाटी',
    'खटवारी',
    'खटाई',
    'खटाक',
    'खटाका',
    'खटाना',
    'खटारा',
    'खटास',
    'खटिका',
    'खटिया',
    'खटीक',
    'खटोलना',
    'खटोला',
    'खट्टर',
    'खट्टा',
    'खट्टापन',
    'खट्टिक',
    'खट्टिका',
    'खट्टे',
    'खट्टेपन',
    'खट्मिठापन',
    'खट्या',
    'खडराग',
    'खड़ंजा',
    'खड़कना',
    'खड़का',
    'खड़काना',
    'खड़कू',
    'खड़खड़',
    'खड़खड़ाना',
    'खड़खड़ाहट',
    'खड़खड़िया',
    'खड़गपुर',
    'खड़ताल',
    'खड़न',
    'खड़ा',
    'खड़ाऊँ',
    'खड़ापन',
    'खड़िका',
    'खड़िया',
    'खड़ी',
    'खड़े',
    'खडीय',
    'खड्ग',
    'खड्गकोश',
    'खड्गधारी',
    'खड्गधेनु',
    'खड्गमुष्टि',
    'खड्गी',
    'खड्ड',
    'खड्डी',
    'खण्ड',
    'खण्डक',
    'खण्डन',
    'खण्डनात्मक',
    'खण्डनीय',
    'खण्डल',
    'खण्डित',
    'खत',
    'खतरनाक',
    'खतरा',
    'खतरे',
    'खतरों',
    'खता',
    'खतिया',
    'खतियाना',
    'खतियौनी',
    'खतिलक',
    'खतौनी',
    'खत्ता',
    'खत्ती',
    'खत्म',
    'खदखदाहट',
    'खदबदाव',
    'खदबदाहट',
    'खदमद',
    'खदान',
    'खदानकार',
    'खदिर',
    'खदीजा',
    'खदेड़',
    'खदेड़ना',
    'खदेड़ा',
    'खद्दर',
    'खद्य',
    'खद्यानाबंदी',
    'खद्योत',
    'खन',
    'खनक',
    'खनकपूर्ण',
    'खनखन',
    'खनखनाहट',
    'खनन',
    'खनना',
    'खनि',
    'खनिक',
    'खनिज',
    'खनिजमय',
    'खनिजविज्ञानी',
    'खनिजी',
    'खनिजीकृत',
    'खनिजीय',
    'खनिजों',
    'खनित',
    'खनिता',
    'खनितृ',
    'खनित्र',
    'खनिन्न',
    'खनियाना',
    'खन्दक',
    'खन्ना',
    'खपच',
    'खपचा',
    'खपची',
    'खपच्ची',
    'खपटा',
    'खपटी',
    'खपड़ा',
    'खपड़ी',
    'खपड़ीदार',
    'खपड़े',
    'खपड़ोई',
    'खपत',
    'खपतवाले',
    'खपन',
    'खपना',
    'खपरट',
    'खपरा',
    'खपरिया',
    'खपरी',
    'खपरैल',
    'खपा',
    'खपाई',
    'खपाऊ',
    'खपाच',
    'खपाची',
    'खपाना',
    'खपाया',
    'खपिंड',
    'खप्पर',
    'खफ़ा',
    'खबर',
    'खबरदार',
    'खबरूआ',
    'खबरें',
    'खब्बा',
    'खब्बापन',
    'खब्बे',
    'खमंडल',
    'खमण',
    'खमध्य',
    'खमली',
    'खमार्ग',
    'खमीर',
    'खमीरा',
    'खमीस',
    'खम्भा',
    'खम्माच',
    'खयाली',
    'खर',
    'खरंजा',
    'खरक',
    'खरका',
    'खरखराते',
    'खरखराहट',
    'खरगोश',
    'खरगोशों',
    'खरचना',
    'खरज',
    'खरपच्ची',
    'खरपात',
    'खरपाल',
    'खरब',
    'खरबिरई',
    'खरबूज़ा',
    'खरबूजा',
    'खरल',
    'खरशबद',
    'खरसैला',
    'खरहरा',
    'खरहा',
    'खरही',
    'खरा',
    'खराद',
    'खरादना',
    'खरादिया',
    'खरापन',
    'खराब',
    'खराबी',
    'खरारि',
    'खरालिक',
    'खराश',
    'खरिया',
    'खरी',
    'खरीक',
    'खरीता',
    'खरीद',
    'खरीदखोरी',
    'खरीदना',
    'खरीदने',
    'खरीदनेवाले',
    'खरीदा',
    'खरीदार',
    'खरीदारी',
    'खरीदी',
    'खरीदे',
    'खरीदें',
    'खरीफ',
    'खरीफ़',
    'खरू',
    'खरेरा',
    'खरोंच',
    'खरोंचना',
    'खरोंट',
    'खरोंटना',
    'खरोश',
    'खरोष्ठी',
    'खरौंच',
    'खरौंचकर',
    'खरौंचना',
    'खर्च',
    'खर्चा',
    'खर्चीला',
    'खर्चे',
    'खर्चो',
    'खर्जु',
    'खर्जुर',
    'खर्पट',
    'खर्पर',
    'खर्परण',
    'खर्परिका',
    'खर्र',
    'खर्रा',
    'खर्राट',
    'खर्राटा',
    'खर्व',
    'खर्वट',
    'खल',
    'खलता',
    'खलधान्य',
    'खलना',
    'खलनायक',
    'खलनायिका',
    'खलबल',
    'खलबलाना',
    'खलबली',
    'खलभल',
    'खलभली',
    'खलमूर्ति',
    'खलाख्यान',
    'खलाचार',
    'खलास',
    'खलासी',
    'खलियान',
    'खलिहान',
    'खलिहानोत्सव',
    'खली',
    'खलीफाः',
    'खलु',
    'खलूरिका',
    'खलूरी',
    'खल्ल',
    'खल्लीट',
    'खल्वाट',
    'खवा',
    'खवाना',
    'खवारि',
    'खविद',
    'खविद्या',
    'खविर्ता',
    'खशा',
    'खशी',
    'खस',
    'खसका',
    'खसखस',
    'खसखसस',
    'खसखसी',
    'खसख़ाना',
    'खसरा',
    'खसियाना',
    'खसियाया',
    'खसूसियत',
    'खसोट',
    'खसोटना',
    'खसोटने',
    'खसोटा',
    'खसोटिया',
    'खसोटू',
    'खस्ता',
    'खस्ताहाल',
    'खस्वस्तिक',
    'खस्सी',
    'ख़ंदक़',
    'ख़ंदक्र',
    'ख़ज़ाना',
    'ख़ज़ाने',
    'ख़जाना',
    'ख़ड़ा',
    'ख़ड़ी',
    'ख़त',
    'ख़तना',
    'ख़तम',
    'ख़तरना',
    'ख़तरनाक',
    'ख़तरनाक़',
    'ख़तरा',
    'ख़तराः',
    'ख़तरे',
    'ख़ता',
    'ख़तावार',
    'ख़ती',
    'ख़तो',
    'ख़त्म',
    'ख़त्मा',
    'ख़दशा',
    'ख़दीजा',
    'ख़नगी',
    'ख़नाए',
    'ख़फ़कफ़न',
    'ख़फ़गी',
    'ख़फ़ा',
    'ख़फ़ीफ़',
    'ख़बर',
    'ख़बरदार',
    'ख़बरदारी',
    'ख़बररसाँ',
    'ख़बीस',
    'ख़ब्त',
    'ख़ब्तीपन',
    'ख़म',
    'ख़मियज़ा',
    'ख़मीर',
    'ख़मीरवाला',
    'ख़यानत',
    'ख़याल',
    'ख़याली',
    'ख़याले',
    'ख़य्याम',
    'ख़र',
    'ख़रगोश',
    'ख़रबूजा',
    'ख़राजत',
    'ख़राद',
    'ख़रादा',
    'ख़रादिया',
    'ख़रादी',
    'ख़राब',
    'ख़राबा',
    'ख़राबाती',
    'ख़राबी',
    'ख़राश',
    'ख़रीता',
    'ख़रीद',
    'ख़रीददार',
    'ख़रीददारी',
    'ख़रीदना',
    'ख़रीदने',
    'ख़रीदार',
    'ख़रीदारी',
    'ख़रीदी',
    'ख़रीफ़',
    'ख़र्च',
    'ख़र्चना',
    'ख़र्चा',
    'ख़र्ची',
    'ख़र्चीला',
    'ख़र्राच',
    'ख़र्रात',
    'ख़र्राती',
    'ख़र्राद',
    'ख़लल',
    'ख़ला',
    'ख़लास',
    'ख़लिफा',
    'ख़लिश',
    'ख़लीज',
    'ख़लीफ़तुल',
    'ख़लीफ़ा',
    'ख़ल्',
    'ख़ल्क',
    'ख़ल्तमल्त',
    'ख़वास',
    'ख़स',
    'ख़सम',
    'ख़समपीटी',
    'ख़सरा',
    'ख़सलत',
    'ख़सलतन',
    'ख़सिया',
    'ख़सी',
    'ख़सीपन',
    'ख़सीस',
    'ख़स्ता',
    'ख़स्सी',
    'ख़ाँ',
    'ख़ाँग',
    'ख़ाक',
    'ख़ाकदान',
    'ख़ाकसार',
    'ख़ाक़ान',
    'ख़ाका',
    'ख़ाकी',
    'ख़ागीना',
    'ख़ातिर',
    'ख़ातिरदारी',
    'ख़ातून',
    'ख़ात्मा',
    'ख़ादिम',
    'ख़ादिमा',
    'ख़ान',
    'ख़ानक़ाह',
    'ख़ानख़ानाँ',
    'ख़ानगाह',
    'ख़ानज़ादा',
    'ख़ानदान',
    'ख़ानदानः',
    'ख़ानदानियत',
    'ख़ानदानी',
    'ख़ानदानीपन',
    'ख़ानसामाँ',
    'ख़ाना',
    'ख़ानाआबाद',
    'ख़ानाख़राब',
    'ख़ानाजंगी',
    'ख़ानादार',
    'ख़ानादारी',
    'ख़ानाबदोश',
    'ख़ानाबदोशी',
    'ख़ाम',
    'ख़ार',
    'ख़ारा',
    'ख़ारिश',
    'ख़ाल',
    'ख़ालसा',
    'ख़ाला',
    'ख़ालाः',
    'ख़ालाजाद',
    'ख़ालिक',
    'ख़ालिस',
    'ख़ाली',
    'ख़ालीपन',
    'ख़ालू',
    'ख़ाविंद',
    'ख़ास',
    'ख़ासकर',
    'ख़ासा',
    'ख़ासियत',
    'ख़ासी',
    'ख़ासीद्घ',
    'ख़िजाँ',
    'ख़िजां',
    'ख़िज्र',
    'ख़िताब',
    'ख़ित्ता',
    'ख़िदमत',
    'ख़िदमतगार',
    'ख़िदमतगारनी',
    'ख़िदमतगारी',
    'ख़िदमतगुज़ार',
    'ख़िदमती',
    'ख़िरमन',
    'ख़िराज',
    'ख़िराजगुज़ार',
    'ख़िराम',
    'ख़िरामाँ',
    'ख़िलअत',
    'ख़िलवत',
    'ख़िलाफ',
    'ख़िलाफत',
    'ख़िलाफी',
    'ख़िल्त',
    'ख़िश्त',
    'ख़ूँख्वार',
    'ख़ून',
    'ख़ूनख़राबा',
    'ख़ूनी',
    'ख़ूब',
    'ख़ूबसूरत',
    'ख़ूबसूरती',
    'ख़ूबानी',
    'ख़ूराक',
    'ख़ूलंजान',
    'ख़ॅंख़ार',
    'ख़ैर',
    'ख़ैरख़्वाह',
    'ख़ैरख़्वाही',
    'ख़ैरबाद',
    'ख़ैरबादी',
    'ख़ैरात',
    'ख़ैराती',
    'ख़ैरियत',
    'ख़ोगीर',
    'ख़ोमचा',
    'ख़ोर',
    'ख़ोल',
    'ख़ोली',
    'ख़ोशा',
    'ख़ौफज़द',
    'ख़ौफज़दगी',
    'ख़ौफ़',
    'ख़्जूर',
    'ख़्यान',
    'ख़्याल',
    'ख़्वाजा',
    'ख़्वाजासरा',
    'ख़्वान',
    'ख़्वानचा',
    'ख़्वानी',
    'ख़्वाब',
    'ख़्वाबगाह',
    'ख़्वाह',
    'ख़्वाहमख़्वाह',
    'ख़्वाहिश',
    'ख़्वाहिशमंद',
    'ख़्वाहिशी',
    'खा',
    'खाँ',
    'खाँगी',
    'खाँच',
    'खाँचना',
    'खाँचा',
    'खाँची',
    'खाँचेदार',
    'खाँट',
    'खाँड़ा',
    'खाँडा',
    'खाँप',
    'खाँस',
    'खाँसी',
    'खांच',
    'खांचा',
    'खांचायुक्त',
    'खांची',
    'खांचेदार',
    'खांडव',
    'खांसकर',
    'खांसना',
    'खांसने',
    'खांसी',
    'खाअ',
    'खाइयों',
    'खाई',
    'खाईदार',
    'खाऊ',
    'खाऊपन',
    'खाऊपीर',
    'खाए',
    'खाकर',
    'खाका',
    'खाकी',
    'खाज',
    'खाजि',
    'खाट',
    'खाटः',
    'खाटा',
    'खाड़ा',
    'खाड़ी',
    'खाडी',
    'खात',
    'खातक',
    'खाता',
    'खाति',
    'खातिका',
    'खाती',
    'खाते',
    'खातेदार',
    'खातेवाले',
    'खातों',
    'खाद',
    'खादक',
    'खादन',
    'खादर',
    'खादित',
    'खादिता',
    'खादी',
    'खाद्य',
    'खाद्यता',
    'खाद्यान्न',
    'खाद्यान्नों',
    'खाद्येतर',
    'खाद्योज',
    'खाद्योत्पादक',
    'खान',
    'खानदान',
    'खानपान',
    'खानपुर',
    'खानम',
    'खाना',
    'खानाबदोश',
    'खानाबदोशी',
    'खानि',
    'खानिक',
    'खानिल',
    'खाने',
    'खानें',
    'खानेवाला',
    'खाबड़',
    'खामोश',
    'खामोशी',
    'खामोशीपूर्वक',
    'खाया',
    'खार',
    'खारा',
    'खारापन',
    'खाराब',
    'खारिज',
    'खारिजी',
    'खारिश',
    'खारी',
    'खारीपन',
    'खाल',
    'खालित्य',
    'खाली',
    'खालीपन',
    'खाव',
    'खावाँ',
    'खास',
    'खासकर',
    'खासदान',
    'खासा',
    'खासी',
    'खाज़',
    'खिंकिर',
    'खिंच',
    'खिंचत',
    'खिंचन',
    'खिंचनशील',
    'खिंचनशीलता',
    'खिंचना',
    'खिंचनाः',
    'खिंचवा',
    'खिंचा',
    'खिंचाई',
    'खिंचाव',
    'खिंचावट',
    'खिंचावदार',
    'खिंचाहट',
    'खिंचिया',
    'खिंची',
    'खिंचैया',
    'खिंडन',
    'खिंडना',
    'खिंडाऊ',
    'खिंडाना',
    'खिंडाव',
    'खिखिंद',
    'खिचखिच',
    'खिचड़न',
    'खिचड़ा',
    'खिचड़ी',
    'खिचाव',
    'खिजड़ना',
    'खिजलाना',
    'खिजाना',
    'खिझाना',
    'खिटखिट',
    'खिड़कियों',
    'खिड़की',
    'खिडाव',
    'खिदमती',
    'खिन',
    'खिन्न',
    'खिन्नता',
    'खिन्नहृदय',
    'खिल',
    'खिलंदड़ा',
    'खिलंदड़ापन',
    'खिलंदड़ी',
    'खिलखिल',
    'खिलखिलाता',
    'खिलखिलाना',
    'खिलखिलाहट',
    'खिलन',
    'खिलना',
    'खिलवाड़',
    'खिला',
    'खिलाई',
    'खिलाकर',
    'खिलाड़ियों',
    'खिलाड़ी',
    'खिलाडी',
    'खिलान',
    'खिलाना',
    'खिलापन',
    'खिलाफ',
    'खिलाफी',
    'खिलाफ़',
    'खिलाफ़ी',
    'खिली',
    'खिलौना',
    'खिलौने',
    'खिल्ली',
    'खिल्लीबाज़',
    'खिवाई',
    'खिवाया',
    'खिसक',
    'खिसकता',
    'खिसकन',
    'खिसकना',
    'खिसका',
    'खिसकाना',
    'खिसकाव',
    'खिसखिस',
    'खिसखिसाहट',
    'खिसलना',
    'खिसलाहट',
    'खिसियाना',
    'खिसियाया',
    'खिसियाहट',
    'खींच',
    'खींचकर',
    'खींचतान',
    'खींचतानी',
    'खींचनहान',
    'खींचना',
    'खींचने',
    'खींचा',
    'खींचाखींची',
    'खींची',
    'खींचू',
    'खीचना',
    'खीज',
    'खीजना',
    'खीझ',
    'खीझना',
    'खीर',
    'खीरा',
    'खीरी',
    'खील',
    'खीला',
    'खीष्ट',
    'खीस',
    'खीसा',
    'खुँसना',
    'खुंखार',
    'खुंखारपन',
    'खुंदक',
    'खुंबी',
    'खुंभी',
    'खुंस',
    'खुआई',
    'खुक्ख',
    'खुखड़ी',
    'खुख़ड़ी',
    'खुचा',
    'खुची',
    'खुजल',
    'खुजलन',
    'खुजलाना',
    'खुजलाहट',
    'खुजली',
    'खुटक',
    'खुटकना',
    'खुटखुट',
    'खुटखुटाहट',
    'खुट्टल',
    'खुट्टलपन',
    'खुट्टी',
    'खुड्डी',
    'खुतबा',
    'खुत्थ',
    'खुत्थी',
    'खुद',
    'खुदइख्तियारी',
    'खुदकाश्त',
    'खुदनुमाई',
    'खुदपरस्त',
    'खुदपसंद',
    'खुदबीनी',
    'खुदबुदाना',
    'खुदमुख्तार',
    'खुदमुख्तारी',
    'खुदरा',
    'खुदराय',
    'खुदरौ',
    'खुदा',
    'खुदाई',
    'खुदादाद',
    'खुदापरस्त',
    'खुदापरस्ती',
    'खुदाबंदी',
    'खुदाय',
    'खुदावंद',
    'खुदावंदगार',
    'खुदी',
    'खुदैया',
    'खुद्दार',
    'खुद्दारी',
    'खुनकदार',
    'खुनकी',
    'खुनसना',
    'खुनसी',
    'खुन्नस',
    'खुफ़िया',
    'खुफिया',
    'खुबन',
    'खुबना',
    'खुबा',
    'खुबाना',
    'खुमरा',
    'खुमार',
    'खुमी',
    'खुर',
    'खुरंट',
    'खुरंड',
    'खुरखुरा',
    'खुरच',
    'खुरचन',
    'खुरचना',
    'खुरचनी',
    'खुरचा',
    'खुरचाई',
    'खुरजा',
    'खुरजी',
    'खुरत्राण',
    'खुरदरापन',
    'खुरदुरा',
    'खुरदुरापन',
    'खुरदुरे',
    'खुरन्यास',
    'खुरपा',
    'खुरपी',
    'खुरमा',
    'खुरम्प',
    'खुरली',
    'खुरहर',
    'खुरा',
    'खुराई',
    'खुराक',
    'खुराकबंदी',
    'खुराकी',
    'खुराना',
    'खुराफ़ात',
    'खुराफ़ाती',
    'खुराफात',
    'खुरिया',
    'खुरी',
    'खुरॉट',
    'खुर्द',
    'खुर्दबीन',
    'खुर्दबुर्द',
    'खुर्दा',
    'खुर्रम',
    'खुर्राट',
    'खुर्राटा',
    'खुल',
    'खुलता',
    'खुलन',
    'खुलना',
    'खुलने',
    'खुलवाई',
    'खुला',
    'खुलाई',
    'खुलाख़र्च',
    'खुलापन',
    'खुलावट',
    'खुलासा',
    'खुलाहोना',
    'खुली',
    'खुले',
    'खुल्लम',
    'खुल्लमखुला',
    'खुल्ला',
    'खुश',
    'खुशइंतिज़ामी',
    'खुशक़िस्मत',
    'खुशक़िस्मती',
    'खुशख़त',
    'खुशख़बरी',
    'खुशख़ू',
    'खुशगवार',
    'खुशदिल',
    'खुशनसीब',
    'खुशनसीबी',
    'खुशनुमा',
    'खुशनुमाई',
    'खुशबयान',
    'खुशबु',
    'खुशबुदार',
    'खुशबू',
    'खुशबूदार',
    'खुशमिज़ाज',
    'खुशरंग',
    'खुशहाल',
    'खुशहाली',
    'खुशामद',
    'खुशामदी',
    'खुशामदीद',
    'खुशी',
    'खुश्क',
    'खुश्कसाली',
    'खुश्का',
    'खुश्की',
    'खुश्याँ',
    'खुसिया',
    'खुसुर',
    'खुसूसियत',
    'खुसूसी',
    'खुही',
    'खूँट',
    'खूँटना',
    'खूँटा',
    'खूँटी',
    'खूँथी',
    'खूँदन',
    'खूँदना',
    'खूँरेज़',
    'खूँरेज़ी',
    'खूंटा',
    'खूंटी',
    'खूंदना',
    'खूझा',
    'खूटना',
    'खूड',
    'खूड़',
    'खून',
    'खूनी',
    'खूब',
    'खूबानी',
    'खूबी',
    'खूसट',
    'खृष्टीय',
    'खेखसा',
    'खेचर',
    'खेचरान्न',
    'खेचरी',
    'खेट',
    'खेटक',
    'खेटकी',
    'खेटा',
    'खेटितान',
    'खेटी',
    'खेड़ा',
    'खेड़ापति',
    'खेड़ी',
    'खेढ़ा',
    'खेत',
    'खेतः',
    'खेतिहर',
    'खेती',
    'खेतीबाड़ी',
    'खेतीहर',
    'खेते',
    'खेतों',
    'खेद',
    'खेदजनक',
    'खेदसूचक',
    'खेदा',
    'खेदित',
    'खेदी',
    'खेना',
    'खेप',
    'खेपना',
    'खेमा',
    'खेमाबंद',
    'खेमाबंदी',
    'खेमों',
    'खेल',
    'खेलकर',
    'खेलकूद',
    'खेलघर',
    'खेलते',
    'खेलना',
    'खेलनी',
    'खेलने',
    'खेला',
    'खेलाड़ी',
    'खेलाना',
    'खेले',
    'खेलों',
    'खेवक',
    'खेवट',
    'खेवटाई',
    'खेवटिया',
    'खेवन',
    'खेवनहान',
    'खेवनहारा',
    'खेवा',
    'खेवाई',
    'खेवैया',
    'खेस',
    'खेसर',
    'खेसारी',
    'खेह',
    'खेज़',
    'खैर',
    'खैरा',
    'खैरात',
    'खैराती',
    'खैलर',
    'खो',
    'खों',
    'खोंच',
    'खोंचना',
    'खोंचा',
    'खोंटना',
    'खोंडर',
    'खोंड़ा',
    'खोंतल',
    'खोंता',
    'खोंप',
    'खोंपना',
    'खोंपी',
    'खोंसनी',
    'खोइया',
    'खोई',
    'खोऊ',
    'खोकर',
    'खोखर',
    'खोखल',
    'खोखला',
    'खोखलापन',
    'खोखली',
    'खोखले',
    'खोखा',
    'खोखी',
    'खोज',
    'खोजकर',
    'खोजकर्ता',
    'खोजता',
    'खोजनहान',
    'खोजना',
    'खोजने',
    'खोजबीन',
    'खोजयात्रा',
    'खोजा',
    'खोजी',
    'खोट',
    'खोटयुक्त',
    'खोटहीन',
    'खोटा',
    'खोटाई',
    'खोटापन',
    'खोटी',
    'खोड',
    'खोड़',
    'खोड़र',
    'खोड़रा',
    'खोड़िया',
    'खोता',
    'खोद',
    'खोदकर',
    'खोदना',
    'खोदनाः',
    'खोदनी',
    'खोदने',
    'खोदपूछ',
    'खोदा',
    'खोदी',
    'खोन',
    'खोनचा',
    'खोनचे',
    'खोना',
    'खोने',
    'खोपड़ा',
    'खोपड़ी',
    'खोपरा',
    'खोपा',
    'खोब',
    'खोभार',
    'खोमचे',
    'खोमचेवाला',
    'खोया',
    'खोयापन',
    'खोये',
    'खोर',
    'खोरा',
    'खोरिया',
    'खोल',
    'खोलक',
    'खोलन',
    'खोलना',
    'खोलने',
    'खोलनेवाला',
    'खोला',
    'खोलि',
    'खोली',
    'खोले',
    'खोलें',
    'खोल्क',
    'खोसना',
    'खोह',
    'खोही',
    'खौ',
    'खौलना',
    'ख्गोलविद',
    'ख्गोलिकी',
    'ख्गोलीय',
    'ख्त्रज्ञालिसा',
    'ख्यात',
    'ख्यातनाम',
    'ख्याति',
    'ख्यातिकर',
    'ख्यातित',
    'ख्यातिप्रद',
    'ख्यातिहीनता',
    'ख्यापक',
    'ख्याल',
    'ख्याली',
    'ख्र्जूरिका',
    'ख्वानपोश',
    'ख्वाहिंस',
    'खज़ाना',
    'खज़ाने',
    'खफ़ा',
    'ग',
    'गँजिया',
    'गँजेड़ी',
    'गँठकटा',
    'गँठजोड़',
    'गँठना',
    'गँठबंधन',
    'गँठा',
    'गँठाई',
    'गँठापन',
    'गँठाव',
    'गँठीला',
    'गँठीलापन',
    'गँडतरा',
    'गँड़ासां',
    'गँडासा',
    'गँडूर',
    'गँवई',
    'गँवना',
    'गँवर',
    'गँवा',
    'गँवाई',
    'गँवाऊ',
    'गँवाना',
    'गँवार',
    'गँवारन',
    'गँवारपन',
    'गँवारा',
    'गँवाराना',
    'गँवारिन',
    'गँवारू',
    'गँवारूपन',
    'गँसा',
    'गँसीद्घ',
    'गँसीला',
    'गंग',
    'गंगटिटी',
    'गंगटोक',
    'गंगबरार',
    'गंगा',
    'गंगांबु',
    'गंगाचिल्ली',
    'गंगाजल',
    'गंगाजली',
    'गंगादत्त',
    'गंगाधर',
    'गंगानगर',
    'गंगापुत्र',
    'गंगामुख',
    'गंगाराम',
    'गंगाल',
    'गंगासुत',
    'गंगो',
    'गंगोदक',
    'गंगोल',
    'गंज',
    'गंजबख़्श',
    'गंजा',
    'गंजाजिका',
    'गंजापन',
    'गंजिका',
    'गंजी',
    'गंजीफ़ा',
    'गंजू',
    'गंटक',
    'गंठाई',
    'गंड',
    'गंडक',
    'गंडकः',
    'गंडग्राम',
    'गंडस्थल',
    'गंडा',
    'गंडाश्म',
    'गंतव्य',
    'गंतव्यप्राप्त',
    'गंता',
    'गंतु',
    'गंतुक',
    'गंत्रिका',
    'गंत्री',
    'गंथन',
    'गंथी',
    'गंद',
    'गंदगी',
    'गंदला',
    'गंदा',
    'गंदापन',
    'गंदी',
    'गंध',
    'गंधक',
    'गंधकाश्म',
    'गंधकुटी',
    'गंधधारी',
    'गंधन',
    'गंधमय',
    'गंधमाद',
    'गंधमादन',
    'गंधमादनी',
    'गंधमापी',
    'गंधयुक्त',
    'गंधयुक्ति',
    'गंधराज',
    'गंधराजी',
    'गंधराल',
    'गंधर्व',
    'गंधर्वः',
    'गंधर्वी',
    'गंधर्वीय',
    'गंधलेना',
    'गंधवती',
    'गंधवाह',
    'गंधविभ्रांति',
    'गंधविर्न',
    'गंधव्य',
    'गंधहीन',
    'गंधा',
    'गंधाखु',
    'गंधाजीव',
    'गंधाना',
    'गंधाली',
    'गंधालु',
    'गंधाव',
    'गंधित',
    'गंधी',
    'गंधेंद्रिय',
    'गंभीर',
    'गंभीरचित्त',
    'गंभीरता',
    'गंरंथक',
    'गंरथि',
    'गंवा',
    'गंवाना',
    'गंवाने',
    'गंवार',
    'गंवारू',
    'गई',
    'गउशाला',
    'गऊ',
    'गए',
    'गगन',
    'गगनगत',
    'गगनचर',
    'गगनचुंबी',
    'गगनधूलि',
    'गगनभेदी',
    'गगनस्पर्शी',
    'गगनांगना',
    'गगनांबु',
    'गगनीय',
    'गगरा',
    'गगरिया',
    'गगरी',
    'गच्छ',
    'गछना',
    'गज',
    'गजंद',
    'गजंदा',
    'गजः',
    'गजक',
    'गजकर्ण',
    'गजकाय',
    'गजकायता',
    'गजगति',
    'गजगामिता',
    'गजट',
    'गजदंत',
    'गजदन्त',
    'गजधंटा',
    'गजधर',
    'गजन',
    'गजनक',
    'गजनासा',
    'गजपति',
    'गजपाल',
    'गजपुट',
    'गजप्रिया',
    'गजबाँक',
    'गजबेली',
    'गजमुख',
    'गजर',
    'गजल',
    'गजवदन',
    'गजवान',
    'गजशाला',
    'गजशीश',
    'गज़',
    'गज़क',
    'गज़ट',
    'गज़ब',
    'गज़रबान',
    'गज़ा',
    'गज़ारिशनामा',
    'गज़ी',
    'गज़ैटिड',
    'गजा',
    'गजाधर',
    'गजानन',
    'गजारि',
    'गजारोह',
    'गजारोही',
    'गजाशन',
    'गजास्थित',
    'गजिनी',
    'गजिर्त',
    'गजी',
    'गजेंद्र',
    'गजैटिड',
    'गटई',
    'गटक',
    'गटकन',
    'गटकना',
    'गटका',
    'गटकू',
    'गटना',
    'गटहीनता',
    'गटागट',
    'गट्टा',
    'गट्टी',
    'गट्ठर',
    'गट्ठा',
    'गट्ठी',
    'गठजोड़',
    'गठन',
    'गठनहीन',
    'गठबंधति',
    'गठबंधन',
    'गठबंधनहीन',
    'गठबंधनहीनता',
    'गठरी',
    'गठाव',
    'गठित',
    'गठिया',
    'गठिला',
    'गठीला',
    'गठ्ठा',
    'गड',
    'गडंग',
    'गडः',
    'गडःंत',
    'गडगडाहट',
    'गडमड',
    'गड़',
    'गड़ंत',
    'गड़गज',
    'गड़गड़',
    'गड़गड़ा',
    'गड़गड़ाते',
    'गड़गड़ाना',
    'गड़गड़ाहट',
    'गड़गड़ी',
    'गड़गूदड़',
    'गड़न',
    'गड़ना',
    'गड़प',
    'गड़पना',
    'गड़प्पा',
    'गड़बड़',
    'गड़बड़ा',
    'गड़बड़ाना',
    'गड़बड़िया',
    'गड़बड़ी',
    'गड़बड़ीवाले',
    'गड़बारा',
    'गड़रिया',
    'गड़वाँत',
    'गड़वी',
    'गड़हा',
    'गड़ही',
    'गड़ा',
    'गड़ाई',
    'गड़ाना',
    'गड़ापन',
    'गड़ारी',
    'गड़ारीदार',
    'गड़ासा',
    'गड़िया',
    'गड़ियार',
    'गड़ी',
    'गड़ु',
    'गड़ुआ',
    'गड़ुई',
    'गड़ुलना',
    'गड़ूलना',
    'गड़ूली',
    'गड़े',
    'गड़ेर',
    'गड़ेरिन',
    'गड़ेरिया',
    'गडोलना',
    'गड्ड',
    'गड्डम',
    'गड्डमड्ड',
    'गड्डर',
    'गड्डरिका',
    'गड्डा',
    'गड्डिलका',
    'गड्डी',
    'गड्ढा',
    'गड्ढे',
    'गड्ढेदार',
    'गढत',
    'गढन',
    'गढना',
    'गढ़',
    'गढ़ंत',
    'गढ़न',
    'गढ़ना',
    'गढ़नीय',
    'गढ़नीयता',
    'गढ़ने',
    'गढ़पति',
    'गढ़पाल',
    'गढ़ा',
    'गढ़ाई',
    'गढ़ियां',
    'गढ़ी',
    'गढ़ीय',
    'गढ़ैया',
    'गढै',
    'गण',
    'गणः',
    'गणक',
    'गणकार',
    'गणकी',
    'गणगौर',
    'गणतंत्र',
    'गणतंत्रवाद',
    'गणतंत्रात्तक',
    'गणतंत्रात्मक',
    'गणतंत्रीय',
    'गणतांत्रिक',
    'गणदेव',
    'गणदेवता',
    'गणधर',
    'गणधिपता',
    'गणधिपति',
    'गणन',
    'गणनकर्ता',
    'गणना',
    'गणनांक',
    'गणनाः',
    'गणनाकर्ता',
    'गणनाथ',
    'गणनायक',
    'गणनीय',
    'गणपति',
    'गणपत्य',
    'गणपूर्ति',
    'गणमान्य',
    'गणमान्यता',
    'गणराज्य',
    'गणवधु',
    'गणवासी',
    'गणवेश',
    'गणाचल',
    'गणात्र',
    'गणादूत',
    'गणाधिपं',
    'गणाध्यक्ष',
    'गणाभ्यास',
    'गणिका',
    'गणित',
    'गणितकार',
    'गणितज्ञ',
    'गणितिक',
    'गणितीय',
    'गणित्र',
    'गणीकरण',
    'गणीय',
    'गणेरुक',
    'गणेरुका',
    'गणेश',
    'गणेशः',
    'गणेशाय',
    'गणेशीय',
    'गणेश्वर',
    'गण्डमाला',
    'गण्डस्थल',
    'गण्डूकपर्णी',
    'गण्य',
    'गण्यता',
    'गत',
    'गतकल्मष',
    'गतका',
    'गतकाल',
    'गतकालिक',
    'गतक्लांति',
    'गतचेतन',
    'गतता',
    'गतप्राण',
    'गतभ्रम',
    'गतमास',
    'गतयौवन',
    'गतरजा',
    'गतलज्ज',
    'गतवयस्क',
    'गतवर्ष',
    'गतवैभव',
    'गतसंग',
    'गतायु',
    'गतार्तवा',
    'गतावधिक',
    'गति',
    'गतिः',
    'गतिक',
    'गतिकता',
    'गतिकी',
    'गतिकीय',
    'गतिज',
    'गतित',
    'गतिपाल',
    'गतिपूर्ण',
    'गतिभ्रंश',
    'गतिमान',
    'गतिमापी',
    'गतिया',
    'गतियुक्तता',
    'गतिरूद्घ',
    'गतिरोध',
    'गतिरोधी',
    'गतिवर्धन',
    'गतिवाद',
    'गतिवान',
    'गतिविज्ञान',
    'गतिविणिपूर्ण',
    'गतिविधि',
    'गतिविधियां',
    'गतिविधियों',
    'गतिविधिहीन',
    'गतिविधिहीनता',
    'गतिविषयक',
    'गतिशील',
    'गतिशीलता',
    'गतिहरण',
    'गतिहर्ता',
    'गतिहीन',
    'गतिहीनता',
    'गतिहीनताः',
    'गतिहृत',
    'गत्चरक',
    'गत्चरण',
    'गत्ता',
    'गत्ताल',
    'गत्यचरण',
    'गत्यवरूद्घ',
    'गत्यवरोध',
    'गत्यात्मक',
    'गत्वर',
    'गत्वरण',
    'गत्वरता',
    'गत्वरित',
    'गत्वरित्र',
    'गदका',
    'गदजदंड',
    'गददी',
    'गदना',
    'गदम',
    'गदर',
    'गदरा',
    'गदराना',
    'गदराया',
    'गदह',
    'गदहा',
    'गदही',
    'गदा',
    'गदांतक',
    'गदांबर',
    'गदागद',
    'गदाग्रज',
    'गदाधर',
    'गदाधारी',
    'गदाल',
    'गदाला',
    'गदाली',
    'गदी',
    'गदीला',
    'गदीलापन',
    'गदेला',
    'गदेली',
    'गद्गद',
    'गद्गदता',
    'गद्गद्ता',
    'गद्द',
    'गद्दर',
    'गद्दा',
    'गद्दार',
    'गद्दारी',
    'गद्दी',
    'गद्दीदार',
    'गद्दीधारी',
    'गद्दीनशीन',
    'गद्दीनशीनी',
    'गद्देदार',
    'गद्य',
    'गद्यकार',
    'गद्यात्मक',
    'गधा',
    'गधाः',
    'गधी',
    'गधे',
    'गन',
    'गनगोर',
    'गनगौर',
    'गनबेल',
    'गनबोट',
    'गनमैन',
    'गनर्नर्स',
    'गनल',
    'गनवाला',
    'गनवेल',
    'गनी',
    'गनेल',
    'गन्दगीयुक्त',
    'गन्दना',
    'गन्दा',
    'गन्दापना',
    'गन्दे',
    'गन्ध',
    'गन्धरस',
    'गन्धविज्ञान',
    'गन्धविहीन',
    'गन्ने',
    'गप',
    'गपकना',
    'गपड़',
    'गपबाज़',
    'गपबाज़ी',
    'गपबाजी',
    'गपशप',
    'गपाड़ा',
    'गपाड़िया',
    'गपिया',
    'गपोड़',
    'गपोड़ा',
    'गपोड़िया',
    'गप्प',
    'गप्पी',
    'गफ',
    'गफपन',
    'गफ़',
    'गफ्फा',
    'गब',
    'गबंद',
    'गबन',
    'गबनकर्ता',
    'गबरा',
    'गबरु',
    'गबाबी',
    'गब्बर',
    'गभ',
    'गभस्ति',
    'गभुआर',
    'गभ्रितता',
    'गम',
    'गमक',
    'गमकता',
    'गमकना',
    'गमगम',
    'गमगमाहट',
    'गमटी',
    'गमत',
    'गमथ',
    'गमन',
    'गमनना',
    'गमनशील',
    'गमना',
    'गमनागमन',
    'गमनीय',
    'गममन',
    'गमम्य',
    'गमर',
    'गमला',
    'गमाड़िया',
    'गमिर्णी',
    'गम्भीर',
    'गम्भीरता',
    'गम्भीरतापूर्वक',
    'गम्य',
    'गम्यता',
    'गय',
    'गया',
    'गयाः',
    'गयापन',
    'गयाराम',
    'गयी',
    'गयीं',
    'गये',
    'गर',
    'गरंड',
    'गरगज',
    'गरगराहट',
    'गरघ्न',
    'गरज',
    'गरजता',
    'गरजना',
    'गरजा',
    'गरदन',
    'गरदनः',
    'गरदनी',
    'गरदान',
    'गरदिनयाँ',
    'गरनाल',
    'गरब',
    'गरबा',
    'गरम',
    'गरमजोशी',
    'गरमागरम',
    'गरमागरमी',
    'गरमाने',
    'गरमाहट',
    'गरमी',
    'गरराना',
    'गरल',
    'गरलधर',
    'गरवा',
    'गरांडील',
    'गराज',
    'गराड़ी',
    'गराड़ीः',
    'गरानी',
    'गरामी',
    'गरारी',
    'गरारे',
    'गरिमा',
    'गरिमापूर्ण',
    'गरिमाशाली',
    'गरिमाहीन',
    'गरियाना',
    'गरियार',
    'गरियाल',
    'गरिष्ठ',
    'गरी',
    'गरीब',
    'गरीबान',
    'गरीबी',
    'गरीबों',
    'गरुड',
    'गरुड़',
    'गरुड़ांक',
    'गरुड़ाकार',
    'गरुड़ाग्रज',
    'गरुड़ाश्मा',
    'गरुत',
    'गरुत्',
    'गरुत्मान',
    'गरूड',
    'गरूड़',
    'गरेबान',
    'गरेली',
    'गरौं',
    'गर्ग',
    'गर्गर',
    'गर्गरी',
    'गर्जन',
    'गर्जनशील',
    'गर्जना',
    'गर्जर',
    'गर्जरिक',
    'गर्टर',
    'गर्डर',
    'गर्त',
    'गर्तकी',
    'गर्तमुख',
    'गर्तिका',
    'गर्द',
    'गर्दखोर',
    'गर्दन',
    'गर्दभ',
    'गर्दभवहिनी',
    'गर्दभश्व',
    'गर्दभी',
    'गर्दाना',
    'गर्दालू',
    'गर्दालूद',
    'गर्दिश',
    'गर्दिशे',
    'गर्दी',
    'गर्ध',
    'गर्बी',
    'गर्बीली',
    'गर्भ',
    'गर्भक्षय',
    'गर्भगृह',
    'गर्भघात',
    'गर्भघातक',
    'गर्भघाती',
    'गर्भच्युति',
    'गर्भज',
    'गर्भधारण',
    'गर्भध्वंस',
    'गर्भनिरोधक',
    'गर्भपात',
    'गर्भमोक्ष',
    'गर्भयुक्तता',
    'गर्भवती',
    'गर्भवतीत्व',
    'गर्भवध',
    'गर्भवास',
    'गर्भसंधि',
    'गर्भस्थ',
    'गर्भस्थता',
    'गर्भस्थापन',
    'गर्भस्राव',
    'गर्भस्रावक',
    'गर्भहता',
    'गर्भागार',
    'गर्भाड',
    'गर्भाणु',
    'गर्भादत्ता',
    'गर्भाधान',
    'गर्भार्वस्था',
    'गर्भावधि',
    'गर्भावस्था',
    'गर्भावास',
    'गर्भाशय',
    'गर्भाशयोच्छेदन',
    'गर्भाश्य',
    'गर्भिणी',
    'गर्भित',
    'गर्म',
    'गर्मजोशी',
    'गर्मी',
    'गर्रा',
    'गर्व',
    'गर्वनर',
    'गर्वरी',
    'गर्वहीन',
    'गर्वी',
    'गर्वीला',
    'गर्वीलेपन',
    'गर्वोक्ति',
    'गर्हण',
    'गर्हणीय',
    'गर्हा',
    'गर्हित',
    'गल',
    'गलंतिका',
    'गलंती',
    'गलई',
    'गलक',
    'गलकंबल',
    'गलगंज',
    'गलगंजना',
    'गलगलाना',
    'गलगलिया',
    'गलगाज',
    'गलगाजना',
    'गलगाजी',
    'गलगुच्छा',
    'गलग्रंथि',
    'गलचाप',
    'गलत',
    'गलतंस',
    'गलतकिया',
    'गलतफहमी',
    'गलतियां',
    'गलतियों',
    'गलती',
    'गलतफ़हमी',
    'गलथैली',
    'गलन',
    'गलना',
    'गलनां',
    'गलनांक',
    'गलफड़ा',
    'गलफड़ी',
    'गलफाँसी',
    'गलफू',
    'गलफूट',
    'गलबल',
    'गलबलाना',
    'गलबलिया',
    'गलबाँही',
    'गलमांस',
    'गलमाला',
    'गलमुच्छा',
    'गलशुंडी',
    'गलस्तन',
    'गलस्तनी',
    'गलहस्त',
    'गलहार',
    'गलही',
    'गला',
    'गलाः',
    'गलाई',
    'गलाना',
    'गलाया',
    'गलावट',
    'गलित',
    'गलियारा',
    'गली',
    'गलीचा',
    'गलीज़पन',
    'गलीलिया',
    'गले',
    'गलेबा',
    'गलेबाज़',
    'गलेबाजी',
    'गलौंछ',
    'गलौआ',
    'गल्प',
    'गल्भ',
    'गल्लम',
    'गल्ला',
    'गल्लाबान',
    'गव',
    'गवक',
    'गवघप',
    'गवन',
    'गवनवा',
    'गवना',
    'गवनिरंग',
    'गवनिरग',
    'गवनैंस',
    'गवय',
    'गवरनर',
    'गवर्नमैंन्टी',
    'गवर्नर',
    'गवाक्ष',
    'गवाचना',
    'गवादनी',
    'गवार',
    'गवारा',
    'गवाशन',
    'गवाह',
    'गवाही',
    'गविनी',
    'गविर्णी',
    'गविर्त',
    'गविर्ता',
    'गविष्ठ',
    'गविष्ठि',
    'गवीश',
    'गवेरूक',
    'गवेषक',
    'गवेषण',
    'गवेषणा',
    'गवेषित',
    'गवेषी',
    'गवेष्ठि',
    'गवैया',
    'गवोर्क्ति',
    'गवोर्नमता',
    'गवोर्न्मत',
    'गव्य',
    'गव्या',
    'गव्यु',
    'गव्यूति',
    'गश',
    'गश्त',
    'गश्तवाला',
    'गश्ती',
    'गश्तीकार',
    'गसीला',
    'गस्सा',
    'गहगड्डा',
    'गहगह',
    'गहगहा',
    'गहगहे',
    'गहन',
    'गहनतः',
    'गहनता',
    'गहना',
    'गहनी',
    'गहने',
    'गहनों',
    'गहमागहमी',
    'गहरा',
    'गहराई',
    'गहराना',
    'गहरापन',
    'गहराव',
    'गहरी',
    'गहरे',
    'गहरेबाज़ी',
    'गहवारा',
    'गहा',
    'गहाई',
    'गहागड्ड',
    'गहागह',
    'गहाना',
    'गहाया',
    'गहाशक्तिशाली',
    'गहृय',
    'गहेला',
    'गह्वर',
    'गह्वरता',
    'ग़ज',
    'ग़ज़ब',
    'ग़ज़ल',
    'ग़ज़लगो',
    'ग़ज़ाला',
    'ग़दर',
    'ग़नी',
    'ग़नीम',
    'ग़नीमत',
    'ग़फ़लत',
    'ग़बन',
    'ग़म',
    'ग़मख़ार',
    'ग़मखा',
    'ग़मग़ीन',
    'ग़मग़ीनी',
    'ग़मगुसार',
    'ग़मजदा',
    'ग़मज़दगी',
    'ग़मनाक',
    'ग़मी',
    'ग़रक़',
    'ग़रक़ी',
    'ग़रजमंद',
    'ग़रज़',
    'ग़रजे',
    'ग़रारा',
    'ग़रीब',
    'ग़र्क',
    'ग़र्ज',
    'ग़लत',
    'ग़लतःचिह्न',
    'ग़लतकार',
    'ग़लतकारी',
    'ग़लतगोई',
    'ग़लतनी',
    'ग़लती',
    'ग़लथन',
    'ग़लथना',
    'ग़लथैली',
    'ग़लद्वार',
    'ग़लन',
    'ग़लबा',
    'ग़लीज़',
    'ग़ल्ला',
    'ग़ल्लामंडी',
    'ग़ाज़ियाबाद',
    'ग़ाज़ी',
    'ग़ायब',
    'ग़ारत',
    'ग़ालिब',
    'ग़ालिबन',
    'ग़ैर',
    'ग़ैरआबादी',
    'ग़ैरकमाऊ',
    'ग़ैरज़रुरी',
    'ग़ैरजानकार',
    'ग़ैरजिम्मदवार',
    'ग़ैरजिम्मदारी',
    'ग़ैरजिम्मेदार',
    'ग़ैरजिस्मानी',
    'ग़ैरत',
    'ग़ैरतनीकी',
    'ग़ैरतमंद',
    'ग़ैरदनियावी',
    'ग़ैरपन',
    'ग़ैरपुश्तैनी',
    'ग़ैरमनकला',
    'ग़ैरमनकूला',
    'ग़ैरमाममूली',
    'ग़ैरमियादी',
    'ग़ैरमुमकिन',
    'ग़ैरमुल्की',
    'ग़ैरमुश्तरका',
    'ग़ैरमुस्तक़िल',
    'ग़ैरमौजूद',
    'ग़ैरमौजूदगी',
    'ग़ैरमौरुसी',
    'ग़ैरयूरोपीय',
    'ग़ैरसिख',
    'ग़ैरहाजि',
    'ग़ोता',
    'ग़ोताखोरी',
    'ग़ोर',
    'ग़ोल',
    'ग़ौर',
    'गा',
    'गाँ',
    'गाँज',
    'गाँजना',
    'गाँजा',
    'गाँठ',
    'गाँठगँठीला',
    'गाँठगँठीलापन',
    'गाँठदार',
    'गाँठना',
    'गाँठबंद',
    'गाँठबनाई',
    'गाँडा',
    'गाँथना',
    'गाँव',
    'गाँवः',
    'गाँवड़ा',
    'गाँस',
    'गाँसना',
    'गाँसी',
    'गांगी',
    'गांगेय',
    'गांगेयी',
    'गांजा',
    'गांठ',
    'गांठगोभी',
    'गांठदार',
    'गांठना',
    'गांडीव',
    'गांडीवधारी',
    'गांत्री',
    'गांदिनी',
    'गांधर्व',
    'गांधवीं',
    'गांधार',
    'गांधारी',
    'गांधारीः',
    'गांधारेय',
    'गांधी',
    'गांधीधाम',
    'गांधीनगर',
    'गांधीवाद',
    'गांभीर्य',
    'गांरटित',
    'गांरटी',
    'गांरटीकृत',
    'गांव',
    'गांवों',
    'गाइड',
    'गाइनैकोलाजिस्ट',
    'गाउन',
    'गाऊधप',
    'गाकंग',
    'गाकंडा',
    'गाकंती',
    'गागर',
    'गागरिया',
    'गागरी',
    'गाछ',
    'गाछी',
    'गाज',
    'गाजना',
    'गाजर',
    'गाजि',
    'गाझिन',
    'गाटर',
    'गाटा',
    'गाड',
    'गाड़',
    'गाड़ना',
    'गाड़नाः',
    'गाड़ा',
    'गाड़ियों',
    'गाड़ी',
    'गाड़ीः',
    'गाड़ीख़ाना',
    'गाड़ीघर',
    'गाड़ीवान',
    'गाडियों',
    'गाडी',
    'गाडीः',
    'गाड्र',
    'गाढ़ता',
    'गाढ़ा',
    'गाढ़ापन',
    'गाढ़ी',
    'गाढ़े',
    'गाढा',
    'गाणपत',
    'गाणपत्य',
    'गाणितिक',
    'गाणेश',
    'गात',
    'गाता',
    'गातागति',
    'गाती',
    'गात्र',
    'गात्रवान',
    'गात्रावरण',
    'गात्रोपघात',
    'गाथ',
    'गाथा',
    'गाथाकार',
    'गाथागीत',
    'गाथिक',
    'गाद',
    'गादड़',
    'गादर',
    'गादी',
    'गादुर',
    'गाद्रव',
    'गाध',
    'गाधा',
    'गाधिसुत',
    'गाधेय',
    'गाध्य',
    'गाध्यता',
    'गान',
    'गानः',
    'गाना',
    'गाने',
    'गानेवाला',
    'गानोकोलाजी',
    'गाभ',
    'गाभा',
    'गाभिन',
    'गाम',
    'गामज़न',
    'गामा',
    'गामिनी',
    'गामी',
    'गाय',
    'गायः',
    'गायक',
    'गायकवाड़',
    'गायकी',
    'गायखोर',
    'गायघर',
    'गायत्री',
    'गायत्रीः',
    'गायन',
    'गायब',
    'गायम',
    'गाया',
    'गायिका',
    'गायित',
    'गायें',
    'गारंटित',
    'गारंटियां',
    'गारंटी',
    'गारंटीकर्ता',
    'गारंटीकृत',
    'गारंटीयुक्त',
    'गारद',
    'गारा',
    'गारूड़ी',
    'गार्गी',
    'गार्ड',
    'गार्डन',
    'गार्नेट',
    'गार्बेज',
    'गार्ह',
    'गार्हस्थ',
    'गार्हस्थ्य',
    'गार्हिक',
    'गाल',
    'गालः',
    'गालःतकिया',
    'गालव',
    'गाला',
    'गाली',
    'गालीचा',
    'गालों',
    'गाव',
    'गावकुशी',
    'गावखुर्द',
    'गावदुम',
    'गावल्गणि',
    'गावी',
    'गाह',
    'गाहक',
    'गाहन',
    'गाहना',
    'गाहनी',
    'गाही',
    'गाहृर्य',
    'गाहे',
    'गि',
    'गिंठू',
    'गिंडुरी',
    'गिंदुक',
    'गिग',
    'गिचपिच',
    'गिजगिजा',
    'गिटकरी',
    'गिटकिरी',
    'गिटार',
    'गिट्ट',
    'गिट्टक',
    'गिट्टी',
    'गिट्टीदार',
    'गिड़गिड़ाना',
    'गिड़गिड़ाहट',
    'गिड़गिडाना',
    'गिद्घ',
    'गिद्घराज',
    'गिद्दा',
    'गिनकर',
    'गिनत',
    'गिनतारा',
    'गिनती',
    'गिनन',
    'गिनना',
    'गिनने',
    'गिना',
    'गिनाई',
    'गिनाना',
    'गिनी',
    'गिने',
    'गिन्नी',
    'गिफ्ट',
    'गियर',
    'गिर',
    'गिरंत',
    'गिरगिट',
    'गिरजा',
    'गिरजाघर',
    'गिरजादार',
    'गिरजाधिकारी',
    'गिरजे',
    'गिरता',
    'गिरती',
    'गिरते',
    'गिरदा',
    'गिरदान',
    'गिरदावर',
    'गिरन',
    'गिरना',
    'गिरनार',
    'गिरने',
    'गिरफ्त',
    'गिरफ्तार',
    'गिरफ्तारी',
    'गिरमिटिया',
    'गिरवी',
    'गिरवीकार',
    'गिरवीग्राही',
    'गिरवीदार',
    'गिरवीनामा',
    'गिरस्ती',
    'गिरह',
    'गिरहः',
    'गिरहकट',
    'गिरहकटी',
    'गिरहदार',
    'गिरहबाज़',
    'गिरा',
    'गिराई',
    'गिराऊ',
    'गिराना',
    'गिराने',
    'गिरापडा',
    'गिरापति',
    'गिरापन',
    'गिरामी',
    'गिराया',
    'गिरावट',
    'गिरि',
    'गिरिका',
    'गिरिकूट',
    'गिरिचर',
    'गिरिज',
    'गिरिजन',
    'गिरिजा',
    'गिरिजाघरों',
    'गिरिजाल',
    'गिरिताल',
    'गिरिधर',
    'गिरिधारी',
    'गिरिध्वज',
    'गिरिपद',
    'गिरिपाद',
    'गिरिपाश्र्व',
    'गिरिपृष्ठ',
    'गिरिप्रस्थ',
    'गिरिप्रिया',
    'गिरिभेदी',
    'गिरिराज',
    'गिरिवर',
    'गिरिवर्तिका',
    'गिरिशृंग',
    'गिरिसार',
    'गिरिसुत',
    'गिरी',
    'गिरींद्र',
    'गिरीदार',
    'गिरीश',
    'गिरे',
    'गिरेवा',
    'गिरोकर',
    'गिरोह',
    'गिरोहबंद',
    'गिरोहबंदी',
    'गिरोहबाज़',
    'गिर्द',
    'गिर्दबाद',
    'गिर्दा',
    'गिर्दावर',
    'गिरफ़्तार',
    'गिरफ़्तारी',
    'गिल',
    'गिलकार',
    'गिलकारी',
    'गिलगिला',
    'गिलगिलापन',
    'गिलट',
    'गिलटकारी',
    'गिलटी',
    'गिलन',
    'गिलबिलाना',
    'गिलम',
    'गिलरी',
    'गिलसिया',
    'गिलहरी',
    'गिला',
    'गिलाफ',
    'गिलाफ़',
    'गिलाव',
    'गिलावा',
    'गिलास',
    'गिलासी',
    'गिलिम',
    'गिलौंदा',
    'गिलौरी',
    'गिलौरीदान',
    'गिल्टी',
    'गिल्ली',
    'गिष्णु',
    'गी',
    'गीजर',
    'गीत',
    'गीतंजली',
    'गीतःआरंभिक',
    'गीतक',
    'गीतकार',
    'गीतमोदी',
    'गीता',
    'गीतात्मक',
    'गीतावली',
    'गीति',
    'गीतिका',
    'गीतिकाव्य',
    'गीतिनाट्य',
    'गीतिमय',
    'गीतोपदेशक',
    'गीदडःबोली',
    'गीदड़',
    'गीदड़ी',
    'गीध',
    'गीयर',
    'गीर',
    'गीरथ',
    'गीरी',
    'गीर्ण',
    'गीर्देवी',
    'गीर्पति',
    'गीर्वाण',
    'गीर्वी',
    'गीला',
    'गीलापन',
    'गीली',
    'गीशा',
    'गीष्पति',
    'गुँजाना',
    'गुँथा',
    'गुँथाई',
    'गुँथापन',
    'गुँधन',
    'गुँधा',
    'गुँधाःपिंड',
    'गुँधाई',
    'गुँधावट',
    'गुँधी',
    'गुँधे',
    'गुँफा',
    'गुँफाई',
    'गुँफित',
    'गुंज',
    'गुंजक',
    'गुंजन',
    'गुंजनशील',
    'गुंजनशीलता',
    'गुंजल्क',
    'गुंजा',
    'गुंजाः',
    'गुंजाइश',
    'गुंजान',
    'गुंजायमान',
    'गुंजार',
    'गुंजारना',
    'गुंजित',
    'गुंठन',
    'गुंठित',
    'गुंडई',
    'गुंडली',
    'गुंडा',
    'गुंडागर्द',
    'गुंडागर्दी',
    'गुंडापन',
    'गुंडी',
    'गुंडेपन',
    'गुंथन',
    'गुंधी',
    'गुंफ',
    'गुंफन',
    'गुंबज',
    'गुंबद',
    'गुंबदिया',
    'गुंबदी',
    'गुंभी',
    'गुंषा',
    'गुआर',
    'गुआरि',
    'गुइयाँ',
    'गुग्गुल',
    'गुचछ',
    'गुच्ची',
    'गुच्छ',
    'गुच्छक',
    'गुच्छन',
    'गुच्छा',
    'गुच्छार्थ',
    'गुच्छित',
    'गुच्छी',
    'गुच्छे',
    'गुच्छों',
    'गुजर',
    'गुजरना',
    'गुजरने',
    'गुजरात',
    'गुजराती',
    'गुज़र',
    'गुज़रगाह',
    'गुज़रता',
    'गुज़रते',
    'गुज़रना',
    'गुज़रनामा',
    'गुज़रा',
    'गुज़रापन',
    'गुज़ारा',
    'गुज़ारिश',
    'गुजांइश',
    'गुजारा',
    'गुजारी',
    'गुज्झा',
    'गुझरोट',
    'गुझरौट',
    'गुझिया',
    'गुझोट',
    'गुट',
    'गुटकन',
    'गुटकना',
    'गुटका',
    'गुटनिरपेक्ष',
    'गुटनिरपेक्षता',
    'गुटबंद',
    'गुटबंदी',
    'गुटबंदीबाज',
    'गुटबन्दी',
    'गुटबाज़',
    'गुटबाजी',
    'गुटबाज़',
    'गुटवाद',
    'गुटहीन',
    'गुटिका',
    'गुटी',
    'गुटों',
    'गुट्टा',
    'गुट्टी',
    'गुट्ठल',
    'गुट्ठा',
    'गुठला',
    'गुठली',
    'गुठलीः',
    'गुठलीदार',
    'गुड',
    'गुडः',
    'गुडक',
    'गुडरु',
    'गुडवा',
    'गुडविल',
    'गुडहर',
    'गुडहल',
    'गुड़',
    'गुड़गंज',
    'गुड़गांव',
    'गुड़गुड',
    'गुड़गुड़ी',
    'गुड़धाना',
    'गुड़धानी',
    'गुड़हर',
    'गुड़हल',
    'गुड़ाई',
    'गुड़ाका',
    'गुड़ाकेश',
    'गुड़िया',
    'गुड़ी',
    'गुडालू',
    'गुडी',
    'गुड्डा',
    'गुड्डी',
    'गुड्स',
    'गुढ़',
    'गुण',
    'गुणक',
    'गुणकार',
    'गुणक्रम',
    'गुणगान',
    'गुणग्राम',
    'गुणग्राहक',
    'गुणज',
    'गुणज्ञ',
    'गुणज्ञता',
    'गुणण्य',
    'गुणता',
    'गुणदोष',
    'गुणन',
    'गुणनांक',
    'गुणनाशन',
    'गुणनिका',
    'गुणनिधि',
    'गुणयुक्त',
    'गुणयुक्त्त',
    'गुणयुक्त्तता',
    'गुणराशि',
    'गुणवंत',
    'गुणवत्ता',
    'गुणवत्तायुक्त',
    'गुणवत्ताहीनता',
    'गुणवाचक',
    'गुणवान',
    'गुणवानों',
    'गुणवाला',
    'गुणशाली',
    'गुणसंपन्न',
    'गुणसम्पन्न',
    'गुणसूत्रीय',
    'गुणसूत्रीयता',
    'गुणसोम',
    'गुणहीन',
    'गुणहीनता',
    'गुणा',
    'गुणांक',
    'गुणांकन',
    'गुणाकर',
    'गुणाढय',
    'गुणातीतः',
    'गुणातीतता',
    'गुणात्मक',
    'गुणानुसार',
    'गुणारोपण',
    'गुणार्थक',
    'गुणालय',
    'गुणावकर्ष',
    'गुणित',
    'गुणी',
    'गुणीय',
    'गुणों',
    'गुणोत्कृष्टता',
    'गुणोत्तम',
    'गुणोत्तर',
    'गुणोम',
    'गुण्डा',
    'गुण्डागर्दी',
    'गुण्डापन',
    'गुण्डे',
    'गुण्य',
    'गुण्यांक',
    'गुत',
    'गुत्थमगुत्था',
    'गुत्थमगुत्थी',
    'गुत्थी',
    'गुत्थीहीन',
    'गुत्थीहीनता',
    'गुत्स',
    'गुथना',
    'गुथा',
    'गुदकारा',
    'गुदगुदा',
    'गुदगुदाहट',
    'गुदगुदी',
    'गुदड़ा',
    'गुदड़िया',
    'गुदड़ी',
    'गुदडी',
    'गुदना',
    'गुदा',
    'गुदांकुर',
    'गुदाः',
    'गुदाई',
    'गुदाज़',
    'गुदार',
    'गुदार्बुद',
    'गुदावस्ति',
    'गुदुम',
    'गुद्दी',
    'गुन',
    'गुनगाहक',
    'गुनगुन',
    'गुनगुना',
    'गुनगुनाना',
    'गुनगुनाने',
    'गुनगुनाहट',
    'गुनगौरी',
    'गुनन',
    'गुनना',
    'गुनहगार',
    'गुना',
    'गुनाह',
    'गुनाहगार',
    'गुनाहगारी',
    'गुनिया',
    'गुनी',
    'गुन्डा',
    'गुपचुप',
    'गुप्त',
    'गुप्तक',
    'गुप्तचर',
    'गुप्तचरी',
    'गुप्तचर्या',
    'गुप्तचर्याः',
    'गुप्ततः',
    'गुप्तता',
    'गुप्तमार्ग',
    'गुप्तवास',
    'गुप्तवासी',
    'गुप्ता',
    'गुप्तांग',
    'गुप्तास्त्र',
    'गुप्ति',
    'गुप्ती',
    'गुप्ते',
    'गुप्तोपासना',
    'गुफा',
    'गुफ्',
    'गुफ्तगु',
    'गुफ्तार',
    'गुफ्फा',
    'गुफ्फेदार',
    'गुबरैला',
    'गुबार',
    'गुबारा',
    'गुब्बारा',
    'गुब्बारे',
    'गुब्बारेबाज़',
    'गुब्बारों',
    'गुम',
    'गुमटा',
    'गुमटी',
    'गुमटेदार',
    'गुमदा',
    'गुमना',
    'गुमनाम',
    'गुमनामी',
    'गुमराह',
    'गुमराही',
    'गुमशुदा',
    'गुमसुम',
    'गुमान',
    'गुमानश्ता',
    'गुमाश्ता',
    'गुमुटी',
    'गुम्बद',
    'गुम्बददार',
    'गुम्मट',
    'गुम्मा',
    'गुर',
    'गुरगा',
    'गुरगाबी',
    'गुरदयाल',
    'गुरदा',
    'गुरदेव',
    'गुरद्वारा',
    'गुरपर्व',
    'गुरबत',
    'गुरबा',
    'गुरबानी',
    'गुरसी',
    'गुरहना',
    'गुराइन',
    'गुराउ',
    'गुराब',
    'गुरिंदा',
    'गुरिया',
    'गुरिल्ला',
    'गुरु',
    'गुरुआनी',
    'गुरुकुल',
    'गुरुग्रंथ',
    'गुरुजन',
    'गुरुत्व',
    'गुरुत्वाकर्षण',
    'गुरुदीक्षा',
    'गुरुद्वारा',
    'गुरुद्वाराः',
    'गुरुपन',
    'गुरुभाई',
    'गुरुमंतर',
    'गुरुमंत्र',
    'गुरुमह',
    'गुरुमुख',
    'गुरुयुग्मक',
    'गुरुवाद',
    'गुरुवार',
    'गुरू',
    'गुरूआइन',
    'गुरूआई',
    'गुरूआनी',
    'गुरूकुल',
    'गुरूगंरंथ',
    'गुरूतम',
    'गुरूतर',
    'गुरूता',
    'गुरूत्व',
    'गुरूब',
    'गुरूभाई',
    'गुरूमंत्र',
    'गुरूर',
    'गुरूहीन',
    'गुर्ज',
    'गुर्जर',
    'गुर्दा',
    'गुर्दाकार',
    'गुर्रा',
    'गुर्राना',
    'गुर्राने',
    'गुर्राहट',
    'गुर्वी',
    'गुल',
    'गुलँदा',
    'गुलअंदाम',
    'गुलकंद',
    'गुलकट',
    'गुलकार',
    'गुलकारी',
    'गुलखन',
    'गुलख़ैरू',
    'गुलगपाड़ा',
    'गुलगश्त',
    'गुलगुला',
    'गुलगुली',
    'गुलगोथना',
    'गुलचीं',
    'गुलचीन',
    'गुलचीनी',
    'गुलछर्रा',
    'गुलछर्रे',
    'गुलज़ार',
    'गुलझट',
    'गुलझटी',
    'गुलझड़ी',
    'गुलतराश',
    'गुलता',
    'गुलदस्ता',
    'गुलदान',
    'गुलदाना',
    'गुलदार',
    'गुलनार',
    'गुलफ़ाम',
    'गुलबन्द',
    'गुलबूटा',
    'गुलमा',
    'गुलमे',
    'गुलमेंहदी',
    'गुलरंग',
    'गुलशन',
    'गुलसितौ',
    'गुलाँट',
    'गुलाटी',
    'गुलाब',
    'गुलाबजल',
    'गुलाबदानी',
    'गुलाबी',
    'गुलाबीपन',
    'गुलाम',
    'गुलामी',
    'गुलाल',
    'गुलिसताँ',
    'गुलीबन्द',
    'गुलुकंद',
    'गुलुबंद',
    'गुलू',
    'गुलूला',
    'गुलेल',
    'गुलेलन',
    'गुलेला',
    'गुलेलारोहण',
    'गुलेली',
    'गुलौँट',
    'गुलौर',
    'गुलौरा',
    'गुल्फ',
    'गुल्फास्थि',
    'गुल्म',
    'गुल्मवन',
    'गुल्लक',
    'गुल्ला',
    'गुल्ली',
    'गुवाहाटी',
    'गुविर्णी',
    'गुसल',
    'गुसाइँ',
    'गुस्ताख',
    'गुस्ताख़',
    'गुस्ताख़ी',
    'गुस्ताखाना',
    'गुस्ताखी',
    'गुस्लख़ाना',
    'गुस्सा',
    'गुस्से',
    'गुस्सैल',
    'गुह',
    'गुहराज',
    'गुहा',
    'गुहार',
    'गुहारना',
    'गुहारी',
    'गुहावासी',
    'गुहेर',
    'गुहेरी',
    'गुह्न',
    'गुह्म',
    'गुह्मक',
    'गुह्मपुष्प',
    'गुह्मयता',
    'गुह्मेश्वर',
    'गुह्य',
    'गुह्यक',
    'गुह्यलेख',
    'गुह्राचार',
    'गुह्रातंत्र',
    'गुज़रना',
    'गुज़ारा',
    'गुफ़ा',
    'गूँगा',
    'गूँगापन',
    'गूँजदार',
    'गूँजना',
    'गूँथ',
    'गूँथन',
    'गूँथना',
    'गूँध',
    'गूँधना',
    'गूंगा',
    'गूंगापन',
    'गूंज',
    'गूंजना',
    'गूंथकर',
    'गूंथना',
    'गूंथा',
    'गूंधना',
    'गूंधा',
    'गूगल',
    'गूजरी',
    'गूढ',
    'गूढ़',
    'गूढ़ंग',
    'गूढ़चर',
    'गूढ़चिन्तन',
    'गूढ़तः',
    'गूढ़ता',
    'गूढ़ार्थ',
    'गूढ़ोक्ति',
    'गूढ़ोपाय',
    'गूण',
    'गूथ',
    'गूथना',
    'गूथा',
    'गूद',
    'गूदड़',
    'गूदा',
    'गूदापन',
    'गूदे',
    'गूदेदार',
    'गूद्देदार',
    'गून',
    'गूनवे',
    'गूमड़',
    'गूलर',
    'गूहन',
    'गृंजन',
    'गृद्घ',
    'गृध',
    'गृधकूट',
    'गृधराज',
    'गृध्या',
    'गृह',
    'गृहकर्म',
    'गृहकार्य',
    'गृहगोधा',
    'गृहज',
    'गृहजन',
    'गृहत्याग',
    'गृहत्यागी',
    'गृहदाह',
    'गृहदीप्ति',
    'गृहपति',
    'गृहपत्नी',
    'गृहप्रिय',
    'गृहभेद',
    'गृहमाता',
    'गृहमुख',
    'गृहमृग',
    'गृहमोचिका',
    'गृहयुद्घ',
    'गृहलक्ष्मी',
    'गृहवधु',
    'गृहवाटिका',
    'गृहसज्जाकार',
    'गृहस्थ',
    'गृहस्थाम्रम',
    'गृहस्थाम्रमप्रवेश',
    'गृहस्थिन',
    'गृहस्थी',
    'गृहस्थीयुक्त',
    'गृहस्वामिनी',
    'गृहस्वामी',
    'गृहहीन',
    'गृहहीनता',
    'गृहा',
    'गृहागत',
    'गृहागमन',
    'गृहातुरता',
    'गृहाभिकर्त्ता',
    'गृहाभिमानी',
    'गृहाम्रम',
    'गृहावेक्षक',
    'गृहासक्त',
    'गृहासक्ति',
    'गृहिणी',
    'गृही',
    'गृहीत',
    'गृहीतता',
    'गृहीता',
    'गृहीताओं',
    'गृहीतार्थ',
    'गृहीति',
    'गृहीद्यान',
    'गृहीय',
    'गृहों',
    'गृहोन्मुख',
    'गृह्म',
    'गॅंवाना',
    'गे',
    'गेंगटा',
    'गेंठी',
    'गेंड़ली',
    'गेंडुआ',
    'गेंडुरी',
    'गेंडुली',
    'गेंडुवा',
    'गेंद',
    'गेंदघर',
    'गेंदबाज',
    'गेंदबाजी',
    'गेंदा',
    'गेंदुक',
    'गेज',
    'गेजबो',
    'गेट',
    'गेटकीपर',
    'गेटिस',
    'गेम',
    'गेय',
    'गेर',
    'गेरना',
    'गेराँव',
    'गेरु',
    'गेरुआ',
    'गेरू',
    'गेरूआ',
    'गेलिं',
    'गेलिस',
    'गेली',
    'गेल्हा',
    'गेसू',
    'गेसूदराज्र',
    'गेह',
    'गेहनी',
    'गेही',
    'गेहुँअन',
    'गेहुवां',
    'गेहूँ',
    'गेहूँअन',
    'गैंग्रीन',
    'गैंडा',
    'गैंबलर',
    'गैगवे',
    'गैब',
    'गैबी',
    'गैया',
    'गैर',
    'गैरआबाद',
    'गैरजिम्मेदार',
    'गैरन्यायिक',
    'गैरपादरी',
    'गैरमुसलिम',
    'गैरहाजिर',
    'गैरहाजिरी',
    'गैरहाज़िरी',
    'गैरा',
    'गैरिक',
    'गैरेज',
    'गैरेय',
    'गैरज़िम्मेदारी',
    'गैल',
    'गैलन',
    'गैलरी',
    'गैलियोन',
    'गैल्वनीकरण',
    'गैल्वेनाइज़र',
    'गैस',
    'गैसलाइट',
    'गैसलीन',
    'गैसवाला',
    'गैसीय',
    'गैसीयता',
    'गैस्ट',
    'गैस्टरुम',
    'गो',
    'गोँठ',
    'गोंछ',
    'गोंड़ला',
    'गोंद',
    'गोंदी',
    'गोंदोला',
    'गोंफा',
    'गोइँठा',
    'गोइँड़ा',
    'गोइंदा',
    'गोई',
    'गोकुंजर',
    'गोकुल',
    'गोकुशी',
    'गोक्ष',
    'गोक्षीर',
    'गोक्षेत्र',
    'गोखरू',
    'गोखा',
    'गोखुरा',
    'गोग्रंथि',
    'गोग्रास',
    'गोघात',
    'गोचना',
    'गोचर',
    'गोचरजगत',
    'गोचरता',
    'गोचर्म',
    'गोचर्मवसन',
    'गोज़',
    'गोजिस्या',
    'गोजी',
    'गोझा',
    'गोट',
    'गोटा',
    'गोटाः',
    'गोटिया',
    'गोटी',
    'गोटे',
    'गोठ',
    'गोड़',
    'गोड़इत',
    'गोड़ना',
    'गोड़ा',
    'गोड़ाई',
    'गोड़ी',
    'गोडाउन',
    'गोण',
    'गोत',
    'गोतम',
    'गोतमः',
    'गोतमी',
    'गोता',
    'गोताखोर',
    'गोताखोरी',
    'गोतिया',
    'गोती',
    'गोत्र',
    'गोत्रकर्ता',
    'गोत्रकार',
    'गोत्रकारी',
    'गोत्रज',
    'गोत्रजन',
    'गोत्रता',
    'गोत्रन',
    'गोत्रपट',
    'गोत्रप्रवर',
    'गोत्रर्षि',
    'गोत्रहीन',
    'गोत्रा',
    'गोत्रिर्षि',
    'गोत्री',
    'गोत्रीय',
    'गोत्रोच्चार',
    'गोद',
    'गोदनर्शी',
    'गोदनशीनी',
    'गोदनहार',
    'गोदनहारा',
    'गोदना',
    'गोदने',
    'गोदनेवाला',
    'गोदा',
    'गोदान',
    'गोदाम',
    'गोदारूण',
    'गोदावरी',
    'गोदी',
    'गोदुग्ध',
    'गोध',
    'गोधन',
    'गोधर',
    'गोधा',
    'गोधिक',
    'गोधिका',
    'गोधुलि',
    'गोधूमक',
    'गोधूमवर्णी',
    'गोधूलि',
    'गोन',
    'गोनद',
    'गोनर',
    'गोनरखा',
    'गोनर्द',
    'गोनस',
    'गोनाथ',
    'गोनाय',
    'गोनिया',
    'गोनी',
    'गोनेड',
    'गोन्दा',
    'गोप',
    'गोपजा',
    'गोपति',
    'गोपन',
    'गोपनशील',
    'गोपनशीलता',
    'गोपनियता',
    'गोपनीय',
    'गोपनीयता',
    'गोपयिता',
    'गोपा',
    'गोपांगना',
    'गोपाल',
    'गोपालक',
    'गोपालन',
    'गोपालिका',
    'गोपाली',
    'गोपिका',
    'गोपित',
    'गोपिनी',
    'गोपी',
    'गोपीनाथ',
    'गोपीश',
    'गोपुटा',
    'गोपुत्र',
    'गोपुर',
    'गोपुरम',
    'गोपेश',
    'गोप्ता',
    'गोप्य',
    'गोप्यता',
    'गोफण',
    'गोफन',
    'गोफना',
    'गोफा',
    'गोफिया',
    'गोबर',
    'गोबरः',
    'गोबरी',
    'गोबलि',
    'गोभक्षी',
    'गोभा',
    'गोभिल',
    'गोभी',
    'गोमती',
    'गोमय',
    'गोमुख',
    'गोमुखी',
    'गोमुत्र',
    'गोमेद',
    'गोमेदक',
    'गोमेध',
    'गोयंदा',
    'गोया',
    'गोयार',
    'गोरंक',
    'गोरक्ष',
    'गोरक्षा',
    'गोरखपुर',
    'गोरखा',
    'गोरखाली',
    'गोरधंधा',
    'गोरस',
    'गोरसी',
    'गोरा',
    'गोरांधन',
    'गोराशाही',
    'गोरिल्ला',
    'गोरी',
    'गोरु',
    'गोरोच',
    'गोर्ध',
    'गोल',
    'गोलंदाज़',
    'गोलंदाज़ी',
    'गोलंबर',
    'गोलक',
    'गोलकास्थि',
    'गोलकी',
    'गोलकृमि',
    'गोलगप्पा',
    'गोलची',
    'गोलप्राय',
    'गोलमटोल',
    'गोलमटोलपन',
    'गोलमाल',
    'गोला',
    'गोलाई',
    'गोलाकार',
    'गोलाकृति',
    'गोलाधार',
    'गोलाबारी',
    'गोलाभ',
    'गोलार्ध',
    'गोलाश्मी',
    'गोलिका',
    'गोलियां',
    'गोलियों',
    'गोली',
    'गोलीकांड',
    'गोलीकाण्ड',
    'गोलीघर',
    'गोलीबारी',
    'गोले',
    'गोलोक',
    'गोल्उन',
    'गोल्ड',
    'गोल्फ',
    'गोल्फ़',
    'गोवंशः',
    'गोवत्स',
    'गोवत्सक',
    'गोवधूटी',
    'गोवर्धन',
    'गोविंद',
    'गोविंदा',
    'गोविंदाष्टमी',
    'गोविद',
    'गोविश्ठा',
    'गोवृंद',
    'गोव्रज',
    'गोशमाली',
    'गोशवारा',
    'गोशा',
    'गोशानशीनी',
    'गोशाला',
    'गोशृंग',
    'गोश्त',
    'गोश्तखा',
    'गोष्ठ',
    'गोष्ठी',
    'गोष्ठीकक्ष',
    'गोसत्र',
    'गोसर्प',
    'गोसव',
    'गोसा',
    'गोसाइँ',
    'गोस्वामी',
    'गोह',
    'गोहत्या',
    'गोहरा',
    'गोहारी',
    'गोही',
    'गौ',
    'गौख',
    'गौखा',
    'गौगल्स',
    'गौगाई',
    'गौजिग',
    'गौड',
    'गौड़',
    'गौडी',
    'गौण',
    'गौणता',
    'गौणिक',
    'गौतम',
    'गौतमी',
    'गौद',
    'गौदा',
    'गौन',
    'गौनहाई',
    'गौनहार',
    'गौनहारी',
    'गौना',
    'गौमतेश्वर',
    'गौर',
    'गौरव',
    'गौरवपूर्ण',
    'गौरवपूर्णता',
    'गौरवशाली',
    'गौरवहीन',
    'गौरवहीनता',
    'गौरवासन',
    'गौरवित',
    'गौरा',
    'गौरांग',
    'गौरांगी',
    'गौरिका',
    'गौरिल',
    'गौरिल्ला',
    'गौरी',
    'गौरीज',
    'गौरीनाथ',
    'गौरीपट्ट',
    'गौरीश',
    'गौरैया',
    'गौसपल्स',
    'गौस्पल',
    'गौहर',
    'गौहरी',
    'ग्द',
    'ग्याक',
    'ग्यान',
    'ग्यारस',
    'ग्यारह',
    'ग्यारहवां',
    'ग्रंथ',
    'ग्रंथः',
    'ग्रंथकार',
    'ग्रंथकुटी',
    'ग्रंथविज्ञान',
    'ग्रंथसूची',
    'ग्रंथसूचीकार',
    'ग्रंथागार',
    'ग्रंथावली',
    'ग्रंथि',
    'ग्रंथिक',
    'ग्रंथित',
    'ग्रंथिमूल',
    'ग्रंथियों',
    'ग्रंथिराज',
    'ग्रंथिल',
    'ग्रंथिलता',
    'ग्रंथिसम्बन्धी',
    'ग्रंथिहीन',
    'ग्रंथिहीनजा',
    'ग्रंथी',
    'ग्रथन',
    'ग्रथालय',
    'ग्रथित',
    'ग्रनाइट',
    'ग्रन्थ',
    'ग्रन्थकार',
    'ग्रन्थाकार',
    'ग्रन्थि',
    'ग्रन्थिल',
    'ग्रन्थी',
    'ग्रफ्राइट',
    'ग्ररंथ',
    'ग्ररंथनकर्ता',
    'ग्ररास',
    'ग्रसन',
    'ग्रसना',
    'ग्रसनी',
    'ग्रसनीय',
    'ग्रसनीशोथ',
    'ग्रसिका',
    'ग्रसित',
    'ग्रसिष्णु',
    'ग्रस्त',
    'ग्रस्तता',
    'ग्रस्ता',
    'ग्रस्ति',
    'ग्रह',
    'ग्रहकक्षा',
    'ग्रहगति',
    'ग्रहग्रस्त',
    'ग्रहण',
    'ग्रहणकारिता',
    'ग्रहणशील',
    'ग्रहणाधिकार',
    'ग्रहणी',
    'ग्रहदशा',
    'ग्रहदाय',
    'ग्रहदृष्टि',
    'ग्रहदोष',
    'ग्रहपति',
    'ग्रहपीड़ा',
    'ग्रहबाधा',
    'ग्रहभोग',
    'ग्रहयुद्घ',
    'ग्रहयूति',
    'ग्रहयोग',
    'ग्रहराज',
    'ग्रहवेध',
    'ग्रहशांति',
    'ग्रहाणु',
    'ग्रहाधार',
    'ग्रहाधिपति',
    'ग्रहिता',
    'ग्रहिल',
    'ग्रहीत',
    'ग्रहीता',
    'ग्रहीय',
    'ग्रांउड',
    'ग्रांट',
    'ग्रांडील',
    'ग्राउंड',
    'ग्राफ',
    'ग्राफिक्स',
    'ग्राफी',
    'ग्राम',
    'ग्रामः',
    'ग्रामकूट',
    'ग्रामणी',
    'ग्रामदेव',
    'ग्रामपति',
    'ग्रामयतापूर्ण',
    'ग्रामयाजी',
    'ग्रामवासी',
    'ग्रामशाला',
    'ग्रामसिंह',
    'ग्रामांचल',
    'ग्रामांत',
    'ग्रामाणु',
    'ग्रामाणुता',
    'ग्रामाध्यक्ष',
    'ग्रामीण',
    'ग्रामीय',
    'ग्रामेय',
    'ग्रामेयी',
    'ग्रामोद्योग',
    'ग्रामोन्मुख',
    'ग्रामोफ़ोन',
    'ग्रामोफोन',
    'ग्रामोफ़ोन',
    'ग्राम्य',
    'ग्राम्यता',
    'ग्राम्यदोष',
    'ग्राम्या',
    'ग्राम्याश्व',
    'ग्रावा',
    'ग्रास',
    'ग्रासिका',
    'ग्रासी',
    'ग्राह',
    'ग्राहक',
    'ग्राहकता',
    'ग्राहकी',
    'ग्राहकों',
    'ग्राहित्र',
    'ग्राही',
    'ग्राह्य',
    'ग्राह्यता',
    'ग्राफ़',
    'ग्राफ़ोटाइप',
    'ग्रिल',
    'ग्रिलिंग',
    'ग्रीक',
    'ग्रीज़',
    'ग्रीटिंग',
    'ग्रीन',
    'ग्रीनरुम',
    'ग्रीनविच',
    'ग्रीव',
    'ग्रीवसिथ',
    'ग्रीवा',
    'ग्रीवाः',
    'ग्रीवी',
    'ग्रीवीय',
    'ग्रीष्म',
    'ग्रीष्मकालन',
    'ग्रीष्मीय',
    'ग्रीस',
    'ग्रुप',
    'ग्रुपबाज',
    'ग्रुपहाउसिंग',
    'ग्रूस',
    'ग्रे',
    'ग्रेडर',
    'ग्रेनाइट',
    'ग्रेनेड',
    'ग्रेफ़ाइट',
    'ग्रैचुइटी',
    'ग्रैजुएट',
    'ग्रैव',
    'ग्रैवेय',
    'ग्रैवेयक',
    'ग्रैष्म',
    'ग्रौस',
    'ग्लव',
    'ग्लाइड',
    'ग्लाइडर',
    'ग्लाइडिंग',
    'ग्लानि',
    'ग्लानिपूर्ण',
    'ग्लास',
    'ग्लासवेअर',
    'ग्लूकोज',
    'ग्लूकोस',
    'ग्लेजिंग',
    'ग्लेजिग',
    'ग्लेमर',
    'ग्लेशियर',
    'ग्लैंड',
    'ग्लोब',
    'ग्लौ',
    'ग्वांला',
    'ग्वार',
    'ग्वाल',
    'ग्वाला',
    'ग्वालिन',
    'ग्वालियर',
    'ग्वाश',
    'ग्वैंठा',
    'गज़',
    'गज़ट',
    'घँघरी',
    'घँघोलना',
    'घंट',
    'घंटा',
    'घंटाः',
    'घंटाकार',
    'घंटाघर',
    'घंटाताड़',
    'घंटानाद',
    'घंटाल',
    'घंटावली',
    'घंटिका',
    'घंटियों',
    'घंटी',
    'घंटे',
    'घंटों',
    'घई',
    'घखुदा',
    'घग्घर',
    'घट',
    'घटक',
    'घटकता',
    'घटकर',
    'घटकर्ण',
    'घटकार',
    'घटकारिता',
    'घटकृत',
    'घटकों',
    'घटघटवासी',
    'घटता',
    'घटती',
    'घटते',
    'घटन',
    'घटनशील',
    'घटना',
    'घटनाएं',
    'घटनाओं',
    'घटनाचक्र',
    'घटनात्मक',
    'घटनात्मकता',
    'घटनापूर्ण',
    'घटनापूर्णता',
    'घटनाप्रधान',
    'घटनामूलक',
    'घटनावली',
    'घटनावृत',
    'घटनास्थल',
    'घटनाहीन',
    'घटनीय',
    'घटने',
    'घटनेवाला',
    'घटनोत्तर',
    'घटपर्णी',
    'घटम',
    'घटय',
    'घटयता',
    'घटवार',
    'घटवारिया',
    'घटवाल',
    'घटवालिया',
    'घटवाह',
    'घटहा',
    'घटा',
    'घटाई',
    'घटाए',
    'घटाएं',
    'घटाकर',
    'घटाटोप',
    'घटान',
    'घटाना',
    'घटाने',
    'घटाया',
    'घटायें',
    'घटाव',
    'घटिका',
    'घटित',
    'घटितता',
    'घटिया',
    'घटियापन',
    'घटी',
    'घटीः',
    'घटूका',
    'घटे',
    'घटेगी',
    'घटोतरी',
    'घटोत्कच',
    'घटोर',
    'घटौती',
    'घट्ट',
    'घट्टन',
    'घट्टा',
    'घट्टित',
    'घट्ठेदार',
    'घडना',
    'घड़कना',
    'घड़घड़',
    'घड़घड़ाना',
    'घड़घड़ाहड',
    'घड़न',
    'घड़नई',
    'घड़नैल',
    'घड़ा',
    'घड़ाई',
    'घड़ियाँ',
    'घड़ियाल',
    'घड़ियाली',
    'घड़ी',
    'घड़ीसाज़',
    'घड़ोला',
    'घड़ौंची',
    'घडा',
    'घडी',
    'घण्टा',
    'घण्टानाद',
    'घण्टे',
    'घतियाना',
    'घन',
    'घनक',
    'घनकना',
    'घनकन्द',
    'घनघटा',
    'घनघन',
    'घनघनाना',
    'घनघनाहट',
    'घनघोर',
    'घनता',
    'घनताल',
    'घनत्व',
    'घनत्वपूर्ण',
    'घनत्वमापी',
    'घनन',
    'घननाद',
    'घनपद',
    'घनप्रिय',
    'घनफल',
    'घनमितिय',
    'घनमीटर',
    'घनमूल',
    'घनवाह',
    'घनश्याम',
    'घनसार',
    'घना',
    'घनांजनी',
    'घनांत',
    'घनांधकार',
    'घनाकार',
    'घनाकारता',
    'घनाकृति',
    'घनागम',
    'घनात्वयुक्त',
    'घनाधन',
    'घनापन',
    'घनाभ',
    'घनाम्रय',
    'घनित',
    'घनिष्ठ',
    'घनिष्ठता',
    'घनी',
    'घनीभूत',
    'घनीय',
    'घने',
    'घनेतर',
    'घनेपन',
    'घनेरा',
    'घनोपल',
    'घपला',
    'घबड़ा',
    'घबड़ाया',
    'घबड़ाहट',
    'घबरा',
    'घबराकर',
    'घबराना',
    'घबराया',
    'घबराहट',
    'घमंड',
    'घमंडी',
    'घमंडीपन',
    'घमकना',
    'घमघम',
    'घमघमहाट',
    'घमघमाना',
    'घमर',
    'घमरौल',
    'घमसान',
    'घमा',
    'घमाघम',
    'घमासान',
    'घमोरी',
    'घर',
    'घरगई',
    'घरघर',
    'घरघरहाहट',
    'घरघराहट',
    'घरघाट',
    'घरजानी',
    'घरजाया',
    'घरजोत',
    'घरड़',
    'घरणानुभूति',
    'घरदा',
    'घरद्वार',
    'घरनी',
    'घरबसा',
    'घरबसी',
    'घरबार',
    'घरबारी',
    'घरबारु',
    'घरराणकर्ता',
    'घरवा',
    'घरवाली',
    'घरष्रण',
    'घराण',
    'घराणक',
    'घराणन',
    'घराणीय',
    'घराणेंद्रिय',
    'घराता',
    'घराती',
    'घराना',
    'घराने',
    'घरिया',
    'घरु',
    'घरुआ',
    'घरुई',
    'घरेलू',
    'घरेलूपन',
    'घरों',
    'घरौंदा',
    'घर्घर',
    'घर्घरा',
    'घर्घरी',
    'घर्म',
    'घर्रघर्र',
    'घर्रघूँ',
    'घर्राटा',
    'घर्ष',
    'घर्षक',
    'घर्षण',
    'घर्षणकल',
    'घर्षणहीन',
    'घर्षणी',
    'घर्षरंजित',
    'घर्षित',
    'घर्षी',
    'घलुआ',
    'घस',
    'घसियारा',
    'घसीट',
    'घसीटकर',
    'घसीटना',
    'घसीटा',
    'घसीटू',
    'घस्मर',
    'घाँघरा',
    'घाँटी',
    'घाई',
    'घाऊघप',
    'घाघरा',
    'घाघस',
    'घाघी',
    'घाट',
    'घाटः',
    'घाटवाल',
    'घाटा',
    'घाटिक',
    'घाटिया',
    'घाटी',
    'घाटे',
    'घाण',
    'घात',
    'घातः',
    'घातक',
    'घातकता',
    'घातकी',
    'घातक्रिया',
    'घाति',
    'घातिया',
    'घाती',
    'घातीय',
    'घातुक',
    'घातोन्माद',
    'घात्य',
    'घान',
    'घानी',
    'घाम',
    'घामड',
    'घायल',
    'घार',
    'घारण',
    'घारणानुभूति',
    'घारणिक',
    'घारणित',
    'घाल',
    'घालना',
    'घालमेल',
    'घाव',
    'घास',
    'घासः',
    'घासपात',
    'घासमय',
    'घासलेट',
    'घासलेटी',
    'घासविज्ञानी',
    'घिआँडा',
    'घिक्कार',
    'घिग्घी',
    'घिघिआना',
    'घिघियाना',
    'घिचपिच',
    'घिन',
    'घिनाना',
    'घिनानौता',
    'घिनावना',
    'घिनावनापन',
    'घिनावने',
    'घिनावनेपन',
    'घिनौना',
    'घिनौनापन',
    'घिनौनेपन',
    'घिन्नी',
    'घिया',
    'घिर',
    'घिरना',
    'घिरनी',
    'घिरनीः',
    'घिरा',
    'घिराई',
    'घिरापन',
    'घिरायँद',
    'घिराव',
    'घिरी',
    'घिरीं',
    'घिरींदार',
    'घिसकन',
    'घिसकर',
    'घिसघिस',
    'घिसघिसाहट',
    'घिसघिसीया',
    'घिसट',
    'घिसटते',
    'घिसटन',
    'घिसटना',
    'घिसन',
    'घिसनहार',
    'घिसना',
    'घिसा',
    'घिसाई',
    'घिसाना',
    'घिसापिटा',
    'घिसैया',
    'घिस्सा',
    'घी',
    'घीं',
    'घीया',
    'घीयाकस',
    'घुँघची',
    'घुँघटा',
    'घुँघराला',
    'घुँघरु',
    'घुँघरुबंद',
    'घुँपन',
    'घुँपा',
    'घुंघची',
    'घुंघराला',
    'घुंघरालापन',
    'घुंघराले',
    'घुंटे',
    'घुंडी',
    'घुंडीदार',
    'घुइँयाँ',
    'घुग्घी',
    'घुग्घू',
    'घुघुआना',
    'घुटटी',
    'घुटन',
    'घुटनभरा',
    'घुटना',
    'घुटनाः',
    'घुटने',
    'घुटनेवाला',
    'घुटनों',
    'घुटन्ना',
    'घुटरियों',
    'घुटरु',
    'घुटरुअन',
    'घुटरुन',
    'घुटा',
    'घुटाई',
    'घुटापन',
    'घुटी',
    'घुट्टी',
    'घुडक',
    'घुडदौड़',
    'घुड़',
    'घुड़कना',
    'घुड़की',
    'घुड़चढी',
    'घुड़दौडनी',
    'घुड़दौड़',
    'घुड़दौड़िया',
    'घुड़बहली',
    'घुड़मक्खी',
    'घुड़सवार',
    'घुड़सवारी',
    'घुड़सवारों',
    'घुड़साल',
    'घुड़सेना',
    'घुधोत्तेजक',
    'घुन',
    'घुनें',
    'घुन्ना',
    'घुप',
    'घुमंतू',
    'घुमंतूपन',
    'घुमकड',
    'घुमक्कड़',
    'घुमक्कड़ी',
    'घुमटा',
    'घुमड़ना',
    'घुमरी',
    'घुमा',
    'घुमाई',
    'घुमाता',
    'घुमाना',
    'घुमाया',
    'घुमाव',
    'घुमावदार',
    'घुमेर',
    'घुयहरी',
    'घुर',
    'घुरघुर',
    'घुरघुराना',
    'घुरघुराहट',
    'घुराना',
    'घुर्र',
    'घुल',
    'घुलकर',
    'घुलन',
    'घुलनशीलता',
    'घुलना',
    'घुलमिलापन',
    'घुला',
    'घुलाना',
    'घुलावट',
    'घुषित',
    'घुष्ट्र',
    'घुसड़न',
    'घुसड़ना',
    'घुसन',
    'घुसना',
    'घुसपैठ',
    'घुसपैठिया',
    'घुसा',
    'घुसाई',
    'घुसाना',
    'घुसेड़',
    'घुसेड़ना',
    'घूँघट',
    'घूँघटवाली',
    'घूँघर',
    'घूँघरदार',
    'घूँघरवा',
    'घूँट',
    'घूँटी',
    'घूँसा',
    'घूँसाघासी',
    'घूँसाघूसी',
    'घूँसेबाजी',
    'घूंघट',
    'घूंघर',
    'घूंघरदार',
    'घूंट',
    'घूंसा',
    'घूत',
    'घूतकर',
    'घूतकार',
    'घूतकारक',
    'घूतकारकता',
    'घूतधारी',
    'घूतशाला',
    'घूताधिकारी',
    'घूताध्यक्ष',
    'घूना',
    'घूम',
    'घूमआ',
    'घूमकर',
    'घूमकारि',
    'घूमघ',
    'घूमती',
    'घूमते',
    'घूमना',
    'घूमने',
    'घूमम',
    'घूममघुमाव',
    'घूममटा',
    'घूममता',
    'घूममती',
    'घूमा',
    'घूर',
    'घूरकर',
    'घूरना',
    'घूरने',
    'घूरा',
    'घूराघारी',
    'घूर्ण',
    'घूर्णक',
    'घूर्णन',
    'घूर्णनात्मक',
    'घूर्णवात',
    'घूर्णा',
    'घूर्णायक',
    'घूर्णिकत्र',
    'घूर्णित',
    'घूर्णिर्',
    'घूर्णिर्त',
    'घूर्णिर्त्र',
    'घूर्णी',
    'घूर्ण्य',
    'घूस',
    'घूसखोर',
    'घूसखोरी',
    'घूसेबाजी',
    'घृणा',
    'घृणाजनक',
    'घृणापूर्वक',
    'घृणासूचक',
    'घृणास्पद',
    'घृणित',
    'घृणिता',
    'घृणी',
    'घृणोत्पाद',
    'घृणोत्पादक',
    'घृण्य',
    'घृत',
    'घृताचिर्',
    'घृताची',
    'घृताशी',
    'घेंटला',
    'घेंटा',
    'घेंटुआ',
    'घेड़िया',
    'घेर',
    'घेरकर',
    'घेरघार',
    'घेरना',
    'घेरने',
    'घेरा',
    'घेराई',
    'घेराबंद',
    'घेराबंदी',
    'घेराबन्दी',
    'घेराव',
    'घेरे',
    'घेसला',
    'घैया',
    'घोंघ',
    'घोंघा',
    'घोंचूं',
    'घोंट',
    'घोंटकर',
    'घोंप',
    'घोंपना',
    'घोंसला',
    'घोखू',
    'घोगी',
    'घोघी',
    'घोटक',
    'घोटना',
    'घोटा',
    'घोटाला',
    'घोटालेबाज़',
    'घोटिका',
    'घोटी',
    'घोटुल',
    'घोटू',
    'घोड़ा',
    'घोड़ाः',
    'घोड़ी',
    'घोड़े',
    'घोड़ों',
    'घोडा',
    'घोडी',
    'घोडीफांद',
    'घोणस',
    'घोणी',
    'घोमरा',
    'घोर',
    'घोरघुष्य',
    'घोरता',
    'घोरदर्शन',
    'घोररुप',
    'घोरा',
    'घोराकृति',
    'घोल',
    'घोलकर',
    'घोलना',
    'घोलमेल',
    'घोला',
    'घोलुवा',
    'घोष',
    'घोषक',
    'घोषणा',
    'घोषणाकर्ता',
    'घोषणात्मक',
    'घोषणापत्र',
    'घोषयिता',
    'घोषयित्नु',
    'घोषवती',
    'घोषित',
    'घोषिततः',
    'घोसी',
    'घोस्ट',
    'घौंद',
    'घौंर',
    'घौंरा',
    'घ्राण',
    'घ्राणबोध',
    'घ्राणीय',
    'चँगेर',
    'चँगेरा',
    'चँगेरी',
    'चँगेली',
    'चँचना',
    'चँदवा',
    'चँदावल',
    'चँदिया',
    'चँदेया',
    'चँदोवा',
    'चँपाई',
    'चँबेली',
    'चँल',
    'चँवर',
    'चँवरी',
    'चंक्रमण',
    'चंगा',
    'चंगुल',
    'चंगेरिक',
    'चंचंलत्वा',
    'चंचरी',
    'चंचरीक',
    'चंचल',
    'चंचलचित्त',
    'चंचलचित्तता',
    'चंचलता',
    'चंचलनयन',
    'चंचलनयना',
    'चंचला',
    'चंचा',
    'चंचु',
    'चंचुका',
    'चंचू',
    'चंट',
    'चंड',
    'चंडकाली',
    'चंडघंटा',
    'चंडता',
    'चंडरिश्म',
    'चंडवती',
    'चंडवात',
    'चंडा',
    'चंडाल',
    'चंडालिका',
    'चंडावल',
    'चंडिका',
    'चंडिग्न',
    'चंडी',
    'चंडीगढ',
    'चंडीगढ़',
    'चंडीपति',
    'चंडीश',
    'चंडू',
    'चंडोग्रा',
    'चंडोदरी',
    'चंडोल',
    'चंद',
    'चंदक',
    'चंदन',
    'चंदनांग',
    'चंदनी',
    'चंदनीया',
    'चंदर',
    'चंदरोजा',
    'चंदा',
    'चंदिर',
    'चंद्र',
    'चंद्रः',
    'चंद्रक',
    'चंद्रकांत',
    'चंद्रकांति',
    'चंद्रकी',
    'चंद्रकेतु',
    'चंद्रगृह',
    'चंद्रघाटी',
    'चंद्रचूडामणि',
    'चंद्रजोत',
    'चंद्रतल',
    'चंद्रता',
    'चंद्रधर',
    'चंद्रपथ',
    'चंद्रपोत',
    'चंद्रप्रभ',
    'चंद्रप्रभा',
    'चंद्रबंधु',
    'चंद्रबग्घी',
    'चंद्रभस्म',
    'चंद्रभास',
    'चंद्रमस',
    'चंद्रमा',
    'चंद्रमाः',
    'चंद्रमास',
    'चंद्रमुख',
    'चंद्रमौलि',
    'चंद्रयान',
    'चंद्ररथ',
    'चंद्ररमा',
    'चंद्रवदन',
    'चंद्रवार',
    'चंद्रशाला',
    'चंद्रशेखर',
    'चंद्रस्वरता',
    'चंद्रहास',
    'चंद्रा',
    'चंद्राकार',
    'चंद्राकारता',
    'चंद्रातप',
    'चंद्रानन',
    'चंद्रामसते',
    'चंद्रायतन',
    'चंद्रालोक',
    'चंद्राशु',
    'चंद्रिकांबुज',
    'चंद्रिकापायी',
    'चंद्रिकोत्सव',
    'चंद्रिमा',
    'चंद्रिल',
    'चंद्रीय',
    'चंपक',
    'चंपत',
    'चंपा',
    'चंपी',
    'चंपू',
    'चंबल',
    'चंबी',
    'चक',
    'चकई',
    'चकचाल',
    'चकचूंदर',
    'चकतराशा',
    'चकतराशी',
    'चकती',
    'चकत्ता',
    'चकत्तेदार',
    'चकनाचूर',
    'चकपक',
    'चकपकाना',
    'चकपकाहट',
    'चकफेरी',
    'चकबंद',
    'चकबंदी',
    'चकबस्त',
    'चकमक',
    'चकमकी',
    'चकमा',
    'चकमेबाज़',
    'चकमेबाज़ी',
    'चकमेबाजी',
    'चकर',
    'चकरा',
    'चकराना',
    'चकराया',
    'चकराये',
    'चकराव',
    'चकराहट',
    'चकरी',
    'चकल',
    'चकलई',
    'चकला',
    'चकलाघर',
    'चकली',
    'चकले',
    'चकलेदार',
    'चकल्लसी',
    'चकल्लास',
    'चकवा',
    'चकहा',
    'चकाचक',
    'चकाचौंध',
    'चकाचौंधपूर्ण',
    'चकार',
    'चकास',
    'चकासना',
    'चकासित',
    'चकित',
    'चकितता',
    'चकुला',
    'चकुवा',
    'चकेठ',
    'चकेश्वर',
    'चकेश्वरी',
    'चकोतरा',
    'चकोर',
    'चकोरी',
    'चकोह',
    'चक्कर',
    'चक्करकाट',
    'चक्करदार',
    'चक्करमापी',
    'चक्का',
    'चक्कामापी',
    'चक्की',
    'चक्कीवाला',
    'चक्के',
    'चक्र',
    'चक्रः',
    'चक्रक',
    'चक्रगदाधर',
    'चक्रगुच्छ',
    'चक्रचर',
    'चक्रचारी',
    'चक्रजीवी',
    'चक्रण',
    'चक्रणशील',
    'चक्रता',
    'चक्रदोला',
    'चक्रधर',
    'चक्रधारा',
    'चक्रधारी',
    'चक्रनख',
    'चक्रनाभि',
    'चक्रनामा',
    'चक्रपज्जा',
    'चक्रपाणि',
    'चक्रपाद',
    'चक्रपाल',
    'चक्रभोग',
    'चक्रभ्रम',
    'चक्रमापी',
    'चक्ररद',
    'चक्रर्ती',
    'चक्रलेखित',
    'चक्रवर्ती',
    'चक्रवर्तीवाद',
    'चक्रवर्मा',
    'चक्रवाक',
    'चक्रवाकी',
    'चक्रवात',
    'चक्रवातायन',
    'चक्रवात्या',
    'चक्रवान',
    'चक्रवृद्धि',
    'चक्रव्यूह',
    'चक्रांक',
    'चक्रांकी',
    'चक्रांग',
    'चक्रांगना',
    'चक्रांगी',
    'चक्रांतरता',
    'चक्रांतरीय',
    'चक्रांश',
    'चक्राकार',
    'चक्राट',
    'चक्रानुक्रम',
    'चक्रानुक्रमण',
    'चक्रानुक्रमिक',
    'चक्रानुचक्र',
    'चक्राभ',
    'चक्रायक',
    'चक्रायण',
    'चक्रायुध',
    'चक्रार्ध',
    'चक्रावर्त',
    'चक्रावर्तन',
    'चक्रावर्ती',
    'चक्रावल',
    'चक्रावृत्ति',
    'चक्रावृत्तिशील',
    'चक्रिका',
    'चक्रिणी',
    'चक्रित',
    'चक्रिय',
    'चक्रिल',
    'चक्रिलता',
    'चक्री',
    'चक्रीय',
    'चक्ष',
    'चक्षु',
    'चक्षुग्राह्मता',
    'चक्षुदान',
    'चक्षुहीनता',
    'चखचख',
    'चखचखिया',
    'चखना',
    'चखने',
    'चखा',
    'चखाई',
    'चखाचखी',
    'चखाव',
    'चखास',
    'चखैया',
    'चखोड़ा',
    'चखौती',
    'चगेरिका',
    'चचा',
    'चचिया',
    'चचिर्का',
    'चचिर्क्य',
    'चचिर्त',
    'चचिर्तता',
    'चचेरा',
    'चचेरी',
    'चचोड़',
    'चचोड़ना',
    'चट',
    'चटक',
    'चटकदार',
    'चटकन',
    'चटकना',
    'चटकनी',
    'चटकर',
    'चटकल',
    'चटका',
    'चटकाना',
    'चटकार',
    'चटकारा',
    'चटकारी',
    'चटकाली',
    'चटकाहट',
    'चटकी',
    'चटकीला',
    'चटकीले',
    'चटखनी',
    'चटखारना',
    'चटखारा',
    'चटखारे',
    'चटखोरापन',
    'चटचट',
    'चटचटाहट',
    'चटन',
    'चटनी',
    'चटपट',
    'चटपटा',
    'चटपटाना',
    'चटपटापन',
    'चटर',
    'चटल',
    'चटसार',
    'चटसाल',
    'चटा',
    'चटाई',
    'चटाक',
    'चटाका',
    'चटाखा',
    'चटाखेदार',
    'चटाचट',
    'चटापट',
    'चटावन',
    'चटास',
    'चटियल',
    'चटु',
    'चटुल',
    'चटुला',
    'चटुलालस',
    'चटुलोल',
    'चटूल्लोल',
    'चटेल',
    'चटोर',
    'चटोरपन',
    'चटोरा',
    'चटोरापन',
    'चट्ट',
    'चट्टा',
    'चट्टान',
    'चट्टानी',
    'चट्टानों',
    'चट्टी',
    'चट्टू',
    'चडियां',
    'चड्ढा',
    'चड्ढी',
    'चढना',
    'चढ़',
    'चढ़ंत',
    'चढ़कर',
    'चढ़चड़',
    'चढ़त',
    'चढ़ता',
    'चढ़ती',
    'चढ़ते',
    'चढ़न',
    'चढ़ना',
    'चढ़नी',
    'चढ़ने',
    'चढ़नेवाला',
    'चढ़वा',
    'चढ़ा',
    'चढ़ाई',
    'चढ़ाऊ',
    'चढ़ाकर',
    'चढ़ाचढ़ी',
    'चढ़ाते',
    'चढ़ान',
    'चढ़ानदार',
    'चढ़ानयुक्त',
    'चढ़ाना',
    'चढ़ाने',
    'चढ़ाव',
    'चढ़ावा',
    'चढ़ी',
    'चढ़ैत',
    'चढ़ौवाँ',
    'चढा',
    'चढाई',
    'चढाना',
    'चढी',
    'चण',
    'चणक',
    'चण्डता',
    'चण्डतापूर्वक',
    'चण्डी',
    'चतरंग',
    'चतावनी',
    'चतु',
    'चतुः',
    'चतुःशाल',
    'चतुःसन',
    'चतुखंड',
    'चतुखंडी',
    'चतुद्रिक',
    'चतुद्रिशता',
    'चतुधर्म',
    'चतुभुज',
    'चतुभुर्जाकार',
    'चतुर',
    'चतुरंग',
    'चतुरंगिणी',
    'चतुरंत',
    'चतुरक्षर',
    'चतुरक्षरीय',
    'चतुरता',
    'चतुरतापूर्ण',
    'चतुरतापूर्वक',
    'चतुरस्र',
    'चतुराई',
    'चतुरात्मा',
    'चतुराधिक',
    'चतुरानन',
    'चतुरानीक',
    'चतुराभ्रम',
    'चतुरामयता',
    'चतुराययामीय',
    'चतुरायाम',
    'चतुरायामीय',
    'चतुर्गुण',
    'चतुर्घात',
    'चतुर्चक्र',
    'चतुर्थ',
    'चतुर्थातुक',
    'चतुर्थाश',
    'चतुर्थाशिक',
    'चतुर्थी',
    'चतुर्दत',
    'चतुर्दलीय',
    'चतुर्दश',
    'चतुर्दशी',
    'चतुर्दशीयुक्त',
    'चतुर्दिक',
    'चतुर्दिश',
    'चतुर्दिशतः',
    'चतुर्दिशता',
    'चतुर्दिशा',
    'चतुर्दिशीय',
    'चतुर्पाटीय',
    'चतुर्पाश्वीय',
    'चतुर्बाहू',
    'चतुर्भागीय',
    'चतुर्भुज',
    'चतुर्भुजः',
    'चतुर्भुजा',
    'चतुर्भुजाकार',
    'चतुर्भुजी',
    'चतुर्मास',
    'चतुर्मुख',
    'चतुर्मुखी',
    'चतुर्मूति',
    'चतुर्युग',
    'चतुर्युगी',
    'चतुर्वर्ग',
    'चतुर्वर्ण',
    'चतुर्वर्णी',
    'चतुर्विभक्त',
    'चतुर्वेद',
    'चतुर्वेदी',
    'चतुविर्द्या',
    'चतुविर्धता',
    'चतुव्र्यूह',
    'चतुष्क',
    'चतुष्काठ',
    'चतुष्की',
    'चतुष्कीबीजाणु',
    'चतुष्कोण',
    'चतुष्टक',
    'चतुष्टय',
    'चतुष्टयी',
    'चतुष्टोम',
    'चतुष्पत्नीत्व',
    'चतुष्पथ',
    'चतुष्पद',
    'चतुष्पदः',
    'चतुष्पदी',
    'चतुष्पर्णी',
    'चतुष्पाद',
    'चतुष्पाश्र्व',
    'चतुष्पुंकेसरी',
    'चतुष्पुरी',
    'चतुष्फलक',
    'चतुष्फलकीय',
    'चतुस्तना',
    'चतुस्संप्रदाय',
    'चतुस्सीमा',
    'चत्वर',
    'चत्वाल',
    'चददर',
    'चदरा',
    'चदरिया',
    'चद्दर',
    'चद्दरी',
    'चनक',
    'चनकन',
    'चनकना',
    'चनका',
    'चना',
    'चनाः',
    'चने',
    'चनौरी',
    'चन्दन',
    'चन्द्र',
    'चन्द्रधर',
    'चन्द्रन',
    'चन्द्रमा',
    'चन्द्रवार',
    'चन्द्रशिला',
    'चन्द्रशेखरन',
    'चन्द्राकार',
    'चप',
    'चपकन',
    'चपका',
    'चपचपाकर',
    'चपट',
    'चपटा',
    'चपटाना',
    'चपटी',
    'चपटे',
    'चपड़',
    'चपड़ा',
    'चपत',
    'चपतना',
    'चपतियाना',
    'चपनी',
    'चपरना',
    'चपरास',
    'चपरासी',
    'चपल',
    'चपलता',
    'चपलम',
    'चपला',
    'चपलाई',
    'चपलाना',
    'चपाट',
    'चपाती',
    'चपाना',
    'चपेट',
    'चपेटना',
    'चपेटा',
    'चपौटी',
    'चप्पन',
    'चप्पल',
    'चप्पा',
    'चप्पाकारी',
    'चप्पुओं',
    'चप्पू',
    'चप्पूदार',
    'चप्पे',
    'चबा',
    'चबाई',
    'चबाकर',
    'चबाना',
    'चबाने',
    'चबारा',
    'चबूतरा',
    'चबेना',
    'चबेनी',
    'चबैना',
    'चबैनी',
    'चम',
    'चमक',
    'चमकता',
    'चमकदमकदार',
    'चमकदार',
    'चमकना',
    'चमकनी',
    'चमका',
    'चमकाई',
    'चमकाना',
    'चमकाने',
    'चमकारना',
    'चमकारा',
    'चमकी',
    'चमकीला',
    'चमकीलापन',
    'चमकीली',
    'चमकीले',
    'चमकीलेपन',
    'चमक्की',
    'चमगादड़',
    'चमगादड़ों',
    'चमचम',
    'चमचमा',
    'चमचमाता',
    'चमचमाना',
    'चमचमाहट',
    'चमचा',
    'चमचागीरी',
    'चमची',
    'चमड़ा',
    'चमड़ाः',
    'चमड़ी',
    'चमड़े',
    'चमडा',
    'चमत्कार',
    'चमत्कारक',
    'चमत्कारपूर्ण',
    'चमत्कारवाद',
    'चमत्कारिक',
    'चमत्कारी',
    'चमत्कृत',
    'चमत्कृतता',
    'चमत्कृति',
    'चमन',
    'चमनबंदी',
    'चमर',
    'चमरख',
    'चमरघेंघ',
    'चमरी',
    'चमरौधा',
    'चमस',
    'चमाचम',
    'चमार',
    'चमारगीरी',
    'चमिर्क',
    'चमू',
    'चमूचर',
    'चमूनाथ',
    'चमूपति',
    'चमेरी',
    'चमेली',
    'चमोटा',
    'चमोटी',
    'चमौआ',
    'चमौवल',
    'चमौवा',
    'चम्पा',
    'चम्मच',
    'चम्मचभर',
    'चम्मल',
    'चय',
    'चयन',
    'चयनः',
    'चयनात्मक',
    'चयनिका',
    'चयनित',
    'चयापचय',
    'चयित',
    'चर',
    'चरई',
    'चरका',
    'चरख',
    'चरखा',
    'चरखी',
    'चरखीपंप',
    'चरचर',
    'चरचराना',
    'चरचराहट',
    'चरण',
    'चरणं',
    'चरणचिह्न',
    'चरणबद्ध',
    'चरणमृत',
    'चरणानुज',
    'चरणामृत',
    'चरणारिंवंद',
    'चरणार्ध',
    'चरणेषु',
    'चरणों',
    'चरत',
    'चरता',
    'चरते',
    'चरथ',
    'चरन',
    'चरना',
    'चरनाः',
    'चरनी',
    'चरने',
    'चरपरा',
    'चरपरापन',
    'चरपराहट',
    'चरब',
    'चरबदस्त',
    'चरबा',
    'चरबाँक',
    'चरबी',
    'चरबीदार',
    'चरबीला',
    'चरम',
    'चरमकाल',
    'चरमर',
    'चरमराहट',
    'चरमसीमा',
    'चरमसुख',
    'चरमापकर्षित',
    'चरमावस्था',
    'चरमोत्कर्ष',
    'चरर',
    'चरवाहगिरी',
    'चरवाहा',
    'चरवाहागीरी',
    'चरवाही',
    'चरस',
    'चरसा',
    'चरसाः',
    'चरसिया',
    'चरसी',
    'चराई',
    'चरागाह',
    'चराचर',
    'चराचरता',
    'चरान',
    'चराना',
    'चराने',
    'चरिंदा',
    'चरित',
    'चरितकार',
    'चरितव्य',
    'चरितार्थ',
    'चरितार्थता',
    'चरितावली',
    'चरित्तर',
    'चरित्र',
    'चरित्रचित्रण',
    'चरित्रबल',
    'चरित्रवती',
    'चरित्रवान',
    'चरित्रहीन',
    'चरित्रहीनता',
    'चरित्रहीना',
    'चरित्री',
    'चरित्रीय',
    'चरी',
    'चरू',
    'चरेरा',
    'चरैया',
    'चरोखर',
    'चर्ख',
    'चर्खा',
    'चर्खी',
    'चर्च',
    'चर्चण',
    'चर्चरिका',
    'चर्चरीक',
    'चर्चवाद',
    'चर्चा',
    'चर्बी',
    'चर्बीयुक्त',
    'चर्बीला',
    'चर्म',
    'चर्मकार',
    'चर्मकारिता',
    'चर्मकील',
    'चर्मकूपक',
    'चर्मझघट',
    'चर्मण्य',
    'चर्मण्वती',
    'चर्मधारी',
    'चर्मपट',
    'चर्मपत्र',
    'चर्मपत्रा',
    'चर्ममुंडा',
    'चर्मर्कवच',
    'चर्मशोधक',
    'चर्मशोधनालय',
    'चर्मात',
    'चर्मावत',
    'चर्मावरण',
    'चर्मावृत',
    'चर्मावृत्त',
    'चर्मिल',
    'चर्मीय',
    'चर्य',
    'चर्या',
    'चर्रमर्र',
    'चर्ररचूँ',
    'चर्राना',
    'चर्वक',
    'चर्वण',
    'चर्वणक',
    'चर्वर्ण',
    'चर्षणी',
    'चर्स्पी',
    'चल',
    'चलंतू',
    'चलंदरी',
    'चलंवंत',
    'चलकर्ण',
    'चलचंचु',
    'चलचित्र',
    'चलचित्रकार',
    'चलचित्रता',
    'चलचित्रांकन',
    'चलछाया',
    'चलजलीय',
    'चलत',
    'चलता',
    'चलताऊ',
    'चलती',
    'चलते',
    'चलदस्ता',
    'चलन',
    'चलनक',
    'चलनशील',
    'चलनहारा',
    'चलना',
    'चलनिका',
    'चलनिधि',
    'चलनी',
    'चलनीय',
    'चलने',
    'चलनेवाला',
    'चलपक्ष',
    'चलपत्र',
    'चलबाँक',
    'चलभाण',
    'चलभाषित्र',
    'चलमचान',
    'चलमुद्रा',
    'चलरुपाणु',
    'चलवायु',
    'चलवासी',
    'चलवृतान्त',
    'चला',
    'चलाई',
    'चलाऊ',
    'चलाऊपन',
    'चलाए',
    'चलाकर',
    'चलाकी',
    'चलाचल',
    'चलाचलता',
    'चलाचली',
    'चलाती',
    'चलाते',
    'चलान',
    'चलाना',
    'चलानी',
    'चलाने',
    'चलायमान',
    'चलायमानता',
    'चलाया',
    'चलाये',
    'चलार्थ',
    'चलाव',
    'चलावन',
    'चलावा',
    'चलित',
    'चलित्तर',
    'चलिष्णु',
    'चलिष्णुता',
    'चली',
    'चलूंतू',
    'चले',
    'चलें',
    'चलेने',
    'चलो',
    'चलौआ',
    'चलौना',
    'चलौनी',
    'चवन्नी',
    'चवरा',
    'चविंका',
    'चविका',
    'चविर्त',
    'चवी',
    'चव्यजा',
    'चश्त',
    'चश्म',
    'चश्मक',
    'चश्मदीद',
    'चश्मपोशी',
    'चश्मा',
    'चश्माः',
    'चश्माधारी',
    'चश्मारु',
    'चश्मासाज़',
    'चश्मी',
    'चश्मे',
    'चश्मेदार',
    'चषक',
    'चसक',
    'चसकता',
    'चसकना',
    'चसका',
    'चस्पाई',
    'चहक',
    'चहकना',
    'चहका',
    'चहचह',
    'चहचहा',
    'चहचहाट',
    'चहचहाना',
    'चहचहाहट',
    'चहबच्चा',
    'चहल',
    'चहलकदमी',
    'चहलना',
    'चहलपहलदार',
    'चहला',
    'चहली',
    'चहा',
    'चहार',
    'चहारचंद',
    'चहारदीवारी',
    'चहारशंबा',
    'चहुँ',
    'चहुंमुखी',
    'चहेता',
    'चा',
    'चाँइयाँ',
    'चाँगली',
    'चाँचर',
    'चाँचरी',
    'चाँद',
    'चाँदः',
    'चाँदकी',
    'चाँदतारा',
    'चाँदना',
    'चाँदनी',
    'चाँदनीः',
    'चाँदमारी',
    'चाँदा',
    'चाँदिया',
    'चाँदी',
    'चाँद्र',
    'चाँप',
    'चाँपनहार',
    'चाँपना',
    'चाँपा',
    'चाँपाकल',
    'चाँपी',
    'चाँप्',
    'चाँवल',
    'चांचल्य',
    'चांटा',
    'चांडा',
    'चांडाल',
    'चांडालनी',
    'चांडालिनी',
    'चांडाली',
    'चांद',
    'चांदनी',
    'चांदमारी',
    'चांदी',
    'चांद्र',
    'चांद्रमस',
    'चांद्रि',
    'चांस',
    'चांसलर',
    'चाइना',
    'चाइनावेअर',
    'चाइनीज़',
    'चाक',
    'चाकचक्य',
    'चाकचक्र',
    'चाकचिक्य',
    'चाकना',
    'चाकर',
    'चाकरनी',
    'चाकरानी',
    'चाकरी',
    'चाकलेट',
    'चाकी',
    'चाकू',
    'चाकूः',
    'चाक्रिक',
    'चाक्षुष',
    'चाक्षुषता',
    'चाचर',
    'चाचा',
    'चाची',
    'चाज',
    'चाट',
    'चाटना',
    'चाटनाः',
    'चाटबटु',
    'चाटुकार',
    'चाटुकारिता',
    'चाटुकारितापूर्ण',
    'चाटुकीय',
    'चाटुपटु',
    'चाटुलोल',
    'चाटू',
    'चाढ़ा',
    'चाणक्य',
    'चातक',
    'चातकानंदन',
    'चातर',
    'चातुदर्श',
    'चातुभ्रद्रक',
    'चातुमांस',
    'चातुरक',
    'चातुरिक',
    'चातुरी',
    'चातुर्जातक',
    'चातुर्मास',
    'चातुर्य',
    'चातुर्यपूर्ण',
    'चातुर्यहीन',
    'चातुर्वण्र्य',
    'चातुविर्ध',
    'चातुहौर्त्र',
    'चादर',
    'चादरयुक्त',
    'चादरें',
    'चादरों',
    'चाप',
    'चापकर्ण',
    'चापकार्ण',
    'चापट',
    'चापड़',
    'चापना',
    'चापबंध',
    'चापलंब',
    'चापलूस',
    'चापलूसी',
    'चापाकार',
    'चापाकारता',
    'चापालंकरण',
    'चापिनी',
    'चापी',
    'चाब',
    'चाबदस्त',
    'चाबना',
    'चाबियों',
    'चाबी',
    'चाबुक',
    'चाभी',
    'चाम',
    'चामत्कारित',
    'चामर',
    'चामरपुष्प',
    'चामरी',
    'चामिर्क',
    'चामुंडा',
    'चाय',
    'चायः',
    'चायका',
    'चायकाल',
    'चायघर',
    'चायदान',
    'चायदानी',
    'चायपानी',
    'चार',
    'चारक',
    'चारखाना',
    'चारखानेदार',
    'चारचाँद',
    'चारजामा',
    'चारण',
    'चारणगीत',
    'चारदिवारी',
    'चारदीवारी',
    'चारपथ',
    'चारपाई',
    'चारबाग',
    'चारभट',
    'चारयारी',
    'चाररोज़ा',
    'चारा',
    'चारागाह',
    'चारासाज़',
    'चारिणी',
    'चारिता',
    'चारित्रिक',
    'चारी',
    'चारु',
    'चारुता',
    'चारुफल',
    'चारुलोचन',
    'चारुलोचना',
    'चारू',
    'चारूकेशी',
    'चारूता',
    'चारूदर्शन',
    'चारूधामा',
    'चारूफल',
    'चारूमुखी',
    'चारूरुप',
    'चारूवेश',
    'चारूशील',
    'चारूशीला',
    'चारूसार',
    'चारे',
    'चारो',
    'चारों',
    'चार्ज',
    'चार्जकारी',
    'चार्जन',
    'चार्ट',
    'चार्टर',
    'चार्टरित',
    'चार्टर्ड',
    'चार्य',
    'चार्याक',
    'चार्वाक',
    'चार्वाकवाद',
    'चार्वाकवादी',
    'चार्वी',
    'चाल',
    'चालक',
    'चालकत्व',
    'चालकवर्ग',
    'चालघात',
    'चालढाल',
    'चालन',
    'चालना',
    'चालनी',
    'चालबाज',
    'चालबाज़',
    'चालबाज़ी',
    'चालबाजी',
    'चालबाज़',
    'चालमापी',
    'चाला',
    'चालाक',
    'चालाकी',
    'चालाकीपन',
    'चालाकीपूर्ण',
    'चालान',
    'चालानों',
    'चालित',
    'चालित्र',
    'चालिस',
    'चाली',
    'चालीस',
    'चालीसवाँ',
    'चालीसवां',
    'चालीसा',
    'चालू',
    'चालें',
    'चालों',
    'चाव',
    'चावड़ी',
    'चावरी',
    'चावल',
    'चावलः',
    'चावला',
    'चाश',
    'चाशनीः',
    'चाश्नी',
    'चाष',
    'चास',
    'चासनी',
    'चाह',
    'चाहकन',
    'चाहत',
    'चाहता',
    'चाहते',
    'चाहतें',
    'चाहना',
    'चाहने',
    'चाहा',
    'चाहापक्षी',
    'चाहिए',
    'चाहिये',
    'चाही',
    'चाहूंगा',
    'चाहे',
    'चाहेंगे',
    'चाहो',
    'चाहों',
    'चिंकारा',
    'चिंगट',
    'चिंगड़ा',
    'चिंगना',
    'चिंगरी',
    'चिंगारण',
    'चिंगारणी',
    'चिंगारियां',
    'चिंगारी',
    'चिंगुरना',
    'चिंघाड़',
    'चिंघाड़ना',
    'चिंचाई',
    'चिंचित',
    'चिंजा',
    'चिंजी',
    'चिंतक',
    'चिंतन',
    'चिंतनशील',
    'चिंतनहीन',
    'चिंतनातीत',
    'चिंतनातीतता',
    'चिंतनाधीन',
    'चिंतनीय',
    'चिंता',
    'चिंताकुल',
    'चिंताग्रस्त',
    'चिंताजनक',
    'चिंतानुभूति',
    'चिंतापर',
    'चिंताप्रद',
    'चिंतामग्न',
    'चिंतामणि',
    'चिंतारहित',
    'चिंतावस्था',
    'चिंताशील',
    'चिंतित',
    'चिंतिततः',
    'चिंतितता',
    'चिंदी',
    'चिंपाज़ी',
    'चिंपाजी',
    'चिंरजीविता',
    'चिउँटी',
    'चिउटा',
    'चिक',
    'चिकन',
    'चिकनदोजी',
    'चिकना',
    'चिकनाई',
    'चिकनाना',
    'चिकनापन',
    'चिकनाहट',
    'चिकनिया',
    'चिकनियापन',
    'चिकनी',
    'चिकने',
    'चिकनेपन',
    'चिकरी',
    'चिकवा',
    'चिक़',
    'चिकित्सक',
    'चिकित्सकीय',
    'चिकित्सन',
    'चिकित्सा',
    'चिकित्सागृह',
    'चिकित्सातीत',
    'चिकित्सालय',
    'चिकित्साविज्ञान',
    'चिकित्साशास्त्र',
    'चिकित्सीय',
    'चिकित्स्य',
    'चिकूर',
    'चिकोटी',
    'चिक्कट',
    'चिक्कण',
    'चिक्कणता',
    'चिक्कणी',
    'चिक्कस',
    'चिक्का',
    'चिक्वण',
    'चिखना',
    'चिखुरना',
    'चिखुराई',
    'चिचड़ी',
    'चिचियाहट',
    'चिचोड़',
    'चिज़लिंग',
    'चिट',
    'चिटकना',
    'चिटचिट',
    'चिटठा',
    'चिटनवीस',
    'चिट्टा',
    'चिट्टी',
    'चिट्ठा',
    'चिट्ठी',
    'चिट्ठीरसाँ',
    'चिठिया',
    'चिठ्ठी',
    'चिड़',
    'चिड़चिड़',
    'चिड़चिड़ा',
    'चिड़चिड़ाते',
    'चिड़चिड़ाना',
    'चिड़चिड़ापन',
    'चिड़चिड़ाहट',
    'चिड़चिड़ेपन',
    'चिड़वा',
    'चिड़ा',
    'चिड़ापन',
    'चिड़िया',
    'चिड़ियाः',
    'चिड़ियाघर',
    'चिड़ियों',
    'चिड़िहार',
    'चिड़ी',
    'चिड़ीमार',
    'चिड़ीमारी',
    'चिडिया',
    'चिढ़',
    'चिढ़न',
    'चिढ़ना',
    'चिढ़ा',
    'चिढ़ाना',
    'चिढ़ानेवाला',
    'चित',
    'चितकबरा',
    'चितचोर',
    'चिततेरा',
    'चितपट',
    'चितराला',
    'चितरी',
    'चितरोहण',
    'चितला',
    'चितवन',
    'चितशायी',
    'चितसार',
    'चितसारी',
    'चिता',
    'चिति',
    'चितेरा',
    'चित्',
    'चित्त',
    'चित्तज',
    'चित्तनाथ',
    'चित्तभ्रम',
    'चित्तवृत्ति',
    'चित्ताकर्षक',
    'चित्ति',
    'चित्तियां',
    'चित्ती',
    'चित्तीदार',
    'चित्तीय',
    'चित्तोद्विग्नता',
    'चित्थड़',
    'चित्य',
    'चित्या',
    'चित्र',
    'चित्रंकन',
    'चित्रः',
    'चित्रक',
    'चित्रकमेल',
    'चित्रकला',
    'चित्रकार',
    'चित्रकारी',
    'चित्रक्षेपी',
    'चित्रखंड',
    'चित्रगंध',
    'चित्रगुप्त',
    'चित्रण',
    'चित्रणकर्ता',
    'चित्रणकर्त्ता',
    'चित्रणात्मक',
    'चित्रदर्शी',
    'चित्रपट',
    'चित्रपतंग',
    'चित्रपदा',
    'चित्रफलक',
    'चित्रभानु',
    'चित्रमय',
    'चित्रमृग',
    'चित्रयुद्घ',
    'चित्रयोधी',
    'चित्ररथ',
    'चित्रल',
    'चित्रलिखित',
    'चित्रलिपि',
    'चित्रलेख',
    'चित्रलेखन',
    'चित्रलेखीय',
    'चित्रवल्लरी',
    'चित्रविचित्रता',
    'चित्रशाल',
    'चित्रशाला',
    'चित्रशालाद्घ',
    'चित्रसार',
    'चित्रसारी',
    'चित्रसाल',
    'चित्रा',
    'चित्रांकन',
    'चित्रांकित',
    'चित्रांग',
    'चित्रांगद',
    'चित्रांगदा',
    'चित्रांश',
    'चित्राकापूप',
    'चित्राक्षर',
    'चित्राक्षी',
    'चित्रागदः',
    'चित्रात्मक',
    'चित्रात्मकता',
    'चित्राधार',
    'चित्रायस',
    'चित्रालंकार',
    'चित्रालय',
    'चित्रावली',
    'चित्रिणी',
    'चित्रित',
    'चित्रीकरण',
    'चित्रीय',
    'चित्रेश',
    'चित्रोपम',
    'चिथड़ा',
    'चिथड़ाः',
    'चिथड़े',
    'चिथाड़ना',
    'चिदाकाश',
    'चिदानंद',
    'चिद्रूप',
    'चिद्विलास',
    'चिनक',
    'चिनकना',
    'चिनगारियां',
    'चिनगारियों',
    'चिनगारी',
    'चिनगी',
    'चिनचिनाना',
    'चिनन',
    'चिनना',
    'चिना',
    'चिनाई',
    'चिनाब',
    'चिनाव',
    'चिन्तक',
    'चिन्तन',
    'चिन्तनपूर्वक',
    'चिन्तनमग्न',
    'चिन्तनशील',
    'चिन्ता',
    'चिन्तामग्न',
    'चिन्तामुक्त',
    'चिन्तित',
    'चिन्मय',
    'चिन्मात्र',
    'चिन्ह',
    'चिन्हित',
    'चिप',
    'चिपक',
    'चिपकन',
    'चिपकना',
    'चिपकने',
    'चिपकनेवाला',
    'चिपकवा',
    'चिपका',
    'चिपकाऊ',
    'चिपकाना',
    'चिपकाने',
    'चिपकाया',
    'चिपकाव',
    'चिपकाहट',
    'चिपकू',
    'चिपके',
    'चिपचिप',
    'चिपचिपा',
    'चिपचिपाना',
    'चिपचिपापन',
    'चिपचिपाहट',
    'चिपचिपाहटपूर्वक',
    'चिपचिपेपन',
    'चिपट',
    'चिपटना',
    'चिपटा',
    'चिपटाए',
    'चिपटी',
    'चिपटू',
    'चिपटे',
    'चिपा',
    'चिपिट',
    'चिपिटक',
    'चिप्पक',
    'चिप्पट',
    'चिप्पड़',
    'चिप्पी',
    'चिप्पीकरण',
    'चिप्स',
    'चिबल',
    'चिबलाई',
    'चिबलाना',
    'चिबिड्डी',
    'चिबिल्ला',
    'चिबुक',
    'चिबुकास्थि',
    'चिभल',
    'चिभलाई',
    'चिभलाना',
    'चिमटना',
    'चिमटा',
    'चिमटी',
    'चिमड़ना',
    'चिमनी',
    'चिमिर्ट',
    'चिम्पी',
    'चिर',
    'चिरंजी',
    'चिरंजीव',
    'चिरंजीवी',
    'चिरंटी',
    'चिरंतन',
    'चिरंतनता',
    'चिरई',
    'चिरकना',
    'चिरकांक्षा',
    'चिरकांक्षित',
    'चिरकारिता',
    'चिरकारी',
    'चिरकाल',
    'चिरकालिक',
    'चिरकालीन',
    'चिरकालीनता',
    'चिरकुटिया',
    'चिरकुमार',
    'चिरकुमारी',
    'चिरकूट',
    'चिरकौमार्य',
    'चिरजीवन',
    'चिरजीवी',
    'चिरता',
    'चिरत्र',
    'चिरन',
    'चिरना',
    'चिरन्तन',
    'चिरपुष्प',
    'चिरभोग्य',
    'चिरमिराहट',
    'चिरयष्य',
    'चिरयातना',
    'चिरयुवा',
    'चिरयुवापन',
    'चिरयौवन',
    'चिरवास',
    'चिरवासिता',
    'चिरवासी',
    'चिरशत्रु',
    'चिरस्थायी',
    'चिरा',
    'चिराई',
    'चिराग',
    'चिरागः',
    'चिरागे',
    'चिराद',
    'चिराबेल',
    'चिरायु',
    'चिरेण',
    'चिरैया',
    'चिरोडी',
    'चिरौंजी',
    'चिरौंदा',
    'चिरौरी',
    'चिर्क',
    'चिर्भटी',
    'चिलक',
    'चिलकता',
    'चिलकना',
    'चिलकाना',
    'चिलगोजा',
    'चिलचिल',
    'चिलचिलाना',
    'चिलड़ा',
    'चिलबिल',
    'चिलबिला',
    'चिलबिलापन',
    'चिलबिल्ला',
    'चिलम',
    'चिलमची',
    'चिलमीलिका',
    'चिलाते',
    'चिली',
    'चिल्ड्रंस',
    'चिल्ल',
    'चिल्लपों',
    'चिल्लर',
    'चिल्लवाँस',
    'चिल्ला',
    'चिल्लाकर',
    'चिल्लाते',
    'चिल्लाना',
    'चिल्लाने',
    'चिल्लाहट',
    'चिल्ली',
    'चिहुँक',
    'चिहुँकना',
    'चिह्न',
    'चिह्नंकन',
    'चिह्नक',
    'चिह्नत',
    'चिह्नऩ',
    'चिह्नांकन',
    'चिह्नांकित',
    'चिह्नित',
    'चिह्नित्र',
    'चिह्नीकरण',
    'चिह्वा',
    'चीं',
    'चींगा',
    'चींचपड़',
    'चींची',
    'चींचीं',
    'चींटा',
    'चींटी',
    'चींथना',
    'चीक',
    'चीकट',
    'चीकू',
    'चीख',
    'चीखना',
    'चीखल',
    'चीख़',
    'चीख़ता',
    'चीख़ना',
    'चीज',
    'चीजवस्तु',
    'चीज़',
    'चीजें',
    'चीजों',
    'चीड़',
    'चीतल',
    'चीता',
    'चीत्कार',
    'चीथड़ा',
    'चीथना',
    'चीदा',
    'चीन',
    'चीनः',
    'चीना',
    'चीनांशुक',
    'चीनी',
    'चीनीदानी',
    'चीनीपोटे',
    'चीन्हा',
    'चीपड़',
    'चीफ',
    'चीफ़',
    'चीबस्त',
    'चीमड़',
    'चीमड़पन',
    'चीर',
    'चीरचीर',
    'चीरना',
    'चीरने',
    'चीरपर्ण',
    'चीरफाड़',
    'चीरफाड़ः',
    'चीरवासा',
    'चीरहर्ता',
    'चीरा',
    'चीरि',
    'चीरिका',
    'चीरी',
    'चीर्ण',
    'चीर्णन',
    'चीर्णपर्ण',
    'चील',
    'चीला',
    'चीलिका',
    'चीलू',
    'चीवर',
    'चीवरधारी',
    'चीवरी',
    'चीस',
    'चीसता',
    'चीसना',
    'चीज़',
    'चीज़ें',
    'चीज़ों',
    'चीफ़',
    'चुँआन',
    'चुँटाई',
    'चुँडा',
    'चुँडी',
    'चुँदरी',
    'चुँदी',
    'चुँधा',
    'चुँधियाता',
    'चुंआई',
    'चुंआन',
    'चुंआना',
    'चुंकी',
    'चुंगल',
    'चुंगी',
    'चुंटा',
    'चुंब',
    'चुंबक',
    'चुंबकता',
    'चुंबकशील',
    'चुंबकशीलता',
    'चुंबकीयता',
    'चुंबन',
    'चुअन',
    'चुआना',
    'चुआया',
    'चुआव',
    'चुकंदर',
    'चुकचुकाना',
    'चुकटी',
    'चुकता',
    'चुकतान',
    'चुकताना',
    'चुकन',
    'चुकना',
    'चुकने',
    'चुकन्दर',
    'चुकरु',
    'चुकरैंड',
    'चुका',
    'चुकाई',
    'चुकाए',
    'चुकाना',
    'चुकाने',
    'चुकाया',
    'चुकायी',
    'चुकिया',
    'चुकी',
    'चुके',
    'चुकौती',
    'चुक्कड़',
    'चुक्र',
    'चुक्रिका',
    'चुक्रिम',
    'चुक्रिमा',
    'चुग',
    'चुगन',
    'चुगना',
    'चुगल',
    'चुगलखोर',
    'चुगलखोरी',
    'चुगली',
    'चुगस',
    'चुगा',
    'चुगाई',
    'चुग्गा',
    'चुचुआना',
    'चुटक',
    'चुटकला',
    'चुटकलेबाजी',
    'चुटकियों',
    'चुटकी',
    'चुटकुला',
    'चुटकुले',
    'चुटपुट',
    'चुटर',
    'चुटिया',
    'चुटीलना',
    'चुटीला',
    'चुटैल',
    'चुड़ियाँ',
    'चुड़िहार',
    'चुड़िहारा',
    'चुड़ैल',
    'चुड़ैलों',
    'चुडै',
    'चुत्थलपना',
    'चुन',
    'चुनँाचे',
    'चुनक',
    'चुनचुनाना',
    'चुनत',
    'चुनते',
    'चुनन',
    'चुननदार',
    'चुनना',
    'चुनने',
    'चुनर',
    'चुनरिया',
    'चुनरी',
    'चुनवाना',
    'चुनस',
    'चुना',
    'चुनाई',
    'चुनाखा',
    'चुनाना',
    'चुनाव',
    'चुनावी',
    'चुनिंदा',
    'चुनिन्दा',
    'चुनी',
    'चुने',
    'चुनौतियों',
    'चुनौती',
    'चुनौतीपूर्ण',
    'चुनौतीपूर्वक',
    'चुन्दी',
    'चुन्न',
    'चुन्नट',
    'चुन्नटदार',
    'चुन्नटीकरण',
    'चुन्नी',
    'चुप',
    'चुपका',
    'चुपके',
    'चुपचाप',
    'चुपड़न',
    'चुपड़ना',
    'चुपड़ा',
    'चुपड़ी',
    'चुप्पा',
    'चुप्पी',
    'चुबंक',
    'चुबल',
    'चुबलना',
    'चुबलाई',
    'चुबलाना',
    'चुभकना',
    'चुभकी',
    'चुभता',
    'चुभन',
    'चुभनशील',
    'चुभना',
    'चुभलाई',
    'चुभलाना',
    'चुभाना',
    'चुभें',
    'चुमकार',
    'चुमकारना',
    'चुमाना',
    'चुम्बक',
    'चुम्बकत्व',
    'चुम्बकीय',
    'चुम्बन',
    'चुम्बी',
    'चुम्मा',
    'चुम्मी',
    'चुरकना',
    'चुरकुट',
    'चुरकूस',
    'चुरना',
    'चुरमुरा',
    'चुरस',
    'चुरा',
    'चुराई',
    'चुराकर',
    'चुराते',
    'चुराना',
    'चुराने',
    'चुराया',
    'चुलबुला',
    'चुलबुलापन',
    'चुलबुली',
    'चुलाई',
    'चुलाना',
    'चुलाब',
    'चुलुपी',
    'चुल्लू',
    'चुल्हा',
    'चुवाना',
    'चुसकी',
    'चुसनी',
    'चुसाई',
    'चुसैया',
    'चुसौवल',
    'चुस्की',
    'चुस्त',
    'चुस्ता',
    'चुस्ती',
    'चुहँटी',
    'चुहचुहाटा',
    'चुहचुहाना',
    'चुहचुहिया',
    'चुहल',
    'चुहलबाज',
    'चुहलबाज़',
    'चुहिया',
    'चुग़लखोरी',
    'चुग़ली',
    'चूँ',
    'चूँचपड़',
    'चूँचूँ',
    'चूँट',
    'चूँटना',
    'चूँधना',
    'चूंकि',
    'चूक',
    'चूकना',
    'चूकने',
    'चूका',
    'चूके',
    'चूकें',
    'चूची',
    'चूचुआन',
    'चूचुक',
    'चूचुहाना',
    'चूजा',
    'चूजों',
    'चूड़ा',
    'चूड़ामणि',
    'चूड़ी',
    'चूड़ीदार',
    'चूडा',
    'चूडाकरण',
    'चूडामणि',
    'चूडियाँ',
    'चूडी',
    'चूडीदार',
    'चूडेश्वर',
    'चूण्र',
    'चूतड़',
    'चूता',
    'चूनभ',
    'चूनर',
    'चूनरी',
    'चूना',
    'चूनी',
    'चूने',
    'चूनेदार',
    'चूम',
    'चूमना',
    'चूमाचाटी',
    'चूर',
    'चूरन',
    'चूरमा',
    'चूरलू',
    'चूरा',
    'चूरी',
    'चूर्ण',
    'चूर्णन',
    'चूर्णवत',
    'चूर्णाश्म',
    'चूर्णिंत',
    'चूर्णित',
    'चूल',
    'चूलदार',
    'चूलिका',
    'चूली',
    'चूल्हा',
    'चूल्हे',
    'चूष',
    'चूषक',
    'चूषण',
    'चूषित',
    'चूस',
    'चूसकर',
    'चूसन',
    'चूसना',
    'चूसनीय',
    'चूसू',
    'चूहा',
    'चूहादान',
    'चूही',
    'चूहे',
    'चूहेखानी',
    'चूहेदान',
    'चूहेदानी',
    'चूहों',
    'चेंचें',
    'चेंटुआ',
    'चेंपे',
    'चेंपें',
    'चेअर',
    'चेक',
    'चेककर्ता',
    'चेकधारी',
    'चेकपर्चियां',
    'चेकर',
    'चेकितान',
    'चेकों',
    'चेकोस्लोवाकिया',
    'चेगा',
    'चेचक',
    'चेचकी',
    'चेजारा',
    'चेट',
    'चेटक',
    'चेटकी',
    'चेटिका',
    'चेटी',
    'चेट्टियार',
    'चेत',
    'चेतकी',
    'चेतन',
    'चेतनकर',
    'चेतनता',
    'चेतना',
    'चेतनायुक्त',
    'चेतनाहरण',
    'चेतनाहीन',
    'चेतनाहीनतः',
    'चेतनाहीनता',
    'चेतनाहृत',
    'चेतनीकरण',
    'चेतन्यता',
    'चेता',
    'चेताना',
    'चेतावनी',
    'चेतिनी',
    'चेदिपति',
    'चेदिराज',
    'चेन',
    'चेना',
    'चेप',
    'चेपदार',
    'चेपन',
    'चेपव्य',
    'चेपा',
    'चेपी',
    'चेम्बर',
    'चेयर',
    'चेयरमैन',
    'चेय़र',
    'चेरी',
    'चेल',
    'चेला',
    'चेलागिरी',
    'चेष्टा',
    'चेष्टाए',
    'चेष्टाक्रम',
    'चेष्टावाद',
    'चेष्टाहीन',
    'चेष्टाहीनतः',
    'चेष्टाहीनता',
    'चेष्टित',
    'चेष्टी',
    'चेस',
    'चेहरा',
    'चेहरे',
    'चेहरेवाला',
    'चेहलकदमी',
    'चेहलक़दमी',
    'चेहलूम',
    'चैंबर',
    'चैक',
    'चैकअप',
    'चैककर्ता',
    'चैकर्स',
    'चैकिंग',
    'चैत',
    'चैतन्य',
    'चैतन्ययुक्त',
    'चैतन्यहीन',
    'चैतन्यहीनता',
    'चैती',
    'चैत्य',
    'चैत्यद्रु',
    'चैत्र',
    'चैत्रक',
    'चैत्ररथ',
    'चैत्रसख',
    'चैद्य',
    'चैन',
    'चैनल',
    'चैनुआ',
    'चैपैया',
    'चैप्टर',
    'चैम्पियन',
    'चैम्बर',
    'चैम्बर्स',
    'चैरिटी',
    'चैरिटेबल',
    'चैलक',
    'चैला',
    'चैलिक',
    'चैली',
    'चैस',
    'चैस्ट',
    'चोंगा',
    'चोंच',
    'चोंचदार',
    'चोंचमय',
    'चोंचला',
    'चोंचले',
    'चोंचलेबाज',
    'चोंचलेबाजी',
    'चोंडा',
    'चोंथ',
    'चोंधर',
    'चोंप',
    'चोआ',
    'चोई',
    'चोईदार',
    'चोएक',
    'चोकर',
    'चोकार',
    'चोकी',
    'चोक्ष',
    'चोखना',
    'चोखा',
    'चोखापन',
    'चोग़ा',
    'चोगा',
    'चोचला',
    'चोचले',
    'चोचलेबाज़',
    'चोट',
    'चोटी',
    'चोटीदार',
    'चोटीधारी',
    'चोट्टा',
    'चोट्टी',
    'चोडक',
    'चोडी',
    'चोताल',
    'चोताला',
    'चोथ',
    'चोथा',
    'चोप',
    'चोपड़ा',
    'चोपन',
    'चोब',
    'चोबदस्ती',
    'चोबदार',
    'चोबा',
    'चोबिया',
    'चोभा',
    'चोमासा',
    'चोया',
    'चोर',
    'चोरःसरदार',
    'चोरटा',
    'चोरटी',
    'चोरनी',
    'चोरस',
    'चोरहटिया',
    'चोराडोब',
    'चोरी',
    'चोरीः',
    'चोरों',
    'चोल',
    'चोलक',
    'चोलकी',
    'चोलट्री',
    'चोलना',
    'चोला',
    'चोली',
    'चोलीदार',
    'चोल्ट्री',
    'चोषण',
    'चोष्यम',
    'चोसर',
    'चौ',
    'चौंक',
    'चौंकना',
    'चौंकने',
    'चौंका',
    'चौंकाना',
    'चौंकाने',
    'चौंटना',
    'चौंध',
    'चौंर',
    'चौंरी',
    'चौआ',
    'चौक',
    'चौककर',
    'चौकठा',
    'चौकड़ी',
    'चौकड़ीदार',
    'चौकड़ीबाज',
    'चौकडा',
    'चौकडी',
    'चौकडीबाज़ी',
    'चौकन्ना',
    'चौकन्नापन',
    'चौकस',
    'चौकसी',
    'चौका',
    'चौकार',
    'चौकी',
    'चौकीदार',
    'चौकीमार',
    'चौकोन',
    'चौकोर',
    'चौखंडा',
    'चौखट',
    'चौखटः',
    'चौखटा',
    'चौखटी',
    'चौखन',
    'चौखना',
    'चौखूँट',
    'चौखूँटा',
    'चौगडा',
    'चौगड्डा',
    'चौगड्डी',
    'चौगान',
    'चौगानगाह',
    'चौगानी',
    'चौगामी',
    'चौगिर्द',
    'चौगुना',
    'चौगोडिया',
    'चौघड़',
    'चौघडा',
    'चौचँद',
    'चौड़',
    'चौड़ा',
    'चौड़ाई',
    'चौड़ापन',
    'चौड़ी',
    'चौड़े',
    'चौडा',
    'चौडाई',
    'चौडान',
    'चौडी',
    'चौडे',
    'चौतरफा',
    'चौतरा',
    'चौताल',
    'चौथ',
    'चौथा',
    'चौथाई',
    'चौथी',
    'चौथे',
    'चौदस',
    'चौदह',
    'चौदहवां',
    'चौदहवीं',
    'चौदहों',
    'चौध',
    'चौधडा',
    'चौधराहट',
    'चौधरी',
    'चौप',
    'चौपई',
    'चौपट',
    'चौपटता',
    'चौपड़',
    'चौपत',
    'चौपतिया',
    'चौपथ',
    'चौपन्ना',
    'चौपन्नी',
    'चौपहला',
    'चौपहलू',
    'चौपहिया',
    'चौपाई',
    'चौपाटी',
    'चौपाया',
    'चौपाल',
    'चौपैरी',
    'चौफेर',
    'चौबंद',
    'चौबंदी',
    'चौबाई',
    'चौबारा',
    'चौबीस',
    'चौबीसवाँ',
    'चौबीसी',
    'चौबे',
    'चौभर',
    'चौमखा',
    'चौमासे',
    'चौमुख',
    'चौमुखी',
    'चौमुहाना',
    'चौमेखा',
    'चौरंधी',
    'चौरठा',
    'चौरस',
    'चौरसपन',
    'चौरसा',
    'चौरसी',
    'चौरस्ता',
    'चौरा',
    'चौरानवे',
    'चौरासिया',
    'चौरासी',
    'चौराह',
    'चौराहा',
    'चौराहे',
    'चौरेठा',
    'चौर्य',
    'चौर्यता',
    'चौर्यान्माद',
    'चौला',
    'चौलाई',
    'चौवाई',
    'चौसर',
    'चौहटी',
    'चौहट्टा',
    'चौहद्दी',
    'चौहरा',
    'चौहान',
    'च्यत',
    'च्यवन',
    'च्यावन',
    'च्युत',
    'च्युति',
    'च्यूत',
    'च्यूति',
    'छ',
    'छँगुनिया',
    'छँगुली',
    'छँटन',
    'छँटना',
    'छँटनी',
    'छँटवाना',
    'छँटस',
    'छँटा',
    'छँटाई',
    'छँटैल',
    'छंकाई',
    'छंकार',
    'छंटनी',
    'छंटाई',
    'छंटाईकर्ता',
    'छंद',
    'छंदः',
    'छंदकार',
    'छंदगणना',
    'छंदगति',
    'छंदबद्घ',
    'छंदबद्घता',
    'छंदमुक्त',
    'छंदस्कृत',
    'छंदहीन',
    'छंदांश',
    'छंदित',
    'छंदीय',
    'छंदोग',
    'छंदोगणना',
    'छः',
    'छक',
    'छकंडा',
    'छकड़ा',
    'छकड़ी',
    'छकड़ेवान',
    'छकना',
    'छका',
    'छकाछक',
    'छकाना',
    'छकाव',
    'छकैडा',
    'छक्का',
    'छक्कापन',
    'छक्के',
    'छखंडा',
    'छगन',
    'छगल',
    'छगली',
    'छगोड़िया',
    'छछिया',
    'छछूँदर',
    'छछूंदर',
    'छजला',
    'छजली',
    'छजाई',
    'छज्जा',
    'छटंकी',
    'छटक',
    'छटकना',
    'छटका',
    'छटकू',
    'छटन',
    'छटपटाता',
    'छटपटाते',
    'छटपटाना',
    'छटपटाहट',
    'छटपटी',
    'छटा',
    'छटाँक',
    'छटाँकः',
    'छटाई',
    'छटाभा',
    'छटे',
    'छठ',
    'छठवाँ',
    'छठा',
    'छठी',
    'छठे',
    'छड़',
    'छड़दार',
    'छड़ा',
    'छड़ापन',
    'छड़िया',
    'छड़ियाल',
    'छड़ी',
    'छड़ीदार',
    'छड़ीबरदार',
    'छड़ों',
    'छड़ॣ',
    'छत',
    'छतगीर',
    'छतता',
    'छतदार',
    'छतदारसीढी',
    'छतनार',
    'छतनारा',
    'छतर',
    'छतरी',
    'छतरीबाज़',
    'छतवर',
    'छतवाला',
    'छतीसी',
    'छतौनी',
    'छत्त',
    'छत्ता',
    'छत्तीस',
    'छत्तीसा',
    'छत्तीसी',
    'छत्ते',
    'छत्तेदार',
    'छत्र',
    'छत्रक',
    'छत्रछाया',
    'छत्रण',
    'छत्रपति',
    'छत्रभंग',
    'छत्रवट',
    'छत्रा',
    'छद',
    'छदन',
    'छदपत्र',
    'छदिमा',
    'छद्दमवेश',
    'छद्म',
    'छद्मता',
    'छद्मपूर्ण',
    'छद्मवास',
    'छद्मवासी',
    'छद्महीन',
    'छद्महीनता',
    'छद्मावरण',
    'छद्मावरणीय',
    'छद्मावृत',
    'छद्मी',
    'छन',
    'छनक',
    'छनकना',
    'छनकाना',
    'छनकार',
    'छनछन',
    'छनछनाना',
    'छनछनाहट',
    'छनन',
    'छनना',
    'छनस',
    'छनाई',
    'छनौरी',
    'छन्द',
    'छन्ना',
    'छन्नी',
    'छपक',
    'छपकन',
    'छपकना',
    'छपका',
    'छपकाना',
    'छपकी',
    'छपछपाना',
    'छपछपाहट',
    'छपटी',
    'छपते',
    'छपना',
    'छपने',
    'छपपय',
    'छपवाए',
    'छपवाना',
    'छपहला',
    'छपहलू',
    'छपा',
    'छपाई',
    'छपाईकार',
    'छपाईगीरी',
    'छपाए',
    'छपाका',
    'छपायी',
    'छपास',
    'छपी',
    'छप्पय',
    'छप्पर',
    'छप्परफाड़',
    'छप्परबंद',
    'छब',
    'छबड़ा',
    'छबड़ी',
    'छबबीसवाँ',
    'छबिधर',
    'छबील',
    'छबीला',
    'छबीलापन',
    'छबीली',
    'छमंड',
    'छमक',
    'छमछम',
    'छमाहियों',
    'छमाही',
    'छमू',
    'छरना',
    'छरहरा',
    'छरहरापन',
    'छरहरी',
    'छरहरेपन',
    'छरीदा',
    'छर्दन',
    'छर्दिक',
    'छर्दिघ्न',
    'छर्रा',
    'छर्री',
    'छर्रे',
    'छल',
    'छलक',
    'छलकन',
    'छलकना',
    'छलकपट',
    'छलकपटहीन',
    'छलकाना',
    'छलकाव',
    'छलघात',
    'छलछदमपूर्ण',
    'छलछल',
    'छलछलहाट',
    'छलछला',
    'छलछलाता',
    'छलछलाना',
    'छलछलाव',
    'छलना',
    'छलनी',
    'छलनीपन',
    'छलपूण',
    'छलपूर्ण',
    'छलपूर्णर्ता',
    'छलपूर्वक',
    'छलमलाना',
    'छलयोजित',
    'छलला',
    'छललो',
    'छलहीन',
    'छलहीनता',
    'छला',
    'छलाँग',
    'छलाँगना',
    'छलांग',
    'छलावा',
    'छलित',
    'छलितक',
    'छलिया',
    'छली',
    'छल्ला',
    'छल्ली',
    'छल्लेदार',
    'छवना',
    'छवाँ',
    'छवां',
    'छवाई',
    'छवि',
    'छविकार',
    'छह',
    'छहगुना',
    'छहरना',
    'छहरीली',
    'छा',
    'छाँट',
    'छाँटन',
    'छाँटना',
    'छाँटा',
    'छाँद',
    'छाँदना',
    'छाँदा',
    'छाँस',
    'छाँह',
    'छाँहदार',
    'छांट',
    'छांटकर',
    'छांटना',
    'छांटने',
    'छांद',
    'छांदना',
    'छांदिक',
    'छांदोग्य',
    'छाक',
    'छाग',
    'छागपाल',
    'छागभोजी',
    'छागमुख',
    'छागमेध',
    'छागल',
    'छाछ',
    'छाज',
    'छाजन',
    'छाजनः',
    'छाटा',
    'छाडना',
    'छाड़',
    'छाता',
    'छाताधारी',
    'छातियां',
    'छातियों',
    'छाती',
    'छात्र',
    'छात्रपाल',
    'छात्रवृति',
    'छात्रवृत्ति',
    'छात्रा',
    'छात्राओं',
    'छात्राकार',
    'छात्राध्यापक',
    'छात्रावास',
    'छात्रावासी',
    'छात्रों',
    'छाद',
    'छादक',
    'छादन',
    'छादित',
    'छादिनी',
    'छान',
    'छानन',
    'छानना',
    'छानबीन',
    'छाना',
    'छानी',
    'छाप',
    'छापना',
    'छापने',
    'छापा',
    'छापाखाना',
    'छापामार',
    'छापे',
    'छाबड़ा',
    'छाबड़ी',
    'छामोदरी',
    'छाया',
    'छायांक',
    'छायांकन',
    'छायाकरण',
    'छायाकार',
    'छायाकृति',
    'छायाग्रह',
    'छायाचित्र',
    'छायाचित्रण',
    'छायादार',
    'छायानुकरण',
    'छायाभास',
    'छायाभासी',
    'छायामर्ति',
    'छायामिति',
    'छायामूर्ति',
    'छायामृगधर',
    'छायायुद्ध',
    'छायालोक',
    'छायावर्धन',
    'छार',
    'छाल',
    'छालटी',
    'छालना',
    'छाला',
    'छालाः',
    'छालिया',
    'छाली',
    'छावनी',
    'छावर',
    'छाह',
    'छिकनी',
    'छिक्का',
    'छिछड़ा',
    'छिछलपान',
    'छिछला',
    'छिछालेदार',
    'छिछियाना',
    'छिछोरा',
    'छिछोरापन',
    'छिजाना',
    'छिटक',
    'छिटकना',
    'छिटकाई',
    'छिटकाना',
    'छिटकाव',
    'छिटकी',
    'छिटपुट',
    'छिटपूट',
    'छिडना',
    'छिड़',
    'छिड़ककर',
    'छिड़कन',
    'छिड़कना',
    'छिड़काई',
    'छिड़काना',
    'छिड़काव',
    'छिड़ना',
    'छिड़ा',
    'छिड़ी',
    'छितनी',
    'छितरना',
    'छितरा',
    'छितराना',
    'छितराने',
    'छितरापन',
    'छितराव',
    'छितरेपन',
    'छित्वर',
    'छिद',
    'छिदना',
    'छिदरा',
    'छिदरापन',
    'छिदा',
    'छिदाई',
    'छिदापन',
    'छिद्र',
    'छिद्रक',
    'छिद्रकर्ण',
    'छिद्रण',
    'छिद्रदर्शी',
    'छिद्रयुक्त',
    'छिद्ररुद्घ',
    'छिद्ररुद्घता',
    'छिद्रहीन',
    'छिद्रहीनता',
    'छिद्रान्वेषक',
    'छिद्रान्वेषण',
    'छिद्रान्वेषी',
    'छिद्रावकाश',
    'छिद्रित',
    'छिद्रिल',
    'छिद्रिलता',
    'छिद्रों',
    'छिन',
    'छिनाई',
    'छिनाल',
    'छिनालपन',
    'छिनाला',
    'छिन्द्रान्वेषी',
    'छिन्न',
    'छिन्नन',
    'छिन्नमस्ता',
    'छिन्नमूल',
    'छिन्नसंशय',
    'छिन्ना',
    'छिप',
    'छिपकर',
    'छिपकली',
    'छिपट',
    'छिपटियां',
    'छिपटी',
    'छिपन',
    'छिपना',
    'छिपने',
    'छिपा',
    'छिपाई',
    'छिपाऊ',
    'छिपाकर',
    'छिपाधन',
    'छिपाना',
    'छिपाने',
    'छिपापन',
    'छिपाव',
    'छिपावदार',
    'छिपी',
    'छिपे',
    'छिपौअल',
    'छिप्टी',
    'छिबड़ा',
    'छिम्बीदार',
    'छियानवे',
    'छिल',
    'छिलका',
    'छिलके',
    'छिलकेदार',
    'छिलन',
    'छिलना',
    'छिलनी',
    'छिला',
    'छिलाई',
    'छिलौरी',
    'छिल्का',
    'छिल्लक',
    'छी',
    'छीं',
    'छींक',
    'छींकना',
    'छींका',
    'छींट',
    'छींटना',
    'छींटा',
    'छीका',
    'छीछड़ा',
    'छीछड़े',
    'छीछी',
    'छीज',
    'छीजन',
    'छीजना',
    'छीजा',
    'छीट',
    'छीटना',
    'छीटा',
    'छीतना',
    'छीदा',
    'छीन',
    'छीनना',
    'छीना',
    'छीप',
    'छीपा',
    'छीपी',
    'छीपीः',
    'छीबी',
    'छीमी',
    'छील',
    'छीलन',
    'छीलना',
    'छीलनेवाला',
    'छीलर',
    'छुअन',
    'छुआ',
    'छुआई',
    'छुआछूत',
    'छुआने',
    'छुई',
    'छुईमुई',
    'छुओ',
    'छुकछुक',
    'छुट',
    'छुटका',
    'छुटकारा',
    'छुटटी',
    'छुटना',
    'छुटपन',
    'छुटपुट',
    'छुटभैया',
    'छुटवाने',
    'छुटा',
    'छुटाना',
    'छुटौती',
    'छुट्टा',
    'छुट्टियों',
    'छुट्टी',
    'छुट्टे',
    'छुठ',
    'छुड़वाई',
    'छुड़वाना',
    'छुड़वाने',
    'छुड़वैया',
    'छुड़ा',
    'छुड़ाई',
    'छुड़ाना',
    'छुड़ौती',
    'छुडाना',
    'छुतहा',
    'छुद्र',
    'छुद्रग्रह',
    'छुद्रता',
    'छुद्रान्त्र',
    'छुनछुन',
    'छुप',
    'छुपकर',
    'छुपने',
    'छुपा',
    'छुपौवल',
    'छुमछुम',
    'छुरा',
    'छुरिका',
    'छुरित',
    'छुरी',
    'छुरीः',
    'छुरे',
    'छुहारा',
    'छू',
    'छूँछा',
    'छूछक',
    'छूछा',
    'छूट',
    'छूटना',
    'छूटने',
    'छूटा',
    'छूत',
    'छूतग्रस्त',
    'छूतछात',
    'छूता',
    'छूना',
    'छूने',
    'छृट्टी',
    'छेंक',
    'छेंकना',
    'छेकना',
    'छेडछाड़',
    'छेडना',
    'छेड़',
    'छेड़खानी',
    'छेड़छाड़',
    'छेड़ना',
    'छेत्ता',
    'छेद',
    'छेदक',
    'छेददार',
    'छेदन',
    'छेदनहार',
    'छेदनहारा',
    'छेदना',
    'छेदनी',
    'छेदवाला',
    'छेदि',
    'छेदित',
    'छेदी',
    'छेदीला',
    'छेद्य',
    'छेद्यता',
    'छेना',
    'छेनाः',
    'छेनी',
    'छेपटी',
    'छेला',
    'छेवना',
    'छैंड़ी',
    'छैनी',
    'छैल',
    'छैला',
    'छैलापन',
    'छो',
    'छोंड़ा',
    'छोआ',
    'छोई',
    'छोकरा',
    'छोकरी',
    'छोटा',
    'छोटाई',
    'छोटापन',
    'छोटाबड़ाई',
    'छोटी',
    'छोटू',
    'छोटूराम',
    'छोटे',
    'छोडना',
    'छोडने',
    'छोड़',
    'छोड़कर',
    'छोड़ते',
    'छोड़ना',
    'छोड़ने',
    'छोड़ा',
    'छोड़ी',
    'छोप',
    'छोपना',
    'छोर',
    'छोरः',
    'छोरकी',
    'छोरच्छादी',
    'छोरस्थ',
    'छोरहीन',
    'छोरा',
    'छोरी',
    'छोलदारी',
    'छोला',
    'छोले',
    'छोह',
    'छोहगर',
    'छोहरा',
    'छोहरी',
    'छोही',
    'छौंक',
    'छौंकः',
    'छौंकना',
    'छौना',
    'ज',
    'जँगरैत',
    'जँगला',
    'जँचता',
    'जँचा',
    'जँचाई',
    'जँचाय',
    'जँचावदार',
    'जँभाई',
    'जँभीर',
    'जँवाई',
    'जंक',
    'जंकशन',
    'जंक्षन',
    'जंग',
    'जंगम',
    'जंगमता',
    'जंगरोधी',
    'जंगल',
    'जंगला',
    'जंगली',
    'जंगलीपन',
    'जंगाल',
    'जंगावर',
    'जंगी',
    'जंघा',
    'जंघाकार',
    'जंघाल',
    'जंजाल',
    'जंजालिया',
    'जंजीर',
    'जंजीरखाना',
    'जंजीरा',
    'जंतर',
    'जंता',
    'जंतु',
    'जंतुओं',
    'जंतुका',
    'जंतुशालास',
    'जंत्र',
    'जंत्री',
    'जंदरा',
    'जंदरे',
    'जंधा',
    'जंप',
    'जंपर',
    'जंबर',
    'जंबु',
    'जंबुक',
    'जंबुर',
    'जंबुल',
    'जंबू',
    'जंबूक',
    'जंबूर',
    'जंबूरक',
    'जंबो',
    'जंभ',
    'जंभाई',
    'जंभारि',
    'जंभारिस्थ',
    'जंभीर',
    'जंभीरी',
    'जंयती',
    'जंुजघर',
    'जअबीयत',
    'जई',
    'जक',
    'जकंदना',
    'जकड़',
    'जकड़न',
    'जकड़ना',
    'जकड़बंद',
    'जकड़ा',
    'जकड़ापन',
    'जकड़े',
    'जकरियाः',
    'जकात',
    'जक्ष',
    'जखनी',
    'जख़ीरा',
    'जख़्म',
    'जख़्मी',
    'जख़्में',
    'जग',
    'जगख्यात',
    'जगजयी',
    'जगजू',
    'जगज्जननी',
    'जगज्जयी',
    'जगण',
    'जगत',
    'जगतः',
    'जगती',
    'जगतीचर',
    'जगतीय',
    'जगत्कर्ता',
    'जगत्परसिद्व',
    'जगदंबा',
    'जगदगुरु',
    'जगदाचार्य',
    'जगदात्मा',
    'जगदादिज',
    'जगदाधार',
    'जगदालोक',
    'जगदीश',
    'जगदीश्वर',
    'जगद्गौरी',
    'जगद्घहा',
    'जगद्घाता',
    'जगद्घात्री',
    'जगद्दीप',
    'जगद्योनी',
    'जगद्विख्यात',
    'जगना',
    'जगन्नाथ',
    'जगन्मयी',
    'जगन्माता',
    'जगन्मोहिनी',
    'जगबीती',
    'जगभर',
    'जगमग',
    'जगमगाना',
    'जगमगाहट',
    'जगमन',
    'जगमाता',
    'जगर',
    'जगराता',
    'जगल',
    'जगह',
    'जगा',
    'जगाई',
    'जगाना',
    'जगाने',
    'जगान्नवास',
    'जगिन्नयंता',
    'जगिन्मथ्या',
    'जगुआर',
    'जघन',
    'जघन्य',
    'जघन्यता',
    'जच्चाघर',
    'जज',
    'जजमान',
    'जजमानी',
    'जजमेंट',
    'जजमैंट',
    'जज़ा',
    'जज़ीरा',
    'जज़ीरानुमा',
    'जजिंग',
    'जज्',
    'जटना',
    'जटल्ली',
    'जटा',
    'जटाघर',
    'जटाजुट',
    'जटाधारी',
    'जटामांसी',
    'जटामाली',
    'जटायु',
    'जटाल',
    'जटावल्ली',
    'जटित',
    'जटिल',
    'जटिलता',
    'जटिला',
    'जटी',
    'जट्टू',
    'जठर',
    'जठरशोथ',
    'जठराग्नि',
    'जठरिन',
    'जठरीय',
    'जठारोद्गार',
    'जड़',
    'जड़ंत',
    'जड़त',
    'जड़ता',
    'जड़त्वयुक्त',
    'जड़न',
    'जड़ना',
    'जड़ने',
    'जड़पूजा',
    'जड़बुद्धि',
    'जड़मति',
    'जड़वाद',
    'जड़हन',
    'जड़ा',
    'जड़ाई',
    'जड़ाऊ',
    'जड़ापन',
    'जड़ाव',
    'जड़ावदार',
    'जड़ावल',
    'जड़ित',
    'जड़िमा',
    'जड़िमाकारी',
    'जड़ी',
    'जड़ीकरण',
    'जड़ीभूत',
    'जड़ीभूतता',
    'जड़ों',
    'जडिया',
    'जतंबूकी',
    'जतंबूरक',
    'जतन',
    'जताइ',
    'जताना',
    'जताया',
    'जति',
    'जती',
    'जतुका',
    'जत्था',
    'जत्थाबंद',
    'जत्थाबंदी',
    'जत्थेदार',
    'जत्थेदारी',
    'जत्रु',
    'जदि',
    'जद्दी',
    'जद्दो',
    'जन',
    'जनक',
    'जनकता',
    'जनकपुरी',
    'जनकात्मजा',
    'जनकों',
    'जनगणना',
    'जनजाति',
    'जनजातियां',
    'जनजातीय',
    'जनडुब्बा',
    'जनडुब्बापन',
    'जनतंत्र',
    'जनतन्त्रीय',
    'जनता',
    'जनती',
    'जनन',
    'जननक्षम',
    'जननक्षमता',
    'जननग्रन्थि',
    'जननय',
    'जननशील',
    'जननहीन',
    'जननहीनता',
    'जनना',
    'जननांग',
    'जननात्मक',
    'जननिद',
    'जननी',
    'जननेंद्रिय',
    'जननेद्रिय',
    'जननेन्द्रियां',
    'जनपति',
    'जनपथ',
    'जनपद',
    'जनपदाधीश',
    'जनपदालय',
    'जनपदीय',
    'जनपिय',
    'जनप्रियता',
    'जनभाषा',
    'जनभ्रुत',
    'जनभ्रुतता',
    'जनभ्रुति',
    'जनम',
    'जनमत',
    'जनमना',
    'जनमाना',
    'जनमान्य',
    'जनमेजय',
    'जनयिता',
    'जनयित्री',
    'जनयिष्णु',
    'जनरय',
    'जनरल',
    'जनराज्यवाद',
    'जनवक्षु',
    'जनवरी',
    'जनवाई',
    'जनवाद',
    'जनवादी',
    'जनवाना',
    'जनवास',
    'जनवासा',
    'जनवासे',
    'जनशून्य',
    'जनश्रुति',
    'जनसंकुल',
    'जनसंख्या',
    'जनसंचार',
    'जनसंपर्क',
    'जनसंहार',
    'जनसख्या',
    'जनसांख्यिक',
    'जनसाधारण',
    'जनसेवा',
    'जनहित',
    'जनहितार्थ',
    'जनहीन',
    'जना',
    'जनांकिकी',
    'जनांत',
    'जनांतिक',
    'जनांदोलन',
    'जनांना',
    'जनाकीर्ण',
    'जनाकीर्णता',
    'जनाचार',
    'जनाजा',
    'जनाधिनाथ',
    'जनाना',
    'जनानी',
    'जनानीपन',
    'जनापवाद',
    'जनाब',
    'जनाभ्रम',
    'जनाम्रय',
    'जनार्थ',
    'जनार्दन',
    'जनावर',
    'जनावर्त',
    'जनि',
    'जनिका',
    'जनित',
    'जनित्र',
    'जनिद',
    'जनिष्णु',
    'जनी',
    'जनीय',
    'जनूब',
    'जनूबी',
    'जनेऊ',
    'जनेऊधारी',
    'जनेत',
    'जनेवा',
    'जनेश',
    'जनेश्वर',
    'जनेष्ट',
    'जनैत',
    'जनों',
    'जनोत्तेजक',
    'जनोत्तेजकता',
    'जनोत्तेजन',
    'जनोपयोगी',
    'जनौ',
    'जनौध',
    'जन्तु',
    'जन्नत',
    'जन्नते',
    'जन्म',
    'जन्मकुण्डली',
    'जन्मगत',
    'जन्मगतता',
    'जन्मजात',
    'जन्मजातता',
    'जन्मजाते',
    'जन्मतः',
    'जन्मतिथि',
    'जन्मदाता',
    'जन्मदात्री',
    'जन्मदान',
    'जन्मदिन',
    'जन्मदेना',
    'जन्मना',
    'जन्मपत्री',
    'जन्मभूमि',
    'जन्मरणशील',
    'जन्मलग्न',
    'जन्मवत्र्म',
    'जन्मसिद्घ',
    'जन्मस्थली',
    'जन्मस्थान',
    'जन्मा',
    'जन्मांतर',
    'जन्मांतरवाद',
    'जन्मांध',
    'जन्मागत',
    'जन्मागतता',
    'जन्मादि',
    'जन्माधिप',
    'जन्माष्टमी',
    'जन्मे',
    'जन्मेजय',
    'जन्मेश',
    'जन्मोत्सव',
    'जन्य',
    'जऩाजा',
    'जप',
    'जपजी',
    'जपतप',
    'जपताप',
    'जपना',
    'जपनी',
    'जपमाला',
    'जपा',
    'जपिया',
    'जपी',
    'जपुजी',
    'जफर',
    'जफाकशी',
    'जफाकार',
    'जफ्फा',
    'जब',
    'जबकि',
    'जबकिया',
    'जबड़ा',
    'जबड़े',
    'जबड़ों',
    'जबन',
    'जबरजद',
    'जबरदस्त',
    'जबरदस्ती',
    'जबरन',
    'जबरी',
    'जबर्दस्त',
    'जबर्दस्ती',
    'जबलपुर',
    'जबाड़ा',
    'जबान',
    'जबानदराज़ी',
    'जबानदान',
    'जबानबंद',
    'जबानबंदी',
    'जबानी',
    'जबाब',
    'जबी',
    'जबीया',
    'जबूर',
    'जबे',
    'जब्त',
    'जब्तशुदा',
    'जब्ती',
    'जभी',
    'जमकर',
    'जमकातर',
    'जमघट',
    'जमघटा',
    'जमज',
    'जमजम',
    'जमना',
    'जमनात',
    'जमने',
    'जमशेद',
    'जमा',
    'जमांबंदी',
    'जमाई',
    'जमाऊ',
    'जमाकर',
    'जमाकर्ता',
    'जमाकर्ताओं',
    'जमाखच्र',
    'जमाखर्च',
    'जमाख़ोरी',
    'जमाखाते',
    'जमाखोर',
    'जमाखोरी',
    'जमात',
    'जमाती',
    'जमादार',
    'जमादारी',
    'जमादी',
    'जमानत',
    'जमानतदार',
    'जमानतनामा',
    'जमानती',
    'जमाना',
    'जमानासाज',
    'जमानासाजी',
    'जमाने',
    'जमापत्र',
    'जमापन',
    'जमापर्ची',
    'जमाबंदी',
    'जमाया',
    'जमाराशि',
    'जमाराशियां',
    'जमाराशियों',
    'जमाल',
    'जमालघोटा',
    'जमाली',
    'जमाव',
    'जमावट',
    'जमावड़ा',
    'जमाशेष',
    'जमी',
    'जमींकंद',
    'जमींदार',
    'जमींदारी',
    'जमींनदारी',
    'जमींनबोसी',
    'जमीदार',
    'जमीन',
    'जमीनी',
    'जमीर',
    'जमीला',
    'जमुन',
    'जमुना',
    'जमुनियाँ',
    'जमुनी',
    'जमुर्रद',
    'जमे',
    'जमोग',
    'जमोगना',
    'जम्मू',
    'जम्हाई',
    'जय',
    'जयंत',
    'जयंतः',
    'जयंती',
    'जयकर्ता',
    'जयकार',
    'जयघंट',
    'जयघोष',
    'जयजयकार',
    'जयजयकृत',
    'जयजयवंती',
    'जयत्सेन',
    'जयद',
    'जयद्रथ',
    'जयद्वल',
    'जयनिमा',
    'जयनी',
    'जयन्ती',
    'जयपत्र',
    'जयपत्री',
    'जयपराजयहीन',
    'जयपराजयहीनता',
    'जयपात्र',
    'जयपाल',
    'जयपुर',
    'जयप्रद',
    'जयमाल',
    'जयमाला',
    'जयम्री',
    'जयलक्ष्मी',
    'जयलेख',
    'जयशील',
    'जया',
    'जयित',
    'जयिष्णु',
    'जयी',
    'जर',
    'जरठ',
    'जरठता',
    'जरण',
    'जरत्कारु',
    'जरत्कारुः',
    'जरत्व',
    'जरनल',
    'जरनैल',
    'जरमनवाद',
    'जरसी',
    'जरा',
    'जराक्षीणता',
    'जराग्रस्तता',
    'जराचिकित्सीय',
    'जराजीर्ण',
    'जराजीर्णता',
    'जरातुर',
    'जरायमपेशा',
    'जरायु',
    'जरायुक्त',
    'जरायुज',
    'जरासंघ',
    'जरासंधः',
    'जरासंधजित',
    'जरासीम',
    'जरासुत',
    'जराह',
    'जराहत',
    'जराहीन',
    'जराहीनता',
    'जरिए',
    'जरी',
    'जरीदा',
    'जरीब',
    'जरीबकश',
    'जरीबकशी',
    'जरूर',
    'जरूरत',
    'जरूरतमंद',
    'जरूरतें',
    'जरूरतों',
    'जरूरी',
    'जर्किन',
    'जर्जर',
    'जर्जरता',
    'जर्जरित',
    'जर्जरीक़',
    'जर्ण',
    'जर्द',
    'जर्दालु',
    'जर्दी',
    'जर्नल',
    'जर्नलिज्म',
    'जर्नलिस्ट',
    'जर्नी',
    'जर्म',
    'जर्मन',
    'जर्मनिक',
    'जर्मनी',
    'जर्मनीवासी',
    'जर्रा',
    'जर्राह',
    'जर्राही',
    'जर्सी',
    'जल',
    'जलंधर',
    'जलः',
    'जलःहिमांक',
    'जलकपाट',
    'जलकपाटपाल',
    'जलकपि',
    'जलकर',
    'जलकल',
    'जलकाक',
    'जलकुंड',
    'जलकूप',
    'जलकूर्म',
    'जलकेलि',
    'जलक्रीड़ा',
    'जलक्रीडा',
    'जलखग',
    'जलखर',
    'जलखरी',
    'जलगम्य',
    'जलगम्यता',
    'जलगर्द',
    'जलगल्म',
    'जलगामिता',
    'जलग्रीवा',
    'जलघर',
    'जलचक्र',
    'जलचर',
    'जलचरा',
    'जलचरी',
    'जलज',
    'जलजंतु',
    'जलजला',
    'जलजलाना',
    'जलज़ीरा',
    'जलजात',
    'जलजीव',
    'जलजीवपाल',
    'जलजीवी',
    'जलतल',
    'जलता',
    'जलत्राण',
    'जलत्रास',
    'जलथलचर',
    'जलद',
    'जलदस्यु',
    'जलदस्युता',
    'जलदस्यू',
    'जलदा',
    'जलदाता',
    'जलदान',
    'जलदाशन',
    'जलदीप',
    'जलद्रोणी',
    'जलद्वार',
    'जलधंर',
    'जलधड़ी',
    'जलधर',
    'जलधरी',
    'जलधार',
    'जलधारा',
    'जलधिज',
    'जलन',
    'जलनखोर',
    'जलना',
    'जलनिकास',
    'जलनिकासी',
    'जलनिधि',
    'जलने',
    'जलनेवाला',
    'जलपक्षी',
    'जलपति',
    'जलपथ',
    'जलपना',
    'जलपरी',
    'जलपात्र',
    'जलपान',
    'जलपानगृह',
    'जलपाश',
    'जलपीटिका',
    'जलपुरुष',
    'जलपूर्ण',
    'जलपोत',
    'जलप्रपा',
    'जलप्रपात',
    'जलप्रलय',
    'जलप्रांत',
    'जलप्लाव',
    'जलप्लावित',
    'जलफलक',
    'जलबहुल',
    'जलभीति',
    'जलभूमि',
    'जलभृंग',
    'जलभ्रम',
    'जलमग्न',
    'जलमय',
    'जलमल',
    'जलमहल',
    'जलमात',
    'जलमार्ग',
    'जलमीटर',
    'जलमुक्त',
    'जलमुर्गे',
    'जलमोद',
    'जलयष्टि',
    'जलयात्रा',
    'जलयान',
    'जलयानों',
    'जलयुक्त',
    'जलयोजन',
    'जलयोजित',
    'जलरंक',
    'जलरंकू',
    'जलरंग',
    'जलरंड',
    'जलरस',
    'जलराशि',
    'जलराशिय',
    'जलरुद्घ',
    'जलरुह',
    'जलरोधी',
    'जलव',
    'जलवातारक',
    'जलवायु',
    'जलवासी',
    'जलवाह',
    'जलवाहक',
    'जलवाहा',
    'जलवाही',
    'जलविहार',
    'जलव्याल',
    'जलशायी',
    'जलशोफ',
    'जलशोफीय',
    'जलसंधि',
    'जलसर्प',
    'जलसह',
    'जलसा',
    'जलसागाह',
    'जलसिक्त',
    'जलसूचि',
    'जलसूत',
    'जलसेवा',
    'जलसोता',
    'जलस्तर',
    'जलस्त्रोतयुक्त',
    'जलस्थलचर',
    'जलस्रोत',
    'जलस्रोतयुक्त',
    'जलहर',
    'जलहरी',
    'जलहार',
    'जलहारी',
    'जलहास',
    'जलहीन',
    'जलहीनता',
    'जला',
    'जलाँका',
    'जलांक',
    'जलांजलि',
    'जलांतक',
    'जलांतराल',
    'जलांदोलन',
    'जलांबिका',
    'जलाई',
    'जलाऊ',
    'जलाकर',
    'जलाका',
    'जलाक्रांत',
    'जलाखु',
    'जलागार',
    'जलाजल',
    'जलात्यय',
    'जलाधिप',
    'जलाना',
    'जलानाः',
    'जलाने',
    'जलान्तक',
    'जलाबर्त',
    'जलाभास',
    'जलाभिषेक',
    'जलायुका',
    'जलाये',
    'जलार्द',
    'जलाल',
    'जलाली',
    'जलालुक',
    'जलावगाहन',
    'जलावतन',
    'जलावतनी',
    'जलावतरण',
    'जलावतरित',
    'जलावतार',
    'जलावतारक',
    'जलावतारण',
    'जलावतारित',
    'जलावतीर्ण',
    'जलावन',
    'जलावनःलकडी',
    'जलावरोध',
    'जलावर्त',
    'जलावारण',
    'जलाशय',
    'जलाशयी',
    'जलाहल',
    'जलाहारी',
    'जलिस्वनी',
    'जली',
    'जलीय',
    'जलील',
    'जलूका',
    'जलूल',
    'जलूस',
    'जले',
    'जलेबी',
    'जलेवाह',
    'जलेश',
    'जलेशय',
    'जलेश्वर',
    'जलोच्छ्वास',
    'जलोढ़',
    'जलोदर',
    'जलोद्घहन',
    'जलोद्भवा',
    'जलौका',
    'जलौध',
    'जल्द',
    'जल्दबाज',
    'जल्दबाज़',
    'जल्दबाज़ी',
    'जल्दबाजी',
    'जल्दबाज़',
    'जल्दी',
    'जल्प',
    'जल्पक',
    'जल्पना',
    'जल्लाद',
    'जल्वए',
    'जवन',
    'जवनिका',
    'जवनी',
    'जवस',
    'जवहर',
    'जवा',
    'जवाँ',
    'जवाखार',
    'जवाधिक',
    'जवान',
    'जवानसाल',
    'जवानी',
    'जवाब',
    'जवाबतलबी',
    'जवाबदेह',
    'जवाबदेही',
    'जवाबी',
    'जवाहर',
    'जवाहरलाल',
    'जवाहरात',
    'जवाहारात',
    'जवाहिर',
    'जशोदा',
    'जश्न',
    'जस',
    'जसगाथा',
    'जसद',
    'जसमति',
    'जसवत',
    'जसीम',
    'जसुदा',
    'जसोदा',
    'जसोमति',
    'जसोवै',
    'जस्टिस',
    'जस्त',
    'जस्ता',
    'जस्तेदार',
    'जहज',
    'जहद',
    'जहद्दम',
    'जहर',
    'जहरीला',
    'जहरीलापन',
    'जहश्रुम',
    'जहाँ',
    'जहाँगर्द',
    'जहाँगर्दी',
    'जहाँगश्त',
    'जहाँगीर',
    'जहाँदीदा',
    'जहाँनुमा',
    'जहाँपनाह',
    'जहाँबी',
    'जहाँबीनी',
    'जहां',
    'जहांगीर',
    'जहाज',
    'जहाजः',
    'जहाजरान',
    'जहाजरानी',
    'जहाज़',
    'जहाजी',
    'जहाजों',
    'जहान',
    'जहानता',
    'जहालत',
    'जहाज़',
    'जहाज़ी',
    'जहीन',
    'जहीर',
    'जहृनू',
    'जहै',
    'जहोवा',
    'ज़ंग',
    'ज़कंद',
    'ज़कन',
    'ज़कर',
    'ज़कोर्न',
    'ज़खीरेदार',
    'ज़चगी',
    'ज़चा',
    'ज़च्चा',
    'ज़च्चाख़ाना',
    'ज़द',
    'ज़दा',
    'ज़न',
    'ज़नख़',
    'ज़नख़ा',
    'ज़नख़ापन',
    'ज़नानख़ाना',
    'ज़नानखाना',
    'ज़नाना',
    'ज़ऩाना',
    'ज़बब्त',
    'ज़ब्ती',
    'ज़मज़म',
    'ज़मान',
    'ज़मानत',
    'ज़माना',
    'ज़माने',
    'ज़मींदारी',
    'ज़मीकंद',
    'ज़मीन',
    'ज़र',
    'ज़रकशी',
    'ज़रक़ून',
    'ज़रकार',
    'ज़रख़रीद',
    'ज़रख़ेज़',
    'ज़रखे',
    'ज़रतारी',
    'ज़रतुश्ती',
    'ज़रदोज़ी',
    'ज़रनिशाँगरी',
    'ज़रनिशानी',
    'ज़रपाश',
    'ज़रब',
    'ज़रबफ्',
    'ज़रबाफ़',
    'ज़रबाफ़ी',
    'ज़रर',
    'ज़रररिसौ',
    'ज़रा',
    'ज़रिए',
    'ज़रिया',
    'ज़री',
    'ज़रीफ़',
    'ज़रुर',
    'ज़रुरत',
    'ज़रुरतमंद',
    'ज़रुरतमंदी',
    'ज़रुरियात',
    'ज़रुरी',
    'ज़रूर',
    'ज़र्क',
    'ज़र्ब',
    'ज़र्मी',
    'ज़ल्फि',
    'ज़हमत',
    'ज़हर',
    'ज़हरबाद',
    'ज़हरा',
    'ज़हरी',
    'ज़हरीला',
    'ज़ात',
    'ज़ाना',
    'ज़ाफरी',
    'ज़ाफ़री',
    'ज़ाफ़ारान',
    'ज़ामिन',
    'ज़ायका',
    'ज़ायकेदार',
    'ज़ायकेदारी',
    'ज़ायचा',
    'ज़ायद',
    'ज़ाया',
    'ज़ार',
    'ज़ारची',
    'ज़ार्जेट',
    'ज़ालिम',
    'ज़ाहिर',
    'ज़ाहिरन',
    'ज़ाहिरा',
    'ज़ाहिरी',
    'ज़ीक्राद',
    'ज़ीन',
    'ज़ीनत',
    'ज़ुंबाँ',
    'ज़ुकाम',
    'ज़ुदा',
    'ज़ुबान',
    'ज़ुरमाना',
    'ज़ुर्रत',
    'ज़ुलाब',
    'ज़ुल्म',
    'ज़ुल्मी',
    'ज़ुल्मीपन',
    'ज़ुल्मो',
    'ज़ेब',
    'ज़ेबा',
    'ज़ेबो',
    'ज़ेब्रा',
    'ज़ेर',
    'ज़ेरबार',
    'ज़ेवर',
    'ज़ेहन',
    'ज़ेह्न',
    'ज़ेह्नी',
    'ज़ेह्नीयत',
    'ज़ैन',
    'ज़ैपलिन',
    'ज़ैरौक्स',
    'ज़ैल',
    'ज़ैली',
    'ज़ोन',
    'ज़ोनल',
    'ज़ोर',
    'ज़ोरदार',
    'ज़ोरादर',
    'ज़ौक़',
    'जा',
    'जाँगर',
    'जाँगरा',
    'जाँगलू',
    'जाँघ',
    'जाँघा',
    'जाँच',
    'जाँचकर्ता',
    'जाँचकृत',
    'जाँचना',
    'जाँचा',
    'जाँता',
    'जाँधिया',
    'जाँनशीन',
    'जाँनिसार',
    'जाँनिसारी',
    'जाँबाज',
    'जाँबाजी',
    'जांगल',
    'जांगली',
    'जांघ',
    'जांघिक',
    'जांघिया',
    'जांच',
    'जांचकर्ता',
    'जांचना',
    'जांचा',
    'जांतव',
    'जांतविक',
    'जांबवंत',
    'जांबवंती',
    'जांबवान',
    'जांबील',
    'जांबू',
    'जाइये',
    'जाइरो',
    'जाइरोस्कोप',
    'जाई',
    'जाए',
    'जाएं',
    'जाएंगी',
    'जाएंगे',
    'जाएगा',
    'जाएगी',
    'जाओ',
    'जाकट',
    'जाकड',
    'जाकड़',
    'जाकर',
    'जाखिनी',
    'जाग',
    'जागता',
    'जागतिक',
    'जागते',
    'जागना',
    'जागर',
    'जागरण',
    'जागरा',
    'जागरुक',
    'जागरुकता',
    'जागरूक',
    'जागरूकता',
    'जागा',
    'जागीर',
    'जागीरदार',
    'जागीरदारी',
    'जागृत',
    'जागृति',
    'जाग्रत',
    'जाग्रतता',
    'जाग्रति',
    'जाग्ररत',
    'जाचक',
    'जाचकताई',
    'जाजम',
    'जाजलि',
    'जाजिम',
    'जाज्वल्यता',
    'जाज्वल्यमान',
    'जाठर',
    'जाडय',
    'जाड़ा',
    'जाड़े',
    'जात',
    'जातक',
    'जातदंत',
    'जातपक्ष',
    'जातपुत्रा',
    'जातरा',
    'जाता',
    'जाति',
    'जातिः',
    'जातिगत',
    'जातिगतता',
    'जातिच्युत',
    'जातिज्यूत',
    'जातिपर्ण',
    'जातिफल',
    'जातिभ्रंष्ट',
    'जातिया',
    'जातियाँ',
    'जातियां',
    'जातियुक्त',
    'जातिवाचक',
    'जातिवाद',
    'जातिवृत्त',
    'जातिहीन',
    'जाती',
    'जातीय',
    'जातीयता',
    'जातुधान',
    'जाते',
    'जातेष्टि',
    'जात्य',
    'जात्रा',
    'जात्री',
    'जादा',
    'जादी',
    'जादुई',
    'जादुगर',
    'जादू',
    'जादूकरण',
    'जादूकृत',
    'जादूगर',
    'जादूगरनी',
    'जादूगरी',
    'जान',
    'जानकर',
    'जानकार',
    'जानकारी',
    'जानकी',
    'जानता',
    'जानते',
    'जानदार',
    'जानदेश',
    'जानन',
    'जाननहार',
    'जानना',
    'जाननी',
    'जानने',
    'जाननेवाला',
    'जानपद',
    'जानपदी',
    'जानपदीक',
    'जानपहचान',
    'जानफलक',
    'जानबख्शी',
    'जानबूझ',
    'जानबूझकर',
    'जानमाज',
    'जानमान्यता',
    'जानलेवा',
    'जानवर',
    'जानवरपन',
    'जानवरों',
    'जानहूँ',
    'जाना',
    'जानापन',
    'जानामानापान',
    'जानारी',
    'जानिब',
    'जानिबदार',
    'जानिबदारी',
    'जानिसार',
    'जानी',
    'जानु',
    'जानुफलक',
    'जानू',
    'जाने',
    'जानेजाँ',
    'जानेबाज़',
    'जानेमन',
    'जानेवाला',
    'जानेवाली',
    'जानो',
    'जान्तव',
    'जान्विका',
    'जाप',
    'जापक',
    'जापताप',
    'जापा',
    'जापाघर',
    'जापानी',
    'जापी',
    'जाफ़री',
    'जाफ्फा',
    'जाबा',
    'जाबालि',
    'जाब्ता',
    'जाम',
    'जामक',
    'जामगी',
    'जामदग्न्य',
    'जामदानी',
    'जामन',
    'जामनत',
    'जामवंत',
    'जामा',
    'जामाता',
    'जामातृ',
    'जामावार',
    'जामि',
    'जामिन',
    'जामिया',
    'जामी',
    'जामुन',
    'जामुनी',
    'जामे',
    'जामेय',
    'जायंट',
    'जायका',
    'जायकेदार',
    'जायज़',
    'जायज़ता',
    'जायज़ा',
    'जायदाद',
    'जायदादी',
    'जायफल',
    'जाया',
    'जायाकुसुम',
    'जायाजीवी',
    'जायानुजीवी',
    'जाये',
    'जायें',
    'जायेगा',
    'जायेगी',
    'जायज़',
    'जार',
    'जारज',
    'जारजात',
    'जारण',
    'जारना',
    'जारिणी',
    'जारित',
    'जारी',
    'जारीकर्ता',
    'जारेय',
    'जारोत्पन्न',
    'जारोबकश',
    'जारज़',
    'जाल',
    'जालंधर',
    'जालक',
    'जालग्रस्तृ',
    'जालतानिका',
    'जालदार',
    'जालधारी',
    'जालन्धर',
    'जालपाद',
    'जालमय',
    'जालवत',
    'जालशयज',
    'जालसाज़',
    'जालसाज़ी',
    'जालसाज़',
    'जालसाज़ी',
    'जाला',
    'जालापाद',
    'जालिक',
    'जालिका',
    'जालिया',
    'जाली',
    'जालीदार',
    'जाले',
    'जाव',
    'जावक',
    'जावती',
    'जावन',
    'जावन्य',
    'जावाबी',
    'जावित्री',
    'जासूस',
    'जासूसी',
    'जाह',
    'जाहपरस्त',
    'जाहवे',
    'जाहिर',
    'जाहिल',
    'जाहिली',
    'जाहो',
    'जाह्नवी',
    'जाफ़रान',
    'जाफ़री',
    'जि',
    'जिंक',
    'जिंजर',
    'जिंदगानी',
    'जिंदगी',
    'जिंदादिल',
    'जिंस',
    'जिंसखाना',
    'जिंसवार',
    'जिंसवारी',
    'जिंसी',
    'जिक्र',
    'जिगर',
    'जिगरा',
    'जिगरी',
    'जिगरीपन',
    'जिग्म',
    'जिघत्सु',
    'जिघर',
    'जिघांसु',
    'जिजमान',
    'जिजीविषा',
    'जिज्ञापयिषा',
    'जिज्ञासा',
    'जिज्ञासाजनक',
    'जिज्ञासापूर्वक',
    'जिज्ञासित',
    'जिज्ञासु',
    'जिज्ञासुहीन',
    'जिज्ञास्य',
    'जिज्ञास्यता',
    'जिठानी',
    'जित',
    'जितकाम',
    'जितक्रोध',
    'जितना',
    'जितनी',
    'जितने',
    'जितमन्यु',
    'जितम्रम',
    'जितशत्रु',
    'जितसंग',
    'जिताक्षार',
    'जितात्मा',
    'जितारि',
    'जितावनहार',
    'जितावनहारा',
    'जिति',
    'जितेंद्र',
    'जितेंद्रता',
    'जितेंद्रिय',
    'जितेंद्रियता',
    'जितै',
    'जितैया',
    'जित्चर',
    'जित्चा',
    'जित्ता',
    'जित्य',
    'जित्या',
    'जिद्दी',
    'जिधर',
    'जिन',
    'जिनका',
    'जिनकी',
    'जिनके',
    'जिनमें',
    'जिनरिकशा',
    'जिनसे',
    'जिनेंद्र',
    'जिनों',
    'जिन्दा',
    'जिन्दादिल',
    'जिन्न',
    'जिन्नात',
    'जिन्नाती',
    'जिन्नाबाद',
    'जिन्नी',
    'जिन्हीं',
    'जिन्हें',
    'जिन्हों',
    'जिन्होंने',
    'जिप',
    'जिपसम',
    'जिप्सम',
    'जिप्सी',
    'जिम',
    'जिमनेजि',
    'जिमाई',
    'जिमाना',
    'जिमाने',
    'जिमि',
    'जिम्नैस्ट',
    'जिम्नैस्टिक्स',
    'जिम्मा',
    'जिम्मे',
    'जिम्मेदार',
    'जिम्मेदारी',
    'जियो',
    'जिरगां',
    'जिरह',
    'जिरहकर्ता',
    'जिरहकार',
    'जिरहबाज़',
    'जिरहबाज़ी',
    'जिराफ़',
    'जिराफ़',
    'जिर्राफ़',
    'जिला',
    'जिलाधिकारी',
    'जिलाधीश',
    'जिलाना',
    'जिले',
    'जिलों',
    'जिल्द',
    'जिल्दः',
    'जिल्दखाना',
    'जिल्दगर',
    'जिल्दगरी',
    'जिल्ददार',
    'जिल्दबंद',
    'जिल्दबंदी',
    'जिल्दबद्घ',
    'जिल्दसाज',
    'जिल्दसाजी',
    'जिल्दसाज़',
    'जिल्दसाज़ी',
    'जिल्लो',
    'जिव्हा',
    'जिषु',
    'जिस',
    'जिसका',
    'जिसकी',
    'जिसके',
    'जिसको',
    'जिसने',
    'जिसमें',
    'जिससे',
    'जिसे',
    'जिस्म',
    'जिस्मानी',
    'जिहाद',
    'जिहादी',
    'जिहृवा',
    'जिहेज़',
    'जिह्मग',
    'जिह्मगति',
    'जिह्रिका',
    'जिह्व',
    'जिह्वा',
    'जिह्वाग्र',
    'जिह्वाहीनता',
    'जिह्वीय',
    'जी',
    'जींस',
    'जीआई',
    'जीजा',
    'जीजी',
    'जीणोर्द्घार',
    'जीणोर्द्घारक',
    'जीणोर्द्घृत',
    'जीत',
    'जीतना',
    'जीतने',
    'जीता',
    'जीतालू',
    'जीते',
    'जीतेंद्र',
    'जीतोड़',
    'जीदयुक्त',
    'जीदीय',
    'जीन',
    'जीनपोश',
    'जीना',
    'जीनियस',
    'जीने',
    'जीन्स',
    'जीप',
    'जीभ',
    'जीभी',
    'जीमखाना',
    'जीमनहार',
    'जीमना',
    'जीमने',
    'जीमूत',
    'जीर',
    'जीरक',
    'जीरा',
    'जीरो',
    'जीर्ण',
    'जीर्णता',
    'जीर्णावस्था',
    'जीर्णोद्धार',
    'जीव',
    'जीवंत',
    'जीवंतता',
    'जीवंती',
    'जीवः',
    'जीवक',
    'जीवकीय',
    'जीवघन',
    'जीवघात',
    'जीवघाती',
    'जीवघ्न',
    'जीवजंतु',
    'जीवजात',
    'जीवजीव',
    'जीवट',
    'जीवटदार',
    'जीवत',
    'जीवत्पति',
    'जीवत्वरोपी',
    'जीवत्वारोपण',
    'जीवथ',
    'जीवद',
    'जीवदान',
    'जीवद्रव्य',
    'जीवधानी',
    'जीवधारी',
    'जीवन',
    'जीवनः',
    'जीवनकाल',
    'जीवनघ्न',
    'जीवनचर्या',
    'जीवनद',
    'जीवनदर्शन',
    'जीवनदायी',
    'जीवनधारी',
    'जीवनप्रद',
    'जीवनयापन',
    'जीवनयुक्त',
    'जीवनयुक्तता',
    'जीवनवृत',
    'जीवनहर',
    'जीवनहीन',
    'जीवनहीनता',
    'जीवनांकिक',
    'जीवनी',
    'जीवनेच्छा',
    'जीवनोत्सर्ग',
    'जीवनोद्घार',
    'जीवनोन्मुखता',
    'जीवनोपयोगी',
    'जीवनोपरांत',
    'जीवन्मृत',
    'जीवन्यास',
    'जीवभौतिकी',
    'जीवमामृका',
    'जीवम्रेष्ठ',
    'जीवयाज',
    'जीवरसायनज्ञ',
    'जीववाद',
    'जीववादी',
    'जीवविज्ञान',
    'जीवविज्ञानी',
    'जीवव्य',
    'जीवशाला',
    'जीवशेष',
    'जीवहीन',
    'जीवा',
    'जीवांतक',
    'जीवांतर',
    'जीवांश',
    'जीवाणु',
    'जीवाणुविज्ञान',
    'जीवाणुविज्ञानी',
    'जीवाति',
    'जीवाधार',
    'जीवानांक',
    'जीवामिन',
    'जीवाश्म',
    'जीवाश्मिकी',
    'जीवाश्मी',
    'जीवाश्मीभवन',
    'जीवाश्मीय',
    'जीविका',
    'जीविकाः',
    'जीविकाहीन',
    'जीविकीय',
    'जीवित',
    'जीवितव्य',
    'जीविता',
    'जीवितांतक',
    'जीवितेश',
    'जीवितेश्वर',
    'जीवी',
    'जीवेश',
    'जीवों',
    'जीवोत्पत्ति',
    'जीसस',
    'जुँधरी',
    'जुआ',
    'जुआं',
    'जुआः',
    'जुआख़ाना',
    'जुआघर',
    'जुआठना',
    'जुआठा',
    'जुआधारी',
    'जुआर',
    'जुआरी',
    'जुइँ',
    'जुई',
    'जुए',
    'जुएबाज',
    'जुएबाज़',
    'जुएबाज़ी',
    'जुकाम',
    'जुग',
    'जुगजुगाना',
    'जुगजुगी',
    'जुगत',
    'जुगतबाज़',
    'जुगतिया',
    'जुगती',
    'जुगनी',
    'जुगनू',
    'जुगनूँ',
    'जुगराफ़िया',
    'जुगल',
    'जुगवना',
    'जुगाल',
    'जुगालना',
    'जुगाली',
    'जुगुनू',
    'जुगुप्सा',
    'जुगुप्सु',
    'जुजबंदी',
    'जुज़',
    'जुज़दान',
    'जुज़बंदीः',
    'जुज्',
    'जुझन',
    'जुझार',
    'जुझारू',
    'जुट',
    'जुटना',
    'जुटा',
    'जुटाई',
    'जुटाकर',
    'जुटाना',
    'जुटाने',
    'जुटापन',
    'जुटाया',
    'जुटायी',
    'जुटाव',
    'जुटे',
    'जुट्टी',
    'जुट्ठा',
    'जुट्ठी',
    'जुठलाना',
    'जुठारना',
    'जुठारा',
    'जुड़',
    'जुड़न',
    'जुड़ना',
    'जुड़वाँ',
    'जुड़वाँपन',
    'जुड़वां',
    'जुड़ा',
    'जुड़ाई',
    'जुड़ाना',
    'जुड़ापन',
    'जुड़ाव',
    'जुड़ी',
    'जुड़े',
    'जुतका',
    'जुतना',
    'जुता',
    'जुताई',
    'जुतिऔवल',
    'जुतियाना',
    'जुतैया',
    'जुथा',
    'जुदा',
    'जुदाई',
    'जुदापन',
    'जुनून',
    'जुनूनी',
    'जुन्हरिया',
    'जुन्हरी',
    'जुन्हाई',
    'जुपिटर',
    'जुबली',
    'जुबिलि',
    'जुबिली',
    'जुमला',
    'जुमा',
    'जुमेरात',
    'जुम्मा',
    'जुराब',
    'जुरी',
    'जुर्म',
    'जुर्माना',
    'जुर्राब',
    'जुल',
    'जुलता',
    'जुलना',
    'जुलबाज़',
    'जुलबाज़ी',
    'जुलम',
    'जुला',
    'जुलाई',
    'जुलाब',
    'जुलाबी',
    'जुलाहा',
    'जुलाहागीरी',
    'जुली',
    'जुलूस',
    'जुल्फ',
    'जुष्ट',
    'जुस्तजु',
    'जुस्तजू',
    'जुस्सा',
    'जुहार',
    'जुहारू',
    'जुही',
    'जुहू',
    'जुहूराण',
    'जूं',
    'जूआ',
    'जूजू',
    'जूझ',
    'जूझना',
    'जूट',
    'जूठन',
    'जूठा',
    'जूठापन',
    'जूड़ा',
    'जूड़ाः',
    'जूड़ाइज्',
    'जूड़ी',
    'जूता',
    'जूताग्र',
    'जूती',
    'जूते',
    'जूतों',
    'जूथ',
    'जूथना',
    'जूथी',
    'जूद',
    'जून',
    'जूना',
    'जूनापन',
    'जूनियर',
    'जूरर',
    'जूरी',
    'जूरीमैन',
    'जूर्णि',
    'जूलहिज्ज',
    'जूष',
    'जूस',
    'जूसर',
    'जूसी',
    'जूही',
    'जृंभ',
    'जेंताक',
    'जेइ',
    'जेउ',
    'जेठ',
    'जेठा',
    'जेठानी',
    'जेठी',
    'जेठीमधु',
    'जेठौत',
    'जेठौता',
    'जेड',
    'जेता',
    'जेतिक',
    'जेतो',
    'जेब',
    'जेबकट',
    'जेबकटाई',
    'जेबकतरा',
    'जेबखर्च',
    'जेबख़र्च',
    'जेबमारी',
    'जेबरा',
    'जेबी',
    'जेमन',
    'जेय',
    'जेर',
    'जेरोएक्स',
    'जेल',
    'जेलख़ाना',
    'जेलखाना',
    'जेलघर',
    'जेलयात्रा',
    'जेलर',
    'जेली',
    'जेवड़ा',
    'जेवड़ी',
    'जेवना',
    'जेवनार',
    'जेवरा',
    'जेसू',
    'जेसे',
    'जेहाद',
    'जेहि',
    'जेहोवा',
    'जैंजिया',
    'जैंट्स',
    'जैक',
    'जैकअप',
    'जैकब',
    'जैकार',
    'जैकारा',
    'जैकिंग',
    'जैकिट',
    'जैकेट',
    'जैगीषव्य',
    'जैगीषव्यः',
    'जैट',
    'जैटी',
    'जैतपत्र',
    'जैतून',
    'जैतूनी',
    'जैत्र',
    'जैन',
    'जैनी',
    'जैन्य',
    'जैम',
    'जैमिग',
    'जैमिनी',
    'जैरी',
    'जैल',
    'जैव',
    'जैवतत्व',
    'जैवभौतिकी',
    'जैविक',
    'जैविकी',
    'जैविकीय',
    'जैश्चर',
    'जैस',
    'जैसवाल',
    'जैसा',
    'जैसी',
    'जैसे',
    'जैसेः',
    'जैसो',
    'जैह्व',
    'जो',
    'जोंक',
    'जोंकमारी',
    'जोंगट',
    'जोइ',
    'जोउ',
    'जोक',
    'जोकर',
    'जोकरपना',
    'जोकि',
    'जोकी',
    'जोख',
    'जोखन',
    'जोखना',
    'जोख़िम',
    'जोखा',
    'जोखित',
    'जोखिम',
    'जोखिमपूर्ण',
    'जोखिमयुक्त',
    'जोखिमवाला',
    'जोखिमवाली',
    'जोखिमवाले',
    'जोखिमों',
    'जोखो',
    'जोग',
    'जोगन',
    'जोगनी',
    'जोगा',
    'जोगिन',
    'जोगिया',
    'जोगी',
    'जोगींद्र',
    'जोगीड़ा',
    'जोगीश्वर',
    'जोगेश',
    'जोगेश्वर',
    'जोजन',
    'जोट',
    'जोटी',
    'जोड',
    'जोडदार',
    'जोडना',
    'जोड़',
    'जोड़कर',
    'जोड़तोड़',
    'जोड़दार',
    'जोड़ना',
    'जोड़ने',
    'जोड़नेवाला',
    'जोड़वाँ',
    'जोड़हीन',
    'जोड़हीनता',
    'जोड़ा',
    'जोड़िया',
    'जोड़ी',
    'जोड़ीदार',
    'जोड़ीदारी',
    'जोड़े',
    'जोडा',
    'जोडीदार',
    'जोडीवाल',
    'जोडू',
    'जोडे',
    'जोत',
    'जोतदार',
    'जोतनहार',
    'जोतना',
    'जोतनी',
    'जोतने',
    'जोतनेवाला',
    'जोतवान',
    'जोतषी',
    'जोता',
    'जोताई',
    'जोतिस',
    'जोतिसी',
    'जोती',
    'जोतों',
    'जोधपुर',
    'जोधा',
    'जोनिसारी',
    'जोन्हारी',
    'जोम',
    'जोर',
    'जोरदार',
    'जोराजोरी',
    'जोरु',
    'जोरुजाता',
    'जोल',
    'जोवन',
    'जोश',
    'जोशपूर्ण',
    'जोशाँदा',
    'जोशी',
    'जोशीला',
    'जोशुआ',
    'जोशे',
    'जोशो',
    'जोषण',
    'जोषणा',
    'जोह',
    'जोहड़',
    'जोहन',
    'जोहना',
    'जोहरी',
    'जौ',
    'जौजीयात',
    'जौजेंट',
    'जौतुक',
    'जौन',
    'जौनिसार',
    'जौसेफ़',
    'जौहरी',
    'ज्ञ',
    'ज्ञपत',
    'ज्ञप्ति',
    'ज्ञयता',
    'ज्ञात',
    'ज्ञातकुल',
    'ज्ञातगोत्र',
    'ज्ञातता',
    'ज्ञातवंश',
    'ज्ञातवास',
    'ज्ञातव्य',
    'ज्ञातव्यता',
    'ज्ञाता',
    'ज्ञाति',
    'ज्ञातिपुत्र',
    'ज्ञान',
    'ज्ञानकर्म',
    'ज्ञानकृत',
    'ज्ञानकोश',
    'ज्ञानगम्य',
    'ज्ञानगम्यता',
    'ज्ञानगोचर',
    'ज्ञानगोष्ठी',
    'ज्ञानज्येष्ठ',
    'ज्ञानदग्धदेह',
    'ज्ञानदा',
    'ज्ञानदाता',
    'ज्ञाननिष्ठ',
    'ज्ञानपति',
    'ज्ञानपूर्ण',
    'ज्ञानपूर्वक',
    'ज्ञानप्रद',
    'ज्ञानप्रभ',
    'ज्ञानबोध',
    'ज्ञानमणि',
    'ज्ञानमूर्ति',
    'ज्ञानयुक्त',
    'ज्ञानयोग',
    'ज्ञानयोग्य',
    'ज्ञानशील',
    'ज्ञानहीन',
    'ज्ञानहीनता',
    'ज्ञानाजिर्त',
    'ज्ञानातीत',
    'ज्ञानातीतता',
    'ज्ञानार्जन',
    'ज्ञानार्थी',
    'ज्ञानालोक',
    'ज्ञानासन',
    'ज्ञानिकी',
    'ज्ञानी',
    'ज्ञानीत्व',
    'ज्ञानेंद्रिय',
    'ज्ञानेच्छुक',
    'ज्ञानेन्द्रिय',
    'ज्ञानेप्सु',
    'ज्ञानेश्वर',
    'ज्ञानोक्षति',
    'ज्ञानोदय',
    'ज्ञानोन्नति',
    'ज्ञापक',
    'ज्ञापन',
    'ज्ञापनपत्र',
    'ज्ञापनेच्छा',
    'ज्ञापनों',
    'ज्ञापयिता',
    'ज्ञापित',
    'ज्ञेय',
    'ज्ञेयवाद',
    'ज्या',
    'ज्यादतियां',
    'ज्यादती',
    'ज्यादा',
    'ज्यादातर',
    'ज्यामिति',
    'ज्यामितीय',
    'ज्यायस',
    'ज्येष्ठ',
    'ज्येष्ठता',
    'ज्येष्ठा',
    'ज्येष्ठाम्रम',
    'ज्येष्ठाम्रमी',
    'ज्यों',
    'ज्योत',
    'ज्योतन',
    'ज्योति',
    'ज्योतित',
    'ज्योतिनी',
    'ज्योतिमिर्ति',
    'ज्योतिर्मय',
    'ज्योतिलिंग',
    'ज्योतिविंद',
    'ज्योतिश्चक्र',
    'ज्योतिष',
    'ज्योतिषः',
    'ज्योतिषिक',
    'ज्योतिषी',
    'ज्योतिषीय',
    'ज्योतिष्क',
    'ज्योतिष्मती',
    'ज्योत्सना',
    'ज्योत्स्ना',
    'ज्योत्स्नाभीसारिका',
    'ज्योनार',
    'ज्योमैट्री',
    'ज्योश्चिक्र',
    'ज्यौनार',
    'ज्वर',
    'ज्वरग्रस्त',
    'ज्वरप्रद',
    'ज्वरमापी',
    'ज्वररोधी',
    'ज्वराभास',
    'ज्वरीय',
    'ज्वलंत',
    'ज्वलन',
    'ज्वलनशील',
    'ज्वलनांक',
    'ज्वलन्त',
    'ज्वलित',
    'ज्वार',
    'ज्वारतल',
    'ज्वारनदमुख',
    'ज्वारभाटा',
    'ज्वारी',
    'ज्वाल',
    'ज्वाला',
    'ज्वालाजिह्व',
    'ज्वालाध्वज',
    'ज्वालामुखी',
    'ज्वालायुक्त',
    'ज्वाली',
    'ज्वाहर',
    'ज्वैल',
    'ज्वैलर',
    'झ',
    'झँगा',
    'झँगुला',
    'झँगुलिया',
    'झँझरी',
    'झँझरीदार',
    'झँझोड़',
    'झँझोड़ना',
    'झँझोड़ा',
    'झँडूला',
    'झँपकना',
    'झँपन',
    'झँपना',
    'झँपरी',
    'झँपा',
    'झँपिया',
    'झँपोला',
    'झँवराना',
    'झँवाना',
    'झँसना',
    'झंकना',
    'झंकवैया',
    'झंकाड़',
    'झंकार',
    'झंकारदार',
    'झंकारना',
    'झंकृत',
    'झंकृता',
    'झंखाड़',
    'झंझट',
    'झंझटी',
    'झंझर',
    'झंझरा',
    'झंझरी',
    'झंझा',
    'झंझानिल',
    'झंझावात',
    'झंझावाती',
    'झंझोड़ना',
    'झंड',
    'झंड़ाका',
    'झंड़ाझड़ी',
    'झंडा',
    'झंडागीर',
    'झंडागीरी',
    'झंडामिवादन',
    'झंडी',
    'झंडू',
    'झंडे',
    'झंप',
    'झंपन',
    'झंपाक',
    'झंपाकी',
    'झंपान',
    'झंपानी',
    'झंपी',
    'झंब',
    'झंला',
    'झक',
    'झकझक',
    'झकझकिया',
    'झकझोर',
    'झकझोरना',
    'झकझोरा',
    'झकझोलना',
    'झकना',
    'झकाझक',
    'झकार',
    'झकुलना',
    'झकोरना',
    'झकोरा',
    'झकोल',
    'झकोला',
    'झकोले',
    'झक्क',
    'झक्कड़',
    'झक्की',
    'झक्कीपन',
    'झक्खना',
    'झख',
    'झगड़ना',
    'झगड़ा',
    'झगड़ालु',
    'झगड़ालू',
    'झगड़ालूपन',
    'झगड़ी',
    'झगड़े',
    'झगडा',
    'झगला',
    'झगा',
    'झगीलापन',
    'झगुली',
    'झज्झर',
    'झझकार',
    'झझकारना',
    'झट',
    'झटक',
    'झटकन',
    'झटकना',
    'झटका',
    'झटकाई',
    'झटकार',
    'झटकारना',
    'झटकाव',
    'झटकासह',
    'झटके',
    'झटा',
    'झटाक',
    'झडना',
    'झडप',
    'झडपना',
    'झडबेरी',
    'झड़',
    'झड़झडाना',
    'झड़ता',
    'झड़न',
    'झड़ना',
    'झड़प',
    'झड़पाझड़पी',
    'झड़ा',
    'झड़ाई',
    'झड़ी',
    'झड़ीः',
    'झड़ीदार',
    'झडाई',
    'झण्डा',
    'झण्डियों',
    'झण्डे',
    'झन',
    'झनक',
    'झनकना',
    'झनकार',
    'झनझन',
    'झनझना',
    'झनझनाता',
    'झनझनाना',
    'झनझनाहट',
    'झनन',
    'झनाझन',
    'झनैना',
    'झनैनापन',
    'झन्न',
    'झन्नटा',
    'झन्नटे',
    'झपक',
    'झपकते',
    'झपकन',
    'झपकना',
    'झपकाई',
    'झपकी',
    'झपझप',
    'झपट',
    'झपटना',
    'झपटाव',
    'झपटी',
    'झपटू',
    'झपट्टा',
    'झपट्टामार',
    'झपताल',
    'झपपट',
    'झपा',
    'झपाटा',
    'झपाटे',
    'झपाना',
    'झपिया',
    'झपेटना',
    'झपेटा',
    'झपोला',
    'झप्पड़',
    'झप्पान',
    'झप्पानी',
    'झबरा',
    'झबरापन',
    'झबरी',
    'झबरीला',
    'झबरे',
    'झबरेपन',
    'झबरैरा',
    'झबला',
    'झबली',
    'झब्बल',
    'झब्बा',
    'झब्बेदार',
    'झम',
    'झमक',
    'झमकना',
    'झमकार',
    'झमकारा',
    'झमझम',
    'झमझमाहट',
    'झमाका',
    'झमाझम',
    'झमेला',
    'झमेलिया',
    'झमेले',
    'झर',
    'झरझर',
    'झरझराना',
    'झरझराहट',
    'झरता',
    'झरन',
    'झरना',
    'झरनी',
    'झरने',
    'झरहराना',
    'झरा',
    'झरी',
    'झरोखा',
    'झर्झर',
    'झर्झरा',
    'झर्झरी',
    'झल',
    'झलक',
    'झलकता',
    'झलकना',
    'झलकाना',
    'झलकी',
    'झलज्झला',
    'झलझलाना',
    'झलना',
    'झलमल',
    'झलमला',
    'झलमलाहट',
    'झलरी',
    'झलाई',
    'झलाईगर',
    'झलाझल',
    'झलाबोर',
    'झलामल',
    'झल्लकी',
    'झल्लरी',
    'झल्ला',
    'झल्लाना',
    'झल्लाहट',
    'झष',
    'झषकेतु',
    'झषराज',
    'झषोवती',
    'झाँ',
    'झाँइँ',
    'झाँक',
    'झाँकनी',
    'झाँका',
    'झाँकी',
    'झाँगला',
    'झाँझ',
    'झाँझरी',
    'झाँझा',
    'झाँप',
    'झाँपना',
    'झाँपी',
    'झाँयझाँय',
    'झाँवर',
    'झाँवली',
    'झाँवा',
    'झाँवाँ',
    'झाँसना',
    'झाँसा',
    'झाँसी',
    'झाँसेबाज़',
    'झाँसेबाज़ी',
    'झांक',
    'झांकना',
    'झांकनेवाला',
    'झांकपन',
    'झांकी',
    'झांझ',
    'झांपना',
    'झांसा',
    'झांसापट्टी',
    'झाकना',
    'झाग',
    'झागदार',
    'झाझर',
    'झाडना',
    'झाड़',
    'झाड़न',
    'झाड़ना',
    'झाड़ने',
    'झाड़फूंक',
    'झाड़ा',
    'झाड़ियों',
    'झाड़ी',
    'झाड़ू',
    'झाड़ूकश',
    'झाड़ूवाद',
    'झापड़',
    'झाबर',
    'झाबा',
    'झाबी',
    'झाम',
    'झामक',
    'झायँझायँ',
    'झारखंड',
    'झारी',
    'झार्झर',
    'झाऱू',
    'झाऱूकश',
    'झाल',
    'झालदार',
    'झालना',
    'झालने',
    'झालर',
    'झालरदार',
    'झालरों',
    'झाला',
    'झावा',
    'झिंगा',
    'झिंगिनी',
    'झिंझी',
    'झिकझिक',
    'झिकझिकिया',
    'झिझक',
    'झिझकना',
    'झिझकार',
    'झिझकू',
    'झिझोड़ना',
    'झिझोड़ा',
    'झिझौटी',
    'झिड़कना',
    'झिड़की',
    'झिपझिन',
    'झिरझिर',
    'झिरझिरा',
    'झिरिका',
    'झिरी',
    'झिरीं',
    'झिलंगा',
    'झिलना',
    'झिलम',
    'झिलमि',
    'झिलमिल',
    'झिलमिला',
    'झिलमिलाता',
    'झिलमिलाना',
    'झिलमिलाहट',
    'झिलमिली',
    'झिल्लका',
    'झिल्लित',
    'झिल्लिदार',
    'झिल्ली',
    'झिल्लीदार',
    'झिल्लीनुमा',
    'झींकते',
    'झींकना',
    'झींख',
    'झींखना',
    'झींगर',
    'झींगा',
    'झींगी',
    'झींगुर',
    'झींगूर',
    'झींझीं',
    'झींवर',
    'झींसी',
    'झीना',
    'झीनापन',
    'झील',
    'झीलिका',
    'झुँकाई',
    'झुँझलाया',
    'झुँझलाहट',
    'झुंझला',
    'झुंझलाना',
    'झुंझलाहट',
    'झुंट',
    'झुंड',
    'झुक',
    'झुककर',
    'झुकन',
    'झुकना',
    'झुकने',
    'झुका',
    'झुकाई',
    'झुकान',
    'झुकाना',
    'झुकापन',
    'झुकाव',
    'झुकी',
    'झुग्गी',
    'झुटपुटा',
    'झुठमुठ',
    'झुठलाना',
    'झुठाईर्',
    'झुठालाना',
    'झुण्ड',
    'झुनझुन',
    'झुनझुना',
    'झुनझुनाना',
    'झुनझुनाहट',
    'झुनझुनियाँ',
    'झुनझुनी',
    'झुमका',
    'झुरकुट',
    'झुरझुराहट',
    'झुरझुरी',
    'झुरमुट',
    'झुराना',
    'झुरावन',
    'झुरावना',
    'झुर्राना',
    'झुर्रियां',
    'झुर्री',
    'झुर्रीदार',
    'झुलस',
    'झुलसता',
    'झुलसन',
    'झुलसना',
    'झुलसा',
    'झुलसाई',
    'झुलसाना',
    'झुलसाने',
    'झुलाना',
    'झुलौआ',
    'झुलौवा',
    'झूँझल',
    'झूँटा',
    'झूठ',
    'झूठा',
    'झूठी',
    'झूठे',
    'झूठों',
    'झूम',
    'झूमझनन',
    'झूमना',
    'झूमर',
    'झूमा',
    'झूमाझूमी',
    'झूमाना',
    'झूमी',
    'झूरा',
    'झूल',
    'झूलता',
    'झूलतापन',
    'झूलते',
    'झूलदार',
    'झूलन',
    'झूलना',
    'झूलनी',
    'झूला',
    'झूलाः',
    'झूली',
    'झेंप',
    'झेंपाना',
    'झेंपू',
    'झेप',
    'झेपू',
    'झेल',
    'झेलन',
    'झेलना',
    'झेलने',
    'झेलम',
    'झेलू',
    'झेले',
    'झोंक',
    'झोंकन',
    'झोंकना',
    'झोंकवा',
    'झोंका',
    'झोंकाई',
    'झोंके',
    'झोंकेदार',
    'झोंझ',
    'झोंटा',
    'झोंपड़ा',
    'झोंपड़ी',
    'झोझर',
    'झोटा',
    'झोपड़ियों',
    'झोपड़ी',
    'झोपड़ीवासी',
    'झोपडी',
    'झोरी',
    'झोल',
    'झोलदार',
    'झोला',
    'झोली',
    'झोलीचुक',
    'झोले',
    'झौंद',
    'झौंर',
    'झौंरा',
    'झौंराना',
    'झ्रड़ा',
    'ट',
    'टँका',
    'टँकाई',
    'टँकापन',
    'टँकार',
    'टँकित',
    'टँकित्र',
    'टँकौरी',
    'टँगड़ी',
    'टँगना',
    'टँगा',
    'टँगाई',
    'टँगापन',
    'टँगाव',
    'टंक',
    'टंकक',
    'टंकटा',
    'टंकण',
    'टंकन',
    'टंकपित',
    'टंकविज्ञान',
    'टंकशाला',
    'टंका',
    'टंकित',
    'टंकी',
    'टंकृत',
    'टंकोर',
    'टंग',
    'टंच',
    'टंट',
    'टंटा',
    'टंटेबाज',
    'टंडन',
    'टंडैल',
    'टंबलर',
    'टइपिंग',
    'टक',
    'टकटकाता',
    'टकटकी',
    'टकटोह',
    'टकटोहना',
    'टकड़ा',
    'टकर',
    'टकरना',
    'टकरा',
    'टकराता',
    'टकराना',
    'टकराने',
    'टकराव',
    'टकराहट',
    'टकशाला',
    'टकसाल',
    'टकसालः',
    'टकसालिया',
    'टकसाली',
    'टकहाई',
    'टकही',
    'टका',
    'टकाई',
    'टकाही',
    'टकुआ',
    'टके',
    'टकैत',
    'टकोर',
    'टकोरना',
    'टकोर्ईज',
    'टकौर',
    'टक्कर',
    'टक्करें',
    'टखना',
    'टखने',
    'टग',
    'टगण',
    'टगर',
    'टगवैव',
    'टगिंग',
    'टघरना',
    'टचडाउन',
    'टटका',
    'टटकाई',
    'टटपूँजिया',
    'टटल',
    'टटीरी',
    'टटुआ',
    'टटोल',
    'टटोलना',
    'टटोलने',
    'टटोहना',
    'टट्टर',
    'टट्टरी',
    'टट्टा',
    'टट्टी',
    'टट्टू',
    'टन',
    'टनटन',
    'टनटनाना',
    'टनटनाहट',
    'टनडम',
    'टनधारिता',
    'टनभार',
    'टनमन',
    'टनमना',
    'टनल',
    'टनलिंग',
    'टनाई',
    'टनाटन',
    'टनेज',
    'टनों',
    'टप',
    'टपक',
    'टपकता',
    'टपकन',
    'टपकना',
    'टपकवाना',
    'टपका',
    'टपकाई',
    'टपकाते',
    'टपकाना',
    'टपकाया',
    'टपकाव',
    'टपटप',
    'टपटपाना',
    'टपटपाहट',
    'टपाई',
    'टपाटप',
    'टपाना',
    'टपैटाइन',
    'टप्पर',
    'टप्पा',
    'टप्पे',
    'टब',
    'टबाल',
    'टमटम',
    'टयूज़डे',
    'टयूब',
    'टर',
    'टरकना',
    'टरका',
    'टरकाना',
    'टरकाया',
    'टरटर',
    'टरटराना',
    'टरबाइन',
    'टर्न',
    'टर्नर',
    'टर्म',
    'टर्मिनल',
    'टर्मिनलों',
    'टर्मिनस',
    'टर्रटर्र',
    'टर्रा',
    'टर्राना',
    'टर्रु',
    'टल',
    'टलना',
    'टलनीय',
    'टलने',
    'टला',
    'टलाटली',
    'टलाव',
    'टसक',
    'टसकना',
    'टसकाना',
    'टसर',
    'टसुआ',
    'टहकना',
    'टहना',
    'टहनी',
    'टहनीदार',
    'टहल',
    'टहलक़दमी',
    'टहलदारनी',
    'टहलना',
    'टहलनी',
    'टहलुआ',
    'टहलुई',
    'टहोका',
    'टाँकना',
    'टाँका',
    'टाँकाः',
    'टाँग',
    'टाँगा',
    'टाँट',
    'टाँठा',
    'टाँड़',
    'टाँड़ा',
    'टाँड़ी',
    'टाँय',
    'टांकना',
    'टांका',
    'टांकी',
    'टांग',
    'टांगना',
    'टांगने',
    'टांगिया',
    'टांगे',
    'टांगें',
    'टांगों',
    'टाइ',
    'टाइगर',
    'टाइटिल',
    'टाइटेनियम',
    'टाइड',
    'टाइप',
    'टाइपकर्ता',
    'टाइपग्रीवा',
    'टाइपपाद',
    'टाइपर',
    'टाइपराइटर',
    'टाइपराइटरों',
    'टाइपराइटिंग',
    'टाइपसैटिंग',
    'टाइपिंग',
    'टाइपिस्ट',
    'टाइपिस्टों',
    'टाइपोग्राफ़ी',
    'टाइप्ड',
    'टाइम',
    'टाइमकीपर',
    'टाइमिंग',
    'टाइम्स',
    'टाई',
    'टाईपराइटर',
    'टाईपिन',
    'टाईम',
    'टाईम्स',
    'टाईल',
    'टाउन',
    'टाउनशिप',
    'टाक',
    'टाकरा',
    'टाकी',
    'टाकीज़',
    'टाट',
    'टाटः',
    'टाटर',
    'टाटरी',
    'टाटा',
    'टाठी',
    'टाप',
    'टापड़',
    'टापते',
    'टापना',
    'टापलैस',
    'टापा',
    'टापू',
    'टाप्स',
    'टाफ्',
    'टाम',
    'टामचीनी',
    'टामलोट',
    'टायर',
    'टायलेट',
    'टारपीडो',
    'टार्टरिक',
    'टाल',
    'टालना',
    'टालने',
    'टालनेयोग्य',
    'टालमटोल',
    'टालमटोलू',
    'टालू',
    'टावर',
    'टास',
    'टाफ़ी',
    'टिंक्चर',
    'टिंचर',
    'टिंडा',
    'टिंडोरा',
    'टिक',
    'टिकट',
    'टिकटघर',
    'टिकटयुक्त',
    'टिकटिक',
    'टिकटिकाता',
    'टिकटिकाना',
    'टिकटिकाहट',
    'टिकटी',
    'टिकड़ा',
    'टिकड़ी',
    'टिकन',
    'टिकना',
    'टिकरी',
    'टिकला',
    'टिकली',
    'टिकस',
    'टिका',
    'टिकाई',
    'टिकाऊ',
    'टिकाऊपन',
    'टिकान',
    'टिकाना',
    'टिकाने',
    'टिकापन',
    'टिकाया',
    'टिकाव',
    'टिकिया',
    'टिकुरी',
    'टिकूली',
    'टिकैत',
    'टिकैता',
    'टिकैती',
    'टिकोरा',
    'टिक्कड़',
    'टिक्का',
    'टिक्की',
    'टिघलना',
    'टिचन',
    'टिज',
    'टिटहरी',
    'टिटिहरी',
    'टिटिहा',
    'टिड्डा',
    'टिड्डी',
    'टिन',
    'टिनटिन',
    'टिप',
    'टिपटिप',
    'टिपणीस',
    'टिपाई',
    'टिप्पण',
    'टिप्पणकार',
    'टिप्पणियां',
    'टिप्पणियों',
    'टिप्पणी',
    'टिप्पणीकार',
    'टिप्पणीयों',
    'टिप्पन',
    'टिप्पस',
    'टिप्पा',
    'टिप्पाशीलता',
    'टिप्पेदार',
    'टिफ़न',
    'टिफिन',
    'टिब्बा',
    'टिमटिमाता',
    'टिमटिमाना',
    'टिमटिमाहट',
    'टिलावा',
    'टिल्ला',
    'टिल्ले',
    'टिशू',
    'टिहूक',
    'टिहूकना',
    'टि्वल',
    'टी',
    'टींडसी',
    'टीअर',
    'टीक',
    'टीका',
    'टीकाओं',
    'टीकाकार',
    'टीकी',
    'टीको',
    'टीचर',
    'टीचिंग',
    'टीड़ा',
    'टीडी',
    'टीन',
    'टीनः',
    'टीनकार',
    'टीनबंद',
    'टीनेजर',
    'टीप',
    'टीपकारी',
    'टीपटाप',
    'टीपना',
    'टीबा',
    'टीबी',
    'टीम',
    'टीमों',
    'टीयर',
    'टीला',
    'टीले',
    'टीवी',
    'टीस',
    'टीसना',
    'टुँगन',
    'टुँगाई',
    'टुंच',
    'टुंडी',
    'टुइल',
    'टुकड़ख़ोरी',
    'टुकड़ा',
    'टुकड़ी',
    'टुकड़े',
    'टुकड़ों',
    'टुकडा',
    'टुकडी',
    'टुकडे',
    'टुकडों',
    'टुकुर',
    'टुक्कड',
    'टुक्का',
    'टुघलाना',
    'टुच्चा',
    'टुच्चापन',
    'टुट',
    'टुटका',
    'टुटरुँ',
    'टुड़ी',
    'टुनगी',
    'टुनटुना',
    'टुनहाया',
    'टुन्ना',
    'टुन्नू',
    'टुपट्टा',
    'टुर्रा',
    'टूँगना',
    'टूँट',
    'टूँड़',
    'टूअर',
    'टूक',
    'टूट',
    'टूटदार',
    'टूटन',
    'टूटना',
    'टूटने',
    'टूटा',
    'टूटापन',
    'टूटे',
    'टूथपेस्ट',
    'टूथब्रश',
    'टूना',
    'टूर',
    'टूरज्',
    'टूरिज्म',
    'टूरिस्ट',
    'टूर्नामैंट',
    'टूर्नीकेट',
    'टूल',
    'टूल्स',
    'टूवर',
    'टूवे',
    'टूस',
    'टेंक',
    'टेंच',
    'टेंट',
    'टेंटुआ',
    'टेंडर',
    'टेंडरदाता',
    'टेउकी',
    'टेक',
    'टेकऔफ़',
    'टेकडी',
    'टेकन',
    'टेकना',
    'टेकनी',
    'टेकने',
    'टेकबंदी',
    'टेकर',
    'टेकरी',
    'टेकिंग',
    'टेकी',
    'टेकुआ',
    'टेकुरी',
    'टेक्नोक्रैट',
    'टेड़ी',
    'टेढ़',
    'टेढ़ा',
    'टेढ़ाई',
    'टेढ़ी',
    'टेढ़े',
    'टेढा',
    'टेढापन',
    'टेढाबैंचापन',
    'टेढी',
    'टेढे',
    'टेनिस',
    'टेनैंट',
    'टेनैंसी',
    'टेन्डर',
    'टेप',
    'टेपरिकार्ड',
    'टेपरिकार्डर',
    'टेपी',
    'टेबल',
    'टेम',
    'टेम्पो',
    'टेम्प्लेट',
    'टेर',
    'टेरना',
    'टेरीसिल्क',
    'टेरु',
    'टेल',
    'टेलर',
    'टेलरिंग',
    'टेलर्स',
    'टेलिफोन',
    'टेलिविजन',
    'टेलिविज़न',
    'टेलिविज़न',
    'टेली',
    'टेलीग्राफ',
    'टेलीग्राफ़ी',
    'टेलीप्रिंटर',
    'टेलीफून',
    'टेलीफोन',
    'टेलीविजन',
    'टेलीविज़न',
    'टेलीविज़न',
    'टेलीसिने',
    'टेलीस्कोप',
    'टेलीफ़ोन',
    'टेलेक्स',
    'टेल्यूरिअम',
    'टेल्हा',
    'टेव',
    'टेवना',
    'टेवा',
    'टेवैया',
    'टेसुआ',
    'टेसू',
    'टेस्ट',
    'टेस्टिंग',
    'टैंक',
    'टैंकर',
    'टैंकलारियों',
    'टैंकलारी',
    'टैंकेंज',
    'टैंग',
    'टैंट',
    'टैंटैं',
    'टैंडम',
    'टैंडर',
    'टैंपो',
    'टैकल',
    'टैक्ट',
    'टैक्शचर',
    'टैक्स',
    'टैक्सटाईल्स',
    'टैक्सियों',
    'टैक्सी',
    'टैक्सीइरंग',
    'टैक्सेशन',
    'टैक्स्ट',
    'टैक्स्टाइल',
    'टैग',
    'टैगोर',
    'टैटू',
    'टैट्रापैक',
    'टैन',
    'टैनडैम',
    'टैनिस',
    'टैपरेचर',
    'टैबलेट',
    'टैम्परेचर',
    'टैर',
    'टैरिकाट',
    'टैरिलीन',
    'टैरीवूल',
    'टैलर',
    'टैलीविज़न',
    'टैलीफ़ोन',
    'टैस्ट',
    'टैस्टामैंट',
    'टो',
    'टोंच',
    'टोंचना',
    'टोंट',
    'टोंटी',
    'टोंटीदार',
    'टोंल',
    'टोइरग',
    'टोएकी',
    'टोएपमैन',
    'टोक',
    'टोकन',
    'टोकना',
    'टोकनी',
    'टोकने',
    'टोकरा',
    'टोकरी',
    'टोकरीया',
    'टोकरे',
    'टोकहीनता',
    'टोकू',
    'टोटका',
    'टोटकाहाई',
    'टोटकेबाज़',
    'टोटकेबाज़ी',
    'टोटल',
    'टोटा',
    'टोड़ी',
    'टोन',
    'टोनहा',
    'टोनहाई',
    'टोना',
    'टोनिक',
    'टोप',
    'टोपाज़',
    'टोपियों',
    'टोपी',
    'टोपीवाला',
    'टोबा',
    'टोरा',
    'टोरी',
    'टोला',
    'टोली',
    'टोस्ट',
    'टोस्टिंग',
    'टोह',
    'टोहना',
    'टोहा',
    'टोहिया',
    'टोही',
    'टौंग',
    'टौंगः',
    'टौंगना',
    'टौंगा',
    'टौक',
    'टौकना',
    'टौकर',
    'टौका',
    'टौकी',
    'टौच',
    'टौचना',
    'टौची',
    'टौप',
    'टौफ़ी',
    'टौरनैडो',
    'टौर्च',
    'टौवर',
    'टौवल',
    'ट्यूब',
    'ट्यूमर',
    'ट्रंक',
    'ट्रंक्स',
    'ट्रंप',
    'ट्रक',
    'ट्रकों',
    'ट्रन्यन',
    'ट्रपीज़',
    'ट्रस्ट',
    'ट्रांजि',
    'ट्रांजिट',
    'ट्रांसपेरैंसी',
    'ट्रांसपोर्ट',
    'ट्रांसफ़र',
    'ट्रांसयमुना',
    'ट्रांसर्पोट',
    'ट्रांसर्पोटर',
    'ट्रांसर्पोटर्स',
    'ट्रांस्लेशन',
    'ट्राइंजिट',
    'ट्राउट',
    'ट्राफी',
    'ट्राम',
    'ट्राली',
    'ट्रासपोर्ट',
    'ट्रास्क्राइवर',
    'ट्राय़',
    'ट्रिगनोमीटरी',
    'ट्रिगर',
    'ट्रिगरन',
    'ट्रिगरिंग',
    'ट्रिटमैंट',
    'ट्रिनट्रिन',
    'ट्रिनिटी',
    'ट्रिप',
    'ट्रीटी',
    'ट्रे',
    'ट्रेंच',
    'ट्रेंड',
    'ट्रेक्टर',
    'ट्रेड',
    'ट्रेडनेम',
    'ट्रेडमार्क',
    'ट्रेडर',
    'ट्रेडिंग',
    'ट्रेन',
    'ट्रेनर',
    'ट्रेनिंग',
    'ट्रेनी',
    'ट्रेन्ड',
    'ट्रेलर',
    'ट्रेवलर',
    'ट्रेस',
    'ट्रेसर',
    'ट्रैक',
    'ट्रैक्टर',
    'ट्रैक्शन',
    'ट्रैजडी',
    'ट्रैज़रर',
    'ट्रैज़री',
    'ट्रैजेडी',
    'ट्रैडल',
    'ट्रैडीशनल',
    'ट्रैफिक',
    'ट्रैवपास',
    'ट्रैवल',
    'ट्रैवलर',
    'ट्रैवलिंग',
    'ट्रौइका',
    'ट्रौली',
    'ट्वीड',
    'ठँसना',
    'ठंठ',
    'ठंठल',
    'ठंठार',
    'ठंड',
    'ठंडक',
    'ठंडा',
    'ठंडाई',
    'ठंडाठार',
    'ठंडापन',
    'ठंडी',
    'ठंडे',
    'ठंढ',
    'ठंढक',
    'ठंढ़ा',
    'ठंढी',
    'ठक',
    'ठकठक',
    'ठकठकाना',
    'ठकठकाहट',
    'ठकठौआ',
    'ठकुराई',
    'ठकुरानी',
    'ठकुरायत',
    'ठकोरी',
    'ठग',
    'ठगई',
    'ठगण',
    'ठगना',
    'ठगनी',
    'ठगने',
    'ठगा',
    'ठगाई',
    'ठगित',
    'ठगिया',
    'ठगी',
    'ठगोरी',
    'ठटना',
    'ठटरी',
    'ठट्ट',
    'ठट्ठा',
    'ठट्ठेबाज़',
    'ठट्ठेबाज़ी',
    'ठट््टा',
    'ठठरी',
    'ठठाना',
    'ठठेर',
    'ठठेरगीरी',
    'ठठेरवाड़ा',
    'ठठेरा',
    'ठठोली',
    'ठण्डक',
    'ठण्डा',
    'ठन',
    'ठनकना',
    'ठनकाना',
    'ठनठन',
    'ठनठनाना',
    'ठनठनाहट',
    'ठनना',
    'ठना',
    'ठनाठनी',
    'ठनी',
    'ठन्डी',
    'ठप',
    'ठपपा',
    'ठप्प',
    'ठप्पा',
    'ठप्पाकारी',
    'ठमक',
    'ठमकना',
    'ठमकारना',
    'ठर्रा',
    'ठलुआ',
    'ठवन',
    'ठवनि',
    'ठस',
    'ठसक',
    'ठसकना',
    'ठसका',
    'ठसके',
    'ठसकेदार',
    'ठसपन',
    'ठसा',
    'ठसाई',
    'ठसाठस',
    'ठस्सा',
    'ठहर',
    'ठहरन',
    'ठहरना',
    'ठहरने',
    'ठहरा',
    'ठहराऊ',
    'ठहराना',
    'ठहराने',
    'ठहराव',
    'ठहरो',
    'ठहरौनी',
    'ठहाका',
    'ठाँ',
    'ठाँउँ',
    'ठाँठ',
    'ठाँव',
    'ठाक',
    'ठाकुर',
    'ठाकुरद्वारा',
    'ठाट',
    'ठाटदार',
    'ठाटबंदी',
    'ठाटबाट',
    'ठाटबाटदार',
    'ठाटे',
    'ठाटें',
    'ठाठ',
    'ठाठबाट',
    'ठान',
    'ठानना',
    'ठाप',
    'ठापना',
    'ठापा',
    'ठाम',
    'ठार',
    'ठाला',
    'ठाली',
    'ठाले',
    'ठाह',
    'ठिंगना',
    'ठिंगनापन',
    'ठिंगनी',
    'ठिंगा',
    'ठिंगू',
    'ठिकाना',
    'ठिकाने',
    'ठिकिया',
    'ठिगना',
    'ठिगनापन',
    'ठिठक',
    'ठिठकना',
    'ठिठकू',
    'ठिठुरता',
    'ठिठुरन',
    'ठिठुरना',
    'ठिठुरा',
    'ठिठोली',
    'ठिठोलीबाज़',
    'ठिनक',
    'ठिनकना',
    'ठिर',
    'ठिरना',
    'ठिलंत',
    'ठिलना',
    'ठिला',
    'ठिलाई',
    'ठिलिया',
    'ठिल्ली',
    'ठीक',
    'ठीकठाक',
    'ठीकम',
    'ठीकरा',
    'ठीकरी',
    'ठीका',
    'ठीकाना',
    'ठीया',
    'ठीहा',
    'ठुँका',
    'ठुँकाई',
    'ठुँसना',
    'ठुँसाई',
    'ठुँसाठुँस',
    'ठुँसाव',
    'ठुंठ',
    'ठुकठुक',
    'ठुकठुकाहट',
    'ठुकठुकी',
    'ठुकराना',
    'ठुकराया',
    'ठुकाई',
    'ठुड्डी',
    'ठुनकना',
    'ठुनठुन',
    'ठुन्नक',
    'ठुमक',
    'ठुमकन',
    'ठुमकना',
    'ठुमका',
    'ठुमकार',
    'ठुमकारना',
    'ठुमरी',
    'ठुमुक',
    'ठूँग',
    'ठूँठा',
    'ठूँठी',
    'ठूँस',
    'ठूँसठूँस',
    'ठूँसन',
    'ठूँसना',
    'ठूँसू',
    'ठूंठ',
    'ठूंस',
    'ठूंसकर',
    'ठूंसना',
    'ठूस',
    'ठूसकर',
    'ठूसना',
    'ठेंगा',
    'ठेंठा',
    'ठेंठी',
    'ठेका',
    'ठेकादार',
    'ठेके',
    'ठेकेदार',
    'ठेकेदारी',
    'ठेकेदारों',
    'ठेकों',
    'ठेगनी',
    'ठेठ',
    'ठेठी',
    'ठेपी',
    'ठेल',
    'ठेलक',
    'ठेलना',
    'ठेलने',
    'ठेलपेल',
    'ठेला',
    'ठेली',
    'ठेले',
    'ठेस',
    'ठेहरी',
    'ठोंक',
    'ठोंककर',
    'ठोंकना',
    'ठोंग',
    'ठोंगा',
    'ठोंसा',
    'ठोकना',
    'ठोकने',
    'ठोकर',
    'ठोकरा',
    'ठोकरें',
    'ठोकाठाकी',
    'ठोड़ी',
    'ठोड़ीः',
    'ठोडीः',
    'ठोढ़ी',
    'ठोली',
    'ठोस',
    'ठोसपन',
    'ठौर',
    'ड',
    'डँगरी',
    'डँगवारा',
    'डंक',
    'डंकदार',
    'डंका',
    'डंकिनी',
    'डंकी',
    'डंके',
    'डंगर',
    'डंगरी',
    'डंठल',
    'डंठलः',
    'डंठलःभूसा',
    'डंड',
    'डंडपेल',
    'डंडवत',
    'डंडा',
    'डंडाधारी',
    'डंडाल',
    'डंडिया',
    'डंडी',
    'डंडीकार',
    'डंडीमार',
    'डंडैत',
    'डंडौत',
    'डंप',
    'डंपर',
    'डंबर',
    'डंबली',
    'डंबैल',
    'डंस',
    'डंींगहीन',
    'डकराहट',
    'डकार',
    'डकारः',
    'डकैत',
    'डकैती',
    'डग',
    'डगडग',
    'डगडगाहट',
    'डगडौर',
    'डगमग',
    'डगमगाता',
    'डगमगाते',
    'डगमगाना',
    'डगमगाहट',
    'डगर',
    'डग्गा',
    'डटन',
    'डटना',
    'डटने',
    'डटाव',
    'डटे',
    'डट्टा',
    'डढ़ार',
    'डण्डा',
    'डण्डे',
    'डना',
    'डपट',
    'डपटना',
    'डपोर',
    'डफ',
    'डफला',
    'डफली',
    'डफ़ला',
    'डफालची',
    'डफाली',
    'डब',
    'डबकना',
    'डबका',
    'डबडब',
    'डबडबाता',
    'डबडबाना',
    'डबडबाहट',
    'डबरा',
    'डबरी',
    'डबरीदार',
    'डबल',
    'डबलरोटी',
    'डबल्स',
    'डबैल',
    'डब्बा',
    'डब्बी',
    'डब्बू',
    'डभकना',
    'डभका',
    'डभकौरी',
    'डमर',
    'डमरु',
    'डमरू',
    'डयन',
    'डयूटी',
    'डयोढ़ीदार',
    'डयोढ़ीवान',
    'डयोढा',
    'डयोढापन',
    'डयोढी',
    'डर',
    'डरती',
    'डरते',
    'डरना',
    'डरने',
    'डरपना',
    'डरपोक',
    'डरपोकपना',
    'डरा',
    'डराऊ',
    'डराकर',
    'डराना',
    'डराने',
    'डरावना',
    'डरावनापन',
    'डरावा',
    'डरे',
    'डरौआ',
    'डरौवा',
    'डल',
    'डलन',
    'डलना',
    'डला',
    'डलाई',
    'डलापन',
    'डलिया',
    'डली',
    'डसना',
    'डसने',
    'डस्टबीन',
    'डस्टर',
    'डा',
    'डाँक',
    'डाँकना',
    'डाँट',
    'डाँटना',
    'डाँटा',
    'डाँड़',
    'डाँड़ा',
    'डाँड़ी',
    'डाँडी',
    'डाँवा',
    'डाँवाडोलन',
    'डाँस',
    'डांटकर',
    'डांटना',
    'डांडी',
    'डांवाडोल',
    'डांस',
    'डांसर',
    'डाइआकसाइड',
    'डाइक',
    'डाइन',
    'डाइनामाइट',
    'डाइनासौर',
    'डाइनिंग',
    'डाइनैमिक्स',
    'डाइरक्शन',
    'डाइरैक्ट',
    'डाइरैक्टर',
    'डाइव',
    'डाइवर',
    'डाइविंग',
    'डाइवोर्स',
    'डाइस',
    'डाई',
    'डाईमेकर',
    'डाउन',
    'डाक',
    'डाकख़ाना',
    'डाकखाना',
    'डाकघर',
    'डाकपाल',
    'डाकबंबा',
    'डाकशुल्क',
    'डाका',
    'डाकाज़नी',
    'डाकिन',
    'डाकिनी',
    'डाकिया',
    'डाकिये',
    'डाकू',
    'डाक्टर',
    'डाक्टरी',
    'डाक्टरेट',
    'डागदर',
    'डागा',
    'डाट',
    'डाटदार',
    'डाटना',
    'डाटबंदी',
    'डाटवाला',
    'डाटा',
    'डाढ़',
    'डाढ़ी',
    'डाब',
    'डाबर',
    'डाभ',
    'डामर',
    'डामरः',
    'डामरदार',
    'डामल',
    'डायगेनैलिटी',
    'डायगोनल',
    'डायटिंग',
    'डायन',
    'डायनमो',
    'डायनासोर',
    'डायनेमो',
    'डायबिटीज',
    'डायमंड',
    'डायमीटर',
    'डायरियां',
    'डायरी',
    'डायरी/G',
    'डायरेक्ट',
    'डायरेक्टर',
    'डायरेक्टरी',
    'डायरेक्टोरेट',
    'डायरैक्ट',
    'डायरैक्टर',
    'डायरैक्टरी',
    'डायरैक्टोरेक्ट',
    'डायल',
    'डायलौग',
    'डायस',
    'डायिन',
    'डार',
    'डारी',
    'डाल',
    'डालकर',
    'डालते',
    'डालदार',
    'डालना',
    'डालने',
    'डालनेवाला',
    'डालर',
    'डाला',
    'डाली',
    'डाले',
    'डाल्टनवाद',
    'डाहना',
    'डाहुक',
    'डिं',
    'डिंगर',
    'डिंगी',
    'डिंडिभ',
    'डिंडिश',
    'डिंडीर',
    'डिंब',
    'डिंबज',
    'डिंबाणु',
    'डिंबाशय',
    'डिंबिका',
    'डिंबिड',
    'डिंबिद',
    'डिंबी',
    'डिंभ',
    'डिंभक',
    'डिंभिया',
    'डिकी',
    'डिक्की',
    'डिक्टेटर',
    'डिक्टेशन',
    'डिक्री',
    'डिक्रीदार',
    'डिक्शनरी',
    'डिगन',
    'डिगना',
    'डिगरी',
    'डिगा',
    'डिगाना',
    'डिग्गी',
    'डिग्री',
    'डिग्रीः',
    'डिज़ाइन',
    'डिज़ाइनर',
    'डिजाइन',
    'डिजाइनकार',
    'डिजाइनिंग',
    'डिजायन',
    'डिजिटल',
    'डिजीटल',
    'डिटजैट',
    'डिठियारा',
    'डिठौना',
    'डिनर',
    'डिनौमैट',
    'डिप',
    'डिपाजि',
    'डिपाजिट',
    'डिपार्टमैंट',
    'डिपार्टमैंटल',
    'डिपो',
    'डिप्टी',
    'डिप्लोमा',
    'डिप्लोमैट',
    'डिफरेंस',
    'डिफेंस',
    'डिफ्रैंस',
    'डिबडिब',
    'डिबडिबाता',
    'डिबडिबाना',
    'डिबडिबाहट',
    'डिबिया',
    'डिबेंचर',
    'डिबेंचरधारक',
    'डिबेंचरों',
    'डिबेट',
    'डिबैंचर',
    'डिब्बा',
    'डिब्बाबंद',
    'डिब्बाबंदी',
    'डिब्बी',
    'डिब्बीबंदी',
    'डिब्बे',
    'डिब्बों',
    'डिमरेज',
    'डिमांड',
    'डिमिटी',
    'डिमोशन',
    'डिम्ब',
    'डिम्बद्रव्य',
    'डिम्बवाही',
    'डिम्बाणु',
    'डिम्बीय',
    'डिलवरी',
    'डिलिया',
    'डिलीवरी',
    'डिवाइडर',
    'डिविजनों',
    'डिवीजन',
    'डिवीज़न',
    'डिवीज़नल',
    'डिवीडैंड',
    'डिवीज़न',
    'डिश',
    'डिसपेंसिंग',
    'डिसैडर्र',
    'डिस्क',
    'डिस्कस',
    'डिस्काउंट',
    'डिस्क्रीमिनेशन',
    'डिस्गाइज़',
    'डिस्चार्जन',
    'डिस्टलरी',
    'डिस्टिड',
    'डिस्टिल',
    'डिस्टिलर',
    'डिस्टैंपर',
    'डिस्ट्र',
    'डिस्ट्रिक्ट',
    'डिस्ट्रीब्यूटर',
    'डिस्ट्रीब्यूटर्स',
    'डिस्ट्रीब्यूशन',
    'डिस्पेंसरी',
    'डिस्पैंसरी',
    'डिस्पैच',
    'डिस्बैडिंग',
    'डिस्सैक्शन',
    'डििस्मस',
    'डिज़ाइन',
    'डिज़ाइनकार',
    'डिज़ायन',
    'डी',
    'डींग',
    'डींगबाज',
    'डींगमार',
    'डींगहीनता',
    'डींगी',
    'डीआर',
    'डीजल',
    'डीज़ल',
    'डीटैंशन',
    'डीटैक्टिव',
    'डीठ',
    'डीडक्शन',
    'डीन',
    'डीप',
    'डीप्रैशन',
    'डीफ्र',
    'डीफ्रसिव',
    'डीयर',
    'डील',
    'डीलडौल',
    'डीलर',
    'डीलरवाईज',
    'डीलरशिपें',
    'डीलरशिपों',
    'डीलरों',
    'डीलर्स',
    'डीलिंग',
    'डीसैडिंग',
    'डीसैलेरोमीटर',
    'डुगडुगी',
    'डुग्गी',
    'डुडंभ',
    'डुप्लिकेटर',
    'डुप्लिकेटिंग',
    'डुबकर',
    'डुबकी',
    'डुबकीः',
    'डुबकीखोर',
    'डुबना',
    'डुबाई',
    'डुबाऊ',
    'डुबाकर',
    'डुबान',
    'डुबाना',
    'डुबाय',
    'डुबाव',
    'डुबोकर',
    'डुबोना',
    'डुबोया',
    'डुब्बी',
    'डुलता',
    'डुलन',
    'डुलना',
    'डुलाई',
    'डुलाना',
    'डुलावन',
    'डुल्लन',
    'डुवियार',
    'डूँगर',
    'डूँगरी',
    'डूप्लीकेट',
    'डूप्लैक्स',
    'डूब',
    'डूबता',
    'डूबती',
    'डूबते',
    'डूबन',
    'डूबना',
    'डूबने',
    'डूबा',
    'डूबापन',
    'डूबी',
    'डूह',
    'डे',
    'डेंडहा',
    'डेक',
    'डेकरान',
    'डेगची',
    'डेट',
    'डेटींग',
    'डेढ़',
    'डेथ',
    'डेबिट',
    'डेम',
    'डेयरी',
    'डेरवाल',
    'डेरा',
    'डेरी',
    'डेरीइंग',
    'डेरीवाला',
    'डेरे',
    'डेला',
    'डेलिगेशन',
    'डेली',
    'डेल्टा',
    'डेवढ़ी',
    'डेसिमल',
    'डेसीग्राम',
    'डेस्क',
    'डेहरी',
    'डेार',
    'डेारफ्रेम',
    'डै',
    'डैक',
    'डैकः',
    'डैकर',
    'डैकरान',
    'डैकहैड',
    'डैकैर',
    'डैकोरेशन',
    'डैज़र्ट',
    'डैजि',
    'डैड',
    'डैडराइट',
    'डैड़वारा',
    'डैनिम',
    'डैबिट',
    'डैमन',
    'डैमोक्रेटिक',
    'डैमोक्रेसी',
    'डैरिक',
    'डैर्जट',
    'डैवलपमेंट',
    'डैविट',
    'डैश',
    'डैसक',
    'डैसीऐर',
    'डैसीग्राम',
    'डैस्क',
    'डैस्ट्रेयर',
    'डैेविल',
    'डैैटर्स',
    'डॉइ',
    'डॉक',
    'डॉक्टर',
    'डॉलर',
    'डॉल्फिन',
    'डॉस',
    'डोंगर',
    'डोंगरी',
    'डोंगा',
    'डोंगी',
    'डोंडी',
    'डोंढूँ',
    'डोई',
    'डोकरी',
    'डोका',
    'डोकी',
    'डोडी',
    'डोडैयो',
    'डोनर',
    'डोब',
    'डोबा',
    'डोम',
    'डोमनी',
    'डोमिनोज़',
    'डोर',
    'डोरक',
    'डोरा',
    'डोरिया',
    'डोरियां',
    'डोरी',
    'डोरे',
    'डोल',
    'डोलचा',
    'डोलता',
    'डोलती',
    'डोलन',
    'डोलना',
    'डोला',
    'डोली',
    'डोसा',
    'डौंक',
    'डौआ',
    'डौक',
    'डौकी',
    'डौक्यूमैंटरी',
    'डौगी',
    'डौट',
    'डौटर',
    'डौना',
    'डौल',
    'डौलना',
    'डौलर',
    'डौला',
    'डौली',
    'ड्युटी',
    'ड्यूटी',
    'ड्यूटीवाला',
    'ड्योढ़ा',
    'ड्योढ़ी',
    'ड्रग',
    'ड्रम',
    'ड्रमस्टिक',
    'ड्रा',
    'ड्रांइग',
    'ड्राइ',
    'ड्राइंग',
    'ड्राइक्लीनर',
    'ड्राइक्लीनिंग',
    'ड्राइव',
    'ड्राइवर',
    'ड्राइविंग',
    'ड्राई',
    'ड्राफ्ट',
    'ड्राफ्ट्समैन',
    'ड्राफ्त',
    'ड्राफ्रट्समैन',
    'ड्राम',
    'ड्रामा',
    'ड्रावर',
    'ड्रावर्ज',
    'ड्राविंग',
    'ड्रिंक',
    'ड्रिल',
    'ड्रिलर',
    'ड्रिलिंग',
    'ड्रीप',
    'ड्रेन',
    'ड्रेनेज',
    'ड्रेस',
    'ड्रेसवाला',
    'ड्रैजर',
    'ड्रैस',
    'ड्रैसिंग',
    'ड्रैसिग',
    'ड्रौप',
    'ढँकन',
    'ढँकना',
    'ढँका',
    'ढँकाई',
    'ढँपन',
    'ढँपना',
    'ढँपा',
    'ढँपाई',
    'ढंक',
    'ढंकना',
    'ढंकी',
    'ढंग',
    'ढंढार',
    'ढअन',
    'ढई',
    'ढक',
    'ढकना',
    'ढकनी',
    'ढकने',
    'ढका',
    'ढकेल',
    'ढकेलना',
    'ढकोस',
    'ढकोसला',
    'ढकोसलेबाज',
    'ढकोसा',
    'ढक्क',
    'ढक्कन',
    'ढक्कनदार',
    'ढक्का',
    'ढक्कारी',
    'ढगण',
    'ढचर',
    'ढचरा',
    'ढठीगड़',
    'ढठीगड़ा',
    'ढनक',
    'ढनमनाना',
    'ढप',
    'ढपना',
    'ढपला',
    'ढपली',
    'ढप्पन',
    'ढब',
    'ढबका',
    'ढबरा',
    'ढबूसा',
    'ढम',
    'ढमढम',
    'ढमढमाहट',
    'ढमाका',
    'ढरकन',
    'ढरकना',
    'ढरका',
    'ढरकी',
    'ढरन',
    'ढरना',
    'ढरहरी',
    'ढर्रा',
    'ढल',
    'ढलक',
    'ढलकना',
    'ढलका',
    'ढलकाना',
    'ढलकाव',
    'ढलता',
    'ढलती',
    'ढलते',
    'ढलन',
    'ढलनशीलता',
    'ढलना',
    'ढलनी',
    'ढलनीय',
    'ढलनीयता',
    'ढलवा',
    'ढलवाँ',
    'ढलवांपन',
    'ढला',
    'ढलाइर',
    'ढलाई',
    'ढलाईकार',
    'ढलाईखाना',
    'ढलाईगर',
    'ढलान',
    'ढलानदार',
    'ढलानयुक्त',
    'ढलाव',
    'ढली',
    'ढलुवाँ',
    'ढले',
    'ढलैत',
    'ढलैया',
    'ढवन',
    'ढह',
    'ढहन',
    'ढहनशील',
    'ढहना',
    'ढहरी',
    'ढहा',
    'ढहाऊ',
    'ढहाना',
    'ढ़ाई',
    'ढ़ीबरी',
    'ढ़ेर',
    'ढ़ेली',
    'ढाँक',
    'ढाँकना',
    'ढाँकने',
    'ढाँचा',
    'ढाँप',
    'ढाँपना',
    'ढाँपने',
    'ढाँपा',
    'ढाँपी',
    'ढाँपू',
    'ढाँसना',
    'ढांकना',
    'ढांकने',
    'ढांचा',
    'ढांचे',
    'ढाई',
    'ढाक',
    'ढाढ़ी',
    'ढाना',
    'ढापना',
    'ढापी',
    'ढाबा',
    'ढामक',
    'ढामरा',
    'ढार',
    'ढारस',
    'ढाल',
    'ढालधारी',
    'ढालनहारा',
    'ढालना',
    'ढालने',
    'ढालवाँ',
    'ढालसा',
    'ढाला',
    'ढालाकार',
    'ढालित',
    'ढाली',
    'ढालू',
    'ढालूपन',
    'ढास',
    'ढासना',
    'ढासा',
    'ढिंढोरची',
    'ढिंढोरा',
    'ढिग',
    'ढिठाई',
    'ढिपनी',
    'ढिबरियां',
    'ढिबरियों',
    'ढिबरी',
    'ढिमका',
    'ढिमाका',
    'ढिलमिल',
    'ढिलाई',
    'ढिलाना',
    'ढिल्लड़',
    'ढिल्लो',
    'ढिसरन',
    'ढिसरना',
    'ढींगड़ा',
    'ढीट',
    'ढीटाई',
    'ढीठ',
    'ढीठना',
    'ढीठपन',
    'ढीढपन',
    'ढील',
    'ढीलना',
    'ढीला',
    'ढीलाढालापन',
    'ढीलापन',
    'ढीली',
    'ढीले',
    'ढीलेपन',
    'ढुँढना',
    'ढुंढा',
    'ढुंढिराज',
    'ढुकन',
    'ढुकना',
    'ढुकास',
    'ढुक्का',
    'ढुलकन',
    'ढुलकना',
    'ढुलकाना',
    'ढुलन',
    'ढुलना',
    'ढुलमुल',
    'ढुलमुलाना',
    'ढुला',
    'ढुलाई',
    'ढुलैया',
    'ढूँकना',
    'ढूँका',
    'ढूँढ',
    'ढूँढना',
    'ढूँढला',
    'ढूंढ',
    'ढूंढना',
    'ढूंढनेवाला',
    'ढूका',
    'ढूढ़िया',
    'ढूह',
    'ढूहा',
    'ढेंक',
    'ढेंकली',
    'ढेंकी',
    'ढेंकुल',
    'ढेंकुली',
    'ढेंचू',
    'ढेंढी',
    'ढेंप',
    'ढेंपनी',
    'ढेपनी',
    'ढेपी',
    'ढेर',
    'ढेरा',
    'ढेरी',
    'ढेरे',
    'ढेरों',
    'ढेलबाँस',
    'ढेलहरा',
    'ढेला',
    'ढेलाकार',
    'ढेली',
    'ढेलेदार',
    'ढैया',
    'ढोंक',
    'ढोंग',
    'ढोंगबाज़',
    'ढोंगी',
    'ढोक',
    'ढोकला',
    'ढोकसा',
    'ढोगपूर्ण',
    'ढोटा',
    'ढोटी',
    'ढोन',
    'ढोना',
    'ढोया',
    'ढोर',
    'ढोल',
    'ढोलक',
    'ढोलकिया',
    'ढोलकी',
    'ढोलची',
    'ढोलना',
    'ढोलनी',
    'ढोला',
    'ढोलाकार',
    'ढोलिया',
    'ढोली',
    'तँगिया',
    'तँबोलन',
    'तँबोली',
    'तँवर',
    'तंक',
    'तंकाबूः',
    'तंग',
    'तंगदस्त',
    'तंगदस्ती',
    'तंगदिल',
    'तंगदिली',
    'तंगहाल',
    'तंगहाली',
    'तंगी',
    'तंज़',
    'तंजिया',
    'तंटक',
    'तंडक',
    'तंडुरीण',
    'तंडुल',
    'तंडुलांबु',
    'तंडुली',
    'तंत',
    'तंतर',
    'तंतरी',
    'तंतवर्धन',
    'तंति',
    'तंतिपाल',
    'तंतु',
    'तंतुक',
    'तंतुकी',
    'तंतुगर्भा',
    'तंतुमय',
    'तंतुयुक्त',
    'तंतुवाप',
    'तंतुवाय',
    'तंतू',
    'तंत्र',
    'तंत्रकीय',
    'तंत्रण',
    'तंत्रवाद',
    'तंत्रवादी',
    'तंत्रवाप',
    'तंत्रवाय',
    'तंत्रात्मक',
    'तंत्रि',
    'तंत्रिक',
    'तंत्रिका',
    'तंत्रिकाप्रेरण',
    'तंत्रिकावसादी',
    'तंत्रिकाविज्ञान',
    'तंत्रिकाशूल',
    'तंत्री',
    'तंत्रीय',
    'तंदुरूस्त',
    'तंदुरूस्ती',
    'तंदुला',
    'तंदूर',
    'तंदूरी',
    'तंद्र',
    'तंद्रा',
    'तंद्राटक',
    'तंद्रालु',
    'तंद्रालुता',
    'तंद्राहीन',
    'तंद्राहीनता',
    'तंद्रिल',
    'तंद्री',
    'तंबाकू',
    'तंबुरूप',
    'तंबू',
    'तंबूल',
    'तंबोकू',
    'तंबोह',
    'तंरग',
    'तंरगित',
    'तअ',
    'तअल्लुकेदार',
    'तअल्लुकेदारी',
    'तअस्सुब',
    'तइँ',
    'तक',
    'तकड़',
    'तकड़ी',
    'तकदीर',
    'तकनीक',
    'तकनीकतंत्री',
    'तकनीकहीन',
    'तकनीकहीनता',
    'तकनीकी',
    'तकनीशियन',
    'तकमील',
    'तकरार',
    'तकरारी',
    'तकरीबन',
    'तकला',
    'तकली',
    'तकलीफ',
    'तकलीफ़',
    'तकलीफ़देह',
    'तकल्लफ़ी',
    'तकल्लुफ़',
    'तकल्लुफ़ाना',
    'तकवा',
    'तकसीम',
    'तक़',
    'तक़दीर',
    'तक़रीर',
    'तक़सीम',
    'तक़ाजा',
    'तक़ावी',
    'तकाजा',
    'तकाजे',
    'तकिया',
    'तकियागाह',
    'तकुआ',
    'तकुए',
    'तक्ति',
    'तक्र',
    'तक्राट',
    'तक्ष',
    'तक्षक',
    'तक्षण',
    'तक्षणी',
    'तक्षा',
    'तक्षित',
    'तख़ता',
    'तख़मीना',
    'तख़लिया',
    'तख़ल्लुस',
    'तख़्त',
    'तख़्तचादर',
    'तख़्तनशीन',
    'तख़्तनशीनी',
    'तख़्तपोश',
    'तख़्ता',
    'तख़्तापलट',
    'तख़्ती',
    'तख़्ते',
    'तख्त',
    'तख्ता',
    'तख्ती',
    'तख्ते',
    'तख्तों',
    'तगड़ा',
    'तगड़ापन',
    'तगड़ॣ',
    'तगडा',
    'तगण',
    'तगदमा',
    'तगमा',
    'तगर',
    'तग़मा',
    'तग़ाफुल',
    'तगाड़',
    'तगार',
    'तगारी',
    'तज',
    'तजना',
    'तजने',
    'तजरबा',
    'तजरबेकार',
    'तजवीज़',
    'तज़किरा',
    'तज़र्',
    'तज़र्ना',
    'तज़र्नी',
    'तजि',
    'तजुरबा',
    'तजोमंडित',
    'तज्जली',
    'तट',
    'तटकर्मी',
    'तटगामी',
    'तटबंध',
    'तटबन्ध',
    'तटय',
    'तटयात्रा',
    'तटवर्ती',
    'तटवासी',
    'तटस्थ',
    'तटस्थता',
    'तटस्थतावाद',
    'तटस्थवाद',
    'तटस्थीकरण',
    'तटस्थीकृत',
    'तटस्थेश्वरवाद',
    'तटस्थेश्वरवादी',
    'तटांचल',
    'तटाक',
    'तटावतरण',
    'तटावतरित',
    'तटास्थ',
    'तटिनी',
    'तटी',
    'तटीय',
    'तडंग',
    'तडकन',
    'तडकभड़कदार',
    'तड़',
    'तड़क',
    'तड़कना',
    'तड़कभड़कदार',
    'तड़का',
    'तड़कीला',
    'तड़कीली',
    'तड़के',
    'तड़केदार',
    'तड़तड़',
    'तड़तड़ाहट',
    'तड़तड़ी',
    'तड़तडाहट',
    'तड़प',
    'तड़पड़ाहट',
    'तड़पडाना',
    'तड़पता',
    'तड़पन',
    'तड़पना',
    'तड़पार',
    'तड़फड़ाना',
    'तड़बंदी',
    'तड़ाक',
    'तड़ाका',
    'तड़ातड़',
    'तड़ित',
    'तड़िता',
    'तड़ित्परभा',
    'तड़िया',
    'तड़ी',
    'तड़ीपार',
    'तडाग',
    'तडित',
    'तडित्वान',
    'तडिद्रेखा',
    'तत',
    'ततंबू',
    'ततंबूः',
    'ततंबूरची',
    'ततंबूरा',
    'ततराना',
    'ततसार',
    'ततारना',
    'ततैया',
    'तत्',
    'तत्काल',
    'तत्कालतः',
    'तत्कालता',
    'तत्कालधी',
    'तत्कालीन',
    'तत्क्षण',
    'तत्क्षणता',
    'तत्ता',
    'तत्ताथेई',
    'तत्तापन',
    'तत्तोथंंबो',
    'तत्त्व',
    'तत्त्वज्ञ',
    'तत्त्वज्ञता',
    'तत्त्वज्ञानी',
    'तत्त्वतः',
    'तत्त्वता',
    'तत्त्वदर्श',
    'तत्त्वदर्शी',
    'तत्त्वमसि',
    'तत्त्वमीमांसक',
    'तत्त्वमीमांसा',
    'तत्त्वमीमांसीय',
    'तत्त्ववाद',
    'तत्त्वहीन',
    'तत्त्वाधान',
    'तत्त्वावधनक',
    'तत्त्वावधान',
    'तत्त्वीय',
    'तत्त्वेत्ता',
    'तत्पर',
    'तत्परतः',
    'तत्परता',
    'तत्परतापूर्वक',
    'तत्परायण',
    'तत्पश्चात',
    'तत्पुरुष',
    'तत्र',
    'तत्रक',
    'तत्रा',
    'तत्री',
    'तत्लीन',
    'तत्व',
    'तत्वग्राही',
    'तत्वज्ञ',
    'तत्वज्ञान',
    'तत्वदर्शी',
    'तत्वपूर्ण',
    'तत्ववादी',
    'तत्वावधान',
    'तत्वों',
    'तत्सम',
    'तत्सम्बन्धित',
    'तत्स्रोत',
    'तथा',
    'तथाकथित',
    'तथाकथितता',
    'तथागत',
    'तथागति',
    'तथागतीय',
    'तथापि',
    'तथाविध',
    'तथास्तु',
    'तथोक्त',
    'तथ्य',
    'तथ्यग्राही',
    'तथ्यज्ञान',
    'तथ्यतः',
    'तथ्यता',
    'तथ्यपरक',
    'तथ्यपरकता',
    'तथ्यपरायण',
    'तथ्यपूर्ण',
    'तथ्यपूर्णता',
    'तथ्यवादी',
    'तथ्यविषयक',
    'तथ्यविषयकता',
    'तथ्यहीन',
    'तथ्यहीनता',
    'तथ्यात्मक',
    'तथ्यात्मकता',
    'तथ्यान्वेक्षण',
    'तथ्यान्वेषक',
    'तथ्यान्वेषण',
    'तथ्यान्वेषित',
    'तथ्यान्वेषी',
    'तथ्यों',
    'तदनंतर',
    'तदनन्तर',
    'तदनुभूति',
    'तदनुरुप',
    'तदनुरुपी',
    'तदनुसार',
    'तदन्तर',
    'तदपि',
    'तदबीर',
    'तदर्थ',
    'तदा',
    'तदाकार',
    'तदात्म',
    'तदापि',
    'तदुपरांत',
    'तदुपरि',
    'तदोपरान्त',
    'तद्',
    'तद्काल',
    'तद्कालीनता',
    'तद्गत',
    'तद्घा',
    'तद्नुरूप',
    'तद्नुरूपी',
    'तद्नुसार',
    'तद्परायणता',
    'तद्पर्यत',
    'तद्पूर्व',
    'तद्भव',
    'तद्रूप',
    'तद्रूपता',
    'तन',
    'तनक',
    'तनख़्वाह',
    'तनख़्वाहदार',
    'तनख़्वाहदारी',
    'तनखैया',
    'तनख्वाह',
    'तनज्',
    'तनदेही',
    'तनन',
    'तनना',
    'तनमन',
    'तनय',
    'तनया',
    'तनसीख़',
    'तनहा',
    'तनहाई',
    'तना',
    'तनाः',
    'तनाई',
    'तनाज़ा',
    'तनातनी',
    'तनाव',
    'तनाहीन',
    'तनि',
    'तनिक',
    'तनिमा',
    'तनियाँ',
    'तनी',
    'तनु',
    'तनुकृत',
    'तनुज',
    'तनुजा',
    'तनुता',
    'तनुत्राण',
    'तनुत्वक',
    'तनुपट',
    'तनुरुह',
    'तनू',
    'तनूजा',
    'तनूर',
    'तने',
    'तन्ज़ीम',
    'तन्तु',
    'तन्तुक',
    'तन्तुमय',
    'तन्त्र',
    'तन्त्रिकान्धता',
    'तन्दूर',
    'तन्न्वी',
    'तन्मनस्क',
    'तन्मनस्कता',
    'तन्मय',
    'तन्मयकारी',
    'तन्मयता',
    'तन्मात्रा',
    'तन्यता',
    'तन्वंगी',
    'तप',
    'तपःशक्ति',
    'तपकना',
    'तपकर्ता',
    'तपता',
    'तपती',
    'तपन',
    'तपना',
    'तपनांशु',
    'तपनी',
    'तपनोपल',
    'तपवंत',
    'तपश्चर्या',
    'तपसी',
    'तपस्या',
    'तपस्वी',
    'तपस्वीय',
    'तपा',
    'तपाई',
    'तपाक',
    'तपाकर',
    'तपानल',
    'तपाना',
    'तपापन',
    'तपाव',
    'तपिया',
    'तपिश',
    'तपी',
    'तपीं',
    'तपु',
    'तपेदिक',
    'तपेदिक़',
    'तपोधन',
    'तपोबल',
    'तपोभूमि',
    'तपोमय',
    'तपोमूर्ति',
    'तपोलोक',
    'तपोवन',
    'तपोव्रत',
    'तपोशक्ति',
    'तपौनी',
    'तप्त',
    'तप्तक',
    'तप्तता',
    'तप्ता',
    'तप्ति',
    'तप्र्य',
    'तफतीश',
    'तफ़तीश',
    'तफ़रीक',
    'तफ़रीह',
    'तफ़सील',
    'तफ्रतीश',
    'तफ्ररीह',
    'तफ्ररीहन',
    'तफ्रसील',
    'तफ्रसीली',
    'तब',
    'तबक़',
    'तबक़ा',
    'तबके',
    'तबदील',
    'तबदीली',
    'तबर्रा',
    'तबला',
    'तबलाःदाहिना',
    'तबलिया',
    'तबलीग़',
    'तबलीची',
    'तबल्लत',
    'तबादला',
    'तबाह',
    'तबाही',
    'तबीअत',
    'तबीब',
    'तबीयत',
    'तबील',
    'तबीला',
    'तबे',
    'तभी',
    'तम',
    'तमंचा',
    'तमक',
    'तमकना',
    'तमग़ा',
    'तमचर',
    'तमचीर',
    'तमतमाना',
    'तमतमाया',
    'तमन',
    'तमन्ना',
    'तमर',
    'तमस',
    'तमस्वी',
    'तमस्सुक',
    'तमा',
    'तमाई',
    'तमाकू',
    'तमाखू',
    'तमाचा',
    'तमाम',
    'तमामणि',
    'तमामशुद्घ',
    'तमामी',
    'तमाल',
    'तमालक',
    'तमाशगीर',
    'तमाशगीरी',
    'तमाशबीन',
    'तमाशा',
    'तमाशाई',
    'तमाशागार',
    'तमाशेबाज़',
    'तमाशेबाज़ी',
    'तमिझ',
    'तमिरत्र',
    'तमिरत्रहा',
    'तमिरत्रा',
    'तमिरत्रामिसारिका',
    'तमिल',
    'तमिस्रा',
    'तमिस्वनी',
    'तमी',
    'तमीचर',
    'तमीज़',
    'तमीज़दार',
    'तमीश',
    'तमूरा',
    'तमेरा',
    'तमोगुण',
    'तमोगुणी',
    'तमोधन',
    'तमोमय',
    'तमोलन',
    'तमोली',
    'तमोहर',
    'तम्बाकू',
    'तम्बू',
    'तमग़ा',
    'तय',
    'तयक्त',
    'तयशुदा',
    'तर',
    'तरंग',
    'तरंगः',
    'तरंगकारी',
    'तरंगन',
    'तरंगयुक्त',
    'तरंगहीन',
    'तरंगहीनता',
    'तरंगा',
    'तरंगायक',
    'तरंगावली',
    'तरंगिका',
    'तरंगिणी',
    'तरंगित',
    'तरंगितता',
    'तरंगित्र',
    'तरंगी',
    'तरंगें',
    'तरंगों',
    'तरंड',
    'तरंडा',
    'तरंडी',
    'तरंत',
    'तरंती',
    'तरकश',
    'तरकस',
    'तरक़ीब',
    'तरक़्क़ी',
    'तरकारी',
    'तरकीब',
    'तरकुल',
    'तरकून',
    'तरक्की',
    'तरखा',
    'तरखान',
    'तरछट',
    'तरज',
    'तरजना',
    'तरजीह',
    'तरजीही',
    'तरजुमा',
    'तरड',
    'तरण',
    'तरणशील',
    'तरणि',
    'तरणी',
    'तरण्ड',
    'तरत',
    'तरतीब',
    'तरतीबवार',
    'तरत्रुम',
    'तरदीद',
    'तरन',
    'तरना',
    'तरन्नुम',
    'तरपर',
    'तरफ',
    'तरफदार',
    'तरफ़',
    'तरफ़दार',
    'तरफ़दारी',
    'तरफा',
    'तरब',
    'तरबतर',
    'तरबूज',
    'तरबूज़',
    'तरबूजा',
    'तरमीम',
    'तररक्क़ॣ',
    'तररक्क़ॣपसंदी',
    'तररना',
    'तरल',
    'तरलः',
    'तरलता',
    'तरलीत',
    'तरलीभूत',
    'तरस',
    'तरसना',
    'तरसाय',
    'तरसों',
    'तरसौंही',
    'तरस्वी',
    'तरह',
    'तरा',
    'तरांधु',
    'तराई',
    'तराज़ू',
    'तराजू',
    'तराना',
    'तरानासाज़',
    'तराप',
    'तरापा',
    'तराबोर',
    'तरार',
    'तराश',
    'तराशक',
    'तराशना',
    'तराशा',
    'तराशे',
    'तराज़ू',
    'तरिक',
    'तरिका',
    'तरिणी',
    'तरित्र',
    'तरिस्वनी',
    'तरी',
    'तरीक़त',
    'तरीक़ा',
    'तरीक़े',
    'तरीका',
    'तरीकावाला',
    'तरीके',
    'तरीकेवाला',
    'तरीछ',
    'तरीदार',
    'तरु',
    'तरुण',
    'तरू',
    'तरूट',
    'तरूण',
    'तरूणाई',
    'तरूणास्थि',
    'तरूणिमा',
    'तरूणी',
    'तरूनख',
    'तरूपतिका',
    'तरूराज',
    'तरूवर',
    'तरूशायी',
    'तरेंदा',
    'तरेड़',
    'तरेर',
    'तरेरना',
    'तरैया',
    'तरो',
    'तरोई',
    'तर्क',
    'तर्कक',
    'तर्कगणित',
    'तर्कगम्य',
    'तर्कण',
    'तर्कत',
    'तर्कना',
    'तर्कनावादी',
    'तर्कपूर्ण',
    'तर्कपूर्णता',
    'तर्कप्रिय',
    'तर्कबुद्धि',
    'तर्कमूलक',
    'तर्कयुक्त',
    'तर्कल',
    'तर्कवादी',
    'तर्कशास्त्र',
    'तर्कसंगत',
    'तर्कसंगति',
    'तर्कहीन',
    'तर्कहीनता',
    'तर्कातीत',
    'तर्काधार',
    'तर्काधीन',
    'तर्कानुकूल',
    'तर्कानुतर्क',
    'तर्कानुतर्कण',
    'तर्काभास',
    'तर्कासंगत',
    'तर्कि',
    'तर्कीय',
    'तर्कु',
    'तर्के',
    'तर्ज',
    'तर्जन',
    'तर्जनी',
    'तर्ज़',
    'तर्जुमा',
    'तर्झु',
    'तर्दक',
    'तर्दन',
    'तर्दू',
    'तर्पक',
    'तर्पण',
    'तर्पणी',
    'तर्पणीय',
    'तर्रार',
    'तर्ष',
    'तरफ़',
    'तरफ़दारी',
    'तल',
    'तलकर्षक',
    'तलकर्षण',
    'तलख़ी',
    'तलघर',
    'तलछंट',
    'तलछट',
    'तलछटन',
    'तलछटी',
    'तलत्राण',
    'तलना',
    'तलफ़्फ़ुज़',
    'तलब',
    'तलबगार',
    'तलबनामा',
    'तलबी',
    'तलमलाना',
    'तलमार्जक',
    'तलमार्जन',
    'तलमुद',
    'तलवा',
    'तलवार',
    'तलवारः',
    'तलवारधारी',
    'तलवारबाजी',
    'तलवे',
    'तलवों',
    'तलशेष',
    'तलस्थ',
    'तलहटी',
    'तला',
    'तलांतर',
    'तलाई',
    'तलाक',
    'तलाकशुदा',
    'तलाक़',
    'तलाक़ः',
    'तलाश',
    'तलाशना',
    'तलाशी',
    'तलाक़',
    'तलीय',
    'तलीयता',
    'तले',
    'तलैया',
    'तलौछ',
    'तल्ख़',
    'तल्ला',
    'तल्लीन',
    'तल्लीनकारी',
    'तल्लीनता',
    'तल्लों',
    'तवा',
    'तवाजो',
    'तवायफ़',
    'तवालत',
    'तवासीर',
    'तविध',
    'तवील',
    'तवे',
    'तशद्दुद',
    'तशद्दृद',
    'तशरीफ',
    'तशरीफ़',
    'तश्त',
    'तश्ततरी',
    'तश्तरियां',
    'तश्तरी',
    'तष्ट',
    'तष्टा',
    'तसकीन',
    'तसदीक़',
    'तसफ़ीय',
    'तसबीह',
    'तसमा',
    'तसर',
    'तसला',
    'तसलीम',
    'तसले',
    'तसल्ली',
    'तसल्लीबख़्श',
    'तसवीर',
    'तसव्वुफ़',
    'तस्कर',
    'तस्करी',
    'तस्मा',
    'तस्लीम',
    'तस्वीर',
    'तह',
    'तहक़ीक़',
    'तहक़ीक़ात',
    'तहक़ीक़ाती',
    'तहकीकात',
    'तहख़ाना',
    'तहखाना',
    'तहज़ीब',
    'तहज़ीबदार',
    'तहत',
    'तहदार',
    'तहपेंच',
    'तहपोशी',
    'तहबंद',
    'तहमत',
    'तहमद',
    'तहरिलता',
    'तहरी',
    'तहरीक',
    'तहरीकी',
    'तहरीर',
    'तहरीरी',
    'तहलका',
    'तहस',
    'तहसील',
    'तहसीलघर',
    'तहसीलदार',
    'तहाँ',
    'तहां',
    'तहाना',
    'तहाशा',
    'तहियाना',
    'ताँगा',
    'ताँत',
    'ताँतघर',
    'ताँता',
    'ताँताया',
    'ताँती',
    'ताँबई',
    'ताँबा',
    'ताँबिया',
    'तांकझांक',
    'तांगा',
    'तांगे',
    'तांडव',
    'तांत',
    'तांतव',
    'तांतवा',
    'तांता',
    'तांति',
    'तांतिकरक',
    'तांतिया',
    'तांत्रिक',
    'तांत्रिकवाद',
    'तांत्विक',
    'तांबा',
    'तांबूल',
    'तांबूली',
    'तांबे',
    'ताइवान',
    'ताई',
    'ताईद',
    'ताउन',
    'ताउम्र',
    'ताऊ',
    'ताऊः',
    'ताऊन',
    'ताऊस',
    'ताक',
    'ताकझाँक',
    'ताकत',
    'ताकना',
    'ताक़',
    'ताक़त',
    'ताक़तवर',
    'ताक़तवरी',
    'ताक़ता',
    'ताकाझाँकी',
    'ताकि',
    'ताकिया',
    'ताकीद',
    'ताक्ष्र्य',
    'ताख',
    'ताखा',
    'तागड़',
    'तागा',
    'ताज',
    'ताजन',
    'ताज़गी',
    'ताज़दार',
    'ताज़पाश',
    'ताज़पाशी',
    'ताज़ा',
    'ताज़ादम',
    'ताज़ापन',
    'ताज़िया',
    'ताज़ीम',
    'ताज़ीर',
    'ताज़ीरात',
    'ताज़ीराते',
    'ताजा',
    'ताजिए',
    'ताजिर',
    'ताजी',
    'ताज्जुब',
    'ताटका',
    'ताडंव',
    'ताडक',
    'ताड़',
    'ताड़क',
    'ताड़का',
    'ताड़काः',
    'ताड़कारि',
    'ताड़केय',
    'ताड़दाना',
    'ताड़न',
    'ताड़ना',
    'ताड़पत्र',
    'ताड़ि',
    'ताड़ित',
    'ताड़ी',
    'तातंबूल',
    'तातगु',
    'तातल',
    'तातहीन',
    'ताता',
    'ताताथेई',
    'ताताथैया',
    'ताति',
    'तातील',
    'तात्कालिक',
    'तात्कालिकता',
    'तात्कालीन',
    'तात्पर्य',
    'तात्विक',
    'ताथेई',
    'तादात्म्य',
    'तादाद',
    'तादृश्य',
    'ताधा',
    'तान',
    'तानकर',
    'तानना',
    'तानने',
    'तानपुरा',
    'तानपूरा',
    'तानयुक्त',
    'ताना',
    'तानाकश',
    'तानाबाना',
    'तानाशाह',
    'तानाशाही',
    'ताप',
    'तापक',
    'तापकारी',
    'तापक्रम',
    'तापती',
    'तापदर्शी',
    'तापन',
    'तापना',
    'तापनाभिकीय',
    'तापमान',
    'तापमान्य',
    'तापमापी',
    'तापमिति',
    'तापयुक्त',
    'तापस',
    'तापस्थायी',
    'तापहर',
    'तापहीन',
    'तापांक',
    'तापाघात',
    'तापानुभूति',
    'तापान्तरमापी',
    'तापायन',
    'तापायनिक',
    'तापायनिकी',
    'तापाल्पता',
    'तापित्र',
    'तापी',
    'तापीय',
    'तापोपचार',
    'ताप्ती',
    'ताप्य',
    'ताफ',
    'ताब',
    'ताबड़तोड़',
    'ताबी',
    'ताबीज',
    'ताबीज़',
    'ताबूत',
    'ताबेदार',
    'ताबेदारी',
    'तामजान',
    'तामझाम',
    'तामदान',
    'तामरस',
    'तामलोट',
    'तामस',
    'तामसिक',
    'तामसिकता',
    'तामसी',
    'तामिस्र',
    'तामीर',
    'तामील',
    'तामोल',
    'ताम्र',
    'ताम्रकर्णी',
    'ताम्रकार',
    'ताम्रकुट्टाक',
    'ताम्रकूट',
    'ताम्रकूटी',
    'ताम्रपत्र',
    'ताम्रमय',
    'ताम्रवर्ण',
    'ताम्रसत',
    'ताम्रार्ध',
    'ताम्रिक',
    'ताम्री',
    'ताम्रोपजीवी',
    'ताय',
    'तायस',
    'तायसरा',
    'तायसराः',
    'ताया',
    'तार',
    'तारक',
    'तारका',
    'तारकाणु',
    'तारकाभ',
    'तारकारि',
    'तारकाशी',
    'तारकिणी',
    'तारकित',
    'तारकी',
    'तारकीय',
    'तारकेश्वर',
    'तारघर',
    'तारण',
    'तारणी',
    'तारणीय',
    'तारणीयता',
    'तारतम्य',
    'तारतम्यपूर्ण',
    'तारतम्यपूर्वक',
    'तारतम्यहीन',
    'तारतम्यहीनतः',
    'तारतम्यहीनता',
    'तारत्व',
    'तारदोर',
    'तारन',
    'तारनहार',
    'तारना',
    'तारपीडो',
    'तारपीन',
    'तारपीनः',
    'तारबंदी',
    'तारबद्घ',
    'तारभाषा',
    'तारयंत्र',
    'तारयिता',
    'तारयुक्त',
    'तारल',
    'तारलेखी',
    'तारल्य',
    'तारस्वरण',
    'तारा',
    'तारांकित',
    'तारांगण',
    'तारांत',
    'ताराः',
    'ताराकीय',
    'ताराग्रह',
    'ताराघर',
    'ताराद्यिप',
    'तारानाथ',
    'तारापति',
    'तारापथ',
    'ताराभ',
    'तारामंडल',
    'तारायुक्त',
    'तारावती',
    'ताराश्म',
    'ताराहीन',
    'तारिका',
    'तारिख',
    'तारित',
    'तारी',
    'तारीक',
    'तारीकी',
    'तारीख',
    'तारीखवाला',
    'तारीख़',
    'तारीख़ी',
    'तारीखें',
    'तारीफ़',
    'तारीफ़शुदा',
    'तारे',
    'तारेय',
    'तारेश',
    'तारों',
    'तार्कसंगति',
    'तार्किक',
    'तार्पिज',
    'तार्य',
    'ताल',
    'तालकेतु',
    'तालक्षीर',
    'तालखंड',
    'तालगत',
    'तालपत्र',
    'तालबद्घ',
    'तालबद्घता',
    'तालबद्ध',
    'तालमय',
    'तालमेल',
    'तालयुक्त',
    'तालयुक्तता',
    'तालव्य',
    'तालव्यीकरण',
    'तालस्वरयुक्त',
    'तालस्वरवाही',
    'तालस्वरहीन',
    'तालहीन',
    'तालहीनता',
    'ताला',
    'तालांक',
    'तालाब',
    'तालाबंदी',
    'तालाबन्दी',
    'तालाश',
    'तालाशी',
    'तालासाज़',
    'तालिक',
    'तालिका',
    'तालिकावार',
    'तालित',
    'तालिब',
    'ताली',
    'तालीम',
    'तालीमयाफ्',
    'तालीमी',
    'तालीशपत्र',
    'तालु',
    'तालुः',
    'तालुमूल',
    'तालू',
    'ताले',
    'तालैक्य',
    'तालोद्भव',
    'ताल्लुक़',
    'ताव',
    'तावान',
    'तावीज',
    'तावीज़',
    'ताश',
    'ताशः',
    'ताशा',
    'ताशी',
    'तासा',
    'तासीर',
    'तास्सुबी',
    'ताहम',
    'ताहिरी',
    'ताक़त',
    'ताज़गी',
    'ताज़ा',
    'तिंतिडी',
    'तिंदिश',
    'तिकड़म',
    'तिकड़मी',
    'तिकड़ा',
    'तिकड़ी',
    'तिकतिक',
    'तिकोन',
    'तिकोना',
    'तिकोनिया',
    'तिक्का',
    'तिक्की',
    'तिक्त',
    'तिक्तता',
    'तिक्तातु',
    'तिखंड',
    'तिखंडा',
    'तिखंडीय',
    'तिखटी',
    'तिखना',
    'तिखूँट',
    'तिगड्डी',
    'तिगना',
    'तिगुना',
    'तिग्म',
    'तिग्मता',
    'तिजहरिया',
    'तिजहरी',
    'तिजारत',
    'तिजारती',
    'तिजोरी',
    'तिड़ी',
    'तित',
    'तितंबा',
    'तितना',
    'तितली',
    'तितिक्षा',
    'तितिक्षु',
    'तितिम्मा',
    'तितो',
    'तित्तलिका',
    'तित्तिर',
    'तित्तिरि',
    'तित्त्वक',
    'तित्त्वकता',
    'तिथ',
    'तिथि',
    'तिथिका',
    'तिथिजन्य',
    'तिथिपत्र',
    'तिथिभ्रम',
    'तिथियां',
    'तिथियों',
    'तिदरा',
    'तिधर',
    'तिन',
    'तिनक',
    'तिनका',
    'तिन्नी',
    'तिन्ह',
    'तिन्हें',
    'तिपतवारी',
    'तिपतिया',
    'तिपत्ती',
    'तिपहर',
    'तिपहरिया',
    'तिपहरी',
    'तिपहला',
    'तिपहलू',
    'तिपहिया',
    'तिपाई',
    'तिफ़्ल',
    'तिब',
    'तिबारा',
    'तिब्बत',
    'तिब्बती',
    'तिमंजिला',
    'तिमहला',
    'तिमाही',
    'तिमि',
    'तिमिंगिल',
    'तिमिक',
    'तिमिर',
    'तिमुहाना',
    'तिम्का',
    'तिय',
    'तिया',
    'तिरंगा',
    'तिरंगापन',
    'तिरछा',
    'तिरछापन',
    'तिरछी',
    'तिरछे',
    'तिरना',
    'तिरपन',
    'तिरपाल',
    'तिरमिराहट',
    'तिरसठ',
    'तिरस्करण',
    'तिरस्कर्ता',
    'तिरस्कार',
    'तिरस्कारपूर्ण',
    'तिरस्कारात्मक',
    'तिरस्कारिता',
    'तिरस्कार्य',
    'तिराना',
    'तिरास्ता',
    'तिराहा',
    'तिरिया',
    'तिरीटक',
    'तिरुअनंतपुरम',
    'तिरूपति',
    'तिरोधान',
    'तिरोधायक',
    'तिरोभाव',
    'तिरोभूत',
    'तिरोहित',
    'तिर्तक',
    'तिर्यक',
    'तिर्यकता',
    'तिर्यग्ता',
    'तिर्यरेखा',
    'तिल',
    'तिलक',
    'तिलका',
    'तिलकाधिकारी',
    'तिलकाभिषेक',
    'तिलगी',
    'तिलघटा',
    'तिलड़ी',
    'तिलदानी',
    'तिलभुग्गा',
    'तिलमिलाता',
    'तिलमिलाना',
    'तिलमिलाहट',
    'तिलहन',
    'तिलांजलि',
    'तिलिस्म',
    'तिलिस्मगर',
    'तिलिस्मबंद',
    'तिलिस्माती',
    'तिलिस्मी',
    'तिली',
    'तिलोत्तमा',
    'तिल्लन',
    'तिल्ला',
    'तिल्ली',
    'तिशना',
    'तिष्ठ',
    'तिष्ठद्गु',
    'तिष्ठन',
    'तिष्ठा',
    'तिष्ठापन',
    'तिष्ठायक',
    'तिष्ठित',
    'तिष्य',
    'तिस',
    'तिसरैत',
    'तिसरैल',
    'तिस्कृत',
    'तिहरा',
    'तिहरी',
    'तिहवारी',
    'तिहाई',
    'तिहार',
    'तिहारा',
    'तिहारी',
    'तिहेजवाँ',
    'तिहेजिया',
    'तिहेजू',
    'तिहैया',
    'तीअल',
    'तीक्ष्ण',
    'तीक्ष्णक',
    'तीक्ष्णगंध',
    'तीक्ष्णगंधा',
    'तीक्ष्णता',
    'तीक्ष्णतापूर्वक',
    'तीक्ष्णधी',
    'तीक्ष्णरस',
    'तीक्ष्णसार',
    'तीक्ष्णांशु',
    'तीक्ष्णाग्र',
    'तीक्ष्णायस',
    'तीखा',
    'तीखापन',
    'तीखी',
    'तीखे',
    'तीखेपन',
    'तीज',
    'तीजा',
    'तीजैं',
    'तीजों',
    'तीतर',
    'तीतापन',
    'तीती',
    'तीन',
    'तीनःउपमान',
    'तीनताल',
    'तीनों',
    'तीमारदारी',
    'तीय',
    'तीया',
    'तीर',
    'तीरंदांज़',
    'तीरंदांज़ी',
    'तीरगर',
    'तीरथ',
    'तीरन्दाज',
    'तीरवर्ती',
    'तीरा',
    'तीरित',
    'तीर्ण',
    'तीर्थ',
    'तीर्थःपंडा',
    'तीर्थकर',
    'तीर्थकरीय',
    'तीर्थकाक',
    'तीर्थराज',
    'तीर्थव्यास',
    'तीर्थसेवी',
    'तीर्थाटक',
    'तीर्थाटन',
    'तीर्थिक',
    'तीर्थेपजीवी',
    'तीलियां',
    'तीली',
    'तीव्र',
    'तीव्रगति',
    'तीव्रगामी',
    'तीव्रतः',
    'तीव्रता',
    'तीव्रन',
    'तीव्रनंद',
    'तीव्रबंध',
    'तीव्रा',
    'तीव्रानुभूति',
    'तीव्रित',
    'तीव्रीकरण',
    'तीव्रोन्माद',
    'तीस',
    'तीसमार',
    'तीसर',
    'तीसरा',
    'तीसरी',
    'तीसरे',
    'तीसवाँ',
    'तीसवां',
    'तीसी',
    'तीसों',
    'तुँदैल',
    'तुंग',
    'तुंगता',
    'तुंगबीज',
    'तुंगिमा',
    'तुंगीश',
    'तुंज',
    'तुंड',
    'तुंडिका',
    'तुंडी',
    'तुंतु',
    'तुंद',
    'तुंदिंल',
    'तुंदी',
    'तुंबर',
    'तुंबरू',
    'तुंबा',
    'तुंबी',
    'तुंबुरु',
    'तुंरग',
    'तुंरत',
    'तुअर',
    'तुक',
    'तुकपूर्णता',
    'तुकबंदी',
    'तुकबन्दी',
    'तुकबाज़',
    'तुकमा',
    'तुकयुक्त',
    'तुकहीन',
    'तुकांत',
    'तुकांतता',
    'तुकान्त',
    'तुकान्तक',
    'तुक्कड़',
    'तुक्का',
    'तुक्के',
    'तुक्केबाज़',
    'तुक्केबाज़ी',
    'तुख़्म',
    'तुगलकाबाद',
    'तुच्छ',
    'तुच्छता',
    'तुच्छातितुच्छ',
    'तुच्छार्थक',
    'तुजु',
    'तुझ',
    'तुझको',
    'तुझे',
    'तुड़ा',
    'तुड़ाई',
    'तुणध्वज',
    'तुतई',
    'तुतलाकर',
    'तुतलाना',
    'तुतलानेवाला',
    'तुतलाहट',
    'तुदंन',
    'तुन',
    'तुनक',
    'तुनकना',
    'तुनकमिजा',
    'तुनकमिजाजी',
    'तुनकमिजाजीपन',
    'तुनकमिज़ाज',
    'तुनकमिज़ाजी',
    'तुनकमिज़ाज़',
    'तुनकमिज़ाज़ी',
    'तुनुक',
    'तुनुकमिज़ाज',
    'तुपक',
    'तुफानी',
    'तुफ्रैंल',
    'तुबड़ा',
    'तुम',
    'तुमको',
    'तुमड़ी',
    'तुमने',
    'तुमसे',
    'तुमाई',
    'तुमुल',
    'तुम्हारी',
    'तुम्हारे',
    'तुम्हें',
    'तुरंग',
    'तुरंगम',
    'तुरंगमी',
    'तुरंगवदन',
    'तुरंगी',
    'तुरंज',
    'तुरंजबीन',
    'तुरंत',
    'तुरंतता',
    'तुरई',
    'तुरग',
    'तुरगी',
    'तुरन्त',
    'तुरपई',
    'तुरपन',
    'तुरपाई',
    'तुरबत',
    'तुरम',
    'तुरमची',
    'तुरसीला',
    'तुरही',
    'तुराई',
    'तुराणय',
    'तुराना',
    'तुराषाह',
    'तुरी',
    'तुरीय',
    'तुरुप',
    'तुरूप',
    'तुरूष',
    'तुरूष्क',
    'तुर्क',
    'तुर्की',
    'तुर्रा',
    'तुर्श',
    'तुर्शाना',
    'तुर्शी',
    'तुलन',
    'तुलनपत्र',
    'तुलना',
    'तुलनात्मक',
    'तुलनापूर्ण',
    'तुलनाहीन',
    'तुलनाहीनता',
    'तुलनीय',
    'तुलवाई',
    'तुलसी',
    'तुला',
    'तुलाःपलड़ा',
    'तुलाई',
    'तुलाईकार',
    'तुलाकूट',
    'तुलाघट',
    'तुलादंड',
    'तुलाधर',
    'तुलाधार',
    'तुलामान',
    'तुलिका',
    'तुलित',
    'तुल्य',
    'तुल्यकोणी',
    'तुल्यता',
    'तुल्यदर्शन',
    'तुल्यदर्शी',
    'तुल्यनामता',
    'तुल्यनामा',
    'तुल्यमान',
    'तुल्यरुप',
    'तुल्यरुपता',
    'तुल्यलक्षण',
    'तुल्यलक्षणता',
    'तुल्यांक',
    'तुल्यानुपात',
    'तुवर',
    'तुवरी',
    'तुष',
    'तुषार',
    'तुषारण',
    'तुषारर्तु',
    'तुषारांशु',
    'तुषाराघात',
    'तुषाराच्छन्न',
    'तुषाराच्छन्नता',
    'तुषाराच्छादन',
    'तुषाराच्छादित',
    'तुषारालय',
    'तुषारावृत',
    'तुषारित',
    'तुषित',
    'तुष्ट',
    'तुष्टता',
    'तुष्टन',
    'तुष्टि',
    'तुष्टिकरण',
    'तुष्टिकारी',
    'तुष्टित',
    'तुष्टीकरण',
    'तुष्टीकृत',
    'तुस',
    'तुसा',
    'तुस्सा',
    'तुहिन',
    'तुहिनकर',
    'तुहिनांशु',
    'तुहिनाचल',
    'तुफ़ानी',
    'तुफ़ानीपन',
    'तू',
    'तूँबड़ी',
    'तूख',
    'तूण',
    'तूणि',
    'तूणीर',
    'तूत',
    'तूती',
    'तूतू',
    'तूद',
    'तूदा',
    'तून',
    'तूना',
    'तूफ़ान',
    'तूफ़ानी',
    'तूफान',
    'तूफानी',
    'तूबरी',
    'तूम',
    'तूमड़ी',
    'तूमडा',
    'तूमना',
    'तूमा',
    'तूमार',
    'तूर',
    'तूरा',
    'तूर्य',
    'तूर्यघोष',
    'तूर्यनाद',
    'तूर्याश्रम',
    'तूल',
    'तूला',
    'तूलि',
    'तूलिक',
    'तूलिका',
    'तूली',
    'तूवर',
    'तूवरिका',
    'तूसदान',
    'तूफ़ान',
    'तूफ़ानी',
    'तूफ़ानीपन',
    'तृण',
    'तृणकेतु',
    'तृणजीवी',
    'तृणराज',
    'तृणशय्या',
    'तृणावर्त',
    'तृणाहारी',
    'तृतीय',
    'तृतीयक',
    'तृतीया',
    'तृतीयांश',
    'तृतीयाशिंक',
    'तृतीयाश्रम',
    'तृप्त',
    'तृप्तः',
    'तृप्तकाम',
    'तृप्ततः',
    'तृप्तता',
    'तृप्ति',
    'तृप्तिवाद',
    'तृम्र',
    'तृषा',
    'तृषालु',
    'तृषाह',
    'तृषाहा',
    'तृषाहीन',
    'तृषाहीनता',
    'तृषित',
    'तृष्टि',
    'तृष्णा',
    'तृष्णाधिक्य',
    'तृष्णाहीन',
    'तृष्ति',
    'तेंज़',
    'तेंदुआ',
    'तेंदुई',
    'तेंदू',
    'तेइरया',
    'तेग',
    'तेग़',
    'तेग़े',
    'तेज',
    'तेजड़िया',
    'तेजवंत',
    'तेजवान',
    'तेजस',
    'तेजस्कर',
    'तेजस्वी',
    'तेजहानि',
    'तेजहीन',
    'तेजहीनता',
    'तेज़',
    'तेज़गति',
    'तेज़दम',
    'तेज़पूर्ण',
    'तेज़ाब',
    'तेज़ी',
    'तेजाब',
    'तेजिस्वता',
    'तेजी',
    'तेजोमंडल',
    'तेजोमय',
    'तेजोमूर्ति',
    'तेजोरुप',
    'तेडड़िया',
    'तेता',
    'तेतिक',
    'तेतीस',
    'तेन',
    'तेमन',
    'तेरस',
    'तेरह',
    'तेरहवी',
    'तेरहवीं',
    'तेरही',
    'तेरा',
    'तेरो',
    'तेल',
    'तेलः',
    'तेलकूप',
    'तेलपोत',
    'तेलरोध',
    'तेलहँड़ा',
    'तेलहँड़ी',
    'तेलहन',
    'तेली',
    'तेलुगु',
    'तेवन',
    'तेवर',
    'तेवरा',
    'तेवरी',
    'तेवहार',
    'तेहरवां',
    'तेहरा',
    'तेज़',
    'तेज़ी',
    'तैंतीस',
    'तैतिर',
    'तैत्तिरीय',
    'तैना',
    'तैनात',
    'तैनाती',
    'तैयार',
    'तैयारी',
    'तैर',
    'तैरता',
    'तैरती',
    'तैरते',
    'तैरना',
    'तैरने',
    'तैराक',
    'तैराकी',
    'तैराना',
    'तैर्थकर',
    'तैर्थिक',
    'तैल',
    'तैलकार',
    'तैलगंना',
    'तैलपर्णी',
    'तैलमुद्रण',
    'तैलमुद्रित',
    'तैलयुक्त',
    'तैलरंग',
    'तैलाख्य',
    'तैलिक',
    'तैश',
    'तैशातैशी',
    'तैसा',
    'तैसे',
    'तो',
    'तोंद',
    'तोंदल',
    'तोंदवाला',
    'तोंदीला',
    'तोंदू',
    'तोखना',
    'तोटका',
    'तोडना',
    'तोड़',
    'तोड़क',
    'तोड़जोड़',
    'तोड़ना',
    'तोड़ने',
    'तोड़फोड़',
    'तोड़ा',
    'तोड़े',
    'तोड़ेदार',
    'तोडू',
    'तोडूफोडू',
    'तोतलापन',
    'तोता',
    'तोताचश्म',
    'तोताचश्मी',
    'तोतापरी',
    'तोती',
    'तोद',
    'तोदन',
    'तोदित',
    'तोप',
    'तोपख़ाना',
    'तोपखाना',
    'तोपगाड़ी',
    'तोपघर',
    'तोपची',
    'तोपना',
    'तोपविद्या',
    'तोपें',
    'तोपों',
    'तोबड़ा',
    'तोबा',
    'तोभ',
    'तोम',
    'तोमनोम',
    'तोमर',
    'तोमरिका',
    'तोमा',
    'तोय',
    'तोयद',
    'तोयनिधि',
    'तोयाधार',
    'तोयेश',
    'तोरई',
    'तोरण',
    'तोरा',
    'तोरी',
    'तोल',
    'तोलक',
    'तोलकार',
    'तोलन',
    'तोलनहार',
    'तोलना',
    'तोलमोल',
    'तोला',
    'तोलाः',
    'तोलित्र',
    'तोशक',
    'तोशा',
    'तोशाख़ाना',
    'तोष',
    'तोषक',
    'तोषण',
    'तोषणा',
    'तोषणीय',
    'तोषदान',
    'तोषप्रद',
    'तोषित',
    'तोषी',
    'तोष्य',
    'तोहती',
    'तोहफ़ा',
    'तोहमत',
    'तोहर',
    'तोहरा',
    'तोहिं',
    'तौंक़',
    'तौंगा',
    'तौंस',
    'तौंसा',
    'तौफ़ीक़',
    'तौफीकी',
    'तौबा',
    'तौबाशिकन',
    'तौर',
    'तौरत',
    'तौरीत',
    'तौरेत',
    'तौर्य',
    'तौल',
    'तौलन',
    'तौलना',
    'तौला',
    'तौलाई',
    'तौलित्र',
    'तौलिया',
    'तौली',
    'तौल्य',
    'तौषार',
    'तौहीद',
    'तौहीन',
    'त्यक्त',
    'त्यक्ता',
    'त्यजनीय',
    'त्याग',
    'त्यागना',
    'त्यागपत्र',
    'त्यागशील',
    'त्यागिनी',
    'त्यागी',
    'त्याज्य',
    'त्यों',
    'त्योरस',
    'त्योरी',
    'त्योहरी',
    'त्योहार',
    'त्यौहार',
    'त्रंयबकेश्वर',
    'त्रंयवरा',
    'त्रचड़ी',
    'त्रपा',
    'त्रपुष',
    'त्रप्सा',
    'त्रय',
    'त्रयंबक',
    'त्रयंबका',
    'त्रयंबिका',
    'त्रयक्षक',
    'त्रयक्षर',
    'त्रयस्त्र',
    'त्रयालय',
    'त्रयिग्न',
    'त्रयी',
    'त्रयीमुख',
    'त्रयोदश',
    'त्रयोदशाह',
    'त्रयोदशी',
    'त्रर',
    'त्रस',
    'त्रसत',
    'त्रसना',
    'त्रसर',
    'त्रसरेणु',
    'त्रसरैनि',
    'त्रसित',
    'त्रस्त',
    'त्राण',
    'त्रात',
    'त्राता',
    'त्रातार',
    'त्रायुध',
    'त्रावणकोर',
    'त्रास',
    'त्रासक',
    'त्रासद',
    'त्रासदी',
    'त्रासना',
    'त्रासी',
    'त्राहि',
    'त्रिं',
    'त्रिंश',
    'त्रिंशत',
    'त्रिआयमता',
    'त्रिआयमी',
    'त्रिआयाम',
    'त्रिआयामता',
    'त्रिआयामीय',
    'त्रिक',
    'त्रिककुंभ',
    'त्रिककुट',
    'त्रिककुद',
    'त्रिका',
    'त्रिकाय',
    'त्रिकाल',
    'त्रिकालज्ञ',
    'त्रिकालज्ञता',
    'त्रिकालदर्शी',
    'त्रिकालिक',
    'त्रिकालीन',
    'त्रिकालीय',
    'त्रिकुट',
    'त्रिकुटी',
    'त्रिकूट',
    'त्रिकोण',
    'त्रिकोणः',
    'त्रिकोणःभुजा',
    'त्रिकोणमिति',
    'त्रिकोणिका',
    'त्रिकोणीय',
    'त्रिक्षार',
    'त्रिखंडीय',
    'त्रिगण',
    'त्रिगर्ता',
    'त्रिगुण',
    'त्रिगुणा',
    'त्रिगुणातीत',
    'त्रिगुणात्मक',
    'त्रिगुणात्मकता',
    'त्रिगुणाधीनता',
    'त्रिगुणित',
    'त्रिगुणी',
    'त्रिगूढ़',
    'त्रिगूढ़क',
    'त्रिघात',
    'त्रिघाती',
    'त्रिघ्न',
    'त्रिचक्षु',
    'त्रिजटा',
    'त्रिजीव',
    'त्रिजीवा',
    'त्रिज्य',
    'त्रिज्यखंड',
    'त्रिज्या',
    'त्रिज्यीय',
    'त्रिणता',
    'त्रिणाचिकेत',
    'त्रित',
    'त्रितंत्रिका',
    'त्रितय',
    'त्रिताप',
    'त्रिदंडी',
    'त्रिदंशाकुश',
    'त्रिदश',
    'त्रिदशपुष्प',
    'त्रिदशाधिप',
    'त्रिदशाध्यक्ष',
    'त्रिदशारि',
    'त्रिदशालय',
    'त्रिदशेश',
    'त्रिदिनस्पृश',
    'त्रिदेव',
    'त्रिदोषसूची',
    'त्रिधामा',
    'त्रिधामूर्ति',
    'त्रिधारा',
    'त्रिनयना',
    'त्रिनाभ',
    'त्रिनेत्र',
    'त्रिपताक',
    'त्रिपथ',
    'त्रिपथगा',
    'त्रिपद',
    'त्रिपदा',
    'त्रिपदिका',
    'त्रिपदी',
    'त्रिपर्ण',
    'त्रिपाटीय',
    'त्रिपाद',
    'त्रिपावस्था',
    'त्रिपाश्र्वीय',
    'त्रिपुंड्र',
    'त्रिपुट',
    'त्रिपुटक',
    'त्रिपुटा',
    'त्रिपुटी',
    'त्रिपुर',
    'त्रिपुरंदर',
    'त्रिपुरा',
    'त्रिपुरांबिका',
    'त्रिपुराः',
    'त्रिपुराघ्न',
    'त्रिपुरातंक',
    'त्रिपुरारि',
    'त्रिपुरेश्वर',
    'त्रिबगुण',
    'त्रिबली',
    'त्रिभ',
    'त्रिभंग',
    'त्रिभंगी',
    'त्रिभागीय',
    'त्रिभाषा',
    'त्रिभुज',
    'त्रिभुजः',
    'त्रिभुजाकार',
    'त्रिभुजीय',
    'त्रिभुवन',
    'त्रिभुवनेश्वर',
    'त्रिभूम',
    'त्रिमात्र',
    'त्रिमात्रा',
    'त्रिमासीय',
    'त्रिमुख',
    'त्रिमुखा',
    'त्रिमुखी',
    'त्रिमुर्तिः',
    'त्रिमूर्ति',
    'त्रियंबक',
    'त्रिया',
    'त्रियामा',
    'त्रियुग',
    'त्रियुमवीर',
    'त्रिरत्न',
    'त्रिराशि',
    'त्रिर्शीषक',
    'त्रिलवण',
    'त्रिलवन',
    'त्रिलाकी',
    'त्रिलोक',
    'त्रिलोकश',
    'त्रिलोकात्मा',
    'त्रिलोकीय',
    'त्रिलोकीश्वर',
    'त्रिलोकेश्वर',
    'त्रिलोचन',
    'त्रिलोचना',
    'त्रिवट',
    'त्रिवर्ग',
    'त्रिवर्ण',
    'त्रिवलय',
    'त्रिवली',
    'त्रिवलीक',
    'त्रिविक्रम',
    'त्रिविष्टप',
    'त्रिवेणी',
    'त्रिवेणु',
    'त्रिवेद',
    'त्रिशंकु',
    'त्रिशरण',
    'त्रिशाला',
    'त्रिशासक',
    'त्रिशिक्षा',
    'त्रिशिख',
    'त्रिशिरा',
    'त्रिशीर्ष',
    'त्रिशुच',
    'त्रिशूल',
    'त्रिशूलधारी',
    'त्रिशूली',
    'त्रिशृंग',
    'त्रिशृंगी',
    'त्रिष्ठ',
    'त्रिसंध्य',
    'त्रिसंध्यस्पृश',
    'त्रिसंध्या',
    'त्रिसर',
    'त्रिस्कंध',
    'त्रिस्तनी',
    'त्रिस्तरीय',
    'त्रिस्पर्शा',
    'त्रिस्पृशा',
    'त्रिहायणी',
    'त्रुटि',
    'त्रुटित',
    'त्रुटिपूर्ण',
    'त्रुटिपूर्णता',
    'त्रुटियुक्त',
    'त्रुटिहीन',
    'त्रुटिहीनता',
    'त्रेता',
    'त्रेताकालीन',
    'त्रेताग्नि',
    'त्रेतायुग',
    'त्रेतायुगीन',
    'त्रैकालिक',
    'त्रैतायुगिन',
    'त्रैदशिक',
    'त्रैमातुर',
    'त्रैमासिक',
    'त्रैयंबक',
    'त्रैलोक्य',
    'त्रैविक्रम',
    'त्रैविष्टप',
    'त्रोटक',
    'त्रोटी',
    'त्रोयदशी',
    'त्रौराशिक',
    'त्वं',
    'त्वंगकुरावली',
    'त्वकपत्र',
    'त्वग',
    'त्वगग्स्तर',
    'त्वगूतक',
    'त्वग्भेदन',
    'त्वचा',
    'त्वचाजन',
    'त्वचारोग',
    'त्वचाविज्ञानी',
    'त्वचीय',
    'त्वम्',
    'त्वरक',
    'त्वरण',
    'त्वरणशील',
    'त्वरा',
    'त्वरान',
    'त्वरायक',
    'त्वरित',
    'त्वरित्र',
    'त्वष्टा',
    'त्वष्ट्र',
    'त्वष्ट्रा',
    'त्वाष्ट्री',
    'त्सरु',
    'त्साङ',
    'थंब',
    'थंबी',
    'थंभ',
    'थंभन',
    'थंभा',
    'थक',
    'थकन',
    'थकना',
    'थका',
    'थकाऊ',
    'थकाऊपन',
    'थकान',
    'थकाना',
    'थकाने',
    'थकानेवाला',
    'थकावट',
    'थकिया',
    'थकी',
    'थके',
    'थक्का',
    'थक्कापन',
    'थक्केदार',
    'थड़ा',
    'थत्ती',
    'थन',
    'थनाकार',
    'थनी',
    'थनेली',
    'थनैत',
    'थप',
    'थपक',
    'थपकना',
    'थपकाई',
    'थपकाना',
    'थपकी',
    'थपड़ी',
    'थपथप',
    'थपथपाई',
    'थपथपाना',
    'थपथपाहट',
    'थपनहार',
    'थपना',
    'थपा',
    'थपाई',
    'थपाना',
    'थपुआ',
    'थपेड़ा',
    'थपैया',
    'थप्पड़',
    'थप्पड़ं',
    'थमला',
    'थमा',
    'थमाई',
    'थमाना',
    'थमी',
    'थमोर्स्टैट',
    'थम्र',
    'थरथर',
    'थरथराता',
    'थरथराना',
    'थरथराहट',
    'थरथरी',
    'थरना',
    'थरमस',
    'थरसल',
    'थर्ड',
    'थर्म',
    'थर्मल',
    'थर्मस',
    'थर्माकोल',
    'थर्मामीटर',
    'थर्मोकोल',
    'थर्राता',
    'थर्राना',
    'थर्राहट',
    'थर्सडे',
    'थल',
    'थलग',
    'थलग्रीवा',
    'थलचर',
    'थलचराः',
    'थलथलाना',
    'थलयान',
    'थलयुद्घ',
    'थलसावी',
    'थलसेना',
    'थलसैनिक',
    'थला',
    'थलावतरण',
    'थलिया',
    'थली',
    'थलीय',
    'थवई',
    'थहराना',
    'थहाना',
    'था',
    'थाँग',
    'थाँगी',
    'थाँगीदारी',
    'थाँवला',
    'थाँह',
    'थांगी',
    'थाः',
    'थाक',
    'थाट',
    'थाती',
    'थान',
    'थाना',
    'थानादार',
    'थाने',
    'थानेदार',
    'थानैत',
    'थाप',
    'थापन',
    'थापना',
    'थापने',
    'थापा',
    'थापी',
    'थाम',
    'थामना',
    'थाल',
    'थाला',
    'थालिका',
    'थाली',
    'थावरा',
    'थाह',
    'थाहना',
    'थाहहीन',
    'थिंपु',
    'थिएटर',
    'थिएट्रिकेलिटी',
    'थित',
    'थिति',
    'थिर',
    'थिरक',
    'थिरकन',
    'थिरकना',
    'थिरण',
    'थिरता',
    'थिरना',
    'थिरू',
    'थिसारस',
    'थी',
    'थीं',
    'थीसिस',
    'थुक्का',
    'थुड़ना',
    'थुड़ाई',
    'थुड़ी',
    'थुतकार',
    'थुतकारना',
    'थुथनी',
    'थुथुआना',
    'थुलथुल',
    'थुलथुलाता',
    'थुलमा',
    'थू',
    'थूक',
    'थूकदानी',
    'थूकना',
    'थूका',
    'थूत्कार',
    'थूथन',
    'थूथनी',
    'थूथू',
    'थून',
    'थूनी',
    'थूली',
    'थे',
    'थेई',
    'थेगली',
    'थैला',
    'थैलियां',
    'थैली',
    'थैलीदार',
    'थैले',
    'थोंथ',
    'थोंप',
    'थोक',
    'थोकदार',
    'थोड़',
    'थोड़ा',
    'थोड़ापन',
    'थोड़ी',
    'थोड़े',
    'थोथा',
    'थोपना',
    'थोबड़ा',
    'थ्योरम',
    'थ्योरी',
    'थ्रशैर',
    'थ्रस्ट',
    'थ्रिलर',
    'थ्री',
    'थ्रैड',
    'थ्रैशर',
    'द',
    'दँतीला',
    'दँतुरिया',
    'दँवरी',
    'दंग',
    'दंगई',
    'दंगल',
    'दंगली',
    'दंगा',
    'दंगाई',
    'दंगे',
    'दंगेबाज',
    'दंगैत',
    'दंड',
    'दंडक',
    'दंडकर्मी',
    'दंडचारी',
    'दंडना',
    'दंडनायक',
    'दंडनीय',
    'दंडपाणि',
    'दंडपाल',
    'दंडमुक्त',
    'दंडमोचन',
    'दंडय',
    'दंडवत',
    'दंडशास्त्री',
    'दंडस्वरूप',
    'दंडाज्ञा',
    'दंडादंडि',
    'दंडादेश',
    'दंडाधिकारी',
    'दंडाधिप',
    'दंडाधिपता',
    'दंडानीक',
    'दंडार',
    'दंडाश्रम',
    'दंडिका',
    'दंडित',
    'दंडितों',
    'दंडी',
    'दंडों',
    'दंडौत',
    'दंत',
    'दंतक',
    'दंतकथा',
    'दंतकाष्ठ',
    'दंतकूट',
    'दंतक्षत',
    'दंतच्छद',
    'दंतपाली',
    'दंतमंजन',
    'दंतहीन',
    'दंताघात',
    'दंतायुध',
    'दंताल',
    'दंतालय',
    'दंतावली',
    'दंतिका',
    'दंती',
    'दंतीधान',
    'दंतीय',
    'दंतुर',
    'दंतुरण',
    'दंतुरित',
    'दंतुल',
    'दंतुलिया',
    'दंतुली',
    'दंतैल',
    'दंतोद्भव',
    'दंतोष्ठ',
    'दंतोष्ठ्य',
    'दंत्य',
    'दंदफंद',
    'दंदान',
    'दंदाना',
    'दंदाने',
    'दंदानेदार',
    'दंदारु',
    'दंद्रुम',
    'दंपति',
    'दंपती',
    'दंभ',
    'दंभदर्शी',
    'दंभपूर्णता',
    'दंभहीन',
    'दंभहीनता',
    'दंभिता',
    'दंभी',
    'दंभीली',
    'दंय',
    'दंवरी',
    'दंश',
    'दंशक',
    'दंशन',
    'दंशित',
    'दंशी',
    'दंशेर',
    'दंष्ट्र',
    'दंष्ट्राविष',
    'दंष्ट्रिका',
    'दंष्ट्री',
    'दइया',
    'दई',
    'दईमारा',
    'दकन',
    'दकना',
    'दकनी',
    'दकने',
    'दकार्गल',
    'दकियानूसी',
    'दक्',
    'दक्कन',
    'दक्कनी',
    'दक्का',
    'दक्खनी',
    'दक्खिनी',
    'दक्रियानूस',
    'दक्रियानूसी',
    'दक्ष',
    'दक्षजा',
    'दक्षता',
    'दक्षतापूर्वक',
    'दक्षारि',
    'दक्षिण',
    'दक्षिणस्थ',
    'दक्षिणा',
    'दक्षिणात्य',
    'दक्षिणापथ',
    'दक्षिणाभिमुख',
    'दक्षिणायन',
    'दक्षिणावर्त',
    'दक्षिणावर्तन',
    'दक्षिणाशा',
    'दक्षिणी',
    'दक्षिणीय',
    'दक्षिणेतर',
    'दक्षिणोन्मुख',
    'दक्षिस्थ',
    'दखन',
    'दखल',
    'दखलकार',
    'दखलकारी',
    'दखलदार',
    'दख़नी',
    'दख़ल',
    'दख़लंदाज',
    'दख़लंदाजी',
    'दख़लकार',
    'दख़ाल',
    'दख़ालदार',
    'दख़्लंदाज़',
    'दख़्लंदाज़र',
    'दख़्लंदाज़री',
    'दगड़',
    'दगधना',
    'दगन',
    'दगना',
    'दगने',
    'दगरा',
    'दगला',
    'दगहा',
    'दग़दग़ा',
    'दग़ा',
    'दग़ाबाज़',
    'दग़ाबाज़ी',
    'दग़ैल',
    'दगाई',
    'दगाऊ',
    'दगार्मल',
    'दग्ध',
    'दग्धक',
    'दग्धा',
    'दचका',
    'दच्छिना',
    'दज़र्',
    'दज़ीगीरी',
    'दजिर्न',
    'दजी',
    'दड',
    'दड़बा',
    'दढ़ियल',
    'दण्ड',
    'दण्डनीय',
    'दण्डनीयता',
    'दण्डरूप',
    'दण्डाकार',
    'दण्डाज्ञा',
    'दण्डाणु',
    'दण्डाणुज',
    'दण्डात्मक',
    'दण्डादेश',
    'दण्डादेशित',
    'दण्डाधिकारी',
    'दण्डित',
    'दण्डोन्मुक्ति',
    'दतिया',
    'दतुअन',
    'दतुवन',
    'दतौन',
    'दत्त',
    'दत्तक',
    'दत्तकग्राही',
    'दत्तकता',
    'दत्तचित्त',
    'दत्तचित्तता',
    'दत्तम',
    'दत्ता',
    'दत्तात्रेय',
    'दत्तावधान',
    'दत्तावधानता',
    'ददिया',
    'ददिहाल',
    'ददुर',
    'ददुरक',
    'ददोरा',
    'दद्दा',
    'दध',
    'दधि',
    'दधिचि',
    'दधिच्यस्थि',
    'दधिधानी',
    'दधिमुख',
    'दधिशोण',
    'दधीय',
    'दध्य',
    'दन',
    'दनदन',
    'दनदनाहट',
    'दना',
    'दनादन',
    'दनायु',
    'दनायुषा',
    'दनु',
    'दनुज',
    'दनुजा',
    'दनुजारि',
    'दनुजेंद्र',
    'दनुजेश',
    'दनुसरट',
    'दनेवाला',
    'दन्ड',
    'दन्त',
    'दन्तकार',
    'दन्तशूल',
    'दन्तसंशोधक',
    'दन्तसंशोधन',
    'दन्ति',
    'दन्तोद्भेद',
    'दन्त्य',
    'दन्दाना',
    'दन्नाहट',
    'दपट',
    'दपटना',
    'दपोर्क्ति',
    'दप्रपूर्णता',
    'दफन',
    'दफ़न',
    'दफ़नाई',
    'दफ़नाना',
    'दफ़नाया',
    'दफ़ा',
    'दफा',
    'दफादार',
    'दफ्',
    'दफ्तर',
    'दफ्तरशाह',
    'दफ्तरशाही',
    'दफ्तरी',
    'दबंग',
    'दबंगता',
    'दबक',
    'दबकगर',
    'दबकगरी',
    'दबकन',
    'दबकना',
    'दबकर',
    'दबका',
    'दबकैया',
    'दबते',
    'दबदबा',
    'दबदबे',
    'दबन',
    'दबनशील',
    'दबनशीलता',
    'दबना',
    'दबा',
    'दबाई',
    'दबाकर',
    'दबाना',
    'दबाने',
    'दबापन',
    'दबाब',
    'दबाव',
    'दबाववाले',
    'दबी',
    'दबे',
    'दबैल',
    'दबैलपन',
    'दबोच',
    'दबोचना',
    'दबोचू',
    'दबोलि',
    'दब्बू',
    'दब्बूपन',
    'दभ्यता',
    'दभ्र',
    'दम',
    'दमक',
    'दमकता',
    'दमकना',
    'दमकल',
    'दमकाई',
    'दमकारिता',
    'दमकीला',
    'दमख़म',
    'दमघोंटू',
    'दमझाँसा',
    'दमड़ी',
    'दमथु',
    'दमदमा',
    'दमदार',
    'दमन',
    'दमनक',
    'दमना',
    'दमनीय',
    'दमनीयता',
    'दमपट्टी',
    'दमपती',
    'दमपुख़्त',
    'दमबाज़',
    'दमबाज़ी',
    'दमबुत्ता',
    'दमयिता',
    'दमसाज',
    'दमसाज़ी',
    'दमहीन',
    'दमा',
    'दमाद',
    'दमादम',
    'दमामा',
    'दमारोगी',
    'दमित',
    'दमी',
    'दम्पति',
    'दम्भ',
    'दम्भी',
    'दम्भीपन',
    'दम्य',
    'दयनीय',
    'दयनीयता',
    'दया',
    'दयाकर',
    'दयाकूट',
    'दयादान',
    'दयानंद',
    'दयानतदार',
    'दयानतदारी',
    'दयानिधि',
    'दयापात्र',
    'दयापूर्ण',
    'दयापूर्वक',
    'दयामय',
    'दयायोग्य',
    'दयार',
    'दयार्द्र',
    'दयार्द्रता',
    'दयाल',
    'दयालु',
    'दयालुता',
    'दयावंत',
    'दयावान',
    'दयाविहीनता',
    'दयाशील',
    'दयासागर',
    'दयासिंधु',
    'दयाहीन',
    'दयाहीनता',
    'दयित',
    'दयिता',
    'दयियादिल',
    'दयियादिली',
    'दयोत्पादक',
    'दयोत्पादकता',
    'दर',
    'दरअसल',
    'दरक',
    'दरकदार',
    'दरकन',
    'दरकना',
    'दरका',
    'दरकार',
    'दरकिनार',
    'दरख़्त',
    'दरख़्वास्त',
    'दरगाह',
    'दरगुज़',
    'दरगुज़र',
    'दरज',
    'दरजिन',
    'दरजी',
    'दरण',
    'दरणीय',
    'दरत',
    'दरथ',
    'दरद',
    'दरदरा',
    'दरदराई',
    'दरदराना',
    'दरदर्शिता',
    'दरपन',
    'दरपना',
    'दरपरदा',
    'दरपेच',
    'दरपेश',
    'दरपेशी',
    'दरब',
    'दरबंदी',
    'दरबदर',
    'दरबदार',
    'दरबा',
    'दरबान',
    'दरबार',
    'दरबारी',
    'दरबारे',
    'दरमन',
    'दरमाहा',
    'दरमियाना',
    'दरमियानी',
    'दरम्यान',
    'दरया',
    'दररना',
    'दरवज़ा',
    'दरवाज़ा',
    'दरवाजा',
    'दरवाजे',
    'दरवान',
    'दरवाज़ा',
    'दरवेश',
    'दरशाना',
    'दरस',
    'दरसना',
    'दरसाना',
    'दरसूची',
    'दरांती',
    'दराज',
    'दराज़',
    'दरार',
    'दरारदार',
    'दरारा',
    'दरारी',
    'दरिंदा',
    'दरिंद्रा',
    'दरित',
    'दरिद्र',
    'दरिद्रण',
    'दरिद्रत',
    'दरिद्रता',
    'दरिद्रतापूर्वक',
    'दरिद्रा',
    'दरिद्रीयन',
    'दरिया',
    'दरियाई',
    'दरियागंज',
    'दरियादिल',
    'दरियादिली',
    'दरियाफ्',
    'दरियाबार',
    'दरियायाफ्',
    'दरियाव',
    'दरी',
    'दरीचा',
    'दरीबा',
    'दरूस्त',
    'दरे',
    'दरें',
    'दरेंद्र',
    'दरेग़',
    'दरेर',
    'दरेरना',
    'दरेरा',
    'दरेसी',
    'दरो',
    'दरों',
    'दरोग़',
    'दरोगा',
    'दरौती',
    'दर्ज',
    'दर्जन',
    'दर्जर्न',
    'दर्जा',
    'दर्जाबंद',
    'दर्जाबंदी',
    'दर्जी',
    'दर्जीःथैली',
    'दर्जे',
    'दर्द',
    'दर्दनाक',
    'दर्दनाशक',
    'दर्दभरा',
    'दर्दमंद',
    'दर्दर',
    'दर्दी',
    'दर्दीला',
    'दर्दुर',
    'दर्दू',
    'दर्देदिल',
    'दर्प',
    'दर्पक',
    'दर्पण',
    'दर्पध्न',
    'दर्पहंता',
    'दर्पहर',
    'दर्पहर्ता',
    'दर्पहा',
    'दर्पहीन',
    'दर्पहीनता',
    'दर्पिता',
    'दर्पी',
    'दर्भ',
    'दर्याए',
    'दर्याचा',
    'दर्रा',
    'दर्व',
    'दर्वी',
    'दर्वीधर',
    'दर्शक',
    'दर्शकः',
    'दर्शकगण',
    'दर्शकागार',
    'दर्शकों',
    'दर्शन',
    'दर्शनशास्त्रज्ञ',
    'दर्शनसुलभता',
    'दर्शनाभिलाषी',
    'दर्शनार्थी',
    'दर्शनी',
    'दर्शनीय',
    'दर्शनीयता',
    'दर्शनोत्तर',
    'दर्शर्यिता',
    'दर्शाकर',
    'दर्शाते',
    'दर्शाना',
    'दर्शाने',
    'दर्शायक',
    'दर्शाया',
    'दर्शिका',
    'दर्शित',
    'दर्शिता',
    'दर्शित्र',
    'दर्शी',
    'दल',
    'दलक',
    'दलकना',
    'दलगतता',
    'दलचर',
    'दलचर्या',
    'दलथंभ',
    'दलदल',
    'दलदलः',
    'दलदला',
    'दलदलाहट',
    'दलदली',
    'दलन',
    'दलनहार',
    'दलना',
    'दलनिष्ठा',
    'दलप',
    'दलपत',
    'दलपतित्व',
    'दलपित',
    'दलबंदी',
    'दलबदल',
    'दलबदलू',
    'दलबद्घ',
    'दलबन्दी',
    'दलबल',
    'दलबाज़',
    'दलमलना',
    'दलवास',
    'दलवासी',
    'दलविन्यास',
    'दलवैया',
    'दलहन',
    'दलहीन',
    'दलहीनता',
    'दलाई',
    'दलाढक',
    'दलाभ',
    'दलारा',
    'दलाल',
    'दलाली',
    'दलित',
    'दलिदर',
    'दलिया',
    'दली',
    'दलीप',
    'दलीपता',
    'दलीय',
    'दलीयता',
    'दलील',
    'दलीलबाज़',
    'दलीलबाज़ी',
    'दलेल',
    'दल्भ',
    'दल्ला',
    'दव',
    'दवा',
    'दवाइयाँ',
    'दवाइयां',
    'दवाइयों',
    'दवाई',
    'दवाएं',
    'दवाओं',
    'दवाख़ाना',
    'दवाखाना',
    'दवागि',
    'दवाघर',
    'दवात',
    'दवातदान',
    'दवादारु',
    'दवाबाज़',
    'दवावितरक',
    'दवीं',
    'दवो',
    'दव्य',
    'दश',
    'दशंकधर',
    'दशक',
    'दशकंठ',
    'दशकंठारि',
    'दशकों',
    'दशकोण',
    'दशग्रीव',
    'दशदिशता',
    'दशन',
    'दशनवसनांगराग',
    'दशपारमिताधार',
    'दशबल',
    'दशबाहु',
    'दशभुज',
    'दशभुजा',
    'दशभूमीश',
    'दशम',
    'दशमक',
    'दशमलव',
    'दशमांश',
    'दशमिक',
    'दशमिकीकरण',
    'दशमी',
    'दशमुख',
    'दशमेश',
    'दशरथ',
    'दशरथः',
    'दशवदन',
    'दशवार्षिक',
    'दशशिर',
    'दशशीश',
    'दशहरा',
    'दशा',
    'दशांश',
    'दशाएं',
    'दशाओं',
    'दशानन',
    'दशानुकूलन',
    'दशानुकूलित',
    'दशाब्द',
    'दशाब्दि',
    'दशार्ध',
    'दशार्ह',
    'दशावतार',
    'दश्रन',
    'दष्ट',
    'दस',
    'दसठौैन',
    'दसदिश',
    'दसमी',
    'दसवाँ',
    'दसवां',
    'दसवीं',
    'दसहरी',
    'दसारी',
    'दसी',
    'दसूटन',
    'दसूती',
    'दसूली',
    'दसों',
    'दस्त',
    'दस्तंदाज',
    'दस्तंदाजी',
    'दस्तंदाज़ी',
    'दस्तक',
    'दस्तकार',
    'दस्तकारी',
    'दस्तखत',
    'दस्तख़त',
    'दस्तख़ती',
    'दस्तगीर',
    'दस्तयाब',
    'दस्तयाबी',
    'दस्तरख़्वान',
    'दस्ता',
    'दस्ताकार',
    'दस्तादाज',
    'दस्तादाज़ी',
    'दस्ताना',
    'दस्ताने',
    'दस्तावर',
    'दस्तावेज',
    'दस्तावेज़',
    'दस्तावेज़जी',
    'दस्तावेजी',
    'दस्तावेजों',
    'दस्तावेज़',
    'दस्ती',
    'दस्तूर',
    'दस्तूरी',
    'दस्म',
    'दस्यु',
    'दस्युता',
    'दस्युपोत',
    'दस्युहा',
    'दस्सा',
    'दस्सी',
    'दह',
    'दहंेगर',
    'दहकता',
    'दहकना',
    'दहक़ान',
    'दहक़ानी',
    'दहन',
    'दहनशील',
    'दहना',
    'दहनोपल',
    'दहय',
    'दहर',
    'दहराकाश',
    'दहल',
    'दहलता',
    'दहलना',
    'दहला',
    'दहलाने',
    'दहलानेवाला',
    'दहलीज़',
    'दहलीज़',
    'दहशत',
    'दहाई',
    'दहाड़',
    'दहाड़ना',
    'दहाडे',
    'दहाना',
    'दहाने',
    'दहिने',
    'दही',
    'दहीः',
    'दहेंड़ी',
    'दहेज',
    'दहेड़ी',
    'दहेला',
    'दाँ',
    'दाँत',
    'दाँता',
    'दाँती',
    'दाँते',
    'दाँतेदार',
    'दाँना',
    'दाँयँ',
    'दाँव',
    'दाँवँ',
    'दाँवःवस्तु',
    'दाँवना',
    'दाँवपेंच',
    'दाँवबाज',
    'दाँवबाज़',
    'दाँवरी',
    'दांए',
    'दांड़िक',
    'दांत',
    'दांता',
    'दांति',
    'दांतेदार',
    'दांतों',
    'दांना',
    'दांपत्य',
    'दांव',
    'दांवना',
    'दाई',
    'दाईं',
    'दाईगिरी',
    'दाऊ',
    'दाऊद',
    'दाऊदी',
    'दाएँ',
    'दाक्ष',
    'दाक्षायणी',
    'दाक्षिण',
    'दाक्षिण्य',
    'दाक्षी',
    'दाख',
    'दाखयुक्त',
    'दाख़िल',
    'दाख़िला',
    'दाखिल',
    'दाखिला',
    'दाग',
    'दागना',
    'दाग़',
    'दाग़धब्बे',
    'दाग़ी',
    'दागा',
    'दागोब',
    'दाघ',
    'दाज',
    'दाटा',
    'दाड़िम',
    'दाढ़',
    'दाढ़ी',
    'दाढ़ीदार',
    'दाढ़ीयुक्त',
    'दाढ़ीवाला',
    'दाण्डिक',
    'दात',
    'दातदानी',
    'दातम',
    'दातवन',
    'दातव्य',
    'दातव्यता',
    'दाता',
    'दातार',
    'दाति',
    'दातुन',
    'दातौन',
    'दात्री',
    'दाद',
    'दादनी',
    'दादरा',
    'दादरी',
    'दादसरा',
    'दादसराः',
    'दादा',
    'दादालाई',
    'दादी',
    'दादुर',
    'दादू',
    'दादे',
    'दाधिक',
    'दान',
    'दानः',
    'दानग्राही',
    'दानदीयता',
    'दानना',
    'दाननिधि',
    'दानपति',
    'दानपत्र',
    'दानपात्र',
    'दानव',
    'दानवः',
    'दानवता',
    'दानवमर्दन',
    'दानवसूची',
    'दानवारि',
    'दानवी',
    'दानवीय',
    'दानवीयता',
    'दानवीयतापूर्ण',
    'दानवीर',
    'दानवेंद्र',
    'दानवोचित',
    'दानवोचितता',
    'दानशाला',
    'दानशील',
    'दानशीलता',
    'दानशूल',
    'दानहीनता',
    'दाना',
    'दानाधिकारी',
    'दानापानी',
    'दानार्थ',
    'दानाश्रिता',
    'दानिश',
    'दानिशमंद',
    'दानिशमंदी',
    'दानी',
    'दानीत्व',
    'दानीय',
    'दानु',
    'दाने',
    'दानें',
    'दानेदार',
    'दाप',
    'दाब',
    'दाबदर्शी',
    'दाबदार',
    'दाबनहार',
    'दाबना',
    'दाबनी',
    'दाबने',
    'दाबमापी',
    'दाबा',
    'दाभ',
    'दाम',
    'दामन',
    'दामनी',
    'दामा',
    'दामाद',
    'दामासाह',
    'दामिनी',
    'दामी',
    'दामोदर',
    'दाम्पत्य',
    'दाय',
    'दायक',
    'दायकता',
    'दायजा',
    'दायता',
    'दायदा',
    'दायभागी',
    'दाययोग्य',
    'दायर',
    'दायरा',
    'दायरे',
    'दाया',
    'दायां',
    'दायागत',
    'दायागतता',
    'दायाद',
    'दायादहीन',
    'दायादहीनता',
    'दायादी',
    'दायाधिकार',
    'दायाधिकारी',
    'दायित्व',
    'दायित्वधीन',
    'दायित्वपूर्ण',
    'दायित्वपूर्वक',
    'दायित्वयुक्त',
    'दायित्वरहित',
    'दायित्ववाला',
    'दायित्वहीन',
    'दायित्वहीनता',
    'दायित्वों',
    'दायिनी',
    'दायी',
    'दायें',
    'दार',
    'दारक',
    'दारचीनी',
    'दारण',
    'दारमदार',
    'दारा',
    'दारारवलंबी',
    'दारिका',
    'दारिद्रय',
    'दारी',
    'दारु',
    'दारुका',
    'दारुगंधा',
    'दारुचीनी',
    'दारुण',
    'दारुणा',
    'दारुफल',
    'दारुबंदी',
    'दारुसिता',
    'दारुहस्त',
    'दारू',
    'दारो',
    'दारोग़ा',
    'दारोगा',
    'दारौं',
    'दार्जिलिंग',
    'दार्दुर',
    'दार्शनिक',
    'दार्शनिकता',
    'दाल',
    'दालचीनी',
    'दालान',
    'दाव',
    'दावत',
    'दावतनामा',
    'दावते',
    'दावरी',
    'दावा',
    'दावाकृत',
    'दावात',
    'दावानल',
    'दावी',
    'दावॅं',
    'दावे',
    'दावेदार',
    'दावों',
    'दाशमिक',
    'दाशरथि',
    'दाशेयी',
    'दाश्त',
    'दास',
    'दासः',
    'दासकरण',
    'दासता',
    'दासवर्ग',
    'दासानुदास',
    'दासी',
    'दासीः',
    'दासेयी',
    'दासेरक',
    'दासोचित',
    'दासोन्माद',
    'दास्तान',
    'दास्य',
    'दास्यमुक्त',
    'दास्यमुक्ति',
    'दाह',
    'दाहक',
    'दाहकर्ता',
    'दाहन',
    'दाहना',
    'दाहभूमि',
    'दाहह',
    'दाहहीन',
    'दाहागार',
    'दाहित्र',
    'दाहिना',
    'दाहिनी',
    'दाहिने',
    'दाही',
    'दाहृय',
    'दिअना',
    'दिअरा',
    'दिअला',
    'दिउली',
    'दिए',
    'दिकाना',
    'दिक्',
    'दिक्कत',
    'दिक्कन्या',
    'दिक्कर',
    'दिक्करिणी',
    'दिक्करी',
    'दिक्कांता',
    'दिक्कामिनी',
    'दिक्काल',
    'दिक्कालयुक्त',
    'दिक्कालहीन',
    'दिक्कालीन',
    'दिक्कालीनता',
    'दिक्कुंजरिणी',
    'दिक्चक्र',
    'दिक्पति',
    'दिक्परिवर्तन',
    'दिक्पात',
    'दिक्पाल',
    'दिक्र',
    'दिक्विन्यास',
    'दिक्सुंदरी',
    'दिक्सूचक',
    'दिखता',
    'दिखन',
    'दिखना',
    'दिखलाई',
    'दिखलाना',
    'दिखलाने',
    'दिखलावा',
    'दिखवाटी',
    'दिखा',
    'दिखाई',
    'दिखाऊ',
    'दिखाऊपन',
    'दिखाकर',
    'दिखाते',
    'दिखाना',
    'दिखाने',
    'दिखाया',
    'दिखावट',
    'दिखावटी',
    'दिखावटीपन',
    'दिखावा',
    'दिगंगना',
    'दिगंत',
    'दिगंतर',
    'दिगंतीय',
    'दिगंबर',
    'दिगंबरता',
    'दिगंबरवाद',
    'दिगंबरवादी',
    'दिगंबरा',
    'दिगंबरी',
    'दिगंश',
    'दिगंशीय',
    'दिगन्त',
    'दिगार',
    'दिगीश',
    'दिगीश्रवर',
    'दिग्गज',
    'दिग्गजी',
    'दिग्गयंद',
    'दिग्घटी',
    'दिग्ज्या',
    'दिग्दर्शक',
    'दिग्दर्शन',
    'दिग्दर्शित्र',
    'दिग्दर्शी',
    'दिग्दिगंत',
    'दिग्दिगंतता',
    'दिग्देवता',
    'दिग्धारण',
    'दिग्बल',
    'दिग्भरम',
    'दिग्भरमहीन',
    'दिग्भरामक',
    'दिग्भेद',
    'दिग्वसन',
    'दिग्वासा',
    'दिग्विजयी',
    'दिग्व्य़ाप्तता',
    'दिग्व्याप्त',
    'दिग्हस्तिनी',
    'दिग्हस्ती',
    'दिठौना',
    'दिति',
    'दितिः',
    'दितिःपुत्र',
    'दितिज',
    'दितिसुत',
    'दित्य',
    'दिधार',
    'दिधिषू',
    'दिधीषू',
    'दिन',
    'दिनः',
    'दिनकर',
    'दिनकांत',
    'दिनगत',
    'दिनचर',
    'दिनचर्या',
    'दिनपति',
    'दिनभृतिता',
    'दिनमल',
    'दिनमान',
    'दिनमाली',
    'दिनमुख',
    'दिनमृत',
    'दिनमृति',
    'दिनमृतिता',
    'दिनरात',
    'दिनांक',
    'दिनांकन',
    'दिनांकरहित',
    'दिनांकित',
    'दिनागत',
    'दिनागम',
    'दिनात्यय',
    'दिनारंभ',
    'दिनार्ध',
    'दिनास्त',
    'दिनेश',
    'दिनेशात्मज',
    'दिनेशात्मजा',
    'दिनों',
    'दिपदिप',
    'दिपदिपाना',
    'दिपाना',
    'दिमाग',
    'दिमागदार',
    'दिमागवट',
    'दिमाग़',
    'दिमागी',
    'दिमाग़',
    'दियना',
    'दियरा',
    'दिया',
    'दियाबाती',
    'दियारा',
    'दियासलाई',
    'दियासा',
    'दिये',
    'दिल',
    'दिलआरा',
    'दिलकश',
    'दिलचला',
    'दिलचस्प',
    'दिलचस्पी',
    'दिलजमई',
    'दिलजला',
    'दिलजोई',
    'दिलदार',
    'दिलदारी',
    'दिलनर्शी',
    'दिलपसंद',
    'दिलफरेब',
    'दिलफेंक',
    'दिलबर',
    'दिलबरी',
    'दिलबस्त',
    'दिलबहलाव',
    'दिलरुबा',
    'दिलरुबाई',
    'दिललगी',
    'दिलवर',
    'दिलवाना',
    'दिला',
    'दिलाऊ',
    'दिलाकर',
    'दिलाजार',
    'दिलाते',
    'दिलाना',
    'दिलाने',
    'दिलारा',
    'दिलाराम',
    'दिलावर',
    'दिलावरी',
    'दिलासा',
    'दिलिप',
    'दिली',
    'दिलीप',
    'दिलीपता',
    'दिलेर',
    'दिलेरी',
    'दिल्भ',
    'दिल्लगी',
    'दिल्लगीबाज़',
    'दिल्लगीबाज़ी',
    'दिल्ला',
    'दिल्ली',
    'दिल्लीःराजधानी',
    'दिल्हा',
    'दिल्हेदार',
    'दिवंगत',
    'दिवंसात',
    'दिवस',
    'दिवसांत',
    'दिवसावसान',
    'दिवसीय',
    'दिवस्पति',
    'दिवा',
    'दिवांध',
    'दिवांधिका',
    'दिवाकर',
    'दिवाचर',
    'दिवान्ध',
    'दिवाभिसारिका',
    'दिवारात्र',
    'दिवाल',
    'दिवाला',
    'दिवालिया',
    'दिवालियापन',
    'दिवालिये',
    'दिवाली',
    'दिवास्वप्न',
    'दिवौका',
    'दिव्य',
    'दिव्यचक्षु',
    'दिव्यतत्व',
    'दिव्यता',
    'दिव्यदृष्टि',
    'दिव्यपुरुष',
    'दिव्यपुष्प',
    'दिव्यलोक',
    'दिव्या',
    'दिव्यांगना',
    'दिव्यायुध',
    'दिव्यासन',
    'दिव्यास्त्र',
    'दिव्योदक',
    'दिव्योपचार',
    'दिश',
    'दिशा',
    'दिशांत',
    'दिशांतर',
    'दिशांतरीय',
    'दिशाः',
    'दिशाई',
    'दिशाओं',
    'दिशाकाल',
    'दिशागत',
    'दिशाज्ञान',
    'दिशात्मक',
    'दिशानिर्देश',
    'दिशानुक्तता',
    'दिशानुगमन',
    'दिशानुगामी',
    'दिशानुशीलन',
    'दिशानुसरण',
    'दिशानुसर्ता',
    'दिशानुसार',
    'दिशान्तर',
    'दिशापति',
    'दिशाभिमुख',
    'दिशाभ्रम',
    'दिशामिमुखता',
    'दिशायोग',
    'दिशावकाश',
    'दिशावर',
    'दिशाव्रत',
    'दिशाशूल',
    'दिशाहीन',
    'दिशाहीनता',
    'दिशेभ',
    'दिशोन्मुख',
    'दिश्य',
    'दिष्ट',
    'दिष्टता',
    'दिसंबर',
    'दिसपुर',
    'दिसम्बर',
    'दिसावर',
    'दिसावरी',
    'दिहाड़ी',
    'दिहाडी',
    'दिहाडीदार',
    'दिहानी',
    'दी',
    'दीक्षक',
    'दीक्षण',
    'दीक्षा',
    'दीक्षांत',
    'दीक्षागुरु',
    'दीक्षात्मक',
    'दीक्षान्त',
    'दीक्षापति',
    'दीक्षित',
    'दीखना',
    'दीगर',
    'दीघति',
    'दीघायुष्य',
    'दीघिति',
    'दीघी',
    'दीजिए',
    'दीटी',
    'दीठ',
    'दीठबंदी',
    'दीठवंत',
    'दीठि',
    'दीढ़',
    'दीद',
    'दीदा',
    'दीदार',
    'दीदी',
    'दीदे',
    'दीन',
    'दीनतः',
    'दीनता',
    'दीनतापूर्वक',
    'दीनदयाल',
    'दीनदयालु',
    'दीनदार',
    'दीनबंधु',
    'दीनलोचन',
    'दीनवत्सल',
    'दीनानाथ',
    'दीनी',
    'दीप',
    'दीपंकर',
    'दीपक',
    'दीपन',
    'दीपनीह',
    'दीपराधन',
    'दीपा',
    'दीपाधार',
    'दीपाली',
    'दीपावली',
    'दीपोत्सव',
    'दीप्त',
    'दीप्तवर्ण',
    'दीप्तांग',
    'दीप्ति',
    'दीप्तिकर',
    'दीप्तिकाल',
    'दीप्तिमय',
    'दीप्तिमान',
    'दीप्तिवान',
    'दीप्तिशील',
    'दीप्यमान',
    'दीमक',
    'दीयों',
    'दीर्घ',
    'दीर्घकाय',
    'दीर्घकाल',
    'दीर्घकालिक',
    'दीर्घकालीन',
    'दीर्घग्रीव',
    'दीर्घचिह्न',
    'दीर्घजंघ',
    'दीर्घजीवी',
    'दीर्घतपा',
    'दीर्घतरु',
    'दीर्घता',
    'दीर्घदृष्टिह',
    'दीर्घनाद',
    'दीर्घपत्री',
    'दीर्घरागा',
    'दीर्घरोमा',
    'दीर्घवार्ताकी',
    'दीर्घवृत्त',
    'दीर्घवृत्ताकार',
    'दीर्घवृत्तीय',
    'दीर्घवृत्तीयता',
    'दीर्घशाखी',
    'दीर्घस्थायी',
    'दीर्घा',
    'दीर्घाकार',
    'दीर्घाक्षर',
    'दीर्घानुभवी',
    'दीर्घायत',
    'दीर्घायु',
    'दीर्घायुंष्कामी',
    'दीर्घायुता',
    'दीर्घायुध',
    'दीर्घायुष्काम',
    'दीर्घायुष्य',
    'दीर्घावधि',
    'दीर्घावधिक',
    'दीर्घीकरण',
    'दीर्ण',
    'दीर्धकालिक',
    'दीर्धकालीन',
    'दीर्धता',
    'दीव',
    'दीवट',
    'दीवाकीर्ति',
    'दीवान',
    'दीवानगी',
    'दीवाना',
    'दीवानापन',
    'दीवानी',
    'दीवार',
    'दीवारःऊचाई',
    'दीवारों',
    'दीवाल',
    'दीवाला',
    'दीवाली',
    'दुंदुभ',
    'दुंदुभि',
    'दुंदुभी',
    'दुंब',
    'दुंबा',
    'दुंबाल',
    'दुःख',
    'दुःखकर',
    'दुःखकातर',
    'दुःखगाथा',
    'दुःखग्रस्त',
    'दुःखग्रस्तता',
    'दुःखड़ा',
    'दुःखत्राण',
    'दुःखत्राता',
    'दुःखद',
    'दुःखन',
    'दुःखपूर्ण',
    'दुःखप्रद',
    'दुःखहर्ता',
    'दुःखहीन',
    'दुःखहीनता',
    'दुःखांत',
    'दुःखायतन',
    'दुःखार्त',
    'दुःखित',
    'दुःखी',
    'दुःसहयता',
    'दुःसाहस',
    'दुःस्वप्न',
    'दुअ',
    'दुअन',
    'दुअन्नी',
    'दुआ',
    'दुआदसी',
    'दुआब',
    'दुआल',
    'दुआस्सी',
    'दुई',
    'दुओ',
    'दुकान',
    'दुकानदार',
    'दुकानदारी',
    'दुकानदारों',
    'दुकानें',
    'दुकानों',
    'दुकूल',
    'दुकूलिनी',
    'दुकेला',
    'दुक्का',
    'दुक्की',
    'दुक्के',
    'दुक्खन',
    'दुख',
    'दुखगाथा',
    'दुखड़ा',
    'दुखता',
    'दुखती',
    'दुखद',
    'दुखदता',
    'दुखदरूप',
    'दुखदायी',
    'दुखदायीपन',
    'दुखन',
    'दुखना',
    'दुखपूर्णता',
    'दुखभोग',
    'दुखहीन',
    'दुखांत',
    'दुखाधीन',
    'दुखाधीनता',
    'दुखाना',
    'दुखारा',
    'दुखिया',
    'दुखियारा',
    'दुखियारी',
    'दुखी',
    'दुखीला',
    'दुख्तर',
    'दुग',
    'दुगई',
    'दुगड्डा',
    'दुगड्डी',
    'दुगना',
    'दुगाड़ा',
    'दुगाना',
    'दुगामा',
    'दुगासरा',
    'दुगुना',
    'दुगुनी',
    'दुगोरत्सव',
    'दुग्ध',
    'दुग्धदा',
    'दुग्धन',
    'दुग्धपूर्णता',
    'दुग्धमापी',
    'दुग्धमुखी',
    'दुग्धवती',
    'दुग्धशर्करा',
    'दुग्धशाला',
    'दुग्धहीन',
    'दुग्धाग्र',
    'दुग्धाशय',
    'दुग्धाहारी',
    'दुग्धिलता',
    'दुग्धीय',
    'दुग्रर्ह',
    'दुग्रार्ह्य',
    'दुघड़िया',
    'दुघरी',
    'दुघैल',
    'दुघोर्ष',
    'दुचंद',
    'दुचितई',
    'दुचिता',
    'दुछत्ती',
    'दुजाति',
    'दुत',
    'दुतई',
    'दुतकार',
    'दुतकारना',
    'दुतकारा',
    'दुतकृत',
    'दुतरफ़ा',
    'दुतरफा',
    'दुतही',
    'दुत्कार',
    'दुत्कारना',
    'दुत्कृत',
    'दुदम',
    'दुद्धी',
    'दुध',
    'दुधमुंहा',
    'दुधमुंहापन',
    'दुधहॅंडी',
    'दुधार',
    'दुधारता',
    'दुधारा',
    'दुधारु',
    'दुधारुता',
    'दुधारू',
    'दुधिया',
    'दुधॉंडी',
    'दुनाली',
    'दुनिया',
    'दुनियां',
    'दुनियादार',
    'दुनियादारी',
    'दुनियावी',
    'दुनियासाज्र',
    'दुनियासाज्री',
    'दुनिर्ग्रह',
    'दुनिर्न',
    'दुनिर्मित्त',
    'दुनिर्वार्य',
    'दुनी',
    'दुपट्टा',
    'दुपर्ती',
    'दुपलिया',
    'दुपल्ला',
    'दुपहर',
    'दुपहरिया',
    'दुपहरी',
    'दुपहला',
    'दुबकना',
    'दुबका',
    'दुबधा',
    'दुबला',
    'दुबलाना',
    'दुबलापतलापन',
    'दुबली',
    'दुबारा',
    'दुबारी',
    'दुबालाह',
    'दुबिधामुक्त',
    'दुबिधाहीनता',
    'दुबे',
    'दुबोध्य',
    'दुबोध्यता',
    'दुबोर्ध्य',
    'दुब्बल',
    'दुभाषिया',
    'दुभाषी',
    'दुभोद्य',
    'दुम',
    'दुमछल्ला',
    'दुमछल्लापन',
    'दुमदार',
    'दुमहला',
    'दुमाता',
    'दुम्बाला',
    'दुम्मट',
    'दुम्मटी',
    'दुम्मा',
    'दुयोर्ग',
    'दुयोर्ध',
    'दुयोर्धन',
    'दुयोर्ध्य',
    'दुयोर्ध्यता',
    'दुयोर्नि',
    'दुर',
    'दुरंगा',
    'दुरंगी',
    'दुरंत',
    'दुरंतता',
    'दुरंधर',
    'दुरक्ष',
    'दुरतिक्रम',
    'दुरदुर',
    'दुरदुरराया',
    'दुरदुराना',
    'दुरधिगम',
    'दुरना',
    'दुरन्वय',
    'दुरभिसंधि',
    'दुरमुट',
    'दुरमुस',
    'दुरवस्था',
    'दुरवार',
    'दुराक्रंद',
    'दुराक्रम',
    'दुराक्रमता',
    'दुराक्रम्यता',
    'दुरागम',
    'दुरागौन',
    'दुराग्रह',
    'दुराग्रहता',
    'दुराग्रही',
    'दुराघर्षता',
    'दुराचरण',
    'दुराचार',
    'दुराचारिणी',
    'दुराचारिता',
    'दुराचारी',
    'दुराजकता',
    'दुरात्मा',
    'दुरादुरी',
    'दुराना',
    'दुराभसंधि',
    'दुराराध्य',
    'दुरारुह',
    'दुरारोह',
    'दुरालंभ',
    'दुरालाप',
    'दुराव',
    'दुरावास',
    'दुराशय',
    'दुराशयता',
    'दुराशा',
    'दुरासा',
    'दुरित',
    'दुरिता',
    'दुरिष्ट',
    'दुरुक्ति',
    'दुरुखा',
    'दुरुत्सहित',
    'दुरुत्साहक',
    'दुरुत्साहन',
    'दुरुपदेश',
    'दुरुपयोग',
    'दुरुस्त',
    'दुरुस्ती',
    'दुरुह',
    'दुरूपयोग',
    'दुरूह',
    'दुरूहता',
    'दुरेफ',
    'दुरोदर',
    'दुर्ग',
    'दुर्गंध',
    'दुर्गंधपूर्ण',
    'दुर्गत',
    'दुर्गति',
    'दुर्गध',
    'दुर्गन्ध',
    'दुर्गपाल',
    'दुर्गम',
    'दुर्गमता',
    'दुर्गम्य',
    'दुर्गम्यता',
    'दुर्गा',
    'दुर्गांत',
    'दुर्गाः',
    'दुर्गाध्यक्ष',
    'दुर्गावती',
    'दुर्गीकरण',
    'दुर्गीय',
    'दुर्गुण',
    'दुर्गुणकारक',
    'दुर्गुणहीन',
    'दुर्गुणहीनता',
    'दुर्गुणी',
    'दुर्गेश',
    'दुर्घट',
    'दुर्घटना',
    'दुर्घटनाग्रस्त',
    'दुर्घटनाजन्य',
    'दुर्जन',
    'दुर्जनता',
    'दुर्जय',
    'दुर्जर',
    'दुर्जात',
    'दुर्जेय',
    'दुर्जेयता',
    'दुर्ज्ञेय',
    'दुर्ज्ञेयता',
    'दुर्दम',
    'दुर्दमनीय',
    'दुर्दमनीयता',
    'दुर्दम्य',
    'दुर्दम्यता',
    'दुर्दर्श',
    'दुर्दशा',
    'दुर्दशाग्रस्त',
    'दुर्दात',
    'दुर्दातता',
    'दुर्दाम',
    'दुर्दामता',
    'दुर्दिन',
    'दुर्दिवस',
    'दुर्दृश्यता',
    'दुर्देव',
    'दुर्द्रुम',
    'दुर्धर',
    'दुर्धर्ष',
    'दुर्धर्षता',
    'दुर्नाम',
    'दुर्नीति',
    'दुर्बल',
    'दुर्बलता',
    'दुर्बलमनस्क',
    'दुर्बलीकरण',
    'दुर्बोध',
    'दुर्बौध',
    'दुर्भगा',
    'दुर्भति',
    'दुर्भर्ग',
    'दुर्भागी',
    'दुर्भाग्य',
    'दुर्भाग्यग्रस्त',
    'दुर्भाग्यवश',
    'दुर्भाग्यशाली',
    'दुर्भाव',
    'दुर्भावना',
    'दुर्भावपूर्ण',
    'दुर्भावी',
    'दुर्भिक्ष',
    'दुर्भिक्षग्रस्त',
    'दुर्भीति',
    'दुर्मनस्कता',
    'दुर्मना',
    'दुर्मर्ष',
    'दुर्मर्षण',
    'दुर्मार्ग',
    'दुर्मुख',
    'दुर्मुट',
    'दुर्मुस',
    'दुर्लध्य',
    'दुर्लभ',
    'दुर्लभतः',
    'दुर्लभता',
    'दुर्ललित',
    'दुर्ललितता',
    'दुर्लालन',
    'दुर्वचन',
    'दुर्वहनीय',
    'दुर्वाचक',
    'दुर्वाद',
    'दुर्वादी',
    'दुर्वाना',
    'दुर्वार्य',
    'दुर्वासा',
    'दुर्विजेय',
    'दुर्विनियुक्त',
    'दुर्विनियोग',
    'दुर्विनियोजन',
    'दुर्व्यवस्था',
    'दुर्व्यवहार',
    'दुर्व्यवहारी',
    'दुर्व्यवहार्य',
    'दुर्व्यसन',
    'दुर्व्यसनी',
    'दुलकना',
    'दुलकी',
    'दुलड़ी',
    'दुलदुल',
    'दुलन',
    'दुलराई',
    'दुलराना',
    'दुलराव',
    'दुलरुआपन',
    'दुलहँडी',
    'दुलहन',
    'दुलहा',
    'दुलहिन',
    'दुलही',
    'दुलहेटा',
    'दुलाई',
    'दुलाना',
    'दुलार',
    'दुलारना',
    'दुलारा',
    'दुलारी',
    'दुलिर्खित',
    'दुलीचा',
    'दुलेख्य',
    'दुल्हन',
    'दुल्हा',
    'दुल्हिन',
    'दुव',
    'दुवार',
    'दुवारी',
    'दुवाल',
    'दुविधा',
    'दुविधाग्रस्त',
    'दुविधाग्रस्तता',
    'दुविधाजनक',
    'दुविधात्मक',
    'दुविधापन',
    'दुविधापूर्ण',
    'दुविधामुक्त',
    'दुविधाहीन',
    'दुविधाहीनता',
    'दुविर्कसित',
    'दुविर्कास',
    'दुविर्नय',
    'दुविर्नीत',
    'दुविर्षह',
    'दुवृर्ष्टि',
    'दुव्र्यवस्था',
    'दुव्र्यवहार',
    'दुव्र्यवहारी',
    'दुव्र्यसनी',
    'दुशाला',
    'दुश्चर',
    'दुश्चरित',
    'दुश्चरित्र',
    'दुश्चरित्रता',
    'दुश्चरित्रा',
    'दुश्चर्मा',
    'दुश्चिंतक',
    'दुश्चिंतन',
    'दुश्चिंता',
    'दुश्मन',
    'दुश्मनाई',
    'दुश्मनाना',
    'दुश्मनी',
    'दुश्मनों',
    'दुश्शील',
    'दुष्ककर्मी',
    'दुष्कमिर्णी',
    'दुष्कर',
    'दुष्करण',
    'दुष्करता',
    'दुष्कर्ता',
    'दुष्कर्म',
    'दुष्कर्मा',
    'दुष्कर्मी',
    'दुष्काम',
    'दुष्कामना',
    'दुष्कार्य',
    'दुष्काल',
    'दुष्कीर्ति',
    'दुष्कुल',
    'दुष्कुलीन',
    'दुष्कृता',
    'दुष्कृति',
    'दुष्कृत्य',
    'दुष्चरित्र',
    'दुष्चरित्रता',
    'दुष्ट',
    'दुष्टचेता',
    'दुष्टता',
    'दुष्टतापूर्ण',
    'दुष्टस्वभाव',
    'दुष्टहृदय',
    'दुष्टा',
    'दुष्टाचारी',
    'दुष्टात्मा',
    'दुष्टान्न',
    'दुष्तोषणीय',
    'दुष्तोषणीयता',
    'दुष्पच',
    'दुष्पराप्य',
    'दुष्परेरण',
    'दुष्पाच्य',
    'दुष्पार्य',
    'दुष्पार्यता',
    'दुष्प्रयुक्त',
    'दुष्प्रयोग',
    'दुष्प्राप्य',
    'दुष्प्रेरक',
    'दुष्प्रेरण',
    'दुष्प्रेरित',
    'दुसर',
    'दुसरिहा',
    'दुसार',
    'दुसाला',
    'दुस्तर',
    'दुस्तरता',
    'दुस्तोषणीय',
    'दुस्वप्न',
    'दुस्संधि',
    'दुस्संयोजन',
    'दुस्सह',
    'दुस्सह्य',
    'दुस्सह्यता',
    'दुस्साध्य',
    'दुस्साध्यतः',
    'दुस्साध्यता',
    'दुस्साहस',
    'दुस्साहसी',
    'दुस्स्वभावी',
    'दुस्स्ह',
    'दुह',
    'दुहत्था',
    'दुहत्थापन',
    'दुहत्थी',
    'दुहना',
    'दुहरा',
    'दुहराते',
    'दुहराना',
    'दुहरी',
    'दुहरे',
    'दुहा',
    'दुहाई',
    'दुहाग',
    'दुहागा',
    'दुहागिन',
    'दुहागिल',
    'दुहिता',
    'दुहेजवाँ',
    'दुहेजिया',
    'दुहेजू',
    'दुहेरा',
    'दुहेल',
    'दुहेला',
    'दुहोतरा',
    'दुहोतरी',
    'दूँगा',
    'दूकानदार',
    'दूज',
    'दूजा',
    'दूजैं',
    'दूत',
    'दूतक',
    'दूतकार्य',
    'दूतावास',
    'दूतिका',
    'दूती',
    'दूतीय',
    'दूध',
    'दूधः',
    'दूधदानी',
    'दूधपूत',
    'दूधवाला',
    'दूधहीन',
    'दूधिया',
    'दूधियापन',
    'दूधी',
    'दून',
    'दूना',
    'दूनी',
    'दूब',
    'दूबदू',
    'दूभर',
    'दूभरता',
    'दूर',
    'दूरंगम',
    'दूरंदेश',
    'दूरंदेशी',
    'दूरकृत',
    'दूरगत',
    'दूरगामी',
    'दूरतम',
    'दूरता',
    'दूरदर्शक',
    'दूरदर्शन',
    'दूरदर्शनी',
    'दूरदर्शिता',
    'दूरदर्शित्र',
    'दूरदर्शी',
    'दूरदूर',
    'दूरदृष्टि',
    'दूरबीन',
    'दूरबीनी',
    'दूरभाष',
    'दूरभूत',
    'दूरमुद्रक',
    'दूरयान',
    'दूरयायी',
    'दूरवर्ती',
    'दूरवाणी',
    'दूरवासिता',
    'दूरवासी',
    'दूरवीक्षणयंत्र',
    'दूरसंचार',
    'दूरसमुद्र',
    'दूरस्थ',
    'दूरांतिक',
    'दूरारुढ',
    'दूराह्वान',
    'दूरी',
    'दूरीकरण',
    'दूरीमापी',
    'दूरीमिति',
    'दूर्भाग्यशाली',
    'दूर्वा',
    'दूलह',
    'दूषक',
    'दूषण',
    'दूषणारि',
    'दूषित',
    'दूषितता',
    'दूष्य',
    'दूसर',
    'दूसरा',
    'दूसरी',
    'दूसरीपन',
    'दूसरे',
    'दूसरें',
    'दूसरों',
    'दूहा',
    'दृगंचल',
    'दृगिंद्रि',
    'दृगेंद्रिय',
    'दृढता',
    'दृढ़',
    'दृढ़कथन',
    'दृढ़काय',
    'दृढ़कायता',
    'दृढ़कारी',
    'दृढ़चित',
    'दृढ़चेता',
    'दृढ़ता',
    'दृढ़तापूर्वक',
    'दृढ़त्वचीय',
    'दृढ़धर्मिता',
    'दृढ़न',
    'दृढ़निश्चयी',
    'दृढ़नीर',
    'दृढ़प्रतिज्ञ',
    'दृढ़मतता',
    'दृढ़मतपूर्ण',
    'दृढ़मतहीन',
    'दृढ़लोमी',
    'दृढ़विश्वासी',
    'दृढ़व्रत',
    'दृढ़व्रतता',
    'दृढ़संकल्प',
    'दृढ़स्थ',
    'दृढ़ांग',
    'दृढ़ाना',
    'दृढ़ानुगामी',
    'दृढ़ानुपालन',
    'दृढ़ारुढ',
    'दृढ़ावलंन',
    'दृढ़ावलंबी',
    'दृढ़ी',
    'दृढ़ीकरण',
    'दृढ़ीकारक',
    'दृढ़ीभूत',
    'दृढ़ोतक',
    'दृप्त',
    'दृर्लभता',
    'दृश्य',
    'दृश्यः',
    'दृश्यकक्ष',
    'दृश्यकला',
    'दृश्यतः',
    'दृश्यता',
    'दृश्यमान',
    'दृश्यलेखक',
    'दृश्यहोना',
    'दृश्यांश',
    'दृश्याग्र',
    'दृश्यादृश्या',
    'दृश्यावली',
    'दृष',
    'दृषं',
    'दृषंत',
    'दृषंतहीन',
    'दृष्ट',
    'दृष्टा',
    'दृष्टांत',
    'दृष्टान्त',
    'दृष्टान्तात्मक',
    'दृष्टि',
    'दृष्टिक',
    'दृष्टिकोण',
    'दृष्टिक्षीणता',
    'दृष्टिगत',
    'दृष्टिगोचर',
    'दृष्टिगोचरता',
    'दृष्टिदोष',
    'दृष्टिपात',
    'दृष्टिबंध',
    'दृष्टिबंधक',
    'दृष्टिमंदता',
    'दृष्टिमापी',
    'दृष्टियुक्त',
    'दृष्टियों',
    'दृष्टिवंत',
    'दृष्टिविज्ञानी',
    'दृष्टिहीन',
    'दृष्टिहीनता',
    'दृष्टींद्रिय',
    'दृष्ट्या',
    'दृष्द',
    'दृष्द्वती',
    'दृष्व्य',
    'दे',
    'दें',
    'देंगे',
    'देंड',
    'देंडधार',
    'देंडधारी',
    'देई',
    'देउर',
    'देकर',
    'देख',
    'देखकर',
    'देखता',
    'देखते',
    'देखन',
    'देखना',
    'देखनि',
    'देखने',
    'देखनेवाला',
    'देखभाल',
    'देखरेख',
    'देखा',
    'देखिए',
    'देखी',
    'देखे',
    'देखें',
    'देखो',
    'देग',
    'देगचा',
    'देगची',
    'देगा',
    'देजावू',
    'देता',
    'देती',
    'देते',
    'देदीप्यमान',
    'देदीप्यमानता',
    'देन',
    'देनदार',
    'देनदारियां',
    'देनदारी',
    'देनदारों',
    'देनहार',
    'देना',
    'देनी',
    'देने',
    'देनेवाला',
    'देनेवाली',
    'देय',
    'देयता',
    'देयताएं',
    'देयताओं',
    'देयतामुक्त',
    'देयतावाली',
    'देयी',
    'देर',
    'देरानी',
    'देरी',
    'देव',
    'देवक',
    'देवकक्ष',
    'देवकाष्ठ',
    'देवकिनी',
    'देवकी',
    'देवकीय',
    'देवकुल',
    'देवखात',
    'देवगुरु',
    'देवगृह',
    'देवच',
    'देवछंद',
    'देवजनी',
    'देवता',
    'देवताः',
    'देवताःविमान',
    'देवताधिप',
    'देवताप्रशमन',
    'देवतासभा',
    'देवतासेना',
    'देवत्रयी',
    'देवत्व',
    'देवत्वारोपण',
    'देवदत्त',
    'देवदाना',
    'देवदार',
    'देवदारु',
    'देवदारू',
    'देवदास',
    'देवदासी',
    'देवदूत',
    'देवदेव',
    'देवद्रोही',
    'देवधानी',
    'देवधूप',
    'देवन',
    'देवना',
    'देवनागरी',
    'देवनागरीः',
    'देवनागिरी',
    'देवपथ',
    'देवपद',
    'देवपुत्र',
    'देवपुत्री',
    'देवपुरी',
    'देवपूज्य',
    'देवमादन',
    'देवमीढ',
    'देवयानीः',
    'देवयुग',
    'देवर',
    'देवरा',
    'देवराज',
    'देवरात',
    'देवरानी',
    'देवरूपवाद',
    'देवर्षि',
    'देवल',
    'देवलः',
    'देववधू',
    'देववर्णा',
    'देववाणी',
    'देववादी',
    'देववायतन',
    'देवव्रत',
    'देवश्री',
    'देवश्रुत',
    'देवसभा',
    'देवसभ्य',
    'देवसेनप',
    'देवसेना',
    'देवसेनाः',
    'देवस्वरुप',
    'देवहत्या',
    'देवहूति',
    'देवहेति',
    'देवा',
    'देवांगना',
    'देवांश',
    'देवागनुग्रह',
    'देवागार',
    'देवाचार्य',
    'देवाजीव',
    'देवाजीवी',
    'देवात्मा',
    'देवाधिदेव',
    'देवाधिप',
    'देवाधीश्वर',
    'देवानीक',
    'देवान्न',
    'देवायुध',
    'देवारि',
    'देवार्थ',
    'देवार्पण',
    'देवार्पित',
    'देवाल',
    'देवालय',
    'देवासन',
    'देवासुर',
    'देवासुरेश्वर',
    'देवाहन',
    'देवाहार',
    'देवाहुति',
    'देवियाँ',
    'देवियां',
    'देवियो',
    'देविल',
    'देवी',
    'देवीः',
    'देवीय',
    'देवेंद्र',
    'देवेच्छा',
    'देवेज्य',
    'देवेश',
    'देवेशी',
    'देवेश्वर',
    'देवेष्टा',
    'देवोद्यान',
    'देवोपम',
    'देवोपहार',
    'देवोपोवन',
    'देवौ',
    'देश',
    'देशक',
    'देशकल',
    'देशज',
    'देशद्रोह',
    'देशद्रोही',
    'देशन',
    'देशना',
    'देशनिकाला',
    'देशभक्त',
    'देशभक्ति',
    'देशभिक्त',
    'देशमुख',
    'देशवासिता',
    'देशवासी',
    'देशव्यापी',
    'देशांतर',
    'देशांतरण',
    'देशांतरी',
    'देशांतरीय',
    'देशांश',
    'देशाचार',
    'देशाटन',
    'देशानुसार',
    'देशान्तर',
    'देशान्तरगामी',
    'देशान्तरण',
    'देशावर',
    'देशिक',
    'देशिनी',
    'देशी',
    'देशीकरण',
    'देशीभाषा',
    'देशीय',
    'देशीयकरण',
    'देशीयता',
    'देशों',
    'देष्टा',
    'देस',
    'देसवाल',
    'देसाई',
    'देसावरी',
    'देसी',
    'देह',
    'देहंभर',
    'देहकर्ता',
    'देहकेन्द्रित',
    'देहजा',
    'देहत्याग',
    'देहत्राण',
    'देहधारी',
    'देहनाशक',
    'देहयष्टि',
    'देहर',
    'देहरा',
    'देहरी',
    'देहली',
    'देहसार',
    'देहहीन',
    'देहहीनता',
    'देहांत',
    'देहांतर',
    'देहांतरण',
    'देहात',
    'देहातिन',
    'देहाती',
    'देहातीत',
    'देहातीपन',
    'देहात्मवाद',
    'देहान्त',
    'देहावरण',
    'देहासवसन',
    'देहिका',
    'देही',
    'देहीय',
    'दैगवारा',
    'दैतेय',
    'दैत्य',
    'दैत्यः',
    'दैत्यकाय',
    'दैत्यघ्न',
    'दैत्यता',
    'दैत्यराज',
    'दैत्यसेना',
    'दैत्या',
    'दैत्यारि',
    'दैत्यीय',
    'दैत्येंद्र',
    'दैध्र्य',
    'दैनंदिन',
    'दैनंदिनी',
    'दैनिक',
    'दैनिकता',
    'दैनिकी',
    'दैन्य',
    'दैमोन',
    'दैया',
    'दैर',
    'दैव',
    'दैवकृत',
    'दैवज्ञ',
    'दैवज्ञता',
    'दैवण्र',
    'दैवत',
    'दैवत्य',
    'दैवयोग',
    'दैववशात',
    'दैववाद',
    'दैववादी',
    'दैवागत',
    'दैवाधीन',
    'दैविक',
    'दैवी',
    'दैवीकृत',
    'दैवीज्ञान',
    'दैवीय',
    'दैवीयता',
    'दैवोपहत',
    'दैशिक',
    'दैशिकता',
    'दैशिका',
    'दैह',
    'दैहिक',
    'दो',
    'दोः',
    'दोआबा',
    'दोउ',
    'दोकडा',
    'दोखंडा',
    'दोखंडीय',
    'दोगला',
    'दोगाना',
    'दोगुना',
    'दोग्ध',
    'दोग्धा',
    'दोघ',
    'दोचंद',
    'दोचिता',
    'दोचिती',
    'दोजर्बी',
    'दोज़ख',
    'दोज़ख़',
    'दोज़खी',
    'दोजानू',
    'दोड',
    'दोडना',
    'दोतरफा',
    'दोतल्ला',
    'दोताल',
    'दोदना',
    'दोदिला',
    'दोदिली',
    'दोदिश',
    'दोदिशंत',
    'दोदोलन',
    'दोध',
    'दोधारा',
    'दोधारी',
    'दोन',
    'दोना',
    'दोनाया',
    'दोनावा',
    'दोनी',
    'दोनों',
    'दोपतवारी',
    'दोपल्ली',
    'दोपहर',
    'दोपहरः',
    'दोपहलू',
    'दोपहिया',
    'दोपाया',
    'दोफाँक',
    'दोबारा',
    'दोबाला',
    'दोमंजिला',
    'दोमट',
    'दोमहला',
    'दोमानी',
    'दोमुँहा',
    'दोय',
    'दोयज',
    'दोयम',
    'दोर',
    'दोरुखा',
    'दोरोजा',
    'दोल',
    'दोलक',
    'दोलड़ी',
    'दोलन',
    'दोलनदर्शी',
    'दोलनलेखी',
    'दोलना',
    'दोलनी',
    'दोला',
    'दोलायक',
    'दोलायनशीलता',
    'दोलायमान',
    'दोलायित',
    'दोलिका',
    'दोलित',
    'दोलितता',
    'दोलित्र',
    'दोली',
    'दोलोत्सव',
    'दोशंबा',
    'दोशीजगी',
    'दोशीजा',
    'दोष',
    'दोषकर',
    'दोषकर्ता',
    'दोषकारी',
    'दोषक्षम',
    'दोषक्षमता',
    'दोषघात',
    'दोषघ्न',
    'दोषजनक',
    'दोषदर्शी',
    'दोषदृष्टि',
    'दोषनिवारक',
    'दोषनिवारना',
    'दोषपूर्ण',
    'दोषप्रद',
    'दोषप्रवणता',
    'दोषभागी',
    'दोषमार्जन',
    'दोषमुक्त',
    'दोषमुक्ति',
    'दोषमोचन',
    'दोषयुक्त',
    'दोषरहित',
    'दोषरोप',
    'दोषहर',
    'दोषहीन',
    'दोषहीनता',
    'दोषा',
    'दोषान्वेषण',
    'दोषारोप',
    'दोषारोपः',
    'दोषारोपक',
    'दोषारोपण',
    'दोषारोपित',
    'दोषी',
    'दोषों',
    'दोसई',
    'दोसती',
    'दोसा',
    'दोसूती',
    'दोस्त',
    'दोस्तवाजी',
    'दोस्ताना',
    'दोस्ती',
    'दोस्तों',
    'दोह',
    'दोहक',
    'दोहगा',
    'दोहता',
    'दोहती',
    'दोहत्थड',
    'दोहत्था',
    'दोहद',
    'दोहदवती',
    'दोहदी',
    'दोहन',
    'दोहना',
    'दोहनी',
    'दोहर',
    'दोहरफ',
    'दोहरा',
    'दोहराई',
    'दोहराना',
    'दोहरापन',
    'दोहराया',
    'दोहराव',
    'दोहरी',
    'दोहरे',
    'दोहली',
    'दोहा',
    'दोहित',
    'दोह्या',
    'दौग्ध',
    'दौड',
    'दौडना',
    'दौड़',
    'दौड़ता',
    'दौड़धूप',
    'दौड़ना',
    'दौड़ने',
    'दौड़ा',
    'दौड़ाक',
    'दौड़ाकर',
    'दौड़ाकी',
    'दौड़ान',
    'दौड़ाना',
    'दौड़ी',
    'दौत्य',
    'दौना',
    'दौनिया',
    'दौनी',
    'दौर',
    'दौरा',
    'दौरान',
    'दौरानी',
    'दौरी',
    'दौरे',
    'दौर्ग',
    'दौर्बल्य',
    'दौर्मनस्य',
    'दौलत',
    'दौलतमंद',
    'दौलतमंदी',
    'दौला',
    'दौवारिक',
    'दौष्यत',
    'दौहित्र',
    'दौहित्री',
    'द्यतित',
    'द्यावा',
    'द्यु',
    'द्युकारि',
    'द्युगण',
    'द्युचर',
    'द्युति',
    'द्युतिमान',
    'द्युनदी',
    'द्युपति',
    'द्युमणि',
    'द्युम्न',
    'द्युलोक',
    'द्यूत',
    'द्यूतकक्ष',
    'द्यूतकर',
    'द्यूतगृह',
    'द्यूलोकीय',
    'द्यो',
    'द्योतक',
    'द्योतन',
    'द्योतित',
    'द्योस',
    'द्यौस',
    'द्र',
    'द्रढिमा',
    'द्रत',
    'द्रप्स',
    'द्रव',
    'द्रवज',
    'द्रवण',
    'द्रवणशील',
    'द्रवता',
    'द्रवत्व',
    'द्रवना',
    'द्रववायु',
    'द्रवसंक्रमण',
    'द्रविण',
    'द्रविणप्रद',
    'द्रवित',
    'द्रवित्र',
    'द्रवीभूत',
    'द्रवेतर',
    'द्रव्य',
    'द्रव्यप्रदान',
    'द्रव्यमान',
    'द्रव्यरूप',
    'द्रव्यवत',
    'द्रव्यात्मक',
    'द्रव्यार्जन',
    'द्रष्टव्य',
    'द्रष्टा',
    'द्रष्टार',
    'द्रह',
    'द्राक्षा',
    'द्राक्षारस',
    'द्राक्षासव',
    'द्राक्षिरा',
    'द्राक्षोद्यान',
    'द्राघिमा',
    'द्रादक',
    'द्राव',
    'द्रावक',
    'द्रावकर',
    'द्रावण',
    'द्राविडक',
    'द्राविडी',
    'द्रावित',
    'द्रावी',
    'द्रुकयूल',
    'द्रुत',
    'द्रुतकारिता',
    'द्रुतगति',
    'द्रुतगामिता',
    'द्रुतगामी',
    'द्रुततः',
    'द्रुतता',
    'द्रुतन',
    'द्रुति',
    'द्रुतित',
    'द्रुपद',
    'द्रुपदात्मज',
    'द्रुम',
    'द्रुमामय',
    'द्रुमालय',
    'द्रुमाश्म',
    'द्रुमिणी',
    'द्रुमिल',
    'द्रुमेश्वर',
    'द्रूम',
    'द्रृष्टा',
    'द्रोण',
    'द्रोणः',
    'द्रोणाचार्य',
    'द्रोणिका',
    'द्रोणी',
    'द्रोपद',
    'द्रोपदी',
    'द्रोपदीः',
    'द्रोपदीवाद',
    'द्रोह',
    'द्रोहपूर्ण',
    'द्रोहहीन',
    'द्रोही',
    'द्रौपदी',
    'द्वंद्व',
    'द्वंद्वचर',
    'द्वंद्वचर्या',
    'द्वंद्वन',
    'द्वंद्वातीतता',
    'द्वंद्वी',
    'द्वगुणिक',
    'द्वगुण्य',
    'द्वत',
    'द्वध',
    'द्वन्द',
    'द्वन्दात्मक',
    'द्वपायन',
    'द्वमातुर',
    'द्वय',
    'द्वयकीय',
    'द्वयर्थक',
    'द्वयापनी',
    'द्वष्टा',
    'द्वादश',
    'द्वादशफलक',
    'द्वादशभुज',
    'द्वादशात्मा',
    'द्वादशाय',
    'द्वादशी',
    'द्वापर',
    'द्वापरी',
    'द्वापरीय',
    'द्वार',
    'द्वारः',
    'द्वारकेश',
    'द्वारकोष्ठ',
    'द्वारचार',
    'द्वारन',
    'द्वारपाल',
    'द्वारपालिन',
    'द्वारमुख',
    'द्वाररक्षक',
    'द्वारा',
    'द्वाराचार',
    'द्वाराध्रुख',
    'द्वारालिंद',
    'द्वारिका',
    'द्वारिकाः',
    'द्वारिकाधीश',
    'द्वारी',
    'द्वारे',
    'द्वि',
    'द्विअक्षर',
    'द्विअर्थक',
    'द्विअर्थी',
    'द्विआधारी',
    'द्विआयामता',
    'द्विआयामीय',
    'द्विक',
    'द्विखंड',
    'द्विखंडित',
    'द्विखंडीय',
    'द्विगुण',
    'द्विगुणा',
    'द्विगुणित',
    'द्विगुणीकरण',
    'द्विग्राफ',
    'द्विचक्री',
    'द्विज',
    'द्विजत्व',
    'द्विजपति',
    'द्विजराज',
    'द्विजा',
    'द्विजायनी',
    'द्विजेंद्र',
    'द्विजेश',
    'द्विजोत्तम',
    'द्वितंत्रीय',
    'द्वितय',
    'द्विताल',
    'द्वितीय',
    'द्वितीयक',
    'द्वितीया',
    'द्वितीयार्ध',
    'द्वितीयो',
    'द्वित्व',
    'द्विदल',
    'द्विदलन',
    'द्विदलित',
    'द्विदलीय',
    'द्विदिश',
    'द्विदिशता',
    'द्विदिशा',
    'द्विदेव',
    'द्विदेशीय',
    'द्विधागति',
    'द्विधातु',
    'द्विधुरवता',
    'द्विनग्क',
    'द्विप',
    'द्विपक्षीत',
    'द्विपक्षीय',
    'द्विपत्नीत्व',
    'द्विपथ',
    'द्विपथी',
    'द्विपद',
    'द्विपदाः',
    'द्विपदी',
    'द्विपाटीय',
    'द्विपात्र',
    'द्विपात्रक',
    'द्विपाश्र्व',
    'द्विपाश्र्वीय',
    'द्विप्क्ष',
    'द्विप्क्षीय',
    'द्विफल',
    'द्विफलक',
    'द्विबिंदु',
    'द्विभागीय',
    'द्विभाजन',
    'द्विभाषिक',
    'द्विभाषी',
    'द्विभाषीकरण',
    'द्विभाषीय',
    'द्विभुज',
    'द्विभूम',
    'द्विमातुर',
    'द्विमात्र',
    'द्विमात्रिक',
    'द्विरता',
    'द्विरद',
    'द्विरागमन',
    'द्विरेफ',
    'द्विलिंगी',
    'द्विलोक',
    'द्विवचन',
    'द्विवर्ण',
    'द्विवार्षिक',
    'द्विविधा',
    'द्विविवाह',
    'द्विवेदी',
    'द्विशाखन',
    'द्विशाखित',
    'द्विशाखी',
    'द्विशायिका',
    'द्विशासन',
    'द्विषतः',
    'द्विष्ट',
    'द्विसंयोजक',
    'द्विसदन',
    'द्विसदनी',
    'द्विसर',
    'द्विसाप्ताहिक',
    'द्विस्वर',
    'द्वीप',
    'द्वीपवती',
    'द्वीपवान',
    'द्वीपावली',
    'द्वीपिका',
    'द्वीपी',
    'द्वीपीय',
    'द्वेष',
    'द्वेषपूर्ण',
    'द्वेषपूर्णता',
    'द्वेषपूर्वक',
    'द्वेषहीन',
    'द्वेषात्मक',
    'द्वेषिणी',
    'द्वेषित',
    'द्वेषी',
    'द्वैत',
    'द्वैतवाद',
    'द्वैतवादी',
    'द्वैधशासन',
    'द्वैपायन',
    'द्व्यर्थी',
    'दग़ाबाजी',
    'दफ़न',
    'दफ़नाना',
    'दफ़र',
    'दफ़ा',
    'ध',
    'धँधलाना',
    'धँधी',
    'धँधोर',
    'धँसक',
    'धँसकना',
    'धँसका',
    'धँसन',
    'धँसना',
    'धँसवाँ',
    'धँसा',
    'धँसान',
    'धँसानदार',
    'धँसाना',
    'धँसापन',
    'धँसाव',
    'धंधा',
    'धंधार',
    'धंधारी',
    'धंधाला',
    'धंधे',
    'धंस',
    'धंसना',
    'धंसा',
    'धक',
    'धकंत',
    'धकड',
    'धकधक',
    'धकधककरना',
    'धकधककाता',
    'धकधककाहट',
    'धकधकाहट',
    'धका',
    'धकाधक',
    'धकाधकी',
    'धकियाना',
    'धकियावा',
    'धकेल',
    'धकेलना',
    'धकेला',
    'धकेा',
    'धक्क',
    'धक्कड',
    'धक्कम',
    'धक्का',
    'धक्कामुक्की',
    'धक्के',
    'धगडा',
    'धगडी',
    'धज',
    'धजना',
    'धजा',
    'धजीला',
    'धज्जियां',
    'धज्जी',
    'धट',
    'धटिका',
    'धटी',
    'धड',
    'धडंग',
    'धडः',
    'धडक',
    'धडकन',
    'धडकना',
    'धडक्का',
    'धडडकता',
    'धडधड',
    'धडधडी',
    'धडबाज',
    'धडल्ला',
    'धडल्ले',
    'धड़',
    'धड़कते',
    'धड़कन',
    'धड़कना',
    'धड़धड़ाहट',
    'धड़ाका',
    'धड़ाम',
    'धड़ी',
    'धडा',
    'धडाक',
    'धडाका',
    'धडाम',
    'धडी',
    'धडेबंद',
    'धडेबंदी',
    'धण्टे',
    'धत',
    'धतकारना',
    'धता',
    'धतीगडा',
    'धतूर',
    'धतूरक',
    'धतूरा',
    'धधक',
    'धधकना',
    'धन',
    'धनंजय',
    'धनः',
    'धनकाम',
    'धनकुबेर',
    'धनगोप',
    'धनढ',
    'धनद',
    'धनदौलत',
    'धनधानी',
    'धनधान्य',
    'धनधान्यपूर्णता',
    'धनधान्यहीन',
    'धनधान्यहीनता',
    'धनन',
    'धनपति',
    'धनपाल',
    'धनप्रेषण',
    'धनफल',
    'धनमूल',
    'धनलाभ',
    'धनलोलुप',
    'धनलोलुपता',
    'धनवान',
    'धनशोघन',
    'धनसंबंधी',
    'धनसम्पत्ति',
    'धनहीन',
    'धना',
    'धनाग्र',
    'धनाढ',
    'धनाढ्यता',
    'धनात्मक',
    'धनादेश',
    'धनाध्यक्ष',
    'धनार्थी',
    'धनाशा',
    'धनाश्री',
    'धनिक',
    'धनिकतन्त्रीय',
    'धनिकता',
    'धनिका',
    'धनिया',
    'धनिष्ठा',
    'धनी',
    'धनीजोग',
    'धनीय',
    'धनु',
    'धनुआ',
    'धनुकौशल',
    'धनुगुर्णः',
    'धनुग्रार्ह',
    'धनुज्र्या',
    'धनुर्धर',
    'धनुर्धारी',
    'धनुर्मध्य',
    'धनुर्मार्ग',
    'धनुर्यज्ञ',
    'धनुर्विद्या',
    'धनुर्वेद',
    'धनुर्वेदी',
    'धनुविद्या',
    'धनुश',
    'धनुशाकार',
    'धनुशाकारता',
    'धनुष',
    'धनुषधारी',
    'धनुहा',
    'धनेच्छा',
    'धनेछुक',
    'धनेश्वर',
    'धनैषी',
    'धनोण',
    'धनोपार्जक',
    'धनोपार्जन',
    'धन्ढारी',
    'धन्ना',
    'धन्य',
    'धन्यवाद',
    'धन्यवादक',
    'धन्यवादपूर्वक',
    'धन्यवादहीन',
    'धन्यवादी',
    'धन्या',
    'धन्याक',
    'धन्व',
    'धन्वंतरि',
    'धन्वा',
    'धन्वाकार',
    'धन्वी',
    'धप्पा',
    'धब्बा',
    'धब्बे',
    'धब्बेदार',
    'धब्बों',
    'धम',
    'धमक',
    'धमकना',
    'धमका',
    'धमकाकर',
    'धमकाना',
    'धमकाने',
    'धमकानेवाला',
    'धमकियों',
    'धमकी',
    'धमकीपूर्वक',
    'धमचक',
    'धमधम',
    'धमन',
    'धमनिका',
    'धमनी',
    'धमनीय',
    'धमा',
    'धमाक',
    'धमाका',
    'धमाके',
    'धमाधम',
    'धमार',
    'धमाल',
    'धमित्र',
    'धमिर्णी',
    'धमिष्ठ',
    'धमूका',
    'धमोर्त्तर',
    'धमोर्पदेशक',
    'धमोर्पाध्याय',
    'धमोर्ल्लंघन',
    'धमोर्ल्लंधक',
    'धम्म',
    'धम्मसंघ',
    'धम्र',
    'धम्रपूर्ण',
    'धर',
    'धरंता',
    'धरण',
    'धरणि',
    'धरणिका',
    'धरणिजा',
    'धरणीधर',
    'धरणीरुह',
    'धरणीश्वर',
    'धरता',
    'धरती',
    'धरन',
    'धरना',
    'धरनी',
    'धरनैत',
    'धरम',
    'धरमी',
    'धरषना',
    'धरसना',
    'धरहर',
    'धरहरिया',
    'धरा',
    'धराई',
    'धरातल',
    'धरातलीय',
    'धरात्मजा',
    'धरादेव',
    'धराधरन',
    'धराधिप',
    'धराधीश',
    'धरापन',
    'धरावतरण',
    'धराशायिता',
    'धराशायी',
    'धरित',
    'धरित्री',
    'धरी',
    'धरुव',
    'धरुवा',
    'धरुवाक्षर',
    'धरुवीकृतता',
    'धरे',
    'धरेजा',
    'धरेला',
    'धरेली',
    'धरैया',
    'धरोहर',
    'धरोहिया',
    'धर्ता',
    'धर्तीगड',
    'धर्म',
    'धर्मः',
    'धर्मःपुत्र',
    'धर्मकाय',
    'धर्मकेतु',
    'धर्मगंथ',
    'धर्मगुप्त',
    'धर्मग्रंथ',
    'धर्मग्रन्थ',
    'धर्मचर्या',
    'धर्मचर्य्या',
    'धर्मचारी',
    'धर्मचिह्न',
    'धर्मच्युत',
    'धर्मज',
    'धर्मज्ञ',
    'धर्मतंत्र',
    'धर्मतंत्रावाद',
    'धर्मतत्वोपदेश',
    'धर्मत्याग',
    'धर्मदण्ड',
    'धर्मदण्डाधिकारी',
    'धर्मदाय',
    'धर्मधातु',
    'धर्मधुर्य',
    'धर्मध्वजी',
    'धर्मनाथ',
    'धर्मनाभ',
    'धर्मनिरपेक्ष',
    'धर्मनिरपेक्षता',
    'धर्मनिरपेक्षतावाद',
    'धर्मनिरपेक्षवादी',
    'धर्मनिष्ठ',
    'धर्मनिष्ठता',
    'धर्मनिष्ठा',
    'धर्मपत्नी',
    'धर्मपरायणता',
    'धर्मपाल',
    'धर्मपुरी',
    'धर्मप्रचार',
    'धर्मप्राण',
    'धर्मबुद्धि',
    'धर्मभाई',
    'धर्मभीरु',
    'धर्मभीरू',
    'धर्मभीरूता',
    'धर्मभ्रष्ट',
    'धर्मयुक्त',
    'धर्मयुक्तता',
    'धर्मयुग',
    'धर्मयुद्घ',
    'धर्मयुद्ध',
    'धर्मराज',
    'धर्मवंत',
    'धर्मवान',
    'धर्मवास',
    'धर्मविक्रय',
    'धर्मविज्ञान',
    'धर्मविधि',
    'धर्मविरूद्ध',
    'धर्मविरोधी',
    'धर्मवीर',
    'धर्मवृद्घ',
    'धर्मशाला',
    'धर्मशास्त्र',
    'धर्मशास्त्री',
    'धर्मशील',
    'धर्मशीलता',
    'धर्मसंकोच',
    'धर्मसंगति',
    'धर्मसंधि',
    'धर्मसत्ता',
    'धर्मसभा',
    'धर्मसम्मलन',
    'धर्मसार',
    'धर्मसेतु',
    'धर्मस्व',
    'धर्मस्वामी',
    'धर्महीन',
    'धर्माचरण',
    'धर्माचार्य',
    'धर्माज्ञा',
    'धर्मातिक्रमण',
    'धर्मात्मज',
    'धर्मात्मवाद',
    'धर्मात्मा',
    'धर्मात्मापन',
    'धर्मादा',
    'धर्मादेश',
    'धर्माधिकार',
    'धर्माधिकारी',
    'धर्माधिष्ठान',
    'धर्माध्यक्ष',
    'धर्मानुचरण',
    'धर्मानुचिंतन',
    'धर्मानुपालक',
    'धर्मानुपालन',
    'धर्मानुयायी',
    'धर्मानुसारण',
    'धर्मान्धता',
    'धर्माभास',
    'धर्माभिषेषक',
    'धर्माभिसिंचना',
    'धर्मारण्य',
    'धर्मार्थ',
    'धर्मावतार',
    'धर्मावलंबन',
    'धर्मावलंबी',
    'धर्माश्रित',
    'धर्मासन',
    'धर्मी',
    'धर्मीय',
    'धर्मेद्र',
    'धर्मोन्मत्त',
    'धर्मोन्माद',
    'धर्र्यहीनता',
    'धव',
    'धवनि',
    'धवर',
    'धवल',
    'धवलता',
    'धवला',
    'धवलित',
    'धवली',
    'धष्टभाषी',
    'धस',
    'धसक',
    'धसकन',
    'धससकना',
    'धसाव',
    'धाँधल',
    'धाँधली',
    'धाँधलेबाज',
    'धाँय',
    'धाँयधाँय',
    'धाँस',
    'धाँसना',
    'धांगड',
    'धांत',
    'धाई',
    'धाक',
    'धाकड',
    'धाकहीन',
    'धागा',
    'धागे',
    'धाटी',
    'धाड',
    'धात',
    'धाता',
    'धातु',
    'धातुओं',
    'धातुक',
    'धातुकर्म',
    'धातुकर्मी',
    'धातुगम्य',
    'धातुगर्भ',
    'धातुज',
    'धातुध्नन',
    'धातुनाश',
    'धातुपल',
    'धातुमय',
    'धातुमल',
    'धातुरंजन',
    'धातुराग',
    'धातुरोपण',
    'धातुवाद',
    'धातुवादी',
    'धातुवाले',
    'धातुविज्ञान',
    'धातुविज्ञानी',
    'धातुहासित',
    'धातृ',
    'धात्री',
    'धात्रीपुत्र',
    'धात्रीश',
    'धात्विक',
    'धात्वीकरण',
    'धात्वीय',
    'धात्वीयन',
    'धान',
    'धानप्रद',
    'धानयूष',
    'धानसाख',
    'धाना',
    'धानी',
    'धानुष्क',
    'धान्य',
    'धान्यक',
    'धान्यकूट',
    'धान्यगार',
    'धान्यविद',
    'धान्यशाली',
    'धान्या',
    'धान्यागार',
    'धान्यारि',
    'धान्योत्तम',
    'धाप',
    'धापना',
    'धापी',
    'धाबा',
    'धाम',
    'धामक',
    'धामस',
    'धामिर्क',
    'धामिर्कता',
    'धामिर्ण',
    'धाय',
    'धायँ',
    'धायक',
    'धार',
    'धारक',
    'धारकों',
    'धारण',
    'धारणधिकार',
    'धारणशील',
    'धारणा',
    'धारणाओं',
    'धारणाग्रस्त',
    'धारणाधिकार',
    'धारणावधि',
    'धारणाहीन',
    'धारणाहीनता',
    'धारदार',
    'धारना',
    'धारयिता',
    'धारविष्णु',
    'धारा',
    'धारांकुर',
    'धारांग',
    'धाराग्र',
    'धाराट',
    'धाराधर',
    'धाराधार',
    'धारापथ',
    'धाराप्रवाह',
    'धाराप्रवाहिता',
    'धाराबद्घ',
    'धारामापी',
    'धारामुख',
    'धारायें',
    'धाराला',
    'धारावही',
    'धारावाहिक',
    'धारावाहिकततः',
    'धारावाहितता',
    'धारावाही',
    'धारिक',
    'धारिणी',
    'धारित',
    'धारिता',
    'धारियां',
    'धारी',
    'धारीदार',
    'धारीवाला',
    'धार्म',
    'धार्मिक',
    'धार्मिकता',
    'धार्य',
    'धार्यता',
    'धाव',
    'धावक',
    'धावन',
    'धावना',
    'धावनी',
    'धावव्य',
    'धावा',
    'धाविका',
    'धावित',
    'धावी',
    'धिक',
    'धिकधिक',
    'धिक्कार',
    'धिक्कारना',
    'धिग',
    'धिज्जयाँ',
    'धियांपति',
    'धिरना',
    'धिरनी',
    'धिराव',
    'धिषणा',
    'धिषणाधिप',
    'धिष्ठय',
    'धिष्णय',
    'धींगडा',
    'धींगाधाँगी',
    'धींगाधीगी',
    'धींगामस्ती',
    'धींगामुश्ती',
    'धींगामुस्ती',
    'धींगी',
    'धीमा',
    'धीमी',
    'धीमे',
    'धीमेपन',
    'धीर',
    'धीरंद्रिय',
    'धीरज',
    'धीरत',
    'धीरता',
    'धीरमा',
    'धीरमान',
    'धीरमापन',
    'धीरमी',
    'धीरमे',
    'धीरमें',
    'धीरय',
    'धीरयस',
    'धीरर',
    'धीररज',
    'धीररता',
    'धीरा',
    'धीराधीरा',
    'धीरे',
    'धीवर',
    'धीवरक',
    'धीवरी',
    'धुँआ',
    'धुँआरा',
    'धुँकार',
    'धुँगार',
    'धुँगारना',
    'धुँघरू',
    'धुँद',
    'धुँध',
    'धुँधकार',
    'धुँधलका',
    'धुँधला',
    'धुँधलाना',
    'धुँधलापन',
    'धुँधलाया',
    'धुँधलायापन',
    'धुँधार',
    'धुँधियाला',
    'धुँधु',
    'धुँधुकार',
    'धुंआ',
    'धुंआकश',
    'धुंआधार',
    'धुंधलका',
    'धुंधला',
    'धुंधलापन',
    'धुअाँ',
    'धुआँ',
    'धुआँकश',
    'धुआँधार',
    'धुआँसा',
    'धुआं',
    'धुआंकाश',
    'धुएँ',
    'धुकड',
    'धुकधुक',
    'धुकधुकाहट',
    'धुकधुकी',
    'धुकनी',
    'धुकाना',
    'धुकुर',
    'धुडंगा',
    'धुत',
    'धुतकारना',
    'धुतू',
    'धुत्कार',
    'धुत्कारना',
    'धुत्कृत',
    'धुत्त',
    'धुधुकार',
    'धुधुरित',
    'धुन',
    'धुनकना',
    'धुनकाना',
    'धुनकी',
    'धुनना',
    'धुना',
    'धुनाई',
    'धुनि',
    'धुनिया',
    'धुनी',
    'धुन्ध',
    'धुपद',
    'धुबिया',
    'धुमनाल',
    'धुमित',
    'धुमैला',
    'धुर',
    'धुरंधर',
    'धुरंधरता',
    'धुरना',
    'धुरव',
    'धुरवीकरण',
    'धुरवीकृत',
    'धुरा',
    'धुराग्र',
    'धुराग्रीय',
    'धुरादण्ड',
    'धुरी',
    'धुरीण',
    'धुरीणता',
    'धुरीय',
    'धुरे',
    'धुर्य',
    'धुल',
    'धुला',
    'धुलाई',
    'धुलाईः',
    'धुलाईघर',
    'धुलाईधर',
    'धुलि',
    'धुलिभाजन',
    'धुले',
    'धुलेंडी',
    'धुव',
    'धुवक',
    'धुवता',
    'धुवाँ',
    'धुसा',
    'धुस्स',
    'धुस्सा',
    'धुाणेंद्रिय',
    'धूआ',
    'धूई',
    'धूत',
    'धूतकल्पष',
    'धूतपाप',
    'धूतुक',
    'धूतू',
    'धूतूतू',
    'धूधू',
    'धून',
    'धूनक',
    'धूना',
    'धूनी',
    'धूप',
    'धूपघडी',
    'धूपछाँव',
    'धूपछाँह',
    'धूपबत्ती',
    'धूपहीन',
    'धूपिया',
    'धूम',
    'धूमकेत',
    'धूमधाम',
    'धूमधामपूर्ण',
    'धूमधामी',
    'धूमध्वज',
    'धूमप्',
    'धूमल',
    'धूमस',
    'धूमांग',
    'धूमाच्छादित',
    'धूमिका',
    'धूमिल',
    'धूमिलतः',
    'धूमिलता',
    'धूम्या',
    'धूम्र',
    'धूम्रकेश',
    'धूम्रडंडी',
    'धूम्रनली',
    'धूम्रपत्र',
    'धूम्रपान',
    'धूम्रपूर्ण',
    'धूम्रमार्ग',
    'धूम्रयान',
    'धूम्ररंधर',
    'धूम्रलोचन',
    'धूम्रवर्ण',
    'धूम्रहीन',
    'धूम्रा',
    'धूम्राक्ष',
    'धूम्राभ',
    'धूम्रावरण',
    'धूरा',
    'धूर्जटि',
    'धूर्त',
    'धूर्तक',
    'धूर्तता',
    'धूर्ततापूर्ण',
    'धूर्ततापूर्वक',
    'धूर्तपन',
    'धूर्ता',
    'धूर्धर',
    'धूर्य',
    'धूल',
    'धूलि',
    'धूलिकण',
    'धूलिका',
    'धूलिध्वज',
    'धूलिमय',
    'धूलिमयता',
    'धूलैंडी',
    'धूस',
    'धूसर',
    'धूसरता',
    'धूसरा',
    'धूसरिका',
    'धूसरित',
    'धूसरितता',
    'धृत',
    'धृतराष्ट्र',
    'धृतराष्ट्रः',
    'धृतराष्ट्री',
    'धृतात्मा',
    'धृति',
    'धृतिमान',
    'धृतिहीन',
    'धृष्ट',
    'धृष्टता',
    'धृष्टतापूर्ण',
    'धृष्टतापूर्वक',
    'धृष्टद्युम्न',
    'धृष्टद्युम्नःपिता',
    'धृष्टपूर्ण',
    'धृष्ठताहीन',
    'धृष्णेश्वर',
    'धेका',
    'धेनु',
    'धेनुक',
    'धेय',
    'धेयना',
    'धेरे',
    'धेलचा',
    'धेला',
    'धेली',
    'धेवता',
    'धेवती',
    'धैनव',
    'धैयहीन',
    'धैर्य',
    'धैर्यपूर्वक',
    'धैर्यवान',
    'धैर्यशाली',
    'धैर्यशील',
    'धैर्यशीली',
    'धैर्यहीन',
    'धैवत',
    'धोई',
    'धोखा',
    'धोखादेह',
    'धोखादेही',
    'धोखाधड़ी',
    'धोखाधडी',
    'धोखे',
    'धोखेबाज',
    'धोखेबाजी',
    'धोटा',
    'धोड',
    'धोतर',
    'धोती',
    'धोतीः',
    'धोदो',
    'धोधा',
    'धोना',
    'धोनाः',
    'धोने',
    'धोब',
    'धोबिन',
    'धोबी',
    'धोबीखाना',
    'धोबीघट',
    'धोर',
    'धोरण',
    'धोरा',
    'धोरी',
    'धोरे',
    'धोवन',
    'धोवनः',
    'धौं',
    'धौंकनी',
    'धौंज',
    'धौंजना',
    'धौंडाल',
    'धौंबाज',
    'धौंस',
    'धौंसना',
    'धौंसपट्टी',
    'धौंसा',
    'धौकनी',
    'धौरे',
    'धौलहरा',
    'ध्न',
    'ध्माक्षी',
    'ध्मात',
    'ध्माता',
    'ध्माति',
    'ध्मान',
    'ध्यात',
    'ध्यातव्य',
    'ध्याता',
    'ध्यान',
    'ध्यानन',
    'ध्याननिष्ठ',
    'ध्यानपर',
    'ध्यानपरायण',
    'ध्यानपूण',
    'ध्यानपूर्णता',
    'ध्यानपूर्वक',
    'ध्यानबद्घ',
    'ध्यानमग्न',
    'ध्यानमग्नता',
    'ध्यानयोग',
    'ध्यानवस्थित',
    'ध्यानवस्थितता',
    'ध्यानस्थ',
    'ध्यानस्थता',
    'ध्यानहीन',
    'ध्यानहीनतः',
    'ध्यानहीनता',
    'ध्यानाकर्षक',
    'ध्यानाकर्षण',
    'ध्यानाकर्षित',
    'ध्यानाकर्षी',
    'ध्यानाधीन',
    'ध्यानापकर्षक',
    'ध्यानापकर्षण',
    'ध्यानार्थ',
    'ध्यानावस्था',
    'ध्यानी',
    'ध्यावन',
    'ध्येय',
    'ध्येयपूर्ण',
    'ध्येयहीन',
    'ध्येयहीनता',
    'ध्रुव',
    'ध्रुवक',
    'ध्रुवण',
    'ध्रुवणमापी',
    'ध्रुवता',
    'ध्रुवतारा',
    'ध्रुवल',
    'ध्रुववृत्तीय',
    'ध्रुवाक्ष',
    'ध्रुवित',
    'ध्रुवीकरण',
    'ध्रुवीय',
    'ध्रुवीयक',
    'ध्रुवीयता',
    'ध्वंस',
    'ध्वंसक',
    'ध्वंसहीन',
    'ध्वंसहीनता',
    'ध्वंसात्मकता',
    'ध्वंसावशेष',
    'ध्वंसास्त्र',
    'ध्वंसी',
    'ध्वज',
    'ध्वजक',
    'ध्वजदंड',
    'ध्वजभिवादन',
    'ध्वजवाह',
    'ध्वजा',
    'ध्वजाधर',
    'ध्वजाधरता',
    'ध्वजाधारी',
    'ध्वजारोहण',
    'ध्वजाहृत',
    'ध्वजिनी',
    'ध्वजी',
    'ध्वजोत्तेलन',
    'ध्वजोत्थान',
    'ध्वनक',
    'ध्वनन',
    'ध्वनि',
    'ध्वनिक',
    'ध्वनिकता',
    'ध्वनिकर',
    'ध्वनिकारी',
    'ध्वनिगुण',
    'ध्वनिग्रह',
    'ध्वनिग्राम',
    'ध्वनिग्रामिक',
    'ध्वनिग्रामिकता',
    'ध्वनिग्रामिकी',
    'ध्वनिग्राही',
    'ध्वनित',
    'ध्वनिध्वनिक',
    'ध्वनिपुनरूक्ति',
    'ध्वनिपूर्ण',
    'ध्वनिभीति',
    'ध्वनिमंदन',
    'ध्वनिमापी',
    'ध्वनिरोध',
    'ध्वनिरोधक',
    'ध्वनिरोधत्र',
    'ध्वनिलेख',
    'ध्वनिलेखन',
    'ध्वनिवाद',
    'ध्वनिवाही',
    'ध्वनिविज्ञान',
    'ध्वनिविस्तारक',
    'ध्वनिश्रवणवादी',
    'ध्वनिहीन',
    'ध्वनिहीनतः',
    'ध्वनिहीनता',
    'ध्वनीय',
    'ध्वन्यात्मक',
    'ध्वन्यात्मकता',
    'ध्वन्यात्मकवादी',
    'ध्वन्यारोपण',
    'ध्वन्यार्थ',
    'ध्वन्यार्थक',
    'ध्वयालेखक',
    'ध्वयावृत्ति',
    'ध्वस्त',
    'ध्वानिशास्त्री',
    'ध्वाभिनंदन',
    'न',
    'नँगियाना',
    'नँग्यावना',
    'नँघना',
    'नंग',
    'नंगा',
    'नंगाई',
    'नंगाझोरी',
    'नंगाझोली',
    'नंगापन',
    'नंगी',
    'नंगे',
    'नंद',
    'नंदंत',
    'नंदंती',
    'नंदक',
    'नंदन',
    'नंदना',
    'नंदनी',
    'नंदपुत्री',
    'नंदरानी',
    'नंदलाल',
    'नंदा',
    'नंदात्मजा',
    'नंदिकेश',
    'नंदिकेश्वर',
    'नंदिघोष',
    'नंदित',
    'नंदिनी',
    'नंदिमुख',
    'नंदी',
    'नंदोसी',
    'नंबर',
    'नंबरदार',
    'नंबरवार',
    'नंबरिंग',
    'नंबरी',
    'नआना',
    'नई',
    'नए',
    'नक',
    'नककटा',
    'नकचढा',
    'नकचढापन',
    'नकछेदन',
    'नकछेदी',
    'नकटना',
    'नकटा',
    'नकटी',
    'नकतोडा',
    'नकद',
    'नकदी',
    'नकदीकरण',
    'नकब',
    'नकबजन',
    'नकबजनी',
    'नकबेसर',
    'नकरना',
    'नकरा',
    'नकराज',
    'नकरात्मक',
    'नकल',
    'नकलकार',
    'नकलची',
    'नकलने',
    'नकलर',
    'नकलवीस',
    'नकली',
    'नकलीपन',
    'नकश',
    'नकशा',
    'नकसीर',
    'नक़ल',
    'नका',
    'नकापोश',
    'नकाब',
    'नकार',
    'नकारची',
    'नकारना',
    'नकारात्क',
    'नकारात्मक',
    'नकारी',
    'नकासना',
    'नकियाना',
    'नकियाव',
    'नकीर',
    'नकीरैन',
    'नकुजाजिन',
    'नकुती',
    'नकुल',
    'नकुलक',
    'नकुलीश',
    'नकेल',
    'नकोर',
    'नकोरा',
    'नक्कार',
    'नक्कारची',
    'नक्कारा',
    'नक्काल',
    'नक्काश',
    'नक्काशी',
    'नक्काशीदार',
    'नक्कू',
    'नक्क्का',
    'नक्चरी',
    'नक्त',
    'नक्तंचर',
    'नक्तचर',
    'नक्तचा',
    'नक्तचारी',
    'नक्दी',
    'नक्रूश',
    'नक्श',
    'नक्ष',
    'नक्षत्र',
    'नक्षत्रकाश',
    'नक्षत्रज्ञ',
    'नक्षत्रनाथ',
    'नक्षत्रनेमि',
    'नक्षत्रामृत',
    'नक्षत्री',
    'नक्षत्रीय',
    'नक्षत्रेश्वर',
    'नक्षा',
    'नक्षानवीस',
    'नक्षानवीसी',
    'नख',
    'नखक्षत',
    'नखप्रसाधन',
    'नखर',
    'नखरा',
    'नखरे',
    'नखरेबाज',
    'नखरेबाजी',
    'नखलेखा',
    'नखशोथ',
    'नखश्रृंगार',
    'नखांक',
    'नखानखी',
    'नखायुध',
    'नखास',
    'नखी',
    'नग',
    'नगज',
    'नगण',
    'नगण्य',
    'नगण्यता',
    'नगदी',
    'नगपति',
    'नगफौंस',
    'नगर',
    'नगरकोट',
    'नगरधिपति',
    'नगरपालिका',
    'नगररांचल',
    'नगरराध्यक्ष',
    'नगरवृद्ध',
    'नगरी',
    'नगरीकरण',
    'नगरीय',
    'नगरीयता',
    'नगरों',
    'नगरोपांत',
    'नगवासी',
    'नग़मा',
    'नग़माकार',
    'नग़माकारी',
    'नगाड़ा',
    'नगाड़े',
    'नगाडा',
    'नगाधिप',
    'नगारि',
    'नगालैंड',
    'नगीना',
    'नगेन्द्र',
    'नगेश',
    'नगौका',
    'नग्गर',
    'नग्ण्यता',
    'नग्न',
    'नग्नक',
    'नग्नकरण',
    'नग्नता',
    'नग्नतावाद',
    'नग्नतावादी',
    'नग्नमूर्ति',
    'नग्नवक्ष',
    'नग्नवादी',
    'नग्ना',
    'नग्नाट',
    'नघात',
    'नच',
    'नचका',
    'नचन',
    'नचना',
    'नचनिया',
    'नचनी',
    'नचवैया',
    'नचाना',
    'नचाने',
    'नचाहना',
    'नचिकेता',
    'नछत्री',
    'नज',
    'नजदीक',
    'नजदीकन',
    'नजदीकी',
    'नजम',
    'नजमी',
    'नजर',
    'नजरबंद',
    'नजरबंदी',
    'नजरबंदीकृत',
    'नजरबट्टू',
    'नजरराना',
    'नजरसानी',
    'नजरहाया',
    'नजराना',
    'नजरिया',
    'नजरे',
    'नजरें',
    'नजरों',
    'नजला',
    'नज़र',
    'नज़रकैद',
    'नज़रबंदी',
    'नज़्म',
    'नजाकत',
    'नजात',
    'नजाना',
    'नजारथ',
    'नजारा',
    'नजीक',
    'नजीर',
    'नजूम',
    'नजूल',
    'नज्म',
    'नज्मगो',
    'नट',
    'नटई',
    'नटखट',
    'नटखटता',
    'नटखटपन',
    'नटखटपना',
    'नटखटिया',
    'नटखटी',
    'नटगरी',
    'नटगीरी',
    'नटन',
    'नटना',
    'नटनी',
    'नटने',
    'नटबाजी',
    'नटरंग',
    'नटराज',
    'नटर्चा',
    'नटवर',
    'नटवेश',
    'नटसार',
    'नटसारी',
    'नटा',
    'नटित',
    'नटिनी',
    'नटी',
    'नटुआ',
    'नटेश्वर',
    'नडक',
    'नत',
    'नतमस्तक',
    'नतमस्तकता',
    'नति',
    'नतिनी',
    'नतीजा',
    'नतोदर',
    'नतोदरता',
    'नतोहू',
    'नत्ता',
    'नत्थी',
    'नत्थीकरण',
    'नत्थीकृत',
    'नत्थू',
    'नत्न',
    'नत्पाल',
    'नत्रक',
    'नत्शीश',
    'नथ',
    'नथना',
    'नथनाः',
    'नथनिया',
    'नथनी',
    'नथा',
    'नथाई',
    'नथुना',
    'नद',
    'नदनु',
    'नदपति',
    'नदर',
    'नदारद',
    'नदिया',
    'नदी',
    'नदीः',
    'नदीदा',
    'नदीधर',
    'नदीन',
    'नदीपति',
    'नदीमुखभूमि',
    'नदीय',
    'नदीरथ',
    'नदीश',
    'नद्घना',
    'नद्घा',
    'नद्घाई',
    'नद्घापन',
    'नद्धि',
    'नद्धी',
    'नद्युत्सृष्ट',
    'नध',
    'नधँघना',
    'नधश्वर',
    'नधा',
    'नधाई',
    'नन',
    'ननंद',
    'ननंदा',
    'ननंदू',
    'ननद',
    'ननदाइया',
    'ननदी',
    'ननदोई',
    'ननसार',
    'ननांदा',
    'ननिया',
    'ननिहाल',
    'नन्दन',
    'नन्हा',
    'नन्हें',
    'नपग्रह',
    'नपच्यून',
    'नपा',
    'नपाई',
    'नपुंसक',
    'नपुंसकता',
    'नपुंसीकरण',
    'नपुंसीकृत',
    'नपुंस्त्व',
    'नपुआ',
    'नपुर',
    'नपुसंक',
    'नपुसकृत',
    'नपैना',
    'नपैया',
    'नप्ता',
    'नप्त्री',
    'नफर',
    'नफरत',
    'नफरतअंगेज',
    'नफरी',
    'नफा',
    'नफाखोरी',
    'नफासत',
    'नफीरी',
    'नफ्स',
    'नफ्सपरस्त',
    'नफ्सपरस्ती',
    'नफ्सानियत',
    'नफ्सी',
    'नबर',
    'नबिया',
    'नबी',
    'नबूवत',
    'नब्ज',
    'नब्बे',
    'नब्बेवां',
    'नभ',
    'नभग',
    'नभगज',
    'नभगामी',
    'नभगेश',
    'नभचर',
    'नभचराः',
    'नभद्वीप',
    'नभयान',
    'नभशीर्ष',
    'नभस्थल',
    'नभस्वान',
    'नभांबुप',
    'नभातीत',
    'नभिकीय',
    'नभैा',
    'नभोगन',
    'नभोगामी',
    'नभोत्तरण',
    'नभोत्तरित',
    'नभोमंडल',
    'नभोमणि',
    'नभ्य',
    'नभ्राट',
    'नम',
    'नमः',
    'नमक',
    'नमकदान',
    'नमकदानी',
    'नमकीन',
    'नमकीनपन',
    'नमगीर',
    'नमत',
    'नमदा',
    'नमदार',
    'नमदारी',
    'नमन',
    'नमना',
    'नमनीय',
    'नमने',
    'नमस्कार',
    'नमस्ते',
    'नमस्या',
    'नमाज',
    'नमाज़',
    'नमाज़गुजार',
    'नमाजी',
    'नमामि',
    'नमित',
    'नमिनाथ',
    'नमी',
    'नमीदार',
    'नमुचि',
    'नमूना',
    'नमूनाकार',
    'नमूने',
    'नमूनों',
    'नमो',
    'नमोगुरु',
    'नमोनः',
    'नम्दा',
    'नम्बर',
    'नम्बरों',
    'नम्य',
    'नम्यता',
    'नम्र',
    'नम्रतः',
    'नम्रता',
    'नम्रतापूर्वक',
    'नय',
    'नयंत्रक',
    'नयज्ञ',
    'नयता',
    'नयन',
    'नयना',
    'नयनाध्यक्ष',
    'नयनाभिराम',
    'नयनारविंद',
    'नयशील',
    'नया',
    'नयापन',
    'नयायिक',
    'नयी',
    'नये',
    'नय्या',
    'नर',
    'नरई',
    'नरक',
    'नरकः',
    'नरकट',
    'नरकटीय',
    'नरकवास',
    'नरकाय',
    'नरकीय',
    'नरकुल',
    'नरगिस',
    'नरगिसी',
    'नरता',
    'नरदेव',
    'नरनारी',
    'नरनारीवपु',
    'नरपिशाच',
    'नरबल',
    'नरबीज',
    'नरभक्षण',
    'नरभक्षता',
    'नरभक्षी',
    'नरम',
    'नरमपंथी',
    'नरमाई',
    'नरमाना',
    'नरमी',
    'नरमुंड',
    'नरयंत्र',
    'नरवध',
    'नरवर',
    'नरवाहन',
    'नरवीर',
    'नरश्वर',
    'नरसिंघा',
    'नरसिह',
    'नरसिहा',
    'नरसों',
    'नरहर',
    'नरहरि',
    'नरह्वेल',
    'नराधम',
    'नराधिप',
    'नरायण',
    'नरिया',
    'नरी',
    'नरीय',
    'नरीयरी',
    'नरीयल',
    'नरीया',
    'नरीष्यंत',
    'नरुआ',
    'नरेंद्र',
    'नरेन्द्र',
    'नरेश',
    'नरोत्तम',
    'नरोम',
    'नर्क',
    'नर्गमन',
    'नर्त',
    'नर्तक',
    'नर्तकियों',
    'नर्तकी',
    'नर्तकों',
    'नर्तन',
    'नर्तयिता',
    'नर्तित',
    'नर्तिनी',
    'नर्तू',
    'नर्दी',
    'नर्बदा',
    'नर्म',
    'नर्मट',
    'नर्मद',
    'नर्मदा',
    'नर्मदाः',
    'नर्मदिली',
    'नर्मदेश्वर',
    'नर्मी',
    'नर्व',
    'नर्वस',
    'नर्स',
    'नर्सरी',
    'नर्सलिंग',
    'नर्सिंग',
    'नल',
    'नलक',
    'नलकार',
    'नलकारी',
    'नलकी',
    'नलकूप',
    'नलवा',
    'नलसाज़',
    'नलसेतु',
    'नलिका',
    'नलिकाकार',
    'नलिन',
    'नलिनी',
    'नलियों',
    'नली',
    'नलीः',
    'नलीदार',
    'नलीमुख',
    'नल्ला',
    'नव',
    'नवंबर',
    'नवक',
    'नवकन्या',
    'नवकर्मी',
    'नवकुमारी',
    'नवगठित',
    'नवग्रही',
    'नवचंद्राकार',
    'नवचंद्राकारता',
    'नवचन्द्रक',
    'नवजात',
    'नवजातक',
    'नवतः',
    'नवता',
    'नवतारा',
    'नवति',
    'नवतिका',
    'नवत्र',
    'नवदंपति',
    'नवदीक्षित',
    'नवदुर्गा',
    'नवद्वार',
    'नवधनी',
    'नवधर्मी',
    'नवन',
    'नवना',
    'नवनिधि',
    'नवनीत',
    'नवनीतक',
    'नवनीतप्रिय',
    'नवपरिवर्तन',
    'नवपाषाण',
    'नवप्रवर्तन',
    'नवप्रस्तर',
    'नवभक्ति',
    'नवम',
    'नवमी',
    'नवम्बर',
    'नवयुग',
    'नवयुवक',
    'नवयुवा',
    'नवयौवती',
    'नवयौवन',
    'नवयौवना',
    'नवरंधर',
    'नवरत्न',
    'नवरस',
    'नवरात्र',
    'नवरात्रि',
    'नवरोज',
    'नवल',
    'नवलता',
    'नववक्ता',
    'नववत',
    'नववतः',
    'नववादी',
    'नववी',
    'नवशक्ति',
    'नवशिक्त',
    'नवशिष्यकाल',
    'नवशिष्यत्व',
    'नवशिष्या',
    'नवशिष्यालय',
    'नवह',
    'नवा',
    'नवाँ',
    'नवां',
    'नवांकुर',
    'नवागंतुक',
    'नवागत',
    'नवाचिर्',
    'नवाज',
    'नवाजना',
    'नवाज़',
    'नवाजिश',
    'नवाजी',
    'नवाडा',
    'नवानुसरण',
    'नवान्न',
    'नवाब',
    'नवाबजादा',
    'नवाबजादी',
    'नवाबी',
    'नवारा',
    'नवार्थ',
    'नवाविष्कृत',
    'नवासा',
    'नवासी',
    'नवी',
    'नवीकरण',
    'नवीकरणीय',
    'नवीकृत',
    'नवीकृतता',
    'नवीन',
    'नवीनत',
    'नवीनतम',
    'नवीनतमता',
    'नवीनता',
    'नवीनतावादी',
    'नवीनीकरण',
    'नवीयन',
    'नवीस',
    'नवीसी',
    'नवेद',
    'नवेधु',
    'नवेला',
    'नवेली',
    'नवैयत',
    'नवोढा',
    'नवोत्पाद',
    'नवोदय',
    'नवोदित',
    'नवोद्भव',
    'नवोन्मेष',
    'नवोन्मेषित',
    'नवोन्ष',
    'नव्य',
    'नव्यता',
    'नशना',
    'नशा',
    'नशाखोर',
    'नशाखोरी',
    'नशापत्ती',
    'नशापानी',
    'नशाबंदी',
    'नशीन',
    'नशीनी',
    'नशीला',
    'नशीलापन',
    'नशीले',
    'नशुंक',
    'नशे',
    'नशेडी',
    'नशेडीपन',
    'नशेदार',
    'नशेबाज',
    'नशेबाजी',
    'नश्तर',
    'नश्वर',
    'नश्वरता',
    'नष्ट',
    'नष्टकुल',
    'नष्टचेतन',
    'नष्टचेष्ट',
    'नष्टचेष्टता',
    'नष्टधी',
    'नष्टपाप',
    'नष्टप्रभ',
    'नष्टबीज',
    'नष्टवंश',
    'नष्टशक्ति',
    'नष्टसंज्ञ',
    'नष्टस्मृति',
    'नष्टा',
    'नष्टात्मता',
    'नष्टेदुकला',
    'नस',
    'नसतालीक',
    'नसदार',
    'नसब',
    'नसबन्दी',
    'नसलदार',
    'नसवार',
    'नसिर्ग',
    'नसीब',
    'नसीबा',
    'नसीम',
    'नसीहत',
    'नसेडी',
    'नसेडीपन',
    'नसैनी',
    'नस्तक',
    'नस्तालीक',
    'नस्य',
    'नस्या',
    'नस्र',
    'नस्रनिगार',
    'नस्रानियत',
    'नस्रानी',
    'नस्ल',
    'नस्लवाला',
    'नस्ली',
    'नह',
    'नहछू',
    'नहर',
    'नहरों',
    'नहलाना',
    'नहलाने',
    'नहले',
    'नहस',
    'नहाँ',
    'नहाड़ी',
    'नहान',
    'नहानः',
    'नहाना',
    'नहाया',
    'नहार',
    'नहारमुँह',
    'नहारी',
    'नहिअन',
    'नही',
    'नहीं',
    'नहुष',
    'नहूसत',
    'नहोना',
    'ना',
    'नाँगा',
    'नाँघना',
    'नाँद',
    'नाँधना',
    'नांक',
    'नांगर',
    'नांजूर',
    'नांद',
    'नांदिकर',
    'नांदी',
    'नांदीक',
    'नांदीकर',
    'नांदीपट',
    'नांराशांस',
    'नांव',
    'नाइ',
    'नाइंसाफ',
    'नाइंसाफी',
    'नाइट',
    'नाइटी',
    'नाइट्रोजन',
    'नाइट्रोजीनस',
    'नाइत्तिफाकी',
    'नाइन',
    'नाइफ',
    'नाइर्टिगेल',
    'नाइलोएन',
    'नाइलोन',
    'नाइस्तेमालशुदा',
    'नाइस्तेमाली',
    'नाई',
    'नाईः',
    'नाईगिरी',
    'नाउत',
    'नाउम्मीद',
    'नाउम्मीदी',
    'नाक',
    'नाकः',
    'नाकचढ़ा',
    'नाकचिमटी',
    'नाकदरा',
    'नाकद्घ',
    'नाकना',
    'नाक़नक्श',
    'नाका',
    'नाकाफी',
    'नाकाबंद',
    'नाकाबंदी',
    'नाकाबिल',
    'नाकाम',
    'नाकामयाब',
    'नाकामी',
    'नाकायाबी',
    'नाकारा',
    'नाकिस',
    'नाकिह',
    'नाक्षत्र',
    'नाक्षात्रिकी',
    'नाक्षात्रिय',
    'नाख',
    'नाखुदा',
    'नाखुन',
    'नाखुश',
    'नाखुशी',
    'नाखून',
    'नाख्वांदा',
    'नाग',
    'नागः',
    'नागचूड',
    'नागज',
    'नागदंत',
    'नागदंती',
    'नागदैत्य',
    'नागधर',
    'नागन',
    'नागपाश',
    'नागपुर',
    'नागपुष्पा',
    'नागफेन',
    'नागबल',
    'नागभूषण',
    'नागमेध',
    'नागर',
    'नागरंग',
    'नागरक',
    'नागरता',
    'नागरतापूर्ण',
    'नागराज',
    'नागरिक',
    'नागरिकता',
    'नागरी',
    'नागरीकरण',
    'नागरीट',
    'नागरीय',
    'नागल',
    'नागवार',
    'नागहाँ',
    'नागा',
    'नागांगना',
    'नागांतक',
    'नागारि',
    'नागालैंड',
    'नागासुर',
    'नागिन',
    'नागिनी',
    'नागी',
    'नागेन्द्र',
    'नागेश',
    'नागेश्वर',
    'नागेश्वरन',
    'नागोदर',
    'नाच',
    'नाचकर',
    'नाचगान',
    'नाचगाना',
    'नाचघर',
    'नाचना',
    'नाचने',
    'नाचरंग',
    'नाचार',
    'नाचारगी',
    'नाचिकेत',
    'नाचीज',
    'नाज',
    'नाजदार',
    'नाजनीन',
    'नाजल',
    'नाजाँ',
    'नाजायज',
    'नाजिम',
    'नाजिर',
    'नाजिल',
    'नाजीवाद',
    'नाजुक',
    'नाजुकदिमाग',
    'नाजुकपन',
    'नाजुकमिजाज',
    'नाजुकी',
    'नाजो',
    'नाट',
    'नाटक',
    'नाटककार',
    'नाटककारों',
    'नाटकांत',
    'नाटकावतार',
    'नाटकिया',
    'नाटकीकरण',
    'नाटकीय',
    'नाटकीयता',
    'नाटकों',
    'नाटय',
    'नाटयः',
    'नाटयकार',
    'नाटयधर',
    'नाटयम्',
    'नाटयशाला',
    'नाटयांत',
    'नाटयागार',
    'नाटयारंभ',
    'नाटयोचित',
    'नाटा',
    'नाटापन',
    'नाटिका',
    'नाटिकाख्यायिका',
    'नाटित',
    'नाट्य',
    'नाट्यकला',
    'नाट्यगृह',
    'नाट्यनौका',
    'नाट्यम',
    'नाट्यशाला',
    'नाट्यशास्त्र',
    'नाट्यिआभनय',
    'नाठ',
    'नाड',
    'नाड़ा',
    'नाड़िका',
    'नाडिका',
    'नाडिया',
    'नाडी',
    'नाडीका',
    'नाडु',
    'नाडे',
    'नाडेदानी',
    'नात',
    'नातजर्बेकार',
    'नातजर्बेकारी',
    'नातबारा',
    'नातमाम',
    'नातवाँ',
    'नातवानी',
    'नाता',
    'नाताक्रती',
    'नातिन',
    'नाती',
    'नाते',
    'नातेदार',
    'नातेदारी',
    'नात्काबंद',
    'नात्र',
    'नात्सीवाद',
    'नाथ',
    'नाथन',
    'नाथना',
    'नाद',
    'नादना',
    'नादपूर्ण',
    'नादान',
    'नादानी',
    'नादित',
    'नादिया',
    'नादिर',
    'नादिरी',
    'नादिहंद',
    'नादी',
    'नादुरुस्त',
    'नादे',
    'नादेय',
    'नाधना',
    'नाधा',
    'नान',
    'नानक',
    'नानका',
    'नानकीन',
    'नानके',
    'नानपज',
    'नानपजी',
    'नानफरोश',
    'नानबाई',
    'नानवाह',
    'नानसरा',
    'नानसा',
    'नाना',
    'नानात्मवाद',
    'नानारुपता',
    'नानारुप्',
    'नानार्थ',
    'नानावती',
    'नानाविध',
    'नानी',
    'नाने',
    'नाप',
    'नापंसद',
    'नापजोख',
    'नापतौल',
    'नापदड',
    'नापन',
    'नापना',
    'नापने',
    'नापवाला',
    'नापसंद',
    'नापसंदः',
    'नापसंदगी',
    'नापसंदी',
    'नापसंदीदा',
    'नापसदगी',
    'नापा',
    'नापाक',
    'नापाकी',
    'नापायदार',
    'नापायदारी',
    'नापी',
    'नापैद',
    'नाफ',
    'नाफरमां',
    'नाफरमानी',
    'नाबंटन',
    'नाबदान',
    'नाबालिग',
    'नाबालिगः',
    'नाबूद',
    'नाभल',
    'नाभि',
    'नाभिक',
    'नाभिका',
    'नाभिकाय',
    'नाभिकीय',
    'नाभिच्छद',
    'नाभिजन',
    'नाभिसोल',
    'नाभीय',
    'नाभ्य',
    'नाम',
    'नामंजूर',
    'नामंजूरी',
    'नामक',
    'नामकरण',
    'नामकृत',
    'नामचार',
    'नामचारतः',
    'नामचिप्पी',
    'नामचीन',
    'नामजद',
    'नामजदगी',
    'नामजात',
    'नामतंत्र',
    'नामदार',
    'नामधातु',
    'नामधारी',
    'नामधेय',
    'नामन',
    'नामना',
    'नामपट',
    'नामपट्ट',
    'नामपट्टिका',
    'नामपट्टी',
    'नामपट्टों',
    'नामपत्रण',
    'नामपद्धति',
    'नाममंत्रवादी',
    'नाममात्र',
    'नाममात्रता',
    'नामयुक्त',
    'नामरासी',
    'नामरुपहीन',
    'नामरूपवाद',
    'नामर्द',
    'नामर्दी',
    'नामलेवा',
    'नामवर',
    'नामवरी',
    'नामवाद',
    'नामविज्ञान',
    'नामविषयक',
    'नामशैली',
    'नामस्त्रोत',
    'नामस्त्रोतीय',
    'नामहीन',
    'नामहीनता',
    'नामा',
    'नामांक',
    'नामांकक',
    'नामांकन',
    'नामांकित',
    'नामांतर',
    'नामांतरण',
    'नामाकुल',
    'नामातीतता',
    'नामानुशासन',
    'नामाभिधान',
    'नामालूम',
    'नामावर',
    'नामावरी',
    'नामावली',
    'नामि',
    'नामिक',
    'नामिका',
    'नामिकीकरण',
    'नामित',
    'नामी',
    'नामुनासिब',
    'नामुमकिन',
    'नामुराद',
    'नामे',
    'नामों',
    'नामोच्चारण',
    'नामोदिष्ट',
    'नामोल्लेख',
    'नामौजूं',
    'नाम्य',
    'नायँ',
    'नायक',
    'नायकता',
    'नायकत्व',
    'नायकसखा',
    'नायका',
    'नायकी',
    'नायडू',
    'नायब',
    'नायलोन',
    'नायाब',
    'नायिका',
    'नार',
    'नारंग',
    'नारंगी',
    'नारंजी',
    'नारक',
    'नारकिक',
    'नारकीय',
    'नारद',
    'नारबी',
    'नारयल',
    'नारसाई',
    'नारसिंहवपु',
    'नारा',
    'नाराच',
    'नाराज',
    'नाराजगी',
    'नाराजी',
    'नारायण',
    'नारायणा',
    'नारायणी',
    'नारायणीय',
    'नाराशंस',
    'नाराज़',
    'नाराज़गी',
    'नारिकेल',
    'नारियल',
    'नारी',
    'नारीः',
    'नारीत्वपूर्ण',
    'नारीय',
    'नारीवत',
    'नार्युचित',
    'नार्सीसिज्',
    'नाल',
    'नालबंद',
    'नालमुखी',
    'नालवाला',
    'नालव्रण',
    'नाला',
    'नालायक्र',
    'नालायक्री',
    'नालिका',
    'नालियों',
    'नालिश',
    'नाली',
    'नालीदार',
    'नालौर',
    'नाव',
    'नावक',
    'नावकिफ्र',
    'नावघर',
    'नावनीत',
    'नावनीतता',
    'नावलैट',
    'नावाजिब',
    'नावारोह',
    'नावास',
    'नाविक',
    'नाविका',
    'नाविकीय',
    'नावी',
    'नावेल',
    'नाव्य',
    'नाव्यता',
    'नाश',
    'नाशक',
    'नाशकारक',
    'नाशन',
    'नाशपाती',
    'नाशवत्ता',
    'नाशवाद',
    'नाशवान',
    'नाशशील',
    'नाशशीलता',
    'नाशहीन',
    'नाशहीनता',
    'नाशाद',
    'नाशिकृमि',
    'नाशिनी',
    'नाशी',
    'नाशुका',
    'नाशुकी',
    'नाश्ता',
    'नाश्ते',
    'नास',
    'नासत्य',
    'नासमझ',
    'नासमझी',
    'नासा',
    'नासाकील',
    'नासाग्र',
    'नासाज़',
    'नासाद्वार',
    'नासापटी',
    'नासापुट',
    'नासामल',
    'नासास्थि',
    'नासिक',
    'नासिका',
    'नासिक्य',
    'नासिर',
    'नासीर',
    'नासूर',
    'नासेह',
    'नास्ति',
    'नास्तिक',
    'नास्तिकता',
    'नास्तिकतावाद',
    'नास्तिवाद',
    'नास्तिवादी',
    'नास्य',
    'नाह',
    'नाहक',
    'नाहक्र',
    'नाहर',
    'नाहीं',
    'नाज़ी',
    'निंदक',
    'निंदन',
    'निंदनीय',
    'निंदनीयता',
    'निंदरना',
    'निंदरा',
    'निंदरिया',
    'निंदा',
    'निंदाई',
    'निंदाना',
    'निंदासा',
    'निंदित',
    'निंदिया',
    'निंद्य',
    'निंफोमैनिया',
    'निंब',
    'निंबु',
    'निःशत्रुता',
    'निःसंकोच',
    'निःसंदेह',
    'निःसंदेहता',
    'निःसपत्न',
    'निअरे',
    'निआराना',
    'निऋत',
    'निऋति',
    'निओन',
    'निक',
    'निकट',
    'निकटतम',
    'निकटता',
    'निकटतापूर्ण',
    'निकटदर्शी',
    'निकटपूर्व',
    'निकटवर्ती',
    'निकटसान्न',
    'निकटस्थ',
    'निकटासन्नता',
    'निकटासीन',
    'निकती',
    'निकम्मा',
    'निकम्मापन',
    'निकम्मे',
    'निकर',
    'निकर्षण',
    'निकर्षणा',
    'निकल',
    'निकलता',
    'निकलते',
    'निकलन',
    'निकलना',
    'निकलने',
    'निकललना',
    'निकलवाना',
    'निकला',
    'निकलाई',
    'निकलापन',
    'निकलाव',
    'निकलावा',
    'निकली',
    'निकष',
    'निकषण',
    'निकसन',
    'निकसा',
    'निकाई',
    'निकाना',
    'निकाय',
    'निकाल',
    'निकालकर',
    'निकालना',
    'निकालने',
    'निकाला',
    'निकाली',
    'निकालू',
    'निकाले',
    'निकालेंगे',
    'निकास',
    'निकासना',
    'निकासशील',
    'निकासी',
    'निकाह',
    'निकाहबंदी',
    'निकाहा',
    'निकाही',
    'निकिन',
    'निकियाना',
    'निकुंज',
    'निकुंभ',
    'निकुर',
    'निकृष्ट',
    'निकृष्टतम',
    'निकृष्टतमता',
    'निकृष्टतर',
    'निकृष्टतरता',
    'निकृष्टता',
    'निकृष्टतावाद',
    'निकेत',
    'निकेतन',
    'निकोटीन',
    'निकोबार',
    'निकौनी',
    'निक्वण',
    'निक्षारक',
    'निक्षारण',
    'निक्षिप्त',
    'निक्षेप',
    'निक्षेपक',
    'निक्षेपगृहिता',
    'निक्षेपचिह्न',
    'निक्षेपण',
    'निक्षेपागार',
    'निक्षेपित',
    'निक्षेपिती',
    'निक्षेपी',
    'निखंड',
    'निखट्टू',
    'निखद',
    'निखनन',
    'निखनित',
    'निखरना',
    'निखरा',
    'निखरापन',
    'निखरी',
    'निखर्व',
    'निखलित',
    'निखात',
    'निखार',
    'निखारना',
    'निखारा',
    'निखालिस',
    'निखिल',
    'निखिलता',
    'निखुटना',
    'निखोट',
    'निख्लस्तान',
    'निगग्रहहीनता',
    'निगड',
    'निगडन',
    'निगडित',
    'निगद',
    'निगदन',
    'निगम',
    'निगमन',
    'निगमनात्मक',
    'निगमाधीन',
    'निगमित',
    'निगमीय',
    'निगम्य',
    'निगरा',
    'निगरानी',
    'निगल',
    'निगलकर',
    'निगलन',
    'निगलना',
    'निगलने',
    'निगला',
    'निगलू',
    'निगहबानी',
    'निगागम',
    'निगान',
    'निगार',
    'निगारी',
    'निगाली',
    'निगाह',
    'निगाहे',
    'निगी',
    'निगीर्ण',
    'निगु',
    'निगुरा',
    'निगूढ',
    'निगूढ़',
    'निगूहन',
    'निगेटिव',
    'निगोडा',
    'निगोडी',
    'निग्नका',
    'निग्रंथ',
    'निग्रंथक',
    'निग्रंथथता',
    'निग्रर्थन',
    'निग्रर्थि',
    'निग्रर्थिक',
    'निग्रर्थित',
    'निग्रर्थिता',
    'निग्रह',
    'निग्रहण',
    'निग्रहित',
    'निग्रही',
    'निघ',
    'निघंटु',
    'निघरघट',
    'निघरा',
    'निघर्षण',
    'निघाकार',
    'निघात',
    'निघाती',
    'निचय',
    'निचला',
    'निचलाया',
    'निचली',
    'निचले',
    'निचाई',
    'निचान',
    'निचित',
    'निचुडना',
    'निचुड़ाई',
    'निचुडा',
    'निचुडाई',
    'निचुलक',
    'निचोड',
    'निचोडना',
    'निचोड़',
    'निचोड़ना',
    'निचोल',
    'निचौहें',
    'निचौहो',
    'निछावर',
    'निछावरि',
    'निछोह',
    'निज',
    'निजजू',
    'निजता',
    'निजत्वहीनता',
    'निजात',
    'निजाम',
    'निजामत',
    'निजिर्त',
    'निजिर्ह्व',
    'निजी',
    'निजीकरण',
    'निजीकृत',
    'निजोरा',
    'निझरना',
    'निटिंग',
    'निठल्ला',
    'निठल्लापन',
    'निठल्लू',
    'निठुर',
    'निठुरता',
    'निठौर',
    'निडर',
    'निडरता',
    'निडीन',
    'निढाल',
    'निणींत',
    'नित',
    'नितंब',
    'नितंबा',
    'नितम्ब',
    'नितम्बी',
    'नितराम',
    'नितल',
    'नितलीय',
    'नितलीयता',
    'नितांत',
    'नितान्त',
    'नित्तर',
    'नित्य',
    'नित्यकर्म',
    'नित्यगति',
    'नित्यचर्या',
    'नित्यतः',
    'नित्यता',
    'नित्ययौवना',
    'नित्यशाः',
    'नित्याक्रिया',
    'नित्याचार',
    'निथरन',
    'निथरना',
    'निथरनी',
    'निथरा',
    'निथराई',
    'निथरी',
    'निथार',
    'निथारना',
    'निदंक',
    'निदनीय',
    'निदरना',
    'निदर्श',
    'निदर्शक',
    'निदर्शन',
    'निदर्शनात्मक',
    'निदर्शनी',
    'निदर्शित',
    'निदहन',
    'निदाघ',
    'निदान',
    'निदानकारी',
    'निदानालय',
    'निदिष्ट',
    'निदृष्ट',
    'निदेश',
    'निदेशक',
    'निदेशकों',
    'निदेशन',
    'निदेशनी',
    'निदेशात्मक',
    'निदेशानुसार',
    'निदेशालय',
    'निदेशिका',
    'निदेशित',
    'निदेशिनी',
    'निदेशों',
    'निदेष्टा',
    'निदोर्ष',
    'निदोर्षता',
    'निदोर्षिता',
    'निद्रण',
    'निद्रा',
    'निद्राकारी',
    'निद्राकाल',
    'निद्राटक',
    'निद्राटन',
    'निद्रामग्न',
    'निद्रालु',
    'निद्रावह',
    'निद्राशाला',
    'निद्राशील',
    'निद्राहीन',
    'निद्राहीनता',
    'निद्रित',
    'निद्रिल',
    'निधडक',
    'निधन',
    'निधाता',
    'निधान',
    'निधार्रण',
    'निधि',
    'निधिक',
    'निधिकरण',
    'निधिबद्ध',
    'निधियन',
    'निधियां',
    'निधियों',
    'निधीश्वर',
    'निधोर्ष',
    'निनाद',
    'निनादन',
    'निनादित',
    'निनादिता',
    'निनादी',
    'निनान',
    'निनिर्मित्त',
    'निनिर्मेष',
    'निनौरा',
    'निन्दक',
    'निन्दनीय',
    'निन्दनीयता',
    'निन्दा',
    'निन्दात्मक',
    'निन्दात्मकता',
    'निन्ने',
    'निन्यानब',
    'निन्यों',
    'निन्वय',
    'निपजना',
    'निपट',
    'निपटना',
    'निपटने',
    'निपटा',
    'निपटाए',
    'निपटान',
    'निपटाना',
    'निपटाने',
    'निपटाया',
    'निपटारना',
    'निपटारा',
    'निपटारे',
    'निपटाव',
    'निपटी',
    'निपतन',
    'निपतित',
    'निपात',
    'निपातित',
    'निपान',
    'निपीडन',
    'निपीत',
    'निपुण',
    'निपुणता',
    'निपुती',
    'निपूणता',
    'निपूत',
    'निपूता',
    'निपोरना',
    'निप्पल',
    'निफरन',
    'निब',
    'निबंध',
    'निबंधकार',
    'निबंधन',
    'निबंधनी',
    'निबंधा',
    'निबंधी',
    'निबकौरी',
    'निबटना',
    'निबटाना',
    'निबटाने',
    'निबटारा',
    'निबटाव',
    'निबडना',
    'निबद्घ',
    'निबद्घन',
    'निबद्ध',
    'निबन्ध',
    'निबन्धकार',
    'निबन्धन',
    'निबबाहने',
    'निबर्बुद्धि',
    'निबल',
    'निबाह',
    'निबाहक',
    'निबाहना',
    'निबिडता',
    'निबुआ',
    'निबेडना',
    'निबेडा',
    'निबोध',
    'निबोली',
    'निबौली',
    'निभना',
    'निभाई',
    'निभाना',
    'निभाने',
    'निभाव',
    'निभृत',
    'निभ्रर्म',
    'निभ्रांत',
    'निमंत्रक',
    'निमंत्रण',
    'निमंत्रना',
    'निमंत्रित',
    'निमग्न',
    'निमग्नता',
    'निमग्नशील',
    'निमज्ज',
    'निमज्जन',
    'निमज्जना',
    'निमज्जित',
    'निमटना',
    'निमद',
    'निमन्त्रण',
    'निमस',
    'निमान',
    'निमाना',
    'निमारण',
    'निमार्ण',
    'निमार्ता',
    'निमिज्जका',
    'निमिज्जत',
    'निमित्त',
    'निमित्तता',
    'निमित्तवाची',
    'निमित्तविद',
    'निमित्तेन',
    'निमिर्त',
    'निमिर्तता',
    'निमिर्ति',
    'निमिष',
    'निमिषन',
    'निमिषित',
    'निमीलन',
    'निमीला',
    'निमीलिका',
    'निमीलित',
    'निमीलिततः',
    'निमुँड',
    'निमुँहा',
    'निमेष',
    'निमेषक',
    'निमेषण',
    'निमेषित',
    'निमेषिततः',
    'निमोनिया',
    'निमोर्क',
    'निमोर्चक',
    'निमोर्चन',
    'निमोर्ल',
    'निमोर्ह',
    'निमोर्ही',
    'निमोर्हीपन',
    'निम्न',
    'निम्नकोटि',
    'निम्नग',
    'निम्नगत',
    'निम्नगामी',
    'निम्नतः',
    'निम्नतम',
    'निम्नतर',
    'निम्नमध्यवर्गी',
    'निम्नलिखित',
    'निम्नवर्गीय',
    'निम्नवार',
    'निम्नवित्त',
    'निम्नवित्तता',
    'निम्नस्तर',
    'निम्नस्तरीय',
    'निम्नस्थ',
    'निम्नांकन',
    'निम्नांकित',
    'निम्नायु',
    'निम्नीकरण',
    'निम्रता',
    'निय',
    'नियंता',
    'नियंत्रक',
    'नियंत्रण',
    'नियंत्रणहीन',
    'नियंत्रणहीनता',
    'नियंत्रणात्मक',
    'नियंत्रित',
    'नियंत्रिततः',
    'नियंत्रितता',
    'नियत',
    'नियतकालिक',
    'नियतता',
    'नियतत्ववाद',
    'नियतन',
    'नियतभागी',
    'नियतात्मा',
    'नियतावधि',
    'नियति',
    'नियतिवश',
    'नियतिवाद',
    'नियतिवादता',
    'नियतीय',
    'नियतेंद्रिय',
    'नियत्रंक',
    'नियत्रिंत',
    'नियधीन',
    'नियन',
    'नियन्त्रक',
    'नियन्त्रणीय',
    'नियन्त्रणीयता',
    'नियन्त्रित',
    'नियम',
    'नियमत',
    'नियमतः',
    'नियमन',
    'नियमबद्घ',
    'नियमबद्घता',
    'नियमबद्ध',
    'नियममुक्त',
    'नियमवाद',
    'नियमवादी',
    'नियमविहीन',
    'नियमशः',
    'नियमाधीन',
    'नियमाधीनतः',
    'नियमाधीनतता',
    'नियमानुकूल',
    'नियमानुकूलतः',
    'नियमानुकूलता',
    'नियमानुकूलन',
    'नियमानुसार',
    'नियमानुसारतः',
    'नियमानुसारता',
    'नियमापत्ति',
    'नियमावली',
    'नियमित',
    'नियमिततः',
    'नियमितता',
    'नियमित्र',
    'नियमी',
    'नियमीय',
    'नियमों',
    'नियमोल्लंघक',
    'नियमोल्लंघन',
    'नियर',
    'नियरा',
    'नियराई',
    'नियरे',
    'नियहीन',
    'नियहीनता',
    'नियाज',
    'नियाजमंद',
    'नियामक',
    'नियामत',
    'नियामन',
    'नियामानुकूल',
    'नियारना',
    'नियुक्त',
    'नियुक्ति',
    'नियुत',
    'नियोक्ता',
    'नियोग',
    'नियोगी',
    'नियोजक',
    'नियोजन',
    'नियोजनीय',
    'नियोजनीयता',
    'नियोजित',
    'नियोरिच',
    'निरंक',
    'निरंकारी',
    'निरंकुश',
    'निरंकुशतः',
    'निरंकुशता',
    'निरंकुशतावादी',
    'निरंग',
    'निरंगुल',
    'निरंजन',
    'निरंतर',
    'निरंतरता',
    'निरंतराल',
    'निरंबर',
    'निरंबु',
    'निरंहकार',
    'निरऋति',
    'निरक्षर',
    'निरक्षरता',
    'निरख',
    'निरखना',
    'निरघ',
    'निरजोसी',
    'निरत',
    'निरत्र',
    'निरथन',
    'निरधार',
    'निरधिकार',
    'निरधिकारतः',
    'निरधिकारिता',
    'निरनुग',
    'निरनुमोदन',
    'निरन्तर',
    'निरन्तरता',
    'निरपराध',
    'निरपराधता',
    'निरपराधी',
    'निरपवाद',
    'निरपेक्ष',
    'निरपेक्षक',
    'निरपेक्षता',
    'निरपेक्षतावादी',
    'निरपेक्षवाद',
    'निरपेक्षा',
    'निरपेक्षिक',
    'निरपेक्षीकरण',
    'निरपेखता',
    'निरबंसिया',
    'निरबंसी',
    'निरभिमान',
    'निरभिलाष',
    'निरभिलाषी',
    'निरभ्र',
    'निरमूलना',
    'निरमोही',
    'निरर्गलतः',
    'निरर्थक',
    'निरर्थकता',
    'निरर्थकतापूर्ण',
    'निरर्हता',
    'निरवका',
    'निरवघ',
    'निरवद्यता',
    'निरवधि',
    'निरवयव',
    'निरवलंब',
    'निरवलंबता',
    'निरवशिष्ट',
    'निरवशेष',
    'निरवशेषता',
    'निरवसाद',
    'निरवसादता',
    'निरवार',
    'निरवारना',
    'निरस',
    'निरसक',
    'निरसन',
    'निरसनकर्ता',
    'निरसित',
    'निरस्त',
    'निरस्तराग',
    'निरस्तीकरण',
    'निरस्त्र',
    'निरस्त्रीकरण',
    'निरस्थि',
    'निरा',
    'निरांकाक्षी',
    'निरांडबर',
    'निरांतक',
    'निराई',
    'निराकरण',
    'निराकरणीय',
    'निराकर्ता',
    'निराकांक्षी',
    'निराकार',
    'निराकारता',
    'निराकारी',
    'निराकाश',
    'निराकुल',
    'निराकृत',
    'निराकृति',
    'निराघात',
    'निरातंक',
    'निरातप',
    'निरातपा',
    'निरादर',
    'निरादरण',
    'निरादरपूर्ण',
    'निरादर्य',
    'निरादर्श',
    'निराधार',
    'निराधारता',
    'निरानंद',
    'निरानंदता',
    'निरानन्द',
    'निराना',
    'निरापद',
    'निरापदता',
    'निराभ',
    'निराभता',
    'निरामय',
    'निरामयता',
    'निरामिष',
    'निरामिषता',
    'निरामिषाहारी',
    'निरायत',
    'निरायाम',
    'निरायामता',
    'निरायास',
    'निरायुध',
    'निरार्द्रित',
    'निरालंब',
    'निरालस',
    'निरालस्य',
    'निराला',
    'निरालापन',
    'निरालोक',
    'निरावरण',
    'निरावरणीकरण',
    'निरावासन',
    'निरावासित',
    'निरावृत',
    'निरावृत्त',
    'निराश',
    'निराशा',
    'निराशाजनक',
    'निराशानुभूति',
    'निराशापूर्ण',
    'निराशावश',
    'निराशावाद',
    'निराशावादात्मक',
    'निराशावादी',
    'निराशी',
    'निराश्रय',
    'निराश्रित',
    'निरास',
    'निरासन',
    'निराहार',
    'निरिद्रिय',
    'निरीक्षक',
    'निरीक्षण',
    'निरीक्षणकर्ता',
    'निरीक्षणाधीन',
    'निरीक्षित',
    'निरीश्वर',
    'निरीश्वरवाद',
    'निरीश्वरवादी',
    'निरीह',
    'निरीहतः',
    'निरीहता',
    'निरीहा',
    'निरुत्साह',
    'निरुत्साहित',
    'निरुत्साही',
    'निरुद्ध',
    'निरुप',
    'निरुपण',
    'निरुपांत',
    'निरुप्ण',
    'निरुहण',
    'निरूक्त',
    'निरूक्ति',
    'निरूतर',
    'निरूत्तर',
    'निरूत्तरता',
    'निरूत्तरदायित्व',
    'निरूत्तरदायी',
    'निरूत्तरित',
    'निरूत्साह',
    'निरूत्साहित',
    'निरूत्सुक',
    'निरूदक',
    'निरूद्घ',
    'निरूद्देश्य',
    'निरूद्देश्यतः',
    'निरूद्देश्यता',
    'निरूद्धिग्न',
    'निरूद्यमी',
    'निरूद्विग्न',
    'निरूद्विग्नता',
    'निरूपक',
    'निरूपण',
    'निरूपणात्मक',
    'निरूपद्रव',
    'निरूपना',
    'निरूपम',
    'निरूपमता',
    'निरूपमा',
    'निरूपयोगिता',
    'निरूपयोगी',
    'निरूपाय',
    'निरूपायतः',
    'निरूपायता',
    'निरूपित',
    'निरूप्य',
    'निरेपक्ष',
    'निरोग',
    'निरोगता',
    'निरोचित',
    'निरोध',
    'निरोधक',
    'निरोधगृह',
    'निरोधात्मक',
    'निरोधी',
    'निरोपण',
    'निरोपित',
    'निर्ख',
    'निर्खनामा',
    'निर्खबंदी',
    'निर्गंध',
    'निर्गत',
    'निर्गन्धीकरण',
    'निर्गम',
    'निर्गमन',
    'निर्गमनवाद',
    'निर्गमनशील',
    'निर्गमित',
    'निर्गर्व',
    'निर्गामक',
    'निर्गामी',
    'निर्गुट',
    'निर्गुण',
    'निर्गुणता',
    'निर्ग्रंथ',
    'निर्जजलन',
    'निर्जजलीकरण',
    'निर्जन',
    'निर्जनता',
    'निर्जनीकरण',
    'निर्जनीकृत',
    'निर्जर',
    'निर्जल',
    'निर्जलता',
    'निर्जलीकरण',
    'निर्जलीय',
    'निर्जीव',
    'निर्जीवता',
    'निर्जीववातुकरण',
    'निर्जीववातुकारी',
    'निर्जीवातुकरण',
    'निर्जीवीकरण',
    'निर्झर',
    'निर्झरणशील',
    'निर्झरध्वनि',
    'निर्झरिणी',
    'निर्झरित',
    'निर्झरी',
    'निर्णय',
    'निर्णयक',
    'निर्णयगत',
    'निर्णयन',
    'निर्णयात्मक',
    'निर्णयादेश',
    'निर्णयार्थ',
    'निर्णयों',
    'निर्णायक',
    'निर्णायकता',
    'निर्णायाधीन',
    'निर्णीत',
    'निर्णीतता',
    'निर्णेता',
    'निर्दत',
    'निर्दभ',
    'निर्दय',
    'निर्दयता',
    'निर्दयतापूर्ण',
    'निर्दयतापूर्वक',
    'निर्दयी',
    'निर्दर्लीयता',
    'निर्दलन',
    'निर्दली',
    'निर्दलीय',
    'निर्दशक',
    'निर्दिष्ट',
    'निर्दिष्टता',
    'निर्दुग्ध',
    'निर्देश',
    'निर्देशक',
    'निर्देशन',
    'निर्देशनी',
    'निर्देशांक',
    'निर्देशानुसार',
    'निर्देशिका',
    'निर्देशित',
    'निर्देशों',
    'निर्देश्टा',
    'निर्देश्य',
    'निर्दोष',
    'निर्दोषता',
    'निर्दोषाचरण',
    'निर्द्वद्व',
    'निर्धट',
    'निर्धन',
    'निर्धनता',
    'निर्धनीकरण',
    'निर्धनों',
    'निर्धर्म',
    'निर्धार',
    'निर्धारक',
    'निर्धारण',
    'निर्धारित',
    'निर्धारितता',
    'निर्धार्य',
    'निर्धूम',
    'निर्नाथ',
    'निर्नाथता',
    'निर्बंध',
    'निर्बध',
    'निर्बन्धन',
    'निर्बल',
    'निर्बलता',
    'निर्बाध',
    'निर्बाधता',
    'निर्बाधित',
    'निर्भय',
    'निर्भयता',
    'निर्भर',
    'निर्भरणीय',
    'निर्भरणीयता',
    'निर्भरता',
    'निर्भरताओं',
    'निर्भाव',
    'निर्भीक',
    'निर्भीकता',
    'निर्भृति',
    'निर्मज्ज',
    'निर्मद',
    'निर्मदीकरण',
    'निर्मदीकृत',
    'निर्मम',
    'निर्ममता',
    'निर्ममला',
    'निर्ममलीकरण',
    'निर्मर्जन',
    'निर्मर्याद',
    'निर्मर्यादतः',
    'निर्मल',
    'निर्मलता',
    'निर्माण',
    'निर्माणः',
    'निर्माणक',
    'निर्माणकार्य',
    'निर्माणपूर्व',
    'निर्माणशीलता',
    'निर्माणात्मक',
    'निर्माणाधीन',
    'निर्माणी',
    'निर्माता',
    'निर्माताओं',
    'निर्मान',
    'निर्माल्य',
    'निर्मास',
    'निर्मासता',
    'निर्मित',
    'निर्मितियां',
    'निर्मुक्त',
    'निर्मूल',
    'निर्मूलक',
    'निर्मूलन',
    'निर्मूलित',
    'निर्मेध',
    'निर्मोक',
    'निर्मोचन',
    'निर्मोही',
    'निर्यसन',
    'निर्याण',
    'निर्यात',
    'निर्यातक',
    'निर्यातकर्ता',
    'निर्यातकों',
    'निर्यातन',
    'निर्यातयोग्य',
    'निर्यातित',
    'निर्यातोन्मुख',
    'निर्यास',
    'निर्यासक',
    'निर्यासन',
    'निर्यासित',
    'निर्यूष',
    'निर्लज',
    'निर्लज्ज',
    'निर्लज्जता',
    'निर्लज्जतापूर्वक',
    'निर्लाभ',
    'निर्लुचन',
    'निर्लेप',
    'निर्वचन',
    'निर्वचनीय',
    'निर्वपण',
    'निर्वश',
    'निर्वशता',
    'निर्वसन',
    'निर्वसनन',
    'निर्वस्त्र',
    'निर्वस्त्रण',
    'निर्वहण',
    'निर्वहन',
    'निर्वाकारता',
    'निर्वाचक',
    'निर्वाचन',
    'निर्वाचनीय',
    'निर्वाचिका',
    'निर्वाचित',
    'निर्वाचितता',
    'निर्वाजित',
    'निर्वाण',
    'निर्वात',
    'निर्वातं',
    'निर्वातता',
    'निर्वापन',
    'निर्वारण',
    'निर्वार्य',
    'निर्वास',
    'निर्वासक',
    'निर्वासन',
    'निर्वासान',
    'निर्वासित',
    'निर्वासिता',
    'निर्वाह',
    'निर्वाहक',
    'निर्वाहन',
    'निर्वाहमात्र',
    'निर्वाहित',
    'निर्वाह्य',
    'निर्विभाग',
    'निर्विरोध',
    'निर्विर्शेष',
    'निर्विवाद',
    'निर्विषी',
    'निर्वीज',
    'निर्वीरा',
    'निर्वीर्य',
    'निर्वेग',
    'निर्वेगता',
    'निर्वेद',
    'निर्वेदतः',
    'निर्वेदता',
    'निर्हार',
    'निर्हारित',
    'निर्हारी',
    'निलंब',
    'निलंबन',
    'निलंबित',
    'निलंबी',
    'निलज्ज',
    'निलय',
    'निलिर्प',
    'निलिर्प्त',
    'निलिर्प्तता',
    'निलीन',
    'निलोंर्म',
    'निलोर्भ',
    'निवड़िया',
    'निवदेन',
    'निवर',
    'निवर्तक',
    'निवर्तन',
    'निवर्ती',
    'निवल',
    'निवसन',
    'निवह',
    'निवाई',
    'निवाकर',
    'निवाकरण',
    'निवाड़ा',
    'निवातता',
    'निवान',
    'निवार',
    'निवारक',
    'निवारण',
    'निवारणार्थ',
    'निवारणीय',
    'निवारना',
    'निवारित',
    'निवार्य',
    'निवाला',
    'निवास',
    'निवासन',
    'निवासस्थल',
    'निवासाधिकार',
    'निवासिता',
    'निवासिनी',
    'निवासी',
    'निवास्य',
    'निविड',
    'निविडता',
    'निविदा',
    'निविदाएं',
    'निविदाओं',
    'निविदागत',
    'निविद्ध',
    'निविर्कल्प',
    'निविर्कार',
    'निविर्कारतः',
    'निविर्कारता',
    'निविर्घ्न',
    'निविर्चारी',
    'निविर्न्न',
    'निविर्न्नतः',
    'निविर्न्नता',
    'निविर्रोध',
    'निविर्रोधता',
    'निविर्वाद',
    'निविर्वादता',
    'निविर्वेक',
    'निविर्ष्ण',
    'निविष्ट',
    'निविष्टि',
    'निविष्टियां',
    'निवृति',
    'निवृत्त',
    'निवृत्तात्मा',
    'निवृत्ति',
    'निवृत्तिः',
    'निवृत्तिका',
    'निवेद',
    'निवेदक',
    'निवेदन',
    'निवेदनात्मक',
    'निवेदित',
    'निवेध',
    'निवेश',
    'निवेशक',
    'निवेशकर्ता',
    'निवेशन',
    'निवेशबंदी',
    'निवेशयोग्य',
    'निवेशित',
    'निवेशी',
    'निवेशों',
    'निवेष्टन',
    'निवोर्चक',
    'निव्र्यय',
    'निश',
    'निशब्दता',
    'निशा',
    'निशाँगर',
    'निशांत',
    'निशाकपि',
    'निशाकर',
    'निशाकार',
    'निशाखातिर',
    'निशागृह',
    'निशाचर',
    'निशाचरी',
    'निशाचारी',
    'निशाजल',
    'निशाटक',
    'निशाटन',
    'निशात',
    'निशातता',
    'निशाद',
    'निशादि',
    'निशान',
    'निशानची',
    'निशानदेही',
    'निशानन',
    'निशानवाला',
    'निशाना',
    'निशानाथ',
    'निशानी',
    'निशाने',
    'निशानेबाज',
    'निशानेबाजी',
    'निशानेबाज़',
    'निशान्ध',
    'निशापुत्र',
    'निशाभीति',
    'निशामृग',
    'निशावान',
    'निशास्ता',
    'निशि',
    'निशिकर',
    'निशिचर',
    'निशित',
    'निशितता',
    'निशिनाथ',
    'निशिपाल',
    'निशी',
    'निशीथ',
    'निशीथिनी',
    'निशीश',
    'निशुंभ',
    'निशुंभन',
    'निश्चन',
    'निश्चय',
    'निश्चयतः',
    'निश्चयन',
    'निश्चयपूर्वक',
    'निश्चयपूवर्क',
    'निश्चयमात्रक',
    'निश्चयमात्रा',
    'निश्चयवाचक',
    'निश्चयहीन',
    'निश्चयात्मक',
    'निश्चयात्मकता',
    'निश्चयी',
    'निश्चल',
    'निश्चलता',
    'निश्चलन',
    'निश्चायक',
    'निश्चायकता',
    'निश्चावन',
    'निश्चित',
    'निश्चिततः',
    'निश्चितता',
    'निश्चिन्त',
    'निश्चिन्तता',
    'निश्चेतक',
    'निश्चेतन',
    'निश्चेतनता',
    'निश्चेष्ट',
    'निश्चेष्टकरण',
    'निश्चेष्टता',
    'निश्छल',
    'निश्छलता',
    'निश्छेद',
    'निश्वास',
    'निश्शंक',
    'निश्शंकतः',
    'निश्शंकता',
    'निश्शत्रु',
    'निश्शत्रुता',
    'निश्शब्द',
    'निश्शब्दता',
    'निश्शम',
    'निश्शल्य',
    'निश्शस्त्र',
    'निश्शस्त्रीकरण',
    'निश्शस्त्रीकृत',
    'निश्शील',
    'निश्शीलता',
    'निश्शुक्र',
    'निश्शुल्क',
    'निश्शुल्कता',
    'निश्शेष',
    'निश्शोकता',
    'निश्श्रीक',
    'निषंग',
    'निषण्ण',
    'निषद',
    'निषदन',
    'निषाद',
    'निषादी',
    'निषिद्घ',
    'निषिद्ध',
    'निषूदन',
    'निषेचक',
    'निषेचन',
    'निषेध',
    'निषेधक',
    'निषेधवाद',
    'निषेधहीन',
    'निषेधहीनता',
    'निषेधाज्ञा',
    'निषेधात्मक',
    'निषेधात्मकता',
    'निषेधाधिकार',
    'निषेधी',
    'निषेवा',
    'निष्कंटक',
    'निष्कपट',
    'निष्कपटता',
    'निष्कर',
    'निष्कर्म',
    'निष्कर्ष',
    'निष्कर्षक',
    'निष्कर्षण',
    'निष्कर्षणीय',
    'निष्कर्षित',
    'निष्कल',
    'निष्कलंक',
    'निष्कलंकता',
    'निष्कलन',
    'निष्कलुष',
    'निष्कलुषता',
    'निष्कवाथ',
    'निष्काम',
    'निष्कामतः',
    'निष्कामना',
    'निष्कारण',
    'निष्कालक',
    'निष्कालन',
    'निष्कालिक',
    'निष्कालित',
    'निष्कासक',
    'निष्कासन',
    'निष्कासित',
    'निष्कासिता',
    'निष्कीटक',
    'निष्कुलीन',
    'निष्कूट',
    'निष्कृत',
    'निष्कृति',
    'निष्कृष्ट',
    'निष्क्रम',
    'निष्क्रमण',
    'निष्क्रमणशील',
    'निष्क्रमित',
    'निष्क्रय',
    'निष्क्रांत',
    'निष्क्रान्त',
    'निष्क्रामक',
    'निष्क्रामण',
    'निष्क्रामित',
    'निष्क्रिय',
    'निष्क्रियण',
    'निष्क्रियता',
    'निष्क्वाथन',
    'निष्चेष्टीकरण',
    'निष्टि',
    'निष्ठ',
    'निष्ठतः',
    'निष्ठता',
    'निष्ठा',
    'निष्ठापूर्वक',
    'निष्ठालु',
    'निष्ठावान',
    'निष्ठावानता',
    'निष्ठाहीन',
    'निष्ठाहीनता',
    'निष्ठित',
    'निष्ठीव',
    'निष्ठुर',
    'निष्ठुरता',
    'निष्ठुरतापूर्वक',
    'निष्णात',
    'निष्पंक',
    'निष्पक्व',
    'निष्पक्ष',
    'निष्पक्षता',
    'निष्पताप',
    'निष्पति',
    'निष्पत्ति',
    'निष्पत्र',
    'निष्पत्रक',
    'निष्पत्रण',
    'निष्पन्न',
    'निष्परतिभ',
    'निष्परभ',
    'निष्परभता',
    'निष्परभाव',
    'निष्परभावन',
    'निष्परयोजन',
    'निष्परयोजनतः',
    'निष्परयोजनता',
    'निष्पराण',
    'निष्परिग्रह',
    'निष्पादक',
    'निष्पादन',
    'निष्पादनकर्ता',
    'निष्पादित',
    'निष्पाद्य',
    'निष्पाद्यता',
    'निष्पाप',
    'निष्पापता',
    'निष्पाव',
    'निष्पावी',
    'निष्पीडक',
    'निष्पीडन',
    'निष्पीड़न',
    'निष्प्क्षता',
    'निष्प्रभाव',
    'निष्प्रभावक',
    'निष्प्रभावन',
    'निष्प्रभावित',
    'निष्प्रभावी',
    'निष्प्रयोजन',
    'निष्प्रयोज्य',
    'निष्प्राणता',
    'निष्फल',
    'निष्फलता',
    'निष्फला',
    'निसरन',
    'निसराना',
    'निसर्ग',
    'निसर्गायु',
    'निसाँस',
    'निसार',
    'निसारा',
    'निसारी',
    'निसि',
    'निसिचर',
    'निसीठी',
    'निसुंद',
    'निसूदन',
    'निसृत',
    'निसैनी',
    'निस्तंद्र',
    'निस्तब्ध',
    'निस्तब्धता',
    'निस्तर',
    'निस्तरण',
    'निस्तरना',
    'निस्तल',
    'निस्तार',
    'निस्तारक',
    'निस्तारण',
    'निस्तारना',
    'निस्तारित',
    'निस्तीर्ण',
    'निस्तीर्णता',
    'निस्तुष',
    'निस्तेज',
    'निस्तेजता',
    'निस्तेजा',
    'निस्पंद',
    'निस्पंदक',
    'निस्पंदता',
    'निस्पंदन',
    'निस्पन्द',
    'निस्पृही',
    'निस्फ',
    'निस्फी',
    'निस्बत',
    'निस्बतन',
    'निस्यंदक',
    'निस्यंदी',
    'निस्यन्द',
    'निस्यन्दन',
    'निस्वती',
    'निस्वेद',
    'निस्संकोच',
    'निस्संख्य',
    'निस्संग',
    'निस्संज्ञित',
    'निस्संज्ञीकरण',
    'निस्संतान',
    'निस्संतानता',
    'निस्संदेह',
    'निस्संधि',
    'निस्सज्ञ',
    'निस्सज्ञता',
    'निस्सत्व',
    'निस्सपल',
    'निस्सरण',
    'निस्सहाय',
    'निस्सादन',
    'निस्सादित',
    'निस्सार',
    'निस्सारक',
    'निस्सारण',
    'निस्सारित्र',
    'निस्सारी',
    'निस्साहस',
    'निस्सीम',
    'निस्सीमता',
    'निस्स्पृह',
    'निस्स्वार्थ',
    'निस्स्वार्थता',
    'निहंग',
    'निहंता',
    'निहठा',
    'निहत',
    'निहत्था',
    'निहल',
    'निहाई',
    'निहाका',
    'निहायत',
    'निहार',
    'निहारना',
    'निहारिका',
    'निहाल',
    'निहालचा',
    'निहाली',
    'निहित',
    'निहिलिज्म',
    'निहेंतु',
    'निहोरा',
    'निहोरे',
    'निीवाली',
    'निेयोग',
    'निज़ी',
    'नी',
    'नींद',
    'नींदरी',
    'नींब',
    'नींबू',
    'नींव',
    'नीक',
    'नीका',
    'नीकाश',
    'नीके',
    'नीग्रो',
    'नीघर',
    'नीच',
    'नीचकर्मी',
    'नीचग',
    'नीचता',
    'नीचतापूर्ण',
    'नीचस्थ',
    'नीचा',
    'नीचाई',
    'नीचाशय',
    'नीची',
    'नीचू',
    'नीचे',
    'नीचेः',
    'नीड',
    'नीडज',
    'नीडजा',
    'नीडल',
    'नीड़',
    'नीत',
    'नीति',
    'नीतिः',
    'नीतिकुशल',
    'नीतिकौशल',
    'नीतिगत',
    'नीतिगामी',
    'नीतिज्ञ',
    'नीतिज्ञता',
    'नीतिद्रोह',
    'नीतिनिपक्षता',
    'नीतिनिपुण',
    'नीतिनिरपेक्ष',
    'नीतिपरक',
    'नीतिमान',
    'नीतिय',
    'नीतियां',
    'नीतियुक्त',
    'नीतियुक्तता',
    'नीतियों',
    'नीतिवचन',
    'नीतिवाद',
    'नीतिवादात्मक',
    'नीतिवादी',
    'नीतिवान',
    'नीतिशास्त्र',
    'नीतिसंगत',
    'नीतिहीनता',
    'नीबू',
    'नीम',
    'नीमःफल',
    'नीमआस्तीन',
    'नीमचा',
    'नीमटर',
    'नीमस्तीन',
    'नीमहकीमी',
    'नीमा',
    'नीमास्तीन',
    'नीयत',
    'नीर',
    'नीरज',
    'नीरजी',
    'नीरद',
    'नीरधि',
    'नीरपति',
    'नीरम',
    'नीरव',
    'नीरवता',
    'नीरस',
    'नीरसता',
    'नीरसतापूर्वक',
    'नीरा',
    'नीराजन',
    'नीराजना',
    'नीरिद',
    'नीरोग',
    'नीरोगकर',
    'नीरोगिता',
    'नील',
    'नीलंगु',
    'नीलकंठ',
    'नीलकांत',
    'नीलगिरि',
    'नीलगिरी',
    'नीलग्रीव',
    'नीलज',
    'नीलतरु',
    'नीलपिच्छ',
    'नीलपीत',
    'नीलम',
    'नीललोह',
    'नीलसेतु',
    'नीला',
    'नीलांबर',
    'नीलांबुज',
    'नीलाकाश',
    'नीलाचल',
    'नीलातिरिकत',
    'नीलापन',
    'नीलाभ',
    'नीलाभत्ता',
    'नीलाम',
    'नीलामकर्ता',
    'नीलामी',
    'नीलारुण',
    'नीलिमा',
    'नीली',
    'नीलोत्पल',
    'नीलोपल',
    'नीलोफर',
    'नीलोफ़र',
    'नीवँ',
    'नीवर',
    'नीवाड',
    'नीवी',
    'नीवीबंध',
    'नीव्र',
    'नीशार',
    'नीसू',
    'नीहार',
    'नीहारिका',
    'नुकता',
    'नुकर',
    'नुकसान',
    'नुकसानदायक',
    'नुकसानदेह',
    'नुकसानी',
    'नुका',
    'नुकीला',
    'नुकीलापन',
    'नुकीली',
    'नुकीले',
    'नुक्कड',
    'नुक्कड़',
    'नुक्का',
    'नुक्ताचीनी',
    'नुक्स',
    'नुक्सानी',
    'नुगदी',
    'नुचा',
    'नुचाँई',
    'नुचाई',
    'नुति',
    'नुत्फा',
    'नुनखारा',
    'नुपूर',
    'नुमा',
    'नुमाइश',
    'नुमाइशगाह',
    'नुमाइशी',
    'नुमाईंदगी',
    'नुमाईंदा',
    'नुमाईश',
    'नुमायशी',
    'नुमायाँ',
    'नुसखा',
    'नुस्खा',
    'नुस्खे',
    'नुक़सानी',
    'नूतन',
    'नूतनता',
    'नून',
    'नूपुर',
    'नूर',
    'नूरबाफ',
    'नूरा',
    'नूरे',
    'नूह',
    'नूहः',
    'नृकेसरी',
    'नृजाति',
    'नृजातिविषयक',
    'नृतक',
    'नृति',
    'नृतित्त्वकी',
    'नृतु',
    'नृत्य',
    'नृत्यकला',
    'नृत्यगृह',
    'नृत्यांगना',
    'नृत्याभिनय',
    'नृदेव',
    'नृप',
    'नृपचिह्न',
    'नृपछत्र',
    'नृपति',
    'नृपनीति',
    'नृपलिग',
    'नृपावत्तर्',
    'नृपासन',
    'नृबल',
    'नृमेध',
    'नृरूपता',
    'नृवंश',
    'नृवराह',
    'नृवानर',
    'नृविज्ञान',
    'नृविज्ञानी',
    'नृवैज्ञानिक',
    'नृशंस',
    'नृशंसता',
    'नृशंसतापूर्ण',
    'नृशस',
    'नृसिंह',
    'नृहत्या',
    'नृहरि',
    'ने',
    'नेक',
    'नेकअंदेश',
    'नेकचलन',
    'नेकचलनी',
    'नेकटाई',
    'नेकतरीन',
    'नेकदिल',
    'नेकनाम',
    'नेकनामी',
    'नेकनीयत',
    'नेकनीयती',
    'नेकबख्त',
    'नेकबख्ती',
    'नेकमिजाज',
    'नेकसीरत',
    'नेकी',
    'नेग',
    'नेगम',
    'नेगमिक',
    'नेगलिजी',
    'नेगेटिव',
    'नेगोशिएशन',
    'नेचक्योर',
    'नेचरोपैथ',
    'नेजक',
    'नेजन',
    'नेजा',
    'नेटवर्क',
    'नेटा',
    'नेटि',
    'नेत',
    'नेतर',
    'नेता',
    'नेतागिरी',
    'नेतानुगामी',
    'नेतायुक्त',
    'नेताहीन',
    'नेति',
    'नेती',
    'नेतृत्व',
    'नेतृत्वहीन',
    'नेत्र',
    'नेत्रक',
    'नेत्रकोटर',
    'नेत्रचिकित्सक',
    'नेत्रच्छद',
    'नेत्रच्छदीय',
    'नेत्रदान',
    'नेत्रपटलदर्शी',
    'नेत्रप्रदाह',
    'नेत्रयोनि',
    'नेत्ररक्षक',
    'नेत्ररोग',
    'नेत्रविज्ञान',
    'नेत्रविज्ञानी',
    'नेत्रशोथ',
    'नेत्रस्पंद',
    'नेत्रहीनता',
    'नेत्रांजन',
    'नेत्रांजित',
    'नेत्रांबु',
    'नेत्रांशक',
    'नेत्रिका',
    'नेत्रीय',
    'नेत्रों',
    'नेदावर',
    'नेदिष्ठ',
    'नेदिष्ठी',
    'नेनुआ',
    'नेपथ्य',
    'नेपाल',
    'नेपालक',
    'नेपाली',
    'नेपोलियन',
    'नेफ़ा',
    'नेम',
    'नेमत',
    'नेमि',
    'नेमिनाथ',
    'नेमी',
    'नेल',
    'नेवज',
    'नेवतना',
    'नेवतहरा',
    'नेवतहरी',
    'नेवता',
    'नेवला',
    'नेवारी',
    'नेवी',
    'नेवीगेटर',
    'नेशंस',
    'नेशन',
    'नेशनल',
    'नेशनललाइजेशन',
    'नेशनलाइज्ड',
    'नेशनहुड',
    'नेशनैलिटी',
    'नेस्ट',
    'नेस्तनाबूद',
    'नेस्तनाबूदी',
    'नेस्ती',
    'नेह',
    'नेहनमन',
    'नेहरु',
    'नेहरू',
    'नेहा',
    'नेही',
    'नैंकनापन',
    'नैंबुत्सु',
    'नैऋत',
    'नैक',
    'नैकटय',
    'नैकटाई',
    'नैकटिक',
    'नैकलेस',
    'नैकवीअर',
    'नैकृतिक',
    'नैखिल',
    'नैचा',
    'नैट',
    'नैटवर्क',
    'नैतिक',
    'नैतिकता',
    'नैतिकतापूर्ण',
    'नैत्य',
    'नैत्यता',
    'नैत्यिक',
    'नैत्रिकी',
    'नैदाघ',
    'नैदाघीय',
    'नैदानिक',
    'नैन',
    'नैना',
    'नैनीताल',
    'नैपकिन',
    'नैप्थलीन',
    'नैब्युला',
    'नैमित्तिक',
    'नैमिष',
    'नैयतिक',
    'नैया',
    'नैयायिक',
    'नैराश्य',
    'नैरेटर',
    'नैरो',
    'नैर्ऋत',
    'नैर्ऋती',
    'नैवल',
    'नैविगेबल',
    'नैविगेबिलिटी',
    'नैविद्या',
    'नैवी',
    'नैवेद्य',
    'नैश',
    'नैशनल',
    'नैशिक',
    'नैष्ठिक',
    'नैष्ठिकता',
    'नैसगिर्क',
    'नैसर्गिक',
    'नैहर',
    'नोंक',
    'नोः',
    'नोआ',
    'नोआह',
    'नोइनी',
    'नोई',
    'नोएडा',
    'नोएद',
    'नोक',
    'नोकः',
    'नोकझोंक',
    'नोकदार',
    'नोकदारी',
    'नोकपान',
    'नोकहीन',
    'नोकाझोंकी',
    'नोकाभ',
    'नोकीला',
    'नोकीलापन',
    'नोच',
    'नोचना',
    'नोचने',
    'नोचा',
    'नोचाई',
    'नोचानोची',
    'नोज',
    'नोजल',
    'नोट',
    'नोटरी',
    'नोटानी',
    'नोटिंग',
    'नोटिस',
    'नोटेबल',
    'नोटेशन',
    'नोटों',
    'नोडयूल',
    'नोदक',
    'नोदन',
    'नोदयिता',
    'नोदित',
    'नोदित्र',
    'नोदी',
    'नोन',
    'नोनिया',
    'नोनी',
    'नोवा',
    'नोवेला',
    'नोहनी',
    'नोहा',
    'नोज़र',
    'नौ',
    'नौंवा',
    'नौइयत',
    'नौक',
    'नौकर',
    'नौकरशाह',
    'नौकरशाही',
    'नौकराना',
    'नौकरानी',
    'नौकरी',
    'नौकरीपेशा',
    'नौकर्ण',
    'नौकर्णधार',
    'नौकर्णी',
    'नौकर्म',
    'नौकर्मि',
    'नौकर्षण',
    'नौका',
    'नौकाः',
    'नौकाकार',
    'नौकागीत',
    'नौकाग्र',
    'नौकाय',
    'नौकायन',
    'नौकावतारण',
    'नौकियान',
    'नौकीय',
    'नौकोदर',
    'नौगमन',
    'नौगम्य',
    'नौगम्यता',
    'नौगही',
    'नौग्रही',
    'नौघरा',
    'नौघाट',
    'नौचर',
    'नौचालक',
    'नौचालन',
    'नौची',
    'नौचोर',
    'नौजवान',
    'नौजवानी',
    'नौजा',
    'नौजीविक',
    'नौट',
    'नौटंकी',
    'नौटः',
    'नौटांक',
    'नौतल',
    'नौतार्य',
    'नौतार्यता',
    'नौदंड',
    'नौदस्यु',
    'नौन',
    'नौनेता',
    'नौपरिवहन',
    'नौब',
    'नौबंध',
    'नौबढिया',
    'नौबत',
    'नौबतनवाज',
    'नौबती',
    'नौभरण',
    'नौभार',
    'नौमी',
    'नौयात्रा',
    'नौयात्री',
    'नौरंगी',
    'नौरतन',
    'नौरते',
    'नौरात्रे',
    'नौरोज',
    'नौलखा',
    'नौलिज',
    'नौली',
    'नौवहन',
    'नौवाह',
    'नौविद्या',
    'नौविहार',
    'नौवीं',
    'नौशा',
    'नौशास्त्र',
    'नौशिक्त',
    'नौशी',
    'नौसंचालन',
    'नौसत',
    'नौसादर',
    'नौसिखिया',
    'नौसिखियां',
    'नौसिखियापन',
    'नौसेना',
    'नौसेनाध्यक्ष',
    'नौसेनापति',
    'नौसेवा',
    'नौसैनिक',
    'न्यंचित',
    'न्यग्रोध',
    'न्यर्बुद',
    'न्यसन',
    'न्यस्त',
    'न्या',
    'न्यागमन',
    'न्याना',
    'न्याय',
    'न्यायकरण',
    'न्यायकर्ता',
    'न्यायघर',
    'न्यायतः',
    'न्यायधिकरण',
    'न्यायधीश',
    'न्यायनिर्णयन',
    'न्यायनिर्णायक',
    'न्यायनिर्णीत',
    'न्यायपति',
    'न्यायपर',
    'न्यायपालिका',
    'न्यायपीठ',
    'न्यायपूर्ण',
    'न्यायपूर्णता',
    'न्यायपूर्वक',
    'न्यायप्रिय',
    'न्यायमूर्ति',
    'न्याययुक्त',
    'न्याययुक्तता',
    'न्यायवाक्य',
    'न्यायवादी',
    'न्यायवाहक',
    'न्यायशास्त्र',
    'न्यायशास्त्री',
    'न्यायशील',
    'न्यायशीलता',
    'न्यायसंगत',
    'न्यायसम्मत',
    'न्यायहीन',
    'न्यायहीनता',
    'न्यायाकांक्षी',
    'न्यायाज्ञा',
    'न्यायाधिकरण',
    'न्यायाधिकारी',
    'न्यायाधीन',
    'न्यायाधीय',
    'न्यायाधीश',
    'न्यायानुकूल',
    'न्यायालय',
    'न्यायालयिक',
    'न्यायासन',
    'न्यायिक',
    'न्यायिकेतर',
    'न्यायी',
    'न्यायेत्तर',
    'न्यायोचित',
    'न्यारना',
    'न्यारा',
    'न्यारिया',
    'न्यारे',
    'न्यास',
    'न्यासधारिता',
    'न्यासधारी',
    'न्यासभंग',
    'न्यासिता',
    'न्यासी',
    'न्यासीकरण',
    'न्याहीनता',
    'न्युब्ज',
    'न्युब्जता',
    'न्यू',
    'न्यूज',
    'न्यूजपेपर',
    'न्यूजबॉय',
    'न्यूजमैन',
    'न्यूजरील',
    'न्यूटेस्टामंट',
    'न्यूट्रलाइजर',
    'न्यूट्रोएन',
    'न्यूट्रोन',
    'न्यून',
    'न्यूनकरण',
    'न्यूनतम',
    'न्यूनता',
    'न्यूनतापी',
    'न्यूनमनस्क',
    'न्यूनांश',
    'न्यूनांशतः',
    'न्यूनांशीय',
    'न्यूनाथ्ज्ञर्क',
    'न्यूनानुमान',
    'न्यूनाभिदत्त',
    'न्यूनीकरण',
    'न्यूनीकृत',
    'न्यूनोक्ति',
    'न्यूनोत्पादन',
    'न्यूमटिक',
    'न्यूमरोलाजी',
    'न्यूयार्क',
    'न्यूरोसिस',
    'न्यूज़ीलैण्ड',
    'न्योछावर',
    'न्योजी',
    'न्योतना',
    'न्योतहरा',
    'न्योतहरी',
    'न्योता',
    'न्यौछावर',
    'न्यौरते',
    'न्हाना',
    'नक़द',
    'नक़दी',
    'नक़ल',
    'नज़दीकी',
    'नज़र',
    'नज़रबंद',
    'नज़रबंदी',
    'नज़ाकत',
    'नफ़रत',
    'प',
    'पँखड़ी',
    'पँजेरा',
    'पँतीजना',
    'पँवरना',
    'पँवरिया',
    'पँवाडा',
    'पँसरहट्टा',
    'पँसुआ',
    'पँसुली',
    'पंक',
    'पंककीर',
    'पंकक्रीड',
    'पंकज',
    'पंकजिनी',
    'पंकहीन',
    'पंकाति',
    'पंकिल',
    'पंकिलता',
    'पंक्चर',
    'पंक्चरिंग',
    'पंक्चुशन',
    'पंक्ति',
    'पंक्तिक',
    'पंक्तिबद्घ',
    'पंक्तिबद्ध',
    'पंक्तिबद्धता',
    'पंक्तियां',
    'पंक्तियों',
    'पंख',
    'पंखडी',
    'पंखदार',
    'पंखनाल',
    'पंखयुक्त',
    'पंखरी',
    'पंखहीन',
    'पंखा',
    'पंखिनी',
    'पंखिया',
    'पंखी',
    'पंखीय',
    'पंखुड़ियों',
    'पंखुरिया',
    'पंखे',
    'पंखेरु',
    'पंखों',
    'पंखोंवाला',
    'पंखोदृभव',
    'पंखोद्भूत',
    'पंग',
    'पंगत',
    'पंगव',
    'पंगा',
    'पंगु',
    'पंगुता',
    'पंगुला',
    'पंच',
    'पंचक',
    'पंचककार',
    'पंचकन्या',
    'पंचकोण',
    'पंचकोणी',
    'पंचकोश',
    'पंचगत',
    'पंचगुण',
    'पंचगुना',
    'पंचगुप्त',
    'पंचग्रन्थ',
    'पंचग्रह',
    'पंचचरण',
    'पंचचोल',
    'पंचजन',
    'पंचज्ञान',
    'पंचतत्त्वीय',
    'पंचतत्व',
    'पंचतन्त्री',
    'पंचतन्मात्र',
    'पंचतय',
    'पंचतान',
    'पंचत्व',
    'पंचदर्शी',
    'पंचदश',
    'पंचदेव',
    'पंचदेवता',
    'पंचनख',
    'पंचनामा',
    'पंचनिर्णय',
    'पंचपतित्व',
    'पंचपदी',
    'पंचपिता',
    'पंचप्राण',
    'पंचबाहु',
    'पंचभर्तारी',
    'पंचभुज',
    'पंचभूत',
    'पंचम',
    'पंचमक',
    'पंचमी',
    'पंचमुख',
    'पंचमेल',
    'पंचयज्ञ',
    'पंचर',
    'पंचलक्षण',
    'पंचवट',
    'पंचवर्ण',
    'पंचवर्षीय',
    'पंचवायु',
    'पंचवार्षिक',
    'पंचवार्षिकी',
    'पंचविंशति',
    'पंचशर',
    'पंचशासन',
    'पंचशील',
    'पंचसंयोजक',
    'पंचांग',
    'पंचांगुल',
    'पंचांगुलि',
    'पंचाक्षर',
    'पंचाट',
    'पंचात्मक',
    'पंचात्मा',
    'पंचानन',
    'पंचाननी',
    'पंचामृत',
    'पंचायत',
    'पंचायती',
    'पंचाल',
    'पंचाली',
    'पंचाशत',
    'पंचफ़लक',
    'पंछा',
    'पंज',
    'पंजतन',
    'पंजतनसूची',
    'पंजम',
    'पंजर',
    'पंजराखेट',
    'पंजरी',
    'पंजशंबह',
    'पंजा',
    'पंजाई',
    'पंजाकार',
    'पंजाब',
    'पंजाबी',
    'पंजाल',
    'पंजिका',
    'पंजियारा',
    'पंजी',
    'पंजीकरण',
    'पंजीकार',
    'पंजीकृत',
    'पंजीक्रम',
    'पंजीपाल',
    'पंजीयक',
    'पंजीयन',
    'पंजुम',
    'पंजे',
    'पंजों',
    'पंडा',
    'पंडाइन',
    'पंडाल',
    'पंडित',
    'पंडितजी',
    'पंडिता',
    'पंडिताइन',
    'पंडिताई',
    'पंडिताऊ',
    'पंडितानी',
    'पंडित्यपूर्ण',
    'पंडुक',
    'पंडुकी',
    'पंढरपुर',
    'पंत',
    'पंत्रि',
    'पंथ',
    'पंथनिरपेक्ष',
    'पंथवाद',
    'पंथान',
    'पंथिक',
    'पंथी',
    'पंदरह',
    'पंद्ररोह',
    'पंद्रह',
    'पंद्रहवां',
    'पंप',
    'पंपा',
    'पंपासर',
    'पंपिंग',
    'पंयत',
    'पंलग',
    'पंसद',
    'पंसदगी',
    'पंसारी',
    'पंसाल',
    'पंसेरी',
    'पइन',
    'पइर',
    'पउम',
    'पकड',
    'पकडना',
    'पकड़',
    'पकड़न',
    'पकड़ना',
    'पकड़ने',
    'पकड़नेवाला',
    'पकड़ा',
    'पकड़ाई',
    'पकड़ाना',
    'पकड़े',
    'पकडाइर',
    'पकडाई',
    'पकन',
    'पकना',
    'पकवान',
    'पका',
    'पकाँलेटर',
    'पकाई',
    'पकाना',
    'पकाने',
    'पकापन',
    'पकाव',
    'पकिंचन',
    'पकॉलेशन',
    'पकोर्लेटर',
    'पकौड़ा',
    'पकौड़ी',
    'पकौडा',
    'पकौडी',
    'पक्का',
    'पक्कापन',
    'पक्की',
    'पक्कीपन',
    'पक्के',
    'पक्र्स',
    'पक्व',
    'पक्वता',
    'पक्वन',
    'पक्वात्र',
    'पक्वावस्था',
    'पक्ष',
    'पक्षः',
    'पक्षकार',
    'पक्षज',
    'पक्षत्याग',
    'पक्षत्यागी',
    'पक्षदंड',
    'पक्षदार',
    'पक्षद्वय',
    'पक्षद्वार',
    'पक्षधर',
    'पक्षधरता',
    'पक्षनाडी',
    'पक्षनिरपेक्ष',
    'पक्षपात',
    'पक्षपातपूर्ण',
    'पक्षपातपूर्णता',
    'पक्षपातरहित',
    'पक्षपातहीन',
    'पक्षपातहीनता',
    'पक्षपाती',
    'पक्षयुक्त',
    'पक्षवक्ता',
    'पक्षवाला',
    'पक्षसमर्थन',
    'पक्षसार',
    'पक्षहीन',
    'पक्षहीनता',
    'पक्षांत',
    'पक्षांतर',
    'पक्षाकार',
    'पक्षाग्रह',
    'पक्षाघात',
    'पक्षापक्ष',
    'पक्षापात',
    'पक्षिणी',
    'पक्षियों',
    'पक्षी',
    'पक्षीय',
    'पक्षीयता',
    'पक्षीराज',
    'पक्षीविज्ञान',
    'पक्षीविज्ञानी',
    'पक्षीवैज्ञानिक',
    'पक्षीशाला',
    'पक्षीश्वर',
    'पक्षों',
    'पक्ष्',
    'पक्ष्ज्ञी',
    'पक्ष्य',
    'पख',
    'पखडी',
    'पखन',
    'पखरु',
    'पखवाई',
    'पखवाड़ा',
    'पखवाडा',
    'पखवारा',
    'पखान',
    'पखार',
    'पखारना',
    'पखारा',
    'पखाल',
    'पखाली',
    'पखावज',
    'पखावजी',
    'पखिया',
    'पखुवा',
    'पखेरु',
    'पखौरा',
    'पग',
    'पगंडडी',
    'पगचिह्न',
    'पगडंडी',
    'पगडण्डी',
    'पगड़ी',
    'पगडी',
    'पगण्डिका',
    'पगतरी',
    'पगना',
    'पगनियां',
    'पगला',
    'पगलाना',
    'पगलैट',
    'पगहा',
    'पगा',
    'पगाई',
    'पगाना',
    'पगार',
    'पगियाना',
    'पगुराना',
    'पगोडा',
    'पग्गड',
    'पच',
    'पचखा',
    'पचगुना',
    'पचड़ा',
    'पचन',
    'पचना',
    'पचनिका',
    'पचनीय',
    'पचने',
    'पचपच',
    'पचपहला',
    'पचपहलू',
    'पचपाश्र्वीय',
    'पचमेल',
    'पचर',
    'पचा',
    'पचाई',
    'पचाना',
    'पचारना',
    'पचास',
    'पचित',
    'पचीस',
    'पचीसी',
    'पचेलिम',
    'पचौर',
    'पचौली',
    'पच्चकारी',
    'पच्चड',
    'पच्चड़',
    'पच्चर',
    'पच्ची',
    'पच्चीकार',
    'पच्चीकारी',
    'पच्चीकृत',
    'पच्चीस',
    'पच्चीसी',
    'पच्चू',
    'पच्छिम',
    'पच्दम',
    'पच्दमी',
    'पच्य',
    'पछ',
    'पछंड',
    'पछडना',
    'पछड़ना',
    'पछताना',
    'पछतावा',
    'पछना',
    'पछवत',
    'पछवाँ',
    'पछाँह',
    'पछाँहिया',
    'पछाँही',
    'पछाड',
    'पछाडना',
    'पछाड़',
    'पछाड़ना',
    'पछाडी',
    'पछारना',
    'पछाहियाँ',
    'पछाहीं',
    'पछियाना',
    'पछीत',
    'पछुआ',
    'पछुआई',
    'पछुवाई',
    'पछेती',
    'पछेतीपन',
    'पछोड',
    'पजमिया',
    'पज़ल',
    'पजावा',
    'पट',
    'पटंबर',
    'पटक',
    'पटकथा',
    'पटकन',
    'पटकना',
    'पटकनिया',
    'पटकनी',
    'पटका',
    'पटकार',
    'पटकी',
    'पटक्षेप',
    'पटटी',
    'पटतरना',
    'पटतारना',
    'पटनम',
    'पटना',
    'पटनी',
    'पटपट',
    'पटपटाना',
    'पटपटी',
    'पटपड',
    'पटबाजी',
    'पटबीजना',
    'पटमात्रता',
    'पटर',
    'पटरस',
    'पटरा',
    'पटरानी',
    'पटरी',
    'पटरीसाज़',
    'पटल',
    'पटलक',
    'पटलन',
    'पटलयुक्त',
    'पटला',
    'पटलित',
    'पटली',
    'पटलीकरण',
    'पटवारी',
    'पटवेश्म',
    'पटशायी',
    'पटसन',
    'पटह',
    'पटा',
    'पटांबर',
    'पटाई',
    'पटाक',
    'पटाका',
    'पटाक्षेप',
    'पटाखा',
    'पटाना',
    'पटापट',
    'पटाफेर',
    'पटार',
    'पटाव',
    'पटावट',
    'पटावदार',
    'पटावरण',
    'पटिया',
    'पटियाला',
    'पटी',
    'पटीय',
    'पटीर',
    'पटील',
    'पटीलना',
    'पटु',
    'पटुआ',
    'पटुका',
    'पटुता',
    'पटूका',
    'पटेल',
    'पटेला',
    'पटैटो',
    'पटैला',
    'पटोर',
    'पटोरी',
    'पटोल',
    'पटोलक',
    'पटोलिका',
    'पटोली',
    'पटोहाँ',
    'पटौनी',
    'पट्ट',
    'पट्टक',
    'पट्टज',
    'पट्टण',
    'पट्टन',
    'पट्टमहिषी',
    'पट्टा',
    'पट्टाकर्ता',
    'पट्टादायी',
    'पट्टाधारिता',
    'पट्टाधारी',
    'पट्टाधृति',
    'पट्टाफेर',
    'पट्टाभूमि',
    'पट्टिका',
    'पट्टित',
    'पट्टिश',
    'पट्टी',
    'पट्टीदार',
    'पट्टीदारी',
    'पट्टीेदार',
    'पट्टीेदारी',
    'पट्टू',
    'पट्टे',
    'पट्टेदार',
    'पट्टेदारी',
    'पट्टेवाला',
    'पट्ठा',
    'पट्ठी',
    'पठंता',
    'पठन',
    'पठनकर्ता',
    'पठनशील',
    'पठनीय',
    'पठय',
    'पठवना',
    'पठाई',
    'पठान',
    'पठाना',
    'पठाय',
    'पठार',
    'पठावन',
    'पठावनहार',
    'पठित',
    'पठिया',
    'पठोर',
    'पठोहार',
    'पठौनी',
    'पडना',
    'पड़',
    'पड़ता',
    'पड़ताल',
    'पड़तालकर्ता',
    'पड़तालना',
    'पड़ती',
    'पड़ते',
    'पड़दादा',
    'पड़दादी',
    'पड़धेवता',
    'पड़धेवती',
    'पड़न',
    'पड़ना',
    'पड़नाना',
    'पड़नानी',
    'पड़ने',
    'पड़पड़',
    'पड़पड़ाना',
    'पड़पोता',
    'पड़पोती',
    'पड़वा',
    'पड़ा',
    'पड़ाई',
    'पड़ाना',
    'पड़ापन',
    'पड़ाव',
    'पड़ी',
    'पड़े',
    'पड़ेगा',
    'पड़ोस',
    'पड़ोसी',
    'पडोस',
    'पडोसी',
    'पडौसी',
    'पढना',
    'पढ़',
    'पढ़ंता',
    'पढ़त',
    'पढ़न',
    'पढ़ना',
    'पढ़ने',
    'पढ़वैया',
    'पढ़ा',
    'पढ़ाई',
    'पढ़ाकू',
    'पढ़ाना',
    'पढ़ी',
    'पढ़े',
    'पढ़ैया',
    'पढ़ो',
    'पढाई',
    'पण',
    'पणछेन',
    'पणजी',
    'पणता',
    'पणन',
    'पणव',
    'पणवी',
    'पणस',
    'पणित',
    'पणी',
    'पण्य',
    'पण्यता',
    'पण्यन',
    'पण्यपति',
    'पण्यवीथी',
    'पण्यशाला',
    'पण्यागार',
    'पण्याजीव',
    'पत',
    'पतंग',
    'पतंगम',
    'पतंगा',
    'पतंगिनी',
    'पतंगी',
    'पतंचिका',
    'पतंजलि',
    'पतझड़',
    'पतझड़ी',
    'पतझर',
    'पतत',
    'पतता',
    'पतत्र',
    'पतत्री',
    'पतन',
    'पतनशील',
    'पतनोन्मु',
    'पतनोन्मुखी',
    'पतम',
    'पतयालु',
    'पतयिष्णु',
    'पतरा',
    'पतरी',
    'पतरौल',
    'पतला',
    'पतलापन',
    'पतली',
    'पतलून',
    'पतलेपन',
    'पतवर',
    'पतवार',
    'पतवारिया',
    'पतवारी',
    'पतशील',
    'पता',
    'पताई',
    'पताका',
    'पताकांकुश',
    'पताकिनी',
    'पताकी',
    'पतालेखी',
    'पतावर',
    'पतावा',
    'पति',
    'पतिआर',
    'पतिका',
    'पतिगृह',
    'पतिज',
    'पतित',
    'पतितता',
    'पतितपादन',
    'पतिता',
    'पतित्याग',
    'पतिदेवा',
    'पतिपरित्याग',
    'पतिपुत्रहीन',
    'पतिप्राणा',
    'पतिभक्ता',
    'पतियाना',
    'पतियार',
    'पतियारा',
    'पतियुक्तता',
    'पतिवंती',
    'पतिवती',
    'पतिवरा',
    'पतिव्रत',
    'पतिव्रतहीनता',
    'पतिव्रतहीना',
    'पतिव्रता',
    'पतिहीनता',
    'पतीजना',
    'पतीय',
    'पतीलचा',
    'पतीलची',
    'पतीला',
    'पतीली',
    'पतीसा',
    'पतुकी',
    'पतुरिया',
    'पतुरियाबाज',
    'पतुही',
    'पते',
    'पतेरा',
    'पतों',
    'पतोखद',
    'पतोखा',
    'पतोर',
    'पतोरा',
    'पतोहू',
    'पतौआ',
    'पतौखी',
    'पत्तन',
    'पत्तर',
    'पत्तरः',
    'पत्तरी',
    'पत्तल',
    'पत्ता',
    'पत्ताः',
    'पत्तिक',
    'पत्तियां',
    'पत्तियों',
    'पत्ती',
    'पत्तीः',
    'पत्तीदार',
    'पत्ते',
    'पत्तेदार',
    'पत्तों',
    'पत्थर',
    'पत्थरचटा',
    'पत्थरदिल',
    'पत्थरपूजा',
    'पत्थरी',
    'पत्था',
    'पत्थार',
    'पत्नी',
    'पत्नीः',
    'पत्नीक',
    'पत्नीकामी',
    'पत्नीज',
    'पत्नीजीवी',
    'पत्नीत्व',
    'पत्नीधन',
    'पत्नीयुक्त',
    'पत्नीयुक्तता',
    'पत्नीव्रत',
    'पत्नीहीनता',
    'पत्र',
    'पत्रं',
    'पत्रक',
    'पत्रकार',
    'पत्रकारिता',
    'पत्रज',
    'पत्रधारी',
    'पत्रवाल',
    'पत्रवाह',
    'पत्रवाहक',
    'पत्रव्यवहार',
    'पत्रव्यवहारी',
    'पत्रा',
    'पत्रांक',
    'पत्राचार',
    'पत्रात्मक',
    'पत्रादि',
    'पत्रालय',
    'पत्राली',
    'पत्रावली',
    'पत्रि',
    'पत्रिका',
    'पत्रिकायें',
    'पत्रिणी',
    'पत्री',
    'पत्रों',
    'पत्रोत्तर',
    'पत्रौषध',
    'पथ',
    'पथः',
    'पथक',
    'पथकर',
    'पथग',
    'पथचि',
    'पथज्ञ',
    'पथज्ञान',
    'पथथिक',
    'पथदृष्टा',
    'पथपाल',
    'पथप्रज्ञ',
    'पथप्रदर्शन',
    'पथभ्रष्ट',
    'पथभ्रष्टता',
    'पथर',
    'पथरकट्टा',
    'पथराना',
    'पथराया',
    'पथरी',
    'पथरीला',
    'पथरीलापन',
    'पथरीलाप्रवाह',
    'पथरीली',
    'पथरौटा',
    'पथरौडा',
    'पथशील',
    'पथहीन',
    'पथांवेषी',
    'पथाई',
    'पथाडना',
    'पथातिथि',
    'पथानुगमन',
    'पथानुगामी',
    'पथाहार',
    'पथिक',
    'पथिका',
    'पथिकाश्रय',
    'पथी',
    'पथीय',
    'पथेरा',
    'पथैया',
    'पथोरा',
    'पथ्य',
    'पथ्यता',
    'पथ्या',
    'पथ्याहार',
    'पद',
    'पदक',
    'पदक्रम',
    'पदक्षिणा',
    'पदक्षेप',
    'पदग',
    'पदग्रहण',
    'पदचर',
    'पदचरा',
    'पदचर्या',
    'पदचाप',
    'पदचिह्न',
    'पदची',
    'पदच्छेद',
    'पदच्युत',
    'पदच्युति',
    'पदछाप',
    'पदतल',
    'पदत्याग',
    'पदत्राण',
    'पददलन',
    'पददलित',
    'पदधर्मी',
    'पदधारी',
    'पदनाम',
    'पदनामित',
    'पदन्यास',
    'पदबंध',
    'पदभ',
    'पदभार',
    'पदभूल',
    'पदमाकर',
    'पदमाला',
    'पदमावती',
    'पदमुक्त',
    'पदमुक्ति',
    'पदयात्रा',
    'पदयात्री',
    'पदलोप',
    'पदवी',
    'पदवीधारी',
    'पदवीयुक्त',
    'पदव्याख्या',
    'पदस्थ',
    'पदस्थीकरण',
    'पदस्थीकृत',
    'पदांक',
    'पदांबु',
    'पदांश',
    'पदाक्रांत',
    'पदाघात',
    'पदाचारी',
    'पदाति',
    'पदातिक',
    'पदातिका',
    'पदाती',
    'पदाधिकारार्थ',
    'पदाधिकारिता',
    'पदाधिकारी',
    'पदानुग',
    'पदानुराग',
    'पदानुसरण',
    'पदानुसार',
    'पदान्वय',
    'पदापहरण',
    'पदाभिषक्त',
    'पदारथ',
    'पदारविंद',
    'पदारूढ़',
    'पदारोहण',
    'पदार्थ',
    'पदार्थता',
    'पदार्थभिनयो',
    'पदार्थर्ता',
    'पदार्थवाद',
    'पदार्थवादी',
    'पदार्थीकरण',
    'पदार्थीय',
    'पदार्पण',
    'पदावधि',
    'पदावनत',
    'पदावनति',
    'पदावली',
    'पदाश्रय',
    'पदासीनन',
    'पदिक',
    'पदि्मनी',
    'पदी',
    'पदीय',
    'पदुका',
    'पदेंद्रिय',
    'पदेन',
    'पदों',
    'पदोदक',
    'पदोन्नत',
    'पदोन्नति',
    'पदोल्लेख',
    'पद्घति',
    'पद्घतिहीन',
    'पद्घतिहीनतः',
    'पद्घतिहीनता',
    'पद्घतीय',
    'पद्धति',
    'पद्धतियां',
    'पद्धतियों',
    'पद्धी',
    'पद्म',
    'पद्मक',
    'पद्मकंद',
    'पद्मकर',
    'पद्मकरा',
    'पद्मकोश',
    'पद्मगर्भ',
    'पद्मगुणा',
    'पद्मनयन',
    'पद्मनाभि',
    'पद्मनेत्र',
    'पद्मपाणि',
    'पद्मप्रभ',
    'पद्मप्रिया',
    'पद्मबं',
    'पद्मभूषण',
    'पद्ममालिनी',
    'पद्मराग',
    'पद्मराज',
    'पद्मलांछन',
    'पद्मलांछना',
    'पद्मलोचन',
    'पद्मलोचना',
    'पद्मविभूषण',
    'पद्मश्री',
    'पद्महस्ता',
    'पद्महास',
    'पद्मा',
    'पद्माकर',
    'पद्मालय',
    'पद्मालया',
    'पद्मावती',
    'पद्मासन',
    'पद्य',
    'पद्यबद्घ',
    'पद्यमय',
    'पद्यरचना',
    'पद्या',
    'पद्यांश',
    'पद्यात्मक',
    'पद्वति',
    'पधराना',
    'पधा',
    'पधारना',
    'पधाराना',
    'पधारे',
    'पन',
    'पनकाल',
    'पनकुकरी',
    'पनगाचा',
    'पनघट',
    'पनचक्की',
    'पनचिरा',
    'पनडब्बा',
    'पनडुब्बा',
    'पनडुब्बी',
    'पनतिरी',
    'पननियाला',
    'पननिहा',
    'पनपना',
    'पनबाड़ी',
    'पनभता',
    'पनभरा',
    'पनलवा',
    'पनवा',
    'पनवाडन',
    'पनवाड़िन',
    'पनवाड़ी',
    'पनवार',
    'पनस',
    'पनसाऊ',
    'पनसाल',
    'पनसुइया',
    'पनसेटिया',
    'पनसोई',
    'पनहा',
    'पनहार',
    'पनहारा',
    'पनहिया',
    'पनहेडी',
    'पना',
    'पनातिन',
    'पनाती',
    'पनार',
    'पनारी',
    'पनाला',
    'पनाली',
    'पनालीदार',
    'पनाह',
    'पनाहगार',
    'पनि',
    'पनिगर',
    'पनियर',
    'पनियल',
    'पनिया',
    'पनी',
    'पनीर',
    'पनीरी',
    'पनीला',
    'पनीलापन',
    'पनेरी',
    'पनेवा',
    'पनौटी',
    'पन्द्रह',
    'पन्द्रहवां',
    'पन्न',
    'पन्नग',
    'पन्नगारि',
    'पन्नगी',
    'पन्ना',
    'पन्नागिनी',
    'पन्नी',
    'पन्नीदार',
    'पन्ने',
    'पन्नेः',
    'पन्नों',
    'पपड़ियाना',
    'पपड़ियाव',
    'पपड़ी',
    'पपड़ीदार',
    'पपड़ीयुक्त',
    'पपड़ीला',
    'पपड़ीलापन',
    'पपडाया',
    'पपडिया',
    'पपडियाया',
    'पपडी',
    'पपडीदार',
    'पपडीला',
    'पपपपडीदार',
    'पपीता',
    'पपीहरा',
    'पपीहा',
    'पपीहाः',
    'पपेट',
    'पपैया',
    'पपोटा',
    'पपोलना',
    'पपोलू',
    'पप्पा',
    'पप्पू',
    'पब',
    'पबि',
    'पबुधन',
    'पब्लिक',
    'पमपम',
    'पम्प',
    'पम्पघर',
    'पम्पवाला',
    'पय',
    'पयंबरी',
    'पयस्य',
    'पयस्या',
    'पयस्वान',
    'पयादा',
    'पयाल',
    'पयि',
    'पयिस्वनी',
    'पयोद',
    'पयोधर',
    'पयोधरा',
    'पयोधारा',
    'पयोधि',
    'पयोष्णी',
    'पर',
    'परंच',
    'परंज',
    'परंजय',
    'परंजा',
    'परंतप',
    'परंतु',
    'परंतुक',
    'परंतुकतः',
    'परंतुकता',
    'परंतुकतापूर्ण',
    'परंतुकताहीन',
    'परंतुकहीन',
    'परंतुकहीनता',
    'परंपर',
    'परंपरया',
    'परंपरा',
    'परंपरागत',
    'परंपरागतता',
    'परंपरानिष्ठ',
    'परंपरानुकूल',
    'परंपरानुकूलतः',
    'परंपरानुसार',
    'परंपरामुक्त',
    'परंपरावादी',
    'परई',
    'परक',
    'परकटा',
    'परकता',
    'परकदंब',
    'परकम्र',
    'परकसना',
    'परकाज',
    'परकाजी',
    'परकार',
    'परकाल',
    'परकीय',
    'परकीया',
    'परकोटा',
    'परक्रामण',
    'परक्रामित',
    'परक्राम्यता',
    'परक्राम्यवत',
    'परख',
    'परखचा',
    'परखचे',
    'परखना',
    'परखा',
    'परखावधि',
    'परखैया',
    'परगना',
    'परगनादा',
    'परगनाधीश',
    'परगनेदार',
    'परगहनी',
    'परगाछा',
    'परगोत्र',
    'परगोत्री',
    'परगोला',
    'परचना',
    'परचम',
    'परचा',
    'परची',
    'परचून',
    'परचूनिया',
    'परचूनी',
    'परचेज',
    'परछत्ती',
    'परछन',
    'परछा',
    'परछाई',
    'परछाही',
    'परजन',
    'परजनसमष्टि',
    'परजनीय',
    'परजन्म',
    'परजा',
    'परजाता',
    'परजाति',
    'परजातीय',
    'परजीविता',
    'परजीवी',
    'परजीवीनाशक',
    'परजीवीनाशी',
    'परठा',
    'परणाम',
    'परणित',
    'परत',
    'परतंत्र',
    'परतंत्रता',
    'परतः',
    'परतदार',
    'परतना',
    'परतबंद',
    'परतबंदी',
    'परतबन्द',
    'परतल',
    'परतला',
    'परतली',
    'परता',
    'परतिष्ठा',
    'परती',
    'परत्र',
    'परत्व',
    'परथन',
    'परदा',
    'परदाज',
    'परदादर',
    'परदादा',
    'परदादार',
    'परदादारी',
    'परदादी',
    'परदादे',
    'परदानशी',
    'परदानशीन',
    'परदानशीनी',
    'परदाफाश',
    'परदार',
    'परदारा',
    'परदुःख',
    'परदे',
    'परदेदार',
    'परदेश',
    'परदेशापवाहक',
    'परदेशी',
    'परदेशीय',
    'परदेश्य',
    'परदेस',
    'परदेसी',
    'परद्रोही',
    'परद्वेषी',
    'परधर्म',
    'परधेवता',
    'परधेवती',
    'परन',
    'परनातिन',
    'परनाना',
    'परनानी',
    'परनाने',
    'परनाम',
    'परनारी',
    'परनाला',
    'परनाली',
    'परनिर्भरता',
    'परनी',
    'परन्तु',
    'परपराना',
    'परपरिणीता',
    'परपालन',
    'परपालिका',
    'परपालित',
    'परपाषित',
    'परपिता',
    'परपिपद',
    'परपीडक',
    'परपीडन',
    'परपीड़क',
    'परपीड़कता',
    'परपीडा',
    'परपुत्र',
    'परपुत्री',
    'परपुरंजय',
    'परपुरुष',
    'परपुष्ट',
    'परपुष्टा',
    'परपूठा',
    'परपैडिक्यूलर',
    'परपैडीक्यूलर',
    'परपैडीक्यूलैरिटी',
    'परपोता',
    'परपोती',
    'परपोलितक',
    'परपोषक',
    'परपोषण',
    'परपोषी',
    'परप्रत्यय',
    'परफैक्ट',
    'परफैक्शनिज्म',
    'परफैक्शनिस्ट',
    'परफोरशन',
    'परब',
    'परबत',
    'परबस',
    'परबी',
    'परब्रह्म',
    'परभाग्योपजीवी',
    'परम',
    'परमगति',
    'परमजा',
    'परमधर्माध्यक्ष',
    'परमनैंट',
    'परमपावन',
    'परमर्षि',
    'परमल',
    'परमवीर',
    'परमाक्षर',
    'परमाणविक',
    'परमाणवीय',
    'परमाणवीयता',
    'परमाणु',
    'परमाणुकता',
    'परमाणुवाद',
    'परमाता',
    'परमात्म',
    'परमात्मा',
    'परमाद्वैत',
    'परमाधिकार',
    'परमानंद',
    'परमार',
    'परमार्थ',
    'परमार्थी',
    'परमाह',
    'परमिट',
    'परमुख',
    'परमुखतः',
    'परमेश',
    'परमेश्वर',
    'परमेश्वरी',
    'परमेश्वरीय',
    'परमेष्ठ',
    'परमेष्ठिनी',
    'परमेष्ठी',
    'परमोच्चशक्ति',
    'परमौंमिंग',
    'परमौमैंस',
    'परम्परा',
    'परम्पराएं',
    'परम्पराओं',
    'परम्परागत',
    'परम्यूटेशन',
    'परयत्न',
    'परयुत',
    'परराष्टीय',
    'परराष्ट्र',
    'परलंपार',
    'परला',
    'परली',
    'परले',
    'परलोक',
    'परलोकार्थ',
    'परलौकिक',
    'परवंश',
    'परवंशी',
    'परवर',
    'परवरिश',
    'परवर्ती',
    'परवर्धी',
    'परवल',
    'परवलय',
    'परवश',
    'परवशतः',
    'परवशता',
    'परवा',
    'परवाज',
    'परवाद',
    'परवादी',
    'परवान',
    'परवानगी',
    'परवाना',
    'परवानापन',
    'परवानाराहदारी',
    'परवाह',
    'परविचार',
    'परविचारज्ञ',
    'परव्यथा',
    'परश',
    'परशु',
    'परशुधर',
    'परशुधारी',
    'परशुराम',
    'परश्शत',
    'परस',
    'परसंतान',
    'परसंसेचन',
    'परसंस्कृति',
    'परसत',
    'परसना',
    'परसय',
    'परसर्ग',
    'परसा',
    'परसाई',
    'परसाद',
    'परसाल',
    'परसुख',
    'परसृ',
    'परसैंट',
    'परसों',
    'परस्त',
    'परस्तिश',
    'परस्ती',
    'परस्त्री',
    'परस्त्रीगमन',
    'परस्पर',
    'परस्परतः',
    'परस्परता',
    'परस्परव्याप्ति',
    'परस्पर्याप्यता',
    'परस्पर्याय',
    'परस्पर्यायवाची',
    'परस्व',
    'परहित',
    'परहितवाद',
    'परहितवादी',
    'परहितार्थ',
    'परहेज',
    'परहेजगार',
    'परहेजदार',
    'परहेजी',
    'परहेज़',
    'परा',
    'पराँठा',
    'परांकन',
    'परांकनकर्ता',
    'परांकित',
    'परांकिती',
    'परांतक',
    'पराई',
    'पराए',
    'पराकन',
    'पराकरण',
    'पराकाष्ठा',
    'पराकाष्ठात्मक',
    'पराकोटि',
    'पराकोटीय',
    'पराक्रम',
    'पराक्रमी',
    'पराक्रमीय',
    'पराक्रांत',
    'पराग',
    'परागकण',
    'परागकेशघर',
    'परागकोष्ठ',
    'परागण',
    'परागद',
    'परागपुट',
    'परागहीन',
    'पराचीन',
    'पराचीनतः',
    'पराजय',
    'पराजयवाद',
    'पराजयों',
    'पराजित',
    'पराजेय',
    'पराजेयता',
    'पराड्मुख',
    'पराड्मुखतः',
    'परात',
    'परात्पर',
    'पराधीन',
    'पराधीनता',
    'पराध्वनि',
    'परान',
    'पराना',
    'परान्नभेजी',
    'पराभव',
    'पराभूत',
    'पराभूति',
    'पराभौतिक',
    'पराम',
    'परामर्श',
    'परामर्शक',
    'परामर्शकुशल',
    'परामर्शदाता',
    'परामर्शदाताओं',
    'परामर्शदात्री',
    'परामर्शमण्डल',
    'परामर्शी',
    'परामार्श',
    'परामृष्आ',
    'परायण',
    'परायणता',
    'परायणा',
    'पराया',
    'परायापन',
    'परार्थ',
    'परार्थवाद',
    'परार्थवादी',
    'परार्ध',
    'परावर',
    'परावर्त',
    'परावर्तन',
    'परावलंबितता',
    'परावलंबी',
    'परावसु',
    'परावह',
    'पराविद्या',
    'परावृत्त',
    'परावृत्ति',
    'पराशक्ति',
    'पराशर',
    'पराशरी',
    'पराश्रयी',
    'पराश्रित',
    'पराश्रितता',
    'परास',
    'परासना',
    'परास्त',
    'पराहत',
    'पराहति',
    'पराह्न',
    'परिंदा',
    'परिकम्मा',
    'परिकर',
    'परिकर्म',
    'परिकर्मी',
    'परिकर्ष',
    'परिकर्षित',
    'परिकलक',
    'परिकलन',
    'परिकलनीय',
    'परिकलित',
    'परिकलित्र',
    'परिकल्प',
    'परिकल्पक',
    'परिकल्पन',
    'परिकल्पना',
    'परिकल्पित',
    'परिकल्पी',
    'परिकष्कार',
    'परिकांक्षी',
    'परिकीर्तित',
    'परिकोष्ठ',
    'परिक्रमण',
    'परिक्रमणमापी',
    'परिक्रमणशील',
    'परिक्रमणिक',
    'परिक्रमा',
    'परिक्रमायण',
    'परिक्रमायुक्त',
    'परिक्रमी',
    'परिक्रय',
    'परिक्रांत',
    'परिक्रामण',
    'परिक्रामी',
    'परिक्लांत',
    'परिक्वजिट',
    'परिक्षेत्र',
    'परिक्षेपी',
    'परिखा',
    'परिखात',
    'परिगंतव्य',
    'परिगणक',
    'परिगणन',
    'परिगणना',
    'परिगणित',
    'परिगणित्र',
    'परिगत',
    'परिगलित',
    'परिगीत',
    'परिगृहीत',
    'परिग्रर्हा',
    'परिग्रह',
    'परिग्रहण',
    'परिग्रहवृत्ति',
    'परिग्रही',
    'परिघट्टना',
    'परिघात',
    'परिघोष',
    'परिचक्र',
    'परिचक्रण',
    'परिचक्रायण',
    'परिचय',
    'परिचयततः',
    'परिचयन',
    'परिचयहीन',
    'परिचयहीनता',
    'परिचयात्मक',
    'परिचयिका',
    'परिचयिर्त',
    'परिचर',
    'परिचरित',
    'परिचर्चा',
    'परिचर्या',
    'परिचल',
    'परिचलय',
    'परिचाय',
    'परिचायक',
    'परिचायन',
    'परिचायित',
    'परिचार',
    'परिचारक',
    'परिचारिका',
    'परिचालक',
    'परिचालन',
    'परिचालनेतर',
    'परिचालिका',
    'परिचालित',
    'परिचिंतन',
    'परिचित',
    'परिचितता',
    'परिचित्रदर्शी',
    'परिचिह्न',
    'परिच्छत्र',
    'परिच्छद',
    'परिच्छिति',
    'परिच्छेद',
    'परिच्छेदक',
    'परिच्छेदन',
    'परिच्छेय',
    'परिच्युत',
    'परिजन',
    'परिजल्प',
    'परिजल्पन',
    'परिजात',
    'परिजेय',
    'परिज्ञात',
    'परिज्ञाता',
    'परिज्ञान',
    'परिज्ञानी',
    'परिणत',
    'परिणति',
    'परिणद्घ',
    'परिणमन',
    'परिणय',
    'परिणयन',
    'परिणाम',
    'परिणामतः',
    'परिणामतन',
    'परिणामपरक',
    'परिणामवाचक',
    'परिणामस्वरुप',
    'परिणामस्वरूप',
    'परिणामानुसार',
    'परिणामी',
    'परिणामों',
    'परिणीत',
    'परिणीता',
    'परिणेता',
    'परिणेया',
    'परितंत्र',
    'परितंत्रिका',
    'परितप्त',
    'परितप्तता',
    'परितर्कण',
    'परिताप',
    'परितापी',
    'परितारिका',
    'परितुण्ड',
    'परितुण्डिका',
    'परितुष्ट',
    'परितुष्टि',
    'परितृप्त',
    'परितोष',
    'परितोषक',
    'परित्यक्त',
    'परित्यक्ता',
    'परित्याग',
    'परित्यागी',
    'परित्याज्य',
    'परित्राण',
    'परित्राणित',
    'परित्राता',
    'परिदग्ध',
    'परिदर्शक',
    'परिदल',
    'परिदशा',
    'परिदान',
    'परिदानप',
    'परिदाय',
    'परिदृश्य',
    'परिदेय',
    'परिध',
    'परिधान',
    'परिधानन',
    'परिधानहीनता',
    'परिधानित',
    'परिधायक',
    'परिधावक',
    'परिधि',
    'परिधिः',
    'परिधित',
    'परिधियुक्त',
    'परिधीय',
    'परिधीयता',
    'परिधूसर',
    'परिनंदन',
    'परिनत',
    'परिनयन',
    'परिनिंदा',
    'परिनिन्दा',
    'परिनियामक',
    'परिनियोजन',
    'परिनिरीक्षक',
    'परिनिर्धारण',
    'परिनिर्धारित',
    'परिनिर्वाण',
    'परिपंथक',
    'परिपंथी',
    'परिपक्व',
    'परिपक्वता',
    'परिपक्वन',
    'परिपक्वा',
    'परिपठन',
    'परिपणित',
    'परिपणितार्थ',
    'परिपत्र',
    'परिपत्रों',
    'परिपथ',
    'परिपांडु',
    'परिपाक',
    'परिपाचक',
    'परिपाचन',
    'परिपाचिका',
    'परिपाचित',
    'परिपाटी',
    'परिपालक',
    'परिपालन',
    'परिपालित',
    'परिपाश्र्व',
    'परिपीड़न',
    'परिपीड़ित',
    'परिपुष्ट',
    'परिपूरक',
    'परिपूरण',
    'परिपूर्ण',
    'परिपूर्णता',
    'परिपूर्णतावादी',
    'परिपूर्ति',
    'परिपृच्छ',
    'परिपृच्छक',
    'परिपृच्छा',
    'परिपृष्ठ',
    'परिपोषक',
    'परिपोषण',
    'परिपोषित',
    'परिपोषी',
    'परिप्रदेश',
    'परिप्रश्न',
    'परिप्रश्नक',
    'परिप्रेक्ष्य',
    'परिप्रेषण',
    'परिप्लव',
    'परिप्लवन',
    'परिप्लुत',
    'परिबर्ह',
    'परिभक्षण',
    'परिभावन',
    'परिभाषण',
    'परिभाषा',
    'परिभाषाएं',
    'परिभाषाकरण',
    'परिभाषातीत',
    'परिभाषातीतता',
    'परिभाषाहीनता',
    'परिभाषित',
    'परिभाषितता',
    'परिभाष्य',
    'परिभू',
    'परिभूमि',
    'परिभूषण',
    'परिभोक्ता',
    'परिभोग',
    'परिभोगन',
    'परिभ्रम',
    'परिभ्रमण',
    'परिभ्रमणशील',
    'परिभ्रामण',
    'परिभ्रामी',
    'परिमंडल',
    'परिमंथर',
    'परिमंद',
    'परिमण्डल',
    'परिमर',
    'परिमर्दन',
    'परिमर्श',
    'परिमर्ष',
    'परिमल',
    'परिमा',
    'परिमाजिर्त',
    'परिमाण',
    'परिमाणित',
    'परिमाणी',
    'परिमाता',
    'परिमाप',
    'परिमापक',
    'परिमापन',
    'परिमापित',
    'परिमापी',
    'परिमार्ग',
    'परिमार्जक',
    'परिमार्जन',
    'परिमार्जित',
    'परिमालित',
    'परिमित',
    'परिमितता',
    'परिमितायु',
    'परिमिताहार',
    'परिमिताहारी',
    'परिमिति',
    'परिमुख',
    'परिमूढ',
    'परिमूल्य',
    'परिमृत्यु',
    'परिमेय',
    'परिमोक्ष',
    'परिमोक्षण',
    'परिमोचक',
    'परिम्लानन',
    'परिया',
    'परियां',
    'परियुक्ति',
    'परियों',
    'परियोजना',
    'परियोजनाएं',
    'परियोजनाओं',
    'परिरंजित',
    'परिरंभ',
    'परिरंभक',
    'परिरक्षक',
    'परिरक्षण',
    'परिरक्षा',
    'परिरक्षित',
    'परिरक्षिता',
    'परिरक्षी',
    'परिरथ्या',
    'परिरेखा',
    'परिरोध',
    'परिरोधनीय',
    'परिर्वतन',
    'परिर्वर्तन',
    'परिलंघन',
    'परिलक्षित',
    'परिलब्धि',
    'परिलब्धियां',
    'परिलाभ',
    'परिलेख',
    'परिलोप',
    'परिवत्रर्न',
    'परिवत्सर',
    'परिवत्सरीय',
    'परिवर्जन',
    'परिवर्जनीय',
    'परिवर्त',
    'परिवर्तक',
    'परिवर्तकारी',
    'परिवर्तन',
    'परिवर्तनकारी',
    'परिवर्तनवाद',
    'परिवर्तनशील',
    'परिवर्तनशीलता',
    'परिवर्तनहीनता',
    'परिवर्तनिका',
    'परिवर्तनीय',
    'परिवर्तनीयता',
    'परिवर्तनों',
    'परिवर्ताणु',
    'परिवर्तित',
    'परिवर्ती',
    'परिवर्धक',
    'परिवर्धन',
    'परिवर्धित',
    'परिवर्मा',
    'परिवह',
    'परिवहन',
    'परिवाद',
    'परिवादी',
    'परिवाप',
    'परिवार',
    'परिवारः',
    'परिवारजिका',
    'परिवारता',
    'परिवारयुक्त',
    'परिवारयुक्तता',
    'परिवारहीन',
    'परिवारहीनता',
    'परिवारासक्ति',
    'परिवारीय',
    'परिवार्षिक',
    'परिवाह',
    'परिवाहक',
    'परिवाहन',
    'परिवाहित',
    'परिवाही',
    'परिविर्तन',
    'परिविहार',
    'परिवीक्षा',
    'परिवीक्षाधीन',
    'परिवीत',
    'परिवृत',
    'परिवृत्त',
    'परिवृत्ति',
    'परिवेद',
    'परिवेदन',
    'परिवेदना',
    'परिवेदी',
    'परिवेश',
    'परिवेशी',
    'परिवेषण',
    'परिवेष्टन',
    'परिवेष्टित',
    'परिव्यक्त',
    'परिव्यय',
    'परिव्यापक',
    'परिव्यापन',
    'परिव्यापी',
    'परिव्याप्त',
    'परिव्याप्ति',
    'परिव्रज्या',
    'परिव्राजक',
    'परिशमन',
    'परिशिल्पी',
    'परिशिष्ट',
    'परिशीलन',
    'परिशुद्घ',
    'परिशुद्घन',
    'परिशुद्ध',
    'परिशुद्धता',
    'परिशुद्धि',
    'परिशून्य',
    'परिशेधित',
    'परिशेष',
    'परिशोध',
    'परिशोधक',
    'परिशोधन',
    'परिशोधित',
    'परिशोष',
    'परिश्रम',
    'परिश्रमपूर्ण',
    'परिश्रमशील',
    'परिश्रमशीलता',
    'परिश्रमहीन',
    'परिश्रमहीनता',
    'परिश्रमी',
    'परिश्रवण',
    'परिश्रांत',
    'परिश्रांति',
    'परिश्रुत',
    'परिषद',
    'परिषदीय',
    'परिषेक',
    'परिषेचन',
    'परिष्कंद',
    'परिष्कर',
    'परिष्करण',
    'परिष्कर्ता',
    'परिष्कार',
    'परिष्कारक',
    'परिष्कारहीनता',
    'परिष्कृत',
    'परिष्कृतता',
    'परिष्कृता',
    'परिष्कृति',
    'परिसंख्या',
    'परिसंख्यात',
    'परिसंख्यान',
    'परिसंघ',
    'परिसंघवाद',
    'परिसंपत्ति',
    'परिसंपत्तियां',
    'परिसंवाद',
    'परिसभ्य',
    'परिसमापक',
    'परिसमापन',
    'परिसमापनाधीन',
    'परिसमाप्त',
    'परिसमूहन',
    'परिसम्पत्ति',
    'परिसम्पत्तियां',
    'परिसर',
    'परिसरण',
    'परिसर्प',
    'परिसर्पण',
    'परिसर्या',
    'परिसहायक',
    'परिसाक्ष्य',
    'परिसार',
    'परिसारक',
    'परिसारी',
    'परिसीमन',
    'परिसीमा',
    'परिसीमाहीन',
    'परिसीमाहीनता',
    'परिसीमित',
    'परिसीमितता',
    'परिस्कंद',
    'परिस्कंदता',
    'परिस्तंभ',
    'परिस्तरण',
    'परिस्थतियों',
    'परिस्थिति',
    'परिस्थितिक',
    'परिस्थितिकी',
    'परिस्थितिकीविज्ञ',
    'परिस्थितिगत',
    'परिस्थितिजन्य',
    'परिस्थितियां',
    'परिस्थितियों',
    'परिस्थितिवश',
    'परिस्पंद',
    'परिस्पर्धी',
    'परिस्फुरण',
    'परिहत',
    'परिहनन',
    'परिहरण',
    'परिहार',
    'परिहारक',
    'परिहारी',
    'परिहार्य',
    'परिहास',
    'परिहासपूर्वक',
    'परिहासोक्ति',
    'परिहास्य',
    'परिहित',
    'परिहीण',
    'परी',
    'परीक्षक',
    'परीक्षण',
    'परीक्षणीय',
    'परीक्षणों',
    'परीक्षा',
    'परीक्षार्थी',
    'परीक्षित',
    'परीखक',
    'परीख़्वान',
    'परीखा',
    'परीज़ढा',
    'परीत्या',
    'परीदन',
    'परीदार',
    'परीरु',
    'परीलोक',
    'परुष',
    'परुषता',
    'परूआ',
    'परूई',
    'परूषोक्ति',
    'परे',
    'परेई',
    'परेग',
    'परेड',
    'परेत',
    'परेता',
    'परेवा',
    'परेश',
    'परेशान',
    'परेशानियों',
    'परेशानी',
    'परेषक',
    'परेषण',
    'परेषिती',
    'परेष्टि',
    'परैया',
    'परों',
    'परोक्ष',
    'परोक्षानुभूति',
    'परोक्षी',
    'परोढ़ा',
    'परोपकार',
    'परोपकारार्थ',
    'परोपकारिता',
    'परोपकारी',
    'परोपजीविता',
    'परोपजीवी',
    'परोल',
    'परोस',
    'परोसना',
    'परोसने',
    'परोसा',
    'परोसैया',
    'परोहन',
    'परोहा',
    'परौंदा',
    'पर्क',
    'पर्ग',
    'पर्च',
    'पर्चा',
    'पर्चियां',
    'पर्ची',
    'पर्जन्य',
    'पर्ण',
    'पर्णक',
    'पर्णकुटी',
    'पर्णग्रन्थि',
    'पर्णग्रन्थीय',
    'पर्णमध्यक',
    'पर्णरूपेण',
    'पर्णशाला',
    'पर्णसन्धि',
    'पर्णहरितिल',
    'पर्णाद',
    'पर्णाधिक्य',
    'पर्णास',
    'पर्णिका',
    'पर्णिनी',
    'पर्णिल',
    'पर्णिला',
    'पर्णी',
    'पर्णेकुटी',
    'पर्त',
    'पर्था',
    'पर्दा',
    'पर्दाफ़ाश',
    'पर्दे',
    'पर्प',
    'पर्पट',
    'पर्पटी',
    'पर्पटीय',
    'पर्पटीयन',
    'पर्पटीयुक्त',
    'पर्परी',
    'पर्यक',
    'पर्यटक',
    'पर्यटन',
    'पर्यटनप्रिय',
    'पर्यटनशील',
    'पर्यत',
    'पर्यलिंद',
    'पर्यवलोकन',
    'पर्यवसान',
    'पर्यवेक्षक',
    'पर्यवेक्षण',
    'पर्यस्त',
    'पर्याकुल',
    'पर्यानुकूलन',
    'पर्याप्त',
    'पर्याप्तता',
    'पर्याप्ति',
    'पर्याय',
    'पर्यायवर्तन',
    'पर्यायवाची',
    'पर्यायोक्ति',
    'पर्यालोचन',
    'पर्यावरण',
    'पर्यावरणवाद',
    'पर्यावरणवादी',
    'पर्यावरणिक',
    'पर्यास',
    'पर्युत्थान',
    'पर्येषणा',
    'पर्ल',
    'पर्व',
    'पर्वणी',
    'पर्वत',
    'पर्वतकाय',
    'पर्वतकायता',
    'पर्वतजन्म',
    'पर्वतजा',
    'पर्वतवासी',
    'पर्वतश्रेणी',
    'पर्वताकार',
    'पर्वताग्र',
    'पर्वतात्मज',
    'पर्वताभियान',
    'पर्वताभिरोहण',
    'पर्वतारोहण',
    'पर्वतारोही',
    'पर्वतीय',
    'पर्वतेश्वर',
    'पर्वतोपदेश',
    'पर्वदिन',
    'पर्वरीण',
    'पर्वीय',
    'पर्वेश',
    'पर्शु',
    'पर्शुका',
    'पर्शुपाणि',
    'पर्स',
    'पर्सनल',
    'पर्सपैक्टिव',
    'पर्सर',
    'पल',
    'पलंकष',
    'पलंकषा',
    'पलंग',
    'पलई',
    'पलक',
    'पलकदरियाव',
    'पलगंड',
    'पलजीवी',
    'पलट',
    'पलटता',
    'पलटन',
    'पलटना',
    'पलटनिया',
    'पलटवाना',
    'पलटा',
    'पलटाई',
    'पलटाना',
    'पलटाव',
    'पलटी',
    'पलटू',
    'पलड़ा',
    'पलड़ाः',
    'पलड़ी',
    'पलथी',
    'पलना',
    'पलभर',
    'पलय',
    'पलया',
    'पलल',
    'पललवयुक्त',
    'पलवल',
    'पलवार',
    'पलस्तर',
    'पलस्तरण',
    'पलस्तरित',
    'पलहा',
    'पलहैंडी',
    'पला',
    'पलांडु',
    'पलाद',
    'पलादत्ता',
    'पलान',
    'पलानी',
    'पलाय',
    'पलायन',
    'पलायनता',
    'पलायनवाद',
    'पलायनवादी',
    'पलायनशील',
    'पलायित',
    'पलायी',
    'पलाल',
    'पलाव',
    'पलाश',
    'पलाशी',
    'पलास्टर',
    'पलिका',
    'पलिध',
    'पली',
    'पलीता',
    'पलीद',
    'पलुआ',
    'पलुहान',
    'पलेंड़ी',
    'पलेंडी',
    'पलेट',
    'पलेथन',
    'पलोथन',
    'पल्प',
    'पल्लव',
    'पल्लवक',
    'पल्लवग्राहिता',
    'पल्लवग्राही',
    'पल्लवन',
    'पल्लवयुक्त',
    'पल्लवहीन',
    'पल्लवांकुर',
    'पल्लवाद',
    'पल्लवाधार',
    'पल्लवित',
    'पल्लवी',
    'पल्ला',
    'पल्ली',
    'पल्लीय',
    'पल्लू',
    'पल्ले',
    'पल्लेदार',
    'पल्लेदारी',
    'पल्वल',
    'पल्वला',
    'पवन',
    'पवनज',
    'पवनवेग',
    'पवना',
    'पवनात्मज',
    'पवनाहार',
    'पवनी',
    'पवमान',
    'पवाई',
    'पवान',
    'पवासित',
    'पविता',
    'पवित्र',
    'पवित्रक',
    'पवित्रतम',
    'पवित्रता',
    'पवित्रा',
    'पवित्रात्मा',
    'पवित्रारोपण',
    'पवित्री',
    'पवित्रीकरण',
    'पविधर',
    'पवैया',
    'पवोर्त्सव',
    'पवौड़ा',
    'पव्वा',
    'पशम',
    'पशमीना',
    'पशु',
    'पशुः',
    'पशुओं',
    'पशुकार्ट',
    'पशुगणना',
    'पशुघात',
    'पशुचर',
    'पशुचारक',
    'पशुचिकत्सा',
    'पशुचोरी',
    'पशुता',
    'पशुतापूर्ण',
    'पशुधन',
    'पशुपति',
    'पशुपथ',
    'पशुपन',
    'पशुपाल',
    'पशुपालक',
    'पशुपालन',
    'पशुपालिका',
    'पशुमेध',
    'पशुराज',
    'पशुवत',
    'पशुवन',
    'पशुविहार',
    'पशुवृत्तिक',
    'पशुशाला',
    'पशुसज्जा',
    'पश्च',
    'पश्चकपाल',
    'पश्चगंता',
    'पश्चगति',
    'पश्चगमन',
    'पश्चगमनता',
    'पश्चगामिता',
    'पश्चगामी',
    'पश्चचारोही',
    'पश्चजता',
    'पश्चतः',
    'पश्चतजातता',
    'पश्चता',
    'पश्चत्तापपूर्ण',
    'पश्चदर्शन',
    'पश्चदर्शी',
    'पश्चदीप्ति',
    'पश्चदेय',
    'पश्चपक्ष',
    'पश्चप्रभाव',
    'पश्चभाग',
    'पश्चभागीय',
    'पश्चभू',
    'पश्चभूमि',
    'पश्चरंधर',
    'पश्चलिखितता',
    'पश्चवक्ष',
    'पश्चवर्तिता',
    'पश्चसर',
    'पश्चसरण',
    'पश्चसूची',
    'पश्चस्थ',
    'पश्चस्थता',
    'पश्चस्वर',
    'पश्चात',
    'पश्चातजातता',
    'पश्चातजाल',
    'पश्चातता',
    'पश्चातपक्वता',
    'पश्चाताप',
    'पश्चातापी',
    'पश्चात्ताप',
    'पश्चात्तापग्रस्त',
    'पश्चात्तापपूर्ण',
    'पश्चात्तापी',
    'पश्चाद्घाट',
    'पश्चाद्वतिता',
    'पश्चाद्वर्ती',
    'पश्चानयन',
    'पश्चानुपात',
    'पश्चारोही',
    'पश्चार्ध',
    'पश्चितार्ध',
    'पश्चिम',
    'पश्चिमा',
    'पश्चिमाचल',
    'पश्चिमाशा',
    'पश्चिमी',
    'पश्चिमीय',
    'पश्चिमोत्तर',
    'पश्चीय',
    'पश्चोक्त',
    'पश्चोक्तता',
    'पश्चोन्मुख',
    'पश्चोन्मुखता',
    'पश्तो',
    'पश्न',
    'पश्म',
    'पस',
    'पसँगा',
    'पसंग',
    'पसंगा',
    'पसंद',
    'पसंदगी',
    'पसंदी',
    'पसंदीदा',
    'पसंदीदापन',
    'पसनी',
    'पसन्द',
    'पसमाँदा',
    'पसर',
    'पसरट्टा',
    'पसरना',
    'पसरने',
    'पसरा',
    'पसराय',
    'पसली',
    'पसाना',
    'पसार',
    'पसारना',
    'पसारने',
    'पसारिए',
    'पसाव',
    'पसिलयों',
    'पसीजता',
    'पसीजना',
    'पसीजा',
    'पसीन',
    'पसीना',
    'पसीने',
    'पसे',
    'पसेऊ',
    'पसेय',
    'पसेरी',
    'पसोपेश',
    'पस्टम',
    'पस्त',
    'पस्तहिम्मत',
    'पस्ती',
    'पस्मृरण',
    'पहचान',
    'पहचानना',
    'पहचाननापन',
    'पहचानने',
    'पहचानयोग्य',
    'पहचाना',
    'पहन',
    'पहनना',
    'पहनने',
    'पहना',
    'पहनाई',
    'पहनाकर',
    'पहनाना',
    'पहनाया',
    'पहनाव',
    'पहनावा',
    'पहने',
    'पहर',
    'पहरन',
    'पहरना',
    'पहरा',
    'पहरानवी',
    'पहरावा',
    'पहरी',
    'पहरुआ',
    'पहरे',
    'पहरेदार',
    'पहरेदारी',
    'पहल',
    'पहलदार',
    'पहलवान',
    'पहलवानी',
    'पहला',
    'पहलापन',
    'पहली',
    'पहलु',
    'पहलुओं',
    'पहलू',
    'पहलूदार',
    'पहले',
    'पहलौठा',
    'पहाड़',
    'पहाड़ा',
    'पहाड़िया',
    'पहाड़ियाँ',
    'पहाड़ियां',
    'पहाड़ी',
    'पहाडिज्ञ',
    'पहाडियाँ',
    'पहाडों',
    'पहिए',
    'पहिएदार',
    'पहित',
    'पहिना',
    'पहिया',
    'पहियाः',
    'पहियों',
    'पहुँच',
    'पहुँचना',
    'पहुँचने',
    'पहुँचवाना',
    'पहुँचा',
    'पहुँचाई',
    'पहुँचाना',
    'पहुँचाया',
    'पहुँची',
    'पहुंच',
    'पहुंचना',
    'पहुंचने',
    'पहुंचा',
    'पहुंचाते',
    'पहुंचाना',
    'पहुंचाने',
    'पहुच',
    'पहुनाई',
    'पहेली',
    'पा',
    'पाँख',
    'पाँखड़ी',
    'पाँखरी',
    'पाँखुरी',
    'पाँग',
    'पाँच',
    'पाँचःउपमान',
    'पाँचवा',
    'पाँचा',
    'पाँजना',
    'पाँजर',
    'पाँझ',
    'पाँडा',
    'पाँडिचेरि',
    'पाँत',
    'पाँयचा',
    'पाँव',
    'पाँवड़ा',
    'पाँवड़ी',
    'पाँवड़े',
    'पाँवडा',
    'पाँवडी',
    'पाँवदान',
    'पाँवफाँस',
    'पाँवरी',
    'पाँस',
    'पाँसना',
    'पाँसा',
    'पांखड़ी',
    'पांगुल',
    'पांच',
    'पांचजन्य',
    'पांचवां',
    'पांचवी',
    'पांचवें',
    'पांचार्थिक',
    'पांचाल',
    'पांचालिका',
    'पांचाली',
    'पांचेन',
    'पांचों',
    'पांट',
    'पांडपुत्र',
    'पांडर',
    'पांडव',
    'पांडित्य',
    'पांडित्यपूर्ण',
    'पांडित्यवाद',
    'पांडित्यहीनता',
    'पांडु',
    'पांडुक',
    'पांडुता',
    'पांडुर',
    'पांडुरता',
    'पांडुरिमा',
    'पांडुरोग',
    'पांडुलिपि',
    'पांडे',
    'पांडेय',
    'पांड्डर',
    'पांथ',
    'पांथशाला',
    'पांपास',
    'पांव',
    'पांशु',
    'पांशुल',
    'पांशुला',
    'पांसा',
    'पाइ',
    'पाइँ',
    'पाइंट',
    'पाइक',
    'पाइका',
    'पाइट',
    'पाइन्ट',
    'पाइप',
    'पाइपिंग',
    'पाइपों',
    'पाइर',
    'पाइराइट',
    'पाइरेट',
    'पाइलट',
    'पाइलटेज',
    'पाइलन',
    'पाइलिंटग',
    'पाइवट',
    'पाई',
    'पाईः',
    'पाईप',
    'पाईपों',
    'पाउंद',
    'पाउच',
    'पाउडर',
    'पाए',
    'पाएगा',
    'पाएच',
    'पाक',
    'पाकचूर्ण',
    'पाकट',
    'पाकटि',
    'पाकदामन',
    'पाकदामनी',
    'पाकनीयत',
    'पाकनीयती',
    'पाकर',
    'पाकरवी',
    'पाकरि',
    'पाकल',
    'पाकशाला',
    'पाकशासनि',
    'पाकशास्त्र',
    'पाकस्थान',
    'पाकागार',
    'पाकिट',
    'पाकिटमार',
    'पाकिम',
    'पाकिस्तान',
    'पाकिस्तानवाद',
    'पाकिस्तानी',
    'पाकी',
    'पाकीज़',
    'पाकीज़गी',
    'पाकीज़ा',
    'पाकेट',
    'पाक्कथन',
    'पाक्ष',
    'पाक्षिक',
    'पाख',
    'पाखंड',
    'पाखंडपूर्ण',
    'पाखंडवाद',
    'पाखंडहीन',
    'पाखंडहीनता',
    'पाखंडी',
    'पाखण्डी',
    'पाख़ाना',
    'पाखाना',
    'पाखी',
    'पाग',
    'पागना',
    'पागल',
    'पागलख़ाना',
    'पागलखाना',
    'पागलपन',
    'पागव्य',
    'पागुर',
    'पाचक',
    'पाचकिन',
    'पाचन',
    'पाचनक',
    'पाचनिका',
    'पाचनी',
    'पाचनीय',
    'पाचप्य',
    'पाचयिता',
    'पाचरोटी',
    'पाचवां',
    'पाचव्य',
    'पाचिका',
    'पाचिन',
    'पाच्य',
    'पाछ',
    'पाछना',
    'पाछी',
    'पाछे',
    'पाजामा',
    'पाजामे',
    'पाजी',
    'पाजीटिव',
    'पाजीपन',
    'पाजीपना',
    'पाजे',
    'पाट',
    'पाटंबर',
    'पाटण',
    'पाटन',
    'पाटना',
    'पाटने',
    'पाटल',
    'पाटलक',
    'पाटला',
    'पाटली',
    'पाटलोपल',
    'पाटव',
    'पाटविक',
    'पाटवी',
    'पाटहिक',
    'पाटा',
    'पाटाफेर',
    'पाटिया',
    'पाटिल',
    'पाटी',
    'पाटीर',
    'पाटुका',
    'पाठ',
    'पाठक',
    'पाठन',
    'पाठनालय',
    'पाठनीय',
    'पाठनीयता',
    'पाठमाला',
    'पाठय',
    'पाठलाग',
    'पाठशाला',
    'पाठशोधन',
    'पाठा',
    'पाठांतर',
    'पाठालोचन',
    'पाठालोचित',
    'पाठालोच्य',
    'पाठी',
    'पाठीन',
    'पाठ्य',
    'पाठ्यक्रम',
    'पाठ्यचर्या',
    'पाठ्यपुस्तक',
    'पाठ्यों',
    'पाड़',
    'पाड़ा',
    'पाड़ी',
    'पाडा',
    'पाडुंलिपि',
    'पाढ़',
    'पाढ़ना',
    'पाढ़ी',
    'पाण',
    'पाणि',
    'पाणिः',
    'पाणिगृहीता',
    'पाणिग्रह',
    'पाणिग्रहण',
    'पाणिग्रहणीय',
    'पाणिघात',
    'पाणिनी',
    'पाणिरुह',
    'पाण्डु',
    'पाण्डुता',
    'पाण्डुलिपि',
    'पात',
    'पातंगी',
    'पातक',
    'पातकता',
    'पातकी',
    'पातन',
    'पातयिता',
    'पातरेबाज़',
    'पाता',
    'पाताबा',
    'पाताल',
    'पाताललौका',
    'पातालवासी',
    'पातित्य',
    'पातिव्रत्य',
    'पाती',
    'पाते',
    'पात्र',
    'पात्रः',
    'पात्रक',
    'पात्रता',
    'पात्रपाल',
    'पात्रा',
    'पात्री',
    'पात्रों',
    'पात्रोक्ति',
    'पात्रोपकरण',
    'पाथ',
    'पाथना',
    'पाथने',
    'पाथर',
    'पाथा',
    'पाथेय',
    'पाद',
    'पादक',
    'पादक्षेप',
    'पादचतुर',
    'पादचारी',
    'पादतल',
    'पादतलाचारी',
    'पादत्र',
    'पादत्राण',
    'पादप',
    'पादपजात',
    'पादपज्ञ',
    'पादपनाशी',
    'पादपरागी',
    'पादपाशी',
    'पादपाश्म',
    'पादपीठ',
    'पादमूल',
    'पादयुक्त',
    'पादरी',
    'पादशाह',
    'पादशाही',
    'पादस्थि',
    'पादहत',
    'पादा',
    'पादांक',
    'पादांभ',
    'पादांश',
    'पादागद',
    'पादातिक',
    'पादार्थिक',
    'पादावरण',
    'पादावसेवन',
    'पादासन',
    'पादुका',
    'पादोदक',
    'पाद्य',
    'पाद्याध्यं',
    'पाद्वतिक',
    'पाधा',
    'पान',
    'पानः',
    'पानक',
    'पानछटी',
    'पानदान',
    'पानवाड़ी',
    'पानवाला',
    'पानवाली',
    'पाना',
    'पानासक्ति',
    'पानी',
    'पानीः',
    'पानीघर',
    'पानीघाट',
    'पानीदार',
    'पानीदेया',
    'पानीपत',
    'पानीफल',
    'पानीय',
    'पानीवाला',
    'पाने',
    'पानेच्छा',
    'पानेच्छुक',
    'पानेवाला',
    'पानोत्सव',
    'पाप',
    'पापकर्म',
    'पापकर्मी',
    'पापचरी',
    'पापचेता',
    'पापज',
    'पापड़',
    'पापड़ी',
    'पापधी',
    'पापध्न',
    'पापध्नी',
    'पापनष्ट',
    'पापनाशक',
    'पापनाशन',
    'पापनाशनी',
    'पापनाशी',
    'पापपूर्ण',
    'पापप्रवणता',
    'पापमयता',
    'पापमुक्त',
    'पापमोचन',
    'पापयादा',
    'पापयुक्त',
    'पापशंकालु',
    'पापशंकालुता',
    'पापशोधन',
    'पापस्वीकरण',
    'पापस्वीकृति',
    'पापहर',
    'पापहा',
    'पापहारी',
    'पापहीन',
    'पापहीनता',
    'पापा',
    'पापागीकरण',
    'पापाचार',
    'पापाचारी',
    'पापात्मक',
    'पापात्मकता',
    'पापात्मा',
    'पापाधम',
    'पापाधीन',
    'पापाहि',
    'पापिन',
    'पापिनी',
    'पापिष्ठ',
    'पापिष्ठा',
    'पापी',
    'पापोश',
    'पाप्मा',
    'पाफिन',
    'पाबंद',
    'पाबंदी',
    'पाबन्दी',
    'पाबोसी',
    'पाम',
    'पामर',
    'पामर्द',
    'पामाल',
    'पामाली',
    'पामिस्ट',
    'पामिस्ट्र',
    'पायँ',
    'पायँचा',
    'पायँचाः',
    'पायँत',
    'पायँता',
    'पायँती',
    'पायंदाज़',
    'पायक',
    'पायकात्मज',
    'पायचाँ',
    'पायजामा',
    'पायजामे',
    'पायडा',
    'पायताना',
    'पायताबा',
    'पायदान',
    'पायदार',
    'पायना',
    'पायबोसी',
    'पायमाली',
    'पायर',
    'पायरा',
    'पायल',
    'पायलट',
    'पायस',
    'पाया',
    'पायाः',
    'पायाब',
    'पायिक',
    'पाये',
    'पाय्या',
    'पायज़ामे',
    'पार',
    'पारंगत',
    'पारंगतता',
    'पारंगमन',
    'पारंपरिक',
    'पारंपरिकतः',
    'पारंपरिकता',
    'पारंम्परिक',
    'पारक',
    'पारकम्य',
    'पारकृत',
    'पारखी',
    'पारखीपन',
    'पारगत',
    'पारगमन',
    'पारगम्य',
    'पारगम्यता',
    'पारगामी',
    'पारगित',
    'पारचा',
    'पारचित्र',
    'पारचीन',
    'पारण',
    'पारणक',
    'पारणा',
    'पारणीय',
    'पारत',
    'पारद',
    'पारदम्य',
    'पारदर्शक',
    'पारदर्शी',
    'पारदर्शीता',
    'पारदीय',
    'पारदृश्यता',
    'पारदेशिक',
    'पारदेशीय',
    'पारधी',
    'पारना',
    'पारपत्र',
    'पारपथ',
    'पारपन्न',
    'पारप्त',
    'पारप्तांक',
    'पारप्ति',
    'पारभासक',
    'पारभासी',
    'पारमित',
    'पारमिता',
    'पारमेश्वर',
    'पारमेष्ठ',
    'पारयिष्णु',
    'पारलगैया',
    'पारलौकिक',
    'पारशव',
    'पारस',
    'पारसक',
    'पारसमणि',
    'पारसा',
    'पारसाई',
    'पारसी',
    'पारसैक',
    'पारस्परिक',
    'पारा',
    'पारापार',
    'पारापारीण',
    'पारायण',
    'पारायणी',
    'पारावत',
    'पारावताश्व',
    'पारावती',
    'पारावार',
    'पाराशर',
    'पाराशरी',
    'पाराशर्य',
    'पारिजात',
    'पारिजातक',
    'पारिणामिकता',
    'पारिणाय्य',
    'पारित',
    'पारितोषिक',
    'पारिदीन',
    'पारिधिक',
    'पारिपाश्र्वक',
    'पारिपाश्र्विक',
    'पारिभद्र',
    'पारिभद्रक',
    'पारिभाषिक',
    'पारिभाषिकता',
    'पारिभाषित',
    'पारिवारिक',
    'पारिवारिकता',
    'पारिश्रमपूर्वक',
    'पारिश्रमिक',
    'पारिश्रमिकहीन',
    'पारिषद',
    'पारिषदक',
    'पारिस्थितिक',
    'पारिस्परिकता',
    'पारी',
    'पारीक्षित',
    'पारीण',
    'पारीय',
    'पारूष्य',
    'पारेत्थापक',
    'पारेत्थित',
    'पारेदियों',
    'पारेषण',
    'पार्क',
    'पार्कभूमि',
    'पार्किंग',
    'पार्किग',
    'पार्चमैंट',
    'पार्ट',
    'पार्टटाइम',
    'पार्टनर',
    'पार्टनरशिप',
    'पार्टस',
    'पार्टिकल',
    'पार्टिकल्स',
    'पार्टियों',
    'पार्टी',
    'पार्टीबंदी',
    'पार्टीबाज़',
    'पार्टीशन',
    'पार्थ',
    'पार्थक्य',
    'पार्थक्यवाद',
    'पार्थक्यवादी',
    'पार्थिय',
    'पार्थियी',
    'पार्थिव',
    'पार्थी',
    'पार्य',
    'पार्यता',
    'पार्लक',
    'पार्लमैंट',
    'पार्लमैंटरी',
    'पार्लयामैंट',
    'पार्लर',
    'पार्लामैंट',
    'पार्लियामेंट',
    'पार्वतिकी',
    'पार्वती',
    'पार्शुका',
    'पार्श्व',
    'पार्श्वगाड़ी',
    'पार्श्वगायक',
    'पार्श्वगायिका',
    'पार्श्वदंती',
    'पार्श्वपथन',
    'पार्श्वसंयोग',
    'पार्श्वस्थ',
    'पार्श्वालय',
    'पार्श्विक',
    'पार्षद',
    'पार्षर्त',
    'पार्षर्ती',
    'पार्ष्णि',
    'पार्सल',
    'पाल',
    'पालः',
    'पालक',
    'पालकी',
    'पालघर',
    'पालट',
    'पालड़ा',
    'पालतू',
    'पालथी',
    'पालन',
    'पालनः',
    'पालना',
    'पालनीय',
    'पालने',
    'पालयिता',
    'पाला',
    'पालागन',
    'पालि',
    'पालिएस्टर',
    'पालिका',
    'पालित',
    'पालिथीन',
    'पालिमय',
    'पालिया',
    'पालिरूप',
    'पालिश',
    'पालिशकार',
    'पालिशदार',
    'पालिशर',
    'पालिशिंग',
    'पालिसियां',
    'पालिसी',
    'पाली',
    'पाले',
    'पाव',
    'पावँ',
    'पावः',
    'पावडर',
    'पावतार',
    'पावती',
    'पावदी',
    'पावन',
    'पावनता',
    'पावना',
    'पावनी',
    'पावर',
    'पावली',
    'पावस',
    'पावा',
    'पाश',
    'पाशक',
    'पाशग्रस्त',
    'पाशन',
    'पाशपाणि',
    'पाशबद्घ',
    'पाशमुक्त',
    'पाशविक',
    'पाशविकता',
    'पाशांत',
    'पाशिक',
    'पाशित',
    'पाशी',
    'पाशु',
    'पाशुपत',
    'पाशुपात',
    'पाश्चात्य',
    'पाश्चात्यवाद',
    'पाश्या',
    'पाश्र्व',
    'पाश्र्वः',
    'पाश्र्वक',
    'पाश्र्वग',
    'पाश्र्वचर',
    'पाश्र्वचरण',
    'पाश्र्वचरतः',
    'पाश्र्वचर्या',
    'पाश्र्वभू',
    'पाश्र्वयुक्त',
    'पाश्र्वयुक्तता',
    'पाश्र्ववर्ती',
    'पाश्र्वस्थ',
    'पाश्र्वास्थि',
    'पाश्र्वीय',
    'पाश्वीय',
    'पाषाण',
    'पाषाणः',
    'पाषाणकला',
    'पाषाणकाल',
    'पाषाणता',
    'पाषाणहृदय',
    'पाषाणिक',
    'पाषाणी',
    'पाषाणीभूत',
    'पाषाणीय',
    'पाषान',
    'पाष्णि',
    'पास',
    'पासंगदार',
    'पासदारी',
    'पासनी',
    'पासपोर्ट',
    'पासबान',
    'पासबानी',
    'पासबुक',
    'पासा',
    'पासी',
    'पास्ट',
    'पास्तेरीकरण',
    'पास्तेरीकृत',
    'पाहन',
    'पाहनी',
    'पाहुन',
    'पाहुना',
    'पाहुनी',
    'पिंग',
    'पिंगपौग',
    'पिंगल',
    'पिंगला',
    'पिंगलाक्ष',
    'पिंगा',
    'पिंगाक्ष',
    'पिंजन',
    'पिंजर',
    'पिंजरक',
    'पिंजरा',
    'पिंजरापोल',
    'पिंजल',
    'पिंजा',
    'पिंजाई',
    'पिंजाल',
    'पिंजिन्न',
    'पिंजियारा',
    'पिंजूल',
    'पिंजूली',
    'पिंट',
    'पिंड',
    'पिंडक',
    'पिंडकक्षा',
    'पिंडगतिक',
    'पिंडगोस',
    'पिंडज',
    'पिंडद',
    'पिंडदान',
    'पिंडन',
    'पिंडफला',
    'पिंडभोजी',
    'पिंडमूल',
    'पिंडयुक्त',
    'पिंडल',
    'पिंडली',
    'पिंडा',
    'पिंडाकार',
    'पिंडाकारता',
    'पिंडाकृति',
    'पिंडाभ्र',
    'पिंडायस',
    'पिंडार',
    'पिंडिका',
    'पिंडित',
    'पिंडिया',
    'पिंडी',
    'पिंडीकरण',
    'पिंडीभूत',
    'पिंडीर',
    'पिंडील',
    'पिंशु',
    'पिअ',
    'पिअरवा',
    'पिआनो',
    'पिउ',
    'पिक',
    'पिकंग',
    'पिकअप',
    'पिकनिक',
    'पिकप',
    'पिकबंधु',
    'पिकराग',
    'पिकी',
    'पिकैटिंग',
    'पिक्चर',
    'पिक्सल',
    'पिग',
    'पिगमैंट',
    'पिगा',
    'पिगाश',
    'पिघल',
    'पिघलकर',
    'पिघलना',
    'पिघला',
    'पिघलाऊ',
    'पिघलाकर',
    'पिघलाना',
    'पिघलाने',
    'पिच',
    'पिचक',
    'पिचकन',
    'पिचकना',
    'पिचका',
    'पिचकाना',
    'पिचकारी',
    'पिचकाव',
    'पिचकी',
    'पिचन',
    'पिचना',
    'पिचपच',
    'पिचपिच',
    'पिचपिचाहट',
    'पिचर',
    'पिचहत्तर',
    'पिचा',
    'पिचाई',
    'पिचिंड',
    'पिचिडिका',
    'पिचूकर',
    'पिच्चट',
    'पिच्छ',
    'पिच्छक',
    'पिच्छट',
    'पिच्छन',
    'पिच्छबाण',
    'पिच्छमूल',
    'पिच्छल',
    'पिच्छाक्ष',
    'पिच्छिका',
    'पिच्छिला',
    'पिछ',
    'पिछडन',
    'पिछडना',
    'पिछड़',
    'पिछड़ना',
    'पिछड़ा',
    'पिछड़ापन',
    'पिछड़ी',
    'पिछड़े',
    'पिछडे',
    'पिछपलाई',
    'पिछलगा',
    'पिछलगापन',
    'पिछलगी',
    'पिछलग्गा',
    'पिछलग्गू',
    'पिछलग्गूपन',
    'पिछला',
    'पिछलापन',
    'पिछली',
    'पिछले',
    'पिछवाड़ा',
    'पिछवाडा',
    'पिछाडना',
    'पिछाडने',
    'पिछाड़',
    'पिछाड़ी',
    'पिछाडा',
    'पिछाडी',
    'पिछेती',
    'पिछौंही',
    'पिछौरा',
    'पिछौरी',
    'पिजट',
    'पिज्',
    'पिटंत',
    'पिटंतू',
    'पिटक',
    'पिटकन',
    'पिटकना',
    'पिटका',
    'पिटना',
    'पिटपिट',
    'पिटपिटाना',
    'पिटयूटरी',
    'पिटरिया',
    'पिटवां',
    'पिटवाकर',
    'पिटा',
    'पिटाई',
    'पिटारा',
    'पिटारी',
    'पिटैल',
    'पिट्टी',
    'पिट्टू',
    'पिट्ठी',
    'पिट्ठू',
    'पिठर',
    'पिठरक',
    'पिठरिका',
    'पिठरी',
    'पिडकिया',
    'पिडकी',
    'पिडिया',
    'पिण्ड',
    'पिण्डली',
    'पिण्डाकार',
    'पिण्याक',
    'पित',
    'पितर',
    'पितरः',
    'पितरिहा',
    'पिता',
    'पिताः',
    'पिताजी',
    'पितातुल्य',
    'पितामह',
    'पितामही',
    'पितामाताहीन',
    'पितामाताहीनता',
    'पिताहीन',
    'पितिया',
    'पितियानी',
    'पितु',
    'पितृ',
    'पितृक',
    'पितृगण',
    'पितृतंत्र',
    'पितृत्व',
    'पितृदाय',
    'पितृदेव',
    'पितृनाथ',
    'पितृनाम',
    'पितृनिर्धारण',
    'पितृपति',
    'पितृपूजा',
    'पितृप्राप्त',
    'पितृवंशिक',
    'पितृवत',
    'पितृवन',
    'पितृवनेचर',
    'पितृवृत्ति',
    'पितृव्य',
    'पितृव्यवहार',
    'पितृसत्ता',
    'पितृसत्तावाद',
    'पितृसुलभ',
    'पितृसू',
    'पितृस्थनीय',
    'पितृस्वसा',
    'पितृहंत',
    'पितृहंता',
    'पितृहत्या',
    'पितृहीन',
    'पित्त',
    'पित्तपांडु',
    'पित्तल',
    'पित्तव्य',
    'पित्ता',
    'पित्ताधिक्य',
    'पित्ताशय',
    'पित्तिद',
    'पित्ती',
    'पित्तीयता',
    'पित्रय',
    'पित्रागतता',
    'पिदना',
    'पिदर',
    'पिदाई',
    'पिदानर',
    'पिद्दा',
    'पिधलन',
    'पिधलना',
    'पिधान',
    'पिधानक',
    'पिधानन',
    'पिधानिका',
    'पिधानित',
    'पिधानी',
    'पिधायी',
    'पिन',
    'पिनआल',
    'पिनकनना',
    'पिनकना',
    'पिनद्घ',
    'पिनपिनाना',
    'पिनपिनाहट',
    'पिनाई',
    'पिनाक',
    'पिनाकपाणि',
    'पिनाकी',
    'पिनों',
    'पिन्ना',
    'पिपरमिन्ट',
    'पिपाशा',
    'पिपास',
    'पिपासा',
    'पिपासार्त',
    'पिपासाहीन',
    'पिपासित',
    'पिपासु',
    'पिपासुता',
    'पिपियाना',
    'पिपीलक',
    'पिपीलिका',
    'पिप्पल',
    'पिप्पलाद',
    'पिप्पली',
    'पिप्पलेश्वर',
    'पिप्पिका',
    'पिप्पित',
    'पिप्लु',
    'पिब',
    'पिब्ब',
    'पिब्लक',
    'पिब्लश',
    'पिब्लशर',
    'पिब्लशिग',
    'पिब्लश्ड',
    'पिब्लसिटी',
    'पिब्लसिस्ट',
    'पिय',
    'पियक्कड़',
    'पियक्कड़पन',
    'पियक्कड़ी',
    'पियक्क्ड़',
    'पियर',
    'पियरवा',
    'पियरा',
    'पियरी',
    'पिया',
    'पियानो',
    'पियाल',
    'पियु',
    'पिये',
    'पियेगा',
    'पिरथी',
    'पिरपाटियां',
    'पिरा',
    'पिराना',
    'पिरान्या',
    'पिरामिड',
    'पिरामिडाकृति',
    'पिरोई',
    'पिरोजन',
    'पिरोना',
    'पिरोया',
    'पिरोहना',
    'पिरौग',
    'पिल',
    'पिलकना',
    'पिलछौंटा',
    'पिलना',
    'पिलपिला',
    'पिलर',
    'पिला',
    'पिलाई',
    'पिलाकर',
    'पिलाना',
    'पिलाने',
    'पिलापा',
    'पिलापी',
    'पिलो',
    'पिल्ला',
    'पिशंगक',
    'पिशाच',
    'पिशाचता',
    'पिशाचनी',
    'पिशाचवाद',
    'पिशाचांगना',
    'पिशाचिका',
    'पिशाचिनी',
    'पिशाची',
    'पिशिताश',
    'पिशुन',
    'पिशुनता',
    'पिश्चम',
    'पिष्ट',
    'पिष्टन',
    'पिसनहार',
    'पिसना',
    'पिसराज',
    'पिसवैया',
    'पिसा',
    'पिसाई',
    'पिसान',
    'पिसी',
    'पिसैया',
    'पिसौनी',
    'पिस्टन',
    'पिस्ता',
    'पिस्तौल',
    'पिस्सू',
    'पिहक',
    'पिहान',
    'पिहित',
    'पी',
    'पींग',
    'पींजन',
    'पींजना',
    'पींजरा',
    'पींडी',
    'पींपीं',
    'पीए',
    'पीऐस',
    'पीक',
    'पीकदान',
    'पीकर',
    'पीको',
    'पीच',
    'पीचना',
    'पीछ',
    'पीछल',
    'पीछा',
    'पीछू',
    'पीछे',
    'पीटते',
    'पीटना',
    'पीटने',
    'पीठ',
    'पीठाभ्रय',
    'पीठिका',
    'पीठी',
    'पीठीः',
    'पीडन',
    'पीड़क',
    'पीड़कनाशी',
    'पीड़ग्रस्त',
    'पीड़ा',
    'पीड़ादायक',
    'पीड़ानाशक',
    'पीड़ाप्रद',
    'पीड़ाभास',
    'पीड़ित',
    'पीडा',
    'पीडाहर',
    'पीडित',
    'पीढ़ा',
    'पीढ़ी',
    'पीढा',
    'पीढी',
    'पीतकंठ',
    'पीतल',
    'पीतसरा',
    'पीता',
    'पीतिमा',
    'पीते',
    'पीनल',
    'पीना',
    'पीने',
    'पीपनी',
    'पीपल',
    'पीपहोल',
    'पीपा',
    'पीपाकार',
    'पीपी',
    'पीयूष',
    'पीयूषक',
    'पीयूषिद',
    'पीर',
    'पीरान',
    'पीरियड',
    'पीरियडिकल',
    'पीरी',
    'पीरे',
    'पील',
    'पीलवान',
    'पीला',
    'पीलापन',
    'पीलिया',
    'पीलियाग्रस्त',
    'पीली',
    'पीलु',
    'पीलुक',
    'पीले',
    'पीलेपन',
    'पीव',
    'पीवन',
    'पीवर',
    'पीवरस्तनी',
    'पीवरी',
    'पीस',
    'पीसना',
    'पीसने',
    'पीसा',
    'पीसी',
    'पीहर',
    'पुँछल्ला',
    'पुँछाई',
    'पुंकेसरी',
    'पुंख',
    'पुंगल',
    'पुंगव',
    'पुंगी',
    'पुंज',
    'पुंजन',
    'पुंजन्म',
    'पुंजित',
    'पुंजीय',
    'पुंडरीक',
    'पुंडर्य',
    'पुंड्र',
    'पुंरत्न',
    'पुंवत',
    'पुंश्चल',
    'पुंश्चली',
    'पुंसक',
    'पुंसकतापूर्ण',
    'पुंसत्वहीन',
    'पुंसवती',
    'पुंसवन',
    'पुंसवान',
    'पुंस्त्व',
    'पुंस्त्वपूर्ण',
    'पुंस्त्वहीन',
    'पुंस्त्वहीनता',
    'पुआ',
    'पुआल',
    'पुकड',
    'पुकार',
    'पुकारना',
    'पुकारने',
    'पुकारा',
    'पुकुर',
    'पुक्कश',
    'पुक्कशी',
    'पुखराज',
    'पुखराजी',
    'पुख़ता',
    'पुख़्त',
    'पुख़्तगी',
    'पुख़्ता',
    'पुख्तगी',
    'पुग',
    'पुगव',
    'पुगाना',
    'पुच',
    'पुचकार',
    'पुचकारना',
    'पुचकारी',
    'पुचारना',
    'पुचारा',
    'पुच्छ',
    'पुच्छकंटक',
    'पुच्छल',
    'पुच्छहीन',
    'पुच्छोछेदन',
    'पुछल्ला',
    'पुछवैया',
    'पुछिया',
    'पुजना',
    'पुजा',
    'पुजाई',
    'पुजाना',
    'पुजापा',
    'पुजाया',
    'पुजारिन',
    'पुजारी',
    'पुजारीवाद',
    'पुजाही',
    'पुजिक',
    'पुजेला',
    'पुजैया',
    'पुजौरा',
    'पुट',
    'पुटक',
    'पुटकी',
    'पुटग्रीव',
    'पुटमात्र',
    'पुटर',
    'पुटलिया',
    'पुटिका',
    'पुटित',
    'पुटी',
    'पुटीन',
    'पुट्ठा',
    'पुट्ठे',
    'पुट्ठों',
    'पुडकना',
    'पुडकी',
    'पुडरीकाक्ष',
    'पुड़ा',
    'पुड़िया',
    'पुड़ी',
    'पुड्डुचैरी',
    'पुणे',
    'पुण्य',
    'पुण्यकर्मा',
    'पुण्यकीर्ति',
    'पुण्यगृह',
    'पुण्यजन',
    'पुण्यजनेश्वर',
    'पुण्यतिथि',
    'पुण्यदर्शन',
    'पुण्यपूर्ण',
    'पुण्यबुद्धि',
    'पुण्यभू',
    'पुण्यवान',
    'पुण्यशकुन',
    'पुण्यशील',
    'पुण्यश्लोक',
    'पुण्यश्लोका',
    'पुण्यात्मा',
    'पुण्याह',
    'पुतरन',
    'पुतला',
    'पुतली',
    'पुतले',
    'पुताई',
    'पुत्तल',
    'पुत्तलिका',
    'पुत्र',
    'पुत्रः',
    'पुत्रक',
    'पुत्रकल्प',
    'पुत्रतुल्य',
    'पुत्रवंती',
    'पुत्रवती',
    'पुत्रवान',
    'पुत्रस्थानीय',
    'पुत्रहीन',
    'पुत्रात्राद',
    'पुत्रावलंबी',
    'पुत्रिका',
    'पुत्रिणी',
    'पुत्रियाँ',
    'पुत्रियां',
    'पुत्री',
    'पुत्रीकल्प',
    'पुत्रीतुल्य',
    'पुत्रीस्थानीय',
    'पुत्रेष्टि',
    'पुथल',
    'पुदीना',
    'पुदीनामय',
    'पुदीने',
    'पुद्गल',
    'पुद्गलीय',
    'पुनः',
    'पुनःम',
    'पुनक्रर्य',
    'पुननर्वास',
    'पुननिर्मार्ण',
    'पुननिर्मिर्त',
    'पुनरंगीकरण',
    'पुनरक्तात्मक',
    'पुनरपि',
    'पुनरवतरण',
    'पुनरवतरित',
    'पुनरवलोकन',
    'पुनरांगत',
    'पुनरागत',
    'पुनरागमन',
    'पुनरानयन',
    'पुनरानीत',
    'पुनरारंभतः',
    'पुनरारोपण',
    'पुनरारोपित',
    'पुनरावर्तित',
    'पुनरावर्तिता',
    'पुनरावृत्ति',
    'पुनरावृत्तिहीन',
    'पुनरावृत्तिहीनता',
    'पुनरावृत्तीय',
    'पुनरावेदन',
    'पुनरावेदनीय',
    'पुनरीक्षण',
    'पुनरीक्षित',
    'पुनरुक्ति',
    'पुनरुज्जीवन',
    'पुनरुज्जीवित',
    'पुनरुढ',
    'पुनरुढा',
    'पुनरुत्थान',
    'पुनरुत्थित',
    'पुनरुत्न',
    'पुनरुत्पत्ति',
    'पुनरुत्पन्न',
    'पुनरुत्भूत',
    'पुनरुद्गत',
    'पुनरुद्धारित',
    'पुनरुपोढ',
    'पुनरुपोढा',
    'पुनरूक्ति',
    'पुनरूज्जीवक',
    'पुनर्कथन',
    'पुनर्गठन',
    'पुनर्ग्रहणाधिकार',
    'पुनर्चलन',
    'पुनर्चालित',
    'पुनर्जन्म',
    'पुनर्जन्मवाद',
    'पुनर्जाग्रत',
    'पुनर्जीवन',
    'पुनर्जीवित',
    'पुनर्नवीकरण',
    'पुनर्नवीकृत',
    'पुनर्निक्षेपण',
    'पुनर्नियुक्ति',
    'पुनर्नियोजन',
    'पुनर्निर्धारण',
    'पुनर्निर्माण',
    'पुनर्निर्यात',
    'पुनर्निवेश',
    'पुनर्पठन',
    'पुनर्बीमा',
    'पुनर्भवन',
    'पुनर्भाव',
    'पुनर्भुगतान',
    'पुनर्भूत',
    'पुनर्भूता',
    'पुनर्मनन',
    'पुनर्मूल्यन',
    'पुनर्रचना',
    'पुनर्रचित',
    'पुनर्रापित',
    'पुनर्वसु',
    'पुनर्वादी',
    'पुनर्वास',
    'पुनर्वासन',
    'पुनर्विक्रय',
    'पुनर्विचार',
    'पुनर्वित',
    'पुनर्वितरण',
    'पुनर्वितीय',
    'पुनर्विनियोग',
    'पुनर्विन्यास',
    'पुनर्विलोकन',
    'पुनर्व्यवस्था',
    'पुनर्सीक्षण',
    'पुनर्स्थापना',
    'पुनवासित',
    'पुनविर्चार',
    'पुनविर्चारक',
    'पुनविर्चारण',
    'पुनविर्चारित',
    'पुनविर्धान',
    'पुनविर्वाह',
    'पुनविर्वाहित',
    'पुनविर्वाहिता',
    'पुनव्यवस्थापन',
    'पुनश्च',
    'पुनश्चः',
    'पुनस्थापित',
    'पुनारावृत्ति',
    'पुनि',
    'पुनीत',
    'पुनीतता',
    'पुन्ज',
    'पुन्न',
    'पुन्नाग',
    'पुन्नामा',
    'पुन्यों',
    'पुपलाई',
    'पुप्पित',
    'पुप्फुस',
    'पुमणु',
    'पुयजित',
    'पुर',
    'पुरं',
    'पुरंजन',
    'पुरंदर',
    'पुरंधरी',
    'पुरअमन',
    'पुरकथा',
    'पुरकाल',
    'पुरख',
    'पुरखन',
    'पुरखा',
    'पुरखापन',
    'पुरखार',
    'पुरखिन',
    'पुरखी',
    'पुरखे',
    'पुरज़ा',
    'पुरजा',
    'पुरजे',
    'पुरजोश',
    'पुरतः',
    'पुरदरा',
    'पुरनम',
    'पुरना',
    'पुरनाक्रय',
    'पुरनापन',
    'पुरबिया',
    'पुरबैया',
    'पुररक्षी',
    'पुररथ',
    'पुरवट',
    'पुरवा',
    'पुरवाइ',
    'पुरवाई',
    'पुरवासिनी',
    'पुरवैया',
    'पुरसना',
    'पुरसा',
    'पुरसां',
    'पुरसाई',
    'पुरसिक्रया',
    'पुरस्करण',
    'पुरस्कर्ता',
    'पुरस्कार',
    'पुरस्कारों',
    'पुरस्कृत',
    'पुरस्तात',
    'पुरस्थापना',
    'पुरस्मरण',
    'पुरस्सारण',
    'पुरहा',
    'पुरा',
    'पुरांतक',
    'पुराई',
    'पुराकथा',
    'पुराकलीन',
    'पुराकलीनता',
    'पुराकल्प',
    'पुराकाल',
    'पुराकालीन',
    'पुराकृत',
    'पुराख्यान',
    'पुराजीवी',
    'पुराण',
    'पुराणकालीन',
    'पुराणपंथी',
    'पुराणरचेता',
    'पुराणीय',
    'पुरातत्त्व',
    'पुरातत्व',
    'पुरातत्वविज्ञान',
    'पुरातत्वविद',
    'पुरातन',
    'पुरातनता',
    'पुरातनवाद',
    'पुरातनवादी',
    'पुरातनिक',
    'पुरातित्त्वकी',
    'पुराती',
    'पुराधिप',
    'पुराना',
    'पुरानापन',
    'पुरानी',
    'पुरानीदुनिया',
    'पुरानीयादें',
    'पुराने',
    'पुरानेपन',
    'पुरापाषाण',
    'पुरापाषाणी',
    'पुराप्रस्तर',
    'पुरायुगीनन्याय',
    'पुरालिपि',
    'पुरालिपिज्ञ',
    'पुरालिपिशास्त्री',
    'पुरालेख',
    'पुरालेखविद्या',
    'पुरालेखवेत्ता',
    'पुरालेखागार',
    'पुरावनस्पति',
    'पुरावर्तन',
    'पुरावशेष',
    'पुरावसु',
    'पुरावस्तु',
    'पुरावासी',
    'पुरावृत्त',
    'पुरावेक्षण',
    'पुरावेत्ता',
    'पुरावेदन',
    'पुराशास्त्र',
    'पुरिया',
    'पुरी',
    'पुरीतत',
    'पुरीष',
    'पुरीषाहारी',
    'पुरु',
    'पुरुरवा',
    'पुरुश',
    'पुरुष',
    'पुरुषख्यातनु',
    'पुरुषत्व',
    'पुरुषपशु',
    'पुरुषरुपी',
    'पुरुषवत',
    'पुरुषवाचक',
    'पुरुषसदृश',
    'पुरुषाणु',
    'पुरुषाद',
    'पुरुषाद्य',
    'पुरुषाधम',
    'पुरुषामित',
    'पुरुषार्थ',
    'पुरुषार्थता',
    'पुरुषार्थहीन',
    'पुरुषार्थहीनता',
    'पुरुषार्थी',
    'पुरुषास्थिमाली',
    'पुरुषीय',
    'पुरुषेंद्र',
    'पुरुषेंद्रिय',
    'पुरुषों',
    'पुरुषोचित',
    'पुरुषोत्तम',
    'पुरूष',
    'पुरूषार्थ',
    'पुरोगंता',
    'पुरोगत',
    'पुरोगम',
    'पुरोगमन',
    'पुरोगामी',
    'पुरोडाश',
    'पुरोधा',
    'पुरोधिका',
    'पुरोबंध',
    'पुरोभाग',
    'पुरोभूमि',
    'पुरोवचन',
    'पुरोवाद',
    'पुरोहि',
    'पुरोहित',
    'पुरोहितनी',
    'पुरोहितवाद',
    'पुरोहिताई',
    'पुरोहिताश्रम',
    'पुरोहिती',
    'पुरोहितीय',
    'पुरौनी',
    'पुर्जा',
    'पुर्ण',
    'पुर्तगाली',
    'पुर्ननिर्माण',
    'पुर्नवैधिकरण',
    'पुल',
    'पुलक',
    'पुलकना',
    'पुलकांग',
    'पुलकालय',
    'पुलकावली',
    'पुलकित',
    'पुलट',
    'पुलटा',
    'पुलटिस',
    'पुलपिट',
    'पुलस्त्य',
    'पुलह',
    'पुला',
    'पुलाक',
    'पुलाव',
    'पुलिंदा',
    'पुलिन',
    'पुलिनरोध',
    'पुलिनवती',
    'पुलिन्दा',
    'पुलिया',
    'पुलिस',
    'पुलिसकर्मी',
    'पुलिसमैन',
    'पुली',
    'पुलोमजा',
    'पुलोमा',
    'पुलोमामारि',
    'पुलोवर',
    'पुवाल',
    'पुवावज',
    'पुश्त',
    'पुश्तनामा',
    'पुश्ता',
    'पुश्तापुश्त',
    'पुश्ती',
    'पुश्तैनी',
    'पुषित',
    'पुष्कर',
    'पुष्करिणी',
    'पुष्करीय',
    'पुष्कल',
    'पुष्कलक',
    'पुष्कलता',
    'पुष्ट',
    'पुष्टता',
    'पुष्टि',
    'पुष्टिकर',
    'पुष्टिकरण',
    'पुष्टिकर्ता',
    'पुष्टिका',
    'पुष्टिकारक',
    'पुष्टिकृत',
    'पुष्टिदायकता',
    'पुष्टिपंधय',
    'पुष्टिप्रद',
    'पुष्टिमार्ग',
    'पुष्टिव',
    'पुष्टीकृत',
    'पुष्ठ',
    'पुष्प',
    'पुष्पं',
    'पुष्पक',
    'पुष्पकेतु',
    'पुष्पगुच्छी',
    'पुष्पज',
    'पुष्पजवी',
    'पुष्पजीव',
    'पुष्पजीवी',
    'पुष्पज्ञ',
    'पुष्पद',
    'पुष्पदंत',
    'पुष्पदंती',
    'पुष्पदल',
    'पुष्पदेह',
    'पुष्पदेहता',
    'पुष्पदेही',
    'पुष्पधन्वा',
    'पुष्पध्वज',
    'पुष्पन',
    'पुष्पपदवी',
    'पुष्पभव',
    'पुष्पमय',
    'पुष्पमाला',
    'पुष्पयुक्त',
    'पुष्परज',
    'पुष्परहित',
    'पुष्पराग',
    'पुष्पराजक',
    'पुष्पलता',
    'पुष्पवती',
    'पुष्पवत्र्मा',
    'पुष्पवदन',
    'पुष्पवाण',
    'पुष्पविज्ञानी',
    'पुष्पवृत्न',
    'पुष्पवृन्त',
    'पुष्पशर',
    'पुष्पशाक',
    'पुष्पसार',
    'पुष्पसारा',
    'पुष्पहार',
    'पुष्पहास',
    'पुष्पहीन',
    'पुष्पहीना',
    'पुष्पा',
    'पुष्पांकुर',
    'पुष्पांगता',
    'पुष्पांजलि',
    'पुष्पांबुज',
    'पुष्पागम',
    'पुष्पाभिषेक',
    'पुष्पायुध',
    'पुष्पार्पण',
    'पुष्पालंकृत',
    'पुष्पावली',
    'पुष्पासन',
    'पुष्पासव',
    'पुष्पासार',
    'पुष्पास्तरण',
    'पुष्पिका',
    'पुष्पित',
    'पुष्पिता',
    'पुष्पी',
    'पुष्पेषु',
    'पुष्पों',
    'पुष्पोत्कटा',
    'पुष्पोद्गम',
    'पुष्पोपहार',
    'पुष्प्',
    'पुष्य',
    'पुसंकता',
    'पुसुर',
    'पुस्त',
    'पुस्तक',
    'पुस्तकः',
    'पुस्तकागार',
    'पुस्तकालय',
    'पुस्तकालयाध्यक्षता',
    'पुस्तकावली',
    'पुस्तकास्तरण',
    'पुस्तकें',
    'पुस्तकों',
    'पुस्ताकालयाध्यक्ष',
    'पुस्तिका',
    'पुस्तिकाएं',
    'पुस्त्वहरण',
    'पुहंचाना',
    'पुहुकर',
    'पुहुकार',
    'पुहुप',
    'पुहुमी',
    'पुूतो',
    'पुज़रे',
    'पूँगा',
    'पूँछ',
    'पूँछःपंख',
    'पूँछताछ',
    'पूँछना',
    'पूँछहीन',
    'पूँजी',
    'पूँजीगत',
    'पूँजीपति',
    'पूँजीवाद',
    'पूँजीवादी',
    'पूंछ',
    'पूंछना',
    'पूंजी',
    'पूंजीकरण',
    'पूंजीगत',
    'पूंजीनिवेश',
    'पूंजीमूलक',
    'पूंरपट',
    'पूग',
    'पूगपीठ',
    'पूगी',
    'पूछ',
    'पूछकर',
    'पूछगछ',
    'पूछताछ',
    'पूछना',
    'पूछने',
    'पूछनेवाला',
    'पूछल्ला',
    'पूछा',
    'पूछाताछी',
    'पूछापाछी',
    'पूछिए',
    'पूजक',
    'पूजन',
    'पूजनकर्म',
    'पूजना',
    'पूजनी',
    'पूजनीय',
    'पूजयिता',
    'पूजा',
    'पूजाः',
    'पूजाकक्ष',
    'पूजागृह',
    'पूजाघर',
    'पूजापाठी',
    'पूजार्पण',
    'पूजाविधि',
    'पूजावेदी',
    'पूजित',
    'पूजोपकरण',
    'पूज्य',
    'पूज्यता',
    'पूटू',
    'पूड़ा',
    'पूड़ी',
    'पूणा',
    'पूण्य',
    'पूण्रप्रतिज्ञ',
    'पूत',
    'पूतन',
    'पूतना',
    'पूतनारि',
    'पूतनिका',
    'पूतपाप',
    'पूतात्मा',
    'पूति',
    'पूतिक',
    'पूतिनी',
    'पूती',
    'पूत्कारी',
    'पूनम',
    'पूनर्जन्म',
    'पूनी',
    'पूनों',
    'पून्यो',
    'पूप',
    'पूपा',
    'पूय',
    'पूयन',
    'पूयारि',
    'पूयिक',
    'पूयित',
    'पूर',
    'पूरंपट',
    'पूरक',
    'पूरकता',
    'पूरण',
    'पूरणसंख्या',
    'पूरना',
    'पूरब',
    'पूरबी',
    'पूरमपुर',
    'पूरयिता',
    'पूरा',
    'पूरापन',
    'पूरिक',
    'पूरित',
    'पूरिया',
    'पूरी',
    'पूरे',
    'पूर्ण',
    'पूर्णकाम',
    'पूर्णकाय',
    'पूर्णकाल',
    'पूर्णकालिक',
    'पूर्णकृत',
    'पूर्णगर्भा',
    'पूर्णज्ञ',
    'पूर्णतः',
    'पूर्णतम',
    'पूर्णतया',
    'पूर्णतर',
    'पूर्णता',
    'पूर्णतावाद',
    'पूर्णतावादी',
    'पूर्णप्रतिज्ञ',
    'पूर्णबाली',
    'पूर्णभंजी',
    'पूर्णभूत',
    'पूर्णमनोरथ',
    'पूर्णमासी',
    'पूर्णमिंद',
    'पूर्णयोग',
    'पूर्णरूप',
    'पूर्णरूपेण',
    'पूर्णविद्य',
    'पूर्णव्यापिता',
    'पूर्णशक्ति',
    'पूर्णशिक',
    'पूर्णश्री',
    'पूर्णहित',
    'पूर्णहोम',
    'पूर्णा',
    'पूर्णांक',
    'पूर्णांकन',
    'पूर्णांकित',
    'पूर्णाक',
    'पूर्णाकार',
    'पूर्णाक्षरी',
    'पूर्णागता',
    'पूर्णाधिकारी',
    'पूर्णामृता',
    'पूर्णायु',
    'पूर्णायुष्य',
    'पूर्णावकाश',
    'पूर्णावतार',
    'पूर्णावधि',
    'पूर्णावयव',
    'पूर्णाश',
    'पूर्णाशतः',
    'पूर्णाशता',
    'पूर्णाशिक',
    'पूर्णाशिकता',
    'पूर्णिमा',
    'पूर्णिमाः',
    'पूर्णीय',
    'पूर्णीयता',
    'पूर्णेच्छ',
    'पूर्णेदु',
    'पूर्त',
    'पूर्तव्रत',
    'पूर्तसंकल्प',
    'पूर्तसकल्प',
    'पूर्ति',
    'पूर्तिः',
    'पूर्तिकर',
    'पूर्तिकार',
    'पूर्तिदोष',
    'पूर्ती',
    'पूर्व',
    'पूर्वक',
    'पूर्वकथित',
    'पूर्वकल्प',
    'पूर्वकल्पित',
    'पूर्वकाल',
    'पूर्वकालीन',
    'पूर्वक्रय',
    'पूर्वगत',
    'पूर्वगामी',
    'पूर्वग्ररह',
    'पूर्वग्रह',
    'पूर्वग्रहणाधिकार',
    'पूर्वघटित',
    'पूर्वज',
    'पूर्वजता',
    'पूर्वजन्मः',
    'पूर्वजवाद',
    'पूर्वजा',
    'पूर्वजात',
    'पूर्वजातक',
    'पूर्वजातता',
    'पूर्वज्ञान',
    'पूर्वतः',
    'पूर्वतन',
    'पूर्वतर',
    'पूर्वता',
    'पूर्वदृष्टि',
    'पूर्वधारणा',
    'पूर्वनियतन',
    'पूर्वनियोजित',
    'पूर्वनिर्धारित',
    'पूर्वनिर्माण',
    'पूर्वनिश्चय',
    'पूर्वन्न',
    'पूर्वपक्वता',
    'पूर्वपक्ष',
    'पूर्वपक्षी',
    'पूर्वपद',
    'पूर्वपरीक्षण',
    'पूर्वपीठिका',
    'पूर्वपुरुष',
    'पूर्वप्रभावी',
    'पूर्वभावी',
    'पूर्वभूत',
    'पूर्वरंग',
    'पूर्वरुप',
    'पूर्ववत',
    'पूर्ववतः',
    'पूर्ववता',
    'पूर्ववर्तिता',
    'पूर्ववर्ती',
    'पूर्वविश्वविद्यालयी',
    'पूर्ववृत',
    'पूर्ववृत्त',
    'पूर्वव्यापी',
    'पूर्वसंकल्पना',
    'पूर्वसंगीत',
    'पूर्वसर',
    'पूर्वसर्ग',
    'पूर्वसूचना',
    'पूर्वस्थ',
    'पूर्वस्थापना',
    'पूर्वस्थिति',
    'पूर्वस्नातक',
    'पूर्वा',
    'पूर्वांचल',
    'पूर्वाग्रह',
    'पूर्वाग्रहपूर्ण',
    'पूर्वाग्रहहीन',
    'पूर्वाग्राही',
    'पूर्वाचार्य',
    'पूर्वातिम',
    'पूर्वाधिकार',
    'पूर्वानिल',
    'पूर्वानुदान',
    'पूर्वानुमान',
    'पूर्वानुमानकर्ता',
    'पूर्वानुमानन',
    'पूर्वानुमानित',
    'पूर्वानुमानी',
    'पूर्वानुमोदन',
    'पूर्वान्तिम',
    'पूर्वापर',
    'पूर्वापरता',
    'पूर्वाभास',
    'पूर्वाभिमुखीकरण',
    'पूर्वाभ्यास',
    'पूर्वार्ध',
    'पूर्वाल',
    'पूर्वावेदक',
    'पूर्वाशा',
    'पूर्वाश्रयिता',
    'पूर्वाश्रयी',
    'पूर्वास्वादन',
    'पूर्वाह्न',
    'पूर्वाह्ल',
    'पूर्वी',
    'पूर्वीय',
    'पूर्वीर्क्त',
    'पूर्वोक्त',
    'पूर्वोत्तर',
    'पूल',
    'पूला',
    'पूली',
    'पूल्य',
    'पूवर्ज',
    'पूवा',
    'पूवाह्न',
    'पूवोर्त्तर',
    'पूवोर्दय',
    'पूष',
    'पूषण',
    'पूषन',
    'पूषा',
    'पूषात्मज',
    'पूस',
    'पृच्छक',
    'पृच्छा',
    'पृच्छित',
    'पृच्दन',
    'पृतना',
    'पृतन्यु',
    'पृथक',
    'पृथककारक',
    'पृथकचर',
    'पृथकचरता',
    'पृथकचर्या',
    'पृथकता',
    'पृथकतावाद',
    'पृथकतावादी',
    'पृथकत्व',
    'पृथकदलीय',
    'पृथकपिंड',
    'पृथक्करण',
    'पृथक्करणीय',
    'पृथक्कारी',
    'पृथक्किया',
    'पृथक्कुल',
    'पृथक्कृत',
    'पृथक्तावाद',
    'पृथक्तावादी',
    'पृथगात्मा',
    'पृथग्गात्र',
    'पृथवी',
    'पृथा',
    'पृथापति',
    'पृथासुत',
    'पृथिवी',
    'पृथु',
    'पृथुल',
    'पृथुलता',
    'पृथूदर',
    'पृथ्वी',
    'पृथ्वीगर्भ',
    'पृथ्वीतर',
    'पृथ्वीतरराकेट',
    'पृथ्वीधर',
    'पृथ्वीनाथ',
    'पृथ्वीपुत्र',
    'पृथ्वीय',
    'पृथ्वीराज',
    'पृथ्वीलोक',
    'पृदाकु',
    'पृशत',
    'पृषाकरा',
    'पृषोदर',
    'पृष्ट',
    'पृष्टभूमि',
    'पृष्टि',
    'पृष्ठ',
    'पृष्ठग',
    'पृष्ठतः',
    'पृष्ठता',
    'पृष्ठदंड',
    'पृष्ठनाद',
    'पृष्ठपट',
    'पृष्ठपरदा',
    'पृष्ठप्रेषण',
    'पृष्ठभाग',
    'पृष्ठभूमि',
    'पृष्ठभूमिक',
    'पृष्ठय',
    'पृष्ठर्ती',
    'पृष्ठवर्तिता',
    'पृष्ठषोषण',
    'पृष्ठस्थ',
    'पृष्ठस्थता',
    'पृष्ठांक',
    'पृष्ठांकक',
    'पृष्ठांकन',
    'पृष्ठांकित',
    'पृष्ठाकार',
    'पृष्ठागमन',
    'पृष्ठाधान',
    'पृष्ठानीक',
    'पृष्ठारक्षी',
    'पृष्ठावरण',
    'पृष्ठास्थित',
    'पृष्ठिका',
    'पृष्ठित',
    'पृष्ठीय',
    'पृष्ठों',
    'पृष्णि',
    'पृिश्न',
    'पॄक्ष',
    'पॄक्षी',
    'पॄद्घति',
    'पे',
    'पेंग',
    'पेंच',
    'पेंचकश',
    'पेंचदार',
    'पेंचीदा',
    'पेंट',
    'पेंटिंग',
    'पेंठ',
    'पेंडुकी',
    'पेंतरा',
    'पेंतरेबाजी',
    'पेंदा',
    'पेंदी',
    'पेंदे',
    'पेंपें',
    'पेंशन',
    'पेंशनभोगी',
    'पेंशनयोग्य',
    'पेंशनर',
    'पेंशनी',
    'पेंसिल',
    'पेंसिलीन',
    'पेएबल',
    'पेच',
    'पेचक',
    'पेचकन',
    'पेचकश',
    'पेचकस',
    'पेचकी',
    'पेचदार',
    'पेचन',
    'पेचपाच',
    'पेचित',
    'पेचिल',
    'पेचिश',
    'पेचिस',
    'पेचीदगी',
    'पेचीदा',
    'पेचीला',
    'पेचीलापन',
    'पेचीलापेचीली',
    'पेचोखम',
    'पेचोताब',
    'पेछी',
    'पेज',
    'पेजी',
    'पेट',
    'पेटक',
    'पेटकैयां',
    'पेटबन्द',
    'पेटभर',
    'पेटल',
    'पेटा',
    'पेटारा',
    'पेटिका',
    'पेटियां',
    'पेटियों',
    'पेटी',
    'पेटीकोट',
    'पेटू',
    'पेटूपन',
    'पेटेंट',
    'पेटैंट',
    'पेटैंटी',
    'पेटैंटीकृत',
    'पेट्रोल',
    'पेट्रोलियम',
    'पेठ',
    'पेठा',
    'पेड',
    'पेड़',
    'पेड़ों',
    'पेडा',
    'पेडी',
    'पेडू',
    'पेढ़ी',
    'पेन्ट',
    'पेन्टर',
    'पेपर',
    'पेपरबैक',
    'पेबन्द',
    'पेय',
    'पेयकक्ष',
    'पेयूष',
    'पेर',
    'पेरज',
    'पेरना',
    'पेरने',
    'पेरमी',
    'पेरा',
    'पेराई',
    'पेराशूट',
    'पेरिस',
    'पेल',
    'पेलना',
    'पेलव',
    'पेला',
    'पेलाई',
    'पेवमैंट',
    'पेवसी',
    'पेवा',
    'पेश',
    'पेशकब्ज',
    'पेशकर्ता',
    'पेशकश',
    'पेशकार',
    'पेशखेमा',
    'पेशगाह',
    'पेशगी',
    'पेशतर',
    'पेशदसती',
    'पेशबंदी',
    'पेशबन्दी',
    'पेशरवी',
    'पेशराज',
    'पेशल',
    'पेशवा',
    'पेशवाई',
    'पेशवाज',
    'पेशा',
    'पेशानी',
    'पेशाब',
    'पेशियों',
    'पेशी',
    'पेशीनगोई',
    'पेशीय',
    'पेशीयां',
    'पेशे',
    'पेशेवर',
    'पेशेवरी',
    'पेशोपस',
    'पेषण',
    'पेषणी',
    'पेषी',
    'पेष्ट',
    'पेष्टन',
    'पेस्ट',
    'पेस्टनाशी',
    'पेस्टरी',
    'पेस्टल',
    'पेस्ट्री',
    'पेहँटा',
    'पैंकड़ा',
    'पैंच',
    'पैंचरंगा',
    'पैंचा',
    'पैंट',
    'पैंटहाउस',
    'पैंटोमाइम',
    'पैंठ',
    'पैंडल',
    'पैंड़',
    'पैंडुलम',
    'पैंत',
    'पैंतरा',
    'पैंतरी',
    'पैंतरेबाजी',
    'पैंदा',
    'पैंदी',
    'पैंदे',
    'पैंफ्रलेट',
    'पैंरों',
    'पैंशन',
    'पैंशनर',
    'पैंसिल',
    'पैंसिलदान',
    'पैंसिलदानी',
    'पैक',
    'पैकर',
    'पैकिंग',
    'पैकिट',
    'पैकी',
    'पैकेज',
    'पैकेजिंग',
    'पैकेजित',
    'पैकेजों',
    'पैकेज्ड',
    'पैकेट',
    'पैक्ड',
    'पैग',
    'पैगंबर',
    'पैगंबरवाद',
    'पैगंबरी',
    'पैगम्बर',
    'पैगाम',
    'पैगामबर',
    'पैगामी',
    'पैचरंगापन',
    'पैचवर्क',
    'पैजनिया',
    'पैजनी',
    'पैजली',
    'पैजामा',
    'पैजार',
    'पैटर्न',
    'पैटी',
    'पैटेंटिड',
    'पैटेंटित',
    'पैट्रियार्क',
    'पैट्रोकैमिकल्स',
    'पैट्रोमैक्स',
    'पैट्रोल',
    'पैट्रोलमैन',
    'पैट्रोलियम',
    'पैठ',
    'पैठन',
    'पैठना',
    'पैठा',
    'पैठाना',
    'पैठार',
    'पैठी',
    'पैड',
    'पैडरा',
    'पैडरी',
    'पैडल',
    'पैड़ियाँ',
    'पैड़ी',
    'पैड़ीदार',
    'पैडी',
    'पैडैंट',
    'पैतरा',
    'पैताना',
    'पैताम',
    'पैतामह',
    'पैतृक',
    'पैतृकता',
    'पैतृकी',
    'पैत्तव्य',
    'पैत्रकता',
    'पैत्रिक',
    'पैथला',
    'पैथोलाजिकल',
    'पैथोलाजिस्ट',
    'पैदल',
    'पैदा',
    'पैदाइश',
    'पैदाइशी',
    'पैदायशी',
    'पैदावार',
    'पैन',
    'पैनः',
    'पैनकेक',
    'पैनल',
    'पैनल्टिमेट',
    'पैना',
    'पैनाना',
    'पैनापन',
    'पैनिनशुला',
    'पैनी',
    'पैन्ट',
    'पैन्ट्री',
    'पैन्य',
    'पैपसिन',
    'पैपिंग',
    'पैप्सीकोला',
    'पैबंद',
    'पैबंदकारी',
    'पैबंददार',
    'पैबंदी',
    'पैबन्द',
    'पैमक',
    'पैमाइश',
    'पैमाना',
    'पैमाने',
    'पैम्फ्लेटबाजी',
    'पैम्फ्लैटबाज',
    'पैया',
    'पैयाँ',
    'पैर',
    'पैरः',
    'पैरना',
    'पैरवी',
    'पैरवीकार',
    'पैरहन',
    'पैरहा',
    'पैरा',
    'पैरांबुलेटर',
    'पैराई',
    'पैराक',
    'पैरागोला',
    'पैराग्राफ',
    'पैराट्रुप',
    'पैराट्रूपर',
    'पैराना',
    'पैरामीटर',
    'पैराशूट',
    'पैराशूटिंग',
    'पैराशूटिस्ट',
    'पैरी',
    'पैरेलल',
    'पैरेलैलिपिपैड',
    'पैरैलैलिज्म',
    'पैरो',
    'पैरों',
    'पैरोंवाला',
    'पैरोकार',
    'पैरोडी',
    'पैरोल',
    'पैलिकन',
    'पैली',
    'पैलेट',
    'पैलेस',
    'पैल्ट',
    'पैवस्त',
    'पैशाच',
    'पैशाचिक',
    'पैशाचिकता',
    'पैशाची',
    'पैसा',
    'पैसे',
    'पैसेंजर',
    'पैसेज',
    'पैस्टोरल',
    'पॉलि',
    'पॉलिटेकनिक',
    'पॉलिसी',
    'पॉलिसीधारकों',
    'पो',
    'पोंगा',
    'पोंगापंथी',
    'पोंगी',
    'पोंछ',
    'पोंछकर',
    'पोंछना',
    'पोंछने',
    'पोंछा',
    'पोंटा',
    'पोइया',
    'पोई',
    'पोएट्र',
    'पोखनरी',
    'पोखर',
    'पोखरा',
    'पोखरी',
    'पोग्राम',
    'पोच',
    'पोचार',
    'पोचारा',
    'पोज',
    'पोज़',
    'पोजीट्रोन',
    'पोजीशन',
    'पोट',
    'पोटना',
    'पोटरी',
    'पोटलक',
    'पोटला',
    'पोटली',
    'पोटा',
    'पोटाश',
    'पोटाशियम',
    'पोटी',
    'पोट्रेट',
    'पोढ़ा',
    'पोत',
    'पोतक',
    'पोतकर',
    'पोतकारखाना',
    'पोतड़ा',
    'पोतदार',
    'पोतना',
    'पोतनाविक',
    'पोतनीस',
    'पोतने',
    'पोतपति',
    'पोतला',
    'पोतवाह',
    'पोतशिल्पी',
    'पोता',
    'पोतांतरण',
    'पोताग्र',
    'पोताध्यक्ष',
    'पोताम्रय',
    'पोतारा',
    'पोतारोहण',
    'पोतावरोहण',
    'पोताश्रय',
    'पोती',
    'पोतीय',
    'पोते',
    'पोत्कारखाना',
    'पोत्या',
    'पोत्र',
    'पोथा',
    'पोथी',
    'पोदना',
    'पोदीना',
    'पोदीने',
    'पोदेना',
    'पोद्दार',
    'पोना',
    'पोप',
    'पोपट',
    'पोपपद',
    'पोपला',
    'पोपली',
    'पोपवाद',
    'पोमेड',
    'पोया',
    'पोर',
    'पोरवा',
    'पोरा',
    'पोरेष्ठ',
    'पोर्क',
    'पोर्च',
    'पोर्ट',
    'पोर्टफ्रोलियो',
    'पोर्टर',
    'पोर्टहोल',
    'पोर्टिको',
    'पोर्टे',
    'पोर्ट्रेकार',
    'पोर्सलिन',
    'पोल',
    'पोला',
    'पोलापन',
    'पोलिंद',
    'पोलिया',
    'पोलियो',
    'पोलिस',
    'पोली',
    'पोले',
    'पोलैण्ड',
    'पोलो',
    'पोल्का',
    'पोल्ट्री',
    'पोशण',
    'पोशाक',
    'पोशिशसाज़',
    'पोशी',
    'पोशीदगी',
    'पोशीदा',
    'पोषक',
    'पोषकता',
    'पोषण',
    'पोषणधीन',
    'पोषणविद',
    'पोषणहीनता',
    'पोषणाभाव',
    'पोषयिता',
    'पोषव्य',
    'पोषामिन',
    'पोषाहार',
    'पोषिका',
    'पोषित',
    'पोषिता',
    'पोषिन',
    'पोष्य',
    'पोस',
    'पोसट',
    'पोसना',
    'पोसा',
    'पोसाइडन',
    'पोस्ट',
    'पोस्टग्रैजुएट',
    'पोस्टमार्टम',
    'पोस्टमास्टर',
    'पोस्टमैन',
    'पोस्टर',
    'पोस्टल',
    'पोस्टिंग',
    'पोस्टेज',
    'पोस्त',
    'पोस्तदाना',
    'पोस्तमाल',
    'पोस्ता',
    'पोस्ती',
    'पोस्तीन',
    'पोस्तीनदोज',
    'पोस्तीपन',
    'पोहना',
    'पोहर',
    'पोहा',
    'पोहिया',
    'पोज़',
    'पौ',
    'पौंछा',
    'पौंटिफ',
    'पौंड',
    'पौंडरीय',
    'पौंडर्य',
    'पौंडी',
    'पौंडेज',
    'पौंढना',
    'पौंपौं',
    'पौंरना',
    'पौंश्चलीय',
    'पौंश्चल्य',
    'पौंसरा',
    'पौआ',
    'पौक्स',
    'पौजीटिव',
    'पौट',
    'पौटरी',
    'पौड़ना',
    'पौड़ी',
    'पौढ़ना',
    'पौढ़ा',
    'पौण्ड',
    'पौतिक',
    'पौत्र',
    'पौत्री',
    'पौद',
    'पौदर',
    'पौदा',
    'पौदागाह',
    'पौद्गल',
    'पौद्गलिक',
    'पौध',
    'पौधशाला',
    'पौधा',
    'पौधे',
    'पौधों',
    'पौन',
    'पौनःपुनिक',
    'पौनटून',
    'पौनधर',
    'पौना',
    'पौनापुन्यं',
    'पौनिकता',
    'पौनी',
    'पौने',
    'पौर',
    'पौरंदर',
    'पौरत्व',
    'पौरना',
    'पौरांगना',
    'पौराण',
    'पौराणक',
    'पौराणिक',
    'पौराणिकता',
    'पौरातनिक',
    'पौरिक',
    'पौरिणी',
    'पौरिया',
    'पौरी',
    'पौरुष',
    'पौरुषहीन',
    'पौरुषहीनता',
    'पौरुषिद',
    'पौरुषी',
    'पौरुषेय',
    'पौरुषेयता',
    'पौरुष्य',
    'पौरुहूत',
    'पौरेय',
    'पौरोधस',
    'पौरोहित्य',
    'पौर्णमासी',
    'पौर्व',
    'पौर्वात्य',
    'पौल',
    'पौलस्ती',
    'पौलस्त्य',
    'पौलि',
    'पौलिंग',
    'पौलिनेशियाई',
    'पौलिपैक',
    'पौलियस्टर',
    'पौली',
    'पौलोम',
    'पौलोमी',
    'पौवा',
    'पौश्चर',
    'पौष',
    'पौष्कर',
    'पौष्टिक',
    'पौष्टिकता',
    'पौष्ण',
    'पौष्य',
    'पौसरा',
    'पौसला',
    'पौसेरा',
    'प्क्ष',
    'प्क्षोद्भव',
    'प्क्ष्म',
    'प्क्ष्मल',
    'प्खान',
    'प्ढत',
    'प्धान',
    'प्यंबर',
    'प्याऊ',
    'प्याज',
    'प्याजी',
    'प्यादा',
    'प्यादापा',
    'प्यादापाई',
    'प्याम',
    'प्यार',
    'प्यारा',
    'प्यारी',
    'प्याला',
    'प्यालिका',
    'प्यालियां',
    'प्याली',
    'प्याले',
    'प्यास',
    'प्यासहीन',
    'प्यासा',
    'प्यासापन',
    'प्यासे',
    'प्यू',
    'प्यूपा',
    'प्यो',
    'प्र',
    'प्रंबंधक',
    'प्रंबधक',
    'प्रंलबक',
    'प्रंलबारि',
    'प्रंशसनीय',
    'प्रंशसा',
    'प्रंशसित',
    'प्रंसस्क्रिय',
    'प्रकंप',
    'प्रकंपन',
    'प्रकंपित',
    'प्रकंपी',
    'प्रकट',
    'प्रकटतः',
    'प्रकटता',
    'प्रकटन',
    'प्रकटना',
    'प्रकटित',
    'प्रकटीकरण',
    'प्रकटीकरणतः',
    'प्रकटीभवन',
    'प्रकट्य',
    'प्रकथन',
    'प्रकन्द',
    'प्रकरण',
    'प्रकरणगत',
    'प्रकरणतः',
    'प्रकर्ष',
    'प्रकर्षक',
    'प्रकर्षित',
    'प्रकल्प',
    'प्रकल्पक',
    'प्रकल्पन',
    'प्रकल्पना',
    'प्रकल्पित',
    'प्रकशन',
    'प्रकसना',
    'प्रकांड',
    'प्रकाण्ड',
    'प्रकाम',
    'प्रकार',
    'प्रकारता',
    'प्रकारान्तर',
    'प्रकारीय',
    'प्रकारों',
    'प्रकार्य',
    'प्रकार्यवाद',
    'प्रकार्यवादी',
    'प्रकार्यात्मक',
    'प्रकाश',
    'प्रकाशः',
    'प्रकाशक',
    'प्रकाशगृह',
    'प्रकाशद',
    'प्रकाशन',
    'प्रकाशनवर्ती',
    'प्रकाशनाधिकार',
    'प्रकाशनालय',
    'प्रकाशनावधि',
    'प्रकाशपोत',
    'प्रकाशफोटो',
    'प्रकाशभाष',
    'प्रकाशभीति',
    'प्रकाशमान',
    'प्रकाशमुद्र',
    'प्रकाशरोधी',
    'प्रकाशविज्ञान',
    'प्रकाशसंकेत',
    'प्रकाशहीन',
    'प्रकाशहीनता',
    'प्रकाशात्मा',
    'प्रकाशिक',
    'प्रकाशिकी',
    'प्रकाशित',
    'प्रकाशितता',
    'प्रकाशी',
    'प्रकाशीय',
    'प्रकाश्य',
    'प्रकिण्व',
    'प्रकिण्वन',
    'प्रकिया',
    'प्रकीर्ण',
    'प्रकीर्णक',
    'प्रकीर्णन',
    'प्रकीर्तित',
    'प्रकृत',
    'प्रकृति',
    'प्रकृतिः',
    'प्रकृतिक',
    'प्रकृतिपूजा',
    'प्रकृतिवाद',
    'प्रकृतिस्थ',
    'प्रकृतिस्थता',
    'प्रकृत्या',
    'प्रकृष्ट',
    'प्रकृष्टता',
    'प्रकोप',
    'प्रकोपन',
    'प्रकोष्ठ',
    'प्रकोष्ठक',
    'प्रक्कलन',
    'प्रक्रम',
    'प्रक्रिया',
    'प्रक्रियाओं',
    'प्रक्रियाजन्य',
    'प्रक्रियात्मक',
    'प्रक्रियाधीन',
    'प्रक्रियावली',
    'प्रक्षाल',
    'प्रक्षालक',
    'प्रक्षालन',
    'प्रक्षालनालय',
    'प्रक्षालनी',
    'प्रक्षालयिता',
    'प्रक्षालित',
    'प्रक्षालित्र',
    'प्रक्षाव्य',
    'प्रक्षितांश',
    'प्रक्षिप्त',
    'प्रक्षुब्ध',
    'प्रक्षेत्र',
    'प्रक्षेप',
    'प्रक्षेपक',
    'प्रक्षेपण',
    'प्रक्षेपयिता',
    'प्रक्षेपास्त्र',
    'प्रक्षेपित',
    'प्रक्षेपित्र',
    'प्रक्षेप्ता',
    'प्रखर',
    'प्रखरता',
    'प्रख्यात',
    'प्रख्यापन',
    'प्रगंड',
    'प्रगंडिका',
    'प्रगटना',
    'प्रगत',
    'प्रगति',
    'प्रगतिवाद',
    'प्रगतिवादी',
    'प्रगतिशील',
    'प्रगतिशीलता',
    'प्रगतिहीन',
    'प्रगतिहीनता',
    'प्रगमन',
    'प्रगल्भ',
    'प्रगल्भता',
    'प्रगल्भा',
    'प्रगसना',
    'प्रगाढता',
    'प्रगाढ़',
    'प्रगाढ़क',
    'प्रगाढ़ता',
    'प्रगाता',
    'प्रगामी',
    'प्रगायक',
    'प्रगायी',
    'प्रगाह',
    'प्रगीत',
    'प्रगीतकार',
    'प्रगीतात्मक',
    'प्रगुणन',
    'प्रग्रह',
    'प्रग्रहण',
    'प्रग्राह',
    'प्रघात',
    'प्रघाती',
    'प्रघोष',
    'प्रचंड',
    'प्रचंडता',
    'प्रचंडा',
    'प्रचंडिका',
    'प्रचक्र',
    'प्रचण्डता',
    'प्रचण्डतापूर्वक',
    'प्रचय',
    'प्रचयन',
    'प्रचरण',
    'प्रचरित',
    'प्रचल',
    'प्रचलन',
    'प्रचलाक',
    'प्रचलित',
    'प्रचलितता',
    'प्रचायक',
    'प्रचार',
    'प्रचारक',
    'प्रचारना',
    'प्रचारित',
    'प्रचालक',
    'प्रचालन',
    'प्रचालनों',
    'प्रचालित',
    'प्रचीन',
    'प्रचुर',
    'प्रचुरतः',
    'प्रचुरता',
    'प्रचेता',
    'प्रचेतित',
    'प्रचोदक',
    'प्रचोदन',
    'प्रचोदना',
    'प्रचोदित',
    'प्रच्छद',
    'प्रच्छदी',
    'प्रच्छन्न',
    'प्रच्छन्नन',
    'प्रच्छादन',
    'प्रच्छालन',
    'प्रच्दादित',
    'प्रच्यावक',
    'प्रच्युत',
    'प्रच्युति',
    'प्रजंघ',
    'प्रजन',
    'प्रजनक',
    'प्रजनन',
    'प्रजननशीलता',
    'प्रजनयिता',
    'प्रजनयित्री',
    'प्रजनित',
    'प्रजनिद',
    'प्रजनिष्णु',
    'प्रजयिता',
    'प्रजल्प',
    'प्रजव',
    'प्रजवन',
    'प्रजवित',
    'प्रजविष्णु',
    'प्रजवी',
    'प्रजा',
    'प्रजांतक',
    'प्रजाकाम',
    'प्रजागर',
    'प्रजागरण',
    'प्रजाजन',
    'प्रजात',
    'प्रजातंतु',
    'प्रजातंत्र',
    'प्रजातंत्रवाद',
    'प्रजातंत्रात्मक',
    'प्रजातंत्रीय',
    'प्रजातन्त्र',
    'प्रजाति',
    'प्रजातिवाद',
    'प्रजातीय',
    'प्रजाद',
    'प्रजाधर',
    'प्रजाध्यक्ष',
    'प्रजानाथ',
    'प्रजापत',
    'प्रजापति',
    'प्रजापतिः',
    'प्रजापतीय',
    'प्रजापत्य',
    'प्रजायी',
    'प्रजावती',
    'प्रजाहित',
    'प्रजाहीन',
    'प्रजाहीनता',
    'प्रजुरित',
    'प्रजेश',
    'प्रजेश्वर',
    'प्रजैंटेशन',
    'प्रज्ञ',
    'प्रज्ञतापूर्वक',
    'प्रज्ञा',
    'प्रज्ञाकूट',
    'प्रज्ञाचक्षु',
    'प्रज्ञाजीवी',
    'प्रज्ञात',
    'प्रज्ञात्मा',
    'प्रज्ञान',
    'प्रज्ञानवाद',
    'प्रज्ञापन',
    'प्रज्ञापित',
    'प्रज्ञावाद',
    'प्रज्ञावान',
    'प्रज्ञाहीन',
    'प्रज्ञेय',
    'प्रज्वल',
    'प्रज्वलन',
    'प्रज्वलित',
    'प्रज्वलित्र',
    'प्रढ़ाल',
    'प्रण',
    'प्रणतता',
    'प्रणतपाल',
    'प्रणति',
    'प्रणमन',
    'प्रणय',
    'प्रणयन',
    'प्रणयिनी',
    'प्रणयी',
    'प्रणव',
    'प्रणाद',
    'प्रणादन',
    'प्रणादित',
    'प्रणादी',
    'प्रणाम',
    'प्रणामित',
    'प्रणायक',
    'प्रणायाम',
    'प्रणाल',
    'प्रणालिका',
    'प्रणालियां',
    'प्रणालियों',
    'प्रणाली',
    'प्रणालीगत',
    'प्रणालीबद्ध',
    'प्रणिधान',
    'प्रणिधानन',
    'प्रणिधायी',
    'प्रणिधि',
    'प्रणी',
    'प्रणीत',
    'प्रणीता',
    'प्रणेता',
    'प्रणेय',
    'प्रणोदक',
    'प्रणोदन',
    'प्रणोदयिता',
    'प्रणोदित',
    'प्रणोदित्र',
    'प्रणोदी',
    'प्रतंबितता',
    'प्रतपन',
    'प्रतप्त',
    'प्रतर्दक',
    'प्रतर्दन',
    'प्रतल',
    'प्रताड़ना',
    'प्रताड़ित',
    'प्रतात्मा',
    'प्रतान',
    'प्रतानित',
    'प्रताप',
    'प्रतापन',
    'प्रतापवान',
    'प्रतापशाली',
    'प्रतापहीन',
    'प्रतापी',
    'प्रतारणा',
    'प्रति',
    'प्रतिअम्ल',
    'प्रतिकंचुक',
    'प्रतिकर',
    'प्रतिकरात्मक',
    'प्रतिकर्ता',
    'प्रतिकर्म',
    'प्रतिकर्मी',
    'प्रतिकर्ष',
    'प्रतिकर्षण',
    'प्रतिकर्षी',
    'प्रतिकष्र्य',
    'प्रतिकष्र्यर्',
    'प्रतिकार',
    'प्रतिकारक',
    'प्रतिकारात्मक',
    'प्रतिकारी',
    'प्रतिकार्य',
    'प्रतिकूप',
    'प्रतिकूल',
    'प्रतिकूलतः',
    'प्रतिकूलता',
    'प्रतिकूला',
    'प्रतिकृति',
    'प्रतिकृष्यता',
    'प्रतिकेंद्र',
    'प्रतिकेंद्रण',
    'प्रतिकेन्द्रक',
    'प्रतिक्रम',
    'प्रतिक्रय',
    'प्रतिक्रांति',
    'प्रतिक्रियताः',
    'प्रतिक्रिया',
    'प्रतिक्रियात्मक',
    'प्रतिक्रियावाद',
    'प्रतिक्रियाशील',
    'प्रतिक्रियाहीन',
    'प्रतिक्रियाहीनता',
    'प्रतिक्षिज',
    'प्रतिक्षिजता',
    'प्रतिक्षेप',
    'प्रतिक्षेपण',
    'प्रतिक्षेप्यता',
    'प्रतिगत',
    'प्रतिगति',
    'प्रतिगतिक',
    'प्रतिगतिशील',
    'प्रतिगमनः',
    'प्रतिगमनशील',
    'प्रतिगामिता',
    'प्रतिगामी',
    'प्रतिगुप्तचर',
    'प्रतिगुप्तचर्या',
    'प्रतिगुरुत्व',
    'प्रतिग्रहण',
    'प्रतिघटी',
    'प्रतिघट्ट',
    'प्रतिघात',
    'प्रतिघातक',
    'प्रतिचक्रवात',
    'प्रतिचक्रीय',
    'प्रतिचयन',
    'प्रतिचयी',
    'प्रतिचार',
    'प्रतिचित्र',
    'प्रतिच्छाया',
    'प्रतिच्छेद',
    'प्रतिच्छेदन',
    'प्रतिछाया',
    'प्रतिजल्प',
    'प्रतिजाति',
    'प्रतिजीव',
    'प्रतिजीवन',
    'प्रतिजीवाणु',
    'प्रतिजैविक',
    'प्रतिजैविकी',
    'प्रतिज्ञ',
    'प्रतिज्ञता',
    'प्रतिज्ञा',
    'प्रतिज्ञाता',
    'प्रतिज्ञान',
    'प्रतिज्ञानात्मक',
    'प्रतिज्ञापत्र',
    'प्रतिज्ञापन',
    'प्रतिज्ञाबद्ध',
    'प्रतिज्वलन',
    'प्रतितुलन',
    'प्रतिदत्त',
    'प्रतिदर्शी',
    'प्रतिदान',
    'प्रतिदावा',
    'प्रतिदिन',
    'प्रतिदिश',
    'प्रतिदीप्ति',
    'प्रतिदीप्तिशील',
    'प्रतिदेय',
    'प्रतिदेवता',
    'प्रतिद्वंदी',
    'प्रतिद्वंद्वितः',
    'प्रतिद्वंद्विता',
    'प्रतिद्वंद्वितापूर्वक',
    'प्रतिद्वंद्वी',
    'प्रतिद्वंद्वीहीनता',
    'प्रतिद्वन्द्विता',
    'प्रतिधारण',
    'प्रतिधारित',
    'प्रतिध्वनक',
    'प्रतिध्वनन',
    'प्रतिध्वनि',
    'प्रतिध्वनिक',
    'प्रतिध्वनिकारी',
    'प्रतिध्वनित',
    'प्रतिध्वनित्र',
    'प्रतिनप्ता',
    'प्रतिनमन',
    'प्रतिनम्यता',
    'प्रतिनयन',
    'प्रतिनाद',
    'प्रतिनादी',
    'प्रतिनायक',
    'प्रतिनायिका',
    'प्रतिनारी',
    'प्रतिनिधान',
    'प्रतिनिधि',
    'प्रतिनिधिक',
    'प्रतिनिधित्व',
    'प्रतिनिधिमंडल',
    'प्रतिनिधियों',
    'प्रतिनियम',
    'प्रतिनियुक्त',
    'प्रतिनियुक्ति',
    'प्रतिनियोजन',
    'प्रतिनिर्देश',
    'प्रतिनिर्धारण',
    'प्रतिनिर्यातन',
    'प्रतिनीत',
    'प्रतिनोदन',
    'प्रतिनोदित',
    'प्रतिपक्ष',
    'प्रतिपक्षता',
    'प्रतिपक्षी',
    'प्रतिपक्षीय',
    'प्रतिपत्ति',
    'प्रतिपत्र',
    'प्रतिपद',
    'प्रतिपदा',
    'प्रतिपदाः',
    'प्रतिपन्न',
    'प्रतिपरीक्षा',
    'प्रतिपादक',
    'प्रतिपादन',
    'प्रतिपादयिता',
    'प्रतिपादित',
    'प्रतिपाद्य',
    'प्रतिपाल',
    'प्रतिपालक',
    'प्रतिपालन',
    'प्रतिपीड़न',
    'प्रतिपीड़नोन्माद',
    'प्रतिपुरुष',
    'प्रतिपुष्टि',
    'प्रतिपूरक',
    'प्रतिपूर्ण',
    'प्रतिपूर्त',
    'प्रतिपूर्ति',
    'प्रतिपूर्त्ति',
    'प्रतिपोषक',
    'प्रतिप्रणाम',
    'प्रतिप्रत्त',
    'प्रतिप्रदत्त',
    'प्रतिप्रदाता',
    'प्रतिप्रदान',
    'प्रतिप्रवाह',
    'प्रतिप्रश्न',
    'प्रतिप्रस्थ',
    'प्रतिप्रस्थता',
    'प्रतिप्रहार',
    'प्रतिप्राप्त',
    'प्रतिप्राप्ति',
    'प्रतिप्रिय',
    'प्रतिप्रेषण',
    'प्रतिप्रेषित',
    'प्रतिप्लव',
    'प्रतिफल',
    'प्रतिफलक',
    'प्रतिफलहीन',
    'प्रतिफलहीनता',
    'प्रतिफलित',
    'प्रतिबंध',
    'प्रतिबंधक',
    'प्रतिबंधकता',
    'प्रतिबंधपूर्ण',
    'प्रतिबंधहीन',
    'प्रतिबंधहीनतः',
    'प्रतिबंधहीनता',
    'प्रतिबंधात्मक',
    'प्रतिबंधात्मकता',
    'प्रतिबंधित',
    'प्रतिबंधी',
    'प्रतिबंधु',
    'प्रतिबंधुता',
    'प्रतिबद्घ',
    'प्रतिबद्घता',
    'प्रतिबद्ध',
    'प्रतिबद्धता',
    'प्रतिबन्ध',
    'प्रतिबल',
    'प्रतिबाधक',
    'प्रतिबाधकता',
    'प्रतिबाधित',
    'प्रतिबाधी',
    'प्रतिबिंब',
    'प्रतिबिंबक',
    'प्रतिबिंबन',
    'प्रतिबिंबित',
    'प्रतिबिम्ब',
    'प्रतिबिम्बित',
    'प्रतिबुद्घ',
    'प्रतिबोध',
    'प्रतिबोधक',
    'प्रतिबोधन',
    'प्रतिभट',
    'प्रतिभति',
    'प्रतिभा',
    'प्रतिभाकूट',
    'प्रतिभागी',
    'प्रतिभार',
    'प्रतिभावान',
    'प्रतिभाव्य',
    'प्रतिभाशाली',
    'प्रतिभाशील',
    'प्रतिभास',
    'प्रतिभासिक',
    'प्रतिभासिकता',
    'प्रतिभाहीन',
    'प्रतिभू',
    'प्रतिभूत',
    'प्रतिभूति',
    'प्रतिभूतिकरण',
    'प्रतिभूतियां',
    'प्रतिभूतियों',
    'प्रतिभेदन',
    'प्रतिमंडल',
    'प्रतिमंत्रित',
    'प्रतिमल्ल',
    'प्रतिमा',
    'प्रतिमागत',
    'प्रतिमान',
    'प्रतिमानात्मक',
    'प्रतिमाया',
    'प्रतिमाला',
    'प्रतिमाविधायकता',
    'प्रतिमास',
    'प्रतिमाह',
    'प्रतिमिति',
    'प्रतिमुख',
    'प्रतिमुद्रा',
    'प्रतिमूत्रक',
    'प्रतिमूर्त',
    'प्रतिमूर्ति',
    'प्रतिमोचक',
    'प्रतियत्न',
    'प्रतियां',
    'प्रतियातना',
    'प्रतियुद्घ',
    'प्रतियों',
    'प्रतियोग',
    'प्रतियोगिओं',
    'प्रतियोगिता',
    'प्रतियोगिताएं',
    'प्रतियोगिताओं',
    'प्रतियोगितात्मक',
    'प्रतियोगितापूर्वक',
    'प्रतियोगी',
    'प्रतियोगीहीनता',
    'प्रतियोजना',
    'प्रतियोद्घा',
    'प्रतियोध',
    'प्रतियोधा',
    'प्रतियोधी',
    'प्रतिरक्षक',
    'प्रतिरक्षण',
    'प्रतिरक्षा',
    'प्रतिरक्षात्मक',
    'प्रतिरक्षित',
    'प्रतिरक्षिता',
    'प्रतिरक्षी',
    'प्रतिरल',
    'प्रतिरुप',
    'प्रतिरुपक',
    'प्रतिरुपतः',
    'प्रतिरुपता',
    'प्रतिरुपी',
    'प्रतिरूद्घ',
    'प्रतिरूप',
    'प्रतिरूपण',
    'प्रतिरूपी',
    'प्रतिरोगण',
    'प्रतिरोध',
    'प्रतिरोधक',
    'प्रतिरोधन',
    'प्रतिरोधित',
    'प्रतिरोधी',
    'प्रतिरोध्य',
    'प्रतिरोपण',
    'प्रतिरोपित',
    'प्रतिलता',
    'प्रतिलब्ध',
    'प्रतिलब्धा',
    'प्रतिलब्धि',
    'प्रतिलाभ',
    'प्रतिलामता',
    'प्रतिलिपि',
    'प्रतिलिपिक',
    'प्रतिलिपिकरण',
    'प्रतिलिपियां',
    'प्रतिलिपी',
    'प्रतिलिपीकार',
    'प्रतिलेखचित्र',
    'प्रतिलोभ',
    'प्रतिलोम',
    'प्रतिलोमत',
    'प्रतिलोमतः',
    'प्रतिलोमीय',
    'प्रतिवक्ता',
    'प्रतिवनिता',
    'प्रतिवर्त',
    'प्रतिवर्तन',
    'प्रतिवर्तित',
    'प्रतिवर्ती',
    'प्रतिवर्ष',
    'प्रतिवस्तु',
    'प्रतिवहन',
    'प्रतिवाक्य',
    'प्रतिवाणी',
    'प्रतिवातित्र',
    'प्रतिवाद',
    'प्रतिवादिका',
    'प्रतिवादित',
    'प्रतिवादी',
    'प्रतिवारण',
    'प्रतिवारित',
    'प्रतिवार्ता',
    'प्रतिवाहित',
    'प्रतिविकास',
    'प्रतिविधि',
    'प्रतिविरोध',
    'प्रतिविस्फोटन',
    'प्रतिवीर',
    'प्रतिवृत',
    'प्रतिवृत्त',
    'प्रतिवेदन',
    'प्रतिवेश',
    'प्रतिवेशी',
    'प्रतिवेष्टित',
    'प्रतिवेष्ठित',
    'प्रतिवैर',
    'प्रतिव्यक्ति',
    'प्रतिव्रजन',
    'प्रतिव्रर्तन',
    'प्रतिश',
    'प्रतिशत',
    'प्रतिशतता',
    'प्रतिशब्द',
    'प्रतिशासक',
    'प्रतिशासन',
    'प्रतिशासित',
    'प्रतिशिष्ट',
    'प्रतिशीत',
    'प्रतिशेयर',
    'प्रतिशोध',
    'प्रतिशोधक',
    'प्रतिशोधहीन',
    'प्रतिशोधहीनता',
    'प्रतिशोधी',
    'प्रतिश्रवण',
    'प्रतिश्रुत',
    'प्रतिश्रुति',
    'प्रतिषिद्ध',
    'प्रतिषेध',
    'प्रतिषेधक',
    'प्रतिष्टित',
    'प्रतिष्ठा',
    'प्रतिष्ठाक्रम',
    'प्रतिष्ठान',
    'प्रतिष्ठानुकूल',
    'प्रतिष्ठानों',
    'प्रतिष्ठापक',
    'प्रतिष्ठापन',
    'प्रतिष्ठापना',
    'प्रतिष्ठापित',
    'प्रतिष्ठापियता',
    'प्रतिष्ठाहीन',
    'प्रतिष्ठाहीनता',
    'प्रतिष्ठित',
    'प्रतिसंगी',
    'प्रतिसंतुलन',
    'प्रतिसंधान',
    'प्रतिसंधि',
    'प्रतिसंवेदी',
    'प्रतिसंहरण',
    'प्रतिसंहरणीय',
    'प्रतिसंहार',
    'प्रतिसंहृत',
    'प्रतिसम',
    'प्रतिसमानान्तर',
    'प्रतिसर्ग',
    'प्रतिसारण',
    'प्रतिसूचीबद्धता',
    'प्रतिस्थानी',
    'प्रतिस्थापन',
    'प्रतिस्थापना',
    'प्रतिस्थापित',
    'प्रतिस्पर्धा',
    'प्रतिस्पर्धात्मक',
    'प्रतिस्पर्धी',
    'प्रतिस्वन',
    'प्रतिहरण',
    'प्रतिहस्तक',
    'प्रतिहस्तांतरण',
    'प्रतिहस्ताक्षर',
    'प्रतिहार',
    'प्रतिहारण',
    'प्रतिहारी',
    'प्रतिहार्य',
    'प्रतिहास्ताक्षरित',
    'प्रतिहिंसा',
    'प्रतीक',
    'प्रतीकचिह्न',
    'प्रतीकात्मक',
    'प्रतीकों',
    'प्रतीक्ष',
    'प्रतीक्षक',
    'प्रतीक्षण',
    'प्रतीक्षा',
    'प्रतीक्षाकुल',
    'प्रतीक्षापूर्ण',
    'प्रतीक्षारत',
    'प्रतीक्षालय',
    'प्रतीक्षाशील',
    'प्रतीक्षित',
    'प्रतीक्षी',
    'प्रतीची',
    'प्रतीच्य',
    'प्रतीत',
    'प्रतीति',
    'प्रतीधीन',
    'प्रतीधीश',
    'प्रतीप',
    'प्रतीपी',
    'प्रतीय',
    'प्रतीयमान',
    'प्रतीयमानवाद',
    'प्रतीवाप',
    'प्रतोद',
    'प्रतोली',
    'प्रत्यंग',
    'प्रत्यंगिरा',
    'प्रत्यंचच्यता',
    'प्रत्यंचन',
    'प्रत्यंचा',
    'प्रत्यंत',
    'प्रत्यंतर',
    'प्रत्यक्',
    'प्रत्यक्ष',
    'प्रत्यक्षत',
    'प्रत्यक्षतः',
    'प्रत्यक्षता',
    'प्रत्यक्षदर्शी',
    'प्रत्यक्षी',
    'प्रत्यक्षीकरण',
    'प्रत्यच्दालन',
    'प्रत्यधिपति',
    'प्रत्यनंतर',
    'प्रत्यनीक',
    'प्रत्यपकार',
    'प्रत्यपकारी',
    'प्रत्यभिज्ञा',
    'प्रत्यभिज्ञात',
    'प्रत्यभिज्ञान',
    'प्रत्यम्ल',
    'प्रत्यय',
    'प्रत्ययकारिणी',
    'प्रत्ययकारिता',
    'प्रत्ययकारी',
    'प्रत्ययन',
    'प्रत्ययवाद',
    'प्रत्ययी',
    'प्रत्यर्थी',
    'प्रत्यर्पक',
    'प्रत्यर्पण',
    'प्रत्यवर',
    'प्रत्यवरोधन',
    'प्रत्यवरोहण',
    'प्रत्यवलोकन',
    'प्रत्यवस्थान',
    'प्रत्यवस्थापन',
    'प्रत्यवहार',
    'प्रत्याक्रमण',
    'प्रत्याख्यात',
    'प्रत्याख्यान',
    'प्रत्याख्यापन',
    'प्रत्याख्यायित',
    'प्रत्यागत',
    'प्रत्यागम',
    'प्रत्यागमन',
    'प्रत्याघात',
    'प्रत्यादिष्ट',
    'प्रत्यादेश',
    'प्रत्यादेशक',
    'प्रत्यादेशन',
    'प्रत्याधात',
    'प्रत्यानयन',
    'प्रत्यानीत',
    'प्रत्यापित',
    'प्रत्याभूत',
    'प्रत्याभूति',
    'प्रत्याभूतिदाता',
    'प्रत्यामूलक',
    'प्रत्याय',
    'प्रत्यायक',
    'प्रत्यायन',
    'प्रत्यायित',
    'प्रत्यायुक्त',
    'प्रत्यायोजक',
    'प्रत्यायोजन',
    'प्रत्यारोप',
    'प्रत्यारोपण',
    'प्रत्यार्थी',
    'प्रत्यार्पण',
    'प्रत्यार्पणीय',
    'प्रत्यावत्रित',
    'प्रत्यावर्तन',
    'प्रत्यावर्तनशील',
    'प्रत्यावर्ति',
    'प्रत्यावर्तित',
    'प्रत्यावर्ती',
    'प्रत्यावासन',
    'प्रत्यावाहन',
    'प्रत्यावाहित',
    'प्रत्यावृत्ति',
    'प्रत्याशा',
    'प्रत्याशाएं',
    'प्रत्याशातीत',
    'प्रत्याशानुकुल',
    'प्रत्याशानुकूलता',
    'प्रत्याशित',
    'प्रत्याशिततः',
    'प्रत्याशितता',
    'प्रत्याशिता',
    'प्रत्याशी',
    'प्रत्याशीत',
    'प्रत्यासन्ना',
    'प्रत्यास्थता',
    'प्रत्याह',
    'प्रत्याहत',
    'प्रत्याहरण',
    'प्रत्याहार',
    'प्रत्याहूत',
    'प्रत्याहूति',
    'प्रत्याहृत',
    'प्रत्याहृन',
    'प्रत्याह्वान',
    'प्रत्युक्त',
    'प्रत्युक्ति',
    'प्रत्युच्छल',
    'प्रत्युत',
    'प्रत्युत्कर्ष',
    'प्रत्युत्क्षेपण',
    'प्रत्युत्तर',
    'प्रत्युत्तेजन',
    'प्रत्युत्थान',
    'प्रत्युत्पन्नमति',
    'प्रत्युत्पन्ना',
    'प्रत्युन्नामन',
    'प्रत्युन्नामनशील',
    'प्रत्युन्नामय',
    'प्रत्युन्नामयता',
    'प्रत्युपकार',
    'प्रत्युपकारी',
    'प्रत्युपहार',
    'प्रत्युपाय',
    'प्रत्यूष',
    'प्रत्यूषा',
    'प्रत्येक',
    'प्रत्योजित',
    'प्रथन',
    'प्रथम',
    'प्रथमक',
    'प्रथमज',
    'प्रथमतः',
    'प्रथमता',
    'प्रथमधिकार',
    'प्रथमर्ध',
    'प्रथमाक्षर',
    'प्रथमाधिकार',
    'प्रथमाधिकारी',
    'प्रथमाश्रम',
    'प्रथमेतर',
    'प्रथमोपचार',
    'प्रथमोपचारक',
    'प्रथा',
    'प्रथाओं',
    'प्रथाक्रम',
    'प्रथागत',
    'प्रथानुकूल',
    'प्रथानुकूलतः',
    'प्रथानुसार',
    'प्रथानुसारतः',
    'प्रथोण',
    'प्रथोन्मूलक',
    'प्रद',
    'प्रदक्षिण',
    'प्रदक्षिणा',
    'प्रदक्षिणाशील',
    'प्रदच्छिना',
    'प्रदत',
    'प्रदत्त',
    'प्रदर्श',
    'प्रदर्शक',
    'प्रदर्शन',
    'प्रदर्शनकारी',
    'प्रदर्शनवादात्मक',
    'प्रदर्शनवादी',
    'प्रदर्शनवृत्ति',
    'प्रदर्शनात्मक',
    'प्रदर्शनालय',
    'प्रदर्शनी',
    'प्रदर्शनीय',
    'प्रदर्शनीयता',
    'प्रदर्शिका',
    'प्रदर्शित',
    'प्रदर्शित्र',
    'प्रदर्शी',
    'प्रदश्र्य',
    'प्रदहन',
    'प्रदाता',
    'प्रदान',
    'प्रदानन',
    'प्रदाय',
    'प्रदायक',
    'प्रदायिनी',
    'प्रदायी',
    'प्रदार',
    'प्रदाह',
    'प्रदाहक',
    'प्रदाहात्मक',
    'प्रदिष्ट',
    'प्रदीप',
    'प्रदीपक',
    'प्रदीपन',
    'प्रदीपिका',
    'प्रदीप्त',
    'प्रदीप्ति',
    'प्रदुषण',
    'प्रदुष्ट',
    'प्रदूषक',
    'प्रदूषण',
    'प्रदूषित',
    'प्रदूषितता',
    'प्रदेय',
    'प्रदेश',
    'प्रदेशवाद',
    'प्रदेशात्मक',
    'प्रदेशीय',
    'प्रदेशों',
    'प्रदेष',
    'प्रदेष्टा',
    'प्रदोल',
    'प्रदोलन',
    'प्रदोलित',
    'प्रदोष',
    'प्रद्युनः',
    'प्रद्युम्न',
    'प्रद्योत',
    'प्रद्योतन',
    'प्रद्रय',
    'प्रद्राव',
    'प्रद्रावी',
    'प्रद्वेष',
    'प्रद्वेषी',
    'प्रधान',
    'प्रधानकाः',
    'प्रधानता',
    'प्रधानमंत्री',
    'प्रधानाचार्य',
    'प्रधानाचार्या',
    'प्रधानाध्यापक',
    'प्रधानी',
    'प्रधानीय',
    'प्रधानों',
    'प्रधानोत्तम',
    'प्रधार',
    'प्रधावन',
    'प्रधि',
    'प्रधिकारी',
    'प्रध्यान',
    'प्रध्वंसक',
    'प्रन',
    'प्रपंच',
    'प्रपंचपूर्ण',
    'प्रपंचहीन',
    'प्रपंचित',
    'प्रपंची',
    'प्रपतन',
    'प्रपतनशील',
    'प्रपतित',
    'प्रपत्र',
    'प्रपत्रदाता',
    'प्रपत्री',
    'प्रपथ',
    'प्रपद',
    'प्रपदन',
    'प्रपन्न',
    'प्रपलायन',
    'प्रपा',
    'प्रपात',
    'प्रपातिका',
    'प्रपाती',
    'प्रपाथ',
    'प्रपिता',
    'प्रपितामह',
    'प्रपितामही',
    'प्रपीड़न',
    'प्रपुंज',
    'प्रपूरक',
    'प्रपूरित',
    'प्रपूर्त',
    'प्रपूर्ति',
    'प्रपौत्र',
    'प्रपौत्री',
    'प्रप्तियां',
    'प्रप्लाव्मा',
    'प्रफुल्ल',
    'प्रफुल्लता',
    'प्रबंध',
    'प्रबंधक',
    'प्रबंधकीय',
    'प्रबंधकीयता',
    'प्रबंधन',
    'प्रबंधहीन',
    'प्रबंधहीनता',
    'प्रबंधालय',
    'प्रबंधित',
    'प्रबंधीय',
    'प्रबन्ध',
    'प्रबन्धक',
    'प्रबन्धकर्ता',
    'प्रबन्धकीय',
    'प्रबर्धक',
    'प्रबर्धित',
    'प्रबल',
    'प्रबलता',
    'प्रबलतापूर्वक',
    'प्रबलन',
    'प्रबलित',
    'प्रबाधक',
    'प्रबाधन',
    'प्रबाल',
    'प्रबाहु',
    'प्रबुद्घ',
    'प्रबुद्ध',
    'प्रबुध',
    'प्रबृद्घ',
    'प्रबोध',
    'प्रबोधक',
    'प्रबोधन',
    'प्रबोधित',
    'प्रबोधिनी',
    'प्रभ',
    'प्रभंजन',
    'प्रभव',
    'प्रभवन',
    'प्रभा',
    'प्रभांजन',
    'प्रभाकर',
    'प्रभाग',
    'प्रभागिक',
    'प्रभागीय',
    'प्रभागों',
    'प्रभाजन',
    'प्रभाजित',
    'प्रभात',
    'प्रभाती',
    'प्रभातीय',
    'प्रभाबिंब',
    'प्रभामण्डल',
    'प्रभायी',
    'प्रभार',
    'प्रभारमुक्ति',
    'प्रभारित',
    'प्रभारी',
    'प्रभाव',
    'प्रभावकारी',
    'प्रभावकाल',
    'प्रभावती',
    'प्रभावन',
    'प्रभावपूर्ण',
    'प्रभावपूर्णता',
    'प्रभाववत्ता',
    'प्रभाववान',
    'प्रभावशालिता',
    'प्रभावशाली',
    'प्रभावशील',
    'प्रभावशीलता',
    'प्रभावहीन',
    'प्रभावहीनता',
    'प्रभावात्मकता',
    'प्रभावान',
    'प्रभावान्वित',
    'प्रभाविकता',
    'प्रभाविकीर्णन',
    'प्रभावित',
    'प्रभावितता',
    'प्रभाविता',
    'प्रभाविष्णु',
    'प्रभावी',
    'प्रभावोत्पादक',
    'प्रभावोत्पादकता',
    'प्रभास',
    'प्रभासक',
    'प्रभासन',
    'प्रभासित',
    'प्रभाहीन',
    'प्रभु',
    'प्रभुता',
    'प्रभुत्व',
    'प्रभुत्वाधिकार',
    'प्रभुभिक्त',
    'प्रभुसत्ता',
    'प्रभूत',
    'प्रभूतता',
    'प्रभूति',
    'प्रभूष्णू',
    'प्रभृति',
    'प्रभेद',
    'प्रभेदक',
    'प्रमग्न',
    'प्रमण्डल',
    'प्रमति',
    'प्रमत्त',
    'प्रमथ',
    'प्रमथन',
    'प्रमथालय',
    'प्रमथित',
    'प्रमथी',
    'प्रमथेश्वर',
    'प्रमद',
    'प्रमदा',
    'प्रमर्दित',
    'प्रमस्तिष्क',
    'प्रमाण',
    'प्रमाणक',
    'प्रमाणकर्ता',
    'प्रमाणकर्त्ता',
    'प्रमाणतः',
    'प्रमाणदृष्ट',
    'प्रमाणधिक',
    'प्रमाणन',
    'प्रमाणपत्र',
    'प्रमाणपत्रित',
    'प्रमाणपूर्वक',
    'प्रमाणवाद',
    'प्रमाणवादी',
    'प्रमाणश',
    'प्रमाणहीन',
    'प्रमाणहीनता',
    'प्रमाणांकन',
    'प्रमाणातीत',
    'प्रमाणिक',
    'प्रमाणिकता',
    'प्रमाणित',
    'प्रमाणितता',
    'प्रमाणीकरण',
    'प्रमाण्य',
    'प्रमाता',
    'प्रमातामह',
    'प्रमातामहः',
    'प्रमातामही',
    'प्रमात्रा',
    'प्रमाथ',
    'प्रमाथी',
    'प्रमाद',
    'प्रमादहीन',
    'प्रमादी',
    'प्रमाप',
    'प्रमापक',
    'प्रमापी',
    'प्रमापीय',
    'प्रमाम',
    'प्रमार्जक',
    'प्रमार्जन',
    'प्रमित',
    'प्रमिताहार',
    'प्रमिति',
    'प्रमीला',
    'प्रमीलित',
    'प्रमीली',
    'प्रमुक्त',
    'प्रमुख',
    'प्रमुखता',
    'प्रमुखतापन',
    'प्रमुग्ध',
    'प्रमुग्धन',
    'प्रमुढ़',
    'प्रमुद',
    'प्रमुदन',
    'प्रमुदित',
    'प्रमुषित',
    'प्रमेय',
    'प्रमेयी',
    'प्रमेह',
    'प्रमोक्ष',
    'प्रमोचन',
    'प्रमोद',
    'प्रमोदक',
    'प्रमोदी',
    'प्रमोशन',
    'प्रमोष',
    'प्रमोह',
    'प्रम्लोचा',
    'प्रयत',
    'प्रयत्न',
    'प्रयत्नतः',
    'प्रयत्नपूर्वक',
    'प्रयत्नवान',
    'प्रयत्नशील',
    'प्रयत्नसिद्ध',
    'प्रयत्नसिद्धता',
    'प्रयत्नहीन',
    'प्रयत्नहीनतः',
    'प्रयत्नहीनता',
    'प्रयलकर',
    'प्रयाग',
    'प्रयाण',
    'प्रयाणी',
    'प्रयाम',
    'प्रयास',
    'प्रयासतः',
    'प्रयासपूव्रर्क',
    'प्रयासशील',
    'प्रयासहीन',
    'प्रयासहीनता',
    'प्रयासित',
    'प्रयासी',
    'प्रयासों',
    'प्रयित्नत',
    'प्रयुक्त',
    'प्रयुक्ति',
    'प्रयुत',
    'प्रयुत्सु',
    'प्रयुिक्त',
    'प्रयोक्ता',
    'प्रयोग',
    'प्रयोगः',
    'प्रयोगनार्थी',
    'प्रयोगनिपुण',
    'प्रयोगवाद',
    'प्रयोगशाला',
    'प्रयोगशालाओं',
    'प्रयोगहीन',
    'प्रयोगात्मक',
    'प्रयोगार्थ',
    'प्रयोगिक',
    'प्रयोगिकता',
    'प्रयोग्य',
    'प्रयोजक',
    'प्रयोजन',
    'प्रयोजनतः',
    'प्रयोजनपूर्ण',
    'प्रयोजनमूलक',
    'प्रयोजनहीन',
    'प्रयोजनहीनतः',
    'प्रयोजनहीनता',
    'प्रयोजनार्थ',
    'प्रयोजनार्थी',
    'प्रयोजनी',
    'प्रयोजनों',
    'प्रयोज्य',
    'प्रयोज्यता',
    'प्ररक्षालन',
    'प्ररदेश',
    'प्रररहार',
    'प्ररवंचित',
    'प्ररश्न',
    'प्ररसंख्या',
    'प्ररस्तरीय',
    'प्ररस्तार',
    'प्ररस्तारक',
    'प्ररस्तारित',
    'प्ररस्तारी',
    'प्ररस्तारोपल',
    'प्ररस्ताव',
    'प्ररस्तावक',
    'प्ररस्तावना',
    'प्ररस्थता',
    'प्ररस्नुषा',
    'प्रराही',
    'प्ररीहिस्टरी',
    'प्ररुढ़',
    'प्ररुप',
    'प्ररेक',
    'प्ररेण',
    'प्ररेणा',
    'प्ररेणाजन्य',
    'प्ररेणाप्रद',
    'प्ररेषण',
    'प्ररोंचना',
    'प्ररोचक',
    'प्ररोचन',
    'प्ररोचना',
    'प्ररोह',
    'प्ररोहण',
    'प्ररोही',
    'प्रर्याप्त',
    'प्रर्यायता',
    'प्रर्वतक',
    'प्रर्वतन',
    'प्रर्सायता',
    'प्रलंफन',
    'प्रलंब',
    'प्रलंबक',
    'प्रलंबन',
    'प्रलंबित',
    'प्रलंबी',
    'प्रलक्षित',
    'प्रलम्बकर्ण',
    'प्रलय',
    'प्रलयंकर',
    'प्रलयकर',
    'प्रलयकारी',
    'प्रलयन',
    'प्रलयाग्नि',
    'प्रलयानल',
    'प्रलयित',
    'प्रललाट',
    'प्रलविता',
    'प्रलवित्र',
    'प्रलाक्ष',
    'प्रलाक्षा',
    'प्रलाप',
    'प्रलापी',
    'प्रलीन',
    'प्रलुब्ध',
    'प्रलुभन',
    'प्रलेख',
    'प्रलेखन',
    'प्रलेखनीय',
    'प्रलेखा',
    'प्रलेखी',
    'प्रलेखों',
    'प्रलेप',
    'प्रलेपक',
    'प्रलेपन',
    'प्रलेपित',
    'प्रलोभ',
    'प्रलोभक',
    'प्रलोभकता',
    'प्रलोभन',
    'प्रलोभनकारी',
    'प्रलोभना',
    'प्रलोभित',
    'प्रलोभिनी',
    'प्रलोभी',
    'प्रलोभ्य',
    'प्रवंचक',
    'प्रवंचन',
    'प्रवंचनशीलता',
    'प्रवंचना',
    'प्रवंचित',
    'प्रवक्तव्य',
    'प्रवक्ता',
    'प्रवचंक',
    'प्रवचन',
    'प्रवण',
    'प्रवणता',
    'प्रवधिर्त',
    'प्रवर',
    'प्रवरण',
    'प्रवरर्षि',
    'प्रवर्ग',
    'प्रवर्गीय',
    'प्रवर्णन',
    'प्रवर्तक',
    'प्रवर्तन',
    'प्रवर्तनशील',
    'प्रवर्तनीय',
    'प्रवर्तयिता',
    'प्रवर्तित',
    'प्रवर्ती',
    'प्रवर्ध',
    'प्रवर्धक',
    'प्रवर्धकता',
    'प्रवर्धन',
    'प्रवर्धित',
    'प्रवर्षण',
    'प्रवर्षा',
    'प्रवल्हिका',
    'प्रवसन',
    'प्रवह',
    'प्रवहण',
    'प्रवहन',
    'प्रवहमान',
    'प्रवहित',
    'प्रवाचक',
    'प्रवात',
    'प्रवात्या',
    'प्रवाद',
    'प्रवादित',
    'प्रवार',
    'प्रवारण',
    'प्रवाल',
    'प्रवालद्वीप',
    'प्रवाली',
    'प्रवावहीन',
    'प्रवाष्पित्र',
    'प्रवास',
    'प्रवासन',
    'प्रवासपूर्व',
    'प्रवासालय',
    'प्रवासिक',
    'प्रवासिता',
    'प्रवासियों',
    'प्रवासी',
    'प्रवासीय',
    'प्रवाह',
    'प्रवाहक',
    'प्रवाहण',
    'प्रवाहन',
    'प्रवाहमय',
    'प्रवाहयुक्त',
    'प्रवाहशील',
    'प्रवाहशीलता',
    'प्रवाहहीन',
    'प्रवाहहीनता',
    'प्रवाहिका',
    'प्रवाहित',
    'प्रवाहिनी',
    'प्रवाही',
    'प्रविधि',
    'प्रविधिक',
    'प्रविधिहीन',
    'प्रविधिहीनता',
    'प्रविरति',
    'प्रविर्णत',
    'प्रविलय',
    'प्रविलयन',
    'प्रविष्ट',
    'प्रविष्टक',
    'प्रविष्टायन',
    'प्रविष्टायित',
    'प्रविष्टि',
    'प्रविष्टिकृत',
    'प्रविष्टित',
    'प्रविष्टियां',
    'प्रविष्टियों',
    'प्रवीण',
    'प्रवीणता',
    'प्रवीर',
    'प्रवृति',
    'प्रवृत्त',
    'प्रवृत्तता',
    'प्रवृत्ति',
    'प्रवृत्तियां',
    'प्रवृत्तिशील',
    'प्रवृत्तिहीन',
    'प्रवृत्यात्मक',
    'प्रवेणी',
    'प्रवेपन',
    'प्रवेश',
    'प्रवेशक',
    'प्रवेशज्ञा',
    'प्रवेशन',
    'प्रवेशपत्र',
    'प्रवेशस्रोत',
    'प्रवेशाधिकार',
    'प्रवेशानुमति',
    'प्रवेशानुष्ठान',
    'प्रवेशारंभ',
    'प्रवेशिका',
    'प्रवेशित',
    'प्रवेशोत्सव',
    'प्रवेश्य',
    'प्रवेश्यता',
    'प्रवेष्टक',
    'प्रवेष्टा',
    'प्रव्यक्त',
    'प्रव्याहृत',
    'प्रव्रजन',
    'प्रव्रज्या',
    'प्रव्राजक',
    'प्रव्राजिका',
    'प्रशंसक',
    'प्रशंसकों',
    'प्रशंसन',
    'प्रशंसनीय',
    'प्रशंसनीयता',
    'प्रशंसा',
    'प्रशंसात्मक',
    'प्रशंसापत्र',
    'प्रशंसित',
    'प्रशंसी',
    'प्रशंस्य',
    'प्रशम',
    'प्रशमता',
    'प्रशमन',
    'प्रशमित',
    'प्रशम्य',
    'प्रशस्त',
    'प्रशस्तता',
    'प्रशस्ति',
    'प्रशस्तिकार',
    'प्रशस्य',
    'प्रशांत',
    'प्रशांतचित',
    'प्रशांतता',
    'प्रशांतात्मा',
    'प्रशांति',
    'प्रशाख',
    'प्रशाखन',
    'प्रशाखा',
    'प्रशाखित',
    'प्रशान्त',
    'प्रशान्ति',
    'प्रशामक',
    'प्रशामन',
    'प्रशाला',
    'प्रशासक',
    'प्रशासकीय',
    'प्रशासन',
    'प्रशासनिक',
    'प्रशासनिकता',
    'प्रशासित',
    'प्रशासी',
    'प्रशास्ता',
    'प्रशिक्षक',
    'प्रशिक्षण',
    'प्रशिक्षणधीन',
    'प्रशिक्षा',
    'प्रशिक्षार्थी',
    'प्रशिक्षिण',
    'प्रशिक्षित',
    'प्रशिक्षु',
    'प्रशिक्षुता',
    'प्रशित',
    'प्रशितक',
    'प्रशितन',
    'प्रशिष्ट',
    'प्रशीतक',
    'प्रशीतन',
    'प्रशुद्घ',
    'प्रशुल्क',
    'प्रशोधक',
    'प्रश्न',
    'प्रश्नकाल',
    'प्रश्नन',
    'प्रश्नपत्र',
    'प्रश्नपूर्ण',
    'प्रश्नफलक',
    'प्रश्नमाला',
    'प्रश्नशीलता',
    'प्रश्नात्मक',
    'प्रश्नावली',
    'प्रश्नी',
    'प्रश्नों',
    'प्रश्नोत्तर',
    'प्रश्रंस',
    'प्रश्रय',
    'प्रश्वसन',
    'प्रश्वास',
    'प्रसंख्यान',
    'प्रसंग',
    'प्रसंगतः',
    'प्रसंगपरक',
    'प्रसंगवश',
    'प्रसंगहीन',
    'प्रसंगहीनता',
    'प्रसंगाधीन',
    'प्रसंगानुकूल',
    'प्रसंगानुकूलता',
    'प्रसंगेतर',
    'प्रसंगेतरता',
    'प्रसंगोचित',
    'प्रसंद',
    'प्रसंभाव्यता',
    'प्रसंविदा',
    'प्रसंविदाएं',
    'प्रसंस्करण',
    'प्रसंस्कर्ता',
    'प्रसंस्कार',
    'प्रसंस्कृत',
    'प्रसक्त',
    'प्रसज्य',
    'प्रसथान',
    'प्रसदना',
    'प्रसन्न',
    'प्रसन्नक',
    'प्रसन्नकृत',
    'प्रसन्नचित',
    'प्रसन्नचित्त',
    'प्रसन्नचित्तता',
    'प्रसन्नतः',
    'प्रसन्नता',
    'प्रसन्नतापूर्ण',
    'प्रसन्नतापूर्वक',
    'प्रसन्नताप्रद',
    'प्रसन्नन',
    'प्रसन्नमना',
    'प्रसन्नमुख',
    'प्रसन्नमुखता',
    'प्रसन्नशीलता',
    'प्रसन्नात्मा',
    'प्रसन्नीकरण',
    'प्रसमता',
    'प्रसर',
    'प्रसरकोण',
    'प्रसरण',
    'प्रसरणीय',
    'प्रसरित',
    'प्रसर्पण',
    'प्रसर्पी',
    'प्रसव',
    'प्रसवः',
    'प्रसवकालीन',
    'प्रसवन',
    'प्रसवना',
    'प्रसवपूर्व',
    'प्रसवपूर्वी',
    'प्रसवशाला',
    'प्रसवाशौच',
    'प्रसविता',
    'प्रसवित्री',
    'प्रसविनी',
    'प्रसवी',
    'प्रसाक्ष्य',
    'प्रसाद',
    'प्रसादक',
    'प्रसादगुण',
    'प्रसादन',
    'प्रसादाध्यक्ष',
    'प्रसादित',
    'प्रसादी',
    'प्रसाद्य',
    'प्रसाधक',
    'प्रसाधन',
    'प्रसाधनी',
    'प्रसाधव्य',
    'प्रसाधान',
    'प्रसाधित',
    'प्रसामान्य',
    'प्रसार',
    'प्रसारक',
    'प्रसारण',
    'प्रसारणीयता',
    'प्रसारना',
    'प्रसारशील',
    'प्रसारित',
    'प्रसारी',
    'प्रसार्य',
    'प्रसावक',
    'प्रसाविका',
    'प्रसिति',
    'प्रसिद्घ',
    'प्रसिद्ध',
    'प्रसिद्धि',
    'प्रसिन्नत',
    'प्रसीदन',
    'प्रसीदित',
    'प्रसुप्त',
    'प्रसुप्तावस्था',
    'प्रसुप्ति',
    'प्रसुविधा',
    'प्रसू',
    'प्रसूत',
    'प्रसूता',
    'प्रसूति',
    'प्रसूतिका',
    'प्रसूतिगृह',
    'प्रसूतिविज्ञान',
    'प्रसूतिविज्ञानी',
    'प्रसून',
    'प्रसूनक',
    'प्रसूनेषू',
    'प्रसृत',
    'प्रसृतता',
    'प्रसेक',
    'प्रसेकी',
    'प्रसेव',
    'प्रस्टन',
    'प्रस्टित',
    'प्रस्तर',
    'प्रस्तरण',
    'प्रस्तरमय',
    'प्रस्तरवासी',
    'प्रस्तार',
    'प्रस्ताव',
    'प्रस्तावक',
    'प्रस्तावना',
    'प्रस्तावनात्मक',
    'प्रस्तावित',
    'प्रस्तावों',
    'प्रस्तुत',
    'प्रस्तुतकर्ता',
    'प्रस्तुतकर्त्ता',
    'प्रस्तुतता',
    'प्रस्तुति',
    'प्रस्तुतिः',
    'प्रस्तुतिकरण',
    'प्रस्तुती',
    'प्रस्तुतीकरण',
    'प्रस्तुत्य',
    'प्रस्तृति',
    'प्रस्थ',
    'प्रस्थान',
    'प्रस्थानशील',
    'प्रस्थानित',
    'प्रस्थानी',
    'प्रस्थापक',
    'प्रस्थापन',
    'प्रस्थापना',
    'प्रस्थापित',
    'प्रस्थित',
    'प्रस्थिति',
    'प्रस्थिद',
    'प्रस्पंद',
    'प्रस्फटितता',
    'प्रस्फुट',
    'प्रस्फुटन',
    'प्रस्फुटित',
    'प्रस्फुटितता',
    'प्रस्फुरण',
    'प्रस्रव',
    'प्रस्राव',
    'प्रस्रुत',
    'प्रस्वेद',
    'प्रस्वेदक',
    'प्रस्वेदन',
    'प्रस्वेदित',
    'प्रस्वेद्य',
    'प्रहत',
    'प्रहर',
    'प्रहरण',
    'प्रहरी',
    'प्रहर्ता',
    'प्रहर्ष',
    'प्रहर्षक',
    'प्रहसन',
    'प्रहस्त',
    'प्रहाद',
    'प्रहार',
    'प्रहारक',
    'प्रहारशील',
    'प्रहारित',
    'प्रहारी',
    'प्रहारोन्मुख',
    'प्रहास',
    'प्रहासी',
    'प्रहृत',
    'प्रहेति',
    'प्रहेलक',
    'प्रहेला',
    'प्रहेलिका',
    'प्रहोरण',
    'प्रह्लाद',
    'प्रह्लादः',
    'प्रह्लादन',
    'प्रह्लादी',
    'प्रांइम',
    'प्रांगण',
    'प्रांगरिक',
    'प्रांगार',
    'प्रांगारिक',
    'प्रांगारीकरण',
    'प्रांग्',
    'प्रांजल',
    'प्रांजलता',
    'प्रांत',
    'प्रांतचर',
    'प्रांतर',
    'प्रांतवाद',
    'प्रांतिक',
    'प्रांतीय',
    'प्रांतीयता',
    'प्रांतीयतावाद',
    'प्रांरभ',
    'प्रांरभिक',
    'प्रांशु',
    'प्रांशुता',
    'प्रांशुल',
    'प्रांशुलता',
    'प्राइम',
    'प्राइवेट',
    'प्राइवेटाइजेशन',
    'प्राइवेटाइज्ड',
    'प्राइस',
    'प्राऊ',
    'प्राक',
    'प्राकम्य',
    'प्राकरणिक',
    'प्राकाम्य',
    'प्राकार',
    'प्राकृत',
    'प्राकृतता',
    'प्राकृतिक',
    'प्राकृतिकता',
    'प्राक्',
    'प्राक्कथन',
    'प्राक्कर्म',
    'प्राक्कलन',
    'प्राक्कल्पना',
    'प्राक्काल',
    'प्राक्कालीन',
    'प्राक्तन',
    'प्राक्तनता',
    'प्राक्षेपिक',
    'प्राक्षेपिकी',
    'प्राखर्य',
    'प्राख्यान',
    'प्राख्यापन',
    'प्रागितिहासिक',
    'प्रागुक्त',
    'प्रागैतिहासिक',
    'प्रागैतिहासिकता',
    'प्राग्भाव',
    'प्राग्र',
    'प्राघात',
    'प्राच',
    'प्राचनीता',
    'प्राचल',
    'प्राचलिक',
    'प्राचार्य',
    'प्राची',
    'प्राचीन',
    'प्राचीनकाल',
    'प्राचीनता',
    'प्राचीपति',
    'प्राचीर',
    'प्राचीरहीन',
    'प्राचुर्य',
    'प्राचेतस',
    'प्राच्',
    'प्राच्य',
    'प्राच्यविद्या',
    'प्राच्यवेत्ता',
    'प्राजापत्य',
    'प्राज्ञ',
    'प्राज्ञजीवी',
    'प्राड़',
    'प्राण',
    'प्राणक',
    'प्राणचय',
    'प्राणजीवनी',
    'प्राणता',
    'प्राणथ',
    'प्राणद',
    'प्राणदण्ड',
    'प्राणधन',
    'प्राणध्न',
    'प्राणन',
    'प्राणनाथ',
    'प्राणपति',
    'प्राणप्रद',
    'प्राणप्रिय',
    'प्राणमय',
    'प्राणयम',
    'प्राणयाम',
    'प्राणरक्षक',
    'प्राणवता',
    'प्राणवान',
    'प्राणवायु',
    'प्राणसमा',
    'प्राणसार',
    'प्राणहर',
    'प्राणहारी',
    'प्राणहीन',
    'प्राणहीनता',
    'प्राणा',
    'प्राणांत',
    'प्राणांतक',
    'प्राणांतिक',
    'प्राणाघात',
    'प्राणाति',
    'प्राणात्मा',
    'प्राणात्यय',
    'प्राणाधर',
    'प्राणाधारा',
    'प्राणाधिक',
    'प्राणाधिका',
    'प्राणाधिप',
    'प्राणायतन',
    'प्राणायन',
    'प्राणायाम',
    'प्राणायामी',
    'प्राणावरोध',
    'प्राणाहुति',
    'प्राणि',
    'प्राणिक',
    'प्राणिजगत',
    'प्राणित',
    'प्राणिविज्ञान',
    'प्राणिविज्ञानी',
    'प्राणी',
    'प्राणीय',
    'प्राणेश',
    'प्राणेशा',
    'प्राणेश्वर',
    'प्राणेश्वरी',
    'प्राणोत्सर्ग',
    'प्राणोत्सर्गी',
    'प्रातः',
    'प्रातःकाल',
    'प्रातःकालीन',
    'प्रातकूल्य',
    'प्रातभाँजन',
    'प्रातराश',
    'प्रातर्गेय',
    'प्रातस्तन',
    'प्रातितिहास',
    'प्रातिपदिक',
    'प्रातिहारक',
    'प्रातीच्य',
    'प्रातीतिक',
    'प्रातीप',
    'प्राथमिक',
    'प्राथमिकता',
    'प्राथमिकताएं',
    'प्राथमिकताओं',
    'प्राथी',
    'प्राथीन',
    'प्रादर्श',
    'प्रादुर्भाव',
    'प्रादुर्भूत',
    'प्रादेश',
    'प्रादेशिक',
    'प्रादे्घोष',
    'प्रादे्घोषक',
    'प्राधनिक',
    'प्राधानिक',
    'प्राधिकरण',
    'प्राधिकार',
    'प्राधिकारिक',
    'प्राधिकारिता',
    'प्राधिकारियों',
    'प्राधिकारी',
    'प्राधिकृत',
    'प्राध्यापक',
    'प्रान्त',
    'प्रान्तास्था',
    'प्रान्तीय',
    'प्रापक',
    'प्रापीयता',
    'प्राप्त',
    'प्राप्तकर्ता',
    'प्राप्तकाम',
    'प्राप्तमनोरथ',
    'प्राप्तयौवन',
    'प्राप्तव्य',
    'प्राप्तव्यता',
    'प्राप्तव्यवहार',
    'प्राप्ता',
    'प्राप्तांक',
    'प्राप्तांश',
    'प्राप्तायु',
    'प्राप्तार्थ',
    'प्राप्ति',
    'प्राप्तिका',
    'प्राप्तियां',
    'प्राप्तियों',
    'प्राप्तिसूचना',
    'प्राप्तीच्छा',
    'प्राप्य',
    'प्राप्यकारी',
    'प्राप्यता',
    'प्राबोधिक',
    'प्राभंजनि',
    'प्राम',
    'प्रामाणिक',
    'प्रामाणिकता',
    'प्राय',
    'प्रायः',
    'प्रायःद्वीप',
    'प्रायगत',
    'प्रायण',
    'प्रायद्वीप',
    'प्रायवृत्त',
    'प्रायशः',
    'प्रायश्चित',
    'प्रायश्चितकारी',
    'प्रायश्चितात्मक',
    'प्रायिक',
    'प्रायिकता',
    'प्रायिश्चत',
    'प्रायिश्चतग्रस्त',
    'प्रायिश्चती',
    'प्रायिश्चत्तपूर्ण',
    'प्रायोगिक',
    'प्रायोजक',
    'प्रायोजन',
    'प्रायोजना',
    'प्रायोजित',
    'प्रारंभ',
    'प्रारंभण',
    'प्रारंभहीन',
    'प्रारंभहीनता',
    'प्रारंभिक',
    'प्रारंभिकता',
    'प्रारंभित',
    'प्रारक्षित',
    'प्रारचल',
    'प्रारब्ध',
    'प्रारब्धवाद',
    'प्रारब्धवादी',
    'प्रारब्धहीनतः',
    'प्रारब्धाधीन',
    'प्रारब्धाधीनता',
    'प्रारब्धीय',
    'प्रारम्भ',
    'प्रारम्भिक',
    'प्रारुप',
    'प्रारुपकार',
    'प्रारुपण',
    'प्रारूप',
    'प्रारूपण',
    'प्रारूपिक',
    'प्रार्ण',
    'प्रार्थना',
    'प्रार्थनाएं',
    'प्रार्थनालय',
    'प्रार्थयिता',
    'प्रार्थित',
    'प्रार्थी',
    'प्रालंब',
    'प्रालेख',
    'प्रालेखक',
    'प्रालेय',
    'प्रावधान',
    'प्रावरक',
    'प्रावरण',
    'प्रावरोध',
    'प्रावरोधी',
    'प्रावस्था',
    'प्रावस्थाबद्ध',
    'प्रावार',
    'प्रावासिक',
    'प्राविधान',
    'प्राविधिक',
    'प्राविधिकता',
    'प्राविधिज्ञ',
    'प्राविसिक',
    'प्राव्राज्य',
    'प्राश',
    'प्राशन',
    'प्राशस्त्य',
    'प्राशासनिक',
    'प्राशित',
    'प्राशी',
    'प्राश्निक',
    'प्रास',
    'प्रासंग',
    'प्रासंगिक',
    'प्रासंगिकता',
    'प्रासन',
    'प्रासाद',
    'प्रास्ताविक',
    'प्रास्थगन',
    'प्रािश्नक',
    'प्रिंट',
    'प्रिंटर',
    'प्रिंटरी',
    'प्रिंटर्स',
    'प्रिंटिंग',
    'प्रिंटिड',
    'प्रिंसिपल',
    'प्रिज्',
    'प्रितमुख',
    'प्रितयोगियों',
    'प्रिन्ट',
    'प्रिन्टर',
    'प्रिमाइज़',
    'प्रिय',
    'प्रियंकर',
    'प्रियंका',
    'प्रियंगु',
    'प्रियंवद',
    'प्रियंवदा',
    'प्रियकर',
    'प्रियकांक्षी',
    'प्रियकाम',
    'प्रियकारी',
    'प्रियजन',
    'प्रियतम',
    'प्रियतमा',
    'प्रियता',
    'प्रियदत्ता',
    'प्रियदर्शनता',
    'प्रियदर्शनी',
    'प्रियदर्शी',
    'प्रियधन्वा',
    'प्रियभाषी',
    'प्रियमधु',
    'प्रिययतिथि',
    'प्रियवंद',
    'प्रियवर',
    'प्रियवरा',
    'प्रियवादी',
    'प्रिया',
    'प्रियांबु',
    'प्रियाख्यान',
    'प्रियार्ह',
    'प्रियाला',
    'प्रियैषी',
    'प्रिश्नत',
    'प्रिस्नग्ध',
    'प्रिस्वन्न',
    'प्रीत',
    'प्रीतम',
    'प्रीति',
    'प्रीतिकर',
    'प्रीतिकार',
    'प्रीतिकारी',
    'प्रीतिद',
    'प्रीतिदत्त',
    'प्रीतिपात्र',
    'प्रीतिभोज',
    'प्रीतिमय',
    'प्रीतिवर्धन',
    'प्रीमियम',
    'प्रीमियर',
    'प्रीमीयम',
    'प्रीस्ट',
    'प्रीस्टवाद',
    'प्रीाव',
    'प्रूफ',
    'प्रूफड',
    'प्रूफ़',
    'प्रूफ़िंग',
    'प्रूफिंग',
    'प्रूर्फिग',
    'प्रेंख',
    'प्रेंखण',
    'प्रेंखा',
    'प्रेक्षक',
    'प्रेक्षगृह',
    'प्रेक्षण',
    'प्रेक्षणाधीन',
    'प्रेक्षणालय',
    'प्रेक्षपण',
    'प्रेक्षा',
    'प्रेक्षागार',
    'प्रेक्षागृह',
    'प्रेक्षित',
    'प्रेक्ष्य',
    'प्रेत',
    'प्रेतगत',
    'प्रेतग्ररस्त',
    'प्रेतग्रस्तता',
    'प्रेतछाया',
    'प्रेतत्व',
    'प्रेतनाथ',
    'प्रेतनी',
    'प्रेतपुरी',
    'प्रेतपूजक',
    'प्रेतबाधा',
    'प्रेतभीति',
    'प्रेतमय',
    'प्रेतराज',
    'प्रेतात्मा',
    'प्रेतावेश',
    'प्रेताशौच',
    'प्रेति',
    'प्रेतिल',
    'प्रेतेश',
    'प्रेत्यजाति',
    'प्रेत्वा',
    'प्रेप्सा',
    'प्रेप्सु',
    'प्रेम',
    'प्रेमकथा',
    'प्रेमगीत',
    'प्रेमनुरक्त',
    'प्रेमपात्र',
    'प्रेमपात्रता',
    'प्रेमपात्रा',
    'प्रेमपूर्ण',
    'प्रेमपूर्वक',
    'प्रेमबद्घता',
    'प्रेमबाण',
    'प्रेममय',
    'प्रेममयी',
    'प्रेमराता',
    'प्रेमल',
    'प्रेमलीन',
    'प्रेमलीला',
    'प्रेमवती',
    'प्रेमवाद',
    'प्रेमवान',
    'प्रेमविह्वल',
    'प्रेमवीर',
    'प्रेमशय्या',
    'प्रेमशर',
    'प्रेमसिक्त',
    'प्रेमा',
    'प्रेमांध',
    'प्रेमांधता',
    'प्रेमातिरेक',
    'प्रेमातुर',
    'प्रेमानुरक्त',
    'प्रेमापहार',
    'प्रेमालिंगन',
    'प्रेमासक्त',
    'प्रेमासक्ति',
    'प्रेमिका',
    'प्रेमिकाः',
    'प्रेमिल',
    'प्रेमी',
    'प्रेमेय',
    'प्रेमोत्पादक',
    'प्रेमोन्मुख',
    'प्रेय',
    'प्रेयस',
    'प्रेयसी',
    'प्रेरक',
    'प्रेरकता',
    'प्रेरकत्व',
    'प्रेरणा',
    'प्रेरणाजन्य',
    'प्रेरणादायक',
    'प्रेरणावाद',
    'प्रेरम',
    'प्रेरमी',
    'प्रेरयिता',
    'प्रेरित',
    'प्रेलखी',
    'प्रेवष्टा',
    'प्रेशर',
    'प्रेष',
    'प्रेषक',
    'प्रेषण',
    'प्रेषणादेश',
    'प्रेषित',
    'प्रेषिति',
    'प्रेषिती',
    'प्रेस',
    'प्रेसमैन',
    'प्रैक्टिस',
    'प्रैजिडेंट',
    'प्रैजिडैंट',
    'प्रैत',
    'प्रैशर',
    'प्रैष',
    'प्रैस',
    'प्रैसिंग',
    'प्रैिस्बटर',
    'प्रोंछ',
    'प्रोक्षण',
    'प्रोग्राम',
    'प्रोग्रेस',
    'प्रोजेक्ट',
    'प्रोजेक्टर',
    'प्रोजैक्टर',
    'प्रोजैक्शन',
    'प्रोजैक्शनिस्ट',
    'प्रोटीन',
    'प्रोटेस्टैंटवाद',
    'प्रोटेस्टैट',
    'प्रोटोकॉल',
    'प्रोटोकोल',
    'प्रोटोटाइप',
    'प्रोटोन',
    'प्रोटोप्लाज्',
    'प्रोडयूसर',
    'प्रोडेक्ट',
    'प्रोड्यूसर',
    'प्रोढधी',
    'प्रोत',
    'प्रोतंभक',
    'प्रोतंभन',
    'प्रोतंभयिता',
    'प्रोतंभिन्न',
    'प्रोतभंक',
    'प्रोतुंग',
    'प्रोत्कर्षण',
    'प्रोत्कर्षित',
    'प्रोत्तंभन',
    'प्रोत्थान',
    'प्रोत्थापक',
    'प्रोत्थापन',
    'प्रोत्थापित',
    'प्रोत्थापित्र',
    'प्रोत्फुल्ल',
    'प्रोत्सारण',
    'प्रोत्साहक',
    'प्रोत्साहन',
    'प्रोत्साहित',
    'प्रोथ',
    'प्रोथूजिन',
    'प्रोदर',
    'प्रोद्गत',
    'प्रोद्गतता',
    'प्रोद्दरी',
    'प्रोद्भूत',
    'प्रोद्यत',
    'प्रोनेट',
    'प्रोनोट',
    'प्रोन्नत',
    'प्रोपैलर',
    'प्रोफार्मा',
    'प्रोफील',
    'प्रोफेशनल',
    'प्रोफेसर',
    'प्रोबेट',
    'प्रोबैबिलिटी',
    'प्रोमियर',
    'प्रोमिसरी',
    'प्रोमिसी',
    'प्रोलौग',
    'प्रोल्का',
    'प्रोविजनल',
    'प्रोविजो',
    'प्रोषण',
    'प्रोषित',
    'प्रोषितपतिका',
    'प्रोषितभीर्तृका',
    'प्रोष्ठपद',
    'प्रोष्ठी',
    'प्रोष्ण',
    'प्रोसिक्यूटर',
    'प्रोसेसर',
    'प्रोसेसिंग',
    'प्रोैफैशन',
    'प्रोैफैशनल',
    'प्रौढ़',
    'प्रौढ़ता',
    'प्रौढ़धी',
    'प्रौढ़न',
    'प्रौढ़ा',
    'प्रौद्योगिकी',
    'प्रौद्योगिकीय',
    'प्रौपराइटर',
    'प्रौपर्टी',
    'प्रौफ़िट',
    'प्रौफिट',
    'प्रौफैसर',
    'प्रौविडंट',
    'प्रफ़ुल्ल',
    'प्लंब',
    'प्लक्ष',
    'प्लक्षजाता',
    'प्लग',
    'प्लय',
    'प्लव',
    'प्लवंगम',
    'प्लवक',
    'प्लवग',
    'प्लवन',
    'प्लवनशील',
    'प्लवपाद',
    'प्लवमान',
    'प्लवसिंचन',
    'प्लवित',
    'प्लविता',
    'प्ला',
    'प्लांट',
    'प्लांटेशन',
    'प्लाईवुड',
    'प्लाज्मा',
    'प्लाट',
    'प्लान',
    'प्लानर',
    'प्लानिंग',
    'प्लाव',
    'प्लावक',
    'प्लावन',
    'प्लावा',
    'प्लाविका',
    'प्लावित',
    'प्लावी',
    'प्लास्टर',
    'प्लास्टिक',
    'प्लास्तर',
    'प्लीट',
    'प्लीटिड',
    'प्लीडर',
    'प्लुत',
    'प्लुतक',
    'प्लुतगति',
    'प्लुति',
    'प्लूटो',
    'प्ले',
    'प्लेग',
    'प्लेग्रांउड',
    'प्लेट',
    'प्लेटफार्म',
    'प्लेटर',
    'प्लेटिंग',
    'प्लेटिग',
    'प्लेटिनम',
    'प्लेन',
    'प्लेबैंक',
    'प्लेबैक',
    'प्लेयर',
    'प्लेस',
    'प्लेसमैंट',
    'प्लैटफार्म',
    'प्लैटिनम',
    'प्लैटून',
    'प्लैनिटेरियम',
    'प्लैसीग्लास',
    'प्लोत',
    'प्लोषण',
    'प्लोषित',
    'प्लौट',
    'फँदान',
    'फँसन',
    'फँसना',
    'फँसरी',
    'फँसा',
    'फँसाना',
    'फँसापन',
    'फँसाय',
    'फंका',
    'फंकाई',
    'फंकी',
    'फंट',
    'फंड',
    'फंडी',
    'फंद',
    'फंदना',
    'फंदा',
    'फंस',
    'फंसना',
    'फंसा',
    'फंसाना',
    'फंसाने',
    'फंसे',
    'फअना',
    'फक',
    'फकार',
    'फकारना',
    'फकीर',
    'फक्कड़',
    'फक्कड़ी',
    'फगुआ',
    'फगुनव',
    'फगुनिया',
    'फजल',
    'फजीहत',
    'फजूल',
    'फट',
    'फटक',
    'फटकन',
    'फटकना',
    'फटकर',
    'फटका',
    'फटकाई',
    'फटकार',
    'फटकारना',
    'फटकारने',
    'फटकाव',
    'फटन',
    'फटना',
    'फटने',
    'फटफट',
    'फटफटाना',
    'फटफटाहट',
    'फटफटिया',
    'फटा',
    'फटाई',
    'फटाक',
    'फटाका',
    'फटापन',
    'फटाफट',
    'फटारना',
    'फटाव',
    'फटिक',
    'फटीचर',
    'फटीचरपन',
    'फटे',
    'फटेहाल',
    'फटेहाली',
    'फट्',
    'फट्टा',
    'फट्टी',
    'फट्ठा',
    'फट्ठी',
    'फड़',
    'फड़क',
    'फड़कता',
    'फड़कन',
    'फड़कना',
    'फड़का',
    'फड़ना',
    'फड़फड़',
    'फड़फड़ाकर',
    'फड़फड़ाता',
    'फड़फड़ाना',
    'फड़फड़ाव',
    'फड़फड़ाहट',
    'फड़फड़ी',
    'फड़बाज',
    'फड़बाजी',
    'फड़ा',
    'फड़ाई',
    'फड़िया',
    'फड़ी',
    'फण',
    'फणधर',
    'फणिज्झक',
    'फणिनी',
    'फणी',
    'फणींद्र',
    'फणीश्वर',
    'फतिंगा',
    'फतूही',
    'फत्ताह',
    'फदकन',
    'फदका',
    'फदकाव',
    'फदफद',
    'फदफदाहट',
    'फदी',
    'फन',
    'फनगना',
    'फना',
    'फनारी',
    'फनियाला',
    'फन्दा',
    'फन्नी',
    'फपकना',
    'फफद',
    'फफदना',
    'फफरना',
    'फफूँद',
    'फफूंद',
    'फफूंदी',
    'फफूंदीदार',
    'फफूंदीपन',
    'फफोला',
    'फबती',
    'फबतीबाज',
    'फबन',
    'फबना',
    'फबाना',
    'फबीला',
    'फर',
    'फरऊन',
    'फरनीचर',
    'फरमाना',
    'फरलोएग',
    'फरवरी',
    'फरसा',
    'फरामोश',
    'फरामोशी',
    'फरार',
    'फरारी',
    'फरियादी',
    'फरिश्ता',
    'फरी',
    'फरीदाबाद',
    'फरीसीवाद',
    'फरुही',
    'फरूवक',
    'फरूहा',
    'फरेब',
    'फरेरा',
    'फरोरा',
    'फरोह',
    'फर्क',
    'फर्जी',
    'फर्द',
    'फर्नीचर',
    'फर्म',
    'फर्मा',
    'फर्में',
    'फर्रा',
    'फर्राटा',
    'फर्राटे',
    'फर्श',
    'फर्शी',
    'फल',
    'फलक',
    'फलकदार',
    'फलका',
    'फलकांक्षी',
    'फलकाम',
    'फलकित',
    'फलकीय',
    'फलकृषि',
    'फलगुन',
    'फलगुनभव',
    'फलगुनी',
    'फलगू',
    'फलग्राही',
    'फलतः',
    'फलता',
    'फलद',
    'फलदता',
    'फलदायी',
    'फलदार',
    'फलन',
    'फलना',
    'फलनांक',
    'फलप्रद',
    'फलप्रदाता',
    'फलभित्ति',
    'फलमेष',
    'फलयान',
    'फलयुक्त',
    'फलराज',
    'फलवान',
    'फलश्रेष्ठ',
    'फलस',
    'फलसफ़ी',
    'फलस्वरुप',
    'फलस्वरूप',
    'फलहारी',
    'फलहीन',
    'फलहीनता',
    'फला',
    'फलाँ',
    'फलाँग',
    'फलाँगना',
    'फलांकुर',
    'फलाकांक्षा',
    'फलागम',
    'फलाढय',
    'फलादेश',
    'फलाधिकारी',
    'फलाध्यक्ष',
    'फलाना',
    'फलानुसार',
    'फलाफल',
    'फलालीन',
    'फलावरण',
    'फलाशन',
    'फलाशा',
    'फलासक्त',
    'फलासक्तिहीन',
    'फलासग',
    'फलास्थित',
    'फलाहार',
    'फलित',
    'फली',
    'फलीता',
    'फलीदार',
    'फलीभूत',
    'फलों',
    'फलोदय',
    'फलोद्यान',
    'फलोपवन',
    'फवक्तन',
    'फव्वारा',
    'फसकड़ा',
    'फसल',
    'फसलावा',
    'फसली',
    'फसलें',
    'फसलों',
    'फसाद',
    'फसादी',
    'फसाना',
    'फसीओ',
    'फहड़',
    'फहरना',
    'फहराता',
    'फहरान',
    'फहराना',
    'फहराव',
    'फहरिस्त',
    'फहारा',
    'फ़क़त',
    'फ़क़ीर',
    'फ़क़ीरी',
    'फ़क़ीह',
    'फ़खिया',
    'फ़ख्र',
    'फ़टना',
    'फ़तवा',
    'फ़तह',
    'फ़तहनामा',
    'फ़तहमंद',
    'फ़तहयाब',
    'फ़तहयाबी',
    'फ़तीला',
    'फ़द्र',
    'फ़र',
    'फ़रजंद',
    'फ़रनिशिंग',
    'फ़रनीचर',
    'फ़रनेस',
    'फ़रफंद',
    'फ़रफंदी',
    'फ़रफ़र',
    'फ़रफ़राना',
    'फ़रमा',
    'फ़रमाँ',
    'फ़रमाइश',
    'फ़रमाइशी',
    'फ़रमान',
    'फ़रमाना',
    'फ़रमानी',
    'फ़ररलांगः',
    'फ़रलांग',
    'फ़रवरी',
    'फ़रश',
    'फ़रसा',
    'फ़रसान',
    'फ़रहरा',
    'फ़रहराना',
    'फ़रहरी',
    'फ़रहाद',
    'फ़राख़',
    'फ़राख़दिल',
    'फ़राखी',
    'फ़रागत',
    'फ़रागती',
    'फ़रामोश',
    'फ़रामोशी',
    'फ़रार',
    'फ़रारी',
    'फ़रिया',
    'फ़रियाद',
    'फ़रिश्ता',
    'फ़रीक्र',
    'फ़रीक्री',
    'फ़रेब',
    'फ़रेबी',
    'फ़रोख़्त',
    'फ़रोख्ता',
    'फ़रोश',
    'फ़रोशी',
    'फ़र्क',
    'फ़र्ज',
    'फ़र्जी',
    'फ़र्द',
    'फ़र्दन',
    'फ़र्नीचर',
    'फ़र्म',
    'फ़र्मा',
    'फ़र्राश',
    'फ़र्राशगिरी',
    'फ़र्राशी',
    'फ़र्श',
    'फ़र्शी',
    'फ़र्स्ट',
    'फ़लक',
    'फ़लसफा',
    'फ़व्वारा',
    'फ़सल',
    'फ़सलमारी',
    'फ़सली',
    'फ़साद',
    'फ़सादी',
    'फ़साना',
    'फ़सील',
    'फ़स्द',
    'फ़स्साद',
    'फ़हमी',
    'फ़ाइंडर',
    'फ़ाइटर',
    'फ़ाइबर',
    'फ़ाक़ामस्त',
    'फ़ाका',
    'फ़ाकाकश',
    'फ़ाकामस्ती',
    'फ़ाकेदार',
    'फ़ाख़ता',
    'फ़ाख़ताः',
    'फ़ाजिल',
    'फ़ातिमा',
    'फ़ातिमाः',
    'फ़ातिहा',
    'फ़ादर',
    'फ़ानी',
    'फ़ानूस',
    'फ़ायदा',
    'फ़ायदेमंद',
    'फ़ायर',
    'फ़ाया',
    'फ़ारख़ती',
    'फ़ारम',
    'फ़ारसी',
    'फ़ारिग',
    'फ़ारी',
    'फ़ार्म',
    'फ़ार्मर',
    'फ़ार्मिग',
    'फ़ार्मेसी',
    'फ़ाल',
    'फ़ालगाना',
    'फ़ालतू',
    'फ़ाल्सी',
    'फ़ाश',
    'फ़ासनर',
    'फ़ासला',
    'फ़ासले',
    'फ़ास्फ्रेट',
    'फ़िकर',
    'फ़िकराबंदी',
    'फ़िक़रा',
    'फ़िक़ाह',
    'फ़िक्र',
    'फ़िक्रमंद',
    'फ़िक्स',
    'फ़िक्स्ड',
    'फ़िज़ाा',
    'फ़िजा',
    'फ़िट',
    'फ़िटकरी',
    'फ़िटकार',
    'फ़िटकिरी',
    'फ़िटकी',
    'फ़िटर',
    'फ़िटिंग',
    'फ़िटिग',
    'फ़िटिड',
    'फ़ितना',
    'फ़ितरत',
    'फ़ितरतन',
    'फ़ितरती',
    'फ़ितूर',
    'फ़िदवी',
    'फ़िदा',
    'फ़िरक़ा',
    'फ़िल्म',
    'फ़िल्मांकनकार',
    'फ़ीता',
    'फ़ील्ड',
    'फ़ेथ',
    'फ़ेस',
    'फ़ेहरिस्त',
    'फ़ैब्रिक',
    'फ़ैशनहीन',
    'फ़ैशनानुकूल',
    'फ़ैसला',
    'फ़ॉर',
    'फ़ोटो',
    'फ़ोटोमीटर',
    'फ़ोनोग्राफ',
    'फ़ोबिया',
    'फ़ोर्क',
    'फ़ौंट',
    'फ़ौरी',
    'फ़ौर्मैट',
    'फ़्यूज़',
    'फ़्लैश',
    'फाँक',
    'फाँकना',
    'फाँट',
    'फाँटना',
    'फाँटबंदी',
    'फाँटा',
    'फाँतासी',
    'फाँद',
    'फाँदता',
    'फाँदना',
    'फाँदी',
    'फाँदू',
    'फाँस',
    'फाँसना',
    'फाँसी',
    'फाँसीः',
    'फांदना',
    'फांस',
    'फांसना',
    'फांसा',
    'फांसी',
    'फाइन',
    'फाइनैंस',
    'फाइनैशल',
    'फाइल',
    'फाइलें',
    'फाइलों',
    'फाईनल',
    'फाईल',
    'फाईलें',
    'फाईलों',
    'फाउंटेन',
    'फाउनटने',
    'फाउन्डर',
    'फाएसें',
    'फाख्ता',
    'फाग',
    'फागुनी',
    'फाजि',
    'फाजिल',
    'फाटक',
    'फाटका',
    'फाटकी',
    'फाड़',
    'फाड़कर',
    'फाड़न',
    'फाड़ना',
    'फाड़े',
    'फाणित',
    'फातिमा',
    'फादर',
    'फाना',
    'फाफर',
    'फामिर्ग',
    'फाम्र',
    'फायदा',
    'फायदे',
    'फायर',
    'फायरमैन',
    'फायरों',
    'फारनहाइट',
    'फारसी',
    'फारेमैन',
    'फार्म',
    'फार्मगृह',
    'फार्मर',
    'फार्मूला',
    'फार्मेसी',
    'फाल',
    'फालकृष्ट',
    'फालगुप्त',
    'फालतू',
    'फालतूपन',
    'फालनामा',
    'फालसा',
    'फालाहत',
    'फालिज',
    'फालियो',
    'फाल्गुन',
    'फाल्गुनिक',
    'फाल्गून',
    'फाल्गूनानुज',
    'फाल्गूनी',
    'फाल्स',
    'फावड़ा',
    'फाशीवाद',
    'फासला',
    'फासले',
    'फास्फोरिक',
    'फाहा',
    'फिंकना',
    'फिंका',
    'फिंकाई',
    'फिंगर',
    'फिंगा',
    'फिंसलन',
    'फिका',
    'फिक्र',
    'फिजफिच',
    'फिजीशियन',
    'फिजूलखर्च',
    'फिजूलखर्ची',
    'फिट',
    'फिटकरी',
    'फिटर',
    'फिटिड़',
    'फिदाइयत',
    'फिद्दा',
    'फिन',
    'फिनाइल',
    'फियास',
    'फिर',
    'फिरंट',
    'फिरकना',
    'फिरकनी',
    'फिरकरन',
    'फिरका',
    'फिरकापरस्ती',
    'फिरकाबंदी',
    'फिरकी',
    'फिरत',
    'फिरता',
    'फिरती',
    'फिरते',
    'फिरदौस',
    'फिरन',
    'फिरना',
    'फिरनी',
    'फिरने',
    'फिरहरी',
    'फिरा',
    'फिराई',
    'फिराऊ',
    'फिराक',
    'फिराकर',
    'फिराक़',
    'फिरिश्ता',
    'फिरोजाबाद',
    'फिरौती',
    'फिलका',
    'फिलस्तीन',
    'फिलहाल',
    'फिल्टर',
    'फिल्टरण',
    'फिल्टरन',
    'फिल्म',
    'फिल्मः',
    'फिल्मकार',
    'फिल्मांकन',
    'फिल्मी',
    'फिश',
    'फिशिंग',
    'फिसड्डी',
    'फिसड्डीपन',
    'फिसफिसा',
    'फिसफिसाना',
    'फिसलन',
    'फिसलनयुक्त',
    'फिसलना',
    'फिसलने',
    'फिसला',
    'फिसलाऊ',
    'फिसलाहट',
    'फिसिर',
    'फिज़ीशियन',
    'फिज़ूलखर्च',
    'फींचना',
    'फीका',
    'फीकापन',
    'फीचर',
    'फीजियोथेरैपी',
    'फीड',
    'फीडर',
    'फीता',
    'फीते',
    'फीनस',
    'फीफरी',
    'फीरोजा',
    'फील',
    'फीलखाना',
    'फीलयान',
    'फील्ड',
    'फील्डर',
    'फील्डिंग',
    'फीस',
    'फीसदी',
    'फीसिर',
    'फुँकनी',
    'फुँदना',
    'फुँदनेदार',
    'फुँदारा',
    'फुंकनी',
    'फुंकार',
    'फुंसी',
    'फुआ',
    'फुकना',
    'फुक़रा',
    'फुग्गा',
    'फुग्गी',
    'फुचड़ा',
    'फुजला',
    'फुजूल',
    'फुजूलखर्च',
    'फुजूलखर्ची',
    'फुट',
    'फुटः',
    'फुटक',
    'फुटकर',
    'फुटकल',
    'फुटका',
    'फुटकाई',
    'फुटकी',
    'फुटन',
    'फुटना',
    'फुटनोट',
    'फुटपाथ',
    'फुटफुटाव',
    'फुटबाल',
    'फुटर',
    'फुटवर्क',
    'फुटवीयर',
    'फुटा',
    'फुटाव',
    'फुटैल',
    'फुटौअल',
    'फुट्टेल',
    'फुडन',
    'फुडाई',
    'फुडिया',
    'फुदक',
    'फुदकता',
    'फुदकना',
    'फुदकी',
    'फुनगा',
    'फुनगी',
    'फुफकार',
    'फुफिया',
    'फुफेरा',
    'फुफ्फुस',
    'फुरकत',
    'फुरती',
    'फुरतीला',
    'फुरना',
    'फुरफराता',
    'फुरफुर',
    'फुरफुराना',
    'फुरफुराहट',
    'फुरफुरी',
    'फुरलम',
    'फुरसत',
    'फुरहरी',
    'फुरेरी',
    'फुर्ती',
    'फुर्तीला',
    'फुर्तीलापन',
    'फुर्र',
    'फुल',
    'फुलका',
    'फुलकारी',
    'फुलकिया',
    'फुलकी',
    'फुलझड़ी',
    'फुलटाइम',
    'फुलबगिया',
    'फुलरा',
    'फुलवारी',
    'फुलहरा',
    'फुलाई',
    'फुलाव',
    'फुलावट',
    'फुलिंग',
    'फुलिया',
    'फुलेल',
    'फुलेला',
    'फुलेहरा',
    'फुल्ल',
    'फुल्लता',
    'फुल्लनयन',
    'फुल्लनयना',
    'फुल्ली',
    'फुल्लीदार',
    'फुल्लीहीन',
    'फुसकी',
    'फुसफुस',
    'फुसफुसाना',
    'फुसफुसाहट',
    'फुसलाई',
    'फुसलाऊ',
    'फुसलाना',
    'फुसलाने',
    'फुसलाया',
    'फुसलाव',
    'फुसलावा',
    'फुसुर',
    'फुहार',
    'फुहारना',
    'फुहारा',
    'फुही',
    'फूँक',
    'फूँकना',
    'फूँका',
    'फूँकी',
    'फूँगा',
    'फूँद',
    'फूँफूँ',
    'फूंक',
    'फूंकना',
    'फूई',
    'फूकँना',
    'फूट',
    'फूटता',
    'फूटदार',
    'फूटन',
    'फूटना',
    'फूटा',
    'फूटापन',
    'फूड',
    'फूत्कार',
    'फूत्कृति',
    'फूफस',
    'फूफसः',
    'फूफसरा',
    'फूफसराः',
    'फूफा',
    'फूफी',
    'फूफू',
    'फूफेरा',
    'फूफेरी',
    'फूल',
    'फूलः',
    'फूलता',
    'फूलते',
    'फूलदान',
    'फूलदानी',
    'फूलदार',
    'फूलना',
    'फूलबाग',
    'फूला',
    'फूलापन',
    'फूले',
    'फूलों',
    'फूस',
    'फूसी',
    'फूहड़',
    'फूहड़ता',
    'फूहड़पन',
    'फूही',
    'फेंअर',
    'फेंक',
    'फेंककर',
    'फेंकन',
    'फेंकना',
    'फेंकनाः',
    'फेंकने',
    'फेंकनेवाला',
    'फेंका',
    'फेंकैत',
    'फेंज़',
    'फेंट',
    'फेंटना',
    'फेंटा',
    'फेंटाई',
    'फेंटी',
    'फेंथ',
    'फेंस',
    'फेंसी',
    'फेअर',
    'फेडरल',
    'फेद',
    'फेदम',
    'फेदमः',
    'फेन',
    'फेनयुक्त',
    'फेनाग्र',
    'फेनिज',
    'फेनिल',
    'फेनी',
    'फेनीय',
    'फेफड़ा',
    'फेफड़े',
    'फेफड़ों',
    'फेमिली',
    'फेयर',
    'फेर',
    'फेरन',
    'फेरना',
    'फेरने',
    'फेरफार',
    'फेरबदल',
    'फेरवट',
    'फेरा',
    'फेराफेरी',
    'फेरी',
    'फेरीवाला',
    'फेरे',
    'फेरो',
    'फेल',
    'फेलाना',
    'फेलाव',
    'फेशल',
    'फेस',
    'फेसला',
    'फेसलाकुन',
    'फेसले',
    'फैकल्टी',
    'फैक्टरिंग',
    'फैक्टरी',
    'फैक्स',
    'फैज़',
    'फैजाबाद',
    'फैट',
    'फैटेसी',
    'फैडरेशन',
    'फैडैरल',
    'फैदम',
    'फैब्रिक',
    'फैमिली',
    'फैयाज',
    'फैयाजी',
    'फैर',
    'फैरस',
    'फैरी',
    'फैरेनहाइट',
    'फैल',
    'फैलन',
    'फैलना',
    'फैलने',
    'फैलनेवाले',
    'फैला',
    'फैलाई',
    'फैलाए',
    'फैलाकर',
    'फैलाना',
    'फैलाने',
    'फैलानेवाला',
    'फैलापन',
    'फैलाव',
    'फैलावट',
    'फैलावदार',
    'फैल्ट',
    'फैशन',
    'फैशनदार',
    'फैशनपसंद',
    'फैशनप्रिय',
    'फैशनप्रियता',
    'फैशनेबल',
    'फैसला',
    'फैसले',
    'फैस्टिवल',
    'फोएदर',
    'फोएर्म',
    'फोक',
    'फोकट',
    'फोकल',
    'फोकस',
    'फोकसिंग',
    'फोकस्ड',
    'फोका',
    'फोटू',
    'फोटो',
    'फोटोकापी',
    'फोटोग्रराफ़',
    'फोटोग्रराफ़र',
    'फोटोग्रराफ़ी',
    'फोटोग्रराफिंग',
    'फोटोग्राफ',
    'फोटोग्राफर',
    'फोटोग्राफिग',
    'फोटोग्राफी',
    'फोटोग्राफ़र',
    'फोटोचित्रण',
    'फोटोफ़िनिश',
    'फोटोस्टेट',
    'फोटोस्टैट',
    'फोड',
    'फोडक',
    'फोडना',
    'फोड़',
    'फोड़क',
    'फोड़ना',
    'फोड़ा',
    'फोडा',
    'फोता',
    'फोतेदार',
    'फोन',
    'फोम',
    'फोर',
    'फोरग्राउंड',
    'फोरपोस्टर',
    'फोरम',
    'फोरमाइका',
    'फोरमैन',
    'फोरशौर्टनिंग',
    'फोर्क',
    'फोर्ट',
    'फोर्टनाइटली',
    'फोर्थ',
    'फोर्स',
    'फोलिओ',
    'फोल्ड',
    'फोल्डर',
    'फोहा',
    'फौआरा',
    'फौइल',
    'फौज',
    'फौजदार',
    'फौजदारी',
    'फौजी',
    'फौजीपन',
    'फौत',
    'फौरन',
    'फौरमाइका',
    'फौरमूला',
    'फौरवर्ड',
    'फौरवर्डिंग',
    'फौरवर्डिग',
    'फौर्ज',
    'फौर्म',
    'फौर्मेशन',
    'फ्यूज',
    'फ्यूजर',
    'फ्यूहरर',
    'फ्यूज़',
    'फ्र',
    'फ्रंट',
    'फ्रंटियर',
    'फ्रजीहत',
    'फ्ररमाना',
    'फ्ररामोश',
    'फ्ररामोशी',
    'फ्ररोश',
    'फ्ररोशी',
    'फ्राँस',
    'फ्रांस',
    'फ्रांसवाला',
    'फ्रांसीसी',
    'फ्राइंग',
    'फ्राइडे',
    'फ्राजिल',
    'फ्रानन',
    'फ्रामिंग',
    'फ्रिक',
    'फ्रिगेट',
    'फ्रिज',
    'फ्री',
    'फ्रीज',
    'फ्रीजर',
    'फ्रीज़र',
    'फ्रीजिंग',
    'फ्रीलांसर',
    'फ्रील्ड',
    'फ्रुट',
    'फ्रूट',
    'फ्रेज',
    'फ्रेट',
    'फ्रेटर',
    'फ्रेटिंग',
    'फ्रेम',
    'फ्रेमवर्क',
    'फ्रैंक्चर',
    'फ्रैंच',
    'फ्रैंजी',
    'फ्रैंड',
    'फ्रैकिंग',
    'फ्रैक्टरी',
    'फ्लांइग',
    'फ्लाइ',
    'फ्लाइंग',
    'फ्लाइट',
    'फ्लाई',
    'फ्लापियां',
    'फ्लापी',
    'फ्लास्क',
    'फ्लिंटन',
    'फ्लीट',
    'फ्लुओरीन',
    'फ्लू',
    'फ्लूइड',
    'फ्लेट',
    'फ्लेटयुक्त',
    'फ्लैक',
    'फ्लैक्स',
    'फ्लैग',
    'फ्लैट',
    'फ्लैप',
    'फ्लैश',
    'फ्लैिक्सबिल',
    'फ्लोएपी',
    'फ्लोर',
    'फ्लौपी',
    'फ्ल्यू',
    'ब',
    'बँगला',
    'बँटना',
    'बँटवारा',
    'बँटा',
    'बँटाई',
    'बँटाईदार',
    'बँटाईदारी',
    'बँडेर',
    'बँदरी',
    'बँध',
    'बँधना',
    'बँधहीन',
    'बँधा',
    'बँधाई',
    'बँधान',
    'बँधाना',
    'बँधापन',
    'बँधाल',
    'बँधित',
    'बँधी',
    'बँधु',
    'बँधुआ',
    'बँधुता',
    'बँधुत्वपूर्ण',
    'बँधुत्वहीन',
    'बँधुदत्त',
    'बँधुवा',
    'बँधुवापन',
    'बँधू',
    'बँसवारी',
    'बँसी',
    'बँसीः',
    'बँसीधर',
    'बँहगी',
    'बँहोली',
    'बंक',
    'बंकता',
    'बंकनाल',
    'बंकर',
    'बंकाई',
    'बंकिंग',
    'बंकिम',
    'बंकिमता',
    'बंकुरता',
    'बंग',
    'बंगला',
    'बंगलौर',
    'बंगसार',
    'बंगाल',
    'बंगाली',
    'बंगीय',
    'बंजर',
    'बंजरपन',
    'बंजुल',
    'बंजूफल',
    'बंट',
    'बंटन',
    'बंटना',
    'बंटवाना',
    'बंटवारा',
    'बंटा',
    'बंटाई',
    'बंटाईदार',
    'बंटाना',
    'बंटित',
    'बंटी',
    'बंड',
    'बंडता',
    'बंडल',
    'बंडा',
    'बंडी',
    'बंडेर',
    'बंद',
    'बंदगी',
    'बंदगोभी',
    'बंददिमागी',
    'बंदनदार',
    'बंदर',
    'बंदरगाह',
    'बंदरगाहः',
    'बंदरगाहविहीन',
    'बंदरिया',
    'बंदरी',
    'बंदस्थान',
    'बंदा',
    'बंदाक',
    'बंदाजादा',
    'बंदानवाज',
    'बंदानवाजी',
    'बंदापरवर',
    'बंदिश',
    'बंदी',
    'बंदीकक्ष',
    'बंदीकरण',
    'बंदीकर्ता',
    'बंदीकृत',
    'बंदीकृतता',
    'बंदीखाना',
    'बंदीगृह',
    'बंदीघर',
    'बंदीजन',
    'बंदूक',
    'बंदूकची',
    'बंदूकधारी',
    'बंदे',
    'बंदोबंस्ती',
    'बंदोबस्त',
    'बंदोबस्ती',
    'बंदौं',
    'बंध',
    'बंधंकः',
    'बंधक',
    'बंधककर्ता',
    'बंधककर्त्ता',
    'बंधकग्राही',
    'बंधकधारी',
    'बंधकनामा',
    'बंधकपत्र',
    'बंधका',
    'बंधकापण',
    'बंधकित',
    'बंधकितता',
    'बंधकी',
    'बंधन',
    'बंधनकारी',
    'बंधनमुक्त',
    'बंधनहीन',
    'बंधनहीनता',
    'बंधना',
    'बंधनी',
    'बंधपत्र',
    'बंधयिता',
    'बंधव्य',
    'बंधा',
    'बंधाई',
    'बंधान',
    'बंधाल',
    'बंधिकता',
    'बंधित',
    'बंधी',
    'बंधु',
    'बंधुआ',
    'बंधुता',
    'बंधुल',
    'बंधे',
    'बंधेज',
    'बंध्यकरण',
    'बंध्यता',
    'बंध्या',
    'बंपुलिस',
    'बंबइया',
    'बंबई',
    'बंबा',
    'बंबारी',
    'बंबूकाट',
    'बंबूकार्ट',
    'बंस',
    'बंसत',
    'बंसरी',
    'बंसल',
    'बंसी',
    'बउराना',
    'बक',
    'बकचर',
    'बकझक',
    'बकट',
    'बकना',
    'बकबक',
    'बकबकिया',
    'बकरम',
    'बकरा',
    'बकरी',
    'बकरीः',
    'बकरीद',
    'बकलम',
    'बकलमखुद',
    'बकलस',
    'बकला',
    'बकवाद',
    'बकवादी',
    'बकवास',
    'बकवासपूर्ण',
    'बकवासी',
    'बकस',
    'बकसा',
    'बकसुआ',
    'बक़र्',
    'बक़ा',
    'बक़ाया',
    'बक़िया',
    'बकाया',
    'बकायात',
    'बकायादार',
    'बकाये',
    'बकायों',
    'बकार',
    'बकारना',
    'बकारि',
    'बकावली',
    'बकी',
    'बकुचना',
    'बकुचा',
    'बकुची',
    'बकुल',
    'बकैया',
    'बकोट',
    'बकोटना',
    'बकोटा',
    'बक्कड़',
    'बक्कम',
    'बक्कल',
    'बक्का',
    'बक्काल',
    'बक्की',
    'बक्खर',
    'बक्षी',
    'बक्स',
    'बक्सा',
    'बक्साबंद',
    'बक्साबंदी',
    'बखरा',
    'बखरी',
    'बखरैत',
    'बख़्त',
    'बख़्तर',
    'बख़्तरः',
    'बख़्तरपोश',
    'बख़्तरबंद',
    'बख़्तावर',
    'बख़्शना',
    'बख़्शयाना',
    'बख़्शाना',
    'बख़्शी',
    'बख़्शीश',
    'बखान',
    'बखानना',
    'बखाना',
    'बखार',
    'बखारी',
    'बखिया',
    'बखीर',
    'बखुद',
    'बखूबी',
    'बखेड़ा',
    'बखेडिया',
    'बखेर',
    'बखेरना',
    'बखेरु',
    'बखैर',
    'बखैरीयत',
    'बख्तर',
    'बख्तियार',
    'बख्शाना',
    'बख्शी',
    'बख्शीश',
    'बगड़म',
    'बगल',
    'बगला',
    'बगली',
    'बग़',
    'बग़छुट',
    'बग़मेल',
    'बग़री',
    'बग़ल',
    'बग़लगीरी',
    'बग़ला',
    'बग़लामुखी',
    'बग़ली',
    'बग़ावत',
    'बगावत',
    'बगाहे',
    'बगिया',
    'बगीचा',
    'बगीची',
    'बगुना',
    'बगुला',
    'बगुलाभगत',
    'बगूला',
    'बगैर',
    'बग्गी',
    'बग्गूगोशा',
    'बग्घी',
    'बग्धी',
    'बघनखा',
    'बघनहौं',
    'बघार',
    'बघारदार',
    'बघारना',
    'बघिया',
    'बघियाना',
    'बघेरा',
    'बच',
    'बचकर',
    'बचकाना',
    'बचकानापन',
    'बचत',
    'बचतः',
    'बचतकर्ता',
    'बचतखाता',
    'बचतें',
    'बचनबद्ध',
    'बचना',
    'बचने',
    'बचपन',
    'बचवा',
    'बचवैया',
    'बचा',
    'बचाई',
    'बचाऊ',
    'बचाकर',
    'बचाना',
    'बचाने',
    'बचाया',
    'बचाव',
    'बचावकारी',
    'बचावनहार',
    'बचावनहारा',
    'बची',
    'बचे',
    'बचेने',
    'बच्चा',
    'बच्चादानी',
    'बच्ची',
    'बच्चे',
    'बच्चो',
    'बच्चों',
    'बच्छल',
    'बच्छलता',
    'बछई',
    'बछड़ा',
    'बछड़ी',
    'बछड़े',
    'बछडा',
    'बछया',
    'बछल',
    'बछिया',
    'बछुआ',
    'बछेड़ा',
    'बछेरु',
    'बजंन्नी',
    'बजकना',
    'बजट',
    'बजटीय',
    'बजटेतर',
    'बजना',
    'बजनिया',
    'बजनी',
    'बजने',
    'बजबजाना',
    'बजर',
    'बजरंग',
    'बजरंगी',
    'बजरबट्टू',
    'बजरा',
    'बजरिया',
    'बजरी',
    'बजरीः',
    'बजवाब',
    'बज़रिए',
    'बज़रिया',
    'बज़ाज़',
    'बज़ाज़ा',
    'बज़ाज़ी',
    'बजा',
    'बजाकर',
    'बजाज',
    'बजाते',
    'बजाना',
    'बजाने',
    'बजानेवाला',
    'बजाय',
    'बजाया',
    'बजार',
    'बजारी',
    'बजारु',
    'बजावन',
    'बजि',
    'बजी',
    'बजूका',
    'बजे',
    'बजैया',
    'बज्जर',
    'बज्र',
    'बझ',
    'बझन',
    'बझना',
    'बट',
    'बटइया',
    'बटई',
    'बटख़रा',
    'बटन',
    'बटनदार',
    'बटनहार',
    'बटना',
    'बटने',
    'बटमार',
    'बटमारी',
    'बटर',
    'बटल',
    'बटलर',
    'बटली',
    'बटलोई',
    'बटवायक',
    'बटवार',
    'बटवारा',
    'बटहरी',
    'बटा',
    'बटाई',
    'बटाटा',
    'बटाणा',
    'बटान',
    'बटालियन',
    'बटिका',
    'बटिया',
    'बटी',
    'बटु',
    'बटुआ',
    'बटुई',
    'बटुक',
    'बटे',
    'बटेउ',
    'बटेर',
    'बटैया',
    'बटोर',
    'बटोरन',
    'बटोरना',
    'बटोरने',
    'बटोरा',
    'बटोरु',
    'बटोही',
    'बट्ट',
    'बट्टा',
    'बट्टागत',
    'बट्टाघर',
    'बट्टी',
    'बट्टू',
    'बट्टे',
    'बट्टेबाज',
    'बट्टेवाला',
    'बट्स',
    'बठना',
    'बडबडाहट',
    'बड़',
    'बड़का',
    'बड़कुल्ला',
    'बड़गा',
    'बड़गोदा',
    'बड़ना',
    'बड़प्पन',
    'बड़बड',
    'बड़बड़',
    'बड़बड़ाते',
    'बड़बड़ाना',
    'बड़बड़ाने',
    'बड़बड़ाहट',
    'बड़बडाना',
    'बड़बोला',
    'बड़भागी',
    'बड़यामुख',
    'बड़वा',
    'बड़वानल',
    'बड़हल',
    'बड़ा',
    'बड़ाई',
    'बड़ापन',
    'बड़ी',
    'बड़े',
    'बड़ेरा',
    'बडा',
    'बडी',
    'बढँवाँ',
    'बढते',
    'बढत्राना',
    'बढद्या',
    'बढना',
    'बढ़',
    'बढ़इया',
    'बढ़ई',
    'बढ़ईगिरी',
    'बढ़कर',
    'बढ़चढ़',
    'बढ़त',
    'बढ़ता',
    'बढ़ती',
    'बढ़ते',
    'बढ़न',
    'बढ़नशील',
    'बढ़ना',
    'बढ़नी',
    'बढ़ने',
    'बढ़नेवाली',
    'बढ़याँ',
    'बढ़वाँ',
    'बढ़ा',
    'बढ़ाई',
    'बढ़ाऊ',
    'बढ़ाकर',
    'बढ़ाचढ़ाकर',
    'बढ़ाना',
    'बढ़ाने',
    'बढ़ानेवाला',
    'बढ़ाबढ़ी',
    'बढ़ाया',
    'बढ़ाये',
    'बढ़ाव',
    'बढ़ावा',
    'बढ़िया',
    'बढ़ियापन',
    'बढ़ी',
    'बढ़ीए',
    'बढ़ीया',
    'बढ़े',
    'बढ़ेगा',
    'बढ़ैया',
    'बढ़ोतरी',
    'बढ़ोत्तरी',
    'बढ़ौतरी',
    'बढ़ौती',
    'बढा',
    'बढाना',
    'बणिक',
    'बत',
    'बतं',
    'बतंगड़',
    'बतंगड़िया',
    'बतकल्लुफ',
    'बतकल्लुफाना',
    'बतकल्लुफी',
    'बतकही',
    'बतख़',
    'बतबढ़ाव',
    'बतरस',
    'बतरसिया',
    'बतराना',
    'बतरौहाँ',
    'बतर्ज',
    'बतलाइए',
    'बतलाना',
    'बतहाशा',
    'बता',
    'बताइए',
    'बताई',
    'बताए',
    'बताएं',
    'बताओ',
    'बताते',
    'बताना',
    'बताने',
    'बतानेवाला',
    'बताम',
    'बताया',
    'बताये',
    'बतायें',
    'बताशा',
    'बतास',
    'बतासा',
    'बतिया',
    'बतियाना',
    'बतियार',
    'बतीस',
    'बतीसा',
    'बतीसी',
    'बतू',
    'बतोरी',
    'बतोला',
    'बतौर',
    'बत्तक',
    'बत्तख',
    'बत्तख़',
    'बत्ती',
    'बत्तीदान',
    'बत्तीवान',
    'बत्तीस',
    'बत्तीसी',
    'बत्रा',
    'बथरी',
    'बथान',
    'बथुआ',
    'बद',
    'बदंगी',
    'बदंरगाहः',
    'बदअमन',
    'बदअमनी',
    'बदअमली',
    'बदइंतजामी',
    'बदइतिजामी',
    'बदकार',
    'बदकारी',
    'बदकिस्मत',
    'बदकिस्मती',
    'बदखू',
    'बदख्वाह',
    'बदख्वाही',
    'बदगी',
    'बदगुमान',
    'बदगुमानी',
    'बदगोई',
    'बदचलन',
    'बदचलनी',
    'बदचश्म',
    'बदजबान',
    'बदजबानी',
    'बदजवानी',
    'बदज़बान',
    'बदज़बानी',
    'बदज़ात',
    'बदज़ायका',
    'बदतमीज',
    'बदतमीजी',
    'बदतमीज़ी',
    'बदतर',
    'बदतरता',
    'बदतरी',
    'बदतरीन',
    'बदतहजीबी',
    'बददयानत',
    'बददिमाग',
    'बददिमागी',
    'बददुआ',
    'बदन',
    'बदनज़र',
    'बदनसीब',
    'बदनसीबी',
    'बदनस्ल',
    'बदना',
    'बदनाम',
    'बदनामी',
    'बदनिगाह',
    'बदनीयत',
    'बदनीयती',
    'बदनुमा',
    'बदनुमाई',
    'बदपरहेज',
    'बदपरहेजी',
    'बदफेली',
    'बदबख़्त',
    'बदबख़्ती',
    'बदबू',
    'बदबूदार',
    'बदमज़गी',
    'बदमजा',
    'बदमस्त',
    'बदमस्ती',
    'बदमाश',
    'बदमाशी',
    'बदमाशों',
    'बदमिजाज',
    'बदमिजाजी',
    'बदमिजाज़',
    'बदमिजाज़ी',
    'बदमीजी',
    'बदयानती',
    'बदर',
    'बदरंग',
    'बदरंगी',
    'बदरपुर',
    'बदरा',
    'बदराह',
    'बदरिका',
    'बदरिया',
    'बदरी',
    'बदरीनाथ',
    'बदरीवासा',
    'बदल',
    'बदलगाम',
    'बदलता',
    'बदलती',
    'बदलते',
    'बदलना',
    'बदलने',
    'बदला',
    'बदलाना',
    'बदलाय',
    'बदलाया',
    'बदलायापन',
    'बदलाव',
    'बदली',
    'बदलूआ',
    'बदले',
    'बदलौवल',
    'बदशऊरी',
    'बदशक्ल',
    'बदसगुन',
    'बदसलीका',
    'बदसलूक',
    'बदसलूकी',
    'बदसूरत',
    'बदसूरती',
    'बदस्त',
    'बदस्तूर',
    'बदहजमी',
    'बदहज़मी',
    'बदहवास',
    'बदहवासी',
    'बदहाल',
    'बदहोश',
    'बदा',
    'बदाबदी',
    'बदामी',
    'बदी',
    'बदौलत',
    'बद्घ',
    'बद्घकक्ष',
    'बद्घकोष्ठता',
    'बद्घचित्त',
    'बद्घजिह्व',
    'बद्घता',
    'बद्घदृष्टि',
    'बद्घध्यान',
    'बद्घन',
    'बद्घनेत्र',
    'बद्घप्रतिज्ञ',
    'बद्घप्ररतिज्ञ',
    'बद्घमुष्टि',
    'बद्घमूल',
    'बद्घमौन',
    'बद्घवाक',
    'बद्घायुध',
    'बद्ध',
    'बद्धता',
    'बद्धहस्त',
    'बद्धिजीवी',
    'बद्धिमत्ता',
    'बद्धी',
    'बध',
    'बधनखा',
    'बधन्न',
    'बधाई',
    'बधाईर्',
    'बधायना',
    'बधाया',
    'बधाव',
    'बधावरा',
    'बधावा',
    'बधिक',
    'बधिया',
    'बधिर',
    'बधिरता',
    'बधिरिमा',
    'बधू',
    'बधूटी',
    'बन',
    'बनउर',
    'बनकंडा',
    'बनकन्या',
    'बनकर',
    'बनखंड',
    'बनखंडी',
    'बनचर',
    'बनचौरी',
    'बनजारा',
    'बनजारागीरी',
    'बनझाऊ',
    'बनड़ा',
    'बनडा',
    'बनडी',
    'बनत',
    'बनता',
    'बनती',
    'बनदेई',
    'बनदेय',
    'बनदेवी',
    'बनन',
    'बनना',
    'बनने',
    'बनपट',
    'बनबेर',
    'बनमानुस',
    'बनमाली',
    'बनमूँग',
    'बनरा',
    'बनराय',
    'बनरिया',
    'बनरी',
    'बनरुह',
    'बनवा',
    'बनवाकर',
    'बनवाना',
    'बनवाने',
    'बनवारी',
    'बनवास',
    'बनवासी',
    'बनस्थली',
    'बनहटी',
    'बना',
    'बनाई',
    'बनाईं',
    'बनाए',
    'बनाओ',
    'बनाकर',
    'बनात',
    'बनाता',
    'बनाते',
    'बनाना',
    'बनाने',
    'बनानेवाला',
    'बनाबनत',
    'बनाम',
    'बनाया',
    'बनाये',
    'बनारस',
    'बनारसी',
    'बनालू',
    'बनाव',
    'बनावट',
    'बनावटी',
    'बनावटीपन',
    'बनावनहार',
    'बनिज',
    'बनिया',
    'बनियाइन',
    'बनियाज़',
    'बनियाजी',
    'बनियान',
    'बनिर्शिंग',
    'बनिस्बत',
    'बनी',
    'बनीर',
    'बने',
    'बनेठी',
    'बनैला',
    'बनौरी',
    'बन्द',
    'बन्दगोभी',
    'बन्दर',
    'बन्दरगाह',
    'बन्दरगाही',
    'बन्दरपन',
    'बन्दवा',
    'बन्दी',
    'बन्दीकरण',
    'बन्दूक',
    'बन्दूकधारी',
    'बन्दोबस्त',
    'बन्दोबस्ती',
    'बन्ध',
    'बन्धक',
    'बन्धककर्ता',
    'बन्धककर्त्ता',
    'बन्धकदाता',
    'बन्धकपत्र',
    'बन्धकी',
    'बन्धन',
    'बन्धनी',
    'बन्धु',
    'बन्ध्यता',
    'बन्ना',
    'बन्नात',
    'बन्नी',
    'बपतिरमा',
    'बपतिस्मा',
    'बपु',
    'बपुश्त',
    'बपोती',
    'बपौती',
    'बप्पा',
    'बफ़',
    'बफ़र',
    'बफ़र्',
    'बफ़र्खाना',
    'बफ़ा',
    'बफ़ारा',
    'बबर',
    'बबलू',
    'बबुआ',
    'बबुई',
    'बबूल',
    'बबूला',
    'बब्बर',
    'बब्बू',
    'बभनी',
    'बभ्रु',
    'बम',
    'बमकना',
    'बमकिन',
    'बमचख',
    'बमचखिया',
    'बमपुलिस',
    'बमबाज़',
    'बमबाजी',
    'बमबारी',
    'बमभार',
    'बममारी',
    'बमवर्षक',
    'बमवर्षा',
    'बमिसाल',
    'बमीठा',
    'बमुकाबला',
    'बमुश्किल',
    'बमोट',
    'बम्पर',
    'बम्बी',
    'बम्हनी',
    'बय',
    'बयँहत्थापन',
    'बयक',
    'बयन',
    'बयना',
    'बयबान',
    'बया',
    'बयाई',
    'बयान',
    'बयाना',
    'बयानी',
    'बयार',
    'बयाला',
    'बयैहत्था',
    'बर',
    'बरंगा',
    'बरअक्स',
    'बरइन',
    'बरई',
    'बरकंदाज़',
    'बरकत',
    'बरकरार',
    'बरक़रार',
    'बरकाज',
    'बरख़ास्त',
    'बरख़ास्तगी',
    'बरख़िलाफ',
    'बरखा',
    'बरखास्त',
    'बरखास्तगी',
    'बरखुरदार',
    'बरगद',
    'बरगलाई',
    'बरगलाना',
    'बरगलाया',
    'बरग़लाना',
    'बरछा',
    'बरछी',
    'बरछेबाज़ी',
    'बरछैत',
    'बरछों',
    'बरजना',
    'बरजबान',
    'बरजस्ता',
    'बरजोरी',
    'बरटफ्लाई',
    'बरत',
    'बरतन',
    'बरतनः',
    'बरतना',
    'बरतने',
    'बरतर',
    'बरतरफ',
    'बरतरफी',
    'बरतरस',
    'बरता',
    'बरताई',
    'बरताव',
    'बरती',
    'बरदा',
    'बरदार',
    'बरदारी',
    'बरदाश्त',
    'बरन',
    'बरना',
    'बरनाला',
    'बरपा',
    'बरफ',
    'बरफः',
    'बरफखाना',
    'बरफानी',
    'बरफी',
    'बरफीला',
    'बरबंटी',
    'बरबरियत',
    'बरबस',
    'बरबाद',
    'बरबादी',
    'बरमा',
    'बरराना',
    'बरस',
    'बरसता',
    'बरसना',
    'बरसाऊ',
    'बरसात',
    'बरसाती',
    'बरसाना',
    'बरसाने',
    'बरसायत',
    'बरसाव',
    'बरसी',
    'बरसीला',
    'बरसू',
    'बरसैया',
    'बरसौंहा',
    'बरहा',
    'बरही',
    'बरांडा',
    'बराए',
    'बरात',
    'बराती',
    'बराबर',
    'बराबरी',
    'बराम',
    'बरामद',
    'बरामदगी',
    'बरामदा',
    'बराय',
    'बरायन',
    'बरार',
    'बरावुर्दर्',
    'बरास्ता',
    'बराह',
    'बराहे',
    'बरिच्छा',
    'बरी',
    'बरुआ',
    'बरूथी',
    'बरेंडा',
    'बरेखी',
    'बरेच्छा',
    'बरेज',
    'बरेजा',
    'बरेठा',
    'बरेत',
    'बरेता',
    'बरेली',
    'बरेव',
    'बरोक',
    'बरोज',
    'बरोठा',
    'बरोठी',
    'बरोठे',
    'बरोह',
    'बरौठा',
    'बरौनी',
    'बर्कत्ररफ्तार',
    'बर्खास्त',
    'बर्गर',
    'बर्गलाना',
    'बर्ची',
    'बर्जन',
    'बर्ड',
    'बर्तन',
    'बर्तनों',
    'बर्ताव',
    'बर्थ',
    'बर्थर्ड',
    'बर्दाश्त',
    'बर्न',
    'बर्नर',
    'बर्नरों',
    'बर्फ',
    'बर्फखाना',
    'बर्फानी',
    'बर्फावरण',
    'बर्फीला',
    'बर्बट',
    'बर्बर',
    'बर्बरता',
    'बर्बरतापूर्ण',
    'बर्बरतापूर्वक',
    'बर्बरीक',
    'बर्बरीकरण',
    'बर्बाद',
    'बर्बूर',
    'बर्म',
    'बर्मा',
    'बर्मी',
    'बर्र',
    'बर्राना',
    'बर्रे',
    'बर्सर',
    'बर्ह',
    'बर्हिकेश',
    'बल',
    'बलइर',
    'बलखाया',
    'बलगति',
    'बलचक्र',
    'बलद',
    'बलदाऊ',
    'बलदार',
    'बलदिया',
    'बलदेय',
    'बलपति',
    'बलपूर्ण',
    'बलपूर्वक',
    'बलप्रयोग',
    'बलप्रसू',
    'बलबल',
    'बलबलाना',
    'बलबलाहट',
    'बलबीर',
    'बलबूता',
    'बलभ',
    'बलभद्र',
    'बलमया',
    'बलमा',
    'बलमापी',
    'बलयंत',
    'बलराम',
    'बलरामः',
    'बलवंत',
    'बलवता',
    'बलवती',
    'बलवर्धन',
    'बलवा',
    'बलवाई',
    'बलवान',
    'बलविन्दर',
    'बलवीर',
    'बलशालिता',
    'बलशाली',
    'बलहीन',
    'बलहीनता',
    'बला',
    'बलाएँ',
    'बलाएं',
    'बलाक',
    'बलाघात',
    'बलात',
    'बलातिरेक',
    'बलात्',
    'बलात्कार',
    'बलात्कारक',
    'बलात्कारी',
    'बलात्ग्रहण',
    'बलात्मक',
    'बलाद्ग्रहण',
    'बलाधिकृत',
    'बलाध्यक्ष',
    'बलानुज',
    'बलापहरण',
    'बलाय',
    'बलारि',
    'बलाहक',
    'बला्त्',
    'बलि',
    'बलिः',
    'बलिक्रिया',
    'बलित',
    'बलिदत्त',
    'बलिदान',
    'बलिदानी',
    'बलिद्वेषी',
    'बलिध्वंसी',
    'बलिपुष्ट',
    'बलिभोग',
    'बलिमा',
    'बलिमुख',
    'बलिरिपु',
    'बलिवेदी',
    'बलिवेश्म',
    'बलिश',
    'बलिष्ठ',
    'बलिष्ठता',
    'बलिहंता',
    'बलिहारना',
    'बलिहारी',
    'बली',
    'बलीय',
    'बलीवर्द',
    'बलुआ',
    'बलूचिस्तान',
    'बलूती',
    'बलैक',
    'बलैयाँ',
    'बल्कि',
    'बल्ब',
    'बल्मीक',
    'बल्लभ',
    'बल्लम',
    'बल्लर',
    'बल्लव',
    'बल्लवी',
    'बल्ला',
    'बल्ली',
    'बल्ले',
    'बल्लेबाज',
    'बल्वल',
    'बवंडर',
    'बवंडरीय',
    'बवना',
    'बवाना',
    'बवासीर',
    'बवेफाई',
    'बश',
    'बशर',
    'बशर्त',
    'बशर्ते',
    'बशाने',
    'बशेशर',
    'बशौक',
    'बस',
    'बसंत',
    'बसंता',
    'बसंती',
    'बसन',
    'बसना',
    'बसनाः',
    'बसनी',
    'बसने',
    'बसन्त',
    'बसर',
    'बसरा',
    'बसली',
    'बसा',
    'बसान',
    'बसाना',
    'बसाने',
    'बसापन',
    'बसाल्अ',
    'बसाल्ट',
    'बसावट',
    'बसिया',
    'बसियौरा',
    'बसीठ',
    'बसीला',
    'बसूरत',
    'बसूला',
    'बसेरा',
    'बसेसर',
    'बसैया',
    'बसौंधा',
    'बसौंधी',
    'बसौधा',
    'बस्ट',
    'बस्त',
    'बस्ता',
    'बस्तारस',
    'बस्तियां',
    'बस्तियों',
    'बस्ती',
    'बह',
    'बहँगिया',
    'बहँगी',
    'बहंत',
    'बहक',
    'बहकना',
    'बहकर',
    'बहका',
    'बहकाई',
    'बहकाऊ',
    'बहकाना',
    'बहकाने',
    'बहकाया',
    'बहकाव',
    'बहकावट',
    'बहकावा',
    'बहता',
    'बहती',
    'बहते',
    'बहत्तरी',
    'बहन',
    'बहना',
    'बहनाः',
    'बहनाई',
    'बहनापा',
    'बहनिया',
    'बहनी',
    'बहने',
    'बहनें',
    'बहनेली',
    'बहनोई',
    'बहनोली',
    'बहनौता',
    'बहम',
    'बहर',
    'बहरा',
    'बहरापन',
    'बहरुपिया',
    'बहरुल्काहित',
    'बहल',
    'बहलना',
    'बहलवान',
    'बहला',
    'बहलाऊ',
    'बहलाना',
    'बहलाव',
    'बहलावा',
    'बहलिया',
    'बहली',
    'बहवानीक',
    'बहस',
    'बहसाबहसी',
    'बहसाबाज',
    'बहसिया',
    'बहसी',
    'बहा',
    'बहाई',
    'बहाउल्लाहः',
    'बहाऊ',
    'बहाकर',
    'बहाती',
    'बहादुर',
    'बहादुरी',
    'बहाना',
    'बहाने',
    'बहानेबाज',
    'बहानेबाज़',
    'बहानेबाजी',
    'बहानेबाज़',
    'बहानेबाज़ी',
    'बहार',
    'बहाल',
    'बहालत',
    'बहाली',
    'बहाव',
    'बहावदार',
    'बहावन',
    'बहिः',
    'बहिक्षेत्र',
    'बहिग्रॉम',
    'बहिदेश',
    'बहिदेशियता',
    'बहिन',
    'बहिनिर्वास',
    'बहिनिर्ष्कर्षण',
    'बहिमार्ग',
    'बहियाँ',
    'बहियों',
    'बहिरंग',
    'बहिरंगता',
    'बहिरभिमुखता',
    'बहिरभिमुखी',
    'बहिरावास',
    'बहिरिंद्रिय',
    'बहिर्गत',
    'बहिर्गम',
    'बहिर्गमन',
    'बहिर्गमनशील',
    'बहिर्गामी',
    'बहिर्गृह',
    'बहिर्गेह',
    'बहिर्जंघिका',
    'बहिर्जन',
    'बहिर्जात',
    'बहिर्दुर्ग',
    'बहिर्देशिय',
    'बहिर्देशीय',
    'बहिर्द्वार',
    'बहिर्भार्ग',
    'बहिर्मुख',
    'बहिर्मुखतः',
    'बहिर्मुखता',
    'बहिर्मुखी',
    'बहिर्यात्रा',
    'बहिर्वर्ती',
    'बहिर्वास',
    'बहिर्वासी',
    'बहिर्वाह',
    'बहिर्विवाह',
    'बहिर्विश्वविद्यालयी',
    'बहिर्वृद्धि',
    'बहिर्वेशन',
    'बहिवैवाहिक',
    'बहिश्त',
    'बहिष्करण',
    'बहिष्कार',
    'बहिष्कारक',
    'बहिष्कृत',
    'बहिष्कृतः',
    'बहिापा',
    'बही',
    'बहीं',
    'बहीखाता',
    'बहीखातों',
    'बहीदगो',
    'बहु',
    'बहुंपुकेसरी',
    'बहुंसख्या',
    'बहुअंशीय',
    'बहुअक्षर',
    'बहुअक्षरता',
    'बहुआयामता',
    'बहुआयामिता',
    'बहुआयामी',
    'बहुआयामीय',
    'बहुकंटक',
    'बहुकक्षीय',
    'बहुकर',
    'बहुकरी',
    'बहुकर्मा',
    'बहुकालिक',
    'बहुकालिकता',
    'बहुकेन्द्री',
    'बहुकोणीयता',
    'बहुक्म',
    'बहुक्रेताधिकार',
    'बहुक्षारिकी',
    'बहुखंडीय',
    'बहुखंडीयता',
    'बहुगमन',
    'बहुगामिनी',
    'बहुगामी',
    'बहुगुणता',
    'बहुगुणा',
    'बहुगुणित',
    'बहुगुणी',
    'बहुगुना',
    'बहुचक्री',
    'बहुचचिर्त',
    'बहुचर्चित',
    'बहुछिद्री',
    'बहुजननी',
    'बहुजनहितार्थ',
    'बहुजल्प',
    'बहुजल्पिता',
    'बहुज्ञ',
    'बहुज्ञता',
    'बहुत',
    'बहुतंत्र',
    'बहुतता',
    'बहुततायत',
    'बहुता',
    'बहुतायत',
    'बहुतेरा',
    'बहुतेरे',
    'बहुदन्ती',
    'बहुदर्शी',
    'बहुदली',
    'बहुदलीय',
    'बहुदा',
    'बहुदिश',
    'बहुदिशता',
    'बहुदेववाद',
    'बहुदेववादी',
    'बहुदेवात्मक',
    'बहुदेशी',
    'बहुदेशीय',
    'बहुधंधिता',
    'बहुधंधी',
    'बहुधर्मवाद',
    'बहुधा',
    'बहुध्वनिकता',
    'बहुना',
    'बहुनाद',
    'बहुनाम',
    'बहुपक्षीय',
    'बहुपति',
    'बहुपतिका',
    'बहुपतित्व',
    'बहुपत्नी',
    'बहुपत्नीक',
    'बहुपत्नीत्व',
    'बहुपद',
    'बहुपदी',
    'बहुपरिचय',
    'बहुपर्णी',
    'बहुपाठित',
    'बहुपाद',
    'बहुपाश्र्व',
    'बहुपाश्र्वीय',
    'बहुपाश्र्वीयता',
    'बहुपुंकेसरता',
    'बहुपुष्प',
    'बहुपुष्पी',
    'बहुप्रचलित',
    'बहुप्रज',
    'बहुप्रजता',
    'बहुप्रदेश',
    'बहुप्रशस्त',
    'बहुप्रसवा',
    'बहुप्रसू',
    'बहुप्राणति',
    'बहुफलक',
    'बहुबार',
    'बहुबाहु',
    'बहुबाहुता',
    'बहुबीजाणु',
    'बहुबीजी',
    'बहुभजता',
    'बहुभागीय',
    'बहुभाग्य',
    'बहुभार्यतव',
    'बहुभाषावाद',
    'बहुभाषाविद',
    'बहुभाषिक',
    'बहुभाषिया',
    'बहुभाषी',
    'बहुभुज',
    'बहुभुजा',
    'बहुभुजाकार',
    'बहुभुजी',
    'बहुभूज',
    'बहुभूमिक',
    'बहुभोग्या',
    'बहुभोजी',
    'बहुमंजरी',
    'बहुमंजिला',
    'बहुमत',
    'बहुमात्रा',
    'बहुमान्य',
    'बहुमिश्रण',
    'बहुमुखता',
    'बहुमुखी',
    'बहुमूत्रता',
    'बहुमूर्ति',
    'बहुमूल',
    'बहुमूलजता',
    'बहुमूल्य',
    'बहुमूल्यतः',
    'बहुमूल्यता',
    'बहुरंग',
    'बहुरंगी',
    'बहुरंगीयता',
    'बहुरना',
    'बहुरस',
    'बहुरि',
    'बहुरुप',
    'बहुरुपक',
    'बहुरुपता',
    'बहुरुपा',
    'बहुरुपिया',
    'बहुरुपी',
    'बहुरूपदर्शी',
    'बहुरूपी',
    'बहुरेता',
    'बहुल',
    'बहुलक',
    'बहुलकता',
    'बहुलता',
    'बहुला',
    'बहुलिंगी',
    'बहुलित',
    'बहुलीकरण',
    'बहुलेखक',
    'बहुलेखित्र',
    'बहुवचन',
    'बहुवर्ण',
    'बहुवर्णता',
    'बहुवर्णी',
    'बहुवर्षी',
    'बहुवर्षीय',
    'बहुवादी',
    'बहुविकल्प',
    'बहुविद',
    'बहुविध',
    'बहुविधतः',
    'बहुविधि',
    'बहुविभागी',
    'बहुविवाह',
    'बहुविवाही',
    'बहुविषयक',
    'बहुवृष्टि',
    'बहुव्ययी',
    'बहुव्यवसायी',
    'बहुव्यापकता',
    'बहुव्यापी',
    'बहुव्रीहि',
    'बहुशः',
    'बहुशिल्पज्ञता',
    'बहुशीर्ष',
    'बहुश्रुत',
    'बहुसंख्य',
    'बहुसंख्यक',
    'बहुसंख्यकता',
    'बहुसंख्या',
    'बहुसंगिनी',
    'बहुसंभोगी',
    'बहुसंयोजक',
    'बहुसंश्लिष्ट',
    'बहुसर्जी',
    'बहुसार',
    'बहुसुरात्मक',
    'बहुसू',
    'बहुसूचित',
    'बहुस्तर',
    'बहुस्तरीय',
    'बहुस्तरीयता',
    'बहुस्थलीय',
    'बहुस्पर्धी',
    'बहुस्रवा',
    'बहुस्वर',
    'बहुस्वरता',
    'बहुफ़लक',
    'बहुफ़लकीय',
    'बहू',
    'बहूटी',
    'बहूपयोगी',
    'बहूप्रसू',
    'बहूमूत्रता',
    'बहे',
    'बहेंतु',
    'बहेतु',
    'बहेतुआपन',
    'बहेलिया',
    'बहेलियागिरी',
    'बहेली',
    'बहैसियत',
    'बहोरना',
    'बहोरा',
    'बहोरि',
    'बह्म',
    'बाँक',
    'बाँकडा',
    'बाँकडी',
    'बाँकपन',
    'बाँका',
    'बाँकिया',
    'बाँकी',
    'बाँकुरा',
    'बाँग',
    'बाँगड़',
    'बाँगड़ा',
    'बाँगड़ी',
    'बाँगड़ु',
    'बाँगुर',
    'बाँग्ला',
    'बाँचक',
    'बाँचन',
    'बाँचना',
    'बाँचने',
    'बाँछ',
    'बाँझ',
    'बाँझपन',
    'बाँट',
    'बाँटना',
    'बाँटने',
    'बाँड़ा',
    'बाँदी',
    'बाँदू',
    'बाँध',
    'बाँधना',
    'बाँधनू',
    'बाँधे',
    'बाँबी',
    'बाँय',
    'बाँस',
    'बाँसः',
    'बाँसवारी',
    'बाँसा',
    'बाँसुरी',
    'बाँसुरीः',
    'बाँह',
    'बाँही',
    'बांइडर',
    'बांई',
    'बांए',
    'बांका',
    'बांग',
    'बांग्लादेश',
    'बांग्लादेशी',
    'बांग्लादेशीय',
    'बांझ',
    'बांझपन',
    'बांट',
    'बांटते',
    'बांटना',
    'बांटने',
    'बांड',
    'बांडधारी',
    'बांड़',
    'बांडी',
    'बांध',
    'बांधकर',
    'बांधना',
    'बांधने',
    'बांधव',
    'बांधवता',
    'बांधवा',
    'बांधव्य',
    'बांधे',
    'बांबी',
    'बांया',
    'बांयी',
    'बांस',
    'बांसुरी',
    'बांह',
    'बाअदब',
    'बाअसर',
    'बाआबरु',
    'बाइ',
    'बाइँ',
    'बाइंडर',
    'बाइंडिंग',
    'बाइक',
    'बाइकार्ब',
    'बाइट',
    'बाइन',
    'बाइनैरी',
    'बाइपास',
    'बाइपासिंग',
    'बाइबिल',
    'बाइरादा',
    'बाइलेन',
    'बाइसिकल',
    'बाई',
    'बाईबिल',
    'बाईमान',
    'बाईमानी',
    'बाईर्',
    'बाईसा',
    'बाउ',
    'बाउंड',
    'बाउंडरी',
    'बाउम्मीद',
    'बाउल',
    'बाउलर',
    'बाऍं',
    'बाएँ',
    'बाएं',
    'बाकंक',
    'बाकंकर',
    'बाकंकरों',
    'बाकंकवार',
    'बाकंकिंग',
    'बाकंकिग',
    'बाकंकेतर',
    'बाकंको',
    'बाकंकों',
    'बाकंगन',
    'बाकंगनी',
    'बाकंच',
    'बाकंड',
    'बाकंडमैन',
    'बाकला',
    'बाकायदगी',
    'बाकायदा',
    'बाकी',
    'बाकीदार',
    'बाकीदारी',
    'बाकुचि',
    'बाक्स',
    'बाखर',
    'बाग',
    'बागड़',
    'बागडोर',
    'बागबानी',
    'बागर',
    'बागवानी',
    'बाग़',
    'बाग़बान',
    'बाग़बानी',
    'बाग़रज',
    'बाग़ान',
    'बाग़ी',
    'बाग़ीचा',
    'बागान',
    'बागी',
    'बाघ',
    'बाघिन',
    'बाघ्यताएं',
    'बाचना',
    'बाछ',
    'बाछना',
    'बाछा',
    'बाछी',
    'बाछे',
    'बाज',
    'बाजपालन',
    'बाजर',
    'बाजरा',
    'बाजरे',
    'बाजवा',
    'बाज़',
    'बाज़गश्त',
    'बाज़रा',
    'बाज़री',
    'बाज़ा',
    'बाज़ाब्ता',
    'बाज़ायका',
    'बाज़ार',
    'बाज़ारी',
    'बाज़ारु',
    'बाज़ी',
    'बाज़ीगर',
    'बाज़ीगरी',
    'बाज़ूबंद',
    'बाज़ौक',
    'बाज़ौकी',
    'बाजा',
    'बाजागाजा',
    'बाजार',
    'बाजारी',
    'बाजी',
    'बाजीगर',
    'बाजीगरी',
    'बाजू',
    'बाजूबंद',
    'बाजे',
    'बाजों',
    'बाट',
    'बाटना',
    'बाटली',
    'बाटी',
    'बाठ',
    'बाड़',
    'बाड़व',
    'बाड़ा',
    'बाड़ी',
    'बाड़े',
    'बाडा',
    'बाढ',
    'बाढ़',
    'बाढ़क्रांत',
    'बाढ़ग्रस्त',
    'बाढ़हीन',
    'बाढ़ीवान',
    'बाण',
    'बाणः',
    'बाणधि',
    'बाणमुख',
    'बाणहा',
    'बाणाग्र',
    'बाणाध्यक्ष',
    'बाणाश्रय',
    'बाणासन',
    'बाणासुरः',
    'बाणी',
    'बात',
    'बातचीत',
    'बातरतीब',
    'बातरतीबी',
    'बातरीका',
    'बातलबंद',
    'बातली',
    'बातलीकृत',
    'बातिल',
    'बातिलकरण',
    'बातिलपरस्त',
    'बाती',
    'बातीः',
    'बातुनी',
    'बातूनी',
    'बातूनीपन',
    'बातें',
    'बातों',
    'बाथ',
    'बाथरुम',
    'बाथू',
    'बाद',
    'बादक',
    'बादकश',
    'बादगीर',
    'बादजान',
    'बादनुमा',
    'बादबान',
    'बादबानी',
    'बादर',
    'बादरा',
    'बादरायण',
    'बादल',
    'बादला',
    'बादलों',
    'बादशाह',
    'बादशाहजादा',
    'बादशाहजादी',
    'बादशाहत',
    'बादशाहना',
    'बादशाही',
    'बादाम',
    'बादामी',
    'बाध',
    'बाधक',
    'बाधकता',
    'बाधन',
    'बाधना',
    'बाधा',
    'बाधाएं',
    'बाधाग्रस्त',
    'बाधाग्रस्तता',
    'बाधाहीन',
    'बाधाहीनतः',
    'बाधाहीनता',
    'बाधिक',
    'बाधित',
    'बाधितता',
    'बाधिता',
    'बाधी',
    'बाध्य',
    'बाध्यक',
    'बाध्यकारी',
    'बाध्यता',
    'बाध्यताकारी',
    'बाध्यताधारी',
    'बाध्यतापूर्वक',
    'बान',
    'बानक',
    'बानगी',
    'बानदराज़',
    'बानर',
    'बानरी',
    'बाना',
    'बानात',
    'बानिया',
    'बानी',
    'बाने',
    'बानेट',
    'बानैत',
    'बानो',
    'बाप',
    'बापदादे',
    'बापदादों',
    'बापा',
    'बापुरा',
    'बापू',
    'बाफ',
    'बाब',
    'बाबत',
    'बाबा',
    'बाबुनियाद',
    'बाबुल',
    'बाबू',
    'बाबूल',
    'बाभन',
    'बाम',
    'बामज़ा',
    'बामी',
    'बामुराद',
    'बामुरौवत',
    'बामुसक्क़त',
    'बामौका',
    'बाम्हन',
    'बाम्हनी',
    'बाय',
    'बायँ',
    'बायटा',
    'बायन',
    'बायनः',
    'बायना',
    'बायभक',
    'बायल',
    'बायलर',
    'बायस',
    'बायाँ',
    'बायां',
    'बायी',
    'बायें',
    'बायों',
    'बायोगैस',
    'बायोडाटा',
    'बार',
    'बारंबार',
    'बारंबारता',
    'बारकश',
    'बारखाना',
    'बारगाह',
    'बारगीर',
    'बारजा',
    'बारदाना',
    'बारदार',
    'बारनगीर',
    'बारना',
    'बारबरदार',
    'बारबरदारी',
    'बारबार',
    'बारमैन',
    'बारम्बार',
    'बारस',
    'बारसओवर',
    'बारह',
    'बारहदरा',
    'बारहदरी',
    'बारहबानी',
    'बारहमासी',
    'बारहसिंगा',
    'बारहा',
    'बारहों',
    'बारा',
    'बारात',
    'बाराती',
    'बारादरी',
    'बारानी',
    'बारावफ्रात',
    'बारिक',
    'बारिज',
    'बारिधि',
    'बारिश',
    'बारिस्टर',
    'बारी',
    'बारीक',
    'बारीक़',
    'बारीक़ी',
    'बारीकी',
    'बारुद',
    'बारुदी',
    'बारूद',
    'बारूदखाना',
    'बारूदी',
    'बारे',
    'बारेक',
    'बारेज',
    'बारोज़गार',
    'बार्क',
    'बार्ज',
    'बार्टर',
    'बार्डर',
    'बार्बेक्यूइंग',
    'बार्स',
    'बार्हद्रथ',
    'बार्हस्पत्य',
    'बाल',
    'बालः',
    'बालक',
    'बालकक्ष',
    'बालकता',
    'बालकनी',
    'बालकिशन',
    'बालकृष्ण',
    'बालकेश्वर',
    'बालचर',
    'बालचर्या',
    'बालचिकित्सा',
    'बालछड़',
    'बालज्',
    'बालटी',
    'बालता',
    'बालदार',
    'बालदारी',
    'बालधन',
    'बालना',
    'बालपन',
    'बालपाइंट',
    'बालपैन',
    'बालबच्चे',
    'बालबच्चों',
    'बालम',
    'बालमा',
    'बालयुक्त',
    'बालवत',
    'बालवत्ता',
    'बालविहार',
    'बालसुलभ',
    'बालसुलभता',
    'बाला',
    'बालाई',
    'बालाए',
    'बालाखा',
    'बालाजी',
    'बालापन',
    'बालापोश',
    'बालाबंद',
    'बालि',
    'बालिं',
    'बालिंग',
    'बालिका',
    'बालिग',
    'बालिगपन',
    'बालिग़',
    'बालित',
    'बालिश',
    'बालिशता',
    'बालिश्त',
    'बालिश्तिया',
    'बालिस',
    'बालिस्टिक',
    'बाली',
    'बालीं',
    'बालीः',
    'बालीसुत',
    'बालीहंता',
    'बालु',
    'बालुका',
    'बालुकाश्म',
    'बालू',
    'बालूचा',
    'बालूदानी',
    'बालूदार',
    'बालूशाही',
    'बाले',
    'बालें',
    'बालेंदु',
    'बालेय',
    'बालेश्वर',
    'बालों',
    'बालोंवाला',
    'बालोचित',
    'बालोधितता',
    'बालोपयोगी',
    'बाल्टी',
    'बाल्टीभर',
    'बाल्टू',
    'बाल्मीकी',
    'बाल्य',
    'बावचीं',
    'बावजूद',
    'बावड़ी',
    'बावन',
    'बावफ़ा',
    'बावरची',
    'बावरा',
    'बावर्ची',
    'बावला',
    'बावलापन',
    'बावली',
    'बावैला',
    'बाशऊर',
    'बाशिंदगी',
    'बाशिंदा',
    'बास',
    'बासंती',
    'बासन',
    'बासना',
    'बासमती',
    'बासय',
    'बासर',
    'बासरो',
    'बासलीका',
    'बासा',
    'बासिलसिला',
    'बासी',
    'बासीमुँह',
    'बासौंधी',
    'बास्किट',
    'बास्केट',
    'बास्केटवाल',
    'बाह',
    'बाहना',
    'बाहर',
    'बाहरगाँव',
    'बाहरला',
    'बाहरवां',
    'बाहरी',
    'बाहरीजेब',
    'बाहरीपन',
    'बाहु',
    'बाहुक',
    'बाहुज',
    'बाहुत्र',
    'बाहुत्राण',
    'बाहुदा',
    'बाहुधरन',
    'बाहुधारक',
    'बाहुपाश',
    'बाहुबंद',
    'बाहुबल',
    'बाहुमूल',
    'बाहुयुद्घ',
    'बाहुयोधी',
    'बाहुल',
    'बाहुलेय',
    'बाहुल्य',
    'बाहुवीर्य',
    'बाहुवीर्यता',
    'बाहुशाली',
    'बाहू',
    'बाहृ',
    'बाहृयतम',
    'बाहोश',
    'बाहोशी',
    'बाह्य',
    'बाह्यकाश',
    'बाह्यता',
    'बाह्यत्वचा',
    'बाह्यभित्ति',
    'बाह्यरूप',
    'बाह्यवती',
    'बाह्यवर्तिता',
    'बाह्यवर्ती',
    'बाह्यस्थ',
    'बाह्याकृत',
    'बाह्यागमन',
    'बाह्याचरण',
    'बाह्यादानवादी',
    'बाह्यावरण',
    'बाह्यीकरण',
    'बाह्यीभवन',
    'बाह्रयचल',
    'बाग़ान',
    'बाज़',
    'बाज़ार',
    'बाज़ी',
    'बिंदा',
    'बिंदास',
    'बिंदासपन',
    'बिंदिया',
    'बिंदी',
    'बिंदु',
    'बिंदुः',
    'बिंदुक',
    'बिंदुचित्र',
    'बिंदुत्व',
    'बिंदुयुक्त',
    'बिंदुल',
    'बिंदुली',
    'बिंदुवार',
    'बिंदू',
    'बिंधना',
    'बिंधा',
    'बिंधाई',
    'बिंब',
    'बिंबक',
    'बिंबावली',
    'बिंबिका',
    'बिंरछ',
    'बिंरजारी',
    'बिंरजी',
    'बिअ',
    'बिक',
    'बिकना',
    'बिकनेवाली',
    'बिकल',
    'बिकलाई',
    'बिकवाना',
    'बिकवाल',
    'बिकवाली',
    'बिका',
    'बिकाऊ',
    'बिकाना',
    'बिकिनी',
    'बिक्रियों',
    'बिक्री',
    'बिक्रीकर',
    'बिक्रीकर्ता',
    'बिखर',
    'बिखरन',
    'बिखरना',
    'बिखरा',
    'बिखराई',
    'बिखराना',
    'बिखरापन',
    'बिखराव',
    'बिखरी',
    'बिखरे',
    'बिखेर',
    'बिखेरना',
    'बिग',
    'बिगड़',
    'बिगड़ता',
    'बिगड़ना',
    'बिगड़ने',
    'बिगड़नेवाला',
    'बिगड़ा',
    'बिगड़ापन',
    'बिगड़ी',
    'बिगड़ैल',
    'बिगडा',
    'बिगडैल',
    'बिगाड़',
    'बिगाड़ना',
    'बिगाड़ने',
    'बिगाडू',
    'बिगुल',
    'बिगुलची',
    'बिगुलर',
    'बिगूल',
    'बिगैमिस्ट',
    'बिच',
    'बिचकन',
    'बिचकना',
    'बिचमाँदा',
    'बिचमाँदापन',
    'बिचलन',
    'बिचलना',
    'बिचला',
    'बिचली',
    'बिचवई',
    'बिचवान',
    'बिचवानी',
    'बिचारा',
    'बिचुआ',
    'बिचौलिया',
    'बिचौलियापन',
    'बिचौलियों',
    'बिचौली',
    'बिच्छा',
    'बिच्छू',
    'बिछडाना',
    'बिछन',
    'बिछलन',
    'बिछलना',
    'बिछाई',
    'बिछाईर्',
    'बिछाए',
    'बिछाकर',
    'बिछात',
    'बिछाना',
    'बिछाने',
    'बिछानेवाला',
    'बिछापन',
    'बिछायत',
    'बिछावट',
    'बिछावन',
    'बिछिया',
    'बिछुआ',
    'बिछुए',
    'बिछुड़ना',
    'बिछुड़ा',
    'बिछुडी',
    'बिछुरंता',
    'बिछुरंती',
    'बिछोह',
    'बिछोहिनी',
    'बिछोही',
    'बिछौना',
    'बिछौनिया',
    'बिछौनी',
    'बिज',
    'बिजना',
    'बिजनेस',
    'बिजली',
    'बिजलीः',
    'बिजवासन',
    'बिज़नैस',
    'बिज़नैसमैन',
    'बिजायठ',
    'बिजार',
    'बिजुका',
    'बिजुरिया',
    'बिजुरी',
    'बिजुली',
    'बिजूखा',
    'बिजैला',
    'बिजैलापन',
    'बिजोग',
    'बिजौलिया',
    'बिज्जु',
    'बिज्जुपात',
    'बिज्जुल',
    'बिज्जू',
    'बिट',
    'बिटप',
    'बिटबना',
    'बिटारना',
    'बिटालना',
    'बिटिया',
    'बिटुमेनी',
    'बिट्टी',
    'बिट्ठल',
    'बिट्स',
    'बिठाई',
    'बिठाना',
    'बिठोबा',
    'बिड',
    'बिडरना',
    'बिड़ई',
    'बिडाल',
    'बित',
    'बितर',
    'बिता',
    'बिताते',
    'बिताना',
    'बिताने',
    'बितौटा',
    'बिथकना',
    'बिथरना',
    'बिथराना',
    'बिथारना',
    'बिथोरना',
    'बिदकना',
    'बिदकाना',
    'बिदरना',
    'बिदराना',
    'बिदा',
    'बिदायगी',
    'बिदुं',
    'बिदेस',
    'बिधा',
    'बिधानी',
    'बिन',
    'बिनती',
    'बिनन',
    'बिनबारी',
    'बिनबुलाया',
    'बिनस',
    'बिना',
    'बिनाई',
    'बिनु',
    'बिनौला',
    'बिन्दु',
    'बिन्दुओं',
    'बिन्दुकरण',
    'बिन्दुकित',
    'बिन्दुपथ',
    'बिपदा',
    'बिफरना',
    'बिब',
    'बिब्बो',
    'बिब्बोक',
    'बिमारी',
    'बिमोट',
    'बिमौरा',
    'बिम्ब',
    'बियर',
    'बियाई',
    'बियाड़',
    'बियाना',
    'बियाबान',
    'बियाबानी',
    'बियाह',
    'बिरंगा',
    'बिरंगापन',
    'बिरंगी',
    'बिरंज',
    'बिरंजी',
    'बिरंधि',
    'बिरता',
    'बिरथा',
    'बिरदंग',
    'बिरदैत',
    'बिरमना',
    'बिरमाना',
    'बिरला',
    'बिरलापन',
    'बिरले',
    'बिरवा',
    'बिरस',
    'बिरह',
    'बिरहन',
    'बिरहनी',
    'बिरहा',
    'बिरहिन',
    'बिरही',
    'बिराग',
    'बिरागना',
    'बिराजना',
    'बिरात',
    'बिरादर',
    'बिरादरी',
    'बिराना',
    'बिरिक्ष',
    'बिरिख',
    'बिरियाँ',
    'बिरियानी',
    'बिरु',
    'बिरोग',
    'बिरोज़ा',
    'बिरोजा',
    'बिरोधना',
    'बिल',
    'बिलंबना',
    'बिलकुल',
    'बिलख',
    'बिलखना',
    'बिलगना',
    'बिलगाना',
    'बिलगाय',
    'बिलटी',
    'बिलना',
    'बिलनी',
    'बिलपना',
    'बिलबिलाना',
    'बिलबिलाहट',
    'बिलमना',
    'बिलमाना',
    'बिललाना',
    'बिलल्ला',
    'बिला',
    'बिलाई',
    'बिलाना',
    'बिलाय',
    'बिलायल',
    'बिलाया',
    'बिलारी',
    'बिलाव',
    'बिलाशक',
    'बिलाशर्त',
    'बिलासना',
    'बिलियन',
    'बिलियर्ड',
    'बिलुब्ध',
    'बिलों',
    'बिलोड़ना',
    'बिलोना',
    'बिलोनी',
    'बिलोया',
    'बिलौटा',
    'बिलौर',
    'बिल्',
    'बिल्कुल',
    'बिल्टी',
    'बिल्डिंग',
    'बिल्य',
    'बिल्लयाँ',
    'बिल्ला',
    'बिल्ली',
    'बिल्लीः',
    'बिल्लौर',
    'बिल्व',
    'बिशप',
    'बिशपः',
    'बिशपतंत्रीय',
    'बिशपपद',
    'बिशपसंघवादी',
    'बिस',
    'बिसमय',
    'बिसमिल',
    'बिसयार',
    'बिसरा',
    'बिसराना',
    'बिसराम',
    'बिसात',
    'बिसातबाना',
    'बिसाता',
    'बिसाती',
    'बिसायैंध',
    'बिसारना',
    'बिसासी',
    'बिसाहना',
    'बिसाहनी',
    'बिसाहा',
    'बिसिनी',
    'बिसूरना',
    'बिसेखना',
    'बिसोक',
    'बिस्किट',
    'बिस्कुट',
    'बिस्तर',
    'बिस्तरनी',
    'बिस्तरबंद',
    'बिस्तरा',
    'बिस्तरी',
    'बिस्तरे',
    'बिस्तुइया',
    'बिस्वा',
    'बिस्वे',
    'बिहँसना',
    'बिहँसाना',
    'बिहरना',
    'बिहाग',
    'बिहान',
    'बिहार',
    'बिहारी',
    'बिहाल',
    'बिहिश्त',
    'बिहिश्ती',
    'बिहीदाना',
    'बिहू',
    'बिहोरना',
    'बिहोरा',
    'बींडी',
    'बींध',
    'बींधना',
    'बींधने',
    'बीअर',
    'बीए',
    'बीकन',
    'बीका',
    'बीकानेर',
    'बीकीपिंग',
    'बीघा',
    'बीच',
    'बीचः',
    'बीचना',
    'बीचम',
    'बीचि',
    'बीचो',
    'बीचों',
    'बीज',
    'बीजः',
    'बीजक',
    'बीजकीकरण',
    'बीजकीकृत',
    'बीजकों',
    'बीजकोष',
    'बीजगर्भित',
    'बीजतः',
    'बीजदार',
    'बीजन',
    'बीजना',
    'बीजपत्र',
    'बीजमंत्र',
    'बीजयुक्त',
    'बीजरुप',
    'बीजल',
    'बीजली',
    'बीजलेख',
    'बीजवाप',
    'बीजहीन',
    'बीजा',
    'बीजांक',
    'बीजांकूर',
    'बीजाकरण',
    'बीजाकृत',
    'बीजाक्षर',
    'बीजाणु',
    'बीजाणुधानी',
    'बीजाण्ग्क',
    'बीजाण्डासन',
    'बीजाधार',
    'बीजाध्यक्ष',
    'बीजारोपण',
    'बीजारोपन',
    'बीजारोपित',
    'बीजावरण',
    'बीजिया',
    'बीजी',
    'बीजु',
    'बीजुरी',
    'बीजों',
    'बीजोत्पति',
    'बीजोत्पादक',
    'बीजोद्भव',
    'बीझ',
    'बीझा',
    'बीट',
    'बीटिंग',
    'बीटोण',
    'बीड़',
    'बीड़ा',
    'बीड़ी',
    'बीत',
    'बीतते',
    'बीतना',
    'बीतने',
    'बीता',
    'बीतापन',
    'बीती',
    'बीते',
    'बीथी',
    'बीदाना',
    'बीधा',
    'बीधाः',
    'बीन',
    'बीनना',
    'बीनने',
    'बीना',
    'बीनाई',
    'बीपी',
    'बीबी',
    'बीभत्स',
    'बीमा',
    'बीमांकक',
    'बीमांकन',
    'बीमांकिक',
    'बीमाकर्ता',
    'बीमाकृत',
    'बीमाक्षति',
    'बीमाधन',
    'बीमाधारी',
    'बीमार',
    'बीमाराशि',
    'बीमारियां',
    'बीमारियों',
    'बीमारी',
    'बीमित',
    'बीमे',
    'बीमेदार',
    'बीयर',
    'बीयरिंग',
    'बीर',
    'बीरन',
    'बीरबानी',
    'बीरान',
    'बीरेन्द्र',
    'बीवर',
    'बीवी',
    'बीस',
    'बीसवाँ',
    'बीसवीं',
    'बीसा',
    'बीसी',
    'बीहड़',
    'बीहड़ता',
    'बुँदका',
    'बुँदकी',
    'बुँदिया',
    'बुँदौरी',
    'बुंगा',
    'बुंचा',
    'बुआ',
    'बुआइर',
    'बुआई',
    'बुक',
    'बुककेस',
    'बुकटा',
    'बुकट्टा',
    'बुकना',
    'बुकनी',
    'बुकनू',
    'बुकरम',
    'बुकलैट',
    'बुकवर्म',
    'बुकसैलर',
    'बुक़ची',
    'बुकिंग',
    'बुके',
    'बुक्का',
    'बुक्रचा',
    'बुखरिया',
    'बुखार',
    'बुखारा',
    'बुखारी',
    'बुग़चा',
    'बुग़ची',
    'बुग्गी',
    'बुग्याल',
    'बुजदिल',
    'बुजदिली',
    'बुज़ीर्',
    'बुजियाला',
    'बुजुगिर्न',
    'बुजुर्ग',
    'बुज्मा',
    'बुझ',
    'बुझक्कड',
    'बुझक्कड़',
    'बुझक्कड़ी',
    'बुझक्कडी',
    'बुझता',
    'बुझना',
    'बुझने',
    'बुझा',
    'बुझाई',
    'बुझाना',
    'बुझाने',
    'बुझापन',
    'बुझापुन',
    'बुझी',
    'बुझौवल',
    'बुडक',
    'बुडढी',
    'बुड़क',
    'बुड़कना',
    'बुड़का',
    'बुड़बुड़ाना',
    'बुड्ढा',
    'बुड्ढे',
    'बुढ़ाना',
    'बुढ़ापा',
    'बुढ़ापे',
    'बुढ़िया',
    'बुढिया',
    'बुढौती',
    'बुत',
    'बुतका',
    'बुतकार',
    'बुतखाना',
    'बुततराशी',
    'बुतना',
    'बुतपरस्त',
    'बुतपरस्ती',
    'बुतशिकन',
    'बुतशिकनी',
    'बुताना',
    'बुदका',
    'बुदबुद',
    'बुदबुदा',
    'बुदबुदाना',
    'बुदबुदाने',
    'बुदबुदाय',
    'बुदबुदाहट',
    'बुदबुदी',
    'बुदबुदेदार',
    'बुद्घ',
    'बुद्घत्व',
    'बुद्घागम',
    'बुद्घावशेष',
    'बुद्घू',
    'बुद्घूपन',
    'बुद्घूपना',
    'बुद्घ्बद',
    'बुद्धः',
    'बुद्धि',
    'बुद्धिः',
    'बुद्धिगम्य',
    'बुद्धिग्राह्य',
    'बुद्धिचक्षू',
    'बुद्धिजीवियों',
    'बुद्धिजीवी',
    'बुद्धिता',
    'बुद्धिपूर्वक',
    'बुद्धिभ्रम',
    'बुद्धिभ्रष्ट',
    'बुद्धिमंत',
    'बुद्धिमत्ता',
    'बुद्धिमान',
    'बुद्धिमानी',
    'बुद्धिमानीपूर्ण',
    'बुद्धियोग',
    'बुद्धिराजा',
    'बुद्धिलब्धि',
    'बुद्धिवादी',
    'बुद्धिवान',
    'बुद्धिशालिता',
    'बुद्धिहीन',
    'बुद्धिहीनता',
    'बुद्धीद्रिय',
    'बुद्धीय',
    'बुद्धू',
    'बुद्धूपन',
    'बुद्धूमल',
    'बुद्वू',
    'बुद्वूपन',
    'बुध',
    'बुधवार',
    'बुधान',
    'बुधित',
    'बुनकर',
    'बुनकरी',
    'बुनती',
    'बुननहारा',
    'बुनना',
    'बुनने',
    'बुना',
    'बुनाई',
    'बुनाईदार',
    'बुनापन',
    'बुनावट',
    'बुनावटः',
    'बुनावटदार',
    'बुनावटी',
    'बुनियाद',
    'बुनियादः',
    'बुनियादी',
    'बुबाई',
    'बुभुक्षा',
    'बुभुक्षित',
    'बुरक',
    'बुरकन',
    'बुरकना',
    'बुरक़ापोशी',
    'बुरका',
    'बुरकाई',
    'बुरकाना',
    'बुरकापोश',
    'बुरकाव',
    'बुरके',
    'बुरशा',
    'बुरा',
    'बुराई',
    'बुरादना',
    'बुरादा',
    'बुरापन',
    'बुरी',
    'बुरुश',
    'बुरे',
    'बुर्का',
    'बुर्ज',
    'बुर्जी',
    'बुर्द',
    'बुर्ददारी',
    'बुल',
    'बुलंडौज़र',
    'बुलंद',
    'बुलंदपरवाज़',
    'बुलंदी',
    'बुलडोजर',
    'बुलडोज़र',
    'बुलडौजर',
    'बुलन्द',
    'बुलबुल',
    'बुलबुला',
    'बुलबुलाव',
    'बुलबुलाहट',
    'बुलबुले',
    'बुलवाई',
    'बुलवाकर',
    'बुलवाना',
    'बुलवाया',
    'बुलवार्क',
    'बुलाई',
    'बुलाए',
    'बुलाना',
    'बुलानाः',
    'बुलाने',
    'बुलाया',
    'बुलायी',
    'बुलावनहार',
    'बुलावा',
    'बुलाहट',
    'बुलियन',
    'बुलेटिन',
    'बुल्ला',
    'बुल्स',
    'बुवाई',
    'बुश',
    'बुशल',
    'बुशिंग',
    'बुश्शर्ट',
    'बुसना',
    'बुसा',
    'बुस्त',
    'बुहार',
    'बुहारन',
    'बुहारना',
    'बुहारने',
    'बुहारा',
    'बुहारी',
    'बुहारु',
    'बूँद',
    'बूँदा',
    'बूँदाबाँदी',
    'बूँदी',
    'बूँदे',
    'बूंद',
    'बूंददार',
    'बूअरी',
    'बूआ',
    'बूइंग',
    'बूक',
    'बूकना',
    'बूका',
    'बूचड़',
    'बूचड़खाना',
    'बूचा',
    'बूज़खाना',
    'बूजाखाना',
    'बूझ',
    'बूझकर',
    'बूझते',
    'बूझना',
    'बूझाना',
    'बूझ्य',
    'बूट',
    'बूटलैगर',
    'बूटा',
    'बूटी',
    'बूटे',
    'बूटेदार',
    'बूट्स',
    'बूड़',
    'बूड़ना',
    'बूड़ा',
    'बूढ़',
    'बूढ़ा',
    'बूढ़ापन',
    'बूढ़ी',
    'बूढ़े',
    'बूढा',
    'बूढी',
    'बूता',
    'बूथ',
    'बूदम',
    'बूदार',
    'बूनामेनिशाँ',
    'बूबास',
    'बूम',
    'बूर',
    'बूरा',
    'बूराः',
    'बूस्टर',
    'बृज',
    'बृषभाकार',
    'बृषभीय',
    'बृहज्जन',
    'बृहत',
    'बृहतर',
    'बृहत्कथा',
    'बृहत्कर्ता',
    'बृहत्काय',
    'बृहत्तर',
    'बृहत्ता',
    'बृहत्पुष्प',
    'बृहत्फल',
    'बृहद',
    'बृहदश्य',
    'बृहदांत्र',
    'बृहदाकाश',
    'बृहदारण्यक',
    'बृहदीकरण',
    'बृहद्कायता',
    'बृहद्भानु',
    'बृहद्रथ',
    'बृहन्नट',
    'बृहन्नला',
    'बृहस्पत',
    'बृहस्पति',
    'बृहस्पतिवार',
    'बॅंटाना',
    'बे',
    'बेंअत',
    'बेंग',
    'बेंगधी',
    'बेंच',
    'बेंटा',
    'बेंड़ा',
    'बेंडीबंदी',
    'बेंत',
    'बेंतः',
    'बेंतबाज़ी',
    'बेंतसाज',
    'बेंदा',
    'बेंदी',
    'बेंल',
    'बेंेरंग',
    'बेअक्ल',
    'बेअक्ली',
    'बेअदब',
    'बेअदबी',
    'बेअसर',
    'बेअसूल',
    'बेअसूली',
    'बेआब',
    'बेआबरु',
    'बेआबरुई',
    'बेआबी',
    'बेआवज़',
    'बेआहट',
    'बेइज्जत',
    'बेइन्दराजी',
    'बेइन्साफी',
    'बेइमानी',
    'बेइल्म',
    'बेईमान',
    'बेईमानी',
    'बेईमानीपूर्वक',
    'बेईसाफ़',
    'बेईसाफ़ी',
    'बेएतबार',
    'बेएतबारी',
    'बेऐब',
    'बेऔज़ार',
    'बेऔलाद',
    'बेक',
    'बेकदरा',
    'बेकबू',
    'बेकब्ज़ा',
    'बेकर',
    'बेकरी',
    'बेकल',
    'बेकलाइट',
    'बेकली',
    'बेकस',
    'बेकसी',
    'बेकसूर',
    'बेकहा',
    'बेक़दरी',
    'बेक़द्री',
    'बेक़रार',
    'बेक़रारी',
    'बेक़सरी',
    'बेक़ायदगी',
    'बेक़ायदा',
    'बेकाम',
    'बेकार',
    'बेकारी',
    'बेकिंग',
    'बेखटक',
    'बेखटके',
    'बेखड़के',
    'बेखबर',
    'बेखर्चा',
    'बेख़तर',
    'बेख़ता',
    'बेख़बर',
    'बेख़बरी',
    'बेख़याली',
    'बेख़ौफ',
    'बेखुद',
    'बेखुदी',
    'बेग',
    'बेगड़ी',
    'बेगम',
    'बेग़म',
    'बेग़र्ज',
    'बेग़र्जी',
    'बेगानगी',
    'बेगाना',
    'बेगानापन',
    'बेगार',
    'बेगारी',
    'बेगिने',
    'बेगुनाह',
    'बेगुनाही',
    'बेगुमान',
    'बेगै',
    'बेघर',
    'बेघरबार',
    'बेघरबारी',
    'बेघरा',
    'बेघरी',
    'बेच',
    'बेचक',
    'बेचकर',
    'बेचन',
    'बेचनहार',
    'बेचना',
    'बेचनीय',
    'बेचनीयता',
    'बेचने',
    'बेचनेवाला',
    'बेचा',
    'बेचानकर्ता',
    'बेचाप',
    'बेचार',
    'बेचारगी',
    'बेचारा',
    'बेचिराग़',
    'बेची',
    'बेचू',
    'बेचूक',
    'बेचे',
    'बेचें',
    'बेचैन',
    'बेचैनी',
    'बेजमानती',
    'बेजवाबदार',
    'बेज़बान',
    'बेज़बानी',
    'बेज़ाब्ता',
    'बेज़ार',
    'बेजा',
    'बेजान',
    'बेजाना',
    'बेजुड़ा',
    'बेजुड़ापन',
    'बेजोड़',
    'बेजोड़पन',
    'बेझ',
    'बेझना',
    'बेझा',
    'बेटक्कर',
    'बेटन',
    'बेटवा',
    'बेटा',
    'बेटियाँ',
    'बेटियों',
    'बेटी',
    'बेटे',
    'बेटों',
    'बेठन',
    'बेठिकाना',
    'बेठिकाने',
    'बेड',
    'बेडवीं',
    'बेड़',
    'बेड़वीं',
    'बेड़ा',
    'बेड़िन',
    'बेड़िनी',
    'बेड़ियां',
    'बेड़ी',
    'बेड़ीं',
    'बेड़ीबंद',
    'बेडा',
    'बेडीबद्घ',
    'बेडौल',
    'बेडौलपन',
    'बेढंगा',
    'बेढंगापन',
    'बेढंगे',
    'बेढब',
    'बेढ़',
    'बेढ़ई',
    'बेढ़ा',
    'बेतमीज़ी',
    'बेतरतीब',
    'बेतरतीबी',
    'बेतरह',
    'बेतरीका',
    'बेतरीके',
    'बेतहज़ीबी',
    'बेताब',
    'बेताबी',
    'बेतार',
    'बेताल',
    'बेताला',
    'बेतालापन',
    'बेताली',
    'बेतालीय',
    'बेतासीर',
    'बेतुक',
    'बेतुका',
    'बेतुकापन',
    'बेतुकी',
    'बेदखल',
    'बेदखली',
    'बेदख़ल',
    'बेदख़ली',
    'बेदम',
    'बेदमी',
    'बेदमुश्क',
    'बेदरोदीवार',
    'बेदरोदीवारी',
    'बेदर्द',
    'बेदर्दी',
    'बेदाग',
    'बेदाग़',
    'बेदाना',
    'बेदाम',
    'बेदामी',
    'बेदार',
    'बेदारी',
    'बेदिल',
    'बेदिली',
    'बेदी',
    'बेदीद',
    'बेदीन',
    'बेदीवार',
    'बेध',
    'बेधक',
    'बेधड़क',
    'बेधन',
    'बेधना',
    'बेधाक',
    'बेधिया',
    'बेध्यान',
    'बेध्यानी',
    'बेन',
    'बेनका',
    'बेनकाबी',
    'बेनट',
    'बेनसीब',
    'बेनसीबी',
    'बेना',
    'बेनाग़ा',
    'बेनाम',
    'बेनामी',
    'बेनियाजी',
    'बेनी',
    'बेनु',
    'बेनुर',
    'बेपढ़ा',
    'बेपनाह',
    'बेपर',
    'बेपरदा',
    'बेपरवा',
    'बेपरवाह',
    'बेपरवाही',
    'बेपर्दगी',
    'बेपर्दा',
    'बेपीर',
    'बेफ़सल',
    'बेफ़ायदा',
    'बेफिक्र',
    'बेफिक्री',
    'बेबरदाश्ती',
    'बेबस',
    'बेबसी',
    'बेबाक',
    'बेबाक़',
    'बेबाकी',
    'बेबी',
    'बेबुनियाद',
    'बेभाव',
    'बेमज़गी',
    'बेमज़ा',
    'बेमतलब',
    'बेमन',
    'बेमरम्मत',
    'बेमरम्मती',
    'बेमरौवती',
    'बेमहल',
    'बेमानी',
    'बेमालूम',
    'बेमियाद',
    'बेमियादी',
    'बेमियायट',
    'बेमिसाल',
    'बेमुरव्वती',
    'बेमुरौवत',
    'बेमुश्ती',
    'बेमेल',
    'बेमौक़ा',
    'बेमौके',
    'बेमौसम',
    'बेमौसमी',
    'बेयक़त',
    'बेयरिंग',
    'बेयोनट',
    'बेर',
    'बेरंग',
    'बेरजा',
    'बेरस',
    'बेरहम',
    'बेरा',
    'बेराय',
    'बेराहा',
    'बेरिंग',
    'बेरी',
    'बेरुख',
    'बेरुखी',
    'बेरेश',
    'बेरोक',
    'बेरोकटोक',
    'बेरोजगार',
    'बेरोजगारी',
    'बेरोज़गार',
    'बेरोज़गारी',
    'बेरोब',
    'बेरोज़गार',
    'बेरोज़गारी',
    'बेरौनक़',
    'बेरौनकी',
    'बेल',
    'बेलगाम',
    'बेलगामी',
    'बेलचा',
    'बेलची',
    'बेलदार',
    'बेलन',
    'बेलना',
    'बेलनाकार',
    'बेलनी',
    'बेलबूटा',
    'बेलबूटाः',
    'बेलबूटे',
    'बेलबूटेदार',
    'बेलरी',
    'बेलवा',
    'बेला',
    'बेलांचल',
    'बेलांचली',
    'बेलाई',
    'बेलातट',
    'बेलायल',
    'बेलिफ',
    'बेलिहाजपन',
    'बेलिहाज़',
    'बेलिहाज़',
    'बेली',
    'बेलुत्फ',
    'बेलुत्फी',
    'बेलोच',
    'बेलौस',
    'बेल्ट',
    'बेवकूफ',
    'बेवकूफी',
    'बेवकूफ़',
    'बेवक्त',
    'बेवक्रत',
    'बेवगी',
    'बेवजह',
    'बेवड़ा',
    'बेवफा',
    'बेवफादारी',
    'बेवल',
    'बेवहरिया',
    'बेवहार',
    'बेवा',
    'बेवापन',
    'बेवास्ता',
    'बेवफ़ाई',
    'बेश',
    'बेशऊर',
    'बेशऊरी',
    'बेशक',
    'बेशकीमती',
    'बेशरम',
    'बेशर्त',
    'बेशर्म',
    'बेशर्मी',
    'बेशिनाख्त',
    'बेशी',
    'बेशुबह',
    'बेशुमार',
    'बेशुमारी',
    'बेशौक्रीनी',
    'बेस',
    'बेसन',
    'बेसबब',
    'बेसबरापन',
    'बेसबाल',
    'बेसब्र',
    'बेसमझ',
    'बेसमैंट',
    'बेसर',
    'बेसरो',
    'बेसलीका',
    'बेसवा',
    'बेसवाई',
    'बेसहारा',
    'बेसाखी',
    'बेसाख्ता',
    'बेसाजो',
    'बेसारा',
    'बेसिंगा',
    'बेसिन',
    'बेसिरपैर',
    'बेसिलसिला',
    'बेसिलसिले',
    'बेसुध',
    'बेसुधी',
    'बेसुर',
    'बेसुरा',
    'बेसुरापन',
    'बेसूद',
    'बेसोचे',
    'बेस्वाद',
    'बेस्वादन',
    'बेहंगम',
    'बेहड़',
    'बेहड़ा',
    'बेहतर',
    'बेहतरता',
    'बेहतरी',
    'बेहतरीन',
    'बेहथियार',
    'बेहद',
    'बेहदी',
    'बेहन',
    'बेहना',
    'बेहनिन',
    'बेहया',
    'बेहयाई',
    'बेहरमी',
    'बेहला',
    'बेहलाइट',
    'बेहवास',
    'बेहवासी',
    'बेहाथ',
    'बेहार',
    'बेहाल',
    'बेहिचक',
    'बेहिजाब',
    'बेहिजाबी',
    'बेहिम्मती',
    'बेहिसाब',
    'बेहिसाबी',
    'बेहुनर',
    'बेहूदगी',
    'बेहूदा',
    'बेहूदापन',
    'बेहैसियत',
    'बेहोश',
    'बेहोशी',
    'बेज़ा',
    'बैंक',
    'बैंकग्राउंड',
    'बैंकर',
    'बैंकिंग',
    'बैंकैअन',
    'बैंगन',
    'बैंगनी',
    'बैंगलो',
    'बैंच',
    'बैंचा',
    'बैंजो',
    'बैंड',
    'बैंड़ा',
    'बैक',
    'बैकग्राउंड',
    'बैकनेल्या',
    'बैकर',
    'बैकवाटर्स',
    'बैकिंग',
    'बैकिट',
    'बैकुंठ',
    'बैकू',
    'बैकूयुक्त',
    'बैकेट',
    'बैक्टीरिया',
    'बैग',
    'बैगन',
    'बैगनी',
    'बैगपाइप',
    'बैगेज',
    'बैच',
    'बैज',
    'बैजनाथ',
    'बैजयंती',
    'बैज़यी',
    'बैज़ा',
    'बैजा',
    'बैजो',
    'बैट',
    'बैटरी',
    'बैटरीमैन',
    'बैटरीवाला',
    'बैटलशिप',
    'बैटा',
    'बैटी',
    'बैठ',
    'बैठक',
    'बैठकखाना',
    'बैठकर',
    'बैठका',
    'बैठकी',
    'बैठकें',
    'बैठकों',
    'बैठता',
    'बैठन',
    'बैठना',
    'बैठनाः',
    'बैठनि',
    'बैठनी',
    'बैठने',
    'बैठा',
    'बैठाई',
    'बैठान',
    'बैठाना',
    'बैठाने',
    'बैठापन',
    'बैठाया',
    'बैठालना',
    'बैठी',
    'बैठे',
    'बैड',
    'बैडमिंटन',
    'बैडरुम',
    'बैडशीट',
    'बैडेज',
    'बैत',
    'बैताल',
    'बैतालिक',
    'बैद',
    'बैदल',
    'बैदा',
    'बैन',
    'बैनर',
    'बैना',
    'बैनामा',
    'बैनिफिट',
    'बैन्ड',
    'बैपटिस्ट्री',
    'बैर',
    'बैरं',
    'बैरंग',
    'बैरक',
    'बैरन',
    'बैरभावी',
    'बैरल',
    'बैरलाकार',
    'बैरा',
    'बैराग',
    'बैरागिन',
    'बैरागी',
    'बैरियर',
    'बैरिस्टर',
    'बैरी',
    'बैरो',
    'बैरोमीटर',
    'बैल',
    'बैलः',
    'बैलगाड़ियों',
    'बैलगाड़ी',
    'बैलट',
    'बैलास्ट',
    'बैली',
    'बैलून',
    'बैलूनिस्ट',
    'बैले',
    'बैलेंस',
    'बैलेरिना',
    'बैल्ट',
    'बैशनों',
    'बैशाख',
    'बैसर',
    'बैसाख',
    'बैसाखी',
    'बॉंटना',
    'बॉक्स',
    'बॉडी',
    'बॉर्डर',
    'बॉल',
    'बोंक',
    'बोंडा',
    'बोंडी',
    'बोअनी',
    'बोइया',
    'बोई',
    'बोएयलरमैन',
    'बोकरा',
    'बोक्काण',
    'बोखलाया',
    'बोगी',
    'बोछार',
    'बोजना',
    'बोझ',
    'बोझः',
    'बोझल',
    'बोझा',
    'बोझिल',
    'बोझीलापन',
    'बोट',
    'बोटा',
    'बोटिंग',
    'बोटी',
    'बोड',
    'बोड़ा',
    'बोतल',
    'बोतलबंद',
    'बोतली',
    'बोतलों',
    'बोता',
    'बोदा',
    'बोध',
    'बोधः',
    'बोधक',
    'बोधकता',
    'बोधगम्य',
    'बोधगम्यता',
    'बोधज्ञ',
    'बोधन',
    'बोधपूर्ण',
    'बोधप्रद',
    'बोधयिता',
    'बोधहीनता',
    'बोधातीत',
    'बोधान',
    'बोधि',
    'बोधित',
    'बोधिनी',
    'बोधिवृक्ष',
    'बोधिसत्त्व',
    'बोधी',
    'बोधोदय',
    'बोध्य',
    'बोध्यता',
    'बोन',
    'बोनस',
    'बोना',
    'बोनी',
    'बोम',
    'बोया',
    'बोर',
    'बोरका',
    'बोरना',
    'बोरसी',
    'बोरा',
    'बोरिंग',
    'बोरियत',
    'बोरिया',
    'बोरी',
    'बोर्ड',
    'बोर्डवाक',
    'बोर्डिंग',
    'बोल',
    'बोलचाल',
    'बोलचालः',
    'बोलता',
    'बोलतान',
    'बोलती',
    'बोलते',
    'बोलना',
    'बोलने',
    'बोलपट',
    'बोला',
    'बोलाड्र',
    'बोलिये',
    'बोली',
    'बोले',
    'बोल्ट',
    'बोल्ड',
    'बोस',
    'बोसित्व',
    'बोस्पिरट',
    'बोह',
    'बोहथ',
    'बोहनी',
    'बोहित्थ',
    'बौइलिंग',
    'बौक्स',
    'बौक्सर',
    'बौखलाना',
    'बौखलाहट',
    'बौखा',
    'बौछार',
    'बौछारी',
    'बौटल',
    'बौटल्ड',
    'बौडकास्टिंग',
    'बौड़',
    'बौड़म',
    'बौड़ी',
    'बौडिस',
    'बौडी',
    'बौडीगार्ड',
    'बौद्घ',
    'बौद्ध',
    'बौद्धधर्मी',
    'बौद्धविहार',
    'बौद्धिक',
    'बौधायन',
    'बौना',
    'बौनापन',
    'बौयाकाट',
    'बौर',
    'बौरई',
    'बौरहा',
    'बौरा',
    'बौराइ',
    'बौराई',
    'बौराना',
    'बौराया',
    'बौरायापन',
    'बौर्डर',
    'बौल',
    'बौशर',
    'बौश्यर',
    'बौस',
    'ब्याज',
    'ब्याजखोरी',
    'ब्याजमुक्त',
    'ब्याजवाला',
    'ब्याजवाली',
    'ब्याजवाले',
    'ब्याजसहित',
    'ब्याजहीन',
    'ब्याजहीनता',
    'ब्याजी',
    'ब्याजू',
    'ब्याना',
    'ब्यापना',
    'ब्याल',
    'ब्यालू',
    'ब्यास',
    'ब्याह',
    'ब्याहता',
    'ब्याहन',
    'ब्याहना',
    'ब्याहली',
    'ब्याहा',
    'ब्याही',
    'ब्यूटी',
    'ब्यूटीशियन',
    'ब्यूरो',
    'ब्यूरों',
    'ब्यूरोकैट',
    'ब्योंच',
    'ब्योंत',
    'ब्योपार',
    'ब्योपारी',
    'ब्योरा',
    'ब्योरे',
    'ब्योरेदार',
    'ब्योरेवार',
    'ब्योहार',
    'ब्यौरा',
    'ब्यौरे',
    'ब्यौरेवार',
    'ब्यौरों',
    'ब्रंच',
    'ब्रज',
    'ब्रजन',
    'ब्रजनाथ',
    'ब्रजराज',
    'ब्रजवासी',
    'ब्रजेप्र',
    'ब्रजेश',
    'ब्रजेश्वर',
    'ब्ररह्मर्षि',
    'ब्ररेन',
    'ब्रश',
    'ब्रह्म',
    'ब्रह्मः',
    'ब्रह्मकला',
    'ब्रह्मकल्प',
    'ब्रह्मकोश',
    'ब्रह्मगति',
    'ब्रह्मगोल',
    'ब्रह्मचर्य',
    'ब्रह्मचर्या',
    'ब्रह्मचर्यात',
    'ब्रह्मचारिणी',
    'ब्रह्मचारी',
    'ब्रह्मज',
    'ब्रह्मजार',
    'ब्रह्मजीवी',
    'ब्रह्मज्ञ',
    'ब्रह्मज्ञान',
    'ब्रह्मज्ञानी',
    'ब्रह्मणी',
    'ब्रह्मणीय',
    'ब्रह्मण्य',
    'ब्रह्मतेज',
    'ब्रह्मतेजयुक्त',
    'ब्रह्मतेजस्वी',
    'ब्रह्मदंड',
    'ब्रह्मद्रव',
    'ब्रह्मद्रुम',
    'ब्रह्मद्वार',
    'ब्रह्मनदी',
    'ब्रह्मपाश',
    'ब्रह्मपुत्र',
    'ब्रह्मपुत्रा',
    'ब्रह्मपुत्री',
    'ब्रह्मपुर',
    'ब्रह्मभूत',
    'ब्रह्मभोज',
    'ब्रह्मयज्ञ',
    'ब्रह्मरंधर',
    'ब्रह्मरात्र',
    'ब्रह्मराशि',
    'ब्रह्मलिखित',
    'ब्रह्मलीन',
    'ब्रह्मलीनता',
    'ब्रह्मलेखा',
    'ब्रह्मवर्चस',
    'ब्रह्मवर्चसी',
    'ब्रह्मवर्चस्यी',
    'ब्रह्मवर्धन',
    'ब्रह्मवाद',
    'ब्रह्मवादिनी',
    'ब्रह्मविद्या',
    'ब्रह्मवेद',
    'ब्रह्मशल्य',
    'ब्रह्मशाप',
    'ब्रह्मशिरा',
    'ब्रह्मसुता',
    'ब्रह्मसू',
    'ब्रह्मा',
    'ब्रह्मांड',
    'ब्रह्मांडिकी',
    'ब्रह्मांडीय',
    'ब्रह्मांभ',
    'ब्रह्माः',
    'ब्रह्माक्षर',
    'ब्रह्माख्या',
    'ब्रह्माचरण',
    'ब्रह्माणी',
    'ब्रह्मानंद',
    'ब्रह्मापुत्र',
    'ब्रह्मार्पण',
    'ब्रह्मावर्त',
    'ब्रह्मासन',
    'ब्रह्मास्मि',
    'ब्रह्मािग्न',
    'ब्रह्मी',
    'ब्रह्मीय',
    'ब्रह्मेशय',
    'ब्रह्य',
    'ब्रह्यविद्या',
    'ब्रा',
    'ब्रांच',
    'ब्रांड',
    'ब्रांडी',
    'ब्राउन',
    'ब्राजकता',
    'ब्राण्ड',
    'ब्राण्डी',
    'ब्रास',
    'ब्राह्म',
    'ब्राह्मण',
    'ब्राह्मणवाद',
    'ब्राह्मणी',
    'ब्राह्मण्य',
    'ब्राह्माणी',
    'ब्राह्मी',
    'ब्राह्यता',
    'ब्रिगड',
    'ब्रिगेड',
    'ब्रिगेडियर',
    'ब्रिग्रेडियर',
    'ब्रिज',
    'ब्रिज़',
    'ब्रिटिश',
    'ब्रिटेन',
    'ब्रिटेनवासी',
    'ब्रीड',
    'ब्रीडिंग',
    'ब्रीफ',
    'ब्रीफकेस',
    'ब्रीफ़केस',
    'ब्रेक',
    'ब्रेकडाउन',
    'ब्रेकप',
    'ब्रेकफ़ास्ट',
    'ब्रेकमैन',
    'ब्रेकर',
    'ब्रेकवैन',
    'ब्रेड',
    'ब्रेनवाश',
    'ब्रेल',
    'ब्रेस्ट',
    'ब्रोकर',
    'ब्रोकरिंग',
    'ब्रोकरेज',
    'ब्लड',
    'ब्लफ़',
    'ब्लाइंग',
    'ब्लाइंड',
    'ब्लाउज',
    'ब्लाउज़',
    'ब्लाउज़',
    'ब्लाक',
    'ब्लाकों',
    'ब्लामा',
    'ब्लास्ट',
    'ब्लीच्ड',
    'ब्लीडिंग',
    'ब्लू',
    'ब्लेयर',
    'ब्लैक',
    'ब्लैकमेल',
    'ब्लैकमेलर',
    'ब्लोएक',
    'ब्लौक',
    'ब्लौटर',
    'ब्लौटिंग',
    'ब्वाय',
    'बक़ाया',
    'बग़ल',
    'बग़ली',
    'बज़ट',
    'भँगरा',
    'भँगरैला',
    'भँगार',
    'भँगेरा',
    'भँजाई',
    'भँड़ेरी',
    'भँड़ैती',
    'भँडेरिया',
    'भँडौआ',
    'भँवर',
    'भँवरा',
    'भँवरी',
    'भँेगेड़ी',
    'भंकार',
    'भंग',
    'भंगापन',
    'भंगि',
    'भंगिमा',
    'भंगी',
    'भंगुर',
    'भंगुरता',
    'भंजक',
    'भंजकता',
    'भंजन',
    'भंजनक',
    'भंजनशील',
    'भंजना',
    'भंजा',
    'भंजित',
    'भंजिता',
    'भंटवार्ता',
    'भंटा',
    'भंड',
    'भंडना',
    'भंडर',
    'भंडरिया',
    'भंड़ैती',
    'भंडाफोड',
    'भंडाफोडना',
    'भंडाफोड़',
    'भंडाफोडू',
    'भंडार',
    'भंडारक',
    'भंडारकर्ता',
    'भंडारकर्मी',
    'भंडारगृह',
    'भंडारण',
    'भंडारा',
    'भंडारित्र',
    'भंडारी',
    'भंडीर',
    'भंते',
    'भंद',
    'भंभरना',
    'भंभा',
    'भंभीरी',
    'भंभ्कड़ा',
    'भंरश',
    'भंवर',
    'भंश',
    'भइया',
    'भई',
    'भकभक',
    'भकभककरना',
    'भकभककाना',
    'भकभककाहट',
    'भकाऊँ',
    'भकाभक',
    'भकुआ',
    'भकोस',
    'भकोसना',
    'भकोसा',
    'भकोसू',
    'भक्त',
    'भक्तजन',
    'भक्ता',
    'भक्ति',
    'भक्तिःनौ',
    'भक्तिगम्य',
    'भक्तिच्छेद',
    'भक्तिपूर्ण',
    'भक्तिभाव',
    'भक्तिमय',
    'भक्तिलीन',
    'भक्षक',
    'भक्षकाणु',
    'भक्षकार',
    'भक्षण',
    'भक्षयिता',
    'भक्षित',
    'भक्षी',
    'भक्ष्य',
    'भक्ष्यम',
    'भखना',
    'भग',
    'भगण',
    'भगत',
    'भगतिए',
    'भगती',
    'भगदड़',
    'भगदेय',
    'भगना',
    'भगया',
    'भगली',
    'भगवंत',
    'भगवत',
    'भगवती',
    'भगवत्पदी',
    'भगवद',
    'भगवदीय',
    'भगवद्',
    'भगवन्मूर्ति',
    'भगवान',
    'भगवानः',
    'भगशेफ',
    'भगहा',
    'भगा',
    'भगाई',
    'भगाना',
    'भगाने',
    'भगाया',
    'भगाली',
    'भगिनी',
    'भगिनीत्व',
    'भगिर्त',
    'भगी',
    'भगेडू',
    'भगेलू',
    'भगोड़ा',
    'भगोड़े',
    'भगोल',
    'भगोलीय',
    'भगौना',
    'भग्गू',
    'भग्न',
    'भग्नचित',
    'भग्नता',
    'भग्नदंत',
    'भग्नदर्प',
    'भग्ननिद्र',
    'भग्नप्रतिज्ञ',
    'भग्नमना',
    'भग्नमनोरथ',
    'भग्नमान',
    'भग्नव्रत',
    'भग्नहृदय',
    'भग्नांग',
    'भग्नांगता',
    'भग्नांवशेष',
    'भग्नांश',
    'भग्नावशेष',
    'भग्नाश',
    'भग्नाशा',
    'भग्नासक्त',
    'भग्नासिक्त',
    'भग्नाहंकार',
    'भग्नैकता',
    'भग्यश्री',
    'भचक',
    'भचकना',
    'भचक्र',
    'भजंग',
    'भजंगिनी',
    'भजन',
    'भजनकार',
    'भजनगान',
    'भजना',
    'भजनावली',
    'भजनिया',
    'भजनी',
    'भजनीक',
    'भजित',
    'भजिया',
    'भजिर्त',
    'भज्जा',
    'भज्य',
    'भट',
    'भटई',
    'भटक',
    'भटकटैया',
    'भटकती',
    'भटकते',
    'भटकन',
    'भटकना',
    'भटकने',
    'भटका',
    'भटकाऊ',
    'भटकाना',
    'भटकाने',
    'भटकाव',
    'भटकैया',
    'भटकौहौ',
    'भटठी',
    'भटनागर',
    'भटभेरा',
    'भटिंडा',
    'भटियाली',
    'भट्ट',
    'भट्टता',
    'भट्टाचार्य',
    'भट्टार',
    'भट्टारक',
    'भट्टारिका',
    'भट्टिनी',
    'भट्टीवाला',
    'भट्ठा',
    'भट्ठी',
    'भट्ठे',
    'भठियार',
    'भठियारपन',
    'भठियारा',
    'भठियाल',
    'भठिहारा',
    'भडकाऊ',
    'भडकू',
    'भडकैल',
    'भडया',
    'भडसाई',
    'भड़',
    'भड़क',
    'भड़कदार',
    'भड़कना',
    'भड़का',
    'भड़काना',
    'भड़काने',
    'भड़काव',
    'भड़कावा',
    'भड़कीला',
    'भड़कीलापन',
    'भड़कीले',
    'भड़कीलेपन',
    'भड़कैल',
    'भड़क्का',
    'भड़भड़',
    'भड़भड़ाना',
    'भड़भड़ाहट',
    'भड़भड़िया',
    'भड़भड़ी',
    'भड़भूँजा',
    'भड़भूँजाः',
    'भड़ाका',
    'भड़ास',
    'भड़िहा',
    'भड़ेरिया',
    'भड़ैती',
    'भडारित',
    'भडिहाई',
    'भडुआ',
    'भडैत',
    'भण',
    'भणन',
    'भणना',
    'भणित',
    'भणिता',
    'भण्डाफोड़',
    'भण्डार',
    'भतमभत्ता',
    'भतयान',
    'भतीजा',
    'भतीजावाद',
    'भतीजी',
    'भतीजे',
    'भत्ता',
    'भत्तादाता',
    'भत्ताबिल',
    'भत्ते',
    'भत्र्सना',
    'भद',
    'भदंत',
    'भदभदाती',
    'भदभदाते',
    'भदमल्ला',
    'भदमल्लापन',
    'भदेस',
    'भदेसपन',
    'भद्द',
    'भद्दा',
    'भद्दापन',
    'भद्दी',
    'भद्दीचाल',
    'भद्दे',
    'भद्देपन',
    'भद्र',
    'भद्रंकर',
    'भद्रक',
    'भद्रकाली',
    'भद्रकाव',
    'भद्रकाष्ठ',
    'भद्रता',
    'भद्रतापूर्ण',
    'भद्रताली',
    'भद्रदारु',
    'भद्रमन्यक',
    'भद्रमन्यता',
    'भद्ररेणु',
    'भद्रश्री',
    'भद्रा',
    'भद्राग',
    'भद्रावह',
    'भद्रासन',
    'भद्रिका',
    'भद्री',
    'भद्रेश',
    'भनक',
    'भनभन',
    'भनभनाहट',
    'भनेली',
    'भनौली',
    'भबका',
    'भबकी',
    'भभक',
    'भभकना',
    'भभका',
    'भभकी',
    'भभाग',
    'भभूका',
    'भभूखा',
    'भभूत',
    'भभूतिया',
    'भभूती',
    'भभूदर',
    'भमंडल',
    'भमि',
    'भमीरी',
    'भय',
    'भयंकर',
    'भयंकरता',
    'भयंरता',
    'भयग्रस्त',
    'भयत्रस्त',
    'भयत्राण',
    'भयत्राता',
    'भयदोग्धा',
    'भयदोहन',
    'भयद्रत',
    'भयपूर्ण',
    'भयपूर्वक',
    'भयप्रद',
    'भयप्रदता',
    'भयभंजन',
    'भयभीत',
    'भयभीतता',
    'भयभ्रष्ट',
    'भयमुक्त',
    'भयमुथ्कत',
    'भयरहित',
    'भयशील',
    'भयशीलता',
    'भयहरणभय',
    'भयहीन',
    'भयहीनतः',
    'भयहीनता',
    'भयाकुल',
    'भयाकुलता',
    'भयाक्रांत',
    'भयातंक',
    'भयातुर',
    'भयादोहन',
    'भयानक',
    'भयानकता',
    'भयानकतावश',
    'भयानुभूति',
    'भयार्त',
    'भयावना',
    'भयावनी',
    'भयावह',
    'भयावहता',
    'भयावहपूर्ण',
    'भयाविष्टता',
    'भयािन्वत',
    'भयीभीतता',
    'भयोत्पादक',
    'भयोपरत',
    'भय्या',
    'भर',
    'भरक',
    'भरकम',
    'भरकर',
    'भरण',
    'भरणी',
    'भरण्य',
    'भरण्यु',
    'भरत',
    'भरतः',
    'भरतपुर',
    'भरता',
    'भरताप्रज',
    'भरतार',
    'भरतिया',
    'भरती',
    'भरते',
    'भरथ',
    'भरदूल',
    'भरद्वाज',
    'भरन',
    'भरना',
    'भरनी',
    'भरने',
    'भरनेवाला',
    'भरपाई',
    'भरपूर',
    'भरपेट',
    'भरभक्षिता',
    'भरभराना',
    'भरभेंटा',
    'भरम',
    'भरमना',
    'भरमाना',
    'भरमाया',
    'भरमार',
    'भरवाँ',
    'भरवाना',
    'भरसक',
    'भरा',
    'भराई',
    'भराईः',
    'भराऊ',
    'भरापन',
    'भरापूरापन',
    'भराय',
    'भराव',
    'भरावट',
    'भरिमा',
    'भरिया',
    'भरी',
    'भरुआ',
    'भरे',
    'भरेठ',
    'भरैया',
    'भरोसमंद',
    'भरोसमंदी',
    'भरोसा',
    'भरोसी',
    'भरोसे',
    'भरौनी',
    'भर्ग',
    'भर्जक',
    'भर्जन',
    'भर्ता',
    'भर्तार',
    'भर्ती',
    'भर्त्सना',
    'भर्त्सनात्मक',
    'भर्या',
    'भर्राया',
    'भर्राहट',
    'भर्लाई',
    'भल',
    'भलका',
    'भलमनसाहत',
    'भलमनसी',
    'भला',
    'भलाई',
    'भलाईर्',
    'भलापन',
    'भली',
    'भले',
    'भल्लनाथ',
    'भल्ला',
    'भल्लाट',
    'भल्लूक',
    'भव',
    'भवंत',
    'भवंती',
    'भवत',
    'भवती',
    'भवत्मज',
    'भवदीय',
    'भवदीया',
    'भवदेह',
    'भवन',
    'भवननाशिनी',
    'भवनाश',
    'भवनाशी',
    'भवनी',
    'भवनीय',
    'भवनों',
    'भवर्ग',
    'भवांतर',
    'भवाचल',
    'भवान',
    'भवानी',
    'भवायनी',
    'भवितव्य',
    'भवितव्यता',
    'भविता',
    'भविल',
    'भविष्णु',
    'भविष्णुता',
    'भविष्य',
    'भविष्यकार',
    'भविष्यत',
    'भविष्यता',
    'भविष्यद',
    'भविष्यद्',
    'भविष्यनिधि',
    'भविष्यरोदन',
    'भविष्यवक्ता',
    'भविष्यवर्ती',
    'भविष्यवाणी',
    'भविष्यवाद',
    'भविष्यवादी',
    'भविष्यसूचक',
    'भविष्यहीन',
    'भवीला',
    'भवुनांड',
    'भवेश',
    'भवैलिया',
    'भव्य',
    'भव्यता',
    'भव्यदृश्यतापूर्ण',
    'भसकना',
    'भसका',
    'भसम',
    'भसान',
    'भसाना',
    'भसित',
    'भसींड',
    'भसुंड',
    'भसुर',
    'भस्म',
    'भस्मक',
    'भस्मकरण',
    'भस्मगंधा',
    'भस्मगात्र',
    'भस्मधारी',
    'भस्मवत',
    'भस्मशायी',
    'भस्मसात',
    'भस्मावेशेष',
    'भस्मिरप्रय',
    'भस्मी',
    'भस्मीभूत',
    'भस्सड़',
    'भा',
    'भाँई',
    'भाँग',
    'भाँगः',
    'भाँगचर्या',
    'भाँज',
    'भाँजना',
    'भाँजी',
    'भाँड',
    'भाँडगीरी',
    'भाँडना',
    'भाँडपन',
    'भाँड़',
    'भाँड़गीरी',
    'भाँडा',
    'भाँडे',
    'भाँड्यो',
    'भाँति',
    'भाँप',
    'भाँपना',
    'भाँय',
    'भाँवना',
    'भाँवर',
    'भाँवरी',
    'भांग',
    'भांजा',
    'भांजी',
    'भांड',
    'भांडक',
    'भांडशाला',
    'भांड़',
    'भांडागार',
    'भांडागारण',
    'भांडार',
    'भांडारपाल',
    'भांडारिक',
    'भांडारी',
    'भांडिक',
    'भांडीक',
    'भांडे',
    'भांति',
    'भांप',
    'भांपना',
    'भाई',
    'भाईचारा',
    'भाईचारापन',
    'भाईबंद',
    'भाईबंदी',
    'भाईबंधु',
    'भाईबन्द',
    'भाउ',
    'भाऊ',
    'भाकंगापन',
    'भाकंस',
    'भाकंसा',
    'भाकसी',
    'भाखड़ा',
    'भाखा',
    'भाखी',
    'भाग',
    'भागक',
    'भागता',
    'भागदौड़',
    'भागध',
    'भागधी',
    'भागधेय',
    'भागना',
    'भागने',
    'भागमभाग',
    'भागवत',
    'भागहारी',
    'भागहीन',
    'भागा',
    'भागांक',
    'भागांश',
    'भागाधिकार',
    'भागाधिकारी',
    'भागाभाग',
    'भागिक',
    'भागिनेय',
    'भागिनेया',
    'भागी',
    'भागीकरण',
    'भागीकृत',
    'भागीदार',
    'भागीदारी',
    'भागीदारों',
    'भागीय',
    'भागीरथ',
    'भागीरथी',
    'भागू',
    'भागों',
    'भाग्य',
    'भाग्यप्रद',
    'भाग्यवंत',
    'भाग्यवती',
    'भाग्यवश',
    'भाग्यवशात',
    'भाग्यवाद',
    'भाग्यवादी',
    'भाग्यवान',
    'भाग्यविहीन',
    'भाग्यशाली',
    'भाग्यसंपद',
    'भाग्यहीन',
    'भाग्यहीनता',
    'भाग्याधीन',
    'भाग्याधीनता',
    'भाग्यास्त',
    'भाग्यीय',
    'भाग्योदय',
    'भाजक',
    'भाजकांक',
    'भाजकांश',
    'भाजन',
    'भाजित',
    'भाजी',
    'भाज्य',
    'भाज्यांश',
    'भाट',
    'भाटक',
    'भाटकित',
    'भाटकी',
    'भाटतीतर',
    'भाटा',
    'भाटित्र',
    'भाटिया',
    'भाठ',
    'भाठा',
    'भाठी',
    'भाडः',
    'भाड़',
    'भाड़ा',
    'भाड़ाः',
    'भाड़ित्र',
    'भाड़े',
    'भाड़ेदार',
    'भाडाः',
    'भाण',
    'भात',
    'भातः',
    'भातई',
    'भाता',
    'भाति',
    'भाथी',
    'भादो',
    'भादों',
    'भाद्र',
    'भाद्रपद',
    'भाद्ररपद',
    'भान',
    'भानजा',
    'भानजी',
    'भानमती',
    'भाना',
    'भानु',
    'भानुजा',
    'भानुमती',
    'भानुमान',
    'भानुमुखी',
    'भानुयार',
    'भाप',
    'भाभज',
    'भाभा',
    'भाभी',
    'भाम',
    'भामक',
    'भामिनी',
    'भामी',
    'भाय',
    'भायँभायँ',
    'भायज',
    'भायज्ञ',
    'भायहों',
    'भाया',
    'भार',
    'भारग्रस्त',
    'भारग्रस्तता',
    'भारग्रहण',
    'भारजन',
    'भारजमान',
    'भारजिष्णु',
    'भारजिष्णुता',
    'भारजीवी',
    'भारण',
    'भारत',
    'भारतः',
    'भारतभूषण',
    'भारतवाद',
    'भारती',
    'भारतीय',
    'भारतीयकरण',
    'भारतीयों',
    'भारतृत्व',
    'भारतृयुद्घ',
    'भारत्रीय',
    'भारत्रेय',
    'भारथ',
    'भारथी',
    'भारद्वाज',
    'भारना',
    'भारभारी',
    'भारमक',
    'भारमात्र',
    'भारमुक्त',
    'भारमुक्ति',
    'भारमोचन',
    'भारयुक्त',
    'भारयुक्तता',
    'भारयोग',
    'भारवाह',
    'भारवाहक',
    'भारवाही',
    'भारस्वरूप',
    'भारहीन',
    'भारहीनता',
    'भारा',
    'भाराक्रांता',
    'भारानुभूति',
    'भारावतारण',
    'भारिक',
    'भारित',
    'भारिता',
    'भारित्र',
    'भारी',
    'भारीपन',
    'भारुप',
    'भारोच्चतामापी',
    'भारोत्तोलक',
    'भारोत्तोलन',
    'भारोद्भरण',
    'भार्गव',
    'भार्य',
    'भार्या',
    'भार्याट',
    'भार्याटिक',
    'भाल',
    'भालचंद्र',
    'भालते',
    'भालना',
    'भाललोचन',
    'भाला',
    'भालांक',
    'भालाकार',
    'भालाधारी',
    'भालि',
    'भाली',
    'भालू',
    'भाले',
    'भाव',
    'भावंता',
    'भावः',
    'भावक',
    'भावकत्व',
    'भावगीत',
    'भावजन्यता',
    'भावज्ञ',
    'भावतः',
    'भावता',
    'भावताव',
    'भावती',
    'भावते',
    'भावन',
    'भावना',
    'भावनाएं',
    'भावनाओं',
    'भावनात्मक',
    'भावनापूर्ण',
    'भावनापूर्णता',
    'भावनापूर्वक',
    'भावनामय',
    'भावनायुक्त',
    'भावनायें',
    'भावनाहीन',
    'भावनाहीनता',
    'भावपूर्ण',
    'भावपूर्णता',
    'भावपूर्वक',
    'भावप्रबल',
    'भावप्रवण',
    'भावप्रवणता',
    'भावमग्न',
    'भावमग्नता',
    'भावरहित',
    'भावली',
    'भावलीन',
    'भावलीनता',
    'भावलेख',
    'भाववाचक',
    'भाववाच्य',
    'भाववाले',
    'भावविष्ट',
    'भावविहृल',
    'भावशील',
    'भावशून्य',
    'भावशून्यता',
    'भावस्थ',
    'भावहीन',
    'भावहीनतः',
    'भावहीनता',
    'भावांतर',
    'भावांदोलन',
    'भावांश',
    'भावातिरेक',
    'भावात्मक',
    'भावात्मकता',
    'भावानुवाद',
    'भावाभास',
    'भावाभिनय',
    'भावायित्री',
    'भावार्थ',
    'भावावेग',
    'भावावेश',
    'भावावेशपूर्ण',
    'भावास्वाद',
    'भाविक',
    'भावित',
    'भाविता',
    'भाविनी',
    'भावी',
    'भावुक',
    'भावुकता',
    'भावुकतापूर्ण',
    'भावों',
    'भावोत्तेजक',
    'भावोत्तेजन',
    'भावोत्तेजना',
    'भावोदय',
    'भावोद्गार',
    'भावोद्दीपक',
    'भावोद्दीपन',
    'भावोद्दीपनः',
    'भावोद्दीप्त',
    'भावोद्रेक',
    'भावोन्मत्त',
    'भावोन्माद',
    'भावोन्मादी',
    'भाशा',
    'भाषण',
    'भाषणकुशल',
    'भाषणगत',
    'भाषणबाजी',
    'भाषणबाज़',
    'भाषा',
    'भाषांग',
    'भाषांतर',
    'भाषांतरकार',
    'भाषाः',
    'भाषाई',
    'भाषाएं',
    'भाषाओं',
    'भाषाधिकारी',
    'भाषाविज्ञान',
    'भाषाविज्ञानी',
    'भाषाविद',
    'भाषाशास्त्र',
    'भाषाशास्त्री',
    'भाषाशास्त्रीय',
    'भाषासंकर',
    'भाषिक',
    'भाषिका',
    'भाषिणी',
    'भाषित',
    'भाषिता',
    'भाषी',
    'भाषीय',
    'भाष्य',
    'भाष्यकर्ता',
    'भाष्यकार',
    'भास',
    'भासंत',
    'भासक',
    'भासता',
    'भासन',
    'भासना',
    'भासमान',
    'भासमानिकी',
    'भासित',
    'भासुर',
    'भासुरता',
    'भास्कर',
    'भास्करन',
    'भास्करि',
    'भास्कर्म',
    'भास्वर',
    'भास्वान',
    'भिंचना',
    'भिंचा',
    'भिंचाई',
    'भिंचापन',
    'भिंड',
    'भिंडी',
    'भिंदिपाल',
    'भिक्कु',
    'भिक्त',
    'भिक्षण',
    'भिक्षा',
    'भिक्षाजीवी',
    'भिक्षाटन',
    'भिक्षान्न',
    'भिक्षार्थी',
    'भिक्षावृति',
    'भिक्षाहार',
    'भिक्षु',
    'भिक्षुक',
    'भिक्षुणी',
    'भिक्षू',
    'भिखमंगा',
    'भिखमंगापन',
    'भिखमंगी',
    'भिखारी',
    'भिगो',
    'भिगोई',
    'भिगोकर',
    'भिगोना',
    'भिगोया',
    'भिगौना',
    'भिचा',
    'भिचाव',
    'भिच्छू',
    'भिच्छूक',
    'भिजवाई',
    'भिजवाना',
    'भिजवानें',
    'भिजवायें',
    'भिज्ञ',
    'भिज्ञता',
    'भिटा',
    'भिड़',
    'भिड़त',
    'भिड़न',
    'भिड़ना',
    'भिड़न्त',
    'भिड़ा',
    'भिड़ाना',
    'भिड़ापन',
    'भिडा',
    'भिडी',
    'भितरिया',
    'भितल्ला',
    'भित्ति',
    'भित्तिकला',
    'भित्तिचित्र',
    'भित्तिपत्रक',
    'भित्तिस्थ',
    'भित्तीय',
    'भिद',
    'भिदक',
    'भिदन',
    'भिदना',
    'भिदा',
    'भिदिर',
    'भिदुर',
    'भिद्य',
    'भिनक',
    'भिनकना',
    'भिनभिन',
    'भिनभिनाना',
    'भिनभिनाहट',
    'भिनसार',
    'भिनसारे',
    'भिन्न',
    'भिन्नक',
    'भिन्नता',
    'भिन्नदर्शी',
    'भिन्नरुप',
    'भिन्नरुपता',
    'भिन्नशाखीय',
    'भिन्नहृदय',
    'भिन्नांक',
    'भिन्नात्मक',
    'भिन्नाना',
    'भिन्नार्थक',
    'भिन्नोदर',
    'भिरुप',
    'भिलिया',
    'भिल्लोट',
    'भिश्ती',
    'भिषज',
    'भिस',
    'भिसटा',
    'भिस्मत',
    'भिस्मत्र',
    'भिस्स',
    'भिस्सटा',
    'भिस्सा',
    'भी',
    'भींचना',
    'भींजना',
    'भीख',
    'भीग',
    'भीगा',
    'भीगापन',
    'भीगी',
    'भीटा',
    'भीड़',
    'भीड़तंत्र',
    'भीड़भाड़',
    'भीड़शाही',
    'भीड़हीन',
    'भीड़हीनता',
    'भीड़ानुगमन',
    'भीड़ानुगामी',
    'भीड़ानुसरण',
    'भीत',
    'भीततः',
    'भीतर',
    'भीतरकोट',
    'भीतरघात',
    'भीतरघाती',
    'भीतरला',
    'भीतरी',
    'भीतरीपन',
    'भीति',
    'भीतियुक्त',
    'भीनना',
    'भीना',
    'भीम',
    'भीमक',
    'भीमकर्मी',
    'भीमकाय',
    'भीमकायता',
    'भीमता',
    'भीमदर्शन',
    'भीमपराक्रम',
    'भीमबल',
    'भीमयेग',
    'भीमरुप',
    'भीमसेन',
    'भीमसेनी',
    'भीमा',
    'भीमाग्रज',
    'भीमोदरी',
    'भीरु',
    'भीरुता',
    'भीरुहृदय',
    'भीरू',
    'भीलूक',
    'भीषण',
    'भीषणकाय',
    'भीषणता',
    'भीषणाकार',
    'भीषम',
    'भीषिका',
    'भीष्म',
    'भीष्मः',
    'भीष्मव्रत',
    'भीसोन',
    'भुँजना',
    'भुँजवा',
    'भुँजाई',
    'भुँर्जी',
    'भुअन',
    'भुआ',
    'भुई',
    'भुईँ',
    'भुकड़ी',
    'भुकरायैध',
    'भुक्कड़',
    'भुक्खड़',
    'भुक्खड़पन',
    'भुक्त',
    'भुक्तता',
    'भुक्तभोगी',
    'भुक्तशेष',
    'भुक्ति',
    'भुखमरी',
    'भुखाना',
    'भुगत',
    'भुगतना',
    'भुगतने',
    'भुगतान',
    'भुगतानकर्ता',
    'भुगताना',
    'भुगतानी',
    'भुगताया',
    'भुज',
    'भुजंग',
    'भुजंगम',
    'भुजंगा',
    'भुजंगारि',
    'भुजंगेश',
    'भुजकवच',
    'भुजग',
    'भुजजाई',
    'भुजता',
    'भुजपत्र',
    'भुजपाश',
    'भुजबंद',
    'भुजबल',
    'भुजमूल',
    'भुजयष्टि',
    'भुजहापन',
    'भुजा',
    'भुजांतराल',
    'भुजाओं',
    'भुजाकवच',
    'भुजाकृति',
    'भुजिया',
    'भुजिर्त',
    'भुजैना',
    'भुजैल',
    'भुजोत्तोलक',
    'भुज्यु',
    'भुट्टा',
    'भुट्टे',
    'भुतहा',
    'भुतहापन',
    'भुनगा',
    'भुनना',
    'भुनभुनाकर',
    'भुनभुनाना',
    'भुना',
    'भुनाई',
    'भुनाए',
    'भुनाग',
    'भुनाना',
    'भुनाने',
    'भुनाया',
    'भुनाये',
    'भुनी',
    'भुने',
    'भुरकना',
    'भुरकस',
    'भुरका',
    'भुरकाई',
    'भुरकाव',
    'भुरकी',
    'भुरकुट',
    'भुरकुस',
    'भुरजी',
    'भुरता',
    'भुरताई',
    'भुरभुरा',
    'भुरभुराना',
    'भुरभुरानोट',
    'भुरभुरापन',
    'भुरभुरी',
    'भुरहटा',
    'भुरहरा',
    'भुरहरे',
    'भुर्रा',
    'भुलक्कड़',
    'भुलवाना',
    'भुला',
    'भुलाना',
    'भुलाने',
    'भुलाया',
    'भुलावा',
    'भुलैया',
    'भुव',
    'भुवः',
    'भुवन',
    'भुवनीय',
    'भुवनेश',
    'भुवनेश्वर',
    'भुवनेश्वरी',
    'भुवलोक',
    'भुवि',
    'भुशुंडि',
    'भुशुंडी',
    'भुष्ट',
    'भुस',
    'भुसुंड',
    'भुसौरा',
    'भुिक्त',
    'भू',
    'भूँक',
    'भूँज',
    'भूँजना',
    'भूँजा',
    'भूँडरी',
    'भूक',
    'भूकंप',
    'भूकंपविज्ञानी',
    'भूकम्प',
    'भूकश्यप',
    'भूकाक',
    'भूकेश',
    'भूकेशा',
    'भूक्षेत्र',
    'भूख',
    'भूखंड',
    'भूखंडीय',
    'भूखण्ड',
    'भूखा',
    'भूखापन',
    'भूखे',
    'भूखो',
    'भूखों',
    'भूगणित',
    'भूगणितीय',
    'भूगर्भ',
    'भूगर्भः',
    'भूगर्भित',
    'भूगाग',
    'भूगेह',
    'भूगोल',
    'भूगोलक',
    'भूगोलज्ञ',
    'भूगोलार्थ',
    'भूचक्र',
    'भूचाल',
    'भूचालन',
    'भूछत्र',
    'भूछाया',
    'भूजल',
    'भूजलविज्ञानी',
    'भूजात',
    'भूजिर्त',
    'भूजी',
    'भूटान',
    'भूटानी',
    'भूटिया',
    'भूड़',
    'भूडोल',
    'भूढाल',
    'भूत',
    'भूतकाल',
    'भूतकालिक',
    'भूतग्रस्त',
    'भूतजीववाद',
    'भूतता',
    'भूतधात्री',
    'भूतध्नी',
    'भूतनाथ',
    'भूतनी',
    'भूतपति',
    'भूतपूर्व',
    'भूतपूर्वता',
    'भूतप्रेत',
    'भूतबाधा',
    'भूतभावी',
    'भूतल',
    'भूतलक्षतापूर्वक',
    'भूतलक्षी',
    'भूतवाद',
    'भूतविद्या',
    'भूतहंता',
    'भूतहर',
    'भूतहा',
    'भूता',
    'भूतांतक',
    'भूतात्मा',
    'भूतादि',
    'भूतापसारक',
    'भूतापसारण',
    'भूतारि',
    'भूतार्थ',
    'भूतावास',
    'भूताविष्ट',
    'भूति',
    'भूतीय',
    'भूतेश',
    'भूतेश्वर',
    'भूतोत्पात',
    'भूदर्शन',
    'भूदान',
    'भूदार',
    'भूदृश्य',
    'भूदेव',
    'भूधर',
    'भूधारक',
    'भूधारण',
    'भून',
    'भूनना',
    'भूनने',
    'भूना',
    'भूनिवेश',
    'भूप',
    'भूपटल',
    'भूपति',
    'भूपर्पटी',
    'भूपर्पटीय',
    'भूपाल',
    'भूपाली',
    'भूपृष्टीय',
    'भूपृष्ठ',
    'भूबद्ध',
    'भूबल',
    'भूभल',
    'भूभाग',
    'भूभुर',
    'भूभुरि',
    'भूभुवः',
    'भूभौतिक',
    'भूभौतिकी',
    'भूभौतिकीय',
    'भूभौतिकीविद',
    'भूमंडलीय',
    'भूमण्डल',
    'भूमण्डलीय',
    'भूमध्य',
    'भूमध्यरेखा',
    'भूमध्यसागर',
    'भूमयी',
    'भूमा',
    'भूमापक',
    'भूमापन',
    'भूमि',
    'भूमिक',
    'भूमिका',
    'भूमिगत',
    'भूमिगतता',
    'भूमिगम',
    'भूमिगुप्त',
    'भूमिगृह',
    'भूमिज',
    'भूमिजा',
    'भूमिजीवी',
    'भूमितल',
    'भूमिति',
    'भूमिदारी',
    'भूमिदास',
    'भूमिधर',
    'भूमिधारी',
    'भूमिपति',
    'भूमिपाल',
    'भूमिया',
    'भूमिरुह',
    'भूमिलेखाचित्रीय',
    'भूमिलेप',
    'भूमिशायी',
    'भूमिसंबंधी',
    'भूमिसुधारवाद',
    'भूमिस्पृक',
    'भूमिहर',
    'भूमिहार',
    'भूमिहीन',
    'भूमीय',
    'भूमूल्य',
    'भूम्यधिकार',
    'भूयः',
    'भूयसी',
    'भूयाति',
    'भूयिष्ठ',
    'भूयिष्ठता',
    'भूयोजन',
    'भूर',
    'भूरसायनज्ञ',
    'भूरा',
    'भूराजनीतिक',
    'भूरि',
    'भूरिगंधा',
    'भूरिता',
    'भूरितेजा',
    'भूरिपुष्पा',
    'भूरिभाग्य',
    'भूरी',
    'भूरे',
    'भूर्ज',
    'भूर्जपात्र',
    'भूल',
    'भूलंब',
    'भूलचूक',
    'भूलना',
    'भूलभुलैया',
    'भूलयुक्त',
    'भूलहीनतः',
    'भूलहीनता',
    'भूला',
    'भूलॉक',
    'भूलोक',
    'भूवासी',
    'भूविज्ञान',
    'भूविज्ञानिकी',
    'भूविज्ञानी',
    'भूविर्वतन',
    'भूवृतांत',
    'भूवैज्ञानिक',
    'भूशाय',
    'भूशायी',
    'भूशास्त्र',
    'भूशास्त्री',
    'भूषण',
    'भूषणा',
    'भूषा',
    'भूषित',
    'भूसंधि',
    'भूसंपत्ति',
    'भूसंपदा',
    'भूसंपर्क',
    'भूसंपर्कित',
    'भूसंपित्त',
    'भूसंपित्तय',
    'भूसंपित्तहीन',
    'भूसप्रण',
    'भूसमतल',
    'भूसम्पति',
    'भूसर्ग',
    'भूसर्वेक्षण',
    'भूसा',
    'भूसाः',
    'भूसाघर',
    'भूसिंधन',
    'भूसी',
    'भूसे',
    'भूस्खलन',
    'भूस्तारी',
    'भूस्पंदन',
    'भूस्पर्श',
    'भूस्वर्ग',
    'भूस्वामी',
    'भृंकुंश',
    'भृंकुटि',
    'भृंकुटी',
    'भृंग',
    'भृंगक',
    'भृंगज',
    'भृंगरोल',
    'भृंगार',
    'भृंगारक',
    'भृंगारी',
    'भृंगी',
    'भृंगु',
    'भृंगुः',
    'भृंगुनाथ',
    'भृंगुपति',
    'भृंगुवार',
    'भृंगुसुत',
    'भृंत',
    'भृंश',
    'भृकुटी',
    'भृग',
    'भृतिक',
    'भृत्य',
    'भृत्यता',
    'भृत्या',
    'भृद',
    'भृदन',
    'भृष्ट',
    'भृष्टि',
    'भेंक',
    'भेंकची',
    'भेंकना',
    'भेंकभोजी',
    'भेंगा',
    'भेंजनहार',
    'भेंट',
    'भेंटना',
    'भेंटवार्ता',
    'भेंड़',
    'भेंड़ी',
    'भेंड़ों',
    'भेंडी',
    'भेज',
    'भेजता',
    'भेजते',
    'भेजना',
    'भेजनाः',
    'भेजने',
    'भेजनेवाला',
    'भेजा',
    'भेजिए',
    'भेजी',
    'भेजे',
    'भेजें',
    'भेट',
    'भेड़',
    'भेड़खाना',
    'भेड़चाल',
    'भेड़चाली',
    'भेड़नुगामी',
    'भेड़शाला',
    'भेड़ा',
    'भेड़ानुगमन',
    'भेड़िया',
    'भेड़े',
    'भेडिया',
    'भेत्ता',
    'भेद',
    'भेदक',
    'भेदकता',
    'भेदकारी',
    'भेदज्ञान',
    'भेदन',
    'भेदना',
    'भेदनीति',
    'भेदनीय',
    'भेदपूर्ण',
    'भेदभरा',
    'भेदभान',
    'भेदभाव',
    'भेदभावपूर्ण',
    'भेदभावपूर्णता',
    'भेदभावहीन',
    'भेदभावहीनता',
    'भेदमूलक',
    'भेदवाद',
    'भेदहीन',
    'भेदहीनता',
    'भेदिया',
    'भेदियापन',
    'भेदी',
    'भेदुर',
    'भेदू',
    'भेद्य',
    'भेद्यता',
    'भेर',
    'भेरी',
    'भेरीकार',
    'भेरु',
    'भेरुंदंक',
    'भेऱें',
    'भेल',
    'भेलन',
    'भेला',
    'भेली',
    'भेष',
    'भेषज',
    'भेषजांग',
    'भेषजी',
    'भेषजीय',
    'भेषज्य',
    'भेषज्यता',
    'भेस',
    'भेसिया',
    'भेसी',
    'भैंडरिया',
    'भैंडरियापन',
    'भैंव',
    'भैंस',
    'भैंसः',
    'भैंसा',
    'भैन',
    'भैना',
    'भैनी',
    'भैया',
    'भैरव',
    'भैरवी',
    'भैरवेश',
    'भैरो',
    'भैव',
    'भैवैया',
    'भैष्मकी',
    'भैसं',
    'भैसः',
    'भोंक',
    'भोंकना',
    'भोंड़ा',
    'भोंड़ापन',
    'भोंडा',
    'भोंडापन',
    'भोंदा',
    'भोंदापन',
    'भोंदू',
    'भोंपा',
    'भोंपू',
    'भोंभो',
    'भोक्तव्य',
    'भोक्ता',
    'भोग',
    'भोगकक्ष',
    'भोगते',
    'भोगधर',
    'भोगन',
    'भोगना',
    'भोगपति',
    'भोगलिप्त',
    'भोगलिप्सु',
    'भोगली',
    'भोगवती',
    'भोगवाद',
    'भोगवादी',
    'भोगवृत्ति',
    'भोगहीनता',
    'भोगा',
    'भोगाधिकार',
    'भोगानुभवी',
    'भोगानुभूति',
    'भोगानुभूती',
    'भोगासक्ति',
    'भोगिनी',
    'भोगियों',
    'भोगी',
    'भोगींद्र',
    'भोगीभुज',
    'भोगेच्छुक',
    'भोगों',
    'भोगोन्माद',
    'भोग्य',
    'भोग्यता',
    'भोज',
    'भोजक',
    'भोजकत्व',
    'भोजदाता',
    'भोजन',
    'भोजनः',
    'भोजनदार',
    'भोजनदारी',
    'भोजनन',
    'भोजनप्रिय',
    'भोजनप्रियता',
    'भोजनयान',
    'भोजनशाला',
    'भोजनहीन',
    'भोजनांश',
    'भोजनाचार',
    'भोजनार्थी',
    'भोजनालय',
    'भोजनावकाश',
    'भोजनिका',
    'भोजनेच्छुक',
    'भोजनोपरान्त',
    'भोजपति',
    'भोजपत्र',
    'भोजयिता',
    'भोजवृक्ष',
    'भोजशाला',
    'भोजसंस्कार',
    'भोजाचार',
    'भोजातिथि',
    'भोजाधिकार',
    'भोजित',
    'भोजिततः',
    'भोजी',
    'भोज्य',
    'भोज्यता',
    'भोज्यम',
    'भोट',
    'भोटांग',
    'भोटांगीय',
    'भोटिया',
    'भोड़ा',
    'भोतिकता',
    'भोथरा',
    'भोथरापन',
    'भोदूपना',
    'भोपाल',
    'भोमीरा',
    'भोर',
    'भोला',
    'भोलानाथ',
    'भोलापन',
    'भोलेनाथ',
    'भोसले',
    'भौं',
    'भौंक',
    'भौंकना',
    'भौंकने',
    'भौंडा',
    'भौंडापन',
    'भौंपू',
    'भौंरा',
    'भौंरी',
    'भौंह',
    'भौंहे',
    'भौगोलिक',
    'भौचक',
    'भौचक्का',
    'भौचक्कापन',
    'भौचक्केपन',
    'भौज',
    'भौजाई',
    'भौजी',
    'भौतिक',
    'भौतिकता',
    'भौतिकतावाद',
    'भौतिकवाद',
    'भौतिकवादिता',
    'भौतिकवादी',
    'भौतिकी',
    'भौतिकीकरण',
    'भौतिकीय',
    'भौभी',
    'भौभौ',
    'भौम',
    'भौमन',
    'भ्रंत',
    'भ्रंरंश',
    'भ्रंश',
    'भ्रंशोद्घत',
    'भ्रंशोद्वारक',
    'भ्रकुटी',
    'भ्रद',
    'भ्रम',
    'भ्रमकारी',
    'भ्रमग्रस्त',
    'भ्रमजाल',
    'भ्रमण',
    'भ्रमणकर्ता',
    'भ्रमणकारी',
    'भ्रमणशील',
    'भ्रमणी',
    'भ्रमपूर्वक',
    'भ्रमर',
    'भ्रमरक',
    'भ्रमरज',
    'भ्रमरानंद',
    'भ्रमरिका',
    'भ्रमरी',
    'भ्रमवश',
    'भ्रमशील',
    'भ्रमहीन',
    'भ्रमहीनता',
    'भ्रमात्मक',
    'भ्रमायण',
    'भ्रमाशा',
    'भ्रमासक्त',
    'भ्रमि',
    'भ्रमिका',
    'भ्रमित',
    'भ्रमी',
    'भ्रमोत्पादक',
    'भ्ररामण',
    'भ्रशन',
    'भ्रशित',
    'भ्रशोद्वार',
    'भ्रष्ट',
    'भ्रष्टता',
    'भ्रष्टधी',
    'भ्रष्टबुद्धि',
    'भ्रष्टबुद्धिता',
    'भ्रष्टमार्ग',
    'भ्रष्टश्री',
    'भ्रष्टसमृति',
    'भ्रष्टा',
    'भ्रष्टाचार',
    'भ्रष्टाचारी',
    'भ्रष्टीकरण',
    'भ्रांत',
    'भ्रांति',
    'भ्रांतिकारी',
    'भ्रांतिग्रस्त',
    'भ्रांतिहर',
    'भ्रांतिहीन',
    'भ्रांतिहीनता',
    'भ्राता',
    'भ्रातापुत्र',
    'भ्रातीया',
    'भ्रातृ',
    'भ्रातृजा',
    'भ्रातृता',
    'भ्रातृत्व',
    'भ्रातृभाव',
    'भ्रातृसंघ',
    'भ्रातृहत्या',
    'भ्रान्त',
    'भ्रान्ति',
    'भ्रान्तिजनक',
    'भ्राभी',
    'भ्रामक',
    'भ्रामकता',
    'भ्रामकतापूर्ण',
    'भ्रामकतावश',
    'भ्रामकतावाद',
    'भ्रामरी',
    'भ्राष्ट्र',
    'भ्रू',
    'भ्रूं',
    'भ्रूकंश',
    'भ्रूकटी',
    'भ्रूण',
    'भ्रूणधाती',
    'भ्रूणध्न',
    'भ्रूणविज्ञान',
    'भ्रूणहा',
    'भ्रूणीय',
    'भ्रूमध्य',
    'म',
    'मँगता',
    'मँगनी',
    'मँगवाई',
    'मँगवाना',
    'मँगवानाः',
    'मँगवाने',
    'मँगवाया',
    'मँगाना',
    'मँगाने',
    'मँगाया',
    'मँगेतर',
    'मँगोडी',
    'मँगोल',
    'मँजरा',
    'मँजा',
    'मँजाई',
    'मँझला',
    'मँडराना',
    'मँडराव',
    'मँडवा',
    'मँडाई',
    'मँडुआ',
    'मँडुया',
    'मँढना',
    'मँढ़ना',
    'मँढ़ाई',
    'मँढा',
    'मँढाई',
    'मँढाय',
    'मँढे',
    'मँदा',
    'मँदोवै',
    'मँाजना',
    'मंंज़र',
    'मंग',
    'मंगनी',
    'मंगल',
    'मंगलकर',
    'मंगलकाम',
    'मंगलकारक',
    'मंगलकारिता',
    'मंगलपूर्ण',
    'मंगलभाषी',
    'मंगलवार',
    'मंगला',
    'मंगलाकांक्षी',
    'मंगलाचरण',
    'मंगलाचार',
    'मंगलारंभ',
    'मंगलालय',
    'मंगलावह',
    'मंगलावास',
    'मंगलेश',
    'मंगलोत्सव',
    'मंगल्य',
    'मंगल्या',
    'मंगवा',
    'मंगवाई',
    'मंगसिर',
    'मंगाइए',
    'मंगाई',
    'मंगाए',
    'मंगाना',
    'मंगाने',
    'मंगाया',
    'मंच',
    'मंचक',
    'मंचन',
    'मंचनीय',
    'मंचसज्जा',
    'मंचाग्र',
    'मंचायोग्य',
    'मंचिका',
    'मंचित',
    'मंचीकरण',
    'मंचीकरणः',
    'मंचीय',
    'मंजन',
    'मंजर',
    'मंजरित',
    'मंजरी',
    'मंजरीक',
    'मंज़रकशी',
    'मंज़ूर',
    'मंजा',
    'मंजाई',
    'मंजि',
    'मंजिल',
    'मंजिलगाह',
    'मंजिला',
    'मंजी',
    'मंजीर',
    'मंजीरा',
    'मंजु',
    'मंजुकेशी',
    'मंजुगर्त',
    'मंजुघोष',
    'मंजुघोषी',
    'मंजुनाशी',
    'मंजुप्राण',
    'मंजुल',
    'मंजुलता',
    'मंजुवादिनी',
    'मंजुश्री',
    'मंजुस्वन',
    'मंजूर',
    'मंजूरशुदा',
    'मंजूरी',
    'मंजूषा',
    'मंझला',
    'मंटल',
    'मंटिनैंस',
    'मंठ',
    'मंड',
    'मंडई',
    'मंडन',
    'मंडना',
    'मंडनोक्ति',
    'मंडप',
    'मंडपक',
    'मंडपन',
    'मंडराना',
    'मंडल',
    'मंडलः',
    'मंडलाकार',
    'मंडलाग्र',
    'मंडलाधीश',
    'मंडलाध्यक्ष',
    'मंडलित',
    'मंडली',
    'मंडलीः',
    'मंडलीक',
    'मंडलीय',
    'मंडलीयता',
    'मंडलेश',
    'मंडलेश्वर',
    'मंडलों',
    'मंडित',
    'मंडितता',
    'मंडी',
    'मंडीर',
    'मंडूक',
    'मंडूकची',
    'मंडूकता',
    'मंडूकी',
    'मंडूर',
    'मंतर',
    'मंतव्य',
    'मंत्र',
    'मंत्रकार',
    'मंत्रकुशल',
    'मंत्रजिह्य',
    'मंत्रणा',
    'मंत्रणाकार',
    'मंत्रणाकुशल',
    'मंत्रणात्मक',
    'मंत्रद',
    'मंत्रदाता',
    'मंत्रधर',
    'मंत्रमुग्ध',
    'मंत्रर्षि',
    'मंत्रवादी',
    'मंत्रविद',
    'मंत्रश',
    'मंत्रसिद्घ',
    'मंत्रालय',
    'मंत्रालयों',
    'मंत्रावाहन',
    'मंत्रावाहित',
    'मंत्राविष्ट',
    'मंत्राविष्टन',
    'मंत्रित',
    'मंत्रिपरिषद',
    'मंत्रिमंडल',
    'मंत्रिमंडलीय',
    'मंत्री',
    'मंत्रीः',
    'मंत्रीत्व',
    'मंत्रीनिवास',
    'मंत्रीपक्षीय',
    'मंत्रीपचार',
    'मंत्रीपति',
    'मंत्रीपदेश',
    'मंत्रीय',
    'मंथ',
    'मंथक',
    'मंथज',
    'मंथन',
    'मंथनः',
    'मंथनक',
    'मंथनी',
    'मंथर',
    'मंथरण',
    'मंथरता',
    'मंथरा',
    'मंथरित',
    'मंथली',
    'मंथा',
    'मंथाचल',
    'मंथाद्रि',
    'मंथित',
    'मंथी',
    'मंद',
    'मंदक',
    'मंदकर्मा',
    'मंदकांति',
    'मंदकामी',
    'मंदग',
    'मंदगामिता',
    'मंदड़िया',
    'मंदतः',
    'मंदता',
    'मंदधी',
    'मंदन',
    'मंदबुद्धि',
    'मंदभाग्य',
    'मंदमंथरता',
    'मंदमति',
    'मंदर',
    'मंदराचल',
    'मंदरिन',
    'मंदा',
    'मंदाकिनी',
    'मंदाकिनीय',
    'मंदात्मा',
    'मंदान',
    'मंदानिल',
    'मंदानुभूति',
    'मंदापन',
    'मंदार',
    'मंदारक',
    'मंदित',
    'मंदिमा',
    'मंदिर',
    'मंदी',
    'मंदीकृत',
    'मंदुरा',
    'मंदोदरी',
    'मंदोदरीः',
    'मंदोष्ण',
    'मंद्र',
    'मंशा',
    'मंसूख',
    'मंसूखी',
    'मंह',
    'मंहगा',
    'मंहगाई',
    'मंहगी',
    'मंज़िल',
    'मंज़ूरी',
    'मई',
    'मकई',
    'मकड़',
    'मकड़जाल',
    'मकड़ा',
    'मकड़ाना',
    'मकड़ी',
    'मकतब',
    'मकतबा',
    'मकतल',
    'मकबरा',
    'मकबरे',
    'मकबूल',
    'मकबूलियत',
    'मकर',
    'मकरंद',
    'मकरंदकोष',
    'मकरकेतु',
    'मकरध्वज',
    'मकररध्वज',
    'मकरालय',
    'मकराश्व',
    'मकरी',
    'मकवाना',
    'मक़बरा',
    'मक़सद',
    'मक़सूद',
    'मकान',
    'मकानदार',
    'मकानदारी',
    'मकानो',
    'मकानों',
    'मकालू',
    'मकुना',
    'मकोई',
    'मकोड़ा',
    'मकोड़ी',
    'मकोड़े',
    'मकोय',
    'मक्का',
    'मक्कार',
    'मक्कारी',
    'मक्की',
    'मक्खन',
    'मक्खनदानी',
    'मक्खनदार',
    'मक्खनयुक्त',
    'मक्खनी',
    'मक्खी',
    'मक्खीमार',
    'मक्खीयूस',
    'मक्रता',
    'मक्रतुल',
    'मक्रनातीस',
    'मक्षिका',
    'मक्षी',
    'मख',
    'मखतूल',
    'मखनिया',
    'मखनी',
    'मखमल',
    'मखमली',
    'मखशाला',
    'मखहा',
    'मख़',
    'मख़ज़न',
    'मख़मल',
    'मख़मली',
    'मख़मूर',
    'मखात्र',
    'मग',
    'मगज',
    'मगजघट',
    'मगजघट्टी',
    'मगजपच्ची',
    'मगज़ी',
    'मगण',
    'मगध',
    'मगधेश्वर',
    'मगन',
    'मगर',
    'मगरचे',
    'मगरमच्छ',
    'मगरमच्छी',
    'मगरुर',
    'मगरैला',
    'मगस',
    'मगसिर',
    'मग़ची',
    'मग़रिब',
    'मग़रिबी',
    'मग़ालता',
    'मग्',
    'मग्गा',
    'मग्न',
    'मग्नता',
    'मघ',
    'मघवा',
    'मघा',
    'मघी',
    'मघोनी',
    'मचक',
    'मचकना',
    'मचका',
    'मचकाना',
    'मचना',
    'मचमचाना',
    'मचल',
    'मचलना',
    'मचलाहट',
    'मचली',
    'मचान',
    'मचाना',
    'मचाने',
    'मचिया',
    'मचे',
    'मचैट',
    'मच्छ',
    'मच्छर',
    'मच्छरदानी',
    'मच्छी',
    'मच्छीभार',
    'मछया',
    'मछरंगा',
    'मछलियों',
    'मछली',
    'मछलीः',
    'मछलीपालनविद',
    'मछलीमार',
    'मछलीशाला',
    'मछुआ',
    'मछुआरा',
    'मछुवा',
    'मछुवों',
    'मछोदरी',
    'मज',
    'मजदूर',
    'मजदूरी',
    'मजदूरीजन्य',
    'मजदूरों',
    'मजनू',
    'मजनूपन',
    'मजबूत',
    'मजबूती',
    'मजबूर',
    'मजबूरन',
    'मजबूरी',
    'मजमा',
    'मजमूआ',
    'मजमूई',
    'मजमून',
    'मजमेबाज़',
    'मजमेबाज़ी',
    'मजम्मत',
    'मजरुआ',
    'मजरुह',
    'मजलिस',
    'मजलिसी',
    'मजलिसे',
    'मजलूम',
    'मज़किरा',
    'मज़कूर',
    'मज़दूर',
    'मज़दूरवाद',
    'मज़दूरी',
    'मज़बूत',
    'मज़बूती',
    'मज़र्',
    'मज़लूम',
    'मज़हब',
    'मज़हबी',
    'मज़हबीयत',
    'मज़ा',
    'मज़ाकपसंद',
    'मज़ाकपसंदी',
    'मज़ाक़',
    'मज़ाक़न',
    'मज़ाकिया',
    'मज़ार',
    'मज़ीर्',
    'मज़े',
    'मजा',
    'मजाक',
    'मजाकिया',
    'मजाकियापन',
    'मजाजी',
    'मजार',
    'मजारः',
    'मजाल',
    'मजाहिया',
    'मजिस्ट्रेट',
    'मजीठिया',
    'मजीद',
    'मजीरा',
    'मजूर',
    'मजूरी',
    'मजे',
    'मजेऋ',
    'मजेदार',
    'मज्',
    'मज्जक',
    'मज्जन',
    'मज्जना',
    'मज्जमान',
    'मज्जा',
    'मज्जाहीन',
    'मज्झम',
    'मझ',
    'मझधार',
    'मझला',
    'मझोला',
    'मझोलापन',
    'मझोले',
    'मट',
    'मटक',
    'मटकन',
    'मटकना',
    'मटकनी',
    'मटका',
    'मटकाना',
    'मटकी',
    'मटकीला',
    'मटकू',
    'मटकैना',
    'मटक्का',
    'मटन',
    'मटमलापन',
    'मटमैला',
    'मटर',
    'मटरः',
    'मटरगश्ती',
    'मटरा',
    'मटरी',
    'मटिया',
    'मटियार',
    'मटियारा',
    'मटियाला',
    'मटियालापन',
    'मटियाली',
    'मटोल',
    'मट्टी',
    'मट्ठर',
    'मट्ठा',
    'मट्ठी',
    'मठ',
    'मठयतन',
    'मठराते',
    'मठराना',
    'मठरी',
    'मठली',
    'मठवाद',
    'मठवासीय',
    'मठविषयक',
    'मठा',
    'मठाधिकारिणी',
    'मठाधीश',
    'मठाध्यक्ष',
    'मठिया',
    'मठी',
    'मठोठा',
    'मठोर',
    'मडराना',
    'मडली',
    'मड़राव',
    'मडैया',
    'मढ़',
    'मढ़ई',
    'मढ़ना',
    'मढ़ने',
    'मढ़ाईकार',
    'मढ़ी',
    'मढा',
    'मढै',
    'मणि',
    'मणिक',
    'मणिकंठ',
    'मणिका',
    'मणिकार',
    'मणिकारी',
    'मणित',
    'मणिधर',
    'मणिनील',
    'मणिपुरी',
    'मणिपूर',
    'मणिबंध',
    'मणिभ',
    'मणिभद्र',
    'मणिभित',
    'मणिभीकरण',
    'मणिभूत',
    'मणिराज',
    'मणिवर',
    'मणिसर',
    'मणींद्र',
    'मणीच',
    'मणीचक',
    'मण्डप',
    'मण्डमय',
    'मण्डल',
    'मण्डली',
    'मण्डलीय',
    'मण्डेरा',
    'मत',
    'मतंग',
    'मतंगज',
    'मतंगी',
    'मतगणक',
    'मतगणना',
    'मतता',
    'मतदाता',
    'मतदान',
    'मतपत्र',
    'मतपरिवर्तन',
    'मतपर्ची',
    'मतपेटी',
    'मतभेद',
    'मतभेदपूर्ण',
    'मतभेदयुक्तता',
    'मतभेदहीन',
    'मतभेदहीनता',
    'मतभेदों',
    'मतयुक्त',
    'मतलब',
    'मतलबी',
    'मतला',
    'मतलाहट',
    'मतली',
    'मतवाला',
    'मतवालापन',
    'मतसंख्या',
    'मतहरी',
    'मतहीन',
    'मतहीनता',
    'मतांतर',
    'मतांतरण',
    'मतांध',
    'मताग्रह',
    'मताग्रहकरण',
    'मताग्रही',
    'मताधंता',
    'मताधिकार',
    'मताधिकारी',
    'मतानयायी',
    'मतानुगमन',
    'मतानुगमिता',
    'मतानुगामी',
    'मतानुयायी',
    'मतानुसार',
    'मतान्ध',
    'मतायलंबिता',
    'मतायु',
    'मतालंबी',
    'मतावलंबन',
    'मतावलंबी',
    'मतावलम्बी',
    'मति',
    'मतित्व',
    'मतिपूर्ण',
    'मतिभ्रंश',
    'मतिभ्रम',
    'मतिभ्ररम',
    'मतिभ्रष्ट',
    'मतिभ्रांत',
    'मतिमंद',
    'मतिमंदता',
    'मतिमान',
    'मतियुक्त',
    'मतिहीन',
    'मतीय',
    'मतेई',
    'मतैक्य',
    'मतैक्यतः',
    'मतैयपूर्ण',
    'मतों',
    'मत्',
    'मत्कुण',
    'मत्त',
    'मत्ता',
    'मत्तेभ',
    'मत्था',
    'मत्थे',
    'मत्रं',
    'मत्रक',
    'मत्र्य',
    'मत्र्यता',
    'मत्र्यमुख',
    'मत्र्यलोक',
    'मत्स',
    'मत्सय',
    'मत्सर',
    'मत्सरतः',
    'मत्सरता',
    'मत्सरपूर्ण',
    'मत्सरहीन',
    'मत्सरी',
    'मत्स्य',
    'मत्स्यकन्या',
    'मत्स्यग्रहण',
    'मत्स्यनाशी',
    'मत्स्यपालन',
    'मत्स्यपालनविद',
    'मत्स्यभक्षी',
    'मत्स्यहारी',
    'मत्स्यालय',
    'मत्स्योदरी',
    'मत्स्योदरीय',
    'मथन',
    'मथनक',
    'मथना',
    'मथनाचल',
    'मथनिया',
    'मथनी',
    'मथने',
    'मथयारी',
    'मथरा',
    'मथा',
    'मथाई',
    'मथानी',
    'मथिता',
    'मथी',
    'मथुरा',
    'मथुरेश',
    'मथूल',
    'मद',
    'मदकर',
    'मदकरी',
    'मदकल',
    'मदकाल',
    'मदगुरिका',
    'मदचक्र',
    'मदच्युति',
    'मदद',
    'मददगार',
    'मदन',
    'मदनक',
    'मदना',
    'मदनारि',
    'मदनालय',
    'मदनेदार',
    'मदनोत्सव',
    'मदपति',
    'मदपूर्ण',
    'मदप्रद',
    'मदफ़न',
    'मदफ़ुन',
    'मदभरा',
    'मदभेद',
    'मदमत्त',
    'मदमस्त',
    'मदमस्ती',
    'मदमाता',
    'मदमाती',
    'मदयित्नु',
    'मदर',
    'मदरसा',
    'मदराग',
    'मदवार',
    'मदव्य',
    'मदव्यय',
    'मदहीन',
    'मदहृत',
    'मदहोश',
    'मदहोशी',
    'मदा',
    'मदांतक',
    'मदांध',
    'मदांबर',
    'मदान',
    'मदापनय',
    'मदापनीत',
    'मदार',
    'मदारी',
    'मदालसा',
    'मदाव्यासक्त',
    'मदासक्त',
    'मदासिक्त',
    'मदिर',
    'मदिरता',
    'मदिरल',
    'मदिरा',
    'मदिरापान',
    'मदिरालय',
    'मदिरोन्मत',
    'मदीनाः',
    'मदुरम',
    'मदें',
    'मदों',
    'मदोत्कट',
    'मदोना',
    'मदोन्मत',
    'मदोन्मत्त',
    'मदोन्मत्तता',
    'मदोन्माद',
    'मदोन्मादक',
    'मदोवै',
    'मद्गु',
    'मद्गुर',
    'मद्घम',
    'मद्घमपन',
    'मद्द',
    'मद्दम',
    'मद्दाह',
    'मद्दे',
    'मद्धिम',
    'मद्य',
    'मद्यकक्ष',
    'मद्यत्याग',
    'मद्यत्यागी',
    'मद्यनिर्माणशाला',
    'मद्यप',
    'मद्यपता',
    'मद्यपस्ती',
    'मद्यपान',
    'मद्यपायी',
    'मद्यप्रिय',
    'मद्यबाला',
    'मद्यरहित',
    'मद्यशाला',
    'मद्यसार',
    'मद्यसेवन',
    'मद्यामोद',
    'मद्याशन',
    'मद्यासक्त',
    'मद्यीय',
    'मद्योन्मत',
    'मद्योन्माद',
    'मद्योन्मादी',
    'मद्रा',
    'मद्रास',
    'मद्रासी',
    'मद्रिता',
    'मध',
    'मधवाजित',
    'मधाभव',
    'मधु',
    'मधुक',
    'मधुकक्ष',
    'मधुकर',
    'मधुकारी',
    'मधुकुंठ',
    'मधुकैटभारि',
    'मधुकोश',
    'मधुगंध',
    'मधुचक्र',
    'मधुच्छदा',
    'मधुज',
    'मधुजा',
    'मधुजित',
    'मधुप',
    'मधुपर्क',
    'मधुपान',
    'मधुपुष्प',
    'मधुप्रिय',
    'मधुबाला',
    'मधुभक्षी',
    'मधुमक्खी',
    'मधुमक्खीपालक',
    'मधुमक्षिकापाल',
    'मधुमती',
    'मधुमय',
    'मधुमान',
    'मधुमालती',
    'मधुमास',
    'मधुमेह',
    'मधुमेही',
    'मधुर',
    'मधुरई',
    'मधुरण',
    'मधुरता',
    'मधुरतापूर्ण',
    'मधुरभाषिणी',
    'मधुरभाषी',
    'मधुरव्य',
    'मधुरस',
    'मधुरसा',
    'मधुरा',
    'मधुराम्ल',
    'मधुराम्लता',
    'मधुरायन',
    'मधुरिका',
    'मधुरिम',
    'मधुरिमा',
    'मधुरीकरण',
    'मधुल',
    'मधुलिका',
    'मधुवन',
    'मधुशय्या',
    'मधुशाला',
    'मधुष्ठील',
    'मधुसख',
    'मधुसूदन',
    'मधुसूदनी',
    'मधुहा',
    'मधूक',
    'मधूकरी',
    'मधूत्सव',
    'मधूरिका',
    'मधूलिका',
    'मधूली',
    'मधौना',
    'मध्य',
    'मध्यंदिन',
    'मध्यक',
    'मध्यकर्ण',
    'मध्यकारता',
    'मध्यकाल',
    'मध्यकालीन',
    'मध्यकालीनतावाद',
    'मध्यगत',
    'मध्यच्छद',
    'मध्यजनस्तर',
    'मध्यजीवी',
    'मध्यनाम',
    'मध्यनेत्र',
    'मध्यपट',
    'मध्यपद',
    'मध्यपूर्व',
    'मध्यपूर्वीय',
    'मध्यफल',
    'मध्यभार',
    'मध्यम',
    'मध्यमक',
    'मध्यमता',
    'मध्यमा',
    'मध्यमान',
    'मध्यमार्गी',
    'मध्यमाहरण',
    'मध्यमिका',
    'मध्यमीय',
    'मध्ययुगवादी',
    'मध्ययुगीन',
    'मध्यरात्रि',
    'मध्यरूप',
    'मध्यवतलता',
    'मध्यवय',
    'मध्यवयस्क',
    'मध्यवर्गीय',
    'मध्यवर्ती',
    'मध्यवित्त',
    'मध्यवित्तता',
    'मध्यस्थ',
    'मध्यस्थता',
    'मध्यस्थहीनता',
    'मध्यस्थानिका',
    'मध्यस्थित',
    'मध्यस्थिति',
    'मध्या',
    'मध्यांणे',
    'मध्यांतर',
    'मध्यांदिन',
    'मध्याकार',
    'मध्यावकाश',
    'मध्यावधि',
    'मध्यावास',
    'मध्याविध्',
    'मध्याह',
    'मध्याह्न',
    'मध्याह्नः',
    'मध्याह्र',
    'मध्यिका',
    'मध्यीय',
    'मध्ये',
    'मध्येष्ठ',
    'मध्वरि',
    'मन',
    'मनः',
    'मनई',
    'मनक',
    'मनकना',
    'मनकला',
    'मनका',
    'मनकामना',
    'मनकूहा',
    'मनकोड़ी',
    'मनगढंत',
    'मनगढ़न्त',
    'मनचला',
    'मनचाही',
    'मनचाहे',
    'मनचीता',
    'मनडे',
    'मनतः',
    'मनधारा',
    'मनन',
    'मननीय',
    'मनपसंद',
    'मनबहलाव',
    'मनभवना',
    'मनभाता',
    'मनभाया',
    'मनभावन',
    'मनभावनी',
    'मनमंथन',
    'मनमजी',
    'मनमस्त',
    'मनमस्ती',
    'मनमानता',
    'मनमाना',
    'मनमानी',
    'मनमाने',
    'मनमीत',
    'मनमुटाव',
    'मनमोदी',
    'मनमोहन',
    'मनमोहिनी',
    'मनमौजी',
    'मनमौजीपन',
    'मनलूजता',
    'मनवांछित',
    'मनवाना',
    'मनशिय',
    'मनश्चक्षु',
    'मनश्चिकित्सक',
    'मनश्चिकित्सा',
    'मनश्चेतना',
    'मनश्शास्त्र',
    'मनसब',
    'मनसबदार',
    'मनसलु',
    'मनसा',
    'मनसिज',
    'मनसिजा',
    'मनसुखा',
    'मनसूब',
    'मनसूबा',
    'मनसूबे',
    'मनसूर',
    'मनसोविकार',
    'मनस्क',
    'मनस्कता',
    'मनस्तंत्र',
    'मनस्ताप',
    'मनस्तुप्ति',
    'मनस्तुष्टि',
    'मनस्पात',
    'मनस्वी',
    'मनहर',
    'मनहरता',
    'मनहार',
    'मनहीनतः',
    'मनहुँ',
    'मनहूस',
    'मनहूसियत',
    'मनहूसी',
    'मना',
    'मनाई',
    'मनाना',
    'मनाने',
    'मनानेवाला',
    'मनापति',
    'मनायन',
    'मनाया',
    'मनायी',
    'मनार्थ',
    'मनाली',
    'मनाही',
    'मनियार',
    'मनिश्चकित्सा',
    'मनिस्वता',
    'मनिहार',
    'मनी',
    'मनीजर',
    'मनीबैग',
    'मनीला',
    'मनीष',
    'मनीषित',
    'मनीषी',
    'मनु',
    'मनुः',
    'मनुआ',
    'मनुख',
    'मनुजी',
    'मनुजोत्तम',
    'मनुष',
    'मनुष्य',
    'मनुष्यता',
    'मनुष्यवत',
    'मनुष्यहारी',
    'मनुष्याकृति',
    'मनुष्यीय',
    'मनुष्योचित',
    'मनुस',
    'मनुहार',
    'मनुहारना',
    'मनूज',
    'मनेंद्रिय',
    'मनेजर',
    'मनो',
    'मनोकल्पना',
    'मनोकल्पित',
    'मनोकामना',
    'मनोकामनापूर्ण',
    'मनोग',
    'मनोगंथित',
    'मनोगत',
    'मनोगम्यता',
    'मनोगुप्त',
    'मनोग्रस्त',
    'मनोग्रस्ति',
    'मनोग्राही',
    'मनोग्राह्य',
    'मनोघात',
    'मनोचा',
    'मनोज',
    'मनोजगत',
    'मनोजया',
    'मनोजयी',
    'मनोजव',
    'मनोज्ञ',
    'मनोतेज',
    'मनोदशा',
    'मनोदाह',
    'मनोदाही',
    'मनोदोष',
    'मनोधर्म',
    'मनोधारा',
    'मनोनयन',
    'मनोनिग्रह',
    'मनोनियोग',
    'मनोनिवेश',
    'मनोनीत',
    'मनोनुकूल',
    'मनोनुभूति',
    'मनोन्माद',
    'मनोबल',
    'मनोबलयुक्त',
    'मनोबली',
    'मनोबाधा',
    'मनोबाध्यता',
    'मनोभंग',
    'मनोभंजन',
    'मनोभाजन',
    'मनोभाव',
    'मनोभावना',
    'मनोभिलषित',
    'मनोभिलाष',
    'मनोभिलाषा',
    'मनोभ्रंश',
    'मनोमंथन',
    'मनोमय',
    'मनोमालिन्य',
    'मनोमुग्धकारी',
    'मनोयायी',
    'मनोयोग',
    'मनोयोगहीनता',
    'मनोयोगी',
    'मनोरंजक',
    'मनोरंजकता',
    'मनोरंजन',
    'मनोरंजनपूर्ण',
    'मनोरंजनप्रिय',
    'मनोरंजनहीन',
    'मनोरंजनहीनता',
    'मनोरंजित',
    'मनोरचना',
    'मनोरथ',
    'मनोरथप्रद',
    'मनोरथी',
    'मनोरम',
    'मनोरमता',
    'मनोरसायन',
    'मनोराग',
    'मनोराज्य',
    'मनोरोग',
    'मनोरोगी',
    'मनोरोचन',
    'मनोरोध',
    'मनोलय',
    'मनोलहरी',
    'मनोलीला',
    'मनोवती',
    'मनोवल्लभा',
    'मनोवांछा',
    'मनोवांछित',
    'मनोविकार',
    'मनोविकृति',
    'मनोविज्ञान',
    'मनोविदलता',
    'मनोविदलतीयता',
    'मनोविनोद',
    'मनोविलास',
    'मनोविश्लेषण',
    'मनोवृति',
    'मनोवृत्ति',
    'मनोवेग',
    'मनोव्यथा',
    'मनोव्यथाकारी',
    'मनोव्यथित',
    'मनोहर',
    'मनोहरता',
    'मनोहारी',
    'मनौअल',
    'मनौती',
    'मनौवल',
    'मन्त्रोच्चार',
    'मन्थर',
    'मन्द',
    'मन्दक',
    'मन्दबुद्धि',
    'मन्दबुद्धिता',
    'मन्दबुद्धी',
    'मन्दाग्नि',
    'मन्दिर',
    'मन्दी',
    'मन्न',
    'मन्नत',
    'मन्मथ',
    'मन्मथालय',
    'मन्य',
    'मन्यु',
    'मन्वंतर',
    'मपा',
    'मपाई',
    'मफलर',
    'मफ़रुरी',
    'मफिस्सल',
    'मम',
    'ममटी',
    'ममता',
    'ममतापूर्ण',
    'ममतामय',
    'ममताहीन',
    'मममी',
    'ममांधता',
    'ममादेश',
    'ममाना',
    'ममिया',
    'ममियाल',
    'ममियौरा',
    'ममिहाल',
    'ममेरा',
    'ममेरी',
    'ममोर्द्घाटन',
    'मम्मा',
    'मय',
    'मयंक',
    'मयः',
    'मयकदा',
    'मयकश',
    'मयख़ाना',
    'मयन',
    'मयनोश',
    'मयनोशी',
    'मयपरस्त',
    'मयस्सर',
    'मयी',
    'मयूख',
    'मयूखी',
    'मयूर',
    'मयूरकंठी',
    'मयूरकेतु',
    'मयूरपंखी',
    'मयूरासन',
    'मयूरी',
    'मयूरेश',
    'मयोचित',
    'मर',
    'मरकज़',
    'मरकज़ी',
    'मरकत',
    'मरकरी',
    'मरक़जी',
    'मरखना',
    'मरघट',
    'मरज़ी',
    'मरजाद',
    'मरजादा',
    'मरजिया',
    'मरजी',
    'मरजीवा',
    'मरण',
    'मरणकारक',
    'मरणशील',
    'मरणशीलता',
    'मरणहीनता',
    'मरणांतक',
    'मरणातीत',
    'मरणान्तक',
    'मरणाभास',
    'मरणासन्न',
    'मरणेच्छुक',
    'मरणेत्तर',
    'मरणैषणा',
    'मरणोन्माद',
    'मरणोन्मुख',
    'मरणोपरांत',
    'मरतबा',
    'मरतबान',
    'मरतबेदार',
    'मरता',
    'मरते',
    'मरतैलिया',
    'मरतैलियापन',
    'मरद',
    'मरदना',
    'मरदानगी',
    'मरदाना',
    'मरदाने',
    'मरदूद',
    'मरन',
    'मरना',
    'मरने',
    'मरप्रसूस',
    'मरभुक्खा',
    'मरभुक्खी',
    'मरमर',
    'मरमराना',
    'मरमराहट',
    'मरमरी',
    'मरम्मत',
    'मरम्मतशुदा',
    'मरम्मती',
    'मरया',
    'मरयाग्राम',
    'मरर',
    'मरसिया',
    'मरसियाख्वानी',
    'मरहबा',
    'मरहम',
    'मरहमपट्टी',
    'मरहमपट्टीकार',
    'मरहला',
    'मरहूम',
    'मरा',
    'मराठी',
    'मरातिब',
    'मरापन',
    'मराल',
    'मरिअल',
    'मरिखंब',
    'मरिच',
    'मरिचिका',
    'मरियम',
    'मरियल',
    'मरियलपन',
    'मरिया',
    'मरी',
    'मरीचम',
    'मरीचि',
    'मरीचिक',
    'मरीचिका',
    'मरीचिमाली',
    'मरीची',
    'मरीज',
    'मरीना',
    'मरीज़',
    'मरु',
    'मरुआ',
    'मरुक',
    'मरुत',
    'मरुत्पट',
    'मरुत्पति',
    'मरुत्पथ',
    'मरुत्सखा',
    'मरुत्सुत',
    'मरुथल',
    'मरुद',
    'मरुदेव',
    'मरुद्रथ',
    'मरुद्वाह',
    'मरुद्विप',
    'मरुद्वीप',
    'मरुधर',
    'मरुप्रिय',
    'मरुबिंब',
    'मरुभूमि',
    'मरुमक्षिका',
    'मरुस्थल',
    'मरुस्वर्ग',
    'मरूत',
    'मरूद्यान',
    'मरूवक',
    'मरे',
    'मरेडाई',
    'मरोड',
    'मरोडते',
    'मरोडना',
    'मरोड़',
    'मरोड़न',
    'मरोड़ना',
    'मरोड़पन',
    'मरोड़फली',
    'मरोड़ी',
    'मरोडा',
    'मर्कट',
    'मर्कटी',
    'मर्कत',
    'मर्करा',
    'मर्करी',
    'मर्चेट',
    'मर्जी',
    'मर्डर',
    'मर्तबा',
    'मर्तबान',
    'मर्त्य',
    'मर्त्यता',
    'मर्थ',
    'मर्द',
    'मर्दक',
    'मर्दन',
    'मर्दना',
    'मर्दानखाना',
    'मर्दानगी',
    'मर्दाना',
    'मर्दानावार',
    'मर्दित',
    'मर्दिनी',
    'मर्दी',
    'मर्दे',
    'मर्म',
    'मर्मघाती',
    'मर्मज्ञ',
    'मर्मज्ञता',
    'मर्मज्ञाता',
    'मर्मभेदी',
    'मर्मर',
    'मर्मराहट',
    'मर्मवेधी',
    'मर्मस्थ',
    'मर्मस्थल',
    'मर्मस्पर्शी',
    'मर्माघात',
    'मर्मातक',
    'मर्मातकेता',
    'मर्मार्ग',
    'मर्माहत',
    'मर्मी',
    'मर्याद',
    'मर्यादा',
    'मर्यादापूर्ण',
    'मर्यादाशील',
    'मर्यादाशीलता',
    'मर्यादाहीन',
    'मर्यादाहीनतः',
    'मर्यादाहीनता',
    'मर्यादित',
    'मर्यादितता',
    'मर्यादी',
    'मर्श',
    'मर्शन',
    'मर्सराइज्ड़',
    'मल',
    'मलंग',
    'मलंगी',
    'मलक',
    'मलकानिया',
    'मलकियत',
    'मलकुल',
    'मलखंभ',
    'मलखम',
    'मलगजा',
    'मलघ्न',
    'मलजल',
    'मलट',
    'मलते',
    'मलत्याग',
    'मलद्वार',
    'मलन',
    'मलना',
    'मलबन्धकारी',
    'मलबा',
    'मलभुक',
    'मलमल',
    'मलमूत्रोत्सर्ग',
    'मलय',
    'मलयज',
    'मलयाचल',
    'मलयानल',
    'मलयानिल',
    'मलयालम',
    'मलयुग',
    'मलराना',
    'मलसा',
    'मलहम',
    'मलहीन',
    'मलहीनता',
    'मला',
    'मलाइक',
    'मलाई',
    'मलाईपन',
    'मलाका',
    'मलाकात',
    'मलाट',
    'मलामत',
    'मलाल',
    'मलावरोध',
    'मलाशय',
    'मलिक',
    'मलिकजादा',
    'मलिकजादी',
    'मलिका',
    'मलिन',
    'मलिनता',
    'मलिनपभ',
    'मलिनमुख',
    'मलिना',
    'मलिनावास',
    'मलिम्लुच',
    'मलिष्ठ',
    'मलीदा',
    'मलीय',
    'मलीहाबादी',
    'मलेच्छ',
    'मलेरिया',
    'मलोत्सर्ग',
    'मलोत्सर्जन',
    'मलोल',
    'मलोला',
    'मल्टीनेशनल',
    'मल्ल',
    'मल्लक',
    'मल्लजोड़',
    'मल्लभूमि',
    'मल्लशाला',
    'मल्लार',
    'मल्लाह',
    'मल्लाही',
    'मल्लाहों',
    'मवक्किल',
    'मवाद',
    'मवेशी',
    'मशक',
    'मशकबीन',
    'मशकूर',
    'मशक्कत',
    'मशक्कती',
    'मशगूल',
    'मशरब',
    'मशरिक',
    'मशरिकी',
    'मशर्रफ',
    'मशवरा',
    'मशविरा',
    'मशहूर',
    'मशहूरी',
    'मशाल',
    'मशीन',
    'मशीनमैन',
    'मशीनरी',
    'मशीनवत',
    'मशीनिस्ट',
    'मशीनी',
    'मशीनीकरण',
    'मशीनीकृत',
    'मशीनें',
    'मशीनों',
    'मश्क',
    'मष्तिक',
    'मस',
    'मसकना',
    'मसखरा',
    'मसखरापन',
    'मसख़रा',
    'मसख़रापन',
    'मसख़री',
    'मसजिद',
    'मसनद',
    'मसनवी',
    'मसना',
    'मसनूई',
    'मसरुफ',
    'मसर्रत',
    'मसल',
    'मसलन',
    'मसलना',
    'मसलहत',
    'मसला',
    'मसलाई',
    'मसले',
    'मसहरी',
    'मसहरीदार',
    'मसा',
    'मसान',
    'मसाना',
    'मसानिया',
    'मसानी',
    'मसालले',
    'मसाला',
    'मसालादान',
    'मसालादानी',
    'मसाले',
    'मसालेदार',
    'मसालों',
    'मसाहत',
    'मसि',
    'मसिजद',
    'मसिजीवी',
    'मसिधान',
    'मसिमयता',
    'मसीका',
    'मसीचूष',
    'मसीत',
    'मसीह',
    'मसीहः',
    'मसीहा',
    'मसीहाई',
    'मसीहादम',
    'मसीही',
    'मसूड़ा',
    'मसूड़े',
    'मसूर',
    'मसूरा',
    'मसूरिका',
    'मसृण',
    'मसृणता',
    'मसे',
    'मसें',
    'मसेः',
    'मसेम',
    'मसेवरा',
    'मसोरा',
    'मसोस',
    'मसोसन',
    'मसोसना',
    'मसौदा',
    'मसौदानवीस',
    'मसौदे',
    'मस्का',
    'मस्जिद',
    'मस्जूद',
    'मस्जूदे',
    'मस्टर्ड',
    'मस्त',
    'मस्तक',
    'मस्तकः',
    'मस्तकवाला',
    'मस्तकहीन',
    'मस्तबा',
    'मस्तमौलापन',
    'मस्तष्क',
    'मस्ताना',
    'मस्तानापन',
    'मस्तिष्क',
    'मस्तिष्कखंड',
    'मस्तिष्कछदि',
    'मस्तिष्कवाला',
    'मस्तिष्कावरण',
    'मस्तिष्कीय',
    'मस्ती',
    'मस्तु',
    'मस्तुली',
    'मस्तूल',
    'मस्सा',
    'मह',
    'महँगा',
    'महँगाई',
    'महँगापन',
    'महँगी',
    'महँगे',
    'महंगा',
    'महंगाई',
    'महंगी',
    'महक',
    'महकता',
    'महकना',
    'महकमा',
    'महकाना',
    'महकूम',
    'महचारी',
    'महज़',
    'महजिद',
    'महत',
    'महतर',
    'महता',
    'महताबी',
    'महतारी',
    'महती',
    'महतो',
    'महत्',
    'महत्कार्य',
    'महत्तम',
    'महत्ता',
    'महत्त्व',
    'महत्त्वकांक्षा',
    'महत्त्वकांक्षी',
    'महत्त्वपूर्ण',
    'महत्त्वहीन',
    'महत्त्वहीनता',
    'महत्त्वाकांक्षा',
    'महत्त्वोन्माद',
    'महत्त्वोन्मादी',
    'महत्व',
    'महत्वन',
    'महत्वपूर्ण',
    'महत्वपूर्णता',
    'महत्वशालिता',
    'महत्वहीन',
    'महत्वहीनता',
    'महत्वाकांक्षी',
    'महत्वानुसार',
    'महत्वोन्मादता',
    'महद',
    'महदर्थ',
    'महदाशय',
    'महदिच्छा',
    'महदूदा',
    'महद्',
    'महफ़िल',
    'महफिल',
    'महफूज',
    'महबूब',
    'महबूबा',
    'महमंद',
    'महमद',
    'महमदी',
    'महमहा',
    'महमहाना',
    'महमिल',
    'महमूदी',
    'महमेज़',
    'महम्मदी',
    'महर',
    'महरम',
    'महरा',
    'महराब',
    'महराबी',
    'महरि',
    'महरुम',
    'महरुमी',
    'महरेटा',
    'महरेटी',
    'महरौली',
    'महर्षि',
    'महल',
    'महलसरा',
    'महले',
    'महलोक',
    'महल्ला',
    'महशर',
    'महसूचना',
    'महसूल',
    'महसूलदार',
    'महसूली',
    'महसूस',
    'महस्वी',
    'महा',
    'महांग',
    'महांड',
    'महांधकार',
    'महांबुज',
    'महांवश',
    'महाउत',
    'महाकंद',
    'महाकक्ष',
    'महाकच्छ',
    'महाकर्म',
    'महाकर्मा',
    'महाकल्प',
    'महाकवि',
    'महाकवियों',
    'महाकांत',
    'महाकाय',
    'महाकायता',
    'महाकाल',
    'महाकाली',
    'महाकालेश्वर',
    'महाकाल्प',
    'महाकाव्य',
    'महाकाव्यकार',
    'महाकाव्योचित',
    'महाकाशीय',
    'महाकुंभ',
    'महाकुल',
    'महाकुल्या',
    'महाकोप',
    'महाकोश',
    'महाक्रतु',
    'महाक्रांति',
    'महाक्ष',
    'महाखंड',
    'महागज',
    'महागजी',
    'महागुरु',
    'महागुरू',
    'महागुल्म',
    'महागौरी',
    'महाग्रंथि',
    'महाग्रह',
    'महाग्रीव',
    'महाघंट',
    'महाघेोष',
    'महाघोष',
    'महाघ्र्य',
    'महाचंडा',
    'महाचंडी',
    'महाचार्य',
    'महाचिंगट',
    'महाछाय',
    'महाजन',
    'महाजनी',
    'महाजयी',
    'महाजल',
    'महाज्ञानी',
    'महाज्योति',
    'महाज्वाल',
    'महातत्व',
    'महातल',
    'महातिक्त',
    'महातेजा',
    'महात्म',
    'महात्मा',
    'महात्माः',
    'महात्म्य',
    'महात्याग',
    'महात्यागी',
    'महादंडधर',
    'महादंष्ट्र',
    'महादशा',
    'महादान',
    'महादानी',
    'महादारु',
    'महादुर्गा',
    'महादेव',
    'महादेवन',
    'महादेवी',
    'महादेश',
    'महाद्युति',
    'महाद्रुम',
    'महाद्वार',
    'महाद्वीप',
    'महाद्वीपीय',
    'महाधनुष',
    'महाधर्माधिकारी',
    'महाधातु',
    'महाधारा',
    'महाधिकार',
    'महाधिक्य',
    'महाधिवक्ता',
    'महाधूर्त',
    'महाध्यक्ष',
    'महान',
    'महानंद',
    'महानगर',
    'महानगरी',
    'महानगरीय',
    'महानगरों',
    'महानजन',
    'महानट',
    'महानता',
    'महानद',
    'महानदी',
    'महानव',
    'महानाद',
    'महानिदेशक',
    'महानिद्रा',
    'महानिधि',
    'महानियंत्रक',
    'महानिरीक्षणालय',
    'महानिर्माणशाला',
    'महानिशा',
    'महानीच',
    'महानील',
    'महानुभाव',
    'महानेत्र',
    'महानेवा',
    'महान्',
    'महान्यायवादी',
    'महापंक',
    'महापंजीयक',
    'महापक्ष',
    'महापथ',
    'महापद्म',
    'महापराध',
    'महापराधिक',
    'महापात्र',
    'महापाद',
    'महापाप',
    'महापापी',
    'महापाप्मा',
    'महापाषाण',
    'महापाषाणयुगीन',
    'महापिंड',
    'महापिता',
    'महापुजारी',
    'महापुर',
    'महापुरुष',
    'महापोप',
    'महापौर',
    'महाप्रज्ञ',
    'महाप्रबंधक',
    'महाप्रबंधकों',
    'महाप्रबनधक',
    'महाप्रभ',
    'महाप्रभु',
    'महाप्ररभ',
    'महाप्ररस्थ',
    'महाप्रलय',
    'महाप्रशासक',
    'महाप्रस्थान',
    'महाप्राण',
    'महाप्राणता',
    'महाप्राणत्व',
    'महाफंल',
    'महाफिज',
    'महाबल',
    'महाबली',
    'महाबालेश्वर',
    'महाबाहू',
    'महाबुद्धि',
    'महाबोधि',
    'महाभाग',
    'महाभागा',
    'महाभारत',
    'महाभारतीकरण',
    'महाभारतीयकृत',
    'महाभिक्षु',
    'महाभियोग',
    'महाभिषव',
    'महाभीम',
    'महाभीष्म',
    'महाभुज',
    'महाभूत',
    'महाभोग',
    'महाभोज',
    'महामंत्र',
    'महामंत्री',
    'महामंदी',
    'महामणि',
    'महामति',
    'महामनस्कता',
    'महामना',
    'महामव',
    'महामहिम',
    'महामहिमता',
    'महामांस',
    'महामाई',
    'महामात्य',
    'महामात्र',
    'महामान्य',
    'महामान्यवर',
    'महामाप',
    'महामाय',
    'महामाया',
    'महामारी',
    'महामार्ग',
    'महामाली',
    'महामुनि',
    'महामूल्य',
    'महामृग',
    'महामेदा',
    'महामेध',
    'महामेधा',
    'महामैया',
    'महामोह',
    'महायज्ञ',
    'महायाजक',
    'महायात्रा',
    'महायान',
    'महायुग',
    'महायुद्घ',
    'महायुद्ध',
    'महायुध',
    'महायोगिनी',
    'महायोगी',
    'महायोजना',
    'महायोद्घा',
    'महायोनि',
    'महार',
    'महारंभ',
    'महारण्य',
    'महारत',
    'महारत्न',
    'महारथी',
    'महारस',
    'महाराज',
    'महाराजा',
    'महाराजाधिराज',
    'महाराजिन',
    'महाराज्ञी',
    'महाराणा',
    'महारात्र',
    'महाराना',
    'महारानी',
    'महारावण',
    'महाराष्ट्र',
    'महाराष्ट्री',
    'महारास',
    'महारुद्र',
    'महारेता',
    'महारोग',
    'महारौद्र',
    'महारौद्री',
    'महार्णव',
    'महार्थ',
    'महार्थता',
    'महार्द्रक',
    'महार्ध',
    'महार्बुद',
    'महाल',
    'महालक्ष्मी',
    'महालय',
    'महालिंग',
    'महालेखा',
    'महालेखाकार',
    'महालेखापरीक्षक',
    'महालौह',
    'महावट',
    'महावत',
    'महावन',
    'महावर',
    'महावर्ण',
    'महावर्षा',
    'महावाक',
    'महावाक्य',
    'महावाग',
    'महावात',
    'महावाद',
    'महावादी',
    'महाविद्या',
    'महाविद्यालय',
    'महाविपत्ति',
    'महाविरति',
    'महाविश्वीय',
    'महाविष',
    'महावीर',
    'महावीर्य',
    'महावीर्या',
    'महावृक्ष',
    'महावृष',
    'महावेग',
    'महावेतनाधिकारी',
    'महावैद्य',
    'महाव्याधि',
    'महाव्योमीय',
    'महाव्रती',
    'महाशंख',
    'महाशक्ति',
    'महाशठ',
    'महाशय',
    'महाशयता',
    'महाशया',
    'महाशास्ता',
    'महाशिक्त',
    'महाशिरा',
    'महाशीर्ष',
    'महाशून्य',
    'महाशोक',
    'महाश्मा',
    'महाश्येन',
    'महाश्रेणी',
    'महाश्रेष्ठि',
    'महाश्वेता',
    'महाषष्ठी',
    'महासंघ',
    'महासंध्या',
    'महासघ',
    'महासचिव',
    'महासती',
    'महासत्व',
    'महासभा',
    'महासमिति',
    'महासम्मेलन',
    'महासर्वेक्षक',
    'महासागर',
    'महासामंत',
    'महासुरी',
    'महासेन',
    'महासेना',
    'महास्कंध',
    'महास्थली',
    'महास्पद',
    'महास्वन',
    'महाह्न',
    'महिं',
    'महिम',
    'महिमा',
    'महिमागान',
    'महिमान्वित',
    'महिमापूर्ण',
    'महिमायुक्त',
    'महिमाशाली',
    'महिमाहीन',
    'महिमाहीनता',
    'महिरावण',
    'महिर्षार्दन',
    'महिला',
    'महिलाओं',
    'महिलाश्रम',
    'महिलोचित',
    'महिष',
    'महिषाक्षी',
    'महिषासुर',
    'महिषी',
    'महिष्ठ',
    'महिष्मती',
    'महिसुर',
    'मही',
    'महीतल',
    'महीधर',
    'महीन',
    'महीना',
    'महीने',
    'महीनों',
    'महीप',
    'महीपाल',
    'महीय',
    'महीयस',
    'महीयान',
    'महीश',
    'महीसुत',
    'महुँ',
    'महुअरी',
    'महुआ',
    'महुआरी',
    'महूरत',
    'महेद्र',
    'महेद्री',
    'महेन्द्र',
    'महेरा',
    'महेश',
    'महेश्वर',
    'महेश्वरी',
    'महेश्वास',
    'महै',
    'महैला',
    'महोगनी',
    'महोजा',
    'महोत्सव',
    'महोत्साह',
    'महोदधि',
    'महोदय',
    'महोदया',
    'महोदर',
    'महोदरी',
    'महोदवी',
    'महोदार',
    'महोद्यम',
    'महोन्नत',
    'महोपाध्याय',
    'महोमिर',
    'महोला',
    'महोश्वरी',
    'महौज',
    'महौषध',
    'महौषधि',
    'मा',
    'माँ',
    'माँग',
    'माँगकर्ता',
    'माँगदेय',
    'माँगना',
    'माँगने',
    'माँगे',
    'माँचना',
    'माँजना',
    'माँजने',
    'माँजा',
    'माँझ',
    'माँझा',
    'माँझी',
    'माँझीगिरी',
    'माँठी',
    'माँड',
    'माँडना',
    'माँड़',
    'माँड़ा',
    'माँड़ी',
    'माँद',
    'माँदगी',
    'माँदर',
    'माँदा',
    'माँदी',
    'माँदे',
    'माँह',
    'मां',
    'मांग',
    'मांगकर्ता',
    'मांगते',
    'मांगना',
    'मांगने',
    'मांगपत्र',
    'मांगलिक',
    'मांगलिकता',
    'मांगल्य',
    'मांगा',
    'मांगानुसार',
    'मांगी',
    'मांगे',
    'मांगें',
    'मांजना',
    'मांजने',
    'मांझी',
    'मांडल',
    'मांडलिक',
    'मांडलिकता',
    'मांडवी',
    'मांडूक्य',
    'मांत्र',
    'मांत्रिक',
    'मांद',
    'मांदा',
    'मांद्य',
    'मांधाताः',
    'मांबा',
    'मांस',
    'मांसः',
    'मांसकारी',
    'मांसप',
    'मांसपेशी',
    'मांसपेशीय',
    'मांसभक्षी',
    'मांसभोजी',
    'मांसमय',
    'मांसयुक्त',
    'मांसयुक्तता',
    'मांसल',
    'मांसलता',
    'मांसहीन',
    'मांसहीनता',
    'मांसाहार',
    'मांसाहारिता',
    'मांसाहारी',
    'मांसिक',
    'मांसीय',
    'मांसोदन',
    'माइक',
    'माइका',
    'माइक्रोफिल्म',
    'माइक्रोफोन',
    'माइक्रोब',
    'माइक्रोवेव',
    'माइक्रोस्कोप',
    'माइक्रोुफोन',
    'माइक्रोफ़ोन',
    'माइन',
    'माइनर',
    'माइनरिटी',
    'माइनिंग',
    'माइम',
    'माई',
    'माउंट',
    'माउज़',
    'माउनटेनियर',
    'माउनटेयरिंग',
    'माकं',
    'माकंने',
    'माकरेल',
    'माक़ूल',
    'माकिेर्टग',
    'माकूल',
    'माकूलियत',
    'माक्र्स',
    'माक्र्सवाद',
    'माक्षिक',
    'माख',
    'माखन',
    'माग',
    'मागिर्क',
    'मागिर्का',
    'मागें',
    'माग्र',
    'माग्रसे',
    'माघ',
    'माघ्यम',
    'माच',
    'माचिंग',
    'माचिश',
    'माचिस',
    'माचिसः',
    'माची',
    'माचेन',
    'माछ',
    'माछी',
    'माजरा',
    'माजा',
    'माजिर्त',
    'माजिर्ता',
    'माजी',
    'माजून',
    'माजूफल',
    'माट',
    'माटी',
    'माटीय',
    'माठ',
    'माठर',
    'माडल',
    'माडवी',
    'माड़ीवाला',
    'माढ़ी',
    'माणव',
    'माणवक',
    'माणविका',
    'माणिक',
    'माणिक्य',
    'मात',
    'मातंग',
    'मातंगी',
    'मातका',
    'मातपुरसी',
    'मातम',
    'मातमकदा',
    'मातमज़दा',
    'मातमदार',
    'मातमसरा',
    'मातमी',
    'मातर',
    'मातरिश्वा',
    'मातलि',
    'मातलिसारथी',
    'मातहत',
    'मातहती',
    'माता',
    'मातातुल्य',
    'मातामह',
    'मातामही',
    'मातायुक्त',
    'माताहीन',
    'माताहीनता',
    'मातु',
    'मातुल',
    'मातुला',
    'मातुलानी',
    'मातुली',
    'मातुलेय',
    'मातृ',
    'मातृक',
    'मातृका',
    'मातृतंत्रात्मक',
    'मातृतंत्रीय',
    'मातृतुल्य',
    'मातृत्व',
    'मातृनाम',
    'मातृभाषा',
    'मातृभूमि',
    'मातृवंशीय',
    'मातृवत',
    'मातृवत्',
    'मातृविहीन',
    'मातृश्वरी',
    'मातृस्थानीय',
    'मातृहत्या',
    'मातृहीन',
    'मातृहीनता',
    'मात्तिर्क',
    'मात्र',
    'मात्रक',
    'मात्रता',
    'मात्रा',
    'मात्राः',
    'मात्राओं',
    'मात्रातीत',
    'मात्रातीतता',
    'मात्रिक',
    'मात्स्यकी',
    'माथ',
    'माथा',
    'माथापच्ची',
    'माथुर',
    'माथे',
    'माद',
    'मादक',
    'मादकता',
    'मादन',
    'मादर',
    'मादरज़ाद',
    'मादरी',
    'मादरे',
    'मादा',
    'मादाम',
    'माद्दा',
    'माद्री',
    'माद्रेय',
    'माधव',
    'माधवी',
    'माधुर',
    'माधुरी',
    'माधुर्य',
    'माधुर्यपूर्ण',
    'माधुर्यूपूर्ण',
    'माधो',
    'माध्य',
    'माध्यम',
    'माध्यमिक',
    'माध्यमिकता',
    'माध्यस्थ',
    'माध्यस्थ्य',
    'माध्यिक',
    'माध्व',
    'माध्वी',
    'माध्वीक',
    'मान',
    'मानक',
    'मानकयुक्त',
    'मानकर',
    'मानकहीन',
    'मानकी',
    'मानकीकरण',
    'मानकीकृत',
    'मानकेतर',
    'मानकों',
    'मानचित्र',
    'मानचित्रकार',
    'मानचित्रण',
    'मानचित्रावली',
    'मानचित्रिका',
    'मानचित्रित',
    'मानता',
    'मानते',
    'मानद',
    'मानदंड',
    'मानदंडों',
    'मानदण्ड',
    'मानदा',
    'मानदान',
    'मानदेय',
    'मानधन',
    'मानना',
    'माननास',
    'माननीय',
    'माननीयता',
    'मानने',
    'मानपत्र',
    'मानभंग',
    'मानमर्दन',
    'मानरंधरा',
    'मानव',
    'मानवकाय',
    'मानवकृति',
    'मानवजाति',
    'मानवजातिविज्ञानी',
    'मानवता',
    'मानवतावादी',
    'मानवती',
    'मानवत्वारोपण',
    'मानवद्वेष',
    'मानवद्वेषवश',
    'मानवद्वेषी',
    'मानवन',
    'मानवनाम',
    'मानवरूपी',
    'मानववाद',
    'मानवविज्ञान',
    'मानवविज्ञानी',
    'मानवाकार',
    'मानवाकृति',
    'मानवाधिकार',
    'मानविकता',
    'मानविकी',
    'मानविद',
    'मानवी',
    'मानवीकरण',
    'मानवीय',
    'मानवीयता',
    'मानवीयतापूर्ण',
    'मानवेंद्र',
    'मानवेतर',
    'मानवेरद',
    'मानवोचित',
    'मानवोचितता',
    'मानस',
    'मानसचारी',
    'मानसज',
    'मानसवाद',
    'मानसिक',
    'मानसिकता',
    'मानसी',
    'मानसूत्र',
    'मानसून',
    'मानहानि',
    'मानहानिपूर्ण',
    'मानहीन',
    'मानहूँ',
    'माना',
    'मानांतर',
    'मानापन',
    'मानार्थ',
    'मानिंद',
    'मानिक',
    'मानित',
    'मानिनी',
    'मानिसक',
    'मानी',
    'मानीटर',
    'मानुष',
    'मानुषिक',
    'मानुषिकता',
    'मानुषी',
    'मानुष्य',
    'मानुस',
    'माने',
    'मानेंगे',
    'मानो',
    'मानों',
    'मान्य',
    'मान्यता',
    'मान्यताप्राप्त',
    'मान्यताहीन',
    'मान्यवर',
    'माप',
    'मापक',
    'मापकर्ता',
    'मापक्रम',
    'मापजोख',
    'मापतंत्र',
    'मापतौल',
    'मापदंड',
    'मापदण्ड',
    'मापन',
    'मापनदण्ड',
    'मापना',
    'मापनी',
    'मापने',
    'मापमान',
    'मापा',
    'मापांक',
    'मापातीत',
    'मापित',
    'मापित्र',
    'मापी',
    'मापैड',
    'माफ',
    'माफ़िक',
    'माफ़िक़',
    'माफ़ी',
    'माफ़ीदार',
    'माफ़ीनामा',
    'माफी',
    'मामल',
    'मामला',
    'मामलात',
    'मामलादानी',
    'मामले',
    'मामलों',
    'मामस',
    'मामा',
    'मामिर्क',
    'मामिर्कता',
    'मामी',
    'मामूल',
    'मामूली',
    'माय',
    'मायँ',
    'मायक',
    'मायका',
    'मायके',
    'मायना',
    'मायने',
    'मायल',
    'माया',
    'मायाः',
    'मायाकार',
    'मायाग्रस्त',
    'मायाजाल',
    'मायाजालिक',
    'मायादर्शी',
    'मायापटू',
    'मायापूर्ण',
    'मायाबद्घ',
    'मायामय',
    'मायावती',
    'मायावाद',
    'मायावान',
    'मायाविता',
    'मायाविनी',
    'मायावी',
    'मायासुत',
    'मायाहीन',
    'मायिक',
    'मायी',
    'मायूस',
    'मायूसी',
    'मार',
    'मारक',
    'मारकता',
    'मारकर',
    'मारकाट',
    'मारकीन',
    'मारकूट',
    'मारके',
    'मारगुर्जा',
    'मारजित',
    'मारण',
    'मारणीय',
    'मारणेच्छुक',
    'मारते',
    'मारतौल',
    'मारधाड',
    'मारना',
    'मारनाः',
    'मारने',
    'मारपीट',
    'मारफ़ीन',
    'मारफीन',
    'मारलीन',
    'मारवा',
    'मारवाडी',
    'मारा',
    'मारामार',
    'मारामारी',
    'मारित',
    'मारिया',
    'मारिषाः',
    'मारी',
    'मारीच',
    'मारीची',
    'मारीवाना',
    'मारु',
    'मारुत',
    'मारुति',
    'मारुपन',
    'मारुफ',
    'मारे',
    'मार्क',
    'मार्कडेय',
    'मार्कर',
    'मार्का',
    'मार्किग',
    'मार्किट',
    'मार्केट',
    'मार्केटर',
    'मार्केटिंग',
    'मार्ग',
    'मार्गःभेंट',
    'मार्गच्युत',
    'मार्गच्युतता',
    'मार्गज्ञ',
    'मार्गण',
    'मार्गणा',
    'मार्गदर्शक',
    'मार्गदर्शन',
    'मार्गदर्शी',
    'मार्गदृष्टा',
    'मार्गनिर्देशिका',
    'मार्गपति',
    'मार्गबंधु',
    'मार्गभ्रष्ट',
    'मार्गभ्रष्टता',
    'मार्गभ्रांत',
    'मार्गया',
    'मार्गवती',
    'मार्गविहीन',
    'मार्गशीर्ष',
    'मार्गशील',
    'मार्गशीष',
    'मार्गस्थ',
    'मार्गस्थिति',
    'मार्गहीन',
    'मार्गहीनता',
    'मार्गाधिकार',
    'मार्गानुगमन',
    'मार्गानुसरण',
    'मार्गानुसार',
    'मार्गाली',
    'मार्गावरोधन',
    'मार्गासारित',
    'मार्गी',
    'मार्गीय',
    'मार्च',
    'मार्चर्',
    'मार्जक',
    'मार्जन',
    'मार्जनीभूषणा',
    'मार्जार',
    'मार्जारकंठ',
    'मार्जारी',
    'मार्जिन',
    'मार्ट',
    'मार्टिन',
    'मार्दव',
    'मार्फत',
    'मार्बल',
    'मार्मिक',
    'मार्मिकता',
    'मार्शल',
    'मार्स',
    'माल',
    'मालक',
    'मालकिन',
    'मालकौस',
    'मालखंभ',
    'मालखाना',
    'मालगाड़ी',
    'मालगाडी',
    'मालगुजार',
    'मालगुजारी',
    'मालगुज़ारी',
    'मालगोदाम',
    'मालघर',
    'मालडिब्बा',
    'मालती',
    'मालदह',
    'मालदार',
    'मालद्वीप',
    'मालद्वीपी',
    'मालद्वीपीय',
    'मालपुआ',
    'मालभाड़ा',
    'मालम',
    'मालमखी',
    'मालवा',
    'मालवाडी',
    'मालवाही',
    'मालवीय',
    'मालसूची',
    'मालसूचीकार',
    'माला',
    'मालाः',
    'मालाकार',
    'मालाधर',
    'मालामाल',
    'मालार्पण',
    'मालि',
    'मालिक',
    'मालिका',
    'मालिकाना',
    'मालिकी',
    'मालिकों',
    'मालिन',
    'मालिनी',
    'मालिया',
    'मालियाना',
    'मालिश',
    'मालिशिया',
    'माली',
    'मालीखूलिया',
    'मालीप',
    'मालुम',
    'मालूम',
    'मालूमात',
    'माले',
    'मालेया',
    'मालोत्सर्ग',
    'मालोल',
    'माल्ट',
    'माल्डिंग',
    'माल्य',
    'माल्यग्रंथ',
    'माल्यवान',
    'माल्या',
    'माल्यापर्ण',
    'माल्यार्पण',
    'माल्लवी',
    'मावस',
    'मावा',
    'माश',
    'माशा',
    'माशाः',
    'माशूक',
    'माशूका',
    'माशूकी',
    'माशूके',
    'माश्की',
    'माष',
    'मास',
    'मासः',
    'मासमान',
    'मासर',
    'मासिक',
    'मासी',
    'मासीन',
    'मासीय',
    'मासूम',
    'मास्क',
    'मास्ट',
    'मास्टर',
    'मास्तिष्क',
    'मास्य',
    'मास्से',
    'माह',
    'माहँ',
    'माहक',
    'माहताब',
    'माहताबी',
    'माहनामा',
    'माहवार',
    'माहवारी',
    'माहाना',
    'माहाल्य',
    'माहाल्यपूर्ण',
    'माहाल्यहीन',
    'माहि',
    'माहिर',
    'माही',
    'माहीगिरी',
    'माहीगीर',
    'माहीपुश्त',
    'माहुर',
    'माहेंन्दी',
    'माहेन्द्र',
    'माहेन्द्री',
    'माहेय',
    'माहेयी',
    'माहेश',
    'माहेश्वर',
    'माहेश्वरी',
    'माहौल',
    'माफ़',
    'माफ़ी',
    'मिंक',
    'मिंगी',
    'मिंजाई',
    'मिंट',
    'मिंडाई',
    'मिंबर',
    'मिआऊं',
    'मिक',
    'मिकराज',
    'मिक़दार',
    'मिक्श्चर',
    'मिक्स',
    'मिक्सर',
    'मिक्सी',
    'मिक्स्ड',
    'मिग्रा',
    'मिचकन',
    'मिचकाना',
    'मिचन',
    'मिचना',
    'मिचमिचाहट',
    'मिचर',
    'मिचलाने',
    'मिचलाहट',
    'मिचली',
    'मिचा',
    'मिचौनी',
    'मिचौली',
    'मिज़',
    'मिज़राब',
    'मिज़ाज',
    'मिज़ाजपुरसी',
    'मिज़ाजपुर्स',
    'मिज़ाजी',
    'मिज़ोरम',
    'मिजाज',
    'मिजाजव्यक्ति',
    'मिजाजी',
    'मिजाज़',
    'मिजो',
    'मिट',
    'मिटटी',
    'मिटना',
    'मिटने',
    'मिटा',
    'मिटाई',
    'मिटाऊ',
    'मिटाना',
    'मिटाने',
    'मिटापन',
    'मिटिया',
    'मिट्टी',
    'मिट्टीः',
    'मिट्टीमय',
    'मिट्ठी',
    'मिट्ठू',
    'मिठ',
    'मिठबोला',
    'मिठबोलापन',
    'मिठबोली',
    'मिठाई',
    'मिठाईः',
    'मिठास',
    'मिड',
    'मिडवाइफ',
    'मिडवाइफ़',
    'मिडशिपमैन',
    'मिड़ाना',
    'मिडाई',
    'मिडिल',
    'मिडी',
    'मित',
    'मितता',
    'मितभाषी',
    'मितवा',
    'मितवाक्य',
    'मितव्यय',
    'मितव्ययिता',
    'मितव्ययी',
    'मिताई',
    'मिताहार',
    'मिताहारी',
    'मिति',
    'मिती',
    'मितीकाटा',
    'मितीकाटे',
    'मित्तल',
    'मित्र',
    'मित्रघात',
    'मित्रता',
    'मित्रताः',
    'मित्रतापूर्ण',
    'मित्रतापूर्णता',
    'मित्रत्याग',
    'मित्रद्राह',
    'मित्रप्रिय',
    'मित्रभाव',
    'मित्रयुक्त',
    'मित्रवत',
    'मित्रवर',
    'मित्रवान',
    'मित्रवृंदा',
    'मित्रहानि',
    'मित्रहीन',
    'मित्रहीनता',
    'मित्रा',
    'मित्रार्थ',
    'मित्रालाप',
    'मित्रावरुण',
    'मित्रावसू',
    'मित्रीय',
    'मित्रों',
    'मित्रोचित',
    'मिथक',
    'मिथककार',
    'मिथकविद',
    'मिथिला',
    'मिथिलेश',
    'मिथिलेश्वर',
    'मिथुन',
    'मिथ्यतः',
    'मिथ्यता',
    'मिथ्या',
    'मिथ्याकरण',
    'मिथ्याक्रमण',
    'मिथ्याचार',
    'मिथ्यापन',
    'मिथ्यापवाद',
    'मिथ्याप्रतिज्ञ',
    'मिथ्याभाषी',
    'मिथ्याभास',
    'मिथ्याभियोग',
    'मिथ्यारोगी',
    'मिथ्यावाद',
    'मिथ्यावादिता',
    'मिथ्यावादी',
    'मिध्यका',
    'मिध्यत्र',
    'मिध्वजा',
    'मिनट',
    'मिनटः',
    'मिनमिन',
    'मिनमिनाना',
    'मिनमिनाहट',
    'मिनरल',
    'मिनसा',
    'मिनहा',
    'मिनिएचर',
    'मिनिट',
    'मिनिट्स',
    'मिनिस्टर',
    'मिनिस्टरी',
    'मिनिस्ट्री',
    'मिनी',
    'मिन्नत',
    'मिमिक',
    'मिमियाना',
    'मिमियाने',
    'मिमियाया',
    'मिमियाहट',
    'मियाँ',
    'मियाँऊ',
    'मियां',
    'मियाद',
    'मियादी',
    'मियान',
    'मियानदारी',
    'मियाना',
    'मियानी',
    'मिरगी',
    'मिरच',
    'मिरची',
    'मिरज़ा',
    'मिरज़ाई',
    'मिरजा',
    'मिरजान',
    'मिरदंग',
    'मिरयाग्राम',
    'मिरर्च',
    'मिरिच',
    'मिर्च',
    'मिर्चदान',
    'मिर्चदानी',
    'मिर्चदार',
    'मिर्चीला',
    'मिर्ज़ा',
    'मिल',
    'मिलकर',
    'मिलकीयत',
    'मिलजुल',
    'मिलता',
    'मिलती',
    'मिलते',
    'मिलन',
    'मिलनसार',
    'मिलनसारिता',
    'मिलनसारी',
    'मिलना',
    'मिलनाकांक्षा',
    'मिलनी',
    'मिलने',
    'मिलनेवाला',
    'मिलमिली',
    'मिलवा',
    'मिलवाई',
    'मिलवाना',
    'मिला',
    'मिलाई',
    'मिलाकर',
    'मिलाजुला',
    'मिलाजुलापन',
    'मिलाद',
    'मिलादुन्नबी',
    'मिलान',
    'मिलानकर्ता',
    'मिलानकर्त्ता',
    'मिलाना',
    'मिलाने',
    'मिलाप',
    'मिलापन',
    'मिलापी',
    'मिलाया',
    'मिलावट',
    'मिलावटी',
    'मिलावा',
    'मिलिंद',
    'मिलिए',
    'मिलिग्राम',
    'मिलिबार',
    'मिलिमीटर',
    'मिलियन',
    'मिलिलीटर',
    'मिली',
    'मिलीमीटर',
    'मिले',
    'मिलें',
    'मिलेगा',
    'मिलेगी',
    'मिलौनी',
    'मिल्क',
    'मिल्कियत',
    'मिल्की',
    'मिल्कीयत',
    'मिल्ल',
    'मिल्लका',
    'मिल्लकार्जुन',
    'मिल्लकार्जुनेश्वर',
    'मिल्लत',
    'मिल्लनाथ',
    'मिशन',
    'मिशनरी',
    'मिश्नह',
    'मिश्ना',
    'मिश्र',
    'मिश्रक',
    'मिश्रज',
    'मिश्रण',
    'मिश्रणहीनता',
    'मिश्रणीय',
    'मिश्रणीयता',
    'मिश्रणों',
    'मिश्रधन',
    'मिश्रातु',
    'मिश्रामिश्र',
    'मिश्रायस',
    'मिश्रित',
    'मिश्रितता',
    'मिश्रितभाषा',
    'मिश्रिताभास',
    'मिश्री',
    'मिश्रेया',
    'मिश्वरा',
    'मिष',
    'मिष्ट',
    'मिष्टता',
    'मिष्टभाषिणी',
    'मिष्टभाषी',
    'मिष्टात्र',
    'मिष्ठान्न',
    'मिस',
    'मिसकीन',
    'मिसकीनी',
    'मिसरा',
    'मिसरी',
    'मिसाइल',
    'मिसाल',
    'मिसिल',
    'मिसिलख्वाह',
    'मिस्जद',
    'मिस्ट',
    'मिस्टर',
    'मिस्तर',
    'मिस्तरी',
    'मिस्तरीखाना',
    'मिस्तरीगीरी',
    'मिस्त्री',
    'मिस्मार',
    'मिस्र',
    'मिस्ल',
    'मिस्सा',
    'मिस्सी',
    'मिहचना',
    'मिहिका',
    'मिहिर',
    'मिहिरवली',
    'मिहिराण',
    'मिहिराभ',
    'मिज़ाज',
    'मिज़ाज़',
    'मींग',
    'मींगी',
    'मींच',
    'मींज',
    'मींजना',
    'मींडना',
    'मींमीं',
    'मींह',
    'मीआद',
    'मीआदी',
    'मीगनी',
    'मीजना',
    'मीजान',
    'मीट',
    'मीटर',
    'मीटरः',
    'मीटरी',
    'मीटिंग',
    'मीट्रिक',
    'मीठा',
    'मीठापन',
    'मीठी',
    'मीड',
    'मीड़',
    'मीडियम',
    'मीत',
    'मीता',
    'मीथेन',
    'मीन',
    'मीनकेतु',
    'मीनगंधा',
    'मीनध्वज',
    'मीननयना',
    'मीनपंख',
    'मीनपक्ष',
    'मीना',
    'मीनाकार',
    'मीनाक्ष',
    'मीनाक्षी',
    'मीनागर',
    'मीनानयन',
    'मीनार',
    'मीनालय',
    'मीनिका',
    'मीनू',
    'मीमांसक',
    'मीमांसा',
    'मीमांसाकार',
    'मीमांसात्मक',
    'मीमियोग्राफ',
    'मीमो',
    'मीयाद',
    'मीयादी',
    'मीर',
    'मीरा',
    'मीरास',
    'मीरी',
    'मीरे',
    'मील',
    'मीलः',
    'मीलन',
    'मीलमापी',
    'मीलित',
    'मीसोट्रोन',
    'मीसोन',
    'मीह',
    'मुँगरा',
    'मुँगरी',
    'मुँगौरा',
    'मुँडना',
    'मुँडा',
    'मुँडाई',
    'मुँडासा',
    'मुँडिया',
    'मुँडेर',
    'मुँडेरा',
    'मुँडेरी',
    'मुँडो',
    'मुँदन',
    'मुँदना',
    'मुँदरी',
    'मुँदा',
    'मुँदाई',
    'मुँह',
    'मुँहचढ़ा',
    'मुँहचढ़ापन',
    'मुँहचोर',
    'मुँहछुट',
    'मुँहजबानी',
    'मुँहजोर',
    'मुँहजोरी',
    'मुँहतोड़',
    'मुँहदेखा',
    'मुँहदेखी',
    'मुँहनाल',
    'मुँहफट',
    'मुँहबँधा',
    'मुँहबंद',
    'मुँहबोली',
    'मुँहमाँगा',
    'मुँहमुँह',
    'मुँहलगा',
    'मुँहासा',
    'मुंज',
    'मुंड',
    'मुंडक',
    'मुंडन',
    'मुंडमाला',
    'मुंडमाली',
    'मुंडलाना',
    'मुंडहीन',
    'मुंडा',
    'मुंडाई',
    'मुंडी',
    'मुंडू',
    'मुंडेर',
    'मुंडेरी',
    'मुंतखब',
    'मुंताजिर',
    'मुंबई',
    'मुंशी',
    'मुंशीगिरी',
    'मुंह',
    'मुंहजोर',
    'मुंहतोड़',
    'मुंहबोला',
    'मुंहभराई',
    'मुंहासा',
    'मुअज्जम',
    'मुअज्जमा',
    'मुअज्ज़िन',
    'मुअत्तल',
    'मुअत्तली',
    'मुअम्मा',
    'मुअल्ला',
    'मुआ',
    'मुआफ',
    'मुआफिक',
    'मुआफिकत',
    'मुआयना',
    'मुआवजा',
    'मुआवज़ा',
    'मुआहिदा',
    'मुई',
    'मुकदमा',
    'मुकदमेबाज',
    'मुकदमेबाज़',
    'मुकदमेबाज़ी',
    'मुकद्दम',
    'मुकद्दमा',
    'मुकद्दर',
    'मुकद्दस',
    'मुकना',
    'मुकम्मल',
    'मुकर',
    'मुकरन',
    'मुकरना',
    'मुकरनी',
    'मुकरने',
    'मुकर्रमी',
    'मुकर्रर',
    'मुकलाया',
    'मुक़दमा',
    'मुक़ाम',
    'मुका',
    'मुकादम',
    'मुकाबला',
    'मुकाबले',
    'मुकाबल्तन',
    'मुकाबिल',
    'मुकाबिला',
    'मुकाम',
    'मुकामी',
    'मुकियाना',
    'मुकिर',
    'मुकिर्र',
    'मुकुंद',
    'मुकुट',
    'मुकुटधर',
    'मुकुटधारी',
    'मुकुटेश्वर',
    'मुकुर',
    'मुकुल',
    'मुकुलन',
    'मुकुलित',
    'मुकुलिततः',
    'मुक्का',
    'मुक्की',
    'मुक्केबाज',
    'मुक्केबाजी',
    'मुक्केबाज़',
    'मुक्कैश',
    'मुक्त',
    'मुक्तक',
    'मुक्तकच्छ',
    'मुक्तकरण',
    'मुक्तकेशी',
    'मुक्तता',
    'मुक्तद्वार',
    'मुक्तवसन',
    'मुक्तवेणी',
    'मुक्तशृंग',
    'मुक्तसंग',
    'मुक्तहस्त',
    'मुक्तहस्तता',
    'मुक्तहासी',
    'मुक्ता',
    'मुक्तांंबर',
    'मुक्तांश',
    'मुक्ताक्षार',
    'मुक्तागार',
    'मुक्तात्मा',
    'मुक्तादाम',
    'मुक्ताधूसर',
    'मुक्ताप्रभु',
    'मुक्ताभ',
    'मुक्तावली',
    'मुक्तावास',
    'मुक्तासुक्ति',
    'मुक्ताहार',
    'मुक्ति',
    'मुक्तिवाद',
    'मुक्तिवादी',
    'मुक्तेश्वर',
    'मुख',
    'मुखः',
    'मुखकृति',
    'मुखचपल',
    'मुखज',
    'मुखड़ा',
    'मुखतार',
    'मुखतारनामा',
    'मुखताल',
    'मुखदूषण',
    'मुखनासिका',
    'मुखपट',
    'मुखपत्र',
    'मुखपाक',
    'मुखप्रिय',
    'मुखबिर',
    'मुखबिरी',
    'मुखमण्डल',
    'मुखर',
    'मुखरता',
    'मुखरागकार',
    'मुखरित',
    'मुखरी',
    'मुखर्जी',
    'मुखलफ़त',
    'मुखलांगल',
    'मुखवाला',
    'मुखविकृति',
    'मुखशीर्षक',
    'मुखशोधन',
    'मुखांकन',
    'मुखांकित',
    'मुखाकृति',
    'मुखाग्र',
    'मुखाटा',
    'मुखातिब',
    'मुखारबिंद',
    'मुखालिफ़',
    'मुखावरण',
    'मुखिका',
    'मुखिया',
    'मुखियापन',
    'मुखी',
    'मुखीय',
    'मुखेतर',
    'मुखौटा',
    'मुख्तलिफ़',
    'मुख्तसर',
    'मुख्तार',
    'मुख्तारनामा',
    'मुख्य',
    'मुख्यत',
    'मुख्यमंत्री',
    'मुख्याध्यापक',
    'मुख्यार्थ',
    'मुख्यार्थक',
    'मुख्यालय',
    'मुगदर',
    'मुगरा',
    'मुगरी',
    'मुगल',
    'मुग़',
    'मुग़ल',
    'मुग़ीर्ख़ाना',
    'मुग़ौं',
    'मुग्दर',
    'मुग्दरक',
    'मुग्ध',
    'मुग्धता',
    'मुग्धा',
    'मुचकुंद',
    'मुचलका',
    'मुचामुच',
    'मुचिर',
    'मुचुकुंद',
    'मुच्छ',
    'मुच्छड़',
    'मुच्छल',
    'मुच्छैल',
    'मुछंदर',
    'मुछियल',
    'मुजफ्फरपुर',
    'मुजरा',
    'मुजरिम',
    'मुजस्समा',
    'मुजायका',
    'मुजारा',
    'मुजाहिद',
    'मुजिस्सम',
    'मुजुगमना',
    'मुझ',
    'मुझको',
    'मुझसे',
    'मुझे',
    'मुटरी',
    'मुटाई',
    'मुट्ठा',
    'मुट्ठियों',
    'मुट्ठी',
    'मुठ',
    'मुठभेड',
    'मुठभेड़',
    'मुठिया',
    'मुडना',
    'मुडमाला',
    'मुडवाँ',
    'मुड़',
    'मुड़कर',
    'मुड़न',
    'मुड़नशील',
    'मुड़ना',
    'मुड़वाँ',
    'मुड़ा',
    'मुड़ाई',
    'मुड़ातुड़ापन',
    'मुड़ापन',
    'मुड़ाव',
    'मुड़िया',
    'मुड़ी',
    'मुड़े',
    'मुडित',
    'मुडिता',
    'मुड्ढा',
    'मुण्डकी',
    'मुण्डन',
    'मुतअिल्लक़',
    'मुतफ़रिंक',
    'मुतबन्ना',
    'मुतवल्ली',
    'मुताफिक',
    'मुताबिक',
    'मुतालबा',
    'मुताह',
    'मुतिस्सर',
    'मुत्ति',
    'मुत्रालय',
    'मुत्राशल',
    'मुत्रेद्रिय',
    'मुद',
    'मुदगल',
    'मुदमुख़्तारी',
    'मुदर्रिस',
    'मुदवंत',
    'मुदा',
    'मुदांकित',
    'मुदित',
    'मुदिर',
    'मुद्ग',
    'मुद्गर',
    'मुद्दई',
    'मुद्दत',
    'मुद्दती',
    'मुद्दा',
    'मुद्दालेह',
    'मुद्दी',
    'मुद्दे',
    'मुद्दों',
    'मुद्र',
    'मुद्रक',
    'मुद्रण',
    'मुद्रणकर्ता',
    'मुद्रणाक्षर',
    'मुद्रणाक्षरों',
    'मुद्रणालय',
    'मुद्रलेख',
    'मुद्रा',
    'मुद्रांक',
    'मुद्रांकक',
    'मुद्रांकन',
    'मुद्रांकनवादी',
    'मुद्रांकित',
    'मुद्रांगुलीयक',
    'मुद्रांतरण',
    'मुद्रांतरणीय',
    'मुद्राएं',
    'मुद्राओं',
    'मुद्राक्षर',
    'मुद्रागत',
    'मुद्रारहित',
    'मुद्रावत',
    'मुद्राशास्त्र',
    'मुद्राशास्त्री',
    'मुद्राशीर्ष',
    'मुद्रास्फीति',
    'मुद्रास्फीतिजनित',
    'मुद्रिका',
    'मुद्रिणी',
    'मुद्रित',
    'मुद्रित्र',
    'मुद्रीकरण',
    'मुद्रीकृत',
    'मुनंग',
    'मुनकर',
    'मुनकिर',
    'मुनक्',
    'मुनक्का',
    'मुनहस्सर',
    'मुनादी',
    'मुनाफ़ाखोर',
    'मुनाफ़ाखोरी',
    'मुनाफा',
    'मुनाफाखोरी',
    'मुनाफे',
    'मुनासिब',
    'मुनाफ़ा',
    'मुनि',
    'मुनिः',
    'मुनिया',
    'मुनियाँ',
    'मुनिवर',
    'मुनिवृत्ति',
    'मुनिश्रेष्ठ',
    'मुनींद्र',
    'मुनीम',
    'मुनीमी',
    'मुनीय',
    'मुनीश',
    'मुनीश्वर',
    'मुन्कर',
    'मुन्नग',
    'मुन्ना',
    'मुन्नू',
    'मुन्य',
    'मुन्शी',
    'मुफलिस',
    'मुफलिसी',
    'मुफ़लर',
    'मुफीद',
    'मुफ्त',
    'मुफ्ती',
    'मुबलग़',
    'मुबारक',
    'मुबारकबाद',
    'मुबारकबादी',
    'मुबालगा',
    'मुबाह',
    'मुबाहिसा',
    'मुब्तला',
    'मुमकिन',
    'मुमनिअत',
    'मुमनियत',
    'मुमुक्षु',
    'मुमूर्षार्',
    'मुमूर्षु',
    'मुर',
    'मुरई',
    'मुरक',
    'मुरकन',
    'मुरकना',
    'मुरकी',
    'मुरगा',
    'मुरगाः',
    'मुरगाबी',
    'मुरगी',
    'मुरज',
    'मुरजित',
    'मुरझन',
    'मुरझा',
    'मुरझाना',
    'मुरझाया',
    'मुरझायापन',
    'मुरझावन',
    'मुरदा',
    'मुरदाः',
    'मुरदाखोर',
    'मुरदाघर',
    'मुरदार',
    'मुरदासग',
    'मुरब्बा',
    'मुरब्बी',
    'मुरमुर',
    'मुरमुरा',
    'मुरमुराहट',
    'मुरला',
    'मुरलिया',
    'मुरली',
    'मुरलीधर',
    'मुरशिद',
    'मुरा',
    'मुराद',
    'मुरादाबाद',
    'मुरादी',
    'मुराफा',
    'मुरार',
    'मुरारि',
    'मुरारी',
    'मुरि',
    'मुरी',
    'मुरीद',
    'मुरीदी',
    'मुरुधर',
    'मुरेठा',
    'मुरेरा',
    'मुरौवत',
    'मुरौवती',
    'मुर्की',
    'मुर्ख',
    'मुर्खतापूर्ण',
    'मुर्ग',
    'मुर्गा',
    'मुर्गाबी',
    'मुर्गियों',
    'मुर्गी',
    'मुर्गीखाना',
    'मुर्गीघर',
    'मुर्गीपालन',
    'मुर्गे',
    'मुर्छा',
    'मुर्झाया',
    'मुर्दघाट',
    'मुर्दनी',
    'मुर्दा',
    'मुर्दादिल',
    'मुर्दादिली',
    'मुर्दासंख',
    'मुर्दासिंख',
    'मुर्दासिंधी',
    'मुर्दे',
    'मुर्रा',
    'मुर्रापा',
    'मुर्शिद',
    'मुलकट',
    'मुलजिम',
    'मुलतानी',
    'मुलम्मा',
    'मुलहठी',
    'मुलाकात',
    'मुलाकाती',
    'मुलाजमत',
    'मुलाजिम',
    'मुलायम',
    'मुलायमपन',
    'मुलायमियत',
    'मुलाहजा',
    'मुलाक़ाती',
    'मुलेठी',
    'मुल्क',
    'मुल्कगिरी',
    'मुल्कगीर',
    'मुल्करानी',
    'मुल्की',
    'मुल्तवी',
    'मुल्तानी',
    'मुल्ला',
    'मुल्लावाद',
    'मुल्लाह',
    'मुवक्किल',
    'मुवाफिक़',
    'मुशरिक',
    'मुशल',
    'मुशायरा',
    'मुश्क',
    'मुश्किल',
    'मुश्किलों',
    'मुश्त',
    'मुश्तरका',
    'मुश्ताक',
    'मुषक',
    'मुषल',
    'मुषली',
    'मुष्क',
    'मुष्कहीन',
    'मुष्टि',
    'मुष्टिका',
    'मुष्टिकांतक',
    'मुसंग',
    'मुसका',
    'मुसटंडा',
    'मुसटी',
    'मुसना',
    'मुसन्ना',
    'मुसम्मात',
    'मुसलमान',
    'मुसलमानी',
    'मुसलमानों',
    'मुसलसल',
    'मुसलायुध',
    'मुसलिम',
    'मुसलिमीन',
    'मुसल्ला',
    'मुसा',
    'मुसातुसापन',
    'मुसापन',
    'मुसाफिर',
    'मुसाफिरत',
    'मुसाहब',
    'मुसाहबत',
    'मुसाहिब',
    'मुसाहिबत',
    'मुसाहिबी',
    'मुसिव्वर',
    'मुसिव्वरी',
    'मुसीबत',
    'मुसीबतज़दगी',
    'मुसीबतज़दा',
    'मुसेंगन',
    'मुसोलिनीवाद',
    'मुस्कराते',
    'मुस्कराना',
    'मुस्कराहट',
    'मुस्काता',
    'मुस्कान',
    'मुस्काना',
    'मुस्कानि',
    'मुस्क्यान',
    'मुस्तकबिल',
    'मुस्तकिल',
    'मुस्तफा',
    'मुस्तहक',
    'मुस्ता',
    'मुस्तैद',
    'मुस्तैदी',
    'मुस्तौफी',
    'मुस्लिम',
    'मुहँ',
    'मुहतरम',
    'मुहतरमा',
    'मुहताज',
    'मुहताजी',
    'मुहतोड',
    'मुहब्बत',
    'मुहब्बती',
    'मुहम्मद',
    'मुहम्मदः',
    'मुहम्मदी',
    'मुहर',
    'मुहरकन',
    'मुहरबंद',
    'मुहरबंदी',
    'मुहरा',
    'मुहराबाज़ी',
    'मुहर्रम',
    'मुहर्रमः',
    'मुहर्रमी',
    'मुहर्रिर',
    'मुहर्रिरी',
    'मुहल्ला',
    'मुहाजिर',
    'मुहाना',
    'मुहार',
    'मुहाल',
    'मुहावरा',
    'मुहावरे',
    'मुहासिब',
    'मुहासिर',
    'मुहिब्बे',
    'मुहिम',
    'मुहिस्सल',
    'मुहुः',
    'मुहुर्मुह',
    'मुहूरत',
    'मुहूर्त',
    'मुहूर्तः',
    'मुहैया',
    'मुिक्त',
    'मुफ़्त',
    'मूँग',
    'मूँगफली',
    'मूँगा',
    'मूँछ',
    'मूँछहीन',
    'मूँडना',
    'मूँदनी',
    'मूंगफली',
    'मूंगा',
    'मूंगिया',
    'मूंछ',
    'मूंडना',
    'मूंहचोर',
    'मूक',
    'मूकता',
    'मूकाभिनय',
    'मूकाभिनेता',
    'मूकिमा',
    'मूछिया',
    'मूछौन्मुख',
    'मूछ्रित',
    'मूजी',
    'मूठ',
    'मूठदार',
    'मूठा',
    'मूठी',
    'मूड',
    'मूढता',
    'मूढधी',
    'मूढमति',
    'मूढ़',
    'मूढ़ता',
    'मूढा',
    'मूत',
    'मूतना',
    'मूती',
    'मूत्र',
    'मूत्रंद्रिय',
    'मूत्रकृच्छ',
    'मूत्रता',
    'मूत्रवर्धक',
    'मूत्राधिक्य',
    'मूत्रालय',
    'मूत्राशय',
    'मून',
    'मूमफली',
    'मूरख',
    'मूरछना',
    'मूरत',
    'मूरि',
    'मूरिंग',
    'मूरिस',
    'मूर्ख',
    'मूर्खता',
    'मूर्खतापन',
    'मूर्खतापूर्ण',
    'मूर्खतापूर्वक',
    'मूर्खवत',
    'मूर्च्छा',
    'मूर्च्छाकारी',
    'मूर्च्छित',
    'मूर्छना',
    'मूर्छा',
    'मूर्त',
    'मूर्तकरण',
    'मूर्ततः',
    'मूर्तता',
    'मूर्ति',
    'मूर्तिकक्ष',
    'मूर्तिकरण',
    'मूर्तिकला',
    'मूर्तिकार',
    'मूर्तिपूजक',
    'मूर्तिपूजा',
    'मूर्तिपूजिका',
    'मूर्तिभंजक',
    'मूर्तिभंजन',
    'मूर्तिमान',
    'मूर्तिवत',
    'मूर्तिहीन',
    'मूर्तीपूजक',
    'मूर्धज',
    'मूर्धन्य',
    'मूर्धा',
    'मूर्धाभिषेक',
    'मूल',
    'मूलक',
    'मूलकता',
    'मूलकुटुम्ब',
    'मूलगत',
    'मूलचंद',
    'मूलज',
    'मूलत',
    'मूलतंत्र',
    'मूलतः',
    'मूलतत्ववाद',
    'मूलता',
    'मूलदेव',
    'मूलधन',
    'मूलपाठ',
    'मूलबीज',
    'मूलभूत',
    'मूलरुप',
    'मूलवाचक',
    'मूलस्थान',
    'मूलहीन',
    'मूलहीनता',
    'मूला',
    'मूलांग',
    'मूलाधार',
    'मूलाभास',
    'मूलिक',
    'मूलिका',
    'मूली',
    'मूलोच्छेदक',
    'मूलोच्छेदन',
    'मूलोच्छेदित',
    'मूलोद्भव',
    'मूल्य',
    'मूल्यदेय',
    'मूल्यन',
    'मूल्यनिर्धारण',
    'मूल्यवर्ग',
    'मूल्यवान',
    'मूल्यवाला',
    'मूल्यवृद्धि',
    'मूल्यहीन',
    'मूल्यहीनतः',
    'मूल्यहीनता',
    'मूल्यह्रास',
    'मूल्यांक',
    'मूल्यांकन',
    'मूल्यांकित',
    'मूल्यांतर',
    'मूल्यांश',
    'मूल्यातीतता',
    'मूल्यानुसार',
    'मूल्यान्नयन',
    'मूल्यार्थ',
    'मूल्याश्रित',
    'मूल्यों',
    'मूल्योतीत',
    'मूव',
    'मूवमैंट',
    'मूवी',
    'मूशेह',
    'मूष',
    'मूषक',
    'मूषली',
    'मूषा',
    'मूषिक',
    'मूषिका',
    'मूषिकाद',
    'मूषी',
    'मूषीकरण',
    'मूस',
    'मूसना',
    'मूसरचंद',
    'मूसरचन्द',
    'मूसल',
    'मूसलधार',
    'मूसला',
    'मूसलाधार',
    'मूसा',
    'मूसाः',
    'मूसीकार',
    'मूहावरों',
    'मृग',
    'मृगछाला',
    'मृगजल',
    'मृगजीवी',
    'मृगणा',
    'मृगतृष्णा',
    'मृगधर',
    'मृगनयन',
    'मृगनयना',
    'मृगनयनी',
    'मृगनाभि',
    'मृगनाभिजा',
    'मृगमद',
    'मृगमरीचिका',
    'मृगमांस',
    'मृगया',
    'मृगराज',
    'मृगलोचन',
    'मृगलोचना',
    'मृगलोचनी',
    'मृगशिरा',
    'मृगहा',
    'मृगांक',
    'मृगांबर',
    'मृगाजिन',
    'मृगारि',
    'मृगित',
    'मृगी',
    'मृगेंद्र',
    'मृगेश',
    'मृच्छकटिका',
    'मृज',
    'मृडानी',
    'मृडीक',
    'मृणाल',
    'मृणालिका',
    'मृणालिनी',
    'मृण्मय',
    'मृण्मयता',
    'मृत',
    'मृतक',
    'मृतकः',
    'मृतकांतक',
    'मृतप्राय',
    'मृतमातृक',
    'मृतयु',
    'मृतात्मा',
    'मृतावस्था',
    'मृति',
    'मृतिक',
    'मृतिका',
    'मृतिभूति',
    'मृतिभोजी',
    'मृतोत्थान',
    'मृतोत्थित',
    'मृत्कांस्य',
    'मृत्ति',
    'मृत्तिका',
    'मृत्तिकाशिल्पी',
    'मृत्पात्र',
    'मृत्यु',
    'मृत्युंजय',
    'मृत्युंजयता',
    'मृत्युः',
    'मृत्युकर',
    'मृत्युकांक्षी',
    'मृत्युकारी',
    'मृत्युकाल',
    'मृत्युकालिक',
    'मृत्युगत',
    'मृत्युगीत',
    'मृत्युदर',
    'मृत्युपरांत',
    'मृत्युपाश',
    'मृत्युभीति',
    'मृत्युशील',
    'मृत्युहीन',
    'मृत्युहीनता',
    'मृत्सना',
    'मृत्सा',
    'मृद',
    'मृदंग',
    'मृदंगिया',
    'मृदंगी',
    'मृदन',
    'मृदा',
    'मृदिंपड',
    'मृदु',
    'मृदुकरण',
    'मृदुकवची',
    'मृदुकृत',
    'मृदुगमिनी',
    'मृदुगामी',
    'मृदुता',
    'मृदुतालव्योच्चारण',
    'मृदुभाषिणी',
    'मृदुभाषी',
    'मृदुरोमिल',
    'मृदुल',
    'मृदुलता',
    'मृदूकरण',
    'मृदूतक',
    'मृदृ',
    'मृद्भांड',
    'मृद्भांडांश',
    'मृषा',
    'मृषार्थक',
    'मृष्ट',
    'में',
    'मेंड',
    'मेंडबंदी',
    'मेंडरा',
    'मेंडरी',
    'मेंड़',
    'मेंडारिन',
    'मेंढक',
    'मेंढ़क',
    'मेंधी',
    'मेंने',
    'मेंमें',
    'मेंल',
    'मेंह',
    'मेंहदी',
    'मेकअप',
    'मेकप',
    'मेकर',
    'मेकैनिक',
    'मेख',
    'मेखला',
    'मेखलाद्रिजा',
    'मेखलायन',
    'मेखली',
    'मेगाटन',
    'मेगाफ़ोन',
    'मेघ',
    'मेघनाथ',
    'मेघनाद',
    'मेघनादजित',
    'मेघमयता',
    'मेघमाली',
    'मेघवर्ण',
    'मेघवान',
    'मेघहीन',
    'मेघांत',
    'मेघागम',
    'मेघाच्छन्न',
    'मेघाच्छन्नता',
    'मेघाच्छादित',
    'मेघाच्दनन',
    'मेघानंद',
    'मेघायमान',
    'मेघायित',
    'मेघारि',
    'मेघावरी',
    'मेघावली',
    'मेज',
    'मेजपोश',
    'मेजबान',
    'मेजर',
    'मेज़',
    'मेट',
    'मेटक',
    'मेटना',
    'मेटिया',
    'मेटीरियल्स',
    'मेठ',
    'मेड',
    'मेडर',
    'मेड़',
    'मेड़कारी',
    'मेडिया',
    'मेढ',
    'मेढक',
    'मेढ़ा',
    'मेढ़ापन',
    'मेढ़े',
    'मेढा',
    'मेढे',
    'मेथली',
    'मेथिका',
    'मेथिली',
    'मेथी',
    'मेद',
    'मेदज',
    'मेदपुच्छ',
    'मेदस्वी',
    'मेदा',
    'मेदार्बुद',
    'मेदिनी',
    'मेदुर',
    'मेध',
    'मेधा',
    'मेधाकर',
    'मेधातिथि',
    'मेधाविनी',
    'मेधावी',
    'मेधिष्ठ',
    'मेध्य',
    'मेन',
    'मेनका',
    'मेनाद',
    'मेन्डारिन',
    'मेन्यू',
    'मेम',
    'मेमना',
    'मेमनी',
    'मेमने',
    'मेमार',
    'मेमारी',
    'मेयर',
    'मेरठ',
    'मेरा',
    'मेराज',
    'मेरी',
    'मेरीनो',
    'मेरु',
    'मेरुक',
    'मेरुपृष्ठ',
    'मेरू',
    'मेरूदण्ड',
    'मेरूमज्जाशोथ',
    'मेरे',
    'मेल',
    'मेलमर्ज',
    'मेला',
    'मेलापक',
    'मेलापन',
    'मेली',
    'मेल्मोवेअर',
    'मेवा',
    'मेष',
    'मेषपाल',
    'मेषराशि',
    'मेषलोम',
    'मेषी',
    'मेह',
    'मेहँदी',
    'मेहतर',
    'मेहतरानी',
    'मेहता',
    'मेहन',
    'मेहनत',
    'मेहनतकश',
    'मेहनताना',
    'मेहनती',
    'मेहनमान',
    'मेहमान',
    'मेहमानदार',
    'मेहमानदारी',
    'मेहमानी',
    'मेहरबान',
    'मेहरबानी',
    'मेहरा',
    'मेहराना',
    'मेहराब',
    'मेहराबदार',
    'मेहराबी',
    'मेहरारु',
    'मेहरी',
    'मेहेश्वर',
    'मेज़',
    'मेज़बान',
    'मैं',
    'मैंटलपीस',
    'मैंद',
    'मैंबर',
    'मैंबरशिप',
    'मैंमैं',
    'मैक',
    'मैकनिक',
    'मैकनिकल',
    'मैकरल',
    'मैकसी',
    'मैका',
    'मैकाडम',
    'मैके',
    'मैकेनिक',
    'मैकेनिज्म',
    'मैग',
    'मैगजिनिस्ट',
    'मैगजीन',
    'मैगनीज़',
    'मैग्नेट',
    'मैग्नेटिक',
    'मैग्नेटिज्म',
    'मैच',
    'मैचबाक्स',
    'मैचयोरिंग',
    'मैजनीन',
    'मैजरिंग',
    'मैजि',
    'मैजिक',
    'मैजी',
    'मैजेंटा',
    'मैट',
    'मैटर',
    'मैटरनिटी',
    'मैटल',
    'मैटलि',
    'मैटिंग',
    'मैटिरक',
    'मैटिरक्स',
    'मैटीरियल',
    'मैट्रन',
    'मैट्रिक्स',
    'मैट्रिमनी',
    'मैट्रैस',
    'मैट्रो',
    'मैडम',
    'मैडल',
    'मैडिकल',
    'मैडिसिन',
    'मैडीकल',
    'मैत्र',
    'मैत्रक',
    'मैत्रायणी',
    'मैत्रावरुणि',
    'मैत्री',
    'मैत्रीपूर्ण',
    'मैत्रीहीनता',
    'मैत्रेय',
    'मैत्रेयी',
    'मैथुन',
    'मैथुनांगीय',
    'मैथुनाक्षम',
    'मैथुनिक',
    'मैदन',
    'मैदाः',
    'मैदान',
    'मैदानी',
    'मैदाने',
    'मैदे',
    'मैन',
    'मैनअल',
    'मैनमेड',
    'मैनमेडयार्न',
    'मैना',
    'मैनाक',
    'मैनाल',
    'मैनिया',
    'मैनुअल',
    'मैने',
    'मैनेज',
    'मैनेजमेंट',
    'मैनेजमैंट',
    'मैनेजर',
    'मैनेजिंग',
    'मैन्युअल',
    'मैन्युस्क्रिप्ट',
    'मैन्यू',
    'मैप',
    'मैमरी',
    'मैमो',
    'मैमोरी',
    'मैमोरैंडम',
    'मैया',
    'मैयार',
    'मैरा',
    'मैरिज',
    'मैरीना',
    'मैरीनो',
    'मैल',
    'मैलनकोलिया',
    'मैलन्यूट्रीशन',
    'मैलमीन',
    'मैला',
    'मैलापन',
    'मैलामइन',
    'मैली',
    'मैले',
    'मैलोड्रामा',
    'मैल्टिंग',
    'मैल्मोवेअर',
    'मैश',
    'मैशीन',
    'मैस',
    'मैसर्स',
    'मैसिज',
    'मैसूर',
    'मैसेंजर',
    'मैसेज',
    'मैसोकिज्म',
    'मैस्मरिज्म',
    'मैहर',
    'मॉं',
    'मोंछ',
    'मोंताज',
    'मोउना',
    'मोएनीटरिंग',
    'मोएनीटरी',
    'मोकला',
    'मोक्ष',
    'मोक्षगत',
    'मोक्षण',
    'मोक्षद',
    'मोक्षदा',
    'मोक्षप्रद',
    'मोक्षप्रदा',
    'मोक्षहीनता',
    'मोखा',
    'मोगरा',
    'मोघ',
    'मोच',
    'मोचक',
    'मोचन',
    'मोचननिषेध',
    'मोचनी',
    'मोचयिता',
    'मोचयित्री',
    'मोचित',
    'मोची',
    'मोज़ा',
    'मोजा',
    'मोजूदा',
    'मोजे',
    'मोजेल',
    'मोट',
    'मोटन',
    'मोटर',
    'मोटरकार',
    'मोटरगाड़ी',
    'मोटरमैन',
    'मोटरवे',
    'मोटरसाइकिल',
    'मोटरिंग',
    'मोटरिस्ट',
    'मोटरी',
    'मोटर्स',
    'मोटल',
    'मोटा',
    'मोटाई',
    'मोटाताजापन',
    'मोटाना',
    'मोटापन',
    'मोटापा',
    'मोटामोटी',
    'मोटिया',
    'मोटिव',
    'मोटी',
    'मोटे',
    'मोठ',
    'मोड',
    'मोडतोड',
    'मोडदार',
    'मोडना',
    'मोडहीन',
    'मोडहीनता',
    'मोड़',
    'मोड़कर',
    'मोड़दार',
    'मोड़ना',
    'मोड़ी',
    'मोडा',
    'मोडिफाइड',
    'मोडी',
    'मोढ़ा',
    'मोढ़ामढापन',
    'मोढा',
    'मोत',
    'मोतदिल',
    'मोतबर',
    'मोतबरी',
    'मोतिया',
    'मोतियाबिंद',
    'मोती',
    'मोतीचूर',
    'मोतीलाल',
    'मोथ',
    'मोथा',
    'मोथी',
    'मोद',
    'मोदक',
    'मोदकार',
    'मोदछल',
    'मोदछाँह',
    'मोदन',
    'मोदप्रद',
    'मोदमय',
    'मोदहीन',
    'मोदहीनता',
    'मोदाख्य',
    'मोदित',
    'मोदिनी',
    'मोदी',
    'मोदीखाना',
    'मोना',
    'मोनिटर',
    'मोनोग्राम',
    'मोनोटाइप',
    'मोनोपली',
    'मोनोप्ले',
    'मोनोरेल',
    'मोबाइक',
    'मोबाइल',
    'मोबिल',
    'मोम',
    'मोमजामा',
    'मोमदिल',
    'मोमदिली',
    'मोमबत्ती',
    'मोमिन',
    'मोमिना',
    'मोमिनीन',
    'मोमिया',
    'मोमैंटम',
    'मोय',
    'मोयन',
    'मोयनदान',
    'मोयनदार',
    'मोर',
    'मोरः',
    'मोरचा',
    'मोरचापन',
    'मोरचाबंदी',
    'मोरचे',
    'मोरछला',
    'मोरट',
    'मोरनी',
    'मोरपंख',
    'मोरपंखी',
    'मोरपर',
    'मोरमनवाद',
    'मोरा',
    'मोरी',
    'मोर्चा',
    'मोर्चाबंदी',
    'मोर्चाबन्दी',
    'मोर्ट',
    'मोर्या',
    'मोल',
    'मोलक्यूल',
    'मोलतोल',
    'मोलना',
    'मोलभाव',
    'मोलस्क',
    'मोला',
    'मोलिब्डेनम',
    'मोल्क्यूल',
    'मोल्ड',
    'मोष',
    'मोषक',
    'मोषण',
    'मोषयिता',
    'मोस्ट',
    'मोह',
    'मोहक',
    'मोहकता',
    'मोहकतावश',
    'मोहकारी',
    'मोहग्रस्त',
    'मोहड़ा',
    'मोहताज',
    'मोहद',
    'मोहन',
    'मोहना',
    'मोहनातीत',
    'मोहनी',
    'मोहपूर्ण',
    'मोहमय',
    'मोहमुक्त',
    'मोहम्मद',
    'मोहम्मदी',
    'मोहर',
    'मोहरबंद',
    'मोहरबन्द',
    'मोहरा',
    'मोहरी',
    'मोहरीः',
    'मोहरें',
    'मोहरों',
    'मोहर्रम',
    'मोहर्रिर',
    'मोहलत',
    'मोहसिन',
    'मोहहीन',
    'मोहहीनता',
    'मोहातीत',
    'मोहार',
    'मोहि',
    'मोहित',
    'मोहिनी',
    'मोही',
    'मोज़े',
    'मौक़ा',
    'मौक़ाए',
    'मौक़ूफ्र',
    'मौक़ूफ्री',
    'मौक़े',
    'मौका',
    'मौके',
    'मौखिक',
    'मौखिकता',
    'मौज',
    'मौजदा',
    'मौज़ा',
    'मौजी',
    'मौजूँ',
    'मौजूद',
    'मौजूदगी',
    'मौजूदा',
    'मौजे',
    'मौडम',
    'मौडरेटर',
    'मौडल',
    'मौडिफाई',
    'मौडिफिकेशन',
    'मौत',
    'मौतः',
    'मौद्गलि',
    'मौद्गल्यायन',
    'मौद्रिक',
    'मौद्रिकता',
    'मौन',
    'मौनतः',
    'मौनता',
    'मौनप्रियता',
    'मौना',
    'मौनानुकूलता',
    'मौनावलंबी',
    'मौनि',
    'मौनिटर',
    'मौनी',
    'मौनेय',
    'मौनेयः',
    'मौनेस्ट्री',
    'मौर',
    'मौरबंदी',
    'मौरुसी',
    'मौरुसीपन',
    'मौर्ग',
    'मौर्टगेजर',
    'मौर्टगेज्ड',
    'मौलवी',
    'मौलश्री',
    'मौलसरा',
    'मौलसराः',
    'मौलसिरी',
    'मौला',
    'मौलाना',
    'मौलापन',
    'मौलि',
    'मौलिक',
    'मौलिकता',
    'मौलिश्री',
    'मौली',
    'मौलूद',
    'मौवीं',
    'मौसड़',
    'मौसम',
    'मौसमविज्ञानी',
    'मौसमी',
    'मौसम्मी',
    'मौसल',
    'मौसस',
    'मौससः',
    'मौससरा',
    'मौससराः',
    'मौसा',
    'मौसिम',
    'मौसिया',
    'मौसी',
    'मौसीकार',
    'मौसीकी',
    'मौसेरा',
    'मौसेरी',
    'मौहम्मद',
    'मौक़ा',
    'मौज़ूदगी',
    'म्बांजा',
    'म्याऊँ',
    'म्यान',
    'म्याना',
    'म्यानी',
    'म्यान्मा',
    'म्यूज',
    'म्यूजिक',
    'म्यूजियम',
    'म्यूजिशियन',
    'म्यूटेशन',
    'म्यूनिसिपैलिटी',
    'म्रेण',
    'म्लान',
    'म्लानता',
    'म्लानन',
    'म्लानि',
    'म्लेच्छ',
    'म्लेच्छितक',
    'म्हारा',
    'मज़दूर',
    'मज़दूरी',
    'मज़बूत',
    'मज़ा',
    'मज़ाक',
    'मज़ाकिया',
    'मज़ाकियापन',
    'यंता',
    'यंत्र',
    'यंत्रः',
    'यंत्रक',
    'यंत्रगति',
    'यंत्रण',
    'यंत्रणा',
    'यंत्रणागार',
    'यंत्रणाप्रद',
    'यंत्रणाप्रियता',
    'यंत्रदि',
    'यंत्रवत',
    'यंत्रवतः',
    'यंत्रवाद',
    'यंत्रवादी',
    'यंत्रशोथ',
    'यंत्रस्थ',
    'यंत्रालय',
    'यंत्रिणी',
    'यंत्रित',
    'यंत्री',
    'यंत्रीकरण',
    'यंत्रीकृत',
    'यंत्रीय',
    'यंत्रों',
    'यंत्रोपकरण',
    'यंविधान',
    'यक',
    'यकजरी',
    'यकज़बान',
    'यकजा',
    'यकजान',
    'यकतरफा',
    'यकताई',
    'यकदिली',
    'यकपहलू',
    'यकफसली',
    'यकबारगी',
    'यकमंजिला',
    'यकमुश्त',
    'यकरंग',
    'यकलाई',
    'यकलौता',
    'यकशंबा',
    'यकसाँ',
    'यकसाँपन',
    'यकसार',
    'यकसारियत',
    'यकसाला',
    'यकसू',
    'यकसूई',
    'यक़ीन',
    'यकायक',
    'यकीदा',
    'यकीन',
    'यकीनन',
    'यकीनी',
    'यकृत',
    'यकृतीय',
    'यक्का',
    'यक्ष',
    'यक्षः',
    'यक्षपुर',
    'यक्षराज',
    'यक्षावास',
    'यक्षिणी',
    'यक्षी',
    'यक्षेंद्र',
    'यक्षेश्वर',
    'यक्ष्मा',
    'यखनी',
    'यगण',
    'यगाना',
    'यचर्म',
    'यजंत',
    'यजत',
    'यजत्र',
    'यजन',
    'यजमान',
    'यजमानः',
    'यजमानक',
    'यजमानता',
    'यजमानत्व',
    'यजमानी',
    'यजी',
    'यजुः',
    'यजुर्वेद',
    'यजुर्वेदः',
    'यजुर्वेदश',
    'यजुष्पात्र',
    'यज्ञ',
    'यज्ञघ्न',
    'यज्ञधर',
    'यज्ञपति',
    'यज्ञपशु',
    'यज्ञभाव',
    'यज्ञभू',
    'यज्ञमान',
    'यज्ञरता',
    'यज्ञवराह',
    'यज्ञवल्क',
    'यज्ञवाहन',
    'यज्ञसेन',
    'यज्ञस्थली',
    'यज्ञहा',
    'यज्ञांग',
    'यज्ञाग्नि',
    'यज्ञात्र',
    'यज्ञानुष्ठान',
    'यज्ञारि',
    'यज्ञाहुति',
    'यज्ञिय',
    'यज्ञीय',
    'यज्ञीयता',
    'यज्ञेतर',
    'यज्ञेश',
    'यज्ञेश्वर',
    'यज्ञोपकरण',
    'यज्ञोपयुक्त',
    'यज्ञोपवीत',
    'यज्ञोवीयत',
    'यज्य',
    'यज्यु',
    'यज्वा',
    'यत',
    'यतता',
    'यतन',
    'यतमना',
    'यतात्मा',
    'यति',
    'यतित',
    'यतित्व',
    'यतित्ववाद',
    'यतिधर्म',
    'यतिनी',
    'यती',
    'यतीम',
    'यतीमखाना',
    'यतीमी',
    'यत्किंचित',
    'यत्त',
    'यत्न',
    'यत्नशील',
    'यत्नहीन',
    'यत्र',
    'यत्रु',
    'यथ',
    'यथकामना',
    'यथकामी',
    'यथकाम्य',
    'यथकाल',
    'यथक्रम',
    'यथक्रमता',
    'यथक्षमता',
    'यथतथ',
    'यथतथ्यता',
    'यथदिश्',
    'यथदेश',
    'यथनियम',
    'यथनिर्दिष्ट',
    'यथनुपूर्व',
    'यथन्याय',
    'यथपूर्व',
    'यथप्रथा',
    'यथभिप्रेम',
    'यथभीष्ट',
    'यथमार्ग',
    'यथयथ',
    'यथयोग्य',
    'यथरीति',
    'यथर्थ',
    'यथर्थतः',
    'यथर्थता',
    'यथर्थवाद',
    'यथवत्ता',
    'यथवश्यक',
    'यथवश्यकता',
    'यथवांछित',
    'यथवांछिततः',
    'यथविध',
    'यथविधि',
    'यथविहित',
    'यथशक्ति',
    'यथशा',
    'यथसमय',
    'यथसमयता',
    'यथसाध्य',
    'यथस्थान',
    'यथस्थिति',
    'यथस्थितिवाद',
    'यथस्थितिवादी',
    'यथा',
    'यथाकथित',
    'यथाकाम',
    'यथाक्रम',
    'यथाक्षमता',
    'यथातथ्य',
    'यथातथ्यता',
    'यथातथ्यतापूर्वक',
    'यथानुपात',
    'यथापूर्ण',
    'यथापूर्व',
    'यथापूर्वतः',
    'यथापूर्वता',
    'यथाप्रस्तावित',
    'यथामूल्य',
    'यथायोग्य',
    'यथार्थ',
    'यथार्थचित्र',
    'यथार्थचित्री',
    'यथार्थता',
    'यथावत',
    'यथावश्यक',
    'यथावसर',
    'यथाशीघ्र',
    'यथाशीध्र',
    'यथासंभव',
    'यथासमय',
    'यथासम्भव',
    'यथासाध्य',
    'यथेच्छ',
    'यथेच्छाचता',
    'यथेच्छाचार',
    'यथेच्छाचारी',
    'यथेष्ट',
    'यथेष्टता',
    'यथेष्ठ',
    'यथेष्ठता',
    'यथोक्त',
    'यथोचित',
    'यथोपरि',
    'यथोवित',
    'यदा',
    'यदापि',
    'यदि',
    'यदु',
    'यदुनाथ',
    'यदुराज',
    'यदुवीर',
    'यदृच्छया',
    'यदृच्छा',
    'यद्यपि',
    'यन्त्र',
    'यन्त्रों',
    'यम',
    'यमः',
    'यमक',
    'यमचिर्त',
    'यमज',
    'यमजता',
    'यमजयी',
    'यमजान',
    'यमदूत',
    'यमधर',
    'यमधार',
    'यमन',
    'यमनकी',
    'यमना',
    'यमनाथ',
    'यमनी',
    'यमपाश',
    'यमपुर',
    'यमपुरी',
    'यमप्रिय',
    'यममार्ग',
    'यमराज',
    'यमल',
    'यमलोक',
    'यमलोकःनदी',
    'यमांतक',
    'यमानी',
    'यमानुजा',
    'यमारि',
    'यमित',
    'यमी',
    'यमुन',
    'यमुना',
    'यमेश्वर',
    'यव',
    'यवः',
    'यवक्षार',
    'यवगाथा',
    'यवज',
    'यवन',
    'यवनप्रिय',
    'यवनारि',
    'यवनाल',
    'यवनालज',
    'यवनिका',
    'यवनेष्ट',
    'यवस',
    'यवागू',
    'यवाग्रज',
    'यवानी',
    'यविका',
    'यविष्ठ',
    'यवोदृभव',
    'यश',
    'यशकाम',
    'यशगाथा',
    'यशद',
    'यशदान',
    'यशदीकरण',
    'यशप्रद',
    'यशब',
    'यशवंत',
    'यशस्कर',
    'यशहीन',
    'यशी',
    'यशोकाम',
    'यशोगान',
    'यशोद',
    'यशोदा',
    'यशोदाः',
    'यशोधन',
    'यशोधरा',
    'यशोमती',
    'यशोमाधव',
    'यष्टि',
    'यष्टिका',
    'यष्टी',
    'यस',
    'यसार',
    'यस्क',
    'यह',
    'यहाँ',
    'यहां',
    'यहि',
    'यहिया',
    'यही',
    'यहीं',
    'यहूद',
    'यहूदियों',
    'यहूदी',
    'यहूदीपन',
    'या',
    'याँ',
    'यांचा',
    'यांत्रिक',
    'यांत्रिकी',
    'याकूत',
    'याकृत',
    'याग',
    'याग्य',
    'याचक',
    'याचना',
    'याचिका',
    'याचिकादाता',
    'याचित',
    'याचिततः',
    'याचिता',
    'याचिष्णु',
    'याची',
    'याज',
    'याजक',
    'याजनिक',
    'याजी',
    'याज्ञ',
    'याज्ञवल्क्य',
    'याज्ञसेनी',
    'याज्ञिक',
    'याज्ञिय',
    'याज्य',
    'याट',
    'याड़ी',
    'यात',
    'यातना',
    'यातनागार',
    'यातयाम',
    'याता',
    'यातायात',
    'यातु',
    'यातुघ्न',
    'यातुधान',
    'यातुधाना',
    'यातुधानी',
    'यात्रा',
    'यात्रांत',
    'यात्राः',
    'यात्राक्रम',
    'यात्रारंभ',
    'यात्रावाल',
    'यात्राशील',
    'यात्रिक',
    'यात्री',
    'यात्रीः',
    'याद',
    'यादआवरी',
    'यादगार',
    'याददाश्त',
    'यादव',
    'यादवी',
    'यादसांपत्ति',
    'यादावरी',
    'यादु',
    'यादुक्ष',
    'यादृच्छिक',
    'यादृच्छित',
    'यादृश',
    'यादें',
    'याध्य',
    'यान',
    'यानचर',
    'यानवाही',
    'यानशाला',
    'यानहीन',
    'यानारुढ़',
    'यानारोही',
    'यानावतारण',
    'यानिकी',
    'यानी',
    'यानीय',
    'याने',
    'यानेतर',
    'यान्त्रिकी',
    'यापन',
    'यापित',
    'याम',
    'यामकिनी',
    'यामघोष',
    'यामघोषा',
    'यामल',
    'यामवती',
    'यामाता',
    'यामि',
    'यामिनी',
    'यामिनीचर',
    'यामी',
    'यामेय',
    'याम्य',
    'याम्या',
    'याम्यायन',
    'याम्योत्तर',
    'यायावर',
    'यायावरता',
    'यायावरी',
    'यायी',
    'यार',
    'यारबाश',
    'यारबाशी',
    'याराना',
    'यारी',
    'यारों',
    'यार्ड',
    'यार्न',
    'यावज्जीवन',
    'यावत्',
    'यावन',
    'यावनक',
    'यावर',
    'यावि',
    'याष्टिक',
    'याष्टीक',
    'यासमीन',
    'यास्क',
    'याहि',
    'याह्या',
    'यित्नत',
    'यिापति',
    'यीड़',
    'यीशु',
    'युंजान',
    'युक्त',
    'युक्तक',
    'युक्ततः',
    'युक्तता',
    'युक्तमना',
    'युक्ताक्षर',
    'युक्तात्मा',
    'युक्तार्थ',
    'युक्ति',
    'युक्तिक',
    'युक्तिकरण',
    'युक्तिचालन',
    'युक्तिपूर्ण',
    'युक्तिपूर्णता',
    'युक्तिपूर्वक',
    'युक्तिमाला',
    'युक्तियुक्त',
    'युक्तिवाद',
    'युक्तिवादी',
    'युक्तिसंगत',
    'युक्तिसम्पन्न',
    'युक्तिहीन',
    'युक्तिहीनता',
    'युक्तीय',
    'युग',
    'युगंधर',
    'युगः',
    'युगत',
    'युगपत',
    'युगपद',
    'युगपदता',
    'युगल',
    'युगलक',
    'युगलता',
    'युगलित',
    'युगांत',
    'युगांतर',
    'युगांतरकारी',
    'युगांतराल',
    'युगादि',
    'युगाध्यक्ष',
    'युगान्त',
    'युगान्तर',
    'युगारंभ',
    'युगारंभी',
    'युगावतार',
    'युगावर्त',
    'युगी',
    'युगीन',
    'युगीय',
    'युगे',
    'युगों',
    'युग्म',
    'युग्मक',
    'युग्मकी',
    'युग्मचर',
    'युग्मचर्या',
    'युग्मचारी',
    'युग्मज',
    'युग्मजा',
    'युग्मन',
    'युग्मनकी',
    'युग्मित',
    'युग्य',
    'युज्य',
    'युत',
    'युतक',
    'युतका',
    'युति',
    'युतियुक्त',
    'युतिहीन',
    'युतिहीनता',
    'युद्घ',
    'युद्घक',
    'युद्घकारी',
    'युद्घन',
    'युद्घनाद',
    'युद्घप्रिय',
    'युद्घप्रियता',
    'युद्घबंदी',
    'युद्घवीर',
    'युद्घसार',
    'युद्घहीन',
    'युद्घहीनता',
    'युद्घांगन',
    'युद्घांत',
    'युद्घाग्र',
    'युद्घातुर',
    'युद्घातुरता',
    'युद्घापराध',
    'युद्घार्थी',
    'युद्घोत्सुकता',
    'युद्घोन्मत',
    'युद्घोन्मतता',
    'युद्घोपकरण',
    'युद्ध',
    'युद्धजीवी',
    'युद्धपोत',
    'युद्धप्रिय',
    'युद्धप्रियता',
    'युद्धभूमि',
    'युद्धमानता',
    'युद्धविराम',
    'युद्धविरोधिता',
    'युद्धशक्ति',
    'युद्धाभिनय',
    'युद्धाभ्यास',
    'युद्धीय',
    'युद्धेच्छु',
    'युद्धेच्छुक',
    'युद्धोपकरण',
    'युधवीर',
    'युधासर',
    'युधिष्ठिर',
    'युध्म',
    'युनानी',
    'युनिटें',
    'युनिवर्सिटी',
    'युयु',
    'युयुत्सा',
    'युयुत्सु',
    'युयुधान',
    'युवक',
    'युवजन',
    'युवती',
    'युवन्यु',
    'युवराज',
    'युवराजः',
    'युवराज्ञी',
    'युवरानी',
    'युवा',
    'युवानक',
    'यूक',
    'यूकरिस्ट',
    'यूकलिप्टस',
    'यूका',
    'यूक्ति',
    'यूक्लिडी',
    'यूग',
    'यूतिहीन',
    'यूथ',
    'यूथचर',
    'यूथचारी',
    'यूथनाथ',
    'यूथपतित्व',
    'यूथबंध',
    'यूथिका',
    'यूथी',
    'यूनाइटिड',
    'यूनान',
    'यूनानवादी',
    'यूनानी',
    'यूनिट',
    'यूनिटवार',
    'यूनिटी',
    'यूनिटें',
    'यूनिटों',
    'यूनिफार्म',
    'यूनियन',
    'यूनिवर्सिटी',
    'यूनिवसिर्टी',
    'यूनिसैक्स',
    'यूनो',
    'यूप',
    'यूपी',
    'यूप्य',
    'यूरिया',
    'यूरेनस',
    'यूरो',
    'यूरोप',
    'यूरोपवाद',
    'यूरोपियन',
    'यूरोपीय',
    'यूष',
    'यूिक्लड्स',
    'ये',
    'येन',
    'येलम्मा',
    'येशु',
    'यों',
    'योक',
    'योक्ता',
    'योक्तृ',
    'योग',
    'योगंधर',
    'योगकरण',
    'योगक्षेम',
    'योगक्षेमकारी',
    'योगदान',
    'योगदायी',
    'योगनाथ',
    'योगपति',
    'योगवती',
    'योगवश',
    'योगवान',
    'योगवाही',
    'योगविद',
    'योगसिद्घ',
    'योगांग',
    'योगांजन',
    'योगाग',
    'योगाचरण',
    'योगाचार',
    'योगाचार्य',
    'योगात्मक',
    'योगात्मा',
    'योगानंद',
    'योगाभ्यास',
    'योगायोग',
    'योगारुड़',
    'योगासन',
    'योगािग्न',
    'योगिक',
    'योगित',
    'योगिता',
    'योगिनी',
    'योगिया',
    'योगी',
    'योगींद्र',
    'योगीकरण',
    'योगीनाथ',
    'योगीय',
    'योगीश',
    'योगीश्वर',
    'योगीश्वरी',
    'योगेंद्र',
    'योगेन्द्र',
    'योगेश',
    'योगेश्वर',
    'योगेश्वरी',
    'योगेष्ट',
    'योगोपनिषद',
    'योग्य',
    'योग्यता',
    'योग्यताएं',
    'योग्यतानुसार',
    'योजक',
    'योजन',
    'योजनगंधा',
    'योजना',
    'योजनाएं',
    'योजनाओं',
    'योजनागत',
    'योजनानुसार',
    'योजनाबद्घ',
    'योजनाबद्ध',
    'योजनाहीन',
    'योजनाहीनतः',
    'योजनाहीनता',
    'योजनेतर',
    'योजिका',
    'योजित',
    'योज्य',
    'योद्घज्य',
    'योद्घज्यता',
    'योद्घा',
    'योद्धा',
    'योद्वा',
    'योध',
    'योधक',
    'योधन',
    'योधा',
    'योधी',
    'योधेय',
    'योध्यता',
    'योनि',
    'योनिः',
    'योनिच्छद',
    'योनिछिद्र',
    'योनिज',
    'योनिमुक्त',
    'योनिमुख',
    'योनीय',
    'योमुल',
    'योषिता',
    'योषित्पिरया',
    'यौक्त',
    'यौगंधर',
    'यौगिक',
    'यौगिकता',
    'यौगिकी',
    'यौगिकीकृत',
    'यौतिक',
    'यौतुक',
    'यौथिक',
    'यौद्धिक',
    'यौद्धिकता',
    'यौधेय',
    'यौन',
    'यौनकी',
    'यौनाकर्षण',
    'यौनिकता',
    'यौम',
    'यौमिया',
    'यौमुल',
    'यौमे',
    'यौवन',
    'यौवनपूर्ण',
    'यौवना',
    'यौवनादे्भव',
    'रँगना',
    'रँगरंज',
    'रँगरेंज',
    'रँगरेज़ी',
    'रँगा',
    'रँगाई',
    'रँगिया',
    'रँगीलापन',
    'रँगीली',
    'रँगे',
    'रँडापा',
    'रँडुआ',
    'रँडुआपन',
    'रँडोरा',
    'रँदना',
    'रँदाई',
    'रँधना',
    'रँधाई',
    'रँधी',
    'रंँगाई',
    'रंक',
    'रंकता',
    'रंग',
    'रंगः',
    'रंगकार',
    'रंगक्षेत्र',
    'रंगगृह',
    'रंगचर',
    'रंगजीवी',
    'रंगढंग',
    'रंगत',
    'रंगतरा',
    'रंगद',
    'रंगदा',
    'रंगदान',
    'रंगदानी',
    'रंगदार',
    'रंगदीप्त',
    'रंगदीप्ति',
    'रंगना',
    'रंगने',
    'रंगपट्टिका',
    'रंगपाशी',
    'रंगपीठ',
    'रंगप्रद',
    'रंगबिरंगा',
    'रंगभंग',
    'रंगभूमि',
    'रंगभूषा',
    'रंगभेद',
    'रंगमंच',
    'रंगमंचीयता',
    'रंगमंडप',
    'रंगरलिया',
    'रंगरलियां',
    'रंगरलियों',
    'रंगरली',
    'रंगरस',
    'रंगराता',
    'रंगरास',
    'रंगरुट',
    'रंगरुप',
    'रंगरूट',
    'रंगरेज',
    'रंगरेली',
    'रंगलेप',
    'रंगव्य',
    'रंगशाला',
    'रंगसाज़',
    'रंगसाजी',
    'रंगसाज़',
    'रंगस्थली',
    'रंगहीन',
    'रंगा',
    'रंगांगण',
    'रंगाई',
    'रंगाजीव',
    'रंगापन',
    'रंगारंग',
    'रंगार्पण',
    'रंगालय',
    'रंगालेपन',
    'रंगावतरण',
    'रंगी',
    'रंगीन',
    'रंगीनमिजाज़',
    'रंगीनमिजाजी',
    'रंगीनी',
    'रंगीय',
    'रंगीला',
    'रंगीली',
    'रंगे',
    'रंगों',
    'रंगोत्सव',
    'रंगोपजीवी',
    'रंच',
    'रंचक',
    'रंचमात्र',
    'रंचमात्रतः',
    'रंचमात्रता',
    'रंज',
    'रंजक',
    'रंजकहीन',
    'रंजकों',
    'रंजन',
    'रंजनी',
    'रंजव्य',
    'रंजिका',
    'रंजित',
    'रंजितता',
    'रंजिश',
    'रंजीदगी',
    'रंजीदा',
    'रंटत',
    'रंड',
    'रंडा',
    'रंडाश्रमी',
    'रंडी',
    'रंडीबाज',
    'रंडीबाजी',
    'रंता',
    'रंति',
    'रंतिदेव',
    'रंद',
    'रंदना',
    'रंदा',
    'रंदाकार',
    'रंधन',
    'रंधर',
    'रंधरयुक्त',
    'रंधरहीन',
    'रंधित',
    'रंधिरत',
    'रंध्र',
    'रंध्रिका',
    'रंब',
    'रंभ',
    'रंभा',
    'रंभापति',
    'रंभाहट',
    'रंभित',
    'रंभी',
    'रंभोक',
    'रअय्यत',
    'रई',
    'रईस',
    'रईसजादा',
    'रईसाना',
    'रईसी',
    'रईसे',
    'रउरे',
    'रकबा',
    'रकम',
    'रकमवार',
    'रकमी',
    'रकसैक',
    'रकाब',
    'रकाबा',
    'रकाबी',
    'रकाबीदान',
    'रकीबा',
    'रकीव',
    'रक्कास',
    'रक्कासा',
    'रक्त',
    'रक्तक',
    'रक्तकंठी',
    'रक्तग्रीव',
    'रक्तचाप',
    'रक्तज',
    'रक्तता',
    'रक्ततुंड',
    'रक्तदाब',
    'रक्तपा',
    'रक्तपात',
    'रक्तपिपासु',
    'रक्तपुष्प',
    'रक्तपूर्ण',
    'रक्तपूर्ति',
    'रक्तफल',
    'रक्तबीज',
    'रक्तमणि',
    'रक्तमुख',
    'रक्तरंजित',
    'रक्तविषायन',
    'रक्तस्कंदनहीन',
    'रक्तस्कंदनहीनता',
    'रक्तस्रावी',
    'रक्तहीन',
    'रक्तांक',
    'रक्तांबर',
    'रक्ताघाती',
    'रक्ताणु',
    'रक्ताधान',
    'रक्ताधिक्य',
    'रक्ताभ',
    'रक्तालय',
    'रक्ताल्पता',
    'रक्ताशय',
    'रक्ति',
    'रक्तिता',
    'रक्तिव्यन',
    'रक्तिहस्त',
    'रक्तिि',
    'रक्तोत्पल',
    'रक्तोदर',
    'रक्तोपल',
    'रक्रमः',
    'रक्ष',
    'रक्षः',
    'रक्षक',
    'रक्षकहीन',
    'रक्षण',
    'रक्षणहीन',
    'रक्षणहीनता',
    'रक्षणीय',
    'रक्षना',
    'रक्षा',
    'रक्षागृह',
    'रक्षात्मक',
    'रक्षार्थ',
    'रक्षावरण',
    'रक्षाहीन',
    'रक्षाहीनता',
    'रक्षिका',
    'रक्षित',
    'रक्षिता',
    'रक्षी',
    'रक्षीदल',
    'रक्षोध्न',
    'रक्षोपाय',
    'रक्ष्ज्ञक',
    'रक्स',
    'रख',
    'रखकर',
    'रखड़ी',
    'रखता',
    'रखती',
    'रखते',
    'रखना',
    'रखनाः',
    'रखनी',
    'रखने',
    'रखनेवाला',
    'रखरखाव',
    'रखल',
    'रखला',
    'रखवाई',
    'रखवाना',
    'रखवार',
    'रखवाल',
    'रखवाला',
    'रखवाली',
    'रखा',
    'रखाई',
    'रखान',
    'रखाना',
    'रखानापन',
    'रखार',
    'रखाव',
    'रखिए',
    'रखिया',
    'रखी',
    'रखे',
    'रखें',
    'रखेडिया',
    'रखेल',
    'रखेली',
    'रखैया',
    'रखैल',
    'रखैलपन',
    'रखौनी',
    'रख्श',
    'रग',
    'रगडना',
    'रगड़',
    'रगड़कर',
    'रगड़ना',
    'रगड़ने',
    'रगड़ा',
    'रगड़ाई',
    'रगड़ाहट',
    'रगडाई',
    'रगण',
    'रगदना',
    'रगद्वार',
    'रगबत',
    'रगस',
    'रगसना',
    'रगसा',
    'रगसाई',
    'रगी',
    'रगीला',
    'रगे',
    'रगेद',
    'रगेदना',
    'रग्रंथि',
    'रघु',
    'रघुकल',
    'रघुकलः',
    'रघुकुल',
    'रघुनाथ',
    'रघुपति',
    'रघुबर',
    'रघुबीर',
    'रघुराय',
    'रघुवंश',
    'रघुवर',
    'रघुवीर',
    'रघूतम',
    'रचन',
    'रचना',
    'रचनाएं',
    'रचनाओं',
    'रचनाकार',
    'रचनाकारः',
    'रचनात्मक',
    'रचनात्मकता',
    'रचनाधर्मी',
    'रचनाशील',
    'रचनाशीलता',
    'रचनाहीन',
    'रचनाहीनता',
    'रचनीय',
    'रचयिता',
    'रचयित्री',
    'रचा',
    'रचाना',
    'रचित',
    'रचिति',
    'रचेता',
    'रचैया',
    'रच्य',
    'रज',
    'रजअत',
    'रजक',
    'रजगज',
    'रजगीर',
    'रजजु',
    'रजत',
    'रजतता',
    'रजतपट',
    'रजतपत्र',
    'रजतमान',
    'रजताद्रि',
    'रजताभता',
    'रजताभास',
    'रजतिम',
    'रजतिमा',
    'रजतोपम',
    'रजन',
    'रजनी',
    'रजनीकर',
    'रजनीचर',
    'रजनीश',
    'रजबहा',
    'रजयशु',
    'रजवाड़ा',
    'रजस्क',
    'रजस्वला',
    'रज़',
    'रज़ब',
    'रज़ा',
    'रज़ाई',
    'रज़ाकार',
    'रज़ामंद',
    'रज़ामंदी',
    'रजाई',
    'रजाणु',
    'रजामंदी',
    'रजायस',
    'रजिमेंट',
    'रजिस्टर',
    'रजिस्टरों',
    'रजिस्टर्ड',
    'रजिस्ट्रर',
    'रजिस्ट्रार',
    'रजिस्ट्री',
    'रजिस्ट्रीकरण',
    'रजिस्ट्रीकृत',
    'रजीय',
    'रजोगुण',
    'रजोगुणी',
    'रजोधर्म',
    'रजोनिवृत्ता',
    'रजोनिवृत्ति',
    'रज्जु',
    'रज्जुका',
    'रज्जुनर्तक',
    'रज्जुमार्ग',
    'रझना',
    'रट',
    'रटंत',
    'रटते',
    'रटन',
    'रटना',
    'रटनाहाश',
    'रटने',
    'रटाई',
    'रटित',
    'रट्टा',
    'रट्टू',
    'रडर',
    'रडुआ',
    'रण',
    'रणक',
    'रणकामी',
    'रणकारी',
    'रणकुशल',
    'रणक्षेत्र',
    'रणचंडी',
    'रणछोड़',
    'रणत्कार',
    'रणदुर्गा',
    'रणधीर',
    'रणन',
    'रणनीति',
    'रणप्रिय',
    'रणभूमि',
    'रणवीर',
    'रणसिंगा',
    'रणांगन',
    'रणित',
    'रणेश',
    'रणोत्कट',
    'रणोनमाद',
    'रत',
    'रतजगा',
    'रतता',
    'रतन',
    'रतनारा',
    'रति',
    'रतिक',
    'रतिगर',
    'रतिगृह',
    'रतिज',
    'रतिनाथ',
    'रतिप्रिय',
    'रतिप्रियता',
    'रतिप्रिया',
    'रतिमदा',
    'रतिया',
    'रतिरमण',
    'रतिराज',
    'रतिरोग',
    'रतिरोगविज्ञानी',
    'रतिरोगी',
    'रतिवर',
    'रतिशील',
    'रतीश',
    'रतौंधी',
    'रत्ती',
    'रत्तीः',
    'रत्तीभर',
    'रत्न',
    'रत्नः',
    'रत्नकीर्ति',
    'रत्नकूट',
    'रत्नगर्भ',
    'रत्नगर्भा',
    'रत्नगिरी',
    'रत्नगृह',
    'रत्नजटित',
    'रत्नदामा',
    'रत्नद्वीप',
    'रत्नबाहु',
    'रत्नमाला',
    'रत्नहाट',
    'रत्नाकर',
    'रत्नागार',
    'रत्नागिरी',
    'रत्नाधिपति',
    'रत्नाभूषित',
    'रत्नालंकृत',
    'रत्नावली',
    'रत्नेश',
    'रत्नों',
    'रत्नोपल',
    'रत्र',
    'रथ',
    'रथक्ष',
    'रथगर्भक',
    'रथपति',
    'रथमार्ग',
    'रथयंता',
    'रथवज़',
    'रथवान',
    'रथवाह',
    'रथहीन',
    'रथाग्र',
    'रथायतन',
    'रथारुड़',
    'रथारोही',
    'रथिक',
    'रथी',
    'रथोत्सव',
    'रथ्य',
    'रथ्या',
    'रद',
    'रदच्छद',
    'रदन',
    'रदनक',
    'रदना',
    'रदा',
    'रदाई',
    'रदित',
    'रदिया',
    'रदी',
    'रदीफ',
    'रद्द',
    'रद्दा',
    'रद्दी',
    'रद्दीकृत',
    'रद्दीखाना',
    'रद्दीपन',
    'रद्दीमाल',
    'रद्दो',
    'रधरण',
    'रधिया',
    'रधिरल',
    'रन',
    'रनकना',
    'रनबंका',
    'रनर',
    'रनवे',
    'रनहारो',
    'रना',
    'रनिवास',
    'रनी',
    'रन्दा',
    'रप',
    'रपट',
    'रपटन',
    'रपटना',
    'रपट्टा',
    'रपयत्त',
    'रपर',
    'रपवाद',
    'रप्रहर',
    'रफ',
    'रफल',
    'रफा',
    'रफीदा',
    'रफू',
    'रफूगरी',
    'रफ्त',
    'रफ्ता',
    'रफ्तार',
    'रब',
    'रबड़',
    'रबड़ः',
    'रबड़ी',
    'रबत',
    'रबर',
    'रबाब',
    'रबाबिया',
    'रबी',
    'रबीउल',
    'रब्त',
    'रब्बा',
    'रब्बाब',
    'रब्बी',
    'रब्बुल',
    'रभस',
    'रम',
    'रमक',
    'रमकना',
    'रमज़ान',
    'रमज़ानी',
    'रमण',
    'रमणक',
    'रमणा',
    'रमणी',
    'रमणीक',
    'रमणीय',
    'रमणीयता',
    'रमता',
    'रमति',
    'रमन',
    'रमनक',
    'रमना',
    'रमल',
    'रमा',
    'रमाकांत',
    'रमान',
    'रमाना',
    'रमानी',
    'रमापति',
    'रमित',
    'रमी',
    'रमूज',
    'रमेश',
    'रमेश्वर',
    'रमैती',
    'रमैया',
    'रम्माल',
    'रम्मी',
    'रम्य',
    'रय',
    'रयल्टी',
    'रयेन',
    'ररक',
    'ररकना',
    'रर्रा',
    'रलना',
    'रलपथ',
    'रली',
    'रल्ला',
    'रव',
    'रवण',
    'रवत्रा',
    'रवना',
    'रवा',
    'रवाँ',
    'रवानगी',
    'रवाना',
    'रवानी',
    'रवानीदार',
    'रवाब',
    'रवायत',
    'रवायती',
    'रवारवी',
    'रवि',
    'रविकुल',
    'रविज',
    'रविजा',
    'रविनेत्र',
    'रविन्द्र',
    'रविप्रिय',
    'रविमार्ग',
    'रविलोचन',
    'रविलौह',
    'रविवार',
    'रविश',
    'रविसुत',
    'रवीन्द्र',
    'रवीषु',
    'रवेदार',
    'रवैया',
    'रशना',
    'रशा',
    'रश्क',
    'रस',
    'रसः',
    'रसक',
    'रसकामी',
    'रसकोरा',
    'रसगल्ला',
    'रसगुल्ला',
    'रसघ्न',
    'रसज',
    'रसज्ञ',
    'रसज्ञा',
    'रसज्येष्ठ',
    'रसद',
    'रसदार',
    'रसधन',
    'रसना',
    'रसनेद्रिय',
    'रसपुष्प',
    'रसपूर्ण',
    'रसपूर्णता',
    'रसप्रद',
    'रसबोध',
    'रसभरी',
    'रसभोग',
    'रसभोगी',
    'रसमय',
    'रसमसा',
    'रसमायिक',
    'रसयुक्त',
    'रसरंग',
    'रसरा',
    'रसराज',
    'रसरैना',
    'रसल',
    'रसलोह',
    'रसवंत',
    'रसवंती',
    'रसवता',
    'रसवती',
    'रसवान',
    'रसव्य',
    'रसशील',
    'रसहीन',
    'रसहीनता',
    'रसा',
    'रसाँ',
    'रसाई',
    'रसाढय',
    'रसातल',
    'रसात्मक',
    'रसात्मकं',
    'रसात्मकता',
    'रसानुभूति',
    'रसाभाव',
    'रसाभास',
    'रसाभोग',
    'रसाम्ल',
    'रसायन',
    'रसायनज्ञ',
    'रसायनविद',
    'रसायनिक',
    'रसायनिकीविद',
    'रसायनी',
    'रसायनों',
    'रसायशेष',
    'रसाल',
    'रसालता',
    'रसालय',
    'रसाला',
    'रसाली',
    'रसावर',
    'रसावेष्ट',
    'रसाव्मा',
    'रसाशी',
    'रसास्वादन',
    'रसिक',
    'रसिकता',
    'रसिका',
    'रसित्र',
    'रसिद',
    'रसिया',
    'रसियापन',
    'रसियाव',
    'रसी',
    'रसीद',
    'रसीदी',
    'रसीदें',
    'रसीदों',
    'रसीय',
    'रसीला',
    'रसीलापन',
    'रसूख',
    'रसूम',
    'रसूल',
    'रसूली',
    'रसूलुल्लाह',
    'रसेदार',
    'रसेश',
    'रसोइया',
    'रसोई',
    'रसोईघर',
    'रसोईदार',
    'रसोईदारिन',
    'रसोक्रियामापी',
    'रसोद्रेक',
    'रसौट',
    'रसौली',
    'रस्क',
    'रस्ट',
    'रस्ता',
    'रस्म',
    'रस्मन',
    'रस्मी',
    'रस्य',
    'रस्सा',
    'रस्साकशी',
    'रस्सियों',
    'रस्सी',
    'रस्सीः',
    'रस्से',
    'रह',
    'रहँकला',
    'रहँचटा',
    'रहँट',
    'रहँटा',
    'रहँटी',
    'रहकर',
    'रहगुज़र',
    'रहचटा',
    'रहट',
    'रहड़ा',
    'रहड़ी',
    'रहता',
    'रहती',
    'रहते',
    'रहन',
    'रहना',
    'रहनी',
    'रहनुमा',
    'रहनुमाई',
    'रहने',
    'रहनेवाले',
    'रहपटा',
    'रहबर',
    'रहबरी',
    'रहम',
    'रहमान',
    'रहल',
    'रहवाल',
    'रहवासी',
    'रहसि',
    'रहस्य',
    'रहस्यज्ञ',
    'रहस्यपूर्ण',
    'रहस्यपूर्णता',
    'रहस्यपूर्वक',
    'रहस्यमय',
    'रहस्यमयता',
    'रहस्यवाद',
    'रहस्यवादी',
    'रहस्यहीन',
    'रहस्यहीनता',
    'रहस्यात्मक',
    'रहस्यात्मकता',
    'रहस्योक्ति',
    'रहस्योद्घाटक',
    'रहस्योद्घाटन',
    'रहा',
    'रहाट',
    'रहित',
    'रहिततः',
    'रहितता',
    'रहिवास',
    'रही',
    'रहीम',
    'रहूंगा',
    'रहूंगी',
    'रहे',
    'रहें',
    'रहेंगी',
    'रहेंगे',
    'रहेगा',
    'रहेगी',
    'रहेगें',
    'रहो',
    'राँगा',
    'राँची',
    'राँजना',
    'राँटा',
    'राँड',
    'राँध',
    'राँधना',
    'रांची',
    'रांदेवू',
    'रांधना',
    'राइ',
    'राइट',
    'राइटहैंड',
    'राइटिंग',
    'राइफल',
    'राइस',
    'राइफ़ल्स',
    'राई',
    'राउंड',
    'राउत',
    'राउर',
    'राउरा',
    'राउरी',
    'राकंक',
    'राकंनडम',
    'राकड़',
    'राकना',
    'राका',
    'राकापति',
    'राकिंग',
    'राकेट',
    'राकेटन',
    'राकेटर',
    'राकेटाग',
    'राकेश',
    'राक्षय',
    'राक्षस',
    'राक्षसकाय',
    'राक्षसता',
    'राक्षसनी',
    'राक्षसी',
    'राक्षसीय',
    'राक्षसीयता',
    'राक्षसोचितता',
    'राख',
    'राखदानी',
    'राखनहार',
    'राखना',
    'राखा',
    'राखी',
    'राग',
    'रागदार',
    'रागदारी',
    'रागपूर्ण',
    'रागपूर्वक',
    'रागबद्घ',
    'रागमय',
    'रागमयता',
    'रागमुक्त',
    'रागरंग',
    'रागव्य',
    'रागहीन',
    'रागहीनता',
    'रागांग',
    'रागात्मक',
    'रागानुराग',
    'रागान्वयक',
    'रागिनी',
    'रागी',
    'राघव',
    'राघवेंद्र',
    'राचक',
    'राचना',
    'राछ',
    'राज',
    'राजः',
    'राजक',
    'राजकता',
    'राजकन्या',
    'राजकर',
    'राजकल',
    'राजकाज',
    'राजकाल',
    'राजकीय',
    'राजकुँवर',
    'राजकुँवरी',
    'राजकुमार',
    'राजकुमारी',
    'राजकुल',
    'राजकोट',
    'राजकोष',
    'राजकोषीय',
    'राजगद्दी',
    'राजगमन',
    'राजगामी',
    'राजगिरी',
    'राजगी',
    'राजगीर',
    'राजचिह्ल',
    'राजछत्र',
    'राजतंत्र',
    'राजतंत्रः',
    'राजतंत्रवाद',
    'राजतंत्रवादी',
    'राजतंत्रात्मक',
    'राजतंत्री',
    'राजतक',
    'राजतिलक',
    'राजत्व',
    'राजदंड',
    'राजदान',
    'राजदानी',
    'राजदार',
    'राजदारी',
    'राजदूत',
    'राजदूतावास',
    'राजदूतीय',
    'राजदूर्वा',
    'राजदेय',
    'राजद्रोह',
    'राजद्रोहात्मक',
    'राजद्रोहात्मकता',
    'राजद्रोही',
    'राजद्रोहीपन',
    'राजधातु',
    'राजधानी',
    'राजन',
    'राजनय',
    'राजनयिक',
    'राजना',
    'राजनिष्ठ',
    'राजनीति',
    'राजनीतिः',
    'राजनीतिक',
    'राजनीतिज्ञ',
    'राजनीतिज्ञों',
    'राजनीतिशास्त्र',
    'राजनेता',
    'राजनैतिक',
    'राजन्य',
    'राजपत्नी',
    'राजपत्र',
    'राजपत्रित',
    'राजपथ',
    'राजपरिवार',
    'राजपरिषद',
    'राजपाट',
    'राजपाल',
    'राजपुत्र',
    'राजपुत्रा',
    'राजपुत्री',
    'राजपूज्य',
    'राजपूत',
    'राजपूताना',
    'राजपूती',
    'राजबाहा',
    'राजबीजी',
    'राजभक्त',
    'राजभक्ति',
    'राजभवन',
    'राजभाषा',
    'राजमर्मज्ञ',
    'राजमहाल',
    'राजमा',
    'राजमाता',
    'राजमार्ग',
    'राजमाष',
    'राजमाह',
    'राजमुकुट',
    'राजय',
    'राजयक्ष्मा',
    'राजयपाल',
    'राजयष्टि',
    'राजयादेश',
    'राजयान',
    'राजयोग',
    'राजराज',
    'राजरोग',
    'राजर्षि',
    'राजवंश',
    'राजवंशज',
    'राजवंशी',
    'राजवंशीय',
    'राजवंश्य',
    'राजवर्चस्व',
    'राजवर्त',
    'राजवीथी',
    'राजवीर',
    'राजवृक्ष',
    'राजशक्ति',
    'राजशाक',
    'राजशासन',
    'राजशाही',
    'राजशुल्क',
    'राजस',
    'राजसंपदा',
    'राजसत्ता',
    'राजसिक',
    'राजसिकता',
    'राजसी',
    'राजसूय',
    'राजस्थली',
    'राजस्थान',
    'राजस्थानी',
    'राजस्व',
    'राजस्वदायक',
    'राजहंस',
    'राज़ी',
    'राज़ीनामा',
    'राजा',
    'राजाः',
    'राजाओं',
    'राजाज्ञा',
    'राजात्र',
    'राजादन',
    'राजादेश',
    'राजाधिकार',
    'राजाधिराज',
    'राजाधीन',
    'राजानक',
    'राजापहरण',
    'राजाम्र',
    'राजायुक्त',
    'राजार्ह',
    'राजावार',
    'राजाहीन',
    'राजिका',
    'राजित',
    'राजिव',
    'राजिस्टरपाल',
    'राजी',
    'राजीव',
    'राजीवलोचन',
    'राजीवलोचना',
    'राजीविनी',
    'राजुक',
    'राजू',
    'राजे',
    'राजेन्द्र',
    'राजेश',
    'राजेश्वर',
    'राजेश्वरी',
    'राजेष्ट',
    'राजोचित',
    'राजोपकरण',
    'राज्ञी',
    'राज्य',
    'राज्यः',
    'राज्यकरण',
    'राज्यक्षेत्र',
    'राज्यच्युत',
    'राज्यपाल',
    'राज्यभिषेक',
    'राज्यरोहण',
    'राज्यश्री',
    'राज्यसंघवाद',
    'राज्यसभा',
    'राज्यस्तरीय',
    'राज्यांग',
    'राज्यांतरिक',
    'राज्याधिकार',
    'राज्याध्यक्ष',
    'राज्यापहरण',
    'राज्याभिषेक',
    'राज्यारुढ',
    'राज्यारोहण',
    'राज्यासन',
    'राज्यीय',
    'राज्येतर',
    'राज्यों',
    'राज्योपकरण',
    'राटुल',
    'राठ',
    'राठोर',
    'राड',
    'राडार',
    'राडारस्थल',
    'राणा',
    'रात',
    'रातः',
    'रातडी',
    'रातना',
    'रातभर',
    'राता',
    'राति',
    'रातिब',
    'राती',
    'रातुल',
    'रातों',
    'रात्र',
    'रात्रि',
    'रात्रिंचर',
    'रात्रिःपहरेदार',
    'रात्रिक',
    'रात्रिकालीन',
    'रात्रिचर',
    'रात्रिभोज',
    'रात्रीय',
    'राद्घ',
    'राध',
    'राधक',
    'राधन',
    'राधना',
    'राधा',
    'राधाकांत',
    'राधारमण',
    'राधिका',
    'राधित',
    'राधित्र',
    'राधे',
    'राधेय',
    'राध्य',
    'रान',
    'राना',
    'रानाई',
    'रानी',
    'रानीत्व',
    'रानीपद',
    'राने',
    'रापित',
    'राप्ती',
    'राब',
    'राबना',
    'राम',
    'रामकली',
    'रामकालीन',
    'रामचंद्र',
    'रामचक्र',
    'रामचन्द्रन',
    'रामचन्द्रा',
    'रामजननी',
    'रामजना',
    'रामजनी',
    'रामजन्म',
    'रामझोल',
    'रामठ',
    'रामदास',
    'रामदूत',
    'रामन',
    'रामनामीदर',
    'रामपुर',
    'रामप्रिया',
    'रामबाण',
    'रामभद्र',
    'रामरस',
    'रामराज्य',
    'रामलीला',
    'रामसखा',
    'रामसर',
    'रामा',
    'रामांटिक',
    'रामाकृष्ण',
    'रामाचन्द्रन',
    'रामानुज',
    'रामानुजवाद',
    'रामाप्रिय',
    'रामायण',
    'रामी',
    'रामू',
    'रामेश्वर',
    'रामेष्ठा',
    'राय',
    'रायजादा',
    'रायजादी',
    'रायता',
    'रायपुर',
    'रायमुनी',
    'रायल',
    'रायल्टी',
    'रार',
    'रारी',
    'राल',
    'राव',
    'रावट',
    'रावटी',
    'रावण',
    'रावणारि',
    'रावणि',
    'रावत',
    'रावनिया',
    'रावरा',
    'रावरी',
    'रावल',
    'रावी',
    'राशन',
    'राशनबंदी',
    'राशनिंग',
    'राशि',
    'राशिकरण',
    'राशिकृत',
    'राशिगत',
    'राशिचक्र',
    'राशिचक्रः',
    'राशियां',
    'राशियों',
    'राशिसूची',
    'राशीकृत',
    'राशीय',
    'राश्यंतरण',
    'राष्ट',
    'राष्टत्व',
    'राष्टनिष्ठ',
    'राष्टप्रेम',
    'राष्टप्रेमी',
    'राष्टाध्यक्ष',
    'राष्टीय',
    'राष्टीयतापत्र',
    'राष्ट्र',
    'राष्ट्रगान',
    'राष्ट्रपति',
    'राष्ट्रभाषा',
    'राष्ट्रमंडल',
    'राष्ट्रमूलक',
    'राष्ट्रवाद',
    'राष्ट्रवादी',
    'राष्ट्राध्यक्ष',
    'राष्ट्रिक',
    'राष्ट्रीतकृत',
    'राष्ट्रीय',
    'राष्ट्रीयकरण',
    'राष्ट्रीयकृत',
    'राष्ट्रीयता',
    'रास',
    'रासनशीन',
    'रासभ',
    'रासभी',
    'रासरंग',
    'रासायनिक',
    'रासायनिकी',
    'रासिख',
    'रासी',
    'रासेश्वरी',
    'रासो',
    'रासोत्सव',
    'रास्त',
    'रास्ता',
    'रास्ते',
    'रास्तें',
    'रास्तों',
    'राह',
    'राहखर्च',
    'राहगीर',
    'राहगुजर',
    'राहचाह',
    'राहजन',
    'राहत',
    'राहती',
    'राहदारी',
    'राहबर',
    'राहबरी',
    'राहरस्म',
    'राहरीति',
    'राहिण',
    'राहिणीश',
    'राहित्य',
    'राहिन',
    'राही',
    'राहु',
    'राहुः',
    'राहुग्ररस्त',
    'राहुल',
    'राहे',
    'राहों',
    'राज़ी',
    'राज़ीनामा',
    'रिंक',
    'रिंग',
    'रिंगन',
    'रिंच',
    'रिंद',
    'रिंदी',
    'रिआयत',
    'रिआयती',
    'रिआया',
    'रिकमंडेशन',
    'रिकवैछ',
    'रिकशा',
    'रिकाब',
    'रिकार्ड',
    'रिकार्डर',
    'रिकार्डार्कार',
    'रिकार्डिंग',
    'रिकार्डिग',
    'रिकार्डिस्ट',
    'रिकॉर्ड',
    'रिकॉर्डकर्ता',
    'रिक्त',
    'रिक्तका',
    'रिक्तता',
    'रिक्तन',
    'रिक्तम',
    'रिक्तमा',
    'रिक्ता',
    'रिक्ति',
    'रिक्तिकरण',
    'रिक्तिमय',
    'रिक्तियों',
    'रिक्तीकरण',
    'रिक्थ',
    'रिक्थभागी',
    'रिक्थाधिकार',
    'रिक्थाधिकारी',
    'रिक्थाधिकारीहीन',
    'रिक्थीय',
    'रिक्शा',
    'रिक्षर',
    'रिक्षा',
    'रिग',
    'रिगिंग',
    'रिचंद्रा',
    'रिचा',
    'रिजर्व',
    'रिजर्वेशन',
    'रिज़ता',
    'रिज़र्व',
    'रिज़ल्ट',
    'रिज़वेरशन',
    'रिज़व्डर्',
    'रिज़ौल्व्ड',
    'रिझकवार',
    'रिझवार',
    'रिझाना',
    'रिझाव',
    'रिट',
    'रिटायर',
    'रिटायरमैंट',
    'रिटायरिंग',
    'रिटेल',
    'रिटेलर',
    'रिट्रीट',
    'रिड़कना',
    'रित',
    'रितंभरण',
    'रितना',
    'रिताई',
    'रिताना',
    'रिति',
    'रितु',
    'रितुरज',
    'रित्वीय',
    'रिद्घ',
    'रिद्धि',
    'रिद्म',
    'रिपट',
    'रिपब्लिकन',
    'रिपिट',
    'रिपिब्लक',
    'रिपिब्लकन',
    'रिपु',
    'रिपुजय',
    'रिपुजेता',
    'रिपुता',
    'रिपुदमन',
    'रिपुध्न',
    'रिपुनाशन',
    'रिपेअर',
    'रिपोर्ट',
    'रिपोर्टर',
    'रिपोर्टिंग',
    'रिपोर्टें',
    'रिफ़ौर्मेशनः',
    'रिफाइनरियों',
    'रिफाइनरी',
    'रिफाइनरीज',
    'रिफाइनरीयां',
    'रिफार्मै',
    'रिफेंटा',
    'रिफोर्म',
    'रिफौर्मेटरी',
    'रिफ्यूजी',
    'रिबन',
    'रिबाउंड',
    'रिबाउंस',
    'रिभु',
    'रिम',
    'रिमझिम',
    'रिमांड',
    'रिमाईडिंग',
    'रिया',
    'रियाज़',
    'रियायत',
    'रियायतग्राही',
    'रियायती',
    'रियायतें',
    'रियासत',
    'रियासती',
    'रिरियाना',
    'रिरिहा',
    'रिर्हर्सल',
    'रिलना',
    'रिलीज',
    'रिलीज़',
    'रिलीफ',
    'रिलेटिविटी',
    'रिवट',
    'रिवटन',
    'रिवटाई',
    'रिवटिंग',
    'रिवटिड',
    'रिवटित',
    'रिवाज़',
    'रिवाज़ी',
    'रिवायत',
    'रिवायती',
    'रिवाल्वर',
    'रिवाज़',
    'रिवोल्विंग',
    'रिवौल्विंग',
    'रिश्ता',
    'रिश्ते',
    'रिश्तेदार',
    'रिश्तेदारी',
    'रिश्तेनातेदारी',
    'रिश्तेमंद',
    'रिश्म',
    'रिश्यत',
    'रिश्वत',
    'रिश्वतखोर',
    'रिश्वतखोरी',
    'रिषभ',
    'रिषि',
    'रिष्टि',
    'रिसकन',
    'रिसच्र',
    'रिसतत्व',
    'रिसता',
    'रिसन',
    'रिसना',
    'रिसनेवाला',
    'रिसर्चर',
    'रिसहा',
    'रिसा',
    'रिसाई',
    'रिसालत',
    'रिसालदार',
    'रिसाला',
    'रिसाव',
    'रिसीट',
    'रिसीवर',
    'रिसैस',
    'रिस्ट',
    'रिहड़ी',
    'रिहर्सल',
    'रिहा',
    'रिहाइश',
    'रिहाइशी',
    'रिहाई',
    'रिहायश',
    'रिहायशी',
    'रिज़र्व',
    'रीअर',
    'रीअलाइज़ेशन',
    'रीइंबर्स्ड',
    'रीइंबस्र',
    'रीक',
    'रीकरिंग',
    'रीकाल',
    'रीख़',
    'रीछ',
    'रीछनी',
    'रीछराज',
    'रीजैंट',
    'रीजैक्शन',
    'रीजैट',
    'रीझ',
    'रीझना',
    'रीट्रैचमैंट',
    'रीठा',
    'रीड',
    'रीडक्शन',
    'रीडर',
    'रीडिंग',
    'रीडैप्शन',
    'रीढ़',
    'रीढ़ः',
    'रीढ़क',
    'रीढ़ास्थि',
    'रीतना',
    'रीता',
    'रीतापन',
    'रीति',
    'रीतिगत',
    'रीतिपूर्वक',
    'रीतिबद्ध',
    'रीतियां',
    'रीतियों',
    'रीतिवाद',
    'रीतिहीनता',
    'रीतीय',
    'रीत्यनुकूल',
    'रीत्यनुसार',
    'रीत्यात्मक',
    'रीप्रिंट',
    'रीफ़',
    'रीफ़िल',
    'रीफैक्टर',
    'रीफ्रैक्टिड',
    'रीफ्रैक्शन',
    'रीफ्लैक्टर',
    'रीफ्लैक्शन',
    'रीम',
    'रीमः',
    'रील',
    'रीवसों',
    'रीवैन्यु',
    'रीवैन्यू',
    'रीस',
    'रीसना',
    'रीसैप्शन',
    'रीसैप्शनिस्ट',
    'रीह',
    'रीही',
    'रुँझना',
    'रुँझा',
    'रुँझापन',
    'रुँदना',
    'रुँदा',
    'रुँदाई',
    'रुँधन',
    'रुँधना',
    'रुँधा',
    'रुँधापन',
    'रुंड',
    'रुंधना',
    'रुआ',
    'रुआँ',
    'रुआफ',
    'रुआब',
    'रुआली',
    'रुई',
    'रुएँदार',
    'रुक',
    'रुकंत',
    'रुकते',
    'रुकना',
    'रुकने',
    'रुका',
    'रुकाई',
    'रुकापन',
    'रुकाव',
    'रुकावट',
    'रुकी',
    'रुके',
    'रुको',
    'रुक्',
    'रुक्का',
    'रुक्न',
    'रुक्म',
    'रुक्मरथ',
    'रुक्मवतीः',
    'रुक्मिणी',
    'रुक्मिणीः',
    'रुक्मीदारण',
    'रुक्ष',
    'रुक्षता',
    'रुख',
    'रुखड़ा',
    'रुख़',
    'रुख़दार',
    'रुख़सत',
    'रुख़सती',
    'रुख़सार',
    'रुख़ाई',
    'रुख़ापन',
    'रुखा',
    'रुखाई',
    'रुखापन',
    'रुखुरी',
    'रुग्ण',
    'रुग्णकार',
    'रुग्णता',
    'रुग्णमनस्क',
    'रुग्णालय',
    'रुचक',
    'रुचना',
    'रुचा',
    'रुचि',
    'रुचिकर',
    'रुचिकरता',
    'रुचिकारी',
    'रुचिता',
    'रुचिप्रद',
    'रुचियुक्त',
    'रुचियों',
    'रुचिर',
    'रुचिरता',
    'रुचिरा',
    'रुचिराई',
    'रुचिवान',
    'रुचिहीन',
    'रुच्य',
    'रुज',
    'रुजापह',
    'रुझान',
    'रुट',
    'रुटीन',
    'रुठ',
    'रुठन',
    'रुठना',
    'रुठा',
    'रुठाराठी',
    'रुठारुठी',
    'रुढ़',
    'रुढ़या',
    'रुढ़ि',
    'रुढ़ी',
    'रुढ़ीगत',
    'रुढार्ध',
    'रुढि',
    'रुढिगत',
    'रुढिगतता',
    'रुढितः',
    'रुढिवादी',
    'रुणित',
    'रुत',
    'रुतबा',
    'रुतबेदार',
    'रुद',
    'रुदन',
    'रुदनशील',
    'रुदाद',
    'रुद्घ',
    'रुद्घता',
    'रुद्घाकांक्षा',
    'रुद्र',
    'रुद्रगभ्र्',
    'रुद्रज',
    'रुद्रता',
    'रुद्रतेज',
    'रुद्रनाथ',
    'रुद्रवान',
    'रुद्रसख',
    'रुद्रा',
    'रुद्राक्ष',
    'रुद्राणी',
    'रुद्रारि',
    'रुद्रावास',
    'रुधिर',
    'रुधिरप',
    'रुधिराक्त',
    'रुधिरालय',
    'रुधिरीय',
    'रुनकझुनक',
    'रुनझुन',
    'रुनाई',
    'रुनुमाई',
    'रुप',
    'रुपए',
    'रुपक',
    'रुपककार',
    'रुपगर्वी',
    'रुपगविर्ता',
    'रुपगव्रिणी',
    'रुपजीवी',
    'रुपट्टा',
    'रुपण',
    'रुपधर',
    'रुपपति',
    'रुपमती',
    'रुपमत्ता',
    'रुपमान',
    'रुपया',
    'रुपयाः',
    'रुपयुक्त',
    'रुपये',
    'रुपयों',
    'रुपरेखा',
    'रुपवंत',
    'रुपवत्ता',
    'रुपवान',
    'रुपशाली',
    'रुपसी',
    'रुपसे',
    'रुपहला',
    'रुपहलापन',
    'रुपहीन',
    'रुपहीनता',
    'रुपा',
    'रुपांक',
    'रुपांकन',
    'रुपांतर',
    'रुपांतरण',
    'रुपांतरणीय',
    'रुपांतरित',
    'रुपाई',
    'रुपाकार',
    'रुपाकारयुक्त',
    'रुपाकारयुक्तता',
    'रुपाकारहीन',
    'रुपाकृत',
    'रुपाकृति',
    'रुपाजीवा',
    'रुपातरित',
    'रुपाध्यक्ष',
    'रुपान्तर',
    'रुपाभिमान',
    'रुपामक्खी',
    'रुपामाखी',
    'रुपायित',
    'रुपित',
    'रुपी',
    'रुपैया',
    'रुपोश',
    'रुप्',
    'रुप्य',
    'रुप्यक',
    'रुप्यरत्न',
    'रुबरु',
    'रुबल',
    'रुबाब',
    'रुबी',
    'रुम',
    'रुमग्अ',
    'रुमझुम',
    'रुमना',
    'रुमान',
    'रुमानी',
    'रुमाल',
    'रुमाली',
    'रुरु',
    'रुरूआब',
    'रुल',
    'रुलन',
    'रुलना',
    'रुलर',
    'रुलाई',
    'रुलेट',
    'रुल्ला',
    'रुवरुप',
    'रुष्ट',
    'रुष्टता',
    'रुष्टपुष्ट',
    'रुष्टा',
    'रुष्टि',
    'रुसना',
    'रुसवा',
    'रुसवाई',
    'रुसारासी',
    'रुसी',
    'रुह',
    'रुहानी',
    'रूंड',
    'रूई',
    'रूकना',
    'रूकावट',
    'रूक्का',
    'रूक्ष',
    'रूक्षता',
    'रूखा',
    'रूखाई',
    'रूखानी',
    'रूखापन',
    'रूखी',
    'रूग्ण',
    'रूग्णता',
    'रूचि',
    'रूटीन',
    'रूढ़',
    'रूढ़ार्थ',
    'रूढ़ि',
    'रूढ़िवादी',
    'रूढ़ी',
    'रूढ़ीगत',
    'रूढ़ीबद्ध',
    'रूढ़ीवाद',
    'रूढ़ीवादिता',
    'रूढ़ीवादी',
    'रूदन',
    'रूद्घ',
    'रूद्घकंठ',
    'रूद्घता',
    'रूद्र',
    'रूधिर',
    'रूधिरयुक्त',
    'रूधिरहीन',
    'रूप',
    'रूपक',
    'रूपकथा',
    'रूपकार',
    'रूपग्राम',
    'रूपप्रक्रिया',
    'रूपया',
    'रूपरेखा',
    'रूपवान',
    'रूपविचार',
    'रूपविज्ञान',
    'रूपविधान',
    'रूपसाधन',
    'रूपस्वनिम',
    'रूपहला',
    'रूपहली',
    'रूपांकन',
    'रूपांतर',
    'रूपांतरण',
    'रूपांतरित',
    'रूपाकृति',
    'रूपात्मक',
    'रूपात्मकता',
    'रूपान्तर',
    'रूपान्तरक',
    'रूपान्तरकारी',
    'रूपान्तरण',
    'रूपान्तरित',
    'रूपावली',
    'रूपी',
    'रूपेण',
    'रूम',
    'रूमानी',
    'रूमानीवाद',
    'रूमाल',
    'रूलानेवाला',
    'रूष्ट',
    'रूस',
    'रूसी',
    'रूह',
    'रूिक्मणी',
    'रॅफू',
    'रे',
    'रेंक',
    'रेंकना',
    'रेंखा',
    'रेंग',
    'रेंगते',
    'रेंगना',
    'रेंगने',
    'रेंज',
    'रेंजर',
    'रेंट',
    'रेंड़न',
    'रेंड़ना',
    'रेंदी',
    'रेक',
    'रेकान',
    'रेकार्ड',
    'रेख',
    'रेखक',
    'रेखण',
    'रेखन',
    'रेखना',
    'रेख़ता',
    'रेखा',
    'रेखांकन',
    'रेखांकित',
    'रेखांश',
    'रेखांशिक',
    'रेखाः',
    'रेखाएं',
    'रेखाओं',
    'रेखाकृति',
    'रेखागणित',
    'रेखागणितीय',
    'रेखाचित्र',
    'रेखापथ',
    'रेखावृत्त',
    'रेखिक',
    'रेखिका',
    'रेखित',
    'रेखीय',
    'रेख्ता',
    'रेगना',
    'रेगमाल',
    'रेगाई',
    'रेगिस्तान',
    'रेगिस्तानी',
    'रेगुलेशन',
    'रेचक',
    'रेचन',
    'रेच्य',
    'रेजगारी',
    'रेज़ा',
    'रेजा',
    'रेजि',
    'रेजिमेंटल',
    'रेट',
    'रेटिंग',
    'रेटी',
    'रेड',
    'रेड़',
    'रेडियस',
    'रेडियो',
    'रेडियोग्राम',
    'रेडियोधर्मी',
    'रेडू',
    'रेणु',
    'रेणुका',
    'रेणुसार',
    'रेणू',
    'रेत',
    'रेतः',
    'रेतघड़ी',
    'रेतना',
    'रेता',
    'रेताई',
    'रेती',
    'रेतीला',
    'रेतीली',
    'रेनकोट',
    'रेनडियर',
    'रेनबसेरा',
    'रेनी',
    'रेनु',
    'रेफरी',
    'रेफ्रिजरेटर',
    'रेफ्रीजरेटर',
    'रेयन',
    'रेल',
    'रेलगाड़ी',
    'रेलगाडी',
    'रेलठेल',
    'रेलना',
    'रेलपथ',
    'रेलपेल',
    'रेलम',
    'रेलमार्ग',
    'रेलवे',
    'रेला',
    'रेलिंग',
    'रेलेवे',
    'रेलों',
    'रेवट',
    'रेवड',
    'रेवत',
    'रेवतक',
    'रेवती',
    'रेवतीभव',
    'रेवा',
    'रेश',
    'रेशम',
    'रेशमविज्ञानी',
    'रेशमी',
    'रेशमीपन',
    'रेशा',
    'रेशाः',
    'रेशाहीन',
    'रेशियो',
    'रेशे',
    'रेशेदार',
    'रेशेवाली',
    'रेस',
    'रेसकोर्स',
    'रेसतारां',
    'रेसतारान',
    'रेसर',
    'रेसिज्म',
    'रेस्ट्रां',
    'रेस्तराँ',
    'रेह',
    'रेहड़ी',
    'रेहन',
    'रेहनदार',
    'रेहननामा',
    'रेहल',
    'रेज़गारी',
    'रैंच',
    'रैंट',
    'रैंडम',
    'रैंप',
    'रैक',
    'रैकरिंग',
    'रैकिट',
    'रैक्टर',
    'रैक्टेंगल',
    'रैक्सीन',
    'रैखिक',
    'रैखिकता',
    'रैजिडेंस',
    'रैजिडैंट',
    'रैजिमैंट',
    'रैजीडेंस',
    'रैट',
    'रैटिना',
    'रैटिफिकेशन',
    'रैड',
    'रैडम',
    'रैडिकल',
    'रैतुवा',
    'रैत्य',
    'रैन',
    'रैनबसेरा',
    'रैना',
    'रैनी',
    'रैनोवेशन',
    'रैनोवोटिड',
    'रैन्डैन',
    'रैप',
    'रैपिंग',
    'रैपिग',
    'रैपिलका',
    'रैफर',
    'रैफरी',
    'रैफरेंस',
    'रैफर्ड',
    'रैफ्रिजरेटर',
    'रैफ्रिजरेटिग',
    'रैबिक',
    'रैबीज',
    'रैम',
    'रैमुकनया',
    'रैयत',
    'रैली',
    'रैलोल्यूशन',
    'रैवत',
    'रैवत्य',
    'रैशनलाइजेशन',
    'रैशनलिज्म',
    'रैशनलिस्ट',
    'रैसिपी',
    'रैस्ट',
    'रो',
    'रोंए',
    'रोंगटा',
    'रोंगटे',
    'रोंद',
    'रोंदाई',
    'रोंयेदार',
    'रोंयेदारपन',
    'रोआँ',
    'रोआं',
    'रोआंसा',
    'रोआंसापन',
    'रोआंसेपन',
    'रोउ',
    'रोउस्टैड',
    'रोएँदार',
    'रोक',
    'रोकउी',
    'रोकड',
    'रोकड़',
    'रोकड़शेष',
    'रोकडिया',
    'रोकथाम',
    'रोकन',
    'रोकना',
    'रोकनी',
    'रोकने',
    'रोकनेवाला',
    'रोका',
    'रोकिए',
    'रोके',
    'रोको',
    'रोग',
    'रोगकारक',
    'रोगग्रस्त',
    'रोगग्रस्तता',
    'रोगजनक',
    'रोगण्ण',
    'रोगन',
    'रोगनगर',
    'रोगनगरी',
    'रोगनगिरी',
    'रोगनदाग',
    'रोगनाशक',
    'रोगप्रतिकारक',
    'रोगप्रद',
    'रोगभिति',
    'रोगभ्रम',
    'रोगभ्रमी',
    'रोगमुक्ति',
    'रोगराज',
    'रोगवाहक',
    'रोगविज्ञानी',
    'रोगहर',
    'रोगहारी',
    'रोगहीन',
    'रोगहीनता',
    'रोग़न',
    'रोगाणु',
    'रोगाणुनाशी',
    'रोगातुर',
    'रोगात्मक',
    'रोगार्त',
    'रोगी',
    'रोगों',
    'रोगोत्तर',
    'रोगोद्विग्नता',
    'रोगोपचार',
    'रोग्र',
    'रोचक',
    'रोचकता',
    'रोचकताहीन',
    'रोचन',
    'रोचना',
    'रोचनी',
    'रोचि',
    'रोचिणु',
    'रोज',
    'रोजंदार',
    'रोजंदारी',
    'रोजगार',
    'रोजनामचा',
    'रोजनामा',
    'रोजमर्रा',
    'रोजर्मा',
    'रोजवुड',
    'रोज़',
    'रोज़ंदार',
    'रोज़ंदारी',
    'रोज़नामचा',
    'रोज़ा',
    'रोज़ाना',
    'रोज़े',
    'रोजा',
    'रोजाकश',
    'रोजादार',
    'रोजाना',
    'रोजावारी',
    'रोजी',
    'रोजे',
    'रोजों',
    'रोट',
    'रोटका',
    'रोटडा',
    'रोटरी',
    'रोटिका',
    'रोटी',
    'रोटीदार',
    'रोटीदारी',
    'रोटीरोजीवाद',
    'रोटीवाद',
    'रोटेटर',
    'रोड',
    'रोडवेज़',
    'रोड़',
    'रोड़ा',
    'रोड़ी',
    'रोडा',
    'रोडादार',
    'रोडी',
    'रोत',
    'रोता',
    'रोते',
    'रोदन',
    'रोदसी',
    'रोदा',
    'रोद्य',
    'रोध',
    'रोधक',
    'रोधन',
    'रोधना',
    'रोधहीन',
    'रोधहीनतः',
    'रोधहीनता',
    'रोधात्मक',
    'रोधिका',
    'रोधित',
    'रोधित्र',
    'रोधी',
    'रोना',
    'रोने',
    'रोनेवाला',
    'रोप',
    'रोपक',
    'रोपण',
    'रोपना',
    'रोपवे',
    'रोपा',
    'रोपाई',
    'रोपित',
    'रोब',
    'रोबट',
    'रोबदाब',
    'रोबदार',
    'रोबाटीकृत',
    'रोबिन',
    'रोबीला',
    'रोबीलापन',
    'रोबोटीकृत',
    'रोम',
    'रोमंथ',
    'रोमंथक',
    'रोमकूप',
    'रोमज',
    'रोमन',
    'रोमन्थक',
    'रोमन्धी',
    'रोमराजि',
    'रोमल',
    'रोमवाद',
    'रोमशा',
    'रोमहर्षक',
    'रोमहीन',
    'रोमा',
    'रोमां',
    'रोमांच',
    'रोमांचक',
    'रोमांचित',
    'रोमांस',
    'रोमांसवादी',
    'रोमाजिन',
    'रोमान्चकारी',
    'रोमावली',
    'रोमिल',
    'रोमिलता',
    'रोमोद्गम',
    'रोया',
    'रोर',
    'रोल',
    'रोलर',
    'रोला',
    'रोली',
    'रोल्ड',
    'रोवनहार',
    'रोवनहारा',
    'रोवना',
    'रोवां',
    'रोवासा',
    'रोशन',
    'रोशनदान',
    'रोशनदार',
    'रोशनाई',
    'रोशनी',
    'रोष',
    'रोषवती',
    'रोषी',
    'रोहंती',
    'रोहक',
    'रोहण',
    'रोहणक',
    'रोहणी',
    'रोहना',
    'रोहिण',
    'रोहिणी',
    'रोहित',
    'रोहिताश्व',
    'रोहिष',
    'रोही',
    'रोहू',
    'रोज़',
    'रोज़गार',
    'रोज़नामचा',
    'रौंद',
    'रौंदन',
    'रौंदना',
    'रौंदा',
    'रौंदाई',
    'रौकिंग',
    'रौकिट',
    'रौकेट',
    'रौकेटियर',
    'रौकेट्री',
    'रौड',
    'रौद',
    'रौद्र',
    'रौद्रता',
    'रौनक',
    'रौनकदार',
    'रौनकी',
    'रौनके',
    'रौप्य',
    'रौप्यंक',
    'रौप्यता',
    'रौब',
    'रौम',
    'रौर',
    'रौरव',
    'रौल',
    'रौलर',
    'रौला',
    'रौस',
    'रौहिणेय',
    'र्कटी',
    'र्चा',
    'र्ता',
    'र्दान',
    'र्पा',
    'र्भार्गयी',
    'र्मम',
    'र्माच्युत',
    'र्मी',
    'र्वत',
    'र्वधर',
    'र्शली',
    'र्सर्वतोभावेन',
    'र्साजनिकता',
    'र्सामथीर',
    'र्हाक',
    'रक़म',
    'रज़ामंदी',
    'रफ़',
    'रफ़ा',
    'रफ़ू',
    'रफ़ूगर',
    'रफ़ूगरी',
    'रफ़्तार',
    'ल',
    'लँगड़ा',
    'लँगड़ाना',
    'लँगडा',
    'लँगडाहट',
    'लँगोट',
    'लँगोटबंध',
    'लँगोटिया',
    'लँगोटियापन',
    'लंक',
    'लंकद्वीप',
    'लंकनाथ',
    'लंकबर्ण',
    'लंकलाट',
    'लंका',
    'लंकाई',
    'लंकादाही',
    'लंकाधिपति',
    'लंकानाथ',
    'लंकापति',
    'लंकारि',
    'लंकावासी',
    'लंकिनी',
    'लंकीर',
    'लंकेश',
    'लंकेश्वर',
    'लंग',
    'लंगड़ा',
    'लंगड़ाना',
    'लंगड़ापन',
    'लंगड़ी',
    'लंगर',
    'लंगरगाह',
    'लंगरवानी',
    'लंगली',
    'लंगा',
    'लंगूर',
    'लंगूल',
    'लंगोचा',
    'लंगोछा',
    'लंगोट',
    'लंघक',
    'लंघन',
    'लंघनक',
    'लंघा',
    'लंघाई',
    'लंघित',
    'लंच',
    'लंजा',
    'लंठ',
    'लंठाचार्य',
    'लंतरानी',
    'लंदराज',
    'लंधनकारी',
    'लंपट',
    'लंपटता',
    'लंपटतापूर्ण',
    'लंपटा',
    'लंपाक',
    'लंफ',
    'लंब',
    'लंबक',
    'लंबकर्ण',
    'लंबकोणक',
    'लंबग्रीव',
    'लंबत',
    'लंबता',
    'लंबन',
    'लंबनीय',
    'लंबनीयता',
    'लंबमान',
    'लंबर',
    'लंबवृत',
    'लंबा',
    'लंबांश',
    'लंबाई',
    'लंबाकार',
    'लंबाडग',
    'लंबान',
    'लंबापन',
    'लंबायममान',
    'लंबायमान',
    'लंबायमानता',
    'लंबिका',
    'लंबित',
    'लंबितता',
    'लंबी',
    'लंबुक',
    'लंबू',
    'लंबे',
    'लंबोतरा',
    'लंबोदर',
    'लंबोष्ठ',
    'लंभक',
    'लंभयुक्त',
    'लंहगा',
    'लकड़बग्घा',
    'लकड़हारा',
    'लकड़ियों',
    'लकड़ी',
    'लकड़ीः',
    'लकडा',
    'लकडियां',
    'लकडी',
    'लकदक',
    'लकब',
    'लकलक',
    'लकवा',
    'लकसी',
    'लका',
    'लकालक',
    'लकीर',
    'लकुच',
    'लकुट',
    'लकुटिया',
    'लकुटी',
    'लक्कड',
    'लक्का',
    'लक्खन',
    'लक्खी',
    'लक्तक',
    'लक्ष',
    'लक्षण',
    'लक्षणज्ञ',
    'लक्षणता',
    'लक्षणयुक्त',
    'लक्षणहीन',
    'लक्षणा',
    'लक्षणातीत',
    'लक्षणातीतता',
    'लक्षणात्मक',
    'लक्षणान्वित',
    'लक्षणार्थ',
    'लक्षणी',
    'लक्षणीय',
    'लक्षद्वीप',
    'लक्षपति',
    'लक्षा',
    'लक्षित',
    'लक्ष्म',
    'लक्ष्मण',
    'लक्ष्मता',
    'लक्ष्मा',
    'लक्ष्मी',
    'लक्ष्मीकांत',
    'लक्ष्मीगृह',
    'लक्ष्मीनाथ',
    'लक्ष्मीपति',
    'लक्ष्मीपुत्र',
    'लक्ष्मीपुष्प',
    'लक्ष्मीबाई',
    'लक्ष्मीवल्लभ',
    'लक्ष्मीवान',
    'लक्ष्मीश',
    'लक्ष्य',
    'लक्ष्यकुशल',
    'लक्ष्यघ्न',
    'लक्ष्यचूक',
    'लक्ष्यज्ञ',
    'लक्ष्यता',
    'लक्ष्यबिन्दु',
    'लक्ष्यहा',
    'लक्ष्यहीन',
    'लक्ष्यहीनता',
    'लक्ष्यानुगमन',
    'लक्ष्यानुगामी',
    'लक्ष्यानुसार',
    'लक्ष्याभिमुख',
    'लक्ष्यार्थ',
    'लक्ष्येतर',
    'लक्ष्यों',
    'लक्स',
    'लख',
    'लखन',
    'लखनऊ',
    'लखनपाल',
    'लखनवी',
    'लखना',
    'लखनू',
    'लखपति',
    'लखपती',
    'लखाई',
    'लखाना',
    'लखिया',
    'लख्ते',
    'लग',
    'लगजिश',
    'लगता',
    'लगती',
    'लगते',
    'लगदी',
    'लगन',
    'लगनशील',
    'लगना',
    'लगनी',
    'लगने',
    'लगनेवाली',
    'लगभग',
    'लगवाना',
    'लगहर',
    'लगा',
    'लगाइए',
    'लगाई',
    'लगाकर',
    'लगाता',
    'लगातार',
    'लगाते',
    'लगान',
    'लगानदार',
    'लगानदारी',
    'लगाना',
    'लगाने',
    'लगानेवाला',
    'लगापन',
    'लगाभगी',
    'लगाम',
    'लगाया',
    'लगाये',
    'लगालगी',
    'लगाव',
    'लगावट',
    'लगाववाला',
    'लगि',
    'लगी',
    'लगुआ',
    'लगुड',
    'लगून',
    'लगे',
    'लगेंगे',
    'लगेगा',
    'लगेज',
    'लगैया',
    'लगो',
    'लग्गा',
    'लग्गी',
    'लग्गू',
    'लग्गे',
    'लग्घड',
    'लग्घा',
    'लग्न',
    'लग्नक',
    'लग्नतः',
    'लग्नता',
    'लग्नधिपति',
    'लग्नन',
    'लग्नह',
    'लग्नाचार्य',
    'लग्नाधिप',
    'लग्नाधिपति',
    'लग्नेश',
    'लघिमा',
    'लघु',
    'लघुअक्षर',
    'लघुक',
    'लघुकथा',
    'लघुकरण',
    'लघुकाय',
    'लघुकारी',
    'लघुकृत',
    'लघुकृति',
    'लघुगणक',
    'लघुगणकीय',
    'लघुगीत',
    'लघुचक्री',
    'लघुचाप',
    'लघुचित्र',
    'लघुतम',
    'लघुता',
    'लघुतावाची',
    'लघुत्ववाचक',
    'लघुदन्ती',
    'लघुपत्र',
    'लघुपथित',
    'लघुपर्णीय',
    'लघुपाप',
    'लघुपुच्छ',
    'लघुबीजाणु',
    'लघुमना',
    'लघुयुग्मक',
    'लघुवीक्षा',
    'लघुसप्तिर्षि',
    'लचक',
    'लचकदार',
    'लचकन',
    'लचकना',
    'लचका',
    'लचकीला',
    'लचकीलील्',
    'लचकौंहौ',
    'लचदार',
    'लचना',
    'लचर',
    'लचलचा',
    'लचलचाना',
    'लचला',
    'लचीला',
    'लचीलापन',
    'लचीले',
    'लच्छन',
    'लच्छा',
    'लच्छी',
    'लच्छेदार',
    'लछमन',
    'लजाऊ',
    'लजाना',
    'लजालु',
    'लजियाना',
    'लजीज',
    'लजीजा',
    'लजीलापन',
    'लज्जत',
    'लज्जतदार',
    'लज्जा',
    'लज्जाकार',
    'लज्जाजनक',
    'लज्जापूर्ण',
    'लज्जापूर्वक',
    'लज्जाप्रद',
    'लज्जालु',
    'लज्जावश',
    'लज्जावान',
    'लज्जाशील',
    'लज्जाशीलता',
    'लज्जासपद',
    'लज्जाहीन',
    'लज्जाहीनता',
    'लज्जित',
    'लट',
    'लटक',
    'लटकता',
    'लटकती',
    'लटकते',
    'लटकन',
    'लटकना',
    'लटकने',
    'लटकवं',
    'लटकवाँ',
    'लटका',
    'लटकाई',
    'लटकाए',
    'लटकाकर',
    'लटकाना',
    'लटकाने',
    'लटकापन',
    'लटकू',
    'लटकौआ',
    'लटकौवा',
    'लटटू',
    'लटना',
    'लटपट',
    'लटपटा',
    'लटपटाता',
    'लटपटान',
    'लटपटाना',
    'लटर',
    'लटर्न',
    'लटा',
    'लटापटी',
    'लटापोट',
    'लटालह',
    'लटिया',
    'लटी',
    'लटुआ',
    'लटूरा',
    'लटूषक',
    'लट्टू',
    'लट्टे',
    'लट्ठ',
    'लट्ठमार',
    'लट्ठमारबोली',
    'लट्ठा',
    'लट्ठे',
    'लट्ठों',
    'लठ',
    'लठिया',
    'लठैत',
    'लठैती',
    'लड',
    'लडंत',
    'लडकपन',
    'लडका',
    'लडखड',
    'लडखडाऊ',
    'लडखडाता',
    'लडखडाती',
    'लडखडी',
    'लडडू',
    'लडयाना',
    'लड़',
    'लड़का',
    'लड़कियां',
    'लड़कियों',
    'लड़की',
    'लड़के',
    'लड़खड़ाना',
    'लड़खड़ाने',
    'लड़खड़ाहट',
    'लड़ता',
    'लड़ती',
    'लड़ते',
    'लड़ना',
    'लड़ने',
    'लड़बडा',
    'लड़बडी',
    'लड़बावला',
    'लड़बावली',
    'लड़बोरापन',
    'लड़बौरा',
    'लड़बौरी',
    'लड़वालापन',
    'लड़ाई',
    'लड़ाका',
    'लड़ाकापन',
    'लड़ाकी',
    'लड़ाकू',
    'लड़ाना',
    'लड़ी',
    'लडाई',
    'लडी',
    'लडुआ',
    'लडै',
    'लडैत',
    'लडैता',
    'लड्डू',
    'लढा',
    'लढिया',
    'लत',
    'लतखोर',
    'लतखोरी',
    'लतफ्',
    'लतर',
    'लता',
    'लताएं',
    'लतागृह',
    'लताड',
    'लताडना',
    'लताफत',
    'लतामंडप',
    'लतिका',
    'लतियाना',
    'लतियाल',
    'लतिहर',
    'लती',
    'लतीफ',
    'लतीफा',
    'लतीफागाई',
    'लतीफासंजी',
    'लतीफी',
    'लते',
    'लत्तर',
    'लत्ता',
    'लत्ती',
    'लत्ते',
    'लथपथ',
    'लथरवाद',
    'लथेड',
    'लथेडना',
    'लदना',
    'लदवाना',
    'लदा',
    'लदाई',
    'लदाऊ',
    'लदान',
    'लदापन',
    'लदायी',
    'लदाव',
    'लदी',
    'लदुआ',
    'लदौनी',
    'लद्दाख',
    'लद्दू',
    'लद्युकायता',
    'लद्वड',
    'लद्वडपन',
    'लधु',
    'लधुकथा',
    'लधूमिर्',
    'लध्वाकार',
    'लध्वाकारता',
    'लध्वीकरण',
    'लध्वीकृत',
    'लन',
    'लने',
    'लन्दन',
    'लप',
    'लपक',
    'लपकना',
    'लपकी',
    'लपकू',
    'लपक्रूपन',
    'लपचकना',
    'लपझप',
    'लपट',
    'लपटता',
    'लपटन',
    'लपटना',
    'लपटनौना',
    'लपटा',
    'लपटी',
    'लपटौआं',
    'लपटौनी',
    'लपना',
    'लपलप',
    'लपलपा',
    'लपलपाना',
    'लपलपाहट',
    'लपलपी',
    'लपसी',
    'लपालप',
    'लपी',
    'लपूसडा',
    'लपेट',
    'लपेटदार',
    'लपेटन',
    'लपेटना',
    'लपेटने',
    'लपेटवां',
    'लपेटा',
    'लपेटी',
    'लप्पड',
    'लफं',
    'लफंगा',
    'लफंगी',
    'लफडा',
    'लफ्ज',
    'लफ्जी',
    'लफ्फड',
    'लफ्फाजी',
    'लब',
    'लबंदा',
    'लबडना',
    'लबडा',
    'लबडी',
    'लबदी',
    'लबरा',
    'लबरी',
    'लबरेज',
    'लबाड',
    'लबाडिया',
    'लबादा',
    'लबार',
    'लबारिया',
    'लबारी',
    'लबालब',
    'लबों',
    'लब्ध',
    'लब्धका',
    'लब्धचेता',
    'लब्धता',
    'लब्धन',
    'लब्धप्रतिष्ठ',
    'लब्धविद्य',
    'लब्धसंज्ञ',
    'लब्धा',
    'लब्धांक',
    'लब्धि',
    'लब्धिहीन',
    'लब्धिहीनता',
    'लभन',
    'लभ्य',
    'लभ्यता',
    'लम',
    'लमछड',
    'लमतडंग',
    'लमतडंगा',
    'लमतडंगी',
    'लमफेदून',
    'लम्पट',
    'लम्ब',
    'लम्बकोण',
    'लम्बन',
    'लम्बमान',
    'लम्बा',
    'लम्बाई',
    'लम्बाक्ष',
    'लम्बित',
    'लम्बी',
    'लम्बे',
    'लम्हा',
    'लम्हाती',
    'लय',
    'लयन',
    'लयपुर्णता',
    'लयपूर्ण',
    'लयबद्घता',
    'लयहीनता',
    'लयात्मक',
    'लयात्मकता',
    'लये',
    'लरज',
    'लरजना',
    'लरजिश',
    'ललक',
    'ललकना',
    'ललकाना',
    'ललकार',
    'ललकारना',
    'ललकी',
    'ललचन',
    'ललचना',
    'ललचाऊ',
    'ललचान',
    'ललचाना',
    'ललचाया',
    'ललचायी',
    'ललचोंहा',
    'ललचौंही',
    'ललजिह्व',
    'ललन',
    'ललना',
    'ललनाप्रिय',
    'ललरी',
    'ललसना',
    'ललसाना',
    'ललसित',
    'लला',
    'ललाइन',
    'ललाई',
    'ललाट',
    'ललाटिका',
    'ललाम',
    'ललालमता',
    'ललित',
    'ललितता',
    'ललितलोचन',
    'ललितलोचना',
    'ललिता',
    'लली',
    'ललौही',
    'ललौहौ',
    'लल्लन',
    'लल्ला',
    'लल्ली',
    'लल्लू',
    'लव',
    'लवंग',
    'लवंगन',
    'लवण',
    'लवणता',
    'लवणमापी',
    'लवणांतक',
    'लवणालय',
    'लवणित',
    'लवणिम',
    'लवणिमा',
    'लवणीय',
    'लवणोत्तम',
    'लवनी',
    'लवप्रणाली',
    'लवलीन',
    'लवलीनता',
    'लवहर',
    'लवा',
    'लवाक',
    'लवाजमा',
    'लवाजमात',
    'लवाजिमात',
    'लवारा',
    'लवोपल',
    'लश',
    'लशकर',
    'लशकररगाहलशकरी',
    'लश्कर',
    'लश्करी',
    'लस',
    'लसदार',
    'लसधार',
    'लसना',
    'लसलसा',
    'लसलसाहट',
    'लसलसी',
    'लसाना',
    'लसित',
    'लसी',
    'लसीका',
    'लसीला',
    'लसीली',
    'लसोडा',
    'लस्टम',
    'लस्त',
    'लस्तक',
    'लस्तपस्त',
    'लस्पतस्त',
    'लस्सान',
    'लस्सी',
    'लहँगा',
    'लहँडा',
    'लहंगाधारी',
    'लहक',
    'लहकता',
    'लहकती',
    'लहकनलहकना',
    'लहकना',
    'लहकाना',
    'लहजा',
    'लहटोरा',
    'लहन',
    'लहना',
    'लहनी',
    'लहम',
    'लहर',
    'लहरदार',
    'लहरन',
    'लहरना',
    'लहरयुक्त',
    'लहरा',
    'लहराकर',
    'लहराग्र',
    'लहराता',
    'लहराती',
    'लहराना',
    'लहराव',
    'लहरित',
    'लहरितता',
    'लहरिया',
    'लहरियादार',
    'लहरिल',
    'लहरिलता',
    'लहरी',
    'लहरीदार',
    'लहरीला',
    'लहरीली',
    'लहरें',
    'लहरों',
    'लहलह',
    'लहलहा',
    'लहलहाता',
    'लहलहाती',
    'लहलही',
    'लहली',
    'लहसनहीन',
    'लहसुन',
    'लहसुनिया',
    'लहा',
    'लहालह',
    'लहालोट',
    'लहासी',
    'लहि',
    'लहीम',
    'लहीमुल',
    'लहू',
    'लहूलुहान',
    'लहेसना',
    'लहोरा',
    'लहज़ा',
    'ला',
    'लाँक',
    'लाँग',
    'लाँघ',
    'लाँघता',
    'लाँघती',
    'लाँघना',
    'लाँघने',
    'लाँबा',
    'लाँबी',
    'लांगल',
    'लांगली',
    'लांगलीषा',
    'लांगूल',
    'लांगूली',
    'लांघ',
    'लांघना',
    'लांच',
    'लांछक',
    'लांछन',
    'लांछना',
    'लांछनीय',
    'लांछय',
    'लांछित',
    'लांजिटयूड',
    'लांड्री',
    'लांरगल',
    'लाइकनैस',
    'लाइट',
    'लाइटर',
    'लाइन',
    'लाइनमैन',
    'लाइनर',
    'लाइनें',
    'लाइनों',
    'लाइन्स',
    'लाइफ',
    'लाइब्रेरियन',
    'लाइब्रेरी',
    'लाइमलाइट',
    'लाइलाज',
    'लाइलाह',
    'लाइल्म',
    'लाइल्मी',
    'लाइव',
    'लाइसेंस',
    'लाइसेंसदार',
    'लाइसेंसीकरण',
    'लाइसेंसों',
    'लाई',
    'लाईट',
    'लाईन',
    'लाईनें',
    'लाईनों',
    'लाईमान',
    'लाईमानी',
    'लाईसेंस',
    'लाउंज',
    'लाउडिस्पीकर',
    'लाए',
    'लाएं',
    'लाकंगिक',
    'लाकंगिकता',
    'लाकंगिकवादी',
    'लाकंप',
    'लाकडी',
    'लाकर',
    'लाक्षण',
    'लाक्षणिक',
    'लाक्षण्य',
    'लाक्षा',
    'लाक्षिक',
    'लाख',
    'लाखन',
    'लाखिराज',
    'लाखों',
    'लाग',
    'लागडाट',
    'लागत',
    'लागतजन्य',
    'लागतवाली',
    'लागतों',
    'लागतोपरि',
    'लागलपेट',
    'लागी',
    'लागुडिक',
    'लागू',
    'लाघव',
    'लाघवी',
    'लाचार',
    'लाचारगी',
    'लाचारी',
    'लाछंन',
    'लाज',
    'लाजवंत',
    'लाजवंती',
    'लाजवन्ती',
    'लाजवर्द',
    'लाजवाब',
    'लाजा',
    'लाजिक',
    'लाजिम',
    'लाजिमी',
    'लाजीशियन',
    'लाट',
    'लाटरी',
    'लाटी',
    'लाठालाठी',
    'लाठी',
    'लाठीधारी',
    'लाठीबाज',
    'लाठीबाजी',
    'लाड',
    'लाडपूर्ण',
    'लाडला',
    'लाडली',
    'लाड़',
    'लाड़ला',
    'लाड़ली',
    'लाडा',
    'लाडी',
    'लाडू',
    'लाडो',
    'लाढिया',
    'लात',
    'लातर',
    'लातादाद',
    'लाते',
    'लाद',
    'लादकर',
    'लादना',
    'लादने',
    'लादिया',
    'लादी',
    'लान',
    'लानत',
    'लाना',
    'लानाः',
    'लानी',
    'लाने',
    'लाप',
    'लापता',
    'लापरवा',
    'लापरवाह',
    'लापरवाही',
    'लाबु',
    'लाभ',
    'लाभक',
    'लाभकर',
    'लाभकारी',
    'लाभजीवी',
    'लाभदायक',
    'लाभदायकता',
    'लाभप्रद',
    'लाभभागी',
    'लाभरहित',
    'लाभवाला',
    'लाभवृत्ति',
    'लाभसहित',
    'लाभहीन',
    'लाभहीनता',
    'लाभांश',
    'लाभात्मक',
    'लाभानुसार',
    'लाभान्वित',
    'लाभार्थ',
    'लाभालाभ',
    'लाम',
    'लामकान',
    'लामजहब',
    'लामजहीयत',
    'लामबंद',
    'लामबंदी',
    'लामा',
    'लामावाद',
    'लामुहाल',
    'लामुहाला',
    'लायक',
    'लायक़',
    'लायकी',
    'लायन',
    'लायब्रेरी',
    'लाया',
    'लाये',
    'लायेंगें',
    'लार',
    'लारमय',
    'लारी',
    'लार्ज',
    'लार्ड',
    'लार्वा',
    'लाल',
    'लालच',
    'लालचहीन',
    'लालचहीनता',
    'लालची',
    'लालचीपन',
    'लालटेन',
    'लालटैन',
    'लालन',
    'लालना',
    'लालपन',
    'लालफीताशाही',
    'लालमुँहा',
    'लालसा',
    'लालसाहीन',
    'लालसाहीनतः',
    'लालसित',
    'लालसी',
    'लाला',
    'लालाइन',
    'लालाटिक',
    'लालायन',
    'लालायित',
    'लालिक',
    'लालित्य',
    'लालित्यपूर्ण',
    'लालिमा',
    'लाली',
    'लाले',
    'लालो',
    'लाव',
    'लावक',
    'लावणिक',
    'लावणी',
    'लावण्य',
    'लावण्यमय',
    'लावण्ययुक्त',
    'लावण्यवती',
    'लावण्यवान',
    'लावनी',
    'लावयमयी',
    'लावा',
    'लावारिस',
    'लावारिसी',
    'लाश',
    'लाशघर',
    'लाषा',
    'लाषिणी',
    'लाषी',
    'लास',
    'लासक',
    'लासन',
    'लासर',
    'लासा',
    'लासानी',
    'लासि',
    'लासिकी',
    'लासिनी',
    'लासी',
    'लासु',
    'लास्ट',
    'लास्य',
    'लास्या',
    'लाह',
    'लाहल',
    'लाही',
    'लाहौरी',
    'लाहौल',
    'लाज़िमी',
    'लिंक',
    'लिंक्स',
    'लिंग',
    'लिंगधर',
    'लिंगपूजक',
    'लिंगवान',
    'लिंगायत',
    'लिंगी',
    'लिंगीय',
    'लिंगेंद्रिय',
    'लिंगोपासना',
    'लिंटर',
    'लिंटल',
    'लिंप',
    'लिए',
    'लिक्खाड',
    'लिक्स',
    'लिख',
    'लिखकर',
    'लिखत',
    'लिखतवार',
    'लिखते',
    'लिखन',
    'लिखना',
    'लिखनी',
    'लिखने',
    'लिखनेवाला',
    'लिखवाकर',
    'लिखवार',
    'लिखा',
    'लिखाई',
    'लिखाना',
    'लिखापढी',
    'लिखावट',
    'लिखित',
    'लिखितता',
    'लिखी',
    'लिखे',
    'लिखें',
    'लिखेरा',
    'लिखेरी',
    'लिग्नाइट',
    'लिजलिजा',
    'लिजलिजापन',
    'लिजलिजी',
    'लिज्जत',
    'लिटमस',
    'लिटाना',
    'लिट्ट',
    'लिट्टी',
    'लिड',
    'लिण्ग्ग',
    'लिथो',
    'लिथोग्राफ',
    'लिनन',
    'लिप',
    'लिपट',
    'लिपटन',
    'लिपटना',
    'लिपटवां',
    'लिपटा',
    'लिपटाई',
    'लिपटाना',
    'लिपटाव',
    'लिपटी',
    'लिपडी',
    'लिपना',
    'लिपसवां',
    'लिपस्टिक',
    'लिपा',
    'लिपाई',
    'लिपि',
    'लिपिः',
    'लिपिक',
    'लिपिकवर्गीय',
    'लिपिकार',
    'लिपिकीय',
    'लिपिन्यास',
    'लिपिबद्घ',
    'लिपिबद्घता',
    'लिपिबद्घन',
    'लिपिबद्ध',
    'लिपियों',
    'लिपिवाहक',
    'लिपी',
    'लिप्त',
    'लिप्तक',
    'लिप्तता',
    'लिप्ता',
    'लिप्तिका',
    'लिप्यंतरण',
    'लिप्यंतरिण',
    'लिप्यांतरण',
    'लिप्सका',
    'लिप्सा',
    'लिप्साहीन',
    'लिफाफा',
    'लिफाफिया',
    'लिफाफे',
    'लिफाफों',
    'लिफाफ़ा',
    'लिफ्ट',
    'लिफ्टमैन',
    'लिफ्टिंग',
    'लिबडना',
    'लिबडी',
    'लिबलिबी',
    'लिबास',
    'लिबिडो',
    'लिमिटेड',
    'लियन',
    'लिया',
    'लियाकत',
    'लिये',
    'लिरिक',
    'लिलद',
    'लिलहार',
    'लिलार',
    'लिलारी',
    'लिवया',
    'लिवाना',
    'लिवाया',
    'लिवाल',
    'लिवालिया',
    'लिवाली',
    'लिसलिसा',
    'लिसा',
    'लिसी',
    'लिसु',
    'लिस्ट',
    'लिह',
    'लिहाज',
    'लिहाज़ा',
    'लिहाजा',
    'लिहाफ',
    'लिहाज़',
    'लिफ़ाफा',
    'ली',
    'लीइन',
    'लीउर',
    'लीक',
    'लीकातिगतन',
    'लीकेज',
    'लीख',
    'लीग',
    'लीगवाद',
    'लीगिज्म',
    'लीचड',
    'लीची',
    'लीज',
    'लीजिए',
    'लीझी',
    'लीटर',
    'लीडर',
    'लीडरी',
    'लीढ',
    'लीद',
    'लीन',
    'लीनता',
    'लीप',
    'लीपनहार',
    'लीपनहारी',
    'लीपना',
    'लीपने',
    'लीपफांग',
    'लीपा',
    'लीफैट',
    'लीमू',
    'लीर',
    'लीरा',
    'लीलना',
    'लीला',
    'लीलाधर',
    'लीलाधारी',
    'लीलामय',
    'लीलावती',
    'लीलावान',
    'लीलाहर',
    'लीव',
    'लीवड',
    'लीवर',
    'लीवरेज',
    'लुँगाडा',
    'लुँगाडी',
    'लुंगा',
    'लुंगी',
    'लुंचक',
    'लुंचन',
    'लुंचिनी',
    'लुंची',
    'लुंज',
    'लुंजपुंज',
    'लुंजा',
    'लुंजी',
    'लुंटन',
    'लुंटाक',
    'लुंठक',
    'लुंठकता',
    'लुंठन',
    'लुंठाक',
    'लुंठित',
    'लुंडी',
    'लुआ',
    'लुआठ',
    'लुआठी',
    'लुआब',
    'लुआबदार',
    'लुक',
    'लुकना',
    'लुकमा',
    'लुकमान',
    'लुका',
    'लुकाई',
    'लुकाछिपी',
    'लुकाठ',
    'लुकाठी',
    'लुकाना',
    'लुकारी',
    'लुकी',
    'लुके',
    'लुगडी',
    'लुगत',
    'लुगदा',
    'लुगदी',
    'लुगरी',
    'लुगाई',
    'लुगात',
    'लुचंक',
    'लुचित',
    'लुच्चई',
    'लुच्चा',
    'लुच्चापन',
    'लुच्ची',
    'लुटंत',
    'लुटना',
    'लुटपुटा',
    'लुटपूटी',
    'लुटा',
    'लुटाई',
    'लुटाऊ',
    'लुटाना',
    'लुटिया',
    'लुटेरा',
    'लुटेरापन',
    'लुटेरी',
    'लुडकी',
    'लुडकू',
    'लुडना',
    'लुढक',
    'लुढकता',
    'लुढकती',
    'लुढकना',
    'लुढकनी',
    'लुढकी',
    'लुढकू',
    'लुढना',
    'लुढ़कता',
    'लुढ़कन',
    'लुढ़कना',
    'लुढ़काते',
    'लुढा',
    'लुढी',
    'लुण्ठन',
    'लुतरा',
    'लुतरी',
    'लुती',
    'लुत्फ',
    'लुधियाना',
    'लुनना',
    'लुनाई',
    'लुनेरा',
    'लुनेरी',
    'लुपना',
    'लुपलुपाना',
    'लुपलुपाहट',
    'लुप्त',
    'लुप्तप्रायता',
    'लुप्ति',
    'लुबाब',
    'लुब्ध',
    'लुब्धक',
    'लुब्धन',
    'लुब्बे',
    'लुभन',
    'लुभना',
    'लुभा',
    'लुभाऊ',
    'लुभाना',
    'लुभानेवाली',
    'लुभाव',
    'लुभावना',
    'लुभावनी',
    'लुभित',
    'लुभी',
    'लुरका',
    'लुरकी',
    'लुलन',
    'लुलना',
    'लुलाप',
    'लुलित',
    'लुशाई',
    'लुस्त',
    'लुहार',
    'लू',
    'लूक',
    'लूकट',
    'लूका',
    'लूगउ',
    'लूट',
    'लूटखूंद',
    'लूटना',
    'लूटने',
    'लूटपाट',
    'लूटपात',
    'लूटमार',
    'लूटार',
    'लूटी',
    'लूटू',
    'लूता',
    'लूतिका',
    'लूथ',
    'लूथर',
    'लूथरन',
    'लूथरवादी',
    'लून',
    'लूनक',
    'लूनर',
    'लूप',
    'लूम',
    'लूमन',
    'लूमीनिसैंस',
    'लूलना',
    'लूला',
    'लूली',
    'लूलू',
    'लूसीफर',
    'लूह',
    'लूहर',
    'ले',
    'लें',
    'लेंगे',
    'लेंस',
    'लेइ',
    'लेई',
    'लेईदार',
    'लेऔफ',
    'लेक',
    'लेकर',
    'लेकिन',
    'लेक्चर',
    'लेक्चरर',
    'लेख',
    'लेखक',
    'लेखकीय',
    'लेखकों',
    'लेखचित्र',
    'लेखन',
    'लेखनहार',
    'लेखनहारा',
    'लेखनहारी',
    'लेखना',
    'लेखनी',
    'लेखपाल',
    'लेखबंदी',
    'लेखहारी',
    'लेखा',
    'लेखांकन',
    'लेखांकित',
    'लेखांश',
    'लेखाकरण',
    'लेखाकर्म',
    'लेखाकार',
    'लेखाकार्य',
    'लेखाकृत',
    'लेखागत',
    'लेखागार',
    'लेखाचित्र',
    'लेखाधिकारी',
    'लेखानुदान',
    'लेखापत्र',
    'लेखापरीक्षक',
    'लेखापरीक्षा',
    'लेखापाल',
    'लेखाबंदी',
    'लेखावाचन',
    'लेखाविधि',
    'लेखाशास्त्र',
    'लेखित',
    'लेखित्र',
    'लेखी',
    'लेखे',
    'लेखों',
    'लेख्य',
    'लेख्यारुढ',
    'लेगा',
    'लेगी',
    'लेजम',
    'लेजर',
    'लेजिम',
    'लेजियम',
    'लेजुर',
    'लेजुरा',
    'लेट',
    'लेटन',
    'लेटना',
    'लेटने',
    'लेटा',
    'लेटापन',
    'लेटिन',
    'लेटी',
    'लेटू',
    'लेटे',
    'लेटैक्स',
    'लेडी',
    'लेडीज',
    'लेता',
    'लेती',
    'लेते',
    'लेथ',
    'लेन',
    'लेनदन',
    'लेनदार',
    'लेनदारी',
    'लेनदारों',
    'लेनदेन',
    'लेना',
    'लेनी',
    'लेने',
    'लेनेवाला',
    'लेनेवाले',
    'लेन्स',
    'लेप',
    'लेपक',
    'लेपचित्र',
    'लेपडा',
    'लेपन',
    'लेपना',
    'लेपयकार',
    'लेपयता',
    'लेपयमयी',
    'लेपी',
    'लेप्य',
    'लेफ्टिनेंट',
    'लेफ्टिनेन्ट',
    'लेबर',
    'लेबरपार्टी',
    'लेबरा',
    'लेबरापन',
    'लेबरी',
    'लेबल',
    'लेमैन',
    'लेय',
    'लेयर',
    'लेरुवा',
    'लेलिहान',
    'लेलीक',
    'लेवडा',
    'लेवरना',
    'लेवलिया',
    'लेवा',
    'लेवाई',
    'लेवाना',
    'लेवार',
    'लेवाल',
    'लेवी',
    'लेवोमैन',
    'लेश',
    'लेशमात्र',
    'लेशमात्रता',
    'लेश्या',
    'लेष्ट',
    'लेस',
    'लेसदार',
    'लेसना',
    'लेसर',
    'लेह',
    'लेहँडी',
    'लेहक',
    'लेहन',
    'लेहना',
    'लेही',
    'लेह्य',
    'लेह्यम',
    'लेज़र',
    'लैंउ',
    'लैंग',
    'लैंगिक',
    'लैंटर्न',
    'लैंड',
    'लैंडफाल',
    'लैंडलेडी',
    'लैंडलोर्ड',
    'लैंडस्केप',
    'लैंडस्लाइड',
    'लैंडिंग',
    'लैंडिस्लप',
    'लैंध',
    'लैंप',
    'लैंफ्टिनैट',
    'लैंस',
    'लैंसमैन',
    'लैंसेट',
    'लैक्',
    'लैक्चर',
    'लैक्चरर',
    'लैगवीअर',
    'लैगवीयर',
    'लैगस्पेस',
    'लैजर',
    'लैजिस्लेटर',
    'लैजिस्लेटिव',
    'लैटर',
    'लैटरबाक्स',
    'लैटिट',
    'लैटिन',
    'लैटिनीकरण',
    'लैड',
    'लैड़िंग',
    'लैदर',
    'लैन',
    'लैप',
    'लैफ्',
    'लैब',
    'लैबर',
    'लैबरेटरी',
    'लैमन',
    'लैमनचूस',
    'लैमनजूस',
    'लैमनड्रौप',
    'लैमनेड',
    'लैला',
    'लैवल',
    'लैवेंडर',
    'लैस',
    'लैसन',
    'लैसो',
    'लो',
    'लों',
    'लोंग',
    'लोंडी',
    'लोंदा',
    'लोंदी',
    'लोंहार',
    'लोअ',
    'लोअर',
    'लोई',
    'लोउ',
    'लोउन',
    'लोएग',
    'लोएटरी',
    'लोएना',
    'लोएरी',
    'लोक',
    'लोकंदी',
    'लोकः',
    'लोककण्टक',
    'लोककार',
    'लोकचार',
    'लोकजित',
    'लोकटी',
    'लोकतंत्र',
    'लोकतंत्रकरण',
    'लोकतंत्रवादी',
    'लोकतंत्रात्मक',
    'लोकतांत्रिक',
    'लोकन',
    'लोकनाथ',
    'लोकनायक',
    'लोकनी',
    'लोकन्यायालय',
    'लोकपति',
    'लोकपाल',
    'लोकप्रिय',
    'लोकप्रियता',
    'लोकबाह्य',
    'लोकभावी',
    'लोकमत',
    'लोकमान्य',
    'लोकमान्यता',
    'लोकरीति',
    'लोकल',
    'लोकलिपि',
    'लोकवत',
    'लोकवाद',
    'लोकस',
    'लोकहारी',
    'लोकहित',
    'लोकांतर',
    'लोकाकश',
    'लोकाचार',
    'लोकाट',
    'लोकातिग',
    'लोकादि',
    'लोकाधिपति',
    'लोकापवाद',
    'लोकायत',
    'लोकायतवाद',
    'लोकायन',
    'लोकार्थ',
    'लोकित',
    'लोकीय',
    'लोकेश',
    'लोकेश्वर',
    'लोको',
    'लोकोक्त',
    'लोकोक्ति',
    'लोकोत्तर',
    'लोकोपकार',
    'लोकोपकारार्थ',
    'लोकोपकारिता',
    'लोकोपकारी',
    'लोकोपयोगी',
    'लोकोमोटिव',
    'लोग',
    'लोगबाग',
    'लोगो',
    'लोगों',
    'लोगोस',
    'लोच',
    'लोचदार',
    'लोचन',
    'लोचना',
    'लोचनातीतता',
    'लोचहीन',
    'लोचहीनता',
    'लोचा',
    'लोचून',
    'लोट',
    'लोटन',
    'लोटना',
    'लोटपोट',
    'लोटम',
    'लोटा',
    'लोटी',
    'लोटू',
    'लोड',
    'लोडना',
    'लोडबीयरिंग',
    'लोत',
    'लोथ',
    'लोथारना',
    'लोथारी',
    'लोधर',
    'लोन',
    'लोना',
    'लोनातीत',
    'लोनिया',
    'लोनी',
    'लोप',
    'लोपन',
    'लोपना',
    'लोपामुद्रा',
    'लोपाश',
    'लोपाशी',
    'लोपी',
    'लोपोन्मुख',
    'लोप्ता',
    'लोप्य',
    'लोबत',
    'लोबतबाज',
    'लोबान',
    'लोबिया',
    'लोभ',
    'लोभक',
    'लोभन',
    'लोभहीन',
    'लोभहीनता',
    'लोभित',
    'लोभी',
    'लोम',
    'लोमकर्ण',
    'लोमचर्म',
    'लोमड़ी',
    'लोमनाशक',
    'लोममश',
    'लोमश',
    'लोमशा',
    'लोमहर्षित',
    'लोमांच',
    'लोमांचित',
    'लोमाजिन',
    'लोमाली',
    'लोमावरण',
    'लोमावली',
    'लोमाश',
    'लोमी',
    'लोराजि',
    'लोरी',
    'लोल',
    'लोलक',
    'लोलकी',
    'लोलन',
    'लोललोचन',
    'लोललोचना',
    'लोलित',
    'लोलिता',
    'लोलिनी',
    'लोलुप',
    'लोलुपता',
    'लोशन',
    'लोष्ट',
    'लोष्ठ',
    'लोह',
    'लोहंडा',
    'लोहक',
    'लोहकार',
    'लोहड़ी',
    'लोहडी',
    'लोहबान',
    'लोहभिहार',
    'लोहमय',
    'लोहमयता',
    'लोहमिष',
    'लोहा',
    'लोहाँग',
    'लोहाँगी',
    'लोहार',
    'लोहारगरी',
    'लोहारी',
    'लोहाश्म',
    'लोहिका',
    'लोहित',
    'लोहितांग',
    'लोहिताक्ष',
    'लोहितायस',
    'लोहिताश्व',
    'लोहितिमा',
    'लोहिया',
    'लोही',
    'लोहू',
    'लोहे',
    'लोह्य',
    'लोहक़ार',
    'लौं',
    'लौंग',
    'लौंगक्लाथ',
    'लौंच',
    'लौंडरिंग',
    'लौंडा',
    'लौंडिया',
    'लौंडी',
    'लौंद',
    'लौआ',
    'लौक',
    'लौकअप',
    'लौकय',
    'लौकर',
    'लौकिक',
    'लौकिकता',
    'लौकिकीकरण',
    'लौकिट',
    'लौकी',
    'लौग',
    'लौचिं',
    'लौचिंग',
    'लौज',
    'लौट',
    'लौटती',
    'लौटना',
    'लौटनाःसमय',
    'लौटनियां',
    'लौटपटा',
    'लौटर',
    'लौटरी',
    'लौटा',
    'लौटाई',
    'लौटाऊ',
    'लौटाना',
    'लौटाने',
    'लौटाया',
    'लौटाव',
    'लौटी',
    'लौटें',
    'लौडी',
    'लौन',
    'लौनहार',
    'लौना',
    'लौनी',
    'लौबी',
    'लौरी',
    'लौलीन',
    'लौलीनता',
    'लौलीपौप',
    'लौल्य',
    'लौह',
    'लौहकार',
    'लौहमय',
    'लौहमल',
    'लौहयुक्त',
    'लौहित',
    'लौहित्य',
    'ल्रड़ाना',
    'ल्हासा',
    'वंक',
    'वंकित',
    'वंक्रि',
    'वंग',
    'वंगज',
    'वंगीय',
    'वंचक',
    'वंचकता',
    'वंचन',
    'वंचना',
    'वंचयिता',
    'वंचित',
    'वंचिता',
    'वंजुल',
    'वंजुलक',
    'वंट',
    'वंटन',
    'वंटाल',
    'वंठर',
    'वंत',
    'वंतता',
    'वंतर्पण',
    'वंती',
    'वंदक',
    'वंदती',
    'वंदन',
    'वंदनवार',
    'वंदना',
    'वंदनी',
    'वंदनीय',
    'वंदित',
    'वंदिता',
    'वंदी',
    'वंदीजन',
    'वंदे',
    'वंद्य',
    'वंधरा',
    'वंध्य',
    'वंध्यता',
    'वंध्यफल',
    'वंध्या',
    'वंध्यीकरण',
    'वंध्यीकृत',
    'वंश',
    'वंशकर',
    'वंशक्रम',
    'वंशक्रमागत',
    'वंशगत',
    'वंशगतता',
    'वंशच्छेता',
    'वंशज',
    'वंशजता',
    'वंशजहीन',
    'वंशजहीनता',
    'वंशजा',
    'वंशजों',
    'वंशतः',
    'वंशधर',
    'वंशधर्म',
    'वंशधारी',
    'वंशनष्ट',
    'वंशनाम',
    'वंशनुकीर्तक',
    'वंशनुचरित',
    'वंशपति',
    'वंशपत्रक',
    'वंशपरमपरा',
    'वंशवर्ती',
    'वंशवर्धन',
    'वंशवृक्ष',
    'वंशव्य',
    'वंशशर्करा',
    'वंशसंस्थपक',
    'वंशहीन',
    'वंशहीनता',
    'वंशागत',
    'वंशागतित्व',
    'वंशाधर',
    'वंशानुकीर्तक',
    'वंशानुकीर्तन',
    'वंशानुक्रम',
    'वंशानुक्रमता',
    'वंशानुगत',
    'वंशानुगतता',
    'वंशावली',
    'वंशिक',
    'वंशिकता',
    'वंशिका',
    'वंशी',
    'वंशीद्भव',
    'वंशीद्भवता',
    'वंशीद्भवा',
    'वंशीधर',
    'वंशीन',
    'वंशीय',
    'वंशीयता',
    'वंशोन्नति',
    'वंशोन्नायक',
    'वंश्य',
    'वक',
    'वकरर',
    'वक़फ़',
    'वक़मक़',
    'वकारि',
    'वकालत',
    'वकी',
    'वकील',
    'वकीलों',
    'वकुल',
    'वक्',
    'वक्त',
    'वक्तन',
    'वक्तव्य',
    'वक्ता',
    'वक्ती',
    'वक्तुकाम',
    'वक्तृता',
    'वक्तृत्व',
    'वक्त्र',
    'वक्फा',
    'वक्र',
    'वक्रअत',
    'वक्रगामी',
    'वक्रचंचु',
    'वक्रत',
    'वक्रतः',
    'वक्रता',
    'वक्रतुड',
    'वक्रदंष्ट्र',
    'वक्रधर',
    'वक्रधी',
    'वक्रपाद',
    'वक्रपुच्छ',
    'वक्रपुष्प',
    'वक्रबुद्धि',
    'वक्रमति',
    'वक्रर्',
    'वक्रहीन',
    'वक्रहीनतः',
    'वक्रहीनता',
    'वक्रांग',
    'वक्रांगता',
    'वक्राकार',
    'वक्रारोही',
    'वक्रावर्तक',
    'वक्रावर्तन',
    'वक्रि',
    'वक्रिमा',
    'वक्रिल',
    'वक्री',
    'वक्रोतर',
    'वक्रोष्ठिका',
    'वक्र्स',
    'वक्ष',
    'वक्षज',
    'वक्षरोग',
    'वक्षस्त्राण',
    'वक्षस्थल',
    'वक्षस्राण',
    'वक्षीय',
    'वक्षोरुह',
    'वक्फ़',
    'वगरना',
    'वगलामुखी',
    'वगिर्कीय',
    'वगीरय',
    'वगैरह',
    'वगैरा',
    'वग्र्य',
    'वचंन',
    'वचन',
    'वचनग्रहीता',
    'वचनदाता',
    'वचनपत्र',
    'वचनपूर्वक',
    'वचनप्त्र',
    'वचनबद्घ',
    'वचनबद्घतः',
    'वचनबद्ध',
    'वचनबद्धता',
    'वचनविदग्ध',
    'वचनीय',
    'वचस्वी',
    'वचोविद',
    'वच्दल',
    'वजद',
    'वजन',
    'वजनकश',
    'वजनदार',
    'वजनी',
    'वजनीपन',
    'वजयिता',
    'वजह',
    'वजारत',
    'वजाहत',
    'वजिर्त',
    'वजिर्न',
    'वजिर्श',
    'वजीफा',
    'वजीफादार',
    'वजीर',
    'वजीरे',
    'वजू',
    'वजूद',
    'वज्र',
    'वज्रक',
    'वज्रतंतु',
    'वज्रतुंड',
    'वज्रदंत',
    'वज्रधर',
    'वज्रधी',
    'वज्रनख',
    'वज्रनाभ',
    'वज्रपाणि',
    'वज्रपात',
    'वज्रबाहु',
    'वज्रमुष्टि',
    'वज्रयान',
    'वज्रल',
    'वज्रहस्त',
    'वज्रहृदय',
    'वज्रा',
    'वज्रांग',
    'वज्राकर',
    'वज्राघात',
    'वज्राचार्य',
    'वज्राभ',
    'वज्रायुध',
    'वज्राशनि',
    'वज्रासन',
    'वज्रीय',
    'वट',
    'वटक',
    'वटवासी',
    'वटाश्रय',
    'वटिका',
    'वटी',
    'वटैक्स',
    'वट्टक',
    'वड',
    'वडवा',
    'वडवानल',
    'वडा',
    'वणागत',
    'वणिक',
    'वणीरय',
    'वण्र',
    'वण्रन',
    'वण्र्य',
    'वत',
    'वतंस',
    'वतक',
    'वतन',
    'वतनी',
    'वती',
    'वतुलाकृति',
    'वत्त',
    'वत्ता',
    'वत्र',
    'वत्रन',
    'वत्र्म',
    'वत्स',
    'वत्सक',
    'वत्सका',
    'वत्सपाल',
    'वत्सर',
    'वत्सरांत',
    'वत्सरादि',
    'वत्सल',
    'वत्सलता',
    'वत्सा',
    'वद',
    'वदक',
    'वदन',
    'वदनोदर',
    'वदान्य',
    'वदाल',
    'वदावद',
    'वदिता',
    'वद्य',
    'वध',
    'वधक',
    'वधकांक्षी',
    'वधकाम',
    'वधत्र',
    'वधधन',
    'वधना',
    'वधरर्',
    'वधस्थल',
    'वधिक',
    'वधित',
    'वधु',
    'वधुका',
    'वधू',
    'वधूःपिता',
    'वधूटी',
    'वधूत',
    'वधोन्माद',
    'वध्य',
    'वध्र्म',
    'वन',
    'वनकन्या',
    'वनग',
    'वनगाचर',
    'वनचर',
    'वनज',
    'वनजंतु',
    'वनडे',
    'वनदेव',
    'वनदेवता',
    'वनदेवी',
    'वनपाल',
    'वनभोज',
    'वनमानुष',
    'वनमाली',
    'वनय',
    'वनयीकरण',
    'वनरक्षक',
    'वनराज',
    'वनवास',
    'वनवासित',
    'वनवासी',
    'वनविचरण',
    'वनविडाल',
    'वनविहार',
    'वनवृक्षविज्ञानी',
    'वनवे',
    'वनवेश',
    'वनसंरक्षक',
    'वनसपतियुक्तता',
    'वनस्थ',
    'वनस्थली',
    'वनस्था',
    'वनस्पति',
    'वनस्पतिज्ञ',
    'वनस्पतियुक्त',
    'वनस्पतियुक्तता',
    'वनस्पतिशास्त्री',
    'वनस्पतिहीन',
    'वनस्पतीय',
    'वनहास',
    'वनांत',
    'वनाखु',
    'वनालक्तक',
    'वनाश्रय',
    'वनि',
    'वनित',
    'वनिता',
    'वनी',
    'वनीकरण',
    'वनीय',
    'वनेचर',
    'वनोन्मूलन',
    'वनौका',
    'वनौषधि',
    'वन्दना',
    'वन्य',
    'वन्यता',
    'वन्यतापूर्ण',
    'वन्यपशु',
    'वन्या',
    'वपता',
    'वपन',
    'वपा',
    'वपु',
    'वपुः',
    'वपुमान',
    'वपुश्मान',
    'वपुष',
    'वप्र',
    'वप्रा',
    'वफक्नामा',
    'वफ़ात',
    'वफा',
    'वफात',
    'वफादार',
    'वफादारी',
    'वबा',
    'वबाई',
    'वबाल',
    'वम',
    'वमथु',
    'वमन',
    'वमनकारी',
    'वमित',
    'वमिर्क',
    'वमी',
    'वम्रक',
    'वम्री',
    'वय',
    'वयः',
    'वयण',
    'वयन',
    'वयम्',
    'वयसंधि',
    'वयसांपति',
    'वयसिका',
    'वयस्क',
    'वयस्कता',
    'वयस्तता',
    'वयस्य',
    'वयस्या',
    'वयित',
    'वयो',
    'वयोगत',
    'वयोधिक',
    'वयोधिगता',
    'वयोवृद्घ',
    'वयोवृद्घा',
    'वयोवृद्ध',
    'वर',
    'वरंच',
    'वरंड',
    'वरक',
    'वरकांक्षिणी',
    'वरकाय',
    'वरकी',
    'वरज',
    'वरजना',
    'वरजिश',
    'वरट',
    'वरटा',
    'वरण',
    'वरणात्मक',
    'वरणाधिकार',
    'वरणीय',
    'वरतत्वक',
    'वरतनु',
    'वरद',
    'वरदक्षिणा',
    'वरदा',
    'वरदाता',
    'वरदात्री',
    'वरदान',
    'वरदानी',
    'वरदी',
    'वरन',
    'वरना',
    'वरपक्ष',
    'वरप्रभ',
    'वरफल',
    'वरम',
    'वरमाल',
    'वरमाला',
    'वरमुखी',
    'वरयाता',
    'वरयात्रा',
    'वरयात्री',
    'वरयिता',
    'वररुचि',
    'वरला',
    'वरली',
    'वरवर्णिनी',
    'वरसोङी',
    'वरस्पति',
    'वरा',
    'वरांकांखी',
    'वरांग',
    'वरांगक',
    'वरांगना',
    'वरांगिणी',
    'वरांगी',
    'वराटक',
    'वराटिक',
    'वराटी',
    'वराथी',
    'वरानन',
    'वरानना',
    'वरासत',
    'वरासतन',
    'वरासन',
    'वराह',
    'वराहावतार',
    'वराही',
    'वरित',
    'वरिता',
    'वरिमा',
    'वरिष्ठ',
    'वरिष्ठा',
    'वरीता',
    'वरीयता',
    'वरीयताप्राप्त',
    'वरीयान',
    'वरीवर्त',
    'वरुण',
    'वरुणालय',
    'वरुणीय',
    'वरुथ',
    'वरुथिनी',
    'वरुथी',
    'वरूण',
    'वरेंद्र',
    'वरेण्य',
    'वर्क',
    'वर्कर',
    'वर्कशोएप',
    'वर्कशौप',
    'वर्किग',
    'वर्ग',
    'वर्गणा',
    'वर्गन',
    'वर्गपद',
    'वर्गफल',
    'वर्गमीटर',
    'वर्गमूल',
    'वर्गयुक्त',
    'वर्गवाद',
    'वर्गवादी',
    'वर्गहीन',
    'वर्गाक',
    'वर्गाका',
    'वर्गाकार',
    'वर्गानुसार',
    'वर्गी',
    'वर्गीकरण',
    'वर्गीकृत',
    'वर्गीय',
    'वर्गीयता',
    'वर्चस',
    'वर्चस्व',
    'वर्चस्वी',
    'वर्ज',
    'वर्जक',
    'वर्जन',
    'वर्जना',
    'वर्जित',
    'वर्ज्य',
    'वर्टिकल',
    'वर्टिकैलिटी',
    'वर्ड',
    'वर्डिक्ट',
    'वर्ण',
    'वर्णः',
    'वर्णक',
    'वर्णकावली',
    'वर्णक्रम',
    'वर्णच्युत',
    'वर्णज',
    'वर्णता',
    'वर्णद',
    'वर्णन',
    'वर्णनकर्ता',
    'वर्णनातीत',
    'वर्णनातीतता',
    'वर्णनात्मक',
    'वर्णनीय',
    'वर्णमाला',
    'वर्णमालीय',
    'वर्णव्य',
    'वर्णशिक्षक',
    'वर्णसंकर',
    'वर्णहीन',
    'वर्णहीनता',
    'वर्णाट',
    'वर्णात्मक',
    'वर्णात्मा',
    'वर्णानुक्रम',
    'वर्णानुसार',
    'वर्णान्धता',
    'वर्णाभा',
    'वर्णाभ्यासी',
    'वर्णाली',
    'वर्णावर',
    'वर्णाश्रम',
    'वर्णिक',
    'वर्णिका',
    'वर्णिकी',
    'वर्णिकीय',
    'वर्णित',
    'वर्णिनी',
    'वर्णिल',
    'वर्णी',
    'वर्णीय',
    'वर्तनशील',
    'वर्तनशीलता',
    'वर्तनी',
    'वर्तमान',
    'वर्तमानकाल',
    'वर्तमानकालिक',
    'वर्तमानता',
    'वर्तयक',
    'वर्तलोह',
    'वर्तानता',
    'वर्तिका',
    'वर्तित',
    'वर्तिता',
    'वर्तित्र',
    'वर्तिर',
    'वर्तिष्णु',
    'वर्ती',
    'वर्तुल',
    'वर्तुलप्राय',
    'वर्तुला',
    'वर्तुलाकार',
    'वर्तुलाग्र',
    'वर्द',
    'वर्दन',
    'वर्दी',
    'वर्दीधारी',
    'वर्द्वित',
    'वर्धक',
    'वर्धकाय',
    'वर्धन',
    'वर्धनी',
    'वर्धमान',
    'वर्धयिता',
    'वर्धर्क',
    'वर्धिक',
    'वर्धित',
    'वर्धित्र',
    'वर्धिष्णु',
    'वर्धिष्यमाण',
    'वर्धी',
    'वर्धीकाय',
    'वर्म',
    'वर्मलोह',
    'वर्मलौह',
    'वर्मा',
    'वर्मी',
    'वर्या',
    'वर्ल्ड',
    'वर्वट',
    'वर्ष',
    'वर्षंत',
    'वर्षः',
    'वर्षक',
    'वर्षगांठ',
    'वर्षज',
    'वर्षण',
    'वर्षत्राण',
    'वर्षद',
    'वर्षधर',
    'वर्षबु',
    'वर्षबोधनी',
    'वर्षभोज',
    'वर्षयिता',
    'वर्षवृत्त',
    'वर्षा',
    'वर्षागम',
    'वर्षाधिप',
    'वर्षाधिपति',
    'वर्षानुवर्ष',
    'वर्षाप्रद',
    'वर्षाप्रिय',
    'वर्षाभव',
    'वर्षाभीति',
    'वर्षाभीरुता',
    'वर्षाभू',
    'वर्षाभ्वी',
    'वर्षामापी',
    'वर्षारंभ',
    'वर्षार्ध',
    'वर्षाल',
    'वर्षावलंबित',
    'वर्षावाली',
    'वर्षाश',
    'वर्षाहीन',
    'वर्षाहीनता',
    'वर्षिता',
    'वर्षिष्ठ',
    'वर्षी',
    'वर्षीय',
    'वर्षुकांबुद',
    'वर्षे',
    'वर्षेश',
    'वर्स',
    'वर्हि',
    'वर्हियान',
    'वलंब',
    'वलगा',
    'वलभी',
    'वलय',
    'वलयन',
    'वलयाकार',
    'वलयातिरेक',
    'वलयित',
    'वलयी',
    'वलयीकरण',
    'वला',
    'वलाक',
    'वलायत',
    'वलि',
    'वलित',
    'वलिम',
    'वलिश',
    'वली',
    'वलीक',
    'वलेकिन',
    'वलोह',
    'वल्कल',
    'वल्कलधारी',
    'वल्कली',
    'वल्कुटीय',
    'वल्गा',
    'वल्गु',
    'वल्गुज',
    'वल्गुनाद',
    'वल्गुल',
    'वल्दीयत',
    'वल्भन',
    'वल्मीक',
    'वल्ल',
    'वल्लकी',
    'वल्लभ',
    'वल्लभता',
    'वल्लभा',
    'वल्लभ्',
    'वल्लरी',
    'वल्लव',
    'वल्लवी',
    'वल्लाह',
    'वल्ली',
    'वल्लुर',
    'वल्लूर',
    'ववत्सल',
    'वश',
    'वशंकर',
    'वशंकृत',
    'वशकर',
    'वशचलना',
    'वशवर्ती',
    'वशागत',
    'वशातीत',
    'वशाधीन',
    'वशाधीनता',
    'वशाधीनन',
    'वशानुग',
    'वशित्व',
    'वशिमा',
    'वशिष्ठ',
    'वशी',
    'वशीकर',
    'वशीकरण',
    'वशीकर्ता',
    'वशीकारक',
    'वशीकृत',
    'वशीभूत',
    'वशीभूति',
    'वशेतर',
    'वशेतरता',
    'वश्य',
    'वश्यता',
    'वश्या',
    'वषट्',
    'वषट्कार',
    'वषे',
    'वषोर्त्पन्न',
    'वषोर्त्सव',
    'वषोर्पल',
    'वसंत',
    'वसंता',
    'वसंती',
    'वसंतोत्सव',
    'वसंतोन्माद',
    'वसती',
    'वसन',
    'वसनहीनता',
    'वसना',
    'वसा',
    'वसाढयक',
    'वसादार',
    'वसामय',
    'वसामुक्त',
    'वसायुक्त',
    'वसारहित',
    'वसाल',
    'वसाहीन',
    'वसिष्ठ',
    'वसीयत',
    'वसीयतकर्ता',
    'वसीयतदार',
    'वसीयतनामा',
    'वसीयतनामे',
    'वसीयती',
    'वसीला',
    'वसु',
    'वसुंधरा',
    'वसुचारुक',
    'वसुत्तम',
    'वसुदेव',
    'वसुदेवः',
    'वसुदेव्या',
    'वसुधर',
    'वसुधा',
    'वसुधाधर',
    'वसुधान',
    'वसुधामा',
    'वसुधारा',
    'वसुपाल',
    'वसुप्रद',
    'वसुमती',
    'वसुरुचि',
    'वसुविंद',
    'वसुश्रेष्ठ',
    'वसुषेण',
    'वसूची',
    'वसूल',
    'वसूलकर्ता',
    'वसूलना',
    'वसूलयाब',
    'वसूलयाबी',
    'वसूलवाना',
    'वसूलियां',
    'वसूली',
    'वसूलीकर्ता',
    'वसूलीयोग्य',
    'वसूलीवाले',
    'वसूले',
    'वस्टैंड',
    'वस्त',
    'वस्ति',
    'वस्तु',
    'वस्तुः',
    'वस्तुआकं',
    'वस्तुएं',
    'वस्तुओं',
    'वस्तुगत',
    'वस्तुतः',
    'वस्तुता',
    'वस्तुनिष्ठ',
    'वस्तुनिष्ठता',
    'वस्तुपरक',
    'वस्तुपूरक',
    'वस्तुयें',
    'वस्तुविद्या',
    'वस्तुशून्य',
    'वस्तुसूची',
    'वस्त्र',
    'वस्त्रव्यापारी',
    'वस्त्रसज्जित',
    'वस्त्रहीन',
    'वस्त्रहीनता',
    'वस्त्रांचल',
    'वस्त्रांत',
    'वस्त्रागार',
    'वस्त्राच्छाद',
    'वस्त्राभरण',
    'वस्त्राभूषण',
    'वस्त्रालय',
    'वस्त्रीय',
    'वस्त्रों',
    'वस्त्रोद्योग',
    'वस्त्रोपहार',
    'वस्ल',
    'वह',
    'वहंत',
    'वहति',
    'वहदत',
    'वहदते',
    'वहन',
    'वहनशुल्क',
    'वहनीय',
    'वहम',
    'वहमी',
    'वहल',
    'वहवी',
    'वहशत',
    'वहशतनाक',
    'वहशियाना',
    'वहशी',
    'वहशीपन',
    'वहा',
    'वहाँ',
    'वहां',
    'वहाबः',
    'वहाबी',
    'वहित्र',
    'वहिनिर्ष्कर्षण',
    'वहिनी',
    'वही',
    'वहीं',
    'वहीद',
    'वहील',
    'वह्नीश्वरी',
    'वह्य',
    'वांगत',
    'वांछक',
    'वांछनीय',
    'वांछनीयता',
    'वांछा',
    'वांछापूर्ण',
    'वांछित',
    'वांछिततः',
    'वांछितव्य',
    'वांछी',
    'वांछ्य',
    'वांजी',
    'वांत',
    'वांतरिक्ष',
    'वांतान्न',
    'वांनुवंश',
    'वांशिक',
    'वांशिकता',
    'वाइउ',
    'वाइज',
    'वाइजर',
    'वाइज़',
    'वाइटामिन',
    'वाइन',
    'वाइनिल',
    'वाइपर',
    'वाइरस',
    'वाइस',
    'वाइसराय',
    'वाई',
    'वाउचर',
    'वाक',
    'वाकई',
    'वाकय',
    'वाकया',
    'वाकयात',
    'वाकिआती',
    'वाकिफ',
    'वाकिफकार',
    'वाकिफकारी',
    'वाकिफीयत',
    'वाकिया',
    'वाकीटाकी',
    'वाकेअ',
    'वाकोपवाक',
    'वाकोपवाक्य',
    'वाक्',
    'वाक्कलह',
    'वाक्केलि',
    'वाक्छल',
    'वाक्पटु',
    'वाक्पटुता',
    'वाक्पति',
    'वाक्पारुष्य',
    'वाक्य',
    'वाक्यं',
    'वाक्यांग',
    'वाक्यांश',
    'वाक्याडंबर',
    'वाक्यात्मक',
    'वाक्यान्तर',
    'वाक्संयम',
    'वाक्संयमी',
    'वाक्स्तंभ',
    'वाग',
    'वागंधिप',
    'वागाडम्बरपूर्णता',
    'वागारु',
    'वागाशनि',
    'वागिंद्रिय',
    'वागीश',
    'वागीशा',
    'वागीश्वर',
    'वागीश्वरी',
    'वागुरा',
    'वागुरिक',
    'वागेश्वरी',
    'वाग्जड़ता',
    'वाग्जाल',
    'वाग्दंड',
    'वाग्दत्त',
    'वाग्दत्ता',
    'वाग्दाता',
    'वाग्दान',
    'वाग्दुष्ट',
    'वाग्देवी',
    'वाग्बद्घ',
    'वाग्मिता',
    'वाग्मितापूर्ण',
    'वाग्मी',
    'वाग्यम',
    'वाग्वज्र',
    'वाग्विकृति',
    'वाग्वितंडा',
    'वाग्विलास',
    'वाग्विलासी',
    'वाग्विस्तार',
    'वाग्वैदग्ध्य',
    'वाग्व्यापार',
    'वाङ्मय',
    'वाङ्मयी',
    'वाच',
    'वाचंयम',
    'वाचक',
    'वाचन',
    'वाचनालय',
    'वाचनिक',
    'वाचयिता',
    'वाचस्पति',
    'वाचा',
    'वाचाघाती',
    'वाचाघातीय',
    'वाचाट',
    'वाचारतः',
    'वाचारुद्घता',
    'वाचारूद्घ',
    'वाचाल',
    'वाचालता',
    'वाचाहीन',
    'वाचाहीनता',
    'वाचिक',
    'वाचित',
    'वाची',
    'वाचीय',
    'वाचेंद्रिय',
    'वाच्य',
    'वाच्यांतर',
    'वाच्यार्थ',
    'वाज',
    'वाजपेय',
    'वाजश्रवा',
    'वाज़',
    'वाजिनी',
    'वाजिब',
    'वाजीकर',
    'वाजीकरण',
    'वाजीमेध',
    'वाजेह',
    'वाजोपंद',
    'वाट',
    'वाटः',
    'वाटर',
    'वाटरप्रूफ',
    'वाटरमैन',
    'वाटिका',
    'वाटी',
    'वाडब',
    'वाडव',
    'वाडवेय',
    'वाड़ा',
    'वाणि',
    'वाणिज्य',
    'वाणिज्यदूत',
    'वाणिज्यदूतीय',
    'वाणिज्यपोत',
    'वाणिज्यवाद',
    'वाणिज्यिक',
    'वाणिज्यीकरण',
    'वाणिज्यीकृत',
    'वाणिज्यीय',
    'वाणिज्येतर',
    'वाणी',
    'वाणीः',
    'वाणीय',
    'वाणीहीन',
    'वाण्गमीमांसक',
    'वाण्गमीमांसा',
    'वात',
    'वातकेतु',
    'वातध्वज',
    'वातरंग',
    'वातरोध',
    'वातस्फीति',
    'वातस्फ़ीति',
    'वाता',
    'वाताधिक्य',
    'वातानुकूल',
    'वातानुकूलक',
    'वातानुकूलन',
    'वातानुकूलित',
    'वातानुकूलित्र',
    'वातानुतापन',
    'वातानुतापित्र',
    'वातानुशीतित्र',
    'वातापि',
    'वातापिः',
    'वाताम',
    'वातायन',
    'वातायनी',
    'वातायु',
    'वातारि',
    'वाताली',
    'वातावरण',
    'वातावर्त',
    'वाताशी',
    'वातास',
    'वाति',
    'वातित्र',
    'वाती',
    'वातुल',
    'वातुलता',
    'वातोत्पात',
    'वातोन्माद',
    'वात्तार्लाप',
    'वात्तार्हर',
    'वात्तिर्क',
    'वात्या',
    'वात्सरिक',
    'वात्सल्य',
    'वात्सल्यपूर्ण',
    'वात्साल्य',
    'वात्स्यायन',
    'वाद',
    'वादक',
    'वादकर',
    'वादकारी',
    'वादग्रस्त',
    'वादन',
    'वादपत्र',
    'वादयोग्य',
    'वादयोग्यता',
    'वादरायण',
    'वादरि',
    'वादहीन',
    'वादहीनता',
    'वादा',
    'वादात्मक',
    'वादानुगमन',
    'वादानुगामिता',
    'वादानुवाद',
    'वादिक',
    'वादित',
    'वादिता',
    'वादित्र',
    'वादिनी',
    'वादिश',
    'वादी',
    'वादे',
    'वाद्य',
    'वाद्यक',
    'वाद्यकर',
    'वाद्यतन्त्र',
    'वाद्यधर',
    'वाद्ययंत्र',
    'वाद्ययोग',
    'वाद्यवृन्द',
    'वाद्यवृन्दकार',
    'वाद्यवृन्दीय',
    'वाद्यित',
    'वान',
    'वानक',
    'वानप्रस्थ',
    'वानप्रस्थी',
    'वानप्रस्थ्य',
    'वानर',
    'वानरः',
    'वानरकेतु',
    'वानरी',
    'वानरीय',
    'वानरेंद्र',
    'वानस्पतिक',
    'वानस्पत्य',
    'वानिकी',
    'वानिर्श',
    'वानीर',
    'वान्य',
    'वान्या',
    'वाप',
    'वापक',
    'वापन',
    'वापरन',
    'वापरना',
    'वापस',
    'वापसियां',
    'वापसी',
    'वापिका',
    'वापित',
    'वापी',
    'वाबस्तगी',
    'वाबस्ता',
    'वाम',
    'वामदेव',
    'वामदेवी',
    'वामन',
    'वामनः',
    'वामनता',
    'वामनयन',
    'वामनयना',
    'वामपंथ',
    'वामपंथी',
    'वामपंथीय',
    'वाममार्ग',
    'वाममार्गी',
    'वामलोचन',
    'वामलोचना',
    'वामस्थनाडी',
    'वामहस्त',
    'वामहस्तता',
    'वामा',
    'वामांगना',
    'वामावर्तन',
    'वामावर्ती',
    'वामी',
    'वामोरु',
    'वाम्विलास',
    'वाय',
    'वायदा',
    'वायदे',
    'वायन',
    'वायर',
    'वायरमैन',
    'वायरिंग',
    'वायल',
    'वायलन',
    'वायलिन',
    'वायलेँस',
    'वायव',
    'वायवी',
    'वायवीय',
    'वायवीयता',
    'वायव्य',
    'वायस',
    'वायसारि',
    'वायु',
    'वायुकूपक',
    'वायुकेश',
    'वायुगति',
    'वायुजात',
    'वायुजीव',
    'वायुदाब',
    'वायुदाबदर्शी',
    'वायुदाबिता',
    'वायुपथ',
    'वायुपुत्र',
    'वायुमंडल',
    'वायुमंडलीय',
    'वायुमण्डल',
    'वायुमण्डलीय',
    'वायुमध्य',
    'वायुमार्ग',
    'वायुयान',
    'वायुलेखी',
    'वायुवर्णन',
    'वायुवहन',
    'वायुवाह',
    'वायुवाहन',
    'वायुवाहित',
    'वायुविज्ञान',
    'वायुसखा',
    'वायुसुत',
    'वायुसेना',
    'वायुहीनता',
    'वार',
    'वारंट',
    'वारंटयुक्त',
    'वारंटी',
    'वारक',
    'वारण',
    'वारणात्मक',
    'वारणानन',
    'वारणीय',
    'वारदात',
    'वारना',
    'वारनिश',
    'वारने',
    'वारपार',
    'वारफेर',
    'वारमुखी',
    'वारयितव्य',
    'वारयिता',
    'वारवारण',
    'वारहैड',
    'वारा',
    'वारांगना',
    'वाराणसी',
    'वारापार',
    'वाराह',
    'वाराही',
    'वारि',
    'वारिज',
    'वारित',
    'वारिद',
    'वारिद्र',
    'वारिधारा',
    'वारिधि',
    'वारिनाथ',
    'वारिवाह',
    'वारिस',
    'वारी',
    'वारींद्र',
    'वारीय',
    'वारीश',
    'वारुंड',
    'वारुंडी',
    'वारुकसु',
    'वारुण',
    'वारुणि',
    'वारुणी',
    'वारुणीश',
    'वारुणेय',
    'वारुण्य',
    'वारूणी',
    'वारे',
    'वार्ड',
    'वार्डन',
    'वार्णिक',
    'वार्ता',
    'वार्ताक',
    'वार्ताकक्ष',
    'वार्ताकार',
    'वार्ताकी',
    'वार्ताक्षेप',
    'वार्तालाप',
    'वार्तालापी',
    'वार्ताहर',
    'वार्दर',
    'वार्दल',
    'वार्धक्य',
    'वार्बट',
    'वार्य',
    'वार्ष',
    'वार्षभानवी',
    'वार्षल',
    'वार्षलि',
    'वार्षिक',
    'वार्षिकियों',
    'वार्षिकी',
    'वार्षिकीग्राही',
    'वार्षिकोत्सव',
    'वार्ष्णि',
    'वार्ष्णेय',
    'वाल',
    'वालंटियर',
    'वालरस',
    'वाला',
    'वालिका',
    'वालिद',
    'वालिदा',
    'वालिदैन',
    'वालियाँ',
    'वाली',
    'वालुक',
    'वालुका',
    'वाले',
    'वालों',
    'वाल्ट',
    'वाल्मीक',
    'वाल्मीकि',
    'वाल्व',
    'वावैला',
    'वाश',
    'वाशर',
    'वाशिंग',
    'वाशिता',
    'वाशिष्ठ',
    'वाष्प',
    'वाष्पक',
    'वाष्पकंठ',
    'वाष्पण',
    'वाष्पदाबमापी',
    'वाष्पपुटिका',
    'वाष्पित',
    'वाष्पित्र',
    'वाष्पीकरण',
    'वाष्पीभूत',
    'वाष्पोचर',
    'वाष्प्त्रि',
    'वास',
    'वासंत',
    'वासंतिक',
    'वासंती',
    'वासखंड',
    'वासगृह',
    'वासन',
    'वासना',
    'वासनाः',
    'वासनाएं',
    'वासनात्मक',
    'वासनाहीन',
    'वासनाहीनतः',
    'वासनाहीनता',
    'वासयुक्त',
    'वासर',
    'वासव',
    'वासवी',
    'वासवीय',
    'वासवेय',
    'वासहीन',
    'वासा',
    'वासाधिकारी',
    'वासित',
    'वासिता',
    'वासिनी',
    'वासिफ्र',
    'वासियों',
    'वासिष्ठ',
    'वासी',
    'वासीयता',
    'वासु',
    'वासुंदी',
    'वासुंधरेयी',
    'वासुकि',
    'वासुदेव',
    'वासुदेवीय',
    'वासुपूज्य',
    'वास्कट',
    'वास्तव',
    'वास्तववाद',
    'वास्तविक',
    'वास्तविकता',
    'वास्ता',
    'वास्तु',
    'वास्तुकला',
    'वास्तुकलात्मक',
    'वास्तुकार',
    'वास्तुपति',
    'वास्तुविज्ञान',
    'वास्तुविद',
    'वास्तुविद्या',
    'वास्तुशिल्प',
    'वास्तुशिल्पीय',
    'वास्ते',
    'वास्त्र',
    'वाह',
    'वाहक',
    'वाहन',
    'वाहनमार्ग',
    'वाहनांतरणीय',
    'वाहनिक',
    'वाहनी',
    'वाहनीय',
    'वाहनों',
    'वाहवाही',
    'वाहिक',
    'वाहिका',
    'वाहित',
    'वाहिता',
    'वाहित्र',
    'वाहिद',
    'वाहिनी',
    'वाहिनीश',
    'वाहिनीहीन',
    'वाहियात',
    'वाही',
    'वाह्य',
    'वाह्याकार',
    'वाह्लीक',
    'वाह्वावरण',
    'विंग',
    'विंग्स',
    'विंच',
    'विंचनहीन',
    'विंटर',
    'विंड',
    'विंडशील्ड',
    'विंडस्क्रीन',
    'विंडो',
    'विंदु',
    'विंधाई',
    'विंध्य',
    'विंध्यवासिनी',
    'विंध्या',
    'विंध्याचल',
    'विंध्याद्रि',
    'विंध्यावली',
    'विंध्वासिनी',
    'विंब',
    'विंलधित',
    'विंशद्वाहु',
    'विंशाति',
    'विउश',
    'विकंपन',
    'विकंपी',
    'विकच',
    'विकट',
    'विकटकाय',
    'विकटता',
    'विकटा',
    'विकटाकृति',
    'विकत्था',
    'विकनापन',
    'विकराल',
    'विकरालता',
    'विकराला',
    'विकरेय',
    'विकर्ण',
    'विकर्णता',
    'विकर्णयुक्त',
    'विकर्णी',
    'विकर्षक',
    'विकर्षण',
    'विकर्षित',
    'विकर्षी',
    'विकल',
    'विकलता',
    'विकलन',
    'विकलनीय',
    'विकला',
    'विकलांग',
    'विकलांगता',
    'विकलांगन',
    'विकलांगीकरण',
    'विकलाना',
    'विकलित',
    'विकल्प',
    'विकल्पग्रस्तता',
    'विकल्पत',
    'विकल्पतः',
    'विकल्पन',
    'विकल्पयुक्त',
    'विकल्पहीन',
    'विकल्पहीनतः',
    'विकल्पहीनता',
    'विकल्पित',
    'विकल्पी',
    'विकल्पों',
    'विकसन',
    'विकसना',
    'विकसित',
    'विकसितता',
    'विकस्वर',
    'विकाम',
    'विकार',
    'विकारक',
    'विकारकारी',
    'विकारग्रस्त',
    'विकारग्रस्तता',
    'विकारज',
    'विकारजन्य',
    'विकारशील',
    'विकारशीलता',
    'विकारहीन',
    'विकारहीनता',
    'विकारित',
    'विकारी',
    'विकाल',
    'विकास',
    'विकासः',
    'विकासक',
    'विकासनीय',
    'विकासमान',
    'विकासवाद',
    'विकासवादी',
    'विकासशील',
    'विकासहीन',
    'विकासहीनता',
    'विकासात्मक',
    'विकिरण',
    'विकिरणविज्ञान',
    'विकिरणविज्ञानी',
    'विकिरणशीलता',
    'विकिरणित',
    'विकीर्ण',
    'विकीर्णन',
    'विकीलन',
    'विकुंचन',
    'विकुंचित',
    'विकुचित',
    'विकृत',
    'विकृतांग',
    'विकृति',
    'विकृतिविज्ञान',
    'विकृतिविज्ञानी',
    'विकृतिहीनता',
    'विकृतीकरण',
    'विकृष्ट',
    'विकेंद्र',
    'विकेंद्रगामी',
    'विकेंद्रण',
    'विकेंद्रता',
    'विकेंद्राभिमुख',
    'विकेंद्राभिमुखी',
    'विकेंद्राभिसरण',
    'विकेंद्राभिसारी',
    'विकेंद्रित',
    'विकेंद्रितता',
    'विकेंद्रीकरण',
    'विकेंद्रीय',
    'विकेंद्रीयकरण',
    'विकेट',
    'विकेन्द्रीकरण',
    'विकेश',
    'विकेशी',
    'विकोक',
    'विकोश',
    'विक्र',
    'विक्रम',
    'विक्रमाब्द',
    'विक्रमी',
    'विक्रमीय',
    'विक्रमे',
    'विक्रय',
    'विक्रयकला',
    'विक्रयता',
    'विक्रयागार',
    'विक्रयात्मक',
    'विक्रयार्थ',
    'विक्रयी',
    'विक्रयोत्तर',
    'विक्रांत',
    'विक्रांता',
    'विक्री',
    'विक्रीकर',
    'विक्रीकारी',
    'विक्रीत',
    'विक्रेतव्य',
    'विक्रेता',
    'विक्रेय',
    'विक्लव',
    'विक्लांत',
    'विक्लांतिकर',
    'विक्षण',
    'विक्षत',
    'विक्षिप',
    'विक्षिप्त',
    'विक्षिप्तता',
    'विक्षुब्ध',
    'विक्षुब्धता',
    'विक्षेप',
    'विक्षेपण',
    'विक्षेपी',
    'विक्षोभ',
    'विक्षोभता',
    'विखंडक',
    'विखंडन',
    'विखंडनीय',
    'विखंडित',
    'विखुर',
    'विख्यात',
    'विख्याति',
    'विख्यापन',
    'विग',
    'विगजिर्त',
    'विगणन',
    'विगणित',
    'विगत',
    'विगतकल्मष',
    'विगतता',
    'विगतराग',
    'विगतश्रीक',
    'विगतस्पृह',
    'विगतार्तवा',
    'विगति',
    'विगतोद्घव',
    'विगत्वरण',
    'विगम',
    'विगर्हित',
    'विगलक',
    'विगलन',
    'विगलित',
    'विगाधा',
    'विगाही',
    'विगुण',
    'विगुणता',
    'विगूढ़',
    'विगोत्र',
    'विगोत्रता',
    'विग्रह',
    'विग्रही',
    'विघटन',
    'विघटनकारी',
    'विघटित',
    'विघट्टन',
    'विघट्टित',
    'विघन',
    'विघात',
    'विघाती',
    'विघूर्णन',
    'विघूर्णा',
    'विघूर्णित',
    'विघ्न',
    'विघ्नकर',
    'विघ्नकारी',
    'विघ्नहर्ता',
    'विघ्नहीन',
    'विघ्नहीनतः',
    'विघ्नेश',
    'विघ्नेश्वर',
    'विच',
    'विचक्षण',
    'विचक्षणता',
    'विचक्षणतापूर्वक',
    'विचड़ा',
    'विचरक',
    'विचरण',
    'विचरणशील',
    'विचरणीय',
    'विचरना',
    'विचर्चण',
    'विचर्मीकरण',
    'विचल',
    'विचलन',
    'विचलनशील',
    'विचलनशीलता',
    'विचलना',
    'विचलित',
    'विचलितता',
    'विचार',
    'विचारक',
    'विचारकर्ता',
    'विचारखंड',
    'विचारगोष्ठी',
    'विचारण',
    'विचारणशीलता',
    'विचारणा',
    'विचारणावाचक',
    'विचारणीय',
    'विचारधारा',
    'विचारधाराओं',
    'विचारधारायें',
    'विचारना',
    'विचारपूर्ण',
    'विचारपूर्वक',
    'विचारमग्न',
    'विचारमग्नता',
    'विचारशील',
    'विचारशीलता',
    'विचारहीन',
    'विचारहीनतः',
    'विचारहीनता',
    'विचारा',
    'विचारात्मक',
    'विचाराधीन',
    'विचाराधीनता',
    'विचाराध्यक्ष',
    'विचारार्थ',
    'विचारासन',
    'विचारित',
    'विचारिततः',
    'विचारी',
    'विचारीय',
    'विचारे',
    'विचारों',
    'विचार्य',
    'विचिंतक',
    'विचिंतन',
    'विचिंतित',
    'विचिंतिता',
    'विचिंत्य',
    'विचिचीषा',
    'विचिचीषु',
    'विचित्ति',
    'विचित्र',
    'विचित्रता',
    'विचित्रवीर्यसू',
    'विचित्रांग',
    'विचुंबकन',
    'विचुंबकित',
    'विचुंबी',
    'विचेत',
    'विचेतता',
    'विचेता',
    'विचेष्ट',
    'विचेष्टता',
    'विचेष्टा',
    'विचैतन्य',
    'विच्छर्दित',
    'विच्छित्ति',
    'विच्छिन्न',
    'विच्छिन्नता',
    'विच्छुरण',
    'विच्छेद',
    'विच्छेदक',
    'विच्छेदकारी',
    'विच्छेदन',
    'विच्छेदनीय',
    'विच्छेदित',
    'विच्युत',
    'विच्युति',
    'विछेदक',
    'विछोह',
    'विछोहिन',
    'विजड़क',
    'विजड़न',
    'विजड़ित',
    'विजड़ितता',
    'विजन',
    'विजनता',
    'विजय',
    'विजयंत',
    'विजयः',
    'विजयगीत',
    'विजयछंद',
    'विजयप्रद',
    'विजयशील',
    'विजया',
    'विजयी',
    'विजयोत्सव',
    'विजल्प',
    'विजात',
    'विजाति',
    'विजातीय',
    'विजातीयता',
    'विजिज्ञासा',
    'विजिटर',
    'विजिटिंग',
    'विजित',
    'विजितेंद्रिय',
    'विजितेंद्रियता',
    'विजुअल',
    'विजुअलाइजर',
    'विजुअलाइज्ड',
    'विजेता',
    'विजेय',
    'विजेयता',
    'विजोगन',
    'विजोगी',
    'विज्ञ',
    'विज्ञता',
    'विज्ञप्ति',
    'विज्ञप्तिका',
    'विज्ञात',
    'विज्ञातता',
    'विज्ञातव्यता',
    'विज्ञाता',
    'विज्ञान',
    'विज्ञानदैहिकी',
    'विज्ञानमय',
    'विज्ञानवाद',
    'विज्ञानवादी',
    'विज्ञानात्मक',
    'विज्ञानानुकूल',
    'विज्ञानी',
    'विज्ञानीय',
    'विज्ञानेश्वर',
    'विज्ञापक',
    'विज्ञापन',
    'विज्ञापनकर्ता',
    'विज्ञापनकर्ती',
    'विज्ञापनदाता',
    'विज्ञापनपट्ट',
    'विज्ञापनिका',
    'विज्ञापयिता',
    'विज्ञिप्सा',
    'विज्ञेय',
    'विज्ञेयता',
    'विज्ञेयवाद',
    'विट',
    'विटंकित',
    'विटप',
    'विटपी',
    'विटामिन',
    'विटो',
    'विट्ठल',
    'विठर',
    'विठल',
    'विठोबा',
    'विडंबन',
    'विडंबना',
    'विडंबनीय',
    'विडंबित',
    'विडंबी',
    'विडम्बन',
    'विडम्बना',
    'विडरना',
    'विडाल',
    'विडालत्व',
    'विडालाक्षी',
    'विडाली',
    'विडियो',
    'विडूर',
    'विडौजा',
    'विड्लवण',
    'वितंडवादी',
    'वितंडा',
    'वितंडाकारी',
    'वितंस',
    'वितक्र्य',
    'वितति',
    'वितना',
    'वितनिता',
    'वितरक',
    'वितरण',
    'वितरणशील',
    'वितरणीय',
    'वितरिका',
    'वितरित',
    'वितरिता',
    'वितर्क',
    'वितर्कित',
    'वितल',
    'वितलीय',
    'वितस्ति',
    'वितान',
    'वितानक',
    'वितानन',
    'वितानना',
    'वितानित',
    'वितुंड',
    'वितृष्ण',
    'वितृष्णा',
    'वित्त',
    'वित्तकाम',
    'वित्तकार',
    'वित्तद',
    'वित्तदाता',
    'वित्तपाल',
    'वित्तपोषक',
    'वित्तपोषण',
    'वित्तवान',
    'वित्तहीन',
    'वित्ता',
    'वित्तागम',
    'वित्तीय',
    'वित्तीयन',
    'वित्तेश',
    'वित्सका',
    'विथुर',
    'विथुरा',
    'विद',
    'विदग्ध',
    'विदग्धता',
    'विदग्धा',
    'विदर',
    'विदरक',
    'विदरण',
    'विदरित',
    'विदर्शना',
    'विदल',
    'विदलक',
    'विदलन',
    'विदलान्न',
    'विदलित',
    'विदल्य',
    'विदा',
    'विदाई',
    'विदागी',
    'विदाय',
    'विदायगी',
    'विदार',
    'विदारक',
    'विदारण',
    'विदारिका',
    'विदारी',
    'विदाह',
    'विदाही',
    'विदित',
    'विदितता',
    'विदिशा',
    'विदिशीय',
    'विदीर्ण',
    'विदीर्णन',
    'विदु',
    'विदुर',
    'विदुष',
    'विदूत्तम',
    'विदूर',
    'विदूरगत',
    'विदूरज',
    'विदूराचल',
    'विदूषक',
    'विदूषकता',
    'विदूषकों',
    'विदूषण',
    'विदूषित',
    'विदूषी',
    'विदेव',
    'विदेश',
    'विदेशज',
    'विदेशता',
    'विदेशागत',
    'विदेशाटन',
    'विदेशी',
    'विदेशीय',
    'विदेशों',
    'विदेषकतापूर्ण',
    'विदेह',
    'विदेहता',
    'विदेही',
    'विदोहन',
    'विद्घ',
    'विद्घकर्ण',
    'विद्घत्ता',
    'विद्घत्समाज',
    'विद्घहृदय',
    'विद्घान',
    'विद्य',
    'विद्यता',
    'विद्यमान',
    'विद्यमानता',
    'विद्या',
    'विद्यांत',
    'विद्यागम',
    'विद्याचार्य',
    'विद्याज्ञान',
    'विद्याडम्बर',
    'विद्याडम्बरी',
    'विद्यात्मक',
    'विद्याधर',
    'विद्याधरी',
    'विद्याधरीय',
    'विद्याधरेंद्र',
    'विद्याधिप',
    'विद्याधिराज',
    'विद्याध्ययन',
    'विद्यापति',
    'विद्यापीठ',
    'विद्याभ्यास',
    'विद्यारंभ',
    'विद्याराज',
    'विद्याराशि',
    'विद्यार्जन',
    'विद्यार्थी',
    'विद्यालय',
    'विद्यालयी',
    'विद्यावादी',
    'विद्यावान',
    'विद्याविहीन',
    'विद्याहीन',
    'विद्यिपूर्वक',
    'विद्युज्ज्वाला',
    'विद्युत',
    'विद्युतग्राही',
    'विद्युतधारा',
    'विद्युतन',
    'विद्युतपताक',
    'विद्युतमापी',
    'विद्युता',
    'विद्युतिकरण',
    'विद्युतीकरण',
    'विद्युतीय',
    'विद्युतोन',
    'विद्युत्परभा',
    'विद्युत्पात',
    'विद्युत्मय',
    'विद्युत्वान',
    'विद्युदणु',
    'विद्युदुन्मेष',
    'विद्युदोन',
    'विद्युद्दामा',
    'विद्युद्ध्वज',
    'विद्युद्वल्ली',
    'विद्युन्माल',
    'विद्युन्माला',
    'विद्युन्मृत्यु',
    'विद्युल्लेखा',
    'विद्येश',
    'विद्येश्वर',
    'विद्योत',
    'विद्योतक',
    'विद्योपार्जन',
    'विद्रधि',
    'विद्राव',
    'विद्रावक',
    'विद्रावित',
    'विद्रावी',
    'विद्रुत',
    'विद्रुति',
    'विद्रुप',
    'विद्रुपता',
    'विद्रुपात्मक',
    'विद्रुपी',
    'विद्रुम',
    'विद्रूप',
    'विद्रोह',
    'विद्रोही',
    'विद्वंमंडली',
    'विद्वज्जन',
    'विद्वता',
    'विद्वतापूर्ण',
    'विद्वत्कल्प',
    'विद्वत्तम',
    'विद्वत्ता',
    'विद्वत्तापूर्ण',
    'विद्वन्मण्डली',
    'विद्वान',
    'विद्वेष',
    'विद्वेषक',
    'विद्वेषता',
    'विद्वेषपूर्ण',
    'विद्वेषपूर्णता',
    'विद्वेषिणी',
    'विद्वेषी',
    'विद्वेष्टा',
    'विध',
    'विधना',
    'विधर',
    'विधर्म',
    'विधर्मी',
    'विधवा',
    'विधवादाय',
    'विधवापन',
    'विधवाश्रम',
    'विधा',
    'विधांतरण',
    'विधातव्य',
    'विधाता',
    'विधात्री',
    'विधान',
    'विधानग',
    'विधानज्ञ',
    'विधानन',
    'विधानमंडल',
    'विधानानुसारता',
    'विधानिका',
    'विधानित',
    'विधानी',
    'विधानीय',
    'विधायक',
    'विधायन',
    'विधायनीय',
    'विधायिका',
    'विधायित',
    'विधायी',
    'विधि',
    'विधिक',
    'विधिकर्ता',
    'विधिज्ञ',
    'विधित',
    'विधितः',
    'विधितता',
    'विधिपूर्ण',
    'विधिपूर्वक',
    'विधिबद्घ',
    'विधिबाह्य',
    'विधिमान्य',
    'विधिमान्यता',
    'विधिय',
    'विधियां',
    'विधिवत',
    'विधिवता',
    'विधिवत्',
    'विधिवश',
    'विधिवशता',
    'विधिवशात',
    'विधिवादिता',
    'विधिविरुद्ध',
    'विधिवेत्ता',
    'विधिशास्त्री',
    'विधिसंगत',
    'विधिहीन',
    'विधिहीनतः',
    'विधिहीनता',
    'विधीतर',
    'विधीय',
    'विधु',
    'विधुप्रिया',
    'विधुर',
    'विधुरता',
    'विधुरा',
    'विधूत',
    'विधृति',
    'विधेय',
    'विधेयः',
    'विधेयक',
    'विधेयात्मा',
    'विध्न',
    'विध्यर्थक',
    'विध्यात्मक',
    'विध्वंस',
    'विध्वंसक',
    'विध्वंसकता',
    'विध्वंसन',
    'विध्वंसवाद',
    'विध्वंसात्मक',
    'विध्वंसात्मकता',
    'विध्वंसी',
    'विध्वस्त',
    'विन',
    'विनत',
    'विनता',
    'विनती',
    'विनतोदर',
    'विनद्घ',
    'विननोदित',
    'विननोदिता',
    'विनमन',
    'विनमित',
    'विनम्य',
    'विनम्यता',
    'विनम्र',
    'विनम्रतः',
    'विनम्रता',
    'विनम्रतापूर्वक',
    'विनय',
    'विनयज्ञ',
    'विनयता',
    'विनयन',
    'विनयपूर्ण',
    'विनयपूर्णता',
    'विनयशील',
    'विनयशीलता',
    'विनयी',
    'विनवना',
    'विनशना',
    'विनशाना',
    'विनश्वर',
    'विनश्वरता',
    'विनष्ट',
    'विनष्टीकरण',
    'विनसना',
    'विनाथ',
    'विनाथता',
    'विनादित',
    'विनादी',
    'विनायक',
    'विनायिका',
    'विनाश',
    'विनाशक',
    'विनाशकता',
    'विनाशकारी',
    'विनाशना',
    'विनाशपूर्णता',
    'विनाशवाद',
    'विनाशवादी',
    'विनाशवान',
    'विनाशशील',
    'विनाशहीन',
    'विनाशहीनता',
    'विनाशी',
    'विनाह',
    'विनिंदा',
    'विनिकष',
    'विनिकषण',
    'विनिगमन',
    'विनिग्रहण',
    'विनिग्रही',
    'विनिदान',
    'विनिद्र',
    'विनिद्रा',
    'विनिधान',
    'विनिधोर्ष',
    'विनिध्न',
    'विनिपात',
    'विनिपातित',
    'विनिबंध',
    'विनिमय',
    'विनिमयता',
    'विनिमयन',
    'विनिमायक',
    'विनिमार्ण',
    'विनिमिर्त',
    'विनिमिर्ति',
    'विनिमीत',
    'विनिमेता',
    'विनिमेय',
    'विनिमेयता',
    'विनिमेष',
    'विनियंत्रण',
    'विनियत',
    'विनियतन',
    'विनियन्त्रण',
    'विनियम',
    'विनियमन',
    'विनियमावली',
    'विनियमित',
    'विनियामक',
    'विनियुक्त',
    'विनियोग',
    'विनियोजक',
    'विनियोजन',
    'विनियोजना',
    'विनियोजनीय',
    'विनियोजित',
    'विनियोज्य',
    'विनिर्जन',
    'विनिर्णय',
    'विनिर्णायक',
    'विनिर्दग्ध',
    'विनिर्दिष्ट',
    'विनिर्देश',
    'विनिर्देशन',
    'विनिर्देशित',
    'विनिर्धारण',
    'विनिर्मल',
    'विनिर्माण',
    'विनिर्माता',
    'विनिविष्ट',
    'विनिवृति',
    'विनिवेश',
    'विनिवेशन',
    'विनिवेशित',
    'विनिश्चय',
    'विनिश्चयन',
    'विनिषिद्घ',
    'विनिष्कासन',
    'विनिष्पादन',
    'विनिष्पाद्यता',
    'विनिहत',
    'विनिहहित',
    'विनीत',
    'विनीतक',
    'विनीततः',
    'विनीतता',
    'विनेय',
    'विनेषेध',
    'विनोद',
    'विनोदक',
    'विनोदपूर्ण',
    'विनोदपूर्वक',
    'विनोदप्रिय',
    'विनोदप्रियता',
    'विनोदशील',
    'विनोदशीलता',
    'विनोदी',
    'विनोदोक्ति',
    'विन्न',
    'विन्ना',
    'विन्यसकीय',
    'विन्यसन',
    'विन्यस्त',
    'विन्यस्ता',
    'विन्यास',
    'विन्यासक',
    'विन्यासकीय',
    'विन्यासन',
    'विपंथिता',
    'विपक्व',
    'विपक्ष',
    'विपक्षधर',
    'विपक्षधरता',
    'विपक्षहीनता',
    'विपक्षी',
    'विपक्षीय',
    'विपक्षीयता',
    'विपण',
    'विपणक',
    'विपणन',
    'विपणनयोग्य',
    'विपणित',
    'विपण्य',
    'विपत',
    'विपत्ति',
    'विपत्तिकर',
    'विपत्तिकालीन',
    'विपत्तियों',
    'विपत्र',
    'विपथ',
    'विपथगमन',
    'विपथगामिता',
    'विपथगामी',
    'विपथचारिता',
    'विपथचारी',
    'विपथन',
    'विपदग्रस्त',
    'विपदा',
    'विपदाग्रस्त',
    'विपदात्मक',
    'विपदाहीन',
    'विपदाहीनतासुरक्षा',
    'विपन्न',
    'विपन्नता',
    'विपरिच्छिति',
    'विपरिवर्तन',
    'विपरिवर्तित',
    'विपरीत',
    'विपरीततः',
    'विपरीतता',
    'विपरीतार्थक',
    'विपरीतार्थकता',
    'विपरीतार्थी',
    'विपरीतोक्ति',
    'विपर्यय',
    'विपर्यसक',
    'विपर्यस्त',
    'विपर्याय',
    'विपर्यायता',
    'विपर्यायवाची',
    'विपर्यास',
    'विपलाश',
    'विपशन',
    'विपश्यना',
    'विपश्यी',
    'विपाक',
    'विपाटन',
    'विपाती',
    'विपादिका',
    'विपिक्त्रम',
    'विपिता',
    'विपिन',
    'विपिनधन',
    'विपिनौका',
    'विपुंजन',
    'विपुंजित',
    'विपुंसन',
    'विपुर',
    'विपुल',
    'विपुलता',
    'विपुला',
    'विपुष्ट',
    'विपुष्प',
    'विपृथक्करण',
    'विप्र',
    'विप्रचिति',
    'विप्रचित्ति',
    'विप्रतिकृत',
    'विप्रत्यय',
    'विप्रलंभ',
    'विप्रलंभी',
    'विप्रलब्ध',
    'विप्रलब्धा',
    'विप्रलाप',
    'विप्रलापी',
    'विप्रसारित',
    'विप्रेक्षित',
    'विप्रेतक',
    'विप्रेषक',
    'विप्रेषण',
    'विप्रेषन',
    'विप्रेषित',
    'विप्लव',
    'विप्लवकारी',
    'विप्लवी',
    'विप्लाव',
    'विप्लावित',
    'विप्लुति',
    'विफल',
    'विफलता',
    'विफलन',
    'विबंध',
    'विबंधन',
    'विबधान',
    'विबाई',
    'विबुध',
    'विबुधाचार्य',
    'विबुधारि',
    'विबुधेश्वर',
    'विबोध',
    'विबोधन',
    'विबोधित',
    'विब्बोक',
    'विब्बोकी',
    'विभंग',
    'विभंगि',
    'विभक्त',
    'विभक्ता',
    'विभक्तायुक्त',
    'विभक्ति',
    'विभव',
    'विभवता',
    'विभवशाली',
    'विभा',
    'विभाँति',
    'विभाकर',
    'विभाग',
    'विभागक',
    'विभागसूचक',
    'विभागाध्यक्ष',
    'विभागीय',
    'विभाजक',
    'विभाजन',
    'विभाजनहीन',
    'विभाजनात्मक',
    'विभाजनीय',
    'विभाजनीयता',
    'विभाजित',
    'विभाज्य',
    'विभाति',
    'विभाव',
    'विभावक',
    'विभावन',
    'विभावरी',
    'विभावरीश',
    'विभावसु',
    'विभावित',
    'विभाव्य',
    'विभाषा',
    'विभासित',
    'विभिन्न',
    'विभिन्नता',
    'विभीत्सा',
    'विभीषण',
    'विभीषिका',
    'विभु',
    'विभुता',
    'विभूत',
    'विभूति',
    'विभूतिमान',
    'विभूषण',
    'विभूषा',
    'विभूषित',
    'विभूष्णू',
    'विभेता',
    'विभेद',
    'विभेदक',
    'विभेदकारी',
    'विभेदन',
    'विभेदमूलक',
    'विभेदात्मक',
    'विभेदी',
    'विभेदीकरण',
    'विभेदीय',
    'विभोर',
    'विभोरता',
    'विभ्रंश',
    'विभ्रम',
    'विभ्रमकारी',
    'विभ्रमण',
    'विभ्रांत',
    'विभ्रांति',
    'विभ्रांतिमूलक',
    'विभ्रान्ति',
    'विमंडन',
    'विमंथन',
    'विमंदक',
    'विमंदन',
    'विमति',
    'विमद्रित',
    'विमध्य',
    'विमनस्क',
    'विमनस्कता',
    'विमना',
    'विमर्द',
    'विमर्दक',
    'विमर्दन',
    'विमर्दी',
    'विमर्श',
    'विमर्शक',
    'विमर्शदाता',
    'विमर्शन',
    'विमर्शित',
    'विमर्शिततः',
    'विमर्शी',
    'विमर्ष',
    'विमल',
    'विमलता',
    'विमला',
    'विमलात्मा',
    'विमलाद्रि',
    'विमश्र्य',
    'विमाता',
    'विमातृज',
    'विमान',
    'विमानः',
    'विमानकीय',
    'विमानचर',
    'विमानचारी',
    'विमानन',
    'विमानना',
    'विमाननीय',
    'विमानपत्तन',
    'विमानवाहित',
    'विमानशाला',
    'विमानांत',
    'विमानाग',
    'विमानाग्र',
    'विमानिकी',
    'विमानिनी',
    'विमानोतरण',
    'विमार्ग',
    'विमार्गण',
    'विमार्गता',
    'विमार्गदर्शन',
    'विमार्गी',
    'विमार्जक',
    'विमार्जन',
    'विमिश्र',
    'विमिश्रण',
    'विमुक्त',
    'विमुक्ति',
    'विमुख',
    'विमुखतः',
    'विमुखता',
    'विमुखी',
    'विमुग्ध',
    'विमुग्धन',
    'विमुद्रीकरण',
    'विमुद्रीकृत',
    'विमूढता',
    'विमूढधी',
    'विमूढ़',
    'विमूढ़ता',
    'विमूर्छित',
    'विमूर्धज',
    'विमूल',
    'विमूलन',
    'विमृदित',
    'विमृश्य',
    'विमृष्ट',
    'विमोक्ष',
    'विमोचक',
    'विमोचन',
    'विमोचनीय',
    'विमोचित',
    'विमोध',
    'विमोह',
    'विमोहक',
    'विमोहन',
    'विमोहित',
    'विमोहिनी',
    'विमोही',
    'विय',
    'वियत',
    'वियति',
    'वियद्गत',
    'वियाम',
    'वियुक्त',
    'वियुक्ततः',
    'वियुक्तता',
    'वियुक्ता',
    'वियुक्ति',
    'वियुजन',
    'वियुजित',
    'वियुत',
    'वियुततः',
    'वियुति',
    'वियुथ',
    'वियूथन',
    'वियूथित',
    'वियोग',
    'वियोगक',
    'वियोगातुर',
    'वियोगात्मा',
    'वियोगानस',
    'वियोगित',
    'वियोगिता',
    'वियोगिनी',
    'वियोगी',
    'वियोजक',
    'वियोजन',
    'वियोजना',
    'वियोजित',
    'वियोज्य',
    'वियोज्यात्मक',
    'विरंगीकरण',
    'विरंजक',
    'विरंजन',
    'विरंजव्य',
    'विरंजित',
    'विरंजितता',
    'विरंधि',
    'विरक्त',
    'विरक्ति',
    'विरचना',
    'विरचित',
    'विरचिति',
    'विरचेता',
    'विरज',
    'विरजस्क',
    'विरजस्का',
    'विरजा',
    'विरत',
    'विरतता',
    'विरति',
    'विरथ',
    'विरथ्या',
    'विरदैत',
    'विरमण',
    'विरमणीय',
    'विरमता',
    'विरमना',
    'विरमाना',
    'विरमित',
    'विरम्य',
    'विरल',
    'विरलक',
    'विरलता',
    'विरला',
    'विरलित',
    'विरव',
    'विरस',
    'विरसता',
    'विरह',
    'विरहन',
    'विरहातुर',
    'विरहानल',
    'विरहिणी',
    'विरहित',
    'विरहितता',
    'विरहिन',
    'विरही',
    'विराग',
    'विराज',
    'विराजना',
    'विराजमान',
    'विराजित',
    'विराज्ञी',
    'विराट',
    'विराटता',
    'विराद्घ',
    'विराधन',
    'विराम',
    'विरामक',
    'विरामकाल',
    'विरामण',
    'विरामतः',
    'विरामपूर्ण',
    'विरामयुक्त',
    'विरामशील',
    'विरामित',
    'विराव',
    'विरावण',
    'विरावित',
    'विरावी',
    'विराष्ट्रीयकरण',
    'विराष्ट्रीयकृत',
    'विरासत',
    'विरासती',
    'विरासतें',
    'विरिक्त',
    'विरुचि',
    'विरुत',
    'विरुद',
    'विरुदावली',
    'विरुद्घ',
    'विरुद्घतः',
    'विरुद्घता',
    'विरुद्ध',
    'विरुद्धतया',
    'विरुद्धार्थ',
    'विरुप',
    'विरुपण',
    'विरुपता',
    'विरुपाक्ष',
    'विरुपित',
    'विरुपितांग',
    'विरूद्घ',
    'विरूद्घता',
    'विरूद्ध',
    'विरूपण',
    'विरूपता',
    'विरूपप्राणी',
    'विरूपित',
    'विरेचक',
    'विरेचन',
    'विरोचन',
    'विरोचना',
    'विरोद्घा',
    'विरोध',
    'विरोधक',
    'विरोधजनक',
    'विरोधन',
    'विरोधपूर्ण',
    'विरोधपूर्वक',
    'विरोधहीन',
    'विरोधहीनता',
    'विरोधात्मक',
    'विरोधाधीन',
    'विरोधाभास',
    'विरोधाभासी',
    'विरोधार्थकता',
    'विरोधार्थी',
    'विरोधालंकार',
    'विरोधित',
    'विरोधिता',
    'विरोधियों',
    'विरोधी',
    'विरोधीपन',
    'विर्दीर्ण',
    'विर्सजन',
    'विल',
    'विलंधक',
    'विलंधन',
    'विलंधित',
    'विलंध्य',
    'विलंब',
    'विलंबक',
    'विलंबकारी',
    'विलंबतः',
    'विलंबन',
    'विलंबपूर्वक',
    'विलंबहीन',
    'विलंबहीनता',
    'विलंबित',
    'विलंबी',
    'विलक्षण',
    'विलक्षणता',
    'विलक्षणा',
    'विलग',
    'विलगतः',
    'विलगता',
    'विलगन',
    'विलगना',
    'विलगाना',
    'विलगाव',
    'विलगित',
    'विलगितता',
    'विलगित्र',
    'विलग्न',
    'विलग्नक',
    'विलग्नता',
    'विलग्नन',
    'विलछण',
    'विलपन',
    'विलब्ध',
    'विलब्धि',
    'विलम्ब',
    'विलम्बक',
    'विलम्बकारी',
    'विलम्बन',
    'विलम्बवादी',
    'विलम्बित',
    'विलय',
    'विलयन',
    'विलयनवादी',
    'विलयनशील',
    'विलयनशीलता',
    'विलयित',
    'विलयितता',
    'विलवणीकरण',
    'विलसना',
    'विलसप्रिय',
    'विला',
    'विलांध',
    'विलाप',
    'विलापन',
    'विलापी',
    'विलापोक्ति',
    'विलायत',
    'विलायती',
    'विलास',
    'विलासक',
    'विलासना',
    'विलासपूर्ण',
    'विलासप्रियता',
    'विलासमय',
    'विलासिता',
    'विलासितापूर्ण',
    'विलासिनी',
    'विलासी',
    'विलासोत्सव',
    'विलिंग',
    'विलिप्त',
    'विलीन',
    'विलीनता',
    'विलीनन',
    'विलुंठित',
    'विलुचन',
    'विलुठित',
    'विलुप्त',
    'विलुप्तता',
    'विलुप्ति',
    'विलुब्ध',
    'विलुब्धन',
    'विलुभन',
    'विलेख',
    'विलेखक',
    'विलेखन',
    'विलेखा',
    'विलेखित',
    'विलेखी',
    'विलेन',
    'विलेप',
    'विलेपन',
    'विलेपी',
    'विलेप्य',
    'विलेप्यकार',
    'विलोकक',
    'विलोकन',
    'विलोकनि',
    'विलोकनीय',
    'विलोकित',
    'विलोचन',
    'विलोड़',
    'विलोड़क',
    'विलोड़ना',
    'विलोडित्रत',
    'विलोप',
    'विलोपक',
    'विलोपन',
    'विलोपित',
    'विलोभक',
    'विलोभन',
    'विलोभी',
    'विलोम',
    'विलोमता',
    'विलोल',
    'विलोलता',
    'विलोलन',
    'विलोलित',
    'विलोलुप',
    'विलोहित',
    'विल्गत',
    'विल्ल',
    'विल्लंगमित',
    'विल्लका',
    'विल्लज',
    'विल्लोल',
    'विल्व',
    'विवक्ता',
    'विवक्षा',
    'विवक्षित',
    'विवक्षितार्थ',
    'विवक्षु',
    'विवजिर्त',
    'विवर',
    'विवरण',
    'विवरणकार',
    'विवरणपत्र',
    'विवरणपूर्वक',
    'विवरणात्मक',
    'विवरणिका',
    'विवरणियां',
    'विवरणियों',
    'विवरणी',
    'विवरणों',
    'विवर्गीकरण',
    'विवर्जन',
    'विवर्जित',
    'विवर्ण',
    'विवर्णता',
    'विवर्त',
    'विवर्तक',
    'विवर्तन',
    'विवर्तनशील',
    'विवर्तनिक',
    'विवर्तनी',
    'विवर्तिका',
    'विवर्तित',
    'विवर्ती',
    'विवर्तुल',
    'विवर्धक',
    'विवर्धन',
    'विवश',
    'विवशतः',
    'विवशता',
    'विवस्त्र',
    'विवस्त्रता',
    'विवस्त्रा',
    'विवस्वत',
    'विवस्वान',
    'विवस्वानः',
    'विवह',
    'विवहन',
    'विवाक',
    'विवाचक',
    'विवाचन',
    'विवाद',
    'विवादग्रस्त',
    'विवादप्रिय',
    'विवादहीन',
    'विवादातीत',
    'विवादात्मक',
    'विवादात्मकता',
    'विवादार्थी',
    'विवादास्पद',
    'विवादित',
    'विवादी',
    'विवादीय',
    'विवादोन्मुख',
    'विवारक',
    'विवास',
    'विवाह',
    'विवाहः',
    'विवाहकाम',
    'विवाहन',
    'विवाहपूर्व',
    'विवाहा',
    'विवाहाकांक्षी',
    'विवाहित',
    'विवाहिता',
    'विवाहिताः',
    'विवाही',
    'विवाहीय',
    'विवाहेच्छुक',
    'विवाहेतर',
    'विवाहोच्छेदन',
    'विवाहोपरांत',
    'विवाह्य',
    'विविक्त',
    'विविक्ति',
    'विविध',
    'विविधतः',
    'विविधता',
    'विविधा',
    'विविधीकरण',
    'विवृत्ति',
    'विवृत्तिभीति',
    'विवेक',
    'विवेकपूर्ण',
    'विवेकपूर्णता',
    'विवेकवाद',
    'विवेकवादी',
    'विवेकवान',
    'विवेकशील',
    'विवेकसम्मत',
    'विवेकहीन',
    'विवेकहीनतः',
    'विवेकहीनता',
    'विवेका',
    'विवेकात्मा',
    'विवेकाधिकार',
    'विवेकाधीन',
    'विवेकानुदान',
    'विवेकी',
    'विवेकीकरण',
    'विवेचक',
    'विवेचन',
    'विवेचना',
    'विवेचनात्मक',
    'विवेचित',
    'विवैन्यीकरण',
    'विश',
    'विशद',
    'विशदकारी',
    'विशदता',
    'विशदन',
    'विशदीकरण',
    'विशप',
    'विशयक',
    'विशल्क',
    'विशल्य',
    'विशल्या',
    'विशषः',
    'विशांपति',
    'विशाख',
    'विशाखक',
    'विशाखन',
    'विशाखमूल',
    'विशाखा',
    'विशाप',
    'विशाययुद्घ',
    'विशारद',
    'विशारदता',
    'विशारध',
    'विशाल',
    'विशालक',
    'विशालकाय',
    'विशालकायता',
    'विशालता',
    'विशालतावाद',
    'विशालदर्शिता',
    'विशालदर्शी',
    'विशालदृश्य',
    'विशालनयन',
    'विशालनयना',
    'विशालबाहु',
    'विशाललोचन',
    'विशाललोचना',
    'विशालवक्षा',
    'विशालहृदय',
    'विशालाक्ष',
    'विशालाक्षी',
    'विशालीकरण',
    'विशिख',
    'विशिप',
    'विशिरा',
    'विशिष्ट',
    'विशिष्टतः',
    'विशिष्टता',
    'विशिष्टतापूर्वक',
    'विशिष्टतावादी',
    'विशिष्टाद्वैत',
    'विशिष्टाधिकार',
    'विशिष्टि',
    'विशिष्टीकरण',
    'विशिष्टीकृत',
    'विशिष्टीभवन',
    'विशीर्ण',
    'विशीर्षा',
    'विशु',
    'विशुचिका',
    'विशुद्घ',
    'विशुद्घता',
    'विशुद्घा',
    'विशुद्घात्मा',
    'विशुद्ध',
    'विशुद्धि',
    'विशुष्कन',
    'विशून्य',
    'विशृंखल',
    'विशृंखलता',
    'विशृंखलित',
    'विशृंग',
    'विशेशता',
    'विशेष',
    'विशेषः',
    'विशेषक',
    'विशेषकर',
    'विशेषकृत',
    'विशेषज्ञ',
    'विशेषज्ञता',
    'विशेषज्ञों',
    'विशेषण',
    'विशेषणात्मकता',
    'विशेषतः',
    'विशेषतया',
    'विशेषता',
    'विशेषताएं',
    'विशेषताओं',
    'विशेषतायें',
    'विशेषांक',
    'विशेषाधिकार',
    'विशेषाधिकारवाद',
    'विशेषाधिकारी',
    'विशेषित',
    'विशेषी',
    'विशेषीकरण',
    'विशेष्ज्ञ',
    'विशेष्य',
    'विशोक',
    'विशोचन',
    'विशोधक',
    'विशोधन',
    'विशोधनी',
    'विशोधित',
    'विशोधिनी',
    'विशोधी',
    'विशोभित',
    'विशोषक',
    'विशोषण',
    'विशोषी',
    'विश्रंभ',
    'विश्रब्ध',
    'विश्रवा',
    'विश्रवाः',
    'विश्रवाटन',
    'विश्रांत',
    'विश्रांति',
    'विश्रान्ति',
    'विश्राम',
    'विश्रामप्रद',
    'विश्रामप्रिय',
    'विश्रामप्रियता',
    'विश्रामहीनतः',
    'विश्रामहीनता',
    'विश्रामानुदान',
    'विश्रामालय',
    'विश्रामावकाश',
    'विश्रुत',
    'विश्रृंखला',
    'विश्लेष',
    'विश्लेषक',
    'विश्लेषण',
    'विश्लेषणवादी',
    'विश्लेषणात्मक',
    'विश्लेषित',
    'विश्व',
    'विश्वंकर',
    'विश्वंभर',
    'विश्वंभरा',
    'विश्वक',
    'विश्वकता',
    'विश्वकर्म',
    'विश्वकर्मा',
    'विश्वकर्माः',
    'विश्वकेतु',
    'विश्वकोश',
    'विश्वक्सेन',
    'विश्वग',
    'विश्वगत',
    'विश्वगुरु',
    'विश्वगोप्ता',
    'विश्वचक्षु',
    'विश्वजनिन',
    'विश्वजनीन',
    'विश्वजय',
    'विश्वजयी',
    'विश्वजित',
    'विश्वजेता',
    'विश्वज्ञान',
    'विश्वदृक',
    'विश्वदेव',
    'विश्वधारी',
    'विश्वनाथ',
    'विश्वनाम',
    'विश्वपति',
    'विश्वपाणि',
    'विश्वपाल',
    'विश्वबोध',
    'विश्वभद्र',
    'विश्वभुज',
    'विश्वमूर्ति',
    'विश्वयुद्घ',
    'विश्वयुद्ध',
    'विश्वरथ',
    'विश्वरुप',
    'विश्वरुपता',
    'विश्वरुपी',
    'विश्वरूप',
    'विश्वलिपि',
    'विश्ववादी',
    'विश्वविद',
    'विश्वविद्यालय',
    'विश्वविद्या्य',
    'विश्ववेवा',
    'विश्वव्यवस्था',
    'विश्वव्यापक',
    'विश्वव्यापकता',
    'विश्वव्यापी',
    'विश्वसन',
    'विश्वसनीय',
    'विश्वसनीयता',
    'विश्वसह',
    'विश्वसोत्पादकता',
    'विश्वस्त',
    'विश्वस्तता',
    'विश्वा',
    'विश्वांड',
    'विश्वाः',
    'विश्वाची',
    'विश्वाटन',
    'विश्वात्मा',
    'विश्वाधिव',
    'विश्वानर',
    'विश्वाप्सु',
    'विश्वामित्र',
    'विश्वायन',
    'विश्वावसु',
    'विश्वास',
    'विश्वासघात',
    'विश्वासघातता',
    'विश्वासघाती',
    'विश्वासधाती',
    'विश्वासपर',
    'विश्वासपात्र',
    'विश्वासपात्रता',
    'विश्वासपूर्ण',
    'विश्वासपूर्वक',
    'विश्वासप्रद',
    'विश्वासप्रदता',
    'विश्वासशील',
    'विश्वासहीन',
    'विश्वासहीनता',
    'विश्वासी',
    'विश्वासों',
    'विश्वासोत्पादक',
    'विश्वास्य',
    'विश्वीय',
    'विश्वेदेव',
    'विश्वेश',
    'विश्वेशा',
    'विश्वेश्वर',
    'विश्वोत्पत्ति',
    'विश्वोश',
    'विश्वौषध',
    'विष',
    'विषकंठ',
    'विषग्रन्थि',
    'विषघातक',
    'विषघाती',
    'विषज',
    'विषजता',
    'विषजन',
    'विषजन्य',
    'विषजन्यता',
    'विषदंश',
    'विषदायी',
    'विषधर',
    'विषधरी',
    'विषधानी',
    'विषध्न',
    'विषपायी',
    'विषपाह',
    'विषम',
    'विषमंत्र',
    'विषमकोण',
    'विषमजातीय',
    'विषमजातीयता',
    'विषमतल',
    'विषमता',
    'विषमधार',
    'विषमबाण',
    'विषमबाहु',
    'विषमय',
    'विषमलिण्ग्गकामी',
    'विषमशर',
    'विषमस्तरीय',
    'विषमांक',
    'विषमिका',
    'विषमीकरण',
    'विषमेक्षण',
    'विषमेषु',
    'विषय',
    'विषयक',
    'विषयपरक',
    'विषयम',
    'विषयरत',
    'विषयवस्तु',
    'विषयवाद',
    'विषयवासना',
    'विषयवासनाहीनता',
    'विषयहीनता',
    'विषया',
    'विषयांतर',
    'विषयांतरता',
    'विषयात्मक',
    'विषयानुकूलता',
    'विषयानुक्रमणिका',
    'विषयानुसार',
    'विषयान्तर',
    'विषयान्तरण',
    'विषयासक्त',
    'विषयासक्ति',
    'विषयासक्तिहीन',
    'विषयी',
    'विषयेतर',
    'विषयों',
    'विषरुपा',
    'विषवत',
    'विषहर',
    'विषहरा',
    'विषांतक',
    'विषाक्त',
    'विषाक्तता',
    'विषाण',
    'विषाणी',
    'विषाणु',
    'विषाणुज',
    'विषाद',
    'विषादग्रस्त',
    'विषादपूर्ण',
    'विषादपूर्णता',
    'विषादमय',
    'विषादमयता',
    'विषादित',
    'विषादिता',
    'विषादी',
    'विषादोन्माद',
    'विषापह',
    'विषायुध',
    'विषायैध',
    'विषालु',
    'विषालुता',
    'विषावजनक',
    'विषावहीन',
    'विषावहीनता',
    'विषी',
    'विषीषण',
    'विषीषणः',
    'विषुव',
    'विषुवत',
    'विषुवतीय',
    'विषुवांश',
    'विषैला',
    'विषैलापन',
    'विष्कंभ',
    'विष्कंभक',
    'विष्ज्ञय',
    'विष्ट',
    'विष्टंभ',
    'विष्टंभ्न',
    'विष्टप',
    'विष्टर',
    'विष्ठा',
    'विष्ठाभोजिता',
    'विष्ठाभोजी',
    'विष्ठाश्म',
    'विष्णु',
    'विष्णुः',
    'विष्णुपद',
    'विष्णुपदी',
    'विष्णुप्रिया',
    'विष्णुयश',
    'विष्णुरात',
    'विष्प्रभावन',
    'विष्व',
    'विष्हर',
    'विसंकट',
    'विसंकुलक',
    'विसंकेतक',
    'विसंक्रामक',
    'विसंगत',
    'विसंगतता',
    'विसंगति',
    'विसंगतियों',
    'विसंग्रहण',
    'विसंचयी',
    'विसंज्ञ',
    'विसंज्ञित',
    'विसंज्ञीकरण',
    'विसंतुलन',
    'विसंधि',
    'विसंपीडन',
    'विसंबंध',
    'विसंयुक्त',
    'विसंयुजन',
    'विसंयुततः',
    'विसंयूथ',
    'विसंयूथन',
    'विसंयूथित',
    'विसंयोग',
    'विसंयोगित',
    'विसंयोगी',
    'विसंयोजनक',
    'विसंयोजनन',
    'विसंवादिता',
    'विसंवादी',
    'विसंस्करण',
    'विसजिर्त',
    'विसतारक',
    'विसभ्यीकरण',
    'विसम',
    'विसमरुपता',
    'विसमूह',
    'विसमूहन',
    'विसमूहित',
    'विसम्मत',
    'विसम्मति',
    'विसरक',
    'विसरणता',
    'विसरणवाद',
    'विसरणशील',
    'विसरित',
    'विसर्ग',
    'विसर्गी',
    'विसर्जक',
    'विसर्जन',
    'विसर्जित',
    'विसर्प',
    'विसर्पण',
    'विसर्पिका',
    'विसर्पित',
    'विसर्पी',
    'विसामान्य',
    'विसामान्यता',
    'विसार',
    'विसारक',
    'विसारित',
    'विसारी',
    'विसार्य',
    'विसाल',
    'विसिंचन',
    'विसिनी',
    'विसूत्र',
    'विसूरणा',
    'विसूरा',
    'विसृष्ट',
    'विसृष्टा',
    'विसृष्टि',
    'विसैन्यीकरण',
    'विसैन्यीकृत',
    'विसोड',
    'विस्तर',
    'विस्तरण',
    'विस्तरणी',
    'विस्तरित',
    'विस्तार',
    'विस्तारक',
    'विस्तारण',
    'विस्तारतः',
    'विस्तारना',
    'विस्तारपूर्ण',
    'विस्तारपूर्वक',
    'विस्तारयुक्त',
    'विस्तारशील',
    'विस्तारहीन',
    'विस्तारित',
    'विस्तारी',
    'विस्तार्थ',
    'विस्तीर्ण',
    'विस्तीर्णता',
    'विस्तृत',
    'विस्तृतता',
    'विस्तृति',
    'विस्त्रंसन',
    'विस्त्रंसी',
    'विस्त्रक',
    'विस्त्रवित',
    'विस्त्रसा',
    'विस्त्रा',
    'विस्त्राण',
    'विस्त्राव',
    'विस्त्रावक',
    'विस्त्रावी',
    'विस्थपन',
    'विस्थानित',
    'विस्थापक',
    'विस्थापन',
    'विस्थापना',
    'विस्थापित',
    'विस्पंद',
    'विस्पंदन',
    'विस्फार',
    'विस्फारक',
    'विस्फारण',
    'विस्फारित',
    'विस्फारिततः',
    'विस्फीत',
    'विस्फीति',
    'विस्फुटन',
    'विस्फुरण',
    'विस्फुरणशील',
    'विस्फुरित',
    'विस्फुलिंग',
    'विस्फुलिंगन',
    'विस्फृत',
    'विस्फोट',
    'विस्फोटक',
    'विस्फोटकता',
    'विस्फोटित',
    'विस्मय',
    'विस्मयंकर',
    'विस्मयकर',
    'विस्मयकारी',
    'विस्मयजनक',
    'विस्मयजनकता',
    'विस्मयपूर्णता',
    'विस्मयपूर्वक',
    'विस्मयाकुल',
    'विस्मयादि',
    'विस्मयादिबोधक',
    'विस्मयाभिभूत',
    'विस्मरण',
    'विस्मरणशील',
    'विस्मरणशीलता',
    'विस्मित',
    'विस्मृत',
    'विस्मृतः',
    'विस्मृति',
    'विस्मृतियुक्त',
    'विस्वरता',
    'विस्फ़ोट',
    'विस्फ़ोटक',
    'विहँडना',
    'विहँसना',
    'विहंग',
    'विहंगक',
    'विहंगम',
    'विहंगमिका',
    'विहंगारति',
    'विहंगावलोकन',
    'विहंगिका',
    'विहंगिनी',
    'विहंडन',
    'विहग',
    'विहगा',
    'विहगी',
    'विहगेश्वर',
    'विहत',
    'विहरण',
    'विहरना',
    'विहर्ता',
    'विहर्ष',
    'विहान',
    'विहायस',
    'विहार',
    'विहारप्रियता',
    'विहारी',
    'विहास',
    'विहिंस्त्र',
    'विहिंस्त्रता',
    'विहित',
    'विहिततः',
    'विहितता',
    'विहिताचार',
    'विहीन',
    'विहीनतः',
    'विहीनता',
    'विहेठ',
    'विहेठन',
    'विह्न',
    'विह्नमुख',
    'विह्नरेता',
    'विह्वल',
    'विह्वलता',
    'वििस्मतता',
    'वििस्मति',
    'विेलेख',
    'विज़ा',
    'विफ़ल',
    'विफ़लता',
    'वीक',
    'वीकली',
    'वीक्षक',
    'वीक्षण',
    'वीक्षणीय',
    'वीक्षा',
    'वीक्षाधीन',
    'वीक्षित्र',
    'वीचि',
    'वीची',
    'वीज',
    'वीजन',
    'वीज़ा',
    'वीजा',
    'वीटक',
    'वीटा',
    'वीटिका',
    'वीटो',
    'वीण',
    'वीणा',
    'वीणावादक',
    'वीत',
    'वीतकाम',
    'वीतकामता',
    'वीतदंभ',
    'वीतभय',
    'वीतमत्सर',
    'वीतमल',
    'वीतराग',
    'वीतरागी',
    'वीतहोत्र',
    'वीतामेह',
    'वीति',
    'वीथि',
    'वीथिका',
    'वीथी',
    'वीधी',
    'वीनमय',
    'वीनस',
    'वीभत्स',
    'वीभत्सता',
    'वीमैंस',
    'वीया',
    'वीर',
    'वीरंधर',
    'वीरकृत्य',
    'वीरगति',
    'वीरगाथा',
    'वीरचक्रेश्वर',
    'वीरचर्या',
    'वीरण',
    'वीरता',
    'वीरतापूर्ण',
    'वीरतापूर्वक',
    'वीरपूजा',
    'वीरबाहु',
    'वीरभद्र',
    'वीरमन्य',
    'वीररस',
    'वीरवती',
    'वीरवार',
    'वीरवाह',
    'वीरव्रत',
    'वीरश्रेष्ठ',
    'वीरसू',
    'वीरसेन',
    'वीरहा',
    'वीरा',
    'वीरांगना',
    'वीरान',
    'वीराना',
    'वीरानापन',
    'वीरानी',
    'वीरानुचित',
    'वीरासन',
    'वीरूध',
    'वीरेंद्र',
    'वीरेश',
    'वीरेश्वर',
    'वीरोचित',
    'वीरोचिततः',
    'वीर्य',
    'वीर्यज',
    'वीर्यवत्ता',
    'वीर्यवान',
    'वीर्यशाली',
    'वीर्यहीन',
    'वीर्यहीनता',
    'वीर्या',
    'वीर्याणु',
    'वीर्यीय',
    'वीवधिक',
    'वीशी',
    'वीीक्षक',
    'वुक्रूआ',
    'वुज़ू',
    'वुजूद',
    'वुड',
    'वुरस्कृत',
    'वूल',
    'वृंत',
    'वृंताकी',
    'वृंद',
    'वृंदगान',
    'वृंदप्रमुख',
    'वृंदा',
    'वृंदाक',
    'वृंदारक',
    'वृंदावन',
    'वृंदावनेश्वरी',
    'वृंदीय',
    'वृक',
    'वृकदेवी',
    'वृकारि',
    'वृकी',
    'वृकोदर',
    'वृक्क',
    'वृक्का',
    'वृक्किद',
    'वृक्ष',
    'वृक्षः',
    'वृक्षक',
    'वृक्षचर',
    'वृक्षराज',
    'वृक्षरूहा',
    'वृक्षवासी',
    'वृक्षवीथि',
    'वृक्षसंकुल',
    'वृक्षहीन',
    'वृक्षायुर्वेद',
    'वृक्षारोही',
    'वृक्षीय',
    'वृक्षैका',
    'वृक्षों',
    'वृक्षोद्यान',
    'वृत',
    'वृतभंग',
    'वृतांत',
    'वृताकार',
    'वृतान्त',
    'वृति',
    'वृतिका',
    'वृतिकाग्राही',
    'वृत्त',
    'वृत्तः',
    'वृत्तखंड',
    'वृत्तचित्र',
    'वृत्तसादी',
    'वृत्तांत',
    'वृत्तांश',
    'वृत्ताकार',
    'वृत्तान्त',
    'वृत्तार्थ',
    'वृत्ति',
    'वृत्तिक',
    'वृत्तिकर',
    'वृत्तिका',
    'वृत्तिकार',
    'वृत्तिदान',
    'वृत्तिनी',
    'वृत्तिबंदी',
    'वृत्तिवाचक',
    'वृत्तीय',
    'वृत्युपाय',
    'वृत्र',
    'वृत्रारि',
    'वृत्रासुरः',
    'वृथा',
    'वृद्घ',
    'वृद्घक',
    'वृद्घता',
    'वृद्घश्रवा',
    'वृद्घा',
    'वृद्घात्मा',
    'वृद्घाश्रम',
    'वृद्ध',
    'वृद्धविवाह',
    'वृद्धा',
    'वृद्धावस्था',
    'वृद्धि',
    'वृद्धिक',
    'वृद्धिकर',
    'वृद्धिकारी',
    'वृद्धिजीवी',
    'वृद्धिद',
    'वृद्धिरूद्ध',
    'वृद्धिरोध',
    'वृद्धिशाली',
    'वृद्धिशील',
    'वृद्वि',
    'वृन्त',
    'वृन्तक',
    'वृन्तिका',
    'वृन्द',
    'वृन्दगान',
    'वृन्दवादन',
    'वृन्दवाद्य',
    'वृन्दवाद्यकार',
    'वृश्चिक',
    'वृश्चिकी',
    'वृष',
    'वृषकर्मा',
    'वृषकेतु',
    'वृषण',
    'वृषणकोण',
    'वृषधर',
    'वृषध्यज',
    'वृषपति',
    'वृषपर्वा',
    'वृषभ',
    'वृषभः',
    'वृषभकेतु',
    'वृषभयुद्ध',
    'वृषभवाहन',
    'वृषभानुजा',
    'वृषमन्यु',
    'वृषल',
    'वृषलांछन',
    'वृषली',
    'वृषांक',
    'वृषांकज',
    'वृषांतक',
    'वृषाकपायी',
    'वृषाकपि',
    'वृषादित्य',
    'वृषी',
    'वृष्टि',
    'वृष्टिक',
    'वृष्टिकर',
    'वृष्टिजीवन',
    'वृष्टिमय',
    'वृष्टिमान',
    'वृष्टिहीन',
    'वृष्णि',
    'वृष्णिपाल',
    'वृहत',
    'वृहतखंड',
    'वृहत्त्कर्ता',
    'वृहत्मुख',
    'वृहद',
    'वृहदवाचक',
    'वृहदवीक्षा',
    'वृहद्घर्ण',
    'वृहद्भानु',
    'वृहद्रथ',
    'वृहन्नखी',
    'वृहन्नल',
    'वृहस्पति',
    'वे',
    'वेंकटेश्वर',
    'वेंधन',
    'वेअरहाउस',
    'वेइंग',
    'वेक',
    'वेकिन',
    'वेक्ष',
    'वेक्षक',
    'वेक्षण',
    'वेग',
    'वेगपूर्वक',
    'वेगमापी',
    'वेगवत्ता',
    'वेगवान',
    'वेगवाही',
    'वेगशाली',
    'वेगहीन',
    'वेगहीनता',
    'वेगालेख',
    'वेगित',
    'वेगिनी',
    'वेगी',
    'वेट',
    'वेटर',
    'वेटलिफ्',
    'वेटलिफ्टर',
    'वेटलैसनैस',
    'वेटिंग',
    'वेटी',
    'वेड़ा',
    'वेण',
    'वेणा',
    'वेणि',
    'वेणिका',
    'वेणिनी',
    'वेणी',
    'वेणीधारिणी',
    'वेणीर',
    'वेणु',
    'वेणुका',
    'वेतन',
    'वेतनपाल',
    'वेतनभोगिता',
    'वेतनभोगी',
    'वेतनमान',
    'वेतनमास्टर',
    'वेतनवृद्धि',
    'वेतनाधिकारी',
    'वेतस',
    'वेतसिनी',
    'वेतसी',
    'वेता',
    'वेताल',
    'वेताली',
    'वेतालीय',
    'वेत्ता',
    'वेत्र',
    'वेत्रक',
    'वेत्रधर',
    'वेत्रधारी',
    'वेत्रहा',
    'वेत्रिक',
    'वेत्री',
    'वेद',
    'वेदकर्ता',
    'वेदगर्भ',
    'वेदगुप्त',
    'वेदज्ञ',
    'वेदत्रयी',
    'वेदन',
    'वेदना',
    'वेदनांतक',
    'वेदनाहीन',
    'वेदनी',
    'वेदयिता',
    'वेदर्षि',
    'वेदवती',
    'वेदवादी',
    'वेदविद',
    'वेदसी',
    'वेदांग',
    'वेदांत',
    'वेदांती',
    'वेदाज्ञा',
    'वेदादि',
    'वेदाध्यक्ष',
    'वेदानुकूल',
    'वेदानुमादित',
    'वेदानुवचन',
    'वेदानुशासन',
    'वेदारंभ',
    'वेदास्त्र',
    'वेदिका',
    'वेदिजा',
    'वेदित',
    'वेदितव्य',
    'वेदिता',
    'वेदिनी',
    'वेदी',
    'वेदीमान',
    'वेदेश्वर',
    'वेदोदय',
    'वेदोपकरण',
    'वेदोपांग',
    'वेद्य',
    'वेध',
    'वेधक',
    'वेधन',
    'वेधना',
    'वेधनी',
    'वेधनीय',
    'वेधनीयता',
    'वेधशाला',
    'वेधानिका',
    'वेधित',
    'वेधी',
    'वेधीनय',
    'वेध्य',
    'वेध्यता',
    'वेन',
    'वेनीर',
    'वेप',
    'वेपधु',
    'वेपन',
    'वेमा',
    'वेरट',
    'वेला',
    'वेलांचल',
    'वेलातिक्रम',
    'वेलासंगम',
    'वेल्डर',
    'वेल्लन',
    'वेल्लित',
    'वेव',
    'वेश',
    'वेशकार',
    'वेशगृह',
    'वेशधर',
    'वेशधारी',
    'वेशभूषा',
    'वेशम',
    'वेशांत',
    'वेशित',
    'वेशी',
    'वेश्म',
    'वेश्य',
    'वेश्या',
    'वेश्यागमन',
    'वेश्यागामिता',
    'वेश्यागामी',
    'वेश्यागृह',
    'वेश्यालय',
    'वेश्यावास',
    'वेश्यावृत्ति',
    'वेष',
    'वेषकर्मी',
    'वेष्ट',
    'वेष्टक',
    'वेष्टन',
    'वेष्टित',
    'वेष्ठन',
    'वैंकटेश',
    'वैंटिलेटर',
    'वैंप',
    'वैकतिक',
    'वैकर्तन',
    'वैकल्पिक',
    'वैकल्पित',
    'वैकालिक',
    'वैकुंठ',
    'वैकुंठीय',
    'वैकृत',
    'वैकृत्य',
    'वैकेशन',
    'वैक्यूअम',
    'वैक्यूम',
    'वैक्रम',
    'वैक्रमीय',
    'वैक्रांत',
    'वैक्सीन',
    'वैखरी',
    'वैखानस',
    'वैखानसि',
    'वैगन',
    'वैघटिक',
    'वैचारिक',
    'वैचित्य',
    'वैचित्रय',
    'वैचित्रवीर्य',
    'वैचित्र्य',
    'वैज',
    'वैजयंत',
    'वैजयंतिक',
    'वैजयंती',
    'वैजयिकी',
    'वैजात्य',
    'वैजिक',
    'वैजिटेबल',
    'वैजिटेरियन',
    'वैजिटेशन',
    'वैज्ञानकिता',
    'वैज्ञानिक',
    'वैज्ञानिकता',
    'वैडनैसडे',
    'वैडाल',
    'वैडिंग',
    'वैढमिका',
    'वैणव',
    'वैणवी',
    'वैतंडिक',
    'वैतनिक',
    'वैतनिकता',
    'वैतरणी',
    'वैतान',
    'वैताल',
    'वैतालिक',
    'वैतालिकी',
    'वैद',
    'वैदग्धी',
    'वैदर',
    'वैदर्भ',
    'वैदर्भी',
    'वैदिक',
    'वैदूर्य',
    'वैदेशिक',
    'वैदेह',
    'वैदेहक',
    'वैदेहिक',
    'वैदेही',
    'वैद्य',
    'वैद्यक',
    'वैद्यकी',
    'वैद्यस्थिति',
    'वैद्यिकी',
    'वैद्युत',
    'वैद्युतिक',
    'वैध',
    'वैधता',
    'वैधतावाद',
    'वैधतावादी',
    'वैधनाथ',
    'वैधनी',
    'वैधमिर्क',
    'वैधम्र्य',
    'वैधराज',
    'वैधव्य',
    'वैधव्यः',
    'वैधव्यहीनता',
    'वैधस',
    'वैधातय',
    'वैधाधर',
    'वैधानिक',
    'वैधानिकता',
    'वैधान्न',
    'वैधिक',
    'वैधीकरण',
    'वैधुर्व',
    'वैन',
    'वैनतेय',
    'वैनतेयः',
    'वैनयिक',
    'वैनायिकी',
    'वैनाशिक',
    'वैनीतक',
    'वैनेय',
    'वैनेशियन',
    'वैबुध',
    'वैभव',
    'वैभवपूर्ण',
    'वैभवशालिता',
    'वैभवशाली',
    'वैभवहीन',
    'वैभावर',
    'वैभाषिक',
    'वैभित्रय',
    'वैभिन्य',
    'वैभ्राज',
    'वैमत्य',
    'वैमनस्य',
    'वैमनस्यकारिता',
    'वैमनस्यपूर्ण',
    'वैमनस्यहीन',
    'वैमनस्यहीनता',
    'वैमात्र',
    'वैमात्रा',
    'वैमात्रीय',
    'वैमानिक',
    'वैमानिकी',
    'वैमुख्य',
    'वैमूढक',
    'वैयक्तिक',
    'वैयक्तिकता',
    'वैयाकरण',
    'वैर',
    'वैरकारी',
    'वैरपूर्ण',
    'वैरभाव',
    'वैरहीन',
    'वैराइटी',
    'वैरागी',
    'वैराग्य',
    'वैराज',
    'वैराज्य',
    'वैरिंच',
    'वैरिंच्य',
    'वैरिएबल',
    'वैरिफ़िकेशन',
    'वैरी',
    'वैरूप्य',
    'वैरोचन',
    'वैरोचनि',
    'वैरोद्घार',
    'वैल',
    'वैलक्रो',
    'वैलक्षण्य',
    'वैलक्ष्य',
    'वैलासिटी',
    'वैलूर',
    'वैलोसिटी',
    'वैल्कम',
    'वैल्ड',
    'वैल्डर',
    'वैल्डिंग',
    'वैल्थ',
    'वैल्वेट',
    'वैवण्र्य',
    'वैवर्त',
    'वैवश्यक',
    'वैवश्यकता',
    'वैवश्यकतापूर्ण',
    'वैवस्वत',
    'वैवस्वती',
    'वैवाह',
    'वैवाहिक',
    'वैवाह्य',
    'वैविध्यपूर्ण',
    'वैशंपायन',
    'वैशाख',
    'वैशाखी',
    'वैशाखीय',
    'वैशाख्य',
    'वैशारद्य',
    'वैशालीय',
    'वैशालेय',
    'वैशिक',
    'वैशिष्ट',
    'वैशेषिक',
    'वैशेष्य',
    'वैश्य',
    'वैश्यीय',
    'वैश्रंमक',
    'वैश्रवण',
    'वैश्रवणावास',
    'वैश्व',
    'वैश्वदेव',
    'वैश्वानर',
    'वैश्वेदेव्य',
    'वैश्वेविक',
    'वैषम्य',
    'वैषुवत',
    'वैष्टिक',
    'वैष्णव',
    'वैष्णवः',
    'वैष्णवाचार',
    'वैष्णवी',
    'वैष्णो',
    'वैसा',
    'वैसे',
    'वैस्ट',
    'वैहायस',
    'वैहिकल',
    'वॉलेट',
    'वो',
    'वों',
    'वोट',
    'वोटर',
    'वोटाधिकारी',
    'वोटिंग',
    'वोडका',
    'वोढव्य',
    'वोढ़',
    'वोढ़ा',
    'वोल',
    'वोह',
    'वौल्यूम',
    'व्ध्र्य',
    'व्यंकटेश्वर',
    'व्यंग',
    'व्यंग्य',
    'व्यंग्यकार',
    'व्यंग्यकाव्य',
    'व्यंग्यता',
    'व्यंग्यपूर्ण',
    'व्यंग्यात्मक',
    'व्यंग्यात्मकता',
    'व्यंग्योक्ति',
    'व्यंजक',
    'व्यंजन',
    'व्यंजना',
    'व्यंजनात्मक',
    'व्यंजनार्थ',
    'व्यंजनीकरण',
    'व्यंजित',
    'व्यक्त',
    'व्यक्तता',
    'व्यक्ति',
    'व्यक्तिः',
    'व्यक्तिक',
    'व्यक्तिकरण',
    'व्यक्तिकोश',
    'व्यक्तिगत',
    'व्यक्तित्व',
    'व्यक्तित्वपूर्ण',
    'व्यक्तित्वयुक्त',
    'व्यक्तित्वहीन',
    'व्यक्तित्वहीनता',
    'व्यक्तित्वों',
    'व्यक्तिपरक',
    'व्यक्तियों',
    'व्यक्तिवाचक',
    'व्यक्तिवाद',
    'व्यक्तिवृत',
    'व्यक्तिशः',
    'व्यग्र',
    'व्यग्रतः',
    'व्यग्रता',
    'व्यग्रतापूर्वक',
    'व्यजन',
    'व्यतिकीर्ण',
    'व्यतिक्रम',
    'व्यतिक्रमित',
    'व्यतिक्रमी',
    'व्यतिक्रांति',
    'व्यतिरेक',
    'व्यतिरोपण',
    'व्यतीत',
    'व्यतीतः',
    'व्यतीततः',
    'व्यतीतता',
    'व्यतीति',
    'व्यथन',
    'व्यथयिता',
    'व्यथा',
    'व्यथाकार',
    'व्यथाकारी',
    'व्यथित',
    'व्यथी',
    'व्यपगत',
    'व्यपगमन',
    'व्यपदिष्ट',
    'व्यपदेश',
    'व्यपदेश्य',
    'व्यपनय',
    'व्यपनीत',
    'व्यपनेता',
    'व्यपरोपण',
    'व्यपहरण',
    'व्यपहरणकर्ता',
    'व्यपेत',
    'व्यपोढ़',
    'व्यभिचार',
    'व्यभिचारिणी',
    'व्यभिचारी',
    'व्यभिमान',
    'व्यय',
    'व्ययः',
    'व्ययक',
    'व्ययशील',
    'व्ययसाध्य',
    'व्ययार्थ',
    'व्ययित',
    'व्ययी',
    'व्यर्थ',
    'व्यर्थतः',
    'व्यर्थता',
    'व्यलीक',
    'व्यवकलन',
    'व्यवकलनीय',
    'व्यवकलित',
    'व्यवच्छेद',
    'व्यवच्छेदक',
    'व्यवच्छेदन',
    'व्यवछेदन',
    'व्यवधान',
    'व्यवधानहीनता',
    'व्यवसनी',
    'व्यवसाय',
    'व्यवसायगत',
    'व्यवसाययुक्त',
    'व्यवसायवाला',
    'व्यवसायहीन',
    'व्यवसायहीनता',
    'व्यवसायांतरण',
    'व्यवसायात्मक',
    'व्यवसायिक',
    'व्यवसायिता',
    'व्यवसायी',
    'व्यवसायीकरण',
    'व्यवस्था',
    'व्यवस्थाएं',
    'व्यवस्थाता',
    'व्यवस्थान',
    'व्यवस्थापक',
    'व्यवस्थापन',
    'व्यवस्थापना',
    'व्यवस्थापिका',
    'व्यवस्थापित',
    'व्यवस्थापूर्वक',
    'व्यवस्थायुक्त',
    'व्यवस्थाहीन',
    'व्यवस्थाहीनतः',
    'व्यवस्थाहीनता',
    'व्यवस्थित',
    'व्यवस्थितता',
    'व्यवस्थीय',
    'व्यवहरण',
    'व्यवहर्ता',
    'व्यवहार',
    'व्यवहारः',
    'व्यवहारकर्ता',
    'व्यवहारकुशलता',
    'व्यवहारक्षम',
    'व्यवहारचतुर',
    'व्यवहारज्ञ',
    'व्यवहारबाह्रय',
    'व्यवहारबाह्रा',
    'व्यवहारहीन',
    'व्यवहारहीनता',
    'व्यवहारिक',
    'व्यवहारी',
    'व्यवहारीय',
    'व्यवहार्य',
    'व्यवहित',
    'व्यवहृत',
    'व्यवहृतता',
    'व्यवहृति',
    'व्यवहृत्य',
    'व्यवासयी',
    'व्यविक्ष्छन्न',
    'व्यवेत',
    'व्यष्टि',
    'व्यष्टिगत',
    'व्यष्टित्व',
    'व्यष्टिविज्ञान',
    'व्यष्टीय',
    'व्यसन',
    'व्यसनकारी',
    'व्यसनहीन',
    'व्यसनहीनता',
    'व्यसनी',
    'व्यस्कता',
    'व्यस्त',
    'व्यस्ततम',
    'व्यस्तता',
    'व्याकरण',
    'व्याकरणज्ञ',
    'व्याकरणातीत',
    'व्याकीर्ण',
    'व्याकीर्णक',
    'व्याकीर्णन',
    'व्याकुंचन',
    'व्याकुल',
    'व्याकुलता',
    'व्याकृत',
    'व्याकृता',
    'व्याक्रोश',
    'व्याक्षिप्त',
    'व्याक्षोभ',
    'व्याख्या',
    'व्याख्याकार',
    'व्याख्यात',
    'व्याख्याता',
    'व्याख्यात्मक',
    'व्याख्यान',
    'व्याख्यापन',
    'व्याघर',
    'व्याघाती',
    'व्याघोष',
    'व्याज',
    'व्याजजीवी',
    'व्याजाह्वय',
    'व्याजी',
    'व्याजोक्ति',
    'व्याड',
    'व्यातिरेकी',
    'व्यादान',
    'व्यादेश',
    'व्याध',
    'व्याधर',
    'व्याधरमुख',
    'व्याधरांबर',
    'व्याधराक्ष',
    'व्याधराजिन',
    'व्याधात',
    'व्याधाती',
    'व्याधि',
    'व्याधिकर',
    'व्याधित',
    'व्याधिध्न',
    'व्याधिशमन',
    'व्याधिहर',
    'व्याधिहीन',
    'व्याधूर्ण',
    'व्यान',
    'व्यानत',
    'व्याप',
    'व्यापक',
    'व्यापकता',
    'व्यापत्ति',
    'व्यापन',
    'व्यापना',
    'व्यापन्न',
    'व्यापर',
    'व्यापरण',
    'व्यापार',
    'व्यापारहीन',
    'व्यापारि',
    'व्यापारिक',
    'व्यापारिकतापूर्ण',
    'व्यापारित',
    'व्यापारियों',
    'व्यापारी',
    'व्यापारीकरण',
    'व्यापारीय',
    'व्यापिनी',
    'व्यापी',
    'व्यापृति',
    'व्याप्त',
    'व्याप्तता',
    'व्याप्ति',
    'व्यामिचारी',
    'व्यामिश्र',
    'व्यामोह',
    'व्यायाम',
    'व्यायामशाला',
    'व्यायामी',
    'व्यारोध',
    'व्यारोष',
    'व्यार्त',
    'व्याल',
    'व्यालता',
    'व्यालमुख',
    'व्यालायुध',
    'व्यालिक',
    'व्याली',
    'व्यालोल',
    'व्यावकलन',
    'व्यावन',
    'व्यावर्त',
    'व्यावर्तक',
    'व्यावर्तन',
    'व्यावसायिक',
    'व्यावहारिक',
    'व्यावहारिकता',
    'व्यावहारिकाता',
    'व्यावृत',
    'व्यावृत्त',
    'व्यावृत्ति',
    'व्यास',
    'व्यासंग',
    'व्यासार्ध',
    'व्यासिद्घ',
    'व्याह',
    'व्याहत',
    'व्याहरण',
    'व्याहृत',
    'व्याहृति',
    'व्यिक्त',
    'व्यिक्तत्व',
    'व्युअर',
    'व्युच्छिन्न',
    'व्युच्छेद',
    'व्युजया',
    'व्युति',
    'व्युत्क्रम',
    'व्युत्क्रमानुपाती',
    'व्युत्धान',
    'व्युत्पत्ति',
    'व्युत्पत्तिक',
    'व्युत्पत्तिविज्ञानी',
    'व्युत्पन्न',
    'व्युत्पन्नी',
    'व्युत्पादक',
    'व्युत्पादन',
    'व्युत्पादित',
    'व्युत्पाद्य',
    'व्युत्सेक',
    'व्युदास',
    'व्युपदेश',
    'व्युपरम',
    'व्यू',
    'व्यूअर',
    'व्यूढ़',
    'व्यूत',
    'व्यूह',
    'व्यूहन',
    'व्यूहबद्घ',
    'व्यूहरचना',
    'व्यूहित',
    'व्योम',
    'व्योमकेश',
    'व्योमगामी',
    'व्योमगुण',
    'व्योमचर',
    'व्योमचारी',
    'व्योमीय',
    'व्योमोदक',
    'व्योरेवार',
    'व्योहरिया',
    'व्रज',
    'व्रजक',
    'व्रजन',
    'व्रजित',
    'व्रज्या',
    'व्रण',
    'व्रणपूय',
    'व्रणारि',
    'व्रत',
    'व्रतः',
    'व्रतचर्या',
    'व्रतचारी',
    'व्रतस्थ',
    'व्रतस्नातक',
    'व्रतांत',
    'व्रताचारी',
    'व्रतारंभ',
    'व्रताहार',
    'व्रतिक',
    'व्रतिनी',
    'व्रती',
    'व्रतोपनयन',
    'व्रतोपवास',
    'व्रतोपहार',
    'व्रतोपायन',
    'व्रत्य',
    'व्राजक',
    'व्राजि',
    'व्राजिका',
    'व्रात',
    'व्रातपति',
    'व्रातीन',
    'व्रात्य',
    'व्रीड़ा',
    'व्रीड़ित',
    'व्रीहि',
    'व्रैहेय',
    'व्वाइट',
    'व्हाइट',
    'व्हिप',
    'व्हिसिल',
    'व्हिस्की',
    'व्हील',
    'व्हेल',
    'वज़न',
    'वफ़ादार',
    'वफ़ादारी',
    'श',
    'शंकनीय',
    'शंकर',
    'शंकरप्रिय',
    'शंकरवाद',
    'शंकरा',
    'शंकराचार्य',
    'शंकराभरण',
    'शंकरावास',
    'शंकरि',
    'शंकरी',
    'शंका',
    'शंकाओं',
    'शंकाग्रस्त',
    'शंकाग्रस्तता',
    'शंकाजनक',
    'शंकापूर्वक',
    'शंकाप्रद',
    'शंकालु',
    'शंकाशील',
    'शंकाशीलता',
    'शंकास्पद',
    'शंकाहीन',
    'शंकाहीनतः',
    'शंकाहीनता',
    'शंकित',
    'शंकिततः',
    'शंकी',
    'शंकु',
    'शंकुदंत',
    'शंकुधारी',
    'शंकुभ',
    'शंकुला',
    'शंकोत्पादक',
    'शंक्य',
    'शंक्वाकार',
    'शंख',
    'शंखक',
    'शंखचक्रधारी',
    'शंखचूड़',
    'शंखधर',
    'शंखध्मा',
    'शंखनखी',
    'शंखनाद',
    'शंखपाणि',
    'शंखमय',
    'शंखलिखित',
    'शंखाकार',
    'शंखाकारता',
    'शंखायन',
    'शंखास्थि',
    'शंखिनी',
    'शंखिल',
    'शंखिलता',
    'शंखी',
    'शंट',
    'शंटकर्ता',
    'शंटन',
    'शंटर',
    'शंटिंग',
    'शंड',
    'शंडील',
    'शंतनु',
    'शंपा',
    'शंपाक',
    'शंबु',
    'शंबुक',
    'शंबूक',
    'शंभल',
    'शंभु',
    'शंभुप्रिया',
    'शंभुसूत',
    'शंभ्र',
    'शंव',
    'शंवर',
    'शंवरांतकर',
    'शंवरारि',
    'शंवरी',
    'शंवल',
    'शंवली',
    'शंवा',
    'शंसन',
    'शंसा',
    'शंसित',
    'शंसी',
    'शंस्य',
    'शंृगार',
    'शऊर',
    'शऊरदार',
    'शक',
    'शकट',
    'शकटक',
    'शकटारि',
    'शकटिका',
    'शकटी',
    'शकर',
    'शकरकंद',
    'शकरकंदी',
    'शकरखोरा',
    'शकरम',
    'शकरी',
    'शकल',
    'शकलित',
    'शकली',
    'शकांग',
    'शकाब्द',
    'शकार',
    'शकुंत',
    'शकुंतक',
    'शकुंतिका',
    'शकुन',
    'शकुनज्ञ',
    'शकुनज्ञा',
    'शकुनि',
    'शकुनिका',
    'शकुनी',
    'शकूर',
    'शकृत',
    'शक्कर',
    'शक्करी',
    'शक्की',
    'शक्त',
    'शक्ति',
    'शक्तिः',
    'शक्तिक्षीण',
    'शक्तिदाता',
    'शक्तिदायक',
    'शक्तिधर',
    'शक्तिध्वज',
    'शक्तिनष्ट',
    'शक्तिपाणि',
    'शक्तिपूर्ण',
    'शक्तिप्रद',
    'शक्तिबाह्य',
    'शक्तिमंत',
    'शक्तिमता',
    'शक्तिमत्ता',
    'शक्तिमान',
    'शक्तिमापी',
    'शक्तिय',
    'शक्तियां',
    'शक्तियों',
    'शक्तिवर्घक',
    'शक्तिवर्धक',
    'शक्तिवान',
    'शक्तिवीर',
    'शक्तिशालिता',
    'शक्तिशाली',
    'शक्तिहीन',
    'शक्तिहीनता',
    'शक्तिह्रासक',
    'शक्य',
    'शक्यता',
    'शक्र',
    'शक्रज',
    'शक्रजजाल',
    'शक्रजित',
    'शक्रधनुष',
    'शक्रपुरी',
    'शक्रशाला',
    'शक्रसुत',
    'शक्राणी',
    'शक्रारि',
    'शक्री',
    'शक्रीय',
    'शक्ल',
    'शक्वरी',
    'शख',
    'शख़्स',
    'शख़्सी',
    'शग़ल',
    'शगाल',
    'शगुन',
    'शगुनिया',
    'शगुफ्तगी',
    'शगुफ्ता',
    'शगूफा',
    'शचि',
    'शची',
    'शचींद्र',
    'शचीः',
    'शचीनाथ',
    'शचीपति',
    'शचीसूत',
    'शजरा',
    'शटर',
    'शटरम',
    'शटल',
    'शटिल',
    'शठ',
    'शठता',
    'शठधी',
    'शण',
    'शत',
    'शतंबूक',
    'शतक',
    'शतगुण',
    'शतघ्नी',
    'शतचंडी',
    'शतजित',
    'शतदल',
    'शतद्रु',
    'शतधा',
    'शतधामा',
    'शतधार',
    'शतपत्र',
    'शतपद',
    'शतपर्व',
    'शतपुष्प',
    'शतपुष्पा',
    'शतप्रतिशत',
    'शतबाहु',
    'शतमख',
    'शतमन्यु',
    'शतमूला',
    'शतमेघ',
    'शतरंज',
    'शतरंजित',
    'शतरंजी',
    'शतरूपा',
    'शतवर्षीय',
    'शतवार्षिक',
    'शतविध',
    'शतविधतः',
    'शतविधता',
    'शतशः',
    'शतांश',
    'शताधिक',
    'शतानंद',
    'शतानन',
    'शतानीक',
    'शताब्द',
    'शताब्दी',
    'शतायु',
    'शतार',
    'शतावधानी',
    'शतावरी',
    'शती',
    'शतोदर',
    'शत्रु',
    'शत्रुंजय',
    'शत्रुओं',
    'शत्रुघाती',
    'शत्रुघ्न',
    'शत्रुघ्नः',
    'शत्रुजय',
    'शत्रुजित',
    'शत्रुता',
    'शत्रुताकारक',
    'शत्रुतादमन',
    'शत्रुतानाशन',
    'शत्रुतापूर्ण',
    'शत्रुतापूर्णता',
    'शत्रुपक्ष',
    'शत्रुपूर्ण',
    'शत्रुमर्दन',
    'शत्रुसहित',
    'शत्रुहंता',
    'शत्रुहा',
    'शत्रुहीन',
    'शत्रुहीनता',
    'शत्वरी',
    'शदवनोद्भव',
    'शनाख़्त',
    'शनाप',
    'शनावर',
    'शनावरी',
    'शनास',
    'शनि',
    'शनिकाल',
    'शनिवलय',
    'शनिवार',
    'शनिश्चर',
    'शनी',
    'शनीचर',
    'शनैः',
    'शनैश्चर',
    'शप',
    'शपथ',
    'शपथकारी',
    'शपथपत्र',
    'शपथपूर्ण',
    'शपथपूर्वक',
    'शपथभंग',
    'शपथहीन',
    'शपथी',
    'शपशप',
    'शपशपाहट',
    'शप्त',
    'शप्ति',
    'शफ',
    'शफर',
    'शफराथिप',
    'शफरी',
    'शफ़क़',
    'शफ़तालू',
    'शफ़ा',
    'शफ़ाख़ाना',
    'शफ़ी',
    'शफ़्फ़ाफ़',
    'शब',
    'शबका',
    'शबगष्त',
    'शबद',
    'शबनम',
    'शबनमी',
    'शबर',
    'शबरः',
    'शबरी',
    'शबरीय',
    'शबरौ',
    'शबल',
    'शबलता',
    'शबाना',
    'शबाब',
    'शबाहत',
    'शबिस्तान',
    'शबीना',
    'शबीह',
    'शबे',
    'शबो',
    'शब्द',
    'शब्दकार',
    'शब्दकोश',
    'शब्दकोष',
    'शब्दजाल',
    'शब्दन',
    'शब्दपाती',
    'शब्दप्रतिशय',
    'शब्दबहुल',
    'शब्दबाहुल्य',
    'शब्दभेद',
    'शब्दभेदी',
    'शब्दमाला',
    'शब्दयोनि',
    'शब्दवादी',
    'शब्दवाही',
    'शब्दवेध',
    'शब्दवेधी',
    'शब्दशः',
    'शब्दहीन',
    'शब्दहीनता',
    'शब्दाक्षर',
    'शब्दाडंबर',
    'शब्दाडंबरपूर्ण',
    'शब्दाडंबरहीन',
    'शब्दाडम्बरपूर्ण',
    'शब्दाडम्बरी',
    'शब्दातीत',
    'शब्दातीतता',
    'शब्दानुक्रमणिका',
    'शब्दानुवाद',
    'शब्दानुशासन',
    'शब्दायमान',
    'शब्दार्थ',
    'शब्दार्थादान',
    'शब्दालंकार',
    'शब्दालेखन',
    'शब्दाल्पता',
    'शब्दावलियां',
    'शब्दावली',
    'शब्दावाली',
    'शब्दिक',
    'शब्दित',
    'शब्दीय',
    'शब्देंद्रिय',
    'शब्दों',
    'शब्दोच्चारण',
    'शब्दोद्गम',
    'शब्बो',
    'शभ',
    'शम',
    'शमक',
    'शमता',
    'शमन',
    'शमनी',
    'शमनीसद',
    'शममिषा',
    'शमला',
    'शमशाद',
    'शमशीर',
    'शमशेर',
    'शमा',
    'शमादान',
    'शमित',
    'शमिर्ला',
    'शमीज़',
    'शमोई',
    'शमोय',
    'शम्बु',
    'शम्बूक',
    'शम्स',
    'शम्सी',
    'शय',
    'शयंड',
    'शयंडक',
    'शयन',
    'शयनकोष्ठ',
    'शयनगृह',
    'शयनशाला',
    'शयनागार',
    'शयनालय',
    'शयालु',
    'शयित',
    'शयिता',
    'शय्या',
    'शर',
    'शरंड',
    'शरअ',
    'शरच्चंद्रिका',
    'शरजन्मा',
    'शरज्ज्योत्सना',
    'शरण',
    'शरणसान',
    'शरणागत',
    'शरणार्थी',
    'शरणी',
    'शरण्य',
    'शरण्यु',
    'शरत',
    'शरत्',
    'शरत्काल',
    'शरत्कालीन',
    'शरत्पद्म',
    'शरद',
    'शरदंड',
    'शरदंत',
    'शरदवान',
    'शरद्कालीन',
    'शरफ़',
    'शरबत',
    'शरबती',
    'शरभंग',
    'शरभाऊ',
    'शरमाना',
    'शरमिंदा',
    'शरमिन्दगी',
    'शरमीला',
    'शरमीलापन',
    'शरयारी',
    'शरयु',
    'शरवाणि',
    'शरव्य',
    'शरह',
    'शराकत',
    'शराग्र',
    'शराटिका',
    'शरापना',
    'शराफ़त',
    'शराफा',
    'शराब',
    'शराबः',
    'शराबखाना',
    'शराबघर',
    'शराबनोशी',
    'शराबबंदी',
    'शराबी',
    'शराबीपन',
    'शराबोरियत',
    'शरायुध',
    'शरारत',
    'शरारतपूर्ण',
    'शरारती',
    'शरारतीपन',
    'शरारा',
    'शरालि',
    'शरालिका',
    'शराव',
    'शरावक',
    'शरावर',
    'शराश्रय',
    'शरासन',
    'शरी',
    'शरीअत',
    'शरीअते',
    'शरीक',
    'शरीके',
    'शरीफ',
    'शरीफ़',
    'शरीफ़ज़ादा',
    'शरीफ़ज़ादी',
    'शरीफा',
    'शरीर',
    'शरीरः',
    'शरीरक्रिया',
    'शरीरज',
    'शरीरतः',
    'शरीरयुक्तता',
    'शरीरव्य',
    'शरीरहीन',
    'शरीरहीनता',
    'शरीरांत',
    'शरीरांतर',
    'शरीरार्पण',
    'शरीरावरण',
    'शरीरिन',
    'शरीरी',
    'शरीरीय',
    'शरुआत',
    'शरेष्ट',
    'शर्करण',
    'शर्करव्य',
    'शर्करा',
    'शर्कराः',
    'शर्करित',
    'शर्ट',
    'शर्टिंग',
    'शर्त',
    'शर्तनामा',
    'शर्तबंद',
    'शर्तयुक्त',
    'शर्तरहित',
    'शर्तहीन',
    'शर्तहीनता',
    'शर्तिया',
    'शर्ती',
    'शर्तें',
    'शर्बत',
    'शर्म',
    'शर्मद',
    'शर्मनाक',
    'शर्मनाकी',
    'शर्मसार',
    'शर्मा',
    'शर्माऊ',
    'शर्माशर्मी',
    'शर्मिन्दा',
    'शर्मीला',
    'शर्मीलापन',
    'शर्व',
    'शर्वर',
    'शर्वरी',
    'शर्वरीश',
    'शर्वला',
    'शल',
    'शलग़म',
    'शलजम',
    'शलभ',
    'शलल',
    'शलवार',
    'शलाका',
    'शलाकी',
    'शलागम',
    'शली',
    'शल्क',
    'शल्कन',
    'शल्कल',
    'शल्कलता',
    'शल्कहीन',
    'शल्कित',
    'शल्की',
    'शल्पारि',
    'शल्य',
    'शल्यक',
    'शल्यकंठ',
    'शल्यकर्म',
    'शल्यकर्मशाला',
    'शल्यकार',
    'शल्यक्रिया',
    'शल्यचिकित्सा',
    'शल्यता',
    'शल्यविज्ञान',
    'शल्यहीन',
    'शल्ल',
    'शल्लक',
    'शल्लकी',
    'शव',
    'शवकूप',
    'शवगृह',
    'शवच्छेदन',
    'शवदाह',
    'शवयात्रा',
    'शवयान',
    'शवर',
    'शवरति',
    'शवलेपकर्त्ता',
    'शवलेपन',
    'शवविकारी',
    'शवशाला',
    'शवागार',
    'शवाच्छादन',
    'शवाधान',
    'शवाधानक',
    'शवोत्खनन',
    'शश',
    'शशंकोपल',
    'शशक',
    'शशधर',
    'शशधाती',
    'शशपंज',
    'शशा',
    'शशांक',
    'शशाद',
    'शशि',
    'शशिधर',
    'शशिनी',
    'शशिप्रभ',
    'शशिबाला',
    'शशिभूषण',
    'शशिसुत',
    'शशी',
    'शशो',
    'शशोपंज',
    'शष्कुलि',
    'शष्कुली',
    'शसवार',
    'शसुकेष्ट',
    'शस्त',
    'शस्तता',
    'शस्ति',
    'शस्त्र',
    'शस्त्रकर्म',
    'शस्त्रकार',
    'शस्त्रकोष',
    'शस्त्रजीवी',
    'शस्त्रजीवीता',
    'शस्त्रज्ञता',
    'शस्त्रधारी',
    'शस्त्रपूत',
    'शस्त्रमार्ज',
    'शस्त्रशाला',
    'शस्त्रसाज़',
    'शस्त्रहीन',
    'शस्त्रागार',
    'शस्त्राधात',
    'शस्त्रायस',
    'शस्त्रास्त्र',
    'शस्त्री',
    'शस्त्रीकरण',
    'शस्त्रीकृत',
    'शस्त्रीय',
    'शस्त्रोपजीवी',
    'शस्त्रोपयोग',
    'शस्य',
    'शस्यता',
    'शस्यध्न',
    'शस्यपाल',
    'शस्यपूर्ण',
    'शस्यशाली',
    'शस्यहंता',
    'शस्यहीन',
    'शस्यहीनता',
    'शस्यागार',
    'शस्योत्सव',
    'शह',
    'शहंशाह',
    'शहंशाही',
    'शहज़ोरी',
    'शहतीर',
    'शहतूत',
    'शहद',
    'शहनाई',
    'शहनाईनवाज़',
    'शहबाला',
    'शहर',
    'शहरः',
    'शहरगश्त',
    'शहरगिर्द',
    'शहरपनाह',
    'शहरबदर',
    'शहरयार',
    'शहराती',
    'शहरातीपन',
    'शहरी',
    'शहरीपन',
    'शहरों',
    'शहवत',
    'शहादत',
    'शहादतनामा',
    'शहाना',
    'शही',
    'शहीद',
    'शहीदनामा',
    'शहीदयादगार',
    'शहीदी',
    'शहीदे',
    'शहीम',
    'शहीहकार',
    'शा',
    'शाँट',
    'शांकर',
    'शांकरी',
    'शांक्तुश',
    'शांखिक',
    'शांडिल्य',
    'शांत',
    'शांतचित्त',
    'शांतचित्तता',
    'शांतचेता',
    'शांततः',
    'शांतनः',
    'शांतनु',
    'शांता',
    'शांति',
    'शांतिः',
    'शांतिकर',
    'शांतिकाम',
    'शांतिकारक',
    'शांतिकारी',
    'शांतिकाल',
    'शांतिकालीन',
    'शांतिगृह',
    'शांतिद',
    'शांतिनाथ',
    'शांतिपूर्ण',
    'शांतिपूर्णता',
    'शांतिपूर्वक',
    'शांतिप्रद',
    'शांतिप्रिय',
    'शांतिप्रियता',
    'शांतिमय',
    'शांतिमयता',
    'शांतिवादी',
    'शांतिहीन',
    'शांतिहीनता',
    'शांबर',
    'शांबरिक',
    'शांबरी',
    'शांभव',
    'शांभवी',
    'शाइस्तगी',
    'शाइस्ता',
    'शाक',
    'शाकंभरी',
    'शाकंभरीय',
    'शाकजीवी',
    'शाकट',
    'शाकटिक',
    'शाकटी',
    'शाकटीन',
    'शाकनाशी',
    'शाकमय',
    'शाकवीर',
    'शाक़',
    'शाक़ी',
    'शाका',
    'शाकाम्ल',
    'शाकारी',
    'शाकाहार',
    'शाकाहारवाद',
    'शाकाहारित',
    'शाकाहारी',
    'शाकिनी',
    'शाकीय',
    'शाकुंतिक',
    'शाकुन',
    'शाकुनिक',
    'शाके',
    'शाकोपजीवी',
    'शाक्त',
    'शाक्तागम',
    'शाक्तिक',
    'शाक्तिहीन',
    'शाक्तीक',
    'शाक्य',
    'शाक्ष्य',
    'शाख',
    'शाखदार',
    'शाखन',
    'शाखल',
    'शाख़',
    'शाख़चा',
    'शाख़सार',
    'शाख़ा',
    'शाखा',
    'शाखांए',
    'शाखाओं',
    'शाखायुक्त',
    'शाखावार',
    'शाखाशयी',
    'शाखाहीन',
    'शाखी',
    'शाखीय',
    'शाखोच्चार',
    'शागिर्द',
    'शागिर्दाना',
    'शागिर्दी',
    'शाङ्गीं',
    'शाङ्र्ग',
    'शाङ्र्गधारी',
    'शाङ्र्गपाणि',
    'शाटक',
    'शाटी',
    'शाठ',
    'शाठय',
    'शाण',
    'शाणी',
    'शाणोपल',
    'शात',
    'शातता',
    'शातन',
    'शाति',
    'शातिर',
    'शात्रव',
    'शाद',
    'शादकाम',
    'शादकामी',
    'शादमान',
    'शादरीय',
    'शादाब',
    'शादियाना',
    'शादी',
    'शादीशुदा',
    'शाद्घल',
    'शाद्घलता',
    'शान',
    'शानची',
    'शानचीपना',
    'शानदार',
    'शाना',
    'शाने',
    'शान्त',
    'शान्तचित',
    'शान्तचिन्तन',
    'शान्ति',
    'शान्तिकर',
    'शान्तिपूर्ण',
    'शान्तिपूर्णता',
    'शान्तिपूर्वक',
    'शान्तिप्रिय',
    'शान्तिभंजक',
    'शान्तिवादिता',
    'शान्तिवादी',
    'शान्तिस्थापक',
    'शान्तिस्थापन',
    'शाप',
    'शापग्रस्त',
    'शापथिक',
    'शापद',
    'शापन',
    'शापप्रद',
    'शापमुक्त',
    'शापस्त्र',
    'शापांत',
    'शापांतक',
    'शापांषु',
    'शापित',
    'शापोत्सर्ग',
    'शापोदक',
    'शापोद्घार',
    'शाफरिक',
    'शाफ़ा',
    'शाफ्',
    'शाबर',
    'शाबरी',
    'शाबान',
    'शाबानः',
    'शाबाश',
    'शाबाशी',
    'शाबासी',
    'शाब्दिक',
    'शाब्दिकता',
    'शाब्दी',
    'शाम',
    'शामः',
    'शामक',
    'शामत',
    'शामतज़दा',
    'शामती',
    'शामन',
    'शामलात',
    'शामलाती',
    'शामित्र',
    'शामियाना',
    'शामिल',
    'शामिलात',
    'शामिलाती',
    'शामी',
    'शामीन',
    'शामे',
    'शायक',
    'शायद',
    'शायर',
    'शायराना',
    'शायरी',
    'शाया',
    'शायिका',
    'शायिकाओं',
    'शायिनी',
    'शायी',
    'शारंग',
    'शारंगधारी',
    'शारंगपाणि',
    'शारंगा',
    'शारंगायुध',
    'शारंगिका',
    'शारंगी',
    'शारद',
    'शारदा',
    'शारदिक',
    'शारदी',
    'शारदीय',
    'शारदेव',
    'शारद्य',
    'शारद्वत',
    'शारि',
    'शारिका',
    'शारिपुत्र',
    'शारीरिक',
    'शारीरिकता',
    'शारीरिकी',
    'शार्क',
    'शार्कर',
    'शार्टूल',
    'शार्दूल',
    'शार्पनर',
    'शार्मिंदा',
    'शार्व',
    'शार्वर',
    'शाल',
    'शालका',
    'शालग्राम',
    'शालपर्णी',
    'शालमलि',
    'शालव',
    'शालशिला',
    'शाला',
    'शालांकी',
    'शालाक्य',
    'शालामुख',
    'शालामृग',
    'शालार',
    'शालि',
    'शालिता',
    'शालिनी',
    'शालिहोत्री',
    'शाली',
    'शालीन',
    'शालीनतः',
    'शालीनता',
    'शालीना',
    'शालुक',
    'शालेय',
    'शाल्मली',
    'शाव',
    'शावक',
    'शाश्वत',
    'शाश्वतकाल',
    'शाश्वतता',
    'शाश्वतपन',
    'शाश्वतवाद',
    'शाश्वता',
    'शासक',
    'शासकीय',
    'शासन',
    'शासनकाल',
    'शासनधर',
    'शासनबद्घ',
    'शासनबद्घता',
    'शासनहर',
    'शासनहरी',
    'शासनहीन',
    'शासनहीनता',
    'शासनाधीन',
    'शासनाध्यक्ष',
    'शासनिक',
    'शासित',
    'शासितता',
    'शासिता',
    'शासी',
    'शास्ता',
    'शास्ति',
    'शास्तिक',
    'शास्त्र',
    'शास्त्रकार',
    'शास्त्रगार',
    'शास्त्रग्रंथ',
    'शास्त्रज्ञ',
    'शास्त्रज्ञता',
    'शास्त्रतात्पर्य',
    'शास्त्रविद',
    'शास्त्रसिद्घ',
    'शास्त्राचरण',
    'शास्त्रादेश',
    'शास्त्रानुकूल',
    'शास्त्रार्थ',
    'शास्त्रियों',
    'शास्त्री',
    'शास्त्रीय',
    'शास्त्रीयता',
    'शास्त्रोक्त',
    'शास्त्रोपदेशक',
    'शास्र',
    'शाह',
    'शाहकारउत्कृष्ट',
    'शाहख़र्च',
    'शाहगाम',
    'शाहजहाँ',
    'शाहजहानाबाद',
    'शाहज़ादा',
    'शाहतीर',
    'शाहदरा',
    'शाहदाना',
    'शाहनशीं',
    'शाहपर',
    'शाहबाला',
    'शाहराह',
    'शाहवार',
    'शाहवैत',
    'शाहानमिज़ाज',
    'शाहाना',
    'शाहानुशाहि',
    'शाहिद',
    'शाही',
    'शाहीनु',
    'शाहीपन',
    'शिंगरफ',
    'शिंगरफी',
    'शिंगाड़ा',
    'शिंगि',
    'शिंघण',
    'शिंघित',
    'शिंज',
    'शिंजन',
    'शिंजा',
    'शिंजित',
    'शिंजिनी',
    'शिंब',
    'शिंबी',
    'शिंशुक',
    'शिंशुपा',
    'शिकंज़ा',
    'शिकंजा',
    'शिकंजे',
    'शिकजबीन',
    'शिकन',
    'शिकनदार',
    'शिकनी',
    'शिकमी',
    'शिकरम',
    'शिकल',
    'शिकवा',
    'शिकस्त',
    'शिकस्ता',
    'शिकस्ताहाल',
    'शिक़',
    'शिक़दार',
    'शिकाकाई',
    'शिकायत',
    'शिकायतकर्ता',
    'शिकायतगर',
    'शिकायती',
    'शिकायतें',
    'शिकायतों',
    'शिकार',
    'शिकारगाह',
    'शिकारवास',
    'शिकारा',
    'शिकारी',
    'शिक्त',
    'शिक्तहीन',
    'शिक्या',
    'शिक्षक',
    'शिक्षकत्व',
    'शिक्षण',
    'शिक्षणालय',
    'शिक्षणीय',
    'शिक्षणीयता',
    'शिक्षा',
    'शिक्षांत',
    'शिक्षाजिर्त',
    'शिक्षात्मक',
    'शिक्षाप्रद',
    'शिक्षारंभ',
    'शिक्षार्जन',
    'शिक्षार्थ',
    'शिक्षार्थी',
    'शिक्षालय',
    'शिक्षालाभ',
    'शिक्षाविद',
    'शिक्षाशास्त्री',
    'शिक्षाशास्त्रीय',
    'शिक्षाहीनता',
    'शिक्षिका',
    'शिक्षित',
    'शिक्षु',
    'शिक्षुता',
    'शिखंड',
    'शिखंडिका',
    'शिखंडिज',
    'शिखंडिनी',
    'शिखंडी',
    'शिखड़ी',
    'शिखनख',
    'शिखर',
    'शिखरन',
    'शिखरस्थ',
    'शिखरिणी',
    'शिखरी',
    'शिखरीय',
    'शिख़्सयत',
    'शिखा',
    'शिखाग्र',
    'शिखाधर',
    'शिखाधारी',
    'शिखावल',
    'शिखावान',
    'शिखिनी',
    'शिखी',
    'शिखीभू',
    'शिखीश्वर',
    'शिगाफ़',
    'शिगाल',
    'शिग्रु',
    'शिग्रुज',
    'शिजंजिका',
    'शिज़ोफ़ेनिया',
    'शिताबकार',
    'शितिकुंभ',
    'शिथिल',
    'शिथिलकाय',
    'शिथिलकायता',
    'शिथिलगामी',
    'शिथिलतः',
    'शिथिलता',
    'शिथिलतापूर्वक',
    'शिथिलन',
    'शिथिलबल',
    'शिथिलाना',
    'शिथिलानुगामी',
    'शिथिलिकरण',
    'शिथिलित',
    'शिथिलीकरण',
    'शिद्दत',
    'शिनाख़्त',
    'शिप',
    'शिपयार्ड',
    'शिपा',
    'शिपिंग',
    'शिपिविष्ट',
    'शिप्रा',
    'शिफ',
    'शिफ़',
    'शिफ़ौन',
    'शिफा',
    'शिफाकंद',
    'शिफारूह',
    'शिफ्',
    'शिमला',
    'शिमशिम',
    'शिम्ब',
    'शिम्बजातीय',
    'शिया',
    'शिर',
    'शिरकत',
    'शिरकती',
    'शिरच्छेद',
    'शिरच्छेदन',
    'शिरसिरूह',
    'शिरस्त्राण',
    'शिरस्थ',
    'शिरस्य',
    'शिरा',
    'शिरातन्त्र',
    'शिराति',
    'शिराबहुल',
    'शिरामय',
    'शिराली',
    'शिराविन्यास',
    'शिरावेध',
    'शिराशल्य',
    'शिराशोथ',
    'शिरिका',
    'शिरी',
    'शिरीय',
    'शिरीयता',
    'शिरीष',
    'शिरों',
    'शिरोधरा',
    'शिरोधाम',
    'शिरोधार्य',
    'शिरोपरि',
    'शिरोबन्धु',
    'शिरोबिंदु',
    'शिरोभाग',
    'शिरोभूषण',
    'शिरोमणि',
    'शिरोमाली',
    'शिरोरत्न',
    'शिरोरूह',
    'शिरोरेखा',
    'शिरोवर्ती',
    'शिरोवल्क',
    'शिरोवस्त्र',
    'शिरोवेष्ठन',
    'शिरोस्थि',
    'शिरोहीन',
    'शिल',
    'शिलपी',
    'शिला',
    'शिलाँग',
    'शिलाज',
    'शिलाजतु',
    'शिलाजीत',
    'शिलाटक',
    'शिलात्मज',
    'शिलादहु',
    'शिलान्यास',
    'शिलापट',
    'शिलाभव',
    'शिलाभूत',
    'शिलामुद्रक',
    'शिलामुद्रण',
    'शिलामुद्रणीय',
    'शिलालेख',
    'शिलाहारी',
    'शिलिकाश्म',
    'शिलींधर',
    'शिलीभूत',
    'शिलीमुख',
    'शिलीय',
    'शिलेय',
    'शिलोत्कीर्णन',
    'शिलोद्भव',
    'शिलौंका',
    'शिल्प',
    'शिल्पक',
    'शिल्पकर्म',
    'शिल्पकार',
    'शिल्पकारिता',
    'शिल्पकारी',
    'शिल्पकृति',
    'शिल्पजीवी',
    'शिल्पज्ञ',
    'शिल्पन',
    'शिल्पविज्ञानीय',
    'शिल्पवैज्ञानिक',
    'शिल्पशिला',
    'शिल्पालय',
    'शिल्पिक',
    'शिल्पी',
    'शिव',
    'शिवंकर',
    'शिवंकरी',
    'शिवंतिका',
    'शिवः',
    'शिवक',
    'शिवकर',
    'शिवकीर्तन',
    'शिवगत',
    'शिवज्ञ',
    'शिवता',
    'शिवदारू',
    'शिवदूती',
    'शिवनेत्र',
    'शिवप्रिय',
    'शिवप्रिया',
    'शिवरंधर',
    'शिवरात्रि',
    'शिवलिंग',
    'शिववंती',
    'शिवहंस',
    'शिवा',
    'शिवाजी',
    'शिवानी',
    'शिवारि',
    'शिवालय',
    'शिवाला',
    'शिवालिक',
    'शिवावास',
    'शिवाह्वय',
    'शिवि',
    'शिविका',
    'शिविर',
    'शिवी',
    'शिवेश्टा',
    'शिवेष्अ',
    'शिशपा',
    'शिशिक्षा',
    'शिशिक्षु',
    'शिशिर',
    'शिशिरकर',
    'शिशिरघ्न',
    'शिशिरता',
    'शिशिरा',
    'शिशिरांत',
    'शिशिरांशु',
    'शिशिरीय',
    'शिशु',
    'शिशुः',
    'शिशुता',
    'शिशुधन',
    'शिशुधानी',
    'शिशुपाल',
    'शिशुपालन',
    'शिशुमार',
    'शिशूचर्या',
    'शिश्न',
    'शिश्नाग्रच्छद',
    'शिश्नावेष्टन',
    'शिष्ट',
    'शिष्टतः',
    'शिष्टता',
    'शिष्टताहीनता',
    'शिष्टमंडल',
    'शिष्टमण्डल',
    'शिष्टाचार',
    'शिष्टाचारज्ञ',
    'शिष्टाचारपूर्वक',
    'शिष्टाचारहीन',
    'शिष्टाचारहीनतः',
    'शिष्टाचारहीनता',
    'शिष्टाचारी',
    'शिष्टि',
    'शिष्टोक्ति',
    'शिष्य',
    'शिष्यता',
    'शिष्या',
    'शिस्त',
    'शिस्त्रत',
    'शिह्लक',
    'शींजिरिका',
    'शीआ',
    'शीकर',
    'शीकाकाई',
    'शीघर',
    'शीघरकर',
    'शीघरकारी',
    'शीघरग',
    'शीघरगामी',
    'शीघरतः',
    'शीघरताः',
    'शीघरतापूर्वक',
    'शीघरधी',
    'शीघरलिपि',
    'शीघरलेख',
    'शीघरलेखक',
    'शीघ्र',
    'शीघ्रकारी',
    'शीघ्रचालनीयता',
    'शीघ्रता',
    'शीट',
    'शीड',
    'शीत',
    'शीतक',
    'शीतकरण',
    'शीतज्वर',
    'शीतता',
    'शीतपिंडन',
    'शीतपुष्प',
    'शीतल',
    'शीतलक',
    'शीतलता',
    'शीतला',
    'शीतलित',
    'शीतलिन्न',
    'शीतलीकृत',
    'शीतसाङ',
    'शीतांक',
    'शीतांशु',
    'शीताकुल',
    'शीतागार',
    'शीताघात',
    'शीतार्त',
    'शीतार्द्र',
    'शीतिका',
    'शीतित्र',
    'शीतिन्न',
    'शीतिमा',
    'शीतोत्तम',
    'शीतोष्ण',
    'शीत्कार',
    'शीत्य',
    'शीधर',
    'शीध्र',
    'शीध्रता',
    'शीन',
    'शीनलन',
    'शीप',
    'शीया',
    'शीर',
    'शीरमाल',
    'शीरा',
    'शीराँ',
    'शीराज़बान',
    'शीराज़ा',
    'शीराज़ाबंद',
    'शीराज्र',
    'शीरीन',
    'शीरीनी',
    'शीरो',
    'शीरौं',
    'शीर्ण',
    'शीर्ष',
    'शीर्षक',
    'शीर्षकाल',
    'शीर्षण्य',
    'शीर्षधर',
    'शीर्षनामा',
    'शीर्षयुक्त',
    'शीर्षवृद्धि',
    'शीर्षस्थ',
    'शीर्षहीन',
    'शीर्षाकाश',
    'शीर्षान्तर',
    'शीर्षिका',
    'शीर्षीय',
    'शील',
    'शीलता',
    'शीलधारी',
    'शीलन',
    'शीलवंत',
    'शीलवंती',
    'शीलवती',
    'शीलवान',
    'शीलहीन',
    'शीलहीनता',
    'शीला',
    'शीलाचार',
    'शीलित',
    'शीली',
    'शील्ड',
    'शीश',
    'शीशफूल',
    'शीशबंध',
    'शीशम',
    'शीशयुक्त',
    'शीशहीन',
    'शीशा',
    'शीशागर',
    'शीशाबाज़',
    'शीशी',
    'शीशे',
    'शीशों',
    'शुंगा',
    'शुंठि',
    'शुंड',
    'शुंडा',
    'शुंडार',
    'शुंडाल',
    'शुंडिका',
    'शुंडी',
    'शुंभ',
    'शुंभानुष्ठान',
    'शुऊर',
    'शुऊरदार',
    'शुक',
    'शुकदेव',
    'शुकायन',
    'शुकी',
    'शुक्कर',
    'शुक्त',
    'शुक्तता',
    'शुक्ता',
    'शुक्ति',
    'शुक्तिक',
    'शुक्तिका',
    'शुक्तिक्षेत्र',
    'शुक्तिज',
    'शुक्तितट',
    'शुक्तिवासक',
    'शुक्तीकरण',
    'शुक्र',
    'शुक्रः',
    'शुक्रगुज़ार',
    'शुक्रगुज़ारी',
    'शुक्रजन',
    'शुक्रजनक',
    'शुक्रमय',
    'शुक्रल',
    'शुक्रवार',
    'शुक्रवाहक',
    'शुक्रवाहिका',
    'शुक्राचार्य',
    'शुक्राणु',
    'शुक्राना',
    'शुक्राशय',
    'शुक्रिया',
    'शुक्रीय',
    'शुक्ल',
    'शुक्लता',
    'शुक्ला',
    'शुक्लांगी',
    'शुक्लांबर',
    'शुक्लाभिसारिका',
    'शुगर',
    'शुचि',
    'शुचिकर्मा',
    'शुचिता',
    'शुचिष्मान',
    'शुतुर',
    'शुतुरगाव',
    'शुतुरमुर्ग',
    'शुद',
    'शुदा',
    'शुदी',
    'शुद्घ',
    'शुद्घकृत',
    'शुद्घचित्त',
    'शुद्घचित्तता',
    'शुद्घता',
    'शुद्घधी',
    'शुद्घन',
    'शुद्घमनस्क',
    'शुद्घवर्ण',
    'शुद्घहृदय',
    'शुद्घांतचारी',
    'शुद्घात्मा',
    'शुद्घाशय',
    'शुद्घाशयता',
    'शुद्घोदन',
    'शुद्घोधन',
    'शुद्ध',
    'शुद्धता',
    'शुद्धि',
    'शुद्धिकर',
    'शुद्धिकरण',
    'शुद्धिकारी',
    'शुद्धीकरण',
    'शुद्धोच्चारण',
    'शुद्र',
    'शुद्रा',
    'शुद्राणी',
    'शुद्रीय',
    'शुद्व',
    'शुनक',
    'शुनाशीर',
    'शुनासीर',
    'शुनि',
    'शुनी',
    'शुन्य',
    'शुबहा',
    'शुभ',
    'शुभंकर',
    'शुभंकरी',
    'शुभकर',
    'शुभकरता',
    'शुभकर्मा',
    'शुभकामना',
    'शुभकामनाएं',
    'शुभकामनाओं',
    'शुभगा',
    'शुभद',
    'शुभदर्शन',
    'शुभन्यु',
    'शुभमस्तु',
    'शुभसूचक',
    'शुभा',
    'शुभांग',
    'शुभांगी',
    'शुभांजन',
    'शुभाकांक्षी',
    'शुभागंतुक',
    'शुभागत',
    'शुभागतं',
    'शुभागमन',
    'शुभारंभ',
    'शुभाशीर्वाद',
    'शुभाशीष',
    'शुभाशुभ',
    'शुभासन',
    'शुभेच्छु',
    'शुभेच्छुक',
    'शुभोदय',
    'शुभ्र',
    'शुभ्रता',
    'शुभ्रदंती',
    'शुभ्रां',
    'शुभ्रांशु',
    'शुमार',
    'शुमारिंद',
    'शुमारी',
    'शुमाल',
    'शुमाली',
    'शुमेच्छा',
    'शुरु',
    'शुरुआत',
    'शुरू',
    'शुरूआत',
    'शुरूआती',
    'शुल्क',
    'शुल्कदर',
    'शुल्काध्यक्ष',
    'शुल्कों',
    'शुल्व',
    'शुल्वज',
    'शुल्वारि',
    'शुवरात्रि',
    'शुश्रूषक',
    'शुश्रूषा',
    'शुषिर',
    'शुष्क',
    'शुष्कता',
    'शुष्कन',
    'शुष्कांग',
    'शुष्कार्द्रक',
    'शुष्कावकृष्ट',
    'शुष्कीकरण',
    'शुहदा',
    'शुीा',
    'शुीावह',
    'शूक',
    'शूकज',
    'शूकधानी',
    'शूकमय',
    'शूकर',
    'शूकरावतार',
    'शूकरी',
    'शूकाकार',
    'शूकी',
    'शूगर',
    'शूज',
    'शूजपाणि',
    'शूटिंग',
    'शूद्र',
    'शूद्रक',
    'शूद्रकेश्वर',
    'शूनज',
    'शूना',
    'शून्य',
    'शून्यक',
    'शून्यकरणीय',
    'शून्यता',
    'शून्यताः',
    'शून्यन',
    'शून्यपाल',
    'शून्यमूल',
    'शून्यवाद',
    'शून्यवादी',
    'शून्या',
    'शून्यां',
    'शून्यांकन',
    'शून्यान्त',
    'शून्येतर',
    'शूप',
    'शूम',
    'शूमिर्का',
    'शूमी',
    'शूर',
    'शूरता',
    'शूरवीर',
    'शूरवीरता',
    'शूरसेन',
    'शूर्प',
    'शूर्पकर्ण',
    'शूर्पकारि',
    'शूर्पणखा',
    'शूर्पा',
    'शूर्मी',
    'शूर्य',
    'शूल',
    'शूलन',
    'शूलना',
    'शूलहस्त',
    'शूलहीन',
    'शूलास्त्र',
    'शूलिक',
    'शूलिका',
    'शूलित',
    'शूली',
    'शूल्य',
    'शृंखल',
    'शृंखला',
    'शृंखलापूर्ण',
    'शृंखलाबद्घ',
    'शृंखलाबद्घता',
    'शृंखलाहीन',
    'शृंखलाहीनतः',
    'शृंखलाहीनता',
    'शृंखलित',
    'शृंखलितता',
    'शृंग',
    'शृंगकूट',
    'शृंगगिरि',
    'शृंगज',
    'शृंगयुक्त',
    'शृंगरित',
    'शृंगवेर',
    'शृंगहीन',
    'शृंगाटक',
    'शृंगारक',
    'शृंगारण',
    'शृंगारहाट',
    'शृंगारिणी',
    'शृंगारी',
    'शृंगिणी',
    'शृंगी',
    'शृगाल',
    'शृगालरूप',
    'शृगालिका',
    'शृगालिनी',
    'शृत',
    'शृतशील',
    'शृतशीलन',
    'शेअर',
    'शेक्सपियर',
    'शेक्सपीयर',
    'शेख',
    'शेखर',
    'शेखरापीड',
    'शेख़',
    'शेख़ी',
    'शेख़ीखोर',
    'शेखी',
    'शेखीबाज',
    'शेखीबाजी',
    'शेखीबाज़',
    'शेखीबाज़ी',
    'शेड',
    'शेप',
    'शेफ',
    'शेफालिका',
    'शेबा',
    'शेयर',
    'शेयरधारक',
    'शेयरधारी',
    'शेयरपूंजी',
    'शेयरों',
    'शेर',
    'शेरदहाँ',
    'शेरदिल',
    'शेरनी',
    'शेरपंजा',
    'शेरपा',
    'शेरमुँहा',
    'शेरिफ',
    'शेरो',
    'शेरों',
    'शेरौं',
    'शेर्तें',
    'शेली',
    'शेव',
    'शेवा',
    'शेष',
    'शेषनाग',
    'शेषफल',
    'शेषफलयुक्त',
    'शेषफलहीन',
    'शेषयुक्त',
    'शेषशायी',
    'शेषांश',
    'शेषांशहीन',
    'शेषात्र',
    'शेषावतार',
    'शेषावस्था',
    'शेषोक्त',
    'शैंपू',
    'शैंपेन',
    'शैकल',
    'शैक्षणिक',
    'शैक्षणिकता',
    'शैक्षणिकी',
    'शैक्षिक',
    'शैख़',
    'शैड',
    'शैडो',
    'शैडोग्राफ',
    'शैडोप्ले',
    'शैडोवर्क',
    'शैतान',
    'शैतानपन',
    'शैतानियत',
    'शैतानी',
    'शैथिल्य',
    'शैदा',
    'शैदाई',
    'शैफ़',
    'शैफ्',
    'शैम',
    'शैम्पेन',
    'शैम्वा',
    'शैया',
    'शैल',
    'शैलक',
    'शैलखटी',
    'शैलख्य',
    'शैलज',
    'शैलजा',
    'शैलधन्वा',
    'शैलधर',
    'शैललूषी',
    'शैलवासी',
    'शैलविज्ञान',
    'शैलांग',
    'शैलाख्य',
    'शैलाग्र',
    'शैलाट',
    'शैलाधारा',
    'शैलाधिप',
    'शैलाली',
    'शैलाह्व',
    'शैलिकी',
    'शैलियों',
    'शैली',
    'शैलीय',
    'शैलीहीनता',
    'शैलूष',
    'शैलूषः',
    'शैलूषिका',
    'शैलेंछ',
    'शैलेय',
    'शैलेयक',
    'शैलेश',
    'शैलेश्वर',
    'शैलैयी',
    'शैलो',
    'शैलोन',
    'शैल्टर',
    'शैल्फ',
    'शैल्य',
    'शैव',
    'शैवय',
    'शैवाल',
    'शैवालविज्ञानी',
    'शैवालिनी',
    'शैशव',
    'शैशवीय',
    'शो',
    'शोक',
    'शोकगीत',
    'शोकगीतकार',
    'शोकग्रस्त',
    'शोकघ्न',
    'शोकजनक',
    'शोकनाशन',
    'शोकपूर्ण',
    'शोकपूर्णता',
    'शोकप्रद',
    'शोकमय',
    'शोकसंतप्त',
    'शोकहंता',
    'शोकहर',
    'शोकहीन',
    'शोकहीनता',
    'शोकांत',
    'शोकाकुल',
    'शोकातुर',
    'शोकात्मक',
    'शोकारि',
    'शोकालय',
    'शोकावसर',
    'शोकाविष्ट',
    'शोकित',
    'शोकी',
    'शोकोच्छवास',
    'शोकोत्सव',
    'शोख',
    'शोखअंदाज',
    'शोखजबान',
    'शोखी',
    'शोच',
    'शोचनीय',
    'शोचनीयता',
    'शोचपूर्ण',
    'शोणित',
    'शोथ',
    'शोथात्मक',
    'शोध',
    'शोधक',
    'शोधक्षय',
    'शोधन',
    'शोधनकर्ता',
    'शोधनक्षम',
    'शोधना',
    'शोधनात्मक',
    'शोधनार्थ',
    'शोधपत्र',
    'शोधपूर्ण',
    'शोधप्रबंध',
    'शोधित',
    'शोधितता',
    'शोध्य',
    'शोध्यपत्र',
    'शोफ',
    'शोफर',
    'शोबदा',
    'शोबदागर',
    'शोबदेबाज',
    'शोबदेबाजी',
    'शोबा',
    'शोभंजन',
    'शोभचार',
    'शोभन',
    'शोभनीय',
    'शोभा',
    'शोभान्वयन',
    'शोभाप्रद',
    'शोभायमान',
    'शोभायात्रा',
    'शोभायान',
    'शोभायुक्त',
    'शोभाहीन',
    'शोभाहीनता',
    'शोभित',
    'शोभिनी',
    'शोर',
    'शोरगुल',
    'शोरगुल्ल',
    'शोरगुल्ला',
    'शोरदार',
    'शोरबा',
    'शोरबे',
    'शोरबेदार',
    'शोरभरी',
    'शोरवाला',
    'शोरशराबा',
    'शोरा',
    'शोरुम',
    'शोला',
    'शोशा',
    'शोष',
    'शोषक',
    'शोषण',
    'शोषयिता',
    'शोषित',
    'शोषित्र',
    'शोषी',
    'शोहदा',
    'शोहदापना',
    'शोहरत',
    'शोहरती',
    'शौंगेय',
    'शौंडिक',
    'शौंडी',
    'शौआल',
    'शौक',
    'शौकत',
    'शौकर',
    'शौक़',
    'शौक़त',
    'शौक़ीन',
    'शौक़ीनी',
    'शौकिया',
    'शौकीन',
    'शौक्तिक',
    'शौक्तेय',
    'शौच',
    'शौचघर',
    'शौचागार',
    'शौचाचार',
    'शौचालय',
    'शौचिक',
    'शौट',
    'शौटीर',
    'शौद्घोदनि',
    'शौद्र',
    'शौनक',
    'शौनिक',
    'शौप',
    'शौपकीपर',
    'शौपकीपिंग',
    'शौपिंग',
    'शौरसेनी',
    'शौरि',
    'शौर्ट',
    'शौर्टहैंड',
    'शौर्य',
    'शौल्विक',
    'शौवर',
    'शौहर',
    'शौहरी',
    'श्दूध',
    'श्पीरीतुश',
    'श्म',
    'श्मशान',
    'श्मश्रु',
    'श्मश्रुधर',
    'श्मश्रुधारी',
    'श्यानित्र',
    'श्याम',
    'श्यामकंठ',
    'श्यामचूड़ा',
    'श्यामपट',
    'श्यामल',
    'श्यामलता',
    'श्यामला',
    'श्यामा',
    'श्यामांग',
    'श्यामिक',
    'श्यामिका',
    'श्याल',
    'श्यालकी',
    'श्याला',
    'श्यालिका',
    'श्याली',
    'श्येन',
    'श्यैनेय',
    'श्रंथ',
    'श्रंथन',
    'श्रंथित',
    'श्रद्घ',
    'श्रद्घा',
    'श्रद्घांजलि',
    'श्रद्घापूर्ण',
    'श्रद्घालु',
    'श्रद्घावान',
    'श्रद्घास्पद',
    'श्रद्घाहीन',
    'श्रद्घाहीनता',
    'श्रद्दधान',
    'श्रद्धा',
    'श्रद्धांजलि',
    'श्रद्धात्मकता',
    'श्रद्धालु',
    'श्रद्धालुतापूर्वक',
    'श्रद्धावान',
    'श्रद्धाहीन',
    'श्रद्धेय',
    'श्रद्वा',
    'श्रद्वांजलि',
    'श्रपण',
    'श्रपित',
    'श्रपिता',
    'श्रम',
    'श्रमगान',
    'श्रमजीवी',
    'श्रमण',
    'श्रमणक',
    'श्रमणा',
    'श्रमदक्षताशास्त्र',
    'श्रमदान',
    'श्रमधंटा',
    'श्रमपूर्वक',
    'श्रमप्रधान',
    'श्रमवाद',
    'श्रमशक्ति',
    'श्रमशील',
    'श्रमशीलता',
    'श्रमसाध्य',
    'श्रमसाध्यता',
    'श्रमहीन',
    'श्रमहीनतः',
    'श्रमांश',
    'श्रमिक',
    'श्रमिकता',
    'श्रमिकेतर',
    'श्रमिकों',
    'श्रमी',
    'श्रमेतर',
    'श्रय',
    'श्रयस्कर',
    'श्रयांस',
    'श्रवण',
    'श्रवणमापी',
    'श्रवणा',
    'श्रवणेंद्रिय',
    'श्रवसुर',
    'श्रवित',
    'श्रविष्टाभू',
    'श्रव्य',
    'श्रव्यता',
    'श्रव्यतामापी',
    'श्रांत',
    'श्राता',
    'श्राद्घ',
    'श्राप',
    'श्राव',
    'श्रावक',
    'श्रावगी',
    'श्रावण',
    'श्रावणी',
    'श्रावरुता',
    'श्रावस्ती',
    'श्रावित',
    'श्राविता',
    'श्राव्य',
    'श्रिंक',
    'श्रिक',
    'श्रित',
    'श्रिय',
    'श्रियण',
    'श्रिया',
    'श्री',
    'श्रींत',
    'श्रीकंट',
    'श्रीकर',
    'श्रीकांत',
    'श्रीकाम',
    'श्रीखंड',
    'श्रीगंगानगर',
    'श्रीगर्भ',
    'श्रीदामा',
    'श्रीधर',
    'श्रीनाथ',
    'श्रीनिवासन',
    'श्रीपति',
    'श्रीपथ',
    'श्रीपाल',
    'श्रीपुष्प',
    'श्रीपूर्णता',
    'श्रीप्रद',
    'श्रीप्रदा',
    'श्रीप्रिय',
    'श्रीफल',
    'श्रीभक्ष',
    'श्रीभ्राता',
    'श्रीमंत',
    'श्रीमंती',
    'श्रीमती',
    'श्रीमद',
    'श्रीमदभगवद्गीता',
    'श्रीमद्',
    'श्रीमान',
    'श्रीमाल',
    'श्रीमूति',
    'श्रीयुत',
    'श्रीरंग',
    'श्रीराम',
    'श्रीर्कीति',
    'श्रील',
    'श्रीलंका',
    'श्रीलांकाई',
    'श्रीवत्स',
    'श्रीवत्सक',
    'श्रीवत्सल',
    'श्रीवत्सांक',
    'श्रीवर',
    'श्रीवर्धन',
    'श्रीवास',
    'श्रीवास्तव',
    'श्रीवेष्ट',
    'श्रीवेष्ठ',
    'श्रीश',
    'श्रीसदा',
    'श्रीहत',
    'श्रीहीन',
    'श्रीहीनता',
    'श्रुत',
    'श्रुतकीर्ति',
    'श्रुतग्रंथ',
    'श्रुतनिष्क्रिय',
    'श्रुतर्षि',
    'श्रुतलेख',
    'श्रुतलेखन',
    'श्रुतवाचक',
    'श्रुतानुश्रुति',
    'श्रुति',
    'श्रुतिकदु',
    'श्रुतिगम्य',
    'श्रुतिगम्यता',
    'श्रुतिगोचरता',
    'श्रुतिधर',
    'श्रुतिप्रिय',
    'श्रुतिभाल',
    'श्रुतिमधुर',
    'श्रुतिलेख',
    'श्रुतिसाम्य',
    'श्रुव',
    'श्रुवा',
    'श्रृंखलन',
    'श्रृंखला',
    'श्रृंखलित',
    'श्रृंगार',
    'श्रृंगारात्मकता',
    'श्रृंगारिक',
    'श्रृंगारी',
    'श्रृंगास्थि',
    'श्रृंगिका',
    'श्रृंगीय',
    'श्रृखंला',
    'श्रृगाल',
    'श्रेकि',
    'श्रेणियां',
    'श्रेणियों',
    'श्रेणी',
    'श्रेणीकरण',
    'श्रेणीकरणीय',
    'श्रेणीकृत',
    'श्रेणीगत',
    'श्रेणीपाद',
    'श्रेणीबद्घ',
    'श्रेणीबद्ध',
    'श्रेणीय',
    'श्रेय',
    'श्रेयस्क',
    'श्रेष्ठ',
    'श्रेष्ठतर',
    'श्रेष्ठता',
    'श्रेष्ठमन्यक',
    'श्रेष्ठमन्यता',
    'श्रेष्ठा',
    'श्रेष्ठाश्रमी',
    'श्रेष्ठि',
    'श्रेष्ठी',
    'श्रेस्करी',
    'श्रैणिक',
    'श्रोणी',
    'श्रोणीय',
    'श्रोतसूत्र',
    'श्रोता',
    'श्रोताओं',
    'श्रोतागार',
    'श्रोतावर्ग',
    'श्रोतावृन्द',
    'श्रोतिका',
    'श्रोत्रय',
    'श्रोत्री',
    'श्रौत',
    'श्रौतश्रव',
    'श्रौम्य',
    'श्लथ',
    'श्लथांग',
    'श्लाघनीय',
    'श्लाघा',
    'श्लाघापूर्ण',
    'श्लाघित',
    'श्लाघ्य',
    'श्लाधा',
    'श्लिष्ट',
    'श्लिष्टोक्ति',
    'श्लील',
    'श्लीलता',
    'श्लेश्मला',
    'श्लेष',
    'श्लेषण',
    'श्लेषयुक्त',
    'श्लेषव्य',
    'श्लेषहीन',
    'श्लेषार्थक',
    'श्लेषित',
    'श्लेषी',
    'श्लेषीकरण',
    'श्लेष्ता',
    'श्लेष्म',
    'श्लेष्मल',
    'श्लेष्मा',
    'श्लेष्माभ',
    'श्लेष्मीय',
    'श्लोक',
    'श्वक',
    'श्वकपाल',
    'श्वदंत',
    'श्वपच',
    'श्वपाद',
    'श्वपुच्छ',
    'श्वम्रू',
    'श्वरूप',
    'श्वरूप्य',
    'श्वश्रु',
    'श्वसन',
    'श्वसनमूल',
    'श्वसनीय',
    'श्वसर',
    'श्वसुर',
    'श्वसुरालय',
    'श्वांस',
    'श्वांसवान',
    'श्वांसहीन',
    'श्वांसहीनता',
    'श्वांसांत',
    'श्वांसी',
    'श्वान',
    'श्वानी',
    'श्वानोन्माद',
    'श्वाश्व',
    'श्वास',
    'श्वासग्रह',
    'श्वासद्वार',
    'श्वासयुक्त',
    'श्वासवरुद्घ',
    'श्वासावरोध',
    'श्वासावरोधक',
    'श्वेत',
    'श्वेतक',
    'श्वेतकेतु',
    'श्वेतकेशी',
    'श्वेतता',
    'श्वेतपटल',
    'श्वेतभानु',
    'श्वेतरथ',
    'श्वेतवासा',
    'श्वेतहय',
    'श्वेता',
    'श्वेतांताबर',
    'श्वेतांबर',
    'श्वेताश्व',
    'श्वेताश्वतर',
    'श्वेतिमा',
    'श्वेतोदर',
    'श्श्वांसवती',
    'श्स्त्राजीव',
    'ष',
    'षंड',
    'षंडाली',
    'षंढ',
    'षंढा',
    'षट',
    'षटफलक',
    'षटि्चति',
    'षट्',
    'षट्क',
    'षट्कर्ण',
    'षट्कर्म',
    'षट्कर्मी',
    'षट्कूटा',
    'षट्कृत्तिका',
    'षट्खंड',
    'षट्खंडीय',
    'षट्ज्य',
    'षट्तंत्री',
    'षट्पद',
    'षट्पदज्य',
    'षट्पदा',
    'षट्पदी',
    'षट्पाटीय',
    'षट्पाद',
    'षट्पाश्र्व',
    'षट्शास्त्र',
    'षडंग',
    'षडंगजित',
    'षडंगिनी',
    'षडक्षर',
    'षडज',
    'षडभिज्ञ',
    'षडभुजाकार',
    'षडयंत्र',
    'षड़यंत्र',
    'षड़यंत्रकारी',
    'षडानन',
    'षडानीक',
    'षडाम्नाय',
    'षडि्वकार',
    'षड्',
    'षड्क्लेश',
    'षड्ग',
    'षड्गुण',
    'षड्चक्र',
    'षड्ज',
    'षड्फलक',
    'षड्भागीय',
    'षड्भुजा',
    'षड्भूज',
    'षड्यंत्र',
    'षड्यंत्रकारी',
    'षड्रिपु',
    'षड्वर्ग',
    'षड्स',
    'षण्मातुर',
    'षण्मातृका',
    'षण्मुख',
    'षत्पाश्र्वीय',
    'षदतर',
    'षष्टक',
    'षष्टि',
    'षष्टिक',
    'षष्ठ',
    'षष्ठक',
    'षष्ठम',
    'षष्ठांश',
    'षष्ठी',
    'षष्ठीप्रिय',
    'षष्मासिक',
    'षाडव',
    'षादतर',
    'षाष्ठिक',
    'षोडश',
    'षोडशांशु',
    'षोडशी',
    'षोडशोपचार',
    'ष्लिष्टि',
    'स',
    'सँकरा',
    'सँकराना',
    'सँकरापन',
    'सँडसा',
    'सँडाई',
    'सँपेरा',
    'सँपोला',
    'सँपोलिया',
    'सँभल',
    'सँभलना',
    'सँभला',
    'सँभार',
    'सँभारना',
    'सँभाल',
    'सँभालना',
    'सँभाला',
    'सँवरना',
    'सँवरा',
    'सँवरिया',
    'सँवरे',
    'सँवार',
    'सँवारना',
    'सँवारा',
    'संक',
    'संकंट',
    'संकट',
    'संकटक',
    'संकटकाल',
    'संकटकालीन',
    'संकटग्रस्त',
    'संकटपूर्ण',
    'संकटपूर्णता',
    'संकटप्रद',
    'संकटमयता',
    'संकटमुक्त',
    'संकटमें',
    'संकटहीन',
    'संकटहीनता',
    'संकटात्मक',
    'संकटाधिकार',
    'संकटापन्न',
    'संकटोत्तीर्ण',
    'संकथन',
    'संकथा',
    'संकथित',
    'संकर',
    'संकरक',
    'संकरज',
    'संकरण',
    'संकरता',
    'संकरवाद',
    'संकरा',
    'संकरांत',
    'संकरांयत',
    'संकराश्व',
    'संकरित',
    'संकरी',
    'संकर्म',
    'संकर्ष',
    'संकर्षक',
    'संकर्षण',
    'संकर्षी',
    'संकल',
    'संकलक',
    'संकलन',
    'संकलनकर्ता',
    'संकलनवाद',
    'संकलनवादी',
    'संकलनशील',
    'संकलनशीलता',
    'संकलनस्थान',
    'संकलना',
    'संकलनीय',
    'संकलपना',
    'संकलिका',
    'संकलित',
    'संकलित्र',
    'संकल्प',
    'संकल्पक',
    'संकल्पक्षमता',
    'संकल्पतः',
    'संकल्पन',
    'संकल्पना',
    'संकल्पपूर्ण',
    'संकल्पपूर्वक',
    'संकल्पशील',
    'संकल्पशीलता',
    'संकल्पहीन',
    'संकल्पित',
    'संकल्पी',
    'संकल्पीय',
    'संका',
    'संकाय',
    'संकार',
    'संकारक',
    'संकास',
    'संकीर्ण',
    'संकीर्णता',
    'संकीर्णहृदय',
    'संकीर्तन',
    'संकीर्तित',
    'संकुचक',
    'संकुचन',
    'संकुचनशील',
    'संकुचाहट',
    'संकुचित',
    'संकुचितता',
    'संकुति',
    'संकुल',
    'संकुलता',
    'संकुलन',
    'संकुलना',
    'संकुलित',
    'संकृष्ट',
    'संकेंद्र',
    'संकेंद्रण',
    'संकेंद्रता',
    'संकेंद्रीय',
    'संकेत',
    'संकेतक',
    'संकेतकार',
    'संकेतन',
    'संकेतनी',
    'संकेतपद',
    'संकेतभाषा',
    'संकेतवाचक',
    'संकेतार्थ',
    'संकेतिक',
    'संकेतिका',
    'संकेतित',
    'संकेती',
    'संकेतीकरण',
    'संकेतों',
    'संकेन्द्रण',
    'संकेन्द्रित',
    'संकेरना',
    'संकोच',
    'संकोचक',
    'संकोचता',
    'संकोचन',
    'संकोचपूर्ण',
    'संकोचपूर्वक',
    'संकोचशील',
    'संकोचहीन',
    'संकोचहीनतः',
    'संकोची',
    'संकोप',
    'संक्रंद',
    'संक्रंदन',
    'संक्रम',
    'संक्रमण',
    'संक्रमणक',
    'संक्रमणशील',
    'संक्रमित',
    'संक्रमिता',
    'संक्रांत',
    'संक्रांति',
    'संक्रांतिहीन',
    'संक्रांद्विय',
    'संक्रांमी',
    'संक्राति',
    'संक्रान्ति',
    'संक्रामक',
    'संक्रामकता',
    'संक्रिया',
    'संक्रियाएं',
    'संक्रियात्मक',
    'संक्रीडा',
    'संक्रूद्घ',
    'संक्रोध',
    'संक्लांत',
    'संक्षारक',
    'संक्षारण',
    'संक्षारित',
    'संक्षिप्त',
    'संक्षिप्तकरण',
    'संक्षिप्तकर्ता',
    'संक्षिप्तता',
    'संक्षिप्तना',
    'संक्षिप्तरुप',
    'संक्षिप्तलिपि',
    'संक्षिप्ति',
    'संक्षिप्तीकरण',
    'संक्षुब्ध',
    'संक्षेप',
    'संक्षेपक',
    'संक्षेपकार',
    'संक्षेपण',
    'संक्षेपतः',
    'संक्षेपाक्षर',
    'संक्षेपित',
    'संक्षोप',
    'संक्षोभ',
    'संखा',
    'संख्ंया',
    'संख्य',
    'संख्यक',
    'संख्यता',
    'संख्यनन',
    'संख्या',
    'संख्यांक',
    'संख्यांकन',
    'संख्यांकित',
    'संख्यांतर',
    'संख्याओं',
    'संख्याक्षरिक',
    'संख्यात',
    'संख्यातिग',
    'संख्यातीत',
    'संख्यातीततः',
    'संख्यातीतता',
    'संख्यात्मक',
    'संख्यान',
    'संख्यानक',
    'संख्यायुक्त',
    'संख्यावती',
    'संख्यावाचक',
    'संख्यावान',
    'संख्याविषयक',
    'संख्याहीन',
    'संख्याहीनता',
    'संख्येय',
    'संग',
    'संगख्वार',
    'संगठक',
    'संगठन',
    'संगठनकर्ता',
    'संगठनकर्म',
    'संगठनन',
    'संगठनहीन',
    'संगठनहीनता',
    'संगठनीय',
    'संगठनों',
    'संगठित',
    'संगठिततः',
    'संगठितता',
    'संगणक',
    'संगणको',
    'संगणकों',
    'संगणन',
    'संगणना',
    'संगणित',
    'संगणित्र',
    'संगत',
    'संगतकर',
    'संगतकार',
    'संगतता',
    'संगततापूर्वक',
    'संगतरा',
    'संगति',
    'संगतिपूर्णता',
    'संगतिया',
    'संगतिहीन',
    'संगतिहीनता',
    'संगती',
    'संगदिल',
    'संगदिली',
    'संगबार',
    'संगम',
    'संगमन',
    'संगमरमर',
    'संगमरमरी',
    'संगमित',
    'संगमूसा',
    'संगर',
    'संगरा',
    'संगरोध',
    'संगलक',
    'संगलन',
    'संगलित',
    'संगव',
    'संगसाज',
    'संगसी',
    'संगहगार',
    'संगाती',
    'संगामी',
    'संगायन',
    'संगारि',
    'संगाव',
    'संगिन',
    'संगिनी',
    'संगिस्तान',
    'संगी',
    'संगीत',
    'संगीतक',
    'संगीतकलाहीन',
    'संगीतकार',
    'संगीतज्ञ',
    'संगीतज्ञों',
    'संगीतप्रिय',
    'संगीतबद्घ',
    'संगीतमय',
    'संगीतहीन',
    'संगीता',
    'संगीतात्मक',
    'संगीतात्मकता',
    'संगीति',
    'संगीतिका',
    'संगीन',
    'संगीसाथीहीन',
    'संगीहीन',
    'संगीहीनता',
    'संगुच्छन',
    'संगुच्छित',
    'संगुटिकाश्म',
    'संगुटीकरण',
    'संगुणन',
    'संगुणित',
    'संगुप्त',
    'संगृहीत',
    'संगे',
    'संगोत्र',
    'संगोष्ठी',
    'संग्रंथन',
    'संग्रंथित',
    'संग्रथंक',
    'संग्रथित',
    'संग्रह',
    'संग्रहकार',
    'संग्रहकारी',
    'संग्रहण',
    'संग्रहणशील',
    'संग्रहणीय',
    'संग्रहपाल',
    'संग्रहपुस्तक',
    'संग्रहाध्यक्ष',
    'संग्रहालय',
    'संग्रहिक',
    'संग्रहिका',
    'संग्रहित',
    'संग्रही',
    'संग्रहीत',
    'संग्रहीता',
    'संग्राम',
    'संग्रामीय',
    'संग्राह',
    'संग्राहक',
    'संग्राही',
    'संग्राह्य',
    'संघ',
    'संघचर्या',
    'संघजीविता',
    'संघजीवी',
    'संघट',
    'संघटक',
    'संघटकवियुजन',
    'संघटन',
    'संघटना',
    'संघटनीय',
    'संघटित',
    'संघटीय',
    'संघट्ट',
    'संघट्टना',
    'संघठन',
    'संघठिततः',
    'संघन',
    'संघनन',
    'संघनि',
    'संघनित',
    'संघपति',
    'संघबद्घ',
    'संघबद्घता',
    'संघबद्ध',
    'संघभाव',
    'संघमुक्त',
    'संघर्ष',
    'संघर्षज',
    'संघर्षण',
    'संघर्षशील',
    'संघर्षी',
    'संघवाद',
    'संघवादी',
    'संघशासित',
    'संघसूची',
    'संघाटिका',
    'संघाटी',
    'संघात',
    'संघाती',
    'संघाधिप',
    'संघाराम',
    'संघि',
    'संघित',
    'संघिनी',
    'संघीय',
    'संघोटना',
    'संघोषक',
    'संच',
    'संचंत्र',
    'संचक',
    'संचतना',
    'संचना',
    'संचनीय',
    'संचय',
    'संचयक',
    'संचयकर्त्ता',
    'संचयकार',
    'संचयता',
    'संचयन',
    'संचयिता',
    'संचयी',
    'संचरण',
    'संचरणशील',
    'संचरना',
    'संचल',
    'संचलता',
    'संचलन',
    'संचलनअभाव',
    'संचलनगत',
    'संचलनशील',
    'संचलित',
    'संचविर्त',
    'संचहीनता',
    'संचायक',
    'संचायिका',
    'संचार',
    'संचारक',
    'संचारण',
    'संचारयिता',
    'संचारिणी',
    'संचारित',
    'संचारी',
    'संचालक',
    'संचालन',
    'संचालना',
    'संचालनालय',
    'संचालनीय',
    'संचालनीयता',
    'संचालिका',
    'संचालित',
    'संचाल्य',
    'संचिंतित',
    'संचिका',
    'संचित',
    'संचिति',
    'संची',
    'संचेतना',
    'संचेता',
    'संचेय',
    'संछाद',
    'संछादन',
    'संछिद्र',
    'संजय',
    'संजवन',
    'संजवी',
    'संजा',
    'संजात',
    'संजाफ',
    'संजावन',
    'संजावित',
    'संजियारा',
    'संजी',
    'संजीदा',
    'संजीव',
    'संजीवक',
    'संजीवन',
    'संजीवनी',
    'संजीवी',
    'संजोए',
    'संजोकर',
    'संजोग',
    'संजोगिता',
    'संजोगी',
    'संजोता',
    'संजोते',
    'संजोना',
    'संजोया',
    'संजोये',
    'संजोवल',
    'संज्ञ',
    'संज्ञक',
    'संज्ञप्ति',
    'संज्ञा',
    'संज्ञाघात',
    'संज्ञात',
    'संज्ञाता',
    'संज्ञातीत',
    'संज्ञात्मक',
    'संज्ञान',
    'संज्ञानी',
    'संज्ञापक',
    'संज्ञापन',
    'संज्ञापनेच्छा',
    'संज्ञापयिता',
    'संज्ञापित',
    'संज्ञाल',
    'संज्ञावती',
    'संज्ञावान',
    'संज्ञाहरण',
    'संज्ञाहरणविज्ञानी',
    'संज्ञाहीन',
    'संज्ञाहीनतः',
    'संज्ञाहीनता',
    'संज्ञाहृत',
    'संज्ञिका',
    'संज्ञी',
    'संज्ञेय',
    'संज्ञेयता',
    'संझा',
    'संझिया',
    'संझोखैं',
    'संटी',
    'संड',
    'संडसा',
    'संडसी',
    'संडा',
    'संडास',
    'संडासी',
    'संडिश',
    'संडी',
    'संत',
    'संतति',
    'संततिहीनता',
    'संतत्वीकरणवाद',
    'संतपन',
    'संतप्त',
    'संतप्तता',
    'संतरण',
    'संतरा',
    'संतराई',
    'संतरी',
    'संतरे',
    'संतरों',
    'संतर्कण',
    'संतर्जक',
    'संतर्जन',
    'संतर्पक',
    'संतर्पी',
    'संतांत्रिक',
    'संतांत्रिकी',
    'संतान',
    'संतानक',
    'संतानकाम',
    'संतानप्रद',
    'संतानयुक्ता',
    'संतानवती',
    'संतानवान',
    'संतानस्थानीय',
    'संतानहीन',
    'संतानहीनता',
    'संतानाकांक्षा',
    'संतानीय',
    'संतानेच्छा',
    'संतानेच्छुक',
    'संतानोत्पादन',
    'संतानोपत्ति',
    'संताप',
    'संतापकर',
    'संतापन',
    'संतापहर',
    'संतापहारी',
    'संतापी',
    'संतार्य',
    'संतार्यता',
    'संतुलक',
    'संतुलन',
    'संतुलनकर्ता',
    'संतुलनकारी',
    'संतुलनभार',
    'संतुलनहीन',
    'संतुलनों',
    'संतुलित',
    'संतुलितता',
    'संतुलित्र',
    'संतुष्ट',
    'संतुष्टतः',
    'संतुष्टन',
    'संतुष्टि',
    'संतृप्त',
    'संतृप्ति',
    'संतृप्तिकारक',
    'संतृप्य',
    'संतृप्यता',
    'संतोलक',
    'संतोष',
    'संतोषक',
    'संतोषजनक',
    'संतोषना',
    'संतोषपूर्ण',
    'संतोषपूर्वक',
    'संतोषप्रद',
    'संतोषशील',
    'संतोषित',
    'संतोषी',
    'संतोष्य',
    'संत्यक्ता',
    'संत्याग',
    'संत्यागी',
    'संत्रस्त',
    'संत्राण',
    'संत्राता',
    'संत्रास',
    'संत्रासी',
    'संत्वण',
    'संत्वति',
    'संत्सगी',
    'संथा',
    'संथागा',
    'संथीय',
    'संदंशिका',
    'संदगध',
    'संदत्त',
    'संदर्तिष्णु',
    'संदर्भ',
    'संदर्भग्रंथ',
    'संदर्भतः',
    'संदर्भता',
    'संदर्भनुकूल',
    'संदर्भनुकूलता',
    'संदर्भयुक्त',
    'संदर्भवश',
    'संदर्भशः',
    'संदर्भहीन',
    'संदर्भहीनता',
    'संदर्भाधीन',
    'संदर्भित',
    'संदर्श',
    'संदर्शक',
    'संदर्शन',
    'संदर्शिका',
    'संदर्शित',
    'संदर्शी',
    'संदल',
    'संदलन',
    'संदली',
    'संदषा',
    'संदहन',
    'संदाय',
    'संदायाद',
    'संदिग्ध',
    'संदिग्धतः',
    'संदिग्धता',
    'संदिग्धार्थक',
    'संदिग्धार्थकता',
    'संदिग्धार्थी',
    'संदिष्ट',
    'संदी',
    'संदीप',
    'संदीपक',
    'संदीपन',
    'संदीपिका',
    'संदीपित',
    'संदीप्त',
    'संदीप्ति',
    'संदीप्र',
    'संदुष्ट',
    'संदूक',
    'संदूकचा',
    'संदूकची',
    'संदूकडी',
    'संदूषक',
    'संदूषण',
    'संदूषित',
    'संदूषितता',
    'संदृप्ति',
    'संदृश्य',
    'संदेय',
    'संदेश',
    'संदेशवाहक',
    'संदेशह',
    'संदेशहारी',
    'संदेशा',
    'संदेशी',
    'संदेह',
    'संदेहजनक',
    'संदेहपूर्ण',
    'संदेहपूर्वक',
    'संदेहप्रद',
    'संदेहलाभ',
    'संदेहशील',
    'संदेहहीन',
    'संदेहहीनतः',
    'संदेहहीनता',
    'संदेहात्मक',
    'संदेहास्पद',
    'संदेहास्पदता',
    'संदेही',
    'संदेहीनतः',
    'संदोल',
    'संदोहण',
    'संदोहन',
    'संद्रव',
    'संद्रवण',
    'संद्रावक',
    'संध',
    'संधन',
    'संधना',
    'संधा',
    'संधाता',
    'संधान',
    'संधानक',
    'संधानना',
    'संधानित',
    'संधानित्र',
    'संधानी',
    'संधारण',
    'संधि',
    'संधिकरण',
    'संधिकर्ता',
    'संधिका',
    'संधिकाल',
    'संधित',
    'संधितता',
    'संधिप्रस्ताव',
    'संधिला',
    'संधिवात',
    'संधिवार्ताकार',
    'संधिस्वर',
    'संधिहीन',
    'संधिहीनता',
    'संधी',
    'संधीकरण',
    'संध्या',
    'संध्यांश',
    'संध्याओं',
    'संध्याचल',
    'संध्यापासक',
    'संध्यापासना',
    'संध्योपासक',
    'संध्योपासना',
    'संनाभि',
    'संनिघर्षण',
    'संनिभ',
    'संनियन',
    'संनियमित',
    'संनियमितता',
    'संनियमी',
    'संनिवेशन',
    'संन्यस्त',
    'संन्यास',
    'संन्यासिनी',
    'संन्यासी',
    'संन्साय',
    'संपत्कुमार',
    'संपत्ति',
    'संपत्तिहरण',
    'संपत्तीय',
    'संपद',
    'संपदा',
    'संपदाएं',
    'संपदाओं',
    'संपद्राय',
    'संपन्न',
    'संपन्नता',
    'संपन्नन',
    'संपराय',
    'संपरिधान',
    'संपरिवर्तन',
    'संपरिवर्तित',
    'संपर्क',
    'संपर्ककर्ता',
    'संपर्कहीन',
    'संपर्कहीनता',
    'संपर्कागत',
    'संपात',
    'संपादक',
    'संपादकत्व',
    'संपादकीय',
    'संपादन',
    'संपादनीय',
    'संपादयिता',
    'संपादिका',
    'संपादित',
    'संपाद्य',
    'संपाद्यता',
    'संपार्श्विक',
    'संपिडन',
    'संपिडित',
    'संपित्त',
    'संपित्तशाली',
    'संपित्तशीलता',
    'संपीडक',
    'संपीडन',
    'संपीड़न',
    'संपीडित',
    'संपीडिता',
    'संपीति',
    'संपुंज',
    'संपुज',
    'संपुजन',
    'संपुजिंत',
    'संपुट',
    'संपुटक',
    'संपुटिका',
    'संपुटित',
    'संपुटी',
    'संपुत्र',
    'संपुत्री',
    'संपुष्ट',
    'संपुष्टि',
    'संपूज्य',
    'संपूरक',
    'संपूरण',
    'संपूर्ण',
    'संपूर्णः',
    'संपूर्णकाम',
    'संपूर्णकालिक',
    'संपूर्णतः',
    'संपूर्णतया',
    'संपूर्णता',
    'संपूर्णवृद्घ',
    'संपूर्त',
    'संपूर्ति',
    'संपृकक्तता',
    'संपृक्त',
    'संपृक्ति',
    'संपोला',
    'संपोषक',
    'संपोषकता',
    'संपोषण',
    'संपोषामिन',
    'संपोषित',
    'संपोषिता',
    'संप्रतर्दन',
    'संप्रति',
    'संप्रतीक',
    'संप्रदा',
    'संप्रदान',
    'संप्रदाय',
    'संप्रदायःचिह्न',
    'संप्रदायागत',
    'संप्रदायिक',
    'संप्रदायी',
    'संप्रयोक्ता',
    'संप्रयोग',
    'संप्ररक्षालित',
    'संप्ररदाय',
    'संप्रवर्तक',
    'संप्रवर्तन',
    'संप्रवाह',
    'संप्रवाहन',
    'संप्रवाही',
    'संप्रश्नन',
    'संप्रसादक',
    'संप्रसार',
    'संप्रहार',
    'संप्राणन',
    'संप्राप्त',
    'संप्राप्तयौवना',
    'संप्राप्ति',
    'संप्रिश्नत',
    'संप्रेक्षण',
    'संप्रेतांगना',
    'संप्रेष',
    'संप्रेषक',
    'संप्रेषण',
    'संप्रेषित',
    'संप्रोक्त',
    'संप्लव',
    'संप्लवक',
    'संप्लवन',
    'संप्लवित',
    'संप्लावी',
    'संबंद्घता',
    'संबंध',
    'संबंधक',
    'संबंधतः',
    'संबंधन',
    'संबंधना',
    'संबंधयिता',
    'संबंधहीन',
    'संबंधहीनता',
    'संबंधित',
    'संबंधिता',
    'संबंधितों',
    'संबंधिन',
    'संबंधी',
    'संबंधीय',
    'संबंधु',
    'संबद्घ',
    'संबद्घता',
    'संबद्ध',
    'संबद्धता',
    'संबधित',
    'संबन्ध',
    'संबल',
    'संबा',
    'संबाध',
    'संबोध',
    'संबोधक',
    'संबोधन',
    'संबोधित',
    'संब्रधक',
    'संभक्त',
    'संभग',
    'संभर',
    'संभरण',
    'संभरित',
    'संभल',
    'संभलकर',
    'संभव',
    'संभवत',
    'संभवतः',
    'संभवता',
    'संभवन',
    'संभवना',
    'संभवा',
    'संभवामि',
    'संभविष्णु',
    'संभविष्णुता',
    'संभव्यता',
    'संभाग',
    'संभागकृत',
    'संभागन',
    'संभागिता',
    'संभागीकरण',
    'संभागीकृत',
    'संभागीय',
    'संभाजन',
    'संभाजित',
    'संभार',
    'संभारिक',
    'संभाल',
    'संभालते',
    'संभालन',
    'संभालना',
    'संभाली',
    'संभावन',
    'संभावना',
    'संभावनाएं',
    'संभावनाओं',
    'संभावनार्थक',
    'संभावनावाली',
    'संभावनीय',
    'संभावित',
    'संभावितता',
    'संभाविता',
    'संभावी',
    'संभाव्य',
    'संभाव्यता',
    'संभाव्यताओं',
    'संभाष',
    'संभाषण',
    'संभाषा',
    'संभाषित',
    'संभाषिता',
    'संभाषी',
    'संभाष्य',
    'संभिक्त',
    'संभिन्न',
    'संभुक्त',
    'संभूत',
    'संभूतता',
    'संभूति',
    'संभूतिशील',
    'संभूय',
    'संभृत',
    'संभृति',
    'संभृष्ट',
    'संभेद',
    'संभेदक',
    'संभेदकारी',
    'संभेदन',
    'संभेदी',
    'संभेद्य',
    'संभोक्ता',
    'संभोग',
    'संभोगी',
    'संभोग्य',
    'संभ्रम',
    'संभ्रमण',
    'संभ्रमि',
    'संभ्रमित',
    'संभ्रमी',
    'संभ्रांति',
    'संभ्राजिष्णुता',
    'संभ्रात',
    'संभ्रान्त',
    'संभ्रामक',
    'संमंत',
    'संमद',
    'संमांता',
    'संमिश्रण',
    'संम्परदायवाद',
    'संम्पादक',
    'संय',
    'संयंत्र',
    'संयंत्रण',
    'संयंत्रित',
    'संयंत्री',
    'संयंत्रीय',
    'संयचक',
    'संयचना',
    'संयत',
    'संयतता',
    'संयतहारी',
    'संयता',
    'संयतात्मा',
    'संयताहार',
    'संयतेंद्रिय',
    'संयत्त',
    'संयत्र',
    'संयम',
    'संयमन',
    'संयमनी',
    'संयमपूर्ण',
    'संयमशील',
    'संयमशीलता',
    'संयमहीनता',
    'संयमित',
    'संयमी',
    'संयात्रा',
    'संयान',
    'संयुक्त',
    'संयुक्तः',
    'संयुक्तक',
    'संयुक्तत',
    'संयुक्तता',
    'संयुक्तधारी',
    'संयुक्ता',
    'संयुक्तांक',
    'संयुक्ताक्षर',
    'संयुक्तीकरण',
    'संयुक्तीकृत',
    'संयुग्मन',
    'संयुग्मी',
    'संयुजन',
    'संयुजित',
    'संयुत',
    'संयुति',
    'संयोग',
    'संयोगजन्य',
    'संयोगपूर्ण',
    'संयोगवश',
    'संयोगवाद',
    'संयोगिक',
    'संयोगिता',
    'संयोगिनी',
    'संयोगी',
    'संयोजक',
    'संयोजन',
    'संयोजनकर्ता',
    'संयोजना',
    'संयोजनीय',
    'संयोजनों',
    'संयोजित',
    'संयोजी',
    'संयोज्य',
    'संयोलित',
    'संरंगीय',
    'संरक्षक',
    'संरक्षण',
    'संरक्षणहीन',
    'संरक्षणहीनता',
    'संरक्षा',
    'संरक्षिका',
    'संरक्षित',
    'संरक्षिता',
    'संरक्षी',
    'संरचना',
    'संरचनात्मक',
    'संरजित',
    'संरभ',
    'संराधन',
    'संराशक',
    'संराशन',
    'संराशिकरण',
    'संराशित',
    'संराशीकृत',
    'संरास',
    'संरुद्घ',
    'संरुष्टि',
    'संरूपण',
    'संरेखण',
    'संरोध',
    'संरोधन',
    'संरोधित',
    'संरोधी',
    'संरोपण',
    'संरोह',
    'संरोहण',
    'संर्दी',
    'संलक्षण',
    'संलक्षित',
    'संलक्ष्य',
    'संलगक',
    'संलग्गन',
    'संलग्न',
    'संलग्नक',
    'संलग्नतः',
    'संलग्नता',
    'संलग्नन',
    'संलग्नीय',
    'संलनकार',
    'संलय',
    'संलयन',
    'संलाप',
    'संलापी',
    'संलिप्त',
    'संलीन',
    'संलेख',
    'संलेप',
    'संलेपक',
    'संलेपन',
    'संलेश',
    'संवग्र्य',
    'संवत',
    'संवत्सर',
    'संवत्सरीय',
    'संवत्सरे',
    'संवर',
    'संवरक',
    'संवरण',
    'संवरित',
    'संवर्ग',
    'संवर्गक',
    'संवर्जन',
    'संवर्त',
    'संवर्तक',
    'संवर्तकी',
    'संवर्तमान',
    'संवर्तमानता',
    'संवर्तिका',
    'संवर्तिष्यमाण',
    'संवर्द्धन',
    'संवर्धक',
    'संवर्धन',
    'संवर्धित',
    'संवलन',
    'संवलय',
    'संवलित',
    'संवलिम',
    'संवविधान',
    'संवश',
    'संवह',
    'संवहन',
    'संवहनकारक',
    'संवहनी',
    'संवातक',
    'संवातन',
    'संवातित',
    'संवाद',
    'संवाददाता',
    'संवादन',
    'संवादरतता',
    'संवादात्मक',
    'संवादिक',
    'संवादी',
    'संवार',
    'संवारक',
    'संवारण',
    'संवारणीय',
    'संवारना',
    'संवारने',
    'संवारित',
    'संवार्य',
    'संवासित',
    'संवाह',
    'संवाहक',
    'संवाहित',
    'संवाहिता',
    'संवाहित्र',
    'संवाहिनी',
    'संवाही',
    'संवाह्य',
    'संवितरण',
    'संवितरित',
    'संवित्ति',
    'संविद',
    'संविदा',
    'संविदाएं',
    'संविदाकार',
    'संविदाकारों',
    'संविदागत',
    'संविदात्मक',
    'संविदित',
    'संविधा',
    'संविधाता',
    'संविधात्री',
    'संविधान',
    'संविधानक',
    'संविधानन',
    'संविधानवाद',
    'संविधानिक',
    'संविधानीय',
    'संविधायक',
    'संविधायन',
    'संविधायिका',
    'संविधि',
    'संविधेय',
    'संविभक्त',
    'संविभाग',
    'संविभागित',
    'संविभाजन',
    'संविभाजित',
    'संविभाव',
    'संविभ्रम',
    'संविवाद',
    'संविषाद',
    'संविष्ट',
    'संविहार',
    'संविहित',
    'संवीक्षक',
    'संवीक्षण',
    'संवीक्षा',
    'संवीत',
    'संवृत',
    'संवृद्घ',
    'संवृद्धि',
    'संवेक्षा',
    'संवेग',
    'संवेगहीन',
    'संवेगहीनता',
    'संवेगात्मक',
    'संवेगात्मकता',
    'संवेगी',
    'संवेद',
    'संवेदक',
    'संवेदन',
    'संवेदनवाद',
    'संवेदनशील',
    'संवेदनशीलता',
    'संवेदनहीन',
    'संवेदना',
    'संवेदनाएं',
    'संवेदनात्मक',
    'संवेदनात्मकता',
    'संवेदनापूर्ण',
    'संवेदनाहरण',
    'संवेदनाहारक',
    'संवेदनाहारी',
    'संवेदनाहीन',
    'संवेदयिता',
    'संवेदिक',
    'संवेदित',
    'संवेदिता',
    'संवेदी',
    'संवेदीकरण',
    'संवेद्य',
    'संवेश',
    'संवेशन',
    'संवेशित',
    'संवेष्टक',
    'संवैधानिक',
    'संवैधानिकता',
    'संव्यवहार',
    'संव्यवहृत',
    'संशंसी',
    'संशपथ',
    'संशय',
    'संशयग्रस्तता',
    'संशयपूर्ण',
    'संशयपूर्वक',
    'संशयवाद',
    'संशयवादी',
    'संशयशील',
    'संशयहीन',
    'संशयहीनता',
    'संशयात्मक',
    'संशयात्मा',
    'संशयालु',
    'संशयी',
    'संशासन',
    'संशितात्मा',
    'संशिष्ट',
    'संशीतन',
    'संशुद्धि',
    'संशोध',
    'संशोधक',
    'संशोधन',
    'संशोधनकारी',
    'संशोधनवादी',
    'संशोधनीय',
    'संशोधनों',
    'संशोधित',
    'संशोधी',
    'संशोषण',
    'संशोसित',
    'संश्यान',
    'संश्यानित्र',
    'संश्रय',
    'संश्रित',
    'संश्रुत',
    'संश्लिष्ट',
    'संश्लेष',
    'संश्लेषण',
    'संश्लेषी',
    'संसक्त',
    'संसक्ति',
    'संसद',
    'संसदीय',
    'संसरण',
    'संसर्ग',
    'संसर्गण',
    'संसर्गी',
    'संसर्प',
    'संसर्पक',
    'संसर्पण',
    'संसर्पी',
    'संससूचना',
    'संसाधन',
    'संसाधनों',
    'संसाधित',
    'संसाध्य',
    'संसार',
    'संसारःआदि',
    'संसारवाद',
    'संसारासिक्त',
    'संसारिक',
    'संसारी',
    'संसिक्त',
    'संसिक्तता',
    'संसिक्तन',
    'संसिद्घ',
    'संसिद्धि',
    'संसुचालन',
    'संसुचालित',
    'संसुचालिततः',
    'संसुप्ति',
    'संसुवाद',
    'संसूचक',
    'संसूचन',
    'संसूचना',
    'संसृति',
    'संसृष्ट',
    'संसृष्टि',
    'संसेक',
    'संसेचन',
    'संसेचित',
    'संसेवा',
    'संस्करण',
    'संस्करणों',
    'संस्कर्ता',
    'संस्कार',
    'संस्कारक',
    'संस्कारपूर्वक',
    'संस्कारयुक्तता',
    'संस्कारवादी',
    'संस्कारहीन',
    'संस्कारहीनता',
    'संस्कारित',
    'संस्कारी',
    'संस्कारीय',
    'संस्कृत',
    'संस्कृति',
    'संस्कृतिहीनता',
    'संस्कृतीय',
    'संस्क्रिया',
    'संस्तंभ',
    'संस्तंभन',
    'संस्तर',
    'संस्तरण',
    'संस्तरित',
    'संस्तवन',
    'संस्तवान',
    'संस्तारण',
    'संस्ताव',
    'संस्तुत',
    'संस्तुति',
    'संस्तुल्य',
    'संस्त्यान',
    'संस्थ',
    'संस्था',
    'संस्थाः',
    'संस्थाएं',
    'संस्थागत',
    'संस्थागार',
    'संस्थाध्यक्ष',
    'संस्थान',
    'संस्थानः',
    'संस्थानीय',
    'संस्थानों',
    'संस्थापक',
    'संस्थापन',
    'संस्थापना',
    'संस्थापनाओं',
    'संस्थापयिता',
    'संस्थापवादी',
    'संस्थापित',
    'संस्थाप्य',
    'संस्थित',
    'संस्थितता',
    'संस्थिति',
    'संस्पर्धी',
    'संस्पर्श',
    'संस्पर्शी',
    'संस्पुष्ट',
    'संस्फीति',
    'संस्फुरण',
    'संस्मरण',
    'संस्मरणीय',
    'संस्मारक',
    'संस्मारण',
    'संस्मारिका',
    'संस्मृत',
    'संस्मृति',
    'संस्यूत',
    'संस्रवित',
    'संस्रष्टा',
    'संस्राव',
    'संस्रावण',
    'संस्वीकरण',
    'संस्वीकर्ता',
    'संस्वीकृति',
    'संस्वेदन',
    'संहत',
    'संहतल',
    'संहति',
    'संहतीय',
    'संहरण',
    'संहर्ता',
    'संहर्श',
    'संहर्ष',
    'संहार',
    'संहारक',
    'संहारकता',
    'संहारकारी',
    'संहारना',
    'संहारिणी',
    'संहारी',
    'संहार्य',
    'संहिकेय',
    'संहित',
    'संहिता',
    'संहिताकरण',
    'संहिताकार',
    'संहिताबद्ध',
    'संहिति',
    'संहृत',
    'संहृति',
    'संह्राद',
    'संह्लादक',
    'सअँभालना',
    'सइयो',
    'सई',
    'सईद',
    'सईस',
    'सउजा',
    'सकंट',
    'सकंधिका',
    'सकंल्प',
    'सकट',
    'सकडी',
    'सकत',
    'सकता',
    'सकती',
    'सकते',
    'सकनत',
    'सकना',
    'सकने',
    'सकनेवाला',
    'सकपकाई',
    'सकपकाना',
    'सकपकाया',
    'सकपकाहट',
    'सकर',
    'सकरण',
    'सकरणक',
    'सकरते',
    'सकरा',
    'सकरी',
    'सकरुणा',
    'सकर्तृक',
    'सकर्मक',
    'सकल',
    'सकलता',
    'सकलभेद',
    'सकलवाद',
    'सकलेश्वर',
    'सकवच',
    'सकषाय',
    'सका',
    'सकाम',
    'सकामा',
    'सकाय',
    'सकार',
    'सकारण',
    'सकारथ',
    'सकारथता',
    'सकारना',
    'सकारने',
    'सकारनेवाला',
    'सकारा',
    'सकारात्मक',
    'सकारात्मकता',
    'सकारी',
    'सकारे',
    'सकाल',
    'सकालता',
    'सकिलना',
    'सकी',
    'सकीं',
    'सकुचाई',
    'सकुचाना',
    'सकुचाहट',
    'सकुचीला',
    'सकुचीली',
    'सकुचौहीं',
    'सकुचौहौं',
    'सकुल',
    'सकुल्य',
    'सकुल्यता',
    'सकुशल',
    'सकूंगा',
    'सकृत',
    'सकृपा',
    'सके',
    'सकें',
    'सकेंगे',
    'सकेंद्र',
    'सकेगा',
    'सकेगी',
    'सकेतना',
    'सकेन्द्रित',
    'सकेरना',
    'सकेरी',
    'सकेरु',
    'सकेलना',
    'सकेली',
    'सको',
    'सकोडना',
    'सकोण',
    'सकोतरा',
    'सकोपना',
    'सकोरा',
    'सकौमार्य',
    'सक्का',
    'सक्काक',
    'सक्त',
    'सक्ति',
    'सक्तु',
    'सक्थि',
    'सक्यूबस',
    'सक्रंमित',
    'सक्रता',
    'सक्रते',
    'सक्रम',
    'सक्रमता',
    'सक्रांति',
    'सक्रिम',
    'सक्रिय',
    'सक्रियक',
    'सक्रियकरण',
    'सक्रियकारक',
    'सक्रियण',
    'सक्रियता',
    'सक्रियतावाद',
    'सक्रियित',
    'सक्रील',
    'सक्षम',
    'सक्षमतः',
    'सक्षमता',
    'सक्षमा',
    'सक्सेना',
    'सख',
    'सखंड',
    'सख़्त',
    'सख़्ती',
    'सखा',
    'सखावत',
    'सखाहीन',
    'सखि',
    'सखिन',
    'सखिल',
    'सखी',
    'सखुआ',
    'सखुरकुल',
    'सखेटक',
    'सख्त',
    'सख्तगीर',
    'सख्तजबान',
    'सख्तजान',
    'सख्तमिजाज',
    'सख्तसज़ा',
    'सख्ती',
    'सख्य',
    'सख्यता',
    'सगंत',
    'सगंध',
    'सगड़ी',
    'सगण',
    'सगति',
    'सगनौती',
    'सगबगाना',
    'सगभत्ता',
    'सगर',
    'सगरा',
    'सगरिया',
    'सगरी',
    'सगरे',
    'सगर्भ',
    'सगर्भा',
    'सगर्व',
    'सगल',
    'सगलगी',
    'सगा',
    'सगाई',
    'सगापन',
    'सगी',
    'सगुण',
    'सगुणता',
    'सगुणपूजा',
    'सगुणी',
    'सगुणोपासना',
    'सगुन',
    'सगुनाना',
    'सगुनिया',
    'सगुनौती',
    'सगृह',
    'सगे',
    'सगोत',
    'सगोती',
    'सगोत्र',
    'सगोत्रता',
    'सगोत्रीय',
    'सग्गड',
    'सग्रीवाग्रज',
    'सघन',
    'सघनता',
    'सघनत्व',
    'सघनन',
    'सघनीकरण',
    'सघीय',
    'सच',
    'सचकी',
    'सचमुच',
    'सचरस्थान',
    'सचल',
    'सचलकरण',
    'सचलकार',
    'सचलता',
    'सचलीकरण्',
    'सचि',
    'सचिंतन',
    'सचित',
    'सचित्र',
    'सचित्रता',
    'सचिव',
    'सचिवालय',
    'सचेत',
    'सचेतक',
    'सचेतता',
    'सचेतन',
    'सचेतनः',
    'सचेतनता',
    'सचेतनीकरण्',
    'सचेतित',
    'सचेष्ट',
    'सचैतन्य',
    'सच्चदानंद',
    'सच्चरित्र',
    'सच्चरित्रता',
    'सच्चरित्रा',
    'सच्चर्या',
    'सच्चा',
    'सच्चाई',
    'सच्चापन',
    'सच्ची',
    'सच्चे',
    'सच्छिद्र',
    'सछल',
    'सज',
    'सजग',
    'सजगता',
    'सजडा',
    'सजधज',
    'सजन',
    'सजना',
    'सजनिया',
    'सजनी',
    'सजबज',
    'सजल',
    'सजवटी',
    'सजा',
    'सजाई',
    'सजाए',
    'सजाकर',
    'सजाति',
    'सजातीय',
    'सजातीयता',
    'सजात्य',
    'सजाना',
    'सजाने',
    'सजायाफ्ता',
    'सजाव',
    'सजावट',
    'सजावटी',
    'सजावटीपन',
    'सजावन',
    'सजावल',
    'सजिना',
    'सजिर्',
    'सजिर्कल',
    'सजिर्का',
    'सजिर्त',
    'सजिर्द',
    'सजिल्द',
    'सजी',
    'सजीला',
    'सजीलापन',
    'सजीली',
    'सजीव',
    'सजीवकरण',
    'सजीवकार',
    'सजीवता',
    'सजीवन',
    'सजोर्न',
    'सज्जक',
    'सज्जन',
    'सज्जनतः',
    'सज्जनता',
    'सज्जनतापूर्वक',
    'सज्जनों',
    'सज्जा',
    'सज्जाकार',
    'सज्जाद',
    'सज्जादा',
    'सज्जापट्टी',
    'सज्जाहीन',
    'सज्जाहीनता',
    'सज्जित',
    'सज्जी',
    'सज्जीकरण',
    'सज्जे',
    'सज्ञान',
    'सज्दागुजार',
    'सज्वाला',
    'सटक',
    'सटकन',
    'सटकना',
    'सटकर',
    'सटका',
    'सटकार',
    'सटकारना',
    'सटकू',
    'सटक्का',
    'सटन',
    'सटना',
    'सटपटाना',
    'सटपटाहट',
    'सटसट',
    'सटसटाहट',
    'सटा',
    'सटाकर',
    'सटाना',
    'सटापन',
    'सटाल',
    'सटासट',
    'सटिया',
    'सटीक',
    'सटे',
    'सटोरिया',
    'सट्टा',
    'सट्टी',
    'सट्टे',
    'सट्टेबाज',
    'सट्टेबाजी',
    'सट्टेबाज़',
    'सठियाना',
    'सठियापा',
    'सठियायापन',
    'सठेरा',
    'सडक',
    'सड़',
    'सड़क',
    'सड़कः',
    'सड़कछाप',
    'सड़कर',
    'सड़कें',
    'सड़क्का',
    'सड़न',
    'सड़ना',
    'सड़सा',
    'सड़सी',
    'सड़ा',
    'सड़ाक',
    'सड़ाना',
    'सड़ापन',
    'सड़ायँध',
    'सड़ायैध',
    'सड़ाव',
    'सड़ियल',
    'सड़ी',
    'सत',
    'सतंीर्ण',
    'सतखंड',
    'सतखंडा',
    'सतगुना',
    'सतगुरु',
    'सतजुग',
    'सतजुगी',
    'सतत',
    'सततग',
    'सततता',
    'सतन',
    'सतपाल',
    'सतपुड़ा',
    'सतफेरा',
    'सतभौंरी',
    'सतमहला',
    'सतमासा',
    'सतयुग',
    'सतर',
    'सतरंगा',
    'सतरंगापन',
    'सतरंज',
    'सतरत्पति',
    'सतरह',
    'सतर्क',
    'सतर्कता',
    'सतलखा',
    'सतलज',
    'सतलड़ी',
    'सतवंती',
    'सतसई',
    'सतह',
    'सतही',
    'सतहीपन',
    'सतागुणी',
    'सतानद',
    'सताना',
    'सताप',
    'सताया',
    'सतारतम्य',
    'सतिया',
    'सती',
    'सतीः',
    'सतीआ',
    'सतीत्व',
    'सतीनाथ',
    'सतीपति',
    'सतीव्रता',
    'सतीश',
    'सतुन',
    'सतून',
    'सतृष्ण',
    'सतेज',
    'सतोगुण',
    'सतौसर',
    'सत्',
    'सत्कर्ता',
    'सत्कर्म',
    'सत्कर्मी',
    'सत्कला',
    'सत्कवि',
    'सत्कार',
    'सत्कारण',
    'सत्कारपूर्वक',
    'सत्कारशील',
    'सत्कारहीनतः',
    'सत्कारहीनता',
    'सत्कारिणी',
    'सत्कारी',
    'सत्कार्य',
    'सत्कीर्ति',
    'सत्कृत',
    'सत्क्रिया',
    'सत्त',
    'सत्तम',
    'सत्तर',
    'सत्तरी',
    'सत्ता',
    'सत्ताच्युत',
    'सत्तातंरण',
    'सत्तात्मक',
    'सत्ताधारी',
    'सत्ताधिकार',
    'सत्ताधिकारी',
    'सत्ताधीश',
    'सत्तानिष्ठ',
    'सत्तानिष्ठता',
    'सत्तापहरण',
    'सत्तामूलक',
    'सत्तार',
    'सत्तारुढ़',
    'सत्तारूढ़',
    'सत्तारोहण',
    'सत्तावाद',
    'सत्ताशील',
    'सत्तासीन',
    'सत्ताहारी',
    'सत्ताहीन',
    'सत्ती',
    'सत्तू',
    'सत्परयोग',
    'सत्पात्र',
    'सत्पुत्र',
    'सत्प्रयत्न',
    'सत्प्रयास',
    'सत्य',
    'सत्यंकार',
    'सत्यअहिंसा',
    'सत्यकाम',
    'सत्यकीर्ति',
    'सत्यगंधा',
    'सत्यघ्न',
    'सत्यजित',
    'सत्यतः',
    'सत्यता',
    'सत्यतापूर्ण',
    'सत्यदर्शी',
    'सत्यदेव',
    'सत्यधर्मा',
    'सत्यनिष्ठ',
    'सत्यनिष्ठा',
    'सत्यनिष्ठापूर्वक',
    'सत्यनुरागी',
    'सत्यपर',
    'सत्यपरकता',
    'सत्यपराक्रम',
    'सत्यपरायण',
    'सत्यपीर',
    'सत्यप्रतिज्ञ',
    'सत्यप्रिय',
    'सत्यभामा',
    'सत्यभाषिता',
    'सत्यभाषी',
    'सत्यभास',
    'सत्यभासिता',
    'सत्यभेदी',
    'सत्यम',
    'सत्यमेघा',
    'सत्ययुग',
    'सत्ययुगीन',
    'सत्यरत',
    'सत्यवक्ता',
    'सत्यवती',
    'सत्यवादिता',
    'सत्यवादी',
    'सत्यवान',
    'सत्यव्रत',
    'सत्यशपथ',
    'सत्यशील',
    'सत्यशीलता',
    'सत्यसंध',
    'सत्यसिद्घ',
    'सत्यस्थ',
    'सत्या',
    'सत्याग्रह',
    'सत्याग्रही',
    'सत्यात्मक',
    'सत्यात्मा',
    'सत्याधारित',
    'सत्यानाश',
    'सत्यानाशी',
    'सत्यानासी',
    'सत्यापक',
    'सत्यापन',
    'सत्यापित',
    'सत्यापित्र',
    'सत्याभाषी',
    'सत्याभास',
    'सत्याशौच',
    'सत्युगी',
    'सत्येतर',
    'सत्योद्घाटन',
    'सत्र',
    'सत्रकंठ',
    'सत्रति',
    'सत्रद्घ',
    'सत्रन्यायालय',
    'सत्रप',
    'सत्रह',
    'सत्रहवां',
    'सत्रागार',
    'सत्रावसान',
    'सत्रिगुण',
    'सत्री',
    'सत्रुटि',
    'सत्व',
    'सत्वगुण',
    'सत्वगुणी',
    'सत्वपथ',
    'सत्वपूर्ण',
    'सत्ववान',
    'सत्वशाली',
    'सत्वस्थ',
    'सत्वहीन',
    'सत्वात्मा',
    'सत्वादेशी',
    'सत्वारोपण',
    'सत्संकल्प',
    'सत्संग',
    'सत्संगति',
    'सत्समागम',
    'सथरी',
    'सथिया',
    'सद',
    'सदक़ा',
    'सदक़े',
    'सदन',
    'सदभावी',
    'सदमा',
    'सदय',
    'सदर',
    'सदरी',
    'सदरे',
    'सदर्भ',
    'सदल',
    'सदलबल',
    'सदसद्',
    'सदसयता',
    'सदस्य',
    'सदस्यता',
    'सदस्यीकरण',
    'सदस्येतर',
    'सदस्यों',
    'सदा',
    'सदाक़त',
    'सदागति',
    'सदागम',
    'सदागमन',
    'सदाचरण',
    'सदाचार',
    'सदाचारिणी',
    'सदाचारिता',
    'सदाचारी',
    'सदाजयी',
    'सदातोया',
    'सदात्मा',
    'सदादान',
    'सदानंद',
    'सदानर्त',
    'सदानीरा',
    'सदापर्ण',
    'सदापुष्प',
    'सदाप्रसून',
    'सदाफल',
    'सदाबहार',
    'सदामद',
    'सदायतापूर्वक',
    'सदायोगी',
    'सदार',
    'सदारत',
    'सदारूह',
    'सदावर्त',
    'सदावर्ती',
    'सदाशय',
    'सदाशयता',
    'सदाशिव',
    'सदिच्छा',
    'सदिच्छुक',
    'सदियों',
    'सदिशा',
    'सदि्नर्वाह',
    'सदी',
    'सदुक्ति',
    'सदुपदेश',
    'सदुपयोग',
    'सदृढ़ता',
    'सदृश',
    'सदृशकारिता',
    'सदृशतः',
    'सदृशता',
    'सदृशरूपता',
    'सदृश्य',
    'सदृश्यता',
    'सदेह',
    'सदैव',
    'सदैवता',
    'सदोष',
    'सद्',
    'सद्गति',
    'सद्गुण',
    'सद्गुणयुक्तता',
    'सद्गुणहीन',
    'सद्गुणी',
    'सद्गुणीय',
    'सद्गुरू',
    'सद्गुहस्थ',
    'सद्गृहस्थिन',
    'सद्घर्भ',
    'सद्ता',
    'सद्दा',
    'सद्दी',
    'सद्धी',
    'सद्परयोग',
    'सद्परामर्श',
    'सद्भाग्य',
    'सद्भाव',
    'सद्भावना',
    'सद्भावपूर्ण',
    'सद्भावपूर्वक',
    'सद्भावी',
    'सद्म',
    'सद्मा',
    'सद्य',
    'सद्यजातक',
    'सद्यता',
    'सद्यस्क',
    'सद्यस्तन',
    'सद्योजात',
    'सद्रूप',
    'सद्रूपता',
    'सद्रे',
    'सद्विचार',
    'सद्विचारी',
    'सद्वेष',
    'सद्व्यवहार',
    'सद्व्यवहारी',
    'सद्सद्',
    'सधना',
    'सधन्यवाद',
    'सधर्म',
    'सधर्मा',
    'सधर्मी',
    'सधवता',
    'सधवा',
    'सधा',
    'सधाई',
    'सधाना',
    'सधापन',
    'सधार',
    'सधाव',
    'सधावर',
    'सधुर',
    'सधुरता',
    'सधुर्यता',
    'सधे',
    'सधैर्य',
    'सधौर',
    'सध्यान',
    'सध्यानतः',
    'सध्येय',
    'सन',
    'सनंदन',
    'सनअत',
    'सनअतगर',
    'सनअती',
    'सनई',
    'सनक',
    'सनकना',
    'सनकी',
    'सनकीपन',
    'सनडे',
    'सनत्कुमार',
    'सनद',
    'सनदी',
    'सनना',
    'सननी',
    'सनम',
    'सनमकदा',
    'सनमाइका',
    'सनशड',
    'सनशेड',
    'सनसन',
    'सनसनाना',
    'सनसनाहट',
    'सनसनी',
    'सनसनीखेज',
    'सनसनीखेज़',
    'सनसनीदार',
    'सनसनीपरकता',
    'सना',
    'सनांग',
    'सनाई',
    'सनातन',
    'सनातनः',
    'सनातनता',
    'सनातनवादी',
    'सनातनी',
    'सनाथता',
    'सनाद',
    'सनाभ',
    'सनाभि',
    'सनाम',
    'सनायक',
    'सनिक',
    'सनिमित',
    'सनियम',
    'सनियमता',
    'सनिश्चय',
    'सनिषेध',
    'सनी',
    'सनीचर',
    'सनीचरी',
    'सनीड़',
    'सनील',
    'सनेहशील',
    'सनेहा',
    'सनेहिया',
    'सनेही',
    'सनोवर',
    'सन्त',
    'सन्तता',
    'सन्तप्त',
    'सन्तरा',
    'सन्तरी',
    'सन्तसुलभ',
    'सन्तान',
    'सन्तुलित',
    'सन्तुष्ट',
    'सन्तोष',
    'सन्तोषक',
    'सन्तोषजनक',
    'सन्तोषजनकता',
    'सन्दर्भ',
    'सन्दर्भगर्भित',
    'सन्दिग्धार्थ',
    'सन्दूक',
    'सन्देश',
    'सन्देह',
    'सन्देहवाद',
    'सन्देहवादिता',
    'सन्देहवादी',
    'सन्धि',
    'सन्धिकरण',
    'सन्धिमय',
    'सन्धिवातीय',
    'सन्धिवार्ता',
    'सन्नद्घ',
    'सन्नद्घतः',
    'सन्नद्घता',
    'सन्नद्घन',
    'सन्नय',
    'सन्नहन',
    'सन्नाटा',
    'सन्नाटेदार',
    'सन्नाद',
    'सन्नादिता',
    'सन्नादी',
    'सन्नारी',
    'सन्नाह',
    'सन्निकट',
    'सन्नितहित',
    'सन्निधि',
    'सन्निपात',
    'सन्निविष्ट',
    'सन्निहित',
    'सन्नोदन',
    'सन्न्यस्त',
    'सन्मति',
    'सन्मान',
    'सन्मार्ग',
    'सन्मुख',
    'सन्यस्त',
    'सन्याय',
    'सन्यास',
    'सन्यासिनियों',
    'सन्यासिनी',
    'सन्यासी',
    'सपंख',
    'सपंर्क',
    'सपंर्कन',
    'सपंवन',
    'सपक्ष',
    'सपक्षपात',
    'सपक्षी',
    'सपटा',
    'सपट्टा',
    'सपट्टी',
    'सपतक',
    'सपताक्षर',
    'सपति',
    'सपतिक',
    'सपतित्व',
    'सपत्न',
    'सपत्नी',
    'सपत्नीक',
    'सपत्नीत्व',
    'सपत्नीय',
    'सपत्र',
    'सपन',
    'सपना',
    'सपनीला',
    'सपने',
    'सपरदा',
    'सपरदाई',
    'सपरना',
    'सपराना',
    'सपरिकर',
    'सपरिक्रम',
    'सपरिच्छद',
    'सपरिजन',
    'सपरिणाम',
    'सपरिवार',
    'सपरिश्रम',
    'सपल्लव',
    'सपसपाहट',
    'सपांचन',
    'सपांत',
    'सपाट',
    'सपाटता',
    'सपाटा',
    'सपाटू',
    'सपाटे',
    'सपाद',
    'सपाप',
    'सपारि',
    'सपालि',
    'सपिंड',
    'सपिंडता',
    'सपिण्ड',
    'सपुण्य',
    'सपुत्र',
    'सपुर्द',
    'सपुलक',
    'सपुष्प',
    'सपूत',
    'सपेंद्र',
    'सपेंश्वर',
    'सपेष्ट',
    'सपोट',
    'सपोटना',
    'सप्त',
    'सप्तंखंडीय',
    'सप्तक',
    'सप्तकोण',
    'सप्तकोणीय',
    'सप्तखंड',
    'सप्तगुण',
    'सप्तग्राम',
    'सप्तजिह्व',
    'सप्तति',
    'सप्तदश',
    'सप्तद्वीप',
    'सप्तद्वीपा',
    'सप्तपदी',
    'सप्तपाटीय',
    'सप्तब्द',
    'सप्तभगीय',
    'सप्तभागी',
    'सप्तभुज',
    'सप्तभुजा',
    'सप्तभू',
    'सप्तभ्रद',
    'सप्तम',
    'सप्तमी',
    'सप्तरूप',
    'सप्तर्षि',
    'सप्तर्षिज',
    'सप्तला',
    'सप्तलोक',
    'सप्तलोकी',
    'सप्तवर्षीय',
    'सप्तवाद',
    'सप्तवार्षिक',
    'सप्तवाहन',
    'सप्तविध',
    'सप्तशत',
    'सप्तशती',
    'सप्तशाखी',
    'सप्तसंख्यक',
    'सप्तस्वर',
    'सप्तांशु',
    'सप्ताक्षरी',
    'सप्ताचिर्',
    'सप्तात्मा',
    'सप्तार्णव',
    'सप्ताश्व',
    'सप्तास्र',
    'सप्ताह',
    'सप्रक',
    'सप्रतिफल',
    'सप्रतिबन्ध',
    'सप्रतियोगिता',
    'सप्रतीक्षा',
    'सप्रतीत',
    'सप्रभ',
    'सप्रमाण',
    'सप्रयत्न',
    'सप्रयास',
    'सप्रयोजन',
    'सप्रयोजनता',
    'सप्रवाह',
    'सप्रवाहता',
    'सप्राण',
    'सप्राणता',
    'सप्राणन',
    'सप्रेम',
    'सप्लाई',
    'सप्लायर',
    'सफ',
    'सफआरा',
    'सफतालू',
    'सफदर',
    'सफबंदी',
    'सफर',
    'सफरचंद',
    'सफरजल',
    'सफरनामा',
    'सफरारी',
    'सफरी',
    'सफल',
    'सफलक',
    'सफलकाम',
    'सफलता',
    'सफलताएं',
    'सफलताकांक्षी',
    'सफलतापूर्वक',
    'सफलताप्रद',
    'सफलमनोरथ',
    'सफलव्रत',
    'सफलित',
    'सफ़र',
    'सफ़ेद',
    'सफा',
    'सफाई',
    'सफाईपक्ष',
    'सफाईवाला',
    'सफाचट',
    'सफाया',
    'सफारी',
    'सफिं',
    'सफीना',
    'सफीर',
    'सफूफ',
    'सफेद',
    'सफेदपोश',
    'सफेदा',
    'सफेदी',
    'सफेदो',
    'सफ्रि',
    'सफ्रिंग',
    'सब',
    'सबंध',
    'सबंधी',
    'सबऐटकि',
    'सबक',
    'सबकंटिनैंट',
    'सबकमिटी',
    'सबका',
    'सबकी',
    'सबके',
    'सबको',
    'सबकौंशस',
    'सबडिवीज़न',
    'सबद',
    'सबदरा',
    'सबद्घन',
    'सबब',
    'सबबग',
    'सबमजैस',
    'सबमैरीन',
    'सबरा',
    'सबरी',
    'सबर्ब',
    'सबल',
    'सबलतः',
    'सबलता',
    'सबला',
    'सबलि',
    'सबवे',
    'सबसे',
    'सबसैक्शन',
    'सबस्टैंडर्ड',
    'सबहैडिंग',
    'सबा',
    'सबाध',
    'सबाधित',
    'सबिंग',
    'सबीज',
    'सबील',
    'सबुद्घ',
    'सबूत',
    'सबूर',
    'सबूही',
    'सबेर',
    'सबेरा',
    'सबेरे',
    'सबोध',
    'सब्ज',
    'सब्जा',
    'सब्जाज़ार',
    'सब्जियां',
    'सब्जियों',
    'सब्जी',
    'सब्जीफरोश',
    'सब्जीयत',
    'सब्जैक्ट',
    'सब्त',
    'सब्बल',
    'सब्याज',
    'सब्ज़ी',
    'सभस्मा',
    'सभा',
    'सभाकक्ष',
    'सभागार',
    'सभागृह',
    'सभाचार',
    'सभाध्यक्ष',
    'सभापति',
    'सभापतित्व',
    'सभार',
    'सभारंभ',
    'सभार्य',
    'सभाषण',
    'सभासद',
    'सभासदता',
    'सभासदन',
    'सभिक',
    'सभी',
    'सभेद',
    'सभेय',
    'सभोचित',
    'सभ्य',
    'सभ्यता',
    'सभ्यताहीनतः',
    'सभ्याहीन',
    'सभ्यीकरण',
    'सम',
    'समंगा',
    'समंगी',
    'समंजक',
    'समंजन',
    'समंजनकारी',
    'समंजस',
    'समंजित',
    'समंत',
    'समंतक',
    'समंतरवर्ती',
    'समंदर',
    'समंस',
    'समकक्ष',
    'समकक्षता',
    'समकरण',
    'समकर्मा',
    'समकारक',
    'समकारी',
    'समकाल',
    'समकालिकता',
    'समकालीन',
    'समकालीनता',
    'समकोटीय',
    'समकोण',
    'समकोणक',
    'समकोणीय',
    'समकोणीयता',
    'समक्ष',
    'समक्षणिक',
    'समक्षणिकता',
    'समक्षतः',
    'समक्षता',
    'समक्षेत्र',
    'समखंडी',
    'समगति',
    'समगुण',
    'समगुणता',
    'समग्र',
    'समग्रण',
    'समग्रता',
    'समग्ररतः',
    'समग्रह',
    'समग्राकृति',
    'समग्रीकृत',
    'समग्रीभूत',
    'समघ्र्य',
    'समचक्रांतरता',
    'समचतुरस्र',
    'समचतुर्भुज',
    'समचर',
    'समचरण',
    'समचिकित्सक',
    'समचित',
    'समचित्त',
    'समचेता',
    'समजात',
    'समजाति',
    'समजातीय',
    'समजातीयता',
    'समज्या',
    'समझ',
    'समझकर',
    'समझता',
    'समझते',
    'समझदार',
    'समझदारी',
    'समझना',
    'समझने',
    'समझा',
    'समझाते',
    'समझाना',
    'समझाने',
    'समझावा',
    'समझी',
    'समझे',
    'समझें',
    'समझौता',
    'समझौतापत्र',
    'समझौतावादी',
    'समझौते',
    'समझौवल',
    'समण्',
    'समतभेद',
    'समतल',
    'समतलता',
    'समतलन',
    'समता',
    'समताप',
    'समतापक',
    'समतापी',
    'समतावाद',
    'समतावादी',
    'समतुल्य',
    'समतैक्य',
    'समतोल',
    'समतोलन',
    'समत्रिपदी',
    'समत्सर',
    'समथ',
    'समदना',
    'समदर्शन',
    'समदर्शी',
    'समदाब',
    'समदिक्पाती',
    'समदिश',
    'समदिश्ता',
    'समदिष्ट',
    'समदुःख',
    'समदूरस्थ',
    'समद्विबाहु',
    'समधंधी',
    'समधन',
    'समधातु',
    'समधानित',
    'समधिक',
    'समधिगत',
    'समधिगम',
    'समधिगमन',
    'समधिन',
    'समधियाना',
    'समधी',
    'समधौरा',
    'समध्वनि',
    'समन',
    'समनाम',
    'समनामी',
    'समनुक्रम',
    'समनुज्ञा',
    'समनुज्ञात',
    'समनुदेश',
    'समनुदेशक',
    'समनुदेशित',
    'समनुदेशिती',
    'समन्यूट्रोन',
    'समन्वय',
    'समन्वयक',
    'समन्वयकों',
    'समन्वयन',
    'समन्वयी',
    'समन्वित',
    'समन्वेषक',
    'समन्वेषी',
    'समपद',
    'समपयिता',
    'समपर्क',
    'समपहरण',
    'समपाद',
    'समपाश्र्व',
    'समपाश्र्वता',
    'समपुष्पता',
    'समपृष्ठ',
    'समपृष्ठता',
    'समप्राप्ति',
    'समप्र्य',
    'समबहु',
    'समबाहु',
    'समबाहुता',
    'समबुद्धि',
    'समभर',
    'समभरता',
    'समभा',
    'समभागी',
    'समभार',
    'समभारता',
    'समभारिक',
    'समभारित',
    'समभाषांश',
    'समभिव्यवहार',
    'समभिसरण',
    'समभुज',
    'समभुजता',
    'समभूमि',
    'समभ्याहार',
    'सममति',
    'सममात्र',
    'सममान',
    'सममित',
    'सममिति',
    'सममूल्य',
    'सममूल्यता',
    'सममूल्येतर',
    'समय',
    'समयचक्र',
    'समयनिष्ठ',
    'समयनुकूलता',
    'समयनुसार',
    'समयपूर्व',
    'समयबद्ध',
    'समयहीनता',
    'समयांतर',
    'समयाचार',
    'समयातित',
    'समयानुकुल',
    'समयानुकूल',
    'समयानुकूलता',
    'समयानुसार',
    'समयानुसारतः',
    'समयावधि',
    'समयावसान',
    'समयुग्मन',
    'समयोचित',
    'समयोचितता',
    'समयोजन',
    'समयोत्तर',
    'समयोपयोगी',
    'समयोपरांत',
    'समयोपरांततः',
    'समयोपरांतता',
    'समयोपरि',
    'समर',
    'समरंगन',
    'समरक्त',
    'समरक्तता',
    'समरथ्',
    'समरस',
    'समरसता',
    'समरांगण',
    'समरांरभ',
    'समरागम',
    'समराजकता',
    'समरुप',
    'समरुपतः',
    'समरुपता',
    'समरुपी',
    'समरूप',
    'समरूपता',
    'समरोह',
    'समर्घ',
    'समर्चना',
    'समर्थ',
    'समर्थक',
    'समर्थता',
    'समर्थन',
    'समर्थनमाँगना',
    'समर्थनीय',
    'समर्पक',
    'समर्पण',
    'समर्पणीय',
    'समर्पित',
    'समर्पितता',
    'समर्याद',
    'समर्ह',
    'समर्हत',
    'समर्हता',
    'समर्हित',
    'समलंकरण',
    'समलंकार',
    'समलंकृत',
    'समलक्षण',
    'समलक्षणता',
    'समलाकंगिक',
    'समलिंग',
    'समलिंगरति',
    'समलिंगी',
    'समलिण्ग्गकामी',
    'समलेख',
    'समलोष्टकांचन',
    'समवंशी',
    'समवतरण',
    'समवतार',
    'समवधान',
    'समवधानी',
    'समवयस्क',
    'समवर्ग',
    'समवर्गी',
    'समवर्गीय',
    'समवर्ण',
    'समवर्तिता',
    'समवर्ती',
    'समवर्तुलाकार',
    'समवसिथत',
    'समवस्थ',
    'समवहार',
    'समवाय',
    'समवायन',
    'समविन्यास',
    'समविन्यासी',
    'समविभाजन',
    'समवृत्त',
    'समवेक्षण',
    'समवेक्षित',
    'समवेत',
    'समवेदना',
    'समवेश',
    'समव्यवसायी',
    'समव्यवहार',
    'समव्यवहारी',
    'समशब्द',
    'समशिख',
    'समशील',
    'समष्टि',
    'समष्टिगत',
    'समष्टीय',
    'समष्रि',
    'समसंख्यक',
    'समसंख्या',
    'समसतरीय',
    'समसतरीयता',
    'समसन',
    'समसमय',
    'समसयापूर्ण',
    'समसामयिक',
    'समसामयिकता',
    'समसिथत',
    'समस्त',
    'समस्तता',
    'समस्तर',
    'समस्तरण',
    'समस्तरता',
    'समस्तरी',
    'समस्थल',
    'समस्थानिक',
    'समस्थिति',
    'समस्यस्कता',
    'समस्या',
    'समस्याएं',
    'समस्याओं',
    'समस्यागत',
    'समस्यात्मक',
    'समस्यामूलक',
    'समस्वभावी',
    'समस्वर',
    'समस्वरता',
    'समह',
    'समह्र्य',
    'समा',
    'समाँ',
    'समांअत',
    'समांक',
    'समांगीकृत',
    'समांजस्य',
    'समांजस्यपूर्ण',
    'समांतर',
    'समांतरता',
    'समांश',
    'समांशभागी',
    'समांशी',
    'समांस',
    'समांसता',
    'समाअ',
    'समाई',
    'समाकर्षक',
    'समाकर्षण',
    'समाकल',
    'समाकलन',
    'समाकलित',
    'समाकल्य',
    'समाकार',
    'समाकारता',
    'समाकृति',
    'समाक्रमण',
    'समाक्षर',
    'समाक्षरिक',
    'समाख्यात',
    'समागत',
    'समागति',
    'समागम',
    'समागमन',
    'समागमस्थल',
    'समाघात',
    'समाचयन',
    'समाचरण',
    'समाचरित',
    'समाचार',
    'समाचारपत्र',
    'समाचीनता',
    'समाच्चरित',
    'समाज',
    'समाजत',
    'समाजप्रिय',
    'समाजप्रियता',
    'समाजप्रेमी',
    'समाजवाद',
    'समाजवादी',
    'समाजविज्ञानी',
    'समाजशास्त्री',
    'समाजसेवी',
    'समाजहीनता',
    'समाजागार',
    'समाजातीयता',
    'समाजी',
    'समाजीकरण',
    'समाजीकृत',
    'समाजीय',
    'समाज्ञप्त',
    'समात',
    'समाता',
    'समातृ',
    'समातृक',
    'समात्मा',
    'समादत्त',
    'समादर',
    'समादरण',
    'समादान',
    'समादृत',
    'समादेय',
    'समादेश',
    'समाधनकृत',
    'समाधशोधन',
    'समाधान',
    'समाधानपूर्ण',
    'समाधानप्रद',
    'समाधानहीनता',
    'समाधान्य',
    'समाधि',
    'समाधिकार',
    'समाधिकारी',
    'समाधिगतता',
    'समाधित',
    'समाधिमग्न',
    'समाधिस्थ',
    'समाधिस्थता',
    'समाधिस्थल',
    'समाधेय',
    'समान',
    'समानघर',
    'समानता',
    'समानयन',
    'समानशील',
    'समाना',
    'समानांतर',
    'समानाधिकरण',
    'समानाधिकार',
    'समानाधिकारी',
    'समानान्तर',
    'समानार्थक',
    'समानार्थी',
    'समानीकरण',
    'समानुकूल',
    'समानुकूलता',
    'समानुकूलन',
    'समानुदेशन',
    'समानुपात',
    'समानुपातता',
    'समानुपातिक',
    'समानुपाती',
    'समानुभूति',
    'समानुरूप',
    'समानुवर्ती',
    'समानों',
    'समान्तर',
    'समान्य',
    'समापक',
    'समापत्र',
    'समापदान',
    'समापन',
    'समापनीय',
    'समापहरण',
    'समापित',
    'समापित्त',
    'समाप्त',
    'समाप्तं',
    'समाप्ततः',
    'समाप्तता',
    'समाप्तमिदं',
    'समाप्ति',
    'समाप्तिक',
    'समाप्य',
    'समाप्यता',
    'समाप्लव',
    'समाप्लाव',
    'समाभाषण',
    'समाभासित',
    'समामेल',
    'समामेलन',
    'समामेलित',
    'समाम्नात',
    'समाम्नाय',
    'समायत',
    'समायताकार',
    'समायन',
    'समाया',
    'समायुक्त',
    'समायोग',
    'समायोजक',
    'समायोजन',
    'समायोजना',
    'समायोजित',
    'समायोज्य',
    'समारंजक',
    'समारंजन',
    'समारंभ',
    'समारंभण',
    'समारब्ध',
    'समारब्धक',
    'समाराधन',
    'समाराध्य',
    'समाराहण',
    'समारिक',
    'समारुढ़',
    'समारेख',
    'समारोह',
    'समारोहण',
    'समारोही',
    'समार्थिक',
    'समालंबन',
    'समालक्ष्य',
    'समालब्ध',
    'समालब्धा',
    'समालब्धि',
    'समालाप',
    'समालापक',
    'समालिप्त',
    'समालोकन',
    'समालोकित',
    'समालोकी',
    'समालोचक',
    'समालोचन',
    'समालोचना',
    'समावर्जन',
    'समावर्तन',
    'समावलोकन',
    'समावाय',
    'समावायन',
    'समावाहक',
    'समावाहन',
    'समावाहित',
    'समाविष्ट',
    'समाविष्टि',
    'समावृत',
    'समावृत्त',
    'समावृत्ति',
    'समावेश',
    'समावेशक',
    'समावेशन',
    'समावेशित',
    'समावेष्टित',
    'समाशोधन',
    'समाशोधित',
    'समाश्रय',
    'समाश्रित',
    'समाश्रितता',
    'समाश्वास',
    'समाश्वासक',
    'समाश्वासन',
    'समाश्वासित',
    'समास',
    'समासक्त',
    'समासग',
    'समासन',
    'समासन्न',
    'समासहीन',
    'समासादन',
    'समासीन',
    'समाहकर्ता',
    'समाहरण',
    'समाहर्ता',
    'समाहर्त्ता',
    'समाहार',
    'समाहित',
    'समाहीनता',
    'समाहूत',
    'समाहूति',
    'समाहृत',
    'समिंधन',
    'समित',
    'समिति',
    'समितिंजय',
    'समितियां',
    'समितियों',
    'समित्र',
    'समित्रता',
    'समिदाधान',
    'समिद्घ',
    'समिध',
    'समिधा',
    'समिन्वत',
    'समिैर्थत',
    'समीकरण',
    'समीकृत',
    'समीकृति',
    'समीक्षक',
    'समीक्षण',
    'समीक्षा',
    'समीक्षाकार',
    'समीक्षात्मक',
    'समीक्षाधीन',
    'समीचीन',
    'समीचीनता',
    'समीप',
    'समीपतः',
    'समीपता',
    'समीपन',
    'समीपवर्ती',
    'समीपस्थ',
    'समीपस्थता',
    'समीपी',
    'समीर',
    'समीरक',
    'समीरण',
    'समीरित',
    'समीहा',
    'समीहित',
    'समुंद',
    'समुंदर',
    'समुक्ति',
    'समुचित',
    'समुचितता',
    'समुच्चय',
    'समुच्चयन',
    'समुच्चयालंकार',
    'समुच्चयित',
    'समुच्चार',
    'समुच्चित',
    'समुज्जवल',
    'समुज्जवलता',
    'समुत्कंटिकत',
    'समुत्कंठ',
    'समुत्कट',
    'समुत्कीर्ण',
    'समुत्कीर्णन',
    'समुत्क्रमण',
    'समुत्खनन',
    'समुत्खनित',
    'समुत्खनित्र',
    'समुत्खात',
    'समुत्तंभन',
    'समुत्तुंग',
    'समुत्तुंगता',
    'समुत्तेजक',
    'समुत्तेजन',
    'समुत्तेजना',
    'समुत्तोलन',
    'समुत्थान',
    'समुत्थापक',
    'समुत्थापिता',
    'समुत्पतित',
    'समुत्पन्न',
    'समुत्सर्पी',
    'समुत्सारण',
    'समुत्साह',
    'समुत्साहन',
    'समुत्साहित',
    'समुत्सुक',
    'समुत्सेध',
    'समुद',
    'समुदंत',
    'समुदय',
    'समुदाय',
    'समुदित',
    'समुदिष्ट',
    'समुद्क',
    'समुद्ग',
    'समुद्गत',
    'समुद्गम',
    'समुद्गमन',
    'समुद्घत',
    'समुद्घरण',
    'समुद्घर्ता',
    'समुद्घहन',
    'समुद्घात',
    'समुद्घातक',
    'समुद्घाहन',
    'समुद्घोषणा',
    'समुद्देश',
    'समुद्देश्य',
    'समुद्धिग्न',
    'समुद्बोधन',
    'समुद्भिज्ज',
    'समुद्भिद',
    'समुद्भूत',
    'समुद्यत',
    'समुद्यमी',
    'समुद्र',
    'समुद्रग',
    'समुद्रगामी',
    'समुद्रज',
    'समुद्रजल',
    'समुद्रतट',
    'समुद्रनेमि',
    'समुद्रपारीय',
    'समुद्रफेन',
    'समुद्रफेनी',
    'समुद्रभिमुख',
    'समुद्रवर्ती',
    'समुद्रविज्ञानी',
    'समुद्रांत',
    'समुद्रांबरा',
    'समुद्रांरु',
    'समुद्री',
    'समुद्रीय',
    'समुद्वार',
    'समुद्वारक',
    'समुन्दर',
    'समुन्नत',
    'समुन्नति',
    'समुन्नद्घ',
    'समुन्नयन',
    'समुन्मूलन',
    'समुपकरण',
    'समुपक्रम',
    'समुपगम',
    'समुपगमन',
    'समुपनयन',
    'समुपनीत',
    'समुपयुक्त',
    'समुपयुक्तता',
    'समुपयोग',
    'समुपयोजन',
    'समुपवेशन',
    'समुपस्थित',
    'समुपस्थिति',
    'समुपागत',
    'समुपागम',
    'समुपार्जन',
    'समुपालंभ्',
    'समुपेत',
    'समुपोढ',
    'समुरदावृत',
    'समुर्दगा',
    'समुर्दगार',
    'समुर्दगीत',
    'समुर्दग्रहीत',
    'समुल्लस',
    'समुल्लसित',
    'समुल्लास',
    'समुल्लासन',
    'समुह',
    'समुहँ',
    'समूचा',
    'समूचापन',
    'समूची',
    'समूढ',
    'समूति',
    'समूर',
    'समूरु',
    'समूर्त',
    'समूर्तता',
    'समूल',
    'समूल्य',
    'समूह',
    'समूहक',
    'समूहगान',
    'समूहचर्या',
    'समूहतः',
    'समूहता',
    'समूहन',
    'समूहनी',
    'समूहबद्घ',
    'समूहबद्घता',
    'समूहवाद',
    'समूहातिरिक्तता',
    'समूहित',
    'समूहीकृत',
    'समूहीय',
    'समृद्घ',
    'समृद्घता',
    'समृद्ध',
    'समृद्धि',
    'समृद्धिप्रद',
    'समृद्धिशाली',
    'समेकन',
    'समेकनकर्ता',
    'समेकित',
    'समेकितता',
    'समेट',
    'समेटन',
    'समेटना',
    'समेटा',
    'समेटी',
    'समेत',
    'समेतर',
    'समैत्री',
    'समोच्च',
    'समोच्चरित',
    'समोत्तल',
    'समोत्पाद',
    'समोद',
    'समोदक',
    'समोना',
    'समोवार',
    'समोसा',
    'समोसे',
    'सम्',
    'सम्पति',
    'सम्पत्ति',
    'सम्पदा',
    'सम्पन्न',
    'सम्पन्नता',
    'सम्परिवर्तक',
    'सम्पर्क',
    'सम्पाती',
    'सम्पादक',
    'सम्पादकीय',
    'सम्पादन',
    'सम्पादित',
    'सम्पार्श्विक',
    'सम्पूर्ण',
    'सम्पूर्णतया',
    'सम्पूर्णता',
    'सम्प्रदाय',
    'सम्प्रदायी',
    'सम्प्रवाही',
    'सम्प्रेषण',
    'सम्बद्ध',
    'सम्बन्ध',
    'सम्बन्धकारक',
    'सम्बन्धता',
    'सम्बन्धित',
    'सम्बन्धी',
    'सम्बोधन',
    'सम्भव',
    'सम्भवत',
    'सम्भावना',
    'सम्भावनाएं',
    'सम्भावनाओं',
    'सम्भावित',
    'सम्भाव्यता',
    'सम्मंत्र',
    'सम्मंत्रित',
    'सम्मत',
    'सम्मतता',
    'सम्मति',
    'सम्मतिपत्र',
    'सम्मतिहीनता',
    'सम्मन',
    'सम्मर्द',
    'सम्मर्दन',
    'सम्मर्दी',
    'सम्मर्शक',
    'सम्मर्शन',
    'सम्मशीर',
    'सम्मा',
    'सम्माता',
    'सम्माद',
    'सम्मान',
    'सम्मानन',
    'सम्मानना',
    'सम्माननीय',
    'सम्मानपत्र',
    'सम्मानपद',
    'सम्मानपूर्ण',
    'सम्मानपूर्वक',
    'सम्मानयुक्त',
    'सम्मानार्थ',
    'सम्मानार्थक',
    'सम्मानित',
    'सम्मानी',
    'सम्मान्य',
    'सम्मार्जन',
    'सम्माहित',
    'सम्मिलित',
    'सम्मिश्र',
    'सम्मिश्रण',
    'सम्मीलन',
    'सम्मीलित',
    'सम्मुख',
    'सम्मुखतः',
    'सम्मुखता',
    'सम्मुखस्थ',
    'सम्मुखी',
    'सम्मुग्ध',
    'सम्मुग्धन',
    'सम्मूढ़',
    'सम्मूर्खता',
    'सम्मूर्च्छा',
    'सम्मेलन',
    'सम्मेलनों',
    'सम्मोद',
    'सम्मोह',
    'सम्मोहक',
    'सम्मोहकता',
    'सम्मोहकारी',
    'सम्मोहन',
    'सम्मोहनपूर्ण',
    'सम्मोहनास्त्र',
    'सम्मोहनीय',
    'सम्मोहात्मक',
    'सम्मोहित',
    'सम्मोहिनी',
    'सम्मोही',
    'सम्यक',
    'सम्यक्',
    'सम्यीची',
    'सम्राज्ञी',
    'सम्राट',
    'सम्हलना',
    'सम्हाल',
    'सम्हालन',
    'सम्हालना',
    'सम्हालने',
    'सम्हाला',
    'सयान',
    'सयाना',
    'सयानापन',
    'सयाने',
    'सयुथ्य',
    'सयोनि',
    'सयोर्दय',
    'सर',
    'सरंजित',
    'सरअंजाम',
    'सरइया',
    'सरई',
    'सरक',
    'सरकंडा',
    'सरकता',
    'सरकते',
    'सरकन',
    'सरकना',
    'सरकने',
    'सरकर्दा',
    'सरकश',
    'सरकशी',
    'सरकस',
    'सरका',
    'सरकाऊ',
    'सरकाना',
    'सरकार',
    'सरकारी',
    'सरकारीकरण',
    'सरकैया',
    'सरकोब',
    'सरकोबा',
    'सरकोबी',
    'सरखा',
    'सरगना',
    'सरगम',
    'सरगरोह',
    'सरगर्म',
    'सरगर्मी',
    'सरगही',
    'सरगुजश्त',
    'सरगुन',
    'सरगुनिया',
    'सरचढ़ा',
    'सरचश्मा',
    'सरजना',
    'सरज़मीन',
    'सरजा',
    'सरजीवन',
    'सरजैंट',
    'सरजोश',
    'सरट',
    'सरटिका',
    'सरटैक्स',
    'सरण',
    'सरणि',
    'सरणी',
    'सरणीबद्ध',
    'सरता',
    'सरताज',
    'सरतान',
    'सरथ',
    'सरद',
    'सरदफ्तर',
    'सरदर',
    'सरदर्द',
    'सरदल',
    'सरदार',
    'सरदारी',
    'सरदी',
    'सरधा',
    'सरनदीब',
    'सरनमे',
    'सरना',
    'सरनाम',
    'सरनामा',
    'सरपंच',
    'सरपट',
    'सरपत',
    'सरपरस्त',
    'सरपरस्ती',
    'सरपल',
    'सरपोश',
    'सरफराज',
    'सरफरोश',
    'सरफरोशी',
    'सरफेस',
    'सरफेसिंग',
    'सरबत',
    'सरबमुंह',
    'सरबस',
    'सरबसर',
    'सरबाज',
    'सरबार',
    'सरबाला',
    'सरबुलंद',
    'सरबुलंदी',
    'सरमद',
    'सरमन',
    'सरमस्त',
    'सरमस्ती',
    'सरमा',
    'सरमाया',
    'सरमायादार',
    'सरमायादारी',
    'सरयू',
    'सरल',
    'सरलचित्त',
    'सरलचित्तता',
    'सरलता',
    'सरला',
    'सरलांग',
    'सरलीकरण',
    'सरव',
    'सरवक',
    'सरवन',
    'सरवर',
    'सरवरी',
    'सरवाक',
    'सरस',
    'सरसई',
    'सरसता',
    'सरसन',
    'सरसना',
    'सरसब्ज',
    'सरसर',
    'सरसराना',
    'सरसराहट',
    'सरसरी',
    'सरसाई',
    'सरसाना',
    'सरसिका',
    'सरसिज',
    'सरसिरुह',
    'सरसी',
    'सरसीय',
    'सरसीला',
    'सरसीली',
    'सरसुति',
    'सरसों',
    'सरसौंहौं',
    'सरसौही',
    'सरस्वत',
    'सरस्वती',
    'सरस्वतीय',
    'सरस्वत्य',
    'सरस्वान',
    'सरह',
    'सरहंग',
    'सरहंगी',
    'सरहंस',
    'सरहद',
    'सरहदी',
    'सरहर',
    'सरा',
    'सराँग',
    'सराकार',
    'सराग',
    'सराना',
    'सरापना',
    'सरापा',
    'सराफ',
    'सराफखाना',
    'सराफा',
    'सराफी',
    'सराबोर',
    'सराय',
    'सरायदार',
    'सराव',
    'सरावगी',
    'सरासर',
    'सरासरी',
    'सराहना',
    'सराहनीय',
    'सराहा',
    'सराफ़',
    'सरि',
    'सरिका',
    'सरित',
    'सरिता',
    'सरितांवरा',
    'सरित्सुरंगा',
    'सरिया',
    'सरियाना',
    'सरिश्त',
    'सरिस',
    'सरी',
    'सरीखा',
    'सरीखी',
    'सरीसृप',
    'सरु',
    'सरुंपता',
    'सरुचि',
    'सरुप',
    'सरुपब्रह्म',
    'सरुपा',
    'सरुपाकार',
    'सरे',
    'सरेराह',
    'सरेश',
    'सरेशदार',
    'सरेस',
    'सरैया',
    'सरो',
    'सरोकार',
    'सरोकारी',
    'सरोज',
    'सरोजनी',
    'सरोजिनी',
    'सरोजी',
    'सरोता',
    'सरोत्सव',
    'सरोद',
    'सरोदिया',
    'सरोपा',
    'सरोवर',
    'सरोवरज',
    'सरोवरी',
    'सरोवरीय',
    'सरोश',
    'सरोष',
    'सरौ',
    'सरौट',
    'सरौता',
    'सरौताः',
    'सर्कल',
    'सर्कस',
    'सर्किट',
    'सर्किल',
    'सर्कुलर',
    'सर्कुलेटिंग',
    'सर्कुलेशन',
    'सर्ग',
    'सर्गक',
    'सर्गबंध',
    'सर्च',
    'सर्ज',
    'सर्जक',
    'सर्जकता',
    'सर्जन',
    'सर्जनशील',
    'सर्जनशीलता',
    'सर्जनहार',
    'सर्जनहीन',
    'सर्जना',
    'सर्जनात्मक',
    'सर्जनात्मकता',
    'सर्जनीय',
    'सर्जरी',
    'सर्जित',
    'सर्जी',
    'सर्टिकिट',
    'सर्टिफ़िकेट',
    'सर्टिफिकेट',
    'सर्टिफ़िकेट',
    'सर्टीफिकेट',
    'सर्थन',
    'सर्द',
    'सर्दई',
    'सर्दखाना',
    'सर्दाब',
    'सर्दी',
    'सर्प',
    'सर्पः',
    'सर्पक',
    'सर्पकेशी',
    'सर्पगतिक',
    'सर्पण',
    'सर्पणी',
    'सर्पदर्शी',
    'सर्पधर',
    'सर्पभीति',
    'सर्पराज',
    'सर्पराज्ञी',
    'सर्पलिंगी',
    'सर्पशीर्ष',
    'सर्पहा',
    'सर्पा',
    'सर्पाकार',
    'सर्पाकारता',
    'सर्पारि',
    'सर्पावास',
    'सर्पाश्म',
    'सर्पिणी',
    'सर्पिल',
    'सर्पिलता',
    'सर्पी',
    'सर्फ',
    'सर्फबोर्ड',
    'सर्मथन',
    'सर्या',
    'सर्रा',
    'सर्राटा',
    'सर्राटे',
    'सर्राफ',
    'सर्राफा',
    'सर्राफी',
    'सर्राफ़',
    'सर्व',
    'सर्वकर',
    'सर्वक़द',
    'सर्वकामंद',
    'सर्वकामद',
    'सर्वकार्यक्षम',
    'सर्वकार्यक्षमता',
    'सर्वकाल',
    'सर्वकुंजी',
    'सर्वक्षमा',
    'सर्वक्षार',
    'सर्वग',
    'सर्वगति',
    'सर्वगामी',
    'सर्वगुण',
    'सर्वगुणी',
    'सर्वगोचर',
    'सर्वग्रास',
    'सर्वग्राहिता',
    'सर्वग्राही',
    'सर्वचारी',
    'सर्वजन',
    'सर्वजनता',
    'सर्वजनीन',
    'सर्वजनीय',
    'सर्वजीववाद',
    'सर्वज्ञ',
    'सर्वज्ञता',
    'सर्वज्ञा',
    'सर्वज्ञात',
    'सर्वज्ञातता',
    'सर्वज्ञानार्जनावाद',
    'सर्वतंत्र',
    'सर्वतः',
    'सर्वता',
    'सर्वतोभद्र',
    'सर्वतोभद्रक',
    'सर्वतोभाव',
    'सर्वतोभ्रदासन',
    'सर्वतोमुख',
    'सर्वतोमुखता',
    'सर्वतोमुखी',
    'सर्वत्यागी',
    'सर्वत्र',
    'सर्वत्रग',
    'सर्वत्रता',
    'सर्वथा',
    'सर्वदंडानायक',
    'सर्वदलीय',
    'सर्वदशीं',
    'सर्वदा',
    'सर्वदाता',
    'सर्वदानी',
    'सर्वदिशत',
    'सर्वदृश्य',
    'सर्वदृश्यक',
    'सर्वदृष्टा',
    'सर्वदेवमय',
    'सर्वदेशीय',
    'सर्वधर्म',
    'सर्वध्वंस',
    'सर्वनष्ट',
    'सर्वनाम',
    'सर्वनाश',
    'सर्वनाशी',
    'सर्वनियंता',
    'सर्वप्रथम',
    'सर्वप्रद',
    'सर्वप्रिय',
    'सर्वप्रियता',
    'सर्वभक्षी',
    'सर्वभीति',
    'सर्वभूत',
    'सर्वभूतमहेश्वर',
    'सर्वभेषज',
    'सर्वभोगी',
    'सर्वभोग्य',
    'सर्वमंगला',
    'सर्वमान्य',
    'सर्वमान्यता',
    'सर्वमापलिख',
    'सर्वमिदं',
    'सर्वमेध',
    'सर्वयोग',
    'सर्वरचनासंग्रह',
    'सर्वरी',
    'सर्वरुप',
    'सर्वरुपी',
    'सर्वरोगहर',
    'सर्वलोक',
    'सर्वलोकेश',
    'सर्वलोकेश्वर',
    'सर्ववर्णिक',
    'सर्वविदता',
    'सर्वविदित',
    'सर्ववेत्ता',
    'सर्ववेशी',
    'सर्वव्यापक',
    'सर्वव्यापी',
    'सर्वशः',
    'सर्वशक्तिमत्ता',
    'सर्वशक्तिमान',
    'सर्वशक्तिशाली',
    'सर्वश्री',
    'सर्वश्रेष्ठ',
    'सर्वश्वरवादात्मक',
    'सर्वसंबंधित',
    'सर्वसंहार',
    'सर्वसत्ताधारी',
    'सर्वसमता',
    'सर्वसमय',
    'सर्वसमावेशी',
    'सर्वसम्मत',
    'सर्वसम्मति',
    'सर्वसहमत',
    'सर्वसहमततः',
    'सर्वसहमति',
    'सर्वसाक्षी',
    'सर्वसिद्धि',
    'सर्वसिद्धिदा',
    'सर्वस्व',
    'सर्वस्वहर',
    'सर्वहर',
    'सर्वहारा',
    'सर्वहारी',
    'सर्वहितेच्छा',
    'सर्वांगपूर्ण',
    'सर्वांगसमता',
    'सर्वांसम',
    'सर्वाख्य',
    'सर्वाग',
    'सर्वागतः',
    'सर्वागतरस्थ',
    'सर्वागता',
    'सर्वागीण',
    'सर्वागीणता',
    'सर्वागीय',
    'सर्वागीयता',
    'सर्वातक',
    'सर्वातरस्थायी',
    'सर्वातर्यामी',
    'सर्वात्मवाद',
    'सर्वात्मा',
    'सर्वादि',
    'सर्वाधिक',
    'सर्वाधिकार',
    'सर्वाधिकारी',
    'सर्वाध्यक्ष',
    'सर्वानुभूति',
    'सर्वार्थ',
    'सर्वार्थसिद्घ',
    'सर्वाश',
    'सर्वाशिक',
    'सर्वाशिकता',
    'सर्वाशी',
    'सर्वास्तिवाद',
    'सर्वाहारी',
    'सर्विस',
    'सर्विसेज',
    'सर्विसेज़',
    'सर्वृक्ष',
    'सर्वे',
    'सर्वेअर',
    'सर्वेक्षक',
    'सर्वेक्षण',
    'सर्वेतोमुखी',
    'सर्वेश',
    'सर्वेश्वर',
    'सर्वेश्वरवादात्मक',
    'सर्वेश्वरवादी',
    'सर्वेसर्या',
    'सर्वेसर्वा',
    'सर्वोच्च',
    'सर्वोच्चता',
    'सर्वोत्कृष्ट',
    'सर्वोत्तम',
    'सर्वोपरि',
    'सर्षप',
    'सल',
    'सलई',
    'सलखडी',
    'सलझन',
    'सलना',
    'सलमा',
    'सलय',
    'सलर',
    'सलवट',
    'सलवटदार',
    'सलवटी',
    'सलवात',
    'सलवार',
    'सलसलाना',
    'सलसलाहट',
    'सलहज',
    'सलाइयाँ',
    'सलाई',
    'सलाईयां',
    'सलाक',
    'सलाका',
    'सलात',
    'सलाना',
    'सलानी',
    'सलाभ',
    'सलाम',
    'सलामत',
    'सलामती',
    'सलाममती',
    'सलामी',
    'सलाया',
    'सलाह',
    'सलाहकार',
    'सलाहट',
    'सलाहसूचना',
    'सलाहाकार',
    'सलाहियत',
    'सलिंग',
    'सलिल',
    'सलिलज',
    'सलिलयोनि',
    'सलिलाकार',
    'सलिलाधिप',
    'सलिलाश्य',
    'सलिलाहारी',
    'सलिलेंद्र',
    'सलिलेश',
    'सलिलेशय',
    'सलिलोद्भ',
    'सलीका',
    'सलीके',
    'सलीकेदार',
    'सलीपर',
    'सलीब',
    'सलीबधारी',
    'सलीबी',
    'सलीम',
    'सलीमशाही',
    'सलील',
    'सलीस',
    'सलूक',
    'सलूका',
    'सलूनो',
    'सलेअ',
    'सलेक्शन',
    'सलेट',
    'सलेटी',
    'सलेपनी',
    'सलेली',
    'सलैया',
    'सलैला',
    'सलोना',
    'सलोनापन',
    'सलोरा',
    'सल्',
    'सल्तनत',
    'सल्तनती',
    'सल्फर',
    'सल्लकी',
    'सल्ला',
    'सल्लू',
    'सल्लेअमा',
    'सव',
    'सवकता',
    'सवग',
    'सवगता',
    'सवन',
    'सवपु',
    'सवयमजिर्त',
    'सवया',
    'सवर्ग',
    'सवर्गीय',
    'सवर्ण',
    'सवर्णज',
    'सवलय',
    'सवा',
    'सवाई',
    'सवादहीनता',
    'सवाब',
    'सवाया',
    'सवार',
    'सवारी',
    'सवाल',
    'सवालिया',
    'सवाली',
    'सवास',
    'सविकरण',
    'सविकल्',
    'सविकार',
    'सविकारता',
    'सविग्रह',
    'सविचार',
    'सविचालय',
    'सविता',
    'सवितृ',
    'सविद्य',
    'सविद्वेष',
    'सविध',
    'सविधि',
    'सविनय',
    'सविनयता',
    'सविनीततः',
    'सविराम',
    'सविरोध',
    'सविर्स',
    'सविर्सिंग',
    'सविलास',
    'सविवरण',
    'सविवेक',
    'सविशेष',
    'सविश्वास',
    'सविस्तार',
    'सविस्तारता',
    'सविस्मय',
    'सवीरता',
    'सवीरय',
    'सवृद्धि',
    'सवृन्त',
    'सवेक्षण',
    'सवेग',
    'सवेच्छाचारी',
    'सवेतन',
    'सवेदना',
    'सवेरा',
    'सवेरे',
    'सवेश',
    'सवैट',
    'सवैट्स',
    'सवैधतः',
    'सवैया',
    'सवोर्च्च',
    'सवोर्च्चता',
    'सवोर्च्चबौद्घ',
    'सवोर्च्चस्थ',
    'सवोर्त्कृष्ट',
    'सवोर्त्कृष्टता',
    'सवोर्त्तम',
    'सवोर्त्त्मता',
    'सवोर्परि',
    'सव्य',
    'सव्यता',
    'सव्यवसाची',
    'सव्यवसाय',
    'सव्यवस्था',
    'सव्याख्या',
    'सव्याज',
    'सव्येष्ठा',
    'सव्रीड',
    'सशंक',
    'सशंकना',
    'सशक्त',
    'सशक्तता',
    'सशत्रु',
    'सशपथ',
    'सशरीर',
    'सशरीरता',
    'सशर्त',
    'सशर्तता',
    'सशल्',
    'सशल्य',
    'सशस्त्र',
    'सशांति',
    'सशिष्टाचार',
    'सशीश',
    'सशीष्र',
    'सशुल्क',
    'सशेष',
    'सशेषफल',
    'सश्रद्घा',
    'सश्रम',
    'सश्रीक',
    'सश्लाघा',
    'सश्वास',
    'ससंकलप',
    'ससंख्य',
    'ससंख्यता',
    'ससंख्या',
    'ससंग',
    'ससंजीदगी',
    'ससंज्ञ',
    'ससंज्ञता',
    'ससंझवाती',
    'ससंता',
    'ससंतानता',
    'ससंदेह',
    'ससंपर्क',
    'ससंभ्रम',
    'ससंयोग',
    'ससंशय',
    'ससंशोधन',
    'ससंस्कार',
    'ससखा',
    'ससत्त्व',
    'ससद',
    'ससमय',
    'ससम्मान',
    'ससयता',
    'ससहमति',
    'ससहयोग',
    'ससहाय',
    'ससहायता',
    'ससहीय',
    'ससा',
    'ससामंजस्य',
    'ससार',
    'ससाहस',
    'ससीम',
    'ससीमता',
    'ससुकेत',
    'ससुर',
    'ससुरा',
    'ससुराल',
    'ससुरी',
    'ससुविधा',
    'ससूदखोरी',
    'ससेवा',
    'ससैन्य',
    'सस्क',
    'सस्तर',
    'सस्ता',
    'सस्तान',
    'सस्ती',
    'सस्ते',
    'सस्नेह',
    'सस्पैंड',
    'सस्पैंशन',
    'सस्य',
    'सस्रोत',
    'सस्वर',
    'सस्वागत',
    'सस्वाद',
    'सस्वामी',
    'सस्वीकृति',
    'सह',
    'सहंगा',
    'सहंगी',
    'सहंगीत',
    'सहकरण',
    'सहकर्ता',
    'सहकर्म',
    'सहकर्मचारिता',
    'सहकर्मी',
    'सहकार',
    'सहकारिता',
    'सहकारी',
    'सहकाल',
    'सहकालिक',
    'सहकालीन',
    'सहकालीनता',
    'सहकुल',
    'सहकेंद्रीयता',
    'सहगमम',
    'सहगान',
    'सहगामि',
    'सहगामिनी',
    'सहगामी',
    'सहगायक',
    'सहगायन',
    'सहगोत्र',
    'सहगौन',
    'सहचय्र',
    'सहचर',
    'सहचरतः',
    'सहचरता',
    'सहचरहीनता',
    'सहचरी',
    'सहचर्या',
    'सहचारिणी',
    'सहचारिता',
    'सहचारी',
    'सहज',
    'सहजगम्यता',
    'सहजचित',
    'सहजचित्तता',
    'सहजतः',
    'सहजता',
    'सहजन',
    'सहजना',
    'सहजन्म',
    'सहजबुद्धि',
    'सहजभाव',
    'सहजात',
    'सहजाता',
    'सहजातीय',
    'सहजानुभति',
    'सहजीवन',
    'सहजीवी',
    'सहजोदासीन',
    'सहठ',
    'सहता',
    'सहतिपूर्ण',
    'सहती',
    'सहदागाह',
    'सहदायिक',
    'सहदारा',
    'सहदेव',
    'सहधंधी',
    'सहधमिर्णी',
    'सहधर',
    'सहधर्म',
    'सहधर्मी',
    'सहन',
    'सहनक्षम',
    'सहनची',
    'सहनयिक',
    'सहनर्तक',
    'सहनशक्ति',
    'सहनशील',
    'सहनशीलता',
    'सहना',
    'सहनाम',
    'सहनामता',
    'सहनामी',
    'सहनायक',
    'सहनाविक',
    'सहनिवासी',
    'सहनीय',
    'सहनोन्माद',
    'सहपंथ',
    'सहपंथा',
    'सहपंथिता',
    'सहपंथी',
    'सहपत्नी',
    'सहपत्नीक',
    'सहपत्र',
    'सहपलायन',
    'सहपाठी',
    'सहपान',
    'सहपानक',
    'सहपायी',
    'सहप्रांगण',
    'सहबंधन',
    'सहबंधु',
    'सहबद्ध',
    'सहबद्धता',
    'सहबाला',
    'सहबुद्धि',
    'सहभगिता',
    'सहभजी',
    'सहभाग',
    'सहभागित',
    'सहभागिता',
    'सहभागिनी',
    'सहभागी',
    'सहभागीदार',
    'सहभागीदारी',
    'सहभाजन',
    'सहभार्य',
    'सहभावना',
    'सहभावी',
    'सहभू',
    'सहभूत',
    'सहभूति',
    'सहभोज',
    'सहभोजी',
    'सहम',
    'सहमत',
    'सहमतता',
    'सहमति',
    'सहमतिहीन',
    'सहमतिहीनता',
    'सहमते',
    'सहमना',
    'सहमरण',
    'सहमा',
    'सहमातृ',
    'सहमापन',
    'सहयात्रा',
    'सहयात्री',
    'सहयायी',
    'सहयुक्त',
    'सहयोग',
    'सहयोगकर्ता',
    'सहयोगपूर्ण',
    'सहयोगशील',
    'सहयोगहीनता',
    'सहयोगिक',
    'सहयोगिता',
    'सहयोगी',
    'सहयोजन',
    'सहयोजित',
    'सहर',
    'सहरगही',
    'सहरण',
    'सहरदम',
    'सहरा',
    'सहराग',
    'सहरी',
    'सहरूदन',
    'सहर्ष',
    'सहलंगी',
    'सहलग्नता',
    'सहलाकर',
    'सहलाना',
    'सहलाव',
    'सहलावनि',
    'सहवंश',
    'सहवंशी',
    'सहवंशीय',
    'सहवर',
    'सहवर्गीय',
    'सहवर्ण',
    'सहवर्णीय',
    'सहवर्तिता',
    'सहवर्ती',
    'सहवादन',
    'सहवास',
    'सहवासिनी',
    'सहवासी',
    'सहवेदनशील',
    'सहवेदना',
    'सहशासित',
    'सहशिक्षक',
    'सहसंघ',
    'सहसंबंध',
    'सहसम्बन्ध',
    'सहसम्बन्धता',
    'सहसम्बन्धी',
    'सहसा',
    'सहसाघात',
    'सहसाझेदार',
    'सहसानु',
    'सहस्तित्व',
    'सहस्त्रार्जुन',
    'सहस्थ',
    'सहस्य',
    'सहस्यानुष्ठान',
    'सहस्र',
    'सहस्रंशुज',
    'सहस्रक',
    'सहस्रग',
    'सहस्रगुण',
    'सहस्रगुणा',
    'सहस्रचरण',
    'सहस्रचिर्',
    'सहस्रजित',
    'सहस्रतम',
    'सहस्रतय',
    'सहस्रदल',
    'सहस्रद्रंष्टथ',
    'सहस्रधा',
    'सहस्रनयन',
    'सहस्रनेत्र',
    'सहस्रफन',
    'सहस्रबाहु',
    'सहस्रभागवती',
    'सहस्रभुज',
    'सहस्रभुजा',
    'सहस्रमूर्धा',
    'सहस्रयानि',
    'सहस्रलोचन',
    'सहस्रशः',
    'सहस्रांशु',
    'सहस्राक्ष',
    'सहस्रानन',
    'सहस्राब्दवाद',
    'सहस्राब्दवादी',
    'सहस्राब्दि',
    'सहस्राब्दिक',
    'सहस्राब्दी',
    'सहस्रार',
    'सहस्रार्जुन',
    'सहस्री',
    'सहस्वामित्व',
    'सहस्वामी',
    'सहा',
    'सहातृक',
    'सहाधिकार',
    'सहाध्यायी',
    'सहानुकम्पी',
    'सहानुगमन',
    'सहानुभूति',
    'सहानुभूतिपूर्ण',
    'सहानुभूतिपूर्वक',
    'सहानुष्ठान',
    'सहानुसारण',
    'सहापति',
    'सहाय',
    'सहायक',
    'सहायकक्रिया',
    'सहायकता',
    'सहायकयुक्त',
    'सहायकहीन',
    'सहायकी',
    'सहायता',
    'सहायतापूर्ण',
    'सहायताप्राप्त',
    'सहायतायुक्त',
    'सहायतायुक्तता',
    'सहायतार्थ',
    'सहायतार्थी',
    'सहायताहीन',
    'सहायताहीनतः',
    'सहायताहीनता',
    'सहायिकी',
    'सहायी',
    'सहार',
    'सहारनपुर',
    'सहारा',
    'सहारे',
    'सहालग',
    'सहावल',
    'सहासही',
    'सहास्य',
    'सहिजन',
    'सहित',
    'सहिततः',
    'सहितता',
    'सहिता',
    'सहिम',
    'सहिष्णु',
    'सहिष्णुता',
    'सही',
    'सहीपन',
    'सहू',
    'सहूलियत',
    'सहृदय',
    'सहृदयता',
    'सहेजन',
    'सहेजना',
    'सहेजा',
    'सहेजी',
    'सहेजू',
    'सहेट',
    'सहेतु',
    'सहेतुक',
    'सहेथना',
    'सहेल',
    'सहेली',
    'सहोए',
    'सहोटज',
    'सहोत्तराधिकारी',
    'सहोत्पाद',
    'सहोदर',
    'सहोदरता',
    'सहोदरा',
    'सहोर',
    'सह्य',
    'सह्यात्मजा',
    'सह्याद्रि',
    'सा',
    'साँकला',
    'साँग',
    'साँच',
    'साँचरनमक',
    'साँचरिक',
    'साँचा',
    'साँचिया',
    'साँची',
    'साँचे',
    'साँझ',
    'साँझा',
    'साँझी',
    'साँट',
    'साँटिया',
    'साँटी',
    'साँठ',
    'साँठगाँठ',
    'साँड',
    'साँड़',
    'साँप',
    'साँपन',
    'साँपा',
    'साँभर',
    'साँवरा',
    'साँवरिया',
    'साँवरी',
    'साँवला',
    'साँवलिया',
    'साँवली',
    'साँस',
    'साँसत',
    'साँसा',
    'सांंटिफिक',
    'सांइंस',
    'सांइसदान',
    'सांकड',
    'सांकल',
    'सांकल्प',
    'सांकल्पिक',
    'सांकेतिक',
    'सांक्षेपित',
    'सांख्य',
    'सांख्यवाद',
    'सांख्यिक',
    'सांख्यिकी',
    'सांख्यिकीय',
    'सांख्यिकीविद',
    'सांग',
    'सांगतिक',
    'सांगम',
    'सांगा',
    'सांगी',
    'सांगोपांग',
    'सांगोपांगता',
    'सांग्रमिक',
    'सांघतिक',
    'सांघिक',
    'सांघी',
    'सांचा',
    'सांचागर',
    'सांचिया',
    'सांचे',
    'सांजस्य',
    'सांजस्यहीनता',
    'सांझा',
    'सांझेदार',
    'सांटी',
    'सांठ',
    'सांड',
    'सांडनी',
    'सांत',
    'सांतनिक',
    'सांतवाद',
    'सांत्वद',
    'सांत्वन',
    'सांत्वना',
    'सांत्वनापूर्ण',
    'सांत्वनाप्रद',
    'सांथरी',
    'सांद',
    'सांदीपनि',
    'सांदृष्टिक',
    'सांद्र',
    'सांद्रता',
    'सांध',
    'सांधना',
    'सांध्य',
    'सांध्यगीत',
    'सांध्यचर',
    'सांध्यलालिमा',
    'सांप',
    'सांपद',
    'सांपदिक',
    'सांपराय',
    'सांपान',
    'सांपिन',
    'सांप्रत',
    'सांप्रतिक',
    'सांप्रदायिक',
    'सांब',
    'सांबंधिक',
    'सांबरी',
    'सांभर',
    'सांमजस्यपूर्वक',
    'सांय',
    'सांयकाल',
    'सांयकालीन',
    'सांयुगन',
    'सांवतसर',
    'सांवत्सर',
    'सांवत्सरिक',
    'सांवदिक',
    'सांवां',
    'सांवाधिक',
    'सांविधिक',
    'सांवेगिक',
    'सांश',
    'सांशतः',
    'सांस',
    'सांसकि',
    'सांसद',
    'सांसरिकता',
    'सांसर्गिक',
    'सांसारिक',
    'सांसारिकता',
    'सांस्कारिक',
    'सांस्कृति',
    'सांस्कृतिक',
    'सांस्थ',
    'सांस्थनिक',
    'साअत',
    'साइअ',
    'साइकिल',
    'साइकिलिस्ट',
    'साइकोलाजी',
    'साइकोसिस',
    'साइक्लोन',
    'साइक्लोमीटर',
    'साइक्लोस्टाइल',
    'साइज',
    'साइज़',
    'साइट',
    'साइटेशन',
    'साइड',
    'साइत',
    'साइन',
    'साइनाइड',
    'साइन्उ',
    'साइप्रस',
    'साइरन',
    'साइरस',
    'साइलैंसर',
    'साइफ़र',
    'साई',
    'साईकिल',
    'साईज',
    'साउं',
    'साउंउ',
    'साउंड',
    'साए',
    'साक',
    'साकंट्रल',
    'साकंभरी',
    'साका',
    'साकांक्ष',
    'साकांक्षतः',
    'साकार',
    'साकारतः',
    'साकारता',
    'साकाररुप',
    'साकारोपासना',
    'साकिन',
    'साकी',
    'साकृति',
    'साक्ति',
    'साक्षर',
    'साक्षरता',
    'साक्षात',
    'साक्षात्करण',
    'साक्षात्कार',
    'साक्षात्कारी',
    'साक्षात्मकतार',
    'साक्षादृष्ट',
    'साक्षिता',
    'साक्षी',
    'साक्ष्ज्ञी',
    'साक्ष्य',
    'साक्ष्यांकन',
    'साक्ष्यांकित',
    'साक्ष्याभाव',
    'साख',
    'साखपत्र',
    'साखा',
    'साखी',
    'साखू',
    'साखेय',
    'साग',
    'सागपात',
    'सागर',
    'सागरग',
    'सागरगा',
    'सागरगामी',
    'सागरधरा',
    'सागरमति',
    'सागरांबरा',
    'सागरिका',
    'सागरीय',
    'सागवान',
    'सागूदाना',
    'सागौन',
    'साग्नि',
    'साग्रह',
    'साज',
    'साजन',
    'साजना',
    'साजबाज',
    'साजसामान',
    'साजिदा',
    'साजिश',
    'साजिशी',
    'साजीय',
    'साजैंट',
    'साझा',
    'साझाहीन',
    'साझी',
    'साझीदार',
    'साझे',
    'साझेदार',
    'साझेदारी',
    'साटन',
    'साटना',
    'साटिन',
    'साटी',
    'साठ',
    'साठगांठ',
    'साठनाठ',
    'साठवाँ',
    'साठा',
    'साठी',
    'साड़ी',
    'साडी',
    'साढी',
    'साढू',
    'साढे',
    'सात',
    'सातगुना',
    'सातत्य',
    'सातभई',
    'सातला',
    'सातलोक',
    'सातवचन',
    'सातवत',
    'सातवां',
    'सातवें',
    'सातस्वर',
    'साती',
    'सातै',
    'सातों',
    'सात्त्व',
    'सात्त्विक',
    'सात्मीयता',
    'सात्यवत',
    'सात्यवतेय',
    'सात्विक',
    'साथ',
    'साथिन',
    'साथियों',
    'साथी',
    'साथीहीन',
    'साद',
    'सादगी',
    'सादत',
    'सादतमंद',
    'सादर',
    'सादा',
    'सादाकार',
    'सादादिल',
    'सादापन',
    'सादि',
    'सादिक',
    'सादित',
    'सादी',
    'सादृश',
    'सादृश्य',
    'सादृश्यता',
    'सादृश्यपूर्ण',
    'सादृश्यमूलक',
    'सादृश्यस',
    'सादृश्याभासित',
    'सादे',
    'सादेश',
    'साध',
    'साधंत',
    'साधक',
    'साधकता',
    'साधन',
    'साधनहीन',
    'साधनहीनता',
    'साधना',
    'साधनी',
    'साधनीय',
    'साधने',
    'साधनों',
    'साधमर्य',
    'साधहीन',
    'साधहीनतः',
    'साधा',
    'साधार',
    'साधारण',
    'साधारणतः',
    'साधारणतया',
    'साधारणता',
    'साधारणीकरण',
    'साधारता',
    'साधारित',
    'साधिका',
    'साधिकार',
    'साधित',
    'साधित्र',
    'साधी',
    'साधु',
    'साधुं',
    'साधुता',
    'साधुधी',
    'साधुनी',
    'साधुवाद',
    'साधुवादी',
    'साधे',
    'साधो',
    'साध्य',
    'साध्यता',
    'साध्या',
    'साध्वाचार',
    'साध्वी',
    'सान',
    'सानंद',
    'सानना',
    'सानवाला',
    'सानी',
    'सानु',
    'सानुकूल',
    'सानुकूलता',
    'सानुग',
    'सानुगामी',
    'सानुपातता',
    'सानुपातिक',
    'सानुभव',
    'सानुमति',
    'सान्द्र',
    'सापत्न',
    'सापत्नेय',
    'सापत्न्य',
    'सापत्न्यक',
    'सापराध',
    'सापवाद',
    'सापेक्ष',
    'सापेक्षतः',
    'सापेक्षता',
    'सापेक्षिक',
    'सापेख',
    'साप्तपदीन',
    'साप्ताहिक',
    'साफ',
    'साफगो',
    'साफगोई',
    'साफदिल',
    'साफदिली',
    'साफ़',
    'साफा',
    'साफ्टवेयर',
    'साफ्टवेयरों',
    'साबका',
    'साबल',
    'साबित',
    'साबितकदम',
    'साबिर',
    'साबुत',
    'साबुन',
    'साबुनीकरण',
    'साबुनीकरणीय',
    'साबूदाना',
    'साबूनी',
    'साभा',
    'साभिप्राय',
    'साभिभावकता',
    'साम',
    'सामंग',
    'सामंजक',
    'सामंजस्य',
    'सामंजस्यपूर्ण',
    'सामंजस्यहीन',
    'सामंज्य',
    'सामंज्यपूर्ण',
    'सामंत',
    'सामंतवादी',
    'सामंतशाही',
    'सामंतीय',
    'सामंवादी',
    'सामक',
    'सामकारी',
    'सामगानप्रिय',
    'सामगायन',
    'सामगायी',
    'सामग्रियों',
    'सामग्री',
    'सामथ्र्य',
    'सामथ्र्यवती',
    'सामथ्र्यवान',
    'सामथ्र्यशालिनी',
    'सामथ्र्यशाली',
    'सामथ्र्यहीन',
    'सामथ्र्यहीनता',
    'सामध्य',
    'सामन',
    'सामना',
    'सामने',
    'सामन्त',
    'सामन्तभूमि',
    'सामन्तवाद',
    'सामन्ती',
    'सामप्रिय',
    'सामयवाद',
    'सामयवादी',
    'सामयानि',
    'सामयिक',
    'सामयिकता',
    'सामयोजन',
    'सामरस्य',
    'सामरिक',
    'सामरिकता',
    'सामर्थ्य',
    'सामवद',
    'सामवायिक',
    'सामवेद',
    'सामहिं',
    'सामाजिक',
    'सामाजिकता',
    'सामान',
    'सामानधर',
    'सामानयुक्त',
    'सामानहीन',
    'सामान्य',
    'सामान्यतः',
    'सामान्यतया',
    'सामान्यता',
    'सामान्यीकरण',
    'सामान्यीकृत',
    'सामान्योक्ति',
    'सामासिक',
    'सामासिकता',
    'सामिप्य',
    'सामिमान',
    'सामिष',
    'सामिषता',
    'सामिषाहारी',
    'सामी',
    'सामीची',
    'सामीप्य',
    'सामुदक',
    'सामुदायिक',
    'सामुदृग',
    'सामुद्र',
    'सामुद्रश',
    'सामुद्रिक',
    'सामुरी',
    'सामुहें',
    'सामूहिक',
    'सामूहिकतः',
    'सामूहिकता',
    'सामूहिकीकरण',
    'सामेहिक',
    'सामोद',
    'साम्नाय',
    'साम्नायतः',
    'साम्नायता',
    'साम्प्रदायवाद',
    'साम्प्रदायवादी',
    'साम्प्रदायिक',
    'साम्प्रदायिकता',
    'साम्मत्य',
    'साम्मुखी',
    'साम्मुय',
    'साम्य',
    'साम्यवाद',
    'साम्यवादी',
    'साम्यानुमान',
    'साम्यिक',
    'साम्राज्ञी',
    'साम्राज्य',
    'साम्राज्यवाद',
    'साम्री',
    'साय',
    'सायं',
    'सायंकाल',
    'सायंकालीन',
    'सायक',
    'सायत',
    'सायन',
    'सायबान',
    'सायम',
    'साया',
    'सायादार',
    'सायाम',
    'सायामता',
    'सायास',
    'सायिका',
    'सायुक्त',
    'सायुज्य',
    'सार',
    'सारंग',
    'सारंगधर',
    'सारंगपाणि',
    'सारंगा',
    'सारंगिक',
    'सारंगिका',
    'सारंगिया',
    'सारंगी',
    'सारंभ',
    'सारक',
    'सारकर्ता',
    'सारखा',
    'सारखी',
    'सारगर्भित',
    'सारगार्भित',
    'सारग्राही',
    'सारग्राह्यता',
    'सारजेंट',
    'सारजैंट',
    'सारण',
    'सारणी',
    'सारणीबद्ध',
    'सारणीयंत्र',
    'सारतः',
    'सारथि',
    'सारथी',
    'सारथ्य',
    'सारना',
    'सारपूर्ण',
    'सारपूर्णता',
    'सारबान',
    'सारभंग',
    'सारमिति',
    'सारमेय',
    'साररहित',
    'सारलेख',
    'सारल्य',
    'सारवण',
    'सारवती',
    'सारवस्तु',
    'सारवान',
    'सारशन',
    'सारस',
    'सारस्वत',
    'सारस्वतोत्सव',
    'सारस्वत्य',
    'सारहीन',
    'सारहीनता',
    'सारा',
    'सारांश',
    'सारांशतः',
    'सारांशन',
    'सारांशित',
    'सारापा',
    'सारार्थ',
    'सारि',
    'सारिका',
    'सारिखा',
    'सारिखी',
    'सारिणी',
    'सारी',
    'सारुप्य',
    'सारे',
    'सारेयी',
    'सार्थ',
    'सार्थक',
    'सार्थकतः',
    'सार्थकता',
    'सार्थपति',
    'सार्थवाह',
    'सार्थिक',
    'सार्द्र',
    'सार्ध',
    'सार्व',
    'सार्वकाल',
    'सार्वकालिक',
    'सार्वजनिक',
    'सार्वजनिकतः',
    'सार्वजनीन',
    'सार्वजन्य',
    'सार्वत्रिक',
    'सार्वदेशिक',
    'सार्वभौम',
    'सार्वभौमिक',
    'सार्वभौमीकरण',
    'सार्वलौकिक',
    'सार्वलौकिकता',
    'सार्ववेदिक',
    'सार्ववेद्य',
    'सार्विक',
    'साल',
    'सालंक',
    'सालंग',
    'सालई',
    'सालग',
    'सालग्राम',
    'सालता',
    'सालती',
    'सालन',
    'सालना',
    'सालनामा',
    'सालर',
    'सालवेष्ट',
    'सालहज',
    'सालहा',
    'साला',
    'सालाना',
    'सालार',
    'सालिक',
    'सालिगराम',
    'सालिम',
    'सालियाना',
    'सालिस',
    'सालिसनामा',
    'सालिसिटर',
    'साली',
    'सालू',
    'साल्य',
    'साल्वेज',
    'सावंत',
    'सावक',
    'सावकार',
    'सावकाश',
    'सावज्ञ',
    'सावत',
    'सावधनीपूर्वक',
    'सावधान',
    'सावधानतः',
    'सावधानियां',
    'सावधानी',
    'सावधानीहीनता',
    'सावधि',
    'सावधिक',
    'सावधिकता',
    'सावन',
    'सावनी',
    'सावयव',
    'सावयवता',
    'सावर',
    'सावर्णि',
    'सावशेष',
    'साविका',
    'सावित्र',
    'सावित्री',
    'साविर्ण',
    'सावीर्य',
    'सावेद',
    'सावेश',
    'साशा',
    'साश्चर्य',
    'साश्रुधी',
    'साषि',
    'साष्टांग',
    'साष्ठव',
    'सास',
    'सासथी',
    'सासनी',
    'सासरा',
    'सासह्य',
    'सासु',
    'सासूय',
    'सासेज',
    'सासैपिक',
    'सासैभद्र',
    'सास्था',
    'सास्ना',
    'सास्र',
    'साह',
    'साहँ',
    'साहचर्य',
    'साहजात्य',
    'साहन',
    'साहनी',
    'साहब',
    'साहबजादा',
    'साहबा',
    'साहबी',
    'साहबे',
    'साहस',
    'साहसकर्म',
    'साहसपूर्ण',
    'साहसपूर्वक',
    'साहसहीन',
    'साहसहीनता',
    'साहसहोना',
    'साहसिक',
    'साहसिकता',
    'साहसी',
    'साहस्र',
    'साहस्रिक',
    'साहाग',
    'साहागा',
    'साहित्य',
    'साहित्यकार',
    'साहित्यकारिता',
    'साहित्यिक',
    'साहिब',
    'साहिबाना',
    'साहिबी',
    'साहिबीयत',
    'साहिबे',
    'साहिर',
    'साहिल',
    'साही',
    'साहु',
    'साहुकार',
    'साहुकारी',
    'साहुल',
    'साहू',
    'साहूकार',
    'साहूकारा',
    'साहूकारी',
    'सािग्न',
    'साित्त्वक',
    'साज़',
    'साफ़',
    'साफ़ी',
    'सि',
    'सिंअरा',
    'सिंअरी',
    'सिंकइार्',
    'सिंकटा',
    'सिंकदरा',
    'सिंगडा',
    'सिंगडागला',
    'सिंगडाडा',
    'सिंगडार',
    'सिंगडारना',
    'सिंगडारपट्टी',
    'सिंगडारपेट्टी',
    'सिंगडारिया',
    'सिंगडाली',
    'सिंगडी',
    'सिंगडौटी',
    'सिंगर',
    'सिंगल',
    'सिंगा',
    'सिंगापुर',
    'सिंगार',
    'सिंगिग',
    'सिंघाड़ा',
    'सिंघाडा',
    'सिंचन',
    'सिंचनहीन',
    'सिंचा',
    'सिंचाई',
    'सिंचित',
    'सिंचिदउ',
    'सिंची',
    'सिंजित',
    'सिंडिकेट',
    'सिंडियासी',
    'सिंण्न',
    'सिंतबर',
    'सिंद',
    'सिंदारा',
    'सिंदूर',
    'सिंदूरदानी',
    'सिंदूरिया',
    'सिंदूरी',
    'सिंदोरा',
    'सिंध',
    'सिंधाण',
    'सिंधिया',
    'सिंधी',
    'सिंधु',
    'सिंधुक',
    'सिंधुज',
    'सिंधुजा',
    'सिंधुजात',
    'सिंधुडा',
    'सिंधुपप्प',
    'सिंधुर',
    'सिंधुराज',
    'सिंधुशायी',
    'सिंधूद्व',
    'सिंबल',
    'सिंर',
    'सिंह',
    'सिंहघोष',
    'सिंहद्वार',
    'सिंहद्वीप',
    'सिंहध्वज',
    'सिंहनाद',
    'सिंहनी',
    'सिंहल',
    'सिंहलक',
    'सिंहली',
    'सिंहाजिन',
    'सिंहावलोकक',
    'सिंहावलोकन',
    'सिंहासन',
    'सिंहासनारुढ',
    'सिंहासनारूढ़',
    'सिंहासनारोहण',
    'सिंहिका',
    'सिंहिकापुत्र',
    'सिंही',
    'सिंहोद्वता',
    'सिंहोन्नता',
    'सिक',
    'सिकटी',
    'सिकड़न',
    'सिकडी',
    'सिकत',
    'सिकता',
    'सिकतामय',
    'सिकतिल',
    'सिकत्तर',
    'सिकन्दरा',
    'सिकरम',
    'सिकरी',
    'सिकरेी',
    'सिकरो',
    'सिकली',
    'सिकलीगर',
    'सिकहर',
    'सिकाई',
    'सिकुउ',
    'सिकुडन',
    'सिकुडना',
    'सिकुड़न',
    'सिकुड़ना',
    'सिकुड़ा',
    'सिकुडा',
    'सिकुडी',
    'सिकोडना',
    'सिकोड़ना',
    'सिकोडा',
    'सिकोडी',
    'सिकोली',
    'सिक्कड',
    'सिक्का',
    'सिक्काशास्त्र',
    'सिक्किम',
    'सिक्के',
    'सिक्कों',
    'सिक्ख',
    'सिक्खी',
    'सिक्त',
    'सिक्तता',
    'सिक्ता',
    'सिक्थ',
    'सिक्य',
    'सिक्या',
    'सिक्योरिटी',
    'सिक्सर',
    'सिख',
    'सिखतड',
    'सिखतडन',
    'सिखरन',
    'सिखलाना',
    'सिखा',
    'सिखाई',
    'सिखाकर',
    'सिखाता',
    'सिखाते',
    'सिखाना',
    'सिखाने',
    'सिखापन',
    'सिखाया',
    'सिख्ष्टान',
    'सिख्ष्टी',
    'सिख्ष्टीय',
    'सिख्ष्टीयत',
    'सिगड़ी',
    'सिगनल',
    'सिगनलवाला',
    'सिगरफ',
    'सिगरफी',
    'सिगरे',
    'सिगरेट',
    'सिग्नल',
    'सिग्नेचर',
    'सिच',
    'सिजदा',
    'सिजदागाह',
    'सिज्जत',
    'सिज्जततः',
    'सिज्जतता',
    'सिज्दा',
    'सिझन',
    'सिझना',
    'सिझाई',
    'सिझाना',
    'सिझाया',
    'सिटकनी',
    'सिटकिनी',
    'सिटपिटाना',
    'सिटी',
    'सिटीज़न',
    'सिटीज़नशिप',
    'सिट्टा',
    'सिट्टी',
    'सिड़ीपन',
    'सिड़ीपना',
    'सित',
    'सितंबर',
    'सितकंठ',
    'सितकर',
    'सितकुंजर',
    'सितता',
    'सितद्रु',
    'सितधार',
    'सितपक्ष',
    'सितपुष्पी',
    'सितम',
    'सितमगर',
    'सितमज़दा',
    'सितम्बर',
    'सितराग',
    'सिता',
    'सिताँ',
    'सितांग',
    'सितांबुज',
    'सितांशु',
    'सितांशुक',
    'सितार',
    'सितारबाज़',
    'सितारा',
    'सिताराशनास',
    'सिताराशनासी',
    'सितारिया',
    'सितारे',
    'सितारों',
    'सितासित',
    'सितासिता',
    'सिति',
    'सितुही',
    'सितेतर',
    'सितोत्पल',
    'सितोपल',
    'सिदौसी',
    'सिद्घ',
    'सिद्घकाम',
    'सिद्घकारी',
    'सिद्घकार्यता',
    'सिद्घता',
    'सिद्घदोष',
    'सिद्घन',
    'सिद्घनाथ',
    'सिद्घपुरुष',
    'सिद्घरस',
    'सिद्घहस्त',
    'सिद्घहस्तता',
    'सिद्घांगना',
    'सिद्घांजन',
    'सिद्घांत',
    'सिद्घांतकार',
    'सिद्घांतकारी',
    'सिद्घांतज्ञ',
    'सिद्घांतवादिता',
    'सिद्घांतवादी',
    'सिद्घांतहीन',
    'सिद्घांतहीनता',
    'सिद्घांती',
    'सिद्घांतीय',
    'सिद्घान्त',
    'सिद्घापराध',
    'सिद्घार्थ',
    'सिद्घार्थकारी',
    'सिद्घार्थमति',
    'सिद्घासन',
    'सिद्घोदक',
    'सिद्दीक़',
    'सिद्ध',
    'सिद्धदोष',
    'सिद्धहस्त',
    'सिद्धांत',
    'सिद्धात',
    'सिद्धान्त',
    'सिद्धान्तवादी',
    'सिद्धान्ती',
    'सिद्धान्तीकरण',
    'सिद्धान्तों',
    'सिद्धार्था',
    'सिद्धि',
    'सिद्धिकर',
    'सिद्धिद',
    'सिद्धिदा',
    'सिद्धिदाता',
    'सिद्धिप्रद',
    'सिद्धिहीनता',
    'सिद्धीश्वर',
    'सिद्धीश्वरी',
    'सिद्ध्य',
    'सिद्वांत',
    'सिधाई',
    'सिधाना',
    'सिधारना',
    'सिनक',
    'सिनकाई',
    'सिनानोग',
    'सिनीवाली',
    'सिने',
    'सिनेगल',
    'सिनेमा',
    'सिनेमाघर',
    'सिनेमास्कोप',
    'सिनेमैटोग्राफ',
    'सिनेमैटोग्राफर',
    'सिनेमैटोग्राफी',
    'सिनेस्क्रीन',
    'सिनौप्टिक',
    'सिन्नकट',
    'सिन्नकटता',
    'सिन्नकर्ष',
    'सिन्नचय',
    'सिन्नध',
    'सिन्नधाता',
    'सिन्नधान',
    'सिन्नपात',
    'सिन्नबद्घ',
    'सिन्नभ',
    'सिन्नमित्त',
    'सिन्नयंता',
    'सिन्नयाजक',
    'सिन्नयुक्त',
    'सिन्नयोग',
    'सिन्नयोजन',
    'सिन्नवास',
    'सिन्नवासी',
    'सिन्नविष्ट',
    'सिन्नवेश',
    'सिन्नवेशक',
    'सिन्नवेशित',
    'सिन्नवेशी',
    'सिन्नहित',
    'सिन्योरा',
    'सिन्हा',
    'सिपर',
    'सिपरदारी',
    'सिपरा',
    'सिपहगरी',
    'सिपहसालार',
    'सिपहिया',
    'सिपाव',
    'सिपाह',
    'सिपाहगरी',
    'सिपाहियों',
    'सिपाही',
    'सिपुर्द',
    'सिपुर्दगी',
    'सिपुर्दारी',
    'सिप्पा',
    'सिप्पी',
    'सिप्रगं',
    'सिप्रा',
    'सिफत',
    'सिफर',
    'सिफरिशों',
    'सिफ़ारिश',
    'सिफारत',
    'सिफारिश',
    'सिफारिशनामा',
    'सिफारिशी',
    'सिफारिशें',
    'सिफारिशों',
    'सिमंत',
    'सिमट',
    'सिमटन',
    'सिमटना',
    'सिमटा',
    'सिमटी',
    'सिमरकदानी',
    'सिमरन',
    'सिमसिम',
    'सिमसिमाना',
    'सिमाना',
    'सिमैट्रिकल',
    'सिमैट्री',
    'सिम्मत',
    'सिम्मतता',
    'सिम्मति',
    'सिम्मलन',
    'सिम्मलित',
    'सिम्मलिततः',
    'सिम्मलितता',
    'सिम्मश्र',
    'सिम्मश्रक',
    'सिम्मश्रण',
    'सिम्मश्रित',
    'सिम्मश्रितता',
    'सिय',
    'सियना',
    'सियरा',
    'सियराई',
    'सियराता',
    'सियराती',
    'सियराना',
    'सियरी',
    'सिया',
    'सियान',
    'सियापा',
    'सियापाना',
    'सियाम',
    'सियार',
    'सियारनी',
    'सियारा',
    'सियाला',
    'सियावड',
    'सियावी',
    'सियासत',
    'सियासती',
    'सियासी',
    'सियाहा',
    'सियाही',
    'सिर',
    'सिरः',
    'सिरई',
    'सिरका',
    'सिरखप',
    'सिरजक',
    'सिरजनहार',
    'सिरजनहारा',
    'सिरजनहारी',
    'सिरजना',
    'सिरताज',
    'सिरतान',
    'सिरती',
    'सिरथन',
    'सिरदर्द',
    'सिरदार',
    'सिरनामा',
    'सिरपेंच',
    'सिरपेच',
    'सिरबंद',
    'सिरमौर',
    'सिरयुक्त',
    'सिरसा',
    'सिरसिराता',
    'सिरसिराती',
    'सिरसिराना',
    'सिरहाना',
    'सिरहीन',
    'सिरा',
    'सिराना',
    'सिराली',
    'सिरावन',
    'सिराश्रय',
    'सिरिंज',
    'सिरी',
    'सिरीस',
    'सिरे',
    'सिरों',
    'सिरोयह',
    'सिरोही',
    'सिर्फ',
    'सिर्फ़',
    'सिल',
    'सिलंडर',
    'सिलंडराकार',
    'सिलखड़ी',
    'सिलगना',
    'सिलना',
    'सिलने',
    'सिलबिल',
    'सिलबिला',
    'सिलबिलापन',
    'सिलबिली',
    'सिलवट',
    'सिलवटें',
    'सिलवटों',
    'सिलसिला',
    'सिलसिले',
    'सिलसिलेवार',
    'सिलह',
    'सिलहंबदी',
    'सिलहदार',
    'सिलहदारी',
    'सिलहारा',
    'सिलहिला',
    'सिलहिली',
    'सिला',
    'सिलाई',
    'सिलाजीत',
    'सिलाये',
    'सिलारस',
    'सिलाह',
    'सिलाहशारी',
    'सिलाहसाज',
    'सिलाहसाजी',
    'सिलाही',
    'सिलिंडर',
    'सिलिकन',
    'सिलिका',
    'सिलिकामय',
    'सिलिकेट',
    'सिलिकोन',
    'सिलिन्डर',
    'सिलियारा',
    'सिली',
    'सिलीपट',
    'सिलुएट',
    'सिले',
    'सिलेंडर',
    'सिलेंडरों',
    'सिलैबिक',
    'सिलो',
    'सिलौट',
    'सिलौटी',
    'सिल्क',
    'सिल्यासा',
    'सिल्ली',
    'सिल्वर',
    'सिल्वरवेअर',
    'सिवइँ',
    'सिवइयाँ',
    'सिवइयां',
    'सिवईयाँ',
    'सिवचिंग',
    'सिवा',
    'सिवाना',
    'सिवाय',
    'सिविक',
    'सिविल',
    'सिवीया',
    'सिवैयाँ',
    'सिश्वः',
    'सिसंहिका',
    'सिसकना',
    'सिसकार',
    'सिसकारना',
    'सिसकारी',
    'सिसकी',
    'सिसपा',
    'सिसलाई',
    'सिस्टम',
    'सिस्टम्स',
    'सिस्टर',
    'सिस्टर्न',
    'सिहर',
    'सिहरन',
    'सिहरना',
    'सिहरा',
    'सिहराना',
    'सिहरी',
    'सिहारना',
    'सिहासन',
    'सिफ़र',
    'सिफ़ारिश',
    'सिफ़ारिशी',
    'सी',
    'सींक',
    'सींका',
    'सींकिया',
    'सींकियापन',
    'सींकों',
    'सींग',
    'सींगदाना',
    'सींगदार',
    'सींगरी',
    'सींगहीन',
    'सींगा',
    'सींगिया',
    'सींगी',
    'सींचना',
    'सींचने',
    'सींझी',
    'सींतोन्नयन',
    'सींधी',
    'सीआईडी',
    'सीकड',
    'सीकर',
    'सीकरयंत्र',
    'सीकरवर्षा',
    'सीका',
    'सीकित',
    'सीक्यूरिटी',
    'सीक्रेट',
    'सीख',
    'सीखचा',
    'सीखना',
    'सीख़',
    'सीखा',
    'सीखी',
    'सीखें',
    'सीगा',
    'सीगिया',
    'सीज',
    'सीजन',
    'सीजर',
    'सीजल',
    'सीझ',
    'सीझना',
    'सीट',
    'सीटी',
    'सीटें',
    'सीठना',
    'सीठा',
    'सीठापन',
    'सीठी',
    'सीड',
    'सीडलिंग',
    'सीडी',
    'सीढ़ियां',
    'सीढ़ी',
    'सीढ़ीनुमा',
    'सीढ़ीयों',
    'सीढी',
    'सीढीदार',
    'सीतल',
    'सीतला',
    'सीता',
    'सीतागोप्ता',
    'सीताधर',
    'सीतानाथ',
    'सीताफल',
    'सीताश्रय',
    'सीतोद',
    'सीत्कार',
    'सीत्य',
    'सीद',
    'सीध',
    'सीधा',
    'सीधापन',
    'सीधी',
    'सीधे',
    'सीन',
    'सीनरियो',
    'सीनरी',
    'सीना',
    'सीनाचाक',
    'सीनाजोर',
    'सीनाजोरी',
    'सीनाबंद',
    'सीनियर',
    'सीने',
    'सीनेट',
    'सीन्योर',
    'सीन्योरा',
    'सीप',
    'सीपज',
    'सीपारा',
    'सीपी',
    'सीपीयू',
    'सीपूयू',
    'सीभन',
    'सीमंत',
    'सीमा',
    'सीमांक',
    'सीमांकक',
    'सीमांकन',
    'सीमांकित',
    'सीमांत',
    'सीमांतरता',
    'सीमांतरीय',
    'सीमांतर्गत',
    'सीमांतर्गतता',
    'सीमांतेतर',
    'सीमाएं',
    'सीमाओं',
    'सीमाकर',
    'सीमाकारी',
    'सीमातिक्रमण',
    'सीमातिक्रामक',
    'सीमातीत',
    'सीमातीतता',
    'सीमाधिप',
    'सीमान्त',
    'सीमापाल',
    'सीमाप्रान्त',
    'सीमाब',
    'सीमाबद्घ',
    'सीमाबद्घता',
    'सीमाबद्ध',
    'सीमाबद्धता',
    'सीमाभित्ति',
    'सीमायुक्त',
    'सीमायुक्तता',
    'सीमारंभ',
    'सीमारेखा',
    'सीमारोध',
    'सीमावर्ती',
    'सीमास्पर्शी',
    'सीमाहीन',
    'सीमाहीनता',
    'सीमिका',
    'सीमित',
    'सीमितता',
    'सीमिया',
    'सीमी',
    'सीमेंट',
    'सीमेंतर',
    'सीमेतर',
    'सीमैंट',
    'सीमोल्लंघक',
    'सीमोल्लंघकन',
    'सीय',
    'सीयन',
    'सीयरा',
    'सीयरी',
    'सीर',
    'सीरत',
    'सीरध्वज',
    'सीरध्वजः',
    'सीरपाणि',
    'सीरम',
    'सीरमविज्ञानी',
    'सीरवाह',
    'सीरा',
    'सीरायुध',
    'सीरियल',
    'सीरी',
    'सीरीज',
    'सील',
    'सीलन',
    'सीलनदार',
    'सीलना',
    'सीला',
    'सीलिंग',
    'सीली',
    'सीलोन',
    'सीलोनी',
    'सील्ड',
    'सीवक',
    'सीवन',
    'सीवनदार',
    'सीवनपन',
    'सीवनहार',
    'सीवनहारा',
    'सीवनहारी',
    'सीवनहीन',
    'सीवनी',
    'सीवर',
    'सीवा',
    'सीवीं',
    'सीवेज',
    'सीस',
    'सीसक',
    'सीसपत्रक',
    'सीसफूल',
    'सीसा',
    'सीसी',
    'सीसे',
    'सुँघनी',
    'सुंडैल',
    'सुंद',
    'सुंदर',
    'सुंदरकाय',
    'सुंदरता',
    'सुंदरताइर',
    'सुंदरी',
    'सुंबा',
    'सुअटी',
    'सुअन',
    'सुअना',
    'सुअनी',
    'सुअर',
    'सुअरों',
    'सुअवसर',
    'सुआ',
    'सुआसिनी',
    'सुई',
    'सुएर',
    'सुकंठ',
    'सुकंठता',
    'सुकंदक',
    'सुकतृ',
    'सुकथित',
    'सुकर',
    'सुकरता',
    'सुकर्म',
    'सुकर्मा',
    'सुकर्मी',
    'सुकल्पित',
    'सुकवित',
    'सुकांत',
    'सुकाज',
    'सुकाता',
    'सुकानी',
    'सुकाय',
    'सुकार्य',
    'सुकार्यता',
    'सुकाल',
    'सुकीर्ति',
    'सुकुमार',
    'सुकुमारता',
    'सुकुमारी',
    'सुकुर',
    'सुकुल',
    'सुकुार',
    'सुकून',
    'सुकूनत',
    'सुकूनती',
    'सुकृत',
    'सुकृतता',
    'सुकृतार्थ',
    'सुकृति',
    'सुकृती',
    'सुकृत्य',
    'सुकेशा',
    'सुकेशी',
    'सुकेसर',
    'सुकोमल',
    'सुकोमलता',
    'सुक्कान',
    'सुख',
    'सुखंकर',
    'सुखंडी',
    'सुखकंद',
    'सुखकर',
    'सुखकारी',
    'सुखता',
    'सुखतापूर्वक',
    'सुखद',
    'सुखदता',
    'सुखदपूर्ण',
    'सुखदा',
    'सुखदाता',
    'सुखदात्री',
    'सुखदायक',
    'सुखदायी',
    'सुखन',
    'सुखनफहम',
    'सुखनवर',
    'सुखनसाज',
    'सुखपर',
    'सुखपूर्वक',
    'सुखप्रद',
    'सुखप्रश्न',
    'सुखभाजन',
    'सुखभोगी',
    'सुखमन',
    'सुखमृत्यु',
    'सुखरासी',
    'सुखवंत',
    'सुखवन',
    'सुखवर्चक',
    'सुखवा',
    'सुखवाद',
    'सुखवादी',
    'सुखवास',
    'सुखसंबंधी',
    'सुखसाधन',
    'सुखा',
    'सुखांत',
    'सुखाई',
    'सुखाकर',
    'सुखान',
    'सुखाना',
    'सुखानी',
    'सुखानुभूति',
    'सुखाने',
    'सुखायन',
    'सुखाया',
    'सुखार्थी',
    'सुखाश',
    'सुखाश्रय',
    'सुखासक्त',
    'सुखासन',
    'सुखासिक्त',
    'सुखिता',
    'सुखिया',
    'सुखी',
    'सुखेच्छा',
    'सुखोत्व',
    'सुखोपभोग',
    'सुखोपभोगी',
    'सुखोष्ण',
    'सुख्यात',
    'सुख्याति',
    'सुग',
    'सुगंध',
    'सुगंधक',
    'सुगंधन',
    'सुगंधपूर्ण',
    'सुगंधवर्षा',
    'सुगंधव्य',
    'सुगंधा',
    'सुगंधार्पण',
    'सुगंधि',
    'सुगंधिक',
    'सुगंधिका',
    'सुगंधित',
    'सुगठन',
    'सुगठित',
    'सुगठितकाय',
    'सुगठितता',
    'सुगड',
    'सुगढता',
    'सुगणक',
    'सुगत',
    'सुगतायतन',
    'सुगतालय',
    'सुगति',
    'सुगतीय',
    'सुगना',
    'सुगन्ध',
    'सुगन्धि',
    'सुगन्धित',
    'सुगन्धियों',
    'सुगबुगाता',
    'सुगबुगाती',
    'सुगबुगाना',
    'सुगबुगाहट',
    'सुगभस्ति',
    'सुगम',
    'सुगमता',
    'सुगम्य',
    'सुगाध',
    'सुगाध्य',
    'सुगायक',
    'सुगीत',
    'सुगृही',
    'सुग्गा',
    'सुग्गी',
    'सुग्रहण',
    'सुग्रामी',
    'सुग्राहितापूर्वक',
    'सुग्राही',
    'सुग्राहीकरण',
    'सुग्राह्य',
    'सुग्रीव',
    'सुग्रीवा',
    'सुग्रीवेश',
    'सुघट',
    'सुघटय',
    'सुघटित',
    'सुघट्टन',
    'सुघट्टित',
    'सुघड',
    'सुघडता',
    'सुघर',
    'सुघरई',
    'सुघोश',
    'सुचना',
    'सुचरित्र',
    'सुचाना',
    'सुचारु',
    'सुचारुतः',
    'सुचालन',
    'सुचालित',
    'सुचिंतन',
    'सुचिंता',
    'सुचिंतित',
    'सुचित',
    'सुचिर',
    'सुचुटी',
    'सुचेता',
    'सुचेलक',
    'सुच्छन्ना',
    'सुच्छाय',
    'सुजंध',
    'सुजन',
    'सुजनता',
    'सुजना',
    'सुजनी',
    'सुजल',
    'सुजला',
    'सुजागर',
    'सुजात',
    'सुजाता',
    'सुजाति',
    'सुजातीय',
    'सुजान',
    'सुजित',
    'सुजीविक',
    'सुजीविता',
    'सुज्ञात',
    'सुज्ञान',
    'सुज्ञानी',
    'सुझवपूर्ण',
    'सुझाना',
    'सुझाव',
    'सुट्टा',
    'सुठौर',
    'सुडक',
    'सुडकी',
    'सुडा',
    'सुडाल',
    'सुडौल',
    'सुडौलता',
    'सुत',
    'सुतंग',
    'सुतंत्रि',
    'सुतडा',
    'सुतता',
    'सुतदा',
    'सुतनु',
    'सुतनुज',
    'सुतपा',
    'सुतर',
    'सुतरण',
    'सुतरां',
    'सुतर्क',
    'सुतर्कित',
    'सुतल',
    'सुतली',
    'सुतहर',
    'सुतही',
    'सुता',
    'सुतात्ज',
    'सुतादान',
    'सुतार',
    'सुतारा',
    'सुतारी',
    'सुतार्य',
    'सुतिनी',
    'सुती',
    'सुतीर्थ',
    'सुतून',
    'सुतूर',
    'सुतेजा',
    'सुतोष',
    'सुत्त',
    'सुत्थन',
    'सुत्र',
    'सुत्रामा',
    'सुथना',
    'सुथनिया',
    'सुथनी',
    'सुथरा',
    'सुथरापन',
    'सुथरी',
    'सुथरेपन',
    'सुथरेशाह',
    'सुदंभ',
    'सुदंर',
    'सुदढ़',
    'सुदम',
    'सुदर्श',
    'सुदर्शन',
    'सुदर्शनता',
    'सुदर्शनधारी',
    'सुदर्शना',
    'सुदर्शनी',
    'सुदशा',
    'सुदांत',
    'सुदामा',
    'सुदाय',
    'सुदारु',
    'सुदिन',
    'सुदी',
    'सुदीति',
    'सुदीप',
    'सुदीप्त',
    'सुदीप्ति',
    'सुदीर्घ',
    'सुदीर्घकालिक',
    'सुदीर्घनकालीन',
    'सुदुससह',
    'सुदूर',
    'सुदूरता',
    'सुदूरस्थ',
    'सुदृढ',
    'सुदृढता',
    'सुदृढ़',
    'सुदृढ़ता',
    'सुदृढ़ीकरण',
    'सुदृश्य',
    'सुदृश्यता',
    'सुदृष्टि',
    'सुदेव',
    'सुदेवय',
    'सुदेशिक',
    'सुदेष्णा',
    'सुदेह',
    'सुदैव',
    'सुदौसी',
    'सुद्दा',
    'सुध',
    'सुधन',
    'सुधन्वा',
    'सुधबुध',
    'सुधबुधयुक्ततः',
    'सुधबुधहीन',
    'सुधबुधहीनता',
    'सुधरता',
    'सुधरती',
    'सुधरना',
    'सुधरा',
    'सुधराई',
    'सुधरी',
    'सुधर्म',
    'सुधर्मा',
    'सुधर्मी',
    'सुधा',
    'सुधांशु',
    'सुधांशुभ',
    'सुधाकंठ',
    'सुधाकर',
    'सुधाकार',
    'सुधाता',
    'सुधातु',
    'सुधाधामा',
    'सुधानिधि',
    'सुधापति',
    'सुधामा',
    'सुधार',
    'सुधारक',
    'सुधारण',
    'सुधारना',
    'सुधारने',
    'सुधारवाद',
    'सुधारवादी',
    'सुधारविरोधी',
    'सुधारागार',
    'सुधारात्मक',
    'सुधारालय',
    'सुधार्य',
    'सुधाहर्ता',
    'सुधि',
    'सुधी',
    'सुधीर',
    'सुधुर',
    'सुनंद',
    'सुनंदक',
    'सुनंदन',
    'सुनंदा',
    'सुनकर',
    'सुनगुन',
    'सुनत',
    'सुनति',
    'सुनना',
    'सुनने',
    'सुनम्य',
    'सुनम्यता',
    'सुनयन',
    'सुनयना',
    'सुनवाई',
    'सुनसन',
    'सुनसान',
    'सुनसुनी',
    'सुनहरा',
    'सुनहरी',
    'सुनहरे',
    'सुनहला',
    'सुनहलापन',
    'सुनहली',
    'सुना',
    'सुनाइर',
    'सुनाई',
    'सुनागर',
    'सुनागरता',
    'सुनाद',
    'सुनादक',
    'सुनादी',
    'सुनाना',
    'सुनाभ',
    'सुनाभक',
    'सुनाम',
    'सुनामा',
    'सुनाया',
    'सुनायी',
    'सुनार',
    'सुनारी',
    'सुनावनी',
    'सुनिमिर्तता',
    'सुनियत',
    'सुनियम',
    'सुनियोजित',
    'सुनियोजितता',
    'सुनिर्धारण',
    'सुनिर्धारित',
    'सुनिर्माणनीय',
    'सुनिर्माणनीयता',
    'सुनिवाय्र',
    'सुनिवार्य',
    'सुनिश्चत',
    'सुनिश्चय',
    'सुनिश्चयन',
    'सुनिश्चल',
    'सुनिश्चित',
    'सुनी',
    'सुनीच',
    'सुनीत',
    'सुनीति',
    'सुनु',
    'सुने',
    'सुन्दर',
    'सुन्दरता',
    'सुन्दरी',
    'सुन्न',
    'सुन्नकरण',
    'सुन्नत',
    'सुन्नता',
    'सुन्ना',
    'सुन्नी',
    'सुपंथ',
    'सुपक्व',
    'सुपठनीय',
    'सुपठय',
    'सुपढ',
    'सुपथ',
    'सुपथ्य',
    'सुपथ्याहार',
    'सुपर',
    'सुपरटैक्स',
    'सुपरमैन',
    'सुपरवाइज़र',
    'सुपरविजन',
    'सुपरसोनिक',
    'सुपरामर्श',
    'सुपरार्मा',
    'सुपरिचित',
    'सुपरिनटैंडैंट',
    'सुपरिपाचित',
    'सुपरिपाति',
    'सुपरिभाषित',
    'सुपरिष्कृत',
    'सुपरीक्षित',
    'सुपर्ण',
    'सुपर्णक',
    'सुपर्णा',
    'सुपर्णी',
    'सुपर्णेय',
    'सुपर्वी',
    'सुपस्थान',
    'सुपाक',
    'सुपाच्य',
    'सुपाठय',
    'सुपाठित',
    'सुपाठ्य',
    'सुपाठ्यता',
    'सुपात्र',
    'सुपात्रता',
    'सुपार',
    'सुपारग',
    'सुपारगय',
    'सुपारग्म्य',
    'सुपारी',
    'सुपार्य',
    'सुपार्यता',
    'सुपाश्र्व',
    'सुपासी',
    'सुपीरियर',
    'सुपीरियरिटी',
    'सुपुत्र',
    'सुपुर्द',
    'सुपुर्दगी',
    'सुपुर्दी',
    'सुपुष्ट',
    'सुपुष्पक',
    'सुपुष्पिका',
    'सुपेदी',
    'सुपोषित',
    'सुप्त',
    'सुप्तता',
    'सुप्ताटक',
    'सुप्ताटन',
    'सुप्ति',
    'सुप्रकट',
    'सुप्रकाशित',
    'सुप्रकाशितता',
    'सुप्रचालन',
    'सुप्रचालनिक',
    'सुप्रज्ञ',
    'सुप्रतर',
    'सुप्रतिज्ञ',
    'सुप्रतिज्ञा',
    'सुप्रतिष्ठ',
    'सुप्रतिष्ठा',
    'सुप्रतिष्ठित',
    'सुप्रतीक',
    'सुप्रबंध',
    'सुप्रबंधित',
    'सुप्रभ',
    'सुप्रभा',
    'सुप्रभात',
    'सुप्रमेय',
    'सुप्रयोग',
    'सुप्रवाही',
    'सुप्रवीण',
    'सुप्रशस्त',
    'सुप्रसन्न',
    'सुप्रसाद',
    'सुप्रसिद्घ',
    'सुप्रसिद्धि',
    'सुप्राणी',
    'सुप्राप्य',
    'सुप्राप्यता',
    'सुप्रीम',
    'सुफ',
    'सुफल',
    'सुफलता',
    'सुफला',
    'सुबक',
    'सुबकना',
    'सुबकी',
    'सुबद्ध',
    'सुबल',
    'सुबह',
    'सुबहान',
    'सुबहे',
    'सुबांधव',
    'सुबाहु',
    'सुबाहू',
    'सुबी',
    'सुबुक',
    'सुबुकदस्ती',
    'सुबुकी',
    'सुबू',
    'सुबूत',
    'सुबूही',
    'सुबोध',
    'सुबोध्य',
    'सुबोध्यता',
    'सुब्रह्मण्य',
    'सुब्हानी',
    'सुभक्ष्य',
    'सुभग',
    'सुभगा',
    'सुभज्य',
    'सुभट',
    'सुभद्र',
    'सुभद्रा',
    'सुभद्रेा',
    'सुभाग',
    'सुभागा',
    'सुभागिनी',
    'सुभागी',
    'सुभान',
    'सुभाष',
    'सुभाषिणी',
    'सुभाषित',
    'सुभाषी',
    'सुभिन्न',
    'सुभीता',
    'सुभुज',
    'सुभुजा',
    'सुभूमि',
    'सुभेद्य',
    'सुमंगल',
    'सुमंगला',
    'सुमंत्र',
    'सुमंत्रणा',
    'सुमंद',
    'सुमति',
    'सुमधुर',
    'सुमध्यमा',
    'सुमन',
    'सुमनचाप',
    'सुमनस',
    'सुमनस्क',
    'सुमना',
    'सुमरन',
    'सुमरना',
    'सुमरनी',
    'सुमशीर्ष',
    'सुमानस',
    'सुमान्य',
    'सुमार्ग',
    'सुमार्गी',
    'सुमालिनी',
    'सुमाली',
    'सुमित्र',
    'सुमित्रा',
    'सुमिरन',
    'सुमुख',
    'सुमूल',
    'सुमेधा',
    'सुमेर',
    'सुमेरु',
    'सुमेरू',
    'सुमेल',
    'सुयंत्रित',
    'सुयत',
    'सुयशा',
    'सुयाजित',
    'सुयुद्घ',
    'सुयोग',
    'सुयोग्य',
    'सुयोजन',
    'सुर',
    'सुरंग',
    'सुरंगभेदी',
    'सुरंगमार्जक',
    'सुरंगा',
    'सुरंगें',
    'सुरकृत',
    'सुरकेतु',
    'सुरक्त',
    'सुरक्षण',
    'सुरक्षा',
    'सुरक्षात्मक',
    'सुरक्षापन',
    'सुरक्षापिन',
    'सुरक्षार्थ',
    'सुरक्षित',
    'सुरक्षिततः',
    'सुरक्षितता',
    'सुरक्षितस्थान',
    'सुरखाब',
    'सुरखाव',
    'सुरखी',
    'सुरचित',
    'सुरजा',
    'सुरत',
    'सुरतरंगिनी',
    'सुरतरंगी',
    'सुरता',
    'सुरतालमय',
    'सुरती',
    'सुरत्राता',
    'सुरत्व',
    'सुरनाथ',
    'सुरनारी',
    'सुरपति',
    'सुरपुरी',
    'सुरप्रिया',
    'सुरबाला',
    'सुरभानु',
    'सुरभि',
    'सुरभित',
    'सुरभी',
    'सुरमइर',
    'सुरमा',
    'सुरमाआलूद',
    'सुरमादानी',
    'सुरमी',
    'सुरमेदानी',
    'सुरम्य',
    'सुरम्यता',
    'सुररखितशव',
    'सुरर्षभ',
    'सुरर्षि',
    'सुरल',
    'सुरवाल',
    'सुरश',
    'सुरशत्रुघ्न',
    'सुरश्रेष्ठ',
    'सुरश्रेष्ठा',
    'सुरस',
    'सुरसंगति',
    'सुरसखा',
    'सुरसता',
    'सुरसरि',
    'सुरसरी',
    'सुरसा',
    'सुरसुराना',
    'सुरसुराहट',
    'सुरसुरी',
    'सुरसेना',
    'सुरस्तर',
    'सुरस्वामी',
    'सुरही',
    'सुरहीन',
    'सुरहीनता',
    'सुरा',
    'सुरांगना',
    'सुराकर',
    'सुराख',
    'सुराखदार',
    'सुराग',
    'सुरागरसानी',
    'सुरागसौ',
    'सुराग़',
    'सुरागाय',
    'सुरागार',
    'सुरागी',
    'सुराचार्य',
    'सुराज',
    'सुराजकता',
    'सुराजय',
    'सुराजी',
    'सुराजीवी',
    'सुरातयाग',
    'सुराधिप',
    'सुराध्यक्ष',
    'सुरानीक',
    'सुरापात्र',
    'सुरापान',
    'सुराय',
    'सुरायुध',
    'सुरारि',
    'सुरारिहा',
    'सुरार्ह',
    'सुरावट',
    'सुरावती',
    'सुराष्ट्रजा',
    'सुरासुर',
    'सुराही',
    'सुरीय',
    'सुरीला',
    'सुरीलापन',
    'सुरीली',
    'सुरुचि',
    'सुरुचिकारी',
    'सुरुचिपूर्ण',
    'सुरुचिपूर्णता',
    'सुरुचिसम्पन्न',
    'सुरुचिहीन',
    'सुरुप',
    'सुरुपता',
    'सुरुपा',
    'सुरुर',
    'सुरूचिमन्यक',
    'सुरूचिमन्यता',
    'सुरूचिहीन',
    'सुरूचिहीनता',
    'सुरेंद्र',
    'सुरेंद्रजित',
    'सुरेंद्रलुप्ति',
    'सुरेखा',
    'सुरेज्य',
    'सुरेज्या',
    'सुरेतर',
    'सुरेभ',
    'सुरेरी',
    'सुरेश',
    'सुरेश्वर',
    'सुरेश्वरी',
    'सुरेष्टक',
    'सुरैतिन',
    'सुरैया',
    'सुरों',
    'सुरोत्तम',
    'सुरोद',
    'सुरोप',
    'सुर्ख',
    'सुर्खरु',
    'सुर्खी',
    'सुर्रा',
    'सुर्रेबाज',
    'सुलक्कड',
    'सुलक्षण',
    'सुलक्षणता',
    'सुलक्षणयुक्त',
    'सुलक्षणा',
    'सुलक्षणी',
    'सुलक्ष्मा',
    'सुलक्ष्मी',
    'सुलगता',
    'सुलगती',
    'सुलगना',
    'सुलगाई',
    'सुलगाना',
    'सुलगाने',
    'सुलच्छन',
    'सुलच्छनी',
    'सुलझना',
    'सुलझाना',
    'सुलझाने',
    'सुलझापन',
    'सुलझाव',
    'सुलटना',
    'सुलटा',
    'सुलटाना',
    'सुलटी',
    'सुलतान',
    'सुलताना',
    'सुलतानी',
    'सुलफा',
    'सुलफेबाज',
    'सुलब्ध',
    'सुलभ',
    'सुलभतः',
    'सुलभता',
    'सुलभतापूर्वक',
    'सुलभा',
    'सुलभ्य',
    'सुलभ्यता',
    'सुललाभ',
    'सुललित',
    'सुलह',
    'सुलहनामा',
    'सुला',
    'सुलाकर',
    'सुलाचेना',
    'सुलाना',
    'सुलाने',
    'सुलाभ',
    'सुलाया',
    'सुलिखित',
    'सुलिपि',
    'सुलूक',
    'सुलेख',
    'सुलेखक',
    'सुलेखकार',
    'सुलेखन',
    'सुलेममान',
    'सुलेमान',
    'सुलेमानी',
    'सुलोचन',
    'सुलोचना',
    'सुलोति',
    'सुलोम',
    'सुलोमा',
    'सुलोहक',
    'सुल्स',
    'सुवंश',
    'सुवक्ता',
    'सुवचन',
    'सुवणक',
    'सुवदन',
    'सुवदना',
    'सुवया',
    'सुवरण',
    'सुवर्चल',
    'सुवर्चला',
    'सुवर्चस',
    'सुवर्चा',
    'सुवर्ची',
    'सुवर्ण',
    'सुवर्णघ्न',
    'सुवर्णाकर',
    'सुवर्णाख्य',
    'सुवर्णीय',
    'सुवर्तित',
    'सुवर्तुल',
    'सुवाकय',
    'सुवाच्य',
    'सुवार्य',
    'सुवास',
    'सुवासक',
    'सुवासन',
    'सुवासव्य',
    'सुवासा',
    'सुवासित',
    'सुवासिनी',
    'सुवाह्य',
    'सुविकसित',
    'सुविक्रम',
    'सुविख्यात',
    'सुविचार',
    'सुविचारण',
    'सुविचारित',
    'सुविज्ञ',
    'सुविज्ञता',
    'सुवित',
    'सुवितल',
    'सुवित्त',
    'सुवित्तता',
    'सुविदित',
    'सुविदितता',
    'सुविधा',
    'सुविधाएं',
    'सुविधाजनक',
    'सुविधान',
    'सुविधानुसार',
    'सुविधापूण्र',
    'सुविधाप्राप्त',
    'सुविधाभार',
    'सुविधायुक्त',
    'सुविधायें',
    'सुविधावादिता',
    'सुविधावाले',
    'सुविधाविहीन',
    'सुविधाहीन',
    'सुविधित',
    'सुविनीत',
    'सुविन्यस्त',
    'सुविभीषण',
    'सुविल्लज',
    'सुविशाल',
    'सुविस्तृत',
    'सुविहित',
    'सुवीर',
    'सुवीरक',
    'सुवीर्य',
    'सुवृद्घ',
    'सुवेग',
    'सुवेण',
    'सुवेशप्रियता',
    'सुवेशित',
    'सुवेशिता',
    'सुवेशिनी',
    'सुवेशी',
    'सुव्क्त',
    'सुव्यक्त',
    'सुव्यवसथा',
    'सुव्यवस्था',
    'सुव्यवस्थित',
    'सुव्यवस्थितता',
    'सुव्याहृत',
    'सुव्यूत',
    'सुव्यूतता',
    'सुव्रत',
    'सुव्रता',
    'सुशंसी',
    'सुशब्द',
    'सुशब्दता',
    'सुशरीर',
    'सुशांत',
    'सुशाक',
    'सुशाका',
    'सुशासन',
    'सुशासित',
    'सुशासितता',
    'सुशिक्षित',
    'सुशिष्ट',
    'सुशीत',
    'सुशीतल',
    'सुशीम',
    'सुशील',
    'सुशीलता',
    'सुशीला',
    'सुशुम्णा',
    'सुशोभन',
    'सुशोभित',
    'सुश्रव्य',
    'सुश्रव्यता',
    'सुश्राव्य',
    'सुश्री',
    'सुश्रीक',
    'सुश्रुत',
    'सुश्रूषा',
    'सुश्लोक',
    'सुषम',
    'सुषमना',
    'सुषमा',
    'सुषि',
    'सुषिर',
    'सुषीम',
    'सुषुपन',
    'सुषुप्त',
    'सुषुप्ति',
    'सुषुप्सा',
    'सुषेण',
    'सुष्ट',
    'सुष्ठुता',
    'सुसंग',
    'सुसंगत',
    'सुसंगतता',
    'सुसंगति',
    'सुसंतुलित',
    'सुसंभावना',
    'सुसंभृत',
    'सुसंयोग',
    'सुसंयोगता',
    'सुसंस्कृत',
    'सुसकी',
    'सुसज्जित',
    'सुसत्त्व',
    'सुसथ',
    'सुसपष्ट',
    'सुसभेय',
    'सुसभ्य',
    'सुसम',
    'सुसमय',
    'सुसमाचार',
    'सुसम्पन्न',
    'सुसरी',
    'सुसवर',
    'सुसवरता',
    'सुसवागर्त',
    'सुसवादपूर्णता',
    'सुसवादिता',
    'सुसहाय',
    'सुसाध्य',
    'सुसाध्यतः',
    'सुसाध्यता',
    'सुसिज्जत',
    'सुसिथतता',
    'सुसिद्घ',
    'सुसिद्धि',
    'सुसुप्ति',
    'सुसुविधा',
    'सुसूक्ष्म',
    'सुसौभग',
    'सुस्त',
    'सुस्ताते',
    'सुस्ताना',
    'सुस्ताने',
    'सुस्ताया',
    'सुस्ती',
    'सुस्तुति',
    'सुस्थापित',
    'सुस्थित',
    'सुस्थिर',
    'सुस्पर्श',
    'सुस्पष्ट',
    'सुस्पष्टता',
    'सुस्मरण',
    'सुस्मृति',
    'सुस्वन',
    'सुस्वभाव',
    'सुस्वभावी',
    'सुस्वर',
    'सुस्वादता',
    'सुस्वादु',
    'सुस्वादुता',
    'सुस्वादुभोज्य',
    'सुहँगा',
    'सुहँगी',
    'सुहंग',
    'सुहबत',
    'सुहस्त',
    'सुहाग',
    'सुहागन',
    'सुहागरात',
    'सुहागा',
    'सुहागिन',
    'सुहागिनी',
    'सुहागी',
    'सुहाता',
    'सुहाती',
    'सुहाना',
    'सुहानापन',
    'सुहानी',
    'सुहावना',
    'सुहावनी',
    'सुहास',
    'सुहासिनी',
    'सुहासी',
    'सुहृद',
    'सुहृदजन',
    'सुहेला',
    'सुहेली',
    'सुहोता',
    'सुहोत्र',
    'सुांडी',
    'सूँघ',
    'सूँघना',
    'सूँघा',
    'सूँघी',
    'सूँड',
    'सूँडदार',
    'सूँडिया',
    'सूँडी',
    'सूँस',
    'सूंघ',
    'सूंघकर',
    'सूंघना',
    'सूंघने',
    'सूंजयी',
    'सूंड',
    'सूंडी',
    'सूअर',
    'सूअरनी',
    'सूअरी',
    'सूआ',
    'सूई',
    'सूए',
    'सूक',
    'सूकर',
    'सूकरी',
    'सूक्त',
    'सूक्तदर्शी',
    'सूक्तद्रष्टा',
    'सूक्ता',
    'सूक्ति',
    'सूक्तिकार',
    'सूक्तिपूर्ण',
    'सूक्तिपूर्णता',
    'सूक्तिबद्ध',
    'सूक्तोक्ति',
    'सूक्षर्माशतः',
    'सूक्ष्म',
    'सूक्ष्मकाय',
    'सूक्ष्मकायता',
    'सूक्ष्मगृहिता',
    'सूक्ष्मछिद्र',
    'सूक्ष्मजीव',
    'सूक्ष्मजीवविज्ञान',
    'सूक्ष्मजीवाश्म',
    'सूक्ष्मतः',
    'सूक्ष्मतक्षणी',
    'सूक्ष्मतम',
    'सूक्ष्मता',
    'सूक्ष्मदर्शिकी',
    'सूक्ष्मदर्शिता',
    'सूक्ष्मदर्शी',
    'सूक्ष्मदर्शीय',
    'सूक्ष्मदर्शीयता',
    'सूक्ष्मदेही',
    'सूक्ष्मवास',
    'सूक्ष्मा',
    'सूक्ष्मांश',
    'सूक्ष्मांशीय',
    'सूक्ष्माकार',
    'सूक्ष्माकारता',
    'सूक्ष्माणु',
    'सूक्ष्माणू',
    'सूक्ष्मात्मा',
    'सूक्ष्मीकरण',
    'सूख',
    'सूखना',
    'सूखपूर्वक',
    'सूखा',
    'सूखाग्रस्त',
    'सूखानासी',
    'सूखान्त',
    'सूखापन',
    'सूखी',
    'सूखे',
    'सूचक',
    'सूचकसंख्या',
    'सूचकांक',
    'सूचकांकी',
    'सूचन',
    'सूचना',
    'सूचनाएं',
    'सूचनाओं',
    'सूचनाकारी',
    'सूचनादाता',
    'सूचनापत्र',
    'सूचनार्थ',
    'सूचालनीय',
    'सूचि',
    'सूचिक',
    'सूचिका',
    'सूचित',
    'सूचियां',
    'सूचियों',
    'सूचिरदन',
    'सूची',
    'सूचीकरण',
    'सूचीकार',
    'सूचीगत',
    'सूचीपत्र',
    'सूचीबद्ध',
    'सूचीयन',
    'सूचीवान',
    'सूच्य',
    'सूच्यग्र',
    'सूच्याकार',
    'सूजन',
    'सूजा',
    'सूजी',
    'सूजीवितापूर्ण',
    'सूझ',
    'सूझना',
    'सूझपूर्ण',
    'सूझबूझ',
    'सूझबूझदार',
    'सूट',
    'सूटकेस',
    'सूटा',
    'सूटिंग',
    'सूत',
    'सूतः',
    'सूतक',
    'सूतकहीनता',
    'सूतना',
    'सूतपुत्र',
    'सूतरी',
    'सूता',
    'सूति',
    'सूतिका',
    'सूतिगृह',
    'सूती',
    'सूतीवस्त्र',
    'सूत्कार',
    'सूत्या',
    'सूत्र',
    'सूत्रक',
    'सूत्रकंठ',
    'सूत्रकार',
    'सूत्रकृमि',
    'सूत्रकृमिक',
    'सूत्रकोण',
    'सूत्रकोश',
    'सूत्रगत',
    'सूत्रण',
    'सूत्रधर',
    'सूत्रधार',
    'सूत्रपात',
    'सूत्रपुष्प',
    'सूत्रबद्घ',
    'सूत्रबद्घन',
    'सूत्रांत',
    'सूत्रात्मक',
    'सूत्रात्मा',
    'सूत्राली',
    'सूत्रित',
    'सूत्री',
    'सूत्रीकरण',
    'सूत्रीय',
    'सूत्रों',
    'सूद',
    'सूदक',
    'सूदखोर',
    'सूदखोरी',
    'सूदन',
    'सूदना',
    'सूदनी',
    'सूदी',
    'सूधा',
    'सूधी',
    'सून',
    'सूना',
    'सूनापन',
    'सूनु',
    'सूनू',
    'सूनृत',
    'सूनृता',
    'सूप',
    'सूपकार',
    'सूपतीर्थ',
    'सूफ',
    'सूफिया',
    'सूफियाना',
    'सूफियानी',
    'सूफी',
    'सूफीवाद',
    'सूबदार',
    'सूबा',
    'सूबाई',
    'सूबाए',
    'सूबेदार',
    'सूम',
    'सूमडा',
    'सूमडी',
    'सूय',
    'सूयोर्द',
    'सूयोर्दय',
    'सूयौर्दय',
    'सूर',
    'सूरः',
    'सूरखदार',
    'सूरज',
    'सूरजः',
    'सूरजमुखी',
    'सूरण',
    'सूरत',
    'सूरतगर',
    'सूरता',
    'सूरते',
    'सूरदास',
    'सूरन',
    'सूरमा',
    'सूरमापन',
    'सूरसुत',
    'सूरसुता',
    'सूरसूत',
    'सूरा',
    'सूराख',
    'सूराख़',
    'सूरि',
    'सूर्पनखा',
    'सूर्मी',
    'सूर्य',
    'सूर्यकांत',
    'सूर्यकात',
    'सूर्यकान्त',
    'सूर्यकाल',
    'सूर्यक्रान्ति',
    'सूर्यज',
    'सूर्यजा',
    'सूर्यनाभ',
    'सूर्यप्रभ',
    'सूर्यप्रिया',
    'सूर्यभानु',
    'सूर्यमुखी',
    'सूर्यवार',
    'सूर्यविमुख',
    'सूर्यसुत',
    'सूर्यस्थान',
    'सूर्या',
    'सूर्याक्ष',
    'सूर्याणी',
    'सूर्यात',
    'सूर्यातप',
    'सूर्याति',
    'सूर्यानुवर्तन',
    'सूर्यानुवर्ती',
    'सूर्यापवर्ती',
    'सूर्यापाय',
    'सूर्याभास',
    'सूर्याभिमुख',
    'सूर्यावर्त',
    'सूर्याशु',
    'सूर्यासत',
    'सूर्यास्त',
    'सूर्यीय',
    'सूर्येदु',
    'सूर्योच्य',
    'सूर्योदय',
    'सूल',
    'सूलता',
    'सूलती',
    'सूलना',
    'सूलल्लाह',
    'सूली',
    'सूवनिर',
    'सूस',
    'सूसमार',
    'सूसू',
    'सूह',
    'सूहा',
    'सूहिकता',
    'सृक्का',
    'सृक्किणी',
    'सृखंला',
    'सृग',
    'सृज',
    'सृजक',
    'सृजन',
    'सृजनशील',
    'सृजनशीलता',
    'सृजनहार',
    'सृजनहीन',
    'सृजनहीनता',
    'सृजनात्मक',
    'सृजित',
    'सृजेता',
    'सृज्य',
    'सृत',
    'सृति',
    'सृत्वर',
    'सृत्वरी',
    'सृत्वा',
    'सृदाकु',
    'सृदृढ',
    'सृप्',
    'सृप्त',
    'सृश्ट',
    'सृश्टय',
    'सृष्ट',
    'सृष्टा',
    'सृष्टि',
    'सृष्टिकर्ता',
    'सृष्टिकाल',
    'सृष्टियात्मक',
    'सृष्टीय',
    'से',
    'सेंक',
    'सेंकना',
    'सेंगरा',
    'सेंट',
    'सेंटर',
    'सेंट्रल',
    'सेंड',
    'सेंत',
    'सेंतना',
    'सेंतमेंत',
    'सेंती',
    'सेंतुबंध',
    'सेंथी',
    'सेंदुरिया',
    'सेंद्रिय',
    'सेंध',
    'सेंधना',
    'सेंधमार',
    'सेंधमारी',
    'सेंधारी',
    'सेंधिया',
    'सेंधी',
    'सेंल्सगर्ल',
    'सेंवई',
    'सेंस',
    'सेंसर',
    'सेंसरी',
    'सेंह',
    'सेंहा',
    'सेंहुआ',
    'सेक',
    'सेकंड',
    'सेकटाई',
    'सेकडा',
    'सेकना',
    'सेकेण्ड',
    'सेक्टर',
    'सेक्त्र',
    'सेक्रेरिए',
    'सेक्षन',
    'सेक्स',
    'सेच',
    'सेचक',
    'सेचन',
    'सेचनक',
    'सेचनी',
    'सेचले',
    'सेज',
    'सेजदह',
    'सेजना',
    'सेट',
    'सेटना',
    'सेटर',
    'सेटलमेंट',
    'सेटेलाईट',
    'सेटैलाईट',
    'सेठ',
    'सेठानी',
    'सेठिया',
    'सेठी',
    'सेढखाना',
    'सेतु',
    'सेतुक',
    'सेतुद्र',
    'सेतुबंध',
    'सेत्र',
    'सेन',
    'सेनजित',
    'सेनप',
    'सेनहा',
    'सेना',
    'सेनाः',
    'सेनाएं',
    'सेनाओं',
    'सेनाग्र',
    'सेनाजीवी',
    'सेनाधिकारी',
    'सेनाधिनाथ',
    'सेनाधिप',
    'सेनाधिपति',
    'सेनाधीश',
    'सेनाध्यक्ष',
    'सेनानी',
    'सेनापति',
    'सेनापतित्व',
    'सेनामुख',
    'सेनावास',
    'सेनी',
    'सेन्ट',
    'सेन्ट्रल',
    'सेन्सर',
    'सेप्टिक',
    'सेफ',
    'सेफ्टी',
    'सेब',
    'सेबल',
    'सेबीय',
    'सेम',
    'सेमई',
    'सेयम',
    'सेयाह',
    'सेर',
    'सेरः',
    'सेरा',
    'सेराई',
    'सेराना',
    'सेरी',
    'सेरु',
    'सेरुआ',
    'सेल',
    'सेलर',
    'सेला',
    'सेलाबी',
    'सेली',
    'सेल्ला',
    'सेल्स',
    'सेल्सगर्ल',
    'सेल्समैन',
    'सेवंई',
    'सेवइयाँ',
    'सेवक',
    'सेवकाई',
    'सेवतंती',
    'सेवती',
    'सेवन',
    'सेवया',
    'सेवर',
    'सेवरा',
    'सेवा',
    'सेवाई',
    'सेवाएं',
    'सेवाओं',
    'सेवाकाल',
    'सेवादार',
    'सेवादारनी',
    'सेवानिवृति',
    'सेवानिवृत्त',
    'सेवानिवृत्ति',
    'सेवान्त',
    'सेवामुक्त',
    'सेवारत',
    'सेवार्थ',
    'सेवार्थी',
    'सेवावधि',
    'सेवावृत्त',
    'सेवाश्रम',
    'सेविका',
    'सेविग्स',
    'सेवित',
    'सेविता',
    'सेवी',
    'सेव्य',
    'सेशन',
    'सेशे',
    'सेश्र्य',
    'सेह',
    'सेहचंद',
    'सेहत',
    'सेहतनामा',
    'सेहतमंद',
    'सेहतमंदी',
    'सेहतयाबी',
    'सेहरा',
    'सेही',
    'सैंक',
    'सैंकज',
    'सैंचुरी',
    'सैंट',
    'सैंटर',
    'सैंटिग्राम',
    'सैंटिग्रेड',
    'सैंटी',
    'सैंटीऐर',
    'सैंटीमीटर',
    'सैंट्रल',
    'सैंट्रिपीटल',
    'सैंट्रिपीटलिटी',
    'सैंट्रिफ्यूगल',
    'सैंट्रिफ्यूगैलिटी',
    'सैंठ',
    'सैंडल',
    'सैंडविच',
    'सैंधव',
    'सैंधवी',
    'सैंयाँ',
    'सैंसर',
    'सैंसरशिप',
    'सैंसस',
    'सैंहथी',
    'सैंहल',
    'सैंहली',
    'सैउल',
    'सैउसेन',
    'सैकंड',
    'सैकंडिग',
    'सैकजगर',
    'सैकडा',
    'सैकडे',
    'सैकत',
    'सैकतिक',
    'सैकती',
    'सैकरीन',
    'सैकल',
    'सैक्चुअरी',
    'सैक्चुरी',
    'सैक्टर',
    'सैक्रामैट',
    'सैक्रेटरी',
    'सैक्रेरिए',
    'सैक्शन',
    'सैक्स',
    'सैक्सैं',
    'सैचल',
    'सैचुरेशन',
    'सैचेट',
    'सैट',
    'सैटलाइट',
    'सैटी',
    'सैडिज्म',
    'सैडिस्',
    'सैद्घांतिक',
    'सैद्घांतिकता',
    'सैद्धांतिक',
    'सैद्धान्तिकता',
    'सैद्धान्तिकी',
    'सैन',
    'सैनि',
    'सैनिक',
    'सैनिकता',
    'सैनिकीकरण',
    'सैनिकों',
    'सैनिरी',
    'सैनेटोरियम',
    'सैन्य',
    'सैन्यदल',
    'सैन्यद्रोह',
    'सैन्यपाल',
    'सैन्यवाद',
    'सैन्यवादी',
    'सैन्याघात',
    'सैन्यीकरण',
    'सैन्यीकृत',
    'सैन्यीरण',
    'सैपर्स',
    'सैप्कि',
    'सैफ़यर',
    'सैफ़ायर',
    'सैफायर',
    'सैब',
    'सैमस्र',
    'सैमिनार',
    'सैमिनारी',
    'सैमिसर्किल',
    'सैयद',
    'सैयदुश',
    'सैयाँ',
    'सैयाद',
    'सैयारा',
    'सैयाल',
    'सैयाही',
    'सैयूलरिज्म',
    'सैर',
    'सैरंधर',
    'सैरंधरी',
    'सैरंधी',
    'सैरगाह',
    'सैरडे',
    'सैरबाग',
    'सैरिक',
    'सैरिफ',
    'सैरिभ',
    'सैर्न',
    'सैल',
    'सैलग',
    'सैलर',
    'सैलस्र',
    'सैला',
    'सैलाइट',
    'सैलानी',
    'सैलाब',
    'सैलिशयस',
    'सैली',
    'सैलून',
    'सैलो',
    'सैलोफेन',
    'सैल्',
    'सैल्फ',
    'सैल्फर्स्टाटर',
    'सैल्मन',
    'सैल्यूट',
    'सैल्यूलाइड',
    'सैल्यूलोज',
    'सैल्वज़',
    'सैशन',
    'सैशे',
    'सैहपहल',
    'सैहपहलू',
    'सैहबारा',
    'सैहशंबा',
    'सैहिकये',
    'सैिल्सय',
    'सॉफ़्ट',
    'सो',
    'सों',
    'सोंचर',
    'सोंज',
    'सोंटा',
    'सोंटी',
    'सोंठ',
    'सोंनी',
    'सोंह',
    'सोंहै',
    'सोआ',
    'सोई',
    'सोएगा',
    'सोएफ्ट',
    'सोएफ्टवेयर',
    'सोएलिसीटर',
    'सोकना',
    'सोकिा',
    'सोख',
    'सोखना',
    'सोखने',
    'सोखपत्र',
    'सोख़्ता',
    'सोखा',
    'सोखाई',
    'सोखी',
    'सोख्ता',
    'सोगंध',
    'सोगवार',
    'सोगवारी',
    'सोगिनी',
    'सोच',
    'सोचकर',
    'सोचते',
    'सोचना',
    'सोचने',
    'सोचा',
    'सोचे',
    'सोचो',
    'सोज',
    'सोजनी',
    'सोजिश',
    'सोटी',
    'सोड़ा',
    'सोडा',
    'सोढव्य',
    'सोढा',
    'सोढी',
    'सोत',
    'सोता',
    'सोतिया',
    'सोतिहा',
    'सोती',
    'सोते',
    'सोत्कंठ',
    'सोत्परास',
    'सोत्सव',
    'सोत्साह',
    'सोत्सुक',
    'सोदर',
    'सोदरशय',
    'सोदरशायी',
    'सोदरा',
    'सोदरी',
    'सोदा',
    'सोदाहरण',
    'सोद्देश्य',
    'सोद्देश्यता',
    'सोद्देश्यपूर्ण',
    'सोद्देश्यवाद',
    'सोद्देश्यवादी',
    'सोद्वेग',
    'सोधना',
    'सोन',
    'सोनचंपा',
    'सोनचिरि',
    'सोनपरी',
    'सोना',
    'सोनामाखी',
    'सोनार',
    'सोनिक',
    'सोनीपत',
    'सोने',
    'सोनोमीटर',
    'सोप',
    'सोपकरण',
    'सोपचार',
    'सोपर्णेय',
    'सोपान',
    'सोपानक',
    'सोपानिका',
    'सोपानिकी',
    'सोपाय',
    'सोपाश्रय',
    'सोफता',
    'सोफ़ा',
    'सोफा',
    'सोभर',
    'सोम',
    'सोमकांत',
    'सोमकुल्या',
    'सोमदत्ति',
    'सोमनाथ',
    'सोमप',
    'सोमपति',
    'सोमपायी',
    'सोमपाल',
    'सोमभक्ष',
    'सोमयोनि',
    'सोमरक्ष',
    'सोमरस',
    'सोमराज',
    'सोमलता',
    'सोमवंश',
    'सोमवार',
    'सोमा',
    'सोमांबुपा',
    'सोमांशु',
    'सोमाद',
    'सोमीय',
    'सोमेश्वर',
    'सोमोदृभवा',
    'सोम्यवपु',
    'सोय',
    'सोयम',
    'सोयवस',
    'सोया',
    'सोयाबनी',
    'सोयाबीन',
    'सोरों',
    'सोल',
    'सोलंकी',
    'सोलउर',
    'सोलला',
    'सोलह',
    'सोलहवां',
    'सोलही',
    'सोलहौं',
    'सोल्उर',
    'सोल्डरिंग',
    'सोल्लुंठ',
    'सोवन',
    'सोवनार',
    'सोवीं',
    'सोशल',
    'सोशलिज्म',
    'सोशलिस्ट',
    'सोष्मा',
    'सोसाइटी',
    'सोसायटी',
    'सोस्मि',
    'सोहँ',
    'सोहं',
    'सोहंग',
    'सोहगी',
    'सोहन',
    'सोहना',
    'सोहनी',
    'सोहबत',
    'सोहबी',
    'सोहर',
    'सोहराना',
    'सोहला',
    'सोहान',
    'सोहावटी',
    'सोहासित',
    'सोहिनी',
    'सोहिस्म',
    'सोही',
    'सोऽहम्',
    'सौ',
    'सौंजा',
    'सौंदर्य',
    'सौंदर्यशालिनी',
    'सौंदर्यशाली',
    'सौंदर्यशास्त्र',
    'सौंदर्यहीन',
    'सौंदर्यहीनता',
    'सौंदर्यानुभव',
    'सौंदानुभूति',
    'सौंदानुभूतिपूर्ण',
    'सौंध',
    'सौंधना',
    'सौंधा',
    'सौंधी',
    'सौंप',
    'सौंपना',
    'सौंपने',
    'सौंपा',
    'सौंपी',
    'सौंफ',
    'सौंरना',
    'सौंह',
    'सौकन',
    'सौकर',
    'सौकारायण',
    'सौकिट',
    'सौकृत्य',
    'सौख्य',
    'सौगंधिक',
    'सौगत',
    'सौगतिक',
    'सौगन्ध',
    'सौगात',
    'सौगाती',
    'सौगुना',
    'सौगुनी',
    'सौचिक',
    'सौजना',
    'सौजन्य',
    'सौजन्यता',
    'सौजन्यपूर्ण',
    'सौजा',
    'सौड',
    'सौत',
    'सौतन',
    'सौतपन',
    'सौतला',
    'सौतलापन',
    'सौतांत्रिक',
    'सौति',
    'सौतिया',
    'सौतियापन',
    'सौतेला',
    'सौतेली',
    'सौत्य',
    'सौत्र',
    'सौत्रांतिक',
    'सौत्रामण',
    'सौथरी',
    'सौदा',
    'सौदाई',
    'सौदाकारी',
    'सौदागर',
    'सौदागरी',
    'सौदामनी',
    'सौदामिनी',
    'सौदायि',
    'सौदे',
    'सौदेबाज़ी',
    'सौदेबाजी',
    'सौध',
    'सौधक',
    'सौधकार',
    'सौनंद',
    'सौनंदी',
    'सौनिक',
    'सौन्दर्य',
    'सौन्दर्यपरक',
    'सौन्दर्यपरखता',
    'सौन्दर्यबोध',
    'सौन्दर्यवाद',
    'सौन्दर्यशास्त्र',
    'सौन्दर्यशास्त्री',
    'सौन्दर्यशास्त्रीय',
    'सौन्दर्यात्मक',
    'सौपना',
    'सौपने',
    'सौप्तिक',
    'सौफ',
    'सौफ्ट',
    'सौफ्टवेअर',
    'सौबल',
    'सौबली',
    'सौबलेय',
    'सौबलेयी',
    'सौबीर',
    'सौभद्रेय',
    'सौभाग्य',
    'सौभाग्यकांक्षिणी',
    'सौभाग्यवति',
    'सौभाग्यवती',
    'सौभाग्यशालिनी',
    'सौभाग्यशाली',
    'सौभाग्यहीनता',
    'सौम',
    'सौमनस्य',
    'सौमनस्यपूर्ण',
    'सौमनस्यहीनता',
    'सौमित्र',
    'सौमेधिक',
    'सौम्य',
    'सौम्यतः',
    'सौम्यता',
    'सौम्या',
    'सौम्याकार',
    'सौर',
    'सौरंगिक',
    'सौरघर',
    'सौरठी',
    'सौरभ',
    'सौरभवाह',
    'सौरभी',
    'सौरमण्डल',
    'सौराष्ट्र',
    'सौराष्ट्री',
    'सौरि',
    'सौरिक',
    'सौरी',
    'सौर्य',
    'सौल',
    'सौलक्षण्य',
    'सौला',
    'सौवर्ण',
    'सौवां',
    'सौवासिनी',
    'सौवीर',
    'सौवीरक',
    'सौवीराजन',
    'सौव्रतय',
    'सौष्ठ',
    'सौष्ठव',
    'सौष्ठवपूर्ण',
    'सौष्ठवहीन',
    'सौष्ठवहीनता',
    'सौस',
    'सौसनी',
    'सौसर',
    'सौसुप्तिक',
    'सौहँ',
    'सौहार्द',
    'सौहार्दपूर्ण',
    'सौहार्दपूर्णता',
    'सौहार्द्य',
    'सौहृद',
    'स्अंप',
    'स्कंत्ता',
    'स्कंद',
    'स्कंदक',
    'स्कंदन',
    'स्कंदनशीलता',
    'स्कंदित',
    'स्कंदितता',
    'स्कंदी',
    'स्कंदीय',
    'स्कंध',
    'स्कंधपट्टी',
    'स्कंधयह',
    'स्कंधवह',
    'स्कंधावार',
    'स्कंधिका',
    'स्कंधी',
    'स्कंधीय',
    'स्कंध्य',
    'स्कद',
    'स्कदजित',
    'स्कदता',
    'स्कधिक',
    'स्कन्ध',
    'स्कन्धावरण',
    'स्कबा',
    'स्करू',
    'स्कर्ट',
    'स्कर्बी',
    'स्कर्वी',
    'स्कल्पचर',
    'स्कवायर',
    'स्कांद',
    'स्कांदिक',
    'स्काउट',
    'स्काउटों',
    'स्काटलाकंड',
    'स्काटलैण्ड',
    'स्कार्फ',
    'स्किन',
    'स्की',
    'स्कीइंग',
    'स्कीम',
    'स्कूटर',
    'स्कूल',
    'स्कूली',
    'स्केट',
    'स्केटिंग',
    'स्केटिग',
    'स्केट्स',
    'स्केप',
    'स्केल',
    'स्कैंडल',
    'स्कैच',
    'स्कोर',
    'स्कौलरशिप',
    'स्क्रिप्ट',
    'स्क्रीन',
    'स्क्रीनप्ले',
    'स्क्रु',
    'स्क्रुइग',
    'स्क्रुड',
    'स्क्रोल',
    'स्क्वाड्रन',
    'स्क्वायर',
    'स्क्वैंड्रन',
    'स्क्वैड',
    'स्खलन',
    'स्खलनशील',
    'स्खलित',
    'स्टडी',
    'स्टर्न',
    'स्टर्लिंग',
    'स्टांप',
    'स्टाइल',
    'स्टाइलिस्ट',
    'स्टाक',
    'स्टाकंड',
    'स्टाकों',
    'स्टाटर',
    'स्टाटिंर्ग',
    'स्टाप',
    'स्टाफ',
    'स्टाम्प',
    'स्टार',
    'स्टारडम',
    'स्टारलैट',
    'स्टार्च',
    'स्टार्ट',
    'स्टार्टर',
    'स्टिकर',
    'स्टिकिंग',
    'स्टिच',
    'स्टिचिंग',
    'स्टिल',
    'स्टिीवाड्रस',
    'स्टीम',
    'स्टीमर',
    'स्टीयर',
    'स्टीयरिंग',
    'स्टील',
    'स्टीवार्ड',
    'स्टूडियो',
    'स्टूडियों',
    'स्टूल',
    'स्टूवर्ड',
    'स्टेंसिल',
    'स्टेज',
    'स्टेट',
    'स्टेटमैंट',
    'स्टेटरुम',
    'स्टेडियम',
    'स्टेडैंअ',
    'स्टेनो',
    'स्टेशन',
    'स्टेशनरी',
    'स्टैंड',
    'स्टैंडडारईजेशन',
    'स्टैंडर्ड',
    'स्टैंप',
    'स्टैंसिल',
    'स्टैच्यू',
    'स्टैजा',
    'स्टैटिस्टिक्स',
    'स्टैड',
    'स्टैडंड्र्स',
    'स्टैण्ड',
    'स्टैनोगाफर',
    'स्टैनोगाफी',
    'स्टैप',
    'स्टैपनी',
    'स्टैपल',
    'स्टैपलर',
    'स्टैरजाइजर',
    'स्टोएकपाल',
    'स्टोएफ',
    'स्टोन',
    'स्टोपर',
    'स्टोर',
    'स्टोरिंग',
    'स्टोरी',
    'स्टोरेज',
    'स्टोर्ड',
    'स्टोर्स',
    'स्टोव',
    'स्टोवेज',
    'स्टौक',
    'स्टौकिंग',
    'स्टौपेज',
    'स्ट्राइक',
    'स्ट्राबैरी',
    'स्ट्राबोर्ड',
    'स्ट्रिपर',
    'स्ट्रिपिग',
    'स्ट्रीकिंग',
    'स्ट्रीट',
    'स्ट्रीटवाकर',
    'स्ट्रीम',
    'स्ट्रैचर',
    'स्ट्रोक',
    'स्ट्रौग',
    'स्तंब',
    'स्तंभ',
    'स्तंभन',
    'स्तंभनी',
    'स्तंभपथ',
    'स्तंभपूजा',
    'स्तंभराज',
    'स्तंभवेष्ठी',
    'स्तंभशीर्ष',
    'स्तंभाकार',
    'स्तंभित',
    'स्तंभितता',
    'स्तंभी',
    'स्तंभेश्वर',
    'स्तन',
    'स्तनंधय',
    'स्तनंधयता',
    'स्तनक',
    'स्तनकाः',
    'स्तनधारी',
    'स्तनप',
    'स्तनपायी',
    'स्तनप्',
    'स्तनवती',
    'स्तनहीना',
    'स्तनाग्र',
    'स्तनावरण',
    'स्तनित',
    'स्तनी',
    'स्तनीय',
    'स्तनों',
    'स्तन्य',
    'स्तन्यपता',
    'स्तन्यपायन',
    'स्तन्यपायी',
    'स्तबक',
    'स्तब्ध',
    'स्तब्धता',
    'स्तब्धन',
    'स्तब्धरोमा',
    'स्तब्धलोचन',
    'स्तभ',
    'स्तभालिंद',
    'स्तम्भ',
    'स्तम्भक',
    'स्तम्भा',
    'स्तम्भोपरिरचना',
    'स्तर',
    'स्तरण',
    'स्तरयुक्त',
    'स्तरस्थ',
    'स्तरहीन',
    'स्तरांतर',
    'स्तराधान',
    'स्तरिका',
    'स्तरित',
    'स्तरीकरण',
    'स्तरीय',
    'स्तरीयता',
    'स्तरों',
    'स्तव',
    'स्तवक',
    'स्तवन',
    'स्तवरक',
    'स्तवि',
    'स्तविता',
    'स्तान',
    'स्ताव',
    'स्तिमित',
    'स्तीर्ण',
    'स्तुत',
    'स्तुति',
    'स्तुतिकार',
    'स्तुतिगान',
    'स्तुतिप्रिय',
    'स्तुत्य',
    'स्तुवि',
    'स्तूप',
    'स्तूपीय',
    'स्तृति',
    'स्तेन',
    'स्तेय',
    'स्तेयी',
    'स्तोतव्य',
    'स्तोता',
    'स्तोत्र',
    'स्तोत्रगान',
    'स्तोत्रगाना',
    'स्तोत्रविद्या',
    'स्तोम',
    'स्त्यान',
    'स्त्रंवती',
    'स्त्रंसी',
    'स्त्रक',
    'स्त्रग्दाम',
    'स्त्रग्धर',
    'स्त्रग्वान',
    'स्त्रयध्यक्ष',
    'स्त्रयी',
    'स्त्रयुचित',
    'स्त्रव',
    'स्त्रवण',
    'स्त्रवणशील',
    'स्त्रवित',
    'स्त्रव्य',
    'स्त्रष्टव्य',
    'स्त्रष्टा',
    'स्त्रष्टार',
    'स्त्रस्त',
    'स्त्राव',
    'स्त्रावक',
    'स्त्रावण',
    'स्त्रावशील',
    'स्त्रावित',
    'स्त्रावी',
    'स्त्रियों',
    'स्त्रियोचित',
    'स्त्री',
    'स्त्रींद्रिय',
    'स्त्रीकरण',
    'स्त्रीकाम',
    'स्त्रीत्व',
    'स्त्रीत्वपूर्ण',
    'स्त्रीधन',
    'स्त्रीपुर',
    'स्त्रीप्रिय',
    'स्त्रीमंद्रक',
    'स्त्रीय',
    'स्त्रीयां',
    'स्त्रीयों',
    'स्त्रीरत्न',
    'स्त्रीराशिक',
    'स्त्रीलिंग',
    'स्त्रीलिण्ग्ग',
    'स्त्रीवियोग',
    'स्त्रीव्रत',
    'स्त्रीसुख',
    'स्त्रुवा',
    'स्त्रृति',
    'स्त्रैण',
    'स्त्रैणता',
    'स्त्रोत',
    'स्त्रोतिस्वनी',
    'स्त्रोतोजव',
    'स्त्रौत',
    'स्थ',
    'स्थंडिल',
    'स्थंडिलशय',
    'स्थग',
    'स्थगन',
    'स्थगित',
    'स्थण',
    'स्थणा',
    'स्थणाराज',
    'स्थनांतरण',
    'स्थनांतरित',
    'स्थपति',
    'स्थपन',
    'स्थपनी',
    'स्थपित',
    'स्थल',
    'स्थलचर',
    'स्थलचित्र',
    'स्थलमार्ग',
    'स्थलयान',
    'स्थलवासी',
    'स्थला',
    'स्थली',
    'स्थलीय',
    'स्थविर',
    'स्थविरता',
    'स्थविरा',
    'स्थाई',
    'स्थाणुता',
    'स्थान',
    'स्थानंतरण',
    'स्थानक',
    'स्थानच्युत',
    'स्थानच्युति',
    'स्थानपति',
    'स्थानपाल',
    'स्थानबद्घ',
    'स्थानबद्ध',
    'स्थानभ्रष्ट',
    'स्थानवाची',
    'स्थानस्थ',
    'स्थानस्थत',
    'स्थानस्थतः',
    'स्थानस्थता',
    'स्थानहीन',
    'स्थानहीनता',
    'स्थानांतरण',
    'स्थानांतरणकर्ता',
    'स्थानांतरिती',
    'स्थानाधिपति',
    'स्थानाध्यक्ष',
    'स्थानाध्यक्षता',
    'स्थानान्तरण',
    'स्थानान्तरित',
    'स्थानापन्न',
    'स्थानापन्नतः',
    'स्थानापन्नता',
    'स्थानाभाव',
    'स्थानिक',
    'स्थानिकता',
    'स्थानी',
    'स्थानीकरण',
    'स्थानीकृत',
    'स्थानीय',
    'स्थानीयकरण',
    'स्थानीयता',
    'स्थानुसार',
    'स्थानेश्वर',
    'स्थानों',
    'स्थाप',
    'स्थापक',
    'स्थापत्य',
    'स्थापत्यकला',
    'स्थापन',
    'स्थापना',
    'स्थापनार्थ',
    'स्थापनीय',
    'स्थापयिता',
    'स्थापित',
    'स्थापितता',
    'स्थापितव्य',
    'स्थाप्य',
    'स्थायिक',
    'स्थायिता',
    'स्थायित्व',
    'स्थायिवत्ता',
    'स्थायी',
    'स्थायीकरण',
    'स्थायीभाव',
    'स्थाल',
    'स्थाली',
    'स्थावर',
    'स्थावरता',
    'स्थावरन',
    'स्थाविर',
    'स्थास्णु',
    'स्थास्णुता',
    'स्थास्नु',
    'स्थास्नुकरण',
    'स्थित',
    'स्थिततः',
    'स्थितता',
    'स्थितन',
    'स्थितप्रज्ञ',
    'स्थितप्रज्ञता',
    'स्थितबुद्धि',
    'स्थितरचित',
    'स्थिति',
    'स्थितिगत',
    'स्थितिजन्य',
    'स्थितिमापी',
    'स्थितियों',
    'स्थितिवश',
    'स्थितिवशता',
    'स्थिर',
    'स्थिरक',
    'स्थिरकर्मा',
    'स्थिरगति',
    'स्थिरचित्त',
    'स्थिरचित्तता',
    'स्थिरच्छाय',
    'स्थिरजीवी',
    'स्थिरण',
    'स्थिरता',
    'स्थिरदंष्ट्र',
    'स्थिरधी',
    'स्थिरपतिज्ञ',
    'स्थिरपद',
    'स्थिरबुद्धि',
    'स्थिरमनस्क',
    'स्थिरमनस्कता',
    'स्थिरमना',
    'स्थिरयोनि',
    'स्थिरयौवन',
    'स्थिरवत',
    'स्थिरसार',
    'स्थिरा',
    'स्थिरात्मा',
    'स्थिरायु',
    'स्थिरीकरण',
    'स्थूल',
    'स्थूलक',
    'स्थूलकाय',
    'स्थूलकायता',
    'स्थूलतः',
    'स्थूलता',
    'स्थूलत्वहीनता',
    'स्थूलपाद',
    'स्थूलफल',
    'स्थूलबुद्धि',
    'स्थूलमान',
    'स्थूलांग',
    'स्थूलाक्षर',
    'स्थूलोदर',
    'स्थेर्य',
    'स्थोरा',
    'स्थौरा',
    'स्नकर',
    'स्नहिल',
    'स्नात',
    'स्नातक',
    'स्नातकता',
    'स्नातकीय',
    'स्नातकोत्तर',
    'स्नातकोत्सव',
    'स्नान',
    'स्नानकक्ष',
    'स्नानकुण्ड',
    'स्नानगृह',
    'स्नानघर',
    'स्नानघाट',
    'स्नानागार',
    'स्नानाभिषिक्त',
    'स्नानाभिषेक',
    'स्नानार्थी',
    'स्नानालय',
    'स्नानी',
    'स्नायु',
    'स्नाव',
    'स्निग्ध',
    'स्निग्धता',
    'स्निग्धभाषी',
    'स्नी',
    'स्नु',
    'स्नुत',
    'स्नुषा',
    'स्नेक',
    'स्नेकस्किन',
    'स्नेह',
    'स्नेहक',
    'स्नेहगर्भ',
    'स्नेहघट',
    'स्नेहन',
    'स्नेहपगा',
    'स्नेहपात्र',
    'स्नेहपूर्ण',
    'स्नेहमय',
    'स्नेहल',
    'स्नेहवर',
    'स्नेहशीलता',
    'स्नेहसार',
    'स्नेहहीन',
    'स्नेहहीनता',
    'स्नेहालिंगन',
    'स्नेहित',
    'स्नेहिता',
    'स्नेही',
    'स्नैक्स',
    'स्नैप',
    'स्नो',
    'स्नौब',
    'स्नौर्कल',
    'स्पंज',
    'स्पंद',
    'स्पंदन',
    'स्पंदनशील',
    'स्पंदनहीनता',
    'स्पंदनहोना',
    'स्पंदित',
    'स्पंदी',
    'स्पंदोलिका',
    'स्पन्दन',
    'स्पन्दित',
    'स्परष्टा',
    'स्परेअर',
    'स्परौकेट',
    'स्पर्ण',
    'स्पर्द्धा',
    'स्पर्धा',
    'स्पर्धी',
    'स्पर्श',
    'स्पर्शक',
    'स्पर्शज',
    'स्पर्शज्या',
    'स्पर्शता',
    'स्पर्शन',
    'स्पर्शनक',
    'स्पर्शमणि',
    'स्पर्शसंघर्षी',
    'स्पर्शाअनुभूति',
    'स्पर्शाअनुभूतिहीन',
    'स्पर्शानन्दा',
    'स्पर्शानुचलन',
    'स्पर्शानुभूतिहीन',
    'स्पर्शित',
    'स्पर्शिता',
    'स्पर्शी',
    'स्पर्शेद्रिय',
    'स्पशारनुभूतियुकत',
    'स्पष्ट',
    'स्पष्टतः',
    'स्पष्टतया',
    'स्पष्टता',
    'स्पष्टभाषिता',
    'स्पष्टभाषी',
    'स्पष्टवादिता',
    'स्पष्टवादी',
    'स्पष्टाकित',
    'स्पष्टार्थक',
    'स्पष्टार्थी',
    'स्पष्टित',
    'स्पष्टीकरण',
    'स्पष्टीकरणीय',
    'स्पष्टीकृत',
    'स्पाइरल',
    'स्पाइरैलिटी',
    'स्पाई',
    'स्पाट',
    'स्पार्क',
    'स्पिंडल',
    'स्पिंरग',
    'स्पिन',
    'स्पिरिट',
    'स्पीकर',
    'स्पीच',
    'स्पीड',
    'स्पीड़',
    'स्पीडोमीटर',
    'स्पूतनिक',
    'स्पून',
    'स्पूल',
    'स्पृश्य',
    'स्पृष्ट',
    'स्पृहणीय',
    'स्पृहा',
    'स्पृहित',
    'स्पृही',
    'स्पृह्य',
    'स्पेन',
    'स्पेरअर',
    'स्पेशल',
    'स्पेशलिस्ट',
    'स्पेस',
    'स्पेसमैन',
    'स्पेसवादक',
    'स्पेसशिप',
    'स्पैक्टेकल',
    'स्पैक्टेक्यूलर',
    'स्पैक्ट्रम',
    'स्पैनर',
    'स्पोक',
    'स्पोट्र्स',
    'स्पोर्टवीअर',
    'स्पोर्ट्स',
    'स्पौंसर',
    'स्पौंसरर',
    'स्पौंसरिंग',
    'स्पौंसर्ड',
    'स्प्रिंगी',
    'स्फटिक',
    'स्फटिका',
    'स्फटिकिकरण',
    'स्फटिकोपम',
    'स्फटिप्रभ',
    'स्फरणशील',
    'स्फर्तिप्रद',
    'स्फार',
    'स्फारण',
    'स्फारित',
    'स्फीत',
    'स्फीति',
    'स्फीतिकारक',
    'स्फीतिकारी',
    'स्फुट',
    'स्फुटता',
    'स्फुटन',
    'स्फुटफल',
    'स्फुटित',
    'स्फुटितता',
    'स्फुटी',
    'स्फुटीकरण',
    'स्फुरक',
    'स्फुरण',
    'स्फुरित',
    'स्फुलन',
    'स्फुलिंग',
    'स्फुलिंगन',
    'स्फूजिर्त',
    'स्फूर्त',
    'स्फूर्ति',
    'स्फूर्तिपूर्वक',
    'स्फूर्तिमय',
    'स्फूर्तिमान',
    'स्फूर्तियुक्त',
    'स्फूर्तिहीन',
    'स्फूर्तिहीनता',
    'स्फोटन',
    'स्फ्र',
    'स्मगलर',
    'स्मगलिंग',
    'स्मट',
    'स्मणीय',
    'स्मय',
    'स्मयी',
    'स्मर',
    'स्मरण',
    'स्मरणपत्र',
    'स्मरणपत्रों',
    'स्मरणवश',
    'स्मरणा',
    'स्मरणातीत',
    'स्मरणार्थ',
    'स्मरणी',
    'स्मरणीय',
    'स्मरणोत्सव',
    'स्मरदहन',
    'स्मरसख',
    'स्मरांकुश',
    'स्मरोन्माद',
    'स्मर्तव्य',
    'स्मर्ता',
    'स्मर्य',
    'स्मारक',
    'स्मारण',
    'स्मारिका',
    'स्मारी',
    'स्मार्त',
    'स्मार्तः',
    'स्मालस्केल',
    'स्मित',
    'स्मृत',
    'स्मृति',
    'स्मृतिधर',
    'स्मृतिधरता',
    'स्मृतिनष्ट',
    'स्मृतिभ्रंश',
    'स्मृतिभ्रष्ट',
    'स्मृतिविद',
    'स्मृतिशेष',
    'स्मृतिहर',
    'स्मृतिहीन',
    'स्मृतिहीनता',
    'स्मेर',
    'स्मैक',
    'स्मोक',
    'स्मोकिंग',
    'स्यंद',
    'स्यंदक',
    'स्यंदन',
    'स्यंदनशील',
    'स्यंदी',
    'स्यंदोलिका',
    'स्यमीक',
    'स्यसा',
    'स्याँग',
    'स्याँगी',
    'स्यात',
    'स्याद्वाद',
    'स्याद्वाद्व',
    'स्याना',
    'स्यापा',
    'स्यालिका',
    'स्याह',
    'स्याहकार',
    'स्याहकारा',
    'स्याहचश्म',
    'स्याहज़बान',
    'स्याहताब',
    'स्याहदिल',
    'स्याही',
    'स्याहीघटा',
    'स्याहीदान',
    'स्याहीवाला',
    'स्याहीसोख',
    'स्यू',
    'स्यूत',
    'स्यूति',
    'स्यूतिकार',
    'स्यून',
    'स्रदीप्तता',
    'स्रदीप्ति',
    'स्रवण',
    'स्रवित',
    'स्रादशील',
    'स्राव',
    'स्रावण',
    'स्रावरोध',
    'स्राविका',
    'स्रावी',
    'स्रोत',
    'स्रोतों',
    'स्रोत्र',
    'स्लंबरैट',
    'स्लम',
    'स्लाइड',
    'स्लाइस',
    'स्लाइसर',
    'स्लिप',
    'स्लीपर',
    'स्लीपरैट',
    'स्लीपिंग',
    'स्लीव',
    'स्लेजिंग',
    'स्लेट',
    'स्लेटी',
    'स्लैंजिंग',
    'स्लैज',
    'स्लैजिंग',
    'स्लैपस्टिक',
    'स्लो',
    'स्व',
    'स्वंय',
    'स्वंयवर',
    'स्वंवशी',
    'स्वंसंज्ञतः',
    'स्वः',
    'स्वकथा',
    'स्वकरण',
    'स्वकर्म',
    'स्वकर्मी',
    'स्वकल्याण',
    'स्वकाय',
    'स्वकार्य',
    'स्वकीय',
    'स्वकीया',
    'स्वकुटुम्बी',
    'स्वकुल',
    'स्वकृत',
    'स्वकृततः',
    'स्वकृतता',
    'स्वकेंद्रता',
    'स्वगत',
    'स्वगिर्क',
    'स्वगुण',
    'स्वगेश्वरी',
    'स्वगौका',
    'स्वगौरव',
    'स्वग्रहणाधिकार',
    'स्वग्राही',
    'स्वग्र्य',
    'स्वघात',
    'स्वघाती',
    'स्वघोष',
    'स्वघोषित',
    'स्वचल',
    'स्वचलन',
    'स्वचलित',
    'स्वचलीकरण',
    'स्वचालन',
    'स्वचालनीकरण',
    'स्वचालित',
    'स्वचालिततः',
    'स्वचालितवत',
    'स्वचिंतन',
    'स्वच्छ',
    'स्वच्छंद',
    'स्वच्छंदतः',
    'स्वच्छंदता',
    'स्वच्छंदतापूर्ण',
    'स्वच्छक',
    'स्वच्छता',
    'स्वच्छतापूर्वक',
    'स्वच्छतावादी',
    'स्वच्छताविज्ञान',
    'स्वच्छन',
    'स्वच्छन्द',
    'स्वच्छन्दता',
    'स्वच्छन्दतावाद',
    'स्वच्छन्दतावादी',
    'स्वच्छन्दवादिता',
    'स्वच्छमंडल',
    'स्वच्छीकरण',
    'स्वच्छीकृत',
    'स्वच्छेचारितापूर्ण',
    'स्वछन्दतावादी',
    'स्वज',
    'स्वजता',
    'स्वजन',
    'स्वजनता',
    'स्वजनीय',
    'स्वजन्म',
    'स्वजन्मा',
    'स्वजा',
    'स्वजात',
    'स्वजाति',
    'स्वजातीय',
    'स्वजित',
    'स्वजिर्',
    'स्वजेता',
    'स्वज्ञाति',
    'स्वज्ञान',
    'स्वणिम',
    'स्वतंत्र',
    'स्वतंत्रः',
    'स्वतंत्रता',
    'स्वतः',
    'स्वतःकृततः',
    'स्वतत्रतां',
    'स्वतत्व',
    'स्वता',
    'स्वत्यागी',
    'स्वत्व',
    'स्वत्वधारी',
    'स्वत्वपूर्ण',
    'स्वत्वयुक्त',
    'स्वत्वविलेख',
    'स्वत्वहीनता',
    'स्वत्वाधिकार',
    'स्वत्वाधिकारी',
    'स्वदन',
    'स्वदर्शन',
    'स्वदर्शी',
    'स्वदारा',
    'स्वदाह',
    'स्वदेश',
    'स्वदेशता',
    'स्वदेशी',
    'स्वदेशीकरण',
    'स्वदेशीय',
    'स्वद्रेश',
    'स्वद्रोही',
    'स्वधर्म',
    'स्वधर्मस्थता',
    'स्वधर्मी',
    'स्वधा',
    'स्वधाकर',
    'स्वधाभोजी',
    'स्वधिति',
    'स्वन',
    'स्वनकरण',
    'स्वनकारी',
    'स्वनन',
    'स्वनप्रक्रियात्मक',
    'स्वनांक',
    'स्वनानुपात',
    'स्वनामधन्य',
    'स्वनारी',
    'स्वनिक',
    'स्वनिग्रह',
    'स्वनित',
    'स्वनियंत्रण',
    'स्वनियोजन',
    'स्वनियोजनक',
    'स्वनियोजित',
    'स्वनिर्णयगत',
    'स्वनिर्भर',
    'स्वनिर्भरता',
    'स्वनिर्मित',
    'स्वनिवेदन',
    'स्वनी',
    'स्वन्य',
    'स्वपक्ष',
    'स्वपक्षी',
    'स्वपक्षीय',
    'स्वपति',
    'स्वपत्नी',
    'स्वपशंसी',
    'स्वपीडन',
    'स्वपीड़क',
    'स्वपीड़न',
    'स्वपुरुष',
    'स्वपूर्ण',
    'स्वपोषी',
    'स्वप्न',
    'स्वप्नकर',
    'स्वप्नचारिता',
    'स्वप्नचारी',
    'स्वप्नदर्शी',
    'स्वप्नदृष्टा',
    'स्वप्नदोष',
    'स्वप्नपूर्णता',
    'स्वप्नमय',
    'स्वप्नवादी',
    'स्वप्नविचारी',
    'स्वप्नशकुनी',
    'स्वप्नशील',
    'स्वप्नसात',
    'स्वप्नाटन',
    'स्वप्नातीत',
    'स्वप्नातीतता',
    'स्वप्नाधिक',
    'स्वप्रंशसा',
    'स्वप्रजननी',
    'स्वप्रसुत',
    'स्वप्रेरक',
    'स्वप्रेरण',
    'स्वप्रेरत्व',
    'स्वबोध',
    'स्वभट',
    'स्वभव',
    'स्वभार्या',
    'स्वभाव',
    'स्वभावगत',
    'स्वभावज',
    'स्वभावजता',
    'स्वभावतः',
    'स्वभावश',
    'स्वभाविक',
    'स्वभाविकता',
    'स्वभावी',
    'स्वभू',
    'स्वभूत',
    'स्वभूति',
    'स्वभूमि',
    'स्वमंथन',
    'स्वमन्यक',
    'स्वमानी',
    'स्वमोह',
    'स्वय',
    'स्वयं',
    'स्वयंकृति',
    'स्वयंग्रहण',
    'स्वयंजात',
    'स्वयंता',
    'स्वयंधिगत',
    'स्वयंपाकी',
    'स्वयंपूर्ण',
    'स्वयंप्रभ',
    'स्वयंप्रभा',
    'स्वयंप्ररकाश',
    'स्वयंभुव',
    'स्वयंभुवन',
    'स्वयंभू',
    'स्वयंभूः',
    'स्वयंमेव',
    'स्वयंवर',
    'स्वयंवरा',
    'स्वयंवशता',
    'स्वयंवह',
    'स्वयंवादी',
    'स्वयंशिक्षित',
    'स्वयंसंचारी',
    'स्वयंसमर्थ',
    'स्वयंसमर्थता',
    'स्वयंसिद्घ',
    'स्वयंसिद्ध',
    'स्वयंसिद्धि',
    'स्वयत्त्ता',
    'स्वयमीश्वर',
    'स्वयमेव',
    'स्वयम्',
    'स्वयसाचिता',
    'स्वयोनि',
    'स्वर',
    'स्वरः',
    'स्वरक',
    'स्वरकर',
    'स्वरक्षक',
    'स्वरक्षी',
    'स्वरगान',
    'स्वरग्राम',
    'स्वरचित्त',
    'स्वरचित्तता',
    'स्वरछेकानुप्रास',
    'स्वरण',
    'स्वरति',
    'स्वरध्वनि',
    'स्वरनादी',
    'स्वरपत्तन',
    'स्वरपात',
    'स्वरभंग',
    'स्वरभंगी',
    'स्वरमान',
    'स्वरमापी',
    'स्वरयंत्र',
    'स्वरलिपि',
    'स्वरविज्ञान',
    'स्वरवेध्ी',
    'स्वरसंगा',
    'स्वरसाम्य',
    'स्वरस्थान',
    'स्वरहीन',
    'स्वरहीनता',
    'स्वरांश',
    'स्वराघात',
    'स्वराज',
    'स्वराजी',
    'स्वराज्य',
    'स्वरान्तराल',
    'स्वरावली',
    'स्वराष्ट्र',
    'स्वराष्ट्री',
    'स्वराष्ट्रीय',
    'स्वरित',
    'स्वरीयता',
    'स्वरुचि',
    'स्वरुप',
    'स्वरुपतः',
    'स्वरुपिका',
    'स्वरुपिणी',
    'स्वरुपी',
    'स्वरुप्',
    'स्वरूप',
    'स्वरेणु',
    'स्वरैक्य',
    'स्वरों',
    'स्वरोच्चारण',
    'स्वरोजगार',
    'स्वरोत्पादक',
    'स्वरोद',
    'स्वरोदय',
    'स्वर्ग',
    'स्वर्गजीवी',
    'स्वर्गत',
    'स्वर्गतुल्य',
    'स्वर्गद्वार',
    'स्वर्गपति',
    'स्वर्गपथ',
    'स्वर्गवास',
    'स्वर्गवासी',
    'स्वर्गाधिपति',
    'स्वर्गारोहण',
    'स्वर्गिक',
    'स्वर्गी',
    'स्वर्गीय',
    'स्वर्गोपम',
    'स्वर्जी',
    'स्वर्ण',
    'स्वर्णकांस्य',
    'स्वर्णकार',
    'स्वर्णकाल',
    'स्वर्णचूड़',
    'स्वर्णजीवी',
    'स्वर्णदेश',
    'स्वर्णपत्र',
    'स्वर्णपुरी',
    'स्वर्णयुक्त',
    'स्वर्णयुग',
    'स्वर्णरेता',
    'स्वर्णवर्णाक',
    'स्वर्णसू',
    'स्वर्णाकार',
    'स्वर्णाभ',
    'स्वर्णारि',
    'स्वर्णाश्म',
    'स्वर्णिम',
    'स्वर्णिमा',
    'स्वर्नगरी',
    'स्वर्सरिता',
    'स्वलक्षण',
    'स्वलाभ',
    'स्वलिंग',
    'स्वलिखित',
    'स्वलिखितः',
    'स्वलिखितता',
    'स्वलीन',
    'स्वलीनता',
    'स्वलेख',
    'स्वलोक',
    'स्वल्प',
    'स्वल्पता',
    'स्वल्पभाषी',
    'स्वल्पायु',
    'स्वल्पाहार',
    'स्वल्पाहारी',
    'स्वल्पिष्ठ',
    'स्वल्पेच्छ',
    'स्ववंश',
    'स्ववन्धयता',
    'स्ववमधिगत',
    'स्वविवेक',
    'स्ववृत्ति',
    'स्वशासन',
    'स्वशासित',
    'स्वशिक्षित',
    'स्वश्लाधा',
    'स्वसंयम',
    'स्वसत्ता',
    'स्वसमर्पक',
    'स्वसुखाय',
    'स्वस्ति',
    'स्वस्तिक',
    'स्वस्तिकद',
    'स्वस्तिका',
    'स्वस्तिकार',
    'स्वस्तिकासन',
    'स्वस्तिमती',
    'स्वस्तिमान',
    'स्वस्तिमुख',
    'स्वस्त्ययन',
    'स्वस्त्री',
    'स्वस्थ',
    'स्वस्थचित्त',
    'स्वस्थता',
    'स्वस्थानस्थतः',
    'स्वस्थ्य',
    'स्वस्थ्यवर्धक',
    'स्वस्य',
    'स्वहस्त',
    'स्वहस्तलिखितः',
    'स्वहस्ताक्षरित',
    'स्वहा',
    'स्वहित',
    'स्वाँग',
    'स्वांकन',
    'स्वांग',
    'स्वांगी',
    'स्वांगीकरण',
    'स्वांगीकृत',
    'स्वांतःसुखाय',
    'स्वाक्षर',
    'स्वाक्षरित',
    'स्वागत',
    'स्वागतकर्ता',
    'स्वागतगतिक',
    'स्वागतपूर्वक',
    'स्वागतम',
    'स्वागतम्',
    'स्वागताध्यक्ष',
    'स्वागताभिलाषी',
    'स्वागती',
    'स्वागतीकरण',
    'स्वागतेय',
    'स्वागत्य',
    'स्वाचरण',
    'स्वाचालित',
    'स्वाजिर्त',
    'स्वातंत्रय',
    'स्वाति',
    'स्वातिकारी',
    'स्वातिमुखा',
    'स्वात्तता',
    'स्वात्तीकरण',
    'स्वाद',
    'स्वादक',
    'स्वादकलिका',
    'स्वादज्ञ',
    'स्वादन',
    'स्वादपूर्णता',
    'स्वादयुक्त',
    'स्वादयुक्तता',
    'स्वादलोलुप',
    'स्वादव्य',
    'स्वादहीन',
    'स्वादहीनता',
    'स्वादानुभूति',
    'स्वादान्न',
    'स्वादित',
    'स्वादिमा',
    'स्वादिष्ट',
    'स्वादिष्टता',
    'स्वादिष्ठ',
    'स्वादिष्ठता',
    'स्वादी',
    'स्वादीय',
    'स्वादु',
    'स्वादुता',
    'स्वादुपटोल',
    'स्वादुपाका',
    'स्वादुमल',
    'स्वादेंद्रिय',
    'स्वादेशिक',
    'स्वाधिकारी',
    'स्वाधिकृत',
    'स्वाधीन',
    'स्वाधीनता',
    'स्वाध्याय',
    'स्वानिक',
    'स्वानिकी',
    'स्वानिमिक',
    'स्वानुभविक',
    'स्वानुभूति',
    'स्वानुशासन',
    'स्वानुशासित',
    'स्वान्नभोजी',
    'स्वापक',
    'स्वापिका',
    'स्वापी',
    'स्वाप्त',
    'स्वाभविकता',
    'स्वाभाविक',
    'स्वाभाविकता',
    'स्वाभिमान',
    'स्वाभिमानपूर्ण',
    'स्वाभिमानपूर्णता',
    'स्वाभिमानहीन',
    'स्वाभिमानहीनता',
    'स्वाभिमानी',
    'स्वाभिलेखी',
    'स्वामिता',
    'स्वामित्व',
    'स्वामित्वपूर्ण',
    'स्वामित्ववाला',
    'स्वामित्ववाली',
    'स्वामित्वहरण',
    'स्वामित्वीय',
    'स्वामिनी',
    'स्वामिभक्त',
    'स्वामिभक्ति',
    'स्वामी',
    'स्वामीय',
    'स्वामीयुक्त',
    'स्वामीरहित',
    'स्वामीहीन',
    'स्वायंभुव',
    'स्वायंभुवी',
    'स्वायत्त',
    'स्वायत्तता',
    'स्वायत्तत्ता',
    'स्वायत्तशासी',
    'स्वायत्तीकृत',
    'स्वारथ',
    'स्वारोचिष',
    'स्वार्जित',
    'स्वार्थ',
    'स्वार्थता',
    'स्वार्थपर',
    'स्वार्थपरकता',
    'स्वार्थपरायण',
    'स्वार्थपरायणता',
    'स्वार्थपूर्ण',
    'स्वार्थवादिता',
    'स्वार्थवादी',
    'स्वार्थहीन',
    'स्वार्थहीनतः',
    'स्वार्थहीनता',
    'स्वार्थाचार',
    'स्वार्थी',
    'स्वालंबतितता',
    'स्वालोकन',
    'स्वाल्प',
    'स्वावलंबी',
    'स्वाश्रयी',
    'स्वास्तिक',
    'स्वास्थयप्रद',
    'स्वास्थयहर',
    'स्वास्थिकी',
    'स्वास्थ्य',
    'स्वास्थ्यकर',
    'स्वास्थ्यकर्त्ता',
    'स्वास्थ्यप्रद',
    'स्वास्थ्यवर्धकता',
    'स्वास्थ्यहर',
    'स्वास्थ्यहीनता',
    'स्वास्थ्योन्मुख',
    'स्वाह',
    'स्वाहकरण',
    'स्वाहा',
    'स्वाहाकार',
    'स्वाहाभोजी',
    'स्विच',
    'स्विचन',
    'स्विचमैन',
    'स्विटज़रलाकंड',
    'स्विप्नल',
    'स्विमिंग',
    'स्विष्ट',
    'स्वीकरण',
    'स्वीकरणीय',
    'स्वीकर्ता',
    'स्वीकार',
    'स्वीकारक',
    'स्वीकारना',
    'स्वीकारात्मक',
    'स्वीकारात्मकता',
    'स्वीकारोक्ति',
    'स्वीकार्य',
    'स्वीकार्र',
    'स्वीकार्रता',
    'स्वीकृत',
    'स्वीकृति',
    'स्वीट',
    'स्वीप',
    'स्वीपर',
    'स्वीय',
    'स्वेंद्रित',
    'स्वेचछापूर्णता',
    'स्वेचछामृत्यु',
    'स्वेच्छया',
    'स्वेच्छा',
    'स्वेच्छाचार',
    'स्वेच्छाचारिणी',
    'स्वेच्छाचारित',
    'स्वेच्छाचारितः',
    'स्वेच्छाचारिता',
    'स्वेच्छाचारितापूर्ण',
    'स्वेच्छाचारी',
    'स्वेच्छातंत्रवादी',
    'स्वेच्छात्मक',
    'स्वेच्छानुकूल',
    'स्वेच्छानुकूलतः',
    'स्वेच्छापूर्णता',
    'स्वेच्छापूर्वक',
    'स्वेच्छार्थ',
    'स्वेच्छाहीनतः',
    'स्वेच्छित',
    'स्वेटर',
    'स्वेड',
    'स्वेद',
    'स्वेदक',
    'स्वेदन',
    'स्वेदनिका',
    'स्वेदमय',
    'स्वेदांबु',
    'स्वेदित',
    'स्वेदी',
    'स्वैचाराचारी',
    'स्वैचारी',
    'स्वैच्छिक',
    'स्वैटर',
    'स्वैर',
    'स्वैरगत',
    'स्वैरतः',
    'स्वैरता',
    'स्वैराचार',
    'स्वैरिणी',
    'स्वैरिता',
    'स्वैरी',
    'स्वोत्थ',
    'स्वोपजीविता',
    'स्वोपजीवी',
    'स्वोपाजिर्त',
    'स्वोपार्जक',
    'स्वोर्ड',
    'स्फ़ीतक',
    'स्फ़ीति',
    'स्फ़ीतिकारी',
    'स्फ़ूर्ती',
    'सज़ा',
    'सफ़र',
    'सफ़ल',
    'सफ़लता',
    'सफ़लतापूर्वक',
    'सफ़ा',
    'सफ़ाई',
    'सफ़ाईवाला',
    'सफ़ाईवाली',
    'सफ़ेद',
    'सफ़ेदी',
    'ह',
    'हँकडना',
    'हँकडाव',
    'हँकवा',
    'हँकाई',
    'हँडिका',
    'हँडिया',
    'हँववैया',
    'हँसना',
    'हँसमुखता',
    'हँसली',
    'हँसाई',
    'हँसिया',
    'हँसी',
    'हँसीदार',
    'हँसुली',
    'हँसोड',
    'हंअर',
    'हंक',
    'हंका',
    'हंगरी',
    'हंगामा',
    'हंगामेदार',
    'हंगार',
    'हंगारना',
    'हंजा',
    'हंजिका',
    'हंडना',
    'हंडा',
    'हंडी',
    'हंड्रेडवेट',
    'हंत',
    'हंता',
    'हंबा',
    'हंभा',
    'हंशीश',
    'हंस',
    'हंसक',
    'हंसकर',
    'हंसकूट',
    'हंसगमन',
    'हंसगमना',
    'हंसगामिता',
    'हंसगामिनी',
    'हंसचूउ',
    'हंसता',
    'हंसती',
    'हंसते',
    'हंसत्व',
    'हंसना',
    'हंसने',
    'हंसपदी',
    'हंसमुख',
    'हंसराज',
    'हंसवाहन',
    'हंसशावक',
    'हंसांशु',
    'हंसाने',
    'हंसिका',
    'हंसिनी',
    'हंसिया',
    'हंसियारा',
    'हंसी',
    'हंसीपूर्ण',
    'हंसोडपन',
    'हंसोडा',
    'हंसोडी',
    'हंसौहां',
    'हंसौहीं',
    'हई',
    'हउ',
    'हक',
    'हकदार',
    'हकदारी',
    'हकनामा',
    'हकपरस्त',
    'हकपरस्ती',
    'हकबक',
    'हकबकाना',
    'हकलाना',
    'हकलापन',
    'हकलाहट',
    'हक़कीत',
    'हकार',
    'हकारत',
    'हकालना',
    'हकीकत',
    'हकीकी',
    'हकीम',
    'हकीमी',
    'हक्का',
    'हक्काक',
    'हक्कानी',
    'हक्रीक्री',
    'हगिर्ज',
    'हचक',
    'हचकना',
    'हचका',
    'हचकाना',
    'हचकोला',
    'हज',
    'हजम',
    'हजरत',
    'हजलूता',
    'हज़म',
    'हज़ार',
    'हज़ारः',
    'हजामत',
    'हजामती',
    'हजार',
    'हजारगुना',
    'हजारगुनी',
    'हजारहा',
    'हजारा',
    'हजारि',
    'हजारी',
    'हजारों',
    'हजूरी',
    'हजो',
    'हज्ज',
    'हज्जाम',
    'हज्जे',
    'हज्म',
    'हट',
    'हटक',
    'हटकन',
    'हटकना',
    'हटका',
    'हटन',
    'हटना',
    'हटबया',
    'हटवाई',
    'हटवार',
    'हटवैया',
    'हटा',
    'हटाइर',
    'हटाई',
    'हटाए',
    'हटाकर',
    'हटाना',
    'हटाने',
    'हटापन',
    'हटाया',
    'हटाव',
    'हटी',
    'हटुई',
    'हटुवा',
    'हटैती',
    'हटौती',
    'हट्ट',
    'हट्टा',
    'हट्टी',
    'हठ',
    'हठत',
    'हठधमिर्ता',
    'हठधर्मी',
    'हठपतपूर्ण',
    'हठपूर्वक',
    'हठमत',
    'हठमतता',
    'हठमति',
    'हठयोग',
    'हठवश',
    'हठविद्या',
    'हठाग्रह',
    'हठी',
    'हठीला',
    'हठीलापन',
    'हठीली',
    'हठोन्मत्त',
    'हठोन्माद',
    'हडकंप',
    'हडकना',
    'हडकाना',
    'हडकी',
    'हडताल',
    'हडतालिया',
    'हडताली',
    'हड़',
    'हड़ताल',
    'हड़प',
    'हड़पना',
    'हड़पू',
    'हड़पूपन',
    'हड़बड़',
    'हड़बड़ा',
    'हड़बड़ाकर',
    'हड़बड़ाना',
    'हड़बड़ाहट',
    'हड़बड़ी',
    'हड़बाँग',
    'हड़यल',
    'हड़ावल',
    'हड़ीला',
    'हड़्डियाँ',
    'हड्डियां',
    'हड्डियों',
    'हड्डी',
    'हड्डीवाला',
    'हत',
    'हतक',
    'हतकुल',
    'हतचेतन',
    'हतचेष्टता',
    'हतदैव',
    'हतना',
    'हतपाप',
    'हतप्रभ',
    'हतप्रमाद',
    'हतबीज',
    'हतबुद्धि',
    'हतभाग',
    'हतभाग्य',
    'हतभाग्यता',
    'हतमान',
    'हतमानस',
    'हतवंश',
    'हतवीड़',
    'हतवीर्य',
    'हतव्य',
    'हतशक्ति',
    'हतशेष',
    'हतश्रद्घ',
    'हतश्री',
    'हतस्मृति',
    'हतहृदय',
    'हतात्मा',
    'हतादर',
    'हताभ',
    'हताश',
    'हताशा',
    'हताश्रय',
    'हताहत',
    'हति',
    'हतेन्द्रिय',
    'हतोत्तर',
    'हतोत्साह',
    'हतोत्साहित',
    'हतौज',
    'हतौजा',
    'हत्था',
    'हत्थी',
    'हत्या',
    'हत्याः',
    'हत्याकांड',
    'हत्यारा',
    'हत्यास्त्र',
    'हत्योन्माद',
    'हत्व',
    'हथउधार',
    'हथकंडा',
    'हथकड़ी',
    'हथकडी',
    'हथकरघा',
    'हथकर्घा',
    'हथकल',
    'हथकुआँ',
    'हथगाड़ी',
    'हथगोला',
    'हथछुट',
    'हथछोड़',
    'हथठेला',
    'हथनी',
    'हथफेर',
    'हथलपक',
    'हथलपका',
    'हथली',
    'हथवाँस',
    'हथा',
    'हथिनी',
    'हथियाना',
    'हथियाने',
    'हथियाया',
    'हथियार',
    'हथियारबंद',
    'हथियारबंदी',
    'हथियारबदं',
    'हथेली',
    'हथेलीः',
    'हथेलीत्राण',
    'हथैडा',
    'हथौटी',
    'हथौड़ा',
    'हथौड़ी',
    'हद',
    'हदबंद',
    'हदबंदी',
    'हदीस',
    'हनतीपन',
    'हनन',
    'हनना',
    'हनिर्या',
    'हनीमून',
    'हनु',
    'हनुमंत',
    'हनुमत',
    'हनुमान',
    'हनुमानः',
    'हनुमानव',
    'हनू',
    'हनूमान',
    'हनूष',
    'हन्य',
    'हप',
    'हप्पा',
    'हफीज़',
    'हफ्त',
    'हफ्ता',
    'हफ्तावारी',
    'हफ्ते',
    'हब',
    'हबशियों',
    'हबीब',
    'हबीबा',
    'हब्बा',
    'हब्शी',
    'हब्स',
    'हम',
    'हमउम्र',
    'हमउम्री',
    'हमका',
    'हमजिंस',
    'हमजुल्फ',
    'हमजोलिन',
    'हमजोली',
    'हमदर्द',
    'हमदर्दी',
    'हमदस्तर',
    'हमन',
    'हमनशीन',
    'हमनशीनी',
    'हमनाम',
    'हमनामी',
    'हमनिवाला',
    'हमने',
    'हमपल्ला',
    'हमपेशा',
    'हमप्याला',
    'हममज़हब',
    'हममुल्क',
    'हमरा',
    'हमराह',
    'हमराही',
    'हमला',
    'हमलावर',
    'हमलावार',
    'हमवक्त',
    'हमवतन',
    'हमवार',
    'हमवारी',
    'हमशक्ल',
    'हमसफ़र',
    'हमसर',
    'हमसाया',
    'हमसे',
    'हमाम',
    'हमायल',
    'हमार',
    'हमारा',
    'हमारापन',
    'हमारी',
    'हमारे',
    'हमारो',
    'हमाल',
    'हमीर',
    'हमें',
    'हमेश',
    'हमेशा',
    'हम्मीर',
    'हम्र्य',
    'हय',
    'हयंकष',
    'हयग्रीव',
    'हयग्रीवा',
    'हयग्रीवावतार',
    'हयध्न',
    'हयन',
    'हयमेध',
    'हयरोहण',
    'हयवाहन',
    'हयशीर्ष',
    'हया',
    'हयात',
    'हयादार',
    'हयादारी',
    'हयानन',
    'हयारोही',
    'हयाशना',
    'हयी',
    'हयेष्ट',
    'हर',
    'हरक',
    'हरकत',
    'हरकतदार',
    'हरकती',
    'हरकतें',
    'हरकारा',
    'हरगिज़',
    'हरगिरी',
    'हरगोविंद',
    'हरगौरी',
    'हरचंद',
    'हरज',
    'हरजाई',
    'हरजाना',
    'हरजीत',
    'हरजोता',
    'हरठिया',
    'हरड़',
    'हरण',
    'हरणि',
    'हरतान',
    'हरतेज',
    'हरना',
    'हरनेत्र',
    'हरपरवेरी',
    'हरप्रिय',
    'हरफनमौला',
    'हरफ़न',
    'हरबा',
    'हरबीज',
    'हरबोंग',
    'हरम',
    'हरमखाना',
    'हरमसरा',
    'हरमोनिया',
    'हरयाणा',
    'हरराय',
    'हररुप',
    'हरवक्त',
    'हरवीर्य',
    'हरषना',
    'हरषाना',
    'हरसना',
    'हरसर्ज',
    'हरसा',
    'हरसाना',
    'हरहर',
    'हरहराहट',
    'हरहा',
    'हरहार',
    'हरा',
    'हराँस',
    'हराई',
    'हराकर',
    'हराना',
    'हरापन',
    'हराभरापन',
    'हराम',
    'हरामकारी',
    'हरामखोर',
    'हरामखोरी',
    'हरामजना',
    'हरामज़दगी',
    'हरामज़ादा',
    'हरामी',
    'हरारत',
    'हरावल',
    'हरास',
    'हरि',
    'हरिकिशन',
    'हरिजन',
    'हरिण',
    'हरिणपदी',
    'हरिणांक',
    'हरिणी',
    'हरित',
    'हरितक',
    'हरितनील',
    'हरिताल',
    'हरितालक',
    'हरितालिका',
    'हरिताश्म',
    'हरितिल',
    'हरितोपल',
    'हरिद्रंजनी',
    'हरिद्रा',
    'हरिद्रु',
    'हरिद्वार',
    'हरिनाकुस',
    'हरिनाथ',
    'हरिनाम',
    'हरिपृष्ठा',
    'हरिप्रिय',
    'हरिप्रिया',
    'हरिबीज',
    'हरिमंथ',
    'हरिमा',
    'हरिय',
    'हरियल',
    'हरिया',
    'हरियाई',
    'हरियाणवी',
    'हरियाणा',
    'हरियान',
    'हरियाना',
    'हरियाला',
    'हरियाली',
    'हरिवंश',
    'हरिवास',
    'हरिवाहन',
    'हरिशर',
    'हरिस',
    'हरिसुत',
    'हरिहय',
    'हरिहर',
    'हरिहरात्मक',
    'हरिहूति',
    'हरी',
    'हरीकेन',
    'हरीतकी',
    'हरीतिमा',
    'हरीय',
    'हरीश',
    'हरे',
    'हरेणु',
    'हरेरी',
    'हरैना',
    'हरैया',
    'हरौती',
    'हरौला',
    'हर्ज',
    'हर्जा',
    'हर्जाना',
    'हर्टज़',
    'हर्डल',
    'हर्ता',
    'हर्निया',
    'हर्फ',
    'हर्यश्व',
    'हर्यातमा',
    'हर्र',
    'हर्राफा',
    'हर्ष',
    'हर्षक',
    'हर्षतिरेक',
    'हर्षद',
    'हर्षध्वनि',
    'हर्षनाद',
    'हर्षपूर्ण',
    'हर्षपूर्वक',
    'हर्षमय',
    'हर्षमयता',
    'हर्षयित्नु',
    'हर्षवर्धक',
    'हर्षवर्धन',
    'हर्षविभोर',
    'हर्षाभिनंदन',
    'हर्षाश्रु',
    'हर्षित',
    'हर्षी',
    'हर्षुल',
    'हर्षोन्माद',
    'हल',
    'हलंत',
    'हलक',
    'हलका',
    'हलकान',
    'हलकापन',
    'हलकारा',
    'हलकारी',
    'हलकी',
    'हलके',
    'हलकेरा',
    'हलचल',
    'हलचलपूर्ण',
    'हलजुता',
    'हलजूती',
    'हलडा',
    'हलद',
    'हलदिया',
    'हलदी',
    'हलधर',
    'हलना',
    'हलपत',
    'हलपशु',
    'हलफ',
    'हलफनामा',
    'हलफिया',
    'हलफी',
    'हलभृत',
    'हलरा',
    'हलराता',
    'हलराती',
    'हलराना',
    'हलराव',
    'हलवा',
    'हलवाई',
    'हलवाहा',
    'हलवाही',
    'हलसारी',
    'हलहलाना',
    'हला',
    'हलाक',
    'हलाकू',
    'हलाना',
    'हलाभला',
    'हलायुध',
    'हलाल',
    'हलाली',
    'हलाहल',
    'हलिक',
    'हलित',
    'हलिश',
    'हली',
    'हलीशा',
    'हलोनी',
    'हलोर',
    'हलोरना',
    'हलोरा',
    'हल्क',
    'हल्का',
    'हल्कापन',
    'हल्की',
    'हल्के',
    'हल्द',
    'हल्दिया',
    'हल्दी',
    'हल्द्वानी',
    'हल्य',
    'हल्या',
    'हल्लन',
    'हल्ला',
    'हल्लागुल्ला',
    'हल्लाज',
    'हल्लीश',
    'हलक़ा',
    'हलफ़नामा',
    'हव',
    'हवन',
    'हवलदार',
    'हवस',
    'हवा',
    'हवाइर',
    'हवाई',
    'हवाईजहाज़',
    'हवाखोरी',
    'हवागीर',
    'हवाते',
    'हवादान',
    'हवादार',
    'हवादारी',
    'हवानिकलना',
    'हवापानी',
    'हवाबाज',
    'हवाबाज़ी',
    'हवाबाजी',
    'हवारहित',
    'हवालगी',
    'हवाला',
    'हवालात',
    'हवालाती',
    'हवाले',
    'हवास',
    'हवासिल',
    'हवि',
    'हविर्दान',
    'हविर्भू',
    'हविर्भेजी',
    'हविर्याजी',
    'हविष्य',
    'हविष्यान्न',
    'हविस',
    'हवेली',
    'हव्य',
    'हव्यवाह',
    'हव्यान्न',
    'हव्यापपाक',
    'हव्याशन',
    'हव्वा',
    'हश्र',
    'हषारत्कर्ष',
    'हषोर्त्फुल्ल',
    'हषोर्त्सव',
    'हषोर्न्मत्त',
    'हषोर्न्माद',
    'हषोर्लस',
    'हस',
    'हसंती',
    'हसतीय',
    'हसन',
    'हसनीय',
    'हसब',
    'हसरत',
    'हसरती',
    'हसिका',
    'हसित',
    'हसिता',
    'हसीन',
    'हसीना',
    'हस्त',
    'हस्तक',
    'हस्तकला',
    'हस्तकारी',
    'हस्तकृति',
    'हस्तकौशल',
    'हस्तक्षेप',
    'हस्तक्षेपक',
    'हस्तगत',
    'हस्तग्राहक',
    'हस्तचालक',
    'हस्तताल',
    'हस्तप्रद',
    'हस्तमैथुन',
    'हस्तरेखा',
    'हस्तरेखाविद',
    'हस्तलाघव',
    'हस्तलिंगन',
    'हस्तलिखित',
    'हस्तलिखिततः',
    'हस्तलिपि',
    'हस्तलेख',
    'हस्तलेखक',
    'हस्तशिल्पी',
    'हस्तस्थ',
    'हस्तहीन',
    'हस्तांजलि',
    'हस्तांतरण',
    'हस्तांतरणकर्ता',
    'हस्तांतरित',
    'हस्ताकार',
    'हस्ताक्षर',
    'हस्ताक्षरकर्ता',
    'हस्ताक्षराधीन',
    'हस्ताक्षरित',
    'हस्ताक्षरों',
    'हस्ताक्षार',
    'हस्ताक्षेप',
    'हस्तागति',
    'हस्ताग्र',
    'हस्तादत्त',
    'हस्तादान',
    'हस्तामलकवत',
    'हस्तारविंद',
    'हस्ताश्रय',
    'हस्तासत्र',
    'हस्ताहस्ति',
    'हस्ताहस्तिका',
    'हस्तिक',
    'हस्तिकण',
    'हस्तिकर',
    'हस्तिगिरी',
    'हस्तिनी',
    'हस्तिमल्ल',
    'हस्ती',
    'हस्तीमुख',
    'हस्तीय',
    'हस्तेंद्रिय',
    'हस्त्य',
    'हस्थ',
    'हस्पतल',
    'हस्बे',
    'हहर',
    'हहराना',
    'हहल',
    'हहा',
    'हहोलिका',
    'हाँ',
    'हाँक',
    'हाँकन',
    'हाँकना',
    'हाँकने',
    'हाँका',
    'हाँकी',
    'हाँके',
    'हाँग',
    'हाँगना',
    'हाँगर',
    'हाँगा',
    'हाँगी',
    'हाँगुर',
    'हाँजी',
    'हाँड',
    'हाँडी',
    'हाँनेवाला',
    'हाँफ',
    'हाँफता',
    'हाँफती',
    'हाँफना',
    'हाँसी',
    'हां',
    'हांक',
    'हांकना',
    'हांकने',
    'हांका',
    'हांगकांग',
    'हांफते',
    'हांफना',
    'हांलाकि',
    'हाइक',
    'हाइकिंग',
    'हाइग्रोमीटर',
    'हाइजीन',
    'हाइजैकिंग',
    'हाइट',
    'हाइड्रो',
    'हाइड्रोकार्बस',
    'हाइड्रोक्लोरिक',
    'हाइड्रोजन',
    'हाइड्रोजनेटिड',
    'हाइड्रोप्लेन',
    'हाइपोकौड्रिया',
    'हाइफन',
    'हाइबिडाइजेशन',
    'हाइवे',
    'हाई',
    'हाईजैकर',
    'हाउस',
    'हाऊ',
    'हाऊस',
    'हाकने',
    'हाकर',
    'हाकाल',
    'हाकित',
    'हाकिम',
    'हाकी',
    'हाज',
    'हाजजा',
    'हाजत',
    'हाजतमंद',
    'हाजती',
    'हाज़मेदार',
    'हाज़रा',
    'हाज़री',
    'हाजिर',
    'हाजिरजवाब',
    'हाजिराती',
    'हाजिरी',
    'हाजी',
    'हाट',
    'हाटक',
    'हाटकलोचन',
    'हाटकेश्वर',
    'हाडमांस',
    'हाड़',
    'हाड़मांस',
    'हाड़ी',
    'हाड़ीय',
    'हात',
    'हातक',
    'हाता',
    'हातिम',
    'हाथ',
    'हाथः',
    'हाथकरघा',
    'हाथखर्च',
    'हाथबंबा',
    'हाथा',
    'हाथाछाँटी',
    'हाथापाई',
    'हाथाबाँही',
    'हाथी',
    'हाथीः',
    'हाथीखाना',
    'हाथीघर',
    'हाथीदाँत',
    'हाथीदांत',
    'हाथीवान',
    'हाथों',
    'हाथ्',
    'हादसा',
    'हाद्रिकता',
    'हान',
    'हाना',
    'हानि',
    'हानिकर',
    'हानिकारक',
    'हानिकारी',
    'हानिपूर्ति',
    'हानिप्रद',
    'हानियां',
    'हानियों',
    'हानिरहित',
    'हानेवाली',
    'हाफ़',
    'हाफ़िज',
    'हाफ़िजा',
    'हामला',
    'हामी',
    'हामीदार',
    'हामीदारी',
    'हाय',
    'हायण',
    'हायन',
    'हायनिक',
    'हायर',
    'हायरार्क',
    'हायल',
    'हायसिर्थ',
    'हार',
    'हारक',
    'हारजीतहीन',
    'हारजीतहीनता',
    'हारण',
    'हारन',
    'हारना',
    'हारने',
    'हारमोन',
    'हारमोनियम',
    'हारवाला',
    'हारा',
    'हारावल',
    'हारिक',
    'हारित',
    'हारी',
    'हारीत',
    'हारे',
    'हार्ड',
    'हार्डवेअर',
    'हार्डवेयर',
    'हार्द',
    'हार्दिक',
    'हार्दिकता',
    'हार्बर',
    'हार्मोन',
    'हार्य',
    'हार्रा',
    'हार्वेस्टर',
    'हाल',
    'हालचाल',
    'हालडोल',
    'हालत',
    'हालतवाला',
    'हालन',
    'हालना',
    'हालपूछ',
    'हालरा',
    'हालही',
    'हाला',
    'हालाँकि',
    'हालांकि',
    'हालाकि',
    'हालात',
    'हालाते',
    'हालिका',
    'हालिम',
    'हालिया',
    'हाली',
    'हालीय',
    'हालैंडः',
    'हालैण्ड',
    'हाव',
    'हावक',
    'हावभाव',
    'हावभावपूर्ण',
    'हावी',
    'हाशिए',
    'हाशिया',
    'हास',
    'हासक',
    'हासदी',
    'हासन',
    'हासनिक',
    'हासशील',
    'हासहीन',
    'हासा',
    'हासित',
    'हासिद',
    'हासिपटल',
    'हासिये',
    'हासिल',
    'हासी',
    'हास्तिक',
    'हास्पिटल',
    'हास्य',
    'हास्यक',
    'हास्यकर',
    'हास्यकार',
    'हास्यजनक',
    'हास्यता',
    'हास्यपूर्ण',
    'हास्यप्रद',
    'हास्यरस',
    'हास्यहीन',
    'हास्यास्पद',
    'हास्यास्पदता',
    'हास्योत्पादक',
    'हाहा',
    'हाहाकार',
    'हाज़िर',
    'हाज़िरी',
    'हाफ़टोन',
    'हिंकार',
    'हिंग',
    'हिंगलाची',
    'हिंगु',
    'हिंगुल',
    'हिंगुलाजा',
    'हिंगुली',
    'हिंच',
    'हिंचाई',
    'हिंज',
    'हिंडक',
    'हिंडन',
    'हिंडोरा',
    'हिंडोलक',
    'हिंडोलन',
    'हिंडोला',
    'हिंडोली',
    'हिंद',
    'हिंदवी',
    'हिंदसा',
    'हिंदी',
    'हिंदु',
    'हिंदुस्तान',
    'हिंदुस्तानी',
    'हिंदू',
    'हिंदूपद',
    'हिंदूवाद',
    'हिंदोल',
    'हिंदोला',
    'हिंदोलिका',
    'हिंदोस्ताँ',
    'हिंदोस्तान',
    'हिंसक',
    'हिंसकता',
    'हिंसन',
    'हिंसना',
    'हिंसा',
    'हिंसात्मक',
    'हिंसारु',
    'हिंसालुता',
    'हिंसित',
    'हिंस्य',
    'हिंस्र',
    'हिंस्रता',
    'हिंस्राः',
    'हिंस्राउ',
    'हिआव',
    'हिकमत',
    'हिकमती',
    'हिकायत',
    'हिकायतगिरी',
    'हिक्का',
    'हिचक',
    'हिचकते',
    'हिचकदार',
    'हिचकना',
    'हिचकशील',
    'हिचकाईकिंग',
    'हिचकाना',
    'हिचकिचाते',
    'हिचकिचाना',
    'हिचकिचाने',
    'हिचकिचाहट',
    'हिचकी',
    'हिचकू',
    'हिचकोला',
    'हिचकोले',
    'हिचकोलेदार',
    'हिचर',
    'हिचहाईकिंग',
    'हिजड़न',
    'हिजड़ा',
    'हिजड़ापन',
    'हिजड़ी',
    'हिजरी',
    'हिजली',
    'हिजाब',
    'हिज्जे',
    'हिज्ब',
    'हिटर',
    'हिटलर',
    'हिटलरवाद',
    'हिडिंब',
    'हिडिंबा',
    'हिडिंबारि',
    'हिडोला',
    'हिण्याक्ष',
    'हित',
    'हितकर',
    'हितकाम',
    'हितकारिता',
    'हितकारी',
    'हितधिकारी',
    'हितप्रहरी',
    'हितबद्ध',
    'हितबुद्धि',
    'हितलाभ',
    'हिताई',
    'हिताकांक्षी',
    'हिताधिकारिक',
    'हिताधिकारी',
    'हितानुसार',
    'हितार्थी',
    'हिताहित',
    'हितू',
    'हितैषणा',
    'हितैषिता',
    'हितैषी',
    'हितों',
    'हितोपदेश',
    'हिदायत',
    'हिदायतकार',
    'हिन',
    'हिनहिनाहट',
    'हिना',
    'हिनाबंदी',
    'हिन्दी',
    'हिन्दुस्तान',
    'हिन्दू',
    'हिप',
    'हिप्नोटिज्म',
    'हिफाजत',
    'हिफाज़त',
    'हिफ्ज',
    'हिब्रू',
    'हिम',
    'हिमंचल',
    'हिमंत',
    'हिमक',
    'हिमकर',
    'हिमकारी',
    'हिमखंड',
    'हिमगिरी',
    'हिमगौर',
    'हिमघात',
    'हिमतेंदुआ',
    'हिमद्रवण',
    'हिमधर',
    'हिमधाव',
    'हिमन',
    'हिमनद',
    'हिमनदी',
    'हिमनदीय',
    'हिमपात',
    'हिमपुंज',
    'हिमप्रस्थ',
    'हिमभंजक',
    'हिममय',
    'हिमयान',
    'हिमयुक्त',
    'हिमयुगीन',
    'हिमवंत',
    'हिमवान',
    'हिमवाह',
    'हिमसरण',
    'हिमांक',
    'हिमांत',
    'हिमांशु',
    'हिमाक्रत',
    'हिमागम',
    'हिमाघात',
    'हिमाचल',
    'हिमाच्छन्न',
    'हिमाच्छादित',
    'हिमाद्रि',
    'हिमानी',
    'हिमाभूत',
    'हिमाभ्र',
    'हिमायत',
    'हिमायती',
    'हिमारि',
    'हिमालय',
    'हिमालयज',
    'हिमालयजा',
    'हिमालयवासी',
    'हिमाला',
    'हिमावरुद्घ',
    'हिमावृत',
    'हिमावृत्त',
    'हिमिका',
    'हिमित',
    'हिमित्र',
    'हिमीकरण',
    'हिमीकृत',
    'हिमीभूत',
    'हिमीय',
    'हिमोपल',
    'हिम्मत',
    'हिम्मतदार',
    'हिम्मती',
    'हिय',
    'हिया',
    'हियाँ',
    'हियाव',
    'हिर',
    'हिरण',
    'हिरणी',
    'हिरण्मय',
    'हिरण्य',
    'हिरण्यकेश',
    'हिरण्यगर्भ',
    'हिरण्यधनुः',
    'हिरण्यनाभ',
    'हिरण्यरेता',
    'हिरण्यवान',
    'हिरण्याक्ष',
    'हिरदय',
    'हिरदा',
    'हिरन',
    'हिरना',
    'हिरनी',
    'हिराना',
    'हिरावल',
    'हिरास',
    'हिरासत',
    'हिरासती',
    'हिरासाँ',
    'हिरोदक',
    'हिर्स',
    'हिर्साहिर्सी',
    'हिर्सी',
    'हिल',
    'हिलकना',
    'हिलकी',
    'हिलकोर',
    'हिलकोरना',
    'हिलकोरा',
    'हिलग',
    'हिलगना',
    'हिलगाना',
    'हिलजुल',
    'हिलता',
    'हिलन',
    'हिलना',
    'हिलने',
    'हिलमोचिका',
    'हिलसा',
    'हिला',
    'हिलाई',
    'हिलाकर',
    'हिलाना',
    'हिलाने',
    'हिलाल',
    'हिलाली',
    'हिलावट',
    'हिलावन',
    'हिलोर',
    'हिलोरना',
    'हिलोरा',
    'हिलोल',
    'हिल्लन',
    'हिल्लोल',
    'हिवाँर',
    'हिसंकता',
    'हिसांपूर्ण',
    'हिसाब',
    'हिसाबदाँ',
    'हिसाबदार',
    'हिसाबी',
    'हिसार',
    'हिसालू',
    'हिस्टीरिया',
    'हिस्रता',
    'हिस्सा',
    'हिस्से',
    'हिस्सेदार',
    'हिस्सेदारी',
    'हिस्सों',
    'ही',
    'हीं',
    'हींग',
    'हीक',
    'हीचंना',
    'हीज़',
    'हीटर',
    'हीठना',
    'हीन',
    'हीनक',
    'हीनकर्मी',
    'हीनकुल',
    'हीनतः',
    'हीनतर',
    'हीनतरता',
    'हीनता',
    'हीनदोष',
    'हीनबल',
    'हीनबुद्धि',
    'हीनमन्यता',
    'हीनमानक',
    'हीनमूल्य',
    'हीनमूल्यता',
    'हीनयान',
    'हीनस्तरता',
    'हीनस्तरीय',
    'हीना',
    'हीनांशु',
    'हीनार्थ',
    'हीनाशय',
    'हीनाशयता',
    'हीनित',
    'हीमिया',
    'हीमियागर',
    'हीमियादा',
    'हीमू',
    'हीमोग्लोबिन',
    'हीय',
    'हीर',
    'हीरक',
    'हीरा',
    'हीरातराश',
    'हीरे',
    'हीरो',
    'हीरोइन',
    'हीलर',
    'हीलहुज्जत',
    'हीला',
    'हीलिंग',
    'हीलियम',
    'हीलेबाज',
    'हील्स',
    'हुँकारना',
    'हुँकारा',
    'हुंकार',
    'हुंकारा',
    'हुंकृति',
    'हुंड',
    'हुंडन',
    'हुंड़ी',
    'हुंडा',
    'हुंडियां',
    'हुंडियों',
    'हुंडी',
    'हुंडीकर्ता',
    'हुआ',
    'हुआपन',
    'हुई',
    'हुए',
    'हुक',
    'हुकदार',
    'हुकना',
    'हुकमरान',
    'हुकुंत',
    'हुकुम',
    'हुकुर',
    'हुकूमत',
    'हुकूमती',
    'हुक्',
    'हुक्का',
    'हुक्काः',
    'हुक्म',
    'हुक्मनामा',
    'हुक्मरान',
    'हुक्मी',
    'हुचका',
    'हुचकी',
    'हुज़ूर',
    'हुजूम',
    'हुजूर',
    'हुजूरिया',
    'हुजूरी',
    'हुज्जत',
    'हुज्जतबाज़',
    'हुज्जती',
    'हुड',
    'हुडक',
    'हुडका',
    'हुडदंग',
    'हुड़कना',
    'हुड़का',
    'हुडुक',
    'हुडुक्क',
    'हुड्डूक',
    'हुत',
    'हुताग्नि',
    'हुतात्मक',
    'हुतात्मा',
    'हुताशन',
    'हुति',
    'हुदहुद',
    'हुनना',
    'हुनर',
    'हुनरमंद',
    'हुनरमंदी',
    'हुन्डी',
    'हुमक',
    'हुमकना',
    'हुमग',
    'हुमगना',
    'हुमचना',
    'हुमस',
    'हुमसना',
    'हुमेल',
    'हुये',
    'हुरहुल',
    'हुर्रा',
    'हुलकना',
    'हुलकी',
    'हुलराई',
    'हुलराता',
    'हुलराना',
    'हुलसना',
    'हुलसा',
    'हुलसाय',
    'हुलसित',
    'हुलसी',
    'हुलहुली',
    'हुलास',
    'हुलासक',
    'हुलिया',
    'हुलैया',
    'हुल्लड़',
    'हुल्लड़बाज',
    'हुल्लड़बाजी',
    'हुसना',
    'हुसैन',
    'हुसैनः',
    'हुस्न',
    'हूँक',
    'हूँकना',
    'हूँज',
    'हूँटना',
    'हूँठा',
    'हूँड़',
    'हूँण',
    'हूँस',
    'हूँसना',
    'हूं',
    'हूक',
    'हूकना',
    'हूत',
    'हूति',
    'हूबहू',
    'हूर',
    'हूराहूरी',
    'हूल',
    'हूलना',
    'हूश',
    'हूह',
    'हूहू',
    'हृत',
    'हृतपेशी',
    'हृति',
    'हृत्कंप',
    'हृत्ताप',
    'हृत्पिंड',
    'हृत्स्तंभ',
    'हृदय',
    'हृदयंगम',
    'हृदयंगमन',
    'हृदयग्राहिता',
    'हृदयग्राही',
    'हृदयता',
    'हृदयपात',
    'हृदयरोग',
    'हृदयवान',
    'हृदयविदारक',
    'हृदयस्थ',
    'हृदयस्पर्शी',
    'हृदयहारी',
    'हृदयहीन',
    'हृदयहीनता',
    'हृदयाकार',
    'हृदयामय',
    'हृदयालू',
    'हृदयावरण',
    'हृदयी',
    'हृदयीय',
    'हृदयेश',
    'हृदयेश्वर',
    'हृदयेश्वरी',
    'हृद्गत',
    'हृद्गम',
    'हृद्य',
    'हृल्लास',
    'हृषित',
    'हृषीक',
    'हृषीकेश',
    'हृष्ट',
    'हृष्टपुष्ट',
    'हृष्टमना',
    'हृष्टरोमा',
    'हृस्त',
    'हृस्व',
    'हेंगा',
    'हेअर',
    'हेकड़',
    'हेकड़ी',
    'हेकड़ीदार',
    'हेकड़ीबाज़',
    'हेक्टेयर',
    'हेच',
    'हेचपोच',
    'हेठ',
    'हेठा',
    'हेठी',
    'हेड',
    'हेडक्वार्टर्स',
    'हेड़ी',
    'हेत',
    'हेति',
    'हेतु',
    'हेतुक',
    'हेतुकथा',
    'हेतुतः',
    'हेतुता',
    'हेतुनिरूपी',
    'हेतुमदनुमान',
    'हेतुवाद',
    'हेतुवादी',
    'हेतुहीन',
    'हेतुहीनता',
    'हेतुहेतुमद्भाव',
    'हेत्वाभास',
    'हेना',
    'हेम',
    'हेमंत',
    'हेमंतीय',
    'हेमकोश्या',
    'हेमगिरि',
    'हेमज',
    'हेमजट',
    'हेमज्वाल',
    'हेमदंता',
    'हेमदुग्धी',
    'हेमरेणू',
    'हेमल',
    'हेमा',
    'हेमांग',
    'हेमांड',
    'हेमाभ',
    'हेमियानी',
    'हेय',
    'हेयपिन',
    'हेरंब',
    'हेरना',
    'हेरफेर',
    'हेराईन',
    'हेराफेरी',
    'हेरोइन',
    'हेलन',
    'हेलना',
    'हेलनीय',
    'हेलमेल',
    'हेला',
    'हेलि',
    'हेलिक',
    'हेलित',
    'हेली',
    'हेलीकाप्टर',
    'हेलीपोर्ट',
    'हेलो',
    'हैं',
    'हैंकउ',
    'हैंकल',
    'हैंकाई',
    'हैंकाना',
    'हैंकार',
    'हैंकारना',
    'हैंकारा',
    'हैंकाल',
    'हैंकेली',
    'हैंगओवर',
    'हैंगर',
    'हैंगार',
    'हैंड',
    'हैंडकफ',
    'हैंडनोट',
    'हैंडपंप',
    'हैंडबिल',
    'हैंडबुक',
    'हैंडबैग',
    'हैंडल',
    'हैंडलूम',
    'हैंडशैक',
    'हैंडिया',
    'हैंडीक्राफ्ट',
    'हैंफ',
    'हैंफाई',
    'हैकाया',
    'हैकिया',
    'हैकेया',
    'हैकेला',
    'हैक्टेयर',
    'हैक्टेर',
    'हैक्टैर',
    'हैक्स',
    'हैगार',
    'हैगिंग',
    'हैजा',
    'हैट',
    'हैड',
    'हैडिंग',
    'हैडिंब',
    'हैण्ड',
    'हैण्डलूम',
    'हैतिक',
    'हैतुक',
    'हैदरसैक',
    'हैदराबाद',
    'हैबत',
    'हैबतनाक',
    'हैम',
    'हैमंत',
    'हैमक',
    'हैमन',
    'हैमवत',
    'हैरंब',
    'हैरण्य',
    'हैरत',
    'हैरतअंगेज',
    'हैरान',
    'हैरानी',
    'हैल',
    'हैलम',
    'हैलिकौप्टर',
    'हैलूसिनेशन',
    'हैल्प',
    'हैल्पर',
    'हैल्मेट',
    'हैवनियत',
    'हैवरसै',
    'हैवान',
    'हैवानी',
    'हैवी',
    'हैशिश',
    'हैसबैस',
    'हैसियत',
    'हैसियतदार',
    'हैहय',
    'हैहेय',
    'हैज़ा',
    'हॉकी',
    'हॉल',
    'हॉल्ट',
    'हॉस्पीटल',
    'हो',
    'हों',
    'होंगी',
    'होंगे',
    'होंगें',
    'होंठ',
    'होंठदार',
    'होंठी',
    'होंठों',
    'होई',
    'होकर',
    'होगा',
    'होगी',
    'होगीं',
    'होगें',
    'होजरी',
    'होजियरी',
    'होटल',
    'होड',
    'होडका',
    'होडल',
    'होड़',
    'होड़ा',
    'होड़ाहोड़ी',
    'होडा',
    'होडिंग',
    'होढ़',
    'होत',
    'होतव्यता',
    'होता',
    'होती',
    'होते',
    'होत्र',
    'होत्री',
    'होत्वा',
    'होद',
    'होदा',
    'होनहार',
    'होनहारी',
    'होना',
    'होनाः',
    'होनी',
    'होने',
    'होनेवाला',
    'होनेवाली',
    'होनेवाले',
    'होम',
    'होमक',
    'होमना',
    'होमाग्नि',
    'होमियोपैथी',
    'होमी',
    'होमेंधन',
    'होमोजनाइज्ड',
    'होम्योपैथ',
    'होम्योपैथिक',
    'होम्योपैथी',
    'होरा',
    'होराविद',
    'होरी',
    'होर्डिंग',
    'होल',
    'होलदिल',
    'होलनाक',
    'होलसेल',
    'होलसेलर',
    'होला',
    'होलिका',
    'होलिकाग्नि',
    'होली',
    'होले',
    'होल्ड',
    'होल्डर',
    'होल्डाल',
    'होवन',
    'होश',
    'होशमंद',
    'होशियार',
    'होशियारी',
    'होस',
    'होस्ट',
    'होस्टल',
    'होस्टेस',
    'होस्टैस',
    'होहल्ला',
    'हौआ',
    'हौकना',
    'हौकर',
    'हौज',
    'हौट',
    'हौताशनि',
    'हौद',
    'हौदा',
    'हौदी',
    'हौबी',
    'हौराइजोंटल',
    'हौरिजोंटल',
    'हौर्न',
    'हौर्स',
    'हौर्सर्',
    'हौल',
    'हौलदिला',
    'हौले',
    'हौवा',
    'हौस',
    'हौसला',
    'हौसले',
    'ह्द',
    'ह्दयवाला',
    'ह्नव',
    'ह्नास',
    'ह्नासक',
    'ह्नासशील',
    'ह्नुति',
    'ह्नोता',
    'ह्रदलेखी',
    'ह्रदस्पंद',
    'ह्रसन',
    'ह्रसित',
    'ह्रस्व',
    'ह्राद',
    'ह्रास',
    'ह्रासगत',
    'ह्रासन',
    'ह्रासमान',
    'ह्रासमानता',
    'ह्रासित',
    'ह्रासोन्मुख',
    'ह्री',
    'ह्लाद',
    'ह्लादक',
    'ह्लादित',
    'ह्लादी',
    'ह्वास',
    'ह्वासन',
    'ह्वासवान',
    'ह्वासोन्मुख',
    'ह्वेल',
    'ह्सित',
    'हक़',
    'हक़दार',
    'हक़दारी',
    'हज़ार',
    'ेऐंड',
    'क़ब्ज़ा',
    'क़रार',
    'क़रारनामा',
    'क़ानून',
    'क़ीमत',
    'क़ैदी',
    'ज़ख्मी',
    'ज़नानियां',
    'ज़नानी',
    'ज़बरदस्त',
    'ज़बरदस्ती',
    'ज़बानी',
    'ज़ब्त',
    'ज़ब्ती',
    'ज़मानत',
    'ज़मानतदार',
    'ज़मानतनामा',
    'ज़मानती',
    'ज़मीन',
    'ज़रूरत',
    'ज़हर',
    'ज़ारी',
    'ज़ाहिर',
    'ज़िद्दी',
    'ज़िम्मा',
    'ज़िम्मेदार',
    'ज़िम्मेदारी',
    'ज़िम्मेवारी',
    'ज़िला',
    'ज़िल्दसाज़ी',
    'ज़ेवर',
    'ज़ोन',
    'ज़ोर',
    'ज़ोरदार',
    'ज़्यादा',
    'फ़राश',
    'फ़र्मो',
    'फ़र्स्ट',
    'फ़सल',
    'फ़सलवाले',
    'फ़ायर',
    'फ़ायरमैन',
    'फ़ारसी',
    'फ़ार्म',
    'फ़ार्मदाता',
    'फ़ार्मवाला',
    'फ़ार्मिग',
    'फ़ालतू',
    'फ़िटर',
    'फ़िलहाल',
    'फ़िल्म',
    'फ़िज़ीशियन',
    'फ़ीता',
    'फ़ील्ड',
    'फ़ीस',
    'फ़ेरो',
    'फ़ोटो',
    'फ़ोटोग्राफर',
    'फ़ोटोग्राफ़र',
    'फ़ोन',
    'फ़ोल्डर',
]