import { Cell } from './Cell'
import { unicodeSplit } from '../../lib/words'
import { matras, solutionLength } from '../../lib/words'
import { dotted_circle } from '../../lib/unicode.helper'

type Props = {
  guess: string
  className: string
}

export const CurrentRow = ({ guess, className }: Props) => {
  const splitGuess = unicodeSplit(guess)
  const emptyCells = Array.from(Array(solutionLength - splitGuess.length))
  const classes = `flex justify-center mb-1 ${className}`

  return (
    <div className={classes}>
      {splitGuess.concat(emptyCells).map((letter = '', i) => {
        let value
        if (!matras[i]) {
          value = letter
        } else if (matras[i].post === '' && letter === '') {
          value = matras[i].pre + dotted_circle + matras[i].post
        } else {
          value = matras[i].pre + letter + matras[i].post
        }
        return <Cell key={i} value={value} />
      })}
    </div>
  )
}
